import React, { useState, useEffect } from 'react'
import './Progressbar.css'

function Progressbar(props) {

    const [min, setMin] = useState(0)

    useEffect(() => {
        const { min } = props
        setMin(min)
    }, [props])

    return <div className="Progressbar_root">
        <div className="Progressbar_min" style={{ width: `${min}%` }} > {min + '%'} </div>
    </div>
}

export default Progressbar