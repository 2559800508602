
function Processdate(dateobj) {
    try {
        var monthNames = [
            "01", "02", "03",
            "04", "05", "06", "07",
            "08", "09", "10",
            "11", "12"
        ];

        var day = dateobj.getDate();
        var monthIndex = dateobj.getMonth();
        var year = dateobj.getFullYear();
        return year + '-' + monthNames[monthIndex] + '-' + day;
    }
    catch (e) {
        return ''
    }

}



export default Processdate