import React from 'react'
import { EMPTYSPACE } from '../../config/constants'
import { Checkdevice } from '../Checkdevice'
import './Headtitle.css'

function Headtitle({ name, bgcolor, color, icon }) {
    const device = Checkdevice()
    return <div className={icon? "headtitle_root" : 'headtitle_rootnobg'} style={{ backgroundColor: bgcolor, color: color }}>
        <div className='container' style={{ height: 85, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {icon}
            {EMPTYSPACE}
            {device !== 'MOBILE' ? <div style={{ fontSize: 30 }}>{name}</div> : <div style={{ fontSize: 24 }}>{name}</div>}
        </div>
    </div>
}

export default Headtitle
