import { useEffect, useState } from "react"
import { iconwrapper } from "../../../../../config/commonfunctions";
import { Link } from "react-router-dom";
import { Checkdevice, Portalmodal } from "../../../../../components";
import { input_dom } from "../../Invitationmaker/components/Commonelements/Commonelements";
import { PENCIL_ICON, COPY_ICON, DOUBLEHEART_ICON } from "../../../../../config/svgicons";
import { INVITATIONTYPES_ARR, WEBSITE_URL, production } from "../../../../../config/constants";

const iconcss = { width: 30, height: 30, color: 'var(--maindark)' }
const itype_icon_map = {
    'Wedding': iconwrapper(DOUBLEHEART_ICON, iconcss),
    'House-Warming': iconwrapper(DOUBLEHEART_ICON, iconcss),
    'Baby Shower': iconwrapper(DOUBLEHEART_ICON, iconcss),
    'Birthday Party': iconwrapper(DOUBLEHEART_ICON, iconcss),
    'Postponement': iconwrapper(DOUBLEHEART_ICON, iconcss)
}
const msgwrapper = (msg, i) => {
    return <div key={'msg' + i} className='planning_msgcreate'> {msg} </div>
}
const copyToClipboard = (string) => {
    let textarea;
    let result;

    try {
        textarea = document.createElement('textarea');
        textarea.setAttribute('readonly', true);
        textarea.setAttribute('contenteditable', true);
        textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
        textarea.value = string;

        document.body.appendChild(textarea);

        textarea.focus();
        textarea.select();

        const range = document.createRange();
        range.selectNodeContents(textarea);

        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        textarea.setSelectionRange(0, textarea.value.length);
        result = document.execCommand('copy');
    } catch (err) {
        console.error(err);
        result = null;
    } finally {
        document.body.removeChild(textarea);
    }

    // manual copy fallback using prompt
    if (!result) {
        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
        result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
        if (!result) {
            return false;
        }
    }
    return true;
}

const Continuepage = (props) => {
    const { lookupclick, continueclick, savepasscode, savepasscodemod, title, titlemod, from = '' } = props
    const input_css = { marginLeft: 0, marginRight: 0, width: '100%', margin: 0, }
    const [urlhandler, setUrlhandler] = useState('')
    const [passcode, setPasscode] = useState('')
    const [passcodemod, setPasscodemod] = useState('')
    const [itype, setItype] = useState('') // use in invitationmaker and guestbook and seatingchart
    const [newpasscode, setNewpasscode] = useState('')
    const [newpasscodemod, setNewpasscodemod] = useState('') // use in guestbook only
    const [modal, setModal] = useState(false) // for passcode
    const [modmodal, setModmodal] = useState(false) // for passcodemod
    const [savebtntxt, setSavebtntxt] = useState('Save')
    const [blocksave, setBlocksave] = useState(false)
    const [msgs, setMsgs] = useState('')
    const [copymode, setCopymode] = useState(false)

    useEffect(() => {
        if (copymode) {
            setTimeout(() => {
                setCopymode(false)
            }, [1500])
        }
    }, [copymode])

    const DEVICE = Checkdevice()
    const portalmidcss = {
        width: { 'WEB': 500, 'TABLET': 400, 'MOBILE': '100%' }[DEVICE],
        height: { 'WEB': 330, 'TABLET': 330, 'MOBILE': '100%' }[DEVICE],
        top: { 'WEB': 'calc( 50% - 165px )', 'TABLET': 'calc( 50% - 165px )', 'MOBILE': 0 }[DEVICE],
        backgroundColor: 'white',
        left: 0, right: 0,
        margin: 'auto',
        transform: 'none'
    }

    const openeditmodal = () => {
        setModal(true)
    }

    const openeditmodpasscodemodal = () => {
        setModmodal(true)
    }

    const unique_dom_map = {
        'invitationmaker': {
            // selection of event
            top: <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row' }}>
                {INVITATIONTYPES_ARR.map((val, index) => {
                    return <div className={"planning_option" + (val === itype)} key={val + index} >
                        <div className="planning_optiontop">{itype_icon_map[val]}</div>
                        <div className="planning_optionbtm"> {val}</div>
                    </div>
                })}
            </div>,
            btm: null,
        },
        'itineraryedit': {
            top: null,
            btm: null,
        },
        'seatingchart': {
            top: <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row' }}>
                {INVITATIONTYPES_ARR.map((val, index) => {
                    return <div className={"planning_option" + (val === itype)} key={val + index} >
                        <div className="planning_optiontop">{itype_icon_map[val]}</div>
                        <div className="planning_optionbtm"> {val}</div>
                    </div>
                })}
            </div>,
            btm: null,
        },
        'guestbook': {
            top: <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row' }}>
                {INVITATIONTYPES_ARR.map((val, index) => {
                    return <div className={"planning_option" + (val === itype)} key={val + index} >
                        <div className="planning_optiontop">{itype_icon_map[val]}</div>
                        <div className="planning_optionbtm"> {val}</div>
                    </div>
                })}
            </div>,
            btm: <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row' }}>
                {input_dom(
                    'Mod Passcode',
                    '',
                    passcodemod,
                    (e) => {
                        const value = e.target.value.includes(' ') ? e.target.value.replace(/\s+/g, "") : e.target.value
                        if (e.target.value.length < 50) { setPasscodemod(value); }
                    },
                    'modpasscode_id',
                    {},
                    true,
                    null,
                    {},
                    input_css
                )}
                <button className="planning_editbtn" onClick={openeditmodpasscodemodal}>
                    {iconwrapper(PENCIL_ICON, { borderRadius: 5, padding: 0, margin: 0, width: 20, height: 20 })}
                </button>
            </div>
        },
        'qrmaker': {
            top: <div />,
            btm: <div />,
        },
        'checklistmaker': {
            top: <div />,
            btm: <div />,
        },
        'budgetmaker': {
            top: <div />,
            btm: <div />,
        }
    }


    useEffect(() => {
        setNewpasscode('')
    }, [])

    useEffect(() => {
        setUrlhandler(props.urlhandler)
        setPasscode(props.passcode)
        setItype(props.itype)
        setPasscodemod(props.passcodemod)
    }, [props])

    const timersequence = (run, msg) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                setSavebtntxt(msg);
                setTimeout(() => {
                    run();
                    resolve(
                        {
                            'Saved': 'SUCCESS',
                            'Failed': 'FAILURE',
                        }[msg]
                    ); // Resolve the promise when the timers are done
                }, 1250);
            }, 1250);
        });
    };


    const onClose = () => {
        setSavebtntxt('Save')
        setNewpasscode('')
        setNewpasscodemod('')
        setMsgs([])
        setBlocksave(false)
        setModal(false)
        setModmodal(false)
    }

    const from_link_map = {
        'itineraryedit': {
            'copy': WEBSITE_URL + '/timelinelookup/' + urlhandler + '/passcode=' + passcode,
            'display': WEBSITE_URL + '/timelinelookup/' + urlhandler + '/passcode=' + passcode,
        },
        'invitationmaker': {
            'copy': WEBSITE_URL + '/invitation/' + urlhandler + '/passcode=' + passcode,
            'display': WEBSITE_URL + '/invitation/' + urlhandler + '/passcode=' + passcode,
        },
        'seatingchart': {
            'copy': WEBSITE_URL + '/seatingchartlookup/' + urlhandler + '/passcode=' + passcode,
            'display': WEBSITE_URL + '/seatingchartlookup/' + urlhandler + '/passcode=' + passcode,
        },
        'guestbook': {
            'copy': WEBSITE_URL + '/guestbooklookup/' + urlhandler + '/' + passcodemod,
            'display': WEBSITE_URL + '/guestbooklookup/' + urlhandler + '/' + passcodemod,
        },
        'qrmaker': {
            'copy': WEBSITE_URL + '/qrlookup/' + urlhandler + '/passcode=' + passcode,
            'display': WEBSITE_URL + '/qrlookup/' + urlhandler + '/passcode=' + passcode,
        },
    }


    const onCopy = () => {
        try {

            var linktext = from_link_map[from].copy

            var test = function (regexp) {
                return regexp.test(window.navigator.userAgent);
            }

            if (test(/safari/i)) { // is safari
                copyToClipboard(linktext)
                setCopymode(true)
            }
            else {
                navigator.clipboard.writeText(linktext)
                setCopymode(true)
            }

        }
        catch (e) {
        }
    }

    const copydom = copymode
        ? <div style={{ width: '100vw', position: 'absolute', placeContent: 'center', alignContent: 'center', left: 0, display: 'flex', bottom: 150, zIndex: 1 }}>
            <div style={{ display: 'flex', width: 200, height: 40, backgroundColor: 'var(--maindark)', color: '#ffffff', placeContent: 'center', alignContent: 'center', borderRadius: 5, position: 'fixed', alignItems: 'center' }}>Copied!</div>
        </div>
        : null

    return <div id='1-1' style={{ display: 'flex', flexDirection: 'column' }}>
        {unique_dom_map[from] && unique_dom_map[from].top ? unique_dom_map[from].top : null}
        {from === 'checklistmaker' || from === 'budgetmaker'
            ? null
            : <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row' }}>
                {input_dom(
                    from === 'guestbook' ? 'Guestbook URL Handler' : 'URL Handler',
                    'url',
                    from_link_map[from].display,
                    (e) => {
                        const value = e.target.value.includes(' ') ? e.target.value.replace(/\s+/g, "") : e.target.value
                        if (e.target.value.length < 50) { setUrlhandler(value); }
                    },
                    'urlhandler_id',
                    {},
                    true,
                    null,
                    {},
                    input_css
                )}
                <button className="planning_editbtn" onClick={onCopy}>
                    {iconwrapper(COPY_ICON, { borderRadius: 5, padding: 0, margin: 0, width: 20, height: 20 })}
                </button>
            </div>}
        {from === 'checklistmaker' || from === 'budgetmaker'
            ? null
            : <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row' }}>
                {input_dom(
                    'Passcode',
                    '',
                    passcode,
                    (e) => {
                        if (e.target.value.length < 50) { setPasscode(e.target.value); }
                    },
                    'passcode_id',
                    {},
                    true,
                    null,
                    {},
                    input_css
                )}
                <button className="planning_editbtn" onClick={openeditmodal}>
                    {iconwrapper(PENCIL_ICON, { borderRadius: 5, padding: 0, margin: 0, width: 20, height: 20 })}
                </button>
            </div>}
        {unique_dom_map[from] && unique_dom_map[from].btm ? unique_dom_map[from].btm : null}
        <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row', placeContent: 'flex-end' }}>
            {from === 'guestbook' || from === 'checklistmaker' || from === 'budgetmaker' ? null : <Link className="planning_lookupbtn" to={lookupclick} target="_blank">Lookup</Link>}
            <button className="planning_contbtn" onClick={continueclick}>Continue</button>
        </div>
        <Portalmodal // newpasscodemod
            portaltype={'commonportal'}
            open={modmodal}
            onClose={onClose}
            floatingclose={false}
            backdropvisible={true}
            modalstyle={portalmidcss}
            closestyle={{ right: 0 }}
            floatingclosecolor='#333333'
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="Rsvpexplorer_portaltitle">{titlemod}</div>
                <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
                    {input_dom(
                        'New Mod Passcode',
                        '',
                        newpasscodemod,
                        (e) => {
                            const value = e.target.value.includes(' ') ? e.target.value.replace(/\s+/g, "") : e.target.value
                            if (e.target.value.length < 50) { setNewpasscodemod(value); }
                        },
                        'modpasscode_id',
                        {},
                        false,
                        null,
                        {},
                        input_css
                    )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', placeContent: 'flex-end', marginTop: 5, padding: '0px 10px' }}>
                    {msgs}
                </div>
                <button
                    className="Rsvpexplorer_portalsave"
                    key={savebtntxt + '_savebtn'}
                    value={newpasscodemod}
                    disabled={blocksave}
                    onClick={(e) => {

                        let msgs = []
                        let passcodmod = e.target.value

                        if (passcodmod.length === 0) {
                            msgs.push(msgwrapper('Mod Passcode cannot be empty.', 1))
                        }

                        if (passcodmod.length < 3) {
                            msgs.push(msgwrapper('Mod Passcode is too short. Min 3 characters.', 2))
                        }

                        if (passcodmod.length > 100) {
                            msgs.push(msgwrapper('Mod Passcode is too long. Max 100 characters.', 100))
                        }

                        if (msgs.length === 0) {
                            setBlocksave(true)
                            setSavebtntxt('Saving...');
                            savepasscodemod(e.target.value).then((message) => timersequence(onClose, message === 'SUCCESS' ? 'Saved' : 'Failed'))
                        }
                        setMsgs(msgs)

                    }}>
                    {savebtntxt}
                </button>
            </div>
        </Portalmodal>
        <Portalmodal // newpasscode
            portaltype={'commonportal'}
            open={modal}
            onClose={onClose}
            floatingclose={false}
            backdropvisible={true}
            modalstyle={portalmidcss}
            closestyle={{ right: 0 }}
            floatingclosecolor='#333333'
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="Rsvpexplorer_portaltitle">{title}</div>
                <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
                    {input_dom(
                        'New Passcode',
                        '',
                        newpasscode,
                        (e) => {
                            const value = e.target.value.includes(' ') ? e.target.value.replace(/\s+/g, "") : e.target.value
                            if (e.target.value.length < 50) { setNewpasscode(value); }
                        },
                        'passcode_id',
                        {},
                        false,
                        null,
                        {},
                        input_css
                    )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', placeContent: 'flex-end', marginTop: 5, padding: '0px 10px' }}>
                    {msgs}
                </div>
                <button
                    className="Rsvpexplorer_portalsave"
                    style={{ borderRadius: 5 }}
                    key={savebtntxt + '_savebtn'}
                    value={newpasscode}
                    disabled={blocksave}
                    onClick={(e) => {

                        let msgs = []
                        let passcode = e.target.value

                        if (passcode.length === 0) {
                            msgs.push(msgwrapper('Passcode cannot be empty.', 1))
                        }

                        if (passcode.length < 3) {
                            msgs.push(msgwrapper('Passcode is too short. Min 3 characters.', 2))
                        }

                        if (passcode.length > 100) {
                            msgs.push(msgwrapper('Passcode is too long. Max 100 characters.', 100))
                        }

                        if (msgs.length === 0) {
                            setBlocksave(true)
                            setSavebtntxt('Saving...');
                            savepasscode(e.target.value).then((message) => timersequence(onClose, message === 'SUCCESS' ? 'Saved' : 'Failed'))
                        }
                        setMsgs(msgs)

                    }}>
                    {savebtntxt}
                </button>
            </div>
        </Portalmodal>
        {copydom}
    </div>
}

export default Continuepage