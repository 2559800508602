import React from 'react';
import { TELEGRAM_ICON_LARGE, TELEGRAM_WEDDINGMARKETPLACE, TELEGRAM_WEDDING, STARTUP_NAME, REDDIT_ICON, TELEGRAM_ICON, INSTAGRAM_ICON, FACEBOOK_ICON } from '../../../../config/constants';
import './Community.css'
import { iconwrapper } from '../../../../config/commonfunctions';
import { Checkdevice } from '../../../../components';
// <div>{`Join ${STARTUP_NAME}`}</div> 
const iconstyle = { width: 30, height: 30, display: 'flex', placeContent: 'center', placeItems: 'center' }
function Main() {
    const device = Checkdevice()
    const socialmediaarr = [
        {
            title: 'Reddit',
            icon: iconwrapper(REDDIT_ICON, iconstyle),
            items: [
                {
                    subtitle: "r/sgwedding",
                    explain: "A space to discuss wedding plans and ideas, share reviews, buy and sell items, and share your wedding experiences.",
                    ref: `https://www.reddit.com/r/sgwedding/`,
                },
                {
                    subtitle: "r/weavebliss",
                    explain: "Discuss anything related to the app, including feature suggestions, bug reports, performance issues, feature requests, and general discussion.",
                    ref: `https://www.reddit.com/r/${STARTUP_NAME.toLowerCase()}/`,
                },
            ]
        },
        {
            title: 'Telegram',
            icon: iconwrapper(TELEGRAM_ICON, iconstyle),
            items: [
                {
                    subtitle: "Weavebliss",
                    explain: "Real-time Chat, discuss anything related to the app, including feature suggestions, bug reports, performance issues, feature requests, and general discussion.",
                    ref: `tg://resolve?domain=${TELEGRAM_WEDDING}`,
                }
            ]
        },
        {
            title: 'Instagram',
            icon: iconwrapper(INSTAGRAM_ICON, iconstyle),
            items: [

                {
                    subtitle: "Weavebliss",
                    explain: "",
                    ref: `https://www.instagram.com/${STARTUP_NAME.toLowerCase()}/`,
                }
            ]
        },
        {
            title: 'Facebook',
            icon: iconwrapper(FACEBOOK_ICON, iconstyle),
            items: [
                {
                    subtitle: "Weavebliss",
                    explain: "",
                    ref: `https://www.facebook.com/profile.php?id=61554543434987`,
                }
            ]
        },



    ]

    const join = (url, title) => {
        if (title === 'Reddit') {
            window.open(url, '_blank');
        } else if (title === 'Telegram') {
            window.open(url, '_blank');
        } else if (title === 'Instagram') {
            window.open(url, '_blank');
        } else if (title === 'Facebook') {
            window.open(url, '_blank');
        }
    }
    
    return (
        <div className={`Toolscommunityroot`}>
            {socialmediaarr.map((obj, x) => {
                return <div key={obj.title + '_' + x} className='Toolscommunitysocialgrp' style={{ width: { 'WEB': 400, 'TABLET': 350, 'MOBILE': 'calc( 100% - 20px )' }[device] }}>
                    <div className='Toolscommunitysocialgrptitle'>
                        <div>{obj.icon}</div>
                        <div className='Toolscommunitysocialgrpsocialmediatitle'>{obj.title}</div>
                    </div>
                    <div className='Toolscommunitysocialgrpitems'>
                        {obj.items.map((item, y) => {
                            return <div key={obj.title + '_' + x + '_' + y} className='Toolscommunitysocialgrpitem' style={{ borderBottom: y < obj.items.length - 1 ? '1px solid var(--maindark)' : '' }}>
                                <div className='Toolscommunitysocialgrpitemtop'>{item.subtitle}</div>
                                {item.explain.length > 0 ? <div className='Toolscommunitysocialgrpitembtm'>{item.explain}</div> : null}
                                <div className='Toolscommunitysocialjoinroot'>
                                    <button className='planning_contbtn' onClick={() => { join(item.ref, obj.title) }}>Join</button>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            })}


        </div>
    )

    return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', placeContent: 'center' }}>
        <a id='tele1' style={{ width: 0, height: 0, visibility: 'hidden', position: 'absolute' }} href={`tg://resolve?domain=${TELEGRAM_WEDDING}`} />
        <a id='tele2' style={{ width: 0, height: 0, visibility: 'hidden', position: 'absolute' }} href={`tg://resolve?domain=${TELEGRAM_WEDDINGMARKETPLACE}`} />
        <button
            className={'Tools_main'}
            style={{ marginRight: 2.5 }}
            onClick={() => {
                document.getElementById('tele1').click()
            }}
        >
            <div>{TELEGRAM_ICON_LARGE}</div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 20 }}>
                <div>Wedding Chat Group</div>
            </div>
        </button>
        <button
            className={'Tools_main'}
            style={{ marginLeft: 2.5 }}
            onClick={() => {
                document.getElementById('tele2').click()
            }}
        >
            <div>{TELEGRAM_ICON_LARGE}</div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 20 }}>
                <div>Feedback Group</div>
            </div>
        </button>
    </div>

}

export default Main