
import React, { useEffect, useRef, useState } from 'react'
import { iconwrapper } from '../../../../../../config/commonfunctions'
import { EMPTYSPACE, ARROWDOWN_SMALL } from '../../../../../../config/constants'
import { ADD_ICON, CROSS_ICON } from '../../../../../../config/svgicons'
import { describe_dom, partialtitle_dom } from '../../../Invitationmaker/components/Commonelements/Commonelements'

function Addguestcontent(props) {
    const { GUEST_MAX, update } = props
    const [addguestnum, setAddguestnum] = useState(1)
    const [addguestname, setAddguestname] = useState([''])
    const [addguestrela, setAddguestrela] = useState([''])
    const [addguestcat, setAddguestcat] = useState([''])
    const [allcats, setAllcats] = useState([''])
    const [catdd, setCatdd] = useState(-1) // category dropdown

    const ADDICON_CSS = { width: 24, height: 24, display: 'flex', placeContent: 'center' }
    const btmref = useRef(null)

    useEffect(() => {
        const { allcats } = props
        setAllcats(allcats)
    }, [props])

    useEffect(()=>{
        btmref.current.scrollIntoView({
            behavior: "smooth"
          });
    },[addguestnum])

    var inputitems = []
    for (let i = 0; i < addguestnum; i++) {
        const _i = i
        inputitems.push(
            <div key={_i + 'addguests'} className="scedit_troot" style={{ height: '' }}>
                <div style={{ display: 'flex', placeContent: 'space-between' }}>
                    <div style={{ padding: '0px 5px 0px 0px', color: 'var(--mainpink)', display: 'flex', fontSize: 16 }}>{_i + 1}</div>
                    <button
                        className='scedit_ddbtn'
                        onClick={() => {
                            setAddguestnum(addguestnum - 1)
                            setAddguestname((a) => {
                                a.splice(_i, 1)
                                return a
                            })

                            setAddguestrela((a) => {
                                a.splice(_i, 1)
                                return a
                            })

                            setAddguestcat((a) => {
                                a.splice(_i, 1)
                                return a
                            })
                        }}
                    >
                        {iconwrapper(CROSS_ICON, { width: 24, height: 24, color: 'var(--mainpink)' })}
                    </button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', }}>

                    {EMPTYSPACE}
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                        <aside style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', minHeight: 55 }}>
                            {partialtitle_dom('Guest Name', {})}
                            <input
                                style={{ height: 40, padding: 5, borderRadius: 5, width: '100%', whiteSpace: 'nowrap', outline: 'none', fontSize: 14, border: 'thin solid #707070', alignSelf: 'flex-start', }}
                                value={addguestname[_i] || ''}
                                onChange={(e) => {
                                    var local_addguestname = addguestname.slice()
                                    local_addguestname[_i] = e.target.value
                                    setAddguestname(local_addguestname)
                                }} />
                        </aside>

                        <div style={{ height: 15 }} />

                        <aside style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', minHeight: 40 }}>
                            {partialtitle_dom('Relationship', {})}
                            <input
                                style={{ height: 40, padding: 5, borderRadius: 5, width: '100%', whiteSpace: 'nowrap', outline: 'none', fontSize: 14, border: 'thin solid #707070', alignSelf: 'flex-start', }}
                                value={addguestrela[_i] || ''}
                                onChange={(e) => {
                                    var local_addguestrela = addguestrela.slice()
                                    local_addguestrela[_i] = e.target.value
                                    setAddguestrela(local_addguestrela)
                                }} />
                        </aside>

                        <div style={{ height: 15 }} />

                        <aside style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', minHeight: 40 }}>
                            {partialtitle_dom('Category', {})}
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', position: 'relative' }}>
                                <input
                                    style={{ height: 40, padding: 5, borderRadius: 5, width: 'calc( 100% - 30px )', whiteSpace: 'nowrap', outline: 'none', fontSize: 14, border: 'thin solid #707070', alignSelf: 'flex-start', }}
                                    value={addguestcat[_i] || ''}
                                    onChange={(e) => {
                                        var local_addguestcat = addguestcat.slice()
                                        local_addguestcat[_i] = e.target.value.toLowerCase()
                                        setAddguestcat(local_addguestcat)

                                    }} />
                                <button className="scedit_ddbtn" onClick={() => catdd === _i ? setCatdd(-1) : setCatdd(_i)}> {ARROWDOWN_SMALL}</button>
                            </div>
                        </aside>

                        {catdd === _i
                            ? <div id='catdd' style={{ display: 'flex', flexDirection: 'column', width: '100%', border: '1px solid #707070', marginTop: 5, borderRadius: 5 }}>
                                {/* <div style={{ backgroundColor: '#ff4e4e', color: 'white', width: '100%', textTransform: 'uppercase' }} onClick={() => setCatdd(-1)}>close</div> */}
                                <div style={{ display: 'flex', flexDirection: 'row', padding: 5, flexWrap: 'wrap', }}>
                                    {allcats.map((item, y) => {
                                        return <button
                                            key={y + 'item'}
                                            className="Rsvpexplorer_portaltag"
                                            onClick={() => {
                                                var local_addguestcat = addguestcat.slice()
                                                local_addguestcat[_i] = item
                                                setAddguestcat(local_addguestcat)
                                                setCatdd(-1)
                                            }}>
                                            {item}
                                        </button>
                                    })}

                                </div>
                            </div>
                            : null}

                    </div>
                </div>
            </div>
        )
    }

    const add = () => {
        var local_addguestname = addguestname.slice()
        var local_addguestrela = addguestrela.slice()
        var local_addguestcat = addguestcat.slice()
        local_addguestname.push('')
        local_addguestrela.push('')
        local_addguestcat.push('')
        setAddguestname(local_addguestname)
        setAddguestrela(local_addguestrela)
        setAddguestcat(local_addguestcat)
        setAddguestnum(addguestnum + 1)
    }

    return <div className='Rsvpexplorer_portal' >
        <div className='Rsvpexplorer_portaltitle'>Add New Guest</div>
        {describe_dom(`You can add a maximum of ${GUEST_MAX} guests.`, {})}
        <div style={{ display: 'flex', flexDirection: 'column', height: 'calc( 100% - 170px)' }}>
            <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 'auto', height: 'auto', width: '100%', overflowY: 'auto', padding: '0px 10px' }}>
                {inputitems}
                <div ref={btmref}/>
            </div>
            {inputitems.length > 9
                ? null
                : <button className='Rsvpexplorer_portaladdnewguest' style={{ width: '100%', placeContent: 'center', minHeight: 40 }} onClick={add}>
                    {iconwrapper(ADD_ICON, ADDICON_CSS)}
                </button>}
            <button className='Rsvpexplorer_portalsave' onClick={() => update(addguestnum, addguestname, addguestrela, addguestcat)}>Update</button>
        </div>

    </div>

}

export default Addguestcontent