
import React, { useState, useEffect } from 'react'
import { Checkdevice } from '../../../../components'
import { FILTERS_THAT_CHECKED_ONCE } from '../../../../config/constants'
import { SEARCH_FILTER_WEDDING, SEARCH_FILTER_EVENT, DEFAULT_FILTERSUB_BOTH } from '../../../../config/constants'
import { BUTTONSTYLE, CLEARSTYLE } from '../../../../config/cssconstants'
import './FilterModalContent.css'

const USERTYPE_EVENTORWED_FILTER = {
    'Wedding': SEARCH_FILTER_WEDDING,
    'Event': SEARCH_FILTER_EVENT
}

function FilterModalContent(props) {

    const { filtersetting, onApplyFilter, selectedVendorType, usertype } = props
    const [filterobj, setFilterobj] = useState({})
    const [active, setActive] = useState(false)
    const [titles, setTitles] = useState([])
    const [filterarr, setFilterarr] = useState([])
    const [filterarrheader, setFilterarrheader] = useState([])
    const device = Checkdevice()

    useEffect(() => {
        var filterobj = USERTYPE_EVENTORWED_FILTER[usertype]
        const { Keys, active } = filterobj[selectedVendorType]

        // if (!Keys.includes('Others')) {
        //     filterobj[selectedVendorType]['Keys'].push('Others')
        //     filterobj[selectedVendorType]['Others'] = [DEFAULT_FILTERSUB_BOTH]
        // }

        var headers = []
        var key_ut_vt = usertype + selectedVendorType
        if (key_ut_vt in filtersetting) {
            headers = filtersetting[key_ut_vt]
        }

        var filterarr = []
        var filterarrheader = []
        for (let i = 0; i < Keys.length; i++) {
            const key = Keys[i]
            for (let j = 0; j < filterobj[selectedVendorType][key].length; j++) {
                const { header } = filterobj[selectedVendorType][key][j]
                var checked = false
                if (headers) {
                    for (let k = 0; k < headers.length; k++) {
                        if (headers[k] === header) {
                            checked = true
                            break
                        }
                    }
                }
                filterarr.push(checked)
                filterarrheader.push(header)
            }
        }
        setFilterarr(filterarr)
        setFilterarrheader(filterarrheader)
        setTitles(Keys)
        setActive(active)
        setFilterobj(filterobj)

    }, [])

    const applyClick = () => {
        var local_filterarr = []
        for (let i = 0; i < filterarr.length; i++) {
            if (filterarr[i]) {
                local_filterarr.push(filterarrheader[i])
            }
        }
        onApplyFilter(local_filterarr)
    }

    const runType0 = (k) => { // with only one selection
        let local_filterarr = filterarr.slice()
        local_filterarr[k] = !local_filterarr[k]
        setFilterarr(local_filterarr)
    }

    const runType1 = (k) => { // with two selection
        let local_filterarr = filterarr.slice() 
        let local_filterarrheader = filterarrheader.slice()
        for (let i = 0; i < local_filterarr.length; i++) {
            if (FILTERS_THAT_CHECKED_ONCE.includes(local_filterarrheader[i]) && k !== i) {
                local_filterarr[i] = false
            }
        }
        local_filterarr[k] = !local_filterarr[k]
        setFilterarr(local_filterarr)
    }

    const checkboxesContent = () => {
        var root = []

        for (let i = 0, k = 0; i < titles.length; i++) {
            const title = titles[i]

            const catarr = filterobj[selectedVendorType][title]
            var checkboxarr = []
            for (let j = 0; j < catarr.length; j++, k++) {
                const _k = k
                const { type, header } = catarr[j]
                const cbclicked = () => type === 0 ? runType0(_k) : runType1(_k)
                checkboxarr.push(
                    <div key={j + title + k} className="FMC_cbrow" onClick={cbclicked}>
                        <input className="FMC_cb" type="checkbox" checked={filterarr[_k] ? filterarr[_k] : false} onChange={cbclicked} />
                        <label className="FMC_cblbl">{header}</label>
                    </div>
                )
            }

            const reset = () => {
                var local_filterarr = filterarr.slice()
                for (let i = 0; i < catarr.length; i++) {// obtain the indexes to be cleared from (1d array)
                    const { header } = catarr[i]
                    var index = filterarrheader.indexOf(header)
                    if (filterarr[index]) {
                        local_filterarr[index] = false
                    }
                }
                setFilterarr(local_filterarr)
            }

            root.push(
                <div key={title + i} className="FMC_mainbox" >
                    <div className="FMC_TITLE">{title}</div>
                    <div className="FMC_BOX" >
                        <div className="FMC_RESETBTNBOX">
                            <button style={CLEARSTYLE} key={'clear' + i} onClick={reset}>Reset</button>
                        </div>
                        {checkboxarr}
                    </div>
                </div>
            )
        }
        return root
    }

    return active
        ? <div className={"FMC_ROOT" + device} style={{ placeContent: 'space-between' }}>
            <div>
                {checkboxesContent()}
            </div>
            {device === 'MOBILE'
                ? <div className="FMC_APPLYBTNBOX">
                    <button
                        className='Rsvpexplorer_portalsave'
                        style={{
                            position: 'relative',
                            bottom: 0,
                            backgroundColor: '#ffffff',
                            color: 'var(--maindark)',
                            border: '1px solid var(--maindark)',
                            minHeight: 45,
                            width: '100%',
                            margin: 0
                        }}
                        onClick={applyClick}
                    >
                        Apply
                    </button>
                </div>
                : <div className="FMC_APPLYBTNBOX">
                    <button
                        className='Rsvpexplorer_portalsave'
                        style={{
                            position: 'relative',
                            bottom: 0,
                            backgroundColor: '#ffffff',
                            color: 'var(--maindark)',
                            border: '1px solid var(--maindark)',
                            minHeight: 45,
                            width: '100%',
                            margin: 0
                        }}
                        onClick={applyClick}
                    >
                        Apply
                    </button>
                </div>}

        </div>
        : <div className="FMC_EMPTYROOT">
            <div className="FMC_EMPTYDIV" />
            <h4>Empty</h4>
            <div className="FMC_EMPTYDIV" />
        </div>
}
export default FilterModalContent