import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect';
import { iconwrapper } from '../../../../../../config/commonfunctions';
import { CLOSE_ICON, RSVP_DEFAULT_QNS_EVENTLOW, RSVP_DEFAULT_QNS_WEDDINGLOW } from '../../../../../../config/constants';
import { GUESTLIST_ICON } from '../../../../../../config/svgicons';
import './Statistics.css'

const MULTIPLECHOICE = 0
const TXTQN = 1

function Statistics(props) {
    const { close, guestqns, grp_guestids_map, guestids, guestcats, guestid_guestinfo_map, guestqnsmap, allguestqnsuids, itype } = props

    const [totalguests, setTotalguests] = useState(0)
    const [totalgroups, setTotalgroups] = useState(0)
    const [totalquestions, setTotalquestions] = useState(0)
    const [totalcats, setTotalcats] = useState(0)
    const [totalrsvpaccept, setTotalrsvpaccept] = useState(0)
    const [totalrsvpnoreply, setTotalrsvpnoreply] = useState(0)
    const [totalrsvpdecline, setTotalrsvpreject] = useState(0)
    const [totalrsvpreminder, setTotalrsvpreminder] = useState(0)
    const [totalrsvpupdate, setTotalrsvpupdate] = useState(0)
    const [qnid_qnobj_map, setQnid_qnobj_map] = useState({})
    const [firsttime, setFirsttime] = useState(true)

    useEffect(() => {
        if (firsttime) {
            console.log(guestids.length)
            setTotalguests(parseInt(guestids.length))
            setTotalgroups(parseInt(Object.keys(grp_guestids_map).length))
            setTotalquestions(parseInt(guestqns.length))
            setTotalcats(parseInt(guestcats.length))
            const { accepts, noreplies, declines, updates, reminders } = obtainRsvp()
            setTotalrsvpaccept(accepts)
            setTotalrsvpnoreply(noreplies)
            setTotalrsvpreject(declines)
            setTotalrsvpupdate(updates)
            setTotalrsvpreminder(reminders)
            setQnid_qnobj_map(createQnidqntypeobjmap())
            setFirsttime(false)
        }
    }, [props])

    function obtainRsvp() {
        let noreplies = 0
        let accepts = 0
        let declines = 0
        let updates = 0
        let reminders = 0
        for (let i = 0; i < guestids.length; i++) {
            const data = guestid_guestinfo_map[guestids[i]]
            const rsvp = data['8-rsvp']
            if (rsvp === 'accept') {
                accepts += 1
            }
            else if (rsvp === 'decline') {
                declines += 1
            }
            else if (rsvp === 'reminder') {
                reminder += 1
            }
            else if (rsvp === 'update') {
                updates += 1
            }
            else {
                console.log('rsvp', rsvp)
                noreplies += 1
            }
        }
        return { noreplies, accepts, declines, reminders, updates }
    }

    function createQnidqntypeobjmap() {
        let RSVP_DEFAULT_QNS = itype === 'Wedding' ? RSVP_DEFAULT_QNS_WEDDINGLOW : RSVP_DEFAULT_QNS_EVENTLOW
        let new_guestqns = [...RSVP_DEFAULT_QNS, ...guestqns]

        let qnid_qnobj_map = {}
        for (let i = 0; i < new_guestqns.length; i++) {
            let mainqn_id = new_guestqns[i][7]

            if (new_guestqns[i][1] === MULTIPLECHOICE) {
                var alloptions = []
                for (let j = 0; j < new_guestqns[i][8].length; j++) { // loop options
                    const singleoption_arr = new_guestqns[i][8][j]
                    alloptions.push(singleoption_arr[0])
                    if (singleoption_arr[1].length > 0) { // consist of followup qn
                        for (let k = 0; k < singleoption_arr[1].length; k++) {
                            if (singleoption_arr[1][k][1] === MULTIPLECHOICE) {
                                let followup_alloptions = singleoption_arr[1][k].splice(4, singleoption_arr[1][k].length)
                                qnid_qnobj_map[singleoption_arr[1][k][3]] = {
                                    qntype: singleoption_arr[1][k][1],
                                    options: followup_alloptions.map((item) => { return { optionname: item, count: 0 } })
                                }
                            }
                            else {
                                qnid_qnobj_map[singleoption_arr[1][k][3]] = {
                                    qntype: singleoption_arr[1][k][1],
                                    answer_count: 0,
                                    noanswer_count: 0
                                }
                            }

                        }
                    }
                }

                if (mainqn_id === '8-rsvp') { // exception for 8-rsvp, to detect ''
                    alloptions.push('unanswered')
                }
                qnid_qnobj_map[mainqn_id] = {
                    qntype: new_guestqns[i][1],
                    options: alloptions.map((item) => { return { optionname: item, count: 0 } })
                }
            }
            else if (new_guestqns[i][1] === TXTQN) {
                qnid_qnobj_map[mainqn_id] = {
                    qntype: new_guestqns[i][1],
                    answer_count: 0,
                    noanswer_count: 0
                }
            }

        }

        for (let j = 0; j < guestids.length; j++) {
            let guestid = guestids[j]
            let guestinfo = guestid_guestinfo_map[guestid] // obj
            for (let k = 0; k < allguestqnsuids.length; k++) {
                const qnid = allguestqnsuids[k]
                if (qnid !== '0-uid' && qnid !== '5-group' && qnid !== '6-category' && qnid !== '9-updatedon') { // these are qns user cannt ans
                    if (qnid_qnobj_map[qnid]) {
                        const qnans = guestinfo[qnid]
                        const { qntype } = qnid_qnobj_map[qnid]
                        if (qntype === MULTIPLECHOICE) {
                            const { options } = qnid_qnobj_map[qnid]
                            if (qnid === '8-rsvp') {
                                if (qnans === 'accept') {
                                    qnid_qnobj_map[qnid]['options'][0]['count'] += 1 // accept
                                }
                                else if (qnans === 'decline') {
                                    qnid_qnobj_map[qnid]['options'][1]['count'] += 1 // decline
                                }
                                else {
                                    qnid_qnobj_map[qnid]['options'][2]['count'] += 1 // noreply
                                }
                            }
                            else {
                                for (let l = 0; l < options.length; l++) {
                                    if (options[l]['optionname'] === qnans) {
                                        qnid_qnobj_map[qnid]['options'][l]['count'] += 1
                                        break
                                    }

                                }
                            }

                        }
                        else if (qntype === TXTQN) {
                            qnid_qnobj_map[qnid][qnans === '' ? 'noanswer_count' : 'answer_count'] += 1
                        }
                    }

                }
            }
        }


        // shape
        // qnid_qnobj_map = {   
        //     8-rsvp : {
        //         qntype: 'multiplechoice',
        //         options : [
        //             {optionname : 'accept', counter:0},
        //             {optionname : 'decline', counter:0},
        //             {optionname : 'unanswered', counter:0},
        //         ]
        //     }
        //     lk1n23k1n23k12n3kj21n312kj3n12 : {
        //         qntype:'multiplechoice',
        //         options : [
        //             {optionname : 'name1', counter:0},
        //             {optionname : 'name2', counter:0},
        //             {optionname : 'name3', counter:0},
        //         ]
        //     },
        //     12lkmlkml2m3lm23klm2 : {
        //         qntype:'text',
        //         answercount : 10,
        //         noanswercount : 0
        //     },
        // }

        return qnid_qnobj_map
    }

    const qndom = (allguestqnsuids.length > 0 && Object.keys(qnid_qnobj_map).length > 0)
        ? allguestqnsuids.map((qnid, i) => {
            if (qnid !== '0-uid' && qnid !== '5-group' && qnid !== '6-category' && qnid !== '9-updatedon' && qnid !=='10-status') { // these are qns user cannt ans
                console.log('qnid', qnid)
                console.log('qnid_qnobj_map', qnid_qnobj_map)
                const { qntype } = qnid_qnobj_map[qnid]
                const fullqn = guestqnsmap[qnid]
                let maindom
                if (qntype === MULTIPLECHOICE) {
                    const { options } = qnid_qnobj_map[qnid]
                    maindom = <div key={'mainqn' + i} className='stat_qnframe'>
                        <div className='Rsvpexplorer_tag Rsvpexplorer_tag0' style={{ borderRadius: '5px 0px' }}>{'Multiple Choice'}</div>
                        <div className='stat_qntxt'>{fullqn}</div>
                        <div className='stat_optionsroot'>
                            {options.map((option, j) => {
                                const { optionname, count } = option
                                return <div key={'option' + j} className='stat_optionroot'>
                                    <div className='stat_optionname'>{optionname}</div>
                                    <div className='stat_optioncount'>{count}</div>
                                </div>
                            })}
                        </div>
                    </div>
                }
                else if (qntype === TXTQN) {
                    const { answer_count, noanswer_count } = qnid_qnobj_map[qnid]
                    maindom = <div key={'mainqn' + i} className='stat_qnframe'>
                        <div className='Rsvpexplorer_tag Rsvpexplorer_tag1' style={{ borderRadius: '5px 0px' }}>{'Open-ended'}</div>
                        <div className='stat_qntxt'>{fullqn}</div>
                        <div className='stat_optionsroot'>
                            <div key={'openendedanswered'} className='stat_optionroot'>
                                <div className='stat_optionname'>Answered</div>
                                <div className='stat_optioncount'>{answer_count}</div>
                            </div>
                            <div key={'openendedblank'} className='stat_optionroot'>
                                <div className='stat_optionname'>Blank</div>
                                <div className='stat_optioncount'>{noanswer_count}</div>
                            </div>
                        </div>

                    </div>

                }

                return maindom
            }

        })
        : null

    const navbtntxt = (toptxt, btmtxt, txtrootcss) => {
        return <div className="ig_btntxt" style={txtrootcss}>
            <div className='ig_commontxt' style={{ marginTop: 0 }}>{toptxt}</div>
            {btmtxt.length > 0 ? <div className='ig_commontxt'>{btmtxt}</div> : null}
        </div>
    }

    return <div className='igl_root' style={{ backgroundColor: '#efeff0', height: 'auto', minHeight: 'auto' }}>
        <div className='Iedittopbarleftmenu_root' style={{ width: '100%' }}>
            <div className='ig_toprowbtns'>
                <button className="iglbtnfalse iglbtnfirstchild" onClick={close} >
                    {iconwrapper(GUESTLIST_ICON, { width: 26 })}
                    {navbtntxt('Guestlist', ``, {})}
                </button>
            </div>
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', position: 'relative', overflowY: 'auto', height: isMobile ? 'calc( 100vh - 170px )' : '100%', overflowX: 'hidden', alignItems: 'center' }}>
            <div className='ig_publishroot' style={{ padding: '10px 10px 0px 10px', placeContent: 'end', width: '100%' }}>
                <button id="Rsvqnexplorer_backbtn" onClick={close}>{CLOSE_ICON}</button>
            </div>

            <div id='stat_root' >
                <div className='stat_row stat_card'>
                    <div className='stat_col' style={{ width: '50%' }}>
                        <div className='stat_num'>{totalguests}</div>
                        <div >Total Guests</div>
                    </div>
                    <div className='stat_col' style={{ width: '50%' }}>
                        <div className='stat_num'>{totalquestions}</div>
                        <div>Total Questions</div>
                    </div>
                </div>
                <div className='stat_row stat_card'>
                    <div className='stat_col' style={{ width: '50%' }}>
                        <div className='stat_num'>{totalgroups}</div>
                        <div>Total Groups</div>
                    </div>
                    <div className='stat_col' style={{ width: '50%' }}>
                        <div className='stat_num'>{totalcats}</div>
                        <div>Total Categories</div>
                    </div>
                </div>
                <div id='stat_acceptdeclinenoreply' className='stat_card'>
                    <div className='stat_title'>RSVP</div>
                    <div className='stat_row'>
                        <div className='stat_col' style={{ width: '50%' }}>
                            <div className='stat_num'>{totalrsvpaccept}</div>
                            <div>Accept</div>
                        </div>
                        <div className='stat_col' style={{ width: '50%' }}>
                            <div className='stat_num'>{totalrsvpdecline}</div>
                            <div>Decline</div>
                        </div>

                    </div>
                    <div style={{ height: 50 }} />
                    <div className='stat_row'>
                        <div className='stat_col' style={{ width: '33.33%' }}>
                            <div className='stat_num'>{totalrsvpnoreply}</div>
                            <div>No Reply</div>
                        </div>
                        <div className='stat_col' style={{ width: '33.33%' }}>
                            <div className='stat_num'>{totalrsvpreminder}</div>
                            <div>Reminder</div>
                        </div>
                        <div className='stat_col' style={{ width: '33.33%' }}>
                            <div className='stat_num'>{totalrsvpupdate}</div>
                            <div>Update</div>
                        </div>
                    </div>
                </div>

                <div className='stat_response stat_card'>
                    <div className='stat_title'>Response</div>
                    {qndom}
                </div>
            </div>
        </div>

    </div >

}
export default Statistics

