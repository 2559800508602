import React, { useState } from 'react'
import { describe_dom } from "../Commonelements/Commonelements"
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BLOCK_ICON, DRAG_ICON, MORE_ICON } from '../../../../../../config/svgicons';
import { Checkdevice } from '../../../../../../components';
import { useEffect } from 'react';
import { iconwrapper } from '../../../../../../config/commonfunctions';
import Success from './components/Success';
import './Glmodal.css'

const blockicon_css = { width: 20, height: 20, display: 'flex' }
const dragicon_css = { width: 20, height: 20, display: 'flex', placeContent: 'center' }
const moreicon_css = { width: 20, height: 20, display: 'flex' }

function Swapcontent(props) {
    const [guestqns, setGuestqns] = useState([])
    const [swapmsgstate, setSwapmsgstate] = useState('')
    const [firsttime, setFirstime] = useState(true)
    const [hidetop, setHidetop] = useState(true)
    const [hidebtm, setHidebtm] = useState(true)

    const DEVICE = Checkdevice()

    useEffect(() => {
        if (firsttime) {
            setGuestqns(props.guestqns)
            setFirstime(false)
        }
    }, [props])

    useEffect(() => {
        setSwapmsgstate(props.swapmsgstate)
    }, [props.swapmsgstate])

    const onDragend = (result) => {
        const { source, destination, type } = result
        if (!destination) { return; }

        let new_guestqns = JSON.parse(JSON.stringify(guestqns))
        const [removed_guestqns] = new_guestqns.splice(source.index, 1);
        new_guestqns.splice(destination.index, 0, removed_guestqns);

        setGuestqns(new_guestqns)
    }

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? '#707070' : 'lightgrey',
        height: 'max-content',
        background: 'transparent',
    });

    const general_dom = <div className='Rsvpexplorer_portalcontent'>
        {describe_dom('Swap your rsvp questions sequence, this will affect the appearance sequence of your rsvp questions that is shown to your guests and guestlist your are viewing.')}

        {hidetop
            ? <button style={{ margin: 0, padding: '20px 0px', backgroundColor: 'white', border: '1px solid transparent', borderRadius: 5, fontSize: 16, width: '100%', display: 'flex', placeContent: 'center' }} onClick={() => { setHidetop(false) }}>
                {iconwrapper(MORE_ICON, moreicon_css)}
            </button>
            : <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15, width: '100%', placeContent: 'space-between', padding: '0px 10px', alignItems: 'center', cursor: 'pointer' }} onClick={() => { setHidetop(true) }}>
                <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #333333', borderRadius: 5, width: '100%', overflow: 'hidden' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #333333', backgroundColor: '#eeeeee' }}>
                        <div style={{ padding: 10, backgroundColor: '#707070', color: '#ffffff', borderBottom: '1px solid #ffffff', width: 36, textAlign: 'center' }}>1</div>
                        <div style={{ padding: 10 }}># Number</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #333333', backgroundColor: '#eeeeee' }}>
                        <div style={{ padding: 10, backgroundColor: '#707070', color: '#ffffff', borderBottom: '1px solid #ffffff', width: 36, textAlign: 'center' }}>2</div>
                        <div style={{ padding: 10 }}>Name</div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #333333', backgroundColor: '#eeeeee' }}>
                        <div style={{ padding: 10, backgroundColor: '#707070', color: '#ffffff', borderBottom: '1px solid #ffffff', borderBottom: '1px solid #ffffff', width: 36, textAlign: 'center' }}>3</div>
                        <div style={{ padding: 10 }}>Email</div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #333333', backgroundColor: '#eeeeee' }}>
                        <div style={{ padding: 10, backgroundColor: '#707070', color: '#ffffff', borderBottom: '1px solid #ffffff', width: 36, textAlign: 'center' }}>4</div>
                        <div style={{ padding: 10 }}>Contact</div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #333333', backgroundColor: '#eeeeee' }}>
                        <div style={{ padding: 10, backgroundColor: '#707070', color: '#ffffff', borderBottom: '1px solid #ffffff', width: 36, textAlign: 'center' }}>5</div>
                        <div style={{ padding: 10 }}>Address</div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #333333', backgroundColor: '#eeeeee' }}>
                        <div style={{ padding: 10, backgroundColor: '#707070', color: '#ffffff', borderBottom: '1px solid #ffffff', width: 36, textAlign: 'center' }}>6</div>
                        <div style={{ padding: 10 }}>Group</div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #333333', backgroundColor: '#eeeeee' }}>
                        <div style={{ padding: 10, backgroundColor: '#707070', color: '#ffffff', borderBottom: '1px solid #ffffff', width: 36, textAlign: 'center' }}>7</div>
                        <div style={{ padding: 10 }}>Category</div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #333333', backgroundColor: '#eeeeee' }}>
                        <div style={{ padding: 10, backgroundColor: '#707070', color: '#ffffff', borderBottom: '1px solid #ffffff', width: 36, textAlign: 'center' }}>8</div>
                        <div style={{ padding: 10 }}>Crypto Wallet Address</div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#eeeeee' }}>
                        <div style={{ padding: 10, backgroundColor: '#707070', color: '#ffffff', width: 36, textAlign: 'center' }}>10</div>
                        <div style={{ padding: 10 }}>Updated On</div>
                    </div>

                </div>
                <span id='Rsvpexplorer_dragicon' style={{ height: '100%' }}>
                    {iconwrapper(BLOCK_ICON, blockicon_css)}
                </span>
            </div>}

        <DragDropContext onDragEnd={onDragend} >
            <Droppable droppableId={'droppable'} style={{ padding: '0px 10px !important', width: '100%' }}>
                {(provided, snapshot) => (
                    <div  {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} className='swapcontent_draggableparent'>
                        {guestqns.map((qn, i) => {
                            const colname = qn[2]
                            const qntype = qn[1]
                            const qnid = qn[7]

                            var mainqncss = {}
                            if (qntype === 0) { // multiple choice
                                const optionobjs = qn[8]
                                let num_of_followupqns = 0
                                for (let a = 0; a < optionobjs.length; a++) {
                                    const optionobj = optionobjs[a]
                                    const followupqns = optionobj[1]
                                    num_of_followupqns += followupqns.length
                                }
                                if (num_of_followupqns > 0) {
                                    mainqncss = { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }
                                }
                            }

                            const qntypetxt = DEVICE === 'MOBILE' ? ['MC', 'TXT'][qntype] : ['Multiple Choice', 'Text'][qntype]
                            const followuptxt = DEVICE === 'MOBILE' ? 'FOL-UP' : 'Follow-Up Question'

                            // brave
                            return <Draggable className='fuckl' draggableId={qnid} index={i} key={qnid + '_draggable'}  style={{ top: 'auto !important', left: 'auto !important' }} >
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps}>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: i === guestqns.length - 1 ? 0 : 15, width: '100%', placeContent: 'space-between', padding: '0px 10px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', placeContent: 'center', padding: 10, backgroundColor: '#333333', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, color: '#ffffff', width: 36, textAlign: 'center' }}>{i + 11}</div>
                                            <div style={{ display: 'flex', backgroundColor: 'white', flexDirection: 'column', borderRadius: 5, minHeight: 40, height: 'max-content', width: 'calc( 100% - 50px )', alignItems: 'flex-start', placeContent: 'center', pointerEvents: 'none' }}>
                                                <div style={{ border: '1px solid #33333380', borderLeft: 'none', overflow: 'hidden', width: '100%', padding: 5, borderRadius: 5, borderTopLeftRadius: 0, userSelect: 'none', ...mainqncss }}>
                                                    <div>{colname}</div>
                                                    <div style={{ width: '100%', placeContent: 'end', display: 'flex' }}>
                                                        <div className={`Rsvpexplorer_tag Rsvpexplorer_tag${qntype}`}>{qntypetxt}</div>
                                                    </div>
                                                </div>
                                                {qntype === 0
                                                    ? qn[8].map((optionobj, j) => {
                                                        const followupqns = optionobj[1]
                                                        return followupqns.length > 0
                                                            ? <div key={"followupqn" + j} style={{ width: '100%' }}>
                                                                {followupqns.map((followupqn, k) => {
                                                                    return <div key={'followupcol' + k} style={{ border: '1px dashed #33333380', borderLeft: 'none', overflow: 'hidden', width: '100%', padding: 5, borderTop: 'none', userSelect: 'none', flexDirection: 'column', opacity: 0.5, borderBottomLeftRadius: 0, borderBottomRightRadius: (followupqns.length - 1 === k) ? 5 : 0 }}>
                                                                        <div>{followupqn[2]}</div>
                                                                        <div style={{ width: '100%', placeContent: 'end', display: 'flex' }}>
                                                                            <div className={`Rsvpexplorer_tag Rsvpexplorer_tag${qntype}`}>{qntypetxt}</div>
                                                                            <div className='Rsvpexplorer_tag Rsvpexplorer_tagfollowup'>{followuptxt}</div>
                                                                        </div>

                                                                    </div>
                                                                })}
                                                            </div>
                                                            : null
                                                    })
                                                    : null}
                                            </div>
                                            <div style={{ width: 5 }} />
                                            <span id='Rsvpexplorer_dragicon' {...provided.dragHandleProps} >{iconwrapper(DRAG_ICON, dragicon_css)}</span>
                                        </div>
                                    </div>
                                )}
                            </Draggable>

                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>


        {hidebtm
            ? <button style={{ margin: 0, padding: '20px 0px', backgroundColor: 'white', border: '1px solid transparent', borderRadius: 5, fontSize: 16, width: '100%', display: 'flex', placeContent: 'center' }} onClick={() => { setHidebtm(false) }}>
                {iconwrapper(MORE_ICON, moreicon_css)}
            </button>
            : <div style={{ display: 'flex', flexDirection: 'row', margin: '15px 0px', width: '100%', placeContent: 'space-between', padding: '0px 10px', alignItems: 'center', cursor: 'pointer' }} onClick={() => { setHidebtm(true) }}>
                <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #333333', borderRadius: 5, width: '100%', overflow: 'hidden' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #333333', backgroundColor: '#eeeeee' }}>
                        <div style={{ padding: 10, backgroundColor: '#707070', color: '#ffffff', borderBottom: '1px solid #ffffff', width: 36, textAlign: 'center' }}>{10 + guestqns.length + 1}</div>
                        <div style={{ padding: 10 }}>RSVP</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #333333', backgroundColor: '#eeeeee' }}>
                        <div style={{ padding: 10, backgroundColor: '#707070', color: '#ffffff', borderBottom: '1px solid #ffffff', width: 36, textAlign: 'center' }}>{10 + guestqns.length + 2}</div>
                        <div style={{ padding: 10 }}>Status</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #333333', backgroundColor: '#eeeeee' }}>
                        <div style={{ padding: 10, backgroundColor: '#707070', color: '#ffffff', borderBottom: '1px solid #ffffff', width: 36, textAlign: 'center' }}>{10 + guestqns.length + 2}</div>
                        <div style={{ padding: 10 }}>Contact</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#eeeeee' }}>
                        <div style={{ padding: 10, backgroundColor: '#707070', color: '#ffffff', width: 36, textAlign: 'center' }}>{10 + guestqns.length + 3}</div>
                        <div style={{ padding: 10 }}>Action</div>
                    </div>
                </div>
                <span id='Rsvpexplorer_dragicon' style={{ height: '100%' }}>{BLOCK_ICON}</span>
            </div>
        }
        <button key={swapmsgstate} className='Rsvpexplorer_portalsave' onClick={() => { props.save(guestqns) }} disabled={swapmsgstate === 'SAVING'}>
            {{ // IDLE, SAVING, SUCCESS, FAILURE
                'IDLE': 'Update',
                'SAVING': 'Updating...',
                'SUCCESS': 'Successfully Swapped',
                'FAILURE': 'Error Occurred, please try again later.'
            }[swapmsgstate]}
        </button>
    </div>

    const success_dom = <Success txt={'New question sequence updated. Click to return.'} onClick={props.close} />


    return <div className='Rsvpexplorer_portal'>
        <div className='Rsvpexplorer_portaltitle'>Swap</div>
        {swapmsgstate !== 'SUCCESS' ? general_dom : success_dom}
    </div>
}

export default Swapcontent