import React from 'react'
import { Checkdevice } from '../../../../../../components'
import { d_fs, c_fs } from '../../../../../../config/constants'
import './Imagepage.css'

const selected_border = 'thick solid var(--maindark)'
const unselected_border = 'thick solid white'

const DEVICE_QUIZIMGDIM_MAP = { // WIDTH HT
    'WEB': { num_row: 5, num_col: 3, sq_dim: 200 },
    'TABLET': { num_row: 5, num_col: 3, sq_dim: 200 },
    'MOBILE': { num_row: 5, num_col: 2, sq_dim: 150 },
}

const DEVICE_FS_MAP = {
    'WEB': { fs: c_fs },
    'TABLET': { fs: d_fs },
    'MOBILE': { fs: d_fs },
}

function Imagepage(props) {

    const device = Checkdevice()
    const { num_row, num_col, sq_dim } = DEVICE_QUIZIMGDIM_MAP[device]
    const { imgs, pageindex, title, selected, updateSelected } = props
    const { fs } = DEVICE_FS_MAP[device]
    const DEVICE_WIDTH = {
        'WEB': 660,
        'TABLET':  660,
        'MOBILE': '100%'
    }

    const Imagequiz = () => {
        const img_css = { display: 'flex', minWidth: sq_dim, minHeight: sq_dim, border: '1px solid var(--maindark)', borderRadius: 10, margin: 5, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }
        const row_css = { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }
        var root_dom = []
        for (let i = 0, imgindex = 0; i < num_row; i++) {
            root_dom.push([])
            var temp = []
            for (let j = 0; j < num_col; j++, imgindex++) {
                const key = pageindex + '_' + imgindex + '_' + i + '_' + j
                if (imgs[imgindex] && imgs[imgindex].src) {
                    const curr_imgindex = imgindex
                    const { src, name } = imgs[imgindex]
                    const bg_css = { ...img_css, border: selected[pageindex].includes(curr_imgindex) ? selected_border : unselected_border, backgroundImage: 'url(' + src + ')', backgroundPosition: 'center center', cursor: 'pointer', position: 'relative', }
                    const name_css = { display: 'flex', position: 'absolute', backgroundColor: 'rgb(0 0 0 / 0.6)', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, fontWeight: 500, letterSpacing: 1, color: 'white', bottom: 0, width: '100%', padding: 5, justifyContent: 'center' }
                    temp.push(
                        <div
                            key={key}
                            style={bg_css}
                            onClick={() => {
                                var local_selected = selected.slice()
                                if (!selected[pageindex].includes(curr_imgindex)) { // img not selected
                                    var replaced = false
                                    for (let k = 0; k < selected[pageindex].length; k++) {
                                        if (selected[pageindex][k] > -1) {
                                            continue
                                        }
                                        else if (selected[pageindex][k] === -1) {
                                            local_selected[pageindex][k] = curr_imgindex
                                            replaced = true
                                            break
                                        }
                                    }
                                    if (!replaced) local_selected[pageindex][local_selected[pageindex].length - 1] = curr_imgindex

                                }
                                else { // img selected
                                    const replace_index = selected[pageindex].indexOf(curr_imgindex)
                                    local_selected[pageindex][replace_index] = -1
                                }
                                updateSelected(local_selected)
                            }}>
                            {name.length > 0 ? <div style={name_css}>{name}</div> : null}
                        </div>
                    )
                }
                else {
                    temp.push(<div key={key} />)
                }

            }
            root_dom[i].push(<div key={i + '_row'} style={row_css}>{temp}</div>)
        }

        return root_dom

    }

    return <div style={{ width: DEVICE_WIDTH[device], padding:10 }}>
        {/* <div className='Imagepage_title'>{title}</div> */}
        <div className='questionpage_qns' style={{ fontSize: fs }}>{title}</div>
        {Imagequiz()}
    </div>
}

export default Imagepage