import React, { useState, useEffect, useRef } from 'react'
import { Checkdevice, Jfmodal, Modcfm, Noti, Progressbar, refresh } from '../../../../components';
import { b_fs, d_fs, c_fs, DOWNLOAD_SMALL, EXPAND_RIGHT_ICON, EXPAND_ICON, EMPTYSPACE, HIDE_SMALL, SHOW_SMALL, PENCIL_ICON_SMALLL, TRASH_ICON, MAIN_PINK_COLOR } from '../../../../config/constants'
import { BUTTONSTYLE } from '../../../../config/cssconstants';
import { READ_CHECKLIST, CREATE_CHECKLIST, REMOVE_SINGLECHECKLIST, UPDATE_CHECKLIST } from '../../../../config/api';
import { Retrieve_personal_info } from '../../../../config/commonfunctions';
import { Prompt } from 'react-router'
import { CSVLink } from 'react-csv';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Selectionbox } from '../Components';
import { check } from 'react-icons-kit/metrize/check'
import { blank } from 'react-icons-kit/metrize/blank'
import { Icon } from 'react-icons-kit/Icon'
import axios from "axios";
import '../Planning.css'
import './Checklistmaker.css'
import Continuepage from '../Components/Continuepage/Continuepage';
import Createnew from '../Components/Createnew/Createnew';

const VT_DEVICE_FS_MAP = { 'WEB': b_fs, 'TABLET': c_fs, 'MOBILE': d_fs }
const CELL_DEVICE_FS_MAP = { 'WEB': 17, 'TABLET': d_fs, 'MOBILE': 15 }

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#707070' : 'lightgrey',
});

const getItemStyle = (isDragging, draggableStyle) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    outline: 'none',
    userSelect: 'none', // some basic styles to make the items look a bit nicer
    backgroundColor: 'white',    // change background colour if dragging
    border: isDragging ? 'thin solid #707070' : 'thin solid transparent',
    minHeight: 60,
    ...draggableStyle,    // styles we need to apply on draggables
});


function Checklistmaker(props) {
    window.screen.lockOrientationUniversal = window.screen.lockOrientation || window.screen.mozLockOrientation || window.screen.msLockOrientation;

    const { token } = Retrieve_personal_info() // for axios call
    const [firsttime, setFirsttime] = useState(true)
    const [loaded, setLoaded] = useState(false)

    const [quota, setQuota] = useState(null)// max 2
    const [page, setPage] = useState('NONE') // NONE, CREATE, OPEN (OPEN EXISTING)

    const [savegate, setSavegate] = useState(false)
    const [checklist_ids, setChecklist_ids] = useState([]) // contain of all checklist_ids (timeline obj) from a user

    const [currchecklist_id, setCurrchecklist_id] = useState('') // user clicked and selected a particular timeline obj
    const [currchecklistobjs, setCurrchecklistobjs] = useState(null) // curr selected obj
    const [currchecklistobjs_name, setCurrchecklistobjs_name] = useState('')// curr selected obj's name
    const [currchecklistobjs_index, setCurrchecklistobjs_index] = useState(null)// curr selected obj's index
    const [currchecklistobjs_innerindex, setCurrchecklistobjs_innerindex] = useState(null)// curr selected obj's index

    const [checked, setChecked] = useState(0)

    const [addclicked, setAddclicked] = useState(false)
    const [msg, setMsg] = useState('')
    const [savemsg, setSavemsg] = useState('')

    const [csvdata, setCsvdata] = useState([]) // for csv

    const [edit, setEdit] = useState(false)
    const [poptemplate, setPoptemplate] = useState(false) // template modal

    const [rmvmod, setRmvmod] = useState(false) // for removal of selectionbox
    const [rmvindex, setRmvindex] = useState(null)
    const [rmvid, setRmvid] = useState(null)

    const btmref = useRef(null) // click add and scroll to btm
    const device = Checkdevice()


    useEffect(() => {
        if (firsttime) {
            setFirsttime(false)
            try {
                let options = {
                    method: 'POST',
                    url: READ_CHECKLIST,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                }
                axios(options).then((result) => {
                    const { data } = result
                    const { gtchecklist, quota, checklist_objs, checklist_ids } = data
                    reorder(gtchecklist, checklist_objs, checklist_ids);
                    setQuota(quota)
                    setLoaded(true)
                    setPage('NONE')
                }).catch((e) => { })
            }
            catch (e) { }
        }
    }, [firsttime])

    useEffect(() => {
        if (savemsg.length > 0) {
            setTimeout(function () {
                setSavemsg('')
            }, 2000);
        }
    }, [savemsg])

    useEffect(() => {
        // restructure the curr_vtobj for CSVLink to export
        if (currchecklistobjs && currchecklistobjs.length > 0 && !edit) {

            var new_csvData = []

            var total = 0
            var checked = 0

            for (let i = 0; i < currchecklistobjs.length; i++) {

                const { maincat, links, strikeoff, subcats } = currchecklistobjs[i]

                new_csvData.push([])
                new_csvData.push([maincat])

                for (let j = 0; j < subcats.length; j++) {

                    if (strikeoff[j]) { checked = checked + 1 }
                    total = total + 1

                    new_csvData.push([
                        subcats[j],
                        links[j].length > 0 ? links[j] : '-',
                        strikeoff[j] ? 'YES' : 'NO'
                    ])
                }
            }

            checked = Math.floor((checked / total) * 100)

            setChecked(checked)
            setCsvdata(new_csvData)

        }

    }, [currchecklistobjs])

    function reorder(gtchecklist, checklist_objs, checklist_ids) {
        if (gtchecklist) {
            // reorder them based on checklist_ids, as db return messy indexed arr
            var new_objs = checklist_objs.map(() => []);
            for (let i = 0; i < checklist_objs.length; i++) {
                const { _id } = checklist_objs[i];
                var index = checklist_ids.indexOf(_id);
                new_objs[index] = checklist_objs[i];
            }
            setChecklist_ids(new_objs);
        }
        else
            setChecklist_ids([]);
    }

    const save = () => {
        setSavemsg('Processing...')
        if (currchecklistobjs.length > 50) {
            localStorage.clear()
            refresh()
        }
        else {
            setTimeout(() => {
                try {
                    axios({
                        method: 'POST',
                        url: UPDATE_CHECKLIST,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                        data: {
                            checklist_id: currchecklist_id,
                            name: currchecklistobjs_name,
                            checklist_obj: currchecklistobjs && currchecklistobjs.length > 0 ? currchecklistobjs : [],
                        }
                    }).then((result) => {
                        const { message } = result.data
                        if (message === 'SUCCESS') {
                            setSavemsg('Successfully saved!')
                            setSavegate(false)
                            setTimeout(() => setSavemsg(''), 1500)
                        }
                    }).catch((e) => { })
                }
                catch (e) {
                }
            }, 1000)
        }
    }

    const onDragend = (result) => {

        if (!result.destination) return;
        const { source, destination, draggableId } = result
        const mainorsub = draggableId.split(' ')[1]
        var new_checklistobjs = currchecklistobjs.slice()
        if (mainorsub === 'primary') {
            var pri_source_index = source.droppableId.split(' ')[1] // the cat index
            var pri_dest_index = destination.droppableId.split(' ')[1] // the cat index
            var source_index = source.index // the inner index
            var dest_index = destination.index // the inner index

            const temp_subcats = new_checklistobjs[pri_source_index]['subcats'][source_index]
            const temp_strikeoff = new_checklistobjs[pri_source_index]['strikeoff'][source_index]
            const temp_links = new_checklistobjs[pri_source_index]['links'][source_index]

            if (pri_source_index === pri_dest_index) { //  a switch in a same cat, conduct a swap
                new_checklistobjs[pri_source_index]['subcats'].splice(source_index, 1)// remove from source
                new_checklistobjs[pri_source_index]['subcats'].splice(dest_index, 0, temp_subcats) // insert to dest

                new_checklistobjs[pri_source_index]['strikeoff'].splice(source_index, 1)// remove from source
                new_checklistobjs[pri_source_index]['strikeoff'].splice(dest_index, 0, temp_strikeoff) // insert to dest

                new_checklistobjs[pri_source_index]['links'].splice(source_index, 1)// remove from source
                new_checklistobjs[pri_source_index]['links'].splice(dest_index, 0, temp_links) // insert to dest
            }
            else { // a switch in diff cat, cross pri swap
                new_checklistobjs[pri_dest_index]['subcats'].splice(dest_index, 0, temp_subcats) // insert to dest
                new_checklistobjs[pri_source_index]['subcats'].splice(source_index, 1)// remove from source

                new_checklistobjs[pri_dest_index]['strikeoff'].splice(dest_index, 0, temp_strikeoff) // insert to dest
                new_checklistobjs[pri_source_index]['strikeoff'].splice(source_index, 1)// remove from source

                new_checklistobjs[pri_dest_index]['links'].splice(dest_index, 0, temp_links) // insert to dest
                new_checklistobjs[pri_source_index]['links'].splice(source_index, 1)// remove from source
            }

            setCurrchecklistobjs(new_checklistobjs)
        }
        else if (mainorsub === 'main') {
            const newest = new_checklistobjs[source.index]
            new_checklistobjs.splice(source.index, 1)
            new_checklistobjs.splice(destination.index, 0, newest)
            setCurrchecklistobjs(new_checklistobjs)
        }
    }

    // const weddingsample = () => {
    //     // this will override current obj
    //     var confirmation = window.confirm("This will override current data, this action is irreversible.");
    //     if (!confirmation) return
    //     setSavegate(false)

    //     const sample_data = [
    //         {
    //             maincat: 'Pre-wedding Preparation',
    //             subcats: ['Parents Meeting Session', 'Finalised Wedding Budget', 'Premarital Screening', 'Select Wedding Date', 'ROM', 'BTO'],
    //             strikeoff: [false, false, false, false, false, false],
    //             links: ['', '', '', '', '', ''],
    //             collapse: false
    //         },
    //         {
    //             maincat: 'Venue Booking',
    //             subcats: ['Select wedding venue', 'Drawing up your wedding guest list', 'Determine number of tables and menu', 'Seating of guests'],
    //             strikeoff: [false, false, false, false],
    //             links: ['', '', '', ''],
    //             collapse: false
    //         },
    //         {
    //             maincat: 'Wedding Photoshoot',
    //             subcats: ['Select wedding photographer', 'Haircare and skincare', 'Finalised photography and videography packages', 'Pre-wedding photoshoot', 'Collect your pre-wedding photoshoot'],
    //             strikeoff: [false, false, false, false, false],
    //             links: ['', '', `https://${WEBSITE_URL}/wedding/Photographers`, '', ''],
    //             collapse: false
    //         },
    //         {
    //             maincat: 'Wedding Preparation',
    //             subcats: ['Finalise wedding day venue decoration', 'Finalise wedding day car', 'Finalise of wedding vows', 'Finalise of wedding bridesmaid & groomsman'],
    //             strikeoff: [false, false, false, false],
    //             links: ['', '', '', ''],
    //             collapse: false
    //         },
    //         {
    //             maincat: 'Wedding Dress',
    //             subcats: ['Select Wedding dress', 'Select wedding dress for bridemaids & groommen', 'Purchase Wedding outfits for parents', 'Purchase wedding outfits for flower girl and page boy'],
    //             strikeoff: [false, false, false, false],
    //             links: ['', '', '', ''],
    //             collapse: false
    //         },
    //         {
    //             maincat: 'Wedding Purchase',
    //             subcats: ['Craft buying list', 'Wedding room decoration', 'Dowry and gifts', 'Purchase of Wedding Bedding Set', 'Prepare gate crash props', 'Select wedding cake & desserts', 'Select Wedding ring and Si Dian Jin', 'Select wedding invitations and wedding favors', 'Wedding shoes'],
    //             strikeoff: [false, false, false, false, false, false, false, false, false],
    //             links: ['', '', '', '', '', '', '', '', ''],
    //             collapse: false
    //         },
    //         {
    //             maincat: 'Final Sprint',
    //             subcats: ['Beauty skin care', 'Lose weight slim down', 'Seats confirmation', 'Final confirm of wedding venue details', 'Planning wedding car route', 'Wedding MV', 'Prepare wedding gate crash games', 'Confirm wedding day itinerary'],
    //             strikeoff: [false, false, false, false, false, false, false, false],
    //             links: ['', '', '', '', '', '', '', ''],
    //             collapse: false
    //         }
    //     ]

    //     // const sample_data1 = [
    //     //     {
    //     //         maincat: '9-13 months in advance',
    //     //         subcats: ['Decide a wedding date', 'Create guest lists', 'Decide wedding theme', 'Book wedding venue', 'Book wedding videographer', 'Book wedding photographer'],
    //     //         strikeoff: [false, false, false, false, false, false],
    //     //         links: ['', '', '', '', '', ''],
    //     //         collapse: false
    //     //     },
    //     //     {
    //     //         maincat: '6-8 months in advance',
    //     //         subcats: ['Buy or rent wedding outfits', 'Buy wedding bands', 'Book vendors ( Emcees, Photobooth, Florists )', 'Create wedding invitations and send to your guests','Choose bridemaids & groommen outfits'],
    //     //         strikeoff: [false, false, false, false, false],
    //     //         links: ['', '', '', '', ''],
    //     //         collapse: false
    //     //     },
    //     //     {
    //     //         maincat: '4-5 months in advance',
    //     //         subcats: ['Food tasting', 'Send reminders to guest', 'Create seating arrangement'],
    //     //         strikeoff: [false, false, false],
    //     //         links: ['', '', ''],
    //     //         collapse: false
    //     //     },
    //     //     {
    //     //         maincat: '2-3 months in advance',
    //     //         subcats: ['Plan local or overseas honeymoon', 'Plan and send wedding day itinerary'],
    //     //         strikeoff: [false, false],
    //     //         links: ['', ''],
    //     //         collapse: false
    //     //     },
    //     //     {
    //     //         maincat: '1 month in advance',
    //     //         subcats: ['Write vows', 'Finalise guest list and seatings'],
    //     //         strikeoff: [false, false],
    //     //         links: ['', ''],
    //     //         collapse: false
    //     //     },
    //     //     {
    //     //         maincat: '1-2 weeks in advance',
    //     //         subcats: ['Get nails done', 'Pack for honeymoon'],
    //     //         strikeoff: [false, false],
    //     //         links: ['', ''],
    //     //         collapse: false
    //     //     },
    //     // ]

    //     // set curr
    //     setCurrchecklistobjs(sample_data)

    //     // set main source
    //     var temp = checklist_ids.slice()
    //     temp[currchecklistobjs_index].checklistobjs = sample_data
    //     setChecklist_ids(temp)
    // }

    const onMaincatchange = (index, e) => {
        setSavegate(true)
        var new_checklistobjs = currchecklistobjs.slice()
        new_checklistobjs[index]['maincat'] = e.target.value
        setCurrchecklistobjs(new_checklistobjs)
    }

    const onMaincatclicked = (i) => {
        var local_currchecklistobjs = currchecklistobjs.slice()
        local_currchecklistobjs[i]['collapse'] = !local_currchecklistobjs[i]['collapse']
        setCurrchecklistobjs(local_currchecklistobjs)
    }

    const onChangename = (e) => {
        setSavegate(true)
        setCurrchecklistobjs_name(e.target.value)
        var curr_checklist_ids = checklist_ids.slice()
        curr_checklist_ids[currchecklistobjs_index]['name'] = e.target.value
        setChecklist_ids(curr_checklist_ids)
    }

    const onAddmain = () => {
        // loop all currvtobjs to ensure no repeat of 
        setSavegate(false)
        var new_checklistobjs = currchecklistobjs.slice()
        new_checklistobjs.push({
            maincat: 'New Category',
            subcats: [''],
            strikeoff: [false],
            links: [''],
            collapse: false
        })
        setCurrchecklistobjs(new_checklistobjs)
        btmref.current.scrollIntoView({ behavior: 'smooth' });
    }

    const onAdditem = (i) => {
        setSavegate(true)
        var new_currchecklistobjs = currchecklistobjs.slice()
        console.log(new_currchecklistobjs[i])
        new_currchecklistobjs[i]['subcats'].push('new item')
        new_currchecklistobjs[i]['strikeoff'].push(false)
        new_currchecklistobjs[i]['links'].push('')
        console.log(new_currchecklistobjs[i])
        setCurrchecklistobjs(new_currchecklistobjs)
    }

    const onRemove = (i) => {
        var new_currchecklistobjs = currchecklistobjs.slice()
        new_currchecklistobjs.splice(i, 1)
        if (new_currchecklistobjs.length > 0) {
            setSavegate(true)
            setCurrchecklistobjs(new_currchecklistobjs)
        }
    }

    const hideall = () => { // hide all vts
        var temp = currchecklistobjs.slice()
        for (let i = 0; i < temp.length; i++) {
            temp[i].collapse = false
        }
        setCurrchecklistobjs(temp)
    }

    const showall = () => { // show all vts
        var temp = currchecklistobjs.slice()
        for (let i = 0; i < temp.length; i++) {
            temp[i].collapse = true
        }
        setCurrchecklistobjs(temp)
    }

    const Collapsible = (index, subcats, links) => {
        const addbtn = <div className="Checklist_additem" onClick={() => onAdditem(index)}>New Item</div>
        const delcatbtn = <div className="planning_delbtn" onClick={() => onRemove(index)}>x</div>

        return <div style={{ padding: 5 }}>
            <Droppable droppableId={'primary ' + index} type="primary">
                {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} >
                        {subcats && subcats.map((item, inner_index) => {

                            const checkthis = () => {
                                setSavegate(true)
                                var local_currchecklistobjs = currchecklistobjs.slice()
                                local_currchecklistobjs[index]['strikeoff'][inner_index] = !local_currchecklistobjs[index]['strikeoff'][inner_index]
                                setCurrchecklistobjs(local_currchecklistobjs)
                            }

                            const checkstate = currchecklistobjs[index]['strikeoff'][inner_index]

                            const checkbtn = <div className="Checklist_chkbtnroot" onClick={checkthis} >
                                {checkstate ? <div className="Checklist_chkbtncheck" ><Icon size={20} icon={check} /> </div> : <div className="Checklist_chkbtnblank" ><Icon size={20} icon={blank} /> </div>}
                            </div>

                            const contentdiv = <div className="Checklist_content" onClick={checkthis} style={{ fontSize: CELL_DEVICE_FS_MAP[device], textDecoration: !checkstate ? '' : 'line-through' }} >
                                {currchecklistobjs[index]['subcats'][inner_index]}
                            </div>

                            const linkdiv = currchecklistobjs[index]['links'][inner_index].length > 0
                                ? <a className="Checklist_link" target="blank" href={currchecklistobjs[index]['links'][inner_index]} >Link</a>
                                : null

                            const editbtn = <div className="Checklist_deledititem" onClick={() => {
                                setEdit(true);
                                setCurrchecklistobjs_innerindex(inner_index)
                                setCurrchecklistobjs_index(index)
                            }}>{PENCIL_ICON_SMALLL}</div>

                            const delbtn = <button className="Checklist_deledititem" onClick={() => {
                                if (window.confirm("Are you sure you want to remove this task from your checklist? This action cannot be undone.")) {
                                    setSavegate(true)
                                    var local_currchecklistobjs = currchecklistobjs.slice()
                                    local_currchecklistobjs[index]['subcats'].splice(inner_index, 1)
                                    local_currchecklistobjs[index]['strikeoff'].splice(inner_index, 1)
                                    local_currchecklistobjs[index]['links'].splice(inner_index, 1)
                                    setCurrchecklistobjs(local_currchecklistobjs)
                                }
                            }}>{TRASH_ICON}</button>

                            return <Draggable key={index + ' primary ' + inner_index} draggableId={index + ' primary ' + inner_index} index={inner_index} type="primary" style={{ border: 'thin solid #707070', display: 'flex', flexDirection: 'column', backgroundColor: 'white' }} >
                                {(provided, snapshot) => (
                                    <div
                                        key={inner_index + 'checklist'}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{ ...getItemStyle(snapshot.isDragging, provided.draggableProps.style), backgroundColor: checkstate ? "#d0ffca" : 'white', borderRadius: 10, margin: '0px 5px 10px 5px' }}
                                    >
                                        {checkbtn}
                                        {contentdiv}
                                        {checkstate ? null : linkdiv}
                                        {checkstate ? null : delbtn}
                                        {checkstate ? null : editbtn}
                                    </div>
                                )}
                            </Draggable>
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {delcatbtn}{addbtn}
            </div>
        </div>
    }

    const Noncollapsable = (strikeoff) => {
        var true_arr = []
        for (let i = 0; i < strikeoff.length; i++) if (strikeoff[i]) true_arr.push(strikeoff[i])
        return <div className={true_arr.length !== 0 && true_arr.length - strikeoff.length === 0 ? "Checklist_equal" : "Checklist_nonequal"}>
            {true_arr.length + ' / ' + strikeoff.length}
        </div>
    }

    const MASTER_TITLE_DIV = currchecklistobjs && currchecklistobjs.length > 0
        ? <div style={{ textAlign: 'flex-start', padding: '10px 0px' }}>
            {[
                'Don\'t know where to start? Use our template to kick start your wedding/event planning! ',
                <button
                    key={'weddingbtn'}
                    style={{ ...BUTTONSTYLE, display: '', cursor: 'pointer', width: 'max-content', padding: 'auto', color: '#707070', alignItems: 'center', whiteSpace: 'nowrap', border: 'thin solid #707070', backgroundColor: 'white', boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px', borderRadius: 5 }}
                    onClick={() => setPoptemplate(true)}>Wedding</button>,
                ''
            ]}
        </div>
        : null

    const MODE_AND_SAVE_AND_NAME_DIV = (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: 35 }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                <button style={{ ...BUTTONSTYLE, cursor: 'pointer', padding: 5, margincolor: '#707070', alignItems: 'center', whiteSpace: 'nowrap', border: 'thin solid #707070', backgroundColor: 'white', boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px', flexDirection: 'row', display: 'flex', borderRadius: 5 }}
                    onClick={hideall}>{HIDE_SMALL}</button>
                {EMPTYSPACE}
                <button style={{ ...BUTTONSTYLE, cursor: 'pointer', padding: 5, margincolor: '#707070', alignItems: 'center', whiteSpace: 'nowrap', border: 'thin solid #707070', backgroundColor: 'white', boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px', flexDirection: 'row', display: 'flex', borderRadius: 5 }}
                    onClick={showall}>{SHOW_SMALL}</button>
                {EMPTYSPACE}
                <CSVLink onClick={showall} filename={"checklistlist.csv"} data={csvdata} style={{ outline: 'none', color: '#707070', textDecoration: 'none', padding: 5, borderRadius: 5, border: 'thin solid #707070', width: 'max-content', height: 'max-content', backgroundColor: 'white' }}>
                    {DOWNLOAD_SMALL}
                </CSVLink>
            </div>
            {savemsg.length > 0 ? null : <div style={{ ...BUTTONSTYLE, cursor: 'pointer', padding: 5, color: 'white', backgroundColor: MAIN_PINK_COLOR, border: 'thin solid ' + MAIN_PINK_COLOR, boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px', borderRadius: 5 }} onClick={save}>Save</div>}
        </div>
    )

    // const addcatbtn = currchecklistobjs && currchecklistobjs.length > 0 ? <button style={{ padding: 5, outline: 'none', borderRadius: 5, borderTopLeftRadius: 0, borderTopRightRadius: 0, margin: '0px 5px 20px 0px', border: 'thin solid transparent', width: '100%', height: 'max-content', backgroundColor: '#f77f7f', color: 'white', fontWeight: 'bold' }} onClick={() => onAddmain()}>Add New Category </button> : null

    const addcatbtn = currchecklistobjs && currchecklistobjs.length > 0
        ? <div className="Planning_addnewcatbg">
            <button className="Planning_addnewcatcircular" onClick={onAddmain}>+</button>
        </div>
        : null

    const clickexist_old = (_id, index) => {
        var switchChecklist = () => {
            var local_checklist_ids = checklist_ids.slice()
            // update curr timelineobj to replace the new one
            if (currchecklistobjs_index > -1 && currchecklistobjs && currchecklistobjs.length > 0) {
                var temp = currchecklistobjs.slice()
                local_checklist_ids[currchecklistobjs_index].checklistobjs = temp
                setChecklist_ids(local_checklist_ids)
            }
            const { checklistobjs, name } = local_checklist_ids[index]
            setCurrchecklistobjs(checklistobjs)
            setCurrchecklist_id(_id)
            setCurrchecklistobjs_name(name)
            setCurrchecklistobjs_index(index)
        }

        var wannasave
        if (savegate) { // gt new updates
            wannasave = window.confirm('You have not save your recent work, click OK to proceed without saving.')
            if (wannasave) {
                switchChecklist();
                setSavegate(false);
            }
            else return
        }
        else { // no changes
            switchChecklist()
            setSavegate(false)
        }
        setAddclicked(false)
    }

    const clickexist = (clickid) => {
        if (clickid === currchecklist_id) {
            // uncheck 
            setCurrchecklist_id('')
            setPage('NONE')
        }
        else {
            var obj
            for (let i = 0; i < checklist_ids.length; i++) {
                if (checklist_ids[i]._id === clickid) {
                    obj = checklist_ids[i]
                    break
                }
            }
            const { _id, } = obj
            setCurrchecklist_id(_id)
            setPage('OPEN')
        }
    }


    const removeexist = (_id, index) => {
        setRmvmod(true);
        setRmvid(_id);
        setRmvindex(index)
        setCurrchecklist_id('')
        setPage('NONE')

        // if (clickid === currchecklist_id) {
        //     // uncheck 
        //     setCurrchecklist_id('')
        //     setPage('NONE')
        // }
        // else {
        //     console.log(checklist_ids)
        //     var obj
        //     for (let i = 0; i < checklist_ids.length; i++) {
        //         if (checklist_ids[i]._id === clickid) {
        //             obj = checklist_ids[i]
        //             break
        //         }
        //     }
        //     const { _id, } = obj
        //     setCurrchecklist_id(_id)
        //     setPage('OPEN')
        // }
    }

    const clear = () => {
        setCurrchecklistobjs([])
        setCurrchecklist_id(null)
        setCurrchecklistobjs_name('')
        setCurrchecklistobjs_index(null)
        setCurrchecklistobjs_innerindex(null)
        setMsg('')
    }

    const create = () => { // create timeline
        setAddclicked(true)
        if (checklist_ids && checklist_ids.length > quota) {
            setCurrchecklistobjs(null)
            setCurrchecklist_id(null)
            setCurrchecklistobjs_name('')
            setCurrchecklistobjs_index(null)
            setMsg('You have reached the maximum number of timelines')
        }
        else {
            try {
                let options = {
                    method: 'POST',
                    url: CREATE_CHECKLIST,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                }
                axios(options).then((result) => {
                    const { singlechecklistobj } = result.data
                    var curr_checklist_ids = checklist_ids.slice()
                    curr_checklist_ids.push(singlechecklistobj)
                    setChecklist_ids(curr_checklist_ids)
                }).catch((e) => {
                    setMsg('You have reached the maximum number of timelines')
                    setCurrchecklistobjs(null)
                    setCurrchecklistobjs_name('')
                    setCurrchecklistobjs_index(null)
                    setCurrchecklist_id(null)
                })
            }
            catch (e) { }
        }
    }

    const updateseq = (new_ids) => { setChecklist_ids(new_ids); clear() };

    const removecfm = (_id, index) => {
        try {
            let options = {
                method: 'POST',
                url: REMOVE_SINGLECHECKLIST,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { checklist_id: _id }
            }
            axios(options).then((result) => {
                const { message } = result.data
                if (message === 'SUCCESS') {
                    var curr_checklistlist_ids = checklist_ids.slice()
                    curr_checklistlist_ids.splice(index, 1)
                    setChecklist_ids(curr_checklistlist_ids)
                    clear()
                }
            }).catch((e) => { })
        }
        catch (e) { }
    }

    const continueclick = () => {
        // props.history.push('qrbuilder/' + urlhandler + '/edit')
        props.history.push(`checklistbulider/${currchecklist_id}/edit`)
    }

    return (
        <div id='planningedit_root'>
            <div id='planningedit_selectionbox'>

                {loaded
                    ? <Selectionbox
                        where={'checklist'}
                        onClickexist={clickexist}
                        onRemoveexist={removeexist}
                        onAdd={create}
                        updateseq={updateseq}
                        quota={quota}
                        ids={checklist_ids}
                        id={currchecklist_id}
                    /> : null}

                {{
                    'NONE': null,
                    'OPEN': <Continuepage
                        {...props}
                        from={'checklistmaker'}
                        urlhandler={''}
                        passcode={''}
                        lookupclick={null}
                        continueclick={continueclick}
                        savepasscode={null}
                        title={''}
                    />,
                    'CREATE': <Createnew
                        {...props}
                        from={'checklistmaker'}
                        reachquota={quota === checklist_ids.length}
                        urlhandler={''}
                        passcode={''}
                        create={create}
                        createsuccess={(id, urlhandler, passcode) => {
                            setCurrchecklist_id(id)
                            setPage('OPEN')
                        }}
                    />
                }[page]}

                {rmvmod ? <Modcfm
                    onHide={() => { setRmvmod(false) }}
                    onOk={() => { removecfm(rmvid, rmvindex); setRmvmod(false) }}
                    onNo={() => { setRmvmod(false) }}
                    txt={'Once you delete this, there is no going back. Please be certain.'}
                /> : null}

                {/* <div ref={btmref} style={{ height: 120, width: 1 }} /> */}
            </div>
        </div>
    )


}
export default Checklistmaker