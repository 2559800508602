import React from 'react'
import { Checkdevice } from '../../../../../../components'
import { d_fs, c_fs } from '../../../../../../config/constants'
import './Questionpage.css'

const DEVICE_FS_MAP = {
    'WEB': { fs: c_fs },
    'TABLET': { fs: d_fs },
    'MOBILE': { fs: d_fs },
}

function Questionpage(props) {
    const device = Checkdevice()
    const { qn, pageindex, selections, selected, updateSelected } = props
    const { fs } = DEVICE_FS_MAP[device]
    const DEVICE_WIDTH = {
        'WEB': 660,
        'TABLET':  660,
        'MOBILE': '100%'
    }

    const Questionquiz = () => {
        var root = []
        for (let i = 0; i < selections.length; i++) {
            const curr_index = i
            var singleselection = selections[i]
            root.push(
                <div
                    className={selected[pageindex].includes(curr_index) ? 'questionpage_selected' : 'questionpage_nonselected'}
                    key={curr_index}
                    onClick={() => {
                        var local_selected = selected.slice()
                        if (!selected[pageindex].includes(curr_index)) { // img not selected
                            var replaced = false
                            for (let k = 0; k < selected[pageindex].length; k++) {
                                if (selected[pageindex][k] > -1) continue
                                else if (selected[pageindex][k] === -1) {
                                    local_selected[pageindex][k] = curr_index
                                    replaced = true
                                    break
                                }
                            }
                            if (!replaced) local_selected[pageindex][local_selected[pageindex].length - 1] = curr_index

                        }
                        else { // img selected
                            const replace_index = selected[pageindex].indexOf(curr_index)
                            local_selected[pageindex][replace_index] = -1
                        }
                        updateSelected(local_selected)
                    }}
                >
                    {singleselection}
                </div>
            )
        }
        return root
    }

    return <div key={qn} style={{ width: DEVICE_WIDTH[device], padding:10 }}>
        <div className='questionpage_qns' style={{ fontSize: fs }}>{qn}</div>
        {Questionquiz()}
    </div>
}

export default Questionpage