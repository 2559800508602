import React, { useEffect, useState } from 'react'
import { a3wtint, a3htint, canva_dlratio, canva_editratio } from '../../../../../../../../../config/constants';
import { ReactP5Wrapper } from "react-p5-wrapper";
import { Checkdevice, Noti } from '../../../../../../../../../components';
import Commonart from '../components/Commonart/Commonart';
import { changeDpiBlob } from 'changedpi';
import { UPDATE_GBARTSAVE } from '../../../../../../../../../config/api';
import { Retrieve_personal_info } from '../../../../../../../../../config/commonfunctions';
import html2canvas from 'html2canvas';
import axios from 'axios'
import './Wordcube.css'

const USERFACING = true // true: user, false: weave

// Direct copy starts here --- 

const artkey = 'WORDCUBE1'

// sentence can only hanlde up to 
const Artcommon = new Commonart()

function determinepos(txts, numoftxt, width, height, canvawidth, canvaheight, i, j, inch) {
    var len = txts.length
    var originx = canvawidth / 2 - ((len / 2) * width)
    var originy = canvaheight / 2 - ((numoftxt.length / 2) * height)
    var x = (originx + (j * width) + width / 2)
    var y = originy + (i * height) + height / 2

    return { x: inch + x - (width / 2), y: inch + y - (height / 2) }
}

function randomIntFromInterval(min, max) {// min and max included 
    return Math.ceil(Math.random() * (max - min + 1) + min)
}
const COLOR_PALLETES = [
    ["#001219", "#005f73", "#0a9396", "#94d2bd", "#e9d8a6", "#ee9b00", "#ca6702", "#bb3e03", "#ae2012", "#9b2226"],
    ["#fec5bb", "#fcd5ce", "#fae1dd", "#f8edeb", "#e8e8e4", "#d8e2dc", "#ece4db", "#ffe5d9", "#ffd7ba", "#fec89a"],
    ["#d9ed92", "#b5e48c", "#99d98c", "#76c893", "#52b69a", "#34a0a4", "#168aad", "#1a759f", "#1e6091", "#184e77"],
    ["#7400b8", "#6930c3", "#5e60ce", "#5390d9", "#4ea8de", "#48bfe3", "#56cfe1", "#64dfdf", "#72efdd", "#80ffdb"]
]
const DEBUG_STOPDL = false // for debug pupose

function Wordcube(props) {
    const { changecat, purchase, openpurchase, artmeta, msgsdata, updateartmeta, urlhandler, artmaintxt } = props
    const { token } = Retrieve_personal_info()
    const [firsttime, setFirsttime] = useState(true)
    const [rankedterms, setRankedterms] = useState([])
    const [tf, setTf] = useState({})
    const [pfive, setPfive] = useState(null)

    const [p5reloadclicked, setP5reloadclicked] = useState(false)
    const [download, setDownload] = useState(false)
    const [downsizeratio, setDownsizeratio] = useState(canva_editratio)

    // artmeta
    const [msgslimit, setMsgslimit] = useState(30)
    const [framecolor, setFramecolor] = useState('white')
    const [seed, setSeed] = useState(null)
    const [orientation, setOrientation] = useState('landscape') // landscape or portrait
    const [frame, setFrame] = useState('framed') // framed or unframed
    const [shape, setShape] = useState('HEXAGON')  // CIRCLE, SQUARE, HEXAGON
    const [cending, setCending] = useState(false) // ASCEND or DESCEND
    const [qractive, setQractive] = useState('NONE') // NONE, LEFT, MID, RIGHT

    // backgrounddesign color
    const [bgdesign, setBgdesign] = useState('') // "", SQUARE or CIRCULAR
    const [showbgdesigncolor, setShowbgdesigncolor] = useState(false)
    const [bgdesigncolors, setBgdesigncolors] = useState([])

    // highlight color
    const [showhighlight, setShowhighlight] = useState(false)
    const [highlightcolors, setHighlightcolors] = useState([])

    // base color
    const [showbase, setShowbase] = useState(false)
    const [basecolors, setBasecolors] = useState([])

    const [showwords, setShowwords] = useState(false)

    // show logo for art
    const [logogate, setLogogate] = useState(true)

    const [status, setStatus] = useState('')

    const device = Checkdevice(true)

    useEffect(() => {

        if (firsttime && Object.keys(msgsdata).length > 0 && artmeta && artmeta[artkey]) {
            const { basecolors, highlightcolors, bgdesign, bgdesigncolors, cending, frame, framecolor, msgslimit, orientation, qractive, seed, shape } = artmeta[artkey]

            const { sorted, tf } = msgsdata
            setRankedterms(sorted)
            setTf(tf)
            setBasecolors(basecolors)
            setHighlightcolors(highlightcolors)
            setSeed(seed)
            setShape(shape)
            setCending(cending)
            setBgdesign(bgdesign)
            setBgdesigncolors(bgdesigncolors)
            setFrame(frame)
            setFramecolor(framecolor)
            setMsgslimit(msgslimit)
            setOrientation(orientation)
            setQractive(qractive)
            setFirsttime(false)

        }
        else {
            if (firsttime && Object.keys(msgsdata).length > 0) {
                const { sorted, tf } = msgsdata
                setRankedterms(sorted)
                setTf(tf)
                setFirsttime(false)
            }
        }
    }, [msgsdata, artmeta, firsttime])


    useEffect(() => {
        if (p5reloadclicked) {
            setSeed(randomIntFromInterval(0, 999999999999999999))
            setP5reloadclicked(false)
        }
    }, [p5reloadclicked])

    useEffect(() => {
        let a3Paper = { width: a3htint, height: a3wtint }
        const oneinchpx = 96 / downsizeratio
        const frameinch = oneinchpx * 2
        setPfive(
            <div id={'shiny'} style={{ width: a3htint / downsizeratio, height: a3wtint / downsizeratio }}>
                <ReactP5Wrapper
                    sketch={(p5) => {
                        var pages = msgsdata['pages'].slice().splice(0, msgslimit)

                        if (cending === 'ASCEND') {
                            pages.sort((a, b) => a.length - b.length);
                        }
                        else if (cending === 'DESCEND') {
                            pages.sort((a, b) => a.length - b.length).reverse()
                        }

                        let a = ((a3Paper.height - frameinch) / downsizeratio) / pages.length
                        var longestsentence_len = -1
                        for (let i = 0; i < pages.length; i++) {
                            if (pages[i].length > longestsentence_len) {
                                longestsentence_len = pages[i].length
                            }
                        }

                        let b = ((a3Paper.width / downsizeratio) - (frameinch)) / longestsentence_len
                        var sqwt = (a < b ? a : b) * 0.95

                        function polygon(x, y, radius, npoints) {
                            let angle = p5.TWO_PI / npoints;
                            p5.beginShape();
                            for (let a = 0; a < p5.TWO_PI; a += angle) {
                                let sx = x + p5.cos(a) * radius;
                                let sy = y + p5.sin(a) * radius;
                                p5.vertex(sx, sy);
                            }
                            p5.endShape(p5.CLOSE);
                        }

                        p5.setup = () => {
                            p5.randomSeed(seed)
                            let w = a3Paper.width / downsizeratio
                            let h = a3Paper.height / downsizeratio
                            const canvas = p5.createCanvas(w, h);
                            canvas.elt.addEventListener("contextmenu", (e) => e.preventDefault())
                            // p5.angleMode(p5.DEGREES);
                            p5.noLoop()
                        }

                        p5.draw = () => {

                            p5.beginShape()
                            p5.background(framecolor)
                            p5.strokeWeight(0)
                            p5.fill('white')
                            p5.rect(oneinchpx, oneinchpx, p5.width - (frameinch), p5.height - (frameinch))
                            p5.endShape(p5.CLOSE);

                            var allobjpos = [] // store all object pos
                            // DRAW MAIN OBJ
                            for (let i = 0; i < pages.length; i++) {

                                var sentence = pages[i]
                                for (let j = 0; j < sentence.length; j++) {

                                    var sqht = sqwt
                                    var word = sentence[j]
                                    const { x, y } = determinepos(sentence, pages, sqwt, sqht, p5.width - (frameinch), p5.height - (frameinch), i, j, oneinchpx)
                                    var wordindex = rankedterms.indexOf(word)
                                    if (wordindex > -1 && highlightcolors && highlightcolors.length > 0 && highlightcolors.length > wordindex) {
                                        p5.fill(highlightcolors[wordindex]);
                                    }
                                    else {
                                        basecolors && basecolors.length > 0
                                            ? p5.fill(basecolors[p5.floor(p5.random(0, basecolors.length - 1))])
                                            : p5.fill('');
                                    }
                                    p5.strokeWeight(sqwt * 0.1)
                                    p5.stroke('white')
                                    if (shape === 'CIRCLE') { p5.square(x, y, sqwt, sqwt) }
                                    else if (shape === 'HEXAGON') { polygon(x + (sqwt / 2), y + (sqwt / 2), sqwt / 2, 6) }
                                    else if (shape === 'SQUARE') { p5.square(x, y, sqwt) }

                                    if (shape === 'HEXAGON') {
                                        allobjpos.push({ x: x + sqwt / 10, y: y + sqwt / 10 })
                                    }
                                    else {
                                        allobjpos.push({ x, y })
                                    }
                                }
                            }

                            p5.push()
                            // DRAW BACKGROUND
                            if (bgdesign && bgdesign.length > 0) {
                                var bgsqwt = sqwt
                                for (let i = 0; i < p5.ceil((p5.height) / bgsqwt); i++) {
                                    for (let j = 0; j < p5.ceil((p5.width) / bgsqwt); j++) {
                                        p5.beginShape()
                                        basecolors && basecolors.length > 0
                                            ? p5.stroke(basecolors[p5.floor(p5.random(0, basecolors.length))])
                                            : p5.stroke('transparent')

                                        var x1 = (j * bgsqwt)
                                        var y1 = (i * bgsqwt)
                                        var x2 = bgsqwt + (j * bgsqwt)
                                        var y2 = bgsqwt * (i + 1)

                                        var overlapping = false
                                        for (let k = 0; k < allobjpos.length; k++) {
                                            if (p5.dist(x1, y1, allobjpos[k].x, allobjpos[k].y) < (sqwt * 0.9)) {
                                                overlapping = true
                                                break
                                            }
                                        }

                                        if (!overlapping) {
                                            p5.noFill();
                                            bgdesigncolors && bgdesigncolors.length > 0
                                                ? p5.stroke(bgdesigncolors[p5.floor(p5.random(0, bgdesigncolors.length))])
                                                : p5.stroke('transparent')
                                            if (bgdesign === 'CIRCULAR') {
                                                if (p5.random(1) < 0.25) {
                                                    p5.arc(x1 + bgsqwt / 2, y1, bgsqwt, bgsqwt, 0, 90)
                                                }
                                                else if (p5.random(1) > 0.25 && p5.random(1) < 0.5) {
                                                    p5.arc(x1 + bgsqwt / 2, y1, bgsqwt, bgsqwt, 90, 180)
                                                }
                                                else if (p5.random(1) > 0.5 && p5.random(1) < 0.75) {
                                                    p5.arc(x1 + bgsqwt / 2, y1, bgsqwt, bgsqwt, 180, 270)
                                                }
                                                else if (p5.random(1) > 0.75 && p5.random(1) < 1) {
                                                    p5.arc(x1 + bgsqwt / 2, y1, bgsqwt, bgsqwt, 270, 360)
                                                }
                                            }
                                            else if (bgdesign === 'SQUARE') {
                                                if (p5.random(1) < 0.25) {
                                                    p5.line(x1, y1, x2, y2)
                                                }
                                                else if (p5.random(1) > 0.25 && p5.random(1) < 0.5) {
                                                    p5.line(x1, y2, x2, y1)
                                                }
                                                else if (p5.random(1) > 0.5 && p5.random(1) < 0.75) {
                                                    p5.line(x2, y2, x1, y1)
                                                }
                                                else if (p5.random(1) > 0.75 && p5.random(1) < 1) {
                                                    p5.line(x2, y1, x1, y2)
                                                }
                                            }

                                        }
                                        p5.endShape(p5.CLOSE);
                                    }
                                }
                            }

                            p5.beginShape()
                            p5.fill(framecolor)
                            p5.strokeWeight(0)
                            p5.rect(0, 0, p5.width, oneinchpx)
                            p5.rect(0, oneinchpx, oneinchpx, p5.height)
                            p5.rect(0, p5.height - oneinchpx, p5.width, p5.height)
                            p5.rect(p5.width - oneinchpx, 0, p5.width, p5.height)
                            p5.endShape(p5.CLOSE);
                            p5.pop()

                        }
                    }}>
                    {!USERFACING && logogate ? Artcommon.logodom(oneinchpx * 1.5, framecolor) : null}
                    {Artcommon.qrcodedom(qractive, urlhandler, oneinchpx * 1.5, oneinchpx)}
                </ReactP5Wrapper>
            </div >
        )

    }, [basecolors, highlightcolors, shape, cending, bgdesign, bgdesigncolors, qractive, framecolor, downsizeratio, msgslimit, logogate, msgsdata, rankedterms, seed, urlhandler])

    useEffect(() => { if (download) { setDownsizeratio(canva_dlratio) } }, [download])

    useEffect(() => {
        if (download) {
            setTimeout(() => {
                html2canvas(document.getElementById('shiny'), {
                    allowTaint: false,
                    useCORS: true,
                    logging: true,
                    backgroundColor: 'white',
                    scrollX: 0,
                    scrollY: -window.scrollY,
                }).then((canvas) => {// download happens here
                    if (!DEBUG_STOPDL) {
                        const link = document.createElement("a");
                        link.download = Math.random() + ".png";
                        document.body.appendChild(link);
                        canvas.toBlob(function (b) {
                            changeDpiBlob(b, 300).then(function (blob) {
                                // use your changed blob
                                link.href = window.URL.createObjectURL(blob);
                                link.click();
                                setDownsizeratio(canva_editratio)
                            })
                        });
                        document.body.removeChild(link);
                    }
                }).catch(e => { alert('Fail to download.') })
            }, 1000)
            setDownload(false)
        }
    }, [pfive, download])

    const save = () => {
        var local_artmeta = artmeta ? artmeta : {}
        local_artmeta[artkey] = { msgslimit, shape, framecolor, seed, orientation, frame, qractive, cending, bgdesign, bgdesigncolors, highlightcolors, basecolors }
        var purchase_artkey1 = artkey + '_SOFT'
        var purchase_artkey2 = artkey + '_HARDSOFT'
        axios({
            method: 'POST',
            url: UPDATE_GBARTSAVE,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, // need token from the web
            data: { artmeta: local_artmeta, urlhandler, artmaintxt, purchase_artkey1, purchase_artkey2 }
        }).then((item) => {
            const { message } = item.data
            if (message === 'SUCCESS') {
                updatestatus('Saved.')
                updateartmeta(local_artmeta)
            }
            else { }
        })
    }

    const updatestatus = (txt) => {
        setStatus(txt)
        setTimeout(() => { setStatus('') }, 1000)
    }

    const reload = () => setP5reloadclicked(!p5reloadclicked)

    const togglecolorpalette = () => setShowhighlight(!showhighlight)

    const togglebasecolorpalette = () => setShowbase(!showbase)

    const toggledownload = () => {
        var isSafari = window.safari !== undefined;
        if (!isSafari) { setDownload(true) }
        else { alert("Download in Safari is not supported. Please use Google Chrome or Firefox browser to download.") }
    }

    const togglepurchase = (e) => { openpurchase(e.target.value) }

    const toggleword = () => setShowwords(!showwords)

    const togglebgdesigncolor = () => setShowbgdesigncolor(!showbgdesigncolor)

    const updateshape = (e) => setShape(e.target.value)

    const updateseq = (e) => { setCending(e.target.value) }

    const updatemsgsize = (e) => { setMsgslimit(parseInt(e.target.value, 10)) }

    const updatebgdesign = (e) => {
        setBgdesign(e.target.value);
        if (e.target.value === '') {
            setShowbgdesigncolor(false);
        }
    }

    const updateqrplacement = (e) => qractive === e.target.value ? setQractive('NONE') : setQractive(e.target.value)

    const updateframecolor = (c) => setFramecolor(c)

    const colorpalette_dom = (
        <div id="Mta_rightcontainer">
            {COLOR_PALLETES.map((arr, x) => {
                const selectcolor = (e) => {
                    var new_wordcolormap = {}
                    if (highlightcolors.includes(e.target.value)) {
                        let temp = highlightcolors.slice()
                        temp.splice(highlightcolors.indexOf(e.target.value), 1)
                        setHighlightcolors(temp)
                        for (let i = 0; i < temp.length; i++) { new_wordcolormap[rankedterms[i]] = temp[i] }
                    }
                    else {
                        setHighlightcolors([...highlightcolors, e.target.value])
                        for (let i = 0; i < [...highlightcolors, e.target.value].length; i++) { new_wordcolormap[rankedterms[i]] = [...highlightcolors, e.target.value][i] }
                    }
                }
                return <div id="Mta_colorrow" key={'row' + x}>
                    {arr.map((color, y) => {
                        return <button
                            id={highlightcolors.includes(color) ? "Mta_colorselected" : "Mta_colornonselect"}
                            key={`${x}color${y}`}
                            onClick={selectcolor}
                            value={color}
                            style={{ backgroundColor: color, width: `calc( 90%/${arr.length} )`, height: 20 }}
                        />
                    })}
                </div>
            })}
        </div>
    )

    const basecolorpalette_dom = (
        <div id="Mta_rightcontainer">
            {COLOR_PALLETES.map((arr, x) => {

                const selectcolor = (e) => {
                    if (basecolors.includes(e.target.value)) {
                        let temp = basecolors.slice()
                        temp.splice(basecolors.indexOf(e.target.value), 1)
                        setBasecolors(temp)
                    }
                    else setBasecolors([...basecolors, e.target.value])
                }

                return <div id="Mta_colorrow" key={'rowbased' + x}>
                    {arr.map((color, y) => {
                        return <button
                            id={basecolors.includes(color) ? "Mta_colorselected" : "Mta_colornonselect"}
                            key={`${x}basecolors${y}`}
                            onClick={selectcolor}
                            value={color}
                            style={{ backgroundColor: color, width: `calc( 90%/${arr.length} )`, height: 20 }}
                        />
                    })}
                </div>
            })}
        </div>
    )

    const word_dom = (
        <div id="Mta_rightcontainer">
            {rankedterms.map((w, i) => {
                return <div key={'w' + i} id="Mta_txtroot">
                    <div id="Mta_firstpart">
                        <div id="Mta_index">{i}</div>
                        <div id="Mta_freq">{tf[w]}</div>
                        <div id="Mta_w">{w}</div>
                    </div>
                    {highlightcolors && highlightcolors.length > 0 && highlightcolors.length > i ? <div style={{ backgroundColor: highlightcolors[i], height: 10, width: 10 }} /> : null}
                </div>
            })}
        </div>
    )

    const bgdesigncolorpalette_dom = (
        <div id="Mta_rightcontainer">
            {COLOR_PALLETES.map((arr, x) => {

                const selectcolor = (e) => {
                    if (bgdesigncolors.includes(e.target.value)) {
                        let temp = bgdesigncolors.slice()
                        temp.splice(bgdesigncolors.indexOf(e.target.value), 1)
                        setBgdesigncolors(temp)
                    }
                    else setBgdesigncolors([...bgdesigncolors, e.target.value])
                }

                return <div id="Mta_colorrow" key={'rowbased' + x}>
                    {arr.map((color, y) => {
                        return <button
                            id={bgdesigncolors.includes(color) ? "Mta_colorselected" : "Mta_colornonselect"}
                            key={`${x}bgdesigncolor${y}`}
                            onClick={selectcolor}
                            value={color}
                            style={{ backgroundColor: color, width: `calc( 90%/${arr.length} )`, height: 20 }}
                        />
                    })}
                </div>
            })}
        </div>
    )

    const highlightcolor_btn = (
        showhighlight
            ? <button className="Art_save Art_shorter" style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: '0px solid transparent', height: 60 }} onClick={togglecolorpalette}>
                <div id='Mta_txt' style={{ height: 28, minHeight: 28, marginBottom: 2 }}>Highlight Color</div>
                <div id='Mta_txt'>{`${highlightcolors.length} highlight color selected.`} </div>
            </button>
            : <button className="Art_save Art_shorter" style={{ height: 30 }} onClick={togglecolorpalette}>
                <div id='Mta_txt'>Highlight Color</div>
            </button>
    )

    const basecolor_btn = (
        showbase
            ? <button className="Art_save Art_shorter" style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: '0px solid transparent', height: 60 }} onClick={togglebasecolorpalette}>
                <div id='Mta_txt' style={{ height: 28, minHeight: 28, marginBottom: 2 }}>Base Color</div>
                <div id='Mta_txt'>{`${basecolors.length} base color selected.`} </div>
            </button>
            : <button className="Art_save Art_shorter" style={{ height: 30 }} onClick={togglebasecolorpalette}>
                <div id='Mta_txt'>Base Color</div>
            </button>
    )

    const wordcolormap_dom = (
        (basecolors.length > 0 || highlightcolors.length > 0)
            ? showwords
                ? <button className="Art_save Art_shorter" style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: '0px solid transparent', height: 60 }} onClick={toggleword}>
                    <div id='Mta_txt' style={{ height: 28, minHeight: 28, marginBottom: 2 }}>Word-Color Summary</div>
                    <div id='Mta_txt'>{`Summary of word to color.`} </div>
                </button>
                : <button className="Art_save Art_shorter" style={{ height: 30 }} onClick={toggleword}>
                    <div id='Mta_txt' >Word-Color Summary</div>
                </button>
            : null
    )

    const bgcolor_btn = (
        (bgdesign && bgdesign.length > 0)
            ? showbgdesigncolor
                ? <button className="Art_save Art_shorter" style={{ marginTop: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: '0px solid transparent', height: 60 }} onClick={togglebgdesigncolor}>
                    <div id='Mta_txt' style={{ height: 28, minHeight: 28, marginBottom: 2 }}>Background Design Color</div>
                    <div id='Mta_txt'>{``} </div>
                </button>
                : <button className="Art_save Art_shorter" style={{ marginTop: 0, height: 30 }} onClick={togglebgdesigncolor}>
                    <div id='Mta_txt' >Background Design Color</div>
                </button>
            : null
    )

    return <div className={"Artwork_root" + device}>
        {Artcommon.backbtn(changecat)}
        <div key={"p5" + seed} className={"Emojiart_left" + device}> <p />{pfive}<p /></div>
        <div className={"Artwork_right" + device}>
            <div className="Artwork_righttop">
                {USERFACING ? Artcommon.endbtns(reload, save, purchase, artkey) : null}
                {highlightcolor_btn}
                {showhighlight ? colorpalette_dom : null}
                {basecolor_btn}
                {showbase ? basecolorpalette_dom : null}
                {wordcolormap_dom}
                {showwords ? word_dom : null}
                {Artcommon.threeselectionbtns('Message Size', msgslimit, 50, 100, 150, updatemsgsize, '<50', '<100', '<150')}
                {Artcommon.threeselectionbtns('Background Design', bgdesign, '', 'SQUARE', 'CIRCULAR', updatebgdesign, 'Default', 'Squarish', 'Cicularish')}
                {bgcolor_btn}
                {showbgdesigncolor && bgdesign !== '' ? bgdesigncolorpalette_dom : null}
                {Artcommon.threeselectionbtns('Shape', shape, 'SQUARE', 'HEXAGON', 'CIRCLE', updateshape, 'Square', 'Hexagon', 'Circle')}
                {Artcommon.threeselectionbtns('Order', cending, '', 'DESCEND', 'ASCEND', updateseq, 'Default', 'Descend', 'Ascend')}
                {frame === 'framed' ? Artcommon.colorpalette(updateframecolor, ['white', 'beige', '#707070', 'black'], framecolor, "Frame Color") : null}
                {!USERFACING ? Artcommon.qrurlbtns(updateqrplacement, qractive, urlhandler) : null}
                {!USERFACING ? Artcommon.twoselectionbtns('Logo', logogate, true, false, (e) => { setLogogate(logogate ? null : true) }, () => { setLogogate(!logogate ? null : false) }, 'Show Logo', 'Hide Logo') : null}
            </div>
            {USERFACING ? Artcommon.purchasedlbtns(purchase, artkey, togglepurchase, toggledownload) : Artcommon.puchasedbtnsonly(purchase, artkey, () => { }, toggledownload)}
        </div>
        <Noti noti={status} />
    </div>
}

export default Wordcube