import React, { useEffect, useState } from 'react'
import { Checkdevice, Jfmodal, Modcfm } from '../../../../components'
import { READ_GB, RMV_GB, EDITPW_GB, EDITPWMOD_GB, CREATE_GB } from '../../../../config/api'
import { Retrieve_personal_info } from '../../../../config/commonfunctions'
import { Plancontent, Selectionbox } from '../Components'
import { GUESTBOOK_ARR, EDIT_ICON } from '../../../../config/constants'
import axios from 'axios'
import './../Planning.css'
import Continuepage from '../Components/Continuepage/Continuepage'
import Createnew from '../Components/Createnew/Createnew'

const gtype_icon_map = {
    'Wedding': "M341.333333 85.333333a85.333333 85.333333 0 0 0 0 170.666667 85.333333 85.333333 0 0 0 0-170.666667z m277.333334 0a85.333333 85.333333 0 0 0 0 170.666667 85.333333 85.333333 0 0 0 0-170.666667z m106.752 128.042667s-2.24 40.384 21.290666 63.914667 63.914667 21.290667 63.914667 21.290666 2.197333-40.341333-21.333333-63.872c-23.530667-23.530667-63.872-21.333333-63.872-21.333333zM330.666667 298.666667a117.461333 117.461333 0 0 0-117.333334 117.333333v256c0 13.909333 8.938667 25.621333 21.333334 30.037333V906.666667a32 32 0 1 0 64 0V682.666667h85.333333v224a32 32 0 0 0 32 32h362.666667a32 32 0 0 0 32-32c0-213.269333-47.594667-319.616-64-349.909334V416a117.333333 117.333333 0 0 0-117.333334-117.333333h-39.68a149.952 149.952 0 0 0-128.576 73.344l-0.234666 0.405333A117.44 117.44 0 0 0 352 298.666667h-21.333333z m258.986666 64h39.68a53.333333 53.333333 0 0 1 53.333334 53.333333v149.333333c0 6.144 1.877333 12.373333 5.205333 17.536 0.554667 0.874667 53.354667 85.312 58.410667 291.797334H491.050667c5.034667-206.485333 57.813333-290.922667 58.410666-291.797334 3.328-5.162667 5.205333-11.392 5.205334-17.536v-100.053333l-81.344 137.685333a31.509333 31.509333 0 0 1-4.821334 5.973334 31.850667 31.850667 0 0 1-38.997333 5.312 32.042667 32.042667 0 0 1-11.264-43.882667L469.333333 483.882667l46.869334-79.296A85.674667 85.674667 0 0 1 589.674667 362.666667z",
    'House-Warming': "M768 528a48 48 0 0 1 47.552 41.472L816 576v272l28.48-0.256a48 48 0 0 1 7.488 95.488l-6.464 0.512-659.392 6.848a48 48 0 0 1-7.488-95.488l6.528-0.512 22.848-0.256V576a48 48 0 0 1 95.552-6.528L304 576v277.376l86.592-0.896v-127.168c0-34.624 26.24-63.168 59.904-66.816l7.36-0.384H576.64c34.624 0 63.168 26.24 66.816 59.904l0.384 7.296v124.48l76.096-0.768V576a48 48 0 0 1 48-48z m-220.096 226.112h-61.312v97.28l61.312-0.64v-96.64z m25.984-628.672l7.872 6.4 138.24 125.12V192a48 48 0 0 1 95.552-6.528L816 192v151.808l112.96 102.144a48 48 0 0 1-58.816 75.52l-5.568-4.352-347.2-314.048a16 16 0 0 0-18.368-2.176l-3.328 2.368-334.912 313.344a48 48 0 0 1-70.4-64.896l4.864-5.248L430.08 133.184a112 112 0 0 1 143.744-7.744z",
    'Baby Shower': "M201.130667 489.685333l529.578666 444.373334a310.869333 310.869333 0 0 1-529.536-220.928l-0.042666-223.445334z m621.696-26.410666v249.856a309.546667 309.546667 0 0 1-67.669334 193.706666l-230.442666-193.408 298.112-250.154666zM512 0a310.869333 310.869333 0 0 1 310.869333 310.869333v104.661334l-326.613333 274.048-295.125333-247.637334V310.869333A310.869333 310.869333 0 0 1 512 0z m33.365333 124.672l-3.114666 0.085333-3.114667 0.341334-3.84 0.64-3.968 0.938666-2.474667 0.768-2.986666 1.066667-1.834667 0.725333a66.517333 66.517333 0 0 0-8.448 4.352l-3.157333 2.005334-1.493334 1.066666-3.456 2.645334-1.706666 1.450666-1.493334 1.28-2.133333 2.133334-2.133333 2.218666-2.645334 3.114667a64.426667 64.426667 0 0 0-4.138666 5.76l-2.090667 3.541333-1.237333 2.346667-1.109334 2.432-0.597333 1.450667c-73.344 8.021333-133.333333 62.336-152.149333 134.186666-24.32 1.706667-43.477333 22.613333-43.477334 48.170667 0 25.514667 19.2 46.464 43.477334 48.128 20.437333 77.909333 89.216 135.253333 170.965333 135.253333 81.749333 0 150.570667-57.344 170.965333-135.253333 24.32-1.706667 43.52-22.613333 43.52-48.128 0-25.6-19.2-46.506667-43.52-48.170667-16.597333-63.317333-65.109333-112.981333-126.592-129.365333l2.133334-0.682667 1.109333-0.426666 2.133333-1.066667 1.834667-1.109333 1.28-0.938667 1.706667-1.493333 1.92-2.090667 1.578666-2.218667 0.938667-1.578666 0.725333-1.536a22.613333 22.613333 0 0 0 1.877334-9.045334 23.125333 23.125333 0 0 0-23.253334-22.997333z m-2.602666 263.637333c5.034667 0 9.130667 4.096 9.130666 9.130667 0 25.258667-20.053333 45.824-44.885333 45.824-21.589333 0-39.68-15.573333-43.946667-36.693333l-0.512-3.072-0.298666-2.986667-0.128-2.730667 0.128-1.706666a9.173333 9.173333 0 0 1 5.76-7.168l1.578666-0.426667 1.706667-0.170667h71.466667zM417.664 324.096a17.92 17.92 0 1 1-0.042667 35.84 17.92 17.92 0 0 1 0.042667-35.84z m178.688 0a17.92 17.92 0 1 1-0.042667 35.84 17.92 17.92 0 0 1 0.042667-35.84z",
    'Birthday Party': "M880.29 848.59h-47.37V529.07a32 32 0 0 0-32-32H601.5v-207A78.13 78.13 0 0 0 523.38 212h-22.76a78.13 78.13 0 0 0-78.12 78.13v207H223.08a32 32 0 0 0-32 32v319.46h-47.37a31.71 31.71 0 0 0-31.71 31.7A31.71 31.71 0 0 0 143.71 912h736.58A31.71 31.71 0 0 0 912 880.29a31.71 31.71 0 0 0-31.71-31.7zM486.5 290.08A14.14 14.14 0 0 1 500.62 276h22.76a14.14 14.14 0 0 1 14.12 14.13v207h-51z m-231.42 271h211.34c1 0.06 1.92 0.1 2.89 0.1h85.38c1 0 1.93 0 2.89-0.1h211.34v44.2c-1.2 1.09-2.38 2.24-3.55 3.47-7.89 8.73-14.85 20-25.06 36.44l-10.22 16.93c-6 5.65-10.21 7.7-13.46 7.7-8.36 0-20.89-13.34-44.56-42.08-17.18-20.53-35.75-30.79-56.64-30.79-33 1-58.49 29.25-81.23 60-10.68 10.78-16.72 11.8-20.43 11.8-8.36-1-20-14.37-35.28-39.51l-3.25-4.11c-18.57-21.55-39-32.84-60.35-32.84h-3.71c-34.82 2.57-61.28 34.38-84.95 68.25a19.71 19.71 0 0 1-16.23 9.27c-7.43 0-13.93-6.16-19-16.42-12.07-22.51-24.63-36.74-35.87-45.73z m0 141.93c14.25 17.13 33.36 26.72 55.36 27.87 25.07 0 47.82-13.34 61.74-35.92 7.43-11.29 27.86-40.54 42.71-41.56 5.11 0 11.14 4.1 18.11 11.29 18.1 29.76 40.85 61.06 74.27 65.17a36.43 36.43 0 0 0 7.43 0.51c20 0 39.46-10.26 58.49-30.27l2.32-2.57c25.07-33.88 36.21-39.53 40.85-39.53 1.86 0 6 1.54 14.86 12.31 19 23.09 35.74 43.11 55.7 53.37a59.93 59.93 0 0 0 28.32 7.18c17.44 0 34.89-8.54 53.68-23.61V848H255.08zM502.45 199.1a98.23 98.23 0 0 0 11.77 1c14.85 0 26.63-4.61 34.3-13.31 13.32-15.36 11.78-37.89 9.73-50.18-3.07-19.46-39.42-61.95-47.1-61.95l-8.15 0.54 1 7.17c0 0.51 5.63 40.45-13.82 48.64-17.41 6.65-27.14 19.45-25.6 34.81 2.03 16.9 16.87 30.18 37.87 33.28z",
}
const title = "Create your Digital Guest Book Now"
const maintext = "Click continue and start editing your Digital Guest Book."
const device_css_map = {
    'WEB': { padding: '0px 80px 80px', width: '', display: 'flex', flexDirection: 'column', color: '#707070', },
    'TABLET': { padding: '0px 16px 16px', width: '', display: 'flex', flexDirection: 'column', color: '#707070', },
    'MOBILE': { padding: '', width: '100%', display: 'flex', flexDirection: 'column', color: '#707070' }
}
function copyToClipboard(string) {
    let textarea;
    let result;

    try {
        textarea = document.createElement('textarea');
        textarea.setAttribute('readonly', true);
        textarea.setAttribute('contenteditable', true);
        textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
        textarea.value = string;

        document.body.appendChild(textarea);

        textarea.focus();
        textarea.select();

        const range = document.createRange();
        range.selectNodeContents(textarea);

        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        textarea.setSelectionRange(0, textarea.value.length);
        result = document.execCommand('copy');
    } catch (err) {
        console.error(err);
        result = null;
    } finally {
        document.body.removeChild(textarea);
    }

    // manual copy fallback using prompt
    if (!result) {
        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
        result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
        if (!result) {
            return false;
        }
    }
    return true;
}

function Guestbookbuilder(props) {
    const device = Checkdevice()
    const { token } = Retrieve_personal_info()
    const [firsttime, setFirsttime] = useState(true)
    const [loaded, setLoaded] = useState(true)
    const [gb_ids, setGb_ids] = useState([])
    const [displaycontent, setDisplaycontent] = useState(false)
    const [_id, setId] = useState('') // to indicate is ADD or event seating chart id clicked
    const [gtype, setGtype] = useState('')
    const [urlhandler, setUrlhandler] = useState('') // event seating chart url handler
    const [errormsg, setErrormsg] = useState([])
    const [quota, setQuota] = useState(0)
    const [page, setPage] = useState('NONE') // NONE, CREATE, OPEN (OPEN EXISTING)

    // edit btn modal
    const [passcode, setPasscode] = useState('')
    const [newpasscode, setNewpasscode] = useState('')
    const [editmodal, setEditmodal] = useState(false)
    const [editmodalmod, setEditmodalmod] = useState(false)
    const [passcodemod, setPasscodemod] = useState('')
    const [newpasscodemod, setNewpasscodemod] = useState('')

    // for removal of selectionbox
    const [rmvmod, setRmvmod] = useState(false)
    const [rmvindex, setRmvindex] = useState(null)
    const [rmvid, setRmvid] = useState(null)


    function reorder(gtgbids, guestbook_ids, orderids) {
        if (gtgbids) {
            // reorder them based on checklist_ids, as db return messy indexed arr
            var new_objs = guestbook_ids && guestbook_ids.map(() => []);
            for (let i = 0; i < guestbook_ids.length; i++) {
                const { _id } = guestbook_ids[i];
                var index = orderids.indexOf(_id);
                new_objs[index] = guestbook_ids[i];
            }

            setGb_ids(new_objs);
        }
        else {
            setGb_ids([]);
        }
    }

    useEffect(() => {
        if (firsttime) {
            try {
                let options = {
                    method: 'POST',
                    url: READ_GB,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                }
                axios(options).then((item) => {
                    const { guestbook_ids, guestbook_quota, orderids } = item.data
                    reorder(guestbook_ids && guestbook_ids.length > 0, guestbook_ids, orderids);
                    setQuota(guestbook_quota)
                    setLoaded(true)
                }).catch((e) => { props.history.push('/') })
            }
            catch (e) {
                props.history.push('/')
            }
            setFirsttime(false)
        }
    }, [])

    const removecfm = (rmvid, index) => {
        try {
            let options = {
                method: 'POST',
                url: RMV_GB,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { gb_id: rmvid }
            }
            axios(options).then((item) => {
                const { data } = item
                const { message } = data
                if (message === 'SUCCESS') {
                    setId('')
                    setDisplaycontent(false)
                    var local_gb_ids = gb_ids.slice()
                    local_gb_ids.splice(index, 1)
                    setGb_ids(local_gb_ids)
                    clearFields()
                }
            }).catch((e) => { props.history.push('/') })
        }
        catch (e) {
            props.history.push('/')
        }
    }

    function clearFields() {
        setGtype('')
        setUrlhandler('')
        setPasscode('')
        setPasscodemod('')
        setPage('NONE')
    }

    const clickexist = (item, index) => {
        if (item === _id) {
            // uncheck 
            setId('')
            setDisplaycontent(false)
            clearFields()

            setPage('NONE')
        }
        else {
            var obj
            for (let i = 0; i < gb_ids.length; i++) {
                if (gb_ids[i]._id === item) {
                    obj = gb_ids[i]
                    break
                }
            }
            const { _id, gtype, urlhandler, passcode, passcodemod } = obj

            clearFields()
            setId(_id)
            setGtype(gtype)
            setDisplaycontent(true)
            setUrlhandler(urlhandler)
            setPasscode(passcode ? passcode : '')
            setPasscodemod(passcodemod ? passcodemod : '')
            setPage('OPEN')
        }

    }

    const removeexist = (_id, index) => {
        setRmvmod(true);
        setRmvid(_id);
        setRmvindex(index);
    }

    const add = () => {
        setId('')
        setPage('CREATE')

        // if (_id === 'ADD') {
        //     setId('')
        //     setDisplaycontent(false)
        //     clearFields()
        // }
        // else {
        //     setId('ADD')
        //     if (gb_ids === -1 || (typeof (gb_ids) === typeof ([]) && gb_ids.length < quota)) { // is empty || less than two project
        //         // then we create a new project for user
        //         setDisplaycontent(true)
        //         clearFields()
        //     }
        //     else {
        //         // have reach max number
        //         setDisplaycontent(false)
        //         setMainmsg('You have reached the maximum number of seating charts')
        //     }
        // }
    }

    const updateseq = (new_ids) => { setGb_ids(new_ids); };

    var EVENT_DIV = (
        <div key="EVENT_DIV" className="Plan_fg">
            <div className="Plan_ft">Event Type</div>
            {_id === 'ADD'
                ? <div style={{ display: 'flex', flexDirection: 'row', margin: '0px 0px 10px 0px', overflowX: 'auto' }} >
                    {GUESTBOOK_ARR.map((val, index) => {
                        var style_logo = { width: 28, height: 28, verticalAlign: 'middle', overflow: 'hidden', fill: '#707070', }
                        return val === 'Postponement'
                            ? <div className={"planning_option" + (val === gtype)} key={val + index} onClick={(e) => setGtype(val)} >
                                {val === gtype ? <div className="planning_left" /> : <div className="planning_lefttransparent" />}
                                <div className="planning_optiontop">{<svg className="icon" style={style_logo} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" ><path d={gtype_icon_map[val]}></path></svg>}</div>
                                <div className="planning_optionbtm">{val + ' / Cancellation'}</div>
                            </div>
                            : <div className={"planning_option" + (val === gtype)} key={val + index} onClick={(e) => setGtype(val)} >
                                {val === gtype ? <div className="planning_left" /> : <div className="planning_lefttransparent" />}
                                <div className="planning_optiontop">{<svg className="icon" style={style_logo} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" ><path d={gtype_icon_map[val]}></path></svg>}</div>
                                <div className="planning_optionbtm"> {val}</div>
                            </div>
                    })}
                </div>
                : <div style={{ display: 'flex', flexDirection: 'row', margin: '0px 0px 10px 0px', overflowX: 'auto' }} >
                    <div className={"planning_optiontrue"} key={gtype}>
                        <div className="planning_left" />
                        <div className="planning_optiontop">{<svg className="icon" style={{ width: 28, height: 28, verticalAlign: 'middle', overflow: 'hidden', fill: '#707070' }} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" ><path d={gtype_icon_map[gtype]}></path></svg>}</div>
                        <div className="planning_optionbtm"> {gtype === 'Postponement' ? gtype + ' / Cancellation' : gtype}</div>
                    </div>
                </div>}
        </div>
    )

    const savepasscode = (newpasscode) => {
        return axios({
            method: 'POST',
            url: EDITPW_GB,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
            data: { newpasscode, passcode, _id }
        }).then((result) => {
            const { data } = result
            if (data.message === 'SUCCESS') {
                setPasscode(newpasscode)
                return 'SUCCESS'
            }
            else if (data.message === 'UNABLE_SAVE') {
                return 'UNABLE_SAVE'
            }
        }).catch((e) => { console.log(e) })
    }


    const savepasscodemod = (newpasscodemod) => {
        return axios({
            method: 'POST',
            url: EDITPWMOD_GB,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
            data: { newpasscodemod, passcodemod, _id }
        }).then((result) => {
            const { data } = result
            if (data.message === 'SUCCESS') {
                setPasscodemod(newpasscodemod)
                return 'SUCCESS'
            }
            else if (data.message === 'UNABLE_SAVE') {
                return 'UNABLE_SAVE'
            }
        }).catch((e) => { console.log(e) })
    }

    const create = (urlhandler, passcode, gtype, passcodemod) => {
        return axios({
            method: 'POST',
            url: CREATE_GB,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
            data: { gtype, urlhandler, passcode, passcodemod }
        }).then((item) => {
            const { data } = item
            const { message } = data
            if (message === 'SUCCESS') {
                setGb_ids([...gb_ids, data.gb])
                setId('')
                setPasscode('')
                setPasscodemod('')
            }
            return { message, id: data.gb._id } // this will return to Createnew.jsx, then it will call createsuccess and set the id.
        }).catch((e) => {
            props.history.push('/')
        })
    }

    const continueclick = () => {
        props.history.push('guestbookbuilder/' + _id + '/edit')
    }

    return <div id='planningedit_root'>
        <div id='planningedit_selectionbox'>
            {loaded
                ? <Selectionbox
                    where={'guestbookbuilder'}
                    onClickexist={clickexist}
                    onRemoveexist={removeexist}
                    onAdd={add}
                    updateseq={updateseq}
                    quota={quota}
                    ids={gb_ids}
                    id={_id}
                />
                : null}

            {{
                'NONE': null,
                'OPEN': <Continuepage
                    {...props}
                    from={'guestbook'}
                    urlhandler={urlhandler}
                    passcode={passcode}
                    passcodemod={passcodemod}
                    itype={gtype}
                    lookupclick={''}
                    continueclick={continueclick}
                    savepasscode={savepasscode}
                    savepasscodemod={savepasscodemod}
                    title={'Change Passcode'}
                    titlemod={'Change Mod Passcode'}
                />,
                'CREATE': <Createnew
                    {...props}
                    from={'guestbook'}
                    reachquota={quota === gb_ids.length}
                    urlhandler={urlhandler}
                    passcode={passcode}
                    itype={'Wedding'}
                    create={create}
                    createsuccess={(id, urlhandler, passcode, gtype, passcodemod) => {
                        setUrlhandler(urlhandler)
                        setPasscode(passcode)
                        setGtype(gtype)
                        setId(id)
                        setPasscodemod(passcodemod)
                        setPage('OPEN')
                    }}
                />
            }[page]}
            {rmvmod ? <Modcfm
                onHide={() => { setRmvmod(false) }}
                onOk={() => { removecfm(rmvid, rmvindex); setRmvmod(false) }}
                onNo={() => { setRmvmod(false) }}
                txt={'Once you delete this, there is no going back. Link to this guestbook will no longer be functional, please be certain.'}
                /> : null}
        </div>
    </div>
}

export default Guestbookbuilder