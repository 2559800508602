import React, { useEffect, useState } from 'react'
import { Checkdevice } from '../../../../components'
import { arrowForward } from 'react-icons-kit/typicons/arrowForward'
import { Icon } from 'react-icons-kit/Icon'
import { EDIT_ICON, STAR_SMALL } from '../../../../config/constants'
import { Link } from 'react-router-dom'
import { USER_GET_DASHBOARD } from '../../../../config/api'
import { Retrieve_personal_info } from '../../../../config/commonfunctions'
import axios from 'axios'
import './UserHomeDashboard.css'
const REDIRECT_ICON = <Icon size={30} icon={arrowForward} />
const default_dboj_state = {
    'bt': ["", "", ""],                 // [ budget_id, name, budgetstr ] 
    'cl': ["", "", 0, 0],               // [ checklist_id, name, num_checked, total ]  
    'gul': ["", 0, 0, 0],               // [ id, num_accept, num_reject, num_noreply ]  
    'sc': ["", "", 0, 0, 0, ""],        // [ id, urlhandler, num_totalguests, num_seatedguests, num_nonseatedguests, passcode ]
    'web': ["", ""],                    // [ id, urlhandler ]   
    'tl': ["", "", ""]                  // [ timeline_id, urlhandler, passcode ]     
}
const dbtext = 'dashboard'.split('')

function UserHomeDashboard() {

    const { token, acct_type } = Retrieve_personal_info()
    const [open, setOpen] = useState(false)
    const [firsttime, setFirsttime] = useState(true)
    const [dbobj, setDbobj] = useState(default_dboj_state)
    const device = Checkdevice()

    const openfunc = () => { localStorage.setItem('dbo', !open); setOpen(!open) }

    useEffect(() => {
        if (firsttime) {
            setFirsttime(false)
            setOpen(localStorage.getItem('dbo') === 'true' ? true : false)
            try {
                axios({
                    method: 'POST',
                    url: USER_GET_DASHBOARD,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                }).then((res) => {
                    const { status, data } = res // true
                    const { dashboard } = data
                    if (status === 200) {
                        var counter = 0
                        var arr = Object.keys(dashboard)
                        for (let i = 0; i < arr.length; i++) {
                            if (Object.keys(default_dboj_state).indexOf(arr[i]) > -1) {
                                counter = counter + 1
                            }
                        }
                        if (counter === Object.keys(default_dboj_state).length) {
                            setDbobj(dashboard);
                        }
                        else {
                            setDbobj(default_dboj_state)
                        }
                    }
                    else setDbobj(default_dboj_state);
                }).catch((e) => setDbobj(default_dboj_state))
            }
            catch (e) { setDbobj(default_dboj_state) }
        }
    }, [firsttime])

    const sq = (id, top, mid, link1, link2) => {
        const checkid = id && id.length > 0
        const checkLink1 = link1 && link1.length > 1
        const checkLink2 = link2 && link2.length > 1
        return <div key={top} className={'uhd_comp' + device}>
            <div className="uhd_topcomp">{top}</div>
            <div className="uhd_midcomp">{mid}</div>
            <div className="uhd_btmcomp">
                {checkid && checkLink1 ? <Link className="uhd_btmcomp" to={link1} target="_blank">{EDIT_ICON}</Link> : <div className="uhd_btmcomp" style={{ visibility: 'hidden' }}>{EDIT_ICON}</div>}
                {checkid && checkLink2 ? <Link className="uhd_btmcomp" to={link2} target="_blank">{REDIRECT_ICON}</Link> : <div className="uhd_btmcomp" style={{ visibility: 'hidden' }}>{REDIRECT_ICON}</div>}
            </div>
        </div>
    }

    return open && acct_type === 'USER' // if data is orginal as stub, block viewing
        ? <div className="uhd_root" >
            <div className="uhd_closebtn" onClick={openfunc}>
                <div className="uhd_starbtnchar">{STAR_SMALL}</div>
                {dbtext.map((c, i) => <div key={c + i} className="uhd_closebtnchar">{c}</div>)}
            </div>
            <div className="uhd_col">
                <div className="uhd_row">
                    {sq(dbobj['cl'][0], 'Checklist Tasks', `${dbobj['cl'][2]}/${dbobj['cl'][3]}`, '/tools/checklistbulider/' + dbobj['cl'][0] + '/edit', null)} {/* [checklist_id, name, num_checked, total] */}
                    {sq(dbobj['bt'][0], 'Your Budget', `$${dbobj['bt'][2]}`, "/tools/budgetbuilder/" + dbobj['bt'][0] + '/edit', null)} {/* [budget_id, name, budgetstr]  */}
                    {sq(dbobj['tl'][0], 'Timeline', null, "/tools/itinerarybuilder/" + dbobj['tl'][0] + '/edit', "/timelinelookup/" + (dbobj['tl'][2].length > 0 ? (dbobj['tl'][1] + '/passcode=' + dbobj['tl'][2]) : dbobj['tl'][1]))}  {/* [timeline_id, urlhandler, passcode]   */}
                </div>
                <div className="uhd_row"> {/* gul gt the correct pc */}
                    {sq(dbobj['web'][0], 'Website', ``, "/tools/invitationbuilder/" + dbobj['web'][0] + '/edit', (dbobj['gul'][4] && dbobj['gul'][4].length > 0) ? "/invitation/" + dbobj['web'][1] + '/passcode=' + dbobj['gul']['4'] : "/invitation/" + dbobj['web'][1])} {/* [id, urlhandler, passcode] */}
                    {sq(dbobj['gul'][0], 'RSVP Accepted', `${dbobj['gul'][1]}/${dbobj['gul'][1] + dbobj['gul'][2] + dbobj['gul'][3]}`, "/tools/invitationbuilder/" + dbobj['web'][0] + '/edit/gl', (dbobj['gul'][4] && dbobj['gul'][4].length > 0) ? "/invitation/" + dbobj['web'][1] + '/passcode=' + dbobj['gul'][4] : "/invitation/" + dbobj['web'][1])}{/* [id, num_accept, num_reject, num_noreply, passcode] */}
                    {sq(dbobj['sc'][0], 'Seat Assigned', `${dbobj['sc'][3]}/${dbobj['sc'][2]}`, "/tools/seatingchartbuilder/" + dbobj['sc'][0] + '/edit', (dbobj['sc'][5] && dbobj['sc'][5].length > 0) ? ("/seatingchartlookup/" + dbobj['sc'][1] + '/passcode=' + dbobj['sc'][5]) : ("/seatingchartlookup/" + dbobj['sc'][1]))} {/* [id, urlhandler, num_totalguests, num_seatedguests, num_nonseatedguests, passcode] */}
                </div>
            </div>
        </div>
        : <div className="uhd_openbtn" onClick={openfunc}>
            <div className="uhd_starbtnchar">{STAR_SMALL}</div>
            {dbtext.map((c, i) => <div key={c + i} className="uhd_openbtnchar">{c}</div>)}
        </div>
}
export default UserHomeDashboard