import React from 'react'
import './Plancontent.css'

function Plancontent({ displaycontent, title, maintext, contentdivs, modals, mainmsg }) {
    return <div className="Plancontent_root">
        {displaycontent
            ? <div className="Plancontent_detail">
                <div className="Plancontent_head">
                    <h3>{title}</h3>
                    <div className="Plancontent_mtxt">{maintext}</div>
                </div>
                <div className="Plancontent_fields">
                    {contentdivs}
                </div>
                {modals}
            </div>
            : <div className="Plancontent_msg">{mainmsg}</div>}
    </div>
}
export default Plancontent