import React from 'react'//useEffect, useLayoutEffect 

function Blogmenu(props) {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <button style={{ display: 'flex', flexDirection: 'row', padding: 5, margin: 5, textAlign: 'center', border: 'thin solid #707070', width: 80, justifyContent: 'center', borderRadius: 5, marginBottom: 20, outline:'none', backgroundColor:'white' }} onClick={() => props.history.push('/blog')}>Blog</button>
            <button style={{ display: 'flex', flexDirection: 'row', padding: 5, margin: 5, textAlign: 'center', border: 'thin solid #707070', width: 80, justifyContent: 'center', borderRadius: 5, marginBottom: 20, outline:'none', backgroundColor:'white' }} onClick={() => props.history.push('/beyond')}>Beyond</button>
        </div>
    )
}

export default Blogmenu

