import React, { useState } from 'react'
import axios from 'axios'
import { iconwrapper } from '../../config/commonfunctions'
import { LOCK_ICON } from '../../config/svgicons'
import './Loginpasscode.css'

function Loginpasscode(props) {
    const { url, urlhandler, bgcolor, onsuccess } = props
    const [pc, setPc] = useState('')
    const [shownoti, setShownoti] = useState(false)
    const [showsubmit, setShowsubmit] = useState(true)
    const failure = () => {
        setShowsubmit(true)
        setShownoti(true)
        setTimeout(() => { setShownoti(false) }, 3000)
    }
    const onsubmit = () => {
        setShowsubmit(false)
        if (pc.length < 100) {
            try {
                let options = { method: 'POST', url: url, data: { urlhandler, passcode: pc } }
                axios(options).then((result) => {
                    const { message } = result.data
                    if (message === 'SUCCESS') { onsuccess(result.data, pc, urlhandler); }
                    else { failure() }
                }).catch((e) => {
                    failure()
                })
            }
            catch (e) { failure() }
        }
    }
    return <div className="loginpc_root">
        <div className="loginpc_subroot">
            <div className='loginpc_lockicon'>
                {iconwrapper(LOCK_ICON, { width: 50, height: 50, display: 'flex', placeContent: 'center',  placeSelf: 'center' })}
            </div>
            <div className='loginpc_title'>Login</div>

            <input className="loginpc_input" value={pc} placeholder='Passcode' onChange={(e) => { if (e.target.value.length < 100) setPc(e.target.value) }} type='password'/>
            {showsubmit ? <button className="loginpc_btn" onClick={onsubmit}>Submit</button> : <button className="loginpc_btn">Submit</button>}
            {shownoti ? <div className="loginpc_errmsg">Wrong Passcode</div> : <div className="loginpc_errmsg" />}
        </div>
    </div>
}

export default Loginpasscode