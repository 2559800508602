import React, { useEffect, useState } from 'react'
import { iconwrapper } from '../../../../../../config/commonfunctions';
import { ADD_ICON, CROSS_ICON, DRAG_ICON, THINTELEGRAM_ICON, THINWHATSAPP_ICON, THINWECHAT_ICON, THININSTAGRAM_ICON, RSVPTELEPHONE_ICON, BLOCKLARGE_ICON, } from '../../../../../../config/svgicons'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Modcfm, Portalmodal } from '../../../../../../components';
import Explain from "../Explain";

const dragsvgcss = { width: 20, height: 20, display: 'flex', placeContent: 'center', margin: 5 }
const crosssvgcss = { ...dragsvgcss, color: 'var(--mainpink)' }
const addsvgcss = { width: 23, height: 23, display: 'flex', placeContent: 'center', }
const blocksvgcss = { ...dragsvgcss, color: 'var(--subdark)' }

const TITLE = 'Contacts'
const TEXT = `Provide a communication channel for your guest.`

const DEFAULT_PAGE = 0
const SWAP_OR_EDIT_PAGE = 1

const iconcss = { width: 25, height: 25, margin: '0px 5px 0px 0px', userSelect: 'none', pointerEvents: 'none', verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden' }

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#707070' : 'white',
    padding: isDraggingOver ? 5 : 0,
});

const PARAMS_MEDIA_MAP = {
    'Telegram': {
        'icon': iconwrapper(THINTELEGRAM_ICON, iconcss),
        'who_placeholder': 'Jason',
        'val_placeholder': 'https://t.me/joinchat/skdmflksmdflkmdflkds'
    },
    'Whatsapp': {
        'icon': iconwrapper(THINWHATSAPP_ICON, iconcss),
        'who_placeholder': '',
        'val_placeholder': ''
    },
    'WeChat': {
        'icon': iconwrapper(THINWECHAT_ICON, iconcss),
        'who_placeholder': '',
        'val_placeholder': ''
    },
    'Instagram': {
        'icon': iconwrapper(THININSTAGRAM_ICON, iconcss),
        'who_placeholder': '',
        'val_placeholder': ''
    },
    'Number': {
        'icon': iconwrapper(RSVPTELEPHONE_ICON, iconcss),
        'who_placeholder': '',
        'val_placeholder': ''
    },
}

function Contacts(props) {
    const { device, partialwt, showsave, setShowsave, updateBackup, clearBackup, restoreBackup, updateContacts, contacts, saveContacts } = props

    const [firsttime, setFirsttime] = useState(true)
    const [page, setPage] = useState(DEFAULT_PAGE) // 0 : default schedule page, 1 : swapping page, 2 : create schedule page, 3 : edit page

    // Contacts obj
    const [contactsdata, setContactsdata] = useState([])

    // remove 
    const [rmvindex, setRmvindex] = useState(-1) // index to remove
    const [rmvcfm, setRmvcfm] = useState(false) // user click cfm or not cfm delete
    const [showrmvmod, setShowrmvmod] = useState(false) // toggle rmv portal to qn user

    // portal 
    const [contactsportal, setContactportal] = useState(false)

    // portal css
    const portalmidcss = (device === 'WEB' || device === 'TABLET')
        ? { position: 'fixed', top: 'calc(50% - 10px)', transform: 'translate(-50%, -50%)', width: 200, height: 'max-content', margin: '10px 0px', right: 0 }
        : { position: 'fixed', width: '100%', height: '100%', margin: 0, top: '50%' }


    useEffect(() => {
        if (firsttime) {
            setContactsdata(props.contacts.slice())
            setFirsttime(false)
            setPage(DEFAULT_PAGE)
        }
    }, [])

    useEffect(() => {
        if (!firsttime) {
            setContactsdata(props.contacts.slice())
        }
    }, [props.contacts])

    useEffect(() => {
        if (rmvcfm) {
            var newcontacts = JSON.parse(JSON.stringify(contacts))
            newcontacts.splice(rmvindex, 1)
            saveContacts(newcontacts, 'remove').then((result) => {
                if (result === 'SUCCESS') {
                    setPage(DEFAULT_PAGE)
                    setShowsave(false)
                    clearBackup()
                    setRmvcfm(false)
                }
            })
        }
    }, [rmvcfm])

    const onDragend = (result) => {
        const { source, destination } = result
        if (!destination) { return }
        else {
            if (page !== SWAP_OR_EDIT_PAGE && source.index !== destination.index) {
                setPage(SWAP_OR_EDIT_PAGE)
            }
            // else if (page === SWAP_OR_EDIT_PAGE && source.index === destination.index) {
            //     return
            // }
            if (!showsave) { updateBackup(); setShowsave(true); }
            let curr_contacts = props.contacts.slice()
            const [removed] = curr_contacts.splice(source.index, 1);
            curr_contacts.splice(destination.index, 0, removed);
            updateContacts(curr_contacts)
        }
    }

    const revert = () => {
        setShowsave(false)
        restoreBackup()
        setPage(DEFAULT_PAGE)
    }

    const create = (newcontacts, action) => {
        saveContacts(newcontacts, action).then((result) => {
            if (result === 'SUCCESS') {
                setPage(DEFAULT_PAGE)
                setShowsave(false)
                clearBackup()
            }
        })
    }

    const update = (newcontacts, action) => {
        console.log('Contacts update')
        console.log(newcontacts, action)
        saveContacts(newcontacts, action).then((result) => {
            if (result === 'SUCCESS') {
                setPage(DEFAULT_PAGE)
                setShowsave(false)
                clearBackup()
            }
        })
    }

    const remove = (i) => {
        setShowrmvmod(() => {
            setRmvindex(i)
            return true
        })
    }

    const toggleselect = () => {
        setContactportal(true)
    }

    const btmbarstyle = {
        'MOBILE': { position: 'fixed', bottom: 0, left: 0, width: '100%' },
        'TABLET': { position: 'fixed', bottom: 10, left: 0, width: partialwt - 1 },
        'WEB': { position: 'fixed', bottom: 0, left: 0, width: partialwt - 1 }
    }[device]

    const btmbar = {
        // display 
        0: <div className='iedit_swaproot' style={btmbarstyle}>
            <button className='iedit_add' onClick={toggleselect}>{iconwrapper(ADD_ICON, addsvgcss)}</button>
        </div>,
        // swap or change text 
        1: <div className='iedit_swaproot' style={btmbarstyle}>
            <button
                className='iedit_cancel'
                value={'swapcancel'}
                disabled={!showsave}
                style={{ opacity: !showsave ? 0.5 : 1 }}
                onClick={revert}>
                Revert
            </button>
            <button className='iedit_save' onClick={() => { update(contactsdata, 'update') }}>Save</button>
        </div>
    }[page]

    const portal = <Portalmodal
        portaltype="commonportal"
        open={contactsportal}
        onClose={() => setContactportal(false)}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalmidcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className='Rsvpexplorer_portal' >
            <div className='Rsvpexplorer_portaltitle'>Contacts</div>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                {Object.keys(PARAMS_MEDIA_MAP).map((mediatype, i) => {
                    const { icon } = PARAMS_MEDIA_MAP[mediatype]
                    return <button
                        className='Portalinnerelem'
                        key={'addcontactsportal' + i}
                        value={mediatype}
                        style={{ borderRadius: i === 0 ? '10px 10px 0px 0px' : i === Object.keys(PARAMS_MEDIA_MAP).length - 1 ? '0px 0px 10px 10px' : '0px' }}
                        onClick={(e) => {
                            var newcontacts = props.contacts.slice()
                            newcontacts.push({ 'contacttype': e.target.value, 'who': '', 'val': '' })
                            create(newcontacts, 'add')
                            setContactportal(false)
                        }}>
                        {icon}
                        <div className='Ieditcontact_mediatxt' style={{ width: '75%' }}>{mediatype}</div>
                    </button>
                })}
            </div>
        </div>

    </Portalmodal>

    const dnd = <div>
        <DragDropContext onDragEnd={onDragend}>
            <Droppable droppableId={'droppable'} >
                {(provided, snapshot) => (
                    <div className='Detailsdrag_root' ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                        {contactsdata.map((item, x) => {
                            const { contacttype, val, who } = item
                            const { icon, who_placeholder, val_placeholder } = PARAMS_MEDIA_MAP[contacttype]
                            const i = x
                            return <Draggable key={x + '_'} draggableId={x + 'id'} index={i}>
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <span className='Detailsdrag_iconroot'>
                                            {icon}
                                            <div style={{ paddingLeft: 5 }}>{contacttype}</div>
                                        </span>
                                        <div style={{ display: 'flex', flexDirection: 'row', height: 80, marginBottom: 15 }} key={i + 'qna'}>

                                            {/* {page !== SWAP_OR_EDIT_PAGE
                                                ? <span
                                                    className='Detailsdrag_closeicon'
                                                    style={{ borderRadius: '0px 0px 0px 10px' }}
                                                    onClick={() => remove(i)}
                                                >
                                                    {iconwrapper(CROSS_ICON, crosssvgcss)}
                                                </span>
                                                : null} */}


                                            {page !== SWAP_OR_EDIT_PAGE
                                                ? <span className='Detailsdrag_closeicon' style={{ borderRadius: '0px 0px 0px 10px' }} onClick={() => remove(i)}>{iconwrapper(CROSS_ICON, crosssvgcss)}</span>
                                                : <span className='Detailsdrag_closeicon' style={{ borderRadius: '0px 0px 0px 10px' }}>{iconwrapper(BLOCKLARGE_ICON, blocksvgcss)}</span>
                                            }

                                            <div className='Detailsdrag_col'>
                                                <input
                                                    className="Iedit_input Iedit_topinput"
                                                    value={who}
                                                    placeholder={who_placeholder}
                                                    onChange={(e) => {
                                                        e.persist();
                                                        if (!showsave) { updateBackup(); setShowsave(true); setPage(SWAP_OR_EDIT_PAGE); }
                                                        setContactsdata((contactsdata) => {
                                                            let new_contactsdata = JSON.parse(JSON.stringify(contactsdata.slice()))
                                                            new_contactsdata[i]['who'] = e.target.value
                                                            return new_contactsdata
                                                        })

                                                    }}
                                                />
                                                <input
                                                    className="Iedit_input Iedit_btminput"
                                                    value={val}
                                                    placeholder={val_placeholder}
                                                    onChange={(e) => {
                                                        e.persist();
                                                        if (!showsave) { updateBackup(); setShowsave(true); setPage(SWAP_OR_EDIT_PAGE); }
                                                        setContactsdata((contactsdata) => {
                                                            let new_contactsdata = JSON.parse(JSON.stringify(contactsdata.slice()))
                                                            new_contactsdata[i]['val'] = e.target.value
                                                            return new_contactsdata
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <span className='Detailsdrag_dragicon' {...provided.dragHandleProps}>
                                                {iconwrapper(DRAG_ICON, dragsvgcss)}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
        {btmbar}
        {portal}
    </div>

    // const maincontent = {// 0 : default schedule page, 1 : swapping page, 2 : create schedule page, 3 : edit page
    //     0: dnd,
    //     1: dnd,
    //     2: <div style={{ display: 'flex', flexDirection: 'column' }}>
    //         <div style={{ padding: 10, margin: '5px 0px 5px 0px', display: 'flex', flexDirection: 'column', width: '100%', height: '100%', border: '1px solid rgb(230, 230, 230)', borderRadius: 5 }}>

    //         </div>
    //         {btmbar}
    //     </div>,
    //     3: <div style={{ display: 'flex', flexDirection: 'column' }}>
    //         <div style={{ padding: 10, margin: '5px 0px 5px 0px', display: 'flex', flexDirection: 'column', width: '100%', height: '100%', border: '1px solid rgb(230, 230, 230)', borderRadius: 5 }}>

    //         </div>
    //         {btmbar}
    //     </div>
    // }

    return (
        <div className='Ieditcomp_div'
            style={{
                height: {
                    'WEB': 'calc(100% - 70px - 65px)',
                    'TABLET': 'calc(100% - 70px - 65px)',
                    'MOBILE': 'calc(100% - 70px)'
                }[device],
                marginBottom: {
                    'MOBILE': 65
                }[device]
            }}>
            <Explain title={TITLE} txt={TEXT} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {/* {maincontent[page]} */}
                {dnd}
            </div>
            {showrmvmod
                ? <Modcfm
                    onHide={() => { setShowrmvmod(false) }}
                    onOk={() => { setShowrmvmod(false); setRmvcfm(true); }}
                    onNo={() => { setShowrmvmod(false); setRmvcfm(false); setRmvindex(-1); }}
                    txt={'Once you delete this, there is no going back. Please be certain.'}
                />
                : null}
        </div>
    )
}

export default Contacts