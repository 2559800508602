import React, { useEffect, useState } from 'react'
import { Checkdevice, Loading, Modcfm, Noti, Portalmodal, refresh, ScreenWidth } from '../../../../components'
import {
    INVITATION_GUESTLIST_READ,
    INVITATION_GUESTLIST_ADDGUEST,
    INVITATION_GUESTLIST_RMVGUEST,
    INVITATION_GUESTLIST_EDITGUEST,
    INVITATION_GUESTLIST_IMPORTCSV,
    INVITATION_GUESTLIST_ADDCAT,
    INVITATION_GUESTLIST_ADDGRP,
    INVITATION_GUESTLIST_ADDMSG,
    INVITATION_GUESTLIST_RSVPON,
    INVITATION_GUESTLIST_RSVPOFF,
    INVITATION_GUESTLIST_CLEARALLCELLS,
    INVITATION_GUESTLIST_CLEARPARTIALCELLS,
    INVITATION_RSVPALT,
    INVITATION_SWAPSAVING,
    INVITATION_GUESTLIST_UPDATESTATUS,
    INVITATION_SETTINGUPDATE,
    USER_EDITICPASSCODE
} from '../../../../config/api'
import { WEBSITE_URL, IG_UL, cryptobool } from '../../../../config/constants' // LOOKUPUNSTYLE_ICON
import { Swapcontent, Addcatcontent, Addguestcontent, Editguestcontent, Addmsgcontent, Settingcontent, Alternativecontent, Rsvpqnexplorer, Addgrpcontent, Statistics } from './components'
import { CAT_ICON, PENCIL_ICON, TRASH_ICON, TRASHTOP_ICON, REDIRECT_ICON, EMAIL_ICON, WHATSAPP_ICON, TELEPHONE_ICON, SMS_ICON, EXPORT_ICON, SETTING_ICON, QNEXP_ICON, STATS_ICON, GRP_ICON, TEMPLATE_ICON, ALTERNATE_ICON, CATSEARCH_ICON, SEARCH_ICON, SORT_ICON, EYE_ICON, TICK_ICON, ADDGUEST20_ICON, REFRESH20_ICON, SWAP_ICON, THINGUESTLIST_ICON } from '../../../../config/svgicons'
import { iconwrapper, Retrieve_personal_info } from '../../../../config/commonfunctions'
import { CSVReader } from 'react-papaparse'
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useCallback } from 'react'
import { useMemo } from 'react'
import { useRef } from 'react'
import { createRef } from 'react'
import { isMobile, isTablet } from 'react-device-detect';
import Table from './components/Table/Table'
import axios from 'axios'
import './invitationguestlist.css'
import { describe_dom, input_dom } from './components/Commonelements/Commonelements'

const STICKY_ON = 1
const STICKY_OFF = 0
const TIMER_CONSTANT = 350
const COMMON_GUESTQNSETTINGWIDTH = { width: 200 }

let FIXED_COLUMNS, FIXED_COLUMNS_ID, FIXED_COLUMNS_ID_FOR_SEARCH, ID_COLS_MAP

if (cryptobool) {

    FIXED_COLUMNS = ['Name', 'Email', 'Contact', 'Address', 'Group', 'Category', 'Crypto Wallet Address', 'RSVP', 'Updated On']
    FIXED_COLUMNS_ID = ['1-name', '2-email', '3-contact', '4-address', '5-group', '6-category', '7-nftwalletaddress', '8-rsvp', '9-updatedon', '10-status'] // without '0-uid',
    FIXED_COLUMNS_ID_FOR_SEARCH = ['1-name', '2-email', '3-contact', '4-address', '5-group', '8-rsvp', '9-updatedon', '10-status'] // without '0-uid', '6-category', '7-nftwalletaddress',
    ID_COLS_MAP = {
        '1-name': 'Name',
        '2-email': 'Email',
        '3-contact': 'Contact',
        '4-address': 'Address',
        '5-group': 'Group',
        '6-category': 'Category',
        '7-nftwalletaddress': 'NFT Wallet address',
        '8-rsvp': 'RSVP',
        '9-updatedon': 'Updated On',
        '10-status': 'Status'
    }
}
else {

    FIXED_COLUMNS = ['Name', 'Email', 'Contact', 'Address', 'Group', 'Category', 'RSVP', 'Updated On']
    FIXED_COLUMNS_ID = ['1-name', '2-email', '3-contact', '4-address', '5-group', '6-category', '8-rsvp', '9-updatedon', '10-status'] // without '0-uid',
    FIXED_COLUMNS_ID_FOR_SEARCH = ['1-name', '2-email', '3-contact', '4-address', '5-group', '8-rsvp', '9-updatedon', '10-status'] // without '0-uid', '6-category', '7-nftwalletaddress',
    ID_COLS_MAP = {
        '1-name': 'Name',
        '2-email': 'Email',
        '3-contact': 'Contact',
        '4-address': 'Address',
        '5-group': 'Group',
        '6-category': 'Category',
        '8-rsvp': 'RSVP',
        '9-updatedon': 'Updated On',
        '10-status': 'Status'
    }
}


// const FIXED_COLUMNS =   ['Name', 'Email', 'Contact', 'Address', 'Group', 'Category', 'Crypto Wallet Address', 'RSVP', 'Updated On']
// const FIXED_COLUMNS_ID = ['1-name', '2-email', '3-contact', '4-address', '5-group', '6-category', '7-nftwalletaddress', '8-rsvp', '9-updatedon', '10-status'] // without '0-uid',
// const FIXED_COLUMNS_ID_FOR_SEARCH = ['1-name', '2-email', '3-contact', '4-address', '5-group', '8-rsvp', '9-updatedon', '10-status'] // without '0-uid', '6-category', '7-nftwalletaddress',
// const ID_COLS_MAP = {
//     '1-name': 'Name',
//     '2-email': 'Email',
//     '3-contact': 'Contact',
//     '4-address': 'Address',
//     '5-group': 'Group',
//     '6-category': 'Category',
//     '7-nftwalletaddress': 'NFT Wallet address',
//     '8-rsvp': 'RSVP',
//     '9-updatedon': 'Updated On',
//     '10-status': 'Status'
// }

const FIXED_LEFT = ['1-name', 'checkbox_uid', 'index_uid']
const RSVP_NOT_ACTIVE = 'Please "Publish" your guestlist before sending invitation out to your guests.'
const INCORRECT_DEVICE = 'Please use mobile phone to access this function.'
const NO_CONTACT_FOUND = 'No Contact Found.'
const NO_EMAIL_FOUND = 'No Email Found.'
const FAIL_TO_UPDATE_STATUS = 'Failed to update status. Please try again later.'
const FAIL_TO_DELETE_GUEST = 'Failed to guest. Please try again later.'

const CELLICON_CSS = { width: 20, height: 20, display: 'flex' }
const GENERALICON_CSS = { width: 24, height: 24, display: 'flex' }
const THICKICON_CSS = { width: 24, height: 24, margin: '0px 5px 0px 0px', display: 'flex' }
const SORTICON_CSS = { width: 8, display: 'flex' }
const CATSEARCHICON_CSS = { width: 20, height: 20, display: 'flex' }

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)) // eslint-disable-line
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

function Invsearch(props) {

    const [search, setSearch] = useState('')
    const DEVICE = Checkdevice()

    useEffect(() => {
        const { updatesearch } = props
        updatesearch(search)
    }, [search])

    useEffect(() => {
        setSearch('')
    }, [props.searchcat])

    return <div className='ig_searchroot'>
        <button className='ig_searchitem ig_searchitemmedia' onClick={props.opensearchfiltermodal}>{ID_COLS_MAP[props.searchcat]}</button>
        {DEVICE === 'MOBILE' ? null : <div className='ig_searchicon ig_searchiconmedia' >{iconwrapper(SEARCH_ICON, GENERALICON_CSS)}</div>}
        <div className='ig_searchcontent ig_searchcontentmedia'>
            <input className='ig_searchinput ig_searchinputmedia' placeholder={`${ID_COLS_MAP[props.searchcat]}`} value={search} onChange={(e) => { setSearch(e.target.value) }} />
        </div>
        <button className='ig_caticon' onClick={props.opensearchcatmodal}>
            <div className='ig_catselected'>{props.searchcatselected.length}</div>
            <div className='ig_caticonicon'>
                {iconwrapper(CATSEARCH_ICON, CATSEARCHICON_CSS)}
            </div>
        </button>
    </div>
}

function Invitationguestlist(props) {

    const DEVICE = Checkdevice()
    const { token } = Retrieve_personal_info()
    const [guestlistreadcounter, setGuestlistreadcounter] = useState(-1)

    const [rsvpactive, setRsvpactive] = useState(false) // if is true, means the rsvp page already published will not be able edit

    const [invitationid, setInvitationid] = useState('')
    const [guestid_guestinfo_map, setGuestid_guestinfo_map] = useState({})
    const [initialguestids, setInitialguestids] = useState([])
    const [guestids, setGuestids] = useState([])
    const [guestqnsmap, setGuestqnsmap] = useState([])// id - column name map
    const [allguestqnsuids, setAllguestqnsuids] = useState([]) // default + user's questions
    const [guestqnsuids, setGuestqnsuids] = useState([]) // only user's create questions
    const [guestexcelattrs, setGuestexcelattrs] = useState([]) // default + user's questions + actions
    const [exportcsv, setExportcsv] = useState([]) // csv file, will have items if user click on the button, data is accumulate after clicking
    const [guestcats, setGuestcats] = useState([])
    const [guestgrps, setGuestgrps] = useState([])
    const [grp_guestids_map, setGrp_guestids_map] = useState({})
    const [guestqns, setGuestqns] = useState([])
    const [guestqnsswapped, setGuestqnsswapped] = useState(false) // for rsvpqnexplorer swap
    const [oldexpandablestates, setOldexpandablestates] = useState([]) // for rsvpqnexplorer swap
    const [oldguestqns, setOldguestqns] = useState([]) // for rsvpqnexplorer swap
    const [passcode, setPasscode] = useState('')
    const [newpasscode, setNewpasscode] = useState('')
    const [urlhandler, setUrlhandler] = useState('')
    const [loading, setLoading] = useState(false)
    const [rendertable, setRendertable] = useState(false)
    const [tableloaded, setTableloaded] = useState(false)
    const [itype, setItype] = useState('Wedding') // Wedding, Birthday Party, House Warming, Baby Shower, Postponement

    const [mainpage, setMainpage] = useState('GUESTLIST')// GUESTLIST, QUESTIONEXPLORER, STATS

    // add qn modal
    const [addqnmodalloading, setAddqnmodalloading] = useState(false)

    // add new guest modal
    const [addmodal, setAddmodal] = useState(false)
    const [addstate, setAddstate] = useState('IDLE')// IDLE, SAVING, SUCCESS, FAILURE

    // edit existing guest modal
    const [editmodal, setEditmodal] = useState(false)
    const [editdata, setEditdata] = useState({})
    const [edituuid, setEdituuid] = useState('')
    const [editstate, setEditstate] = useState('IDLE')// IDLE, SAVING, SUCCESS, FAILURE

    // add cat modal
    const [addcatmodal, setAddcatmodal] = useState(false)
    const [addcatmodalguestcats, setAddcatmodalguestcats] = useState([])
    const [addcatsavingstate, setAddcatsavingstate] = useState('IDLE')// IDLE, SAVING, SUCCESS, FAILURE

    // add group modal
    const [addgrpmodal, setAddgrpmodal] = useState(false)
    const [addgrpmodalguestgrps, setAddgrpmodalguestgrps] = useState([])
    const [addgrpsavingstate, setAddgrpsavingstate] = useState('IDLE')// IDLE, SAVING, SUCCESS, FAILURE

    const [addmsgmodal, setAddmsgmodal] = useState(false)
    const [addmsg, setAddmsg] = useState([]) // ['subject', 'email or whatsapp text']
    const [addmsgstate, setAddmsgstate] = useState('IDLE')// IDLE, SAVING, SUCCESS, FAILURE

    // text modal
    const [shortcutmodal, setShortcutmodal] = useState(false)
    const [shortcutdata, setShortcutdata] = useState('')

    // alternative modal
    const [alternativemodal, setAlternativemodal] = useState(false)
    const [altstate, setAltstate] = useState('IDLE')

    // delete modal
    const [showmoddelete, setShowmoddelete] = useState(false)

    // swap modal
    const [swapmodal, setSwapmodal] = useState(false)
    const [swapmsgstate, setSwapmsgstate] = useState('IDLE')// IDLE, SAVING, SUCCESS, FAILURE

    // settings modal
    const [settingmodal, setSettingmodal] = useState(false)
    const [guestlistsettings, setGuestlistsettings] = useState({})
    const [settingsavingstate, setSettingsavingstate] = useState('IDLE')// IDLE, SAVING, SUCCESS, FAILURE

    // sorting
    const [sorting, setSorting] = useState({ title: 0, type: 'ascending' }) // default state is empty, else is key FIXED_COLUMNS_ID + RSVP QN IDS

    // allow qn to be editable for the following : 1-name, 2-email, 3-contact, 4-address, 7-nftwalletaddress
    const [guestid_defaultqnstate_map, setGuestid_defaultqnstate_map] = useState({})

    const [rsvpalt, setRsvpalt] = useState(['', '']) // first is google url
    const [rsvptype, setRsvptype] = useState(null) // rsvp type

    // shared between tables
    // const [selectedidsmap, setSelectedidsmap] = useState([]) // is updated in CLEARALL_DIV onClick
    const [selectedidsmap, setSelectedidsmap] = useState({}) // is updated in CLEARALL_DIV onClick
    const [columns, setColumns] = useState([])

    // for default table
    const [rows, setRows] = useState([])

    // update table draggable column width
    const [guestqnssettingsmap, setGuestqnssettingsmap] = useState({})

    // will only be use if search is activate 
    const [searchactivate, setSearchactivate] = useState(false)
    const [searchrows, setSearchrows] = useState([])
    const [searchcat, setSearchcat] = useState('1-name')
    const [searchtxt, setSearchtxt] = useState('')

    const [searchfiltermodal, setSearchfiltermodal] = useState(false)
    const [searchcatmodal, setSearchcatmodal] = useState(false) // modal for category search
    const [searchcatselected, setSearchcatselected] = useState([]) // search category selected
    const [rsvpqnexplorerexpandablestates, setRsvpqnexplorerexpandablestates] = useState([]) // will have data only after Rsvpqnportal "save" action activated
    const [checkboxmodal, setCheckboxmodal] = useState(false)

    // all the portal modal
    const [AddnewguestModal, setAddnewguestModal] = useState(null)
    const [EditexistingguestModal, setEditexistguestmodal] = useState(null)
    const [AddcatModal, setAddcatModal] = useState(null)
    const [AddgrpModal, setAddgrpModal] = useState(null)
    const [AddmsgModal, setAddmsgModal] = useState(null)
    const [ShortcutModal, setShortcutModal] = useState(null)
    const [AlternativeModal, setAlternativeModal] = useState(null)
    const [ModdeleteModal, setModdeleteModal] = useState(null)
    const [SearchfilterModal, setSearchfilterModal] = useState(null)
    const [SearchcatModal, setSearchcatModal] = useState(null)
    const [SettingModal, setSettingModal] = useState(null)
    const [SwapModal, setSwapModal] = useState(null)

    const [elRefs, setElRefs] = useState([]);
    const [maincheck, setMaincheck] = useState(true)
    const [mainclicked, setMainclicked] = useState(false)
    const [checkboxidselected, setCheckboxidselected] = useState(null)
    const [checkboxesticked, setCheckboxesticked] = useState(0)

    // contact icon clicked, but device not apporiate
    const [showstatuspopup, setShowstatuspopup] = useState(false)
    const [statuspopupdata, setStatuspopupdata] = useState({})
    const [statuscommunicationtool, setStatuscommunicationtool] = useState('')
    const [statusguestid, setStatusguestid] = useState('')
    const [statusguestname, setStatusguestname] = useState('')
    // for mobile scroll back, related to status
    const [rendercounter, setRendercounter] = useState(0)
    const [scrollposition, setScrollposition] = useState({ x: 0, y: 0 });

    // general popup
    const [showgeneralpopup, setShowgeneralpopup] = useState(false)
    const [showgeneralpopupcontent, setShowgeneralpopupcontent] = useState('')

    // guests table
    const [actualtable, setActualtable] = useState(null)

    // show delete modcfm
    const [showdeletepopup, setShowdeletepopup] = useState(false)
    const [showdeletepopupcontent, setShowdeletepopupcontent] = useState('')
    const [showdeletepopupguestid, setShowdeletepopupguestid] = useState('')

    // passcode modcfm
    const [passcodemodal, setPasscodemodal] = useState(false)
    const [popupmsg, setPopupmsg] = useState('')

    const wt = ScreenWidth()

    const csvLink = useRef()
    const mainref = useRef(null)

    const portalleftcss = { width: { 'WEB': 500, 'TABLET': 400, 'MOBILE': '100%' }[DEVICE], height: '100%', backgroundColor: 'white', top: 0, left: null, right: 0, transform: 'none' }

    const portalmidcss = (DEVICE === 'WEB' || DEVICE === 'TABLET')
        ? { position: 'fixed', top: 'calc(50% - 10px)', transform: 'translate(-50%, -50%)', width: 300, height: 'max-content', margin: '10px 0px', right: 0 }
        : { position: 'fixed', width: '100%', height: '100%', margin: 0, top: '50%' }

    useEffect(() => { // add or remove refs
        setElRefs((elRefs) => Array(guestids.length).fill().map((_, i) => elRefs[i] || createRef()));
        for (let i = 0; i < elRefs.length; i++) {
            if (elRefs[i] && elRefs[i].current && elRefs[i].current.checked) {
                elRefs[i].current.click()
            }
        }
    }, [guestids.length]);

    function dissectInit(item) {
        const { guestid_guestinfo_map = {}, guestids, allguestqnsuids, guestqnsuids, guestcats, guestgrps, grp_guestids_map = {}, guestqns, guestqnsmap, passcode, rsvpactive, _id, urlhandler, addmsg, rsvpalt, rsvptype, guestqnssettingsmap, guestlistreadcounter, guestid_defaultqnstate_map, itype, guestlistsettings = {} } = item.data.gd

        setGuestlistreadcounter(guestlistreadcounter)
        setGuestqnssettingsmap(guestqnssettingsmap) // settings of the table
        setGuestid_defaultqnstate_map(guestid_defaultqnstate_map)

        setGuestid_guestinfo_map(guestid_guestinfo_map) // default is undefined, need to let this know its a map
        setInitialguestids(guestids) // original id sequence of guestids, delete and add will also be updated here
        setGuestids(guestids)

        var selectedidsmap = {}
        for (let i = 0; i < guestids.length; i++) {
            selectedidsmap[guestids[i]] = false
        }
        setSelectedidsmap(selectedidsmap)

        var excelattrs = []
        for (let i = 1; i < allguestqnsuids.length; i++) {
            excelattrs.push(guestqnsmap[allguestqnsuids[i]])
        }
        // setGuestexcelattrs([...excelattrs, 'Send', 'Actions']) // combine of default allguestqnsuids and user's qns
        setGuestexcelattrs(excelattrs) // combine of default allguestqnsuids and user's qns

        setAllguestqnsuids(allguestqnsuids)
        setGuestqnsuids(guestqnsuids)
        setGuestqnsmap(guestqnsmap)

        setAddcatmodalguestcats(guestcats) // a replica of guestcats
        setGuestcats(guestcats)

        setAddgrpmodalguestgrps(guestgrps) // a replica of guestcats
        setGuestgrps(guestgrps)
        setGrp_guestids_map(grp_guestids_map)

        setGuestqns(guestqns)
        setPasscode(passcode)
        setAddmsg(addmsg)
        setRsvpactive(rsvpactive)
        props.updateRsvpactive(rsvpactive) // update invitationedit rsvpactive
        setUrlhandler(urlhandler)
        setInvitationid(_id)
        setRsvpalt(rsvpalt)
        setRsvptype(rsvptype)
        setGrp_guestids_map(grp_guestids_map)
        setGuestlistsettings(guestlistsettings)
        setItype(itype)

    }
    const init = () => {
        try {
            const { id } = props.match.params

            let options = {
                method: 'POST',
                url: INVITATION_GUESTLIST_READ,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { id }
            }
            axios(options).then((item) => {

                dissectInit(item)
                setTimeout(() => {
                    setLoading(false)
                    setRendertable(true)
                }, TIMER_CONSTANT)

            }).catch((e) => {
                // props.history.push('/')
            })
        }
        catch (e) {
            // props.history.push('/')
        }
    }

    useEffect(() => {
        setLoading(true)
        init()
    }, [])

    useEffect(() => {
        if (guestids.length > 0 && allguestqnsuids.length > 0 && Object.keys(guestqnsmap).length > 0 && Object.keys(guestid_guestinfo_map).length > 0 && Object.keys(guestid_guestinfo_map).length === guestids.length) {
            var exportcsv = []
            var titles = []
            for (let i = 0; i < allguestqnsuids.length; i++) {
                titles.push(guestqnsmap[allguestqnsuids[i]])
            }
            exportcsv.push(titles) // first line of excel, are titles.
            for (let x = 0; x < guestids.length; x++) {
                var item = []
                var guestid = guestids[x]
                for (let y = 0; y < allguestqnsuids.length; y++) {
                    if (y === 0) {
                        item.push(guestid)
                    }
                    else {
                        item.push(guestid_guestinfo_map[guestid][allguestqnsuids[y]])
                    }
                }
                exportcsv.push(item)
            }
            setExportcsv(exportcsv)
        }
    }, [guestids, allguestqnsuids, guestqnsmap, guestid_guestinfo_map])


    useEffect(() => {
        if (mainclicked) {
            var selectedidsmap = {}
            var indexes = [] // obtain the guestids index of every row within searchrows 
            if (searchactivate) {

                for (let i = 0; i < searchrows.length; i++) {
                    const { guestid } = searchrows[i]
                    indexes.push(guestids.indexOf(guestid))
                }

                if (maincheck) {
                    for (let x = 0; x < indexes.length; x++) {
                        const i = indexes[x]
                        if (elRefs[i] && elRefs[i].current && !elRefs[i].current.checked) {
                            elRefs[i].current.click()
                        }
                        selectedidsmap[guestids[i]] = true
                    }
                    setCheckboxesticked(parseInt(indexes.length))
                }
                else {
                    for (let x = 0; x < indexes.length; x++) {
                        const i = indexes[x]
                        if (elRefs[i] && elRefs[i].current && elRefs[i].current.checked) {
                            elRefs[i].current.click()
                        }
                        selectedidsmap[guestids[i]] = false
                    }
                    setCheckboxesticked(0)
                }

            }
            else {

                for (let i = 0; i < rows.length; i++) {
                    const { guestid } = rows[i]
                    indexes.push(guestids.indexOf(guestid))
                }

                if (maincheck) { // check true
                    for (let x = 0; x < indexes.length; x++) {
                        const i = indexes[x]
                        if (elRefs[i] && elRefs[i].current && !elRefs[i].current.checked) {
                            elRefs[i].current.click()
                        }
                        selectedidsmap[guestids[i]] = true
                    }
                    setCheckboxesticked(parseInt(indexes.length))
                }
                else { // check false
                    for (let x = 0; x < elRefs.length; x++) {
                        const i = indexes[x]
                        if (elRefs[i] && elRefs[i].current && elRefs[i].current.checked) {
                            elRefs[i].current.click()
                        }
                        selectedidsmap[guestids[i]] = false
                    }
                    setCheckboxesticked(0)
                }
            }

            setSelectedidsmap(selectedidsmap)
            setMaincheck(!maincheck)
            setMainclicked(false)
        }
    }, [mainclicked])


    var value_checked_count = () => {
        var positive_count = Object.values(selectedidsmap).filter((val) => {
            if (val) { return val }
        }).length
        return positive_count
    }

    var ref_checked_count = () => {
        var positive_count = 0
        var checked_ids = []
        for (let i = 0; i < elRefs.length; i++) {
            if (elRefs[i] && elRefs[i].current && elRefs[i].current.checked) {
                checked_ids.push(elRefs[i].current.value)
                positive_count++;
            }
        }
        return { count: positive_count, checked_ids: checked_ids }
    }

    useEffect(() => {
        if (mainclicked === false && checkboxidselected && checkboxidselected.length > 0) {

            setSelectedidsmap((selectedidsmap) => {
                let local_selectedidsmap = JSON.parse(JSON.stringify(selectedidsmap))
                const { count, checked_ids } = ref_checked_count()
                setCheckboxesticked(count)
                setCheckboxidselected(null)

                let allids = Object.keys(local_selectedidsmap)
                for (let i = 0; i < allids.length; i++) {
                    local_selectedidsmap[allids[i]] = false
                }

                for (let i = 0; i < checked_ids.length; i++) {
                    local_selectedidsmap[checked_ids[i]] = true
                }

                return local_selectedidsmap
            })
            // number of checkbox clicked, NOT ALL but less than MAX number of checkboxes
        }
    }, [checkboxidselected])

    useEffect(() => {
        if (rendertable) {

            const colfixed = guestlistsettings['sticky'] === STICKY_ON ? STICKY_ON : STICKY_OFF

            var colid_colname_map = {}

            const updatesearchdata = (new_sorting) => {
                setSearchactivate((searchactivate) => {
                    setSearchtxt((searchtxt) => {
                        setSearchcatselected((searchcatselected) => {
                            updatesearchtabledata(searchtxt, searchcatselected, new_sorting)
                            return searchcatselected
                        })
                        return searchtxt
                    })
                    return searchactivate
                })
            }

            const sorticonstate = (title) => {
                if (sorting['title'] === title) {
                    return iconwrapper(SORT_ICON, SORTICON_CSS)
                }
            }

            const headeronclick = () => {
                setSorting((prevsort) => {
                    const { title, type } = prevsort
                    var new_sorting
                    if (title === 0 && type === 'ascending') {
                        new_sorting = { title: 0, type: 'descending' }
                    }
                    else if (title === 0 && type === 'descending') {
                        new_sorting = { title: 0, type: 'ascending' }
                    }
                    else {
                        new_sorting = { title: 0, type: 'descending' }
                    }
                    updatesearchdata(new_sorting)
                    return new_sorting
                })
                setRendertable(true)
            }

            const col_checkbox = {
                Header: <div className='iglheadercheckboxroot' key={maincheck + '_maincheck'}>
                    <input className='iglheadercheckboxinput' ref={mainref} type='checkbox' onChange={() => setMainclicked(true)} />
                </div>,
                accessor: 'checkbox',
                sticky: (colfixed && FIXED_LEFT.includes('index_uid')) ? 'left' : '',
                width: 50
            }

            const col_index = {
                Header: <div className='iglcolumnroot' onClick={headeronclick} ><div>#</div>
                    <button className='iglcolumnsortbtn'>{sorticonstate(0)}</button>
                </div>,
                accessor: 'index_uid',
                sticky: (colfixed && FIXED_LEFT.includes('index_uid')) ? 'left' : '',
                width: guestqnssettingsmap['index_uid'].width
            }

            let cols = [col_checkbox, col_index]// set column

            // add default qns to first row
            for (let j = 0; j < FIXED_COLUMNS.length; j++) {

                // if (FIXED_COLUMNS_ID[j] === '8-rsvp' || FIXED_COLUMNS_ID[j] === '7-nftwalletaddress') {
                if (FIXED_COLUMNS_ID[j] === '8-rsvp') {
                    continue
                }

                let colname = FIXED_COLUMNS[j]
                let qnid = FIXED_COLUMNS_ID[j]

                const colclick = () => {
                    setSorting((prevsort) => {
                        // state1 1                             <=> state 2
                        // { title: 0, type: 'ascending' } <=> { title: 0, type: 'descending' }
                        const { title, type } = prevsort
                        var new_sorting
                        if (j === 7) { // only rsvp
                            // accept decline noreplies - decline accept noreplies - noreplies accept decline - default
                            var new_sorting
                            if (title === j + 1 && type === 'accept decline noreplies') {
                                new_sorting = { title: j + 1, type: 'decline accept noreplies' }
                            }
                            else if (title === j + 1 && type === 'decline accept noreplies') {
                                new_sorting = { title: j + 1, type: 'noreplies accept decline' }
                            }
                            else if (title === j + 1 && type === 'noreplies accept decline') {
                                new_sorting = { title: j + 1, type: 'default' }
                            }
                            else {
                                new_sorting = { title: j + 1, type: 'accept decline noreplies' }
                            }
                        }
                        else { // all others 
                            if (title === j + 1 && type === 'ascending') {
                                new_sorting = { title: j + 1, type: 'descending' }
                            }
                            else if (title === j + 1 && type === 'descending') {
                                new_sorting = { title: j + 1, type: 'default' }
                            }
                            else {
                                new_sorting = { title: j + 1, type: 'ascending' }
                            }
                        }

                        updatesearchdata(new_sorting)
                        return new_sorting
                    })
                    setRendertable(true)
                }

                cols.push({
                    Header: <div className='iglcolumnroot' onClick={colclick}>
                        <div>{colname}</div>
                        <button className='iglcolumnsortbtn'>{sorticonstate(j + 1)}</button>
                    </div>,
                    accessor: qnid,
                    sticky: (colfixed && FIXED_LEFT.includes(qnid)) ? 'left' : '', // if columns is fixed 
                    width: guestqnssettingsmap[qnid].width
                })
                colid_colname_map[qnid] = colname
            }


            // add user defined qns to first row
            for (let i = 0; i < guestqns.length; i++) {

                const qn = guestqns[i]
                const colname = guestqns[i][2]
                const qntype = guestqns[i][1]
                const qnid = guestqns[i][7]

                const mainqnonclick = () => {
                    setSorting((prevsort) => {
                        // state1 1                             <=> state 2
                        // { title: 0, type: 'ascending' } <=> { title: 0, type: 'descending' }
                        const { title, type } = prevsort
                        var new_sorting
                        if (title === qnid && type === 'ascending') {
                            new_sorting = { title: qnid, type: 'descending' }
                        }
                        else if (title === qnid && type === 'descending') {
                            new_sorting = { title: qnid, type: 'default' }
                        }
                        else {
                            new_sorting = { title: qnid, type: 'ascending' }
                        }
                        updatesearchdata(new_sorting)
                        return new_sorting
                    })
                    setRendertable(true)
                }

                cols.push({
                    Header: <div className='iglcolumnroot' onClick={mainqnonclick}>
                        <div>{colname}</div>
                        <button className='iglcolumnsortbtn'> {sorticonstate(qnid)} </button>
                    </div>,
                    accessor: qnid,
                    sticky: "",
                    width: guestqnssettingsmap[qnid].width
                })

                colid_colname_map[qnid] = colname

                // follow up qns columns
                var followuparr = []
                if (qntype === 0) { // if qn is multiple choice
                    const folup_colnames = (qn, new_colid_colname_map) => {
                        // only multiple choice qn will enter this function and obtain options
                        let cols = []

                        for (let i = 0; i < qn[8].length; i++) { // loop all options
                            if (qn[8][i][1].length > 0) { // check if option gt followup qns
                                for (let j = 0; j < qn[8][i][1].length; j++) { // loop all followup qns
                                    // obtain all column name
                                    var colname = qn[8][i][1][j][2]
                                    var followupqnid = qn[8][i][1][j][3]

                                    const followupqnonclick = () => {
                                        setSorting((prevsort) => {
                                            const { title, type } = prevsort
                                            var new_sorting
                                            if (title === followupqnid && type === 'ascending') {
                                                new_sorting = { title: followupqnid, type: 'descending' }
                                            }
                                            else if (title === followupqnid && type === 'descending') {
                                                new_sorting = { title: followupqnid, type: 'default' }
                                            }
                                            else {
                                                new_sorting = { title: followupqnid, type: 'ascending' }
                                            }
                                            updatesearchdata(new_sorting)
                                            return new_sorting
                                        })
                                        setRendertable(true)
                                    }

                                    cols.push({
                                        Header: <div className='iglcolumnroot'>
                                            <div>{colname}</div>
                                            <button className='iglcolumnsortbtn' onClick={followupqnonclick} >
                                                {sorticonstate(followupqnid)}
                                            </button>
                                        </div>,
                                        accessor: followupqnid,
                                        sticky: "",
                                        width: 200
                                    })
                                    new_colid_colname_map[followupqnid] = colname
                                }
                            }
                        }
                        return { cols, new_colid_colname_map }
                    }
                    const { cols, new_colid_colname_map } = folup_colnames(qn, colid_colname_map)
                    followuparr = cols
                    colid_colname_map = new_colid_colname_map
                }

                cols = [...cols, ...followuparr]

            }

            const statusonclick = () => { // pikachu
                setSorting((prevsort) => {
                    const { title, type } = prevsort
                    var new_sorting
                    if (title === 10 && type === '') {
                        new_sorting = { title: 10, type: 'ascending' }
                    }
                    else if (title === 10 && type === 'ascending') {
                        new_sorting = { title: 10, type: 'descending' }
                    }
                    else {
                        new_sorting = { title: 10, type: '' }
                    }
                    updatesearchdata(new_sorting)
                    return new_sorting
                })
                setRendertable(true)
            }

            const rsvponclick = () => {
                setSorting((prevsort) => {
                    const j = 7
                    // state1 1                             <=> state 2
                    // { title: 0, type: 'ascending' } <=> { title: 0, type: 'descending' }
                    const { title, type } = prevsort

                    var new_sorting
                    // accept decline noreplies - decline accept noreplies - noreplies accept decline - default
                    if (title === j + 1 && type === 'accept decline noreplies') {
                        new_sorting = { title: j + 1, type: 'decline accept noreplies' }
                    }
                    else if (title === j + 1 && type === 'decline accept noreplies') {
                        new_sorting = { title: j + 1, type: 'noreplies accept decline' }
                    }
                    else if (title === j + 1 && type === 'noreplies accept decline') {
                        new_sorting = { title: j + 1, type: 'default' }
                    }
                    else {
                        new_sorting = { title: j + 1, type: 'accept decline noreplies' }
                    }
                    updatesearchdata(new_sorting)
                    return new_sorting
                })
                setRendertable(true)
            }

            // add rsvp
            cols.push({ Header: <div className='iglcolumncommonheader' onClick={rsvponclick}>RSVP</div>, accessor: '8-rsvp', sticky: isMobile ? "" : colfixed ? 'right' : "", width: guestqnssettingsmap['8-rsvp'].width })

            // add status
            cols.push({ Header: <div className='iglcolumncommonheader' onClick={statusonclick}>Status</div>, accessor: '10-status', sticky: isMobile ? "" : colfixed ? 'right' : "", width: guestqnssettingsmap['10-status'].width })

            // add contact to first row 
            cols.push({ Header: <div className='iglcolumncommonheader'>Contact / Send</div>, accessor: 'secondlast-action', sticky: isMobile ? "" : colfixed ? 'right' : "", width: guestqnssettingsmap['secondlast-action'].width })

            // add action to first row 
            cols.push({ Header: <div className='iglcolumncommonheader'>Action</div>, accessor: 'last-action', sticky: isMobile ? "" : colfixed ? 'right' : "", width: guestqnssettingsmap['last-action'].width })


            var sorted_guestids = runsort(sorting) // return array of pairs where : [ [] ]
            let rows = []

            for (let i = 0; i < sorted_guestids.length; i++) {
                const { id, index } = sorted_guestids[i]
                rows.push(genrow(id, index))
            }

            setRendertable(false)
            setTableloaded(true)
            setColumns(cols)
            setRows(rows)
        }

    }, [rendertable])

    useEffect(() => { // pikachu
        try {
            if ((isMobile || isTablet) && rendercounter > 0) {
                const root = document.getElementsByClassName('Table_bodywrapper')[0]
                const { x, y } = scrollposition
                root.scrollTo(x, y)
            }
        }
        catch (e) {
        }
    }, [rendercounter])


    useCallback(() => {
        setCheckboxmodal(false)
    }, [checkboxmodal])

    useMemo(() => {
        setAddnewguestModal(<Portalmodal
            animation="right_left"
            portaltype='commonportal'
            open={addmodal && !rsvpactive}
            onClose={() => { setAddmodal(false); setAddstate('IDLE'); }}
            floatingclose={false}
            backdropvisible={true}
            modalstyle={portalleftcss}
            closestyle={{ right: 0 }}
            floatingclosecolor='#333333'
        >
            <Addguestcontent
                addstate={addstate}
                guestcats={guestcats}
                guestgrps={guestgrps}
                guestqns={guestqns}
                guestqnsuids={guestqnsuids}
                allguestqnsuids={allguestqnsuids}
                update={(data, grpldr, defaultqn_state_map) => {
                    axios({
                        method: 'POST',
                        url: INVITATION_GUESTLIST_ADDGUEST,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                        data: { _id: invitationid, data, guestlistreadcounter, grpldr, defaultqn_state_map }
                    }).then((result) => {
                        setAddstate('SAVING')
                        setTimeout(() => {
                            const { message, date, uuid } = result.data

                            if (message === 'SUCCESS') {
                                var new_data = data
                                new_data['9-updatedon'] = date
                                guestid_guestinfo_map[uuid] = new_data
                                setGuestid_guestinfo_map(guestid_guestinfo_map)

                                var new_guestqnssettingsmap = guestqnssettingsmap
                                new_guestqnssettingsmap[uuid] = COMMON_GUESTQNSETTINGWIDTH
                                setGuestqnssettingsmap(new_guestqnssettingsmap)

                                // var new_guestid_guestinfo_map = Object.assign({}, guestid_guestinfo_map)
                                // new_guestid_guestinfo_map[uuid] = defaultqn_state_map
                                // setGuestid_defaultqnstate_map(new_guestid_guestinfo_map)
                                setGuestid_defaultqnstate_map((new_defaultqn_state_map) => {
                                    new_defaultqn_state_map[uuid] = defaultqn_state_map
                                    return new_defaultqn_state_map
                                })

                                setGrp_guestids_map((grp_guestids_map) => {
                                    if (data['5-group'].length > 0 && grpldr) {
                                        grp_guestids_map[data['5-group']].unshift(uuid)
                                    }
                                    else if (data['5-group'].length > 0 && !grpldr) {
                                        grp_guestids_map[data['5-group']].push(uuid)
                                    }
                                    return grp_guestids_map
                                })

                                setGuestids([...guestids, uuid])
                                setInitialguestids([...guestids, uuid])
                                setRendertable(true)
                                setAddstate('SUCCESS')

                            }
                            else setAddstate('FAILURE')
                        }, 500)

                    }).catch((e) => { setAddstate('IDLE') })
                }}
                returnpage={() => {
                    setAddstate('IDLE')
                }}
            />
        </Portalmodal>)
    }, [addmodal, addstate, rsvpactive])

    useMemo(() => {
        setEditexistguestmodal(<Portalmodal
            animation="right_left"
            portaltype="commonportal"
            open={editmodal}
            onClose={() => {
                if (editstate === 'IDLE' || editstate === 'FAILURE' || editstate === 'SUCCESS') {
                    // clearAlltimeout()
                    setEditstate('IDLE')
                    setEditmodal(false)
                    setEditdata({})
                    setEdituuid('')
                }
            }}
            floatingclose={false}
            backdropvisible={true}
            modalstyle={portalleftcss}
            closestyle={{ right: 0 }}
            floatingclosecolor='#333333'
        >
            <Editguestcontent
                grp_guestids_map={grp_guestids_map}
                edituuid={edituuid}
                rsvpactive={rsvpactive}
                data={editdata}
                FIXED_COLUMNS_ID={FIXED_COLUMNS_ID}
                editstate={editstate}
                guestcats={guestcats}
                guestqns={guestqns}
                guestgrps={guestgrps}
                guestqnsuids={guestqnsuids}
                allguestqnsuids={allguestqnsuids}
                guestid_defaultqnstate_map={guestid_defaultqnstate_map}
                next={() => {
                    let nextindex = guestids.indexOf(edituuid) + 1
                    if (nextindex > guestids.length - 1) {
                        nextindex = 0
                    }
                    const nextid = guestids[nextindex]
                    const data = guestid_guestinfo_map[nextid]

                    setEdituuid(nextid)
                    setEditdata({ ...data, index_uid: nextindex + 1 })
                    setEditstate('IDLE')
                }}
                back={() => {
                    let previndex = guestids.indexOf(edituuid) - 1
                    if (previndex === -1) {
                        previndex = guestids.length - 1
                    }
                    const previd = guestids[previndex]
                    const data = guestid_guestinfo_map[previd]
                    setEdituuid(previd)
                    setEditdata({ ...data, index_uid: previndex + 1 })
                    setEditstate('IDLE')
                }}
                update={(newdata, initrsvpstate, new_grpldr, defaultqn_state_map) => {
                    let dataobj
                    let data = Object.assign({}, newdata)

                    var new_grp_guestids_map = Object.assign({}, grp_guestids_map)
                    var old_groupstate = guestid_guestinfo_map[edituuid]['5-group']
                    var old_grpldr = grp_guestids_map[old_groupstate] ? grp_guestids_map[old_groupstate].indexOf(edituuid) === 0 : false
                    var new_groupstate = data['5-group'] // string

                    if (
                        (new_groupstate !== old_groupstate && old_grpldr === new_grpldr) ||
                        (new_groupstate !== old_groupstate && old_grpldr !== new_grpldr)
                    ) {
                        if (old_groupstate !== '') { new_grp_guestids_map[old_groupstate] = new_grp_guestids_map[old_groupstate].filter(item => item !== edituuid); }
                        if (new_grpldr) { new_grp_guestids_map[new_groupstate].unshift(edituuid); }
                        else { new_grp_guestids_map[new_groupstate].push(edituuid) }
                    }
                    else if (new_groupstate === old_groupstate && old_grpldr !== new_grpldr) {
                        if (old_groupstate !== '') {
                            new_grp_guestids_map[new_groupstate] = new_grp_guestids_map[new_groupstate].filter(item => item !== edituuid);
                        }
                        if (new_grpldr) { // assign to position 0
                            new_grp_guestids_map[new_groupstate].unshift(edituuid);
                        }
                        else { // push to the back
                            new_grp_guestids_map[new_groupstate].push(edituuid)
                        }
                    }

                    dataobj = {
                        guestlistreadcounter,
                        _id: invitationid,
                        uuid: edituuid,
                        data,
                        initrsvpstate,
                        new_grp_guestids_map,
                        defaultqn_state_map
                    }

                    try {
                        axios({
                            method: 'POST',
                            url: INVITATION_GUESTLIST_EDITGUEST,
                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                            data: dataobj
                        }).then((result) => {
                            setEditstate('SAVING')
                            setTimeout(() => {
                                const { message, updatedon_arr } = result.data
                                if (message === 'SUCCESS') {

                                    // upon success update, Editguestcontent useeffect will be updated again

                                    let data = Object.assign({}, newdata)

                                    data['9-updatedon'] = updatedon_arr

                                    setGuestid_guestinfo_map((guestid_guestinfo_map) => {
                                        guestid_guestinfo_map[edituuid] = data
                                        return guestid_guestinfo_map
                                    })

                                    setGrp_guestids_map(new_grp_guestids_map)

                                    setGuestid_defaultqnstate_map((guestid_defaultqnstate_map) => {
                                        guestid_defaultqnstate_map[edituuid] = defaultqn_state_map
                                        return guestid_defaultqnstate_map
                                    })

                                    setEditdata(() => {  // { Name, Email, Contact, Address, Category, RSVP }
                                        return { ...data, index_uid: editdata['index_uid'] }
                                    })

                                    if (searchactivate) { // not sure need sorting or not
                                        updatesearchtabledata(searchtxt, searchcatselected, sorting)
                                    }

                                    setRendertable(true)
                                    setEditstate('SUCCESS')

                                    // setTimeout(() => {
                                    //     setEditstate('IDLE')
                                    // }, TIMER_CONSTANT)

                                }
                                else setEditstate('FAILURE')
                            }, 500)

                        }).catch((e) => { setEditstate('IDLE') })
                    }
                    catch (e) { }
                }}
                returnpage={() => {
                    setEditstate('IDLE')
                }}
            />
        </Portalmodal>)
    }, [editmodal, editdata, editstate, edituuid, rsvpactive, guestcats, guestid_guestinfo_map, grp_guestids_map, guestgrps, guestid_defaultqnstate_map])

    useMemo(() => {
        setAddcatModal(<Portalmodal
            animation="right_left"
            portaltype="commonportal"
            open={addcatmodal && !rsvpactive}
            onClose={() => {
                if (addcatsavingstate === 'IDLE' || addcatsavingstate === 'FAILURE' || addcatsavingstate === 'SUCCESS') {
                    // clearAlltimeout()
                    setAddcatmodal(!addcatmodal);
                    setAddcatsavingstate('IDLE');
                }
            }}
            floatingclose={false}
            backdropvisible={true}
            modalstyle={portalleftcss}
            closestyle={{ right: 0 }}
            floatingclosecolor='#333333'
        >
            <Addcatcontent
                addcatmodalguestcats={addcatmodalguestcats}
                addcatsavingstate={addcatsavingstate}
                close={() => {
                    if (addcatsavingstate === 'IDLE' || addcatsavingstate === 'FAILURE' || addcatsavingstate === 'SUCCESS') {
                        // clearAlltimeout()
                        setAddcatmodal(!addcatmodal);
                        setAddcatsavingstate('IDLE');
                    }
                }}
                save={(addcatmodalguestcats) => {
                    if (addcatsavingstate === 'IDLE') {
                        setAddcatsavingstate('SAVING')
                        var new_guestcats = addcatmodalguestcats.slice()
                        let options = {
                            method: 'POST',
                            url: INVITATION_GUESTLIST_ADDCAT,
                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                            data: { guestlistreadcounter, invitationid, new_guestcats: new_guestcats, old_guestcats: guestcats, guestids: guestids, guestqns: guestqns }
                        }
                        axios(options).then(() => {

                            // update guestid_guestinfo_map 
                            var newly_removed = []
                            for (let j = 0; j < guestcats.length; j++) {
                                const old_guestcat = guestcats[j]
                                if (!new_guestcats.includes(old_guestcat)) { // if cat not in old_cat means this is newly added
                                    newly_removed.push(old_guestcat)
                                }
                            }

                            var new_guestid_guestinfo_map = Object.assign({}, guestid_guestinfo_map)
                            for (let i = 0; i < guestids.length; i++) {
                                var cats = new_guestid_guestinfo_map[guestids[i]]['6-category']
                                for (let j = 0; j < newly_removed.length; j++) {
                                    if (cats.includes(newly_removed[j])) {
                                        const rmv_index = cats.indexOf(newly_removed[j])
                                        new_guestid_guestinfo_map[guestids[i]]['6-category'].splice(rmv_index, 1)
                                    }
                                }
                            }

                            // remove all guestqns with catind activated that consist of removed cat 
                            var new_guestqns = guestqns.slice()
                            for (let i = 0; i < new_guestqns.length; i++) {
                                const allorcatind = new_guestqns[i][3]
                                if (allorcatind === 'catind') {
                                    for (let j = 0; j < newly_removed.length; j++) {
                                        if (new_guestqns[i][4].includes(newly_removed[j])) {
                                            new_guestqns[i][4].splice(new_guestqns[i][4].indexOf(newly_removed[j]), 1)
                                        }
                                    }
                                }
                            }

                            setGuestqns(new_guestqns)
                            setGuestid_guestinfo_map(new_guestid_guestinfo_map)
                            setGuestcats(new_guestcats) // add all category
                            setAddcatmodalguestcats(new_guestcats)
                            setRendertable(true)
                            setAddcatsavingstate('SUCCESS')
                            // setTimeout(() => { setAddcatsavingstate('IDLE') }, TIMER_CONSTANT)

                        }).catch((e) => {
                            setAddcatsavingstate('FAILURE')
                        })
                    }
                }}
            />
        </Portalmodal>)
    }, [addcatmodal, addcatsavingstate, rsvpactive])

    useMemo(() => {
        setAddgrpModal(<Portalmodal
            animation="right_left"
            portaltype="commonportal"
            open={addgrpmodal && !rsvpactive}
            onClose={() => {
                if (addgrpsavingstate === 'IDLE' || addgrpsavingstate === 'FAILURE' || addgrpsavingstate === 'SUCCESS') {
                    // clearAlltimeout()
                    setAddgrpmodal(!addgrpmodal);
                    setAddgrpsavingstate('IDLE');
                }
            }}
            floatingclose={false}
            backdropvisible={true}
            modalstyle={portalleftcss}
            closestyle={{ right: 0 }}
            floatingclosecolor='#333333'
        >
            <Addgrpcontent
                guestid_guestinfo_map={guestid_guestinfo_map}
                grp_guestids_map={grp_guestids_map}
                addgrpmodalguestgrps={addgrpmodalguestgrps}
                addgrpsavingstate={addgrpsavingstate}
                close={() => {
                    if (addgrpsavingstate === 'IDLE' || addgrpsavingstate === 'FAILURE' || addgrpsavingstate === 'SUCCESS') {
                        // clearAlltimeout()
                        setAddgrpmodal(!addgrpmodal);
                        setAddgrpsavingstate('IDLE');
                    }
                }}
                save={(addgrpmodalguestgrps) => {
                    if (addgrpsavingstate === 'IDLE') {
                        setAddgrpsavingstate('SAVING')
                        var new_guestgrps = addgrpmodalguestgrps.slice()
                        let options = {
                            method: 'POST',
                            url: INVITATION_GUESTLIST_ADDGRP,
                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                            data: { guestlistreadcounter, invitationid, grp_guestids_map, new_guestgrps, old_guestgrps: guestgrps, guestids, guestlistreadcounter }
                        }
                        axios(options).then((item) => {
                            const old_guestgrps = guestgrps.slice()
                            var newly_added = [] // newly added group
                            for (let i = 0; i < new_guestgrps.length; i++) {
                                const new_guestgrp = new_guestgrps[i]
                                if (!old_guestgrps.includes(new_guestgrp)) { // if cat not in old_cat means this is newly added
                                    newly_added.push(new_guestgrp)
                                }
                            }

                            var newly_removed = []  // newly removed group
                            for (let j = 0; j < old_guestgrps.length; j++) {
                                const old_guestgrp = old_guestgrps[j]
                                if (!new_guestgrps.includes(old_guestgrp)) { // if cat not in old_cat means this is newly added
                                    newly_removed.push(old_guestgrp)
                                }
                            }

                            setGuestgrps(new_guestgrps) // add all category
                            setAddgrpmodalguestgrps(new_guestgrps)
                            setAddgrpsavingstate('SUCCESS')
                            setGrp_guestids_map((grp_guestids_map) => {
                                var new_grp_guestids_map = Object.assign(grp_guestids_map, {})
                                for (let i = 0; i < newly_added.length; i++) {
                                    const single_guestgrp = newly_added[i]
                                    if (!new_grp_guestids_map[single_guestgrp]) {
                                        new_grp_guestids_map[single_guestgrp] = []
                                    }
                                }
                                for (let j = 0; j < newly_removed.length; j++) {
                                    const single_guestgrp = newly_removed[j]
                                    delete new_grp_guestids_map[single_guestgrp]
                                }
                                return new_grp_guestids_map
                            })
                            setGuestid_guestinfo_map((guestid_guestinfo_map) => {
                                var new_guestid_guestinfo_map = Object.assign(guestid_guestinfo_map, {})
                                var guestids = Object.keys(new_guestid_guestinfo_map)
                                for (let i = 0; i < newly_removed.length; i++) {
                                    const single_removed = newly_removed[i]
                                    for (let j = 0; j < guestids.length; j++) {
                                        if (guestid_guestinfo_map[guestids[j]]['5-group'] === single_removed) {
                                            new_guestid_guestinfo_map[guestids[j]]['5-group'] = ''
                                        }
                                    }
                                }
                                return new_guestid_guestinfo_map
                            })
                            setRendertable(true)

                        }).catch((e) => {
                            setAddgrpsavingstate('FAILURE')
                        })
                    }
                }}
            />
        </Portalmodal>)
    }, [addgrpmodal, addgrpsavingstate, rsvpactive])

    useMemo(() => {
        setAddmsgModal(<Portalmodal
            animation="right_left"
            portaltype="commonportal"
            open={addmsgmodal}
            onClose={() => {
                if (addmsgstate === 'IDLE' || addmsgstate === 'FAILURE' || addmsgstate === 'SUCCESS') {
                    // clearAlltimeout()
                    setAddmsgmodal(false);
                    setAddmsgstate('IDLE');
                }
            }}
            floatingclose={false}
            backdropvisible={true}
            modalstyle={portalleftcss}
            closestyle={{ right: 0 }}
            floatingclosecolor='#333333'
        >
            <Addmsgcontent
                addmsg={addmsg}
                updateaddmsg={(msg) => { setAddmsg(msg); setAddmsgstate('IDLE'); }}
                addmsgstate={addmsgstate}
                update={() => {
                    if (addmsgstate === 'IDLE') {
                        setAddmsgstate('SAVING')
                        setTimeout(() => {
                            let options = {
                                method: 'POST',
                                url: INVITATION_GUESTLIST_ADDMSG,
                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                data: { guestlistreadcounter, invitationid, addmsg: addmsg, guestlistreadcounter }
                            }
                            axios(options).then((item) => {
                                if (item.data.message === 'SUCCESS') {
                                    setAddmsg(addmsg)
                                    setAddmsgstate('SUCCESS')
                                }
                                else {
                                    setAddmsgstate('FAILURE')
                                }
                            }).catch((e) => {
                                setAddmsgstate('FAILURE')
                            })
                        }, TIMER_CONSTANT)
                    }
                }}
                close={() => {
                    if (addmsgstate === 'IDLE' || addmsgstate === 'FAILURE' || addmsgstate === 'SUCCESS') {
                        // clearAlltimeout()
                        setAddmsgmodal(false);
                        setAddmsgstate('IDLE');
                    }
                }}
            />
        </Portalmodal>
        )
    }, [addmsgmodal, addmsgstate, rsvpactive])

    useMemo(() => {
        setShortcutModal(<Portalmodal
            animation="right_left"
            portaltype="commonportal"
            open={shortcutmodal && !rsvpactive}
            onClose={() => setShortcutmodal(false)}
            floatingclose={false}
            backdropvisible={true}
            modalstyle={portalleftcss}
            closestyle={{ right: 0 }}
            floatingclosecolor='#333333'
        >
            <div className='Rsvpexplorer_portal'>
                <div className='Rsvpexplorer_portaltitle'>Speed Send</div>
                <div className='Rsvpexplorer_portalcontent'>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 10px' }}>
                        <div style={{ display: 'flex', margin: '0px 0px 10px 0px', userSelect: 'none' }}>Speed send your website link to your guest using existing iphone's app (Shortcut) based on guests contact number. You are required to use Shortcut App that is in your iphone mobile to speed send your invitations. Firstly, select the data below and copy.</div>
                        <div style={{ border: 'thin solid #707070', padding: 5, borderRadius: 5, width: '100%', overflowX: 'auto' }}>{shortcutdata}</div>
                    </div>
                </div>
            </div>
        </Portalmodal>)
    }, [shortcutmodal])

    useMemo(() => {
        setAlternativeModal(<Portalmodal
            animation="right_left"
            portaltype="commonportal"
            open={alternativemodal && !rsvpactive}
            onClose={() => {
                if (altstate === 'IDLE' || altstate === 'FAILURE' || altstate === 'SUCCESS') {
                    // clearAlltimeout()
                    setAlternativemodal(false);
                    setAltstate('IDLE')
                }
            }}
            floatingclose={false}
            backdropvisible={true}
            modalstyle={portalleftcss}
            closestyle={{ right: 0 }}
            floatingclosecolor='#333333'
        >
            <Alternativecontent
                rsvpalt={rsvpalt}
                rsvptype={rsvptype}
                altstate={altstate}
                close={() => {
                    if (altstate === 'IDLE' || altstate === 'FAILURE' || altstate === 'SUCCESS') {
                        // clearAlltimeout()
                        setAlternativemodal(false);
                        setAltstate('IDLE')
                    }
                }}
                update={(rsvpalttxt, rsvptype) => {
                    setAltstate('SAVING')
                    try {
                        let options = {
                            method: 'POST',
                            url: INVITATION_RSVPALT,
                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                            data: { guestlistreadcounter, rsvpalt: [rsvpalttxt, ''], rsvptype: rsvptype, urlhandler }
                        };
                        axios(options).then((item) => {
                            if (item && item.data && item.data.message === 'SUCCESS') {
                                setRsvpalt([rsvpalttxt, ''])
                                setRsvptype(rsvptype)
                                setAltstate('SUCCESS')
                            }
                            else {
                                setAltstate('Fail to update.')
                            }
                        });
                    }
                    catch (e) { }
                }}
            />
        </Portalmodal>)
    }, [alternativemodal, altstate, rsvpactive])

    useMemo(() => {
        setCheckboxesticked((checkboxesticked) => {
            setSelectedidsmap((selectedidsmap) => {
                setModdeleteModal(showmoddelete
                    ? <Modcfm
                        onHide={() => { setShowmoddelete(false) }}
                        onNo={() => { setShowmoddelete(false) }}
                        onOk={() => {

                            var idstoremoved = []
                            var keys = Object.keys(selectedidsmap)
                            for (let i = 0; i < keys.length; i++) {
                                var key = keys[i]
                                if (selectedidsmap[key]) {
                                    idstoremoved.push(key)
                                }
                            }
                            if (idstoremoved.length === guestids.length) { // user deleted all ids
                                try {
                                    let options = {
                                        method: 'POST',
                                        url: INVITATION_GUESTLIST_CLEARALLCELLS,
                                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                        data: { guestlistreadcounter, invitationid, guestgrps, guestqnsuids }

                                    }
                                    axios(options).then(() => {
                                        refresh()
                                    })
                                }
                                catch (e) { }
                            }
                            else {
                                var accept = 0
                                var noreply = 0
                                var decline = 0
                                for (let i = 0; i < guestids.length; i++) {
                                    let rsvpresult = guestid_guestinfo_map[guestids[i]]['8-rsvp']
                                    if (rsvpresult === 'decline') {
                                        decline = decline + 1
                                    }
                                    else if (rsvpresult === 'accept') {
                                        accept = accept + 1
                                    }
                                    else {
                                        noreply = noreply + 1
                                    }
                                }

                                var new_accept = accept
                                var new_noreply = noreply
                                var new_decline = decline
                                for (let i = 0; i < idstoremoved.length; i++) {
                                    let rsvpresult = guestid_guestinfo_map[idstoremoved[i]]['8-rsvp']
                                    if (rsvpresult === '') {
                                        new_noreply = new_noreply - 1
                                    }
                                    else if (rsvpresult === 'decline') {
                                        new_decline = new_decline - 1
                                    }
                                    else if (rsvpresult === 'accept') {
                                        new_accept = new_accept - 1
                                    }
                                }

                                var grp_guestid_map_tobermv = {} 
                                for (let i = 0; i < idstoremoved.length; i++) {
                                    var group = guestid_guestinfo_map[idstoremoved[i]]['5-group']
                                    if (group.length > 0) {
                                        if (!grp_guestid_map_tobermv[group]) {
                                            grp_guestid_map_tobermv[group] = []
                                        }
                                        grp_guestid_map_tobermv[group].push(idstoremoved[i])
                                    }
                                }

                                try {
                                    let options = {
                                        method: 'POST',
                                        url: INVITATION_GUESTLIST_CLEARPARTIALCELLS,
                                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                        data: { guestlistreadcounter, invitationid, idstoremoved, accept, decline, noreply, new_accept, new_decline, new_noreply, guestgrps, grp_guestid_map_tobermv, guestqnsuids }
                                    }
                                    axios(options).then((result) => {
                                        const { message } = result.data
                                        if (message === 'SUCCESS') {

                                            var new_guestids = guestids.slice()
                                            var new_rows = rows.slice()
                                            let new_guestid_guestinfo_map = Object.assign({}, guestid_guestinfo_map)
                                            for (let i = 0; i < idstoremoved.length; i++) {
                                                var arr_index = new_guestids.indexOf(idstoremoved[i])
                                                new_guestids.splice(arr_index, 1)
                                                new_rows.splice(arr_index, 1)
                                                delete new_guestid_guestinfo_map[idstoremoved[i]]
                                            }

                                            var new_selectedidsmap = {}
                                            for (let i = 0; i < new_guestids.length; i++) {
                                                new_selectedidsmap[new_guestids[i]] = false
                                            }

                                            setGuestqns((initialguestqns) => {
                                                let guestqns = initialguestqns.slice()
                                                for (let i = 0; i < idstoremoved.length; i++) {
                                                    const id = idstoremoved[i]
                                                    guestqns = Remove_Guestid_Guestqncatind(id, guestqns)
                                                }
                                                return guestqns
                                            })


                                            // setElRefs(new_elRefs)
                                            setGuestid_guestinfo_map(new_guestid_guestinfo_map)
                                            setSelectedidsmap(new_selectedidsmap)
                                            setRows(new_rows)
                                            setGuestids(new_guestids)
                                            setInitialguestids(new_guestids)
                                            setShowmoddelete(false)
                                            setCheckboxesticked(0)
                                            setRendertable(true)
                                            setModdeleteModal(null)

                                        }
                                    })
                                }
                                catch (e) { }
                            }

                        }}
                        txt={<div>
                            {`
                            You are removing ${checkboxesticked} guests information from your guestlist.
                            Once it is removed, corresponding information of the guest will be emptied and there is no going back.
                            Please be certain.
                            `}
                        </div>}
                    />
                    : null
                )
                return selectedidsmap
            })
            return checkboxesticked
        })
    }, [showmoddelete, selectedidsmap, rsvpactive, checkboxesticked])

    useMemo(() => {
        setSearchfilterModal(<Portalmodal
            portaltype="commonportal"
            open={searchfiltermodal}
            onClose={() => setSearchfiltermodal(false)}
            floatingclose={false}
            backdropvisible={true}
            modalstyle={portalmidcss}
            closestyle={{ right: 0 }}
            floatingclosecolor='#333333'
        >
            <div className='Rsvpexplorer_portal'>
                <div className='Rsvpexplorer_portaltitle'>Search Filter</div>
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                    {FIXED_COLUMNS_ID_FOR_SEARCH.map((id, i) => {
                        return <button
                            className='Portalinnerelem'
                            key={id + '_' + i}
                            value={id}
                            style={{ borderRadius: i === 0 ? '10px 10px 0px 0px' : i === FIXED_COLUMNS_ID_FOR_SEARCH.length - 1 ? '0px 0px 10px 10px' : '0px' }}
                            onClick={(e) => {
                                setSearchcat(e.target.value)
                                setSearchfiltermodal(false)
                            }}>
                            {ID_COLS_MAP[id]}
                        </button>
                    })}
                </div>
            </div>
        </Portalmodal>)
    }, [searchfiltermodal, rsvpactive])

    useMemo(() => {
        if (searchcatmodal) {
            setSearchcatModal(<Portalmodal
                portaltype="commonportal"
                open={searchcatmodal}
                onClose={() => { setSearchcatmodal(false) }}
                floatingclose={false}
                backdropvisible={true}
                modalstyle={portalmidcss}
                closestyle={{ right: 0 }}
                floatingclosecolor='#333333'
            >
                <div className='Rsvpexplorer_portal'>
                    <div className='Rsvpexplorer_portaltitle'>
                        Search Category
                    </div>
                    <div style={{ padding: '0px 10px 0px 13px', marginBottom: 10 }}>
                        Table will display data that has the selected category. Select category by clicking the tag.
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', padding: '0px 10px 10px', flexWrap: 'wrap' }}>
                        {(guestcats && guestcats.length > 0)
                            ? guestcats.map((cat, i) => {
                                const catindex = searchcatselected.indexOf(cat)
                                return <button
                                    className={`Rsvpexplorer_portaltag ${catindex > -1 ? 'Rsvpexplorer_portaltagselected' : ''} `}
                                    key={'search_' + i}
                                    onClick={(e) => {
                                        const { value } = e.target
                                        setSearchcatselected((searchcatselected) => {
                                            setSearchtxt((searchtxt) => {
                                                let local_searchcatselected = searchcatselected.slice()
                                                catindex > -1 ? local_searchcatselected.splice(catindex, 1) : local_searchcatselected.push(value)
                                                updatesearchtabledata(searchtxt, local_searchcatselected, sorting)
                                                let local_searchactivate = local_searchcatselected.length === 0 && searchtxt.length === 0 ? false : true
                                                setSearchactivate(local_searchactivate)
                                                setSearchcatselected(local_searchcatselected)
                                                return searchtxt
                                            })
                                            return searchcatselected
                                        })

                                    }}
                                    value={cat}
                                >
                                    {cat}
                                </button>
                            })
                            : <div style={{ display: 'flex', flexDirection: 'row', padding: 10, flexWrap: 'wrap', width: '100%', placeContent: 'center', textAlign: 'center', border: '1px solid black' }}>
                                You do not have any category for now.
                            </div>}
                    </div>
                </div>
            </Portalmodal>)
        }
        else {
            setSearchcatModal(null)
        }
    }, [searchcatmodal, searchcatselected, guestcats, rsvpactive])

    useMemo(() => {

        if (settingmodal) {

            setSettingModal(<Portalmodal
                animation="right_left"
                portaltype="commonportal"
                open={settingmodal}
                onClose={() => {
                    setSettingmodal(false);
                    setSettingsavingstate('IDLE')
                }}
                floatingclose={false}
                backdropvisible={true}
                modalstyle={portalleftcss}
                closestyle={{ right: 0 }}
                floatingclosecolor='#333333'
            >
                <Settingcontent
                    settingsavingstate={settingsavingstate}
                    guestlistsettings={guestlistsettings}
                    save={(new_guestlistsettings) => {
                        setSettingsavingstate('SAVING')
                        try {
                            let options = {
                                method: 'POST',
                                url: INVITATION_SETTINGUPDATE,
                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                                data: { invitationid, new_guestlistsettings, urlhandler, guestlistreadcounter }
                            };
                            axios(options).then((item) => {
                                if (item && item.data && item.data.message === 'SUCCESS') { // dranconian
                                    setSettingsavingstate('SUCCESS')
                                    setGuestlistsettings(new_guestlistsettings)
                                    setRendertable(true)
                                }
                                else {
                                    setSettingsavingstate('FAILURE')
                                }
                            })
                        }
                        catch (e) { }
                    }}
                    close={() => {
                        setSettingmodal(false)
                        setSettingsavingstate('IDLE')
                    }}
                />
            </Portalmodal>)
        }
        else setSettingModal(null)
    }, [settingmodal, rsvpactive, guestlistsettings, settingsavingstate])

    useMemo(() => {
        if (swapmodal) {
            setSwapModal(<Portalmodal
                animation="right_left"
                portaltype="commonportal"
                open={swapmodal}
                onClose={() => { setSwapmodal(false) }}
                floatingclose={false}
                backdropvisible={true}
                modalstyle={portalleftcss}
                closestyle={{ right: 0 }}
                floatingclosecolor='#333333'
            >
                <Swapcontent
                    guestqns={guestqns}
                    swapmsgstate={swapmsgstate}
                    save={(guestqns) => {
                        setSwapmsgstate('SAVING')
                        try {
                            let options = {
                                method: 'POST',
                                url: INVITATION_SWAPSAVING,
                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                                data: { invitationid, guestqns, urlhandler, guestlistreadcounter }
                            };
                            axios(options).then((item) => {
                                if (item && item.data && item.data.message === 'SUCCESS') {
                                    setSwapmsgstate('SUCCESS')
                                    setGuestqns(guestqns)
                                    setRendertable(true)
                                }
                                else {
                                    setSwapmsgstate('FAILURE')
                                }
                            })
                        }
                        catch (e) { }
                    }}
                    close={() => { setSwapmodal(false) }}
                />
            </Portalmodal>)
        }
        else setSwapModal(null)
    }, [swapmodal, rsvpactive, guestqns, swapmsgstate, rsvpactive])

    const runsort = (sorting) => {
        // index ,'Name', 'Email', 'Contact', 'Address', 'Group', 'Category', 'Crypto Wallet Address', 'RSVP', 'Updated On'
        // setInitialguestids((initialguestids)=>{

        //     return initialguestids
        // })

        var guestids = initialguestids.slice()
        const { title, type } = sorting

        const default_sort = () => {
            var new_guestids = []
            for (let i = 0; i < guestids.length; i++) {
                new_guestids.push({ id: guestids[i], index: i })
            }
            return new_guestids
        }

        const alphanumeric_ascending = (key, guestids_objs) => {

            var sorted_guestids_objs

            sorted_guestids_objs = guestids_objs.sort((a, b) => {
                const itemA = a[key].toUpperCase();
                const itemB = b[key].toUpperCase();
                if (itemA < itemB) {
                    return 1;
                }
                if (itemA > itemB) {
                    return -1;
                }
                return 0;
            })

            var arr_of_empties = []
            for (let i = 0; i < sorted_guestids_objs.length; i++) {
                const item = sorted_guestids_objs[i][key]
                if (item === '') { // if item is empty 
                    arr_of_empties.push(sorted_guestids_objs[i])
                    sorted_guestids_objs.splice(i, 1)
                    i = i - 1
                }
            }

            sorted_guestids_objs.reverse()
            sorted_guestids_objs = [...sorted_guestids_objs, ...arr_of_empties]

            let local_new_guestids = []
            for (let i = 0; i < sorted_guestids_objs.length; i++) {
                const id = sorted_guestids_objs[i]['guestid']
                local_new_guestids.push({ id: id, index: guestids.indexOf(id) })
            }
            return local_new_guestids

        }

        const alphanumeric_descending = (key, guestids_objs) => {
            var sorted_guestids_objs

            sorted_guestids_objs = guestids_objs.sort((a, b) => {
                const itemA = a[key].toUpperCase();
                const itemB = b[key].toUpperCase();
                if (itemA < itemB) {
                    return 1;
                }
                if (itemA > itemB) {
                    return -1;
                }
                return 0;
            });

            var arr_of_empties = []
            for (let i = 0; i < sorted_guestids_objs.length; i++) {
                const item = sorted_guestids_objs[i][key]
                if (item === '') {
                    arr_of_empties.push(sorted_guestids_objs[i])
                    sorted_guestids_objs.splice(i, 1)
                    i = i - 1
                }
            }

            sorted_guestids_objs = [...sorted_guestids_objs, ...arr_of_empties]

            let local_new_guestids = []
            for (let i = 0; i < sorted_guestids_objs.length; i++) {
                const id = sorted_guestids_objs[i]['guestid']
                local_new_guestids.push({ id: id, index: guestids.indexOf(id) })
            }
            return local_new_guestids
        }

        var new_guestids = []
        if (title === 0) { // index
            if (type === 'ascending') {
                for (let i = 0; i < guestids.length; i++) {
                    new_guestids.push({ id: guestids[i], index: i })
                }
            }
            else if (type === 'descending') {
                for (let j = guestids.length - 1; j > -1; j--) {
                    new_guestids.push({ id: guestids[j], index: j })
                }
            }

        }
        else if (title > 0 && title < 11) { // sort for : name, email, contact, address, group, crypto address, updated on, status
            var guestids_objs = []

            for (let i = 0; i < guestids.length; i++) {
                const id = guestids[i]
                guestids_objs.push({ ...guestid_guestinfo_map[id], guestid: id })
            }

            if (title === 1) { // name
                var key = '1-name'
                if (type === 'default') {
                    new_guestids = default_sort()
                }
                else if (type === 'ascending') {
                    new_guestids = alphanumeric_ascending(key, guestids_objs)
                }
                else if (type === 'descending') {
                    new_guestids = alphanumeric_descending(key, guestids_objs)
                }
            }
            else if (title === 2) { // email
                var key = '2-email'
                if (type === 'default') {
                    new_guestids = default_sort()
                }
                else if (type === 'ascending') {
                    new_guestids = alphanumeric_ascending(key, guestids_objs)
                }
                else if (type === 'descending') {
                    new_guestids = alphanumeric_descending(key, guestids_objs)
                }
            }
            else if (title === 3) { // contact
                var key = '3-contact'
                if (type === 'default') {
                    new_guestids = default_sort()
                }
                else if (type === 'ascending') {
                    new_guestids = alphanumeric_ascending(key, guestids_objs)
                }
                else if (type === 'descending') {
                    new_guestids = alphanumeric_descending(key, guestids_objs)
                }
            }
            else if (title === 4) { // address
                var key = '4-address'
                if (type === 'default') {
                    new_guestids = default_sort()
                }
                else if (type === 'ascending') {
                    new_guestids = alphanumeric_ascending(key, guestids_objs)
                }
                else if (type === 'descending') {
                    new_guestids = alphanumeric_descending(key, guestids_objs)
                }
            }
            else if (title === 5) { // group

                if (type === 'default') {
                    new_guestids = default_sort()
                }
                else {

                    var sorted_guestgrps = guestgrps.sort((a, b) => {
                        const nameA = a.toUpperCase()
                        const nameB = b.toUpperCase()
                        if (nameA < nameB) { return -1 }
                        if (nameA > nameB) { return 1 }
                        return 0;
                    })

                    var sorted_grps_ids = []

                    for (let i = 0; i < sorted_guestgrps.length; i++) {
                        var grp = sorted_guestgrps[i]
                        var ids = grp_guestids_map[grp]
                        if (ids) {
                            sorted_grps_ids = [...sorted_grps_ids, ...ids]
                        }
                    }

                    for (let i = 0; i < guestids.length; i++) {
                        if (sorted_grps_ids.indexOf(guestids[i]) > -1) {

                        }
                        else {
                            sorted_grps_ids.push(guestids[i])
                        }
                    }

                    for (let i = 0; i < sorted_grps_ids.length; i++) {
                        var index = initialguestids.indexOf(sorted_grps_ids[i])
                        new_guestids.push({ id: sorted_grps_ids[i], index: index })
                    }

                    if (type === 'descending') { new_guestids.reverse() }

                }

            }
            else if (title === 6) { // category
                var key = '6-category'
                new_guestids = default_sort()
            }
            else if (title === 7) { // crypto address    
                var key = 'NFT Wallet address'
                new_guestids = default_sort()
            }
            else if (title === 8) { // rsvp
                var key = 'RSVP'
                if (type === 'default') {
                    new_guestids = default_sort()
                }
                else {
                    var accepts = []
                    var declines = []
                    var noreplies = []
                    for (let i = 0; i < guestids.length; i++) {
                        var rsvp = guestid_guestinfo_map[guestids[i]]['8-rsvp']
                        var id = guestids[i]
                        if (rsvp === 'accept') {
                            accepts.push({ id: id, index: i })
                        }
                        else if (rsvp === 'decline') {
                            declines.push({ id: id, index: i })
                        }
                        else {
                            noreplies.push({ id: id, index: i })
                        }
                    }
                    if (type === 'accept decline noreplies') {
                        new_guestids = [...accepts, ...declines, ...noreplies]
                    }
                    else if (type === 'decline accept noreplies') {
                        new_guestids = [...declines, ...accepts, ...noreplies]
                    }
                    else if (type === 'noreplies accept decline') {
                        new_guestids = [...noreplies, ...accepts, ...declines]
                    }
                }

            }
            else if (title === 9) { // updated on
                var key = 'Updated On'
                if (type === 'default') {
                    new_guestids = default_sort()
                }
                else if (type === 'ascending') {

                    var sorted_guestids_objs = guestids_objs.sort((a, b) => {
                        const itemA = new Date(a['9-updatedon'])
                        const itemB = new Date(b['9-updatedon'])
                        if (itemA < itemB) {
                            return 1;
                        }
                        if (itemA > itemB) {
                            return -1;
                        }
                        return 0;
                    });

                    for (let i = 0; i < sorted_guestids_objs.length; i++) {
                        const id = sorted_guestids_objs[i]['guestid']
                        new_guestids.push({ id: id, index: guestids.indexOf(id) })
                    }
                }
                else if (type === 'descending') {
                    var sorted_guestids_objs = guestids_objs.sort((a, b) => {
                        const itemA = new Date(a['9-updatedon'])
                        const itemB = new Date(b['9-updatedon'])
                        if (itemA < itemB) {
                            return 1;
                        }
                        if (itemA > itemB) {
                            return -1;
                        }
                        return 0;
                    });

                    for (let i = 0; i < sorted_guestids_objs.length; i++) {
                        const id = sorted_guestids_objs[i]['guestid']
                        new_guestids.push({ id: id, index: guestids.indexOf(id) })
                    }
                    new_guestids = new_guestids.reverse()
                }
            }
            else if (title === 10) { // status
                const key = "10-status"

                for (let i = 0; i < guestids_objs.length; i++) {
                    guestids_objs[i]['10-status'] = guestids_objs[i]['10-status']['action']
                }

                if (type === 'default') {
                    new_guestids = default_sort()
                }
                else if (type === 'ascending') {
                    new_guestids = alphanumeric_ascending(key, guestids_objs)
                }
                else {
                    new_guestids = alphanumeric_descending(key, guestids_objs)
                }

            }
        }
        else { // user defined guestqns

            var guestids_objs = []

            for (let i = 0; i < guestids.length; i++) {
                guestids_objs.push({ ...guestid_guestinfo_map[guestids[i]], guestid: guestids[i] })
            }

            if (type === 'default') {
                new_guestids = default_sort()
            }
            else if (type === 'ascending') {
                new_guestids = alphanumeric_ascending(title, guestids_objs)
            }
            else {
                new_guestids = alphanumeric_descending(title, guestids_objs)
            }

        }

        return new_guestids
    }

    const singleoptionpopup = (msg) => {
        setShowgeneralpopup(true)
        setShowgeneralpopupcontent(msg)
    }

    const doubleoptionpopup = (msg, data) => {
        setShowstatuspopup(true)
        // setStatuspopupcontent(msg)
        setStatuspopupdata(data)
    }

    const initcommunicationtool = (id, from) => {
        var data = {
            passcode: passcode,
            invitationid: invitationid,
            guestlistreadcounter,
            status: {
                'date': new Date().toLocaleString(),
                'from': from,
                'action': 'sent'
            },
            urlhandler,
            guestid: id
        }
        setStatuspopupdata(data)
    }

    function genrow(guestid, i) {
        const curr_guestinfo = JSON.parse(JSON.stringify(guestid_guestinfo_map[guestid]))
        let curr_row = curr_guestinfo

        const cellsize = guestlistsettings['cellsize']

        // input checkbox
        curr_row['checkbox'] = <div style={{ minHeight: cellsize === 0 ? '' : 70, display: 'flex', placeContent: 'center', height: '100%', alignItems: 'center' }}>
            <input // CHECKBOX 
                ref={elRefs[i]}
                className='iglnonheadercheckbox'
                style={{ width: '17px !important', height: '17px !important' }}
                type='checkbox'
                defaultValue={guestid}
                onChange={(e) => {
                    setMainclicked((mainclicked) => {
                        if (!mainclicked) { setCheckboxidselected(guestid) }// user clicked
                        // else is click event from main check box 
                        return mainclicked
                    })
                }}
            />
        </div>

        // guestid
        curr_row['guestid'] = guestid // this will not be show on the table

        // number
        curr_row['index_uid'] = i + 1

        // obtain updatedon
        curr_row['9-updatedon'] = (curr_guestinfo['9-updatedon'] && curr_guestinfo['9-updatedon'][0])
            ? curr_guestinfo['9-updatedon'][0].date
            : ''

        // wallet
        if (cryptobool) {
            curr_row['7-nftwalletaddress'] = curr_guestinfo['7-nftwalletaddress'] === -1 ? <div className='Rsvpexplorer_portaltag Rsvpexplorer_portaltagselected Rsvpexplorer_portaltagslim' style={{ backgroundColor: '#333333', color: 'white', width: 'max-content', border: '1px solid #333333' }}>DISABLED</div> : curr_guestinfo['7-nftwalletaddress']
        }

        // obtain category, reshape into doms
        if (curr_row['6-category'].length > 0) {
            let catitem_dom = []
            for (let x = 0; x < curr_row['6-category'].length; x++) {
                catitem_dom.push(
                    <div key={i + 'cat' + x} className='Rsvpexplorer_portaltag Rsvpexplorer_portaltagselected Rsvpexplorer_portaltagslim'>
                        {curr_row['6-category'][x]}
                    </div>
                )
            }
            let cat_dom = <div style={{ display: 'flex', flexDirection: 'row' }}>{catitem_dom}</div>
            curr_row['6-category'] = cat_dom
        }

        if (curr_row['5-group'].length > 0) {

            if (grp_guestids_map[curr_row['5-group']] && grp_guestids_map[curr_row['5-group']].indexOf(guestid) === 0) {// check if he/she is leader
                curr_row['5-group'] = <div className='Rsvpexplorer_portaltag Rsvpexplorer_portaltagselected Rsvpexplorer_portaltagslim' style={{ backgroundColor: 'white', color: '#333333', width: 'max-content', border: '1px solid #333333' }}>
                    {curr_row['5-group'] + ' ( Leader )'}
                </div>
            }
            else {
                if (grp_guestids_map[curr_row['5-group']] && grp_guestids_map[curr_row['5-group']].length === 0) {
                    curr_row['5-group'] = <div className='Rsvpexplorer_portaltag Rsvpexplorer_portaltagselected Rsvpexplorer_portaltagslim' style={{ backgroundColor: 'white', color: '#333333', width: 'max-content', border: '1px solid #333333' }}>
                        {curr_row['5-group'] + ' ( Leader )'}
                    </div>
                }
                else {
                    curr_row['5-group'] = <div className='Rsvpexplorer_portaltag Rsvpexplorer_portaltagselected Rsvpexplorer_portaltagslim' style={{ backgroundColor: 'white', color: '#333333', width: 'max-content', border: '1px solid #333333' }}>
                        {curr_row['5-group']}
                    </div>
                }
            }
        }

        curr_row['10-status'] = (curr_guestinfo['10-status'] && curr_guestinfo['10-status'].action)
            ? <div id={'iglstatus_' + guestid} style={{ width: '100%', display: 'flex', placeContent: 'center', alignContent: 'center' }}>
                <div style={{ padding: '0px 10px', border: '1px solid #333333', backgroundColor: '#333333', color: '#ffffff', borderRadius: 15 }}>
                    {curr_guestinfo['10-status'].action.charAt(0).toUpperCase() + curr_guestinfo['10-status'].action.slice(1)}
                </div>
            </div>
            : ''

        const subject = addmsg[0] // for email only
        const commontext = addmsg[1] + ' ' + WEBSITE_URL + '/invitation/' + urlhandler
        const text = passcode
            ? commontext + '/passcode=' + passcode + '/guestid=' + guestid
            : commontext + urlhandler + '/guestid=' + guestid

        const clickWhatsapp = (id, contact, urlhandler, passcode, rsvpactive, Name) => {

            if (!rsvpactive) {
                singleoptionpopup(RSVP_NOT_ACTIVE)
                return
            }

            if (contact.length === 0) {
                singleoptionpopup(NO_CONTACT_FOUND)
                return
            }

            window.open('https://api.whatsapp.com/send?phone=' + contact + '&text=' + text)
            // var data = { passcode: passcode, invitationid: invitationid, guestlistreadcounter, status: { 'date': new Date().toLocaleString(), 'from': 'whatsapp', 'action': 'sent' }, urlhandler, guestid: id }
            // doubleoptionpopup(UPDATE_STATUS, data) // kola

            initcommunicationtool('whatsapp', id)
            setStatuscommunicationtool('whatsapp')
            setStatusguestid(id)
            setStatusguestname(Name)
            setShowstatuspopup(true)
        }

        const clickSms = (id, contact, urlhandler, passcode, rsvpactive, Name) => {
            if (!rsvpactive) {
                singleoptionpopup(RSVP_NOT_ACTIVE)
                return
            }

            if (contact.length === 0) {
                singleoptionpopup(NO_CONTACT_FOUND)
                return
            }

            if (!isMobile) {
                singleoptionpopup(INCORRECT_DEVICE)
                return
            }

            window.open(`sms:${contact}&body=${text}`)
            // var data = { passcode: passcode, invitationid: invitationid, guestlistreadcounter, status: { 'date': new Date().toLocaleString(), 'from': 'sms', 'action': 'sent' }, urlhandler, guestid: id }
            // doubleoptionpopup(UPDATE_STATUS, data) // kola
            initcommunicationtool('sms', id)
            setStatuscommunicationtool('sms')
            setStatusguestid(id)
            setStatusguestname(Name)
            setShowstatuspopup(true)
        }

        const clickTelephone = (id, contact, passcode, rsvpactive, Name) => {

            if (!rsvpactive) {
                singleoptionpopup(RSVP_NOT_ACTIVE)
                return
            }

            if (contact.length === 0) {
                singleoptionpopup(NO_CONTACT_FOUND)
                return
            }

            if (!isMobile) {
                singleoptionpopup(INCORRECT_DEVICE)
                return
            }

            window.open(`tel:${contact}`);
            // var data = { passcode: passcode, invitationid: invitationid, guestlistreadcounter, status: { 'date': new Date().toLocaleString(), 'from': 'phone', 'action': 'sent' }, urlhandler, guestid: id }
            // doubleoptionpopup(UPDATE_STATUS, data) // kola
            initcommunicationtool('phone', id)
            setStatuscommunicationtool('phone')
            setStatusguestid(id)
            setStatusguestname(Name)
            setShowstatuspopup(true)
        }

        const clickEmail = (id, email, urlhandler, passcode, rsvpactive, Name) => {

            if (!rsvpactive) {
                singleoptionpopup(RSVP_NOT_ACTIVE)
                return
            }

            if (email.length === 0) {
                singleoptionpopup(NO_EMAIL_FOUND)
                return
            }

            window.open("mailto:" + email + '?&subject=' + subject + '&body=' + text);
            // var data = { passcode: passcode, invitationid: invitationid, guestlistreadcounter, status: { 'date': new Date().toLocaleString(), 'from': 'email', 'action': 'sent' }, urlhandler, guestid: id }
            // doubleoptionpopup(UPDATE_STATUS, data) // kola
            initcommunicationtool('email', id)
            setStatuscommunicationtool('email')
            setStatusguestid(id)
            setStatusguestname(Name)
            setShowstatuspopup(true)
        }

        const Name = guestid_guestinfo_map[guestid]['1-name']
        const Contact = guestid_guestinfo_map[guestid]['3-contact']
        const Email = guestid_guestinfo_map[guestid]['2-email']
        curr_row['secondlast-action'] = <div id='igl_communicationtools' style={{ display: 'flex', flexDirection: 'row' }}>
            <button className="igl_iconbtn" onClick={() => clickWhatsapp(guestid, Contact, urlhandler, passcode, rsvpactive, Name)}>{iconwrapper(WHATSAPP_ICON, CELLICON_CSS)}</button>
            <button className="igl_iconbtn" onClick={() => clickSms(guestid, Contact, urlhandler, passcode, rsvpactive, Name)}> {iconwrapper(SMS_ICON, CELLICON_CSS)}</button>
            <button className="igl_iconbtn" onClick={() => clickTelephone(guestid, Contact, urlhandler, passcode, rsvpactive, Name)}> {iconwrapper(TELEPHONE_ICON, CELLICON_CSS)}</button>
            <button className="igl_iconbtn" onClick={() => clickEmail(guestid, Email, urlhandler, passcode, rsvpactive, Name)}>{iconwrapper(EMAIL_ICON, CELLICON_CSS)}</button>
        </div>

        // trash and redirect
        curr_row['last-action'] = <div style={{ display: 'flex', flexDirection: 'row' }}>

            <button
                className="igl_iconbtn"
                onClick={() => {
                    const name = guestid_guestinfo_map[guestid]['1-name']
                    setShowdeletepopup(true)
                    setShowdeletepopupcontent(`
                    You are removing "${name}" from your guestlist.
                    Once it is removed, corresponding information of the guest will be emptied and there is no going back. 
                    Please be certain.`)
                    setShowdeletepopupguestid(guestid)
                }}>
                {iconwrapper(TRASH_ICON, CELLICON_CSS)}
            </button>

            <button
                className="igl_iconbtn"
                // disabled={rsvpactive}
                // style={{ color: !rsvpactive ? '#707070' : '#b0b0b0' }}
                onClick={() => {
                    const { guestid } = curr_row
                    const data = guestid_guestinfo_map[guestid]
                    setEdituuid(guestid)
                    setEditdata({ ...data, index_uid: i + 1 }) // { Name, Email, Contact, Address, Category, RSVP }
                    setEditmodal(true);
                }}>
                {iconwrapper(PENCIL_ICON, CELLICON_CSS)}
            </button>

            <Link
                className="igl_iconbtn"
                to={'/invitation/' + urlhandler + '/passcode=' + passcode + '/guestuid=' + guestid}
                target="_blank"
                style={{ outline: 'none' }}
            >
                {iconwrapper(REDIRECT_ICON, CELLICON_CSS)}
            </Link>
        </div>
        return curr_row
    }

    function charcomparing(searchtxt, namefrmdata) {
        if (searchtxt.length === 0) {
            return true
        }
        if (searchtxt.length <= namefrmdata.length) {
            let truecounter = 0
            for (let i = 0; i < searchtxt.length; i++) {
                if (searchtxt[i] === namefrmdata[i]) {
                    truecounter = truecounter + 1;
                }
            }
            return truecounter === searchtxt.length
        }
    }

    function tagcompare(singleguestobj, searchcatselected = []) {
        if (searchcatselected.length === 0) {
            return true
        }
        else { // searchcarselected gt element(s)
            if (singleguestobj['6-category'].length === 0) {
                return false
            }
            else {
                for (let i = 0; i < singleguestobj['6-category'].length; i++) {
                    let currcat = singleguestobj['6-category'][i]
                    if (searchcatselected.indexOf(currcat) > -1) {
                        return true
                    }
                }
            }
            return false
        }
    }

    const [hidenavbtn, setHidenavbtn] = useState(true)

    const navbtntxt = (toptxt, btmtxt, txtrootcss) => {
        return hidenavbtn
            ? <div className="ig_btntxt" style={txtrootcss}>
                <div className='ig_commontxt' style={{ marginTop: 0 }}>{toptxt}</div>
                {btmtxt.length > 0 ? <div className='ig_commontxt'>{btmtxt}</div> : null}
            </div> : null
    }

    var ADDQN_DIV = <button
        key={mainpage === 'QUESTIONEXPLORER' + 'addbtn'}
        className={'iglbtnfalse iglbtnfirstchild'}
        onClick={() => {
            setAddqnmodalloading(true)
            setTimeout(() => {
                setMainpage('QUESTIONEXPLORER');
                setAddqnmodalloading(false)
            }, TIMER_CONSTANT)
        }}
    >
        {iconwrapper(QNEXP_ICON, GENERALICON_CSS)}
        {navbtntxt('Question', '', { color: mainpage === 'QUESTIONEXPLORER' ? 'white !important' : '#333333 !important' })}
    </button>

    var ADDCAT_DIV = <button className={"iglbtn" + rsvpactive} disabled={rsvpactive} onClick={() => setAddcatmodal(true)}>
        {iconwrapper(CAT_ICON, GENERALICON_CSS)}
        {navbtntxt('Category', '', {})}
    </button>

    var ADDGROUP_DIV = <button className={"iglbtn" + rsvpactive} disabled={rsvpactive} onClick={() => setAddgrpmodal(true)}>
        {iconwrapper(GRP_ICON, GENERALICON_CSS)}
        {navbtntxt(`Group`, '', {})}
    </button>

    var MSG_DIV = <button className='iglbtnfalse' onClick={() => setAddmsgmodal(true)}>
        {iconwrapper(TEMPLATE_ICON, GENERALICON_CSS)}
        {navbtntxt('Message', ``, {})}
    </button>

    var CLEARALL_DIV = <button
        disabled={rsvpactive}
        className={"iglbtn" + rsvpactive + ` ${(checkboxesticked > 0 && !rsvpactive) ? '' : 'iglbtnnonselected'} `}
        onClick={() => { // DELETE FUNCTION
            var gtrowstodelete = Object.values(selectedidsmap).filter((v) => v).length;
            if (!rsvpactive && gtrowstodelete > 0) { setShowmoddelete(true) }
        }}
    >
        {iconwrapper(TRASHTOP_ICON, GENERALICON_CSS)}
        {navbtntxt('Delete', ``, {})}
    </button>

    var ALTERNATIVE_DIV = <button className={"iglbtn" + rsvpactive} style={{ borderLeft: rsvptype === 'google' ? '1px solid #dddddd' : '' }} disabled={rsvpactive} onClick={() => { setAlternativemodal(true) }}>
        {iconwrapper(ALTERNATE_ICON, GENERALICON_CSS)}
        {navbtntxt('Replace', ``, {})}
    </button>

    var SETTING_DIV = <button className="iglbtnfalse" onClick={() => { setSettingmodal(true) }}>
        {iconwrapper(SETTING_ICON, GENERALICON_CSS)}
        {navbtntxt('Settings', ``, {})}
    </button>

    var SWAP_DIV = !isMobile
        ? <button className="iglbtnfalse" onClick={() => { setSwapmodal(true) }}>
            {iconwrapper(SWAP_ICON, GENERALICON_CSS)}
            {navbtntxt('Swap', ``, {})}
        </button>
        : null

    var EXPORT_DIV = !isMobile
        ? <button className="iglbtnfalse" onClick={() => csvLink?.current?.link.click()} >
            {iconwrapper(EXPORT_ICON, GENERALICON_CSS)}
            {navbtntxt('Export', ``, {})}
            <CSVLink
                ref={csvLink}
                id="csvid"
                filename={"guestlist.csv"}
                data={exportcsv}
                style={{ width: '0px !important', height: '0px !important', position: 'absolute', }}
                target='_blank'
            />
        </button>
        : null

    var STATS_DIV = <button className="iglbtnfalse" onClick={() => { setMainpage('STATS') }}>
        {iconwrapper(STATS_ICON, GENERALICON_CSS)}
        {navbtntxt('Insights', ``, {})}
    </button>

    var IMPORT_DIV = isEmpty(guestid_guestinfo_map)
        && guestids.length === 0
        && guestexcelattrs.toString() === ["UID", "Name", "Email", "Contact", "Address", "Category", "RSVP", 'Updated On', "Send", "Actions"].toString()
        && !rsvpactive ? <button className={"iglbtn" + rsvpactive} id={'csvparent'} >
        <CSVReader
            style={{ backgroundColor: 'transparent', minWidth: 80, maxWidth: 80, }}
            onDrop={(row_obj) => {
                var size = new Blob([JSON.stringify(row_obj)]).size

                if (size > 262144000) { // 250mb
                    alert('.csv file too big')
                }
                else {
                    var NAME_INDEX_MAP = {
                        Name: -1,
                        Email: -1,
                        Contact: -1,
                        Address: -1,
                        Category: -1,
                        RSVP: -1,
                        'Updated On': -1
                    }
                    var firstrow = row_obj[0]['data']

                    // first row is the fixed columns FIXED_COLUMNS
                    for (let i = 0; i < firstrow.length; i++) {
                        var cellname = firstrow[i]
                        NAME_INDEX_MAP[cellname] = i  // assign the corresponding index to the map
                    }

                    // update every single rows based on the uploaded data
                    var local_guestid_guestinfo_map = {}
                    var local_guestids = []
                    for (let i = 1; i < row_obj.length; i++) {
                        var key = uuidv4()
                        var row = row_obj[i]['data']
                        local_guestids.push(key)
                        local_guestid_guestinfo_map[key] = {
                            Name: row[NAME_INDEX_MAP['Name']] ? row[NAME_INDEX_MAP['Name']] : '',
                            Email: row[NAME_INDEX_MAP['Email']] ? row[NAME_INDEX_MAP['Email']] : '',
                            Contact: row[NAME_INDEX_MAP['Contact']] ? row[NAME_INDEX_MAP['Contact']] : '',
                            Address: row[NAME_INDEX_MAP['Address']] ? row[NAME_INDEX_MAP['Address']] : '',
                            Category: row[NAME_INDEX_MAP['Category']] ? row[NAME_INDEX_MAP['Category']] : '',
                            RSVP: row[NAME_INDEX_MAP['RSVP']] ? row[NAME_INDEX_MAP['RSVP']] : '',
                            'Updated On': row[NAME_INDEX_MAP['Updated On']] ? row[NAME_INDEX_MAP['Updated On']] : '',
                        }
                    }

                    try {
                        let options = {
                            method: 'POST',
                            url: INVITATION_GUESTLIST_IMPORTCSV,
                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                            data: { guestlistreadcounter, invitationid, guestqns: [], guestids: local_guestids, guestid_guestinfo_map: local_guestid_guestinfo_map, allguestqnsuids: ['UID', 'Name', 'Email', 'Contact', 'Address', 'Category', 'RSVP', 'Updated On'] }
                        }
                        axios(options).then(() => {
                            setGuestid_guestinfo_map(local_guestid_guestinfo_map)
                            setGuestids(local_guestids)
                            setGuestexcelattrs(['UID', ...FIXED_COLUMNS, 'Send', 'Actions'])
                            setRendertable(true)
                        })
                    }
                    catch (e) { }
                }
            }}
            onError={() => { }}
            noDrag
        >
            <span style={{ display: "flex", flexDirection: 'row' }}>
                <div className="ig_btnicon"> {IG_UL}</div>
                <div className="ig_btntxt">{'Upload .csv'}</div>
            </span>
        </CSVReader>
    </button>
        : null

    const rsvpactivefunc = () => {

        if (passcode.length > 0) {

            var transitionpage
            var finalpage
            if (rsvptype === 'default') {
                transitionpage = 'publish-default'
                finalpage = 'default'
            }
            else if (rsvptype === 'google') {
                transitionpage = 'publish-google'
                finalpage = 'google'
            }
            setRsvptype(transitionpage)
            setTimeout(() => {
                try {
                    let options = {
                        method: 'POST',
                        url: !rsvpactive ? INVITATION_GUESTLIST_RSVPON : INVITATION_GUESTLIST_RSVPOFF,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                        data: { guestlistreadcounter, invitationid, urlhandler, passcode }
                    }
                    axios(options).then((item) => {
                        if (rsvpactive) { // rsvp is on going to off, need retrieve latest data
                            // rsvp off is set subsequently, so current state is on
                            dissectInit(item)
                        }

                        setRsvptype(finalpage)
                        setRsvpactive(!rsvpactive)
                        props.updateRsvpactive(!rsvpactive) // update iedit rsvpactive
                        setRendertable(true)

                    }).catch((e) => { })
                }
                catch (e) { }
            }, TIMER_CONSTANT)

        }
        else {
            setPasscodemodal(true)
        }

    }

    var PUBLISH_DIV = (
        <button
            className={rsvpactive ? 'ig_published' : 'ig_publish'}
            onClick={rsvpactivefunc}>
            {rsvpactive ? iconwrapper(TICK_ICON, THICKICON_CSS) : null}
            <div>{rsvpactive ? 'Published' : 'Publish'}</div>
        </button>
    )

    var PREVIEW_BTN = (rsvpactive && DEVICE !== 'MOBILE') || rsvptype === 'google'
        ? <button className="iepreview_btn" onClick={() => { document.getElementById('iepreview_btninvisible').click() }} >
            {iconwrapper(EYE_ICON, THICKICON_CSS)} Preview
            <Link id="iepreview_btninvisible" to={'/invitation/' + urlhandler + (passcode.length > 0 ? '/passcode=' + passcode : '')} target="_blank" />
        </button>
        : null

    const [refreshtime, setRefreshtime] = useState(0)
    const [refreshstate, setRefreshstate] = useState('STOP') // START STOP LOADING
    const [refreshmsg, setRefreshmsg] = useState('')

    useEffect(() => {
        if (refreshstate === 'LOADING') {
            init()
            setRefreshmsg('Retrieving Latest Data')
            setTimeout(() => {
                setRefreshmsg('')
                setRefreshstate('START')
            }, 3000)
        }
        else if (refreshtime < 30 && refreshstate === 'START') {
            setTimeout(() => {
                setRefreshtime(refreshtime + 1)
            }, 1000)
        }

        if (refreshtime === 30) {
            setRefreshstate('STOP')
            setRefreshtime(0)
            setRefreshmsg('')
        }
    }, [refreshtime, refreshstate])

    var REFRESH_BTN = DEVICE !== 'MOBILE'
        ? <button
            className='igl_btnrefresh'
            onClick={() => {
                if (refreshstate === 'STOP') {
                    Uncheck_allcheckbox()
                    setRefreshstate('LOADING')
                }
            }}
        >
            {refreshstate === 'START'
                ? <div className='igl_numbertimer'>{refreshtime}</div>
                : iconwrapper(REFRESH20_ICON, { width: 26, height: 26 })}
        </button>
        : null

    // var ADDGUEST_BTN = (tableloaded && rsvptype === 'default' && !rsvpactive && !addmodal && !editmodal && !addcatmodal && !addmsgmodal && !shortcutmodal && !alternativemodal && !settingmodal)
    var ADDGUEST_BTN = (tableloaded && rsvptype === 'default' && !rsvpactive)
        ? <button
            className="igladdbtn"
            onClick={() => {
                if (!addmodal && !editmodal && !addcatmodal && !addmsgmodal && !shortcutmodal && !alternativemodal && !settingmodal && !swapmodal) {
                    setAddmodal(!addmodal);
                    setAddstate('IDLE')
                }
            }}
        >
            {iconwrapper(ADDGUEST20_ICON, { width: 26, height: 26, })}
        </button>
        : null

    var CheckboxModal = checkboxmodal
        ? <div style={{ position: 'absolute', top: 0, left: 0, width: 0, height: 0 }} />
        : null

    // const portalmidcss = (DEVICE === 'WEB' || DEVICE === 'TABLET')
    //     ? { position: 'fixed', top: 'calc(50% - 10px)', transform: 'translate(-50%, -50%)', width: 200, height: 'max-content', margin: '10px 0px', right: 0 }
    //     : { position: 'fixed', width: '100%', height: '100%', margin: 0, top: '50%' }

    var PasscodeModal =
        popupmsg.length > 0
            ? <Noti noti={popupmsg} />
            : <Portalmodal
                portaltype={'commonportal'}
                open={passcodemodal}
                onClose={() => setPasscodemodal(!passcodemodal)}
                floatingclose={false}
                backdropvisible={true}
                modalstyle={{ ...portalmidcss, width: 250, visibility: popupmsg.length > 0 ? 'hidden' : 'visible' }}
                closestyle={{ right: 0 }}
                floatingclosecolor='#333333'
            >
                <div style={{ width: 250, height: 230, backgroundColor: '#ffffff' }}>
                    <div className='Rsvpexplorer_portaltitle'>Passcode</div>
                    {describe_dom('Set passcode before publishing.')}
                    {input_dom(
                        'New Passcode',
                        'Passcode',
                        newpasscode,
                        (e) => {
                            if (e.target.value.length < 50 && passcode.length === 0) {
                                setNewpasscode(e.target.value)
                            }
                        },
                        'passcode_id',
                        {},
                        false,
                        null,
                        {},
                        { margin: 0, width: '100%', padding: '0px 10px' }
                    )}
                    <button
                        className='Rsvpexplorer_portalsave'
                        onClick={() => {

                            setPopupmsg('Saving...')

                            let options = {
                                method: 'POST',
                                url: USER_EDITICPASSCODE,
                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                data: { newpasscode, active_ic_id: invitationid, passcode }
                            }
                            axios(options).then((result) => {
                                const { message } = result.data
                                setTimeout(() => {
                                    if (message === 'SUCCESS') {
                                        setPopupmsg('Saved')
                                        setTimeout(() => {
                                            setPasscode(newpasscode)
                                            setNewpasscode('')
                                            setPasscodemodal(false)
                                            setPopupmsg('')
                                        }, 1250)

                                    }
                                }, 1250)

                            })
                        }}
                    >
                        Update
                    </button>
                </div>
            </Portalmodal>



    function iOS() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    const updatesearchtabledata = (searchtxt, searchcatselected, sorting) => {
        var searchrows = []
        var sorted_guestids = runsort(sorting)
        if (searchtxt.length === 0 && searchcatselected.length > 0) {
            for (let i = 0; i < sorted_guestids.length; i++) {
                const { id, index } = sorted_guestids[i]
                if (tagcompare(guestid_guestinfo_map[id], searchcatselected)) {
                    searchrows.push(genrow(id, index))
                }
            }
        }
        else if (searchtxt.length > 0 && searchcatselected.length === 0) {
            setSearchcat((searchcat) => {
                for (let i = 0; i < sorted_guestids.length; i++) {
                    const { id, index } = sorted_guestids[i]
                    if (charcomparing(searchtxt, guestid_guestinfo_map[id][searchcat].toLowerCase())) {
                        searchrows.push(genrow(id, index))
                    }
                }
                return searchcat
            })
        }
        else if (searchtxt.length === 0 && searchcatselected.length === 0) {
            for (let i = 0; i < sorted_guestids.length; i++) {
                const { id, index } = sorted_guestids[i]
                searchrows.push(genrow(id, index))
            }
        }
        else {
            for (let i = 0; i < sorted_guestids.length; i++) {
                const { id, index } = sorted_guestids[i]
                setSearchcat((searchcat) => {
                    setSearchcatselected((searchcatselected) => {
                        if (
                            charcomparing(searchtxt, guestid_guestinfo_map[id][searchcat].toLowerCase()) &&
                            tagcompare(guestid_guestinfo_map[id], searchcatselected)
                        ) {
                            searchrows.push(genrow(id, index))
                        }
                        return searchcatselected
                    })
                    return searchcat
                })
            }
        }
        setSearchrows(searchrows)
    }

    const closepage = () => {
        setMainpage('GUESTLIST')
        setTableloaded(false)
        setTimeout(() => {
            setTableloaded(true)
        }, TIMER_CONSTANT)
    }

    useMemo(() => {
        setActualtable(
            <Table
                rsvpactive={rsvpactive}
                columns={columns}
                guestqnssettingsmap={guestqnssettingsmap}
                urlhandler={urlhandler}
                invitationid={invitationid}
                data={!searchactivate && rows ? rows : searchrows}
                updateRendercounter={() => { setRendercounter(t => t + 1) }}
                updatePosition={(val) => { setScrollposition(val) }}
                updateSelectedids={(idsmap) => setSelectedidsmap(idsmap)}
                updateSettings={(setting_map) => {
                    if (DEVICE === 'MOBILE') {
                        setRendertable(true)
                    }
                    setGuestqnssettingsmap(setting_map)

                }}
                rowClick={(row) => {
                    const { guestid } = row.original
                    const { index_uid } = row.values
                    const data = guestid_guestinfo_map[guestid]
                    setEdituuid(guestid)
                    setEditdata({ ...data, index_uid: index_uid }) // { Name, Email, Contact, Address, Category, RSVP }
                    setEditmodal(true)
                }}
            />
        )
    }, [guestids, rendertable, columns, guestqnssettingsmap, rows, searchrows, searchactivate, editdata, editmodal, edituuid, checkboxmodal])

    const Reset_DeleteGuestPopup = () => {
        setShowdeletepopupcontent('');
        setShowdeletepopupguestid('')
        setShowdeletepopup(false);
    }

    const Reset_StatusPopup = () => {
        setShowstatuspopup(false)
        setStatuspopupdata({})
        setStatuscommunicationtool('')
        setStatusguestid('')
    }

    const Reset_ContactPopup = () => {
        setShowgeneralpopup(false)
    }

    const Uncheck_allcheckbox = () => {
        // if any checkboxes clicked, remvoe them all
        if (checkboxesticked > 0 || (mainref && mainref.current && mainref.current.checked)) {
            var selectedidsmap = {}
            for (let i = 0; i < guestids.length; i++) {
                if (elRefs[i] && elRefs[i].current && elRefs[i].current.checked) {
                    elRefs[i].current.click()
                }
                selectedidsmap[guestids[i]] = false
            }
            setCheckboxesticked(0)
            setSelectedidsmap(selectedidsmap)
            if (mainref && mainref.current && mainref.current.checked) {
                setMaincheck(false)
                mainref.current.click()
            }
        }
    }

    const Remove_Guestid_Guestqncatind = (rmvid, initguestqns) => {
        var guestqns = initguestqns.slice()
        for (let i = 0; i < guestqns.length; i++) {
            if (guestqns[i][3] === 'catind') {
                if (guestqns[i][5].includes(rmvid)) {
                    guestqns[i][5].splice(guestqns[i][5].indexOf(rmvid), 1)
                }
            }
        }
        return guestqns

    }

    const status_info = (txt) => { // bear
        const EXPLANATION = {
            'replied': `"Replied" indicate that user has already replied to your RSVP.`,
            'reminder': `"Reminder" indicate that user has not respond to your RSVP, and you send another reminder to inform your guest.`,
            'sent': `"Sent" indicate that RSVP is sent and waiting for your guest reply.`,
            'update': `"Update" indicate you have changes in your RSVP, new replies required from your guest.`
        }
        const CASING = {
            'replied': 'Replied',
            'reminder': 'Reminder',
            'sent': 'Sent',
            'update': 'Update',
        }
        return <button
            className='igl_statusbtnorigin'
            onClick={() => {
                var data = {
                    passcode: passcode,
                    invitationid: invitationid,
                    guestlistreadcounter,
                    status: {
                        'date': new Date().toLocaleString(),
                        'from': statuscommunicationtool,
                        'action': txt
                    },
                    urlhandler,
                    guestid: statusguestid
                }
                setStatuspopupdata(data)
            }}>
            <button className={`igl_statusbtnroot ${(Object.keys(statuspopupdata).length > 0 && statuspopupdata.status.action) === txt ? 'igl_statusbtnroot_highlight' : ''}`}>
                {CASING[txt]}
            </button>
            <div className={`igl_statusexplain ${(Object.keys(statuspopupdata).length > 0 && statuspopupdata.status.action) === txt ? 'igl_statusexplain_highlight' : ''}`}>
                {EXPLANATION[txt]}
            </div>
        </button>

    }

    if (loading) {
        const { page } = props.match.params
        if (page !== 'gl') return <Loading logo={true} />
        else return null
    }
    else {
        return addqnmodalloading
            ? <div className="igl_root">
                <div className="ig_tableloading">Loading Question Explorer...</div>
            </div>
            : mainpage === 'QUESTIONEXPLORER'
                ? <Rsvpqnexplorer
                    guestlistreadcounter={guestlistreadcounter}
                    invitationid={invitationid}
                    close={closepage}
                    guestid_guestinfo_map={guestid_guestinfo_map}
                    guestids={guestids}
                    guestcats={guestcats}
                    guestqns={guestqns}
                    guestqnsuids={guestqnsuids}
                    guestqnsswapped={guestqnsswapped}
                    guestqnssettingsmap={guestqnssettingsmap}
                    guestqnsmap={guestqnsmap}
                    rsvpqnexplorerexpandablestates={rsvpqnexplorerexpandablestates}
                    AddcatModal={AddcatModal} // modal
                    opencatmodal={() => { setAddcatmodal(true) }}
                    rsvpactive={rsvpactive}
                    rsvpactivefunc={rsvpactivefunc}
                    updateswap={(new_guestqns, new_expandablestates, keeptrackold, expandablestates) => {
                        if (keeptrackold) {
                            setOldguestqns(JSON.parse(JSON.stringify(guestqns)))
                            setOldexpandablestates(JSON.parse(JSON.stringify(expandablestates)))
                            setGuestqnsswapped(true)
                        }
                        setRsvpqnexplorerexpandablestates(new_expandablestates)
                        setGuestqns(new_guestqns)
                    }}
                    cancelswap={() => {
                        setGuestqns(oldguestqns)
                        setRsvpqnexplorerexpandablestates(oldexpandablestates)
                        setGuestqnsswapped(false)
                    }}
                    successswap={(new_guestqns, new_expandablestatess) => {
                        setRsvpqnexplorerexpandablestates(new_expandablestatess)
                        setGuestqns(new_guestqns)
                        setGuestqnsswapped(false)
                        setRendertable(true)
                    }}
                    successupdate={(data, expandablestates) => {
                        const { allguestqnsuids, guestqns, guestid_guestinfo_map, guestqnsmap, guestids, guestqnsuids } = data

                        setAllguestqnsuids(allguestqnsuids)
                        var attrs = []
                        for (let i = 1; i < allguestqnsuids.length; i++) {
                            attrs.push(guestqnsmap[allguestqnsuids[i]])
                        }
                        setGuestexcelattrs([...attrs, 'Send', 'Actions']) // combine of default allguestqnsuids and user's qns
                        setGuestqnssettingsmap(data.guestqnssettingsmap)
                        setGuestqns(guestqns)
                        setGuestid_guestinfo_map(guestid_guestinfo_map)
                        setGuestqnsmap(guestqnsmap)
                        setInitialguestids(guestids)
                        setGuestids(guestids)
                        setGuestqnsuids(guestqnsuids)

                        // for rsvpqnexplorer
                        setRsvpqnexplorerexpandablestates(expandablestates)

                        setRendertable(true)

                    }}
                />
                : mainpage === 'STATS'
                    ? <Statistics
                        close={closepage}
                        guestqns={guestqns}
                        grp_guestids_map={grp_guestids_map}
                        guestid_guestinfo_map={guestid_guestinfo_map}
                        guestqnsmap={guestqnsmap}
                        allguestqnsuids={allguestqnsuids}
                        guestids={guestids}
                        guestcats={guestcats}
                        itype={itype}
                    />
                    : <div id="igl_allroot">
                        {AddnewguestModal}
                        {EditexistingguestModal}
                        {AddcatModal}
                        {AddgrpModal}
                        {AddmsgModal}
                        {ShortcutModal}
                        {AlternativeModal}
                        {ModdeleteModal}
                        {SearchfilterModal}
                        {SearchcatModal}
                        {SettingModal}
                        {SwapModal}
                        {CheckboxModal}
                        {{
                            'GUESTLIST': <div className={`igl_root`} style={{ minHeight: isMobile ? '100%' : '100vh' }}>
                                {/* {rsvpactive ? <div className={`igl_disabledscreen`} /> : null} */}
                                {{
                                    'default': tableloaded
                                        ? <>

                                            {/* {showstatuspopup
                                                ? <Modcfm
                                                    txt={<div>{statuspopupcontent}</div>}
                                                    onNo={Reset_StatusPopup}
                                                    onOk={() => {
                                                        let options = {
                                                            method: 'POST',
                                                            url: INVITATION_GUESTLIST_UPDATESTATUS,
                                                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                                            data: statuspopupdata
                                                        }
                                                        axios(options).then((result) => {
                                                            const { message } = result.data
                                                            const { guestid, status } = statuspopupdata
                                                            if (message === 'SUCCESS') {
                                                                setGuestid_guestinfo_map((guestid_guestinfo_map) => {
                                                                    guestid_guestinfo_map[guestid]['10-status'] = status
                                                                    return guestid_guestinfo_map
                                                                })
                                                                setRendertable(true)
                                                                Reset_StatusPopup()
                                                            }
                                                            else {
                                                                Reset_StatusPopup()
                                                                singleoptionpopup(FAIL_TO_UPDATE_STATUS)
                                                            }

                                                        })

                                                    }}
                                                />
                                                : null} */}


                                            {showstatuspopup // bear
                                                ? <Modcfm
                                                    txt={<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                        <div className='igl_statustitle'>{`Update Status for ${statusguestname.length > 0 ? statusguestname : 'No-Name'} `}</div>
                                                        {status_info('sent')}
                                                        {status_info('replied')}
                                                        {status_info('reminder')}
                                                        {status_info('update')}
                                                    </div>}
                                                    onNo={Reset_StatusPopup}
                                                    showcrossonly={Object.keys(statuspopupdata).length > 0 ? false : true}
                                                    showallonly={Object.keys(statuspopupdata).length > 0 ? true : false}
                                                    showtickonly={false}
                                                    onOk={() => {

                                                        let options = {
                                                            method: 'POST',
                                                            url: INVITATION_GUESTLIST_UPDATESTATUS,
                                                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                                            data: statuspopupdata
                                                        }
                                                        axios(options).then((result) => {
                                                            const { message } = result.data
                                                            const { guestid, status } = statuspopupdata
                                                            if (message === 'SUCCESS') {
                                                                setGuestid_guestinfo_map((guestid_guestinfo_map) => {
                                                                    guestid_guestinfo_map[guestid]['10-status'] = status
                                                                    return guestid_guestinfo_map
                                                                })
                                                                Reset_StatusPopup()
                                                                setRendertable(true)
                                                                setLoading(false)
                                                                // pikachu
                                                            }
                                                            else {
                                                                Reset_StatusPopup()
                                                                singleoptionpopup(FAIL_TO_UPDATE_STATUS)
                                                            }

                                                        })

                                                    }}
                                                />
                                                : null}


                                            {showdeletepopup
                                                ? <Modcfm
                                                    txt={showdeletepopupcontent}
                                                    onNo={Reset_DeleteGuestPopup}
                                                    onOk={() => {
                                                        if (!rsvpactive) {
                                                            var guestid = showdeletepopupguestid
                                                            setGuestid_guestinfo_map((guestid_guestinfo_map) => {

                                                                var new_grp_guestids_map = {}
                                                                var arr_grp_guestids_map = Object.keys(grp_guestids_map)
                                                                for (let i = 0; i < arr_grp_guestids_map.length; i++) {
                                                                    const grpname = arr_grp_guestids_map[i]
                                                                    new_grp_guestids_map[grpname] = grp_guestids_map[grpname].slice()
                                                                    // check if guestid within new_grp...
                                                                    const index = new_grp_guestids_map[grpname].indexOf(guestid)
                                                                    if (index > -1) {
                                                                        new_grp_guestids_map[grpname].splice(index, 1)
                                                                    }
                                                                }

                                                                // accept, decline, noreply, guestidrsvpstate
                                                                var guestids_size = guestids.length
                                                                var guestidrsvpstate = guestid_guestinfo_map[guestid]['8-rsvp']
                                                                var accept = 0
                                                                var decline = 0
                                                                var noreply = 0

                                                                // var acceptarr = []
                                                                // var declinearr = []
                                                                // var noreplyarr = []
                                                                for (let i = 0; i < guestids.length; i++) {
                                                                    const local_id = guestids[i]
                                                                    const rsvpstate = guestid_guestinfo_map[local_id]['8-rsvp']
                                                                    if (rsvpstate === 'accept') {
                                                                        accept++;
                                                                        // acceptarr.push(local_id)
                                                                    }
                                                                    else if (rsvpstate === 'decline') {
                                                                        decline++;
                                                                        // declinearr.push(local_id)
                                                                    }
                                                                    else if (rsvpstate === '') {
                                                                        noreply++;
                                                                        // noreplyarr.push(local_id)
                                                                    }
                                                                }
                                                              
                                                                let options = {
                                                                    method: 'POST',
                                                                    url: INVITATION_GUESTLIST_RMVGUEST,
                                                                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                                                    data: { _id: invitationid, guestgrps: guestgrps, urlhandler: urlhandler, guestid, guestids_size, accept, decline, noreply, guestidrsvpstate, grp_guestids_map, new_grp_guestids_map, guestlistreadcounter, guestqnsuids }
                                                                }
                                                                axios(options).then((result) => {

                                                                    const { message } = result.data

                                                                    if (message === 'SUCCESS') {
                                                                        // need to check this lines of code again to ensure delete will update correctly

                                                                        let new_guestid_guestinfo_map = Object.assign({}, guestid_guestinfo_map)
                                                                        delete new_guestid_guestinfo_map[guestid]

                                                                        let new_guestids = guestids.slice()
                                                                        const guestid_index = new_guestids.indexOf(guestid)
                                                                        new_guestids.splice(guestid_index, 1)

                                                                        let new_initialguestids = initialguestids.slice()
                                                                        const initialguestid_index = new_initialguestids.indexOf(guestid)
                                                                        new_initialguestids.splice(initialguestid_index, 1)

                                                                        setGuestqns((initialguestqns) => {
                                                                            return Remove_Guestid_Guestqncatind(guestid, initialguestqns.slice())
                                                                        })

                                                                        setSelectedidsmap((selectedidsmap) => {
                                                                            selectedidsmap[guestid] = false
                                                                            return selectedidsmap
                                                                        })

                                                                        setCheckboxesticked(0)

                                                                        setRendertable(() => {
                                                                            setGuestid_guestinfo_map(new_guestid_guestinfo_map)
                                                                            setInitialguestids(new_initialguestids)
                                                                            setGuestids(new_guestids)
                                                                            return true
                                                                        })
                                                                        Reset_DeleteGuestPopup()
                                                                    }
                                                                    else {
                                                                        Reset_DeleteGuestPopup()
                                                                        singleoptionpopup(FAIL_TO_DELETE_GUEST)
                                                                    }

                                                                })

                                                                return guestid_guestinfo_map
                                                            })
                                                        }
                                                    }}
                                                />
                                                : null}

                                            {showgeneralpopup
                                                ? <Modcfm
                                                    txt={showgeneralpopupcontent}
                                                    onNo={Reset_ContactPopup}
                                                    onOk={Reset_ContactPopup}
                                                    showallonly={false}
                                                    showtickonly={true}
                                                />
                                                : null}

                                            <Noti noti={refreshmsg} />

                                            <div className="Iedittopbarleftmenu_root">

                                                {!rsvpactive
                                                    ? <div className="ig_toprowbtns" style={{ height: 60 }}>
                                                        {/* ? <div className="ig_toprowbtns"> */}
                                                        {ADDQN_DIV}{STATS_DIV}{ADDCAT_DIV}{ADDGROUP_DIV}{CLEARALL_DIV}{MSG_DIV}{IMPORT_DIV}{ALTERNATIVE_DIV}{SWAP_DIV}{SETTING_DIV}{EXPORT_DIV}
                                                    </div>
                                                    : <div className="ig_toprowbtns" >
                                                        {ADDQN_DIV}{STATS_DIV}{MSG_DIV}{IMPORT_DIV}{SETTING_DIV}
                                                    </div>
                                                }

                                                <div className='ig_publishroot'>
                                                    {REFRESH_BTN}
                                                    {ADDGUEST_BTN}
                                                    {PREVIEW_BTN}
                                                    <div style={{ width: 5 }} />
                                                    {PUBLISH_DIV}
                                                </div>

                                            </div>
                                            <Invsearch
                                                searchcat={searchcat}
                                                searchcatselected={searchcatselected}
                                                opensearchfiltermodal={() => setSearchfiltermodal(true)}
                                                opensearchcatmodal={() => setSearchcatmodal(true)}
                                                updatesearch={(val) => {

                                                    Uncheck_allcheckbox()

                                                    var temp = true
                                                    if (!searchactivate && (val.length > 0 || searchcatselected.length > 0)) {
                                                        temp = true
                                                        setSearchactivate(true)
                                                    }
                                                    else if (val.length === 0 && searchcatselected.length === 0) {
                                                        temp = false
                                                        setSearchactivate(false)
                                                    }
                                                    else {
                                                        temp = true
                                                        setSearchactivate(true)
                                                    }

                                                    if (temp) {
                                                        const value = val.toLowerCase()
                                                        updatesearchtabledata(value, searchcatselected, sorting)
                                                        setSearchtxt(value)
                                                    }

                                                }}
                                            />
                                            {{
                                                'MOBILE': !rendertable ? actualtable : null,
                                                'TABLET': actualtable,
                                                'WEB': actualtable
                                            }[DEVICE]}
                                        </>
                                        : <div className="ig_tableloading">Loading Guest List...</div>,
                                    'google':
                                        <>
                                            <div className="Iedittopbarleftmenu_root" style={{ borderBottom: '1px solid rgb(221, 221, 221)' }}>
                                                <div className='ig_toprowbtns'>{rsvpactive ? null : ALTERNATIVE_DIV}</div>
                                                <div className='ig_publishroot' style={{ flexDirection: rsvpactive ? 'row' : 'row-reverse' }}>
                                                    {PUBLISH_DIV}
                                                    <div style={{ width: 5 }} />
                                                    {PREVIEW_BTN}
                                                </div>
                                            </div>

                                            <div className="ig_altrsvproot">

                                                <div>You are using alternative rsvp method.</div>
                                                <div className='ig_altrsvplabel' >{(rsvpalt[0] && rsvpalt[0].length > 0) ? rsvpalt[0] : 'Your Link'}</div>
                                            </div>
                                        </>,
                                    'default-publish': <>
                                        <div className="Iedittopbarleftmenu_root" />
                                        <div className="ig_tableloading">Publishing...</div>
                                    </>,
                                    'publish-default': <>
                                        <div className="Iedittopbarleftmenu_root" />
                                        <div className="ig_tableloading">Loading...</div>
                                    </>,
                                    'google-publish': <>
                                        <div className="Iedittopbarleftmenu_root" />
                                        <div className="ig_tableloading">Publishing...</div>
                                    </>,
                                    'publish-google': <>
                                        <div className="Iedittopbarleftmenu_root" />
                                        <div className="ig_tableloading">Loading...</div>
                                    </>,
                                }[rsvptype]}
                            </div>

                        }[mainpage]}
                        {PasscodeModal}
                    </div>
    }

}
export default Invitationguestlist