
import React, { useEffect, useRef, useState } from 'react'
import { iconwrapper } from '../../../../../../config/commonfunctions'
import { EMPTYSPACE, } from '../../../../../../config/constants'
import { ADD_ICON, CROSS_ICON } from '../../../../../../config/svgicons'
import { describe_dom, partialtitle_dom } from '../../../Invitationmaker/components/Commonelements/Commonelements'

function Addtablecontent({TABLE_MAX, update }) {

    const [addtablemax, setAddtablemax] = useState(1)
    const [addtableobjs, setAddtableobjs] = useState([{ name: '', max: 8, seatnames: [], relas: [], cats: [] }])
    const btmref = useRef(null)

    useEffect(()=>{
        btmref.current.scrollIntoView({
            behavior: "smooth"
          });
    },[addtablemax])

    var inputitems = []
    for (let i = 0; i < addtablemax; i++) {
        const _i = i
        inputitems.push(
            <div key={_i + 'addtables'} className="scedit_troot" >
                <div style={{ display: 'flex', placeContent: 'space-between' }}>
                    <div style={{ padding: '0px 5px 0px 0px', color: 'var(--mainpink)', display: 'flex', fontSize: 16 }}>{_i + 1}</div>
                    <button
                        className='scedit_ddbtn'
                        onClick={() => {
                            setAddtablemax(addtablemax - 1)
                            setAddtableobjs((a) => {
                                a.splice(_i, 1)
                                return a
                            })
                        }}
                    >
                        {iconwrapper(CROSS_ICON, { width: 24, height: 24, color: 'var(--mainpink)' })}
                    </button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', }}>
                    <aside style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '80%', minHeight: 55 }}>
                        {partialtitle_dom('Table Name', {})}
                        <input
                            style={{ height: 40, padding: 5, borderRadius: 5, width: '100%', whiteSpace: 'nowrap', outline: 'none', fontSize: 14, border: 'thin solid #707070', alignSelf: 'flex-start', }}
                            value={addtableobjs[_i]['name']}
                            onChange={(e) => {
                                var local_addtableobjs = addtableobjs.slice()
                                local_addtableobjs[_i]['name'] = e.target.value
                                setAddtableobjs(local_addtableobjs)
                            }} />
                    </aside>
                    {EMPTYSPACE}
                    <aside style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '20%', minHeight: 55 }}>
                        {partialtitle_dom('Max', {})}
                        <input
                            style={{ height: 40, padding: 5, borderRadius: 5, width: '100%', whiteSpace: 'nowrap', outline: 'none', fontSize: 14, border: 'thin solid #707070', alignSelf: 'flex-start', }}
                            type="number"
                            value={addtableobjs[_i]['max']}
                            onChange={(e) => {
                                var local_addtableobjs = addtableobjs.slice()
                                local_addtableobjs[_i]['max'] = e.target.value
                                setAddtableobjs(local_addtableobjs)
                            }} />
                    </aside>
                </div>
            </div>
        )
    }




    const add = () => {
        var local_addtableobjs = addtableobjs.slice()
        local_addtableobjs.push({ name: '', max: 8, seatnames: [], relas: [], cats: [] })
        setAddtableobjs(local_addtableobjs)
        setAddtablemax(addtablemax + 1)
     
    }

    const ADDICON_CSS = { width: 24, height: 24, display: 'flex', placeContent: 'center' }

    
    return <div className='Rsvpexplorer_portal' >
        <div className='Rsvpexplorer_portaltitle'>Add New Table</div>
        {describe_dom(`You can add a maximum of ${TABLE_MAX} tables.`, {})}
        <div style={{ display: 'flex', flexDirection: 'column', height: 'calc( 100% - 170px)' }}>
            <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 'auto', height: 'auto', width: '100%', overflowY: 'auto', padding: '0px 10px' }}>
                {inputitems}
                <div ref={btmref}/>
            </div>
            {inputitems.length > 9
                ? null
                : <button className='Rsvpexplorer_portaladdnewguest' style={{ width: '100%', placeContent: 'center', minHeight: 40 }} onClick={add}>
                    {iconwrapper(ADD_ICON, ADDICON_CSS)}
                </button>}
            <button className='Rsvpexplorer_portalsave' onClick={() => update(addtableobjs)}>Update</button>
        </div>

    </div>

}

export default Addtablecontent