import { S3CLOUD_CLOUDFRONT_ASSET } from '../../config/s3directories'

const cat = 'floral'

const LL = 0
const LR = 1
const RL = 2
const RR = 3

const thumbnailurl = (n) => `${S3CLOUD_CLOUDFRONT_ASSET}${cat}/${n}/thumbnail`
const lookupurl = (n) => `${S3CLOUD_CLOUDFRONT_ASSET}${cat}/${n}/lookup`
const bannerurl = (n) => `${S3CLOUD_CLOUDFRONT_ASSET}${cat}/${n}/banner`
const seatchart = (n) => `${S3CLOUD_CLOUDFRONT_ASSET}${cat}/${n}/seatchart`

export const allimages = new Map([
    [ // Empty
        'Empty',
        {
            name: `Empty`,
            cat: 'Floral',
            summary: ``,
            background: {
                topleft: {
                    url: ['', '']
                },
                topright: {
                    url: ['', '']
                },
                btmright: {
                    url: ['', '']
                },
                btmleft: {
                    url: ['', '']
                },
                midleft: {
                    url: []
                },
                midright: {
                    url: []
                },
                top: {
                    url: []
                },
                btm: {
                    url: ['', '']
                },
            },
            thumbnail: { // thumbnail use btmleft
                url: ['', '']
            },
            banner: {
                url: ['', '']
            },
            corner: {
                topleft: {
                    url: ['', '']
                },
                topright: {
                    url: ['', '']
                },
            },
            itype: ['Wedding'],
            orientation_allow_list: [
                { value: LL, state: true, },
                { value: LR, state: true, },
                { value: RL, state: true, },
                { value: RR, state: true, },
            ],
            bgformat: [ // determine whatimage to be used
                {
                    // 0 TOP_RIGHT_BTM_LEFT
                    // detemine the orientation of image, bg1 and bg2 is the address of the img for background
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topright', bg2: 'btmleft' },
                    MOBILE: { state: true, bg1: 'topright', bg2: 'btmleft' }
                },
                {
                    // 1 TOP_LEFT_BTM_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topleft', bg2: 'btmright' },
                    MOBILE: { state: true, bg1: 'topleft', bg2: 'btmright' }
                },
                {
                    // 2 BTM_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'btmleft', bg2: 'btmright' },
                    MOBILE: { state: false, bg1: 'btmleft', bg2: 'btmright' }
                },
                {
                    // 3 TOP_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'topleft', bg2: 'topright' },
                    MOBILE: { state: false, bg1: 'topleft', bg2: 'topright' }
                },
                {
                    // 4 MID_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: 'midright' },
                    MOBILE: { state: false, bg1: 'midleft', bg2: 'midright' }
                },
                {
                    // 5 MID_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midright', bg2: null },
                    MOBILE: { state: false, bg1: 'midright', bg2: null }
                },
                {
                    // 6 MID_LEFT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: null },
                    MOBILE: { state: false, bg1: 'midleft', bg2: null }
                },
                {
                    // 7 TOP_BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: 'btm' },
                    MOBILE: { state: false, bg1: 'top', bg2: 'btm' }
                },
                {
                    // 8 TOP
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: null },
                    MOBILE: { state: false, bg1: 'top', bg2: null }
                },
                {
                    // 9 BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: false, img1: 0, img2: 1 }, { state: false, img1: 1, img2: 0 }, { state: false, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'btm', bg2: null },
                    MOBILE: { state: true, bg1: 'btm', bg2: null, affectnamepos: { state: true }, }
                }
            ]
        }
    ],
    [ // Amaryllis_Purpurea
        'Amaryllis_Purpurea',
        {
            name: `Amaryllis Purpurea
(Barbados lily)`,
            cat: 'Floral',
            summary: `Amaryllis Purpurea, also known as Barbados lily or Amaryllis, is a bold and elegant flower that is often used in wedding decorations. In the language of flowers, Amaryllis represents pride and determination, symbolizing the couple's commitment and confidence in their relationship. Its large, showy blooms make it a perfect addition to centerpieces, bouquets, and other floral arrangements. Amaryllis is available in different colors, including red, pink, white, and peach, and can be used in weddings throughout the year. Its versatility and beauty make it an ideal choice for creating stunning wedding decor.`,
            background: {
                topleft: {
                    url: [`${lookupurl('Amaryllis_Purpurea')}/topleft0.png`, `${lookupurl('Amaryllis_Purpurea')}/topleft1.png`]
                },
                topright: {
                    url: [`${lookupurl('Amaryllis_Purpurea')}/topright0.png`, `${lookupurl('Amaryllis_Purpurea')}/topright1.png`]
                },
                btmright: {
                    url: [`${lookupurl('Amaryllis_Purpurea')}/btmright0.png`, `${lookupurl('Amaryllis_Purpurea')}/btmright1.png`]
                },
                btmleft: {
                    url: [`${lookupurl('Amaryllis_Purpurea')}/btmleft0.png`, `${lookupurl('Amaryllis_Purpurea')}/btmleft1.png`]
                },
                midleft: {
                    url: []
                },
                midright: {
                    url: []
                },
                top: {
                    url: []
                },
                btm: {
                    url: [`${lookupurl('Amaryllis_Purpurea')}/btm.png`]
                },
            },
            thumbnail: { // thumbnail use btmleft
                url: `${thumbnailurl('Amaryllis_Purpurea')}/thumbnail.png`
            },
            banner: {
                url: `${bannerurl('Amaryllis_Purpurea')}/banner.png`
            },
            corner: {
                topleft: {
                    url: [`${seatchart('Amaryllis_Purpurea')}/seatchart.png`]
                },
                topright: {
                    url: [`${seatchart('Amaryllis_Purpurea')}/seatchart.png`]
                },
            },
            itype: ['Wedding'],
            orientation_allow_list: [
                { value: LL, state: true, },
                { value: LR, state: true, },
                { value: RL, state: true, },
                { value: RR, state: true, },
            ],
            bgformat: [ // determine whatimage to be used
                {
                    // 0 TOP_RIGHT_BTM_LEFT
                    // detemine the orientation of image, bg1 and bg2 is the address of the img for background
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topright', bg2: 'btmleft' },
                    MOBILE: { state: true, bg1: 'topright', bg2: 'btmleft' }
                },
                {
                    // 1 TOP_LEFT_BTM_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topleft', bg2: 'btmright' },
                    MOBILE: { state: true, bg1: 'topleft', bg2: 'btmright' }
                },
                {
                    // 2 BTM_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'btmleft', bg2: 'btmright' },
                    MOBILE: { state: false, bg1: 'btmleft', bg2: 'btmright' }
                },
                {
                    // 3 TOP_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'topleft', bg2: 'topright' },
                    MOBILE: { state: false, bg1: 'topleft', bg2: 'topright' }
                },
                {
                    // 4 MID_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: 'midright' },
                    MOBILE: { state: false, bg1: 'midleft', bg2: 'midright' }
                },
                {
                    // 5 MID_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midright', bg2: null },
                    MOBILE: { state: false, bg1: 'midright', bg2: null }
                },
                {
                    // 6 MID_LEFT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: null },
                    MOBILE: { state: false, bg1: 'midleft', bg2: null }
                },
                {
                    // 7 TOP_BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: 'btm' },
                    MOBILE: { state: false, bg1: 'top', bg2: 'btm' }
                },
                {
                    // 8 TOP
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: null },
                    MOBILE: { state: false, bg1: 'top', bg2: null }
                },
                {
                    // 9 BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: false, img1: 0, img2: 1 }, { state: false, img1: 1, img2: 0 }, { state: false, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'btm', bg2: null },
                    MOBILE: { state: false, bg1: 'btm', bg2: null, affectnamepos: { state: true }, }
                }
            ]
        }
    ],
    [ // Blanket_Flower
        `Blanket_Flower`,
        {
            name: `Blanket flower
(Gaillardia)`,
            cat: 'Floral',
            summary: `Blanket flower, also known as Gaillardia, is a vibrant and colorful flower that can add a cheerful touch to any wedding decor. Its common name is derived from its resemblance to the bright patterns found in Native American blankets. In the language of flowers, Blanket flower represents undying love and devotion, making it a fitting choice for a wedding. Blanket flower blooms in a range of colors, including red, yellow, and orange, and can be used in bridal bouquets, centerpieces, and other floral arrangements. Its bold and cheerful blooms are sure to bring joy and happiness to any wedding celebration.`,
            background: {
                topleft: {
                    url: [`${lookupurl('Blanket_Flower')}/topleft0.png`, `${lookupurl('Blanket_Flower')}/topleft1.png`]
                },
                topright: {
                    url: [`${lookupurl('Blanket_Flower')}/topright0.png`, `${lookupurl('Blanket_Flower')}/topright1.png`]
                },
                btmright: {
                    url: [`${lookupurl('Blanket_Flower')}/btmright0.png`, `${lookupurl('Blanket_Flower')}/btmright1.png`]
                },
                btmleft: {
                    url: [`${lookupurl('Blanket_Flower')}/btmleft0.png`, `${lookupurl('Blanket_Flower')}/btmleft1.png`]
                },
                midleft: {
                    url: []
                },
                midright: {
                    url: []
                },
                top: {
                    url: []
                },
                btm: {
                    url: [`${lookupurl('Blanket_Flower')}/btm.png`]
                },
            },
            thumbnail: { // thumbnail use btmleft
                url: `${thumbnailurl('Blanket_Flower')}/thumbnail.png`
            },
            banner: {
                url: `${bannerurl('Blanket_Flower')}/banner.png`
            },
            corner: {
                topleft: {
                    url: [`${seatchart('Blanket_Flower')}/seatchart.png`]
                },
                topright: {
                    url: [`${seatchart('Blanket_Flower')}/seatchart.png`]
                },
            },
            itype: ['Wedding'],
            orientation_allow_list: [
                { value: LL, state: true, },
                { value: LR, state: true, },
                { value: RL, state: true, },
                { value: RR, state: true, },
            ],
            bgformat: [ // determine whatimage to be used
                {
                    // 0 TOP_RIGHT_BTM_LEFT
                    // detemine the orientation of image, bg1 and bg2 is the address of the img for background
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topright', bg2: 'btmleft' },
                    MOBILE: { state: true, bg1: 'topright', bg2: 'btmleft' }
                },
                {
                    // 1 TOP_LEFT_BTM_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topleft', bg2: 'btmright' },
                    MOBILE: { state: true, bg1: 'topleft', bg2: 'btmright' }
                },
                {
                    // 2 BTM_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'btmleft', bg2: 'btmright' },
                    MOBILE: { state: false, bg1: 'btmleft', bg2: 'btmright' }
                },
                {
                    // 3 TOP_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'topleft', bg2: 'topright' },
                    MOBILE: { state: false, bg1: 'topleft', bg2: 'topright' }
                },
                {
                    // 4 MID_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: 'midright' },
                    MOBILE: { state: false, bg1: 'midleft', bg2: 'midright' }
                },
                {
                    // 5 MID_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midright', bg2: null },
                    MOBILE: { state: false, bg1: 'midright', bg2: null }
                },
                {
                    // 6 MID_LEFT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: null },
                    MOBILE: { state: false, bg1: 'midleft', bg2: null }
                },
                {
                    // 7 TOP_BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: 'btm' },
                    MOBILE: { state: false, bg1: 'top', bg2: 'btm' }
                },
                {
                    // 8 TOP
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: null },
                    MOBILE: { state: false, bg1: 'top', bg2: null }
                },
                {
                    // 9 BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: false, img1: 0, img2: 1 }, { state: false, img1: 1, img2: 0 }, { state: false, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'btm', bg2: null },
                    MOBILE: { state: true, bg1: 'btm', bg2: null, affectnamepos: { state: true }, }
                }
            ]
        }
    ],
    [ // Crimson_Damask
        'Crimson_Damask',
        {
            name: `Crimson Damask
(The Hay Rose Garden)`,
            cat: 'Floral',
            summary: `Roseraie de l'Hay, also known as The Hay Rose Garden, is a beautiful hybrid rugosa rose that is often used in wedding decorations. This rose variety features stunning double blooms in shades of deep pink, mauve, and purple, and is known for its hardiness and disease resistance. In the language of flowers, roses represent love, passion, and devotion, making Roseraie de l'Hay a perfect choice for a wedding. This rose can be used in bridal bouquets, centerpieces, and other floral arrangements, adding a romantic and timeless touch to the wedding decor. Its stunning blooms and sweet fragrance are sure to create a beautiful ambiance for the special day.`,
            background: {
                topleft: {
                    url: [`${lookupurl('Crimson_Damask')}/topleft0.png`, `${lookupurl('Crimson_Damask')}/topleft1.png`]
                },
                topright: {
                    url: [`${lookupurl('Crimson_Damask')}/topright0.png`, `${lookupurl('Crimson_Damask')}/topright1.png`]
                },
                btmright: {
                    url: [`${lookupurl('Crimson_Damask')}/btmright0.png`, `${lookupurl('Crimson_Damask')}/btmright1.png`]
                },
                btmleft: {
                    url: [`${lookupurl('Crimson_Damask')}/btmleft0.png`, `${lookupurl('Crimson_Damask')}/btmleft1.png`]
                },
                midleft: {
                    url: []
                },
                midright: {
                    url: []
                },
                top: {
                    url: []
                },
                btm: {
                    url: [`${lookupurl('Crimson_Damask')}/btm.png`]
                },
            },
            thumbnail: { // thumbnail use btmleft
                url: `${thumbnailurl('Crimson_Damask')}/thumbnail.png`
            },
            banner: {
                url: `${bannerurl('Crimson_Damask')}/banner.png`
            },
            corner: {
                topleft: {
                    url: [`${seatchart('Crimson_Damask')}/seatchart.png`]
                },
                topright: {
                    url: [`${seatchart('Crimson_Damask')}/seatchart.png`]
                },
            },
            itype: ['Wedding'],
            orientation_allow_list: [
                { value: LL, state: true, },
                { value: LR, state: true, },
                { value: RL, state: true, },
                { value: RR, state: true, },
            ],
            bgformat: [ // determine whatimage to be used
                {
                    // 0 TOP_RIGHT_BTM_LEFT
                    // detemine the orientation of image, bg1 and bg2 is the address of the img for background
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topright', bg2: 'btmleft' },
                    MOBILE: { state: true, bg1: 'topright', bg2: 'btmleft' }
                },
                {
                    // 1 TOP_LEFT_BTM_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topleft', bg2: 'btmright' },
                    MOBILE: { state: true, bg1: 'topleft', bg2: 'btmright' }
                },
                {
                    // 2 BTM_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'btmleft', bg2: 'btmright' },
                    MOBILE: { state: false, bg1: 'btmleft', bg2: 'btmright' }
                },
                {
                    // 3 TOP_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'topleft', bg2: 'topright' },
                    MOBILE: { state: false, bg1: 'topleft', bg2: 'topright' }
                },
                {
                    // 4 MID_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: 'midright' },
                    MOBILE: { state: false, bg1: 'midleft', bg2: 'midright' }
                },
                {
                    // 5 MID_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midright', bg2: null },
                    MOBILE: { state: false, bg1: 'midright', bg2: null }
                },
                {
                    // 6 MID_LEFT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: null },
                    MOBILE: { state: false, bg1: 'midleft', bg2: null }
                },
                {
                    // 7 TOP_BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: 'btm' },
                    MOBILE: { state: false, bg1: 'top', bg2: 'btm' }
                },
                {
                    // 8 TOP
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: null },
                    MOBILE: { state: false, bg1: 'top', bg2: null }
                },
                {
                    // 9 BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: false, img1: 0, img2: 1 }, { state: false, img1: 1, img2: 0 }, { state: false, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'btm', bg2: null },
                    MOBILE: { state: true, bg1: 'btm', bg2: null, affectnamepos: { state: true }, }
                }
            ]
        }
    ],
    [ // Helenium
        'Helenium',
        {
            name: `Helenium
(Purple Sneezeweed)`,
            cat: 'Floral',
            summary: `Helenium atropurpureum, also known as Purple Sneezeweed, is a beautiful and unique flower that can add a pop of color to wedding decorations. This flower features vibrant purple petals with a dark center, making it a striking addition to bridal bouquets and centerpieces. In the language of flowers, Helenium represents strength and endurance, symbolizing the couple's commitment to their love and marriage. While not as well-known as some other wedding flowers, Helenium atropurpureum can make a beautiful and unexpected addition to wedding decor, adding a touch of uniqueness and personality to the special day.`,
            background: {
                topleft: {
                    url: [`${lookupurl('Helenium')}/topleft0.png`, `${lookupurl('Helenium')}/topleft1.png`]
                },
                topright: {
                    url: [`${lookupurl('Helenium')}/topright0.png`, `${lookupurl('Helenium')}/topright1.png`]
                },
                btmright: {
                    url: [`${lookupurl('Helenium')}/btmright0.png`, `${lookupurl('Helenium')}/btmright1.png`]
                },
                btmleft: {
                    url: [`${lookupurl('Helenium')}/btmleft0.png`, `${lookupurl('Helenium')}/btmleft1.png`]
                },
                midleft: {
                    url: []
                },
                midright: {
                    url: []
                },
                top: {
                    url: []
                },
                btm: {
                    url: [`${lookupurl('Helenium')}/btm.png`]
                },
            },
            thumbnail: { // thumbnail use btmleft
                url: `${thumbnailurl('Helenium')}/thumbnail.png`
            },
            banner: {
                url: `${bannerurl('Helenium')}/banner.png`
            },
            corner: {
                topleft: {
                    url: [`${seatchart('Helenium')}/seatchart.png`]
                },
                topright: {
                    url: [`${seatchart('Helenium')}/seatchart.png`]
                },
            },
            itype: ['Wedding'],
            orientation_allow_list: [
                { value: LL, state: true, },
                { value: LR, state: true, },
                { value: RL, state: true, },
                { value: RR, state: true, },
            ],
            bgformat: [ // determine whatimage to be used
                {
                    // 0 TOP_RIGHT_BTM_LEFT
                    // detemine the orientation of image, bg1 and bg2 is the address of the img for background
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topright', bg2: 'btmleft' },
                    MOBILE: { state: true, bg1: 'topright', bg2: 'btmleft' }
                },
                {
                    // 1 TOP_LEFT_BTM_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topleft', bg2: 'btmright' },
                    MOBILE: { state: true, bg1: 'topleft', bg2: 'btmright' }
                },
                {
                    // 2 BTM_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'btmleft', bg2: 'btmright' },
                    MOBILE: { state: false, bg1: 'btmleft', bg2: 'btmright' }
                },
                {
                    // 3 TOP_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'topleft', bg2: 'topright' },
                    MOBILE: { state: false, bg1: 'topleft', bg2: 'topright' }
                },
                {
                    // 4 MID_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: 'midright' },
                    MOBILE: { state: false, bg1: 'midleft', bg2: 'midright' }
                },
                {
                    // 5 MID_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midright', bg2: null },
                    MOBILE: { state: false, bg1: 'midright', bg2: null }
                },
                {
                    // 6 MID_LEFT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: null },
                    MOBILE: { state: false, bg1: 'midleft', bg2: null }
                },
                {
                    // 7 TOP_BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: 'btm' },
                    MOBILE: { state: false, bg1: 'top', bg2: 'btm' }
                },
                {
                    // 8 TOP
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: null },
                    MOBILE: { state: false, bg1: 'top', bg2: null }
                },
                {
                    // 9 BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: false, img1: 0, img2: 1 }, { state: false, img1: 1, img2: 0 }, { state: false, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'btm', bg2: null },
                    MOBILE: { state: true, bg1: 'btm', bg2: null, affectnamepos: { state: true }, }
                }
            ]
        }
    ],
    [ // Leptodactylon_Californicum
        'Leptodactylon_Californicum',
        {
            name: `Leptodactylon Californicum
(Prairie flax)`,
            cat: 'Floral',
            summary: ``,
            background: {
                topleft: {
                    url: [`${lookupurl('Leptodactylon_Californicum')}/topleft0.png`, `${lookupurl('Leptodactylon_Californicum')}/topleft1.png`]
                },
                topright: {
                    url: [`${lookupurl('Leptodactylon_Californicum')}/topright0.png`, `${lookupurl('Leptodactylon_Californicum')}/topright1.png`]
                },
                btmright: {
                    url: [`${lookupurl('Leptodactylon_Californicum')}/btmright0.png`, `${lookupurl('Leptodactylon_Californicum')}/btmright1.png`]
                },
                btmleft: {
                    url: [`${lookupurl('Leptodactylon_Californicum')}/btmleft0.png`, `${lookupurl('Leptodactylon_Californicum')}/btmleft1.png`]
                },
                midleft: {
                    url: []
                },
                midright: {
                    url: []
                },
                top: {
                    url: []
                },
                btm: {
                    url: [`${lookupurl('Leptodactylon_Californicum')}/btm.png`]
                },
            },
            thumbnail: { // thumbnail use btmleft
                url: `${thumbnailurl('Leptodactylon_Californicum')}/thumbnail.png`
            },
            banner: {
                url: `${bannerurl('Leptodactylon_Californicum')}/banner.png`
            },
            corner: {
                topleft: {
                    url: [`${seatchart('Leptodactylon_Californicum')}/seatchart.png`]
                },
                topright: {
                    url: [`${seatchart('Leptodactylon_Californicum')}/seatchart.png`]
                },
            },
            itype: ['Wedding'],
            orientation_allow_list: [
                { value: LL, state: true, },
                { value: LR, state: true, },
                { value: RL, state: true, },
                { value: RR, state: true, },
            ],
            bgformat: [ // determine whatimage to be used
                {
                    // 0 TOP_RIGHT_BTM_LEFT
                    // detemine the orientation of image, bg1 and bg2 is the address of the img for background
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topright', bg2: 'btmleft' },
                    MOBILE: { state: true, bg1: 'topright', bg2: 'btmleft' }
                },
                {
                    // 1 TOP_LEFT_BTM_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topleft', bg2: 'btmright' },
                    MOBILE: { state: true, bg1: 'topleft', bg2: 'btmright' }
                },
                {
                    // 2 BTM_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'btmleft', bg2: 'btmright' },
                    MOBILE: { state: false, bg1: 'btmleft', bg2: 'btmright' }
                },
                {
                    // 3 TOP_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'topleft', bg2: 'topright' },
                    MOBILE: { state: false, bg1: 'topleft', bg2: 'topright' }
                },
                {
                    // 4 MID_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: 'midright' },
                    MOBILE: { state: false, bg1: 'midleft', bg2: 'midright' }
                },
                {
                    // 5 MID_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midright', bg2: null },
                    MOBILE: { state: false, bg1: 'midright', bg2: null }
                },
                {
                    // 6 MID_LEFT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: null },
                    MOBILE: { state: false, bg1: 'midleft', bg2: null }
                },
                {
                    // 7 TOP_BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: 'btm' },
                    MOBILE: { state: false, bg1: 'top', bg2: 'btm' }
                },
                {
                    // 8 TOP
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: null },
                    MOBILE: { state: false, bg1: 'top', bg2: null }
                },
                {
                    // 9 BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: false, img1: 0, img2: 1 }, { state: false, img1: 1, img2: 0 }, { state: false, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'btm', bg2: null },
                    MOBILE: { state: true, bg1: 'btm', bg2: null, affectnamepos: { state: true }, }
                }
            ]
        }
    ],
    [ // Mexican_Poppy
        'Mexican_Poppy',
        {
            name: `Mexican Poppy
(California Poppy)`,
            cat: 'Floral',
            summary: `Eschscholzia mexicana, also known as Mexican gold poppy or California poppy, is a beautiful flower that is native to western North America. This flower is known for its vibrant yellow-orange blooms and delicate, feathery foliage. In the language of flowers, Eschscholzia represents wealth and success, making it a fitting choice for a wedding. While not as commonly used in weddings as some other flowers, Mexican gold poppy can make a unique and beautiful addition to bridal bouquets and other floral arrangements. Its bright and cheerful blooms are sure to bring joy and happiness to any special occasion.`,
            background: {
                topleft: {
                    url: [`${lookupurl('Mexican_Poppy')}/topleft0.png`, `${lookupurl('Mexican_Poppy')}/topleft1.png`]
                },
                topright: {
                    url: [`${lookupurl('Mexican_Poppy')}/topright0.png`, `${lookupurl('Mexican_Poppy')}/topright1.png`]
                },
                btmright: {
                    url: [`${lookupurl('Mexican_Poppy')}/btmright0.png`, `${lookupurl('Mexican_Poppy')}/btmright1.png`]
                },
                btmleft: {
                    url: [`${lookupurl('Mexican_Poppy')}/btmleft0.png`, `${lookupurl('Mexican_Poppy')}/btmleft1.png`]
                },
                midleft: {
                    url: []
                },
                midright: {
                    url: []
                },
                top: {
                    url: []
                },
                btm: {
                    url: [`${lookupurl('Mexican_Poppy')}/btm.png`]
                },
            },
            thumbnail: { // thumbnail use btmleft
                url: `${thumbnailurl('Mexican_Poppy')}/thumbnail.png`
            },
            banner: {
                url: `${bannerurl('Mexican_Poppy')}/banner.png`
            },
            corner: {
                topleft: {
                    url: [`${seatchart('Mexican_Poppy')}/seatchart.png`]
                },
                topright: {
                    url: [`${seatchart('Mexican_Poppy')}/seatchart.png`]
                },
            },
            itype: ['Wedding'],
            orientation_allow_list: [
                { value: LL, state: true, },
                { value: LR, state: true, },
                { value: RL, state: true, },
                { value: RR, state: true, },
            ],
            bgformat: [ // determine whatimage to be used
                {
                    // 0 TOP_RIGHT_BTM_LEFT
                    // detemine the orientation of image, bg1 and bg2 is the address of the img for background
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topright', bg2: 'btmleft' },
                    MOBILE: { state: true, bg1: 'topright', bg2: 'btmleft' }
                },
                {
                    // 1 TOP_LEFT_BTM_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topleft', bg2: 'btmright' },
                    MOBILE: { state: true, bg1: 'topleft', bg2: 'btmright' }
                },
                {
                    // 2 BTM_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'btmleft', bg2: 'btmright' },
                    MOBILE: { state: false, bg1: 'btmleft', bg2: 'btmright' }
                },
                {
                    // 3 TOP_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'topleft', bg2: 'topright' },
                    MOBILE: { state: false, bg1: 'topleft', bg2: 'topright' }
                },
                {
                    // 4 MID_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: 'midright' },
                    MOBILE: { state: false, bg1: 'midleft', bg2: 'midright' }
                },
                {
                    // 5 MID_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midright', bg2: null },
                    MOBILE: { state: false, bg1: 'midright', bg2: null }
                },
                {
                    // 6 MID_LEFT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: null },
                    MOBILE: { state: false, bg1: 'midleft', bg2: null }
                },
                {
                    // 7 TOP_BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: 'btm' },
                    MOBILE: { state: false, bg1: 'top', bg2: 'btm' }
                },
                {
                    // 8 TOP
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: null },
                    MOBILE: { state: false, bg1: 'top', bg2: null }
                },
                {
                    // 9 BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: false, img1: 0, img2: 1 }, { state: false, img1: 1, img2: 0 }, { state: false, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'btm', bg2: null },
                    MOBILE: { state: true, bg1: 'btm', bg2: null, affectnamepos: { state: true }, }
                }
            ]
        }





    ],
    [ // Myosotis_Alpestris
        'Myosotis_Alpestris',
        {
            name: `Myosotis Alpestris
(Alpine Forget-me-not)`,
            cat: 'Floral',
            summary: `Myosotis alpestris, also known as Alpine Forget-me-not, is a delicate and charming flower that is native to the mountains of Europe. This flower features small, sky-blue blooms with yellow centers and soft, fuzzy foliage. In the language of flowers, Forget-me-nots represent true love and memories, making it a meaningful choice for a wedding. While not as commonly used in weddings as some other flowers, Alpine Forget-me-not can make a lovely addition to bridal bouquets and other floral arrangements. Its dainty blooms and delicate appearance symbolize the fragility and beauty of love, and serve as a sweet reminder of the couple's special day.`,
            background: {
                topleft: {
                    url: [`${lookupurl('Myosotis_Alpestris')}/topleft0.png`, `${lookupurl('Myosotis_Alpestris')}/topleft1.png`]
                },
                topright: {
                    url: [`${lookupurl('Myosotis_Alpestris')}/topright0.png`, `${lookupurl('Myosotis_Alpestris')}/topright1.png`]
                },
                btmright: {
                    url: [`${lookupurl('Myosotis_Alpestris')}/btmright0.png`, `${lookupurl('Myosotis_Alpestris')}/btmright1.png`]
                },
                btmleft: {
                    url: [`${lookupurl('Myosotis_Alpestris')}/btmleft0.png`, `${lookupurl('Myosotis_Alpestris')}/btmleft1.png`]
                },
                midleft: {
                    url: []
                },
                midright: {
                    url: []
                },
                top: {
                    url: []
                },
                btm: {
                    url: [`${lookupurl('Myosotis_Alpestris')}/btm.png`]
                },
            },
            thumbnail: { // thumbnail use btmleft
                url: `${thumbnailurl('Myosotis_Alpestris')}/thumbnail.png`
            },
            banner: {
                url: `${bannerurl('Myosotis_Alpestris')}/banner.png`
            },
            corner: {
                topleft: {
                    url: [`${seatchart('Myosotis_Alpestris')}/seatchart.png`]
                },
                topright: {
                    url: [`${seatchart('Myosotis_Alpestris')}/seatchart.png`]
                },
            },
            itype: ['Wedding'],
            orientation_allow_list: [
                { value: LL, state: true, },
                { value: LR, state: true, },
                { value: RL, state: true, },
                { value: RR, state: true, },
            ],
            bgformat: [ // determine whatimage to be used
                {
                    // 0 TOP_RIGHT_BTM_LEFT
                    // detemine the orientation of image, bg1 and bg2 is the address of the img for background
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topright', bg2: 'btmleft' },
                    MOBILE: { state: true, bg1: 'topright', bg2: 'btmleft' }
                },
                {
                    // 1 TOP_LEFT_BTM_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topleft', bg2: 'btmright' },
                    MOBILE: { state: true, bg1: 'topleft', bg2: 'btmright' }
                },
                {
                    // 2 BTM_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'btmleft', bg2: 'btmright' },
                    MOBILE: { state: false, bg1: 'btmleft', bg2: 'btmright' }
                },
                {
                    // 3 TOP_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'topleft', bg2: 'topright' },
                    MOBILE: { state: false, bg1: 'topleft', bg2: 'topright' }
                },
                {
                    // 4 MID_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: 'midright' },
                    MOBILE: { state: false, bg1: 'midleft', bg2: 'midright' }
                },
                {
                    // 5 MID_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midright', bg2: null },
                    MOBILE: { state: false, bg1: 'midright', bg2: null }
                },
                {
                    // 6 MID_LEFT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: null },
                    MOBILE: { state: false, bg1: 'midleft', bg2: null }
                },
                {
                    // 7 TOP_BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: 'btm' },
                    MOBILE: { state: false, bg1: 'top', bg2: 'btm' }
                },
                {
                    // 8 TOP
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: null },
                    MOBILE: { state: false, bg1: 'top', bg2: null }
                },
                {
                    // 9 BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: false, img1: 0, img2: 1 }, { state: false, img1: 1, img2: 0 }, { state: false, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'btm', bg2: null },
                    MOBILE: { state: true, bg1: 'btm', bg2: null, affectnamepos: { state: true }, }
                }
            ]
        }
    ],
    [ // Red_Epidendrum
        'Red_Epidendrum',
        {
            name: `Red Epidendrum`,
            cat: 'Floral',
            summary: `Epidendrum Erubescens, also known as the Red Epidendrum, is a striking orchid that is native to Central and South America. This orchid features tall stems with bright red blooms that have a waxy texture and a slight fragrance. In the language of flowers, the Red Epidendrum represents strength and courage, making it a fitting choice for a wedding. While not as commonly used in weddings as some other flowers, the Red Epidendrum can make a unique and meaningful addition to bridal bouquets and other floral arrangements. Its bold and vibrant appearance symbolizes the passion and vitality of the couple's love, and serves as a beautiful reminder of their special day.`,
            background: {
                topleft: {
                    url: [`${lookupurl('Red_Epidendrum')}/topleft0.png`, `${lookupurl('Red_Epidendrum')}/topleft1.png`]
                },
                topright: {
                    url: [`${lookupurl('Red_Epidendrum')}/topright0.png`, `${lookupurl('Red_Epidendrum')}/topright1.png`]
                },
                btmright: {
                    url: [`${lookupurl('Red_Epidendrum')}/btmright0.png`, `${lookupurl('Red_Epidendrum')}/btmright1.png`]
                },
                btmleft: {
                    url: [`${lookupurl('Red_Epidendrum')}/btmleft0.png`, `${lookupurl('Red_Epidendrum')}/btmleft1.png`]
                },
                midleft: {
                    url: []
                },
                midright: {
                    url: []
                },
                top: {
                    url: []
                },
                btm: {
                    url: [`${lookupurl('Red_Epidendrum')}/btm.png`]
                },
            },
            thumbnail: { // thumbnail use btmleft
                url: `${thumbnailurl('Red_Epidendrum')}/thumbnail.png`
            },
            banner: {
                url: `${bannerurl('Red_Epidendrum')}/banner.png`
            },
            corner: {
                topleft: {
                    url: [`${seatchart('Red_Epidendrum')}/seatchart.png`]
                },
                topright: {
                    url: [`${seatchart('Red_Epidendrum')}/seatchart.png`]
                },
            },
            itype: ['Wedding'],
            orientation_allow_list: [
                { value: LL, state: true, },
                { value: LR, state: true, },
                { value: RL, state: true, },
                { value: RR, state: true, },
            ],
            bgformat: [ // determine whatimage to be used
                {
                    // 0 TOP_RIGHT_BTM_LEFT
                    // detemine the orientation of image, bg1 and bg2 is the address of the img for background
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topright', bg2: 'btmleft' },
                    MOBILE: { state: true, bg1: 'topright', bg2: 'btmleft' }
                },
                {
                    // 1 TOP_LEFT_BTM_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topleft', bg2: 'btmright' },
                    MOBILE: { state: true, bg1: 'topleft', bg2: 'btmright' }
                },
                {
                    // 2 BTM_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'btmleft', bg2: 'btmright' },
                    MOBILE: { state: false, bg1: 'btmleft', bg2: 'btmright' }
                },
                {
                    // 3 TOP_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'topleft', bg2: 'topright' },
                    MOBILE: { state: false, bg1: 'topleft', bg2: 'topright' }
                },
                {
                    // 4 MID_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: 'midright' },
                    MOBILE: { state: false, bg1: 'midleft', bg2: 'midright' }
                },
                {
                    // 5 MID_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midright', bg2: null },
                    MOBILE: { state: false, bg1: 'midright', bg2: null }
                },
                {
                    // 6 MID_LEFT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: null },
                    MOBILE: { state: false, bg1: 'midleft', bg2: null }
                },
                {
                    // 7 TOP_BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: 'btm' },
                    MOBILE: { state: false, bg1: 'top', bg2: 'btm' }
                },
                {
                    // 8 TOP
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: null },
                    MOBILE: { state: false, bg1: 'top', bg2: null }
                },
                {
                    // 9 BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: false, img1: 0, img2: 1 }, { state: false, img1: 1, img2: 0 }, { state: false, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'btm', bg2: null },
                    MOBILE: { state: true, bg1: 'btm', bg2: null, affectnamepos: { state: true }, }
                }
            ]
        }
    ],
    [ // Rosa_Gallica
        'Rosa_Gallica',
        {
            name: `Rosa Gallica
(Damask Rose)`,
            cat: 'Floral',
            summary: `Rosa Damascena Coccinea, also known as the Coccinea Damask Rose, is a beautiful and fragrant flower that is often used in perfumes and essential oils. This rose variety features clusters of deep red blooms with a sweet and spicy fragrance. In the language of flowers, the Coccinea Damask Rose represents love and passion, making it a popular choice for weddings. Its stunning appearance and intoxicating scent make it a classic and timeless addition to bridal bouquets and other floral arrangements. The Coccinea Damask Rose is also rich in cultural and historical significance, and has been cultivated for centuries in various parts of the world.`,
            background: {
                topleft: {
                    url: [`${lookupurl('Rosa_Gallica')}/topleft0.png`, `${lookupurl('Rosa_Gallica')}/topleft1.png`]
                },
                topright: {
                    url: [`${lookupurl('Rosa_Gallica')}/topright0.png`, `${lookupurl('Rosa_Gallica')}/topright1.png`]
                },
                btmright: {
                    url: [`${lookupurl('Rosa_Gallica')}/btmright0.png`, `${lookupurl('Rosa_Gallica')}/btmright1.png`]
                },
                btmleft: {
                    url: [`${lookupurl('Rosa_Gallica')}/btmleft0.png`, `${lookupurl('Rosa_Gallica')}/btmleft1.png`]
                },
                midleft: {
                    url: []
                },
                midright: {
                    url: []
                },
                top: {
                    url: []
                },
                btm: {
                    url: [`${lookupurl('Rosa_Gallica')}/btm.png`]
                },
            },
            thumbnail: { // thumbnail use btmleft
                url: `${thumbnailurl('Rosa_Gallica')}/thumbnail.png`
            },
            banner: {
                url: `${bannerurl('Rosa_Gallica')}/banner.png`
            },
            corner: {
                topleft: {
                    url: [`${seatchart('Rosa_Gallica')}/seatchart.png`]
                },
                topright: {
                    url: [`${seatchart('Rosa_Gallica')}/seatchart.png`]
                },
            },
            itype: ['Wedding'],
            orientation_allow_list: [
                { value: LL, state: true, },
                { value: LR, state: true, },
                { value: RL, state: true, },
                { value: RR, state: true, },
            ],
            bgformat: [ // determine whatimage to be used
                {
                    // 0 TOP_RIGHT_BTM_LEFT
                    // detemine the orientation of image, bg1 and bg2 is the address of the img for background
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topright', bg2: 'btmleft' },
                    MOBILE: { state: true, bg1: 'topright', bg2: 'btmleft' }
                },
                {
                    // 1 TOP_LEFT_BTM_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topleft', bg2: 'btmright' },
                    MOBILE: { state: true, bg1: 'topleft', bg2: 'btmright' }
                },
                {
                    // 2 BTM_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'btmleft', bg2: 'btmright' },
                    MOBILE: { state: false, bg1: 'btmleft', bg2: 'btmright' }
                },
                {
                    // 3 TOP_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'topleft', bg2: 'topright' },
                    MOBILE: { state: false, bg1: 'topleft', bg2: 'topright' }
                },
                {
                    // 4 MID_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: 'midright' },
                    MOBILE: { state: false, bg1: 'midleft', bg2: 'midright' }
                },
                {
                    // 5 MID_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midright', bg2: null },
                    MOBILE: { state: false, bg1: 'midright', bg2: null }
                },
                {
                    // 6 MID_LEFT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: null },
                    MOBILE: { state: false, bg1: 'midleft', bg2: null }
                },
                {
                    // 7 TOP_BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: 'btm' },
                    MOBILE: { state: false, bg1: 'top', bg2: 'btm' }
                },
                {
                    // 8 TOP
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: null },
                    MOBILE: { state: false, bg1: 'top', bg2: null }
                },
                {
                    // 9 BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: false, img1: 0, img2: 1 }, { state: false, img1: 1, img2: 0 }, { state: false, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'btm', bg2: null },
                    MOBILE: { state: true, bg1: 'btm', bg2: null, affectnamepos: { state: true }, }
                }
            ]
        }
    ],
    [ // Rosa_Sulfurea
        'Rosa_Sulfurea',
        {
            name: `Rosa Sulfurea
(Sulfur Yellow Rose)`,
            cat: 'Floral',
            summary: `Rosa Sulfurea, also known as the Sulfur Yellow Rose, is a unique and eye-catching rose variety that features stunning sulfur yellow blooms with a light fragrance. In the language of flowers, the Sulfur Yellow Rose represents happiness and joy, making it a wonderful choice for weddings. Its bright and cheerful appearance is symbolic of the happiness and excitement of the couple's special day, and it can make a lovely addition to bridal bouquets and other floral arrangements. The Sulfur Yellow Rose is a relatively rare rose variety, making it a special and distinctive choice for a wedding.`,
            background: {
                topleft: {
                    url: [`${lookupurl('Rosa_Sulfurea')}/topleft0.png`, `${lookupurl('Rosa_Sulfurea')}/topleft1.png`]
                },
                topright: {
                    url: [`${lookupurl('Rosa_Sulfurea')}/topright0.png`, `${lookupurl('Rosa_Sulfurea')}/topright1.png`]
                },
                btmright: {
                    url: [`${lookupurl('Rosa_Sulfurea')}/btmright0.png`, `${lookupurl('Rosa_Sulfurea')}/btmright1.png`]
                },
                btmleft: {
                    url: [`${lookupurl('Rosa_Sulfurea')}/btmleft0.png`, `${lookupurl('Rosa_Sulfurea')}/btmleft1.png`]
                },
                midleft: {
                    url: []
                },
                midright: {
                    url: []
                },
                top: {
                    url: []
                },
                btm: {
                    url: [`${lookupurl('Rosa_Sulfurea')}/btm.png`]
                },
            },
            thumbnail: { // thumbnail use btmleft
                url: `${thumbnailurl('Rosa_Sulfurea')}/thumbnail.png`
            },
            banner: {
                url: `${bannerurl('Rosa_Sulfurea')}/banner.png`
            },
            corner: {
                topleft: {
                    url: [`${seatchart('Rosa_Sulfurea')}/seatchart.png`]
                },
                topright: {
                    url: [`${seatchart('Rosa_Sulfurea')}/seatchart.png`]
                },
            },
            itype: ['Wedding'],
            orientation_allow_list: [
                { value: LL, state: true, },
                { value: LR, state: true, },
                { value: RL, state: true, },
                { value: RR, state: true, },
            ],
            bgformat: [ // determine whatimage to be used
                {
                    // 0 TOP_RIGHT_BTM_LEFT
                    // detemine the orientation of image, bg1 and bg2 is the address of the img for background
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topright', bg2: 'btmleft' },
                    MOBILE: { state: true, bg1: 'topright', bg2: 'btmleft' }
                },
                {
                    // 1 TOP_LEFT_BTM_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topleft', bg2: 'btmright' },
                    MOBILE: { state: true, bg1: 'topleft', bg2: 'btmright' }
                },
                {
                    // 2 BTM_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'btmleft', bg2: 'btmright' },
                    MOBILE: { state: false, bg1: 'btmleft', bg2: 'btmright' }
                },
                {
                    // 3 TOP_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'topleft', bg2: 'topright' },
                    MOBILE: { state: false, bg1: 'topleft', bg2: 'topright' }
                },
                {
                    // 4 MID_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: 'midright' },
                    MOBILE: { state: false, bg1: 'midleft', bg2: 'midright' }
                },
                {
                    // 5 MID_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midright', bg2: null },
                    MOBILE: { state: false, bg1: 'midright', bg2: null }
                },
                {
                    // 6 MID_LEFT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: null },
                    MOBILE: { state: false, bg1: 'midleft', bg2: null }
                },
                {
                    // 7 TOP_BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: 'btm' },
                    MOBILE: { state: false, bg1: 'top', bg2: 'btm' }
                },
                {
                    // 8 TOP
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: null },
                    MOBILE: { state: false, bg1: 'top', bg2: null }
                },
                {
                    // 9 BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: false, img1: 0, img2: 1 }, { state: false, img1: 1, img2: 0 }, { state: false, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'btm', bg2: null },
                    MOBILE: { state: true, bg1: 'btm', bg2: null, affectnamepos: { state: true }, }
                }
            ]
        }
    ],
    [ // Slender_Flowered_Gilia
        'Slender_Flowered_Gilia',
        {
            name: `Slender Flowered Gilia
(Gilia Flexuosa)`,
            cat: 'Floral',
            summary: `Slender-flowered Gilia, also known as Gilia Flexuosa, is a delicate and charming flower that is native to the western United States. This flower variety features slender stems that are adorned with clusters of tiny pink or lavender blooms. In the language of flowers, the Slender-flowered Gilia represents purity and innocence, making it a perfect choice for a wedding. Its dainty and delicate appearance is symbolic of the bride's beauty and grace, and it can make a lovely addition to bridal bouquets and other floral arrangements. The Slender-flowered Gilia is a subtle and understated choice, but its elegance and simplicity make it a timeless classic.`,
            background: {
                topleft: {
                    url: [`${lookupurl('Slender_Flowered_Gilia')}/topleft0.png`, `${lookupurl('Slender_Flowered_Gilia')}/topleft1.png`]
                },
                topright: {
                    url: [`${lookupurl('Slender_Flowered_Gilia')}/topright0.png`, `${lookupurl('Slender_Flowered_Gilia')}/topright1.png`]
                },
                btmright: {
                    url: [`${lookupurl('Slender_Flowered_Gilia')}/btmright0.png`, `${lookupurl('Slender_Flowered_Gilia')}/btmright1.png`]
                },
                btmleft: {
                    url: [`${lookupurl('Slender_Flowered_Gilia')}/btmleft0.png`, `${lookupurl('Slender_Flowered_Gilia')}/btmleft1.png`]
                },
                midleft: {
                    url: []
                },
                midright: {
                    url: []
                },
                top: {
                    url: []
                },
                btm: {
                    url: [`${lookupurl('Slender_Flowered_Gilia')}/btm.png`]
                },
            },
            thumbnail: { // thumbnail use btmleft
                url: `${thumbnailurl('Slender_Flowered_Gilia')}/thumbnail.png`
            },
            banner: {
                url: `${bannerurl('Slender_Flowered_Gilia')}/banner.png`
            },
            corner: {
                topleft: {
                    url: [`${seatchart('Slender_Flowered_Gilia')}/seatchart.png`]
                },
                topright: {
                    url: [`${seatchart('Slender_Flowered_Gilia')}/seatchart.png`]
                },
            },
            itype: ['Wedding'],
            orientation_allow_list: [
                { value: LL, state: true, },
                { value: LR, state: true, },
                { value: RL, state: true, },
                { value: RR, state: true, },
            ],
            bgformat: [ // determine whatimage to be used
                {
                    // 0 TOP_RIGHT_BTM_LEFT
                    // detemine the orientation of image, bg1 and bg2 is the address of the img for background
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topright', bg2: 'btmleft' },
                    MOBILE: { state: true, bg1: 'topright', bg2: 'btmleft' }
                },
                {
                    // 1 TOP_LEFT_BTM_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topleft', bg2: 'btmright' },
                    MOBILE: { state: true, bg1: 'topleft', bg2: 'btmright' }
                },
                {
                    // 2 BTM_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'btmleft', bg2: 'btmright' },
                    MOBILE: { state: false, bg1: 'btmleft', bg2: 'btmright' }
                },
                {
                    // 3 TOP_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'topleft', bg2: 'topright' },
                    MOBILE: { state: false, bg1: 'topleft', bg2: 'topright' }
                },
                {
                    // 4 MID_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: 'midright' },
                    MOBILE: { state: false, bg1: 'midleft', bg2: 'midright' }
                },
                {
                    // 5 MID_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midright', bg2: null },
                    MOBILE: { state: false, bg1: 'midright', bg2: null }
                },
                {
                    // 6 MID_LEFT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: null },
                    MOBILE: { state: false, bg1: 'midleft', bg2: null }
                },
                {
                    // 7 TOP_BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: 'btm' },
                    MOBILE: { state: false, bg1: 'top', bg2: 'btm' }
                },
                {
                    // 8 TOP
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: null },
                    MOBILE: { state: false, bg1: 'top', bg2: null }
                },
                {
                    // 9 BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: false, img1: 0, img2: 1 }, { state: false, img1: 1, img2: 0 }, { state: false, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'btm', bg2: null },
                    MOBILE: { state: true, bg1: 'btm', bg2: null, affectnamepos: { state: true }, }
                }
            ]
        }
    ],
    [ // White_Odontoglossum
        'White_Odontoglossum',
        {
            name: `Odontoglossum Roezlii
(White Butterfly Orchid)`,
            cat: 'Floral',
            summary: `Odontoglossum Roezlii Album, commonly known as "white butterfly orchid," is a beautiful and elegant flower that symbolizes purity, innocence, and everlasting love in the context of weddings. The delicate and fragrant white blooms with intricate patterns resemble fluttering butterflies, making them a popular choice for bridal bouquets and floral arrangements. According to flower language, giving a white butterfly orchid conveys the message of pure and true love, making it a perfect choice for couples on their wedding day. The simplicity and elegance of these flowers make them a timeless addition to any wedding celebration.`,
            background: {
                topleft: {
                    url: [`${lookupurl('White_Odontoglossum')}/topleft0.png`, `${lookupurl('White_Odontoglossum')}/topleft1.png`]
                },
                topright: {
                    url: [`${lookupurl('White_Odontoglossum')}/topright0.png`, `${lookupurl('White_Odontoglossum')}/topright1.png`]
                },
                btmright: {
                    url: [`${lookupurl('White_Odontoglossum')}/btmright0.png`, `${lookupurl('White_Odontoglossum')}/btmright1.png`]
                },
                btmleft: {
                    url: [`${lookupurl('White_Odontoglossum')}/btmleft0.png`, `${lookupurl('White_Odontoglossum')}/btmleft1.png`]
                },
                midleft: {
                    url: []
                },
                midright: {
                    url: []
                },
                top: {
                    url: []
                },
                btm: {
                    url: [`${lookupurl('White_Odontoglossum')}/btm.png`]
                },
            },
            thumbnail: { // thumbnail use btmleft
                url: `${thumbnailurl('White_Odontoglossum')}/thumbnail.png`
            },
            banner: {
                url: `${bannerurl('White_Odontoglossum')}/banner.png`
            },
            corner: {
                topleft: {
                    url: [`${seatchart('White_Odontoglossum')}/seatchart.png`]
                },
                topright: {
                    url: [`${seatchart('White_Odontoglossum')}/seatchart.png`]
                },
            },
            itype: ['Wedding'],
            orientation_allow_list: [
                { value: LL, state: true, },
                { value: LR, state: true, },
                { value: RL, state: true, },
                { value: RR, state: true, },
            ],
            bgformat: [ // determine whatimage to be used
                {
                    // 0 TOP_RIGHT_BTM_LEFT
                    // detemine the orientation of image, bg1 and bg2 is the address of the img for background
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topright', bg2: 'btmleft' },
                    MOBILE: { state: true, bg1: 'topright', bg2: 'btmleft' }
                },
                {
                    // 1 TOP_LEFT_BTM_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'topleft', bg2: 'btmright' },
                    MOBILE: { state: true, bg1: 'topleft', bg2: 'btmright' }
                },
                {
                    // 2 BTM_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: true, bg1: 'btmleft', bg2: 'btmright' },
                    MOBILE: { state: false, bg1: 'btmleft', bg2: 'btmright' }
                },
                {
                    // 3 TOP_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'topleft', bg2: 'topright' },
                    MOBILE: { state: false, bg1: 'topleft', bg2: 'topright' }
                },
                {
                    // 4 MID_LEFT_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: 'midright' },
                    MOBILE: { state: false, bg1: 'midleft', bg2: 'midright' }
                },
                {
                    // 5 MID_RIGHT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midright', bg2: null },
                    MOBILE: { state: false, bg1: 'midright', bg2: null }
                },
                {
                    // 6 MID_LEFT
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'midleft', bg2: null },
                    MOBILE: { state: false, bg1: 'midleft', bg2: null }
                },
                {
                    // 7 TOP_BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: 'btm' },
                    MOBILE: { state: false, bg1: 'top', bg2: 'btm' }
                },
                {
                    // 8 TOP
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: true, img1: 0, img2: 1 }, { state: true, img1: 1, img2: 0 }, { state: true, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'top', bg2: null },
                    MOBILE: { state: false, bg1: 'top', bg2: null }
                },
                {
                    // 9 BTM
                    orientation_allow_list: [{ state: true, img1: 0, img2: 0 }, { state: false, img1: 0, img2: 1 }, { state: false, img1: 1, img2: 0 }, { state: false, img1: 1, img2: 1 },],
                    WEB_TABLET: { state: false, bg1: 'btm', bg2: null },
                    MOBILE: { state: false, bg1: 'btm', bg2: null, affectnamepos: { state: true }, }
                }
            ]
        }
    ],
])


export const bannernames = []
allimages.forEach((obj) => {
    const { name } = obj
    bannernames.push(name)
    return
})

export const banners = []
allimages.forEach((obj) => {
    const { url } = obj.banner
    banners.push(url)
    return
})

export const flowers = []
allimages.forEach((obj) => {
    const { url } = obj.thumbnail
    flowers.push(url)
    return
})

export const corners = []
allimages.forEach((obj) => {
    const { url } = obj.corner.topleft
    corners.push(url)
    return
})