
import React, { useMemo, useState } from "react";
import './TableBody.css'

const TableBody = ({ rows, getTableBodyProps, prepareRow, rowClick }) => {
  return <div {...getTableBodyProps()} >
    {rows.map((row, i) => {
      prepareRow(row);
      return (
        <div {...row.getRowProps()} className="tr" >
          {row.cells.map((cell, cellindex) => {
            return <div className={"td"} {...cell.getCellProps({ onClick: () => (cellindex !== 0 && cellindex !== row.cells.length - 1 && cellindex !== row.cells.length - 2) ? rowClick(row) : null })} >
              {cell.render("Cell")}
            </div>
          })}
        </div>
      )
    })}
  </div>
}

//React.memo with custom fuction to prevent render lag when resizing
export default React.memo(
  TableBody,
  (prevProps, nextProps) => nextProps.isColumnResizing
);



// import React from "react";
// import './TableBody.css'

// const TableBody = ({ rows, getTableBodyProps, prepareRow, rowClick }) => {
//   return <div {...getTableBodyProps()}>
//     {rows.map((row, i) => {
//       prepareRow(row);
//       return (
//         <div {...row.getRowProps()} className="tr">
//           {row.cells.map((cell, cellindex) => {
//             return <div
//               {...cell.getCellProps({
//                 onClick: () => {
//                   if (cellindex !== 0 && cellindex !== row.cells.length - 1) {
//                     rowClick(row)
//                   }
//                 }
//               })}
//               className={"td"}>
//               {cell.render("Cell")}
//             </div>
//           })}
//         </div>
//       )
//     })}
//   </div>
// }

// //React.memo with custom fuction to prevent render lag when resizing
// export default React.memo(
//   TableBody,
//   (prevProps, nextProps) => nextProps.isColumnResizing
// );
