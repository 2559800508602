import { applyMiddleware, createStore } from 'redux'
import rootReducer from './reducers'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import { production } from '../config/constants'

export default () => {
    // const middleware = applyMiddleware(promise(),logger,thunk)
    let middleware
    if (typeof (window) !== 'undefined' && !production) {
        middleware = applyMiddleware(promise(),logger, thunk)
    }
    else{
        middleware = applyMiddleware(promise(), thunk)
    }
    return createStore(rootReducer, middleware)
}