import React, { useState, useEffect } from 'react'
import { ABBREV_PRESENCE_LOCATION_SMALL, EMPTY, ABBREV_PRESENCELOC_MAP } from '../../config/constants'
import { Checkdevice, refresh } from '..'
import { Retrieve_personal_info } from '../../config/commonfunctions'
import { USER_CHANGE_LOC } from '../../config/api'
import { Portalmodal } from '../../components';
import axios from 'axios'
import './Locationselector.css'

function Locationselector({ abbrev_userlocation, homeuser_header_ref, locclickable=true }) {

    const [abbrev_locations, setAbbrev_locations] = useState([])
    const [loc, setLoc] = useState(EMPTY)
    const [loaded, setLoaded] = useState(false)
    const [open, setOpen] = useState(false)
    const { _id, token } = Retrieve_personal_info()
    const device = Checkdevice()

    useEffect(() => {
        // will only appear in Home, Wedding and Event page
        if (abbrev_userlocation) {
            var NEW_ABBREV_PRESENCE_LOCATION = [...ABBREV_PRESENCE_LOCATION_SMALL]
            NEW_ABBREV_PRESENCE_LOCATION.splice(NEW_ABBREV_PRESENCE_LOCATION.indexOf(abbrev_userlocation), 1) // remove user's location
            NEW_ABBREV_PRESENCE_LOCATION.unshift(abbrev_userlocation) // append to the first index
            setLoc(abbrev_userlocation)
            setAbbrev_locations(NEW_ABBREV_PRESENCE_LOCATION)
            setLoaded(true)
        }
    }, [abbrev_userlocation])

    useEffect(() => {
        if (loaded && loc !== abbrev_userlocation) { // change of location
            try {
                let options = {
                    method: 'POST',
                    url: USER_CHANGE_LOC,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { _id, new_loc: ABBREV_PRESENCELOC_MAP[loc.toLowerCase()] }
                }
                axios(options).then(() => { refresh() }).catch((e) => { refresh() }) // just refresh
            }
            catch (e) {
                refresh()
            }
        }
    }, [loc])

    const changeLocation = (value) => setLoc(value)
    
    return (
        <div ref={homeuser_header_ref} className="locationselector_root">
            <div
                className="locationselector_loc"
                onClick={() => {
                    if (locclickable) {
                        setOpen(true)
                    }
                }}
            >
                {loc.toUpperCase()}
            </div>
            <Portalmodal
                portaltype={'commonportal'}
                open={open}
                onClose={() => { setOpen(!open) }}
                floatingclose={false}
                backdropvisible={true}
                modalstyle={{
                    height: device === 'MOBILE' ? '100%' : 'max-content',
                    width: device === 'MOBILE' ? '100%' : 450
                }}
                closestyle={{ right: 0 }}
                floatingclosecolor='var(--maindark)'
            >
                {/* <div style={{ marginLeft: 10, height: 60, minHeight: 60 }} /> */}
                <div className='Rsvpexplorer_portaltitle'>Region</div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', overflowY: 'auto', marginBottom: 10 }}>
                    {abbrev_locations.map((val, i) => {
                        return <div
                            className={abbrev_userlocation === val ? 'Portalinnerelem_selected' : 'Portalinnerelem'}
                            style={{
                                borderRadius: i === 0 ? '10px 10px 0px 0px' : i === abbrev_locations.length - 1 ? '0px 0px 10px 10px' : '0px',
                                cursor: 'pointer'
                            }}
                            key={val}
                            onClick={() => changeLocation(val)}
                        >
                            <div className="locationselector_val">{val.toUpperCase()}</div>
                            <div className="locationselector_locitem">{ABBREV_PRESENCELOC_MAP[val]}</div>
                        </div>
                    })}
                </div>
            </Portalmodal>
        </div>
    )
}


export default Locationselector