import React, { useState } from 'react';
import { refresh, Textfield } from '../../../../components';
import { USER_LOGIN, USER_LOGINFB, } from '../../../../config/api';
import { EMPTY, FB_APPID, LOGIN_ERROR_MSG } from '../../../../config/constants';
import { BUTTONSTYLE } from '../../../../config/cssconstants';
import { FacebookLogin } from 'react-facebook-login-component';
import axios from 'axios'
import './Login.css'

const orline = (
    <div className="UserLoginOrline" key="orlinekey">
        <div className="ULfirstchild" >
            <div className="ULfirstgrandchildren" />
        </div>
        <div className="ULsecondchild" >OR</div>
        <div className="ULfirstchild" >
            <div className="ULfirstgrandchildren" />
        </div>
    </div>
)

const btncss = { minHeight: 42, height: 42, marginTop: 8, width: '100%' }
const errorcss = { ...BUTTONSTYLE, color: 'var(--maindark)', minHeight: 42, height: 42, marginTop: 8, width: '100%', borderRadius: 5, outline: '', marginTop: 10, fontWeight: 'none', placeContent: 'center', alignItems: 'center', textAlign: 'center', border: 'none', }

function Login() {

    const [email, setEmail] = useState(EMPTY)
    const [password, setPassword] = useState(EMPTY)
    const [error, setError] = useState(EMPTY)
    const [loginstate, setLoginstate] = useState('LOGINBTN') // PROCESSING, ERROR, LOGINBTN, REDIRECTING

    const setLocal = (bool_success, curr_token) => {
        try {
            if (bool_success) {
                localStorage.setItem("token", curr_token)
                setEmail(EMPTY)
                setPassword(EMPTY)
            }
        }
        catch (e) { }
    }

    const pw_login = () => {
        setLoginstate('PROCESSING')
        if (email === EMPTY || password === EMPTY) {
            setLoginstate('ERROR')
            setError(LOGIN_ERROR_MSG['FIELDS_INCOMPLETE'])
        }
        else {
            var provider = "emailpw"
            axios({
                method: 'POST',
                url: USER_LOGIN,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', },
                data: { provider, email, password }
            }).then((result) => {
                const { data, status } = result
                const { error, msg_key, token, } = data //user_id
                if (error === false && status === 200) {
                    const bool_success = true
                    const curr_token = token
                    setLocal(bool_success, curr_token)
                    setLoginstate('REDIRECTING')
                }
                else if (error === true) {
                    setEmail(EMPTY)
                    setPassword(EMPTY)
                    setLoginstate('ERROR')
                    setError(LOGIN_ERROR_MSG[msg_key])
                }
                if (msg_key === 'SUCCESS_LOGIN') refresh()
            })
        }
    }

    const fb_login = (res) => {
        const { id, email, name, accessToken } = res

        if (email === '' || email === null || email === undefined) { // check if fb return correct email address or not
            refresh()
        }
        else {
            var provider = "facebook"
            let options = {
                method: 'POST',
                url: USER_LOGINFB,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', },
                data: { provider, 'fbbbb_id': id, email, name, accessToken }
            }
            axios(options).then((result) => {
                const { data, status } = result
                const { error, msg_key, token, } = data //user_id
                if (error === false && status === 200) {
                    const bool_success = true
                    const curr_token = token
                    setLocal(bool_success, curr_token)
                    setError(LOGIN_ERROR_MSG[msg_key])
                }
                else if (error === true) {
                    setError(LOGIN_ERROR_MSG[msg_key])
                }
                if (msg_key === 'SUCCESS_LOGIN') refresh()

            })
        }
    }

    return <div className="login_root">
        {/* <FacebookLogin
            key='fbloginbtn'
            socialId={FB_APPID}
            language="en_US"
            scope="public_profile,email"
            responseHandler={fb_login}
            xfbml={true}
            fields="id,email,name"
            version="v2.5"
            className="facebook-login"
            buttonText="Login with Facebook"
        />
        {orline} */}
        <Textfield
            key={'email'}
            id={'email'}
            label={'Email'}
            val={email}
            type={'text'}
            onchange={(e) => {
                setEmail(e.target.value);
                setError('');
                if (loginstate !== 'LOGINBTN') {
                    setLoginstate('LOGINBTN')
                }
            }}
            disabled={loginstate === 'PROCESSING'}
        />
        <Textfield
            key={'password'}
            id={'password'}
            label={'Pasword'}
            val={password}
            type={'password'}
            onchange={(e) => {
                setPassword(e.target.value);
                setError('')
                if (loginstate !== 'LOGINBTN') {
                    setLoginstate('LOGINBTN')
                }
            }}
            disabled={loginstate === 'PROCESSING'}
        />
        {{
            'PROCESSING': <div className='VL_error'>Processing</div>,
            'REDIRECTING': <div className='VL_error'>Redirecting</div>,
            'ERROR': <div className='VL_error'>{error}</div>,
            'LOGINBTN': <button className='VL_loginbtn' onClick={pw_login} >Login</button>
        }[loginstate]}
    
    </div>
}

export default Login