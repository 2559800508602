import React from 'react'
import { CLOSE_ICON } from "../../../config/constants";

function Circularclosebtn(props) {
    const { fc, bgc, clickfunc, rootstyle = {} } = props

    return <div className="Ilookup_closeroot" style={rootstyle} >
        <button
            id={bgc + '_' + fc}
            className='Ilookup_close'
            style={{ fill: fc, backgroundColor: bgc }}
            onClick={clickfunc}
        >
            {CLOSE_ICON}
        </button>
    </div>
}

export default Circularclosebtn