import React from 'react'//useEffect, useLayoutEffect 
import { Checkdevice } from '../../components/Checkdevice';
import { Headtitle, Blogmenu } from '../../components';
import { EMPTYSPACE } from '../../config/constants';


function Beyond(props) {
    const device = Checkdevice()
    const outerlayer_padding = device === 'WEB' ? '90px 80px 80px' : device === 'TABLET' ? '50px 16px 16px' : '50px 16px 16px'
    const titlestrip_map = {
        'WEB': { width: 1000, height: 100, border: 'thin solid #707070', borderLeft: '5px solid rgb(155, 155, 155)', borderBottom: '5px solid rgb(155, 155, 155)', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: 5, fontSize: 34, fontWeight: 'bold' },
        'TABLET': { width: '100%', height: 80, border: 'thin solid #707070', borderLeft: '5px solid rgb(155, 155, 155)', borderBottom: '5px solid rgb(155, 155, 155)', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: 5, fontSize: 30, fontWeight: 'bold' },
        'MOBILE': { width: 320, height: 70, border: 'thin solid #707070', borderLeft: '5px solid rgb(155, 155, 155)', borderBottom: '5px solid rgb(155, 155, 155)', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: 5, fontSize: 23, fontWeight: 'bold' }
    }

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', minHeight: '100vh', color: '#707070', touchAction: 'manipulation' }}>
            <Headtitle name="Beyond" color="var(--maindark)" />
            <div className="container" style={{ padding: outerlayer_padding, margin: '0 auto', }}>
                {Blogmenu(props)}
                <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
                    {/* <div style={{ ...titlestrip_map[device], }} onClick={()=>props.history.push('/beyond/coronavirus')}>Coronavirus</div> */}
                    {/* {EMPTYSPACE} */}
                    <div style={{ ...titlestrip_map[device], }} onClick={()=>props.history.push('/beyond/unichoices')}>Uni Choices</div>
                    {EMPTYSPACE}
                </div>
            </div>
        </div>
    )
}

export default Beyond