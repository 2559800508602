import React, { useState, useRef, useLayoutEffect } from 'react'
import { NUMMTH_CHARMTH_MAP, } from '../../../../config/constants'//TEMPLATETYPECOLORS_OPENINGBGCOLOR_MAP
import { TypeWriter, ScreenHeight, ScreenWidth } from '../../../../components'
import Openingtransition from './Openingtransition'
import './Opening.css'

// TEXT MESSAGE DIALOG (LEFT OR RIGHT DEPENDS ON PARAM)
const dialogstyle = { border: 'thin solid #c0c0c070', padding: 5, margin: 5, borderRadius: 5, minHeight: 'max-content', maxWidth: '70%', width: 'max-content', display: 'flex', flexDirection: 'column', }
const Textdialog = (content, key, name, namegate, LEFT_OR_RIGHT) => {
    return (
        <div key={key} style={{ ...dialogstyle, alignSelf: LEFT_OR_RIGHT === 'LEFT' ? 'flex-start' : 'flex-end', backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 5px 0px' }}>
            {namegate ? <div style={{ fontWeight: 'bold' }}>{name}</div> : null}
            <div >{content}</div>
        </div>
    )
}

// TEXT LOADING MESSAGE (LEFT OR RIGHT DEPENDS ON PARAM)
const loadingstyle = { border: 'thin solid #c0c0c070', backgroundColor: 'white', maxHeight: 50, minHeight: 50, position: 'relative', padding: 5, margin: 5, borderRadius: 5, maxWidth: '70%', width: 'max-content', display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', }
const Textloading = (LEFT_OR_RIGHT) => {
    return <span key={'loading' + Math.random()} className="appendMovingDots" style={{ ...loadingstyle, alignSelf: LEFT_OR_RIGHT === 'LEFT' ? 'flex-start' : 'flex-end', fontSize: 30, width: 100, boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 5px 0px' }} />
}


function Opening(props) {// eslint-disable-line

    const wt = 360
    const ht = ScreenHeight()
    const swt = ScreenWidth()

    const [firsttime, setFirsttime] = useState(true)
    const [messages, setMessages] = useState([])
    const [transition, setTransition] = useState(false)
    const transitionref = useRef(false)

    // const data = [ // 0 is right, 
    //     // [sequence,  seconds, message]
    //     [0, 1000, 'You are coming to my wedding',],
    //     [1, 1000, 'You already know who you are marrying?',],
    //     [0, 1000, 'Yeah, she is gorgeous'],
    //     [1, 1000, 'Oh, I bet!'],
    //     [0, 3000, 'She is perfect. She just makes my life'],
    //     [1, 1000, 'Cool'],
    //     [0, 1000, 'So I have a question..'],
    //     [1, 1000, '..?'],
    //     [0, 1000, 'Will you marry me?'],
    // ]

    // useEffect(() => {
    //     // if detect change in size, terminate transition animation in p5js
    //     setTransition(false)
    // }, [wt, ht])

    let scrolldown = useRef(null)
    function scrolltoview() {
        try { scrolldown.current.scrollIntoView({ behavior: "smooth" }) }
        catch (e) { }
    }
    function int_to_sec(obj, i) {
        try { return obj[i + 1][1] * 1000 }
        catch (e) { return 7000 }
    }

    var firstleft = true
    var firstright = true
    useLayoutEffect(() => {
        try {

            if (firsttime && props && props.openingtype === 'Convo' && props.openingobjs.length > 0) { // || props.openingtype === 'Story')
                setFirsttime(false)
                var i = 0
                var local_messages = messages.slice()

                let run = () => {
                    var rendername
                    local_messages.pop()
                    const { openingobjs, openingnames } = props


                    if (openingobjs[i][0] === openingnames[0]) { // continue of prev name
                        if (firstleft) { rendername = true; firstleft = false; }
                        else if (openingobjs[i - 1] && openingobjs[i - 1][0] !== openingobjs[i][0]) rendername = true // previous dialog name not equal curr dialog name we must render name
                        else rendername = false

                        local_messages.push(Textdialog(openingobjs[i][2], i, openingobjs[i][0], rendername, 'LEFT'))

                        // see the future, if is different name loading should be different position too
                        if (openingobjs[i + 1] && openingobjs[i + 1][0] !== openingobjs[i][0]) local_messages.push(Textloading('RIGHT'))
                        else local_messages.push(Textloading('LEFT'))
                    }
                    else {
                        if (firstright) { rendername = true; firstright = false; }
                        else if (openingobjs[i - 1] && openingobjs[i - 1][0] !== openingobjs[i][0]) rendername = true // previous dialog name not equal curr dialog name we must render name
                        else rendername = false

                        local_messages.push(Textdialog(openingobjs[i][2], i, openingobjs[i][0], rendername, 'RIGHT'))

                        // see the future, if is different name loading should be different position too
                        if (openingobjs[i + 1] && openingobjs[i + 1][0] !== openingobjs[i][0]) local_messages.push(Textloading('LEFT'))
                        else local_messages.push(Textloading('RIGHT'))
                    }


                    if (i >= openingobjs.length - 1) { local_messages.pop() }

                    i++ // moving to the next msg

                    if (i > props.openingobjs.length - 1) {
                        run = null
                    }
                    else {
                        transitionref.current = setTimeout(run, int_to_sec(props.openingobjs, i))
                    }

                    setMessages(local_messages.slice())

                    scrolltoview()

                }
                run()
            }
            else if (firsttime && props && props.openingtype === 'Story' && props.openingstoryobjs.length > 0) {
                setFirsttime(false)
                setMessages(props.openingstoryobjs)
            }
        }
        catch (e) { }
    }, [props])

    const Date_div = () => {
        var fulldate = new Date()
        var dt = fulldate.getDate()
        var mth = NUMMTH_CHARMTH_MAP[fulldate.getMonth()]
        var yr = fulldate.getFullYear()
        return <div className="Opening_dt">{dt + ' ' + mth + ' ' + yr}</div>
    }

    const Convo_div = (
        <div style={{ width: wt, height: '85%', maxHeight: '85%', display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff80', border: 'thin solid #c0c0c07d', margin: '5px 0px', alignItems: 'center', color: '#707070', borderRadius: 10, overflowY: 'auto' }}> {/*, boxShadow: 'rgb(241 232 232 / 60%) 0px 20px 30px 0px',*/}
            {Date_div()}
            {[...messages, <div key={'scrolldown'} ref={scrolldown} />]}
        </div>
    )

    const Story_div = (
        <div style={{ width: wt, height: '85%', display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: 5, alignItems: 'center', color: '#707070', justifyContent: 'center', borderRadius: 10, overflowY: 'auto' }}>
            {messages && messages.length > 0 ? <TypeWriter heading={""} messages={[messages]} /> : null}
        </div>
    )

    const { templatecolor, openingtype,openingclosing } = props

    const opening_bgcolor = templatecolor + 'bf'
    const opening_fillcolor = templatecolor

    return <div style={{ width: swt, height: '100%', fontFamily: 'Lato', position: 'fixed' }}>
        {transition ? <Openingtransition color={opening_fillcolor} onend={props.closeopening} ht={ht} wt={swt} openingclosing={openingclosing}/> : null}
        <div style={{ backgroundColor: opening_bgcolor, height: '100%', position: 'absolute', top: 0, display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', overflow: 'hidden', zIndex: transition ? -1 : 1 }}>
            {openingtype === 'Convo' ? Convo_div : Story_div}
            <div style={{ width: wt, display:'flex',placeContent:'flex-end' }}>
                <button className="Opening_nextbtn" onClick={() => { setTransition(true); clearTimeout(transitionref.current); }}>Next</button>
            </div>
        </div>
    </div>

}

export default Opening