import React, { useEffect, useState } from 'react'
import { SMALL_STAR_ICON, GENERAL_GREY, VENUE_RATING_TEXT_MAP, READABLE_VENDORTYPE_MAP } from '../../config/constants';
import { google } from 'react-icons-kit/fa/google'
import { facebookSquare } from 'react-icons-kit/fa/facebookSquare'
import Icon from 'react-icons-kit';
import './Reviewcard.css'
import { getS3fullpath_withbucketname } from '../../config/commonfunctions';

const NAME_ICON_MAP = {
    'google': <Icon size={25} icon={google} />,
    'facebook': <Icon size={25} icon={facebookSquare} />,
    'weavebliss': ''
}
const STAR_DEFAULT_BGCOLOR = GENERAL_GREY
const STAR_SELECTED_BGCOLOR = 'rgb(231, 190, 62)'

const BlockedRatingstar = (props) => {
    const [starsstyle, setStarsstyle] = useState([
        {
            color: STAR_DEFAULT_BGCOLOR
        },
        {
            color: STAR_DEFAULT_BGCOLOR
        },
        {
            color: STAR_DEFAULT_BGCOLOR
        },
        {
            color: STAR_DEFAULT_BGCOLOR
        },
        {
            color: STAR_DEFAULT_BGCOLOR
        }
    ])

    useEffect(() => {
        const { score } = props
        var local_starsstyle = [...starsstyle]
        for (let i = 0; i < score; i++) {
            local_starsstyle[i].color = STAR_SELECTED_BGCOLOR
        }
        setStarsstyle(local_starsstyle)
    }, [props.score])

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ borderRadius: 15, }}>
                <div style={{ display: 'flex', flexDirection: 'row', }}>
                    {starsstyle.map((star_style, index) => {
                        return (
                            <div
                                key={'star' + index}
                                style={{ display: 'flex', color: star_style.color, padding: '0px 2px 0px 2px', outline: 'none', backgroundColor: 'transparent', border: 'none' }}
                            >
                                {SMALL_STAR_ICON}
                            </div>
                        )
                    })}
                </div>
            </span>
        </div>

    )

}

const dateConvert = (item) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var curr_date = new Date(item)
    return monthNames[curr_date.getMonth()] + ' ' + curr_date.getFullYear()
}


// for vendorlookup and vendorprofile
const Reviewcard = (props) => {
    const [obj, setObj] = useState({})
    const [index, setIndex] = useState(-1)

    useEffect(() => {
        const {
            index, // index of the review
            obj, // full data of the review
            // profileimg,
            // rating, // use in blockedratingstar
            // name,
            // maxwidth, // full width or nt 

        } = props

        if (props) {
            setIndex(index)
            // determine whether need more button
            var local_obj = obj
            local_obj = ShortenContent(local_obj)
            setObj(local_obj)
        }
    }, [props.index])

    const ShortenContent = (obj) => {
        // this function will edit obj iteself and add two new fields if need to shorten the content
        console.log('obj', obj)
        var content = obj.content
        var words = content.split(' ')
        var num_words = words.length

        // var chars = content.split('')

        // need to show content that is 240 chars 
        const maxChar = 240
        var currChar = 0
        var currSent = []

        obj.require_subcontent = false
        for (let x = 0; x < num_words; x++) {
            if (words[x].length > 50) {
                currSent = currSent + '... '
            }
            currChar = currChar + words[x].length
            currSent = currSent + words[x] + ' '
            if (currChar >= maxChar) {
                obj.require_subcontent = true
                currSent = currSent + '... '
                break
            }
        }

        obj.subcontent = currSent
        return obj
    }

    return (
        <div className='Reviewcard_root'>
            <div key={index + obj._id} className='Reviewcard_content'>
                <div className='Reviewcard_row'>
                    {props.reviewer_accttype === 'User'
                        ? <img className='Reviewcard_profileimg' alt='' src={getS3fullpath_withbucketname('USER_PROFILE_IMG', props.profileimg)} />
                        : null}
                    <div className='Reviewcard_col'>
                        <div className='Reviewcard_name'>{props.name}</div>

                        <div style={{ display: 'flex', flexDirection: 'row', padding: '5px 0px' }}>

                            <div className='Reviewcard_date'>{dateConvert(obj.createdAt)}</div>

                            <div style={{ placeSelf: 'center', margin: '0px 5px' }}>•</div>

                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ padding: '3px 5px', border: '1px solid var(--maindark)', borderRadius: 5, marginRight: 5 }}>{READABLE_VENDORTYPE_MAP[obj.vendortype]}</div>
                                <div style={{ padding: '3px 5px', border: '1px solid var(--maindark)', borderRadius: 5, }}>{obj.reviewer_role}</div>
                            </div>

                        </div>

                        <div className='Reviewcard_namestar'>
                            {obj.vendortype === 'Venue'
                                ? <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <div style={{ padding: '5px 0px' }}>
                                        <div>{VENUE_RATING_TEXT_MAP['venuerating0']}</div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <BlockedRatingstar score={parseInt(obj.venuerating0, 10)} />
                                            <div style={{ padding: '0px 0px 0px 10px' }}>{'( ' + obj.venuerating0 + ' / 5 ) '}</div>
                                        </div>
                                    </div>
                                    <div style={{ padding: '5px 0px' }}>
                                        <div>{VENUE_RATING_TEXT_MAP['venuerating1']}</div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>                                            <BlockedRatingstar score={parseInt(obj.venuerating0, 10)} />
                                            <div style={{ padding: '0px 0px 0px 10px' }}>{'( ' +obj.venuerating0 + ' / 5 ) '}</div>
                                        </div>
                                    </div>
                                    <div style={{ padding: '5px 0px' }}>
                                        <div>{VENUE_RATING_TEXT_MAP['venuerating2']}</div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <BlockedRatingstar score={parseInt(obj.venuerating0, 10)} />
                                            <div style={{ padding: '0px 0px 0px 10px' }}>{'( ' +obj.venuerating0 + ' / 5 ) '}</div>
                                        </div>
                                    </div>
                                </div>
                                : <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <div style={{ padding: '5px 0px' }}>
                                        <div>Rating</div>
                                        <BlockedRatingstar score={parseInt(props.rating, 10)} />
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
                {obj.require_subcontent
                    ? <div key={Math.random()}>
                        {obj.subcontent}
                        <button
                            className='Reviewcard_more'
                            onClick={() => { setObj({ ...obj, require_subcontent: !obj.require_subcontent }) }}
                        >
                            More
                        </button>
                    </div>
                    : <div key={Math.random()} style={{whiteSpace:'preserve'}}>{obj.content}</div>}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', color: '#707070', width: '100%' }}>
                {props && props.obj && props.obj.sources && props.obj.sources.length > 0
                    ? <div style={{ display: 'flex', flexDirection: 'column', padding: 10, placeSelf: 'flex-end' }}>
                        {props.obj.sources[0].link.length === 0 && props.obj.sources[1].link.length === 0
                            ? null
                            : <div style={{ fontFamily: 'Lato', color: 'var(--maindark)', fontSize: 12 }}>Source</div>}
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {props.obj.sources.map((item, index) => {
                                const { key, link } = item
                                if (link.length === 0) return
                                return <a key={index} style={{ color: '#707070', paddingRight: 5 }} target="_blank" href={item.link}>
                                    {NAME_ICON_MAP[item.key]}
                                </a>
                            })}
                        </div>
                    </div>
                    : <div />}
            </div>
        </div>
    )
}

export default Reviewcard





