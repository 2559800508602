import Homeuser from './homeuser.reducer'
import Weddingvendors from './weddingvendors.reducer'
import Eventvendors from './eventvendors.reducer'
import Projectgallery from './projectgallery.reducer'
import Vendorlookup from './vendorlookup.reducer'
import Projectlookup from './projectlookup.reducer'
import { combineReducers } from 'redux'

const rootReducer = combineReducers({
    Homeuser,
    Weddingvendors,
    Eventvendors,
    Projectgallery,
    Vendorlookup,
    Projectlookup
})

export default rootReducer