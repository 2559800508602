import React, { useEffect, useState } from 'react'
import { READ_GBLOOKUP, LOGIN_GBLOOKUP } from '../../config/api'
import { Loading, Loginpasscode, Nohori, refresh, ScreenHeight } from '../../components'
import { Start, Play2 } from './components'
import axios from 'axios'
import './Gblookup.css'

function Gblookup(props) {
    const [ps, setPs] = useState('LOADING') // pagestate : LOADING ,LOGIN, START, PLAY
    const [msgs, setMsgs] = useState([]) //  array of msgs
    const [artmainimg, setArtmainimg] = useState('')
    const [artmaintxt, setArtmaintxt] = useState('')
    const [arttype, setArttype] = useState('')
    const [artmeta, setArtmeta] = useState(-1)
    const [gtype, setGtype] = useState('') // guestbook type
    const [startpageloaded, setStartpageloaded] = useState(false)
    const ht = ScreenHeight()

    const isLandscape = () => window.matchMedia('(orientation:landscape)').matches,
        [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait'),
        onWindowResize = () => {
            clearTimeout(window.resizeLag)
            window.resizeLag = setTimeout(() => {
                delete window.resizeLag
                setOrientation(isLandscape() ? 'landscape' : 'portrait')
            }, 200)
        }

    useEffect(() => (
        onWindowResize(),// eslint-disable-line
        window.addEventListener('resize', onWindowResize),
        () => window.removeEventListener('resize', onWindowResize)
    ), [])

    useEffect(() => {
        props.hidetopbar(true)
        if (ps === 'LOADING') {
            const { urlhandler, passcodemod } = props.match.params
            if (passcodemod) {
                axios({
                    method: 'POST',
                    url: READ_GBLOOKUP,
                    data: { urlhandler, passcodemod }
                }).then((result) => {
                    const { status, data } = result
                    const { msgs, artmainimg, artmaintxt, artmeta, gtype, arttype } = data.item
                    if (status === 200) {
                        setMsgs(msgs)
                        setArtmainimg(artmainimg)
                        setArtmaintxt(artmaintxt)
                        setArtmeta(artmeta)
                        setArttype(arttype)
                        setGtype(gtype)
                        setPs('START')
                    }
                })
            }
            else { setPs('LOGIN') }
        }
    }, [])

    const start = () => { setPs('PLAY'); }

    const backtostart = () => { setPs('START'); setStartpageloaded(false); } // need reset start loaded 

    const loginsuccess = (data, pc, urlhandler) => {
        if (data.message === 'SUCCESS') {
            props.history.push('/guestbooklookup/' + urlhandler + '/' + pc)
            refresh()
        }
    }

    return ht < 400 && orientation === 'landscape'
        ? <Nohori />
        : {
            'LOADING': <Loading />,
            'START': <div>
                {startpageloaded
                    ? null // show nothing since loaded
                    : <div style={{ display: 'flex', position: 'absolute' }}>
                        <Loading />
                    </div>}
                < Start start={start} msgs={msgs} artmainimg={artmainimg} artmaintxt={artmaintxt} arttype={arttype} artmeta={artmeta} gtype={gtype} startloaded={(l) => { setStartpageloaded(l) }} />
            </div>,
            'LOGIN': <Loginpasscode url={LOGIN_GBLOOKUP} urlhandler={props.match.params.urlhandler} bgcolor={'#fffbf3'} onsuccess={loginsuccess} />,
            'PLAY': msgs.length === 0
                ? <div className="Gblookup_empty">Your Guestbook is Empty</div>
                : <Play2 msgs={msgs} orientation={orientation} backtostart={backtostart} />

            // 'PLAY': msgs.length === 0
            //     ? <div className="Gblookup_empty">Your Guestbook is Empty</div>
            //     : tt === 0 && ht > 400
            //         ? <Play2 msgs={msgs} orientation={orientation} backtostart={backtostart} />
            //         : tt === 1 && ht > 400
            //             ? <Play1 msgs={msgs} orientation={orientation} />
            //             : tt === 2 && ht > 400
            //                 ? <Play0 msgs={msgs} orientation={orientation} />
            //                 : null
        }[ps]

}
export default Gblookup