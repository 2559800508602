import React, { useEffect, useState } from 'react'
import { Checkdevice, Loading, Loginpasscode, ScreenWidth } from '../../components'
import { LOGIN_QR, READ_QRLOOKUP } from '../../config/api'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { iconwrapper } from '../../config/commonfunctions'
import { S3CLOUD_CLOUDFRONT_ASSET } from '../../config/s3directories'
import axios from 'axios'
var QRCode = require('qrcode.react');

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#707070' : 'transparent',
    height: 'max-content',
    // boxShadow: '#19191940 1px 1px 3px 1px'
});

const device_wt = {
    'WT': 500,
    'M': 320,
    'P': 584.666667 // 584.666667  * 3 =  1754px a4 width,  
}

function Qrlookup(props) {
    const [from, setFrom] = useState('') // EDIT or APP
    const [hover, setHover] = useState(-1)
    const [urlhandler, setUrlhandler] = useState('')
    const [passcode, setPasscode] = useState('')
    const [qrobjs, setQrobjs] = useState([])
    const [selectededitindex, setSelectededitindex] = useState(-1)
    const [simulatordevice, setSimulatordevice] = useState('')
    const [grid, setGrid] = useState(false)
    const [bgcolor, setBgcolor] = useState('')
    const [loaded, setLoaded] = useState(false)
    const [firsttime, setFirsttime] = useState(true)

    const [frame, setFrame] = useState(0)
    const [framecolor, setFramecolor] = useState('black')
    const [framecorner, setFramecorner] = useState(0)
    const [framethickness, setFramethickness] = useState(0)
    const [framestroke, setFramestroke] = useState(false)

    const [downloadtransparent, setDownloadtransparent] = useState(false) // transparent if is true, for download

    const device = Checkdevice()
    const wt = ScreenWidth()

    useEffect(() => {
        if (props.from === 'APP') {
            props.hidetopbar(true)
        }

        if (props.from === 'APP' && firsttime) {

            const { urlhandler, passcode } = props.match.params
            const from = (passcode && passcode.length > 0) ? 'APP' : 'LOGIN'

            setFrom(from)
            setFirsttime(false)
            setUrlhandler(urlhandler)

            if (from === 'APP' && !loaded) {
                axios({
                    method: 'POST',
                    url: READ_QRLOOKUP,
                    data: { urlhandler, passcode }
                })
                    .then((qr) => {
                        const { message, item } = qr.data
                        if (message === 'SUCCESS') {
                            const { qrobjs, bgcolor, frame, framecolor, framecorner, framethickness, framestroke, urlhandler } = item
                            document.body.style.backgroundColor = bgcolor;
                            setUrlhandler(urlhandler)
                            setPasscode(passcode)
                            setBgcolor(bgcolor)
                            setQrobjs(JSON.parse(JSON.stringify(qrobjs)))
                            setFrame(frame)
                            setFramecolor(framecolor)
                            setFramecorner(framecorner)
                            setFramethickness(framethickness)
                            setFramestroke(framestroke)
                            const simulatordevice = {
                                'WEB': 'WT',
                                'TABLET': 'WT',
                                'MOBILE': 'M'
                            }[device]
                            setSimulatordevice(simulatordevice)
                        }
                        setLoaded(true)
                    }).catch((e) => {
                        // props.history.push('/')
                    })
            }

            return () => props.hidetopbar(false)

        }
        else if (from !== 'APP') {
            setFrom(props.from)
            setFirsttime(false)
            const {
                from, qrobjs, selectededitindex, simulatordevice, grid, bgcolor,
                frame, framecolor, framecorner, framethickness, framestroke,
            } = props
            setQrobjs(JSON.parse(JSON.stringify(qrobjs)))
            setSelectededitindex(selectededitindex)
            setSimulatordevice(simulatordevice)
            setGrid(grid)
            setBgcolor(bgcolor)
            setFrame(frame)
            setFramecolor(framecolor)
            setFramecorner(framecorner)
            setFramethickness(framethickness)
            setFramestroke(framestroke)
            setHover(-1)
        }
    }, [props])

    useEffect(() => {
        if (from === 'APP') {
            const simulatordevice = {
                'WEB': 'WT',
                'TABLET': 'WT',
                'MOBILE': 'M',
            }[device]
            setSimulatordevice(simulatordevice)
        }
    }, [device])

    useEffect(() => {
        if (!props.allowedit) {
            setSelectededitindex(-1)
        }
    }, [props.allowedit])

    useEffect(() => {
        setDownloadtransparent(props.downloadtransparent)
    }, [props.downloadtransparent])

    const EDIT_DOM = <DragDropContext onDragEnd={props.onDragEnd}>
        <Droppable droppableId={'droppable'}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={getListStyle(snapshot.isDraggingOver)}
                >
                    {qrobjs.map((qrobj, index) => {
                        const { component } = qrobj // component : TEXT, BANNER, TIMELINE, IMAGE
                        let dom

                        let selectedborder = (props.allowedit && selectededitindex === index) ? '1px dashed var(--maindark)' : grid ? '1px dashed var(--subgrey)' : '1px solid transparent'

                        let hover_dom = (props.allowedit && index === hover)
                            ? <div className='Itinerarylookup_menu'>
                                <button
                                    className='Itinerarylookup_editbtn'
                                    style={{ backgroundColor: 'var(--maindark)', color: '#ffffff', fontFamily: 'Lato', fontSize: 16 }}
                                    onClick={() => props.edit(index, component)}
                                    value={index}
                                >
                                    Edit
                                </button>
                            </div>
                            : null

                        const btnwt = 25
                        let addmoretop_dom = (props.allowedit && index === hover)
                            ? <button style={{
                                position: 'absolute',
                                width: btnwt,
                                height: btnwt,
                                right: `calc( 50% - ${btnwt / 2}px )`,
                                top: `calc( 100% -  ${btnwt / 2}px )`,
                                backgroundColor: 'var(--maindark)',
                                color: '#ffffff',
                                borderRadius: 50,
                                display: 'flex',
                                alignItems: 'center',
                                placeContent: 'center',
                                border: '1px solid var(--maindark)',
                                zIndex: 1,
                                fontFamily: 'none',
                                fontSize: 16,
                                fontStyle: 'normal'
                            }}
                                onClick={() => props.addbtm(index)}
                            >
                                +
                            </button>
                            : null

                        let addmorebtm_dom = (props.allowedit && index === hover)
                            ? <button style={{
                                position: 'absolute',
                                width: btnwt,
                                height: btnwt,
                                right: `calc( 50% - ${btnwt / 2}px )`,
                                bottom: `calc( 100% -  ${btnwt / 2}px )`,
                                backgroundColor: 'var(--maindark)',
                                color: '#ffffff',
                                borderRadius: 50,
                                display: 'flex',
                                alignItems: 'center',
                                placeContent: 'center',
                                border: '1px solid var(--maindark)',
                                zIndex: 1,
                                fontFamily: 'none',
                                fontSize: 16,
                                fontStyle: 'normal'
                            }}
                                onClick={() => props.addtop(index)}
                            >
                                +
                            </button>
                            : null

                        if (Object.keys(qrobj).length > 0) {
                            if (component === 'TEXT') {
                                // need to add url later :  url,
                                const { content, fontFamily, textAlign, fontStyle, fontWeight, color } = qrobj
                                const { fontSize, marginBottom, marginTop } = qrobj['device'][simulatordevice]
                                dom = <div
                                    className='Itinerarylookup_textroot'
                                    onMouseEnter={() => setHover(index)}
                                    style={{
                                        border: selectedborder,
                                        fontSize: fontSize,
                                        paddingTop: marginTop,
                                        paddingBottom: marginBottom,
                                        fontFamily: fontFamily,
                                        fontStyle: fontStyle,
                                        fontWeight: fontWeight,
                                        color: color,
                                        textAlign: textAlign,
                                    }}>

                                    <div className='Itinerarylookup_content' style={{ height: content.length === 0 ? 40 : '' }}>{content}</div>
                                    {hover_dom}
                                    {addmoretop_dom}
                                    {addmorebtm_dom}
                                </div>
                            }
                            else if (component === 'BANNER') {
                                const { url } = qrobj
                                dom = <div
                                    className='Itinerarylookup_textroot'
                                    style={{
                                        border: selectedborder,
                                        height: url === '' ? 40 : 'auto',
                                        paddingLeft: qrobj['device'][simulatordevice].paddingRight,
                                        paddingRight: qrobj['device'][simulatordevice].paddingRight,
                                        paddingTop: qrobj['device'][simulatordevice].paddingTop,
                                        paddingBottom: qrobj['device'][simulatordevice].paddingBottom,
                                    }}
                                    onMouseEnter={() => setHover(index)}
                                >
                                    <img src={url} width='100%' />
                                    {hover_dom}
                                    {addmoretop_dom}
                                    {addmorebtm_dom}
                                </div>
                            }
                            else if (component === 'EMPTYSPACE') {
                                dom = <div
                                    style={{
                                        border: selectedborder,
                                        height: { 'Thin': 50, 'Medium': 100, 'Large': 150 }[qrobj.value],
                                        display: 'flex', flexDirection: 'row', width: '100%', position: 'relative'
                                    }}
                                    onMouseEnter={() => setHover(index)}
                                >
                                    {hover_dom}
                                    {addmoretop_dom}
                                    {addmorebtm_dom}
                                </div>
                            }
                            else if (component === 'IMAGE') {
                                dom = <div
                                    style={{
                                        border: selectedborder,
                                        height: 100, display: 'flex', flexDirection: 'row', width: '100%', position: 'relative', placeContent: 'center', alignItems: 'center'
                                    }}
                                    onMouseEnter={() => setHover(index)}
                                >
                                    {qrobjs[index] && qrobjs[index].value === 'couple'
                                        ? <div style={{ display: 'flex', flexDirection: 'row', }}>
                                            <img
                                                className='Itinerarylookup_img'
                                                src={qrobjs[index].coupleimg1.length === 0
                                                    ? S3CLOUD_CLOUDFRONT_ASSET + "classab10123.8097526711624771582975"
                                                    // : qrobjs[index].coupleimg1}
                                                    : getS3fullpath_withbucketname('USER_QR_IMG', qrobjs[index]['coupleimg1'])}

                                                width={100}
                                                height={100} />
                                            <div style={{ position: 'relative', display: 'flex', placeContent: 'center', alignContent: 'center' }}>
                                                <div style={{ width: 10 }} />
                                                {qrobjs[index] && qrobjs[index].value === 'couple' && qrobjs[index].coupleheartstate
                                                    ? <div style={{ position: 'absolute', bottom: 0, color: qrobjs[index].coupleheartcolor }}>
                                                        {iconwrapper(HEARTFILLBORDER_ICON, { width: 30, height: 30, })}
                                                    </div>
                                                    : null}
                                            </div>

                                            <img
                                                className='Itinerarylookup_img'
                                                src={qrobjs[index].coupleimg2.length === 0
                                                    ? S3CLOUD_CLOUDFRONT_ASSET + "classab10123.3682746001617776634978"
                                                    // : qrobjs[index].coupleimg2}
                                                    : getS3fullpath_withbucketname('USER_QR_IMG', qrobjs[index]['coupleimg2'])}
                                                width={100}
                                                height={100}
                                            />
                                        </div>
                                        : null}

                                    {hover_dom}
                                    {addmoretop_dom}
                                    {addmorebtm_dom}
                                </div>
                            }
                            else if (component === 'QR') {
                                const { url, color, device } = qrobj
                                const { marginBottom, marginTop, qrdim } = device[simulatordevice]

                                dom = <div
                                    className='Itinerarylookup_qrroot'
                                    style={{
                                        border: selectedborder,
                                        paddingTop: marginTop,
                                        paddingBottom: marginBottom,
                                        color: color,
                                    }}

                                    onMouseEnter={() => setHover(index)}
                                >
                                    {url && url.length > 0 ? <QRCode className={"Qr_coderoot"} value={url} size={qrdim} /> : null}
                                    {hover_dom}
                                    {addmoretop_dom}
                                    {addmorebtm_dom}
                                </div>
                            }

                        }

                        return <Draggable
                            key={'qrobj' + index}
                            draggableId={'id' + index}
                            index={index}
                        >
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                                    <div id='Itinerarylookup_comproot' style={{ width: device_wt[simulatordevice] }}>
                                        {dom}
                                    </div>
                                </div>)}
                        </Draggable>

                    })}
                    {provided.placeholder}
                </div>)}
        </Droppable>
    </DragDropContext>

    const APP_DOM = loaded && qrobjs.length > 0
        ? <div >
            {qrobjs.map((qrobj, index) => {
                const { component } = qrobj // component : TEXT, BANNER, TIMELINE, IMAGE
                let dom
                let selectedborder = selectededitindex === index ? '1px solid red' : grid ? '1px dashed var(--subgrey)' : 'none'
                if (Object.keys(qrobj).length > 0) {
                    if (component === 'TEXT') {
                        // need to add url later :  url,
                        const { content, fontFamily, textAlign, fontStyle, fontWeight, color } = qrobj
                        const { fontSize, marginBottom, marginTop } = qrobj['device'][simulatordevice]
                        dom = <div
                            className='Itinerarylookup_textroot'
                            onMouseEnter={() => setHover(index)}
                            style={{
                                border: selectedborder,
                                fontSize: fontSize,
                                paddingTop: marginTop,
                                paddingBottom: marginBottom,
                                fontFamily: fontFamily,
                                fontStyle: fontStyle,
                                fontWeight: fontWeight,
                                color: color,
                                textAlign: textAlign,
                            }}>

                            <div className='Itinerarylookup_content' style={{ height: content.length === 0 ? 40 : '' }}>{content}</div>

                        </div>
                    }
                    else if (component === 'BANNER') {
                        const { url } = qrobj

                        dom = <div
                            className='Itinerarylookup_textroot'
                            style={{
                                border: selectedborder,
                                height: url === '' ? 40 : 'auto',
                                paddingLeft: qrobj['device'][simulatordevice].paddingRight,
                                paddingRight: qrobj['device'][simulatordevice].paddingRight,
                                paddingTop: qrobj['device'][simulatordevice].paddingTop,
                                paddingBottom: qrobj['device'][simulatordevice].paddingBottom,
                            }}
                            onMouseEnter={() => setHover(index)}
                        >
                            <img src={url} width='100%' />

                        </div>
                    }
                    else if (component === 'TIMELINE') {
                        const s_ = { width: 30, height: 40, verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden' }
                        const vb_ = "0 0 1024 1024"
                        const v_ = "1.1"
                        const x_ = "http://www.w3.org/2000/svg"

                        const { size, content, icon, time, title } = qrobj

                        const peeknext_is_tl = qrobjs[index + 1]
                            ? qrobjs[index + 1].component === 'TIMELINE'
                            : false

                        const size_map = {
                            0: ['30%', '50px', 'calc( 100% - 30% - 50px)'],
                            1: ['25%', '50px', 'calc( 100% - 25% - 50px)']
                        }[size]

                        dom = <div
                            style={{
                                border: selectedborder,
                                display: 'flex', flexDirection: 'row', width: '100%', position: 'relative'
                            }}
                            onMouseEnter={() => setHover(index)}
                        >
                            <div
                                // className='Itinerarylookup_timeroot'
                                style={{
                                    // border: '1px solid var(--maindark)', 
                                    borderRight: 'none', display: 'flex', whiteSpace: 'pre-wrap',
                                    justifyContent: time['justifyContent'],
                                    width: size_map[0],
                                    fontFamily: time['fontFamily'],
                                    color: time['color'],
                                    fontStyle: time['fontStyle'],
                                    fontWeight: time['fontWeight'],
                                    fontSize: time['device'][simulatordevice]['fontSize'],
                                    paddingBottom: time['device'][simulatordevice]['marginBottom'],
                                    paddingTop: time['device'][simulatordevice]['marginTop'],
                                    paddingLeft: time['device'][simulatordevice]['marginLeft'],
                                    paddingRight: time['device'][simulatordevice]['marginRight'],
                                }}
                            >
                                {time.value}
                            </div>

                            {icon.state
                                ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div
                                        className='Itinerarylookup_iconroot'
                                        style={{
                                            // border: '1px solid var(--maindark)', 
                                            minHeight: 50, minWidth: 50, display: 'flex', placeContent: 'center', alignItems: 'center',
                                            color: icon['color']
                                        }}
                                    >
                                        {icon.value.length > 0
                                            ? <svg className="icon" style={s_} viewBox={vb_} version={v_} xmlns={x_}>
                                                <path d={icon.value} />
                                            </svg>
                                            : null}

                                    </div>
                                    {peeknext_is_tl
                                        ? <div style={{ height: '100%', width: 1, borderLeft: '1px solid var(--maindark)' }} />
                                        : null}
                                </div>
                                : <div
                                    className='Itinerarylookup_iconroot'
                                    style={{
                                        //  border: '1px solid var(--maindark)',
                                        height: 50, borderLeft: 'none', borderRight: 'none', display: 'flex', placeContent: 'center', alignItems: 'center',
                                        width: size_map[1],
                                    }}
                                />}

                            <div
                                className='Itinerarylookup_titlecontentroot'
                                style={{
                                    //  border: '1px solid var(--maindark)',
                                    height: 'auto', minHeight: 50, borderLeft: 'none',
                                    width: size_map[2],
                                }}
                            >
                                <div
                                    style={{
                                        whiteSpace: 'pre-wrap',
                                        fontFamily: title['fontFamily'],
                                        color: title['color'],
                                        fontStyle: title['fontStyle'],
                                        fontWeight: title['fontWeight'],
                                        fontSize: title['device'][simulatordevice]['fontSize'],
                                        paddingBottom: title['device'][simulatordevice]['marginBottom'],
                                        paddingTop: title['device'][simulatordevice]['marginTop'],
                                        paddingLeft: title['device'][simulatordevice]['marginLeft'],
                                        paddingRight: title['device'][simulatordevice]['marginRight'],
                                    }}
                                >
                                    {title.value}
                                </div>
                                <div
                                    style={{
                                        whiteSpace: 'pre-wrap',
                                        fontFamily: content['fontFamily'],
                                        color: content['color'],
                                        fontStyle: content['fontStyle'],
                                        fontWeight: content['fontWeight'],
                                        fontSize: content['device'][simulatordevice]['fontSize'],
                                        paddingBottom: content['device'][simulatordevice]['marginBottom'],
                                        paddingTop: content['device'][simulatordevice]['marginTop'],
                                        paddingLeft: content['device'][simulatordevice]['marginLeft'],
                                        paddingRight: content['device'][simulatordevice]['marginRight'],
                                    }}
                                >
                                    {content.value}
                                </div>
                            </div>

                        </div>

                    }
                    else if (component === 'EMPTYSPACE') {
                        dom = <div
                            style={{
                                border: selectedborder,
                                height: { 'Thin': 50, 'Medium': 100, 'Large': 150 }[qrobj.value],
                                display: 'flex', flexDirection: 'row', width: '100%', position: 'relative'
                            }}
                        >

                        </div>
                    }
                    else if (component === 'IMAGE') {
                        dom = <div
                            style={{
                                border: selectedborder,
                                height: 100, display: 'flex', flexDirection: 'row', width: '100%', position: 'relative', placeContent: 'center', alignItems: 'center'
                            }}
                            onMouseEnter={() => setHover(index)}
                        >
                            {qrobjs[index] && qrobjs[index].value === 'couple'
                                ? <div style={{ display: 'flex', flexDirection: 'row', }}>
                                    <img
                                        className='Itinerarylookup_img'
                                        src={qrobjs[index].coupleimg1.length === 0
                                            ? S3CLOUD_CLOUDFRONT_ASSET + "classab10123.8097526711624771582975"
                                            // : qrobjs[index].coupleimg1}
                                            : getS3fullpath_withbucketname('USER_QR_IMG', qrobjs[index]['coupleimg1'])}

                                        width={100}
                                        height={100} />
                                    <div style={{ position: 'relative', display: 'flex', placeContent: 'center', alignContent: 'center' }}>
                                        <div style={{ width: 10 }} />
                                        {qrobjs[index] && qrobjs[index].value === 'couple' && qrobjs[index].coupleheartstate
                                            ? <div style={{ position: 'absolute', bottom: 0, color: qrobjs[index].coupleheartcolor }}>
                                                {iconwrapper(HEARTFILLBORDER_ICON, { width: 30, height: 30, })}
                                            </div>
                                            : null}
                                    </div>

                                    <img
                                        className='Itinerarylookup_img'
                                        src={qrobjs[index].coupleimg2.length === 0
                                            ? S3CLOUD_CLOUDFRONT_ASSET + "classab10123.3682746001617776634978"
                                            // : qrobjs[index].coupleimg2}
                                            : getS3fullpath_withbucketname('USER_QR_IMG', qrobjs[index]['coupleimg2'])}

                                        width={100}
                                        height={100}
                                    />
                                </div>
                                : null}

                        </div>
                    }
                    else if (component === 'QR') {
                        const { url, color, device } = qrobj
                        const { marginBottom, marginTop, qrdim } = device[simulatordevice]

                        dom = <div
                            className='Itinerarylookup_qrroot'
                            style={{
                                border: selectedborder,
                                paddingTop: marginTop,
                                paddingBottom: marginBottom,
                                color: color,
                            }}

                            onMouseEnter={() => setHover(index)}
                        >
                            {url && url.length > 0 ? <QRCode className={"Qr_coderoot"} value={url} size={qrdim} /> : null}
                        </div>
                    }

                }

                return <div
                    key={'qrobj' + index}
                    id='Itinerarylookup_comproot'
                    style={{ width: device_wt[simulatordevice] }}
                >
                    {dom}
                </div>

            })}
        </div>
        : <div />

    const frame_css = (frame === 0
        ? {
            border: `${framethickness}px ${framestroke} ${framecolor}`,
            borderRadius: framecorner,
            width: 'calc( 100% - 2px )',
            height: 'auto',
            margin: 1
        }
        : {})

    const device_css = {
        'WT': {},
        'M': from === 'EDIT' ? { width: 'max-content' } : {},
        'P': from === 'EDIT' ? { width: 'max-content', } : {},
    }[simulatordevice]

    if (from === 'EDIT') {
        return <div key="EDIT_ITINERARYLOOKUP" style={{ display: 'flex', width: '100%', placeContent: wt < 958 && simulatordevice === 'P' ? 'start' : 'center' }}>
            <div
                id='Itinerarylookup_root'
                style={{
                    backgroundColor: downloadtransparent ? 'transparent' : bgcolor,
                    boxShadow: props.downloadmodal ? 'none' : '0 2px 8px #0e131812',
                    ...frame_css,
                    ...device_css
                }}
                onClick={() => setHover(-1)}
            >
                {EDIT_DOM}
            </div>
        </div>
    }
    else if (from === 'APP') {
        return qrobjs.length > 0
            ? <div
                id='Itinerarylookup_root'
                style={{
                    backgroundColor: bgcolor,
                    ...frame_css,
                    ...device_css,
                    boxShadow: 'none'
                }}
                onClick={() => setHover(-1)}
            >
                {APP_DOM}
            </div>
            : !firsttime
                ? <div className='Itinerarylookup_empty'>
                    Your Page Is Empty
                </div>
                : null
    }
    else if (from === 'LOGIN') {
        return <div
            id='Itinerarylookup_root'
            style={{ backgroundColor: bgcolor, ...frame_css, ...device_css }}
            onClick={() => setHover(-1)}
        >
            <Loginpasscode
                url={LOGIN_QR}
                urlhandler={urlhandler}
                onsuccess={(data, pc, urlhandler) => {
                    if (data.message === 'SUCCESS') {
                        props.history.push('/timelinelookup/' + urlhandler + '/passcode=' + pc)
                        refresh()
                    }
                }} />
        </div>
    }
    else {
        return <Loading />
    }

}
export default Qrlookup