import React from 'react'
import './Togglebutton.css'

function Togglebutton(props) {

    const {
        rootstyle = {}, // root style
        singlebtnstyle = {}, // style for every single btn
        funcs, // arr of functions
        txts, // arr of strings OR arr of icon
        btnselected  // first btn is selected
    } = props

    return <div className='Togglebtn_root' style={rootstyle}>
        {[...Array(txts.length).keys()].map((btn, i) => {
            return <button
                key={'togglebtn' + i}
                className={`Togglebtn_btn ${btnselected === i ? 'Togglebtn_selected' : ''}`}
                style={singlebtnstyle}
                onClick={() => { funcs[i]() }}
            >
                {txts[i]}
            </button>
        })}
    </div>
}

export default Togglebutton