import React, { useState, useEffect, } from 'react' //useEffect, useLayoutEffect 
import { EMPTY, EMPTYSPACE, ARROWLEFT_ICON, TABLET } from '../../config/constants';
import { BLOGCONTENT, BLOG_KEYNAME_MAP, BEYONDCONTENT, BEYOND_KEYNAME_MAP } from '../../localdb/Localdb';
import { Processdate, Checkdevice } from '../../components';

const textcolor = '#707070'

const handleText = (key, val) => {
    return (
        <div key={key} style={{ color: textcolor, marginTop: 20, fontSize: 18, }}> {/*color: 'rgba(0, 0, 0, 0.54)', */}
            {val}
        </div>
    )
}

const handleQuote = (key, val, from) => {
    return (
        <div key={key} style={{ marginTop: 20, backgroundColor: 'rgb(235, 100, 117)', fontSize: 20, fontWeight: 'bold', borderRadius: 5, padding: 5, whiteSpace: 'wrap', textAlign: 'start', display: 'flex', flexDirection: 'column', color: 'white', }}>
            <div style={{ padding: 10 }}>{val}</div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', padding: 5, fontWeight: 'bold' }}>{from}</div>
        </div>
    )
}

const handleDom = (key, val) => {
    return (
        <div key={key} style={{ marginTop: 20, width: '100%', overflowY: 'auto', WebkitOverflowScrolling: 'touch', justifyContent: 'center' }}>
            {val}
        </div>
    )
}

function Blogpageparser(props) {

    const { content, wt } = props
    const [content_dom, setContent_dom] = useState([])
    // content structure , array of map
    // [
    //     { 
    //         key: 'TEXT' | 'HTML' | 'QUOTE',
    //         val: '' | <div/>,
    //     },
    // ]

    useEffect(() => {
        if (content) {
            var content_dom = []
            for (let i = 0; i < content.length; i++) {
                const { key, val } = content[i]

                switch (key) {
                    case 'TEXT': {
                        content_dom.push(handleText(key + i, val))
                        break
                    }
                    case 'DOM': {
                        content_dom.push(handleDom(key + i, val))
                        break
                    }
                    case 'QUOTE': {
                        const { from } = content[i]
                        content_dom.push(handleQuote(key + i, val, from))
                        break
                    }
                    default: break
                }
            }
            setContent_dom(content_dom)

        }

    }, [props])

    return (
        <div style={{ width: wt, display: 'flex', flexDirection: 'column', height: 'max-content', paddingTop: 10 }}>
            {content_dom}
        </div>
    )


}



export default function Bpage(props) {

    const device = Checkdevice()
    var { outerlayer_padding, imgwt, imght, imgbr, titlefs, subtitlefs, datefs, outerlayer_margin, imgfs } = 0
    if (device === 'WEB') {
        outerlayer_padding = '60px 40px 40px'
        outerlayer_margin = '0 auto'

        imgwt = 1000
        imght = 500
        imgbr = 5
        titlefs = 34
        subtitlefs = 24
        datefs = 13
        imgfs = 150
    }
    else if (device === 'TABLET') {
        outerlayer_padding = '50px 16px 16px'
        outerlayer_margin = '5px'
        imgwt = TABLET * 0.9
        imght = 500
        imgbr = 5

        titlefs = 34
        subtitlefs = 24
        datefs = 13
        imgfs = 100

    }
    else if (device === 'MOBILE') {
        outerlayer_padding = '50px 16px 16px'
        outerlayer_margin = '0 auto'

        imgwt = 320
        imght = 190
        imgbr = 5
        titlefs = 20
        subtitlefs = 15
        datefs = 13
        imgfs = 35
    }

    const [CONTENT, setCONTENT] = useState([])

    const [title, setTitle] = useState(EMPTY)
    const [data, setData] = useState([])
    const [key, setKey] = useState(0)
    const [dir, setDir] = useState('')

    useEffect(() => {
        if (title === EMPTY && props.match) {
            const { path } = props.match
            var dir = path.split('/')[1]
            dir.toLowerCase()
            const local_title = props.match.params.title

            if (dir === 'blog' && BLOG_KEYNAME_MAP[local_title]) {
                try {
                    window.scrollTo(0, 0) // scroll to the top
                    const key = BLOG_KEYNAME_MAP[local_title]
                    const data = BLOGCONTENT[key]
                    setKey(key)
                    setTitle(local_title)
                    setData(data)
                    setCONTENT(BLOGCONTENT)
                    setDir('blog')
                }
                catch (e) {
                    props.history.push('/')
                }

            }
            else if (dir === 'beyond' && BEYOND_KEYNAME_MAP[local_title]) {
                try {
                    window.scrollTo(0, 0) // scroll to the top
                    const key = BEYOND_KEYNAME_MAP[local_title]
                    const data = BEYONDCONTENT[key]
                    setCONTENT(BEYONDCONTENT)

                    setKey(key)
                    setTitle(local_title)
                    setData(data)
                    setDir('beyond')

                }
                catch (e) {
                    props.history.push('/')
                }
            }
            else {
                props.history.push('/')

            }


        }

    }, [])

    const leftbtn = <button className="planning_backicon"
        onClick={() => {
            if (dir === 'blog') {
                props.history.push('/blog')
            }
            else {
                props.history.push('/beyond')
            }
        }}
    >
        {ARROWLEFT_ICON}
    </button>

    return (
        <div style={{ padding: outerlayer_padding, margin: outerlayer_margin, display: 'flex', flexDirection: 'column', minHeight: '100vh', touchAction: 'manipulation' }}>
            <div style={{ display: 'flex', flexDirection: 'column ', width: '100%', alignItems: 'center' }}>
                <div style={{ margin: 5, width: '100%' }}>{leftbtn}</div>
                <div style={{ fontSize: titlefs, color: '#707070', fontWeight: 'bold', width: '100%', display: 'flex', justifyContent: 'center', padding: '10px 0px 0px 0px' }}>{data.title}</div>
                <div style={{ fontSize: subtitlefs, fontWeight: 'lighter', width: '100%', display: 'flex', justifyContent: 'center', padding: 3 }}> {key ? CONTENT[key].subtitle : ''}</div>
                <div style={{ fontSize: datefs, fontWeight: 'lighter', width: '100%', display: 'flex', justifyContent: 'center', padding: 3 }}> {key ? Processdate(CONTENT[key].date) : ''}</div>
                {data.img
                    ? data.imgtype === 'svg'
                        ? <object style={{ backgroundColor: 'rgba(251, 225, 229, 0.52)', padding: 10, height: imght, width: imgwt, borderRadius: imgbr }} data={data.img} type="image/svg+xml">{EMPTYSPACE}</object>
                        : <div style={{ backgroundColor: 'rgba(251, 225, 229, 0.52)', padding: 10, height: imght, width: imgwt, borderRadius: imgbr, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: imgfs }}>{data.img}</div>
                    : <div />}
                <Blogpageparser content={data.content} wt={imgwt} />
            </div>
        </div>
    )
}

