
import React, { useState, useEffect, useRef } from 'react'
import { Checkdevice, refresh } from '../../../../components'
import { DEVICE_DIM_MAP, VT_QUIZ_MAP, RIGHTARROW_ICON_SMALL, READABLE_VENDORTYPE_MAP, RECAPTCHA_KEY } from '../../../../config/constants'
import { Questionpage, Imagepage } from './components'
import { BUTTONSTYLE } from '../../../../config/cssconstants'
import { UPDATE_QUIZUSERINTERPRET } from '../../../../config/api'
import ReCAPTCHA from "react-google-recaptcha"
import axios from 'axios'
import './Quiz.css'


function Quiz(props) {
    const device = Checkdevice()
    const { cat, vt, token } = props
    const { wt, ht, fs } = DEVICE_DIM_MAP[device]
    const [firsttime, setFirsttime] = useState(true)
    const [data, setData] = useState({})
    const [selected, setSelected] = useState([])
    const [currpage, setCurrpage] = useState(0)
    const [maindom, setMaindom] = useState([])
    const [loadqns, setLoadqns] = useState(true)
    const [loadresult, setLoadresult] = useState(false)
    const [finalresult, setFinalresult] = useState(false)
    const [captchagate, setCaptchagate] = useState(false) // to detect if captchais verified or not 
    const [captchacodetoken, setCaptchatoken] = useState('') // store captcha keys
    const captchaRef = useRef(null) // for captcha
    const captchaChange = (res) => { setCaptchatoken(res); res ? setCaptchagate(true) : setCaptchagate(false) }

    useEffect(() => {
        if (firsttime) {
            setFirsttime(false)
            setData(VT_QUIZ_MAP[vt])
            document.body.style.overflowY = 'hidden'
        }

        return () => {
            document.body.style.overflowY = 'auto'
        }
    }, []);

    useEffect(() => {
        if (!firsttime && !(selected.length > 0)) {
            var local_selected = selected.splice()
            const { pages, imgset, qnset } = data
            var imgindex = 0
            var qnindex = 0
            for (let i = 0; i < pages.length; i++) {
                var choose_holder = []
                if (pages[i] === 'img' && imgset && imgset[imgindex]) {
                    const { choose } = imgset[imgindex]
                    console.log(imgset[imgindex])
                    for (let j = 0; j < parseInt(choose, 10); j++) choose_holder.push(-1)
                    imgindex++
                    local_selected.push(choose_holder)
                }
                else if (pages[i] === 'qn' && qnset && qnset[qnindex]) {
                    const { choose } = qnset[qnindex]
                    for (let j = 0; j < parseInt(choose, 10); j++) choose_holder.push(-1)
                    qnindex++
                    local_selected.push(choose_holder)
                }
            }
            setSelected(local_selected)
        }
    }, [data])

    useEffect(() => {
        if (!firsttime) setMaindom(makeMaindom())
    }, [selected])

    useEffect(() => {
        if (loadresult) {
            try {
                let options = {
                    method: 'POST',
                    url: UPDATE_QUIZUSERINTERPRET,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                    data: { vt: vt, cat: cat, selected: selected, captcha: captchacodetoken }
                }
                axios(options).then((res) => {
                    const { status } = res // true
                    if (status === 200) {
                        setFinalresult(true)
                        setLoadresult(false)
                    }
                }).catch((e) => { })
            }
            catch (e) {
                console.log('e', e)
            }
        }
    }, [loadresult])


    const makeMaindom = () => {
        var local_maindom = []
        const { imgset, qnset, pages } = VT_QUIZ_MAP[vt]
        if (pages && pages.length > 0) {

            var imgindex = 0
            var qnindex = 0

            for (let i = 0; i < pages.length; i++) {
                if (pages[i] === 'img') {
                    const { title, imgs } = imgset[imgindex]
                    local_maindom.push(
                        <Imagepage
                            title={title}
                            imgs={imgs}
                            pageindex={i}
                            selected={selected}
                            updateSelected={(item) => { setSelected(item) }}
                        />)
                    imgindex = imgindex + 1
                }
                else if (pages[i] === 'qn') {
                    const { title, qn, selections } = qnset[qnindex]
                    local_maindom.push(
                        <Questionpage
                            title={title}
                            qn={qn}
                            pageindex={i}
                            selections={selections}
                            selected={selected}
                            updateSelected={(item) => { setSelected(item) }}
                        />)
                    qnindex = qnindex + 1
                }
            }
        }
        return local_maindom
    }

    function verifyPageQnsAnswered() {
        for (let i = 0; i < selected[currpage].length; i++) {
            if (selected[currpage][i] < 0) return false
        }
        return true
    }

    const nextdom = maindom && maindom.length > 0 && currpage < maindom.length - 1 && verifyPageQnsAnswered()
        ? <button
            style={{ ...BUTTONSTYLE, width: '100%', alignSelf: 'flex-end', color: 'var(--maindark)', border: 'none', margin: '10px 0px 50px 0px' }}
            onClick={() => { setCurrpage(currpage + 1); }}>{RIGHTARROW_ICON_SMALL}</button>
        : null

    const captchafield = currpage === maindom.length - 1 && captchacodetoken.length === 0 && verifyPageQnsAnswered()
        ? <ReCAPTCHA
            ref={(el) => { captchaRef.current = el }}
            style={{ display: 'flex', margin: '10px 0px 100px 0px', placeContent: 'center' }}
            sitekey={RECAPTCHA_KEY}
            onChange={captchaChange}
        /> : null

    const completedom = captchagate
        ? <button
            style={{ ...BUTTONSTYLE, width: '100%', alignSelf: 'flex-end', color: 'var(--maindark)', margin: '10px 0px 100px 0px' }}
            onClick={() => { setLoadqns(false); setLoadresult(true) }}>Complete</button>
        : null

    return (   //width: wt, minHeight: ht,
        <div className='Quiz_root' style={{ fontSize: fs, width: { 'WEB': 660, 'TABLET': 660, 'MOBILE': '100%' }[device] }}>

            <h2 className='Quiz_title'>{cat + ', ' + READABLE_VENDORTYPE_MAP[vt]}</h2>

            {loadqns
                ? <div style={{ width: '100%' }}>
                    <div className='Quiz_content'>
                        {maindom[currpage]}
                    </div>
                    {nextdom}
                    {captchafield}
                    {completedom}
                </div>
                : null}

            {loadresult
                ? <div style={{ position: 'absolute', display: 'flex', flexDirection: 'column', zIndex: 1, width: '100vw', height: '100vh', left: 0, top: 0 }}>
                    <div style={{ position: 'absolute', display: 'flex', placeContent: 'center', alignContent: 'center', backgroundColor: '#fde9e9b5', width: '100vw', height: '100vh' }} />
                    <div style={{ position: 'absolute', display: 'flex', placeContent: 'center', alignContent: 'center', width: '100vw', height: '100vh', zIndex: 1, justifyContent: 'center', alignItems: 'center', color: 'var(--maindark)' }} >
                        Loading...
                    </div>
                </div>
                : null}

            {finalresult
                ? <div style={{ position: 'absolute', width: '100vw', height: '100vh', top: 0, left: 0, backgroundColor: 'var(--maindark)6e', placeContent: 'center', display: 'flex', alignItems: 'center', color: 'var(--maindark)' }}>
                    <div style={{ position: 'relative', display: 'flex', backgroundColor: 'white', width: 250, height: 300, borderRadius: 10, flexDirection: 'column', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px' }}>
                        <div style={{ color: 'var(--maindark)', height: '100%', padding: 10, display: 'flex', placeContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                            {`Our vendors will contact you shortly via email or phone. By the way, we have also updated your filters in the ${cat} '${vt}' category based on your quiz answers. Click OK to return and discover vendors that match your style.`}
                        </div>
                        <button
                            style={{ ...BUTTONSTYLE, border: '1px solid var(--maindark)', color: 'var(--maindark)', margin: 5 }}
                            onClick={() => {
                                props.history.push('/' + cat + '/' + vt)
                                refresh()
                            }}
                        >
                            OK
                        </button>
                    </div>
                </div>
                : null}


        </div>
    )

}

export default Quiz