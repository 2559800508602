import React from 'react'
import { Link } from 'react-router-dom'
import { FACEBOOK_ICON, INSTAGRAM_ICON, REDDIT_ICON, STARTUP_NAME, TELEGRAM_ICON } from '../../config/constants'
import { Checkdevice } from '../Checkdevice'
import './Footer.css'
import { S3CLOUD_CLOUDFRONT_ASSET } from '../../config/s3directories'
const sg_icon =  `${S3CLOUD_CLOUDFRONT_ASSET}footer/sg.png`
function Footer() {
    const device = Checkdevice()
    return <div className={"Footer_root" + device}>
        <div className={"Footer_subroot" + device} style={{ width: device === 'MOBILE' ? '80%' : '50%' }}>
            <div className="Footer_title">{STARTUP_NAME}</div>
            <div className="Footer_content">
                <div className="Footer_item" style={{display:'flex', flexDirection:'column'}}>
                    <div>Wedding & Event Planning Made Simple</div>
                    <div>Celebrations Made Memorable</div>
                </div>
                {/* <div className="Footer_divider" /> */}
                {/* <img src={sg_icon} width="100px" alt="" /> */}
            </div>
        </div>
        <div className={"Footer_subroot" + device}>
            <div className="Footer_title">About</div>
            <div className="Footer_content">
                <Link className="Footer_item" to="/faq">FAQ</Link>
                {/* <Link className="Footer_item" to="/blog">Blog</Link> */}
            </div>
        </div>
        <div className={"Footer_subroot" + device}>
            <div className="Footer_title">Social Media</div>
            <div className="Footer_content">
                <a className="Footer_iconrow" href={`https://www.instagram.com/${STARTUP_NAME.toLowerCase()}/`} target="_blank" rel="noopener noreferrer">
                    <div className="Footer_icon" >{INSTAGRAM_ICON}</div>
                    <div className="Footer_item">Instagram</div>
                </a>
                <a className="Footer_iconrow" href={`https://www.facebook.com/profile.php?id=61554543434987`} target="_blank" rel="noopener noreferrer">
                    <div className="Footer_icon" >{FACEBOOK_ICON}</div>
                    <div className="Footer_item">Facebook</div>
                </a>
                <a className="Footer_iconrow" href="" target="_blank" rel="noopener noreferrer">
                    <div className="Footer_icon" >{TELEGRAM_ICON}</div>
                    <div className="Footer_item">Telegram</div>
                </a>
                <a className="Footer_iconrow" href="https://www.reddit.com/r/sgwedding/" target="_blank" rel="noopener noreferrer">
                    <div className="Footer_icon" >{REDDIT_ICON}</div>
                    <div className="Footer_item">{'Reddit ( Wedding )'}</div>
                </a>
                <a className="Footer_iconrow" href={`https://www.reddit.com/r/${STARTUP_NAME.toLowerCase()}/`} target="_blank" rel="noopener noreferrer">
                    <div className="Footer_icon" >{REDDIT_ICON}</div>
                    <div className="Footer_item">{'Reddit ( Weavebliss )'}</div>
                </a>
            </div>
        </div>
        <div className={"Footer_subroot" + device} style={{ fontWeight: 'bold' }}>v1.8.7</div>
    </div>
}

export default Footer