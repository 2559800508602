import React, { useEffect, useRef, useState } from 'react'
import { TRASH_ICON, } from '../../../../../../../config/constants'
import { ScreenWidth } from '../../../../../../../components';
import './Rgallery.css'
import '../Rstyle.css'
import '../Common.css'

export default function Rgallery(props) {

    const { device, galleryquota, updategallery, rmvgalleryimg, galleryimgs } = props
    const [localgalleryimgs, setLocalgalleryimgs] = useState([])
    const galleryinputref = useRef(null)
    const wt = ScreenWidth()

    useEffect(() => {
        setLocalgalleryimgs(galleryimgs)
    }, [galleryimgs])

    return device === 'MOBILE'
        ? <div className="gbrwd_content_m">
            <div className="gbrwd_numbers_m">
                <div className="gbrwd_galleryindex" style={{ marginRight: 0 }}>
                    <div>{'Total'}</div>
                    <div className="gbrwd_digit_m">
                        {localgalleryimgs && localgalleryimgs.length > 0
                            ? localgalleryimgs.length + ' / ' + galleryquota
                            : 0 + ' / ' + galleryquota}
                    </div>
                </div>
            </div>
            <div className="gbrwd_plusrootm">
                <button className="gbrwd_plusm" style={{ width: ((wt - 40) / 2), height: ((wt - 40) / 2) }} onClick={() => galleryinputref.current.click()} >+</button>
            </div>
            <div className="gbrwd_gallerygridm">
                {localgalleryimgs && localgalleryimgs.map((img, index) => {
                    return <div className="gbrwd_gallerycardm" key={'gbrwd_galleryimg' + img} style={{ backgroundImage: `url(${img})`, width: ((wt - 40) / 2), height: ((wt - 40) / 2) }} >
                        <button className="gbrwd_deleteroot" onClick={() => rmvgalleryimg(img)}>{TRASH_ICON}</button>
                    </div>
                })}
            </div>
            <input ref={galleryinputref} className="gbrwd_galleryinput" onChange={updategallery} accept="image/*" type='file' multiple="multiple" name="input-file" />
        </div>
        : <div className="gbrwd_gallery">
            <div className="gbrwd_gallerycol">
                <div className="gbrwd_numbers">
                    <div className="gbrwd_galleryindex">
                        <div>{'Total'}</div>
                        <div className="gbrwd_digit">
                            {localgalleryimgs && localgalleryimgs.length > 0
                                ? localgalleryimgs.length + ' / ' + galleryquota
                                : 0 + ' / ' + galleryquota}
                        </div>
                    </div>
                </div>
                <div className="gbrwd_gallerygrid">
                    <div className="gbrwd_gallerycard" style={{ position: 'relative', backgroundColor: 'white', cursor: 'pointer', placeContent: 'center' }} onClick={() => galleryinputref.current.click()}>
                        <button className="gbrwd_plusroot" onClick={() => galleryinputref.current.click()} >+</button>
                    </div>
                    {localgalleryimgs && localgalleryimgs.map((img, index) => {
                        return <div className="gbrwd_gallerycard" key={'gbrwd_galleryimg' + img} style={{ backgroundImage: `url(${img})` }} >
                            <button className="gbrwd_deleteroot" onClick={() => rmvgalleryimg(img)}>{TRASH_ICON}</button>
                        </div>
                    })}
                </div>
            </div>
            <input ref={galleryinputref} className="gbrwd_galleryinput" onChange={updategallery} accept="image/*" type='file' multiple="multiple" name="input-file" />
        </div>

}
