import React, { useState, useEffect } from 'react'
import { ETHERJS } from '../../../../../../../config/api'
import { CLOSE_ICON } from '../../../../../../../config/constants'
import './Purchase.css'

const cereweaveaddr = '0x44Da0941E15D95507D0927D56bC8eD0873151485'
const ETH_NETWORK = '0x1'
const POLYGON_NETWORK = '0x89'
const POLYGONTEST_NETWORK = '80001'

function Purchase(props) {
    // HARDSOFT |
    //           |-> detail
    //           |-> deliver method
    //           |-> payment method |
    //                              |-> METAMASK
    //                              |-> CODE
    //                              |-> PAYNOW
    // SOFT      |
    //           |-> payment method |
    //                              |-> METAMASK
    //                              |-> CODE
    //                              |-> PAYNOW


    // for purchase
    const [purchasetype, setPurchasetype] = useState('SOFT')
    const [paymentmethod, setPaymentmethod] = useState('')
    const [delievermethod, setDelievermethod] = useState('')
    const [code, setCode] = useState('')

    // address & details
    const [name, setName] = useState('')
    const [contact, setContact] = useState('')
    const [email, setEmail] = useState('')
    const [postalcode, setPostalcode] = useState('')
    const [blkstname, setBlkstname] = useState('')
    const [buildinghsenum, setBuildinghsenum] = useState('')
    const [unitno, setUnitno] = useState('')
    const [country, setCountry] = useState('')

    // const [maticprice, setMaticprice] = useState(-1)

    // metamask wallet
    const [mwallet, setMwallet] = useState('-1')
    const [chain, setChain] = useState(null)

    const NFTPRICE = 0.04 // 150 SGD
    const CURRENCY = 'Ξ'
    // const PHY_AND_NFT_PRICE = 0.06

    useEffect(() => {
        const script = document.createElement('script');
        script.src = ETHERJS
        script.type = 'text/javascript'
        script.async = true;
        document.body.appendChild(script);
        window.ethereum.request({ method: 'eth_requestAccounts' }).then((wallet) => {
            setMwallet(wallet[0])
        })
        window.ethereum.request({ method: 'eth_chainId' }).then((chain) => {
            setChain(chain)
            console.log(chain)
        })
        return () => { document.body.removeChild(script); }
    }, [])

    useEffect(() => {
        // function runCoinbasefetch() {
        //     try {
        //         let options = {
        //             method: 'GET',
        //             url: 'https://api.coinbase.com/v2/exchange-rates?currency=MATIC',
        //         }
        //         axios(options).then((res) => {
        //             const { currency, rates } = res['data']['data']
        //             if (currency === 'MATIC') {
        //                 const { SGD } = rates
        //                 const maticprice = NFTPRICE / SGD
        //                 setMaticprice(maticprice)
        //             }
        //         }).catch((e) => {
        //             props.history.push('/')
        //         })
        //     }
        //     catch (e) {
        //         props.history.push('/')
        //     }
        // }

        // runCoinbasefetch()
        // setInterval(function () {
        //     runCoinbasefetch()
        // }, 60 * 1000); // 60 * 1000 milsec

    }, [])

    useEffect(() => {
        setCode('')
        setPaymentmethod('')
    }, [purchasetype])



    const onMetamask = () => {
        window.ethereum.request({ method: 'eth_requestAccounts' }).then((wallet) => {
            if (wallet[0] === mwallet) {
                const ethers = window.ethers
                var provider = new ethers.providers.Web3Provider(window.ethereum)
                var signer = provider.getSigner()
                ethers.utils.getAddress(cereweaveaddr) // to check if the address exist
                signer.sendTransaction({
                    to: cereweaveaddr,
                    // value: ethers.utils.parseEther(maticprice.toString())
                    value: ethers.utils.parseEther(NFTPRICE.toString())
                }).then((info) => {
                    props.onApply(purchasetype, info, name, email, contact)
                }).catch(e => {
                    console.log('sent err', e)
                })
            }
        })

    }


    const switchmmnetwork = (e) => {
        const networkid = e.target.value
        if (networkid === ETH_NETWORK) {
            window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: ETH_NETWORK }],
            }).then((res) => {
                setChain(ETH_NETWORK)
                alert('Switched to Ethereum network')
            }).catch((e) => { })
        }
        else if (networkid === POLYGON_NETWORK) {
            window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: POLYGON_NETWORK }],
            }).then((res) => {
                setChain(POLYGON_NETWORK)
                alert('Switched to Polygon network')
            })
        }
        else if (networkid === POLYGONTEST_NETWORK) {
            window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{
                    chainId: POLYGONTEST_NETWORK,
                    rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
                    chainName: "Mumbai Testnet",
                    nativeCurrency: {
                        name: "MATIC",
                        symbol: "MATIC",
                        decimals: 18
                    },
                    blockExplorerUrls: ["https://mumbai-explorer.matic.today/"]
                }],
            }).then((res) => {
                setChain(POLYGONTEST_NETWORK)
                alert('Switched to Polygon network')
            })
        }
    }

    // const onMetamask = async () => {

    //     // const accounts = await window.ethereum.request({
    //     //     method: "wallet_requestPermissions",
    //     //     params: [{
    //     //         eth_accounts: {}
    //     //     }]
    //     // }).then(() => window.ethereum.request({
    //     //     method: 'eth_requestAccounts'
    //     // }))

    //     // const account = accounts[0]

    //     window.ethereum.request({ method: 'eth_requestAccounts' }).then(async (wallet) => {

    //         if (wallet[0] === mwallet) {
    //             const ethers = window.ethers

    //             // check if is polygon network, if is continue, if not add/switch to matic chain
    //             try {
    //                 await window.ethereum.request({
    //                     method: 'wallet_switchEthereumChain',
    //                     params: [{ chainId: '0x89' }],
    //                 });
    //             } catch (switchError) {
    //                 // This error code indicates that the chain has not been added to MetaMask.
    //                 if (switchError.code === 4902) {
    //                     try {
    //                         // switch to matic network
    //                         window.ethereum.request({
    //                             method: "wallet_addEthereumChain",
    //                             params: [{
    //                                 chainId: "0x89",
    //                                 rpcUrls: ["https://rpc-mainnet.matic.network/"],
    //                                 chainName: "Matic Mainnet",
    //                                 nativeCurrency: {
    //                                     name: "MATIC",
    //                                     symbol: "MATIC",
    //                                     decimals: 18
    //                                 },
    //                                 blockExplorerUrls: ["https://polygonscan.com/"]
    //                             }]
    //                         });

    //                     } catch (addError) {
    //                         // handle "add" error
    //                     }
    //                 }
    //                 // handle other "switch" errors
    //             }


    //             // pay matic
    //             // const matic_params = {
    //             //     name: 'matic',
    //             //     chainId: 137,
    //             //     _defaultProvider: (providers) => new ethers.providers.JsonRpcProvider('https://rpc-mainnet.matic.network/')
    //             // };
    //             var provider = new ethers.providers.Web3Provider(window.ethereum)
    //             var signer = provider.getSigner()

    //             // setTimeout(() => {
    //             //     provider = null
    //             //     console.log('null liao')
    //             //     alert('transaction expired')
    //             //     window.ethereum.on('disconnect', (a) => {
    //             //         console.log('disconnect', a)
    //             //     });
    //             // }, 100)

    //             const cereweaveaddr = '0x44Da0941E15D95507D0927D56bC8eD0873151485'

    //             // alert('payment have to make within 30 minutes. Any payment made subsequent will be forfeit cryptocurrency will not be returned.')

    //             ethers.utils.getAddress(cereweaveaddr) // to check if the address exist
    //             signer.sendTransaction({
    //                 to: cereweaveaddr,
    //                 // value: ethers.utils.parseEther(maticprice.toString())
    //                 value: ethers.utils.parseEther(NFTPRICE.toString())
    //             }).then((info) => {
    //                 props.onApply( purchasetype, info, name, email, contact) 
    //             }).catch(e => {
    //                 console.log('sent err', e)
    //             })




    //         }
    //     })

    // }

    return <div className="p_root">

        <div className="p_subroot">

            <div className='p_toptitlebar'>
                <div>Purchase</div>
                <div className="p_close" onClick={() => props.close(false)}>{CLOSE_ICON}</div>
            </div>

            <div className="p_bodyroot">

                <div className='p_row'>
                    {/* <button className='p_btnleft' style={{ border: purchasetype === 'HARDSOFT' ? '1px solid #eb5d7b' : '1px solid black', color: purchasetype === 'HARDSOFT' ? '#eb5d7b' : 'black', fontWeight: purchasetype === 'HARDSOFT' ? 'bold' : '' }} onClick={() => { setPurchasetype('HARDSOFT') }}>
                        <div>{'Physical & Digital/NFT'}</div>
                                <div >{`${PHY_AND_NFT_PRICE} Ξ`}</div>
                    </button> */}
                    <button
                        className='p_btnright'
                        style={{ border: purchasetype === 'SOFT' ? '1px solid #eb5d7b' : '1px solid black', color: purchasetype === 'SOFT' ? '#eb5d7b' : 'black', fontWeight: purchasetype === 'SOFT' ? 'bold' : '', margin: '10px', width: '100%' }}
                        onClick={() => { setPurchasetype('SOFT') }}
                    >
                        {props.purchase && !Object.keys(props.purchase).includes(props.purchaseitem + '_SOFT')
                            ? <div>
                                <div>{'Digital/NFT'}</div>
                                <div>{`${NFTPRICE + ' ' + CURRENCY}`}</div>
                            </div>
                            : <div>{'Digital/NFT ( Bought )'}</div>}
                    </button>
                </div>
                {{
                    'HARDSOFT': <div className='p_addressroot'>
                        <div className="p_label">Detail</div>
                        <div className="gbedit_purchase_content">
                            <div className="">Name</div>
                            <input className="gbedit_purchase_input" onChange={(e) => setName(e.target.value)} value={name} />
                            <div className="">Contact Number ( Singapore Only )</div>
                            <input className="gbedit_purchase_input" onChange={(e) => setContact(e.target.value)} value={contact} />
                            <div className="">Email</div>
                            <input className="gbedit_purchase_input" onChange={(e) => setEmail(e.target.value)} value={email} />
                            <div className="">Postal Code</div>
                            <input className="gbedit_purchase_input" onChange={(e) => setPostalcode(e.target.value)} value={postalcode} />
                            <div>Block/Street Name</div>
                            <input className="gbedit_purchase_input" onChange={(e) => setBlkstname(e.target.value)} value={blkstname} />
                            <div>Building/House Number</div>
                            <input className="gbedit_purchase_input" onChange={(e) => setBuildinghsenum(e.target.value)} value={buildinghsenum} />
                            <div>Unit Number</div>
                            <input className="gbedit_purchase_input" onChange={(e) => setUnitno(e.target.value)} value={unitno} />
                            <div>Country</div>
                            <input className="gbedit_purchase_input" onChange={(e) => setCountry(e.target.value)} value={country} />
                        </div>
                    </div>,
                    'SOFT': null
                }[purchasetype]}

                {{
                    'HARDSOFT': <div className='p_contentroot'>
                        <div className="p_label">Deliver Method</div>
                        <div className="gbedit_purchase_content">
                            <div className='p_row'>
                                <button className="gbedit_purchase_delibtn" style={{ marginLeft: 0, border: delievermethod === 'normalshipping' ? '1px solid #eb5d7b' : '1px solid black', color: delievermethod === 'normalshipping' ? '#eb5d7b' : 'black', fontWeight: delievermethod === 'normalshipping' ? 'bold' : '' }} onClick={() => { setDelievermethod('normalshipping') }}>
                                    <div>Normal Shipping</div>
                                    <div className="p_delsub">3-5 Days Delivery</div>
                                </button>
                                <button className="gbedit_purchase_delibtn" style={{ border: delievermethod === 'selfcollection' ? '1px solid #eb5d7b' : '1px solid black', color: delievermethod === 'selfcollection' ? '#eb5d7b' : 'black', fontWeight: delievermethod === 'selfcollection' ? 'bold' : '' }} onClick={() => { setDelievermethod('selfcollection') }}>
                                    <div>Self Collection</div>
                                    <div className="p_delsub">Collection At Yishun</div>
                                </button>
                                <button className="gbedit_purchase_delibtn" style={{ border: delievermethod === 'expresshipping' ? '1px solid #eb5d7b' : '1px solid black', color: delievermethod === 'expresshipping' ? '#eb5d7b' : 'black', fontWeight: delievermethod === 'expresshipping' ? 'bold' : '' }} onClick={() => { setDelievermethod('expresshipping') }}>
                                    <div>Express Shipping</div>
                                    <div className="p_delsub">Next Day Delivery for orders before 4PM. (Dm for confirmation)</div>
                                </button>
                            </div>
                        </div>
                    </div>,
                    'SOFT': null
                }[purchasetype]}

                {(purchasetype === 'SOFT' || purchasetype === 'HARDSOFT') && props.purchase && !Object.keys(props.purchase).includes(props.purchaseitem + '_' + purchasetype)
                    ? <div className='p_contentroot'>
                        <div className="p_label">Payment Method</div>
                        <div className="gbedit_purchase_paymentmethod">
                            <div className='p_row'>
                                {/* 
                                <button className="gbedit_purchase_paymentbtn" style={{ marginLeft: 0, border: paymentmethod === 'METAMASK' ? '1px solid #eb5d7b' : '1px solid black', color: paymentmethod === 'METAMASK' ? '#eb5d7b' : 'black', fontWeight: paymentmethod === 'METAMASK' ? 'bold' : '' }} onClick={() => { setPaymentmethod('METAMASK') }}>Metamask</button>
                                {purchasetype === 'SOFT' ? <button className="gbedit_purchase_paymentbtn" style={{ border: paymentmethod === 'CODE' ? '1px solid #eb5d7b' : '1px solid black', color: paymentmethod === 'CODE' ? '#eb5d7b' : 'black', fontWeight: paymentmethod === 'CODE' ? 'bold' : '' }} onClick={() => { setPaymentmethod('CODE') }}>Code</button> : null}
                                <button className="gbedit_purchase_paymentbtn" style={{ border: paymentmethod === 'PAYNOW' ? '1px solid #eb5d7b' : '1px solid black', color: paymentmethod === 'PAYNOW' ? '#eb5d7b' : 'black', fontWeight: paymentmethod === 'PAYNOW' ? 'bold' : '' }} onClick={() => { setPaymentmethod('PAYNOW') }}>Paynow</button>
                                 */}
                                <button className="gbedit_purchase_paymentbtn" style={{ marginLeft: 0, border: paymentmethod === 'METAMASK' ? '1px solid #eb5d7b' : '1px solid black', color: paymentmethod === 'METAMASK' ? '#eb5d7b' : 'black', fontWeight: paymentmethod === 'METAMASK' ? 'bold' : '' }} onClick={() => { setPaymentmethod('METAMASK') }}>Metamask</button>
                                {/* <button className="gbedit_purchase_paymentbtn" style={{ border: paymentmethod === 'OTHER' ? '1px solid #eb5d7b' : '1px solid black', color: paymentmethod === 'OTHER' ? '#eb5d7b' : 'black', fontWeight: paymentmethod === 'OTHER' ? 'bold' : '' }} onClick={() => { setPaymentmethod('OTHER') }}>Other</button> */}
                            </div>
                            {{
                                'CODE': {
                                    'HARDSOFT': null,
                                    'SOFT': <div className="gbedit_purchase_paymentdom">
                                        <div>Code</div>
                                        <div>
                                            <input className="p_codeinput" onChange={(e) => setCode(e.target.value)} value={code} />
                                            <button className="p_applycode" onClick={() => { props.onApply(code, purchasetype) }}>Apply</button>
                                        </div>
                                    </div>
                                }[purchasetype],
                                'PAYNOW': <div className="gbedit_purchase_paymentdom">Coming Soon!</div>,
                                'METAMASK': <div className="gbedit_purchase_paymentdom">
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', padding: 10, border: '1px solid black', borderRadius: 10, marginBottom: 10 }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', padding: 5 }}>
                                                <div style={{ minWidth: 80, placeContent: 'flex-start', display: 'flex', fontWeight: 'bold' }}>Quantity</div>
                                                <div style={{ padding: '0px 2px' }}>:</div>
                                                <div>1</div>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', padding: '0px 5px 5px 5px' }}>
                                                <div style={{ minWidth: 80, placeContent: 'flex-start', display: 'flex', fontWeight: 'bold' }}>Type</div>
                                                <div style={{ padding: '0px 2px' }}>:</div>
                                                <div>Digital / NFT</div>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', padding: '0px 5px 5px 5px' }}>
                                                <div style={{ minWidth: 80, placeContent: 'flex-start', display: 'flex', fontWeight: 'bold' }}>Description</div>
                                                <div style={{ padding: '0px 2px' }}>:</div>
                                                <div style={{ display: 'flex', flexWrap: 'wrap', letterSpacing: 'white-space' }}>Upon purchase you will be receive an NFT of the design you have made and you will be able to download you art piece in high resolution. NFT will be send to the wallet that were used to make payment. NFT minted might slightly differ from the actual design, some of the generators will generate in animated form etc. 5% of the secondary sales amount can be allotted as royalty to us. Once purchase "save" function will be locked, your guest will not be able to upload new messages and moderator will not be able to delete messages until the transaction is fulfilled.</div>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', padding: '0px 5px 5px 5px' }}>
                                                <div style={{ minWidth: 80, placeContent: 'flex-start', display: 'flex', fontWeight: 'bold' }}>Deliver</div>
                                                <div style={{ padding: '0px 2px' }}>:</div>
                                                <div>NFT will be send to paying wallet and link to download of digital copy in high resolution will be available the next day before 12pm. </div>
                                            </div>

                                            <div style={{ display: 'flex', flexDirection: 'row', padding: '0px 5px 5px 5px' }}>
                                                <div style={{ minWidth: 80, placeContent: 'flex-start', display: 'flex', fontWeight: 'bold' }}>Network</div>
                                                <div style={{ padding: '0px 2px' }}>:</div>
                                                <div>We accept payment from Ethereum mainnet or Polygon mainnet. Polygon mainnet require less gas fee.</div>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', padding: '0px 5px 5px 5px' }}>
                                                <div style={{ minWidth: 80, placeContent: 'flex-start', display: 'flex', fontWeight: 'bold' }}>Price</div>
                                                <div style={{ padding: '0px 2px' }}>:</div>
                                                <div style={{ fontWeight: 'bold', color: '#eb5d7b' }}>{NFTPRICE + ' Ξ'} </div>
                                            </div>

                                        </div>
                                        <div className="">Metamask Network</div>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                            <button className={chain === ETH_NETWORK ? 'gbedit_mmnetwork_selected' : 'gbedit_mmnetwork'} style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: 'none' }} onClick={switchmmnetwork} value={ETH_NETWORK}>Ethereum Network</button>
                                            <button className={chain === POLYGON_NETWORK ? 'gbedit_mmnetwork_selected' : 'gbedit_mmnetwork'} style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: 'none' }} onClick={switchmmnetwork} value={POLYGON_NETWORK}>Polygon Network</button>
                                            <button className={chain === POLYGONTEST_NETWORK ? 'gbedit_mmnetwork_selected' : 'gbedit_mmnetwork'} style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: 'none' }} onClick={switchmmnetwork} value={POLYGONTEST_NETWORK}>Polygon Test Network</button>
                                        </div>
                                        <div className="">Your Wallet</div>
                                        <div className="gbedit_label">{mwallet}</div>
                                        <div className="">Name</div>
                                        <input className="gbedit_purchase_input" onChange={(e) => setName(e.target.value)} value={name} />
                                        <div className="">Contact Number ( Singapore Only )</div>
                                        <input className="gbedit_purchase_input" onChange={(e) => setContact(e.target.value)} value={contact} />
                                        <div className="">Email</div>
                                        <input className="gbedit_purchase_input" onChange={(e) => setEmail(e.target.value)} value={email} />
                                        <button className="gbedit_purchase_paymentbtn" style={{ marginLeft: 0, marginTop: 10 }} onClick={onMetamask}>Pay</button>
                                    </div>
                                    {/* <div>
                                        <input className="p_codeinput" onChange={(e) => setCode(e.target.value)} value={code} />
                                        <button className="p_applycode" onClick={() => { props.onApply(code, purchasetype) }}>Pay Now</button>
                                    </div> */}
                                </div>
                            }[paymentmethod]}
                        </div>
                    </div>
                    : <div className='p_contentroot'>
                        <div style={{ display: 'flex', padding: '10px 0px 10px 0px' }}>You Have Bought Digital/NFT copy.</div>
                        <div style={{ display: 'flex', flexDirection: 'row', placeContent: 'center' }}>
                            <div style={{ width: '25%', display: 'flex', placeContent: 'flex-end', fontWeight: 'bold' }}>Date : </div>
                            <div style={{ width: '65%', padding: '0px 10px', margin: '0px 5px', backgroundColor: 'black', color: 'white' }}>{props.purchase[props.purchaseitem + '_' + purchasetype]['date']}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', placeContent: 'center', margin: '2px 0px' }}>
                            <div style={{ width: '25%', display: 'flex', placeContent: 'flex-end', fontWeight: 'bold' }}>Payment Method : </div>
                            <div style={{ width: '65%', padding: '0px 10px', margin: '0px 5px', backgroundColor: 'black', color: 'white' }}>{props.purchase[props.purchaseitem + '_' + purchasetype]['paymentmethod']}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', placeContent: 'center', margin: '2px 0px' }}>
                            <div style={{ width: '25%', display: 'flex', placeContent: 'flex-end', fontWeight: 'bold' }}>ID : </div>
                            <div style={{ width: '65%', padding: '0px 10px', margin: '0px 5px', backgroundColor: 'black', color: 'white' }}>{props.purchase[props.purchaseitem + '_' + purchasetype]['id']}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', placeContent: 'center' }}>
                            <div style={{ width: '25%', display: 'flex', placeContent: 'flex-end', fontWeight: 'bold' }}>IPFS Link : </div>
                            <div style={{ width: '65%', padding: '0px 10px', margin: '0px 5px', backgroundColor: 'black', color: 'white' }}>{props.purchase[props.purchaseitem + '_' + purchasetype]['ipfs']}</div>
                        </div>
                    </div>}

            </div>
            {{
                'METAMASK': null,
                'CODE': <div className='p_row' style={{ position: 'absolute', bottom: 0, padding: '10px 0px' }}>
                    {/* {code.length > 0
                        ? <button className='p_confirm' onClick={() => {
                            var data = {}
                            if (purchasetype === 'HARDSOFT') {
                                data = {
                                    'purchasetype': purchasetype,
                                    'paymentmethod': paymentmethod,
                                    'name': name,
                                    'contact': contact,
                                    'code': code,
                                    'address': {
                                        'postalcode': postalcode,
                                        'blkstname': blkstname,
                                        'buildinghsenum': buildinghsenum,
                                        'unitno': unitno,
                                        'country': country
                                    }
                                }
                            }
                            else if (purchasetype === 'SOFT') {
                                data = {
                                    'purchasetype': purchasetype,
                                    'paymentmethod': paymentmethod,
                                    'code': code
                                }
                            }
                            props.onConfirm(data)
                        }}>Confirm</button>
                        : null} */}
                </div>,
                'PAYNOW': null
            }[paymentmethod]}
        </div>
    </div >
}

export default Purchase