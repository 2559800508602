import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import "./Chart.css"
import { Checkdevice } from '../../../../components'
import { MAIN_PINK_COLOR } from '../../../../config/constants'

const Chart = ({ xdim, ydim, margin, xdata, ydata, ids, yaxistitle, title, alldata, mode, hover }) => {


    const canvas = useRef(null)
    const device = Checkdevice()
    const device_fs_map = {
        'WEB': { title: '18px', xy: '16px', tt: '14px' },
        'TABLET': { title: '16px', xy: '14px', tt: '13px' },
        'MOBILE': { title: '14px', xy: '10px', tt: '12px' }
    }

    useEffect(() => {
        d3.selectAll('g').remove() // remove the graph
        d3.selectAll(".d3rect").remove() // remove y-axis
        d3.selectAll(".x.axis").remove() // remove x-axis
        d3.selectAll(".y.axis").remove() // remove y-axis
        d3.selectAll(".tooltip").remove()
        d3.selectAll("text").remove()

        var datamap = {}

        for (let i = 0; i < alldata.length; i++) {
            const { items, itemsequence } = alldata[i]
            var new_arr = []
            for (let j = 0; j < itemsequence.length; j++) {
                new_arr.push(items[itemsequence[j]])
            }
            datamap[xdata[i]] = new_arr
        }

        const svg = d3.select(canvas.current)

        addAxes(svg)
        addBars(svg, datamap)
        addText(svg)

    }, [xdim, ydim, margin, xdata, ydata])

    const xscale = d3.scaleBand()
        .domain(xdata)
        .range([margin.left, xdim + margin.left])
        .padding(0.1)

    const yscale = d3.scaleLinear()
        .domain([0, d3.max(ydata)])
        .range([ydim, 0])

    const addAxes = (svg) => {

        // x axis
        const xAxis = d3.axisBottom(xscale)

        svg.append("g")
            .call(xAxis)
            .attr("transform", `translate(0, ${ydim + margin.top})`)
            .selectAll("text")
            .attr('text-anchor', 'start')
            .attr("transform", "rotate(45)")
            .style("font-size", device_fs_map[device].xy)
            .attr("color", (d, i) => device === 'WEB' && hover === ids[i] ? '#ff5252' : "#707070")
            .attr("font-weight", (d, i) => device === 'WEB' && hover === ids[i] ? 'bold' : "normal")

        // y axis
        const yAxis = d3.axisLeft(yscale)

        svg.append("g")
            .call(yAxis)
            .attr("transform", `translate(${margin.left},${margin.top})`)
            .style("font-size", device_fs_map[device].xy)
    }

    const addBars = (svg, datamap) => {

        const linearScale = d3.scaleLinear()
            .domain([0, d3.max(ydata)])
            .range([0, ydim])

        const scaledYData = ydata.map(yval => {
            return linearScale(yval)
        })

        var div = d3.select('.canvas')
            .append("div")
            .attr("class", "tooltip")
            .style("opacity", 0)
            .attr("fill", MAIN_PINK_COLOR)

        svg.selectAll('d3rect')
            .data(scaledYData)
            .enter()
            .append('rect')
            .attr('class', 'd3rect')
            .attr('width', xscale.bandwidth())
            .attr('height', (d) => {
                return d
            })
            .attr('x', (d, i) => {
                return xscale(xdata[i])
            })
            .attr('y', (d) => { return margin.top + ydim - d })
            .attr("fill", (d, i) => device === 'WEB' && hover === ids[i] ? '#ff5252' : MAIN_PINK_COLOR)
            .on("mousemove", function (d, i, nodes) {
                var dom = []
                if (mode === '0' || mode === 0) {
                    dom = dom + ` <div style="display:flex; align-items:center; margin:7px 0px; width:100%; font-weight:bold; color:#6dc761;justify-content:start;">Price ( Actual Cost )</div>`
                    for (let j = 0; j < datamap[xdata[i]].length; j++) dom = dom + ` <div style="margin:3px 3px 0px 3px;">${datamap[xdata[i]][j].itemname} - ${'$ ' + datamap[xdata[i]][j].actual_cost}</div>`
                }
                else if (mode === '1' || mode === 1) {
                    dom = dom + ` <div style="display:flex; align-items:center; margin:7px 0px; width:100%; font-weight:bold; color:#6dc761;justify-content:start;">Price ( Actual Cost )</div>`
                    for (let j = 0; j < datamap[xdata[i]].length; j++) dom = dom + ` <div style="margin:3px 3px 0px 3px;">${datamap[xdata[i]][j].itemname} - ${'$ ' + datamap[xdata[i]][j].actual_cost}</div>`
                    dom = dom + ` <div style="display:flex; align-items:center;  margin:7px 0px; width:100%; font-weight:bold; color:#6dc761;justify-content:start;">Amount Paid</div>`
                    for (let j = 0; j < datamap[xdata[i]].length; j++) dom = dom + ` <div style="margin:3px 3px 0px 3px;">${datamap[xdata[i]][j].itemname} - ${'$ ' + datamap[xdata[i]][j].amount_paid}</div>`
                    dom = dom + ` <div style="display:flex; align-items:center; margin:7px 0px; width:100%; font-weight:bold; color:#6dc761;justify-content:start;">Amount Due</div>`
                    for (let j = 0; j < datamap[xdata[i]].length; j++) dom = dom + ` <div style="margin:3px 3px 0px 3px;">${datamap[xdata[i]][j].itemname} - ${'$ ' + datamap[xdata[i]][j].amount_due}</div>`
                }

                div.transition()
                    .duration(100)
                    .style("opacity", .9);

                div.html(
                    `<div style="width:200px;justify-content:start;display:flex;flex-direction:column;text-align:center;color:white;border-radius:5px;font-size:${device_fs_map[device].tt}; background-color:black;">
                        <div style="margin:7px 3px; color:white; display:flex; width:100%; font-weight:bold; color:#6dc761; ">
                            ${xdata[i]}
                        </div>
                        <div style="margin:3px; color:white; justify-content:start; display:flex">
                            ${title} : $ ${ydata[i]}
                        </div>
                        <div style="width:100%; border-top:thin solid white;"/>
                        <div style="margin:3px; color:white; justify-content:start; display:flex; flex-direction:column; align-items:flex-start; text-align:left">
                            ${dom}
                        </div>
                    </div>`
                )
                    .style("left", d3.event.layerX + 'px')
                    .style("top", d3.event.layerY + 'px')

            })
            .on("mouseout", function (d, i, nodes) {
                div.transition()
                    .duration(100)
                    .style("opacity", 0);

            })
        // .on("mouseout", function () {
        //     div.transition()
        //         .duration(100)
        //         .style("opacity", 0);
        // })

    }

    const addText = (svg) => {

        // what you do ?
        // why it has value?
        // how to you accomplish it?
        // why do it at current time
        // your current progress?

        svg.append('text')
            .text(title)
            .attr('text-anchor', 'middle')
            .attr('x', (margin.left + margin.right + xdim) / 2)
            .attr('y', (margin.bottom) / 2)
            .attr('text-color', '#707070')
            .style("font-size", device_fs_map[device].title)

        svg.append('text')
            .text(yaxistitle)
            .attr('text-anchor', 'middle')
            .attr('x', -(ydim) / 2)
            .attr('y', margin.left / 2)
            .attr('transform', `rotate(-90, ${margin.left / 2}, ${margin.top / 2})`)
            .attr('text-color', '#707070')
            .style("font-size", device_fs_map[device].title)

    }


    // var displayname = ''
    // for (let i = 0; i < alldata.length; i++) {
    //     const {sectionid, sectionname} = alldata[i]
    //     if(sectionid === hover){
    //         displayname = sectionname
    //     }
    // }

    return <div className="canvas">

        <svg
            viewBox={`0 0 ${xdim + margin.left + margin.right} ${ydim + margin.top + margin.bottom}`}
            preserveAspectRatio="xMinYMin"
            width="100%"
            height="100%"
            ref={canvas}
            style={{ position: 'relative' }}
        />

        {/* <div style={{ position: 'absolute', fontSize: 16 }}>
            {displayname}
        </div> */}

    </div>

}

export default Chart