export const BUTTONSTYLE = {
    padding: '10px',
    borderRadius: '10px',
    fontWeight: 'bold',
    border: 'solid 1px var(--maindark)',
    backgroundColor: 'white',
    outline: 'none'
}

export const CLEARSTYLE = {
    outline: 'none',
    backgroundColor: 'white',
    color: 'var(--maindark)',
    textTransform: 'uppercase',
    fontSize: 14,
    userSelect: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    border:'1px solid transparent',
    fontFamily:'Lato'
    
}