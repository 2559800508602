
import React, { useEffect, useState } from 'react'
import { iconwrapper } from '../../../../../../config/commonfunctions'
import { EMPTYSPACE, } from '../../../../../../config/constants'
import { TRASH_ICON } from '../../../../../../config/svgicons'
import { partialtitle_dom } from '../../../Invitationmaker/components/Commonelements/Commonelements'

function Edittablecontent(props) {
    const { update, rmv } = props

    const [edittabletext, setEdittabletext] = useState('')
    const [edittablemax, setEdittablemax] = useState(-1)
    const [edittableindex, setEdittableindex] = useState(-1)

    useEffect(() => {
        const { data } = props
        setEdittableindex(data[2])
        setEdittablemax(data[1])
        setEdittabletext(data[0])
    }, [props])

    return <div className='Rsvpexplorer_portal'>
        <div className='Rsvpexplorer_portaltitle'>Edit Table</div>
        <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 'auto', height: 'auto', width: '100%', overflowY: 'auto', padding: '0px 10px' }}>
            <div key={'edittable'} className="scedit_troot" style={{padding:0, border:'none'}} >
                <div style={{ display: 'flex', flexDirection: 'row', }}>
                    <aside style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '80%', minHeight: 55 }}>
                        {partialtitle_dom('Table Name', {})}
                        <input
                            style={{ height: 40, padding: 5, borderRadius: 5, width: '100%', whiteSpace: 'nowrap', outline: 'none', fontSize: 14, border: 'thin solid #707070', alignSelf: 'flex-start', }}
                            value={edittabletext}
                            onChange={(e) => { setEdittabletext(e.target.value) }}
                        />
                    </aside>
                    {EMPTYSPACE}
                    <aside style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '20%', minHeight: 55 }}>
                        {partialtitle_dom('Max', {})}
                        <input
                            style={{ height: 40, padding: 5, borderRadius: 5, width: '100%', whiteSpace: 'nowrap', outline: 'none', fontSize: 14, border: 'thin solid #707070', alignSelf: 'flex-start', }}
                            type="number"
                            value={edittablemax}
                            onChange={(e) => { setEdittablemax(e.target.value) }}
                        />
                    </aside>
                </div>
            </div>
        </div>
        <div className='gl_portalbtnrow'>
            <button onClick={() => rmv(edittableindex)} style={{ borderRadius: 10, backgroundColor: '#ffffff', border: '1px solid var(--maindark)' }}>{iconwrapper(TRASH_ICON, { width: 24, height: 24 })}</button>
            <button className='gl_portalupdatebtn' onClick={() => update(edittableindex, edittabletext, edittablemax)}>Update</button>
        </div>


    </div>

}

export default Edittablecontent