// import React, { useEffect, useState } from 'react'
// import { Checkdevice, ScreenHeight, ScreenWidth } from '../../../../components'
// import { Gallery, Item } from 'react-photoswipe-gallery'
// import Circularclosebtn from '../../../../basic_components/Button/Circularclosebtn/Circularclosebtn'
// import 'photoswipe/dist/photoswipe.css'
// import 'photoswipe/dist/default-skin/default-skin.css'
// import './Igallery.css'

// function Igallery({ images, closeGallery, templatecolor, fc }) {
//     const device = Checkdevice()
//     const [rows, setRows] = useState([])
//     const [arr, setArr] = useState([])
//     const [minmaxwt, setMinmaxwt] = useState(-1)
//     const swt = ScreenWidth()
//     const sht = ScreenHeight()

//     const init = () => {
//         if (images && images.length > 0) {
//             // const { wt, dim } = DEVICE_NUM_MAP[device]
//             var wt
//             var dim
//             var minmaxwt
//             if (swt > 1024) {
//                 console.log(1)
//                 wt = 850
//                 dim = 3
//                 minmaxwt = wt / dim
//             }
//             else if (swt > 767) {
//                 console.log(2)
//                 wt = 700
//                 dim = 3
//                 minmaxwt = wt / dim
//             }
//             else if (swt > 450) {
//                 console.log(3)
//                 wt = swt
//                 dim = 3
//                 minmaxwt = (swt) / dim
//             }
//             else if (swt > 390) {
//                 console.log(4)
//                 wt = swt
//                 dim = 3
//                 minmaxwt = (swt) / dim
//             }
//             else if (swt > 350) {
//                 console.log(5)
//                 wt = swt
//                 dim = 2
//                 minmaxwt = (swt) / dim
//             }

//             else {
//                 console.log(6)
//                 wt = swt
//                 dim = 2
//                 minmaxwt = (swt) / dim
//             }

//             setMinmaxwt(minmaxwt)
//             const actual_wt = minmaxwt
//             var arr = []
//             for (let i = 0; i < images.length; i = i + 2) {
//                 const img = images[i]
//                 const thumbnail = images[i + 1]
//                 arr.push(
//                     <Item
//                         key={'img' + i + '_arr' + thumbnail}
//                         thumbnail={thumbnail}
//                         original={img}
//                         width={window.innerWidth}
//                         height={window.innerHeight}
//                     >
//                         {({ ref, open }) => (
//                             <div
//                                 ref={ref}
//                                 onClick={open}
//                                 style={{
//                                     backgroundImage: `url(${thumbnail})`,
//                                     width: actual_wt,
//                                     height: actual_wt,
//                                     backgroundRepeat: 'no-repeat',
//                                     backgroundSize: 'cover',
//                                     backgroundPosition: 'center',
//                                     // borderLeft: dim === 2 ? (i % 4 === 0 ? `2px solid ${templatecolor}` : '0px solid transparent') : null,
//                                     // borderRight: `2px solid ${templatecolor}`,
//                                     // borderTop: `2px solid ${templatecolor}`
//                                     border:`1px solid ${templatecolor}`,
//                                     // borderBottom:'none',
//                                 }}
//                             />
//                         )}
//                     </Item>
//                 )
//             }
//             setArr(arr)
//         }
//     }

//     useEffect(() => {
//         init()
//     }, [])

//     useEffect(() => {
//         init()
//     }, [sht])

//     return <div className={"Igallery_root" + device} style={{ backgroundColor: templatecolor }}>
//         <Circularclosebtn
//             fc={fc}
//             bgc={templatecolor}
//             clickfunc={closeGallery}
//         />
//         <Gallery
//             shareButton={false}
//             fullscreenButton={false}
//             zoomButton={false}
//         >
//             {/* {device === 'MOBILE' || device === 'TABLET'
//                 ? <div style={{ display: 'grid', justifyContent: 'center', gridTemplateColumns: `repeat(auto-fit, minmax(${minmaxwt}px, max-content))` }}>
//                     {arr}
//                 </div>
//                 : rows.map((row, i) => {
//                     return <div key={'row' + i} style={{ display: 'flex', width: '100%', flexDirection: 'row' }}>{row}</div>
//                 })} */}
//             <div style={{ display: 'grid', justifyContent: 'center', gridTemplateColumns: `repeat(auto-fit, minmax(${minmaxwt}px, max-content))` }}>
//                 {arr}
//             </div>
//         </Gallery>
//     </div >

// }

// export default Igallery



import React, { useEffect, useState } from 'react'
import { Checkdevice, ScreenHeight, ScreenWidth } from '../../../../components'
import { Gallery, Item } from 'react-photoswipe-gallery'
import Circularclosebtn from '../../../../basic_components/Button/Circularclosebtn/Circularclosebtn'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import './Igallery.css'

function Igallery({ images, closeGallery, templatecolor, fc, secff }) {
    const device = Checkdevice()
    const [arr, setArr] = useState([])
    const [minmaxwt, setMinmaxwt] = useState(-1)
    const swt = ScreenWidth()
    const sht = ScreenHeight()

    const init = () => {

        if (images && images.length > 0) {
            // const { wt, dim } = DEVICE_NUM_MAP[device]
            var wt
            var dim
            var minmaxwt
            if (swt > 1024) {
                console.log(1)
                wt = 850
                dim = 3
                minmaxwt = wt / dim
            }
            else if (swt > 767) {
                console.log(2)
                wt = 700
                dim = 3
                minmaxwt = wt / dim
            }
            else if (swt > 450) {
                console.log(3)
                wt = swt
                dim = 3
                minmaxwt = (swt) / dim
            }
            else if (swt > 390) {
                console.log(4)
                wt = swt
                dim = 3
                minmaxwt = (swt) / dim
            }
            else if (swt > 350) {
                console.log(5)
                wt = swt
                dim = 2
                minmaxwt = (swt) / dim
            }

            else {
                console.log(6)
                wt = swt
                dim = 2
                minmaxwt = (swt) / dim
            }

            setMinmaxwt(minmaxwt)
            const actual_wt = minmaxwt
            var arr = []
            for (let i = 0; i < images.length; i = i + 2) {
                const img = images[i]
                const thumbnail = images[i + 1]
                arr.push(
                    <Item
                        key={'img' + i + '_arr' + thumbnail}
                        thumbnail={thumbnail}
                        original={img}
                        width={window.innerWidth}
                        height={window.innerHeight}
                    >
                        {({ ref, open }) => (
                            <div
                                ref={ref}
                                onClick={open}
                                style={{
                                    backgroundImage: `url(${thumbnail})`,
                                    width: actual_wt,
                                    height: actual_wt,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    // borderLeft: dim === 2 ? (i % 4 === 0 ? `2px solid ${templatecolor}` : '0px solid transparent') : null,
                                    // borderRight: `2px solid ${templatecolor}`,
                                    // borderTop: `2px solid ${templatecolor}`
                                    border: `1px solid ${templatecolor}`,
                                    // borderBottom:'none',
                                }}
                            />
                        )}
                    </Item>
                )
            }
            setArr(arr)
        }
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        init()
    }, [sht])

    return <div className={"Igallery_root" + device} style={{ backgroundColor: templatecolor, position: 'fixed', width: '100%', height: '100%', alignItems: 'center', zIndex: 1000000, top: 0, overflowX: 'hidden', display: 'flex', flexDirection: 'column', touchAction: 'manipulation' }}>
        <Circularclosebtn
            fc={fc}
            bgc={templatecolor}
            clickfunc={closeGallery}
        />
        {/* <div style={{ display: 'flex', flexDirection: 'column', width: '100%', placeContent: 'flex-start', padding: '50px 0px', overflowY: 'auto', alignItems: device === 'WEB' ? 'center' : '' }}> */}
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', placeContent: 'flex-start', padding: '50px 0px', overflowY: 'auto' }}>
            <div className='RSVP_titleroot' style={{ fontFamily: secff, color: fc }}>Gallery</div>
            <Gallery
                shareButton={false}
                fullscreenButton={false}
                zoomButton={false}
            >
                <div style={{ display: 'grid', justifyContent: 'center', placeSelf:'center', gridTemplateColumns: `repeat(auto-fit, minmax(${minmaxwt}px, max-content))`, width: { 'WEB': 935, 'TABLET': '100%', 'MOBILE': '100%' }[device] }}>
                    {arr}
                </div>
            </Gallery>
        </div>
    </div >

}

export default Igallery