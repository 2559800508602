import React, { useEffect, useState } from 'react'
import { b_fs } from '../../config/constants';

const CONSTANTS = {
    DELETING_SPEED: 15,
    TYPING_SPEED: 50,
}

function TypeWriter({ messages, heading }) {
    const [state, setState] = useState({
        text: "",
        message: "",
        isDeleting: false,
        loopNum: 0,
        typingSpeed: CONSTANTS.TYPING_SPEED,
    });

    useEffect(() => {
        let timer = "";
        const handleType = () => {
            setState(currstate => ({
                ...currstate, // currstate means currentState
                text: getCurrentText(currstate),
                typingSpeed: getTypingSpeed(currstate)
            }));
            timer = setTimeout(handleType, state.typingSpeed);
        };
        handleType();
        return () => clearTimeout(timer);
    }, [state.isDeleting]);

    useEffect(() => {
        // if (!state.isDeleting && state.text === state.message) {
        //     setTimeout(() => {
        //         setState(currstate => ({
        //             ...currstate,
        //             isDeleting: true
        //         }))
        //     }, 500);
        // } else if (state.isDeleting && state.text === "") {
        //     setState(currstate => ({
        //         ...currstate, // currstate means currentState
        //         isDeleting: false,
        //         loopNum: currstate.loopNum + 1,
        //         message: getMessage(currstate, messages)
        //     }));
        // }
        if (state.text === "") {
            setState(currstate => ({
                ...currstate, // currstate means currentState
                isDeleting: false,
                loopNum: currstate.loopNum + 1,
                message: getMessage(currstate, messages)
            }));
        }
    }, [state.text, state.message, state.isDeleting, messages]);

    function getCurrentText(currentState) {
        return currentState.isDeleting
            ? currentState.message.substring(0, currentState.text.length - 1)
            : currentState.message.substring(0, currentState.text.length + 1);
    }

    function getMessage(currentState, data) {
        return data[Number(currentState.loopNum) % Number(data.length)];
    }

    function getTypingSpeed(currentState) {
        return currentState.isDeleting
            ? CONSTANTS.TYPING_SPEED
            : CONSTANTS.DELETING_SPEED;
    }

    return (
        <div style={{ fontsize: b_fs }}>
            {heading}&nbsp;
            <div>{state.text}</div>
            <span id="cursor" />
        </div>
    );
}


export default TypeWriter