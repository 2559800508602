import React, { useEffect, useState } from 'react'
import Gbmessage from '../../../../components/Gbmessage/Gbmessage';

function Rightdefault(props) {

    const [allmsgs, setAllmsgs] = useState([]) // all unique msgs
    const [firsttime, setFirsttime] = useState(true) // when first loaded

    useEffect(() => {
        const { msgs } = props
        if (msgs && msgs.length > 0 && firsttime) {
            setAllmsgs(msgs)
            setFirsttime(false)
        }
    }, [props.msgs])

    useEffect(() => {
        const { newmsgs, emptynew } = props
        if (newmsgs.length > 0) {
            // ensure all msgs are unique
            var new_allmsgs = allmsgs.slice()
            for (let i = 0; i < newmsgs.length; i++) {
                var samesame = false
                for (let j = 0; j < allmsgs.length; j++) {
                    if (newmsgs[i]['id'] === allmsgs[j]['id']) {
                        samesame = true
                        break
                    }
                }
                if (!samesame) { new_allmsgs.push(newmsgs[i]) }
            }
            setAllmsgs(new_allmsgs)
            emptynew()
        }
    }, [props.newmsgs])

    useEffect(()=>{

    },[props.modrmvmsgs])

    const guestmsgselect = (e) => {
        var index
        for (let i = 0; i < props.msgs.length; i++) {
            if (e.target.id === props.msgs[i].id) {
                index = i;
                break;
            }
        }
        props.updatestart(index)
    }

    return <div className="gbcast_right">
        <div className="gbcastexpandedmsg_root">
            {allmsgs.map((msg, i) => {
                return <Gbmessage key={'msg' + i} index={i} latestmsgbool={i === allmsgs.length - 1} msg={msg} selected={props.startindex === i} onselect={guestmsgselect} msgtype={props.msgtype} />
            })}
            <div style={{height:10, width:'100%'}}/>
        </div>
    </div>


}

export default Rightdefault;