import { RE_NUMBER, EMPTY } from "../../../../config/constants";

// return true implies got error,
// return false implies no error
export const Validateform = (items) => {
    const { form_key, form_parts } = items
    switch (form_key) {
        case 'oldpw': {
            if (form_parts.oldpw.length < 100 && form_parts.oldpw.length > 5) {
                return false
            }
            else {
                return true
            }
        }
        case 'newpw': {
            if (form_parts.newpw1 === form_parts.newpw2 && form_parts.newpw1 !== '' && form_parts.newpw1.length < 100 && form_parts.newpw1.length > 5) {
                return false
            }
            else {
                return true
            }

        }
        case 'name': { // name must be all chars
            if (form_parts.name === EMPTY) return false
            if (form_parts.name.length > 50 || form_parts.name.length < 2) { return true }
            else return false;
        }
        case 'spousename': { // name must be all chars
            if (form_parts.spousename.length > 50) { return true }
            else return false;
        }
        case 'contact': {  // must be number
            if(form_parts.contact.length===0) return false
            if (RE_NUMBER.test(String(form_parts.contact)) === false || form_parts.contact.length < 8 || form_parts.contact.length > 20) { return true }
            else return false;
        }
        case 'location': {  // must be number
            if (form_parts.location === EMPTY) { return true }
            else return false;
        }
        default: {
            return null
        }

    }


}
