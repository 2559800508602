import React, { useEffect, useRef, useState } from 'react'
import './Leftexpanded.css'

// mtype 0 : image, 1 : video

function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        let id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
}


function Leftexpanded(props) {

    const { musicindex, updateMusicindex, duration, medias, selectindex, updateSelectindex, casttype, msgtype } = props

    const [currmedia, setCurrmedia] = useState({ mediatype: null })
    const [musicobjs, setMusicobjs] = useState([])
    const [once, setOnce] = useState(true)
    const [dur, setDur] = useState(0)
    const [playnow, setPlaynow] = useState(false)

    const audioref = useRef(null)
    const srcref = useRef(null)
    const vidref = useRef(null)

    function pausebgmusic() {
        if (musicobjs.length > 0 && audioref && audioref.current) {
            audioref.current.pause()
            // audioref.current.volume = 0
        }
    }

    function playbgmusic() {
        if (musicobjs.length > 0 && audioref && audioref.current) {
            audioref.current.play()
        }
    }

    function resetsrcref() {
        try {
            vidref.current.pause();
            vidref.current.removeAttribute('src'); // empty source
            vidref.current.srcObject = null
            vidref.current.load();
        }
        catch (e) { }
    }

    useEffect(() => {
        audioref.current = new Audio()
        srcref.current = new Audio()
        setPlaynow(true)
        return () => {
            // console.log('unmount bro')
            setPlaynow(false)
            try {
                vidref.current.pause();
                vidref.current.removeAttribute('src'); // empty source
                vidref.current.srcObject = null
                vidref.current.load();

                audioref.current.pause();
                audioref.current.removeAttribute('src'); // empty source
                audioref.current.srcObject = null
                audioref.current.load();

                srcref.current.pause()
                srcref.current.removeAttribute('src'); // empty source
                srcref.current.srcObject = null
                srcref.current.load();
                updateMusicindex(0)
            }
            catch (e) { }
        };
    }, []);

    useEffect(() => {
        if (props.musicobjs.length > 0 && once) {
            setMusicobjs(props.musicobjs)
            setOnce(false)
        }
    }, [props.musicobjs])

    useEffect(() => {
        if (musicobjs.length > 0 && musicindex > -1) {

            audioref.current.src = musicobjs[musicindex]
            audioref.current.load()
            if (audioref.current) {
                const update = () => {
                    updateMusicindex();
                    // audioref.current.removeAttribute('src'); // empty source
                    // audioref.current.srcObject = null
                }
                audioref.current.loop = false
                audioref.current.addEventListener('ended', update);
                return function cleanupListener() {
                    audioref.current.removeEventListener('ended', update)
                }
            }
        }
    }, [musicobjs, musicindex])

    useInterval(() => {
        if (medias.length > 0) {
            if (selectindex !== -1 && medias[selectindex].mtype === 1) resetsrcref();
            var index = selectindex + 1 < medias.length ? selectindex + 1 : 0

            const nextmedia = medias[index]
            const { content, mtype, key } = nextmedia
            if (mtype === 0) { // image

                if (duration !== dur) setDur(duration)
                setCurrmedia({ mediatype: 'img', content: content })
            }
            else { // video
                srcref.current.src = content.blobData;
                srcref.current.onloadedmetadata = function () {
                    setDur(Math.ceil((srcref.current.duration * 1000) + 1000)) // this would give dur of the video/audioref file
                    srcref.current.pause();
                    srcref.current.removeAttribute('src');
                    srcref.current.srcObject = null
                    srcref.current.load();
                    setCurrmedia({ mediatype: 'vid', key: key, content: content, ft: content.file.type })
                };

                // srcref.onended = function(){
                //     this.currentSrc = null;
                //     this.removeAttribute('src');
                //     this.srcObject = null;
                //     this.remove();
                // };
            }
            updateSelectindex(index)
        }
    }, [dur]);

    useEffect(() => {
        if (currmedia.mediatype === 'img') {
            playbgmusic()
        }
        else if (currmedia.mediatype === 'vid') {
            pausebgmusic()
        }
    }, [currmedia])

    const MSGTYPE_MAP = {
        0: { minWidth: '101vw', maxWidth: '101vw' }, // img
        1: { minWidth: '0vw', maxWidth: '0vw' }, // msgbox
        2: { width: '100%', minWidth: '100%' }, // img + msgbox
        // 3: { width: '70%', minWidth: '70%' }, // img + small msgbox
        // 4: { minWidth: '101vw', maxWidth: '101vw' }, // img + bullet
    }

    if (currmedia.mediatype === 'img') {
        return <div key={'keyimg' + casttype + '_' + msgtype} className={'gbcastexpanded_left'} style={{ minHeight: '100vh', ...MSGTYPE_MAP[casttype] }}>
            <div className={'gbcastimg_expandedroot'} style={{ backgroundImage: `url(${currmedia.content})`, transition: 'background-image 0.2s linear' }} />
        </div>
    }
    else if (currmedia.mediatype === 'vid' && vidref) {
        return <div key={currmedia.key + casttype + '_' + msgtype} className={'gbcastexpanded_left'} style={{ minWidth: '101vw', minHeight: '100vh', zIndex: 4, ...MSGTYPE_MAP[casttype] }}>
            <div className={'gbcastimg_expandedroot'} >
                <video ref={vidref} controls style={{ width: '100%', height: '100%' }} autoPlay={playnow} src={currmedia.content.blobData} type={currmedia.content.file.type} >
                </video>
            </div>
        </div>
    }
    else return null

    // const MSGTYPE_MAP = { 
    //     0: IMG ONLY
    //     1: <div > CHATBOX ONLY </div>,
    //     2: IMG + LONG CHATBOX
    //     3: <div >IMG + MINI CHATBOX</div>,
    //     4: <div >IMG + BULLET MSG </div>
    // }

    // return MSGTYPE_MAP[casttype]


}

export default Leftexpanded;