import React from 'react'
// import React, { useState, useEffect } from 'react'
// import { BUTTONSTYLE } from '../../config/cssconstants'
// import { EMPTY } from '../../config/constants'


// function Unichoices() {// eslint-disable-line

//     const schs = ['NUS', 'NTU', 'SMU']
//     const data_20192020 = { // eslint-disable-line
//         // salary does not include hons
//         'NUS': {
//             'Law': {
//                 faculty: 'Faculty of Law',
//                 jc_tenpercentile: 'AAA/A',
//                 jc_tenpercentile_num: '85',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '85',
//                 poly_tenpercentile: '3.86',
//                 poly_nintypercentile: '3.93',
//                 places: 223,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$5,160',
//                     median_str: '$5,400',
//                     mean_val: 5160,
//                     median_val: 5400
//                 }
//             },
//             'Medicine': {
//                 faculty: 'School of Medicine',
//                 jc_tenpercentile: 'AAA/A',
//                 jc_tenpercentile_num: '85',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '85',
//                 poly_tenpercentile: '3.94',
//                 poly_nintypercentile: '4.00',
//                 places: 280,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$5,218',
//                     median_str: '$5,237',
//                     mean_val: 5218,
//                     median_val: 5237
//                 }
//             },
//             'Nursing': {
//                 faculty: 'School of Medicine',
//                 jc_tenpercentile: 'CCD/B',
//                 jc_tenpercentile_num: '66.25',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '85',
//                 poly_tenpercentile: '3.33',
//                 poly_nintypercentile: '3.86',
//                 places: 311,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$3,314',
//                     median_str: '$3,358',
//                     mean_val: 3314,
//                     median_val: 3358
//                 }
//             },
//             'Dentistry': {
//                 faculty: 'Faculty of Dentistry',
//                 jc_tenpercentile: 'AAA/A',
//                 jc_tenpercentile_num: '66.25',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '85',
//                 poly_tenpercentile: '#',
//                 poly_nintypercentile: '#',
//                 places: 60,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$4,068',
//                     median_str: '$4,200',
//                     mean_val: 4068,
//                     median_val: 4200
//                 }
//             },
//             'Architecture': {
//                 faculty: 'School of Design & Environment',
//                 jc_tenpercentile: 'ABB/C',
//                 jc_tenpercentile_num: '77.5',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '3.59',
//                 poly_nintypercentile: '3.92',
//                 places: 160,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$4,199',
//                     median_str: '$4,100',
//                     mean_val: 4199,
//                     median_val: 4100
//                 }
//             },
//             'Industrial Design': {
//                 faculty: 'School of Design & Environment',
//                 jc_tenpercentile: 'ABB/C',
//                 jc_tenpercentile_num: '77.5',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '3.88',
//                 poly_nintypercentile: '3.97',
//                 places: 48,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$3,245',
//                     median_str: '$3,300',
//                     mean_val: 3245,
//                     median_val: 3300
//                 }
//             },
//             'Project & Facilities Management': {
//                 faculty: 'School of Design & Environment',
//                 jc_tenpercentile: 'BCC/B',
//                 jc_tenpercentile_num: '71.25',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: 'BBB/B',
//                 poly_tenpercentile: '3.59',
//                 poly_nintypercentile: '3.84',
//                 places: 167,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$3,243',
//                     median_str: '$3,155',
//                     mean_val: 3243,
//                     median_val: 3155
//                 }
//             },
//             'Real Estate': {
//                 faculty: 'School of Design & Environment',
//                 jc_tenpercentile: 'BCC/B',
//                 jc_tenpercentile_num: '71.25',
//                 jc_nintypercentile: 'BBB/B',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '3.58',
//                 poly_nintypercentile: '3.83',
//                 places: 165,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$3,494',
//                     median_str: '$3,400',
//                     mean_val: 3494,
//                     median_val: 3400
//                 }
//             },
//             'Biomedical Engineering': {
//                 faculty: 'Faculty of Engineering',
//                 jc_tenpercentile: 'BBC/B',
//                 jc_tenpercentile_num: '73.75',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '3.71',
//                 poly_nintypercentile: '3.92',
//                 places: 161,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$3,535',
//                     median_str: '$3,500',
//                     mean_val: 3545,
//                     median_val: 3500
//                 }
//             },
//             'Chemical Engineering': {
//                 faculty: 'Faculty of Engineering',
//                 jc_tenpercentile: 'BBC/B',
//                 jc_tenpercentile_num: '73.75',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '3.65',
//                 poly_nintypercentile: '3.95',
//                 places: 253,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$3,959',
//                     median_str: '$3,950',
//                     mean_val: 3959,
//                     median_val: 3950
//                 }
//             },
//             'Civil Engineering': {
//                 faculty: 'Faculty of Engineering',
//                 jc_tenpercentile: 'CCD/B',
//                 jc_tenpercentile_num: '',
//                 jc_nintypercentile: 'AAB/B',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '3.71',
//                 poly_nintypercentile: '3.95',
//                 places: 133,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$3,746',
//                     median_str: '$3,600',
//                     mean_val: 3746,
//                     median_val: 3600
//                 }
//             },
//             'Electrical Engineering': {
//                 faculty: 'Faculty of Engineering',
//                 jc_tenpercentile: 'CCC/C',
//                 jc_tenpercentile_num: '67.5',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '3.68',
//                 poly_nintypercentile: '3.94',
//                 places: 207,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$4,101',
//                     median_str: '$4,000',
//                     mean_val: 4101,
//                     median_val: 4000
//                 }
//             },
//             'Engineering': {
//                 faculty: 'Faculty of Engineering',
//                 jc_tenpercentile: 'BCC/C',
//                 jc_tenpercentile_num: '70',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '#',
//                 poly_nintypercentile: '#',
//                 places: 56,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '',
//                     median_str: '',
//                     mean_val: '',
//                     median_val: ''
//                 }
//             },
//             'Engineering Science': {
//                 faculty: 'Faculty of Engineering',
//                 jc_tenpercentile: 'BCC/C',
//                 jc_tenpercentile_num: '70',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '3.83',
//                 poly_nintypercentile: '3.99',
//                 places: 35,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$4,285',
//                     median_str: '$3,750',
//                     mean_val: 4285,
//                     median_val: 3750
//                 }
//             },
//             'Environmental Engineering': {
//                 faculty: 'Faculty of Engineering',
//                 jc_tenpercentile: 'BCC/B',
//                 jc_tenpercentile_num: '71.25',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '3.57',
//                 poly_nintypercentile: '3.94',
//                 places: 47,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$3,569',
//                     median_str: '$3,400',
//                     mean_val: 3569,
//                     median_val: 3400
//                 }
//             },
//             'Industrial & Systems Engineering': {
//                 faculty: 'Faculty of Engineering',
//                 jc_tenpercentile: 'BCC/B',
//                 jc_tenpercentile_num: '71.25',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '#',
//                 poly_nintypercentile: '#',
//                 places: 65,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$4,451',
//                     median_str: '$4,000',
//                     mean_val: 4451,
//                     median_val: 4000
//                 }
//             },
//             'Materials Science & Engineering': {
//                 faculty: 'Faculty of Engineering',
//                 jc_tenpercentile: 'CCC/C',
//                 jc_tenpercentile_num: '67.5',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '3.76',
//                 poly_nintypercentile: '3.93',
//                 places: 79,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$4,086',
//                     median_str: '$4,000',
//                     mean_val: 4086,
//                     median_val: 4000
//                 }
//             },
//             'Mechanical Engineering': {
//                 faculty: 'Faculty of Engineering',
//                 jc_tenpercentile: 'BCC/B',
//                 jc_tenpercentile_num: '71.25',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '3.65',
//                 poly_nintypercentile: '3.94',
//                 places: 346,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$3,847',
//                     median_str: '$3,700',
//                     mean_val: 3847,
//                     median_val: 3700
//                 }
//             },
//             'Mechanical Engineering (Aeronautical)': {
//                 faculty: 'Faculty of Engineering',
//                 jc_tenpercentile: 'AAB/B',
//                 jc_tenpercentile_num: '81.25',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '85',
//                 poly_tenpercentile: '#',
//                 poly_nintypercentile: '#',
//                 places: 346,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '',
//                     median_str: '',
//                     mean_val: '',
//                     median_val: ''
//                 }
//             },
//             'Computing (Business Analytics)': {
//                 faculty: 'School of Computing',
//                 jc_tenpercentile: 'AAA/A',
//                 jc_tenpercentile_num: '85',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '85',
//                 poly_tenpercentile: '3.81',
//                 poly_nintypercentile: '3.95',
//                 places: 241,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$4,881',
//                     median_str: '$5,000',
//                     mean_val: 4881,
//                     median_val: 5000
//                 }
//             },
//             'Computing (Computer Science)': {
//                 faculty: 'School of Computing',
//                 jc_tenpercentile: 'AAA/A',
//                 jc_tenpercentile_num: '85',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '85',
//                 poly_tenpercentile: '3.86',
//                 poly_nintypercentile: '3.98',
//                 places: 596,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$5,571',
//                     median_str: '$5,000',
//                     mean_val: 5571,
//                     median_val: 5000
//                 }
//             },
//             'Computing (Information Security)': {
//                 faculty: 'School of Computing',
//                 jc_tenpercentile: 'AAA/A',
//                 jc_tenpercentile_num: '85',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '85',
//                 poly_tenpercentile: '3.84',
//                 poly_nintypercentile: '3.97',
//                 places: 53,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '',
//                     median_str: '',
//                     mean_val: '',
//                     median_val: ''
//                 }
//             },
//             'Computing (Information Systems)': {
//                 faculty: 'School of Computing',
//                 jc_tenpercentile: 'AAA/A',
//                 jc_tenpercentile_num: '85',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '3.82',
//                 poly_nintypercentile: '3.99',
//                 places: 124,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$4,330',
//                     median_str: '$4,228',
//                     mean_val: 4330,
//                     median_val: 4228
//                 }
//             },
//             'Computer Engineering': {
//                 faculty: 'Faculty of Engineering & School of Computing',
//                 jc_tenpercentile: 'AAB/C',
//                 jc_tenpercentile_num: '80',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '3.87',
//                 poly_nintypercentile: '3.97',
//                 places: 181,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$4,732',
//                     median_str: '$4,000',
//                     mean_val: 4732,
//                     median_val: 4000
//                 }
//             },
//             'Data Science and Analytics': {
//                 faculty: 'Faculty of Science',
//                 jc_tenpercentile: 'AAB/C',
//                 jc_tenpercentile_num: '80',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '',
//                 poly_nintypercentile: '',
//                 places: 179,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '',
//                     median_str: '',
//                     mean_val: '',
//                     median_val: ''
//                 }
//             },
//             'Pharmaceutical Science': {
//                 faculty: 'Faculty of Science',
//                 jc_tenpercentile: 'AAA/A',
//                 jc_tenpercentile_num: '85',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '#',
//                 poly_nintypercentile: '#',
//                 places: 48,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '',
//                     median_str: '',
//                     mean_val: '',
//                     median_val: ''
//                 }
//             },
//             'Pharmacy': {
//                 faculty: 'Faculty of Science',
//                 jc_tenpercentile: 'AAA/A',
//                 jc_tenpercentile_num: '85',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '#',
//                 poly_nintypercentile: '#',
//                 places: 156,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$3,840',
//                     median_str: '$3,750',
//                     mean_val: 3840,
//                     median_val: 3750
//                 }
//             },
//             'Science (Chemistry)': {
//                 faculty: 'Faculty of Science',
//                 jc_tenpercentile: '#',
//                 jc_tenpercentile_num: '#',
//                 jc_nintypercentile: '#',
//                 jc_nintypercentile_num: '#',
//                 poly_tenpercentile: '3.85',
//                 poly_nintypercentile: '3.96',
//                 places: 0,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '',
//                     median_str: '',
//                     mean_val: '',
//                     median_val: ''
//                 }
//             },
//             'Science (Life Sciences)': {
//                 faculty: 'Faculty of Science',
//                 jc_tenpercentile: '#',
//                 jc_tenpercentile_num: '#',
//                 jc_nintypercentile: '#',
//                 jc_nintypercentile_num: '#',
//                 poly_tenpercentile: '3.83',
//                 poly_nintypercentile: '3.97',
//                 places: 0,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '',
//                     median_str: '',
//                     mean_val: '',
//                     median_val: ''
//                 }
//             },
//             'Science (Physics)': {
//                 faculty: 'Faculty of Science',
//                 jc_tenpercentile: '#',
//                 jc_tenpercentile_num: '#',
//                 jc_nintypercentile: '#',
//                 jc_nintypercentile_num: '#',
//                 poly_tenpercentile: '#',
//                 poly_nintypercentile: '#',
//                 places: 0,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '',
//                     median_str: '',
//                     mean_val: '',
//                     median_val: ''
//                 }
//             },
//             'Science': {
//                 faculty: 'Faculty of Science',
//                 jc_tenpercentile: 'AAA/C',
//                 jc_tenpercentile_num: '82.5',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '',
//                 poly_nintypercentile: '',
//                 places: 571,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$3,320',
//                     median_str: '$3,100',
//                     mean_val: 3320,
//                     median_val: 3100
//                 }
//             },
//             'Science (Food Science & Technology)': {
//                 faculty: 'Faculty of Science',
//                 jc_tenpercentile: 'AAA/A',
//                 jc_tenpercentile_num: '',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '',
//                 poly_tenpercentile: '#',
//                 poly_nintypercentile: '#',
//                 places: 0,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '',
//                     median_str: '',
//                     mean_val: '',
//                     median_val: ''
//                 }
//             },
//             'Business Admin': {
//                 faculty: 'NUS Business School',
//                 jc_tenpercentile: 'AAB/C',
//                 jc_tenpercentile_num: '80',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '85',
//                 poly_tenpercentile: '3.73',
//                 poly_nintypercentile: '3.95',
//                 places: 765,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$4,593',
//                     median_str: '$4,150',
//                     mean_val: 4593,
//                     median_val: 4150
//                 }
//             },
//             'Accountancy': {
//                 faculty: 'NUS Business School',
//                 jc_tenpercentile: 'AAB/C',
//                 jc_tenpercentile_num: '80',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '85',
//                 poly_tenpercentile: '3.76',
//                 poly_nintypercentile: '3.97',
//                 places: 220,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$4,005',
//                     median_str: '$3,000',
//                     mean_val: 4005,
//                     median_val: 3000
//                 }
//             },
//             'Arts & Social Sciences (Philosophy, Politics & Economics)': {
//                 faculty: 'Faculty of Arts & Social Sciences',
//                 jc_tenpercentile: 'AAA/A',
//                 jc_tenpercentile_num: '85',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '85',
//                 poly_tenpercentile: '#',
//                 poly_nintypercentile: '#',
//                 places: 0,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$3,456',
//                     median_str: '$3,200',
//                     mean_val: 3456,
//                     median_val: 3200
//                 }
//             },
//             'Arts & Social Sciences': {
//                 faculty: 'Faculty of Arts & Social Sciences',
//                 jc_tenpercentile: 'BBB/C',
//                 jc_tenpercentile_num: '75',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '85',
//                 poly_tenpercentile: '3.67',
//                 poly_nintypercentile: '3.92',
//                 places: 0,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$3,456',
//                     median_str: '$3,200',
//                     mean_val: 3456,
//                     median_val: 3200
//                 }
//             },
//             'Arts & Social Sciences (MT related)': {
//                 faculty: 'Faculty of Arts & Social Sciences',
//                 jc_tenpercentile: 'BBC/C',
//                 jc_tenpercentile_num: '72.5',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '85',
//                 poly_tenpercentile: '',
//                 poly_nintypercentile: '',
//                 places: 0,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$3,456',
//                     median_str: '$3,200',
//                     mean_val: 3456,
//                     median_val: 3200
//                 }
//             },
//             'Environmental Studies': {
//                 faculty: 'Environmental Studies (Faculty of Arts & Social Sciences and Faculty of Science)',
//                 jc_tenpercentile: 'AAA/C',
//                 jc_tenpercentile_num: '82.5',
//                 jc_nintypercentile: 'AAA/A',
//                 jc_nintypercentile_num: '85',
//                 poly_tenpercentile: '#',
//                 poly_nintypercentile: '#',
//                 places: 47,
//                 requiretest: true,
//                 salary: {
//                     mean_str: '$3,332',
//                     median_str: '$3,325',
//                     mean_val: 3332,
//                     median_val: 3325
//                 }
//             },

//         },
//         'NTU': {

//         },
//         'SMU': {

//         }


//     }

//     const [firsttime, setFirsttime] = useState(true)
//     const [selectedsch, setSelectedsch] = useState(EMPTY)

//     useEffect(() => {
//         if (firsttime) {
//             setFirsttime(false)
//             setSelectedsch(schs[0])
//         }
//     }, [])

//     return (
//         <div style={{ display: 'flex', flexDirection: 'row' }}>
//             {/* School */}
//             {schs.map((item, index) => {
//                 return (
//                     <div
//                         key={item + index}
//                         style={{ ...BUTTONSTYLE, marginLeft: 5, cursor: 'pointer', border: selectedsch === item ? 'thin solid rgb(226, 90, 90)' : 'thin solid #707070', color: selectedsch === item ? 'rgb(226, 90, 90)' : '#707070' }}
//                         onClick={() => setSelectedsch(item)}
//                     >
//                         {item}
//                     </div>
//                 )
//             })}



//         </div>
//     )




// }
// function Beyond2() {

//     return (
//         [
//             {
//                 key: 'TEXT',
//                 val: ""
//             },
//             {
//                 key: 'DOM',
//                 val: <Unichoices />
//             }
//         ]
//     )
// }

// export default Beyond2


function Beyond2(){
    return <div/>
}
export default Beyond2