import React, { useState } from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
// import { HEART_PATH, WEDO_PATH } from '../../config/svgicons'
import './Runsvg.css'

const STATIC_MODE = 0
const ONCE_MODE = 1
const INFINITELOOP_MODE = 2

function Runsvg(props) {
    const { svgoverlay_map, svgstate, device } = props
    const [counter, setCounter] = useState(0)
    const [pathexist, setPathexist] = useState(false)
    const [firsttime, setFirsttime] = useState(true)
    const [mode, setMode] = useState(-1)
    const alignitempos = (svgoverlay_map && svgoverlay_map['mainimg']) ? svgoverlay_map['mainimg']['svgposition'] : -1
    const svgcolor = (svgoverlay_map && svgoverlay_map['mainimg']) ? svgoverlay_map['mainimg']['color'] : ''
    const strokewidth = (svgoverlay_map && svgoverlay_map['mainimg']) ? svgoverlay_map['mainimg']['sw'] : -1
    const speed = (svgoverlay_map && svgoverlay_map['mainimg']) ? svgoverlay_map['mainimg']['speed'] : -1 // 0 - slow, 1 - mid, 2 - fast
    const replay = (svgoverlay_map && svgoverlay_map['mainimg']) ? svgoverlay_map['mainimg']['replay'] : false // true or false
    // const repeat = 1  // replay btn
    const timeout_ref = useRef(null)
    const wedo_ref = useRef(null)
    const heart_ref = useRef(null)
    const WEDO_PATH =  <path ref={wedo_ref} d="M1 715.771C108.067 713.038 346.029 694.679 441.334 643.104C560.465 578.636 789.177 699.374 843.465 683.35C897.753 667.326 1088.26 514.167 1098.82 598.759C1109.37 683.35 1049.56 957.248 1136.52 892.78C1223.48 828.311 1354.67 719.497 1299.88 643.104C1245.09 566.711 1202.37 892.78 1299.88 892.78C1397.4 892.78 1625.11 598.386 1446.16 510.068C1267.21 421.75 1451.69 767.942 1625.61 683.35C1799.53 598.759 1951.84 546.215 1772.39 510.068C1592.94 473.921 1310.44 1106.31 2478.63 643.104C3646.82 179.9 2571.12 -263.181 2571.12 280.516C2565.59 155.554 2503.46 -66.1235 2299.18 46.864C2043.83 188.098 2769.17 1366.79 3427.16 643.104C3688.14 328.587 3636.1 242.257 3577.46 238.406C3515.29 312.563 3398.21 539.284 3427.16 852.906C3463.35 1244.93 2994.37 828.684 3447.27 650.184C3521.16 614.782 3301.49 1139.85 3524.17 895.015C3746.85 650.184 3779.53 610.311 3885.09 643.104C3990.65 675.897 4054.48 939.361 3844.87 977.744C3635.26 1016.13 3682.01 640.868 3828.28 623.353C3974.56 605.839 3939.37 912.902 4278.17 804.834C4616.96 696.765 4716.49 593.541 5050 693.039" stroke="currentColor" strokeWidth="inherit"/>  
    const HEART_PATH = <path ref={heart_ref} d="M0.5 762.623C60.5 762.623 168.561 628.599 354.601 656.815C587.15 692.084 618.535 868.431 830.287 762.623C1042.04 656.815 1295.76 551.006 1549.49 656.815C1803.21 762.623 2118.19 1116.99 2470.61 939.809C2823.03 762.623 2992.74 553.008 3048.77 407.408C3099.73 274.993 3105.49 -0.290458 2914.91 15.6648C2724.34 31.62 2650.98 221.718 2611.65 313.356C2562.27 428.419 2548.13 78.6459 2357.93 78.6459C2167.73 78.6459 2089.06 248.644 2105.34 372.453C2130.29 562.237 2307.64 958.129 2802.23 1010.35C3297.53 1062.64 3493.07 656.815 3775.16 656.815C4057.24 656.815 4247.82 762.623 4508.73 762.623C4769.64 762.623 4910.3 656.815 4995 656.815" stroke="currentColor" strokeWidth='inherit' />

    const duration = {
        'WEB': [250, 500, 750],
        'TABLET': [250, 500, 750],
        'MOBILE': [250, 500, 750],
    }

    useEffect(() => {
        setMode((svgoverlay_map && svgoverlay_map['mainimg']) ? svgoverlay_map['mainimg']['mode'] : -1)
    }, [mode])

    useEffect(() => {
        if (!firsttime) {
            if (svgoverlay_map && svgoverlay_map['mainimg'] && svgoverlay_map['mainimg']['svgitem'] === 1) {
                let pathlen = wedo_ref.current.getTotalLength()
                wedo_ref.current.style.strokeDasharray = pathlen + ' ' + pathlen
                wedo_ref.current.style.strokeDashoffset = 0
            }
            else if (svgoverlay_map && svgoverlay_map['mainimg'] && svgoverlay_map['mainimg']['svgitem'] === 2) {
                let pathlen = heart_ref.current.getTotalLength()
                heart_ref.current.style.strokeDasharray = pathlen + ' ' + pathlen
                heart_ref.current.style.strokeDashoffset = 0
            }
            setCounter(1)
        }
    }, [svgoverlay_map ])

    useEffect(() => {
        if (document.querySelector('path') && props.startrunsvg && firsttime) {
            setPathexist(true)
        }
    }, [document.querySelector('path'), props.startrunsvg])

    useEffect(() => {
        if (pathexist && firsttime) {
            setFirsttime(false)
            if (svgoverlay_map['mainimg']['svgitem'] === 1) {
                let pathlen = wedo_ref.current.getTotalLength()
                wedo_ref.current.style.strokeDasharray = pathlen + ' ' + pathlen
                wedo_ref.current.style.strokeDashoffset = 0
            }
            else if (svgoverlay_map['mainimg']['svgitem'] === 2) {
                let pathlen = heart_ref.current.getTotalLength()
                heart_ref.current.style.strokeDasharray = pathlen + ' ' + pathlen
                heart_ref.current.style.strokeDashoffset = 0
            }
            setCounter(1)
        }
    }, [pathexist])

    useEffect(()=>{
        if(svgstate==='PLAYING'&& !firsttime ){
            setCounter(counter+1)
        }
    },[svgstate])

    useEffect(() => {
        if (counter > 0 && !firsttime ) {
            timeout_ref.current = setTimeout(() => {
                let path = svgoverlay_map['mainimg']['svgitem'] === 1 ? wedo_ref.current : svgoverlay_map['mainimg']['svgitem'] === 2 ? heart_ref.current : null
                if (path) {
                    try {
                        let pathlen = path.getTotalLength()
                        var val = pathlen - (counter * (duration[device][speed]))
                        if (svgstate === 'PAUSE') {
                            path.style.strokeDashoffset = 0
                            // setCounter(counter + 1)
                        }
                        else if (svgstate === 'PLAYING') {
                            if (mode === 0) {
                                path.style.strokeDashoffset = 0
                                setCounter(counter + 1)
                            }
                            else if (parseInt(val) < 0 && mode === 1) {
                                path.style.strokeDashoffset = 0
                                setCounter(0)
                                clearTimeout(timeout_ref.current);
                            }
                            else {
                                path.style.strokeDashoffset = val
                                setCounter(counter + 1)
                            }
                        }
                    }
                    catch (e) {
                        clearTimeout(timeout_ref.current);
                    }
                }
                else return
            }, 50)

            return () => {clearTimeout(timeout_ref.current) }
        }
    }, [counter])



    const pureheartsvg = <svg
        style={{ opacity: (counter >= 0) ? 1 : 0, color: svgcolor, strokeWidth: strokewidth, height: 'max-content' }}
        width="100%"
        height="100%"
        viewBox="0 0 4995 1030"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        {HEART_PATH}
    </svg>

    const wedosvg = <svg
        style={{ opacity: (counter >= 0) ? 1 : 0, color: svgcolor, strokeWidth: strokewidth, height: 'max-content' }}
        width="100%"
        height="100%"
        viewBox="0 0 5030 1032"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        {WEDO_PATH}
    </svg>

    const svgmap = {
        0: <div style={{ position: 'absolute', width: 0, height: 0 }} />,
        1: wedosvg,
        2: pureheartsvg
    }

    return props.startrunsvg // pure heart
        ? <div key={svgoverlay_map['mainimg']['svgitem']} style={{ height: '100%', width: '100%', position: 'absolute', display: 'flex', alignItems: ['flex-start', 'center', 'flex-end'][alignitempos] }}>
            {svgmap[svgoverlay_map['mainimg']['svgitem']]}
        </div>
        : null

}

export default Runsvg
