import React, { useEffect, useState } from 'react'
import { USER_READQRPROJS,UPDATE_QRPASSCODE, INVITATION_DESTROY, QR_CREATE, QR_DELETE } from '../../../../config/api'
import { Retrieve_personal_info } from '../../../../config/commonfunctions'
import { Modcfm } from '../../../../components'
import Selectionbox from '../Components/Selectionbox/Selectionbox'
import axios from 'axios'
import Createnew from '../Components/Createnew/Createnew'
import { input_dom } from '../Invitationmaker/components/Commonelements/Commonelements'
import Continuepage from '../Components/Continuepage/Continuepage'
import '../Planning.css'

function Qrmaker(props) {

    window.screen.lockOrientationUniversal = window.screen.lockOrientation || window.screen.mozLockOrientation || window.screen.msLockOrientation;

    const { token } = Retrieve_personal_info()
    const [firsttime, setFirsttime] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [qr_ids, setQr_ids] = useState([])
    const [qr_objs, setQr_objs] = useState([])

    const [currqr_id, setCurrqr_id] = useState('') // qr id

    // current form, if invitationcard gt items, den render them else default is profile details
    const [urlhandler, setUrlhandler] = useState('') // invitation url handler
    const [passcode, setPasscode] = useState('')

    // quota
    const [quota, setQuota] = useState(0)

    // removal of selectionbox
    const [rmvmod, setRmvmod] = useState(false)
    const [rmvindex, setRmvindex] = useState(null)
    const [rmvid, setRmvid] = useState(null)

    // all pages
    const [page, setPage] = useState('NONE') // NONE, CREATE, OPEN (OPEN EXISTING)

    useEffect(() => {
        if (firsttime) {
            try {
                let options = {
                    method: 'POST',
                    url: USER_READQRPROJS,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                }
                axios(options)
                    .then((qr) => {
                        const { qr_ids, qrquota, qr_data, } = qr.data
                        setQr_ids(qr_ids)
                        setQr_objs(qr_data)
                        setQuota(qrquota)
                        setLoaded(true)
                        setPage('NONE')
                    })
                    .catch((e) => {
                         props.history.push('/')
                    })
            }
            catch (e) {
                props.history.push('/')
            }
            setFirsttime(false)
        }
    }, [])

    function clearFields() {
        setPage('NONE')
    }

    const continueclick = () => {
        // props.history.push('qrbuilder/' + urlhandler + '/edit')
        props.history.push(`qrbuilder/${currqr_id}/edit`)
    }

    const add = () => {
        setCurrqr_id('')
        setPage('CREATE')
    }

    const clickexist = (clickid) => {
        if (clickid === currqr_id) {
            // uncheck 
            clearFields()
            setCurrqr_id('')
            setPage('NONE')
        }
        else {
            var obj
            for (let i = 0; i < qr_objs.length; i++) {
                if (qr_objs[i]._id === clickid) {
                    obj = qr_objs[i]
                    break
                }
            }
            const { _id, urlhandler, passcode } = obj
            clearFields()
            setCurrqr_id(_id)
            setPasscode(passcode)
            setUrlhandler(urlhandler)
            setPage('OPEN')
        }
    }

    const removeexist = (_id, index) => {
        setRmvmod(true);
        setRmvid(_id);
        setRmvindex(index);
    }

    const updateseq = (new_ids) => { setQr_ids(new_ids); };

    const removecfm = (qr_id, index) => {
        try {
            let options = {
                method: 'POST',
                url: QR_DELETE,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { qr_id: qr_id }
            }
            axios(options).then((item) => {
                const { data } = item
                const { message } = data
                if (message === 'SUCCESS') {
                    setCurrqr_id('')

                    var local_ic_ids = qr_ids.slice()
                    local_ic_ids.splice(index, 1)
                    setQr_ids(local_ic_ids)

                    setQr_objs((curr) => {
                        let new_qrobjs = JSON.parse(JSON.stringify(curr))
                        let index = -1
                        for (let i = 0; i < new_qrobjs.length; i++) {
                            console.log(new_qrobjs)
                            if (new_qrobjs[i]._id === qr_id) {
                                index = i
                            }
                        }
                        new_qrobjs.splice(index, 1)
                        return new_qrobjs
                    })

                    clearFields()

                }
            }).catch((e) => {
                props.history.push('/')
            })
        }
        catch (e) {
            props.history.push('/')
        }
    }

    const create = (urlhandler, passcode) => {
        return axios({
            method: 'POST',
            url: QR_CREATE,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
            data: { urlhandler, passcode }
        })
            .then((result) => {
                const { message, qrid } = result.data
                if (message === 'SUCCESS') {
                    setQr_ids((curr) => {
                        let new_qrids = curr.slice()
                        new_qrids.push(qrid)
                        return new_qrids
                    })
                    setQr_objs((curr) => {
                        let new_qrobjs = JSON.parse(JSON.stringify(curr))
                        new_qrobjs.push({ urlhandler: urlhandler, passcode: passcode, _id: qrid })
                        return new_qrobjs
                    })
                }
                return { message, id: qrid }

            })
            .catch((e) => { })

    }

    const savepasscode = (newpasscode) => {
        console.log({ passcode: newpasscode, qr_id: currqr_id, old_passcode: passcode, urlhandler: urlhandler })
        return axios({
            method: 'POST',
            url: UPDATE_QRPASSCODE,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
            data: { passcode: newpasscode, qr_id: currqr_id, old_passcode: passcode, urlhandler: urlhandler }
        }).then((result) => {
            const { data } = result
            if (data.message === 'SUCCESS') {
                setPasscode(newpasscode)
                return 'SUCCESS'
            }
            else if (data.message === 'UNABLE_SAVE') {
                return 'UNABLE_SAVE'
            }
        }).catch((e) => { console.log(e) })
    }

    const lookupclick = '/qrlookup/' + urlhandler + (passcode.length > 0 ? '/passcode=' + passcode : '')

    return (
        <div id='planningedit_root'>
            <div id='planningedit_selectionbox'>
                {loaded
                    ? <Selectionbox
                        where={'qrmaker'}
                        onClickexist={clickexist}
                        onRemoveexist={removeexist}
                        onAdd={add}
                        updateseq={updateseq}
                        quota={quota}
                        ids={qr_objs}
                        id={currqr_id}
                    />
                    : null}

                {{
                    'NONE': null,
                    'OPEN': <Continuepage
                        {...props}
                        from={'qrmaker'}
                        urlhandler={urlhandler}
                        passcode={passcode}
                        lookupclick={lookupclick}
                        continueclick={continueclick}
                        savepasscode={savepasscode}
                        title={'Change Passcode'}
                    />,
                    'CREATE': <Createnew
                        {...props}
                        from={'qrmaker'}
                        reachquota={quota === qr_ids.length}
                        urlhandler={urlhandler}
                        passcode={null}
                        itype={'Wedding'}
                        create={create}
                        createsuccess={(id, urlhandler, passcode) => {
                            setUrlhandler(urlhandler)
                            setPasscode(passcode)
                            setCurrqr_id(id)
                            setPage('OPEN')
                        }}
                    />

                }[page]}

                {rmvmod ? <Modcfm
                    onHide={() => { setRmvmod(false) }}
                    onOk={() => { removecfm(rmvid, rmvindex); setRmvmod(false) }}
                    onNo={() => { setRmvmod(false) }}
                    txt={'Once you delete this, there is no going back. Link to this Invitation and Guestlist will no longer be functional, please be certain.'}
                /> : null}
            </div>
        </div>

    )
}
export default Qrmaker