import React, { useState, useEffect } from 'react'
import { retrieveDateobj } from '../../config/commonfunctions'
// import { DAYS_ARR, NUMMTH_FULLCHARMTH_MAP } from '../../config/constants'
import { Checkdevice } from '../Checkdevice'
import './Invdate.css'

const from_params_map = {
    'Details': {
        'WEB': { mainwt: '100%', mainminwt: 115, mainfs: 24, subfs: 20, slashmargin: 10, scale: 1 },
        'TABLET': { mainwt: '100%', mainminwt: 115, mainfs: 22, subfs: 18, slashmargin: 10, scale: 1 },
        'MOBILE': { mainwt: '85%', mainminwt: 115, mainfs: 19, subfs: 15, slashmargin: 10, scale: 1 },
    },
    'Invitationlookup': {
        'WEB': { mainwt: '100%', mainminwt: 115, mainfs: 25, subfs: 20, slashmargin: 10, scale: 1 },
        'TABLET': { mainwt: '100%', mainminwt: 115, mainfs: 25, subfs: 20, slashmargin: 10, scale: 1 },
        'MOBILE': { mainwt: '100%', mainminwt: 115, mainfs: 25, subfs: 20, slashmargin: 10, scale: 0.98 },
    },
    'T1sim': {
        'WEB': { mainwt: '60%', mainminwt: 73, mainfs: 13, subfs: 9, slashmargin: 3, scale: 1 },
        'TABLET': { mainwt: '60%', mainminwt: 73, mainfs: 13, subfs: 9, slashmargin: 3, scale: 1 },
        'MOBILE': { mainwt: '60%', mainminwt: 73, mainfs: 13, subfs: 9, slashmargin: 3, scale: 1 },
    },
    'T1simmobile': {
        'WEB': { mainwt: '100%', mainminwt: 72, mainfs: 13, subfs: 9, slashmargin: 3, scale: 1 },
        'TABLET': { mainwt: '100%', mainminwt: 72, mainfs: 13, subfs: 9, slashmargin: 3, scale: 1 },
        'MOBILE': { mainwt: '100%', mainminwt: 72, mainfs: 13, subfs: 9, slashmargin: 3, scale: 1 },
    }
}

function common(a, b, c, d, time, fc, mainwt, mainfs, subfs, mainminwt, ff, from, scale) { // placement of datetime_dom


    // const from_params_map = {
    //     'Details': {
    //         'WEB': { },
    //         'TABLET': {},
    //         'MOBILE': {},
    //     },
    //     'Invitationlookup': {
    //         'WEB': {margin:'20px 0px'},
    //         'TABLET': {margin:'20px 0px'},
    //         'MOBILE': {margin:'20px 0px'},
    //     },
    //     'T1sim': {
    //         'WEB': {},
    //         'TABLET': {},
    //         'MOBILE': {},
    //     },
    //     'T1simmobile': {
    //         'WEB': {},
    //         'TABLET': {},
    //         'MOBILE': {},
    //     }
    // }

    const params = {
        'Details': '15px 0px',
        'Invitationlookup': '20px 0px',
        'T1sim': '15px 0px',
        'T1simmobile': '15px 0px',
    }

    const justborder = from === 'Details' ? `0.5px solid var( --maindark )` : `1px solid ${fc}4d`

    return (
        <div style={{ width: mainwt, color: fc, fontFamily: ff, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', transform: `scale(${scale})` }}>

            <div style={{ display: 'flex', flexDirection: 'row', padding: params[from], letterSpacing: 1 }}>

                <div style={{ fontSize: subfs, padding: '0px 10px', alignItems: 'center', display: 'flex', justifyContent: 'center', minWidth: mainminwt }}>
                    {a}
                </div>

                <div style={{ borderLeft: justborder, borderRight: justborder, padding: '0px 10px', display: 'flex', flexDirection: 'column', minWidth: mainminwt }}>
                    <div style={{ fontSize: subfs }} >{b}</div>
                    <div style={{ fontSize: mainfs, }}>{c}</div>
                    <div style={{ fontSize: subfs }}>{d}</div>
                </div>
                <div style={{ fontSize: subfs, padding: '0px 10px', alignItems: 'center', display: 'flex', justifyContent: 'center', minWidth: mainminwt }}>{time}</div>

            </div>

        </div>
    )
}

function stacktopdown(day, date, time, fc, mainwt, mainfs, subfs, slashmargin, ff, from) {
    const params = {
        'Details': '15px 0px',
        'Invitationlookup': '20px 0px',
        'T1sim': '15px 0px',
        'T1simmobile': '15px 0px',
    }

    const justborder = from === 'Details' ? `0.5px solid var( --maindark )` : `1px solid ${fc}4d`

    return (
        <div style={{ width: mainwt, color: fc, fontFamily: ff, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', }}>

            <div style={{ display: 'flex', flexDirection: 'row', padding: params[from] }}>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0px 10px' }}>
                    <div style={{ fontSize: subfs }}>{day}</div>
                    <div style={{ fontSize: subfs }}>{date.split("/").join('.')}</div>
                </div>

                <div style={{ borderLeft: justborder, display: 'flex', flexDirection: 'column', transform: 'rotate(22.5deg)', margin: slashmargin }} />

                <div style={{ fontSize: mainfs, display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0px 10px' }}>
                    <div>{time}</div>
                </div>

            </div>

        </div>
    )
}

function Invdate(props) {

    const { date_e, date_w, itype, dt_type, time, fc, from, datetimeff } = props
    const [dateobj, setDateobj] = useState({}) // {date:'', day:'', d:'', m:'', y:''}
    const device = Checkdevice()
    const { mainwt, mainfs, subfs, slashmargin, mainminwt, scale } = from_params_map[from][device]
    useEffect(() => {
        var obj_date_str =
            (itype === 'Wedding' && date_w && (date_w.length > 0 || date_w.toString().length > 0))
                ? date_w
                : (itype === 'Event' && date_e && (date_e.length > 0 || date_e.toString().length > 0))
                    ? date_e
                    : ''
        setDateobj(retrieveDateobj(obj_date_str))
    }, [props])

    const params = {
        'Details': '15px 0px',
        'Invitationlookup': '20px 0px',
        'T1sim': '15px 0px',
        'T1simmobile': '15px 0px',
    }

    // return <div key={'wtf' +Math.random()}/>
    return dateobj && Object.keys(dateobj).length === 5
        ? {
            'Wedding': {
                0: <div style={{ width: mainwt, color: fc, fontFamily: props.datetimeff, margin: params[from], display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', }}>
                    <div style={{ fontSize: mainfs }}>{dateobj.date}</div>
                    <div style={{ fontSize: mainfs }}>{time}</div>
                </div>,
                1: common(dateobj.day, dateobj.m, dateobj.d, dateobj.y, time, fc, mainwt, mainfs, subfs, mainminwt, datetimeff, from, scale),
                2: common(dateobj.m, dateobj.day, dateobj.d, dateobj.y, time, fc, mainwt, mainfs, subfs, mainminwt, datetimeff, from, scale),
                3: stacktopdown(dateobj.day, dateobj.date, time, fc, mainwt, mainfs, subfs, slashmargin, datetimeff, from)
            },
            'Event': {}
        }[itype][parseInt(dt_type, 10)]
        : <div style={{ width: 0, height: 0, visibility: 'hidden' }} />


}

export default Invdate