import { useEffect, useState, useRef } from "react"
import { Checkdevice, Loading, Modcfm, Portalmodal, refresh, ScreenWidth } from "../../../../../components"
import { EMPTYSPACE, EXPAND_RIGHT_ICON, EXPAND_ICON, TRASH_SMALL, SIXDOT_SMALL, MAIN_PINK_COLOR, b_fs, f_fs, d_fs, e_fs, c_fs, g_fs, HIDE_SMALL, SHOW_SMALL, DOWNLOAD_SMALL, ARROWLEFT_ICON, PLOBJ } from "../../../../../config/constants"
import { COLOR_ICON, FRAME_ICON, LAPTOP_ICON, DOWNLOAD_ICON, MOBILE_ICON, TRASH_ICON, GRID_ICON, RESIZE_ICON, ITINERARYTEMPLATE_ICON, ITINERARYCOMP_ICON, PRINT_ICON, COPY_ICON, ENTER_ICON, TEMPLATE_ICON, PLANNING_QR_ICON, COLLAPSE_ICON, UNCOLLAPSE_ICON, SETTING10_ICON, LINEDOWNARROW_ICON, LINERIGHTARROW_ICON, } from "../../../../../config/svgicons"
import { Link } from 'react-router-dom'
import { iconwrapper, Retrieve_personal_info } from "../../../../../config/commonfunctions"
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { READ_SINGLEBUDGET, UPDATE_BUDGET, UPDATE_BUDGETSWAP, UPDATE_BUDGETADDITEM, UPDATE_BUDGETSECTION, UPDATE_BUDGETADDSECTION, UPDATE_BUDGETNAME, UPDATE_BUDGETTOTAL, UPDATE_BUDGETCHANGETEMPLATE, UPDATE_BUDGETSETTINGS, UPDATE_CHECKLISTARCHIVE, UPDATE_BUDGETITEM, REMOVE_BUDGETDELETEITEM, REMOVE_BUDGETDELETESECTION } from "../../../../../config/api"
import axios from "axios";
import { input_dom, partialtitle_dom, textarea_dom } from "../../Invitationmaker/components/Commonelements/Commonelements"
import Success from "../../Invitationmaker/components/Portalcontent/components/Success"
import './Budgetedit.css'
import Chart from "../Chart"
import { CSVLink } from "react-csv"

const CheckArrayZeroes = (arr) => {
    var allzeroes = true
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== 0) {
            allzeroes = false
        }
    }
    return allzeroes
}

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#707070' : 'lightgrey',
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // // some basic styles to make the items look a bit nicer
    // userSelect: 'none',
    // // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,

    // // change background colour if dragging
    // // background: isDragging ? '#707070' : 'white',
    // background: 'white',


    // // styles we need to apply on draggables
    // ...draggableStyle
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    outline: 'none',
    userSelect: 'none', // some basic styles to make the items look a bit nicer
    backgroundColor: 'white',    // change background colour if dragging
    border: isDragging ? '1px solid #707070' : '1px solid transparent',
    minHeight: 60,
    marginBottom: 5,
    ...draggableStyle,    // styles we need to apply on draggables
});

const getMainobjStyle = (isDragging, draggableStyle) => {
    return isDragging
        ? { border: '1px solid var(--maindark)', ...draggableStyle, paddding: 0 }
        : { border: '1px solid transparent', ...draggableStyle, paddding: 0 }
}

const exceltitle_root = { border: 'none', outline: 'none', textTransform: 'uppercase', alignSelf: 'center' }
const exceltitle = { display: 'flex', alignItems: 'center', padding: 5, outline: 'none', border: '1px solid rgb(238, 238, 238)', borderRadius: 0, alignSelf: 'center', height: 60 } //, borderBottom: '1px solid #707070'
const title_ratio = '40%'
const price_ratio = '20%'
const amtpaid_ratio = '20%'
const amtdue_ratio = '20%'

const title_ratio_1 = '50%'
const price_ratio_1 = '50%'

function Budgetedit(props) {
    // default stuff
    const { token } = Retrieve_personal_info()
    const device = Checkdevice()
    const [firsttime, setFirsttime] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [returnhome, setReturnhome] = useState(false)

    // save block
    const [blocksave, setBlocksave] = useState(false)
    const [notitxt, setNotitxt] = useState('')

    // data
    const [update_id, setUpdate_id] = useState('') // verifier to ensure one one checklist exist everytime.
    const [currbudgetid, setCurrbudgetid] = useState('')
    const [currsectionid, setCurrsectionid] = useState('')
    const [curritemid, setCurritemid] = useState('')
    const [currbudgetstr, setCurrbudgetstr] = useState('') //  the total budget in string.
    const [currbudgetobjs, setCurrbudgetobjs] = useState(null)
    const [currbudgetname, setCurrbudgetname] = useState('')
    const [currbudgetcurrency, setCurrbudgetcurrency] = useState('')
    const [currbudgetsequence, setCurrbudgetsequence] = useState([])

    const [portaltempdata, setPortaltempdata] = useState({}) // for portal temp data

    // chart data
    const [actualcost, setActualcost] = useState([])
    const [amtpaid, setAmtpaid] = useState([])
    const [amtdue, setAmtdue] = useState([])

    const [totalcost, setTotalcost] = useState(0)
    const [totalpaid, setTotalpaid] = useState(0)
    const [totaldue, setTotaldue] = useState(0)

    // setting
    const [chart, setChart] = useState('Total')
    const [chartbudgetobjs, setChartbudgetobjs] = useState([])
    const [hover, setHover] = useState('')

    // drag and drop
    const [dragsavemode, setDragsavemode] = useState(false)
    const [clonebudgetobjs, setClonebudgetobjs] = useState({})
    const [clonebudgetsequence, setClonebudgetsequence] = useState([])

    // template portal
    const [opentemplate, setOpentemplate] = useState(false)
    const [templatemod, setTemplatemod] = useState(false) // for template confirmation
    const [templatekey, setTemplatekey] = useState('') // for template confirmation

    // portal state
    const [portalstate, setPortalstate] = useState('') // EDITBUDGETNAME, EDITSECTION, EDITSECTIONNOTE, ADDSECTION, EDITITEM, ADDITEM
    const [portalresult, setPortalresult] = useState('')
    const [portalmsgs, setPortalmsgs] = useState('')

    // store general data for CHECKLIST, EDITSECTION, ITEM, NEW_ITEM, NEW_SEC
    const [portaltext, setPortaltext] = useState('')

    // csv
    const [csvdata, setCsvdata] = useState([]) // for csv

    // expand currbudgetobjs
    const [expandall, setExpandall] = useState(false)

    // download portal
    const [downloadportal, setDownloadportal] = useState(false)
    const [csvdom, setCsvdom] = useState(null) // for csv

    // setting
    const [openbudgetsetting, setOpenbudgetsetting] = useState(false)
    const [budgetsettings, setBudgetsettings] = useState({ mode: -1, placement: '', showtrash: false })
    const [portaltempsettingdata, setPortaltempsettingdata] = useState({ mode: -1, placement: '', showtrash: false }) // for portal only


    // Archive MODE or Budget list mode
    const [screenmode, setScreenmode] = useState('BUDGET') // BUDGET, ARCHIVE

    // delete item
    const [deletemod, setDeletemod] = useState(false)

    // delete section
    const [deletesectiongate, setDeletesectiongate] = useState(false)

    const [hoversub, setHoversub] = useState(null)

    const wt = ScreenWidth()

    const VT_DEVICE_FS_MAP = { 'WEB': b_fs, 'TABLET': c_fs, 'MOBILE': d_fs }
    const CELL_DEVICE_FS_MAP = { 'WEB': d_fs, 'TABLET': d_fs, 'MOBILE': f_fs }
    const TITLE_DEVICE_FS_MAP = { 'WEB': f_fs, 'TABLET': f_fs, 'MOBILE': g_fs }

    const iconsize = { 'WEB': 30, 'TABLET': 25, 'MOBILE': 25 }[device]
    const itemiconsize = { 'WEB': 20, 'TABLET': 18, 'MOBILE': 18 }[device]
    const portalleftcss = { width: { 'WEB': 500, 'TABLET': 450, 'MOBILE': '100%' }[device], height: '100%', backgroundColor: 'white', top: 0, left: null, right: 0, transform: 'none' }

    // retrieve itinerary data from urlhandler 
    useEffect(() => {
        props.hidetopbar(true)
        if (firsttime) {
            var id = props.match.params.id
            axios({
                method: 'POST',
                url: READ_SINGLEBUDGET,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { budget_id: id }
            })
                .then((budget) => {
                    const { message, budget_obj } = budget.data
                    if (message === 'SUCCESS') {
                        const { budgetstr, currency, name, _id, budgetobjs, budgetsequence, update_id, settings } = budget_obj
                        setCurrbudgetsequence(budgetsequence)
                        setCurrbudgetobjs(budgetobjs)
                        setCurrbudgetname(name)
                        setCurrbudgetid(_id)
                        setCurrbudgetcurrency(currency)
                        setCurrbudgetstr(budgetstr)
                        setUpdate_id(update_id)
                        setBudgetsettings(JSON.parse(JSON.stringify(settings)))
                        setPortaltempsettingdata(JSON.parse(JSON.stringify(settings)))
                        setLoaded(true)
                        setFirsttime(false)
                    }
                }).catch((e) => {
                    // props.history.push('/') 
                })
        }
        return () => props.hidetopbar(false)

    }, [])

    useEffect(() => {
        setPortalresult('DEFAULT')
    }, [portalstate])

    useEffect(() => {
        if (currbudgetobjs && currbudgetsequence && Object.keys(currbudgetobjs).length === currbudgetsequence.length) {
            let chartbudgetobjs = []
            for (let i = 0; i < currbudgetsequence.length; i++) {
                let sectionid = currbudgetsequence[i]
                chartbudgetobjs.push(currbudgetobjs[sectionid])
            }
            setChartbudgetobjs(chartbudgetobjs)
        }
    }, [currbudgetobjs, currbudgetsequence])

    useEffect(() => {
        // restructure the curr_vtobj for CSVLink to export

        // if (currbudgetsequence
        //     && currbudgetsequence.length > 0
        //     // && Object.keys(currbudgetobjs)
        //     // && Object.keys(currbudgetobjs).length === currbudgetsequence.length
        // ) {

        // if (currbudgetobjs && currbudgetsequence && Object.keys(currbudgetobjs).length > 0 && currbudgetsequence.length > 0) {

        if (currbudgetobjs && currbudgetsequence && Object.keys(currbudgetobjs).length === currbudgetsequence.length) {

            var totalcost = 0
            var totalpaid = 0
            var totaldue = 0
            var new_csvData = []
            var currvtobjs_actualcost = [] // chart
            var currvtobjs_amtpaid = [] // chart
            var currvtobjs_amtdue = [] // chart

            new_csvData.push(['', 'actual_cost', 'amount_due', 'amount_paid', 'estimated_cost'])

            for (let i = 0; i < currbudgetsequence.length; i++) {

                const sectionid = currbudgetsequence[i]
                const { sectionname, items, itemsequence, content } = currbudgetobjs[sectionid]

                if (i !== 0) new_csvData.push([''])
                new_csvData.push([sectionname])
                new_csvData.push([content])
                var actualcost_data = 0 // chart
                var amtpaid_data = 0 // chart
                var amtdue_data = 0 // chart

                for (let j = 0; j < itemsequence.length; j++) {
                    const itemid = itemsequence[j]
                    const { actual_cost = 0, amount_paid = 0, amount_due = 0, estimated_cost, itemname } = items[itemid]
                    var row = [itemname, actual_cost, amount_due, amount_paid, estimated_cost]
                    new_csvData.push(row)

                    var actualcost = (actual_cost.length === 0 ? 0 : parseInt(actual_cost, 10))
                    var amtpaid = (amount_paid.length === 0 ? 0 : parseInt(amount_paid, 10))
                    var amtdue = (amount_due.length === 0 ? 0 : parseInt(amount_due, 10))

                    actualcost_data = actualcost_data + actualcost // chart
                    amtpaid_data = amtpaid_data + amtpaid// chart 
                    amtdue_data = amtdue_data + amtdue// chart 

                    totalcost = totalcost + actualcost
                    totalpaid = totalpaid + amtpaid
                    totaldue = totaldue + amtdue

                }
                currvtobjs_actualcost.push(actualcost_data)// chart
                currvtobjs_amtpaid.push(amtpaid_data)// chart
                currvtobjs_amtdue.push(amtdue_data)// chart
            }

            // check if currvtobjs_actualcost is all 0? if is all 0s then dont render graph
            var allzeroes = CheckArrayZeroes(currvtobjs_actualcost)

            if (!allzeroes) setActualcost(currvtobjs_actualcost) // chart
            else setActualcost([])

            allzeroes = CheckArrayZeroes(currvtobjs_amtpaid)
            if (!allzeroes) setAmtpaid(currvtobjs_amtpaid) // chart
            else setAmtpaid([])

            allzeroes = CheckArrayZeroes(currvtobjs_amtdue)
            if (!allzeroes) setAmtdue(currvtobjs_amtdue) // chart
            else setAmtdue([])

            setTotalcost(totalcost)
            setTotalpaid(totalpaid)
            setTotaldue(totaldue)
            setCsvdata(new_csvData)

        }

    }, [currbudgetobjs, currbudgetsequence])

    useEffect(() => {

        if (currbudgetsequence && currbudgetsequence.length > 0) {

            let data = []
            let iddata = []

            for (let i = 0; i < currbudgetsequence.length; i++) {
                const sectionid = currbudgetsequence[i]
                if (currbudgetobjs && currbudgetobjs[sectionid]) {
                    const { items, itemsequence, collapse, content, icon, sectionname } = currbudgetobjs[sectionid]
                    for (let j = 0; j < itemsequence.length; j++) {
                        const itemid = itemsequence[j]
                        const { actual_cost, amount_due, amount_paid, content, estimated_cost, itemname } = items[itemid]
                        if (iddata.indexOf(sectionid) > -1) {
                            data.push({ sectionname: '', actual_cost, amount_due, amount_paid, estimated_cost, content, itemname })
                        }
                        else {
                            iddata.push(sectionid)
                            data.push({ sectionname, actual_cost, amount_due, amount_paid, estimated_cost, content, itemname })
                        }
                    }
                    data.push({ sectionname: '', actual_cost: '', amount_due: '', amount_paid: '', estimated_cost: '', content: '', itemname: '' })
                }
            }

            setCsvdom(
                <CSVLink
                    id='DOWNLOAD_BUDGET'
                    filename={"data.csv"}
                    data={data}
                    style={{ visibility: 'hidden', width: 0, height: 0 }}
                >
                </CSVLink>
            )

        }

    }, [currbudgetobjs, currbudgetsequence])

    // home modcfm start
    const home_mod = returnhome
        ? <Modcfm
            onHide={() => { setReturnhome(false) }}
            onOk={() => { props.history.push(PLOBJ['budget']['toolurl']) }}
            onNo={() => { setReturnhome(false) }}
            txt={'You are returning to planning tools menu.'}
        /> : null

    const save = () => {
        setBlocksave(true)
        setNotitxt('Saving...')
        let options = {
            method: 'POST',
            url: UPDATE_BUDGET,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }, //need token from the web
            data: {}
        }
        axios(options).then((result) => {
            const { data, status } = result
            const { message } = data
            if (status === 200) {
                timersequence(
                    () => {
                        setBlocksave(false)
                        if (message === 'SUCCESS') {
                            setNotitxt('')
                        }
                    },
                    {
                        'SUCCESS': 'Saved',
                        'FAILURE': 'Failed'
                    }[message]
                )
            }
        }).catch(e => { })
    }

    const dragsave_dom = dragsavemode
        ? <div className='Rsvpqnexplorer_swappedsavedroot'>

            <button
                className="Rsvpqnexplorer_swappedcancel"
                onClick={() => {
                    setPortalstate('')
                    setCurrbudgetobjs(JSON.parse(JSON.stringify(clonebudgetobjs)))
                    setCurrbudgetsequence(clonebudgetsequence.slice())
                    setDragsavemode(false)
                }}
            >
                Cancel
            </button>
            <button
                className="Rsvpqnexplorer_swappedsavedbtn"
                onClick={() => {

                    axios({
                        method: 'POST',
                        url: UPDATE_BUDGETSWAP,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                        data: { update_id, budget_id: currbudgetid, budgetsequence: currbudgetsequence, budgetobjs: currbudgetobjs }
                    })
                        .then((checklist) => {
                            const { message, new_update_id } = checklist.data
                            if (message === 'SUCCESS') {
                                setPortalstate('')
                                setDragsavemode(false)
                                setClonebudgetobjs({})
                                setClonebudgetsequence([])
                                setUpdate_id(new_update_id)
                            }
                            else if (message === 'CHECKLISTDOUBLE') {
                                refresh()
                            }
                        }).catch((e) => {
                            setPortalstate('')
                            setCurrbudgetobjs(JSON.parse(JSON.stringify(clonebudgetobjs)))
                            setCurrbudgetsequence(clonebudgetsequence.slice())
                            setDragsavemode(false)
                        })
                }}
            >
                Save
            </button>
        </div>
        : null



    // top bar start
    const bar_wt_dom = <div style={{ width: '100%', borderBottom: '1px solid #dddddd', padding: 10, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', zIndex: 1040 }}>
        <div style={{ display: 'flex', flexDirection: 'row', fontSize: e_fs, alignItems: 'center', pointerEvents: 'none', userSelect: 'none', opacity: 0 }}></div>
        <button className="planning_backicon" style={{ position: 'absolute', left: 10, }} onClick={() => { setReturnhome(true) }}>{ARROWLEFT_ICON}</button>
        <div style={{ display: 'flex', flexDirection: 'row', fontSize: e_fs, alignItems: 'center' }}></div>
        <div className="ie_previewbtn_root" style={{ position: 'relative', height: 'max-content', margin: 0, right: 0 }}>
            <button className="scedit_savingbtn" style={{ margin: 0, visibility: 'hidden' }} disabled={blocksave} onClick={save}>Save</button>
        </div>
    </div>

    const bar_m_dom = <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'hidden' }}>
        <div style={{ width: '100%', padding: '0px 10px', height: 60, borderBottom: '1px solid #dddddd', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: 'max-content', userSelect: 'none' }}>
            <button className="planning_backicon" onClick={() => { setReturnhome(true) }}>{ARROWLEFT_ICON}</button>
        </div>
    </div>

    const topbar = { 'WEB': bar_wt_dom, 'TABLET': bar_wt_dom, 'MOBILE': bar_m_dom }[device]

    const resetProtaldata = () => {
        setPortalstate('')
        setPortalresult('')
        setPortaltext('')
        setPortalmsgs({})
        setPortaltempdata({})
        setCurrsectionid('')
        setCurritemid('')
        setDeletemod(false)
        setDeletesectiongate(false)
    }

    const portal_update = () => {

        let api
        let data
        if (portalstate === 'ADDITEM') {
            api = UPDATE_BUDGETADDITEM
            data = {
                update_id,
                budget_id: currbudgetid,
                section_id: currsectionid,
                itemname: portaltext,
                ...portaltempdata
            }
        }
        else if (portalstate === 'ADDSECTION') {
            api = UPDATE_BUDGETADDSECTION
            data = {
                update_id,
                budget_id: currbudgetid,
                sectionname: portaltext,
                content: portaltempdata['content']
            }
        }
        else if (portalstate === 'EDITBUDGETNAME') {
            api = UPDATE_BUDGETNAME
            data = {
                update_id,
                budget_id: currbudgetid,
                name: portaltext,
            }
        }
        else if (portalstate === 'EDITTOTALBUDGET') {
            api = UPDATE_BUDGETTOTAL
            data = {
                update_id,
                budget_id: currbudgetid,
                budgetstr: portaltext,
            }
        }
        else if (portalstate === 'EDITSECTION') {
            api = UPDATE_BUDGETSECTION
            data = {
                update_id,
                budget_id: currbudgetid,
                section_id: currsectionid,
                sectionname: portaltext,
                content: portaltempdata['content']
            }
        }
        if (portalstate === 'EDITITEM') {
            api = UPDATE_BUDGETITEM
            data = {
                update_id,
                budget_id: currbudgetid,
                item_id: curritemid,
                section_id: currsectionid,
                itemname: portaltext,
                content: portaltempdata['content'],
                ...portaltempdata
            }
        }

        try {
            axios({
                method: 'POST',
                url: api,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: data
            })
                .then((result) => {
                    const { message, returndata, new_update_id } = result.data
                    if (message === 'SUCCESS') {
                        setPortalresult('SUCCESS')

                        if (portalstate === 'ADDITEM') {

                            const { new_itemid, new_createdate } = returndata
                            let new_currbudgetobjs = JSON.parse(JSON.stringify(currbudgetobjs))

                            if (new_currbudgetobjs[currsectionid]['items'] && Object.keys(new_currbudgetobjs[currsectionid]['items']).length === 0) {

                                new_currbudgetobjs[currsectionid]['itemsequence'] = []
                                new_currbudgetobjs[currsectionid]['itemsequence'].push(new_itemid)

                                new_currbudgetobjs[currsectionid]['items'][new_itemid] = {}
                                new_currbudgetobjs[currsectionid]['items'][new_itemid] = { itemid: new_itemid, createdate: new_createdate, itemname: portaltext, ...portaltempdata }

                                setCurrbudgetobjs(new_currbudgetobjs)

                            }
                            else {
                                new_currbudgetobjs[currsectionid]['itemsequence'].push(new_itemid)
                                new_currbudgetobjs[currsectionid]['items'][new_itemid] = { itemid: new_itemid, createdate: new_createdate, itemname: portaltext, ...portaltempdata }
                                setCurrbudgetobjs(new_currbudgetobjs)
                            }

                        }
                        else if (portalstate === 'ADDSECTION') {

                            const { new_sectionid, new_sectionobj } = returndata
                            let new_currbudgetsequence
                            if (currbudgetsequence && currbudgetsequence.length > 0) {
                                new_currbudgetsequence = currbudgetsequence.slice()
                                new_currbudgetsequence.push(new_sectionid)
                            }
                            else if (currbudgetsequence && currbudgetsequence.length === 0) {
                                new_currbudgetsequence = []
                                new_currbudgetsequence.push(new_sectionid)
                            }

                            let new_currbudgetobjs
                            if (currbudgetobjs) {
                                new_currbudgetobjs = JSON.parse(JSON.stringify(currbudgetobjs))
                                new_currbudgetobjs[new_sectionid] = new_sectionobj
                            }
                            else {
                                new_currbudgetobjs = {}
                                new_currbudgetobjs[new_sectionid] = new_sectionobj
                            }

                            setCurrbudgetsequence(new_currbudgetsequence)
                            setCurrbudgetobjs(new_currbudgetobjs)


                            // let currbudgetsequence = new_currbudgetsequence
                            // let currbudgetobjs = new_currbudgetobjs


                            // if (currbudgetsequence
                            //     && currbudgetsequence.length > 0
                            //     // && Object.keys(currbudgetobjs)
                            //     // && Object.keys(currbudgetobjs).length === currbudgetsequence.length
                            // ) {

                            //     var totalcost = 0
                            //     var totalpaid = 0
                            //     var totaldue = 0
                            //     var new_csvData = []
                            //     var currvtobjs_actualcost = [] // chart
                            //     var currvtobjs_amtpaid = [] // chart
                            //     var currvtobjs_amtdue = [] // chart

                            //     new_csvData.push(['', 'actual_cost', 'amount_due', 'amount_paid', 'estimated_cost'])


                            //     for (let i = 0; i < currbudgetsequence.length; i++) {

                            //         const sectionid = currbudgetsequence[i]
                            //         const { sectionname, items, itemsequence, content } = currbudgetobjs[sectionid]

                            //         if (i !== 0) new_csvData.push([''])
                            //         new_csvData.push([sectionname])
                            //         new_csvData.push([content])
                            //         var actualcost_data = 0 // chart
                            //         var amtpaid_data = 0 // chart
                            //         var amtdue_data = 0 // chart

                            //         for (let j = 0; j < itemsequence.length; j++) {
                            //             const itemid = itemsequence[j]
                            //             const { actual_cost = 0, amount_paid = 0, amount_due = 0, estimated_cost, itemname } = items[itemid]
                            //             var row = [itemname, actual_cost, amount_due, amount_paid, estimated_cost]
                            //             new_csvData.push(row)

                            //             var actualcost = (actual_cost.length === 0 ? 0 : parseInt(actual_cost, 10))
                            //             var amtpaid = (amount_paid.length === 0 ? 0 : parseInt(amount_paid, 10))
                            //             var amtdue = (amount_due.length === 0 ? 0 : parseInt(amount_due, 10))

                            //             actualcost_data = actualcost_data + actualcost // chart
                            //             amtpaid_data = amtpaid_data + amtpaid// chart 
                            //             amtdue_data = amtdue_data + amtdue// chart 

                            //             totalcost = totalcost + actualcost
                            //             totalpaid = totalpaid + amtpaid
                            //             totaldue = totaldue + amtdue

                            //         }
                            //         currvtobjs_actualcost.push(actualcost_data)// chart
                            //         currvtobjs_amtpaid.push(amtpaid_data)// chart
                            //         currvtobjs_amtdue.push(amtdue_data)// chart
                            //     }

                            //     // check if currvtobjs_actualcost is all 0? if is all 0s then dont render graph
                            //     var allzeroes = CheckArrayZeroes(currvtobjs_actualcost)
                            //     if (!allzeroes) setActualcost(currvtobjs_actualcost) // chart
                            //     else setActualcost([])

                            //     allzeroes = CheckArrayZeroes(currvtobjs_amtpaid)
                            //     if (!allzeroes) setAmtpaid(currvtobjs_amtpaid) // chart
                            //     else setAmtpaid([])

                            //     allzeroes = CheckArrayZeroes(currvtobjs_amtdue)
                            //     if (!allzeroes) setAmtdue(currvtobjs_amtdue) // chart
                            //     else setAmtdue([])

                            //     setTotalcost(totalcost)
                            //     setTotalpaid(totalpaid)
                            //     setTotaldue(totaldue)
                            //     setCsvdata(new_csvData)
                            // }
                        }
                        else if (portalstate === 'EDITBUDGETNAME') {
                            setCurrbudgetname(portaltext)
                        }
                        else if (portalstate === 'EDITTOTALBUDGET') {
                            setCurrbudgetstr(portaltext)
                        }
                        else if (portalstate === 'EDITSECTION') {
                            let new_currbudgetobjs = JSON.parse(JSON.stringify(currbudgetobjs))
                            new_currbudgetobjs[currsectionid]['sectionname'] = portaltext
                            new_currbudgetobjs[currsectionid]['content'] = portaltempdata['content']
                            setCurrbudgetobjs(new_currbudgetobjs)
                        }
                        else if (portalstate === 'EDITITEM') {
                            let new_currbudgetobjs = JSON.parse(JSON.stringify(currbudgetobjs))
                            const { estimated_cost, actual_cost, amount_due, amount_paid, content } = portaltempdata

                            new_currbudgetobjs[currsectionid]['items'][curritemid]['itemname'] = portaltext
                            new_currbudgetobjs[currsectionid]['items'][curritemid]['estimated_cost'] = estimated_cost
                            new_currbudgetobjs[currsectionid]['items'][curritemid]['actual_cost'] = actual_cost
                            new_currbudgetobjs[currsectionid]['items'][curritemid]['amount_paid'] = amount_due
                            new_currbudgetobjs[currsectionid]['items'][curritemid]['amount_due'] = amount_paid
                            new_currbudgetobjs[currsectionid]['items'][curritemid]['content'] = content

                            setCurrbudgetobjs(new_currbudgetobjs)
                        }

                        setUpdate_id(new_update_id)
                        resetProtaldata()
                    }
                    else if (message === 'CHECKLISTDOUBLE') {
                        refresh()
                    }
                })
                .catch((e) => { })
        }
        catch (e) {
        }
    }

    const portal_archive = () => {

    }

    const portal_success = () => {
        setPortalstate('')
    }

    const portal_ok = () => {

    }

    const portal_templatecancel = () => {
        setOpentemplate(false);
        setTemplatemod(false);
        setTemplatekey('');
    }

    const portal_templatecfm = () => {
        const value = templatekey
        try {
            axios({
                method: 'POST',
                url: UPDATE_BUDGETCHANGETEMPLATE,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: {
                    update_id,
                    budget_id: currbudgetid,
                    template: value,
                }
            }).then((result) => {
                const { message, new_update_id, new_currbudgetobjs, new_currbudgetstr, new_currbudgetsequence, new_name } = result.data
                console.log('result.data', result.data)
                if (message === 'SUCCESS') {
                    setCurrbudgetobjs(JSON.parse(JSON.stringify(new_currbudgetobjs)))
                    setCurrbudgetsequence(new_currbudgetsequence)
                    setCurrbudgetstr(new_currbudgetstr)
                    setUpdate_id(new_update_id)
                    setCurrbudgetname(new_name)
                    setOpentemplate(false)
                    setTemplatemod(false)
                    setTemplatekey('')
                }
                else if (message === 'CHECKLISTDOUBLE') {
                    refresh()
                }
            }).catch((e) => {
                refresh()
            })
        }
        catch (e) { }
    }

    const portal_templatecfmcancel = () => {
        setTemplatemod(false)
        setTemplatekey('')
    }

    const portal_assigntemplate = (e) => {
        setTemplatemod(true)
        setTemplatekey(e.target.value)
    }

    const portal_settingcancel = () => {
        setOpenbudgetsetting(false)
    }

    const update_portalposition = (e) => {
        const { value } = e.target
        let new_portaltempsettingdata = JSON.parse(JSON.stringify(portaltempsettingdata))
        new_portaltempsettingdata['placement'] = value
        setPortaltempsettingdata(new_portaltempsettingdata)
    }

    const update_portalmode = (e) => {
        const { value } = e.target
        let new_portaltempsettingdata = JSON.parse(JSON.stringify(portaltempsettingdata))
        new_portaltempsettingdata['mode'] = new_portaltempsettingdata['mode'] === 0 ? 1 : 0
        setPortaltempsettingdata(new_portaltempsettingdata)
    }

    const update_portalshowtrash = (e) => {
        let new_portaltempsettingdata = JSON.parse(JSON.stringify(portaltempsettingdata))
        new_portaltempsettingdata['showtrash'] = !new_portaltempsettingdata['showtrash']
        setPortaltempsettingdata(new_portaltempsettingdata)
    }

    const template_portal = <Portalmodal
        animation={device === 'MOBILE' ? "" : "right_left"}
        portaltype={'commonportal'}
        open={opentemplate}
        onClose={portal_templatecancel}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalleftcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className='Rsvpexplorer_portal'>
            <div className='Rsvpexplorer_portaltitle'>Template</div>
            {templatemod
                ? <div className='Rsvpexplorer_portalcontent' style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {/* <div className="Checklist_deletetext">By clicking confirm, you acknowledge that your current budget will be completely wiped out. Archive will remain untouched.</div> */}
                    <div className="Checklist_deletetext">By clicking confirm, you acknowledge that your current budget will be completely wiped out and replaced with a new one.</div>
                    <div className="Checklist_deletesectionroot">
                        <button
                            className="Checklist_deletesectioncancel"
                            onClick={portal_templatecfmcancel}
                        >
                            Cancel
                        </button>
                        <button
                            className="Checklist_deletesectioncfm"
                            onClick={portal_templatecfm}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
                : <div className='Rsvpexplorer_portalcontent' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <button className="Checklist_templatebtn" onClick={portal_assigntemplate} value='TRADITIONAL_CHINESE'>{'Chinese'} </button>
                    {/* <button className="Checklist_templatebtn" onClick={portal_assigntemplate} value='TRADITIONAL_MUSLIM'>{'Muslim'}</button> */}
                    <button className="Checklist_templatebtn" style={{ backgroundColor: 'var(--subgrey)', color: 'var(--maindark)' }} value='TRADITIONAL_MUSLIM'>{'Muslim ( Coming Soon! )'}</button>
                    <button className="Checklist_templatebtn" style={{ backgroundColor: 'var(--subgrey)', color: 'var(--maindark)' }} value='TRADITIONAL_HINDUS'>{'Hindus ( Coming Soon! )'}</button>
                </div>
            }
        </div>
    </Portalmodal>

    const delete_item_or_section = (portalstate) => {
        try {
            axios({
                method: 'POST',
                url: portalstate === 'EDITITEM' ? REMOVE_BUDGETDELETEITEM : REMOVE_BUDGETDELETESECTION,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: {
                    update_id,
                    budget_id: currbudgetid,
                    section_id: currsectionid,
                    item_id: curritemid,
                }
            }).then((result) => {
                const { message, new_update_id } = result.data
                if (message === 'SUCCESS') {
                    var local_currbudgetobjs = JSON.parse(JSON.stringify(currbudgetobjs))

                    if (portalstate === 'EDITITEM') {
                        delete local_currbudgetobjs[currsectionid]['items'][curritemid]
                        delete local_currbudgetobjs[currsectionid]['itemsequence'].splice(local_currbudgetobjs[currsectionid]['itemsequence'].indexOf(curritemid), 1)
                    }
                    else {
                        delete local_currbudgetobjs[currsectionid]
                        let new_currbudgetsequence = currbudgetsequence.slice()
                        let index = currbudgetsequence.indexOf(currsectionid)
                        new_currbudgetsequence.splice(index, 1)
                        setCurrbudgetsequence(new_currbudgetsequence)
                    }

                    setCurrbudgetobjs(local_currbudgetobjs)
                    setUpdate_id(new_update_id)
                    setDeletesectiongate(false)
                    resetProtaldata()
                }
                else if (message === 'CHECKLISTDOUBLE') { refresh() }
            }).catch((e) => {
                refresh()
            })
        }
        catch (e) {
            refresh()
        }
    }

    // part portal of dom element
    const edit_portaldelete_dom = <div className="Checklistedit_deleteroot">
        {deletesectiongate
            ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginBottom: 5, color: 'var(--mainpink)', textAlign: 'center' }}>Once you delete this, there is no going back. Please be certain.</div>
                <div className="Checklist_deletesectionroot" style={{ placeContent: 'center' }}>
                    <button
                        className="Checklist_deletesectioncancel"
                        onClick={() => { setDeletesectiongate(false) }}
                    >
                        Cancel
                    </button>
                    <button
                        className="Checklist_deletesectioncfm"
                        onClick={() => { delete_item_or_section(portalstate) }}
                    >
                        Confirm Delete
                    </button>
                </div>
            </div>

            : <button
                className="Checklistedit_delete"
                style={{ border: '1px dashed var(--maindark)' }}
                onClick={() => { setDeletesectiongate(true) }}
            >
                {iconwrapper(TRASH_ICON, { width: itemiconsize, height: itemiconsize, display: 'flex', placeContent: 'center' })}
            </button>}
    </div>

    const edit_portal = <Portalmodal
        animation={device === 'MOBILE' ? "" : "right_left"}
        portaltype={'commonportal'}
        open={portalstate.length > 0}
        onClose={resetProtaldata}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalleftcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className='Rsvpexplorer_portal'>
            <div className='Rsvpexplorer_portaltitle'>
                {{
                    EDITBUDGETNAME: 'Edit Budget Name',
                    EDITTOTALBUDGET: 'Edit Total Budget',
                    EDITSECTION: 'Edit Section',
                    ADDSECTION: 'Add New Section',
                    EDITITEM: 'Edit Item',
                    ADDITEM: 'Add New Item',
                }[portalstate]}
            </div>
            <div className='Rsvpexplorer_portalcontent' style={{ height: '100%' }}>
                {{
                    'DEFAULT': <div className='Rsvpqnexplorer_portalroot'>
                        <div className='Rsvpexplorer_portalcontent'>

                            {portalstate === 'EDITITEM' || portalstate === 'ADDITEM'
                                ? <div className="ig_lblinputroot" >
                                    {partialtitle_dom(`Section`, {})}
                                    <div className="ig_input" style={{ display: 'flex', alignItems: 'center', backgroundColor: 'var(--maindark)', color: '#ffffff', border: '1px solid var(--maindark)' }}>
                                        {currbudgetobjs && currbudgetobjs[currsectionid] && currbudgetobjs[currsectionid].sectionname ? currbudgetobjs[currsectionid].sectionname : ''}
                                    </div>
                                </div>
                                : null}

                            {input_dom(
                                {
                                    EDITBUDGETNAME: 'Budget Name',
                                    EDITTOTALBUDGET: 'Total Budget',
                                    EDITSECTION: 'Section Name',
                                    ADDSECTION: 'Section Name',
                                    EDITITEM: 'Item Name',
                                    ADDITEM: 'Item Name',
                                }[portalstate],
                                ``,
                                portaltext,
                                (e) => {
                                    if (portalstate === 'EDITTOTALBUDGET') {
                                        if (Number(e.target.value) >= 0) {
                                            setPortaltext(e.target.value)
                                        }
                                    }
                                    else {
                                        setPortaltext(e.target.value);
                                    }
                                    setPortalmsgs({})
                                },
                                portalstate,
                                portalmsgs,
                                null,
                                null,
                                {}
                            )}
                            {{
                                ADDITEM: <div>
                                    {input_dom(
                                        'Actual Cost',
                                        ``,
                                        portaltempdata['actual_cost'],
                                        (e) => {
                                            if (Number(e.target.value) >= 0) {
                                                let new_additemdata = JSON.parse(JSON.stringify(portaltempdata))
                                                new_additemdata['actual_cost'] = e.target.value
                                                setPortaltempdata(new_additemdata)
                                            }

                                        },
                                        portaltempdata['actual_cost'],
                                        portalmsgs,
                                        null,
                                        null,
                                        {}
                                    )}

                                    {input_dom(
                                        'Amount Paid',
                                        ``,
                                        portaltempdata['amount_paid'],
                                        (e) => {
                                            if (Number(e.target.value) >= 0) {
                                                let new_additemdata = JSON.parse(JSON.stringify(portaltempdata))
                                                new_additemdata['amount_paid'] = e.target.value
                                                setPortaltempdata(new_additemdata)
                                            }
                                        },
                                        portaltempdata['amount_paid'],
                                        portalmsgs,
                                        null,
                                        null,
                                        {}
                                    )}

                                    {input_dom(
                                        'Amount Due',
                                        ``,
                                        portaltempdata['amount_due'],
                                        (e) => {
                                            if (Number(e.target.value) >= 0) {
                                                let new_additemdata = JSON.parse(JSON.stringify(portaltempdata))
                                                new_additemdata['amount_due'] = e.target.value
                                                setPortaltempdata(new_additemdata)
                                            }
                                        },
                                        portaltempdata['amount_due'],
                                        portalmsgs,
                                        null,
                                        null,
                                        {}
                                    )}


                                    {textarea_dom(
                                        'Content',
                                        ``,
                                        portaltempdata['content'],
                                        (e) => {
                                            if (Number(e.target.value) >= 0) {
                                                let new_additemdata = JSON.parse(JSON.stringify(portaltempdata))
                                                new_additemdata['content'] = e.target.value
                                                setPortaltempdata(new_additemdata)
                                            }
                                        },
                                        'content_id',
                                        portalmsgs,
                                        null,
                                        null,
                                        {}
                                    )}

                                </div>,

                                // Edit section name & delete item
                                EDITITEM: <div>

                                    {input_dom(
                                        'Estimated Cost',
                                        ``,
                                        portaltempdata['estimated_cost'],
                                        (e) => {
                                            if (Number(e.target.value) >= 0) {
                                                let new_additemdata = JSON.parse(JSON.stringify(portaltempdata))
                                                new_additemdata['estimated_cost'] = e.target.value
                                                setPortaltempdata(new_additemdata)
                                            }
                                        },
                                        portaltempdata['estimated_cost'],
                                        portalmsgs,
                                        null,
                                        null,
                                        {}
                                    )}

                                    {input_dom(
                                        'Actual Cost',
                                        ``,
                                        portaltempdata['actual_cost'],
                                        (e) => {
                                            if (Number(e.target.value) >= 0) {
                                                let new_additemdata = JSON.parse(JSON.stringify(portaltempdata))
                                                new_additemdata['actual_cost'] = e.target.value
                                                setPortaltempdata(new_additemdata)
                                            }
                                        },
                                        portaltempdata['actual_cost'],
                                        portalmsgs,
                                        null,
                                        null,
                                        {}
                                    )}

                                    {input_dom(
                                        'Amount Paid',
                                        ``,
                                        portaltempdata['amount_paid'],
                                        (e) => {
                                            if (Number(e.target.value) >= 0) {
                                                let new_additemdata = JSON.parse(JSON.stringify(portaltempdata))
                                                new_additemdata['amount_paid'] = e.target.value
                                                setPortaltempdata(new_additemdata)
                                            }
                                        },
                                        portaltempdata['amount_paid'],
                                        portalmsgs,
                                        null,
                                        null,
                                        {}
                                    )}

                                    {input_dom(
                                        'Amount Due',
                                        ``,
                                        portaltempdata['amount_due'],
                                        (e) => {
                                            if (Number(e.target.value) >= 0) {
                                                let new_additemdata = JSON.parse(JSON.stringify(portaltempdata))
                                                new_additemdata['amount_due'] = e.target.value
                                                setPortaltempdata(new_additemdata)
                                            }
                                        },
                                        portaltempdata['amount_due'],
                                        portalmsgs,
                                        null,
                                        null,
                                        {}
                                    )}

                                    {textarea_dom(
                                        'Note',
                                        ``,
                                        portaltempdata['content'],
                                        (e) => {
                                            let new_additemdata = JSON.parse(JSON.stringify(portaltempdata))
                                            new_additemdata['content'] = e.target.value
                                            setPortaltempdata(new_additemdata)
                                        },
                                        'content_id',
                                        portalmsgs,
                                        null,
                                        null,
                                        {}
                                    )}

                                    {edit_portaldelete_dom}
                                </div>,

                                // Edit section name & delete section 
                                EDITSECTION: <div>
                                    {textarea_dom(
                                        'Note',
                                        ``,
                                        portaltempdata['content'],
                                        (e) => {
                                            let new_additemdata = JSON.parse(JSON.stringify(portaltempdata))
                                            new_additemdata['content'] = e.target.value
                                            setPortaltempdata(new_additemdata)
                                        },
                                        'content_id',
                                        portalmsgs,
                                        null,
                                        null,
                                        {}
                                    )}
                                    {edit_portaldelete_dom}
                                </div>,

                                // content only
                                ADDSECTION: <div>
                                    {textarea_dom(
                                        'Content',
                                        ``,
                                        portaltempdata['content'],
                                        (e) => {
                                            let new_additemdata = JSON.parse(JSON.stringify(portaltempdata))
                                            new_additemdata['content'] = e.target.value
                                            setPortaltempdata(new_additemdata)
                                        },
                                        'content_id',
                                        portalmsgs,
                                        null,
                                        null,
                                        {}
                                    )}
                                </div>
                            }[portalstate]}
                        </div>

                        {{
                            EDITBUDGETNAME: <button
                                className='Rsvpexplorer_portalsave'
                                onClick={portal_update} >
                                Update
                            </button>,
                            EDITTOTALBUDGET: <button
                                className='Rsvpexplorer_portalsave'
                                onClick={portal_update} >
                                Update
                            </button>,
                            ADDITEM: <div className="gl_portalbtnrow">
                                <button
                                    style={{ borderRadius: 10, backgroundColor: '#ffffff', border: '1px solid var(--maindark)', color: 'var(--maindark)', fontSize: 18, padding: '0px 10px' }}
                                    onClick={portal_archive}
                                >
                                    Archive
                                </button>
                                <button
                                    className='gl_portalupdatebtn'
                                    onClick={portal_update}
                                >
                                    Update
                                </button>
                            </div>,
                            EDITITEM: <div className="gl_portalbtnrow">
                                {/* <button
                                    style={{ borderRadius: 10, backgroundColor: '#ffffff', border: '1px solid var(--maindark)', color: 'var(--maindark)', fontSize: 18, padding: '0px 10px' }}
                                    onClick={portal_archive}
                                >
                                    Archive
                                </button> */}
                                <button
                                    className='gl_portalupdatebtn'
                                    onClick={portal_update}
                                >
                                    Update
                                </button>
                            </div>,
                            EDITSECTION: <button
                                className='Rsvpexplorer_portalsave'
                                onClick={portal_update} >
                                Update
                            </button>,
                            ADDSECTION: <button
                                className='Rsvpexplorer_portalsave'
                                onClick={portal_update} >
                                Create
                            </button>
                        }[portalstate]}
                    </div>,
                    'SUCCESS': <div className='Rsvpqnexplorer_portalroot'>
                        <Success txt={'Successfully Updated.'} onClick={portal_success} />
                    </div>,
                    'FAILURE': <div className='Rsvpqnexplorer_portalroot'>
                        <div className='Rsvpqnexplorer_portalmaincontenttitle'>Failed to update.</div>
                        <button
                            className='Rsvpexplorer_portalsave'
                            onClick={portal_ok}
                        >
                            OK
                        </button>
                    </div>,
                    'DONE': <div className='Rsvpqnexplorer_portalroot'>
                        <div className='Rsvpqnexplorer_portalmaincontenttitle'>Update successfully</div>
                        <button
                            className='Rsvpexplorer_portalsave'
                            onClick={portal_ok}
                        >
                            OK
                        </button>
                    </div>
                }[portalresult]}
            </div>
        </div>

    </Portalmodal >

    const setting_portal = <Portalmodal
        animation={device === 'MOBILE' ? "" : "right_left"}
        portaltype={'commonportal'}
        open={openbudgetsetting}
        onClose={portal_settingcancel}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalleftcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className='Rsvpexplorer_portal'>
            <div className='Rsvpexplorer_portaltitle'>Setting</div>
            <div className='Rsvpexplorer_portalcontent' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div className="Checklist_dateroot">
                    {partialtitle_dom(`Position ( Non-Mobile )`, {})}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', placeContent: 'start', marginBottom: 15 }}>
                            <button
                                key={portaltempsettingdata['placement'] + '2'}
                                onClick={update_portalposition}
                                value={'graphspreadsheet'}
                                className={`Budgetsettingroot${portaltempsettingdata['placement'] === 'graphspreadsheet' ? '_graphspreadsheet' : ''}`}
                                style={{ borderRadius: '5px 0px 0px 5px', borderRight: 'none' }}
                            >
                                Graph - Spreadsheet
                            </button>
                            <button
                                key={portaltempsettingdata['placement'] + '1'}
                                onClick={update_portalposition}
                                value={'spreadsheetgraph'}
                                className={`Budgetsettingroot${portaltempsettingdata['placement'] === 'spreadsheetgraph' ? '_spreadsheetgraph' : ''}`}
                                style={{ borderRadius: '0px 5px 5px 0px' }}
                            >
                                Spreadsheet - Graph
                            </button>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', placeContent: 'start', marginBottom: 15 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {partialtitle_dom(`Mode`, {})}
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', placeContent: 'start' }}>
                                    <button
                                        onClick={update_portalmode}
                                        value={0}
                                        className={`Budgetsettingroot${portaltempsettingdata['mode'] === 0 ? '_graphspreadsheet' : ''}`}
                                        style={{ borderRadius: '5px 0px 0px 5px', borderRight: 'none' }}
                                    >
                                        Normal
                                    </button>
                                    <button
                                        onClick={update_portalmode}
                                        value={1}
                                        className={`Budgetsettingroot${portaltempsettingdata['mode'] === 1 ? '_spreadsheetgraph' : ''}`}
                                        style={{ borderRadius: '0px 5px 5px 0px' }}
                                    >
                                        Advanced
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', placeContent: 'start', marginBottom: 15 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {partialtitle_dom(`Trash`, {})}
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', placeContent: 'start' }}>
                                    <button
                                        onClick={update_portalshowtrash}
                                        value={true}
                                        className={`Budgetsettingroot${portaltempsettingdata['showtrash'] ? '_graphspreadsheet' : ''}`}
                                        style={{ borderRadius: '5px 0px 0px 5px', borderRight: 'none' }}
                                    >
                                        Show
                                    </button>
                                    <button
                                        onClick={update_portalshowtrash}
                                        value={false}
                                        className={`Budgetsettingroot${!portaltempsettingdata['showtrash'] ? '_spreadsheetgraph' : ''}`}
                                        style={{ borderRadius: '0px 5px 5px 0px' }}
                                    >
                                        Hide
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='Rsvpexplorer_portalcontent' style={{ height: '100%' }}>
                <button
                    className='Rsvpexplorer_portalsave'
                    onClick={() => {

                        try {
                            axios({
                                method: 'POST',
                                url: UPDATE_BUDGETSETTINGS,
                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                data: {
                                    update_id,
                                    budget_id: currbudgetid,
                                    budgetsettings: portaltempsettingdata
                                }
                            })
                                .then((result) => {
                                    const { message, new_update_id } = result.data
                                    if (message === 'SUCCESS') {
                                        setPortalresult('SUCCESS')
                                        setBudgetsettings(JSON.parse(JSON.stringify(portaltempsettingdata)))
                                        setUpdate_id(new_update_id)
                                        resetProtaldata()
                                        portal_settingcancel()
                                    }
                                })
                                .catch((e) => { })
                        }
                        catch (e) {
                        }
                    }} >
                    Update
                </button>
            </div>

        </div>
    </Portalmodal>

    const chartplacementstyle = budgetsettings['placement'] === 'graphspreadsheet' ? { left: 0 } : { right: 0 }
    const spreadplacementstyle = budgetsettings['placement'] === 'graphspreadsheet' ? { right: 0 } : { left: 0 }

    const budget_graph_web = <div // GRAPH
        className="Budgetedit_graph_web"
        style={{ ...chartplacementstyle, backgroundColor: (currbudgetsequence && currbudgetsequence.length > 0) ? '#ffffff' : 'var(--subgrey)', margin: '0px 10px 10px 0px ' }}
    >
        {currbudgetsequence && currbudgetsequence.length > 0
            ? <Chart
                xdim={700}
                ydim={500}
                margin={{ top: 180, bottom: 180, left: 120, right: 120 }}
                hover={hover}
                mode={budgetsettings['mode']}
                alldata={chartbudgetobjs}
                ids={currbudgetsequence}
                xdata={chartbudgetobjs.map((item, i) => {
                    const { sectionname } = item

                    // update sectionname to detect if there is same sectionname, if yes add index behind
                    var sectionname_count = []
                    for (let j = 0; j < Object.keys(chartbudgetobjs).length; j++) {
                        if (chartbudgetobjs[j].sectionname === sectionname) {
                            sectionname_count.push(j)
                        }
                    }

                    if (sectionname_count.length > 1) {
                        return sectionname + `${sectionname_count.indexOf(i) === 0 ? '' : ' ( ' + sectionname_count.indexOf(i) + ' )'}`
                    }
                    else {
                        return sectionname
                    }

                })}
                ydata={chart === 'Total'
                    ? actualcost
                    : chart === 'Paid'
                        ? amtpaid
                        : amtdue}

                title={{
                    'Total': 'Total',
                    'Paid': 'Amount Paid',
                    'Due': 'Amount Due'
                }[chart]}

                yaxistitle={'Amount'}
            />
            : null}

        {((currbudgetsequence && currbudgetsequence.length) > 0)
            ? <div style={{ display: 'flex', flexDirection: 'row', placeContent: 'center', marginBottom: 50 }}>
                <button className={`Budgetedit_chartbtn Budgetedit_chartbtn${chart === 'Total' ? 'selected' : ''}`} onClick={() => { setChart('Total') }}>Total Cost</button>
                <button className={`Budgetedit_chartbtn Budgetedit_chartbtn${chart === 'Paid' ? 'selected' : ''}`} onClick={() => { setChart('Paid') }}>Amount Paid</button>
                <button className={`Budgetedit_chartbtn Budgetedit_chartbtn${chart === 'Due' ? 'selected' : ''}`} onClick={() => { setChart('Due') }}>Amount Due</button>
            </div>
            : null}
    </div>


    // GRAPH
    const budget_graph_otherdevice = (currbudgetsequence && currbudgetsequence.length) > 0
        ? <div className="Budgetedit_graph_others" >
            <Chart
                xdim={700}
                ydim={500}
                margin={{ top: 180, bottom: 180, left: 120, right: 120 }}
                hover={hover}
                mode={budgetsettings['mode']}
                alldata={chartbudgetobjs}
                ids={currbudgetsequence}
                xdata={chartbudgetobjs.map((item, i) => {
                    const { sectionname } = item

                    // update sectionname to detect if there is same sectionname, if yes add index behind
                    var sectionname_count = []
                    for (let j = 0; j < Object.keys(chartbudgetobjs).length; j++) {
                        if (chartbudgetobjs[j].sectionname === sectionname) {
                            sectionname_count.push(j)
                        }
                    }

                    if (sectionname_count.length > 1) {
                        return sectionname + `${sectionname_count.indexOf(i) === 0 ? '' : ' ( ' + sectionname_count.indexOf(i) + ' )'}`
                    }
                    else {
                        return sectionname
                    }

                })}
                ydata={chart === 'Total'
                    ? actualcost
                    : chart === 'Paid'
                        ? amtpaid
                        : amtdue}


                title={{
                    'Total': 'Total',
                    'Paid': 'Amount Paid',
                    'Due': 'Amount Due'
                }[chart]}
                yaxistitle={'Amount'}
            />
            {((currbudgetsequence && currbudgetsequence.length) > 0)
                ? <div style={{ display: 'flex', flexDirection: 'row', placeContent: 'center', marginBottom: 50 }}>
                    <button className={`Budgetedit_chartbtn Budgetedit_chartbtn${chart === 'Total' ? 'selected' : ''}`} onClick={() => { setChart('Total') }}>Total Cost</button>
                    <button className={`Budgetedit_chartbtn Budgetedit_chartbtn${chart === 'Paid' ? 'selected' : ''}`} onClick={() => { setChart('Paid') }}>Amount Paid</button>
                    <button className={`Budgetedit_chartbtn Budgetedit_chartbtn${chart === 'Due' ? 'selected' : ''}`} onClick={() => { setChart('Due') }}>Amount Due</button>
                </div>
                : null}

        </div>
        : null

    const onDragend = (result) => {
        // draggableId : section or item
        if (!result.destination) return;
        if (result.destination.index === result.source.index && result.destination.droppableId === result.source.droppableId) return; // drag and drop on the same pos
        if (!dragsavemode) {
            setDragsavemode(true)
            setClonebudgetobjs(JSON.parse(JSON.stringify(currbudgetobjs)))
            setClonebudgetsequence(currbudgetsequence.slice())
        }

        const { source, destination } = result

        if (source.droppableId === 'section' && destination.droppableId === 'section') {
            let new_currbudgetsequence = currbudgetsequence.slice()
            let sourcesection = currbudgetsequence[source.index]
            new_currbudgetsequence.splice(source.index, 1)
            new_currbudgetsequence.splice(destination.index, 0, sourcesection)
            setCurrbudgetsequence(new_currbudgetsequence)
        }
        else {

            const src_droppableId_obj = source.droppableId.split('_')
            const src_type = src_droppableId_obj[0]
            const src_section_id = src_droppableId_obj[1]

            const dest_droppableId_obj = destination.droppableId.split('_')
            const dest_type = dest_droppableId_obj[0]
            const dest_section_id = dest_droppableId_obj[1]

            if (src_type === 'item' && dest_type === 'item') { // is a item switching between

                let new_currbudgetobjs = JSON.parse(JSON.stringify(currbudgetobjs))
                const itemid = new_currbudgetobjs[src_section_id]['itemsequence'][source.index]
                const item = JSON.parse(JSON.stringify(new_currbudgetobjs[src_section_id]['items'][itemid]))

                new_currbudgetobjs[src_section_id]['itemsequence'].splice(source.index, 1)
                delete new_currbudgetobjs[src_section_id]['items'][itemid]


                //check if destination is empty or not?
                if (new_currbudgetobjs[dest_section_id]['itemsequence'].length > 0) {
                    new_currbudgetobjs[dest_section_id]['itemsequence'].splice(destination.index, 0, itemid)
                    new_currbudgetobjs[dest_section_id]['items'][itemid] = item
                }
                else {
                    new_currbudgetobjs[dest_section_id]['itemsequence'].push(itemid)
                    new_currbudgetobjs[dest_section_id]['items'] = {}
                    new_currbudgetobjs[dest_section_id]['items'][itemid] = item
                }

                setCurrbudgetobjs(new_currbudgetobjs)
            }

        }



    }

    const Collapsible = (items, itemsequence, sectionid, index) => {

        const notebtn = <div
            className="Checklist_additem"
            style={{ margin: '20px 5px 10px 0px' }}
            onClick={() => {
                if (!dragsavemode) {
                    setPortalstate('EDITSECTION')
                    setPortaltext(currbudgetobjs[sectionid].sectionname)
                    setCurrsectionid(sectionid)
                    setPortaltempdata({
                        content: currbudgetobjs[sectionid].content,
                    })
                }

            }}
        >
            Note
        </div>

        const addbtn = <div
            className="Checklist_additem"
            onClick={() => {
                if (!dragsavemode) {
                    setPortalstate('ADDITEM');
                    setCurrsectionid(sectionid)
                    setPortaltempdata({
                        'estimated_cost': 0,
                        'actual_cost': 0,
                        'amount_paid': 0,
                        'amount_due': 0,
                        'content': ''
                    })
                }
            }}>
            New Expense
        </div>

        var local_ac = 0
        var local_ap = 0
        var local_ad = 0
        if (budgetsettings['mode'] === 0) {
            for (let i = 0; i < itemsequence.length; i++) {
                const { actual_cost } = items[itemsequence[i]]
                local_ac += parseInt(actual_cost, 10)
            }
        }
        else {
            for (let i = 0; i < itemsequence.length; i++) {
                const { actual_cost, amount_paid, amount_due } = items[itemsequence[i]]
                local_ac += parseInt(actual_cost, 10)
                local_ap += parseInt(amount_paid, 10)
                local_ad += parseInt(amount_due, 10)
            }
        }

        const budgetitemselected = (data, itemid, sectionid) => {
            if (!dragsavemode) {
                const { estimated_cost, actual_cost, amount_paid, amount_due, itemname, content } = data
                setPortalstate('EDITITEM');
                setPortaltext(itemname)
                setPortaltempdata({ estimated_cost, actual_cost, amount_paid, amount_due, content })
                setCurrsectionid(sectionid)
                setCurritemid(itemid)
            }
        }

        return <div className='Budgetedit_collapsibleroot'>
            {budgetsettings['mode'] === 1
                ? <div style={{ display: 'flex', flexDirection: 'row', padding: '10px 5px 0px 5px' }}>
                    <div style={{ ...exceltitle_root, width: title_ratio, fontSize: TITLE_DEVICE_FS_MAP[device] }}>Item</div>
                    <div style={{ ...exceltitle_root, width: price_ratio, fontSize: TITLE_DEVICE_FS_MAP[device], borderLeft: 'none' }}>Actual Cost</div>
                    <div style={{ ...exceltitle_root, width: amtpaid_ratio, fontSize: TITLE_DEVICE_FS_MAP[device], borderLeft: 'none' }}>Amount Paid</div>
                    <div style={{ ...exceltitle_root, width: amtdue_ratio, fontSize: TITLE_DEVICE_FS_MAP[device], borderLeft: 'none' }}>Amount Due</div>
                    <div style={{ width: 5 }} />
                    {budgetsettings['showtrash'] ? iconwrapper(TRASH_ICON, { width: 40, height: 40, display: 'flex', placeContent: 'center', margin: '0px 0px 0px 0px', color: 'transparent' }) : null}
                </div>
                : <div style={{ display: 'flex', flexDirection: 'row', padding: '10px 5px 0px 5px' }}>
                    <div style={{ ...exceltitle_root, width: title_ratio_1, fontSize: TITLE_DEVICE_FS_MAP[device] }}>Item</div>
                    <div style={{ ...exceltitle_root, width: price_ratio_1, fontSize: TITLE_DEVICE_FS_MAP[device], borderLeft: 'none' }}>Actual Cost</div>
                    <div style={{ width: 5 }} />
                    {budgetsettings['showtrash'] ? iconwrapper(TRASH_ICON, { width: 40, height: 40, display: 'flex', placeContent: 'center', margin: '0px 0px 0px 0px', color: 'transparent' }) : null}
                </div>}
            <Droppable droppableId={`item_${sectionid}`} type="item">
                {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} >
                        {itemsequence && itemsequence.map((key, inner_index) => {
                            const { itemname, actual_cost, amount_paid, amount_due } = items[key] // estimated_cost

                            const delbtn = budgetsettings['showtrash'] && !dragsavemode
                                ? <button
                                    className="Checklist_deledititem"
                                    onClick={() => {
                                        if (!dragsavemode) {
                                            setCurrsectionid(sectionid)
                                            setCurritemid(key)
                                            setDeletemod(true)
                                        }
                                    }}
                                >
                                    {iconwrapper(TRASH_ICON, { width: itemiconsize, height: itemiconsize, display: 'flex', placeContent: 'center' })}
                                </button>
                                : null

                            return (
                                <Draggable key={index + ' primary ' + inner_index} draggableId={index + ' primary ' + inner_index} index={inner_index} type="primary" >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            onMouseEnter={() => { setHoversub(index + 'hoversub' + inner_index) }} onMouseLeave={() => { setHoversub('') }}
                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            className='Checklist_collapsible_item'
                                        >
                                            {budgetsettings['mode'] === 1
                                                ? <div key={inner_index + 'primary'} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px 5px', width: '100%' }}  >
                                                    <div disabled={true} onClick={() => budgetitemselected(items[key], key, sectionid)} style={{ ...exceltitle, width: title_ratio, fontSize: CELL_DEVICE_FS_MAP[device], }} > {itemname}</div>
                                                    <div disabled={true} onClick={() => budgetitemselected(items[key], key, sectionid)} style={{ ...exceltitle, width: price_ratio, fontSize: CELL_DEVICE_FS_MAP[device], borderLeft: 'none' }} > {actual_cost}</div>
                                                    <div disabled={true} onClick={() => budgetitemselected(items[key], key, sectionid)} style={{ ...exceltitle, width: amtpaid_ratio, fontSize: CELL_DEVICE_FS_MAP[device], borderLeft: 'none' }} > {amount_paid}</div>
                                                    <div disabled={true} onClick={() => budgetitemselected(items[key], key, sectionid)} style={{ ...exceltitle, width: amtdue_ratio, fontSize: CELL_DEVICE_FS_MAP[device], borderLeft: 'none' }}   > {amount_due}</div>
                                                    <div style={{ width: 5 }} />
                                                    {delbtn}
                                                </div>
                                                : <div key={inner_index + 'primary'} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px 5px', width: '100%' }}  >
                                                    <div disabled={true} onClick={() => budgetitemselected(items[key], key, sectionid)} style={{ ...exceltitle, width: title_ratio_1, fontSize: CELL_DEVICE_FS_MAP[device], }}  > {itemname}</div>
                                                    <div disabled={true} onClick={() => budgetitemselected(items[key], key, sectionid)} style={{ ...exceltitle, width: price_ratio_1, fontSize: CELL_DEVICE_FS_MAP[device], borderLeft: 'none' }} > {actual_cost}</div>
                                                    <div style={{ width: 5 }} />
                                                    {delbtn}
                                                </div>}
                                        </div>)}
                                </Draggable>
                            )
                        })}

                        {itemsequence && itemsequence.length > 0
                            ? provided.placeholder
                            : <div style={{ height: 100, margin: 5, backgroundColor: '#f8f8f8' }}>{provided.placeholder}</div>}

                    </div>
                )}
            </Droppable>
            <div style={{ width: '100%', height: 1, border: '1px solid #eeee', margin: '10px 0px' }} />
            {budgetsettings['mode'] === 1
                ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px 5px' }} >
                    <div style={{ ...exceltitle, width: title_ratio, fontSize: CELL_DEVICE_FS_MAP[device], backgroundColor: 'white', color: '#707070', border: 'none', fontWeight: 'bold', textAlign: 'right', placeContent: 'flex-end' }}>Total: </div>
                    <div style={{ ...exceltitle, width: price_ratio, fontSize: CELL_DEVICE_FS_MAP[device], backgroundColor: '#f8f8f8', color: '#707070' }}>{local_ac}</div>
                    <div style={{ ...exceltitle, width: amtpaid_ratio, fontSize: CELL_DEVICE_FS_MAP[device], backgroundColor: '#f8f8f8', color: '#707070' }} >{local_ap}</div>
                    <div style={{ ...exceltitle, width: amtdue_ratio, fontSize: CELL_DEVICE_FS_MAP[device], backgroundColor: '#f8f8f8', color: '#707070' }} >{local_ad}</div>
                    <div style={{ width: 5 }} />
                    {budgetsettings['showtrash'] ? iconwrapper(TRASH_ICON, { width: 40, height: 40, display: 'flex', placeContent: 'center', margin: '0px 0px 0px 0px', color: 'transparent' }) : null}
                </div>
                : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px 5px' }} >
                    <div style={{ ...exceltitle, width: title_ratio_1, fontSize: CELL_DEVICE_FS_MAP[device], backgroundColor: 'white', color: '#707070', border: 'none', fontWeight: 'bold', textAlign: 'right', placeContent: 'flex-end' }}>Total : </div>
                    <div style={{ ...exceltitle, width: price_ratio_1, fontSize: CELL_DEVICE_FS_MAP[device], backgroundColor: '#f8f8f8', color: '#707070' }}>{local_ac}</div>
                    <div style={{ width: 5 }} />
                    {budgetsettings['showtrash'] ? iconwrapper(TRASH_ICON, { width: 40, height: 40, display: 'flex', placeContent: 'center', margin: '0px 0px 0px 0px', color: 'transparent' }) : null}
                </div>}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {notebtn}
                    {EMPTYSPACE}
                    {addbtn}
                </div>
            </div>
        </div>
    }

    const Noncollapsable = (itemsequence) => {
        return <div className="Checklist_nonequal">
            {itemsequence.length + ' Item'}
        </div>
    }

    // SPREADSHEET
    const budget_spreadsheet_web = <div
        className="Budgetedit_spreadsheet_web"
        style={{ ...spreadplacementstyle }}

    // style={{ ...spreadplacementstyle, borderTop: (currbudgetsequence && currbudgetsequence.length > 0) ? '' : '1px solid var(--subgrey)', borderLeft:'none' }}
    >
        <div
            className="Checklistedit_title"
            style={{ width: '100%' }}
            onClick={() => {
                setPortalstate('EDITTOTALBUDGET');
                setPortaltext(currbudgetstr)
            }}
        >
            <div style={{ fontSize: 14, color: 'var(--maindark)', marginTop: 5 }}>Budget</div>
            <div>{'$' + currbudgetstr}</div>
        </div>

        <div className="Checklistedit_titlenohover" style={{ width: '100%', marginBottom: 0, borderBottom: 'none' }}>
            <div style={{ fontSize: 14, color: 'var(--maindark)', marginTop: 5 }}>Total Cost</div>
            <div>{'$' + totalcost}</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="Checklistedit_titlenohover" style={{ width: '100%' }}>
                <div style={{ fontSize: 14, color: 'var(--maindark)', marginTop: 5 }}>Amount Due</div>
                <div>{'$' + totaldue}</div>
            </div>
            <div className="Checklistedit_titlenohover" style={{ width: '100%', borderLeft: 'none' }} >
                <div style={{ fontSize: 14, color: 'var(--maindark)', marginTop: 5 }}>Amount Paid</div>
                <div>{'$' + totalpaid}</div>
            </div>

        </div>



        {/* need to solve the id issue */}
        <DragDropContext onDragEnd={onDragend}>
            <Droppable droppableId={'section'} type="section">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {currbudgetsequence.map((id, index) => {
                            if (currbudgetobjs && currbudgetobjs[id]) {
                                const { collapse, items, itemsequence, sectionname } = currbudgetobjs[id] // icon, content,
                                var local_ac = 0
                                for (let i = 0; i < itemsequence.length; i++) {
                                    const itemid = itemsequence[i]
                                    const { actual_cost } = items[itemid]
                                    local_ac += parseInt(actual_cost, 10)
                                }
                                return <Draggable
                                    key={index + ' main ' + index + ' _ ' + id + sectionname}
                                    draggableId={'section_' + index}
                                    index={index}
                                    type="main"
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getMainobjStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            onMouseOver={() => { setHover(id) }}
                                            onMouseLeave={() => { setHover('') }}
                                        >
                                            <div
                                                key={index + 'main'}
                                                className="Checklist_maindrag"
                                            >
                                                <div className={'Checklist_cattitle' + (collapse ? '' : '_withouthover')} >
                                                    <div
                                                        onClick={() => {
                                                            if (!dragsavemode) {
                                                                setPortalstate('EDITSECTION')
                                                                setPortaltext(sectionname)
                                                                setCurrsectionid(id)
                                                                setPortaltempdata({
                                                                    content: currbudgetobjs[id].content,
                                                                })
                                                            }
                                                        }}
                                                        style={{ padding: 5, width: '100%', whiteSpace: 'nowrap', border: 'none', outline: 'none', fontSize: VT_DEVICE_FS_MAP[device] }}
                                                    >
                                                        {sectionname}
                                                    </div>
                                                    <div
                                                        className="Bkmer_expandtab"
                                                        onClick={() => {
                                                            var new_currbudgetobjs = JSON.parse(JSON.stringify(currbudgetobjs))
                                                            new_currbudgetobjs[id]['collapse'] = !collapse
                                                            setCurrbudgetobjs(new_currbudgetobjs)
                                                        }}
                                                    >
                                                        {collapse
                                                            ? iconwrapper(LINEDOWNARROW_ICON, { width: 20, height: 20, display: 'flex' })
                                                            : iconwrapper(LINERIGHTARROW_ICON, { width: 20, height: 20, display: 'flex' })}
                                                    </div>
                                                </div>
                                                {collapse ? null : <div className={"Bkmer_dragprice" + device}>{'$ ' + local_ac}</div>}
                                                {collapse ? Collapsible(items, itemsequence, id, index) : Noncollapsable(itemsequence)}
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            }
                        })}

                        {provided.placeholder}

                    </div>
                )}
            </Droppable>
        </DragDropContext>

        {!dragsavemode
            ? <button
                className="Budgetedit_sectionadd"
                onClick={() => {
                    if (!dragsavemode) {
                        setPortalstate('ADDSECTION')
                        setPortaltext('')
                    }
                }}
            >
                +
            </button>
            : <div style={{ height: 120 }} />}

    </div>

    // SPREADSHEET
    const budget_spreadsheet_otherdevice = <div
        className="Budgetedit_spreadsheet_others"
        // style={{ ...spreadplacementstyle, borderTop: (currbudgetsequence && currbudgetsequence.length > 0) ? '' : '1px solid var(--subgrey)' }}
        style={{ ...spreadplacementstyle }}
    >

        <div
            className="Checklistedit_title"
            style={{ width: '100%' }}
            onClick={() => {
                setPortalstate('EDITTOTALBUDGET');
                setPortaltext(currbudgetstr)
            }}
        >
            <div style={{ fontSize: 14, color: 'var(--maindark)', marginTop: 5 }}>Budget</div>
            <div>{'$' + currbudgetstr}</div>
        </div>

        <div className="Checklistedit_titlenohover" style={{ width: '100%', marginBottom: 0, borderBottom: 'none' }}>
            <div style={{ fontSize: 14, color: 'var(--maindark)', marginTop: 5 }}>Total Cost</div>
            <div>{'$' + totalcost}</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="Checklistedit_titlenohover" style={{ width: '100%' }}>
                <div style={{ fontSize: 14, color: 'var(--maindark)', marginTop: 5 }}>Amount Due</div>
                <div>{'$' + totaldue}</div>
            </div>
            <div className="Checklistedit_titlenohover" style={{ width: '100%', borderLeft: 'none' }} >
                <div style={{ fontSize: 14, color: 'var(--maindark)', marginTop: 5 }}>Amount Paid</div>
                <div>{'$' + totalpaid}</div>
            </div>

        </div>


        {/* need to solve the id issue */}
        <DragDropContext onDragEnd={onDragend}>
            <Droppable droppableId={'section'} type="section">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {currbudgetsequence.map((id, index) => {
                            if (currbudgetobjs && currbudgetobjs[id]) {
                                const { collapse, items, itemsequence, sectionname } = currbudgetobjs[id] // icon, content,
                                var local_ac = 0
                                for (let i = 0; i < itemsequence.length; i++) {
                                    const itemid = itemsequence[i]
                                    const { actual_cost } = items[itemid]
                                    local_ac += parseInt(actual_cost, 10)
                                }
                                return <Draggable
                                    key={index + ' main ' + index + ' _ ' + id + sectionname}
                                    draggableId={'section_' + index}
                                    index={index}
                                    type="main"
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getMainobjStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            onMouseOver={() => { setHover(id) }}
                                            onMouseLeave={() => { setHover('') }}
                                        >
                                            <div
                                                key={index + 'main'}
                                                className="Checklist_maindrag"
                                            >
                                                <div className={'Checklist_cattitle' + (collapse ? '' : '_withouthover')} >
                                                    <div
                                                        onClick={() => {
                                                            if (!dragsavemode) {
                                                                setPortalstate('EDITSECTION')
                                                                setPortaltext(sectionname)
                                                                setCurrsectionid(id)
                                                                setPortaltempdata({
                                                                    content: currbudgetobjs[id].content,
                                                                })
                                                            }
                                                        }}
                                                        style={{ padding: 5, width: '100%', whiteSpace: 'nowrap', border: 'none', outline: 'none', fontSize: VT_DEVICE_FS_MAP[device] }}
                                                    >
                                                        {sectionname}
                                                    </div>
                                                    <div
                                                        className="Bkmer_expandtab"
                                                        onClick={() => {
                                                            var new_currbudgetobjs = JSON.parse(JSON.stringify(currbudgetobjs))
                                                            new_currbudgetobjs[id]['collapse'] = !collapse
                                                            setCurrbudgetobjs(new_currbudgetobjs)
                                                        }}
                                                    >
                                                        {collapse
                                                            ? iconwrapper(LINEDOWNARROW_ICON, { width: 20, height: 20, display: 'flex' })
                                                            : iconwrapper(LINERIGHTARROW_ICON, { width: 20, height: 20, display: 'flex' })}
                                                    </div>
                                                </div>
                                                {collapse ? null : <div className={"Bkmer_dragprice" + device}>{'$ ' + local_ac}</div>}
                                                {collapse ? Collapsible(items, itemsequence, id, index) : Noncollapsable(itemsequence)}
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            }
                        })}

                        {provided.placeholder}

                    </div>
                )}
            </Droppable>
        </DragDropContext>

        {!dragsavemode
            ? <button
                className="Budgetedit_sectionadd"
                onClick={() => {
                    if (!dragsavemode) {
                        setPortalstate('ADDSECTION')
                        setPortaltext('')
                    }
                }}
            >
                +
            </button>
            : <div style={{ height: 120 }} />}

    </div>

    const budget_dom = !firsttime && currbudgetid && currbudgetid.length > 0
        ? <div id='Checklist_main'>
            <div className="Checklistedit_devicebtns">
                <div style={{ display: 'flex', flexDirection: 'row', placeContent: 'space-between', alignItems: 'center', width: '100%', position: 'relative' }}>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                        <button
                            className="itinerary_colorbtn"
                            style={{ position: 'relative', margin: 0 }}
                            onClick={() => {
                                if (!dragsavemode) { setOpentemplate(true) }
                            }}
                        >
                            {iconwrapper(ITINERARYTEMPLATE_ICON, { width: iconsize, height: iconsize, display: 'flex', placeContent: 'center' })}
                        </button>

                        <button
                            className="itinerary_colorbtn"
                            style={{ position: 'relative', margin: 0 }}
                            onClick={() => {
                                if (currbudgetsequence && currbudgetsequence.length > 0) {
                                    let new_budgetobjs = JSON.parse(JSON.stringify(currbudgetobjs))
                                    if (expandall) {
                                        for (let i = 0; i < currbudgetsequence.length; i++) {
                                            const key = currbudgetsequence[i]
                                            new_budgetobjs[key]['collapse'] = false
                                        }
                                    }
                                    else {
                                        for (let i = 0; i < currbudgetsequence.length; i++) {
                                            const key = currbudgetsequence[i]
                                            new_budgetobjs[key]['collapse'] = true
                                        }
                                    }
                                    setCurrbudgetobjs(new_budgetobjs)
                                    setExpandall(!expandall)
                                }
                            }}
                        >
                            {iconwrapper(expandall ? COLLAPSE_ICON : UNCOLLAPSE_ICON, { width: iconsize, height: iconsize, display: 'flex', placeContent: 'center' })}
                        </button>

                        <button
                            className="itinerary_colorbtn"
                            style={{ position: 'relative', margin: 0 }}
                            onClick={() => {
                                setOpenbudgetsetting(true)
                            }}
                        >
                            {iconwrapper(SETTING10_ICON, { width: iconsize, height: iconsize, display: 'flex', placeContent: 'center' })}
                        </button>
                    </div>

                    <button
                        className='scedit_qrbtn'
                        style={{ position: 'relative' }}
                        onClick={() => {
                            // setDownloadportal(true) 
                            document.getElementById('DOWNLOAD_BUDGET').click()

                        }}
                    >
                        {iconwrapper(DOWNLOAD_ICON, { width: iconsize, height: iconsize })}
                    </button>

                </div>
            </div>

            <div
                className="Checklistedit_title"
                onClick={() => {
                    if (!dragsavemode) {
                        setPortalstate('EDITBUDGETNAME')
                        setPortaltext(currbudgetname)
                    }
                }}
            >
                <div style={{ fontSize: 14, color: 'var(--maindark)', marginTop: 5 }}>Name</div>
                {currbudgetname}
            </div>

            {/*
            // to be added in the future
             <div className="Checklistedit_objsroot" >
                <div style={{ display: 'flex', flexDirection: 'row', padding: '10px 0px 20px 0px', width: '100%', placeContent: 'center' }}>
                    <button
                        className={screenmode === 'BUDGET' ? 'Checklistedit_modebtnselected' : 'Checklistedit_modebtn'}
                        style={{ borderRadius: '5px 0px 0px 5px', borderRight: screenmode === 'BUDGET' ? '1px solid var(--maindark)' : 'none' }}
                        onClick={() => { if (!dragsavemode) { setScreenmode('BUDGET') } }}
                    >
                        Budget
                    </button>
                    <button
                        className={screenmode === 'ARCHIVE' ? 'Checklistedit_modebtnselected' : 'Checklistedit_modebtn'}
                        style={{ borderRadius: '0px 5px 5px 0px', borderLeft: screenmode === 'ARCHIVE' ? '1px solid var(--maindark)' : 'none' }}
                        onClick={() => { if (!dragsavemode) { setScreenmode('ARCHIVE') } }}
                    >
                        Archive
                    </button>
                </div>
            </div>
             */}

            <div className="Checklistedit_objsroot" >
                <div style={{ display: 'flex', flexDirection: 'row', height: 50, width: '100%', placeContent: 'center' }}>
                </div>
            </div>

            {device === 'WEB' && wt > 1199
                ? <div
                    className="Budgetedit_webcontentroot"
                    style={{ flexDirection: budgetsettings['placement'] === 'graphspreadsheet' ? 'row' : 'row-reverse' }}
                >
                    {budget_graph_web}
                    {budget_spreadsheet_web}
                </div>
                : null}

            {(device === 'TABLET') || (device === 'WEB' && wt < 1200)// TOP GRAPH, BTM SPREADSHEET
                ? <div className="Budgetedit_tabletcontentroot">
                    {budget_graph_otherdevice}
                    {budget_spreadsheet_otherdevice}
                </div>
                : null}


            {device === 'MOBILE' // TOP GRAPH, BTM SPREADSHEET
                ? <div className="Budgetedit_mobilecontentroot">
                    {/* {budget_graph_otherdevice} */}
                    {budget_spreadsheet_otherdevice}
                </div>
                : null}

        </div>
        : null

    const itemdelete_mod = deletemod
        ? <Modcfm
            onHide={() => setDeletemod(false)}
            onOk={() => delete_item_or_section('EDITITEM')}
            onNo={() => { setDeletemod(false) }}
            txt={'Once you delete this, there is no going back. Please be certain.'}
        />
        : null

    return !loaded
        ? <Loading ht={'100%'} />
        : <div id="itineraryedit_root" >
            {topbar}
            {home_mod}
            {budget_dom}
            {dragsave_dom}
            {edit_portal}
            {setting_portal}
            {template_portal}
            {itemdelete_mod}
            {csvdom}
        </div>
}
export default Budgetedit