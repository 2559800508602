
import React, { useEffect, useState } from 'react'
import { HEART_ICON, NORMAL_GREY, LIKEABLE_RED, EMPTY } from './../config/constants'
import { Retrieve_personal_info } from '../config/commonfunctions'
import { USER_FAV_A_VENDOR, USER_UNFAV_A_VENDOR } from '../config/api'
import axios from 'axios'
import { Checkdevice } from '../components'

function Heart(props) {
    const [userlike, setUserlike] = useState(false)
    const [clicked, setClicked] = useState(false)
    const {
        vendortypeinterface_id, vendortype, secondaryvendortype = EMPTY, vendortype_id,
        updateHeart = () => {
            // console.log('Error: Dec or Inc fav')
        }, // this is to update the source of state
        parent, // where the favourite is triggered
        qn = false, // if true, will prompt user cfm or nt to dislike of like
        hoverfunc=true// to have or not to have hover func
    } = props
    const [hover, setHover] = useState({ visibility: 'hidden' })
    const device = Checkdevice()
    useEffect(() => {
        setUserlike(props.userlike)
    }, [props])

    useEffect(() => {
        if (clicked) {
            try {
                var link = EMPTY
                if (userlike === false) link = USER_FAV_A_VENDOR
                else if (userlike === true) link = USER_UNFAV_A_VENDOR
                const { token, _id, acct_type } = Retrieve_personal_info()
                if (acct_type === 'USER') {
                    let options = {
                        method: 'POST',
                        url: link,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                        data: { _id, token, acct_type, vendortypeinterface_id, vendortype, secondaryvendortype: secondaryvendortype === null ? EMPTY : secondaryvendortype, vendortype_id }
                    }
                    axios(options).then((result) => {
                        const { data, status } = result
                        const { error } = data
                        if (status === 200 && error === false) {
                            var userlike_state = !userlike
                            setClicked(false)
                            setUserlike(userlike_state)
                            updateHeart(userlike_state, vendortypeinterface_id, vendortype, secondaryvendortype, vendortype_id)
                        }
                    })
                }
            }
            catch (e) { setClicked(false) }
        }
    }, [clicked])

    return <button
        key={vendortypeinterface_id + parent}
        onMouseOver={() => { if (device !== 'MOBILE' && hoverfunc) setHover({ visibility: 'visible' }) }}
        onMouseLeave={() => { if (device !== 'MOBILE' && hoverfunc) setHover({ visibility: 'hidden' }) }}
        style={{ color: userlike ? LIKEABLE_RED : NORMAL_GREY, position: "relative", display: 'flex', padding: 3, height: 31, outline: 'none', backgroundColor: 'transparent', borderRadius: '100px', border: 'thin solid transparent' }}//border: 'thin solid rgb(217, 216, 216)'
        onClick={() => {
            if (qn) {
                if (window.confirm('Once you unfav, card will be removed. Please be certain.')) {
                    setClicked(!clicked)
                }
            }
            else setClicked(!clicked)
        }}>

        <div style={{ ...hover, position: 'absolute', minWidth: 100, height: 30, left: 'calc( 100% - 64.5px )', width: 100, textAlign: 'center', display: 'flex', alignItems: 'center', placeContent: 'center', placeSelf: 'center', top: -30, backgroundColor: 'black', color: "white", borderRadius: 5, zIndex: '9999999999999999' }}>
            <div style={{ position: 'absolute', display: 'flex', width: 15, height: 15, transform: 'rotate(45deg)', backgroundColor: 'black', top: 20, zIndex: -1 }} />
            <div>Favourite</div>
        </div>
        {HEART_ICON}
    </button>

}

export default Heart

