import React, { Component } from 'react'
import { WEB, BOTTOM_BAR_HEIGHT, NAV_BAR_HEIGHT, TABLET, EMPTY, MAX_BOARD_PROJ_FOR_USER, IMG_ALT, READABLE_VENDORTYPE_MAP, PIN_SMALL } from '../../config/constants'
import { Link } from "react-router-dom";
import { BUTTONSTYLE } from '../../config/cssconstants';
import axios from 'axios'

//redux**
import { bindActionCreators } from 'redux'
import * as types from '../../redux/actions/projectlookup.action'
import { connect } from 'react-redux'
import { Retrieve_personal_info, getS3fullpath_withbucketname } from '../../config/commonfunctions';
import { CREATE_BOARD, READ_U_ALL_BOARDS, UPDATE_BOARD, REMOVE_PI_FROM_BOARD } from '../../config/api';
import { Jfmodal, Loading } from '../../components';
import Preloadimages from '../../components/Preloadimages';
import './projectlookup.css'

const mapStateToProps = (state) => {
    // get state from reducer
    return {
        projectlookup_data: state.Projectlookup.projectlookup_data,
        isContentLoading: state.Projectlookup.isContentLoading,
        isContentLoaded: state.Projectlookup.isContentLoaded,
        savebtn: state.Projectlookup.savebtn
    }
}
const mapDispatchToProps = (dispatch) => { // https://www.bilibili.com/video/av23568132/?p=10
    // dispatch action to reducer
    return bindActionCreators(types, dispatch)
}

class Projectlookup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            screen_width: 0,
            screen_height: 0,
            scrollingLock: true,
            token: EMPTY,
            user_id: null,
            acct_type: EMPTY,
            savebtn: false,
            savemodal: false,
            createnewboard: false,
            boardname: EMPTY,
            board_ids_dom: [],
            board_ids_count: 0,
            loadimg: false,
            loadfont: true
        }
    }

    componentDidMount = async () => {
        const { project_id } = this.props.match.params

        var { token, _id = undefined, acct_type = undefined } = Retrieve_personal_info()
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions)
        window.addEventListener('scroll', this.updateScroll)

        const loadimgfunc = () => {

            var imgs = []
            const { projectlookup_data } = this.props
            if (projectlookup_data) {
                const { obj_key, obj_map } = projectlookup_data
                for (let i = 0; i < projectlookup_data.obj_key.length; i++) {
                    if (obj_map[obj_key[i]].pattern === 'I') {
                        imgs.push(projectlookup_data.obj_map[obj_key[i]].value)
                    }
                    else if (obj_map[obj_key[i]].pattern === 'IP') {
                        imgs.push(projectlookup_data.obj_map[obj_key[i]].value)
                        imgs.push(projectlookup_data.obj_map[obj_key[i]].value1)
                    }
                }
                this.setState({ loadimg: true })
                Preloadimages(imgs, () => this.setState({ loadimg: true }))
            }
            else{
                this.setState({ loadimg: false })
            }

        }

        if (acct_type === 'USER') {
            this.props.fetchprojectdetaillogged_action(project_id, token).then(() => {
                this.setState({ token, user_id: _id, acct_type, savebtn: this.props.savebtn }, () => { loadimgfunc() })
            })
        }
        else {// VENDOR or EMPTY
            this.props.fetchprojectdetail_action(project_id).then(() => {
                this.setState({ token, user_id: _id, acct_type, savebtn: false }, () => { loadimgfunc() })
            })
        }

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
        window.removeEventListener('scroll', this.updateScroll)
    }
    updateWindowDimensions = () => {
        // 30 is the side scrolling bar for windows
        this.setState({ screen_width: window.innerWidth, screen_height: window.innerHeight });
    }
    updateScroll = () => {
        if (window.scrollY > 50) {
            this.setState({
                scrollingLock: false
            });
        }
        else {
            this.setState({
                scrollingLock: true
            });
        }
    }
    RenderTitle = () => {
        try {
            const { screen_width } = this.state
            const device = screen_width > WEB ? 'WEB' : screen_width < TABLET ? 'MOBILE' : 'TABLET'
            return (
                <div className={"projectlookup_title" + device} style={{ fontFamily: this.props.projectlookup_data.fontfamily }}>
                    {this.props.projectlookup_data.name}
                </div>
            )
        }
        catch (e) {
            return null
        }

    }
    RenderMainText = () => {
        try {
            const { screen_width } = this.state
            const device = screen_width > WEB ? 'WEB' : screen_width < TABLET ? 'MOBILE' : 'TABLET'
            return <div className={"projectlookup_maintext" + device} style={{ fontFamily: this.props.projectlookup_data.fontfamily }}>
                {this.props.projectlookup_data.maintext}
            </div>
        }
        catch (e) {
            return null
        }
    }
    RenderCollaborators = () => {
        const { collaborator_displaynames, collaborator_ids, collaborator_profileimg_ids, collaborator_names, collaborator_vendortypes, displayname, username, vendortype, creator_profileimg_id } = this.props.projectlookup_data
        return collaborator_ids && collaborator_ids.length > 0
            ? <div className="projectlookup_collabroot">
                {collaborator_names && collaborator_names.length > 0 ? <div className="projectlookup_collatitle">Collaborators</div> : null}
                {collaborator_vendortypes.map((vt, i) => {
                    const c_dname = collaborator_displaynames[i]
                    return <Link className='projectlookup_collablink' key={c_dname} to={'/vendorlookup/' + collaborator_names[i]} target="_blank" style={{ textDecoration: 'none', color: 'var(--maindark)', marginBottom: i === collaborator_vendortypes.length - 1 ? 0 : 10 }} >
                        <img className="projectlookup_circleimg" alt={IMG_ALT} src={getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', collaborator_profileimg_ids[i].link)} />
                        <div>
                            <div className="projectlookup_dn">{c_dname}</div>
                            <div className="projectlookup_vt">{READABLE_VENDORTYPE_MAP[vt]}</div>
                            {displayname === c_dname
                                ? <div className="projectlookup_owner">Owner</div>
                                : null}
                        </div>
                    </Link>
                })}
            </div>
            : <div className="projectlookup_collabroot">
                <Link className='projectlookup_collablink' key={displayname} to={'/vendorlookup/' + username} target="_blank" style={{ textDecoration: 'none', color: 'var(--maindark)', marginTop: 10, marginBottom: 0 }} >
                    <img className="projectlookup_circleimg" alt={IMG_ALT} src={getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', creator_profileimg_id.link)} />
                    <div>
                        <div className="projectlookup_dn">{displayname}</div>
                        <div className="projectlookup_vt">{READABLE_VENDORTYPE_MAP[vendortype]}</div>
                        <div className="projectlookup_owner">Owner</div>
                    </div>
                </Link>
            </div>
    }

    RenderGallery = () => {
        try {
            const { screen_width } = this.state
            const device = screen_width > WEB ? 'WEB' : screen_width < TABLET ? 'MOBILE' : 'TABLET'
            const { obj_key, obj_map, fontfamily } = this.props.projectlookup_data
            var dom_result = []
            for (let i = 0; i < obj_key.length; i++) {
                var key = obj_key[i]
                const { dimorientation, pattern, value } = obj_map[key]
                if (pattern === 'I') {
                    dimorientation === 'landscape'
                        ? dom_result.push(<img className={"projectlookup_imgIL" + device} alt={IMG_ALT} src={getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG', value)} key={key} />)
                        : dom_result.push(<img className={"projectlookup_imgIP" + device} alt={IMG_ALT} src={getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG', value)} key={key} />)
                }
                else if (pattern === 'IP') {// images with two portraits
                    const { value1 } = obj_map[key]
                    var space_in_btw
                    if (device === 'MOBILE') space_in_btw = 2.5
                    else space_in_btw = 5
                    dom_result.push(
                        <div className="projectlookup_rowimg" key={key}>
                            <img className={"projectlookup_imgP" + device} alt={IMG_ALT} src={getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG', value)} style={{ width: `calc( 50% - ${space_in_btw}px )`, marginRight: space_in_btw, height: '100%' }} />
                            <img className={"projectlookup_imgP" + device} alt={IMG_ALT} src={getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG', value1)} style={{ width: `calc( 50% - ${space_in_btw}px )`, marginLeft: space_in_btw, height: '100%' }} />
                        </div>
                    )
                }
                else if (pattern === 'S') {
                    dom_result.push(<div key={key} className={"projectlookup_space" + device} />)
                }
                else if (pattern === 'T') {
                    dom_result.push(
                        <div key={key} className={"projectlookup_text" + device} style={{ fontFamily: fontfamily }}>{value}</div>
                    )
                }
                else if (pattern === 'V') {
                    var index = value.match(/.com\//).index + 5;
                    var new_value = value.slice(0, index) + 'embed/' + value.slice(index)
                    new_value = new_value.replace('watch?v=', '');

                    dom_result.push(
                        <div key={key} className="projectlookup_vid">
                            <iframe
                                className="projectlookup_vidiframe"
                                src={new_value}
                                frameBorder='0'
                                allow='autoplay; encrypted-media'
                                allowFullScreen
                                title='video'
                            />
                        </div>
                    )
                }
            }
            return <div className="projectlookup_imgroot">{dom_result}</div>
        }
        catch (e) {
            return null
        }

    }
    RenderLink = () => {
        const { project_extlinks } = this.props.projectlookup_data
        return project_extlinks && project_extlinks.length > 0 && project_extlinks[0].length > 0
            ? <div>
                <div className="projectlookup_collatitle">External link</div>
                <a href={'//' + project_extlinks[0]} style={{ color: '#707070' }}>{project_extlinks[0]}</a>
            </div>
            : null

    }
    RenderLocation = () => {
        const { location = EMPTY, } = this.props.projectlookup_data//locationpt = EMPTY,
        return location && location.length > 1
            ? <div>
                <div className="projectlookup_collatitle">Location</div>
                <div style={{ color: '#707070' }}>{location}</div>
            </div>
            : null
    }
    RenderNonexistant = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <div>No Such Project</div>
            </div>
        )
    }
    RenderSave = () => {
        const { _id = null, projectinterface_id = null, } = this.props.projectlookup_data
        const { token, acct_type, savebtn } = this.state

        const onUpdateboard = (item) => {
            const { _id } = item
            try {
                let options = {
                    method: 'POST',
                    url: UPDATE_BOARD,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { board_id: _id, pi_id: projectinterface_id }
                }
                axios(options).then((result) => {
                    const { message } = result.data
                    if (message === 'SUCCESS') {
                        this.setState({ createnewboard: false, savemodal: false, savebtn: !savebtn })
                    }
                    else if (message === 'IMGLIMITREACH') {
                        alert('Image limit reach!')
                        this.setState({ createnewboard: false, savemodal: false, savebtn: !savebtn })
                    }
                })
            }
            catch (e) { }
        }

        const onPin = () => {
            if (_id && _id.length > 0) {
                let options = {
                    method: 'POST',
                    url: READ_U_ALL_BOARDS,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                }
                axios(options).then((result) => {
                    const { data } = result
                    const { message, bdata } = data

                    if (message === 'SUCCESS') {
                        var board_ids_dom = []
                        for (let i = 0; i < bdata.length; i++) {
                            const { name, pi_ids } = bdata[i]
                            board_ids_dom.push(
                                <button className="projectlookup_pinbtn" key={'pinbtn' + i} onClick={() => { onUpdateboard(bdata[i]) }} >
                                    <div>{name}</div>
                                    <div>{pi_ids.length} / {MAX_BOARD_PROJ_FOR_USER}</div>
                                </button>
                            )
                        }
                        this.setState({ board_ids_dom: board_ids_dom, board_ids_count: bdata.length })
                    }

                })
                this.setState({ savemodal: true })
            }
        }

        const onRemove = () => {
            if (window.confirm('This will remove your image from board, are you sure?')) {
                if (_id !== null && _id !== undefined && _id !== EMPTY) {

                    let options = {
                        method: 'POST',
                        url: REMOVE_PI_FROM_BOARD,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                        data: { 'projectinterface_id': projectinterface_id }
                    }
                    axios(options).then((save) => {
                        const { data } = save
                        const { message } = data
                        if (message === 'SUCCESS') {
                            this.setState({ savebtn: !savebtn })
                        }
                    })

                }
            }

        }

        if (acct_type === 'USER' && savebtn) {
            return <button className="projectlookup_boardbtn" onClick={onPin}>
                <div>Pin To Board</div>
                <div className="projectlookup_boardbtnicon">{PIN_SMALL}</div>
            </button>
        }
        else if (acct_type === 'USER' && !savebtn) {
            return <button className="projectlookup_boardbtn" onClick={onRemove}>Remove From Board</button>
        }
        else {
            return null
        }

    }
    RenderSavemodal = () => {
        const { savebtn, savemodal, token, screen_width, createnewboard, boardname, board_ids_dom, loadimg ,loadfont} = this.state //board_ids_count, screen_height,

        if (savebtn && loadimg && loadfont) {
            const { projectinterface_id = null, } = this.props.projectlookup_data

            const device = screen_width < TABLET ? 'MOBILE' : screen_width < WEB ? 'TABLET' : 'WEB'

            const toogleCreatenew = () => {
                this.setState({ createnewboard: true })
            }
            const updateInput = (event) => {
                this.setState({ boardname: event.target.value })
            }
            const onClose = () => {
                this.setState({ savemodal: false, createnewboard: false, })
            }
            const onCreate = () => {
                try {
                    let options = {
                        method: 'POST',
                        url: CREATE_BOARD,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                        data: { boardname, projectinterface_id }
                    }
                    axios(options).then((result) => {
                        const { message } = result.data
                        if (message === 'SUCCESS') {
                            this.setState({ createnewboard: false, savemodal: false, savebtn: !savebtn })
                        }
                        else if (message === 'LIMITREACH') {
                            alert('Limit reach!')
                            this.setState({ createnewboard: false, savemodal: false, savebtn: !savebtn })
                        }
                    })
                }
                catch (e) {

                }
            }
            const createbtn = () => {
                return createnewboard
                    ? <div style={{ display: 'flex', flexDirection: 'row', width: '100%', margin: '10px 0px ', }}>
                        <input
                            style={{ width: '100%', border: 'thin solid rgb(217, 216, 216)', marginRight: 10, outline: 'none' }}
                            placeholder={' New board name'}
                            onChange={updateInput}
                        ></input>
                        <button style={{ ...BUTTONSTYLE, width: '20%', }} onClick={onCreate}>
                            Create
                        </button>
                    </div>
                    : <button style={{ ...BUTTONSTYLE, textAlign: 'center', width: '100%', margin: '10px 0px ', }} onClick={toogleCreatenew}>Create New Board</button>
            }

            return (
                <Jfmodal
                    show={savemodal}
                    title={'Add to board'}
                    onHide={onClose}
                    device={device === 'MOBILE' ? 'MOBILE' : 'WEB'}
                    bsSize={'large'}
                    content={
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ width: '100%', color: '#707070', justifyContent: 'center', padding: 15 }}>
                                {board_ids_dom}
                                {createbtn()}
                            </div>
                        </div>
                    }
                />
            )
        }
        else return <div/>
    }

    RenderMobile = () => {
        const { screen_height, loadimg, loadfont } = this.state
        if (loadimg && loadfont) {
            return (
                <div className="projectlookup_mobileroot" style={{ animation: 'projectlookup_imgroot_anim 1000ms forwards' }}>
                    <div style={{ backgroundColor: 'rgb(238, 238, 238)', width: '100%', minHeight: screen_height - NAV_BAR_HEIGHT - BOTTOM_BAR_HEIGHT, padding: '20px 0px 0px 0px', display: 'flex', boxShadow: '#0000000d 1px 1px 5px', flexDirection: 'column' }}>
                        {this.RenderTitle()}
                        {this.RenderMainText()}
                        {this.RenderGallery()}
                        <div style={{ backgroundColor: 'white', padding: 15 }}>
                            {this.RenderSave()}
                            {this.RenderCollaborators()}
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {this.RenderLocation()}
                                {this.RenderLink()}
                            </div>
                        </div>
                        <div style={{ height: BOTTOM_BAR_HEIGHT }}>
                        </div>
                    </div>
                </div>
            )
        }
        else return <Loading /> // content must be loading
    }

    RenderWeb = () => {
        try {
            const { screen_height, scrollingLock, loadimg, loadfont } = this.state
            const wt_left = '80%'
            const wt_right = '20%'
            if (loadimg && loadfont) {
                return (
                    <div className="projectlookup_webroot" style={{ minHeight: screen_height - NAV_BAR_HEIGHT - BOTTOM_BAR_HEIGHT, animation: 'projectlookup_imgroot_anim 1000ms forwards', fontFamily: 'Lato' }}>
                        <div style={{ width: wt_left, backgroundColor: 'white', minHeight: screen_height, boxShadow: '#0000000d 1px 1px 5px' }}> {/*'80%'*/}
                            {this.RenderTitle()}
                            {this.RenderMainText()}
                            {this.RenderGallery()}
                        </div>
                        <div style={{ width: wt_right, right: 0, backgroundColor: 'white', boxShadow: '#0000000d 5px 1px 5px', height: screen_height, position: scrollingLock ? 'absolute' : 'fixed', top: scrollingLock ? '35px' : '0px', }}> {/*'20%'  scrollingLock ? '55px' : '0px', */}
                            <div style={{ height: screen_height }}>
                                <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 10px' }}>
                                    {this.RenderSave()}
                                    {this.RenderCollaborators()}
                                </div>
                                <div style={{ padding: '0px 10px', display: 'flex', flexDirection: 'column' }}>
                                    {this.RenderLocation()}
                                    {this.RenderLink()}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else return <Loading /> // content must be loading
        }
        catch (e) { }
    }

    render() {
        const { screen_width } = this.state

        return <div>
            {screen_width < TABLET || screen_width < WEB + 1
                ? this.RenderMobile()
                : this.RenderWeb()}
            {this.RenderSavemodal()}
        </div>
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(Projectlookup)