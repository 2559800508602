function Processmoney(x) {
    try{
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }
    catch(e){
        return ''
    }

}

export default Processmoney