import React from 'react'

function Explain(props) {
    //, backgroundColor:'#ffedf1', border:'1px solid #eb5d7b'
    const { title, txt, device } = props
    return <div style={{ display: 'flex', flexDirection: 'column',color:'#333333', padding: 10, border: 'thin solid #dddddd', borderRadius: 10, width: '100%', marginBottom:10 }}>
        <div style={{ fontSize: device === 'WEB' ? 21 : 19, fontWeight:'bold' }}>{title}</div>
        <div style={{ fontSize: 14, padding: '10px 0px 0px 0px' }}>{txt}</div>
    </div>
}

export default Explain