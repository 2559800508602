import { useEffect, useState } from "react"
import { Modcfm } from "../../../../components";
import { READ_TLSPROJ, CREATE_TIMELINE, UPDATE_TLPASSCODE, REMOVE_SINGLETIMELINE } from "../../../../config/api";
import { Retrieve_personal_info } from "../../../../config/commonfunctions";
import { Selectionbox } from "../Components";
import Createnew from "../Components/Createnew/Createnew";
import Continuepage from "../Components/Continuepage/Continuepage";
import axios from 'axios'
import './Itinerarymaker.css'

function Itinerarymaker(props) {

    window.screen.lockOrientationUniversal = window.screen.lockOrientation || window.screen.mozLockOrientation || window.screen.msLockOrientation;

    const { token } = Retrieve_personal_info()
    const [firsttime, setFirsttime] = useState(true)
    const [loaded, setLoaded] = useState(false)

    const [quota, setQuota] = useState('')
    const [page, setPage] = useState('NONE') // NONE, CREATE, OPEN (OPEN EXISTING)

    const [timeline_ids, setTimeline_ids] = useState([])
    const [timeline_objs, setTimeline_objs] = useState([])


    const [rmvmod, setRmvmod] = useState(false) // for removal of selectionbox
    const [rmvindex, setRmvindex] = useState(null)
    const [rmvid, setRmvid] = useState(null)

    const [currtimeline_id, setCurrtimeline_id] = useState('') // user clicked and selected a particular timeline obj
    const [urlhandler, setUrlhandler] = useState('')
    const [passcode, setPasscode] = useState('')

    useEffect(() => {
        if (firsttime) {
            try {
                let options = {
                    method: 'POST',
                    url: READ_TLSPROJ,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                }
                axios(options).then((tls) => {
                    const { gttimelines, quota, message, timeline_objs, tl_ids } = tls.data
                    if (gttimelines && message === 'SUCCESS') {
                        setTimeline_ids(tl_ids)

                        let newobjs = []
                        for(let i =0;i<tl_ids.length;i++){
                            for(let j=0;j<timeline_objs.length;j++){
                                if(timeline_objs[j]._id === tl_ids[i]){
                                    newobjs.push(timeline_objs[j])
                                }
                            }
                        }

                        setTimeline_objs(newobjs)

                    }
                    setQuota(quota)
                    setLoaded(true)
                    setPage('NONE')
                }).catch((e) => { props.history.push('/') })
            }
            catch (e) {
                props.history.push('/')
            }
            setFirsttime(false)
        }
    }, [])

    const clickexist = (_id) => {
        if (_id !== currtimeline_id) {
            setPage('OPEN')
            setCurrtimeline_id(_id)
            for (let i = 0; i < timeline_objs.length; i++) {
                const { passcode, urlhandler } = timeline_objs[i]
                if (timeline_objs[i]._id === _id) {
                    setPasscode(passcode)
                    setUrlhandler(urlhandler)
                    break
                }
            }
        }
        else {
            setCurrtimeline_id('')
            setUrlhandler('')
            setPasscode('')
            setPage('NONE')
        }

    }

    const removeexist = (_id, index) => {
        setRmvmod(true);
        setRmvid(_id);
        setRmvindex(index)
    }

    const add = () => {
        setCurrtimeline_id('')
        setPage('CREATE')
    }

    const updateseq = (new_objs) => {
        setTimeline_objs(JSON.parse(JSON.stringify(new_objs)))
        //  clearall();
    };




    const savepasscode = (newpasscode) => {
        return axios({
            method: 'POST',
            url: UPDATE_TLPASSCODE,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
            data: { passcode: newpasscode, tl_id: currtimeline_id, old_passcode: passcode, urlhandler: urlhandler }
        }).then((result) => {
            const { data } = result
            if (data.message === 'SUCCESS') {
                setPasscode(newpasscode)
                return 'SUCCESS'
            }
            else if (data.message === 'UNABLE_SAVE') {
                return 'UNABLE_SAVE'
            }
        }).catch((e) => { console.log(e) })
    }

    const removecfm = (_id) => {
        try {
            let options = {
                method: 'POST',
                url: REMOVE_SINGLETIMELINE,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { timeline_id: _id }
            }
            axios(options).then((result) => {
                const { message } = result.data
                if (message === 'SUCCESS') {
                    setTimeline_ids((curr) => {
                        let new_tlids = JSON.parse(JSON.stringify(curr))
                        new_tlids.splice(new_tlids.indexOf(_id), 1)
                        return new_tlids
                    })
                    setTimeline_objs((curr) => {
                        let new_tlobjs = JSON.parse(JSON.stringify(curr))
                        let index = -1
                        for (let i = 0; i < new_tlobjs.length; i++) {
                            if (new_tlobjs[i]._id === _id) {
                                index = i
                            }
                        }
                        new_tlobjs.splice(index, 1)
                        return new_tlobjs
                    })
                    setUrlhandler('')
                    setPasscode('')
                    setCurrtimeline_id('')
                    setPage('NONE')

                }
            }).catch((e) => { })
        }
        catch (e) { }
    }

    const create = (urlhandler, passcode) => {
        return axios({
            method: 'POST',
            url: CREATE_TIMELINE,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
            data: { urlhandler: urlhandler, passcode: passcode }
        })
            .then((result) => {
                const { message, tlid } = result.data
                if (message === 'SUCCESS') {
                    setTimeline_ids((curr) => {
                        let new_tlids = curr.slice()
                        new_tlids.push(tlid)
                        return new_tlids
                    })
                    setTimeline_objs((curr) => {
                        let new_tlobjs = JSON.parse(JSON.stringify(curr))
                        new_tlobjs.push({ urlhandler: urlhandler, passcode: passcode, _id: tlid })
                        return new_tlobjs
                    })
                }
                return { message, id: tlid }
            })
            .catch((e) => { })
    }

    const continueclick = () => {
        props.history.push('itinerarybuilder/' + currtimeline_id + '/edit')
    }

    const lookupclick = '/timelinelookup/' + urlhandler + (passcode.length > 0 ? '/passcode=' + passcode : '')

    return <div id='planningedit_root'>
        <div id='planningedit_selectionbox'>
            {loaded
                ? <Selectionbox
                    where={'itinerary'}
                    onClickexist={clickexist}
                    onRemoveexist={removeexist}
                    onAdd={add}
                    updateseq={updateseq}
                    id={currtimeline_id}
                    ids={timeline_objs}
                    quota={quota}
                />
                : null}
            {{
                'NONE': null,
                'OPEN': <Continuepage
                    {...props}
                    from={'itineraryedit'}
                    urlhandler={urlhandler}
                    passcode={passcode}
                    lookupclick={lookupclick}
                    continueclick={continueclick}
                    savepasscode={savepasscode}
                    title={'Change Passcode'}
                />,
                'CREATE': <Createnew
                    {...props}
                    from={'itineraryedit'}
                    reachquota={quota === timeline_ids.length}
                    urlhandler={urlhandler}
                    passcode={passcode}
                    create={create}
                    createsuccess={(id, urlhandler, passcode) => {
                        setUrlhandler(urlhandler)
                        setPasscode(passcode)
                        setCurrtimeline_id(id)
                        setPage('OPEN')
                    }}
                />,
            }[page]}
            {rmvmod ? <Modcfm
                onHide={() => { setRmvmod(false) }}
                onOk={() => { removecfm(rmvid, rmvindex); setRmvmod(false) }}
                onNo={() => { setRmvmod(false) }}
                txt={'Once you delete this, there is no going back. Link to this Timeline Itinerary will no longer be functional, please be certain.'}
            /> : null}
        </div>
    </div>
}

export default Itinerarymaker