import React, { useEffect, useLayoutEffect, useState } from 'react'
import { ReactP5Wrapper } from "react-p5-wrapper";
import { Checkdevice, useWindowPosition } from '../../../../../../components';
import Commonstart from '../components/Commonstart/Commonstart';
var Cs = new Commonstart()
function Wordcube(props) {
    const { meta, ht, wt, msgsdata, startloaded, toggleguestbookbtn } = props
    const { basecolors, bgdesign, bgdesigncolors, cending, framecolor, highlightcolors, seed, shape } = meta
    const [p5sketch, setP5sketch] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loaded, setLoaded] = useState(false)

    const [activatepointer, setActivatepointer] = useState(false)
    const [sentenceobjs, setSentenceobjs] = useState([])
    const [selector, setSelector] = useState(-1)
    const [selecteddata, setSelecteddata] = useState({}) // msg that gt clicked, { txt, name}
    const [showsinglemsg, setShowsinglemsg] = useState(false)
    const device = Checkdevice()
    const scrollpos = useWindowPosition()

    const device_innerwt = {
        'MOBILE': '100%',
        'TABLET': 450,
        'WEB': 450,
    }
    const device_canvaht = {
        'MOBILE': ht * 1.15,
        'TABLET': ht * 1.15,
        'WEB': ht,
    }

    const init = (wt, ht) => {

        if (meta && Object.keys(meta).length > 0) {
            function determinepos(txts, numoftxt, width, height, canvawidth, canvaheight, i, j, inch) {
                var len = txts.length
                var originx = canvawidth / 2 - ((len / 2) * width)
                var originy = canvaheight / 2 - ((numoftxt.length / 2) * height)
                var x = (originx + (j * width) + width / 2)
                var y = originy + (i * height) + height / 2
                return { x: inch + x - (width / 2), y: inch + y - (height / 2) }
            }

            setP5sketch(
                <ReactP5Wrapper
                    sketch={(p5) => {
                        var a3Paper = { width: wt, height: ht }
                        var pages = msgsdata['pages'].slice()
                        var pagesinfo = msgsdata['pagesinfo'].slice()
                        var sentobjs = []

                        const { sorted } = msgsdata

                        if (cending === 'ASCEND') {
                            pages.sort((a, b) => a.length - b.length);
                            pagesinfo.sort((a, b) => a.length - b.length);
                        }
                        else if (cending === 'DESCEND') {
                            pages.sort((a, b) => a.length - b.length).reverse()
                            pagesinfo.sort((a, b) => a.length - b.length).reverse()
                        }

                        let a = a3Paper.height / pages.length
                        var longestsentence_len = -1
                        for (let i = 0; i < pages.length; i++) {
                            if (pages[i].length > longestsentence_len) {
                                longestsentence_len = pages[i].length
                            }
                        }

                        let b = a3Paper.width / longestsentence_len
                        var sqwt = (a < b ? a : b) * 0.95

                        function polygon(x, y, radius, npoints) {
                            let angle = p5.TWO_PI / npoints;
                            p5.beginShape();
                            for (let a = 0; a < p5.TWO_PI; a += angle) {
                                let sx = x + p5.cos(a) * radius;
                                let sy = y + p5.sin(a) * radius;
                                p5.vertex(sx, sy);
                            }
                            p5.endShape(p5.CLOSE);
                        }

                        p5.setup = () => {
                            setLoading(false)
                            startloaded(true)
                            p5.randomSeed(seed)
                            let w = a3Paper.width
                            let h = a3Paper.height
                            p5.createCanvas(w, h);
                            p5.noLoop()
                        }

                        p5.draw = () => {

                            p5.beginShape()
                            p5.background(framecolor)
                            p5.strokeWeight(0)
                            p5.fill('white')
                            p5.rect(0, 0, p5.width, p5.height)
                            p5.endShape(p5.CLOSE);

                            var allobjpos = [] // store all object pos
                            // DRAW MAIN OBJ
                            for (let i = 0; i < pages.length; i++) {

                                var sentence = pages[i]
                                var colors = []
                                var words = []
                                for (let j = 0; j < sentence.length; j++) {

                                    var sqht = sqwt
                                    var word = sentence[j]
                                    const { x, y } = determinepos(sentence, pages, sqwt, sqht, p5.width, p5.height, i, j, 0)
                                    var wordindex = sorted.indexOf(word)
                                    var color = ""
                                    if (wordindex > -1 && highlightcolors && highlightcolors.length > 0 && highlightcolors.length > wordindex) {
                                        color = highlightcolors[wordindex]
                                        p5.fill(color);
                                    }
                                    else {
                                        if (basecolors && basecolors.length > 0) {
                                            color = basecolors[p5.floor(p5.random(0, basecolors.length - 1))]
                                        }
                                        else {
                                            color = ""
                                        }
                                    }
                                    p5.fill(color)
                                    colors.push(color)
                                    words.push(word)

                                    p5.strokeWeight(1)
                                    p5.stroke('white')
                                    if (shape === 'CIRCLE') { p5.square(x, y, sqwt, sqwt * sqwt) }
                                    else if (shape === 'HEXAGON') { polygon(x + (sqwt / 2), y + (sqwt / 2), sqwt / 2, 6) }
                                    else if (shape === 'SQUARE') { p5.square(x, y, sqwt) }

                                    allobjpos.push(shape === 'HEXAGON' ? { x: x + sqwt / 10, y: y + sqwt / 10 } : { x, y })


                                    if (j === sentence.length - 1) {
                                        var startx = (p5.width / 2) - ((sentence.length / 2) * sqwt)
                                        var endx = p5.width - (startx + (sentence.length * sqwt))
                                        sentobjs.push({
                                            data: pagesinfo[i],
                                            y: y + sqwt,
                                            sqwt: sqwt,
                                            startx: startx,
                                            endx: endx,
                                            sentlen: sentence.length,
                                            colors: colors,
                                            words: words
                                        })
                                    }
                                }
                            }

                            p5.push()
                            p5.strokeWeight(1)
                            // DRAW BACKGROUND
                            if (bgdesign && bgdesign.length > 0) {
                                var bgsqwt = sqwt
                                for (let i = 0; i < p5.ceil((p5.height) / bgsqwt); i++) {
                                    for (let j = 0; j < p5.ceil((p5.width) / bgsqwt); j++) {
                                        p5.beginShape()
                                        basecolors && basecolors.length > 0
                                            ? p5.stroke(basecolors[p5.floor(p5.random(0, basecolors.length))])
                                            : p5.stroke('transparent')

                                        var x1 = (j * bgsqwt)
                                        var y1 = (i * bgsqwt)
                                        var x2 = bgsqwt + (j * bgsqwt)
                                        var y2 = bgsqwt * (i + 1)

                                        var overlapping = false
                                        for (let k = 0; k < allobjpos.length; k++) {
                                            if (p5.dist(x1, y1, allobjpos[k].x, allobjpos[k].y) < (sqwt * 0.9)) {
                                                overlapping = true
                                                break
                                            }
                                        }

                                        if (!overlapping) {
                                            p5.noFill();
                                            bgdesigncolors && bgdesigncolors.length > 0
                                                ? p5.stroke(bgdesigncolors[p5.floor(p5.random(0, bgdesigncolors.length))])
                                                : p5.stroke('transparent')
                                            if (bgdesign === 'CIRCULAR') {
                                                if (p5.random(1) < 0.25) {
                                                    p5.arc(x1 + bgsqwt / 2, y1, bgsqwt, bgsqwt, 0, 90)
                                                }
                                                else if (p5.random(1) > 0.25 && p5.random(1) < 0.5) {
                                                    p5.arc(x1 + bgsqwt / 2, y1, bgsqwt, bgsqwt, 90, 180)
                                                }
                                                else if (p5.random(1) > 0.5 && p5.random(1) < 0.75) {
                                                    p5.arc(x1 + bgsqwt / 2, y1, bgsqwt, bgsqwt, 180, 270)
                                                }
                                                else if (p5.random(1) > 0.75 && p5.random(1) < 1) {
                                                    p5.arc(x1 + bgsqwt / 2, y1, bgsqwt, bgsqwt, 270, 360)
                                                }
                                            }
                                            else if (bgdesign === 'SQUARE') {
                                                if (p5.random(1) < 0.25) {
                                                    p5.line(x1, y1, x2, y2)
                                                }
                                                else if (p5.random(1) > 0.25 && p5.random(1) < 0.5) {
                                                    p5.line(x1, y2, x2, y1)
                                                }
                                                else if (p5.random(1) > 0.5 && p5.random(1) < 0.75) {
                                                    p5.line(x2, y2, x1, y1)
                                                }
                                                else if (p5.random(1) > 0.75 && p5.random(1) < 1) {
                                                    p5.line(x2, y1, x1, y2)
                                                }
                                            }

                                        }
                                        p5.endShape(p5.CLOSE);
                                    }
                                }
                            }
                            setSentenceobjs(sentobjs)
                            setLoaded(true)
                        }
                    }}>
                </ReactP5Wrapper>

            )
        }
    }


    useEffect(() => {
        if (!loaded) {
            init(wt, device_canvaht[device])
            setLoading(true)
        }
    }, [msgsdata])

    useEffect(() => {
        setShowsinglemsg(Object.keys(selecteddata).length > 0 ? true : false)
    }, [selecteddata])

    useLayoutEffect(() => {
        if (showsinglemsg) {
            //get original value of body
            const originalStyle = window.getComputedStyle(document.body).overflowX;
            //prevent scrolling on mount
            document.body.style.overflowX = "hidden";
            // re-enable scrolling when component unmounts
            return () => (document.body.style.overflowX = originalStyle);
        }
    }, [showsinglemsg]); //empty array to ensures effect is only run when mount and unmount

    useEffect(() => {
        if (selector > -1 && activatepointer) {
            const { y, data, sqwt, startx, endx, sentlen, words, colors } = sentenceobjs[selector]
            setSelecteddata({ y, data, sqwt, startx, endx, sentlen, words, colors })
            window.scrollTo({ behavior: 'smooth', top: y - sqwt - 100 })
        }
    }, [selector])

    const up = () => { setSelector(selector === 0 ? sentenceobjs.length - 1 : selector - 1) }

    const down = () => { setSelector(selector + 1 === sentenceobjs.length ? 0 : selector + 1) }

    const close = () => {
        setSelecteddata({});
        setShowsinglemsg(false);
        setSelector(-1);
        setActivatepointer(false)
        toggleguestbookbtn()
    }

    return <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', overflowX: 'hidden', visibility: loading ? 'hidden' : 'visible' }}>
        {p5sketch}
        {props.children}
        {scrollpos > (ht / 2) && !activatepointer
            ? null
            : activatepointer
                ? Cs.pinkprompt(close, up, down)
                : Cs.pinkbtn(() => { setActivatepointer(true); down(); toggleguestbookbtn(); })}
        {showsinglemsg
            ? <div style={{
                position: 'absolute',
                zIndex: 2,
                paddingLeft: (selecteddata['sqwt'] * selecteddata['sentlen']),
                paddingRight: 0,
                paddingTop: selecteddata['sqwt'],
                paddingBottom: 0,
                top: 0,
                borderTop: `${selecteddata['y'] - selecteddata['sqwt']}px solid #000000d9`,
                borderRight: `calc( 100vw - ${selecteddata['sqwt'] * selecteddata['sentlen'] + selecteddata['startx'] - 10}px  ) solid #000000d9`,
                borderLeft: `${selecteddata['startx']}px solid #000000d9`,
                borderBottom: ` 1500vh solid #000000d9`
            }} />
            : null}

        {showsinglemsg // btm
            ? <div key={selecteddata['name']} style={{ position: 'absolute', top: `calc( ${selecteddata['y']}px )`, left: 0, width: '100%', height: '200vh', backgroundColor: 'transparent', overflow: 'hidden', zIndex: 2, paddingLeft: `calc( 50% - ${device_innerwt[device] / 2}px ) ` }}>
                <div style={{ height: '100%', width: device_innerwt[device], flexDirection: 'row', display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {selecteddata ?
                            <div style={{ display: 'flex', flexDirection: 'column', color: 'white' }}>
                                <div className="play2_btmname">{selecteddata['data']['name']}</div>
                                <div className="play2_btmtxt" style={{ letterSpacing: 1 }}>{selecteddata['data']['txt']}</div>
                                <div style={{ marginTop: 40, fontWeight: 'bold', marginLeft: 20 }}> Word Color Map</div>
                                {selecteddata['words'].map((w, x) => {
                                    return <div key={Math.random()} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 20 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', fontSize: 12, padding: '0px 10px' }}>{w}</div>
                                        <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: selecteddata['colors'][x], width: 25, height: 25, margin: 5 }} />
                                    </div>
                                })}
                            </div>
                            : null}
                    </div>
                </div>
            </div>
            : null}
    </div>
}
export default Wordcube