import React, { useEffect, useState } from 'react'
import { ReactP5Wrapper } from "react-p5-wrapper";
import { ScreenHeight, useWindowPosition } from '../../../../../../components';
import { P5REASSIGN_COUNTER, WEB_WEAVEBLISS } from '../../../../../../config/constants';
import { gb_dissectmsgs } from '../../../../../../config/gbcf';
import Commonstart from '../components/Commonstart/Commonstart';

const sizing_map = {
    'WEB': {
        emojisizefactor: 0.035,
        imgsizefactor: 0.2,
        frameconstant: 66
    },
    'TABLET': {
        emojisizefactor: 0.08,
        imgsizefactor: 0.35,
        frameconstant: 33
    },
    'MOBILE': {
        emojisizefactor: 0.08,
        imgsizefactor: 0.45,
        frameconstant: 20

    },
}
const GT_CENTER_ITEM = false
function textDim(text, ff, fs) {
    var tag = document.createElement('div')
    tag.style.whiteSpace = 'pre-wrap'
    tag.style.position = 'absolute'
    tag.style.left = '-99in'
    tag.style.fontFamily = ff
    tag.style.fontSize = fs
    tag.innerHTML = text

    document.body.appendChild(tag)
    var result = { wt: tag.clientWidth, ht: tag.clientHeight }
    document.body.removeChild(tag)
    return result;
}

var Cs = new Commonstart()


function populatemoreemoji(arr, numemoji) {
    var terms = arr.slice()
    var differences = numemoji - parseInt(terms.length, 10)
    if (differences > 0) {
        let y = 0
        for (let x = 0; x < differences; x++) {
            terms.push(terms[y])
            y = ((y + 1) === parseInt(terms.length, 10)) ? 0 : y + 1
        }
    }
    return terms
}

function Emoji(props) {
    const { artmainimg, artmaintxt, meta, ht, wt, msgsdata, d, startloaded, toggleguestbookbtn } = props
    const { seed, maincanvacolor, numemoji, centertype, fs, ff } = meta // framecolor, emojisizeconstant
    const [p5sketch, setP5sketch] = useState(null)
    const [loading, setLoading] = useState(true)
    const [activatepointer, setActivatepointer] = useState(false)
    const [tf, setTf] = useState({})
    const [emoji_msgobj_map, setEmoji_msgobj_map] = useState({})
    const [selectedemojiuri, setSelectedemojiuri] = useState('')
    const localht = ScreenHeight()
    const scrollpos = useWindowPosition()

    const init = (wt, ht) => {
        var initemoji = () => {
            const { tfidf, tf, emoji_msgobj_map } = gb_dissectmsgs(msgsdata)
            return { tfidf: tfidf, terms: populatemoreemoji(Object.keys(tf), numemoji), tf, emoji_msgobj_map }
        }
        if (meta && Object.keys(meta).length > 0) {
            const { tfidf, terms, tf, emoji_msgobj_map } = initemoji()
            const emojisize = wt * sizing_map[d]['emojisizefactor']
            const userimgsize = Math.floor(wt * sizing_map[d]['imgsizefactor'])
            var newfontsize = fs
            var smaller_userimgsize = userimgsize * 0.90 // might need to determine font family
            for (let i = 1; i < 500; i++) {
                const { wt } = textDim(artmaintxt, ff, (fs + i) + 'px')
                if (wt < smaller_userimgsize) {
                    newfontsize = fs + i
                }
                else {
                    break
                }
            }

            for (let i = 0; i < 1000; i++) {
                const { ht } = textDim(artmaintxt, ff, (fs - i) + 'px')
                if (ht > smaller_userimgsize) {
                    newfontsize = fs - i
                }
                else {
                    break
                }
            }
            setTf(tf)
            setEmoji_msgobj_map(emoji_msgobj_map)
            setP5sketch(
                <div style={{ position: 'relative' }}>
                    <ReactP5Wrapper
                        sketch={(p5) => {

                            var allimgs = []
                            var termsdata = []
                            let div
                            var portraitimg

                            const frameconstant = sizing_map[d]['frameconstant']

                            function centeritem(artmainimg, portraitimg, artmaintxt, ff, newfontsize) {
                                const scalefactor = 1

                                termsdata.push({
                                    img: artmainimg + '?' + Math.random(),
                                    loadedimg: portraitimg,
                                    x: wt / 2,
                                    y: ht / 2,
                                    divx: (((wt / 2)) - (userimgsize / 2)) * scalefactor,
                                    divy: (((ht / 2)) - (userimgsize / 2)) * scalefactor,
                                    width: userimgsize * scalefactor,
                                    height: userimgsize * scalefactor
                                })

                                if (centertype === 'image') {
                                    div = p5.createDiv();
                                    div.style('width', `calc( ${userimgsize}px * ${scalefactor})`)
                                    div.style('height', `calc( ${userimgsize}px * ${scalefactor})`)
                                    div.style('background-image', "url(" + termsdata[0].img + ")")
                                    div.style('background-size', 'cover')
                                    div.style('background-position', 'center')
                                    div.style('background-repeat', 'no-repeat')
                                    div.style('border-radius', '100%')
                                }
                                else {
                                    div = p5.createDiv(artmaintxt);
                                    div.style('display', 'flex')
                                    div.style('place-content', 'center')
                                    div.style('align-items', 'center')
                                    div.style('text-align', 'center')
                                    div.style('width', `calc( ${userimgsize}px * ${scalefactor})`)
                                    div.style('height', `calc( ${userimgsize}px * ${scalefactor})`)
                                    div.style('background-size', 'contain')
                                    div.style('background-position', 'center')
                                    div.style('background-repeat', 'no-repeat')
                                    div.style('border-radius', '100%')
                                    div.style('white-space', 'pre-wrap')
                                    div.style('font-family', ff)


                                    // div.style('font-size', `calc( ${fs}px * ${localht * fs_map[device]} )`)
                                    div.style('font-size', `calc( ${newfontsize}px  )`)
                                }

                                div.position(termsdata[0].divx, termsdata[0].divy)
                                p5.imageMode(p5.CENTER);
                            }

                            p5.preload = () => {
                                // loading of image is problem
                                
                                for (let x = 0; x < terms.length; x++) { allimgs.push(p5.loadImage(terms[x])) }
                                if (artmainimg.length > 0) {
                                    portraitimg = p5.loadImage(artmainimg + '?' + Math.random()) // stopped here
                                }
                                else {
                                    portraitimg = p5.loadImage(WEB_WEAVEBLISS)
                                }
                            }

                            p5.setup = () => {
                                p5.randomSeed(seed)
                                p5.colorMode(p5.HSB);
                                p5.noStroke();
                                p5.createCanvas(wt, ht)
                                p5.smooth();
                                p5.clear()
                                p5.angleMode(p5.DEGREES)
                                p5.fill(maincanvacolor)

                                if (GT_CENTER_ITEM) {
                                    p5.rect(frameconstant, frameconstant, wt - frameconstant, ht - frameconstant) //  with centeritem use this as pos
                                    centeritem(artmainimg, portraitimg, artmaintxt, ff, newfontsize, termsdata)
                                }
                                else {
                                    p5.rect(0, 0, wt, ht)
                                }

                                for (let i = 0; i < allimgs.length; i++) {
                                    const img = allimgs[i]
                                    var counter = 0
                                    while (true) {
                                        var img_radius = (emojisize * (tfidf[terms[i]] + 1)) / 2

                                        var new_x
                                        var new_y
                                        if (GT_CENTER_ITEM) {
                                            // with centeritem use this as pos
                                            new_x = p5.random(frameconstant, wt - frameconstant)
                                            new_y = p5.random(frameconstant, ht - frameconstant)
                                        }
                                        else {
                                            // without centeritem use this
                                            new_x = p5.random(0, wt - (emojisize * (tfidf[terms[i]] + 1)))
                                            new_y = p5.random(0, ht - (emojisize * (tfidf[terms[i]] + 1)))
                                        }
                                        var overlapping
                                        for (let k = 0; k < termsdata.length; k++) {
                                            overlapping = p5.dist(termsdata[k].x, termsdata[k].y, new_x, new_y) < (termsdata[k].width / 2 + img_radius) ? true : false
                                            if (overlapping) { break }
                                        }
                                        if (!overlapping) {
                                            const emojicircle = {
                                                img: img,
                                                x: new_x,
                                                y: new_y,
                                                width: img_radius * 2,
                                                height: img_radius * 2
                                            }
                                            termsdata.push(emojicircle)
                                            p5.image(emojicircle.img, emojicircle.x, emojicircle.y, emojicircle.width, emojicircle.height);
                                            break
                                        }
                                        counter = counter + 1;
                                        if (counter === P5REASSIGN_COUNTER) { break }
                                    }
                                }
                                p5.noLoop()
                                setLoading(false)
                                startloaded(true)
                            }
                        }} >
                    </ReactP5Wrapper>
                </div>
            )
        }
    }

    useEffect(() => {
        init(wt, ht)
        setLoading(true)
    }, [msgsdata])

    useEffect(() => {
        init(wt, localht)
        setLoading(true)
    }, [d])

    const close = () => {
        setActivatepointer(false)
    }

    return <div style={{ backgroundColor: maincanvacolor, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', overflowX: 'hidden', visibility: loading ? 'hidden' : 'visible' }}>
        {p5sketch}
        {props.children}
        {scrollpos > (ht / 2) && !activatepointer
            ? null
            : activatepointer ? Cs.pinkprompt(close, null, null) : Cs.pinkbtn(() => { setActivatepointer(true); toggleguestbookbtn(); })}
        {activatepointer
            ? <div style={{ position: 'absolute', top: 0, left: 0, backgroundColor: '#000000d9', width: '100%', height: '1500vh', zIndex: 2 }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', placeContent: 'center', margin: '5vh 0px' }}>
                    {Object.keys(tf).map((uri, i) => {
                        var num = tf[uri]
                        var emojirow = []
                        for (let j = 0; j < num; j++) {
                            emojirow.push(<img key={'emoemo' + j} src={uri} width={25} height={25} style={{ margin: '5px' }} alt="" />)
                        }
                        return <div key={'emokey' + i} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', width: '90%', height: '100%', placeContent: 'flex-start', alignContent: 'center', flexWrap: 'wrap', paddingBottom: 15 }}>
                            <div
                                onClick={() => { setSelectedemojiuri(uri) }}
                                style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', placeContent: 'flex-start', alignContent: 'center', flexWrap: 'wrap' }}>
                                {emojirow}
                                <div style={{ color: 'white', display: 'flex', alignItems: 'center', fontSize: 20 }}>{'x' + emojirow.length}</div>
                            </div>
                            {selectedemojiuri === uri
                                ? <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid white', borderRadius: 10, marginBottom: 15 }}>
                                    {emoji_msgobj_map[uri].map(({ emojiascii, name, txt }, k) => {
                                        return <div key={k + 'emojiobjitems'} style={{ display: 'flex', flexDirection: 'column', color: 'white', padding: 10 }}>
                                            <div>{emojiascii}</div>
                                            <div>{name}</div>
                                        </div>
                                    })}
                                </div>
                                : null}
                        </div>
                    })}
                </div>
            </div>
            : null}
    </div>


}
export default Emoji