import React, { useEffect, useState, useRef } from 'react';
import { Header, MainMenu } from '../components';
import { EMPTY, TELEGRAM_WEDDINGMARKETPLACE, COLLECTION_VENDORTYPE, EMPTYSPACE, ARROWDOWN_SMALL, ARROWUP_SMALL, d_fs, f_fs, e_fs, FS_LS_MAP, FS_LH_MAP, CURRENCY_ARR, MARKET_NUMMTH_CHARMTH_MAP, FILTERDATE_MAP, IMPORTANT, TELEGRAM_ICON_LARGE, MAIN_PINK_COLOR, STARTUP_NAME } from '../../../config/constants';
import { READ_MARKETLISTING, READ_MARKETLISTINGU, READ_USERMARKETLISTING, DELETE_USERMARKETLISTINGSINGLE, ADDLISTING_MARKETLISTING, ADDLISTING_MARKETLISTINGIMG } from '../../../config/api';
import { Retrieve_personal_info } from '../../../config/commonfunctions';
import { Checkdevice, Processmoney, Jfmodal, Loading } from '../../../components';
import { BUTTONSTYLE } from '../../../config/cssconstants';
import { ControlLabel, FormGroup } from "react-bootstrap"
import { market } from '../../../assets/homeuser';
import axios from "axios";
import './Market.css'

const loginsell = 'Please login to sell.'

const loginlisting = 'Please login to see your listing.'

const modalhowitworks = `
    Fill up the form, and click submit.
    You will be redirected to whatsapp, send the pre-written message to the number and do not edit the message. 
    Your whatsapp number will not be stored in our database unless you have selected whatsapp as a method of contact. 
 `
const introductionhowitworks = `
    This is a consumer to consumer wedding marketplace. 
    If you are letting go your wedding package or looking to buy anything wedding related, you are at the right place.
`

String.prototype.replaceAt = function (index, replacement) {  // eslint-disable-line
    if (index >= this.length) return this.valueOf();
    var chars = this.split('');
    chars[index] = replacement;
    return chars.join('');
}
const MAXTITLE = 100
const MAXDESCRIP = 5000
const CURRDT = new Date()
const CURRMTH = CURRDT.getMonth()
const CURRYR = CURRDT.getFullYear()
const CONTACT = '6582738761'
const SEPARATOR = '¾'
const DESCRIP_SEPARATOR = '¤'
const SCHEME_MAP = {
    1: [[0, 3], [3, 2], [2, 6], [4, 3], [3, 6]],
    2: [[0, 4], [3, 2], [0, 1], [4, 2], [2, 7]],
    3: [[0, 1], [3, 2], [5, 2], [7, 2], [3, 2]],
    4: [[1, 2], [2, 5], [5, 2], [3, 1], [1, 7]],
    5: [[4, 3], [7, 2], [7, 4], [3, 2], [1, 6]],
    6: [[1, 0], [1, 3], [4, 7], [2, 5], [2, 3]],
    7: [[4, 6], [5, 3], [2, 6], [4, 8], [7, 4]],
    8: [[1, 2], [2, 5], [4, 1], [1, 7], [5, 7]],
    9: [[7, 0], [1, 2], [4, 5], [5, 1], [7, 3]]
}

const { token, _id } = Retrieve_personal_info()
// https://unsplash.com/photos/z4n8CGRuzOA
// https://unsplash.com/photos/fEBBSuFusUU
// https://unsplash.com/photos/vC8wj_Kphak

var LOCAL_COLLECTION_VENDORTYPE = COLLECTION_VENDORTYPE.slice()
LOCAL_COLLECTION_VENDORTYPE[0] = { value: 'All' }

var LOCAL_COLLECTION_VT_WITHOUTALL = COLLECTION_VENDORTYPE.slice()
LOCAL_COLLECTION_VT_WITHOUTALL.shift()

const DEFAULTHEADER_MAP = {
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    height: '350px',
    position: 'relative',
    overflow: 'hidden',
    backgroundPosition: '50% 45%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
}

const VT_TAG_MAP = {
    'Venue': ['Lunch', 'Dinner', 'Hotel', 'Restaurants', 'Garden'],
    'Photographers': ['Pre-Wedding', 'Actual Day', 'ROM'],
    'Videographers': ['Pre-Wedding', 'Actual Day', 'ROM'],
    'Dress Attire': ['Gowns', 'White', 'Kua'],
}

const TAG_MAP = {
    'Credit': [],
    'Package': [],
    'Postponable': [],
    'Local': [],
    'Overseas': []
}

const SORT_MAP = {
    '$ High-Low': [],
    '$ Low-High': [],
}

const DEVICE_VTBTNS_ROOT_WT = {
    'WEB': {
        rootwt: 750,
        li_wt: 750 / 7,
    },
    'TABLET': {
        rootwt: 600,
        li_wt: 600 / 6,
    },
    'MOBILE': {
        rootwt: '100%',
        li_wt: '30%',
    },
}

const MAINBODY_CSS = { width: '-webkit-fill-available', borderRadius: 5, display: 'flex', flexDirection: 'row', border: 'thin solid rgba(25, 25, 25, 0.15)', boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px', margin: '0px 10px', padding: '0px 10px', flexWrap: 'wrap', justifyContent: 'start', cursor: 'pointer' }

const DEVICE_MTHWT_MAP = { 'MOBILE': 22, 'TABLET': 40, 'WEB': 50 }

const DEVICE_MODALMTHWT_MAP = { 'MOBILE': 22, 'TABLET': 40, 'WEB': 40 }

const dataURLToBlob = function (dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
        var parts1 = dataURL.split(',');
        var contentType1 = parts1[0].split(':')[1];
        var raw1 = parts1[1];
        return new Blob([raw1], { type: contentType1 });
    }

    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
}

function Selling({ data, device, authuser }) {
    return data && data.length > 0 ? data.map((item, index) => {
        const { vt, tags, price, oprice, title, descrip, currency, _id } = item
        var root_css
        if (device === 'WEB') {
            root_css = { display: 'flex', flexDirection: 'column', boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px', border: 'thin solid rgba(25, 25, 25, 0.15)', borderRadius: 5, margin: '30px 0px 0px 0px', height: 'max-content', textDecoration: 'none', color: '#707070' }
        }
        else if (device === 'TABLET') {
            root_css = { display: 'flex', flexDirection: 'column', boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px', border: 'thin solid rgba(25, 25, 25, 0.15)', borderRadius: 5, margin: '30px 0px 0px 0px', height: 'max-content', textDecoration: 'none', color: '#707070' }
        }
        else {
            root_css = { display: 'flex', flexDirection: 'column', boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px', border: 'thin solid rgba(25, 25, 25, 0.15)', borderLeft: 'none', borderRight: 'none', margin: '50px 0px 0px 0px', height: 'max-content', textDecoration: 'none', color: '#707070' }
        }

        return (
            <a key={item.vt + index} style={root_css} target={"_blank"} href={'marketlookup/' + _id} >

                <div style={{ height: 150, display: 'flex', flexDirection: 'row', }}>

                    <div style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', width: '70%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', height: '30%' }}>
                            <div className="vendortype" style={{ minWidth: DEVICE_VTBTNS_ROOT_WT[device].li_wt, justifyContent: 'center', textAlign: 'center', height: 'max-content', display: 'flex', flexDirection: 'column', padding: 3, margin: 3, borderRadius: 5, boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px', fontSize: f_fs, border: 'thin solid #707070' }}>
                                {vt}
                            </div>
                            <div className="title" style={{ margin: '6px 6px 0px 6px', color: '#707070', fontWeight: 'bold', overflow: 'hidden' }}>
                                {title}
                            </div>
                        </div>
                        <div className="description" style={{ margin: 6, color: '#707070', height: '70%', overflow: 'hidden', whiteSpace: 'break-spaces' }}>{descrip}</div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'center', alignItems: 'space-between', height: '100%' }}>
                        <div style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', height: '70%', fontFamily: 'lato' }}>
                            {oprice ? <del style={{ fontSize: d_fs * 0.8 }}>{'$' + Processmoney(oprice) + ' ' + currency}</del> : null}
                            <div style={{ fontSize: d_fs, color: 'rgb(226, 90, 90)', fontWeight: 'bold', textAlign: 'center' }}>{'$' + Processmoney(price) + ' ' + currency}</div>
                        </div>
                    </div>

                </div>

                <div style={{ cursor: 'pointer', borderTop: 'thin solid rgba(25, 25, 25, 0.15)', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', padding: '5px 0px', height: 40 }}>
                    {tags.length > 0 && tags[0].length > 0 && tags.map((item, index) => {
                        return <li
                            key={item + index}
                            style={{ width: 'max-content', display: 'flex', margin: 3, padding: '3px 5px', letterSpacing: FS_LS_MAP[f_fs], borderRadius: 2, height: 'max-content', cursor: 'pointer', color: 'white', backgroundColor: '#707070', fontSize: f_fs }}
                        >{item}</li>
                    })}
                </div>

            </a>
        )

    }) : null
}

function Creation({ device, token }) {

    const [title, setTitle] = useState(EMPTY)
    const [vt, setVt] = useState(LOCAL_COLLECTION_VT_WITHOUTALL[0].value) // first vt
    const [delievereabledates, setDelieverabledates] = useState([]) // each element strictly no comma 
    const [tags, setTags] = useState([]) // each element strictly no comma 
    const [currency, setCurrency] = useState(CURRENCY_ARR[0])
    const [oprice, setOprice] = useState(EMPTY)
    const [price, setPrice] = useState(EMPTY)
    const [descrip, setDescrip] = useState(EMPTY)
    const [vname, setVname] = useState(EMPTY)
    const [vlink, setVlink] = useState(EMPTY)
    const [location, setLocation] = useState(EMPTY)
    const [link1, setLink1] = useState(EMPTY)
    const [link2, setLink2] = useState(EMPTY)
    const [link3, setLink3] = useState(EMPTY)
    const [contact, setContact] = useState(EMPTY)
    const [telegram, setTelegram] = useState(EMPTY)
    const [destroydate, setDestroydate] = useState('Manually Destroy')
    const [imgs, setImgs] = useState([]) // img 
    const [error, setError] = useState('')
    const [errorarr, setErrorarr] = useState({})
    const imgref = useRef(null)

    function updateModalfilter(item) {
        // return () => item === delievereabledates ? setDelieverabledates(EMPTY) : setDelieverabledates(item)
        return () => {

            var index = delievereabledates.indexOf(item)

            var local_dd = delievereabledates.slice()
            if (index > -1) {
                local_dd.splice(index, 1)
            }
            else {
                local_dd.push(item)
            }
            setDelieverabledates(local_dd)
        }
    }
    function updateTag(item) {
        if (tags.indexOf(item) === -1) {
            setTags([...tags, item])
        }
        else {
            var new_tags = tags.slice()
            new_tags.splice(tags.indexOf(item), 1)
            setTags(new_tags)
        }
    }
    function processid(_id) {

        function randomIntFromInterval(min, max) { // min and max included 
            return Math.floor(Math.random() * (max - min + 1) + min);
        }

        var scheme_index = randomIntFromInterval(1, 9) // generate random scheme
        var str_id = _id.toString()

        var curr_scheme = SCHEME_MAP[scheme_index]
        for (let x = 0; x < curr_scheme.length; x++) {
            var from_index = curr_scheme[x][0]
            var to_index = curr_scheme[x][1]
            var charfrom = str_id.charAt(from_index)
            var charto = str_id.charAt(to_index)
            str_id = str_id.replaceAt(from_index, charto);
            str_id = str_id.replaceAt(to_index, charfrom);
        }
        return [scheme_index, str_id]


    }
    function submit() { // eslint-disable-line
        if (
            (descrip.length < MAXDESCRIP && descrip.length > 0)
            && (title.length < MAXTITLE && title.length > 0)
            && (telegram.length > 0 || contact.length > 0)
            && (price.length > 0)
        ) {

            var arr_descrip = descrip.replace('&', 'and').split('\n')
            var new_descrip = ''
            if (arr_descrip.length > 1) {
                for (let i = 0; i < arr_descrip.length; i++) {
                    if (arr_descrip[i].length === 0) new_descrip = new_descrip + DESCRIP_SEPARATOR
                    else new_descrip = new_descrip + arr_descrip[i] + DESCRIP_SEPARATOR
                }
                new_descrip = new_descrip.slice(0, -1)
            }
            else new_descrip = arr_descrip[0]

            new_descrip = new_descrip.replace('&', 'and')

            var new_title = title.replace('&', 'and')

            const dt = new Date().getTime()

            var processob = processid(_id)
            const scheme = processob[0]
            const encrypted_userid = processob[1]
            const listinginfo =
                SEPARATOR +
                new_title + SEPARATOR +
                vt + SEPARATOR +
                delievereabledates + SEPARATOR +
                tags + SEPARATOR +
                currency + SEPARATOR +
                oprice + SEPARATOR +
                price + SEPARATOR +
                new_descrip + SEPARATOR +
                vname + SEPARATOR +
                vlink + SEPARATOR +
                location + SEPARATOR +
                link1 + SEPARATOR +
                link2 + SEPARATOR +
                link3 + SEPARATOR +
                contact + SEPARATOR +
                dt + scheme + SEPARATOR +
                encrypted_userid + SEPARATOR +
                telegram + SEPARATOR +
                destroydate + SEPARATOR

            const result = '[DO_NOT_EDIT]' + listinginfo + '[DO_NOT_EDIT]'
            window.open('https://api.whatsapp.com/send?phone=' + CONTACT + '&text=' + result)
        }
    }
    function create() {

        function checklistingdata() {
            var result = true
            try {
                var arr = []
                if (title.length < 4) { arr.push('Title too short.'); result = false; }
                if (title.length > 100) { arr.push('Title too long'); result = false; }
                if (oprice.toString().length > 8) { arr.push('Original price is too unrealistic.'); result = false; }
                if (price.toString().length < 1) { arr.push('Asking Price is too unrealistic.'); result = false; }
                if (price.toString().length > 8) { arr.push('Asking Price is too unrealistic.'); result = false; }
                if (descrip.length < 1) { arr.push('Description too short.'); result = false; }
                if (descrip.length > 5000) { arr.push('Description too long.'); result = false; }
                if (vname.length > 50) { arr.push('Description too long.'); result = false; }
                if (vlink.length > 50) { arr.push('Vendor link too long.'); result = false; }
                if (location.length > 50) { arr.push('Location too long.'); result = false; }
                if (link1.length > 50) { arr.push('External link 1 too long.'); result = false; }
                if (link2.length > 50) { arr.push('External link 2 too long.'); result = false; }
                if (link3.length > 50) { arr.push('External link 3 too long.'); result = false; }
                if (contact.toString().length > 50) { arr.push('Contact too long.'); result = false; }
                if (telegram.toString().length < 1) { arr.push('Telegram username cannot be empty.'); result = false; }
                if (telegram.toString().length > 50) { arr.push('Telegram id too long.'); result = false; }
                if (result) setErrorarr(arr)
            }
            catch (e) {
                result = false;
            }
            return result
        }

        if (checklistingdata()) {
            let fd = new FormData()

            fd.append('title', title)
            fd.append('vt', vt)
            fd.append('delievereabledates', delievereabledates)
            fd.append('tags', tags)
            fd.append('currency', currency)
            fd.append('oprice', oprice)
            fd.append('price', price)
            fd.append('descrip', descrip)
            fd.append('vname', vname)
            fd.append('vlink', vlink)
            fd.append('location', location)
            fd.append('link1', link1)
            fd.append('link2', link2)
            fd.append('link3', link3)
            fd.append('contact', contact)
            fd.append('telegram', telegram)
            fd.append('destroydate', destroydate)

            for (let i = 0; i < imgs.length; i++) { fd.append('img', dataURLToBlob(imgs[i])) }

            try {
                let options = {
                    method: 'POST',
                    url: imgs.length > 0 ? ADDLISTING_MARKETLISTINGIMG : ADDLISTING_MARKETLISTING,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: imgs.length > 0 ? fd : {
                        title,
                        vt,
                        delievereabledates,
                        tags,
                        currency,
                        oprice,
                        price,
                        descrip,
                        vname,
                        vlink,
                        location,
                        link1,
                        link2,
                        link3,
                        contact,
                        telegram,
                        destroydate
                    }
                }
                axios(options).then((res) => {
                    const { status } = res
                    if (status === 200) { }
                    else { alert('Fail to create listing') }
                }).catch((e) => { alert('Fail to create listing') })
            }
            catch (e) { alert('Fail to create listing') }
        }
        else {
            return
        }


    }

    const imginput = <input
        ref={imgref}
        accept="image/*"
        className="inputfile"
        id="formControlsFile"
        type='file'
        multiple="multiple"
        name="input-file"
        style={{ opacity: 0, position: 'absolute', border: 'thin solid #707070', padding: 0, margin: 5 }}
        onChange={(e) => {

            if (Array.from(e.target.files).length > 2) {
                setError(`Cannot upload files more than 2`);
                setTimeout(() => {
                    setError('')
                }, 1000)
                return;
            }
            for (let i = 0; i < e.target.files.length; i++) {
                var reader = new FileReader()
                let file = e.target.files[i]
                reader.onload = (e) => {
                    var img = document.createElement("img");
                    img.onload = () => {
                        if (file.size < 20000000) { // 2MB : 2097152, 20MB : 20000000
                            setImgs((e) => { return [...e, img.src] })
                        }
                        else {
                            setError(`Image size must be less than 20MB`);
                            setTimeout(() => {
                                setError('')
                            }, 3000)
                        }
                    }
                    img.src = e.target.result;
                }
                reader.readAsDataURL(file);
            }
            e.target.value = null;
        }}
    />

    const imgsdiv = imgs.map((img, i) => {
        return <div key={'img' + i} style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover', width: 100, height: 100, margin: 5, borderRadius: 5, }} />
    })

    return (
        <div style={{ display: 'flex', flexDirection: 'column', color: '#707070', padding: '2%', width: '100%' }}>

            <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'row', }}>How it works?</div>
            </ControlLabel>
            <div style={{ margin: '5px 0px 15px' }}>{modalhowitworks}</div>

            <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'row', }}>Title {IMPORTANT}</div>
                <div>{title.length + ' / 100'}</div>
            </ControlLabel>
            <input
                style={{ width: '100%', height: 30, overflow: 'hidden', margin: '0px 0px 15px 0px', overflowWrap: 'break-word', resize: 'none', outline: 'none', borderColor: 'transparent', border: '1px solid #ccc', marginTop: 5 }}
                value={title}
                onChange={(event) => {
                    const { value } = event.target
                    if (value.length <= MAXTITLE) setTitle(event.target.value)
                }}
            />

            <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>Category {IMPORTANT}</ControlLabel>
            <FormGroup controlId="formControlsTextarea" style={{ width: '100%', padding: 0 }}>
                <select value={vt} onChange={(e) => { setVt(e.target.value) }} name="location" style={{ backgroundColor: 'transparent', width: '100%', height: 30, outline: 'none', fontSize: 13, borderRadius: 5 }}>
                    {LOCAL_COLLECTION_VT_WITHOUTALL.map((item, index) => {
                        const { value } = item
                        return <option key={value + index} value={value}> {value}</option>
                    })}
                </select>
            </FormGroup>

            <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'row', }}>Description {IMPORTANT}</div>
                <div>{descrip.length + ' / 5000'}</div>
            </ControlLabel>
            <textarea
                style={{ width: '100%', minHeight: 'max-content', height: 100, margin: '0px 0px 15px 0px', overflowWrap: 'break-word', resize: 'none', outline: 'none', borderColor: 'transparent', border: '1px solid #ccc', marginTop: 5 }}
                value={descrip}
                onChange={(event) => {
                    const { value } = event.target
                    console.log(value.length)
                    if (value.length <= MAXDESCRIP) setDescrip(event.target.value)
                }}
            />

            <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>Currency {IMPORTANT}</ControlLabel>
            <FormGroup controlId="formControlsTextarea" style={{ width: '100%', padding: 0 }}>
                <select value={currency} onChange={(e) => { setCurrency(e.target.value) }} name="currency" style={{ backgroundColor: 'transparent', width: '100%', height: 30, outline: 'none', fontSize: 13, borderRadius: 5 }}>
                    {CURRENCY_ARR.map((value, index) => {
                        return <option key={value + index} value={value}> {value}</option>
                    })}
                </select>
            </FormGroup>

            <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>Contact{IMPORTANT}</ControlLabel>
            <div style={{ ...MAINBODY_CSS, margin: '0px 0px 15px 0px', padding: 5 }}>
                <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>Telegram Username</ControlLabel>
                <input
                    style={{ width: '100%', height: 30, overflow: 'hidden', margin: '0px 0px 15px 0px', overflowWrap: 'break-word', resize: 'none', outline: 'none', borderColor: 'transparent', border: '1px solid #ccc', marginTop: 5 }}
                    value={telegram}
                    placeholder={`@${STARTUP_NAME.toLocaleLowerCase()}`}
                    onChange={(event) => { setTelegram(event.target.value) }}
                />
                <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>Your Whatsapp Contact</ControlLabel>
                <input
                    style={{ width: '100%', height: 30, overflow: 'hidden', margin: '0px 0px 15px 0px', overflowWrap: 'break-word', resize: 'none', outline: 'none', borderColor: 'transparent', border: '1px solid #ccc', marginTop: 5 }}
                    value={contact}
                    placeholder={'65XXXXXXXX ( optional )'}
                    onChange={(event) => { setContact(event.target.value) }}
                />
            </div>

            <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>Price {IMPORTANT}</ControlLabel>
            <input
                style={{ width: '100%', height: 30, overflow: 'hidden', margin: '0px 0px 15px 0px', overflowWrap: 'break-word', resize: 'none', outline: 'none', borderColor: 'transparent', border: '1px solid #ccc', marginTop: 5 }}
                value={price}
                placeholder={'50000'}
                onChange={(event) => { setPrice(event.target.value) }}
            />

            <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>Original Price</ControlLabel>
            <input
                style={{ width: '100%', height: 30, overflow: 'hidden', margin: '0px 0px 15px 0px', overflowWrap: 'break-word', resize: 'none', outline: 'none', borderColor: 'transparent', border: '1px solid #ccc', marginTop: 5 }}
                value={oprice}
                placeholder={'70000'}
                onChange={(event) => { setOprice(event.target.value) }}
            />

            <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>Delieverable Month</ControlLabel>
            <div style={{ ...MAINBODY_CSS, margin: '0px 0px 15px 0px', padding: 0 }}>
                {Object.keys(FILTERDATE_MAP).map((year, index_x) => {
                    return (
                        <div key={year + index_x} style={{ display: 'flex', flexDirection: 'column', margin: 5, padding: 5 }}>
                            <div style={{ fontWeight: 'bold', color: '#707070', fontSize: 16 }}>{year}</div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {FILTERDATE_MAP[year].map((mth, index_y) => {
                                    const mth_str = device === 'MOBILE' ? mth : MARKET_NUMMTH_CHARMTH_MAP[mth]
                                    if (parseInt(mth, 10) < parseInt(CURRMTH, 10) + 1 && parseInt(year, 10) === parseInt(CURRYR, 10)) {
                                        return (
                                            <del
                                                key={mth + ' / ' + year + index_y}
                                                style={{ width: DEVICE_MODALMTHWT_MAP[device], height: DEVICE_MODALMTHWT_MAP[device], backgroundColor: '#80808082', alignItems: 'center', placeContent: 'center', display: 'flex', padding: 5, marginRight: 5, borderRadius: 5, cursor: 'pointer', border: 'thin solid #707070' }}
                                            >{mth_str}</del>
                                        )
                                    }
                                    else {
                                        return (
                                            <div
                                                key={mth + ' / ' + year + index_y}
                                                className={delievereabledates.indexOf(mth + ' / ' + year) > -1 ? 'bsbtn_selected' : 'bsbtn'}
                                                style={{ width: DEVICE_MODALMTHWT_MAP[device], height: DEVICE_MODALMTHWT_MAP[device], alignItems: 'center', placeContent: 'center', display: 'flex', padding: 5, marginRight: 5, borderRadius: 5, cursor: 'pointer', border: 'thin solid #707070' }}
                                                onClick={updateModalfilter(mth + ' / ' + year)}
                                            >{mth_str}</div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>

            <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>Tags</ControlLabel>
            <div style={{ ...MAINBODY_CSS, margin: '0px 0px 15px 0px', padding: 0 }}>
                {(Object.keys(TAG_MAP).concat(VT_TAG_MAP[vt] ? VT_TAG_MAP[vt] : null)).map((item, index) => {
                    if (item) {
                        return (
                            <li
                                key={item + index}
                                className={tags.indexOf(item) > -1 ? 'bsbtn_selected' : 'bsbtn'}
                                style={{ width: DEVICE_VTBTNS_ROOT_WT[device].li_wt, display: 'flex', margin: 5, padding: 5, borderRadius: 5, height: 'max-content', cursor: 'pointer' }}
                                onClick={() => updateTag(item)}>{item}</li>
                        )
                    }
                    else return null
                })}
            </div>

            <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>Vendor's Name</ControlLabel>
            <input
                style={{ width: '100%', height: 30, overflow: 'hidden', margin: '0px 0px 15px 0px', overflowWrap: 'break-word', resize: 'none', outline: 'none', borderColor: 'transparent', border: '1px solid #ccc', marginTop: 5 }}
                value={vname}
                onChange={(event) => { setVname(event.target.value) }}
            />

            <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>Vendor's Link</ControlLabel>
            <input
                style={{ width: '100%', height: 30, overflow: 'hidden', margin: '0px 0px 15px 0px', overflowWrap: 'break-word', resize: 'none', outline: 'none', borderColor: 'transparent', border: '1px solid #ccc', marginTop: 5 }}
                value={vlink}
                placeholder={`Must include https or http, eg. https://www.${WEBSITE_URL}`}
                onChange={(event) => { setVlink(event.target.value) }}
            />

            <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>Location</ControlLabel>
            <input
                style={{ width: '100%', height: 30, overflow: 'hidden', margin: '0px 0px 15px 0px', overflowWrap: 'break-word', resize: 'none', outline: 'none', borderColor: 'transparent', border: '1px solid #ccc', marginTop: 5 }}
                value={location}
                onChange={(event) => { setLocation(event.target.value) }}
            />

            <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>
                <div style={{ display: 'flex', alignItems: 'center', paddingRight: 5 }}> Link 1</div>
                <div style={{ fontSize: 10, display: 'flex', alignItems: 'center' }}> {' ( Link To Your Package Detail, Eg Google Drive )'}</div>
            </ControlLabel>
            <input
                style={{ width: '100%', height: 30, overflow: 'hidden', margin: '0px 0px 15px 0px', overflowWrap: 'break-word', resize: 'none', outline: 'none', borderColor: 'transparent', border: '1px solid #ccc', marginTop: 5 }}
                value={link1}
                onChange={(event) => { setLink1(event.target.value) }}
            />

            <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>Link 2</ControlLabel>
            <input
                style={{ width: '100%', height: 30, overflow: 'hidden', margin: '0px 0px 15px 0px', overflowWrap: 'break-word', resize: 'none', outline: 'none', borderColor: 'transparent', border: '1px solid #ccc', marginTop: 5 }}
                value={link2}
                onChange={(event) => { setLink2(event.target.value) }}
            />

            <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>Link 3</ControlLabel>
            <input
                style={{ width: '100%', height: 30, overflow: 'hidden', margin: '0px 0px 15px 0px', overflowWrap: 'break-word', resize: 'none', outline: 'none', borderColor: 'transparent', border: '1px solid #ccc', marginTop: 5 }}
                value={link3}
                onChange={(event) => { setLink3(event.target.value) }}
            />

            <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>Auto Destory After (days)</ControlLabel>
            <FormGroup controlId="formControlsTextarea" style={{ width: '100%', padding: 0 }}>
                <select value={destroydate} onChange={(e) => { setDestroydate(e.target.value) }} name="destroydate" style={{ backgroundColor: 'transparent', width: '100%', height: 30, outline: 'none', fontSize: 13, borderRadius: 5 }}>
                    {['Manually Destroy', 90, 80, 70, 60, 50, 40, 30, 20].map((value, index) => {
                        return <option key={value + index} value={value}> {value}</option>
                    })}
                </select>
            </FormGroup>

            <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>Images</ControlLabel>
            {imgs.length < 2 ? <div style={{ width: 100, height: 100, display: 'flex', fontSize: 12, borderRadius: 5, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: 'thin solid #707070' }} onClick={() => imgref.current.click()}>
                <div>+</div>
                <div style={{ color: '#acacac' }}>{'MAX ' + 2}</div>
                <div style={{ color: '#acacac' }}>Less than 20MB</div>
            </div> : null}
            <div style={{ display: 'flex', flexDirection: 'row', }}>
                {imginput}
                {imgsdiv}
            </div>
            <div style={{ width: '100%', display: 'flex', placeContent: 'center' }}>{error}</div>
            {EMPTYSPACE}
            <button style={BUTTONSTYLE} onClick={create}>Create</button>
            {errorarr.length > 0
                ? <div className="marketerr_root">
                    {errorarr.map((err, i) => {
                        return <div className="marketerr_single" key={'err' + i}>{err}</div>

                    })}
                </div>
                : null}
        </div>
    )
}

function Deletion({ device }) {

    const [data, setData] = useState([])
    const [firsttime, setFirsttime] = useState(true)
    useEffect(() => {
        try {
            if (firsttime) {
                let options = {
                    method: 'POST',
                    url: READ_USERMARKETLISTING,//DELETE_USERMARKETLISTINGSINGLE
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                }
                axios(options).then((result) => {
                    const { items } = result.data
                    setData(items)
                }).catch((e) => { })
                setFirsttime(false)
            }
        }
        catch (e) { }
    }, [])


    var vt_css = { minWidth: DEVICE_VTBTNS_ROOT_WT[device].li_wt, width: 'max-content', justifyContent: 'center', textAlign: 'center', height: 'max-content', display: 'flex', flexDirection: 'column', padding: 3, margin: 3, borderRadius: 5, boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px', fontSize: f_fs, border: 'thin solid #707070' }
    return (
        <div style={{ display: 'flex', flexDirection: 'column', color: '#707070', padding: '2%', width: device === 'TABLET' ? DEVICE_VTBTNS_ROOT_WT[device].rootwt : '100%' }}>
            {data && data.length > 0
                ? data.map(({ _id, title, vt, descrip, imgs }, index) => {
                    return (
                        <div key={index + 'listingobj'} style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 5, border: 'thin solid #707070', marginBottom: 20, borderRadius: 5 }}>
                            <a target={"_blank"} href={'marketlookup/' + _id} style={{ display: 'flex', textDecoration: 'none', color: '#707070', flexDirection: 'column', borderRadius: 5, alignContent: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <div style={vt_css}>{vt}</div>
                                    <div className="title" style={{ margin: '6px 6px 0px 6px', color: '#707070', fontWeight: 'bold', overflow: 'hidden' }}>{title}</div>
                                </div>
                                <div className="description" style={{ margin: 6, color: '#707070', height: 100, overflow: 'hidden', whiteSpace: 'break-spaces' }}>{descrip}</div>
                            </a>
                            <button
                                style={{ ...BUTTONSTYLE, padding: 5, borderRadius: 5, justifyContent: 'center', width: '100%', textAlign: 'center', backgroundColor: MAIN_PINK_COLOR, color: 'white', border: 'none' }}
                                onClick={() => {
                                    try {
                                        let options = {
                                            method: 'POST',
                                            url: DELETE_USERMARKETLISTINGSINGLE,
                                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                            data: { marketlisting_id: _id, imgs: imgs }
                                        }
                                        axios(options).then((res) => {
                                            const { message } = res.data
                                            if (message === 'SUCCESS') {
                                                var local_data = data.slice()
                                                local_data.splice(index, 1)
                                                setData(local_data)
                                            }
                                        }).catch((e) => { })
                                    }
                                    catch (e) { }
                                }}>
                                Delete
                            </button>
                        </div>
                    )
                })
                : <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>You do not have any listings</div>}
        </div>

    )
}

function Market(props) {
    const { authuser } = props
    const [usertype, setUsertype] = useState(EMPTY)
    const [abbrev_userlocation, setAbbrev_userlocation] = useState(EMPTY)
    const [firsttime, setFirsttime] = useState(true)

    // gate to open filter
    const [select, setSelect] = useState(true)

    // all the search filters
    const [vt, setVt] = useState('All')
    const [delievereabledates, setDelieverabledates] = useState([])
    const [tags, setTags] = useState([])
    const [sort, setSort] = useState(EMPTY)

    // data of all
    const [fulldata, setFulldata] = useState([])
    const [data, setData] = useState([])

    // sell modal
    const [sellmodal, setSellmodal] = useState(false)

    // modal that show curr user listings and can remove
    const [listingmodal, setListingmodal] = useState(false)

    // toggle help content
    const [questionmark, setQuestionmark] = useState(false)

    const device = Checkdevice()

    const [name, setName] = useState('')
    const [spousename, setSpousename] = useState('')
    const [date_w, setDate_w] = useState('')

    let scrollto = useRef()
    function scrolltoview() {
        try { scrollto.current.scrollIntoView({ behavior: "smooth" }) }
        catch (e) { }
    }

    useEffect(() => {
        if (firsttime) {

            var localstorage_select_toggle = localStorage.getItem('selecttoggle')
            if (localstorage_select_toggle !== null) setSelect(localstorage_select_toggle === 'true' ? localstorage_select_toggle : false)
            else setSelect(false)

            try {
                let options
                if (token && token.length > 0) {
                    options = {
                        method: 'POST',
                        url: READ_MARKETLISTINGU,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    }
                }
                else {
                    if (token && token.length > 0) {
                        options = {
                            method: 'POST',
                            url: READ_MARKETLISTING,
                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                        }
                    }
                }

                axios(options).then((result) => {
                    const { location, listingobjs, date_w, spousename, name } = result.data
                    setFulldata(listingobjs)
                    setAbbrev_userlocation(location)
                    setDate_w(date_w)
                    setSpousename(spousename)
                    setName(name)

                }).catch((e) => { })

                setFirsttime(false)

            }
            catch (e) { }
        }
        if (props && props.usertype) {
            setUsertype(props.usertype)
        }
    }, [props])

    useEffect(() => {
        if (fulldata) {
            setData(fulldata)
        }
    }, [fulldata])

    useEffect(() => {
        if (select) scrolltoview()
    }, [select])

    useEffect(() => {

        if (!firsttime) {
            var new_data = []

            // vt
            if (vt === 'All') {
                new_data = fulldata.slice()
            }
            else {
                for (let i = 0; i < fulldata.length; i++) {
                    if (fulldata[i].vt === vt) {
                        new_data.push(fulldata[i])
                    }
                }
            }

            // delievereabledates
            var temp1 = []
            if (delievereabledates.length > 0) {
                for (let i = 0; i < new_data.length; i++) {
                    var local_dds = new_data[i].delievereabledates
                    var consec_true1 = 0
                    for (let j = 0; j < delievereabledates.length; j++) {
                        if (local_dds.indexOf(delievereabledates[j]) > -1) consec_true1++
                    }
                    if (consec_true1 >= delievereabledates.length) temp1.push(new_data[i])
                }
                new_data = temp1.slice()
            }

            // tags
            var temp2 = []
            if (tags.length > 0) {
                for (let i = 0; i < new_data.length; i++) {
                    var local_tags = new_data[i].tags
                    var consec_true2 = 0
                    for (let j = 0; j < tags.length; j++) {
                        if (local_tags.indexOf(tags[j]) > -1) consec_true2++
                    }
                    if (consec_true2 >= tags.length) temp2.push(new_data[i])
                }
                new_data = temp2.slice()
            }

            // sort
            if (sort !== '') {
                var price_arr = []
                var price_obj_map = {}
                var sort_result = []
                var sorted_price_arr = []
                for (let i = 0; i < new_data.length; i++) {
                    if (price_arr.indexOf(new_data[i].price) < 0) {
                        price_arr.push(new_data[i].price)
                        price_obj_map[new_data[i].price] = []
                    }
                    price_obj_map[new_data[i].price].push(new_data[i])
                }
                if (sort === '$ Low-High') {
                    sorted_price_arr = price_arr.sort(function (a, b) { return a - b })
                }
                else if (sort === '$ High-Low') {
                    sorted_price_arr = price_arr.sort(function (a, b) { return b - a })
                }

                for (let i = 0; i < sorted_price_arr.length; i++) {
                    sort_result.push(...price_obj_map[sorted_price_arr[i]])
                }
                new_data = sort_result
            }

            setData(new_data)
        }
    }, [vt, tags, delievereabledates, sort])

    function updateSelect() {
        localStorage.setItem('selecttoggle', !select)
        setSelect(!select)
    }
    function updateMarketfilter(item) {
        // return () => item === delievereabledates ? setDelieverabledates(EMPTY) : setDelieverabledates(item)
        return () => {

            var index = delievereabledates.indexOf(item)

            var local_dd = delievereabledates.slice()
            if (index > -1) {
                local_dd.splice(index, 1)
            }
            else {
                local_dd.push(item)
            }
            setDelieverabledates(local_dd)
        }
    }
    function updateTag(item) {
        return () => {
            var index = tags.indexOf(item)
            var local_tags = tags.slice()
            if (index > -1) {
                local_tags.splice(index, 1)
            }
            else {
                local_tags.push(item)
            }
            setTags(local_tags)
        }
    }
    function updateVt(item) {
        return () => {
            setTags([])
            setVt(item)
        }
    }
    function updateSort(item) {
        return () => item === sort ? setSort(EMPTY) : setSort(item)
    }
    function updateSellmodalopen() {
        if (authuser) {
            setSellmodal(true)
        }
        else {

            alert(loginsell)
        }
    }
    function updateSellmodalclose() {
        setSellmodal(false)
    }
    function updateListingmodalopen() {
        if (authuser) {
            setListingmodal(true)
        }
        else {
            alert(loginlisting)
        }
    }
    function updateListingmodalclose() {
        setListingmodal(false)
    }
    function howitworks() {
        setQuestionmark(!questionmark)
    }
    function title(item, ref = null) {
        return <div ref={ref} style={{ width: '-webkit-fill-available', height: 'max-content', outline: 'none', color: '#707070', margin: '0px 10px', backgroundColor: 'white', fontSize: 20, display: 'flex', textAlign: 'center', placeContent: 'start' }}>{item}</div>
    }

    const movingslider = (
        <div style={{ display: 'flex', width: '100%', backgroundColor: MAIN_PINK_COLOR, overflow: 'hidden', height: 30, WebkitBoxAlign: 'center', alignItems: 'center', marginTop: authuser ? 20 : '' }}>
            <div className="movingslide"> Sell / Buy your wedding related stuff here!</div>
        </div>
    )

    const marketroot_css = device !== 'MOBILE' ? { position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', touchAction: 'manipulation', overflow: 'hidden' } : {}

    return firsttime
        ? <Loading />
        : <div style={marketroot_css}>
            {authuser
                ? <Header {...props} usertype={'Market'} wedorevent={props.wedorevent} abbrev_userlocation={abbrev_userlocation} name={name} spousename={spousename} date_w={date_w} >
                    <MainMenu {...props} selectedMainCategory={'Market'} usertype={usertype} />
                </Header>
                : <div style={{ ...DEFAULTHEADER_MAP, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ ...DEFAULTHEADER_MAP, backgroundImage: "url(" + market + ")" }}>
                        <div style={{ ...DEFAULTHEADER_MAP, position: 'absolute', backgroundColor: '#70707042' }} />
                        <div style={{ position: 'absolute', fontWeight: 'bold', fontSize: 64, width: 88, height: 88, color: 'rgb(241, 94, 94)', backgroundColor: MAIN_PINK_COLOR, borderRadius: 10, alignSelf: 'center', textAlign: 'center' }}>囍</div>
                    </div>
                    {movingslider}
                </div>}

            <div style={{ width: DEVICE_VTBTNS_ROOT_WT[device].rootwt, display: 'flex', flexDirection: 'column', minHeight: 400 }}>
                {authuser ? movingslider : null}
                <a href={`tg://resolve?domain=${TELEGRAM_WEDDINGMARKETPLACE}`} style={{ ...BUTTONSTYLE, display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%', padding: '5px 10px', margin: '5px 5px 5px 0px', border: 'none', borderRadius: 5, boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px' }}>
                    <div>{TELEGRAM_ICON_LARGE}</div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> Join {`${STARTUP_NAME}`} Marketplace Telegram Group</div>
                </a>
                <div style={{ margin: 10, color: '#707070', fontSize: e_fs, lineHeight: FS_LH_MAP[e_fs], justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                    <div onClick={howitworks} style={{ ...BUTTONSTYLE, height: 32, width: 40, alignItems: 'center', display: 'flex', justifyContent: 'center', cursor: 'pointer', padding: '5px 10px', margin: '5px 10px 5px 0px', borderRadius: 5, boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px', border: 'thin solid silver' }}>
                        ?
                    </div>
                    <div>
                        <button onClick={updateListingmodalopen} style={{ ...BUTTONSTYLE, cursor: 'pointer', width: 100, padding: '5px 10px', margin: '5px 5px 5px 0px', border: 'none', borderRadius: 5, boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px' }}>
                            Listings
                        </button>
                        <button onClick={updateSellmodalopen} style={{ ...BUTTONSTYLE, cursor: 'pointer', width: 100, backgroundColor: MAIN_PINK_COLOR, color: 'white', border: 'none', padding: '5px 10px', margin: '5px 5px 5px 0px', borderRadius: 5, boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px' }}>
                            Sell
                        </button>
                    </div>
                </div>

                {questionmark
                    ? <div onClick={howitworks} style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(112, 112, 112, 0.7)', zIndex: 4, left: 0, top: 0 }} />
                    : null}
                {questionmark
                    ? <div onClick={howitworks} style={{ padding: 15, margin: 10, boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px', zIndex: 4, backgroundColor: 'white', borderRadius: 5, }}>{introductionhowitworks}</div>
                    : null}

                {select
                    ? <div>
                        {title('Category')}
                        <div style={MAINBODY_CSS}>
                            {LOCAL_COLLECTION_VENDORTYPE.map((item, index) => {
                                return (
                                    <li
                                        key={item + index}
                                        className={vt === item.value ? 'bsbtn_selected' : 'bsbtn'}
                                        style={{ width: DEVICE_VTBTNS_ROOT_WT[device].li_wt, minHeight: 50, display: 'flex', margin: 5, padding: 5, borderRadius: 5, whiteSpace: 'pre-wrap', height: 'max-content', cursor: 'pointer' }}
                                        onClick={updateVt(item.value)}>{item.value}</li>
                                )
                            })}
                        </div>

                        {EMPTYSPACE}
                        {title('Delieverable Month')}
                        <div style={{ ...MAINBODY_CSS, flexDirection: 'column' }}>
                            {Object.keys(FILTERDATE_MAP).map((year, index_x) => {
                                return (
                                    <div key={year + index_x} style={{ display: 'flex', flexDirection: 'column', margin: 5, padding: 5 }}>
                                        <div style={{ fontWeight: 'bold', color: '#707070', fontSize: 16 }}>{year}</div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            {FILTERDATE_MAP[year].map((mth, index_y) => {
                                                const mth_str = device === 'MOBILE' ? mth : MARKET_NUMMTH_CHARMTH_MAP[mth]
                                                if (parseInt(mth, 10) < parseInt(CURRMTH, 10) + 1 && parseInt(year, 10) === parseInt(CURRYR, 10)) {
                                                    return <del
                                                        key={mth + ' / ' + year + index_y}
                                                        style={{ width: DEVICE_MTHWT_MAP[device], height: DEVICE_MTHWT_MAP[device], backgroundColor: '#80808082', alignItems: 'center', placeContent: 'center', display: 'flex', padding: 5, marginRight: 5, borderRadius: 5, cursor: 'pointer', border: 'thin solid #707070' }}
                                                    >{mth_str}</del>
                                                }
                                                else {
                                                    return (
                                                        <div
                                                            key={mth + ' / ' + year + index_y}
                                                            className={delievereabledates.indexOf(mth + ' / ' + year) > -1 ? 'bsbtn_selected' : 'bsbtn'}
                                                            style={{ width: DEVICE_MTHWT_MAP[device], height: DEVICE_MTHWT_MAP[device], alignItems: 'center', placeContent: 'center', display: 'flex', padding: 5, marginRight: 5, borderRadius: 5, cursor: 'pointer', border: 'thin solid #707070' }}
                                                            onClick={updateMarketfilter(mth + ' / ' + year)}
                                                        >{mth_str}</div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        {EMPTYSPACE}
                        {title('Tags')}
                        <div style={MAINBODY_CSS}>
                            {(Object.keys(TAG_MAP).concat(VT_TAG_MAP[vt] ? VT_TAG_MAP[vt] : null)).map((item, index) => {
                                if (item) {
                                    return (
                                        <li
                                            key={item + index}
                                            className={tags.indexOf(item) > -1 ? 'bsbtn_selected' : 'bsbtn'}
                                            style={{ width: DEVICE_VTBTNS_ROOT_WT[device].li_wt, display: 'flex', margin: 5, padding: 5, borderRadius: 5, height: 'max-content', cursor: 'pointer' }}
                                            onClick={updateTag(item)}>{item}</li>
                                    )
                                }
                                else return null
                            })}
                        </div>

                        {EMPTYSPACE}
                        {title('Sort')}
                        <div style={MAINBODY_CSS} >
                            {Object.keys(SORT_MAP).map((item, index) => {
                                return (
                                    <li
                                        key={item + index}
                                        className={sort === item ? 'bsbtn_selected' : 'bsbtn'}
                                        style={{ width: DEVICE_VTBTNS_ROOT_WT[device].li_wt, display: 'flex', margin: 5, padding: 5, borderRadius: 5, height: 'max-content', cursor: 'pointer' }}
                                        onClick={updateSort(item)}>{item}</li>
                                )
                            })}
                        </div>

                        {EMPTYSPACE}
                        <div style={{ width: '-webkit-fill-available', display: 'flex', justifyContent: 'center', margin: '0px 10px', paddingBottom: 10 }}>
                            <button style={{ ...BUTTONSTYLE, border: 'none', color: '#707070' }} onClick={updateSelect}>{ARROWUP_SMALL}</button>
                        </div>

                    </div>
                    : <div>
                        {title('Search', scrollto)}
                        <div style={{ ...MAINBODY_CSS, flexDirection: 'column', justifyContent: 'space-between' }} onClick={updateSelect}>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {[vt, ...delievereabledates, sort, ...tags].map((item, index) => {
                                    return item
                                        ? <li key={'select' + index} style={{ display: 'flex', margin: 5, padding: '5px 10px', borderRadius: 5, height: 'max-content', cursor: 'pointer', color: 'white', backgroundColor: MAIN_PINK_COLOR }}>
                                            {item}
                                        </li>
                                        : null
                                })}
                            </div>
                            <div style={{ color: '#707070', display: 'flex', alignSelf: 'center', padding: 5 }}>{ARROWDOWN_SMALL}</div>
                        </div>
                    </div>
                }
                {EMPTYSPACE}
                <div style={{ minHeight: 500, display: 'flex', flexDirection: 'column' }}>
                    <Selling data={data} device={device} authuser={authuser} />
                </div>

                {EMPTYSPACE}
                <Jfmodal
                    show={sellmodal}
                    title={'Sell Something'}
                    onHide={updateSellmodalclose}
                    device={device}
                    content={< Creation
                        device={device}
                        token={token}
                    />}
                />

                <Jfmodal
                    show={listingmodal}
                    title={'Your Listing'}
                    onHide={updateListingmodalclose}
                    device={device}
                    content={<Deletion device={device} />}
                />

            </div>

        </div>


}

export default Market