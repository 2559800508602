import React from 'react'
import { Link } from 'react-router-dom'
import { READABLE_VENDORTYPE_MAP, EMPTYSPACE, ABBREVCITY_CURRENCY_MAP, BOXSHADOW_CARD, f_fs, VENDORCARD_WT_WEB, VENDORCARD_WT_MOBILE, VENDORCARD_WT_LARGEMOBILE, VENDORCARD_WT_SMALLMOBILE, SUB_FF, VC_NAME_COLOR, TIER_DIAMOND_POSITION } from './../../config/constants'
import { Heart } from './../../async_components'
import { Processmoney, Processname } from '..'
import { ScreenWidth } from '../Dimension';
import { getS3fullpath_withbucketname } from '../../config/commonfunctions'
import './Vendorcard.css'

function Vendorcard(props) {

    const sw = ScreenWidth()

    const getImgSrc = () => {
        const { profileimg_id = [] } = props.content
        if (profileimg_id.length > 0) {
            return getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', profileimg_id[0].link)
        }
        else if ('link' in profileimg_id) {
            return getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', profileimg_id.link)
        }
    }

    const getPrice = (fs, color) => {
        // const { selectedvt, content, abbrev_userlocation } = props
        // const minprice = Processmoney(content[abbrev_userlocation + 'minprice'])
        // const currency = ABBREVCITY_CURRENCY_MAP[abbrev_userlocation]

        // if (selectedvt === 'All' || selectedvt === 'all') {
        //     return ''
        // }
        // else {
        //     return <div style={{ fontSize: fs, color: color, display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', width: '100%', padding: props.type === 'MOBILE' ? '10px 0px 0px' : '5px 10px 0px 10px', justifyContent: 'center' }}>
        //         <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', fontsize: fs - 3, fontWeight: 'bold', lineHeight: 1 }}>
        //             {/* <div style={{ fontSize: 12, letterSpacing: 1 }}>{"MIN"}</div> */}
        //             <div >{"FROM"}</div>
        //         </div>
        //         <div style={{ display: 'flex', border: 'thin solid ' + color, margin: '2px 3px', padding: '2px 3px', borderRadius: 5, fontSize: props.type === 'MOBILE' ? 12 : 16, width: '100%', justifyContent: 'center', fontWeight: 'bold', }}>
        //             {'$ ' + minprice + ' ' + currency}
        //         </div>
        //     </div>
        // }

        const { selectedvt, content, abbrev_userlocation } = props
        const minprice = Processmoney(content[abbrev_userlocation + 'minprice'])
        const currency = ABBREVCITY_CURRENCY_MAP[abbrev_userlocation]
        if (selectedvt === 'All' || selectedvt === 'all') {
            return ''
        }
        else {
            return <div style={{
                fontSize: fs,
                color: color,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                textAlign: 'center',
                width: '100%',
                padding: props.type === 'MOBILE' ? '10px 0px 0px' : '5px 10px 0px 10px',
                justifyContent: 'center'
            }}>
                <div style={{ paddingRight: 6 }}>Starts At </div>
                <div>{'$ ' + minprice + ' ' + currency}</div>
            </div>
        }
    }

    const getVt_web = (selectedvt, vendortype, secondaryvendortype) => {
        if (selectedvt === 'All' && (secondaryvendortype !== 'None' || secondaryvendortype)) {
            return (
                <div className="VCARD_VT_WEB_ROOT">
                    <font className="VCARD_VT_WEB_FONT">{READABLE_VENDORTYPE_MAP[vendortype]}</font>
                    <font className="VCARD_VT_WEB_FONT">{READABLE_VENDORTYPE_MAP[secondaryvendortype]}</font>
                </div>
            )
        }
        else {
            return <font className="VCARD_VT_WEB_FONT">{READABLE_VENDORTYPE_MAP[selectedvt]}</font>
        }
    }

    const getVt_mobile = (selectedvt, vendortype, secondaryvendortype) => {

        if (secondaryvendortype && secondaryvendortype.length < 1) return <div className="VCARD_VT_MOB_ROOT">
            <font className="VCARD_VT_MOB_FONT">{READABLE_VENDORTYPE_MAP[vendortype]}</font>
            <div style={{ minHeight: 14 }}>{' '}</div>
        </div>

        if (selectedvt === 'All' && (secondaryvendortype !== 'None' || secondaryvendortype)) {
            return (
                <div className="VCARD_VT_MOB_ROOT" onClick={() => { alert('2') }}>
                    <font className="VCARD_VT_MOB_FONT">{READABLE_VENDORTYPE_MAP[vendortype]}</font>
                    <font className="VCARD_VT_MOB_FONT">{READABLE_VENDORTYPE_MAP[secondaryvendortype]}</font>
                </div>
            )
        }

    }

    const RenderHeart = () => {
        const { userlike, vendortype, secondaryvendortype, vendortype_id, vendortypeinterface_id, updateHeart } = props
        return <Heart
            vendortypeinterface_id={vendortypeinterface_id}
            vendortype={vendortype}
            secondaryvendortype={secondaryvendortype}
            vendortype_id={vendortype_id}
            userlike={userlike}
            updateHeart={updateHeart}
            parent={'Vendorcard'}
        />
    }

    const RenderCovid19 = () => {
        const { c0, type } = props
        var curr_css = type === 'MOBILE' ? { margin: '', padding: '2px 5px', fontSize: f_fs } : { margin: 5, padding: '0px 5px', fontSize: f_fs }
        const covid19_css = { ...curr_css, fontFamily: SUB_FF, display: 'flex', textAlign: 'left', boxShadow: '0 3px 10px 0 rgba(44,44,45,.07), inset 0 0 0 1px rgba(44,44,45,.07)', color: 'white', backgroundColor: 'black', border: 'thin solid #707070', borderRadius: 5, alignItems: 'center', height: 'max-content', width: 'max-content' }
        return c0 ? <div style={covid19_css}>COVID19 Flexi-Policy</div> : <div />
    }

    const RenderMobile = () => {
        const { content, selectedvt } = props
        const { displayname, pricelist_num = 0, project_num = 0, review_num = 0, username, vendortype, secondaryvendortype } = content
        const wt = sw > 400 ? VENDORCARD_WT_LARGEMOBILE : sw > 374 ? VENDORCARD_WT_MOBILE : VENDORCARD_WT_SMALLMOBILE
        // return (
        //     <div className="VCARD_MOBILE_ROOT" key={username} style={{ minWidth: wt, maxWidth: wt, fontFamily: SUB_FF }}>
        //         <div className="VCARD_MOBILE_COVIDANDHEART"><div>{RenderCovid19()}{RenderHeart()}</div></div>
        //         <Link className="VCARD_MOBILE_IMGLINK" to={'/vendorlookup/' + username} target="_blank" style={{ backgroundImage: 'url(" ' + getImgSrc() + ' ")', minWidth: wt - 2, maxWidth: wt - 2, }} />
        //         <div style={{ display: 'flex', width: '100%', height: 140, padding: 5, flexDirection: 'column' }}>
        //             <font style={{ fontSize: 12, backgroundColor: 'transparent', color: VC_NAME_COLOR, fontWeight: 'bold' }}>{Processname(displayname, username, 'MOBILE')}</font>
        //             {getVt_mobile(selectedvt, vendortype, secondaryvendortype)}
        //             <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        //                 {getPrice(12, '#21201f')}
        //                 {EMPTYSPACE}
        //                 <div style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
        //                     <div style={{ display: 'flex', flexDirection: 'row', width: '100%', borderTop: '1px solid var(--subgrey)', height: 25, marginBottom: 5 }}>
        //                         <div style={{ display: 'flex', flexDirection: 'row', width: '50%', }}>
        //                             <font style={{ fontSize: 11, color: 'var(--maindark)', display: 'flex', width: '50%', placeContent: 'start', height: '100%', alignItems: 'center' }}>{'Pricelist '}</font>
        //                             <font style={{ fontSize: 12, color: 'var(--maindark)', display: 'flex', width: '50%', placeContent: 'start', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', height: '100%' }}>{parseInt(pricelist_num, 10) === 0 ? '-' : pricelist_num}</font>
        //                         </div>
        //                         <div style={{ display: 'flex', flexDirection: 'row', width: '50%', }}>
        //                             <font style={{ fontSize: 11, color: 'var(--maindark)', display: 'flex', width: '50%', placeContent: 'start', height: '100%', alignItems: 'center' }}>{'Project '}</font>
        //                             <font style={{ fontSize: 12, color: 'var(--maindark)', display: 'flex', width: '50%', placeContent: 'start', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', height: '100%' }}>{parseInt(project_num, 10) === 0 ? '-' : project_num}</font>
        //                         </div>
        //                     </div>
        //                     <div style={{ display: 'flex', flexDirection: 'row', width: '50%', borderTop: '1px solid var(--subgrey)', height: 25 }}>
        //                         <div style={{ display: 'flex', flexDirection: 'row', width: '100%', }}>
        //                             <font style={{ fontSize: 11, color: 'var(--maindark)', display: 'flex', width: '50%', placeContent: 'start', height: '100%', alignItems: 'center' }}>{'Review '}</font>
        //                             <font style={{ fontSize: 12, color: 'var(--maindark)', display: 'flex', width: '50%', placeContent: 'start', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', height: '100%' }}>{parseInt(review_num, 10) === 0 ? '-' : review_num}</font>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // )

        return <div style={{ width: 'calc( 100% - 10px)', margin: '0px 5px 5px 5px', height: 400, border: '1px solid var(--subgrey)' }}>

        </div>
    }

    // const RenderWeb = () => {
    //     const { content, selectedvt } = props
    //     const { displayname, pricelist_num = 0, project_num = 0, review_num = 0, username, vendortype, secondaryvendortype, } = content
    //     return (
    //         <div
    //             className="VCARD_ROOT"
    //             style={{ ...hover, minWidth: VENDORCARD_WT_WEB, maxWidth: VENDORCARD_WT_WEB, marginTop: 20, fontFamily: SUB_FF, border: '1px solid #cdcdcd' }}
    //             onMouseOver={() => { if (DEVICE !== 'TABLET') setHover({ boxShadow: BOXSHADOW_CARD }) }}
    //             onMouseLeave={() => { if (DEVICE !== 'TABLET') setHover({ boxShadow: '' }) }}
    //             key={username}
    //         >
    //             <div className="VCARD_COVIDANDHEART">
    //                 {RenderCovid19()}
    //                 {RenderHeart()}
    //             </div>
    //             <Link className="VCARD_LINK" to={'/vendorlookup/' + username} target="_blank" >
    //                 <div className="VCARD_IMGROOT">
    //                     <div className="VCARD_IMG" style={{ backgroundImage: 'url(" ' + getImgSrc() + ' ")', }} />
    //                     <div className="VCARD_NAMEVTPRICEROOT">
    //                         <div className="VCARD_NAMESUBROOT" style={{ color: VC_NAME_COLOR }}>
    //                             <font style={{ fontSize: 14, backgroundColor: 'white' }}>{Processname(displayname, username, 'WEB')}</font>
    //                         </div>
    //                         <div className="VCARD_NAMESECROOT">
    //                             {getVt_web(selectedvt, vendortype, secondaryvendortype)}
    //                             {getPrice(12, '#21201f')}
    //                         </div>
    //                     </div>
    //                 </div>

    //                 <div className="VCARD_DETAIL_ROOT">
    //                     <div className="VCARD_TOP">
    //                         <div className="VCARD_TOP_TITLE">Review</div>
    //                         <div className="VCARD_NUM">{parseInt(review_num, 10) === 0 ? '-' : review_num}</div>
    //                     </div>
    //                     <div className="VCARD_LR_ROOT">
    //                         <div className="VCARD_LEFT">
    //                             <div className="VCARD_TOP_TITLE">Project</div>
    //                             <div className="VCARD_NUM">{parseInt(project_num, 10) === 0 ? '-' : project_num}</div>
    //                         </div>
    //                         <div className="VCARD_RIGHT">
    //                             <div className="VCARD_TOP_TITLE">Pricelist</div>
    //                             <div className="VCARD_NUM">{parseInt(pricelist_num, 10) === 0 ? '-' : pricelist_num}</div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </Link>
    //         </div>
    //     )
    // }


    const { content, selectedvt, cardwt, cardmargin } = props

    const { displayname, pricelist_num = 0, project_num = 0, review_num = 0, username, vendortype, secondaryvendortype, tier } = content
    const viewlookup = () => document.getElementById(username).click()

    let showprice = true
    let calprice

    console.log('----------')
    console.log('displayname', displayname)
    console.log('selectedvt', selectedvt)
    console.log('props.abbrev_userlocation ', props.abbrev_userlocation )
    console.log(props.content)


    if (content.vendortype === selectedvt) { // show vt price

        console.log(
            'props.content[props.abbrev_userlocation + minprice]',
            props.content[props.abbrev_userlocation + 'minprice']
        )

        calprice = Processmoney(props.content[props.abbrev_userlocation + 'minprice']) + ' ' + ABBREVCITY_CURRENCY_MAP[props.abbrev_userlocation]
    }
    else if (content.secondaryvendortype === selectedvt) { // show svt price
        console.log('props.abbrev_userlocation', props.abbrev_userlocation)
        console.log('minprice_svt')
        console.log(
            'props.content[props.abbrev_userlocation + minprice_svt]',
            props.content[props.abbrev_userlocation + 'minprice_svt']
        )

        calprice = Processmoney(props.content[props.abbrev_userlocation + 'minprice_svt']) + ' ' + ABBREVCITY_CURRENCY_MAP[props.abbrev_userlocation]
    }
    else {
        showprice = false
        calprice = ''
    }


    console.log('----------')

    // if is all, render privt and svt
    // if is not all, render both
    return (
        <div
            key={username}
            className='Vendorcard_root'
            style={{
                cursor: 'pointer',
                width: cardwt, height: 426, border: '1px solid var(--subgrey)',
                fontFamily: 'Lato', margin: cardmargin, borderRadius: 7,
                color: 'var(--maindark)', backgroundColor: '#ffffff',
            }}
        >
            <Link id={username} style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/vendorlookup/' + username} target="_blank" />
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', placeContent: 'flex-end', height: 40, position: 'relative' }}>
                {tier === 0
                    ? <div className='VCARD_DIAMONDROOT' />
                    : <div className={`VCARD_DIAMONDROOT VCARD_DIAMONDROOT_${tier}`} style={{ position: 'absolute', top: 35 }}>
                        <div
                            className='VCARD_DIAMONDCONTENT'
                            style={{
                                backgroundImage: `url('https://asset.weavebliss.com/assets/sprites/diamonds.png')`,
                                backgroundPositionY: TIER_DIAMOND_POSITION[tier].y + 'px',
                            }}
                        />
                    </div>}
                <div style={{ width: 40, height: 40, display: 'flex', placeContent: 'center', alignItems: 'center' }}>
                    <Heart
                        vendortypeinterface_id={props.vendortypeinterface_id}
                        vendortype={props.vendortype}
                        secondaryvendortype={props.secondaryvendortype}
                        vendortype_id={props.vendortype_id}
                        userlike={props.userlike}
                        updateHeart={props.updateHeart}
                        parent={'Vendorcard'}
                    />
                </div>
            </div>

            <div onClick={viewlookup} style={{ display: 'flex', flexDirection: 'column' }}>

                <div style={{ display: 'flex', width: '100%', placeContent: 'center', height: 145, position: 'relative', }} >
                    <img
                        src={getImgSrc()}
                        style={{
                            borderRadius: 145, width: 145, height: 145,
                            display: 'flex', placeContent: 'center', objectFit: 'cover',
                            border: '1px solid var(--subgrey)'
                        }} />

                </div>

                <div style={{ width: '100%', display: 'flex', placeContent: 'center', alignItems: 'center', height: 30, margin: '10px 0px', padding: '0px 5px', fontWeight: 'bold', fontSize: 14, textAlign: 'center', whiteSpace: 'pre', textWrap: 'wrap' }}>
                    {Processname(displayname)}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 50, padding: '0px 5px', fontSize: 14 }}>
                    {selectedvt === 'All'
                        ? <>
                            <div>{READABLE_VENDORTYPE_MAP[vendortype]}</div>
                            {secondaryvendortype === '' ? null : <div>{READABLE_VENDORTYPE_MAP[secondaryvendortype]}</div>}
                        </>
                        : <div>{vendortype === selectedvt ? READABLE_VENDORTYPE_MAP[vendortype] : READABLE_VENDORTYPE_MAP[secondaryvendortype]}</div>
                    }
                </div>

                {!showprice || props.content[props.abbrev_userlocation + 'minprice'] === 0
                    ? <div style={{ height: 50 }} />
                    : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 50, padding: '0px 5px', }}>
                        <div style={{
                            fontSize: 14,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'baseline',
                            textAlign: 'center',
                            width: '100%',
                            justifyContent: 'center',
                        }}>
                            <div style={{ paddingRight: 6, fontSize: 12, fontWeight: 'bold', }}>Starts At </div>
                            <div style={{ color: 'var(--mainpink)' }}>{'$ ' + calprice}</div>
                        </div>
                    </div>

                }

                <div style={{ width: '100%', height: 85, display: 'flex', color: 'var(--maindark)', flexDirection: 'column', borderTop: '1px solid var(--subgrey)', fontFamily: 'Lato' }}>
                    <div style={{ width: '100%', height: '50%', display: 'flex', borderBottom: '1px solid var(--subgrey)', flexDirection: 'column', placeContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: 12, }}>Review</div>
                        <div style={{ fontSize: 13 }}>{parseInt(review_num, 10) === 0 ? '-' : review_num}</div>
                    </div>
                    <div style={{ width: '100%', height: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', placeContent: 'center', }}>
                            <div style={{ fontSize: 12, }}>Project</div>
                            <div style={{ fontSize: 13 }}>{parseInt(project_num, 10) === 0 ? '-' : project_num}</div>
                        </div>
                        <div style={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', placeContent: 'center', borderLeft: '1px solid var(--subgrey)' }}>
                            <div style={{ fontSize: 12, }}>Pricelist</div>
                            <div style={{ fontSize: 13 }}>{parseInt(pricelist_num, 10) === 0 ? '-' : pricelist_num}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}


export default Vendorcard