import React, { useState, useEffect, useRef } from 'react'
import { Link } from "react-router-dom";
import { ScreenHeight, ScreenWidth, Headtitle, Processname, Checkdevice } from '../../components';
import { NEAR_TRANSPARENT_GREY, EMPTYSPACE, EMPTY, WEB, SENDMSG_ICON, SOFT_GREY, TRASH_ICON, BACK_ICON, READABLE_VENDORTYPE_MAP, IMG_ALT, production, NUM_WORDS_CANSEND, LEN_MSG_CANSEND } from '../../config/constants';
import { Retrieve_personal_info, getS3fullpath_withbucketname } from '../../config/commonfunctions';
import { READ_CONV_VENDOR_START, READ_CONV_USER_START, READ_CONV_SPECIFIC_RM_USER, READ_CONV_SPECIFIC_RM_VENDOR, REMOV_CONV_USER, REMOV_CONV_VENDOR, B_W } from '../../config/api';
import Inquiries from './components/Inquiries/Inquiries'
import Message from './components/Message/Message'
import axios from 'axios'
import io from 'socket.io-client';
import './Inbox.css'

function Inbox(props) {

    // Inquiries or message
    const [inquiriesormsg, setInquiriesormsg] = useState('Inquiries')
    const [hidemenu, setHidemenu] = useState(false)
    const device = Checkdevice()

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => { // when navigate to another page
            document.body.style.overflow = "";
        }
    }, [])

    function updateInquiriesormsg(e) {
        setInquiriesormsg(e.target.value)
    }


    const selectmsgorinquiry = !hidemenu
        ? <div className='Inbox_msgorinqroot'>
            <button className={`Inbox_msgorinq Inbox_msgorinq_left ${inquiriesormsg === 'Inquiries' ? 'Inbox_msgorinq_selected' : ''}`} onClick={updateInquiriesormsg} value={'Inquiries'}>Inquiries</button>
            <button className={`Inbox_msgorinq Inbox_msgorinq_right ${inquiriesormsg === 'Message' ? 'Inbox_msgorinq_selected' : ''}`} onClick={updateInquiriesormsg} value={'Message'}>Message</button>
        </div>
        : null

    return <div id='Inbox_root'>
        <Headtitle name="Inbox" color="var(--maindark)" />
        <div id='Inbox_main'>
            {selectmsgorinquiry}
            {{
                'Inquiries': <Inquiries />,
                'Message': <Message
                    activatehidemenu={() => {
                        if (device === 'MOBILE' || device === 'TABLET') {
                            setHidemenu(!hidemenu)
                        }
                        else {
                            setHidemenu(false)
                        }

                    }}
                />
            }[inquiriesormsg]}
        </div>

    </div>







}

export default Inbox