import React, { Component } from 'react'
import { Header, MainMenu, VendorCards, MainSelectionBox, FilterModalContent, FilterDisplaySingleElem } from '../components'
import { Retrieve_personal_info, shuffle } from '../../../config/commonfunctions'
import { Loading, Portalmodal } from '../../../components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { EMPTY, WEB, TABLET, HOME_COLLECTION_VENDORTYPE_EVENT_ARR, SEARCH_FILTER_EVENT, DEFAULT_FILTERSUB_BOTH } from '../../../config/constants';
import * as types from '../../../redux/actions/eventvendors.action'
import './EventVendors.css'

const mapStateToProps = (state) => {
    const { isLoading, isLoaded, data_info, data } = state.Eventvendors
    return { // get state from reducer
        isLoading: isLoading,
        isLoaded: isLoaded,
        data_info: data_info, // give the size etc
        data: data, // give the real data
    }
}

const mapDispatchToProps = (dispatch) => { // https://www.bilibili.com/video/av23568132/?p=10
    return bindActionCreators(types, dispatch)// dispatch action to reducer
}

// @connect(mapStateToProps, mapDispatchToProps) // https://www.bilibili.com/video/av23568132/?p=11
class EventVendors extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,

            // user info
            _id: EMPTY,
            token: EMPTY,
            acct_type: EMPTY,

            // screen dim
            screen_width: 0,
            screen_height: 0,

            selectedMainCategory: 'Event',
            selectedVendorType: EMPTY,

            // for infiinite scrolling
            scrolling: false,

            data: [],
            page_counter: 0,
            batchesVendortypeinterface_ids: [],
            favourite: [],

            mainselectionbox_type: 'FAT', // this determine the type of mainselectionbox | FAT or SLIM 

            filters: {}, // filters for mainselectionbox -> filtermodalcontent
            showfilter: false,

            minprice_map: {},// store, vendortypeinterface as key, and min price as item
            abbrev_userlocation: EMPTY, // abbreviated user location

            preasset_loaded: false, // this just ensure the newMainSelectionBox is Loaded only

            filter_arr_bool: [],
            prev_filter_arr: [],
            filter_arr_header: [],

            date_w: '',
            name: '',
            spousename: '',

            showsticky: false

        }
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0) // scroll to the top
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions)
        const personal_info = Retrieve_personal_info()
        var { vt } = this.props.match.params
        var validvt = false

        // check if vt in event collection or nt, if nt redirect to /
        for (let i = 0; i < HOME_COLLECTION_VENDORTYPE_EVENT_ARR.length; i++) {
            if (HOME_COLLECTION_VENDORTYPE_EVENT_ARR[i].toLowerCase() === vt.toLowerCase()) { // this exist
                validvt = true
            }
        }
        if (!validvt) { // if is nt valid vt redirect
            this.props.history.push('/')
        }


        vt = vt.charAt(0).toUpperCase() + vt.substr(1)
        if (personal_info.acct_type === 'VENDOR') {
            this.props.history.push('/')
        }
        this.setState({
            _id: personal_info._id,
            token: personal_info.token,
            acct_type: personal_info.acct_type,
            selectedVendorType: vt === EMPTY ? 'All' : vt,
            preasset_loaded: true
        }, () => {
            this.initialize_event()
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
        window.removeEventListener('scroll', this.updateScrolling)
    }

    UNSAFE_componentWillMount() {
        this.scrollListner = window.addEventListener('scroll', this.updateScrolling)
    }

    updateScrolling = () => {
        // once scroll down, need to extend content by querying db
        const { scrolling, page_counter, batchesVendortypeinterface_ids } = this.state
        var { isLoading, isLoaded, } = this.props
        if (!isLoading && isLoaded) {
            if (scrolling) return
            const bottomalrdy = document.querySelector('div.bottomalrdy')
            const lastLiOffset = bottomalrdy.offsetTop + bottomalrdy.clientHeight
            const pageOffset = window.pageYOffset + window.innerHeight
            var bottomOffset = 0
            // console.log('scrolling -----------------------------------------------------------------')
            // console.log('pageOffset', pageOffset)
            // console.log('bottomalrdy.clientHeight', bottomalrdy.clientHeight)
            // console.log('bottomalrdy.offsetTop', bottomalrdy.offsetTop)
            // console.log('lastLiOffset - bottomOffset', lastLiOffset - bottomOffset)
            // console.log('page_counter', page_counter)
            if (pageOffset >= (lastLiOffset - 2 - bottomOffset) && page_counter < batchesVendortypeinterface_ids.length) {
                this.extendContent_event()
            }

            const blackdom = document.getElementById('weddingvendorsshow')
            if (pageOffset > (blackdom.offsetTop + blackdom.clientHeight) && pageOffset > 2000) {
                if (!this.state.showsticky) {
                    this.setState({ showsticky: true, mainselectionbox_type: 'SLIM' })
                }
            }
            else {
                if (this.state.showsticky) {
                    this.setState({ showsticky: false, mainselectionbox_type: 'FAT' })
                }
            }

        }
    }

    unpack_data = (data, requestContent_list = [], selectedVendorType) => {
        const { minprice_map } = this.state
        if (requestContent_list.length === 0) return []
        var result = []
        for (let i = 0; i < requestContent_list.length; i++) {
            var vti_id = requestContent_list[i]
            var vtsvt_pl_num = data && data[vti_id] && data[vti_id].allpricelist_ids && data[vti_id].allpricelist_ids.length > 0 ? data[vti_id].allpricelist_ids.length : 0
            var newobj
            if (data && data[vti_id] && selectedVendorType === 'All') { // In 'All' cat, it must reflect the total number of pricelist exist in both vt and svt(if any)
                newobj = { 'minprice': minprice_map[vti_id], 'pricelist_num': vtsvt_pl_num, ...data[vti_id] }
                result.push(newobj)
            }
            else if (data && data[vti_id] && data[vti_id].secondaryvendortype === selectedVendorType) { // if the selected is svt, den we need edit the pricelist_num as it is retrieve from vti. 
                var svt_pl_num = vtsvt_pl_num - data[vti_id].pricelist_num // vt and svt pl - vt pl = svt pl
                newobj = { 'minprice': minprice_map[vti_id], 'pricelist_num': svt_pl_num, ...data[vti_id], }
                result.push(newobj)
            }
            else if (data && data[vti_id]) {
                newobj = { 'minprice': minprice_map[vti_id], ...data[vti_id] }
                result.push(newobj)
            }
        }
        return result
    }

    Partone(maincatvt_key) {
        const { rendercard_count, vendortypeinterface_ids, filtersetting_e = {}, abbrev_userlocation } = this.props.data_info
        const { selectedVendorType, minprice_map } = this.state

        var anyFilterInAll = (filtersetting_e = {}) => {
            return filtersetting_e && filtersetting_e.EventAll && filtersetting_e.EventAll.length > 0 ? true : false
        }

        var filterRawvtiids = (vti_ids, abbrev_userlocation) => {
            var local_minprice_map = minprice_map
            var result = []
            for (let i = 0; i < vti_ids.length; i++) {
                if (selectedVendorType === 'All') {
                    result.push(vti_ids[i]._id)
                }
                else {
                    var vti_id = vti_ids[i].vendortypeinterface_id
                    result.push(vti_id)
                    if (vti_ids[i][abbrev_userlocation]) { // if vendor open user's location for listing 
                        local_minprice_map[vti_id] = vti_ids[i][abbrev_userlocation + 'minprice']
                    }
                }
            }
            var gate = false
            this.setState({ minprice_map: local_minprice_map }, () => {
                gate = true
            })
            if (gate)
                return result
        }

        var splitTobatches = (vti_ids = [], count) => {
            // count, is the number card in a batch
            var len_arr = vti_ids.length
            if (len_arr > count) {
                var result = []
                for (let i = 0; i < len_arr;) {
                    var temp = []
                    for (let j = 0; i < len_arr && j < count; j++, i++) {
                        temp.push(vti_ids[i])
                    }
                    result.push(temp)
                }
                return result
            }
            else if (len_arr <= count) {
                return [vti_ids]
            }
        }

        var filtered_vti_ids = filterRawvtiids(vendortypeinterface_ids, abbrev_userlocation)
        if (selectedVendorType === 'All' && anyFilterInAll()) { // if current selected vt is 'All' and filter is empty we shall shuffle
            filtered_vti_ids = shuffle(filtered_vti_ids)
        }
        else {
            if ((maincatvt_key in filtersetting_e && filtersetting_e[maincatvt_key].length === 0) || filtersetting_e[maincatvt_key] === undefined) {
                filtered_vti_ids = shuffle(filtered_vti_ids)
            }
        }
        var split_by_batches_vti_ids = splitTobatches(filtered_vti_ids, rendercard_count)
        var page_counter = 0
        var requestContent_list = split_by_batches_vti_ids[page_counter]

        return { requestContent_list, split_by_batches_vti_ids, page_counter }

    }

    Parttwo(maincatvt_key) {
        const { filtersetting_e = {} } = this.props.data_info
        const { selectedVendorType } = this.state

        var filter_arr_bool = []
        var prev_filter_arr = []
        var filter_arr_header = []
        var dbsaved_filters = filtersetting_e ? filtersetting_e[maincatvt_key] : []
        var VTFILTER = SEARCH_FILTER_EVENT[selectedVendorType]

        // add covid19 filter 
        // if (VTFILTER['Keys'] && !VTFILTER['Keys'].includes('Others')) {
        //     VTFILTER['Keys'].push('Others')
        //     VTFILTER['Others'] = [DEFAULT_FILTERSUB_BOTH]
        // }

        const { active, Keys } = VTFILTER

        if (active) { // sub category of filter
            for (let i = 0; i < Keys.length; i++) {
                var key = Keys[i]
                for (let j = 0; j < VTFILTER[key].length; j++) {
                    const { header } = VTFILTER[key][j]
                    var checked = false
                    if (dbsaved_filters) { // check if filter exist
                        for (let k = 0; k < dbsaved_filters.length; k++) {
                            if (dbsaved_filters[k] === header) {
                                checked = true
                                break
                            }
                        }
                    }
                    filter_arr_bool.push(checked)
                    prev_filter_arr.push(checked)
                    filter_arr_header.push(header)
                }
            }
        }

        return { filter_arr_bool, prev_filter_arr, filter_arr_header }
    }

    initialize_event = () => { // initialize for wedding
        const { selectedMainCategory, selectedVendorType, _id, } = this.state // _id is user _id

        this.props.fetcheventvendorscontentsize_action(selectedVendorType, _id).then(() => {

            // 1 divide vendortypeinterface_ids into batches
            // 2 send to fetchhomecontent_action request for content

            if (this.props.isLoaded) { // need ensure data loaded before second fetch
                const { favourite, filtersetting_e = {}, abbrev_userlocation, date_w, name, spousename } = this.props.data_info
                var maincatvt_key = selectedMainCategory + selectedVendorType

                var { requestContent_list, split_by_batches_vti_ids, page_counter } = this.Partone(maincatvt_key)
                if (requestContent_list && requestContent_list.length > 0) {

                    this.props.fetcheventvendors_action(selectedVendorType, requestContent_list).then(() => {// data for filter modal

                        var { filter_arr_bool, prev_filter_arr, filter_arr_header } = this.Parttwo(maincatvt_key)

                        this.setState({
                            data: this.unpack_data(this.props.data, requestContent_list, selectedVendorType),
                            batchesVendortypeinterface_ids: split_by_batches_vti_ids,
                            page_counter: page_counter,// increment the window size
                            favourite: favourite.map((item, index) => { return item[0] }), // favourite is a pair, we need to select first element to obtain vendortypeinterface_id
                            filters: filtersetting_e, filter_arr_bool, prev_filter_arr, filter_arr_header,
                            abbrev_userlocation,
                            loaded: true,
                            date_w, name, spousename
                        }, () => { window.scroll(0, 0) })
                    })
                }
                else {
                    var { filter_arr_bool, prev_filter_arr, filter_arr_header } = this.Parttwo(maincatvt_key)
                    var needupdate_weddateinfo = this.state.date_w && this.state.date_w.length > 0 ? {} : { date_w, name, spousename }
                    this.setState({
                        data: [],
                        batchesVendortypeinterface_ids: [],
                        page_counter: 0,// increment the window size
                        favourite: favourite.map((item, index) => { return item[0] }), // favourite is a pair, we need to select first element to obtain vendortypeinterface_id
                        filters: filtersetting_e, filter_arr_bool, prev_filter_arr, filter_arr_header,
                        abbrev_userlocation,
                        ...needupdate_weddateinfo,
                        loaded: true,

                    }, () => { window.scroll(0, 0) })
                }
            }
        })

    }

    extendContent_event = () => {// extend content after scrolling for event
        const { page_counter, batchesVendortypeinterface_ids, selectedVendorType } = this.state
        // if (batchesVendortypeinterface_ids != undefined && batchesVendortypeinterface_ids != null && batchesVendortypeinterface_ids != []) {
        if (typeof batchesVendortypeinterface_ids === 'object' && batchesVendortypeinterface_ids.length > 0) {
            var requestContent_list = batchesVendortypeinterface_ids[page_counter + 1]
            this.props.fetcheventvendors_action(selectedVendorType, requestContent_list).then(() => {
                this.setState({
                    data: [...this.state.data, ...this.unpack_data(this.props.data, requestContent_list, selectedVendorType)], //append latest content 
                    scrolling: false,
                    page_counter: page_counter + 1
                })
            })
        }

    }

    updateWindowDimensions = () => {
        this.setState({ screen_width: window.innerWidth, screen_height: window.innerHeight });
    }

    // for MainSelectionBox
    onApplyFilter = (filter_queries) => {
        const { selectedMainCategory, selectedVendorType, token } = this.state
        this.props.updateuserfilter_action(token, filter_queries, selectedVendorType, selectedMainCategory).then(() => {
            this.initialize_event()
        })
    }

    // for MainSelectionBox
    openFiltermodal = () => {
        document.body.style.overflow = 'hidden'
        this.setState({ showfilter: true })
    }

    // for MainSelectionBox
    genFiltermodalDom_web = () => {
        var { selectedMainCategory, selectedVendorType, showfilter } = this.state

        const portalmidcss_web = {
            width: 600,
            height: 600,
            top: 'calc( 50% - 300px )',
            backgroundColor: 'white',
            left: 0, right: 0,
            margin: 'auto',
            transform: 'none'
        }

        return <Portalmodal
            portaltype={'commonportal'}
            open={showfilter}
            onClose={() => {
                document.querySelector("#app-root").style.overflow = "";
                document.querySelector("#app-root").style.position = "";
                document.body.style.overflow = ''
                this.setState({ showfilter: false })
            }}
            floatingclose={false}
            backdropvisible={true}
            modalstyle={portalmidcss_web}
            closestyle={{ right: 0 }}
            floatingclosecolor='var(--maindark)'
        >
            <FilterModalContent
                onApplyFilter={(filters) => {
                    this.onApplyFilter(filters);
                    this.setState({ filters, showfilter: false })
                    document.body.style.overflow = ''
                }}
                filtersetting={this.state.filters}
                usertype={selectedMainCategory}// Wedding or Event
                selectedVendorType={selectedVendorType}
            />
        </Portalmodal>
    }

    // for MainSelectionBox
    genFiltermodalDom_mobile = () => {
        var { selectedMainCategory, selectedVendorType, showfilter } = this.state
        const portalmidcss_mobile = {
            width: '100%',
            height: '100%',
            top: 0,
            backgroundColor: 'white',
            left: 0, right: 0,
            margin: 'auto',
            transform: 'none'
        }

        return <Portalmodal
            portaltype={'commonportal'}
            open={showfilter}
            onClose={() => {

                document.body.style.overflow = ''
                this.setState({ showfilter: false })

            }}
            floatingclose={false}
            backdropvisible={true}
            modalstyle={portalmidcss_mobile}
            closestyle={{ right: 0 }}
            floatingclosecolor='var(--maindark)'
        >
            <FilterModalContent
                onApplyFilter={(filters) => { this.onApplyFilter(filters); this.setState({ filters, showfilter: false }) }}
                filtersetting={this.state.filters}
                usertype={selectedMainCategory}// Wedding or Event
                selectedVendorType={selectedVendorType}
            />
        </Portalmodal>
    }

    // for MainSelectionBox
    genFilterelemDom = () => {
        const { showfilter, filter_arr_bool, filter_arr_header } = this.state
        return (
            <div style={{ display: 'flex', flexDirection: 'row', height: 50, textAlign: 'center', alignItems: 'center', padding: 5, overflowX: 'auto', overflowY: 'hidden', whiteSpace: 'nowrap' }}>
                {filter_arr_bool.map((item, index) => {
                    // implies the filter element is selected
                    return item && !showfilter
                        ? <FilterDisplaySingleElem
                            key={filter_arr_header[index] + index}
                            index={index}
                            item={filter_arr_header[index]}
                            removeSingleFilterElem={() => {
                                const local_filter_arr_bool = filter_arr_bool.slice() //copy the array
                                local_filter_arr_bool[index] = !local_filter_arr_bool[index]
                                var filters = []
                                for (let i = 0; i < filter_arr_header.length; i++) {
                                    if (local_filter_arr_bool[i]) filters.push(filter_arr_header[i])
                                }
                                this.onApplyFilter(filters)
                            }}
                        />
                        : null
                })}
            </div>
        )
    }

    // for MainSelectionBox
    vtChange = (vt) => { // select on new vendortype
        const { selectedVendorType } = this.state
        if (vt !== selectedVendorType) {
            this.setState({ selectedVendorType: vt }, () => {
                this.props.history.push('/event/' + vt)
                this.initialize_event()
            })// scroll to the top
        }
    }

    // for MainSelectionBox
    catChange = () => {
        this.state.selectedMainCategory === 'Wedding' ? this.props.history.push('/event/all') : this.props.history.push('/wedding/all')
    }

    render() {
        var { selectedMainCategory, selectedVendorType, screen_width, screen_height, data, favourite, mainselectionbox_type, abbrev_userlocation, isLoading, preasset_loaded, loaded, date_w, name, spousename } = this.state
        const device = screen_width < WEB ? (screen_width < TABLET ? 'MOBILE' : 'WEB') : 'WEB'

        return loaded
            ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', touchAction: 'manipulation', overflowX: 'hidden' }}>

                <Header {...this.props} usertype="Event" abbrev_userlocation={abbrev_userlocation} wedorevent={'eventvendors'} name={name} spousename={spousename} date_w={date_w}>
                    <MainMenu  {...this.props} selectedMainCategory={selectedMainCategory} usertype={'Event'} />
                </Header>

                {/* {mainselectionbox_type === 'SLIM' ? <div style={{ height: emptyht[device], width: '100%' }} /> : null} */}

                {/* {preasset_loaded
                    ? <Sticky
                        height={startfixed[device]}
                        width='100%'
                        callback={(pos) => {
                            if (mainselectionbox_type === 'FAT' && pos > startfixed[device]) {
                                this.setState({ mainselectionbox_type: 'SLIM' })
                            }
                            else if (mainselectionbox_type === 'SLIM' && pos < startfixed[device]) {
                                this.setState({ mainselectionbox_type: 'FAT' })
                            }
                        }}
                    >
                        <MainSelectionBox

                            {...this.props}

                            // dom elements
                            name={'Event Vendors'}
                            from="EVENT"

                            mobilesticky={mainselectionbox_type === 'SLIM'}

                             // filter data
                            totalfilternum={
                                this.state.filters[selectedMainCategory + selectedVendorType]
                                    ? parseInt(this.state.filters[selectedMainCategory + selectedVendorType].length, 10)
                                    : 0
                            }

                            // box type
                            boxtype={mainselectionbox_type}
                            selectedcat={selectedMainCategory}
                            selectedvt={selectedVendorType}


                            filterelemDom={this.genFilterelemDom()}

                            // filter
                            applyFilter={(filters) => { this.onApplyFilter(filters); this.setState({ filters, showfilter: false }) }}
                            filters={this.state.filters}

                            // functions
                            openFiltermodal={this.openFiltermodal}
                            vtChange={this.vtChange}
                            nameClick={this.catChange}
                            quizFunc={() => { }} // no quiz

                        />
                    </Sticky>
                    : null} */}

                {/* {device === 'WEB' && mainselectionbox_type === 'SLIM' ? <div style={{ display: 'flex', width: '100%', height: startfixed[device] / 2, transition: 'background-color 1500ms cubic-bezier(0.23, 1, 0.32, 1) 0s' }} /> : null} */}

                <MainSelectionBox

                    {...this.props}

                    // dom elements
                    name={'Event Vendors'}
                    from="EVENT"

                    mobilesticky={mainselectionbox_type === 'SLIM'}

                    // filter data
                    totalfilternum={
                        this.state.filters[selectedMainCategory + selectedVendorType]
                            ? parseInt(this.state.filters[selectedMainCategory + selectedVendorType].length, 10)
                            : 0
                    }

                    // box type
                    boxtype={'FAT'} // FAT or SLIM
                    selectedcat={selectedMainCategory}
                    selectedvt={selectedVendorType}


                    filterelemDom={this.genFilterelemDom()}

                    // filter
                    applyFilter={(filters) => {
                        this.onApplyFilter(filters);
                        this.setState({ filters, showfilter: false })
                    }}
                    filters={this.state.filters}

                    // functions
                    openFiltermodal={this.openFiltermodal}
                    vtChange={this.vtChange}
                    nameClick={this.catChange}
                    quizFunc={() => { }} // no quiz

                />

                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    opacity: this.state.showsticky ? 1 : 0,
                    backgroundColor: 'white',
                    zIndex: 1,
                }}>

                    <MainSelectionBox

                        {...this.props}

                        // dom elements
                        name={'Event Vendors'}
                        from="EVENT"

                        mobilesticky={mainselectionbox_type === 'SLIM'}

                        // filter data
                        totalfilternum={
                            this.state.filters[selectedMainCategory + selectedVendorType]
                                ? parseInt(this.state.filters[selectedMainCategory + selectedVendorType].length, 10)
                                : 0
                        }

                        // box type
                        boxtype={'SLIM'}
                        selectedcat={selectedMainCategory}
                        selectedvt={selectedVendorType}


                        filterelemDom={this.genFilterelemDom()}

                        // filter
                        applyFilter={(filters) => { this.onApplyFilter(filters); this.setState({ filters, showfilter: false }) }}
                        filters={this.state.filters}

                        // functions
                        openFiltermodal={this.openFiltermodal}
                        vtChange={this.vtChange}
                        nameClick={this.catChange}
                        quizFunc={() => { }} // no quiz

                    />
                </div>

                <div id='weddingvendorsshow' style={{ height: 1, width: 30, }} />

                {isLoading
                    ? <Loading />
                    : <VendorCards
                        type={device}
                        vendorContent={data}
                        selectedVendorType={selectedVendorType}
                        abbrev_userlocation={abbrev_userlocation}
                        favourite={favourite}
                        updateHeart={(addorremove, id) => {
                            var curr_fav = [...favourite]
                            if (addorremove) { // add
                                curr_fav.push(id)
                                this.setState({ favourite: curr_fav })
                            }
                            else { //remove
                                curr_fav.splice(favourite.indexOf(id), 1)
                                this.setState({ favourite: curr_fav })
                            }
                        }}
                    />}
                <div className='invisiblebox' style={{ height: screen_height / 5 }} />
                <div className='bottomalrdy' style={{ backgroundColor: 'transparent', height: 10, width: '100%' }} />
            </div>
            : <Loading />
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(EventVendors);
