import React, { useEffect, useState } from 'react'
import { EMPTY, SEARCH_FILTER_WEDDING, READABLE_VENDORTYPE_MAP, f_fs, e_fs, d_fs, EXPAND_RIGHT_ICON, PRESENCELOC_ABBREV_MAP, ABBREVCITY_CURRENCY_MAP, WEDDINGVT_ICON_MAP, } from '../../../../config/constants'//EXPAND_ICON,
import Jfmap from '../../../../components/Jfmap/Jfmap';
import { subtitle } from '../Commonfunction';
import { Checkdevice } from '../../../../components';
import { iconwrapper } from '../../../../config/commonfunctions';
import { EXPAND_ICON } from '../../../../config/svgicons';
import './Renderdetail.css'

const emptydom = <div style={{ height: 200, borderRadius: 5, color: 'var(--subgrey)', display: 'flex', placeContent: 'center', fontSize: 24 }} >
</div>

const tagtitle = 'Services and amenities provided.'
const faqtitle = 'Commonly asked questions and answers.'
const pricingtitle = 'Starting price of the vendor.'
const maptitle = 'The venue and location of the vendor.'
const SEP_LINE = <div className='Renderdetail_sep' />

function Renderdetail(props) {

    const [markersdatagate, setMarkersdatagate] = useState(false)
    const [markersData, setMarkersdata] = useState([])// { latLng: { lat: 1.284402, lng: 103.850982 }, title: 1 } // 1.284402,103.850982
    const [faqbool, setFaqbool] = useState([])
    const [firsttime, setFirsttime] = useState(true)
    const [showmap, setShowmap] = useState(false)
    const device = Checkdevice()

    useEffect(() => {
        if (props) {

            const { latlong, qnsans, showmap } = props

            // for map
            setShowmap(showmap)
            setMarkersdata([{ latLng: { lat: isNaN(latlong[0]) ? 0 : latlong[0], lng: isNaN(latlong[1]) ? 0 : latlong[1] }, title: 1 }])
            if (latlong[0] > 0 && latlong[1] > 0) {
                setMarkersdatagate(true)
            }
            else setMarkersdatagate(false)

            if (firsttime) {
                // for faq
                var new_faqbool = []
                for (let i = 0; i < qnsans.length; i++) {
                    new_faqbool.push(false)
                }
                setFaqbool(new_faqbool)
                setFirsttime(false)
            }

        }
    }, [props])


    function RenderSpecific(specific, vt,) {
        if (vt) {
            var root_map = SEARCH_FILTER_WEDDING[vt]

            // removal of Sort subcategory
            if ('Sort' in root_map) {
                delete root_map['Sort']
                root_map.Keys.shift()
            }

            var category_tags_map = {}

            if (root_map) {
                const keys = root_map.Keys
                for (let i = 0; i < keys.length; i++) {
                    var key = keys[i]
                    category_tags_map[key] = []
                    for (let j = 0; j < root_map[key].length; j++) {
                        category_tags_map[key].push([])
                    }
                }

                // remake a map for rendering, category_tags_map : { category:[] }
                for (let i = 0; i < specific.length; i++) {
                    var tag = specific[i]
                    for (let j = 0; j < root_map.Keys.length; j++) {
                        var subcat = root_map.Keys[j]
                        for (let k = 0; k < root_map[subcat].length; k++) {

                            // ------------------
                            // |                |
                            // |KEY             |
                            // |                |
                            // ------------------  
                            // |SUBCAT          |
                            // |   ----------   |
                            // |   |        |   |
                            // |   | TAGsss |   |
                            // |   |        |   |
                            // |   ----------   |
                            // ------------------  

                            if (tag === root_map[subcat][k].header) {
                                category_tags_map[subcat][k] = <div key={'stylesitem' + tag} className='Renderdetail_tag'>{tag}</div>
                            }
                        }
                    }
                }
                return (
                    <div>
                        {root_map.Keys.map((category, index) => {
                            var bool = false
                            for (let i = 0; i < category_tags_map[category].length; i++) {
                                if (category_tags_map[category][i].length !== 0) {
                                    bool = true
                                }
                            }
                            if (bool) {
                                return <div
                                    key={category + index}
                                    style={{
                                        paddingBottom: 10,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        fontSize: d_fs,
                                    }}
                                >
                                    <div className={`Renderdetail_tagcattitle_${device}`}>{category}</div>
                                    <div className={`Renderdetail_taggroup Renderdetail_taggroup_${device}`}>{category_tags_map[category]}</div>
                                </div>
                            }
                            else return null
                        })}
                    </div>
                )
            }
            else {
                return null
            }
        }
        else {
            return null

        }

    }

    function RenderPricing() {
        const { gtsvt, location_pricepresence_map, acct_location, vendortype, secondaryvendortype, location } = props
        const abbrev_location = PRESENCELOC_ABBREV_MAP[acct_location === 'NOLOCATION' ? location : acct_location] // viewer location, viewer either USER or VENDOR
        const currency = ABBREVCITY_CURRENCY_MAP[abbrev_location] // currency, RM, SGD, ...

        var vt_pricing = location_pricepresence_map[abbrev_location + 'minprice_vt']
        var svt_pricing = location_pricepresence_map[abbrev_location + 'minprice_svt']
        // var vendorprofile_country_presence = location_pricepresence_map[abbrev_location]

        var iconcss = { width: 30, height: 30, display: 'flex', placeContent: 'center', alignItems: 'center' }
        return <div className='Renderdetail_vtroot'>
            <div style={{ width: '100%', justifyContent: 'flex-start' }}> {subtitle('Pricing', pricingtitle)}</div>
            <div className={`Renderdetail_pricecardsroot${device}`}>
                <div className={`Renderdetail_pricecardroot Renderdetail_pricecardroot${device}`}>
                    <div className='Renderdetail_pricecardtop'>
                        {iconwrapper(WEDDINGVT_ICON_MAP[vendortype], iconcss)}
                        <div className='Renderdetail_vt'>{READABLE_VENDORTYPE_MAP[vendortype]}</div>
                    </div>
                    <div className='Renderdetail_pricecardbtm'>
                        <div className='Renderdetail_pricecardstartsat'>Starts At</div>
                        <div className='Renderdetail_pricing'>{'$' + vt_pricing + ' ' + currency}</div>
                    </div>
                </div>
                {device === 'TABLET' ? <div style={{height:10}}/>:null}
                {secondaryvendortype && secondaryvendortype.length > 0
                    ? <div className={`Renderdetail_pricecardroot Renderdetail_pricecardroot${device}`}>
                        <div className='Renderdetail_pricecardtop'>
                            {iconwrapper(WEDDINGVT_ICON_MAP[secondaryvendortype], iconcss)}
                            <div className='Renderdetail_vt'>{READABLE_VENDORTYPE_MAP[secondaryvendortype]}</div>
                        </div>
                        <div className='Renderdetail_pricecardbtm'>
                            <div className='Renderdetail_pricecardstartsat'>Starts At</div>
                            <div className='Renderdetail_pricing'>{'$' + svt_pricing + ' ' + currency}</div>
                        </div>
                    </div>
                    : null}
            </div>
        </div>
    }

    function RenderDescription() {
        const { description, pri_specific, vendortype, } = props

        if (description.length > 0) {
            return (
                <div style={{ padding: '30px 20px 20px 20px', color: 'var(--maindark)', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}> {/*description*/}
                    {description}
                </div>
            )
        }
        else return null
    }

    function RenderFaqs(wt) {
        const { qnsans } = props
        if (qnsans && qnsans.length > 0) {
            return <div className='Renderdetail_vtroot' style={{ width: wt }}>
                <div style={{ width: '100%', justifyContent: 'flex-start', }}> {subtitle('FAQ', faqtitle)}</div>
                {faqbool && faqbool.length > 0 && qnsans.map(({ qn, ans }, index) => {

                    const onClickfaq = () => {
                        var old_faqbool = faqbool.slice()
                        old_faqbool[index] = !old_faqbool[index]
                        setFaqbool(old_faqbool)
                    }

                    let br
                    if (index === 0 && index === qnsans.length - 1) {
                        br = '5px 5px 5px 5px'
                    }
                    else if (index === 0) {
                        br = '5px 5px 0px 0px'
                    }
                    else if (index === qnsans.length - 1) {
                        br = '0px 0px 5px 5px'
                    }

                    return faqbool[index]
                        ? <div
                            key={'faq' + index}
                            onClick={onClickfaq}
                            className='Renderdetail_faqinnerroot'
                            style={{ borderRadius: br }}
                        >
                            <div className='Renderdetail_qnopened'>
                                <div>{qn}</div>
                            </div>
                            <div style={{ padding: 10, display: 'flex', flexDirection: 'column', width: '100%' }}>
                                {ans.split("\n").map((ans, x) => <div key={'ans' + x}>{ans}</div>)}
                            </div>
                        </div>
                        : <div
                            key={'faq' + index}
                            onClick={onClickfaq}
                            className='Renderdetail_faqinnerroot'
                            style={{ borderRadius: br }}
                        >
                            <div className='Renderdetail_faqcloseroot'>
                                <div>{qn}</div>
                                <div>{EXPAND_RIGHT_ICON}</div>
                            </div>
                        </div>
                })}
            </div>
        }
        else {
            return <div className='Renderdetail_vtroot' style={{ border: 'none', width: wt }}>
                <div style={{ width: '100%', justifyContent: 'flex-start', }}> {subtitle('FAQ', faqtitle)}</div>
                <div style={{}}>
                    {emptydom}
                </div>
            </div>
        }
    }

    function RenderFaqs_web(wt) {
        const { qnsans } = props
        if (qnsans && qnsans.length > 0) {
            return <div className='Renderdetail_faqroot' style={{ width: wt }}>
                <div className='Renderdetail_tagtitle'> {subtitle('FAQ', faqtitle)}</div>
                {faqbool && faqbool.length > 0 && qnsans.map(({ qn, ans }, index) => {

                    const onClickfaq = () => {
                        var old_faqbool = faqbool.slice()
                        old_faqbool[index] = !old_faqbool[index]
                        setFaqbool(old_faqbool)
                    }

                    let br
                    if (index === 0 && index === qnsans.length - 1) {
                        br = '5px 5px 5px 5px'
                    }
                    else if (index === 0) {
                        br = '5px 5px 0px 0px'
                    }
                    else if (index === qnsans.length - 1) {
                        br = '0px 0px 5px 5px'
                    }

                    return faqbool[index]
                        ? <div
                            key={'faq' + index}
                            onClick={onClickfaq}
                            className='Renderdetail_faqinnerroot'
                            style={{ borderBottom: index === qnsans.length - 1 ? '1px solid var(--subgrey)' : '', borderRadius: br }}
                        >
                            <div className='Renderdetail_qnopened'>
                                <div>{qn}</div>
                            </div>
                            <div style={{ padding: 10, display: 'flex', flexDirection: 'column', width: '100%' }}>
                                {ans.split("\n").map((ans, x) => <div key={'ans' + x}>{ans}</div>)}
                            </div>
                        </div>
                        : <div
                            key={'faq' + index}
                            onClick={onClickfaq}
                            className='Renderdetail_faqinnerroot'
                            style={{ borderBottom: index === qnsans.length - 1 ? '1px solid var(--subgrey)' : '', borderRadius: br }}
                        >
                            <div className='Renderdetail_faqcloseroot'>
                                <div>{qn}</div>
                                <div>{EXPAND_RIGHT_ICON}</div>
                            </div>
                        </div>
                })}
            </div>
        }
        else {
            return <div className='Renderdetail_faqroot' style={{ width: wt }}>
                <div className='Renderdetail_tagtitle'> {subtitle('FAQ', faqtitle)}</div>
                <div style={{}}>
                    {emptydom}
                </div>
            </div>
        }
    }

    function RenderVendorspecific_web(wt) {
        const { pri_specific = [], sec_specific = [], vendortype, secondaryvendortype } = props
        if (vendortype !== EMPTY && secondaryvendortype !== EMPTY) {
            if (pri_specific.length > 0 && sec_specific.length > 0) {
                return (
                    <div className='Renderdetail_tagroot' style={{ width: wt }}>
                        <div className='Renderdetail_tagtitle'>{subtitle('Tags', tagtitle)}</div>
                        <div className='Renderdetail_tagprimarycontent_web'>
                            <div className='Renderdetail_tagcat_web'>
                                <div>{READABLE_VENDORTYPE_MAP[vendortype]}</div>
                                <div className='Renderdetail_taglbl'>{pri_specific.length + ' TAGS'}</div>
                            </div>
                            <div className='Renderdetail_tagcatcontent_web'>
                                {RenderSpecific(pri_specific, vendortype)}
                            </div>
                        </div>
                        <div className='Renderdetail_tagsecondarycontent_web'>
                            <div className='Renderdetail_tagcat_web'>
                                <div>{READABLE_VENDORTYPE_MAP[secondaryvendortype]}</div>
                                <div className='Renderdetail_taglbl'>{sec_specific.length + ' TAGS'}</div>
                            </div>
                            <div className='Renderdetail_tagcatcontent_web'>
                                {RenderSpecific(sec_specific, secondaryvendortype)}
                            </div>
                        </div>
                    </div>
                )
            }
            else if (pri_specific.length > 0 && sec_specific.length === 0) {
                return (
                    <div className='Renderdetail_tagroot' style={{ width: wt, }}>
                        <div className='Renderdetail_tagtitle'> {subtitle('Tags', tagtitle)}</div>

                        <div className='Renderdetail_tagprimarycontent_web'>
                            <div className='Renderdetail_tagcat_web'>
                                <div>{READABLE_VENDORTYPE_MAP[vendortype]}</div>
                                <div className='Renderdetail_taglbl'>{pri_specific.length + ' TAGS'}</div>
                            </div>
                            <div className='Renderdetail_tagcatcontent_web'>{RenderSpecific(pri_specific, vendortype)}</div>
                        </div>
                    </div>
                )
            }
            else if (pri_specific.length === 0 && sec_specific.length > 0) {
                return (
                    <div className='Renderdetail_tagroot' style={{ width: wt, }}>
                        <div className='Renderdetail_tagtitle'> {subtitle('Tags', tagtitle)}</div>

                        <div className='Renderdetail_tagprimarycontent_web'>
                            <div className='Renderdetail_tagcat_web'>
                                <div>{READABLE_VENDORTYPE_MAP[secondaryvendortype]}</div>
                                <div className='Renderdetail_taglbl'>{sec_specific.length + ' TAGS'}</div>
                            </div>
                            <div className='Renderdetail_tagcatcontent_web'>{RenderSpecific(pri_specific, vendortype)}</div>
                        </div>
                    </div>
                )
            }
            else if (pri_specific.length === 0 && sec_specific.length === 0) {
                return <div className='Renderdetail_tagroot' style={{ width: wt, }}>
                    <div className='Renderdetail_tagtitle'> {subtitle('Tags', tagtitle)}</div>
                    <div style={{}}>
                        {emptydom}
                    </div>
                </div>

            }
        }
        else {
            if (pri_specific.length > 0) {
                return (
                    <div className='Renderdetail_tagroot' style={{ width: wt, }}>
                        <div className='Renderdetail_tagtitle'> {subtitle('Tags', tagtitle)}</div>

                        <div className='Renderdetail_tagprimarycontent_web'>
                            <div className='Renderdetail_tagcat_web'>
                                <div>{READABLE_VENDORTYPE_MAP[vendortype]}</div>
                                <div className='Renderdetail_taglbl'>{pri_specific.length + ' TAGS'}</div>
                            </div>
                            <div className='Renderdetail_tagcatcontent_web'>{RenderSpecific(pri_specific, vendortype)}</div>
                        </div>
                    </div>
                )
            }
            else if (pri_specific.length === 0) {
                return <div className='Renderdetail_tagroot' style={{ width: wt, }}>
                    <div className='Renderdetail_tagtitle'> {subtitle('Tags', tagtitle)}</div>
                    <div style={{}}>
                        {emptydom}
                    </div>
                </div>

            }
        }
    }

    function RenderVendorspecific_mobile() {
        const { pri_specific = [], sec_specific = [], vendortype, secondaryvendortype, eventorwed } = props

        if (vendortype !== EMPTY && secondaryvendortype !== EMPTY) {
            if (pri_specific.length > 0 && sec_specific.length > 0) {
                return <div className='Renderdetail_tagroot' style={{}}>
                    <div className='Renderdetail_tagtitle'> {subtitle('Tags', tagtitle)}</div>

                    <div className='Renderdetail_tagprimarycontent_mobile' style={{ paddingBottom: 10 }}>
                        <div className='Renderdetail_tagcat_mobile'>
                            <div>{READABLE_VENDORTYPE_MAP[vendortype]}</div>
                            <div className='Renderdetail_taglbl'>{pri_specific.length + ' TAGS'}</div>
                        </div>
                        <div className='Renderdetail_tagcatcontent_mobile'>
                            {RenderSpecific(pri_specific, vendortype)}
                        </div>
                    </div>
                    <div className='Renderdetail_tagprimarycontent_mobile' >
                        <div className='Renderdetail_tagcat_mobile'>
                            <div>{READABLE_VENDORTYPE_MAP[secondaryvendortype]}</div>
                            <div className='Renderdetail_taglbl'>{sec_specific.length + ' TAGS'}</div>
                        </div>
                        <div className='Renderdetail_tagcatcontent_mobile'>
                            {RenderSpecific(sec_specific, secondaryvendortype)}
                        </div>
                    </div>
                </div>
            }
            else if (pri_specific.length > 0 && sec_specific.length === 0) {
                return <div className='Renderdetail_tagroot' style={{}}>
                    <div className='Renderdetail_tagtitle'> {subtitle('Tags', tagtitle)}</div>

                    <div className='Renderdetail_tagprimarycontent_mobile'>
                        <div className='Renderdetail_tagcat_mobile'>
                            <div>{READABLE_VENDORTYPE_MAP[vendortype]}</div>
                            <div className='Renderdetail_taglbl'>{pri_specific.length + ' TAGS'}</div>
                        </div>
                        <div className='Renderdetail_tagcatcontent_mobile'>
                            {RenderSpecific(pri_specific, vendortype)}
                        </div>
                    </div>
                </div>
            }
            else if (pri_specific.length === 0 && sec_specific.length > 0) {
                return <div className='Renderdetail_tagroot'>
                    <div className='Renderdetail_tagtitle'> {subtitle('Tags', tagtitle)}</div>

                    <div className='Renderdetail_tagprimarycontent_mobile' >
                        <div className='Renderdetail_tagcat_mobile'>
                            <div>{READABLE_VENDORTYPE_MAP[secondaryvendortype]}</div>
                            <div className='Renderdetail_taglbl'>{sec_specific.length + ' TAGS'}</div>
                        </div>
                        <div className='Renderdetail_tagcatcontent_mobile'>
                            {RenderSpecific(sec_specific, secondaryvendortype)}
                        </div>
                    </div>
                </div>
            }
            else if (pri_specific.length === 0 && sec_specific.length === 0) {
                return <div className='Renderdetail_tagroot'>
                    <div className='Renderdetail_tagtitle'> {subtitle('Tags', tagtitle)}</div>
                    <div style={{}}>
                        {emptydom}
                    </div>
                </div>
            }
        }
        else { // only vendortype exist
            if (pri_specific.length > 0) {
                return <div className='Renderdetail_tagroot'>
                    <div className='Renderdetail_tagtitle'> {subtitle('Tags', tagtitle)}</div>
                    <div className='Renderdetail_tagprimarycontent_mobile'>
                        <div className='Renderdetail_tagcat_mobile'>
                            <div>{READABLE_VENDORTYPE_MAP[vendortype]}</div>
                            <div className='Renderdetail_taglbl'>{pri_specific.length + ' TAGS'}</div>
                        </div>
                        <div className='Renderdetail_tagcatcontent_mobile'>
                            {RenderSpecific(pri_specific, vendortype)}
                        </div>
                    </div>
                </div>
            }
            else if (pri_specific.length === 0) {
                return null
            }
        }
    }

    function RenderMap() {

        if (showmap) {
            if (markersdatagate) {
                return (
                    <div className='Renderdetail_vtroot' style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
                        <div style={{ width: '100%', justifyContent: 'flex-start' }}> {subtitle('Map', maptitle)}</div>
                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <a
                                style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', position: 'absolute', zIndex: 999, right: 0, backgroundColor: 'transparent', borderRadius: 5, cursor: 'pointer', margin: 10 }}
                                onClick={() => {
                                    if (markersData) {
                                        var lat = markersData[0].latLng.lat
                                        var lng = markersData[0].latLng.lng
                                        window.open("https://www.google.com/maps/search/?api=1&query=" + lat + "," + lng, "_blank")
                                    }
                                }}
                            >
                                {iconwrapper(
                                    EXPAND_ICON,
                                    {
                                        width: 27,
                                        height: 27,
                                        display: 'flex', borderRadius: 4, padding: 5, placeContent: 'center', alignItems: 'center', backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.65) 0px 1px 5px', color: 'var(--maindark)'
                                    }
                                )}
                            </a>
                            <Jfmap markersData={markersData} />
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className='Renderdetail_vtroot' style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <div style={{ width: '100%', justifyContent: 'flex-start' }}> {subtitle('Map', maptitle)}</div>
                        <div style={{ position: 'relative', width: '100%', height: '100%', border: '1px solid var(--subgrey)', height: 200, backgroundColor: 'var(--subgrey)' }} />
                    </div>
                )
            }
        }

    }

    const RenderFaqspecific = () => {
        return {
            'MOBILE': < div className='Renderdetail_faqspecificMOBILE'>
                <div className='Renderdetail_faqspecificcol'>
                    {RenderVendorspecific_mobile()}
                </div>
                <div className='Renderdetail_faqspecificcol'>
                    {RenderFaqs('100% !important')}
                </div>
            </div>,
            'TABLET': < div className='Renderdetail_faqspecificTABLET'>
                <div className='Renderdetail_faqspecificcol'>
                    {RenderVendorspecific_mobile()}
                </div>
                <div className='Renderdetail_faqspecificcol'>
                    {RenderFaqs('100% !important')}
                </div>
            </div>,
            'WEB': <div className='Renderdetail_faqspecificWEB'>
                <div className='Renderdetail_faqspecificrow'>
                    {RenderVendorspecific_web('50%')}
                    {RenderFaqs_web('50%')}
                </div>
            </div>
        }[device]
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', fontFamily: 'Lato' }}>
            {RenderDescription()}
            {props.description.length > 0 ? SEP_LINE : null}
            {device === 'MOBILE'
                ? < div className='Renderdetail_faqspecificMOBILE'>
                    <div className='Renderdetail_faqspecificcol'>
                        {RenderVendorspecific_mobile()}
                    </div>
                    {SEP_LINE}
                    <div className='Renderdetail_faqspecificcol'>
                        {RenderFaqs('100% !important')}
                    </div>
                    {SEP_LINE}
                </div>
                : null}
            {device === 'TABLET'
                ? < div className='Renderdetail_faqspecificTABLET'>
                    <div className='Renderdetail_faqspecificcol'>
                        {RenderVendorspecific_mobile()}
                    </div>
                    {SEP_LINE}
                    <div className='Renderdetail_faqspecificcol'>
                        {RenderFaqs('100% !important')}
                    </div>
                    {SEP_LINE}
                </div>
                : null}
            {device === 'WEB'
                ? <>
                    <div className='Renderdetail_faqspecificWEB'>
                        <div className='Renderdetail_faqspecificrow'>
                            {RenderVendorspecific_web('50%')}
                            {RenderFaqs_web('50%')}
                        </div>
                    </div>
                    {SEP_LINE}
                </>
                : null}
            {RenderPricing()}
            {SEP_LINE}
            {RenderMap()}
            {showmap ? SEP_LINE : null}

        </div>
    )
}

export default Renderdetail