import React, { Component, useEffect, useState } from 'react'
import { READABLE_VENDORTYPE_MAP, WEB, TABLET } from '../../config/constants'
import { Renderrequests } from '../../pages/Vendorlookup/components';
import { Link } from "react-router-dom";
import { BUTTONSTYLE } from '../../config/cssconstants';
import { Checkdevice, Portalmodal, Processmoney } from '..';
import { getS3fullpath_withbucketname, iconwrapper } from '../../config/commonfunctions';
import { DELIEVERABLES_ICON, DOLLAR_ICON, DURATION_ICON, LOCATION_ICON, PLUS_ICON } from '../../config/svgicons';
import './Pricelistcard.css'
import Enquirycontent from '../Enquirycontent/Enquirycontent';

function commontitle(title, icon) {
    return <div style={{ display: 'flex', width: '100%', placeContent: 'flex-start', padding: 10 }}>
        {icon ? <div>{icon}</div> : null}
        <div style={{ fontWeight: 'bold', paddingLeft: 5, fontSize: 16, color: '#707070' }}>{title}</div>
    </div>
}

function bullets(val, index, fs) {
    return <div key={index} style={{ margin: '15px 15px 10px 15px', fontSize: 14, flexWrap: 'wrap' }}>
        {'• ' + val}
    </div>
}

const ICON_CSS = { width: 20, height: 20, display: 'flex', placeContent: 'center' }


function Pricelistcard(props) {

    const { item, whatsapp_link, telegram_link, vti_id, vt, svt, index } = props

    const { name, _id, displayname,
        wedtype, eventorwed, location, //indicatorDom 
        curr_vendortype, // tag
        price, price_fixedval, currency, price_rangestartval, price_rangeendval, price_addnote,// price
        destination, destination_addnote, //destination
        duration, duration_fixedval, duration_rangestartval, duration_rangeendval, duration_addnote, // duration
        deliverables_items,
        additionalcharges_items,
        description,
        others, // others
        tnc,// terms and cond
        pltype,
        redirectlink,
        downloadablelink
    } = item

    const device = Checkdevice()
    const [commontextsize, setCommontextsize] = useState(null)
    const [expand, setExpand] = useState(false)

    // ---------- Enquiry Portal related data END

    const mobileportalcss = { width: '100%', height: '100%', backgroundColor: 'white', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)', overflowY: 'auto' }
    const webtabletportalcss = { width: 600, height: 650, backgroundColor: 'white', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)' }

    // show enquire portal
    const [showenquire, setShowenquire] = useState(false)

    // for both single enquire and all selected enquire
    const [enquiredata, setEnquiredata] = useState([])

    // login user data
    const [loggeduserdata, setLoggeduserdata] = useState({
        'name': '',
        'spousename': '',
        'date_w': '',
        'date_e': '',
        'email': '',
        'contact': '',
        'usertype': '', // wedding, event or guest
        'location': ''
    })

    // ---------- Enquiry Portal related data END

    useEffect(() => {
        setCommontextsize(device === 'MOBILE' ? 13 : 14)
        setLoggeduserdata(props.loggeduserdata)
    }, [props])

    useEffect(() => {
        setExpand(props.expand)
    }, [props.expand])

    const nameDom = (name, wt) => {
        return <div className="pllook_name Pricelistcardbuildin_expandcard" style={{ width: wt }}>
            {name}
        </div>
    }

    const indicatorDom = (wedtype, location, wt) => {
        const indcontentcss = { display: 'flex', alignSelf: 'flex-start', width: '100%' }
        return <div className='Pricelistcardbuildin_hiddenindicator' style={{ width: wt }}>
            <div style={indcontentcss}>{location + ', ' + wedtype}</div>
        </div>
    }

    const tagDom = (curr_vts = [], cat, wt) => {
        const fs = 12
        var tags
        if (curr_vts.length > 1) tags = [cat, READABLE_VENDORTYPE_MAP[curr_vts[0]], READABLE_VENDORTYPE_MAP[curr_vts[1]]]
        else tags = [cat, READABLE_VENDORTYPE_MAP[curr_vts[0]]]
        const tagcss = { display: 'flex', color: 'white', padding: '6px 8px ', margin: '0px 8px 8px 0px', letterSpacing: 0.5, borderRadius: 4, backgroundColor: '#707070' }
        return (
            <div style={{ borderBottom: '1px solid #eee', display: 'flex', flexDirection: 'row', width: wt, justifyContent: 'flex-start', fontSize: fs, padding: 10, flexWrap: 'wrap' }}>
                {tags.length > 0 && tags.map((val, i) => {
                    return <div style={tagcss} key={i + val}>
                        <div style={{ paddingLeft: 5, }}>{val && val.length > 0 ? val.toString().toUpperCase() : null}</div>
                    </div>
                })}
            </div>
        )
    }

    const destinationDom = (destination, destination_addnote) => {
        const destination_bool = destination !== 'None' || destination !== ''
        const destinationnote_bool = destination_addnote && destination_addnote.length > 0
        const destinationval = {
            '': 'None',
            'None': 'None',
            'Local': 'Local',
            'Overseas': 'Overseas',
            'Both': 'Both',
            'Local + Local': 'Local(Pre) & Local(Actual)',
            'Local + Overseas': 'Local(Pre) & Overseas(Actual)',
            'Overseas + Local': 'Overseas(Pre) & Local(Actual)',
            'Overseas + Overseas': 'Overseas(Pre) & Overseas(Actual)'
        }[destination]
        return destination_bool
            ? <div className='Pricelistcardbuildin_expandattrroot'>

                <div className='Pricelistcardbuildin_expandattrrow'>

                    <div className='Pricelistcardbuildin_expandattricon'>
                        {iconwrapper(LOCATION_ICON, ICON_CSS)}
                        <div className='Pricelistcardbuildin_expandattriconlbltxt'>Destination</div>
                    </div>

                    <div className='Pricelistcardbuildin_expandattricontxt'>
                        {destinationval}
                    </div>

                </div>

                {destinationnote_bool
                    ? <div className='Pricelistcardbuildin_expandattrextra'>
                        {destination_addnote}
                    </div>
                    : null}

            </div>
            : null
    }

    const durationDom = (duration, duration_fixedval, duration_rangestartval, duration_rangeendval, duration_addnote, wt) => {
        let duration_bool = duration !== 'None' || duration !== ''
        let durationnote_bool = duration_addnote && duration_addnote.length > 0
        let durationval = {
            '': 'None',
            'None': 'None',
            'Range': duration_rangestartval + ' - ' + duration_rangeendval + ' hr',
            'Fixed': duration_fixedval + ' hr'
        }[duration]

        return duration_bool
            ? <div className='Pricelistcardbuildin_expandattrroot'>

                <div className='Pricelistcardbuildin_expandattrrow'>

                    <div className='Pricelistcardbuildin_expandattricon'>
                        {iconwrapper(DURATION_ICON, ICON_CSS)}
                        <div className='Pricelistcardbuildin_expandattriconlbltxt'>Duration</div>
                    </div>

                    <div className='Pricelistcardbuildin_expandattricontxt'>
                        {durationval}
                    </div>

                </div>

                {durationnote_bool
                    ? <div className='Pricelistcardbuildin_expandattrextra'>
                        {duration_addnote}
                    </div>
                    : null}

            </div>
            : null
    }

    const delieverablesDom = (deliverables_items = [], wt) => {
        return <div className='Pricelistcardbuildin_expandattrroot'>

            <div className='Pricelistcardbuildin_expandattrcol'>

                <div className='Pricelistcardbuildin_expandattricon'>
                    {iconwrapper(DELIEVERABLES_ICON, ICON_CSS)}
                    <div className='Pricelistcardbuildin_expandattriconlbltxt'>Delieverables</div>
                </div>

                {deliverables_items.map((val, index) => bullets(val, index, commontextsize))}

            </div>

        </div>

    }

    const additionalchargesDom = (additionalcharges_items, wt) => {
        let additionalcharges_bool = additionalcharges_items && additionalcharges_items.length > 0

        return additionalcharges_bool
            ? <div className='Pricelistcardbuildin_expandattrroot'>

                <div className='Pricelistcardbuildin_expandattricon'>
                    {iconwrapper(PLUS_ICON, ICON_CSS)}
                    <div className='Pricelistcardbuildin_expandattriconlbltxt'>Additional Charges</div>
                </div>
                {additionalcharges_items.map((val, index) => bullets(val, index, commontextsize))}
            </div>
            : null

    }

    const descriptionDom = (description) => {
        let description_bool = description && description.length > 0
        return description_bool
            ? <div className='Pricelistcardbuildin_expandattrroot'>

                <div className='Pricelistcardbuildin_expandattricon'>
                    <div className='Pricelistcardbuildin_expandattriconlbltxt' style={{ paddingLeft: 0 }}>Description</div>
                </div>

                <div className='Pricelistcardbuildin_expandattrextra' style={{ padding: '0px 10px' }}>
                    {description}
                </div>
            </div>
            : null

    }

    const othersDom = (others) => {
        let others_bool = others && others.length > 0
        return others_bool
            ? <div className='Pricelistcardbuildin_expandattrroot'>

                <div className='Pricelistcardbuildin_expandattricon'>
                    <div className='Pricelistcardbuildin_expandattriconlbltxt' style={{ paddingLeft: 0 }}>Others</div>
                </div>

                <div className='Pricelistcardbuildin_expandattrextra' style={{ padding: '0px 10px' }}>
                    {others}
                </div>
            </div>
            : null

    }

    const termsandcondDom = (tnc, wt) => {
        let tnc_bool = tnc && tnc.length > 0
        return tnc_bool
            ? <div className='Pricelistcardbuildin_expandattrroot'>

                <div className='Pricelistcardbuildin_expandattricon'>
                    <div className='Pricelistcardbuildin_expandattriconlbltxt' style={{ paddingLeft: 0 }}>{`Terms & Conditions`}</div>
                </div>

                <div className='Pricelistcardbuildin_expandattrextra' style={{ padding: '0px 10px' }}>
                    {tnc}
                </div>
            </div>
            : null
    }


    const priceDom = (price, price_fixedval, currency, price_rangestartval, price_rangeendval, wt) => {
        return (
            <div className='Pricelistcardbuildin_hiddenpriceroot' style={{ padding: '45px 0px', height: 'auto', margin: 0 }}>
                <div className='Pricelistcardbuildin_hiddenpricestr'>
                    {/* {iconwrapper(ENTER_ICON, { ...ICON_CSS, margin: 10, color: 'transparent' })} */}
                    {price === 'FIXED'
                        ? '$ ' + Processmoney(price_fixedval) + ' ' + currency
                        : '$ ' + Processmoney(price_rangestartval) + ' - $ ' + Processmoney(price_rangeendval) + ' ' + currency
                    }
                    {/* {iconwrapper(ENTER_ICON, { ...ICON_CSS, margin: 10 })} */}
                </div>
            </div>
        )
    }

    const hidden_nameDom = (name, wt) => {
        const fs = device === 'MOBILE' ? 16 : 18
        const NUM = 50
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: wt, padding: 10, fontSize: fs }}>
                {name && name.length > NUM ? <div>{name.substring(0, 50) + '...'}</div> : <div>{name}</div>}
            </div>
        )
    }

    const hidden_indicatorDom = (wedtype, location, wt) => {
        return <div className='Pricelistcardbuildin_hiddenindicator' style={{ width: wt }}>
            <div >{location + ', ' + wedtype}</div>
        </div>
    }

    const hidden_destinationDom = (destination, wt) => {
        return (
            <div className='Pricelistcardbuildin_hiddenattrs' style={{ width: wt }}>
                {iconwrapper(LOCATION_ICON, ICON_CSS)}
                <div className='Pricelistcardbuildin_hiddenattrstxt' >{destination && destination.length > 0 && destination !== 'None' ? '✓' : '-'}</div>
            </div>
        )
    }

    const hidden_durationDom = (duration, wt) => {
        return (
            <div className='Pricelistcardbuildin_hiddenattrs' style={{ width: wt }}>
                {iconwrapper(DURATION_ICON, ICON_CSS)}
                <div className='Pricelistcardbuildin_hiddenattrstxt'>{duration && duration.length > 0 && duration !== 'None' ? '✓' : '-'}</div>
            </div>
        )
    }

    const hidden_delieverablesDom = (deliverables_items = [], wt) => {
        return (
            <div className='Pricelistcardbuildin_hiddenattrs' style={{ width: wt }}>
                {iconwrapper(DELIEVERABLES_ICON, ICON_CSS)}
                <div className='Pricelistcardbuildin_hiddenattrstxt'>{deliverables_items.length}</div>
            </div>
        )
    }

    const hidden_tagDom = (curr_vts, cat, wt) => {
        let tags = curr_vts.length > 1
            ? [cat, READABLE_VENDORTYPE_MAP[curr_vts[0]], READABLE_VENDORTYPE_MAP[curr_vts[1]]]
            : [cat, READABLE_VENDORTYPE_MAP[curr_vts[0]]]
        return (
            <div className='Pricelistcardbuildin_hiddentagroot' style={{ width: wt }}>
                {tags.length > 0 && tags.map((val, i) => <div className='pllook_tag' key={i + val} style={{ margin: '5px 5px 0px 0px' }}>
                    <div >{val && val.length > 0 ? val.toString().toUpperCase() : null}</div>
                </div>
                )}
            </div>
        )
    }

    const hidden_priceDom = (price, price_fixedval, currency, price_rangestartval, price_rangeendval, wt) => {
        return (
            <div className='Pricelistcardbuildin_hiddenpriceroot'>
                <div className='Pricelistcardbuildin_hiddenpricestr'>
                    {price === 'FIXED'
                        ? '$ ' + Processmoney(price_fixedval) + ' ' + currency
                        : '$ ' + Processmoney(price_rangestartval) + ' - $ ' + Processmoney(price_rangeendval) + ' ' + currency
                    }
                </div>
            </div>
        )
    }

    const wt = '100%'


    // ---------- Enquiry Portal related data END
    const enquire_click = () => {
        setShowenquire(true)
        setEnquiredata([{ displayname, vendortypeinterface_id: vti_id, telegram_link: telegram_link, whatsapp_link: whatsapp_link, }])
    }

    const enquiry_portal = <Portalmodal
        portaltype={'commonportal'}
        open={showenquire}
        onClose={() => { setShowenquire(false); setEnquiredata([]) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={{ 'WEB': webtabletportalcss, 'TABLET': webtabletportalcss, 'MOBILE': mobileportalcss }[device]}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <Enquirycontent
            from="Pricelistcard"
            userdata={loggeduserdata}
            data={enquiredata}
            allowchat={true}
            allowtelegram={true}
            allowwhatsapp={true}
            allowemail={true}
            extradata={name}
            oneormany={'ONE'} // send to only one vendor
            vt={vt}
            svt={svt}
        />
    </Portalmodal>
    // ---------- Enquiry Portal related data END

    // pltype 
    // redirectlink
    // downloadablelink

    const redirect_or_downloadable_dom = <button
        style={{width:100, height:50}}
        onClick={() => {
            let link = ''
            if (downloadablelink.length > 0) {
                link = getS3fullpath_withbucketname('VENDOR_PRICELIST_IMG', downloadablelink)
            }
            else {
                link = redirectlink
            }
            window.open(link, '_blank');  // Open link in a new tab
        }}
    >
        {`Pricelist Detail (.pdf)`}
    </button>

    return expand
        ? <div key={'pl' + index} className='Pricelistcardbuildin_expandedroot' >
            {[
                <>
                    {nameDom(name, wt)}
                    {indicatorDom(wedtype, location, wt)}
                    {hidden_tagDom(curr_vendortype, eventorwed, wt)}
                    {destinationDom(destination, destination_addnote, wt)}
                    {durationDom(duration, duration_fixedval, duration_rangestartval, duration_rangeendval, duration_addnote, wt)}
                    {delieverablesDom(deliverables_items, wt)}
                    {additionalchargesDom(additionalcharges_items, wt)}
                    {descriptionDom(description, wt)}
                    {othersDom(others)}
                    {termsandcondDom(tnc)}
                    {priceDom(price, price_fixedval, currency, price_rangestartval, price_rangeendval, '100%')}
                </>,
                <>
                    {nameDom(name, wt)}
                    {indicatorDom(wedtype, location, wt)}
                    {hidden_tagDom(curr_vendortype, eventorwed, wt)}
                    {priceDom(price, price_fixedval, currency, price_rangestartval, price_rangeendval, '100%')}
                    {/* {redirect_or_downloadable_dom} */}
                </>
            ][pltype]}
            {enquiry_portal}
            <Link
                id={props.item._id + '_link'}
                to={'/pricelistlookup/' + props.item._id}
                target="_blank"
                style={{ visibility: 'hidden' }}
            />
            <div className='Pricelistcardbuildin_actionbtnroot'>
                <button className='Pricelistcardbuildin_btnaction Pricelistcardbuildin_btnhide' onClick={() => { setExpand(false) }}>Hide</button>
                <button className='Pricelistcardbuildin_btnaction Pricelistcardbuildin_btnlookup' onClick={(e) => { document.getElementById(e.target.value).click() }} value={props.item._id + '_link'}>Lookup</button>
                <button className={`Pricelistcardbuildin_btnaction Pricelistcardbuildin_btnenquire`} onClick={enquire_click}>Enquire Pricelist</button>
            </div>

        </div>
        : <div key={'pl' + index} className='Pricelistcardbuildin_root' onClick={() => { setExpand(true) }}>
            <div className='Pricelistcardbuildin_plroot'>
                {hidden_nameDom(name)}
                {device === 'MOBILE'
                    ? <div className='Pricelistcardbuildin_btm'>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {hidden_indicatorDom(wedtype, location, '100%')}
                            {hidden_tagDom(curr_vendortype, eventorwed, '100%')}
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: '70%', padding: '16px 10px' }}>
                            <div className='Pricelistcardbuildin_hiddenrow'>
                                {hidden_destinationDom(destination, '50%')}
                                {hidden_durationDom(duration, '50%')}
                            </div>
                            {hidden_delieverablesDom(deliverables_items, '100%')}
                        </div>

                    </div>
                    : <div className='Pricelistcardbuildin_btm'>

                        <div className='Pricelistcardbuildin_indicatortagroot'>
                            {hidden_indicatorDom(wedtype, location, '100%')}
                            {hidden_tagDom(curr_vendortype, eventorwed, '100%')}
                        </div>

                        <div className='Pricelistcardbuildin_hiddenattrroot'>
                            <div className='Pricelistcardbuildin_hiddenrow'>
                                {hidden_destinationDom(destination, '50%')}
                                {hidden_durationDom(duration, '50%')}
                            </div>
                            {hidden_delieverablesDom(deliverables_items, '100%')}
                        </div>

                    </div>
                }
                {hidden_priceDom(price, price_fixedval, currency, price_rangestartval, price_rangeendval, '100%')}
            </div>
            {enquiry_portal}
        </div>

    // if (expand) {
    //     return <div className='Pricelistcardbuildin_expandedroot' >
    //         {nameDom(name, wt)}
    //         {indicatorDom(wedtype, location, wt)}
    //         {hidden_tagDom(curr_vendortype, eventorwed, wt)}
    //         {destinationDom(destination, destination_addnote, wt)}
    //         {durationDom(duration, duration_fixedval, duration_rangestartval, duration_rangeendval, duration_addnote, wt)}
    //         {delieverablesDom(deliverables_items, wt)}
    //         {additionalchargesDom(additionalcharges_items, wt)}
    //         {descriptionDom(description, wt)}
    //         {othersDom(others)}
    //         {termsandcondDom(tnc)}
    //         {priceDom(price, price_fixedval, currency, price_rangestartval, price_rangeendval, '100%')}
    //         {enquiry_portal}
    //         <Link
    //             id={props.item._id + '_link'}
    //             to={'/pricelistlookup/' + props.item._id}
    //             target="_blank"
    //             style={{ visibility: 'hidden' }}
    //         />
    //         <div className='Pricelistcardbuildin_actionbtnroot'>
    //             <button className='Pricelistcardbuildin_btnaction Pricelistcardbuildin_btnhide' onClick={() => { setExpand(false) }}>Hide</button>
    //             <button className='Pricelistcardbuildin_btnaction Pricelistcardbuildin_btnlookup' onClick={(e) => { document.getElementById(e.target.value).click() }} value={props.item._id + '_link'}>Lookup</button>
    //             <button className={`Pricelistcardbuildin_btnaction Pricelistcardbuildin_btnenquire`} onClick={enquire_click}>Enquire Pricelist</button>
    //         </div>

    //     </div>
    // }
    // else {
    //     return <div className='Pricelistcardbuildin_root' onClick={() => { setExpand(true) }}>
    //         <div className='Pricelistcardbuildin_plroot'>
    //             {hidden_nameDom(name)}
    //             {device === 'MOBILE'
    //                 ? <div className='Pricelistcardbuildin_btm'>

    //                     <div style={{ display: 'flex', flexDirection: 'column' }}>
    //                         {hidden_indicatorDom(wedtype, location, '100%')}
    //                         {hidden_tagDom(curr_vendortype, eventorwed, '100%')}
    //                     </div>

    //                     <div style={{ display: 'flex', flexDirection: 'column', width: '70%', padding: '16px 10px' }}>
    //                         <div className='Pricelistcardbuildin_hiddenrow'>
    //                             {hidden_destinationDom(destination, '50%')}
    //                             {hidden_durationDom(duration, '50%')}
    //                         </div>
    //                         {hidden_delieverablesDom(deliverables_items, '100%')}
    //                     </div>

    //                 </div>
    //                 : <div className='Pricelistcardbuildin_btm'>

    //                     <div className='Pricelistcardbuildin_indicatortagroot'>
    //                         {hidden_indicatorDom(wedtype, location, '100%')}
    //                         {hidden_tagDom(curr_vendortype, eventorwed, '100%')}
    //                     </div>

    //                     <div className='Pricelistcardbuildin_hiddenattrroot'>
    //                         <div className='Pricelistcardbuildin_hiddenrow'>
    //                             {hidden_destinationDom(destination, '50%')}
    //                             {hidden_durationDom(duration, '50%')}
    //                         </div>
    //                         {hidden_delieverablesDom(deliverables_items, '100%')}
    //                     </div>

    //                 </div>
    //             }
    //             {hidden_priceDom(price, price_fixedval, currency, price_rangestartval, price_rangeendval, '100%')}
    //         </div>
    //         {enquiry_portal}
    //     </div>
    // }
}


export default Pricelistcard