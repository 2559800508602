import React, { useEffect, useState } from 'react'
import { Router, Route, Link, Switch } from "react-router-dom";
import { Vendorlookup, UserProfile, UserLogin, About, UserSignup, UserFavourite, EventVendors, WeddingVendors, ProjectGallery, Homeuser, UserVerify, Resetpw, Faq, Beyond, UserQuote, UserBoard, UserQuestion, Blog, Bpage, Notfound, Pricelist, Pricelistlookup, EventDiscovery, Tools, Invitationlookup, Guestbook, Projectlookup, Inbox, Planning, Qredit, Invitationedit, Seatchartedit, Guestbookedit, Timelinelookup, Market, Marketlookup, Jmap, Sclookup, Guestbookcast, Guestbookmod, Gblookup, Qrlookup, Itinerarylookup, Comparevenue } from './pages' //Bulletsend, Bulletmonitor, 
import { NAVBAR_ICON, EMPTY, SS_LINE, production, STARTUP_NAME, transactionandquote, marketbool } from './config/constants'
import { Loading, refresh, Checkdevice, Portalmodal } from './components';
import { USER_VALIDATE } from './config/api';
import axios from 'axios'

// START OF GA
// import ReactGA from 'react-ga';
import ReactGA from "react-ga4";
import { createBrowserHistory } from 'history'
import { iconwrapper, Retrieve_personal_info } from './config/commonfunctions';

// CSS
import './App.css'
import Itineraryedit from './pages/Main/Planning/Itinerarymaker/Itineraryedit/Itineraryedit';
import Checklistedit from './pages/Main/Planning/Checklistmaker/Checklistedit/Checklistedit';
import Budgetedit from './pages/Main/Planning/Budgetmaker/Budgetedit/Budgetedit';
import { WEAVEBLISS_WHITE } from './config/svgicons';
import { Helmet } from 'react-helmet';

const LOADING = 0
const LOGGED = 1
const LOGOUT = 2

const TOPLEFTNAV_DIV = <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center' }}  >
    <div style={{ minHeight: 33, minWidth: 33, padding: '1px 0px', position: 'relative', margin: 0, display: 'flex', placeContent: 'center', alignItems: 'center', padding: '0px 0px 0px 3px' }}>
        <Link style={{ width: 27, height: 27, position: 'absolute' }} to="/" />
        {/* {production ? iconwrapper(WEAVEBLISS_WHITE, { width: 27, height: 27, color: '#ffffff' }) : null} */}
        {iconwrapper(WEAVEBLISS_WHITE, { width: 27, height: 27, color: '#ffffff' })}
    </div>
    <Link className="navbar_weavetxtroot" to="/" >
        {/* {production ? <div className="headertitlelogorealtextmobile" >{STARTUP_NAME}</div> : null} */}
        <div className="headertitlelogorealtextmobile" >{STARTUP_NAME}</div>
    </Link>
</div>


const history = createBrowserHistory()
production ? ReactGA.initialize('UA-150353866-1') : ReactGA.initialize('1');
// history.listen((location, action) => { ReactGA.pageview(location.pathname + location.search) });
ReactGA.send({
    hitType: "pageview",
    page: location.pathname + location.search,
    title: "Custom Title"
});

const ValidateAcct = () => {
    try {
        var rawToken = localStorage.getItem('token')
        const currToken = rawToken.substring(4, rawToken.length)        // remove 'JWT '
        var base64Urlstr = currToken.split('.')[1];                        // parse jwt preprocess
        var base64str = base64Urlstr.replace('-', '+').replace('_', '/');     // parse jwt preprocess
        const jsonjwt = JSON.parse(window.atob(base64str))
        const { id, acct_type } = jsonjwt
        return axios({
            method: 'POST',
            url: USER_VALIDATE,
            headers: { 'Authorization': 'Bearer ' + currToken },
            data: { id }
        }).then((response) => {
            const res = response.data
            if (!res.error) {
                if (acct_type === 'USER') {
                    const { usertype } = res.user
                    const { fev } = res
                    return { id, acct_type, usertype, fev }
                }
                else {
                    return null
                }
            }
            else {
                return null
            }

        }).catch((e) => {
            alert(e)
            return null
        })
    }
    catch (e) { return null }
}

function App() {

    const [auth, setAuth] = useState(LOADING) // 0 - LOADING, 1 - LOGGED, 2 - LOGOUT
    const [token, setToken] = useState('')
    const [id, setId] = useState('')
    const [usertype, setUsertype] = useState('') // Wedding | Event | Guest
    const [menugate, setMenugate] = useState(false) // menu modal gate for mobile
    const [hidetop, setHidetop] = useState(null)
    const [partdoneloading, setPartdoneloading] = useState(false)
    const device = Checkdevice()

    useEffect(() => {
        window.addEventListener("storage", (s) => { if (s.key === 'token') { refresh() } }, false)
        setToken(localStorage.getItem('token'))

        setTimeout(() => {
            try {
                var local_token
                if (token) {
                    local_token = token
                    localStorage.setItem('token', token)
                }
                else {
                    local_token = localStorage.getItem('token')
                    setToken(local_token)
                }

                const { id, acct_type, usertype } = ValidateAcct(local_token)



                if (acct_type === 'USER') { // this is for user
                    localStorage.setItem("token", local_token)
                    setId(id)
                    setUsertype(usertype)
                    setAuth(LOGGED)
                }
                else {
                    const { _id, token, acct_type } = Retrieve_personal_info()
                    try {
                        return axios({
                            method: 'POST',
                            url: USER_VALIDATE,
                            headers: { 'Authorization': 'Bearer ' + token },
                            data: { id: _id }
                        }).then((response) => {
                            const res = response.data
                            if (!res.error && acct_type === 'USER') {
                                const { fev } = res
                                const { usertype } = res.user
                                localStorage.setItem("token", local_token)
                                setId(_id)
                                setAuth(LOGGED)
                                setUsertype(usertype)

                                const cachedfrontendversion = localStorage.getItem('fev') ? localStorage.getItem('fev') : ''
                                if (fev !== cachedfrontendversion) {
                                    localStorage.setItem('fev', fev)
                                    window.location.reload(true)
                                    // window.location.href = window.location.href;
                                }

                            }
                            else { logout(1) }
                        }).catch((e) => {
                            logout(2)
                        })
                    }
                    catch (e) { logout(3) }
                }
            }
            catch (e) { logout(4) }
        }, 1000)

        const params = new URLSearchParams(window.location.search);
        const source = params.get('s');
        const medium = params.get('m');
        const campaign = params.get('c');

        if (source || medium || campaign) {
            // instagram: http://weavebliss.com/?s=i&m=s&c=smc
            // thread: http://weavebliss.com/?s=th&m=s&c=smc
            // facebook: http://weavebliss.com/?s=f&m=s&c=smc
            // tiktok: http://weavebliss.com/?s=tt&m=s&c=smc
            // youtube: http://weavebliss.com/?s=y&m=s&c=smc
            // reddit: http://weavebliss.com/?s=r&m=s&c=smc
            // pinterest: http://weavebliss.com/?s=p&m=s&c=smc
            // linkedin: http://weavebliss.com/?s=l&m=s&c=smc
            try {
                ReactGA.send({
                    hitType: 'pageview',
                    page: window.location.pathname + window.location.search,
                    source: { 'i': 'instagram', 'th': 'thread', 'f': 'facebook', 'tt': 'tiktok', 'y': 'youtube' , 'p': 'pinterest','l':'linkedin' }[source],
                    medium: { 's': 'social' }[medium],
                    campaign: { 'smc': 'social_media_campaign' }[campaign],
                });
            }
            catch (e) {

            }


        }

    }, [])


    const logout = (item) => {
        window.removeEventListener("storage", () => { refresh() }, false)
        setMenugate(false)
        setAuth(LOGOUT)
        setToken('')
        setId('')
        localStorage.token = EMPTY
    }

    const hidetopbar = (g) => { setHidetop(g) }

    const offdialog = (e) => {
        e.stopPropagation();
        setMenugate((prevState) => { return !prevState })
    }

    const NavbarHeader = () => {

        const loggedmenuG_mobile = { // guest
            'Home': { link: '/' },
            'Profile': { link: '/profile' },
            'FAQ': { link: '/faq', style: { borderRight: '1px solid white' } },
            'Blog': { link: '/blog' },
            'Logout': { link: '/', func: logout },
        }

        const loggedmenuWE_mobile = !transactionandquote
            ? { // wedding and event
                'Home': { link: '/' },
                'Favourite': { link: '/favourite/vendor' },
                'Board': { link: '/board' },
                'Profile': { link: '/profile' },
                'Inbox': { link: '/chat' },
                'FAQ': { link: '/faq', style: { borderRight: '1px solid white' } },
                // 'Blog': { link: '/blog' },
                'Logout': { link: '/', func: logout },
            }
            : { // wedding and event
                'Home': { link: '/' },
                'Favourite': { link: '/favourite/vendor' },
                'Board': { link: '/board' },
                'Profile': { link: '/profile' },
                'Transaction': { link: '/transaction' },
                'Inbox': { link: '/chat' },
                'FAQ': { link: '/faq', style: { borderRight: '1px solid white' } },
                // 'Blog': { link: '/blog' },
                'Logout': { link: '/', func: logout },
            }

        const loggedmenuG = { // guest
            // 'Blog': { link: '/blog' },
            'FAQ': { link: '/faq', style: { borderRight: '1px solid white' } },
            'Home': { link: '/' },
            'Profile': { link: '/profile' },
            'Logout': { link: '/', func: logout },
        }

        const loggedmenuWE = !transactionandquote
            ? { // wedding and event and without transaction
                // 'Blog': { link: '/blog' },
                'Tools': { link: '/tools' },
                'FAQ': { link: '/faq', style: { borderRight: '1px solid white' } },
                'Home': { link: '/' },
                'Inbox': { link: '/chat' },
                'Favourite': { link: '/favourite/vendor' },
                'Board': { link: '/board' },
                'Profile': { link: '/profile' },
                'Logout': { link: '/', func: logout },
            }
            : { // wedding and event and  with transaction
                // 'Blog': { link: '/blog' },
                'Tools': { link: '/tools' },
                'FAQ': { link: '/faq', style: { borderRight: '1px solid white' } },
                'Home': { link: '/' },
                'Transaction': { link: '/transaction' },
                'Inbox': { link: '/chat' },
                'Favourite': { link: '/favourite/vendor' },
                'Board': { link: '/board' },
                'Profile': { link: '/profile' },
                'Logout': { link: '/', func: logout },
            }

        const unloggedmenu = marketbool
            ? {
                'Tools': { link: '/tools' },
                'Market': { link: '/market' },
                // 'Blog': { link: '/blog' },  
                'FAQ': { link: '/faq', style: { borderRight: '1px solid white' } },
                // 'About' : { link: '/about' }, 
                'Sign Up': { link: '/signup' },
                'Login': { link: '/' },
            }
            : {
                'Tools': { link: '/tools' },
                // 'Market': { link: '/market' },
                // 'Blog': { link: '/blog' },  
                'FAQ': { link: '/faq', style: { borderRight: '1px solid white' } },
                // 'About' : { link: '/about' }, 
                'Sign Up': { link: '/signup' },
                'Login': { link: '/' },
            }

        if (device === 'WEB') { // web
            return <div className='app_navroot'>
                {TOPLEFTNAV_DIV}
                <div className='app_navright'>
                    {auth === LOGGED
                        ? usertype === 'Guest'
                            ? Object.keys(loggedmenuG).map((key, index) => {
                                const { link, func, style } = loggedmenuG[key]
                                return <button className='navtopbaritem' key={key + index} style={{ ...style }} onClick={() => document.getElementById(key).click()}>
                                    <Link id={`${key}`} to={link} style={{ visibility: 'hidden' }} onClick={func} />
                                    {key}
                                </button>
                            })
                            : (usertype === 'Event' || usertype === 'Wedding')
                                ? Object.keys(loggedmenuWE).map((key, index) => {
                                    const { link, func, style } = loggedmenuWE[key]
                                    return <button className='navtopbaritem' key={key + index} style={{ ...style }} onClick={() => document.getElementById(key).click()}>
                                        <Link id={`${key}`} to={link} style={{ visibility: 'hidden' }} onClick={func} />
                                        {key}
                                    </button>
                                })
                                : null
                        : Object.keys(unloggedmenu).map((key, index) => {
                            const { link, func, style } = unloggedmenu[key]
                            return <button className='navtopbaritem' key={key + index} style={{ ...style }} onClick={() => document.getElementById(key).click()}>
                                <Link id={`${key}`} to={link} style={{ visibility: 'hidden' }} onClick={func} />
                                {key}
                            </button>
                        })}
                </div>
            </div>
        }
        else { // mobile
            // const login_m_about_unauth = <Link key={'login_m_about_unauth'} to="/about" onClick={offdialog} style={{ width: '100%', letterSpacing: 1, color: GENERAL_GREY, display: 'flex', justifyContent: 'flex-start', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', lineHeight: '1.16667em', padding: '15px 0px 15px 10px' }}><div style={{ justifyItems: 'flex-start' }}>About</div></Link>
            const SidelistIcon = <button className="app_navbaricon" onClick={() => setMenugate(true)} >{NAVBAR_ICON}</button>

            if (auth === LOGGED) {
                return <div className='app_navroot'>
                    {TOPLEFTNAV_DIV}
                    {SidelistIcon}
                    <Portalmodal
                        portaltype={'commonportal'}
                        open={menugate}
                        onClose={() => setMenugate(false)}
                        floatingclose={false}
                        backdropvisible={true}
                        modalstyle={{}}
                        closestyle={{ right: 0 }}
                        floatingclosecolor='var(--maindark)'
                    >
                        <div style={{ marginLeft: 10, height: 60, minHeight: 60 }} />
                        <div className='App_linksroot'>
                            {usertype === 'Guest'
                                ? Object.keys(loggedmenuG_mobile).map((key, index) => {
                                    const { link } = loggedmenuG_mobile[key]
                                    return [<Link className="AppNavItemM" key={key + index} to={link} onClick={offdialog}>{key}</Link>, SS_LINE]
                                })
                                : (usertype === 'Event' || usertype === 'Wedding')
                                    ? Object.keys(loggedmenuWE_mobile).map((key, index) => {
                                        const { link, func } = loggedmenuWE_mobile[key]
                                        if (key === 'Logout') {
                                            return [SS_LINE, <Link className="AppNavItemM" key={key + index} to={link} onClick={key === 'Logout' ? func : offdialog}>{key}</Link>]
                                        }
                                        return [<Link className="AppNavItemM" key={key + index} to={link} onClick={key === 'Logout' ? func : offdialog}>{key}</Link>]
                                    })
                                    : null}
                        </div>
                    </Portalmodal>
                </div>
            }
            else {
                return <div className='app_navroot'>
                    {TOPLEFTNAV_DIV}
                    {SidelistIcon}
                    <Portalmodal
                        portaltype={'commonportal'}
                        open={menugate}
                        onClose={() => setMenugate(false)}
                        floatingclose={false}
                        backdropvisible={true}
                        modalstyle={{}}
                        closestyle={{ right: 0 }}
                        floatingclosecolor='var(--maindark)'
                    >
                        <div style={{ marginLeft: 10, height: 60, minHeight: 60 }} />
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', overflowY: 'auto' }}>
                            {Object.keys(unloggedmenu).map((key, index) => {
                                const { link } = unloggedmenu[key]
                                if (key === 'Sign Up') {
                                    return [SS_LINE, <Link className="AppNavItemM" key={key + index} to={link} onClick={key === 'Logout' ? func : offdialog}>{key}</Link>]
                                }
                                else {
                                    return [<Link className="AppNavItemM" key={key + index} to={link} onClick={offdialog}>{key}</Link>]
                                }
                            })}
                        </div>
                    </Portalmodal>
                </div>
            }
        }
    }

    // START OF ROUTING DOM --------------------------------------------------
    const Home_dom = (props) => {
        if (auth === LOGGED) {
            return <Homeuser {...props} changeOfUsertype={(ut) => { if (ut !== usertype) { setUsertype(ut) } }} />
        }
        else if (auth === LOGOUT) {
            return <UserLogin {...props} />
        }
    }

    const Quote_dom = (props) => {
        return auth === LOGGED && usertype !== 'Guest' ? <UserQuote {...props} /> : <Notfound />
    }

    const Chat_dom = (props) => {
        return auth === LOGGED && usertype !== 'Guest' ? <Inbox  {...props} /> : <Notfound />
    }

    const Userquestion_dom = (props) => {
        return auth === LOGGED && usertype !== 'Guest' ? <UserQuestion  {...props} app_usertype={usertype} hidetopbar={hidetopbar} /> : <Notfound />
    }

    const Eventdiscovery_dom = (props) => {
        return auth === LOGGED && usertype !== 'Guest' && usertype !== 'Wedding' ? <EventDiscovery  {...props} /> : <Notfound />
    }

    const Tool_dom = (props) => {
        if (usertype === 'Event' || usertype === 'Guest') {
            return <Notfound />
        }
        return <Tools {...props} usertype={usertype} wedorevent={usertype} authuser={auth === LOGGED && !(usertype === 'Event' || usertype === 'Guest')} />
    }

    const Market_dom = (props) => {
        return <Market {...props} usertype={usertype} wedorevent={usertype} authuser={auth === LOGGED && usertype !== 'Guest'} />
    }

    // const Invitationmaker_dom = (props) => {
    //     return auth === LOGGED && (usertype === 'Wedding' || usertype === 'Event' || usertype === 'Guest') ? <Planning  {...props} name={'Invitation'} /> : null
    // }

    // const Budgetmaker_dom = (props) => {
    //     return auth === LOGGED && (usertype === 'Wedding' || usertype === 'Event' || usertype === 'Guest') ? <Planning {...props} name={'Budget'} /> : null
    // }

    // const Itinerary_dom = (props) => {
    //     return auth === LOGGED && (usertype === 'Wedding' || usertype === 'Event' || usertype === 'Guest') ? <Planning {...props} name={'Itinerary'} /> : null
    // }

    // const Checklist_dom = (props) => {
    //     return auth === LOGGED && (usertype === 'Wedding' || usertype === 'Event' || usertype === 'Guest') ? <Planning {...props} name={'Checklist'} /> : null
    // }

    // const Seatingchart_dom = (props) => {
    //     return auth === LOGGED && (usertype === 'Wedding' || usertype === 'Event' || usertype === 'Guest') ? <Planning {...props} name={'Seatingchart'} hidetopbar={hidetopbar} /> : null
    // }

    // const Qr_dom = (props) => {
    //     return auth === LOGGED && (usertype === 'Wedding' || usertype === 'Event' || usertype === 'Guest') ? <Planning {...props} name={'Qr'} hidetopbar={hidetopbar} /> : null
    // }

    // const Guesttbookbuilder_dom = (props) => {
    //     return auth === LOGGED && (usertype === 'Wedding' || usertype === 'Event' || usertype === 'Guest') ? <Planning {...props} name={'Guestbook'} hidetopbar={hidetopbar} /> : null
    // }

    const Planning_dom = (props) => {
        return auth === LOGGED && (usertype === 'Wedding' || usertype === 'Event' || usertype === 'Guest') ? <Planning {...props} name={'Planning'} /> : null
    }

    const Itineraryedit_dom = (props) => {
        return auth === LOGGED && usertype !== 'Guest' && (usertype === 'Wedding' || usertype === 'Event') ? <Itineraryedit {...props} hidetopbar={hidetopbar} /> : null
    }

    const Invitationedit_dom = (props) => {
        return auth === LOGGED && usertype !== 'Guest' && (usertype === 'Wedding' || usertype === 'Event') ? <Invitationedit {...props} hidetopbar={hidetopbar} /> : null
    }

    const Qredit_dom = (props) => {
        return auth === LOGGED && usertype !== 'Guest' && (usertype === 'Wedding' || usertype === 'Event') ? <Qredit {...props} hidetopbar={hidetopbar} /> : null
    }

    const Qrlookup_dom = (props) => {
        return auth === LOGGED && usertype !== 'Guest' && (usertype === 'Wedding' || usertype === 'Event') ? <Qrlookup {...props} hidetopbar={hidetopbar} from='APP' /> : null
    }

    const Seatchartedit_dom = (props) => {
        return auth === LOGGED && usertype !== 'Guest' && (usertype === 'Wedding' || usertype === 'Event') ? <Seatchartedit  {...props} hidetopbar={hidetopbar} /> : null
    }

    const Guestbookedit_dom = (props) => {
        return auth === LOGGED && (usertype === 'Wedding' || usertype === 'Event' || usertype === 'Guest') ? <Guestbookedit {...props} hidetopbar={hidetopbar} /> : null
    }
    const Checklistedit_dom = (props) => {
        return auth === LOGGED && (usertype === 'Wedding' || usertype === 'Event' || usertype === 'Guest') ? <Checklistedit {...props} hidetopbar={hidetopbar} /> : null
    }

    const Budgetedit_dom = (props) => {
        return auth === LOGGED && (usertype === 'Wedding' || usertype === 'Event' || usertype === 'Guest') ? <Budgetedit {...props} hidetopbar={hidetopbar} /> : null
    }

    const Guestbookcast_dom = (props) => {
        return <Guestbookcast {...props} hidetopbar={hidetopbar} />
    }

    const Guestbookmod_dom = (props) => {
        return <Guestbookmod {...props} hidetopbar={hidetopbar} />
    }

    const Guestbookguest_dom = (props) => {
        return <Guestbook {...props} hidetopbar={hidetopbar} />
    }

    const Gblookup_dom = (props) => {
        return <Gblookup {...props} hidetopbar={hidetopbar} />
    }

    const Invitationlookup_dom = (props) => {
        return <Invitationlookup {...props} hidetopbar={hidetopbar} />
    }

    const Timelinelookup_dom = (props) => {
        return <Itinerarylookup {...props} hidetopbar={hidetopbar} from='APP' />
    }

    const Seatchartlookup_dom = (props) => {
        return <Sclookup {...props} hidetopbar={hidetopbar} />
    }

    const Pricelist_dom = (props) => {
        return auth === LOGGED && usertype !== 'Guest' ? <Pricelist {...props} usertype={usertype} /> : <Notfound />
    }

    const Projectgallery_dom = (props) => {
        return auth === LOGGED && usertype !== 'Guest' ? <ProjectGallery {...props} usertype={usertype} token={token} id={id} /> : <Notfound />
    }

    const Weddingvendors_dom = (props) => {
        return auth === LOGGED && usertype !== 'Guest' ? <WeddingVendors {...props} /> : <Notfound />
    }

    const Eventvendors_dom = (props) => {
        return auth === LOGGED && usertype !== 'Guest' ? <EventVendors {...props} /> : <Notfound />
    }

    const Userboard_dom = (props) => {
        return auth === LOGGED && usertype !== 'Guest' ? <UserBoard {...props} /> : <Notfound />
    }

    const Favouritevendor_dom = () => {
        return auth === LOGGED && usertype !== 'Guest' ? <UserFavourite /> : <Notfound />
    }

    const Userprofile_dom = () => {

        return auth === LOGGED ? <UserProfile /> : <Notfound />
    }

    const Resetupw_dom = (props) => {
        return auth === LOGOUT ? <Resetpw  {...props} ut={'u'} hidetopbar={hidetopbar} /> : null
    }

    const Usersignup_dom = (props) => {
        return auth === LOGOUT ? <UserSignup {...props} hidetopbar={hidetopbar} /> : null
    }

    const Vendorlookup_dom = (props) => {
        return <Vendorlookup {...props} />
    }

    const Faq_dom = (props) => {
        return <Faq {...props} accttype={'USER'} />
    }

    const Pricelistlookup_dom = (props) => {
        return <Pricelistlookup {...props} hidetopbar={hidetopbar} />
    }

    const Marketlookup_dom = (props) => {
        return <Marketlookup {...props} />
    }

    const Map_dom = (props) => {
        return <Jmap {...props} hidetopbar={hidetopbar} />
    }

    const Comparevenue_dom = (props) => {
        return <Comparevenue {...props} hidetopbar={hidetopbar} />
    }

    const reacthelmet = production ? <Helmet><title>{`${STARTUP_NAME} - Wedding & Event Planning`}</title><meta name="description" content="User Login" /></Helmet> : null

    return <Router history={history} >
        <div id="app-root">
            {reacthelmet}
            {(auth === LOADING || hidetop) ? null : NavbarHeader()}
            {auth === LOADING
                ? <Loading from='main-app' />
                : <Switch>
                    <Route exact path="/" render={Home_dom} />
                    <Route exact path="/wedding" render={Home_dom} />
                    <Route exact path="/event" render={Home_dom} />
                    <Route exact path="/transaction" render={Quote_dom} />
                    <Route exact path="/chat" render={Chat_dom} />
                    <Route exact path="/profile" render={Userprofile_dom} />
                    <Route exact path="/favourite/vendor" render={Favouritevendor_dom} />
                    <Route exact path="/board" render={Userboard_dom} />
                    <Route exact path="/event/:vt" render={Eventvendors_dom} />
                    <Route exact path="/wedding/:vt" render={Weddingvendors_dom} />
                    <Route exact path="/projectgallery" render={Projectgallery_dom} />
                    <Route exact path="/pricelist" render={Pricelist_dom} />
                    <Route exact path="/promo" render={Pricelist_dom} />
                    <Route exact path="/userquestion" render={Userquestion_dom} />
                    <Route exact path='/eventdiscovery' render={Eventdiscovery_dom} />
                    <Route exact path='/market' render={Market_dom} />


                    <Route exact path='/tools' render={Tool_dom} />
                    <Route exact path='/tools/:app' render={Tool_dom} />
                    <Route exact path='/tools/itinerarybuilder/:id/edit' render={Itineraryedit_dom} />
                    <Route exact path='/tools/invitationbuilder/:id/edit' render={Invitationedit_dom} />
                    <Route exact path='/tools/invitationbuilder/:id/edit' render={Invitationedit_dom} />
                    <Route exact path='/tools/invitationbuilder/:id/edit/:page' render={Invitationedit_dom} />
                    <Route exact path='/tools/qrbuilder/:id/edit' render={Qredit_dom} />
                    {/* <Route exact path='/tools/budgetbuilder' render={Budgetmaker_dom} /> */}
                    {/* <Route exact path='/tools/checklistbuilder' render={Checklist_dom} /> */}
                    {/* <Route exact path='/tools/seatingchartbuilder' render={Seatingchart_dom} /> */}
                    {/* <Route exact path='/tools/guestbookbuilder' render={Guesttbookbuilder_dom} /> */}
                    <Route exact path='/tools/seatingchartbuilder/:id/edit' render={Seatchartedit_dom} />
                    <Route exact path='/tools/guestbookbuilder/:id/edit' render={Guestbookedit_dom} />
                    <Route exact path='/tools/checklistbulider/:id/edit' render={Checklistedit_dom} />
                    <Route exact path='/tools/budgetbuilder/:id/edit' render={Budgetedit_dom} />


                    <Route exact path='/guestbook/:urlhandler/:passcode' render={Guestbookguest_dom} /> {/* for guest to key in msg and imgs */}
                    <Route exact path='/guestbookcast/:castno/:urlhandler/:passcode/:gbid' render={Guestbookcast_dom} />
                    <Route exact path='/guestbookmod/:urlhandler/:passcodemod' render={Guestbookmod_dom} />
                    <Route exact path='/guestbooklookup/:urlhandler/:passcodemod' render={Gblookup_dom} />
                    <Route exact path='/guestbooklookup/:urlhandler' render={Gblookup_dom} />

                    {/*
                    <Route exact path='/tools/qrbuilder' render={Qr_dom} />
                    <Route exact path='/qr/:bannerdesign/:apptype/:url' render={Qr_dom} />
                    <Route exact path='/qr/:bannerdesign/:apptype/:url/:passcode' render={Qr_dom} /> 
                    */}

                    {/* UNAUTH only */}
                    <Route exact path="/signup" render={Usersignup_dom} />
                    {/* ALL */}
                    <Route path="/vendorlookup/:vendorname" render={Vendorlookup_dom} />
                    <Route path="/blog/" exact component={Blog} />
                    <Route path="/blog/:title" exact component={Bpage} />
                    <Route path="/beyond" exact component={Beyond} />
                    <Route path="/beyond/:title" exact component={Bpage} />
                    <Route path="/projectlookup/:project_id" exact component={Projectlookup} />
                    <Route path="/pricelistlookup/:pricelist_id" render={Pricelistlookup_dom} />
                    <Route path="/marketlookup/:marketlisting_id" render={Marketlookup_dom} />
                    <Route path="/about" exact component={About} />
                    <Route path="/user/verify/:email/:verifyingtoken" exact component={UserVerify} />
                    <Route path="/resetpassword/:resettoken" exact component={Resetupw_dom} />
                    <Route path="/faq" exact>{Faq_dom}</Route>
                    {/* Planning */}
                    {/* <Route exact path='/planning' render={Planning_dom} /> */}

                    {/* Invitation */}
                    <Route path="/invitation/:invitationlookup" exact>{Invitationlookup_dom}</Route>
                    <Route path="/invitation/:invitationlookup/guestuid=:guestuid" exact>{Invitationlookup_dom}</Route>
                    <Route path="/invitation/:invitationlookup/passcode=:passcode" exact>{Invitationlookup_dom}</Route>
                    <Route path="/invitation/:invitationlookup/passcode=:passcode/guestuid=:guestuid" exact>{Invitationlookup_dom}</Route>
                    <Route path="/invitation/:invitationlookup/guestuid=:guestuid/passcode=:passcode" exact>{Invitationlookup_dom}</Route>
                    <Route path="/invitation/:invitationlookup/gallery=:gallery" exact>{Invitationlookup_dom}</Route>
                    <Route path="/invitation/:invitationlookup/guestuid=:guestuid/gallery=:gallery" exact>{Invitationlookup_dom}</Route>
                    <Route path="/invitation/:invitationlookup/passcode=:passcode/gallery=:gallery" exact>{Invitationlookup_dom}</Route>
                    <Route path="/invitation/:invitationlookup/passcode=:passcode/guestuid=:guestuid/gallery=:gallery" exact>{Invitationlookup_dom}</Route>
                    <Route path="/invitation/:invitationlookup/guestuid=:guestuid/passcode=:passcode/gallery=:gallery" exact>{Invitationlookup_dom}</Route>
                    {/* Timeline */}
                    <Route path="/timelinelookup/:urlhandler" exact>{Timelinelookup_dom}</Route>
                    <Route path="/timelinelookup/:urlhandler/passcode=:passcode" exact>{Timelinelookup_dom}</Route>
                    {/* Seatchartlookup */}
                    <Route path='/seatingchartlookup/:urlhandler' exact>{Seatchartlookup_dom}</Route>
                    <Route path='/seatingchartlookup/:urlhandler/passcode=:passcode' exact>{Seatchartlookup_dom}</Route>
                    <Route path='/seatingchartlookup/:urlhandler/:passcode' exact>{Seatchartlookup_dom}</Route>

                    {/* Qr */}
                    <Route path='/qrlookup/:urlhandler/passcode=:passcode' render={Qrlookup_dom} />
                    <Route path='/qrlookup/:urlhandler/:passcode' exact>{Qrlookup_dom}</Route>

                    {/* Visualise Venue, state will not reset if its use this way  */}
                    <Route path="/compare/weddingvenue" exact >{Comparevenue_dom}</Route>

                    {/* <Route path="/compare/weddingvenue" exact component={Comparevenue_dom} />
                    resize in this component will lead to state being reset */}

                    {/* Map */}
                    <Route path="/map" render={Map_dom} />
                    {/* Not found */}
                    {usertype !== null ? <Route path="*" component={Notfound} /> : null}
                    {auth === LOGOUT ? <Route path="*" component={Notfound} /> : null}
                </Switch>}
        </div>
    </Router>
}

export default App