// guestbook common functions

import twemoji from 'twemoji'

function gb_isValidHttpUrl(string) {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

function gb_sortitems(tfidf) {
    // do sort for tfidf
    var keys = Object.keys(tfidf)
    var sorted = []
    for (let x = 0; x < keys.length; x++) {
        var curr_key = keys[x]
        var curr_weight = tfidf[curr_key]
        var heaviest = curr_weight
        var heaviest_key = curr_key
        var heaviest_index = x
        for (let y = 0; y < keys.length; y++) {
            if (x !== y) {
                var target_key = keys[y]
                var target_weight = tfidf[target_key]
                if (heaviest > target_weight) {
                    heaviest = tfidf[target_key]
                    heaviest_key = target_key
                    heaviest_index = y
                }
            }
        }
        if (!sorted.includes(heaviest_key)) {
            sorted.push(heaviest_key)
            keys.splice(heaviest_index, 1)
            x = 0
        }
    }
    sorted = [...sorted, ...keys].reverse()
    return sorted
}

export const gb_dissectmsgs = (msgs, emojitype) => {
    // raw
    var pages = []
    var pagesinfo = []

    // tfidf
    var tf = {}
    var idf = {}

    // combined of tf and idf
    var tfidf = {}

    // emoji_msgobj_map
    var emoji_msgobj_map = {}

    // extract all emoji
    var msgtxt = []
    for (let i = 0; i < msgs.length; i++) {
        const { txt, name, emojiascii } = msgs[i]
        if (emojiascii) {
            var newval = twemoji.parse(emojiascii, { ext: '.png',base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/'  })
            var newarr = newval.split('"/>')
            var singleline = []
            for (let x = 0; x < newarr.length; x++) {
                var singlelineemojis = newarr[x].split('src="')
                for (let y = 0; y < singlelineemojis.length; y++) {
                    if (gb_isValidHttpUrl(singlelineemojis[y])) {
                        var term = singlelineemojis[y]
                        tf[term] = tf[term] > 0 ? tf[term] + 1 : 1
                        singleline.push(term)

                        if (!(term in emoji_msgobj_map)) {
                            emoji_msgobj_map[term] = []
                        }
                        if (!emoji_msgobj_map[term].includes({ txt, name, emojiascii })) {
                            emoji_msgobj_map[term].push({ txt, name, emojiascii })
                        }

                    }
                }
            }
            pages.push(singleline)
        }
        if (txt) {
            msgtxt.push(txt)
            pagesinfo.push({ name, txt })
        }
    }

    var allterms = Object.keys(tf)
    for (let x = 0; x < allterms.length; x++) {
        var t1 = allterms[x]
        var countterm = 0
        for (let y = 0; y < pages.length; y++) {
            // does this page contain current term ? 
            if (pages[y].includes(t1)) {
                countterm = countterm + 1
            }
        }
        idf[t1] = parseInt(pages.length, 10) / countterm
    }

    for (let i = 0; i < allterms.length; i++) {
        var t2 = allterms[i]
        tfidf[t2] = Math.log10(tf[t2] * idf[t2], 10) - 1 // -1 for resizing purpose
    }

    var sorted = gb_sortitems(tfidf)

    return { tf, tfidf, sorted, msgtxt, emoji_msgobj_map, pagesinfo }

}

export const gb_extractmsgtfidf = (msgs) => {
    // raw
    var pages = []
    var pagesinfo = []

    // tfidf
    var tf = {}
    var idf = {}

    // combined of tf and idf
    var tfidf = {}

    for (let i = 0; i < msgs.length; i++) {
        const { txt, name } = msgs[i]
        let newtxtarr = txt.split(' ')
        let singleline = []
        for (let x = 0; x < newtxtarr.length; x++) {
            var term = newtxtarr[x]
            tf[term] = tf[term] > 0 ? tf[term] + 1 : 1
            singleline.push(term)
        }
        pages.push(singleline)
        pagesinfo.push({ name, txt })
    }

    var allterms = Object.keys(tf)
    for (let x = 0; x < allterms.length; x++) {
        var t1 = allterms[x]
        var countterm = 0
        for (let y = 0; y < pages.length; y++) {
            if (pages[y].includes(t1)) {
                countterm = countterm + 1
            }
        }
        idf[t1] = parseInt(pages.length, 10) / countterm
    }

    for (let i = 0; i < allterms.length; i++) {
        var t2 = allterms[i]
        tfidf[t2] = Math.log10(tf[t2] * idf[t2], 10) - 1 // -1 for resizing purpose
        // if(tfidf[t2]<0){
        //     tfidf[t2] = tfidf[t2]*-1
        // }
    }

    var sorted = gb_sortitems(tfidf)

    return {
        tf: tf,
        tfidf: tfidf,
        sorted: sorted,
        pages: pages,
        pagesinfo: pagesinfo
    }
}