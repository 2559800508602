import React, { useEffect, useState } from 'react'
import { Checkdevice, Appcard, } from '../../../components'
import { auspiciousbool, guestbookbool, PLOBJ } from '../../../config/constants'
import Budgetmaker from './Budgetmaker/Budgetmaker'
import Invitationmaker from './Invitationmaker/Invitationmaker'
import Itinerarymaker from './Itinerarymaker/Itinerarymaker'
import Checklist from './Checklistmaker/Checklistmaker'
import Seatingchart from './Seatingchart/Seatingchart'
import Guestbookbuilder from './Guestbookmaker/Guestbookbuilder'
import Qrmaker from './Qrmaker/Qrmaker'


const APP_DETAILS = guestbookbool
    ? {
        'Checklist': {
            fullname: 'Checklist',
            txt: 'Our checklist app helps you plan your big day with ease. With a comprehensive list of tasks and timelines, you can stay organized and on track throughout the planning process.',
        },
        'Budget': {
            fullname: 'Budget' + `\n` + 'Tracker',
            txt: 'Our budget tracker app helps you stay on top of your finances with ease. Set a budget, track expenses, and monitor your progress all in one place. With customizable categories you can reach your financial goals and manage your money like a pro.',
        },
        'Itinerary': {
            fullname: `Timeline` + '\n' + `Itinerary`,
            txt: `Create a personalized wedding day timeline for you and your guests with Timeline Itinerary. Add all events, share with ease, and customize it to suit your needs, ensuring a stress-free wedding day.`,
        },
        'Invitation': {
            fullname: `Site Builder,` + '\n' + `Guest List`,
            txt: `Organize your dream wedding with our essential Wedding Site Builder and Guest List app. Create a stunning website to share wedding details and manage RSVPs, meal preferences, and guest details with ease, ensuring a stress-free planning process.`,
        },
        'Seatingchart': {
            fullname: `Seating` + '\n' + `Chart`,
            txt: 'Our Seating Chart app is an essential tool for organizing your wedding reception, both online and offline. Create and manage a personalized seating chart with ease, ensuring a smooth and enjoyable reception for everyone.',
        },
        'Qr': {
            fullname: 'QR',
            txt: 'With our QR Creator, you can easily create and customize QR codes for your wedding. Share important information with guests, such as your wedding website, seating chart, and timeline itinerary, with just a scan.',
        },
        'Guestbook': {
            fullname: 'Guest' + `\n` + 'Book',
            txt: 'Our Digital Guestbook allows guests to leave messages, photos, and audio, creating an interactive keepsake that you can cherish forever. Capture memories of your special day with ease.',
        },
    }
    : {
        'Checklist': {
            fullname: 'Checklist',
            txt: 'Our checklist app helps you plan your big day with ease. With a comprehensive list of tasks and timelines, you can stay organized and on track throughout the planning process.',
        },
        'Budget': {
            fullname: 'Budget' + `\n` + 'Tracker',
            txt: 'Our budget tracker app helps you stay on top of your finances with ease. Set a budget, track expenses, and monitor your progress all in one place. With customizable categories you can reach your financial goals and manage your money like a pro.',
        },
        'Itinerary': {
            fullname: `Timeline` + '\n' + `Itinerary`,
            txt: `Create a personalized wedding day timeline for you and your guests with Timeline Itinerary. Add all events, share with ease, and customize it to suit your needs, ensuring a stress-free wedding day.`,
        },
        'Invitation': {
            fullname: `Site Builder,` + '\n' + `Guest List`,
            txt: `Organize your dream wedding with our essential Wedding Site Builder and Guest List app. Create a stunning website to share wedding details and manage RSVPs, meal preferences, and guest details with ease, ensuring a stress-free planning process.`,
        },
        'Seatingchart': {
            fullname: `Seating` + '\n' + `Chart`,
            txt: 'Our Seating Chart app is an essential tool for organizing your wedding reception, both online and offline. Create and manage a personalized seating chart with ease, ensuring a smooth and enjoyable reception for everyone.',
        },
        'Qr': {
            fullname: 'QR',
            txt: 'With our QR Creator, you can easily create and customize QR codes for your wedding. Share important information with guests, such as your wedding website, seating chart, and timeline itinerary, with just a scan.',
        },
    }
function Planning(props) {

    const device = Checkdevice()

    const [plobj, setPlobj] = useState({})

    useEffect(() => {
        const PLDOMOBJ = guestbookbool
            ? {
                'Telegram': <Checklist {...props} />,
                'Auspiciousdate': <Budgetmaker {...props} />,
                'Checklist': <Checklist {...props} />,
                'Budget': <Budgetmaker {...props} />,
                'Itinerary': <Itinerarymaker  {...props} />,
                'Invitation': <Invitationmaker {...props} />,
                'Seatingchart': <Seatingchart {...props} />,
                'Guestbook': <Guestbookbuilder {...props} />,
                'Qr': <Qrmaker {...props} />
            }
            : {
                'Telegram': <Checklist {...props} />,
                'Auspiciousdate': <Budgetmaker {...props} />,
                'Checklist': <Checklist {...props} />,
                'Budget': <Budgetmaker {...props} />,
                'Itinerary': <Itinerarymaker  {...props} />,
                'Invitation': <Invitationmaker {...props} />,
                'Seatingchart': <Seatingchart {...props} />,
                'Qr': <Qrmaker {...props} />
            }

        var new_PLOBJ = {}
        for (let i = 0; i < Object.keys(PLOBJ).length; i++) {
            const key = Object.keys(PLOBJ)[i]
            new_PLOBJ[key] = PLOBJ[key]
            new_PLOBJ[key]['dom'] = PLDOMOBJ[key]
        }
        if (!guestbookbool) delete new_PLOBJ['Guestbook']
        delete new_PLOBJ['Telegram']
        delete new_PLOBJ['Auspiciousdate']
        delete new_PLOBJ['Budgetestimator']
        delete new_PLOBJ['Hashtaggenerator']
        setPlobj(new_PLOBJ)

    }, [])

    return Object.keys(plobj).length > 0
        ? <div className="planning_main_root">
            <div style={{ width: '100%', height: 187, backgroundColor: '#fff5f5', position: 'absolute', zIndex: -1 }} />
            <div id='planningedit_root'>
                <div className="planning_apps_strip">
                    {Object.keys(plobj).map((key, index) => {
                        const { name, url, icon } = plobj[key]
                        return <Appcard
                            key={name + index}
                            device={device}
                            onClick={() => props.history.push(url)}
                            icon={icon}
                            content={name}
                            selected={key === props.name}
                        />
                    })}
                </div>
            </div>
            <div className='planning_tool_title'>
                <div>{APP_DETAILS[props.name].fullname}</div>
                <div className='planning_tool_subtitle'>
                    {APP_DETAILS[props.name].txt}
                </div>
            </div>
            {props.name === 'Planning' ? null : plobj[props.name]['dom']}
            <div style={{ height: 60 }} />
        </div>
        : null

}

export default Planning