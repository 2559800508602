import  {useState,  useLayoutEffect} from 'react'

export function ScreenHeight(){
  const [height, setHeight] = useState(window.innerHeight);

  useLayoutEffect(() => {
    function updateHeight() {
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', updateHeight);
    updateHeight();
    return () => window.removeEventListener('resize', updateHeight);
  }, []);
  return height;
}

export function ScreenWidth(){
  const [width, setWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    function updateWidth() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return width;
}