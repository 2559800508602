import axios from 'axios'
import { PROJECT_PROJECT_READ_DETAIL_LINK,USER_PROJECT_PROJECT_READ_DETAIL_LOGGED_LINK } from '../../config/api'

export const FETCH_PROJECT_DETAIL_ACTION = 'FETCH_PROJECT_DETAIL_ACTION'
export const FETCH_PROJECT_DETAIL_ACTION_FULFILLED = 'FETCH_PROJECT_DETAIL_ACTION_FULFILLED'
export const FETCH_PROJECT_DETAIL_ACTION_PENDING = 'FETCH_PROJECT_DETAIL_ACTION_PENDING'
export const FETCH_PROJECT_DETAIL_ACTION_REJECTED = 'FETCH_PROJECT_DETAIL_ACTION_REJECTED'
export function fetchprojectdetail_action(project_id) {
    let options = {
        method: 'POST',
        data: {
            project_id, 
        }
    }
    return {
        type: FETCH_PROJECT_DETAIL_ACTION,
        payload: axios.post(PROJECT_PROJECT_READ_DETAIL_LINK, options)
    }
}



export const FETCH_PROJECT_DETAIL_LOGGED_ACTION = 'FETCH_PROJECT_DETAIL_LOGGED_ACTION'
export const FETCH_PROJECT_DETAIL_LOGGED_ACTION_FULFILLED = 'FETCH_PROJECT_DETAIL_LOGGED_ACTION_FULFILLED'
export const FETCH_PROJECT_DETAIL_LOGGED_ACTION_PENDING = 'FETCH_PROJECT_DETAIL_LOGGED_ACTION_PENDING'
export const FETCH_PROJECT_DETAIL_LOGGED_ACTION_REJECTED = 'FETCH_PROJECT_DETAIL_LOGGED_ACTION_REJECTED'
export function fetchprojectdetaillogged_action(project_id, token) {
    let options = {
        method: 'POST',
        url: USER_PROJECT_PROJECT_READ_DETAIL_LOGGED_LINK,
        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
        data: { project_id },
    }
    return {
        type: FETCH_PROJECT_DETAIL_LOGGED_ACTION,
        payload: axios(options)
    }
}






























