import React, { useEffect, useRef, useState, memo } from 'react'
import { Togglebutton } from '../../../../../../basic_components'
import { Modcfm } from '../../../../../../components'
import { EMPTY, cryptobool } from '../../../../../../config/constants'
import { title_dom, status_dom, input_dom, msg_dom, textarea_dom, multiplechoice_dom, radiobtnsmall_dom } from '../Commonelements/Commonelements'
import Success from './components/Success'
import './Glmodal.css'

function Addguestcontent({ returnpage,addstate, update, allguestqnsuids, guestcats, guestqns, guestqnsuids, guestgrps }) {

    const [page, setPage] = useState('UPDATE') // UPDATE or CAT
    const [addname, setAddname] = useState(EMPTY)
    const [addemail, setAddemail] = useState(EMPTY)
    const [addcontact, setAddcontact] = useState(EMPTY)
    const [addaddress, setAddaddress] = useState(EMPTY)
    const [addcat, setAddcat] = useState([])
    const [addrsvp, setAddrsvp] = useState(EMPTY)
    const [addstatus, setAddstatus] = useState({ date: '', from: '', action: '' })
    const [addgroup, setAddgroup] = useState(EMPTY)
    const [addqnsans, setAddqnsans] = useState({})
    const [allguestqns, setAllguestqns] = useState([]) // all guest qns in a single array
    const [msgs, setMsgs] = useState({})
    const [checking, setChecking] = useState(false)
    const [grpldr, setGrpldr] = useState(false)
    const [defaultqn_state_map_counter, setDefaultqn_state_map_counter] = useState(0)
    const [defaultqn_state_map, setDefaultqn_state_map] = useState({ // default state
        "1-name": {
            "editable": false,
            "choice": "" // choice exist only if "editable" is true
        },
        "2-email": {
            "editable": true,
            "choice": "MANDATORY"
        },
        "3-contact": {
            "editable": true,
            "choice": "OPTIONAL"
        },
        "4-address": {
            "editable": true,
            "choice": "OPTIONAL"
        },
        '7-nftwalletaddress': {
            "editable": true,
            "choice": "OPTIONAL"
        }
    })

    const [wallet, setWallet] = useState(EMPTY)
    const [walletstatus, setWalletstatus] = useState(false)
    const [walletcfmmod, setWalletcfmmod] = useState(false)

    const focusref = useRef(null)

    useEffect(() => {
        if (addstate === 'IDLE') {
            setAddname(EMPTY)
            setAddemail(EMPTY)
            setAddcontact(EMPTY)
            setAddaddress(EMPTY)
            setAddcat([])
            setAddrsvp(EMPTY)
            setAddgroup(EMPTY)
            setWallet(EMPTY)
            setWalletstatus(false)
            setWalletcfmmod(false)
            setAddstatus({ date: '', from: '', action: '' })
            setGrpldr(false)

            // clear all addqnsans
            let arr = Object.keys(addqnsans)
            let new_addqnsans = addqnsans
            for (let i = 0; i < arr.length; i++) {
                const key = arr[i]
                new_addqnsans[key] = ''
            }
            setAddqnsans(new_addqnsans)
            let new_msgs = {}
            for (let j = 0; j < allguestqnsuids.length; j++) {
                new_msgs[allguestqnsuids[j]] = ""
            }
            setMsgs(new_msgs)
            focusref.current.focus();
        }
    }, [addstate])

    useEffect(() => {

        // flatten guestqns into a single array, and add extra info to each guestqn obj
        // add unique id and question category ( mainqn of followupqn )

        function addmoreinfo_mainqn(qntype, currqn, id) {
            return [qntype, id, ...currqn]
        }

        function addmoreinfo_followqn(qntype, currqn) {
            return [qntype, ...currqn]
        }

        let temp_allguestqns = []
        if (guestqns.length > 0) {
            for (let i = 0; i < guestqns.length; i++) {
                let currqn = guestqns[i]
                let qntype = currqn[1]
                let mainqnid = guestqnsuids[i]
                temp_allguestqns.push(addmoreinfo_mainqn('mainqn', currqn, mainqnid))
                if (qntype === 0) {
                    let options = currqn[8]
                    for (let k = 0; k < options.length; k++) { // options
                        var followupqns = options[k][1]
                        if (followupqns.length > 0) {
                            for (let j = 0; j < followupqns.length; j++) {
                                temp_allguestqns.push(addmoreinfo_followqn('followupqn', followupqns[j]))
                            }
                        }
                    }
                }
            }
        }

        setAllguestqns(temp_allguestqns)

    }, [guestqns])

    useEffect(() => {
        if (checking) {
            // just to check if the new added guest details obey to the rule
            setChecking(() => { // will return false once everything is done

                let data = {}

                // update data, as data is empty object
                for (let i = 1; i < allguestqnsuids.length; i++) {  // additional data for user defined rsvp questions
                    const qnuid = allguestqnsuids[i]
                    data[qnuid] = addqnsans[qnuid] ? addqnsans[qnuid] : ''
                }
                data['1-name'] = addname
                data['2-email'] = addemail
                data['3-contact'] = addcontact
                data['4-address'] = addaddress
                data['5-group'] = addgroup
                data['6-category'] = addcat
                data['7-nftwalletaddress'] = wallet
                data['8-rsvp'] = addrsvp
                data['10-status'] = addstatus

                // loop all data to ensure no more than characters limit
                // and update errormsg
                let new_msgs = msgs
                let keys = Object.keys(data)
                let error_counter = 0
                for (let i = 0; i < keys.length; i++) {
                    let key = keys[i]
                    if (data[key].length > 500) {
                        new_msgs[key] = 'You have exceeded 500 character limit.'
                        error_counter++
                    }
                    else {
                        new_msgs[key] = ""
                    }
                }

                error_counter > 0 ? setMsgs(new_msgs) : update(data, grpldr, defaultqn_state_map)
                return false
            })
        }
    }, [checking])


    const selectbtn_dom = <div className='Rsvpexplorer_portalrow' style={{ marginBottom: 40 }}>
        <Togglebutton
            rootstyle={{ width: '100%' }}
            singlebtnstyle={{ width: '33.33%' }}
            funcs={[
                () => { setPage('UPDATE') },
                () => { setPage('CAT') },
                () => { setPage('RSVP') },
            ]}
            txts={['Guest Details', `Category ${addcat.length > 0 ? `( ${addcat.length} )` : ''}`, 'RSVP Questions']}
            btnselected={{ 'UPDATE': 0, 'CAT': 1, 'RSVP': 2 }[page]}
        />
    </div>

    const rsvpaccept_dom = <div className='ig_lblinputroot'>
        <div className='ig_lbl'>RSVP</div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <select className='ig_select' onChange={(e) => { setAddrsvp(e.target.value) }}>
                <option value={''}>{'( Unanswered )'}</option>
                <option value={'accept'}>Accept</option>
                <option value={'decline'}>Decline</option>
            </select>
        </div>
        {msg_dom('8-rsvp', msgs)}
    </div>

    const rsvpqn_dom = allguestqns.length > 0
        ? <div
            key={'userdefinedqns_dom'}
            style={{ margin: '40px 0px 60px 0px' }}>
            {title_dom('RSVP Questions')}
            {allguestqns.map((qn) => {

                const mainqn_or_folqn = qn[0]

                if (mainqn_or_folqn === 'mainqn') {

                    const uniqid = qn[1]
                    const qnstxt = qn[2]
                    const qntype = qn[3]

                    const onchange = async (e) => {
                        const { id, value } = e.target;
                        addqnsans[id] = value;
                        msgs[id] = ''
                        setAddqnsans({ ...addqnsans })
                        setMsgs({ ...msgs })
                        setChecking(false)
                    }

                    if (qntype === 0) { // multiple choice
                        let options = qn[10].map((item) => item[0])
                        return multiplechoice_dom(qnstxt, options, onchange, uniqid, msgs, addqnsans[uniqid])
                    }
                    else if (qntype === 1) { // text qn
                        return textarea_dom(qnstxt, '', addqnsans[uniqid], onchange, uniqid, msgs)
                    }

                }
                else if (mainqn_or_folqn === 'followupqn') {
                    const uniqid = qn[4]
                    const qnstxt = qn[1]
                    const qntype = qn[2]

                    const onchange = async (e) => {
                        const { id, value } = e.target;
                        addqnsans[id] = value;
                        msgs[id] = ''
                        setAddqnsans({ ...addqnsans })
                        setMsgs({ ...msgs })
                        setChecking(false)
                    }

                    if (qntype === 0) { // multiple choice
                        let tempqn = qn.slice()
                        let options = tempqn.splice(5, tempqn.length)
                        return multiplechoice_dom(qnstxt, options, onchange, uniqid, msgs, addqnsans[uniqid])
                    }
                    else if (qntype === 1) { // text qn
                        return textarea_dom(qnstxt, '', addqnsans[uniqid], onchange, uniqid, msgs)
                    }

                }
                return null
            })}
        </div>
        : null

    const group_dom = <div className='ig_lblinputroot'>
        <div className='ig_lbl'>{'Grouping'}</div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <select className='ig_select' value={addgroup} onChange={(e) => { setAddgroup(e.target.value) }}>
                <option value={''}>{'( Unanswered )'}</option>
                {guestgrps.map((gg, i) => {
                    return <option key={'guestgrp_' + i} value={gg}>{gg}</option>
                })}
            </select>
        </div>
        {(addgroup && addgroup.length > 0)
            ? <button onClick={() => { setGrpldr(!grpldr) }} style={{ marginTop: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: 'transparent', border: 'none', boxShadow: 'none', paddingLeft: 0 }} >
                <input type='checkbox' checked={grpldr} onChange={() => { setGrpldr(!grpldr) }} />
                <div style={{ paddingLeft: 5 }}>Assign as leader</div>
            </button>
            : null}
        {msg_dom('5-group', msgs)}
    </div>

    const Extradom = (key) => {
        // { // default state
        //     "1-name": {
        //         "editable": false,
        //         "choice": "" // choice exist only if "editable" is true
        //     },
        //     "2-email": {
        //         "editable": true,
        //         "choice": "MANDATORY"
        //     },
        //     "3-contact": {
        //         "editable": true,
        //         "choice": "OPTIONAL"
        //     },
        //     "4-address": {
        //         "editable": true,
        //         "choice": "OPTIONAL"
        //     },
        //     '7-nftwalletaddress': {
        //         "editable": true,
        //         "choice": "OPTIONAL"
        //     }
        // }
        return <div key={defaultqn_state_map[key]['editable'] + defaultqn_state_map_counter} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            {radiobtnsmall_dom(
                defaultqn_state_map[key]['editable'],
                () => {
                    setDefaultqn_state_map_counter((defaultqn_state_map_counter) => defaultqn_state_map_counter + 1)
                    setDefaultqn_state_map((defaultqn_state_map) => {
                        defaultqn_state_map[key]['editable'] = !defaultqn_state_map[key]['editable']
                        return defaultqn_state_map
                    })
                },
                defaultqn_state_map[key]['editable'] ? 'Guest Editable' : 'Guest Uneditable')}
        </div>
    }

    const disablewallet = () => {
        setWalletcfmmod(true)
    }

    const enablewallet = () => {
        setWalletstatus(true)
        setWallet('')
    }

    const update_dom = <div style={{ display: 'flex', flexDirection: 'column' }}>
        {title_dom('Guest Details')}
        {status_dom(
            addstatus,
            (e) => { // update status main
                const { value } = e.target
                setAddstatus(() => {
                    if (value === 'sent') {
                        return { date: new Date().toLocaleString(), from: '', action: 'sent' }
                    }
                    else if (value === 'replied') {
                        return { date: new Date().toLocaleString(), from: '', action: 'replied' }
                    }
                    else if (value === 'update') {
                        return { date: new Date().toLocaleString(), from: '', action: 'update' }
                    }
                    else if (value === 'reminder') {
                        return { date: new Date().toLocaleString(), from: '', action: 'reminder' }
                    }
                    else if (value === '') {
                        return { date: '', from: '', action: '' }
                    }
                })
            },
            (e) => { // update : Whatsapp, Telegram, Others
                const { value } = e.target
                setAddstatus((status) => {
                    var new_status = Object.assign({}, status)
                    new_status['from'] = value
                    return new_status
                })
            }
        )}
        {input_dom('Name', `Guest Name`, addname, (e) => { setAddname(e.target.value); setMsgs((msgs) => { msgs['1-name'] = ''; return msgs; }) }, '1-name', msgs, null, Extradom('1-name'), { borderBottomRightRadius: 0 })}
        {input_dom('Email', `Guest Email`, addemail, (e) => { const { value } = e.target; setAddemail(value); setMsgs((msgs) => { msgs['2-email'] = ''; return msgs }) }, '2-email', msgs, null, Extradom('2-email'), { borderBottomRightRadius: 0 })}
        {input_dom('Contact', `Guest Contact`, addcontact, (e) => { const { value } = e.target; setAddcontact(value); setMsgs((msgs) => { msgs['3-contact'] = ''; return msgs }) }, '3-contact', msgs, null, Extradom('3-contact'), { borderBottomRightRadius: 0 })}
        {input_dom('Address', `Guest Address`, addaddress, (e) => { const { value } = e.target; setAddaddress(value); setMsgs((msgs) => { msgs['4-address'] = ''; return msgs }) }, '4-address', msgs, null, Extradom('4-address'), { borderBottomRightRadius: 0 })}
        {cryptobool 
        ? <div className='gl_walletbg'>
            {walletstatus
                ? <>
                    <button className='gl_walletbtn' onClick={disablewallet}>Disable Crypto Wallet</button>
                    {input_dom('Crypto Wallet Address', 'Guest Wallet', wallet, (e) => { setWallet(e.target.value); setMsgs((msgs) => { msgs['7-nftwalletaddress'] = ''; return msgs; }) }, '7-nftwalletaddress', msgs, null, Extradom('7-nftwalletaddress'), { borderBottomRightRadius: 0 })}
                </>
                : <button className='gl_walletbtn' onClick={enablewallet}>Enable Crypto Wallet</button>
            }
        </div>
        :null}
        {group_dom}
        {rsvpaccept_dom}
    </div>

    const categories_dom = <div style={{ display: 'flex', flexDirection: 'column' }}>
        {title_dom('Category')}
        {(guestcats && guestcats.length > 0)
            ? <div style={{ display: 'flex', flexDirection: 'column', }}>
                <div className='Rsvpexplorer_portaltaglabel' style={{ padding: '0px 10px' }}>Category Tag Collection.</div>
                <div className='Rsvpexplorer_portaltagrow' style={{ padding: 10, border: '1px solid #dddddd', borderRadius: 5, margin: '0px 10px' }}>
                    {/* {tagsalrdydeleted.map((tag, i) => {
                    return <button key={i + tag} className={`Rsvpexplorer_portaltag ${tagsarr.indexOf(tag) > -1 ? 'Rsvpexplorer_portaltagselected' : ''} `} onClick={() => { btnremovetag(tag, i) }} > {tag} </button>
                })} */}
                    {guestcats.map((cat, i) => {
                        return <button
                            key={'cat' + i}
                            className={`Rsvpexplorer_portaltag ${addcat.indexOf(cat) > -1 ? 'Rsvpexplorer_portaltagselected' : ''}`}
                            style={{ width: 'max-content' }}
                            value={cat}
                            onClick={(e) => {
                                const { value } = e.target
                                let local_addcat = addcat.slice()
                                if (addcat.indexOf(cat) > -1) { // alrdy exist, remove it from array
                                    local_addcat.splice(addcat.indexOf(cat), 1)
                                }
                                else {
                                    local_addcat.push(value)
                                }
                                setAddcat(local_addcat)
                            }}
                        >{cat}</button>
                    })}
                </div>
            </div>
            : <div style={{ display: 'flex', flexDirection: 'column', }}>
                <div className='Rsvpexplorer_portaltaglabel' style={{ padding: '0px 10px' }}>
                    You do not have any category.
                </div>
            </div>}
    </div>

    return <div className='Rsvpexplorer_portal' ref={focusref}>
        <div className='Rsvpexplorer_portaltitle'>Add New Guest</div>
        {addstate !== 'SUCCESS'
            ? <>
                <div className='Rsvpexplorer_portalcontent'>
                    {selectbtn_dom}
                    {page === 'UPDATE' ? update_dom : null}
                    {page === 'CAT' ? categories_dom : null}
                    {page === 'RSVP' ? rsvpqn_dom : null}
                    <div style={{ height: 50, width: '100%' }} />
                </div>
                <button className='Rsvpexplorer_portalsave' onClick={() => { setChecking(true) }} disabled={addstate === 'SAVING'}>
                    {{ // IDLE, SAVING, SUCCESS, FAILURE
                        'IDLE': 'Save',
                        'SAVING': 'Saving...',
                        'SUCCESS': 'New Question Created',
                        'FAILURE': 'Error Occurred, please try again later.'
                    }[addstate]}
                </button>
            </>
            : <Success
                txt={'New guest added. Click to return.'}
                onClick={returnpage}
            />
        }

        {walletcfmmod
            ? <Modcfm
                onHide={() => {
                    setWalletcfmmod(false)
                }}
                onOk={() => {
                    setWalletcfmmod(false)
                    setWalletstatus(false)
                    setWallet(-1)
                }}
                onNo={() => {
                    setWalletcfmmod(false)
                }}
                txt={
                    <div>
                        {`Once disable current wallet address will be compeletely wiped out. Please be sure before proceeding.`}
                    </div>
                }
            />
            : null}
    </div>
}

export default memo(Addguestcontent)