import React from 'react'
import { Palette } from '../../../../../../../../../../components';
import { WEBSITE_URL } from '../../../../../../../../../../config/constants';
var QRCode = require('qrcode.react');

class Commonart {
    backbtn(onclick) {
        return <button className='Art_backbtn' onClick={onclick} >
            <svg className="gblm_backbtn" viewBox="0 0 1024 1024"  ><path d="M232.727273 0h558.545454a232.727273 232.727273 0 0 1 232.727273 232.727273v558.545454a232.727273 232.727273 0 0 1-232.727273 232.727273H232.727273a232.727273 232.727273 0 0 1-232.727273-232.727273V232.727273a232.727273 232.727273 0 0 1 232.727273-232.727273z m438.178909 711.819636L471.086545 512l199.819637-199.819636L605.090909 246.365091 339.456 512 605.090909 777.634909l65.815273-65.815273z" ></path></svg>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className='Art_backinner1'>Art</span>
                <span className='Art_backinner2'>Menu</span>
            </div>
        </button>
    }
    endbtns(reload, save, purchase, artkey) {

        var softonly = artkey + '_SOFT'
        var hardsoft = artkey + '_HARDSOFT'
        if (purchase.hasOwnProperty(softonly) || purchase.hasOwnProperty(hardsoft)) {
            const { status } = purchase.hasOwnProperty(softonly) ? purchase[softonly] : purchase[hardsoft]
            return <div className="Art_row">
                <button className="Art_reload" style={{ width: status === 'PENDING' ? 'calc( 100% - 10px )' : '100%' }} onClick={reload}>Reload</button>
                {status !== 'PENDING' ? <button className="Art_save" onClick={save}>Save</button> : null}
            </div>
        }
        else { // not FULFILLED or PENDING 
            return <div className="Art_row">
                <button className="Art_reload" onClick={reload}>Reload</button>
                <button className="Art_save" onClick={save}>Save</button>
            </div>
        }
    }
    papersizebtns(update, papersize) {
        return <div className="Art_color">
            <div className="Art_colortitle">Size</div>
            <div className="Art_row" style={{ padding: '0px 5px' }}>
                <button className={papersize === 'a3' ? 'Art_btnselected' : 'Art_btnunselected'} style={{ borderTopLeftRadius: 5, borderBottomLeftRadius: 5, borderRight: 'none' }} onClick={update} value="a3">A3</button>
                <button className={papersize === 'a4' ? 'Art_btnselected' : 'Art_btnunselected'} style={{ borderTopRightRadius: 5, borderBottomRightRadius: 5, borderLeft: 'none' }} onClick={update} value="a4">A4</button>
            </div>
        </div>
    }
    orientbtns(updateorient, orientation) {
        return <div className="Art_color">
            <div className="Art_colortitle">Orientation</div>
            <div className="Art_row" style={{ padding: '0px 5px' }}>
                <button className={orientation === 'landscape' ? 'Art_btnselected' : 'Art_btnunselected'} style={{ borderTopLeftRadius: 5, borderBottomLeftRadius: 5, borderRight: 'none' }} onClick={updateorient} value="landscape">Landscape</button>
                <button className={orientation === 'portrait' ? 'Art_btnselected' : 'Art_btnunselected'} style={{ borderTopRightRadius: 5, borderBottomRightRadius: 5, borderLeft: 'none' }} onClick={updateorient} value="portrait">Portrait</button>
            </div>
        </div>
    }
    framebtns(updateframe, frame) {
        // return <div className="Art_2btnroot">
        return <div className="Art_color">
            <div className="Art_colortitle">Framing</div>
            <div className="Art_row" style={{ padding: '0px 5px' }}>

                <button className={frame === 'framed' ? 'Art_btnselected' : 'Art_btnunselected'} style={{ borderTopLeftRadius: 5, borderBottomLeftRadius: 5, borderRight: 'none' }} onClick={updateframe} value="framed">Framed</button>
                <button className={frame === 'unframed' ? 'Art_btnselected' : 'Art_btnunselected'} style={{ borderTopRightRadius: 5, borderBottomRightRadius: 5, borderLeft: 'none' }} onClick={updateframe} value="unframed">Unframed</button>
            </div>
        </div>
    }
    colorpalette(updatecolor, colors, selectedcolor, title) {
        return <div className="Art_color">
            <div className="Art_colortitle">{title}</div>
            <Palette sc={selectedcolor} colors={colors} onClick={updatecolor} selfselect={false} />
        </div>
    }
    qrurlbtns(updateqrplacement, qractive, urlhandler) {
        return <div className="Art_color">
            <div className="Art_colortitle">QR Code Url</div>
            <label className="Art_urhandler"> {`https://${WEBSITE_URL}/guestbooklookup/`+ urlhandler}</label>
            <div className="Art_2btnroot" style={{ paddingTop: 0 }}>
                <button
                    className={qractive === 'LEFT' ? "Art_btnselected" : "Art_btnunselected"}
                    style={{ borderRight: '1px solid #cfcecf', borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }}
                    value="LEFT"
                    onClick={updateqrplacement}>
                    Left
                </button>
                <button
                    className={qractive === 'RIGHT' ? "Art_btnselected" : "Art_btnunselected"}
                    style={{ borderLeft: '1px solid transparent', borderTopRightRadius: 5, borderBottomRightRadius: 5 }}
                    value="RIGHT"
                    onClick={updateqrplacement}>Right</button>
            </div>
        </div>
    }
    purchasedlbtns(purchase, artkey, togglepurchase, toggledownload) {
        if (purchase) {

            if (purchase[artkey + '_HARDSOFT'] || purchase[artkey + '_SOFT']) {
                var status
                if (purchase.hasOwnProperty(artkey + '_HARDSOFT')) {
                    status = purchase[artkey + '_HARDSOFT']['status']
                }
                else if (purchase.hasOwnProperty(artkey + '_SOFT')) {
                    status = purchase[artkey + '_SOFT']['status']
                }
                else {
                    return null
                }

                return {
                    'FULFILLED': <div className='Emojiart_row'>
                        <button className="Emojiart_purchase" style={{ position: 'relative' }} key={artkey} value={artkey} onClick={togglepurchase}>Purchase</button>
                        <button className="Emojiart_purchase" style={{ position: 'relative', borderLeft: '1px solid white' }} onClick={toggledownload}>Download</button>
                    </div>,
                    'PENDING': <button className="Emojiart_purchase" key={artkey}> Transaction Pending</button>
                }[status]
            }
            else {
                return <button className="Emojiart_purchase" key={artkey} value={artkey} onClick={togglepurchase}>Purchase</button>
            }

            // return (purchase[artkey + '_HARDSOFT'] || purchase[artkey + '_SOFT'])
            //     ? {
            //         'FULFILLED': <div className='Emojiart_row'>
            //             <button className="Emojiart_purchase" style={{ position: 'relative' }} key={artkey} value={artkey} onClick={togglepurchase}>Purchase</button>
            //             <button className="Emojiart_purchase" style={{ position: 'relative', borderLeft: '1px solid white' }} onClick={toggledownload}>Download</button>
            //         </div>,
            //         'PENDING': <button className="Emojiart_purchase" style={{ position: 'relative', borderLeft: '1px solid white' }} >Pending</button>
            //     }[purchase[artkey + '_HARDSOFT'] ? purchase[artkey + '_HARDSOFT']['status'] : purchase[artkey + '_SOFT']['status']]
            //     : <button className="Emojiart_purchase" key={artkey} value={artkey} onClick={togglepurchase}>Purchase</button>
        }

    }
    puchasedbtnsonly(purchase, artkey, togglepurchase, toggledownload) {
        return <div className='Emojiart_row'>
            <button className="Emojiart_purchase" style={{ position: 'relative', borderLeft: '1px solid white' }} onClick={toggledownload}>Download</button>
        </div>
    }
    twoselectionbtns(title, selectedval, btn1val, btn2val, btn1func, btn2func, btn1txtval, btn2txtval) {
        return <div className="Art_color">
            <div className="Art_colortitle">{title}</div>
            <div className="Art_row" style={{ padding: '0px 5px' }}>
                <button className={selectedval === btn1val ? 'Art_btnselected' : 'Art_btnunselected'} value={btn1val} style={{ borderTopLeftRadius: 5, borderBottomLeftRadius: 5, borderRight: '1px solid #cfcecf' }} onClick={btn1func}>{btn1txtval}</button>
                <button className={selectedval === btn2val ? 'Art_btnselected' : 'Art_btnunselected'} value={btn2val} style={{ borderTopRightRadius: 5, borderBottomRightRadius: 5, borderLeft: 'none' }} onClick={btn2func}>{btn2txtval}</button>
            </div>
        </div>
    }
    threeselectionbtns(title, selectedval, btn1val, btn2val, btn3val, btnfunc, btn1txtval, btn2txtval, btn3txtval) {
        return <div className="Art_color">
            <div className="Art_colortitle">{title}</div>
            <div className="Art_row" style={{ padding: '0px 5px' }}>
                <button className={selectedval === btn1val  ? 'Art_btnselected' : 'Art_btnunselected'} value={btn1val} style={{ borderTopLeftRadius: 5, borderBottomLeftRadius: 5, borderRight: '1px solid #cfcecf' }} onClick={btnfunc}>{btn1txtval}</button>
                <button className={selectedval === btn2val ? 'Art_btnselected' : 'Art_btnunselected'} value={btn2val} style={{ border: 'none', borderTop: '1px solid #cfcecf', borderBottom: '1px solid #cfcecf' }} onClick={btnfunc}>{btn2txtval}</button>
                <button className={selectedval === btn3val ? 'Art_btnselected' : 'Art_btnunselected'} value={btn3val} style={{ borderTopRightRadius: 5, borderBottomRightRadius: 5, borderLeft: '1px solid #cfcecf' }} onClick={btnfunc}>{btn3txtval}</button>
            </div>
        </div>
    }
    qrcodedom(qractive, urlhandler, size, m) {
        return qractive !== 'NONE'
            ? <QRCode
                style={{ margin: m }}
                // style={qractive === 'LEFT' ? { left: `calc( ${size}px / 2 )`, bottom: `calc( ${size}px / 2 )` } : { right: `calc( ${size}px / 2 )`, bottom: `calc( ${size}px / 2 )` }}
                className={qractive === 'LEFT' ? "Art_qrcodel" : "Art_qrcoder"}
                value={`${WEBSITE_URL}/guestbooklookup/` + urlhandler}
                size={size}
            />
            : null
    }
    logodom(size, fc) {
        return null
    }
}


export default Commonart