import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { READABLE_VENDORTYPE_MAP, EMPTYSPACE, ABBREVCITY_CURRENCY_MAP, BOXSHADOW_CARD, f_fs, VENDORCARD_WT_WEB, VENDORCARD_WT_MOBILE, VENDORCARD_WT_LARGEMOBILE, VENDORCARD_WT_SMALLMOBILE, SUB_FF, VC_NAME_COLOR, TIER_DIAMOND_POSITION } from '../../config/constants'
import { Heart } from '../../async_components'
import { Checkdevice, Processmoney, Processname } from '..'
import { getS3fullpath_withbucketname } from '../../config/commonfunctions'
import './Vendorcard.css'

function Vendorcardtablet(props) {

    const getImgSrc = () => {
        const { profileimg_id = [] } = props.content
        if (profileimg_id.length > 0) {
            return getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', profileimg_id[0].link)
        }
        else if ('link' in profileimg_id) {
            return getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', profileimg_id.link)
        }
    }

    const { content, selectedvt, cardwt, cardmargin } = props
    const { displayname, pricelist_num = 0, project_num = 0, review_num = 0, username, vendortype, secondaryvendortype, tier } = content
    const viewlookup = () => document.getElementById(username).click()

    let showprice = true
    let calprice

    if (content.vendortype === selectedvt) { // show vt price

        calprice = Processmoney(content[props.abbrev_userlocation + 'minprice']) + ' ' + ABBREVCITY_CURRENCY_MAP[props.abbrev_userlocation]
    }
    else if (content.secondaryvendortype === selectedvt) { // show svt price
        calprice = Processmoney(props.content[props.abbrev_userlocation + 'minprice_svt']) + ' ' + ABBREVCITY_CURRENCY_MAP[props.abbrev_userlocation]
    }
    else {
        showprice = false
        calprice = ''
    }

    // if is all, render privt and svt
    // if is not all, render both
    return <div
        className='Vendorcard_root'
        style={{
            cursor: 'pointer',
            width: cardwt, height: 416, border: '1px solid var(--subgrey)',
            fontFamily: 'Lato', margin: cardmargin, borderRadius: 7,
            color: 'var(--maindark)', backgroundColor: '#ffffff',
        }}
        key={username}
    >

        <Link id={username} style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/vendorlookup/' + username} target="_blank" />

        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', placeContent: 'flex-end', height: 40, position: 'relative' }}>
            {tier === 0
                ? <div className='VCARD_DIAMONDROOT' />
                : <div className={`VCARD_DIAMONDROOT VCARD_DIAMONDROOT_${tier}`} style={{ position: 'absolute', top: 35 }}>
                    <div
                        className='VCARD_DIAMONDCONTENT'
                        style={{
                            backgroundImage: `url('https://asset.weavebliss.com/assets/sprites/diamonds.png')`,
                            backgroundPositionY: TIER_DIAMOND_POSITION[tier].y + 'px',
                        }}
                    />
                </div>}
            <div style={{ width: 40, height: 40, display: 'flex', placeContent: 'center', alignItems: 'center' }}>
                <Heart
                    vendortypeinterface_id={props.vendortypeinterface_id}
                    vendortype={props.vendortype}
                    secondaryvendortype={props.secondaryvendortype}
                    vendortype_id={props.vendortype_id}
                    userlike={props.userlike}
                    updateHeart={props.updateHeart}
                    parent={'Vendorcard'}
                />
            </div>
        </div>

        <div onClick={viewlookup} style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', width: '100%', placeContent: 'center', height: 145, }}>
                <img
                    src={getImgSrc()}
                    style={{
                        borderRadius: 145, width: 145, height: 145,
                        display: 'flex', placeContent: 'center', objectFit: 'cover',
                        border: '1px solid var(--subgrey)'
                    }} />
            </div>

            <div style={{ width: '100%', display: 'flex', placeContent: 'center', alignItems: 'center', height: 30, margin: '5px 0px', padding: '0px 5px', fontWeight: 'bold', fontSize: 13, textAlign: 'center', whiteSpace: 'pre', }}>
                {Processname(displayname)}
            </div>

            <div style={{ fontSize: 13, display: 'flex', flexDirection: 'column', alignItems: 'center', height: 50, padding: '0px 5px' }}>
                {selectedvt === 'All'
                    ? <>
                        <div>{READABLE_VENDORTYPE_MAP[vendortype]}</div>
                        {secondaryvendortype === '' ? null : <div>{READABLE_VENDORTYPE_MAP[secondaryvendortype]}</div>}
                    </>
                    : <div>{vendortype === selectedvt ? READABLE_VENDORTYPE_MAP[vendortype] : READABLE_VENDORTYPE_MAP[secondaryvendortype]}</div>
                }
            </div>

            {props.content[props.abbrev_userlocation + 'minprice'] === 0
                ? <div style={{ height: 50 }} />
                : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 50, padding: '0px 5px', }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                        textAlign: 'center',
                        width: '100%',
                        justifyContent: 'center',
                    }}>
                        <div style={{ paddingRight: 6, fontSize: 12, fontWeight: 'bold', }}>Starts At </div>
                        <div style={{fontSize: 13, color: 'var(--mainpink)' }}>{'$ ' + calprice}</div>
                    </div>
                </div>

            }

            <div style={{ width: '100%', height: 85, display: 'flex', color: 'var(--maindark)', flexDirection: 'column', borderTop: '1px solid var(--subgrey)', fontFamily: 'Lato' }}>
                <div style={{ width: '100%', height: '50%', display: 'flex', borderBottom: '1px solid var(--subgrey)', flexDirection: 'column', placeContent: 'center', alignItems: 'center' }}>
                    <div style={{ fontSize: 12, }}>Review</div>
                    <div style={{ fontSize: 13 }}>{parseInt(review_num, 10) === 0 ? '-' : review_num}</div>
                </div>
                <div style={{ width: '100%', height: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', placeContent: 'center', }}>
                        <div style={{ fontSize: 12, }}>Project</div>
                        <div style={{ fontSize: 13 }}>{parseInt(project_num, 10) === 0 ? '-' : project_num}</div>
                    </div>
                    <div style={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', placeContent: 'center', borderLeft: '1px solid var(--subgrey)' }}>
                        <div style={{ fontSize: 12, }}>Pricelist</div>
                        <div style={{ fontSize: 13 }}>{parseInt(pricelist_num, 10) === 0 ? '-' : pricelist_num}</div>
                    </div>
                </div>
            </div>

        </div>

    </div>

}

export default Vendorcardtablet