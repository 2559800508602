//https://stackoverflow.com/questions/3971841/how-to-resize-images-proportionally-keeping-the-aspect-ratio

/* Utility function to convert a canvas to a BLOB */
var dataURLToBlob = function (dataURL) {
    // console.log('dataURL', dataURL)
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
        var parts1 = dataURL.split(',');
        var contentType1 = parts1[0].split(':')[1];
        var raw1 = parts1[1];

        return new Blob([raw1], { type: contentType1 });
    }

    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
}
export var Imagedownsize = (img, type, max_width, max_height) => {
    var canvas = document.createElement('canvas');
    var ctx1 = canvas.getContext("2d");
    ctx1.drawImage(img, 0, 0);


    var newfile
    var dataurl 
    if (type === 'scale') {
        var MAX_WIDTH = max_width;
        var MAX_HEIGHT = max_height;
        var width = img.width;
        var height = img.height;
    
        if (width > height) { // this will adjust in ratio
            if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
            }
        } else {
            if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
            }
        }
        canvas.width = width;
        canvas.height = height;
        var ctx2 = canvas.getContext("2d");
        ctx2.drawImage(img, 0, 0, width, height)
        dataurl = canvas.toDataURL("image/png")
        newfile = dataURLToBlob(dataurl)
    }
    else if (type === 'logo') {
        // var MAX_WIDTH = 500;
        // var MAX_HEIGHT = 500;
        // var width = img.width;
        // var height = img.height;

        // if (width > height) { // this will adjust in ratio
        //     if (width > MAX_WIDTH) {
        //         height *= MAX_WIDTH / width;
        //         width = MAX_WIDTH;
        //     }
        // } else {
        //     if (height > MAX_HEIGHT) {
        //         width *= MAX_HEIGHT / height;
        //         height = MAX_HEIGHT;
        //     }
        // }
        canvas.width = 300;
        canvas.height = 300;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, 300, 300)
        dataurl = canvas.toDataURL("image/png")
        newfile = dataURLToBlob(dataurl)
    }


    return [dataurl, newfile]
}
