import React, { useState, useEffect } from 'react';
import { BUTTONSTYLE } from '../../../../config/cssconstants';
import axios from 'axios';
import { USER_ENQHOST, USER_READHOSTPUBLIC } from '../../../../config/api';
import { Checkdevice, Loading, refresh } from '../../../../components';
import { EMPTY, IMG_ALT } from '../../../../config/constants';

const dashedline = <div style={{ display: 'flex', borderTop: 'thin dashed #707070', margin: 10 }} />
function UserHomeHostReq(props) {

    const device = Checkdevice()
    const { _id, token } = props
    const [firsttime, setFirsttime] = useState(true)
    const [action, setAction] = useState(EMPTY)
    const [data, setData] = useState({})
    const [yourinfo, setYourinfo] = useState(EMPTY)

    useEffect(() => {
        if (firsttime && _id) {
            let options = {
                method: 'POST',
                url: USER_READHOSTPUBLIC,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                data: { userhost_id: _id }
            }

            axios(options).then((result) => {
                const { data } = result
                const { message, r, yourinfo } = data
                try {
                    if (message === 'REFRESH') refresh()
                    setFirsttime(false)
                    setAction(message)
                    if (yourinfo) setYourinfo(yourinfo)
                    setData(r)
                }
                catch (e) {
                    refresh()
                }
            })
        }
    }, [firsttime && _id])

    var root_height, root_width
    if (device === 'MOBILE') {
        root_height = 'max-content'
        root_width = '100vw'
    }
    else if (device === 'WEB' || device === 'TABLET') {
        root_height = '70vh'
        root_width = '100%'
    }



    const tellus_qn = (
        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px 5px', justifyContent: 'center', alignItems: 'center' }}>
            <h5 style={{ display: 'flex', color: '#707070', marginBottom: 0, width: '95%', }}>{'Tell us more about you.'} </h5>
            <textarea
                style={{ width: '95%', height: 100, overflow: 'hidden', overflowWrap: 'break-word', resize: 'none', outline: 'none', borderColor: 'transparent', border: '1px solid #ccc', marginTop: 5 }}
                value={yourinfo}
                onChange={(event) => { setYourinfo(event.target.value) }}
            />
        </div>

    )

    const btns = (
        <div style={{ padding: 5, width: '100%', justifyContent: 'center', display: 'flex', }}>

            {/*REQUEST BUTTON*/}
            {action === 'HVNTREQ' ? <button
                style={{ ...BUTTONSTYLE }}
                onClick={() => {
                    let options = {
                        method: 'POST',
                        url: USER_ENQHOST,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                        data: { userhost_id: _id, yourinfo: yourinfo }
                    }
                    axios(options).then((result) => {
                        try {
                            const { data } = result
                            const { message } = data
                            if (message === 'SUCCESS') {
                                setAction('PENDING')
                            }
                        }
                        catch (e) {
                            refresh()
                        }
                    })
                }}
            >
                Request
        </button> : null}

            {/*PENDING BUTTON*/}
            {action === 'PENDING' ? <button style={{ ...BUTTONSTYLE }}>Request Pending</button> : null}

            {/*MAKEPAYMENT BUTTON*/}
            {action === 'MAKEPAYMENT' ? <button style={{ ...BUTTONSTYLE }}>Make Payment</button> : null}

            {/*PAID BUTTON*/}
            {action === 'PAID' ? <button style={{ ...BUTTONSTYLE }}>Paid</button> : null}

        </div>
    )



    if (firsttime) {
        return <Loading />
    }
    else {
        const { address, cost, currency, date_arr, food, hosttype, itinerary, location, title, profileimg_id } = data//numticket, email, contact, sold, username, _id,
        const pricing = (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', padding: '10px 5px' }}>
                <div style={{ display: 'flex', padding: '3px 0px 0px 0px', width: 'fit-content', fontWeight: 'bold', fontSize: 'large' }}>Price</div>
                <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
                    <h2 style={{ margin: 0, }}> {cost}</h2>
                    <div style={{ display: 'flex', alignItems: 'center', padding: '0px 10px' }}>{currency}</div>
                </div>
            </div>
        )
        if (device === 'MOBILE') {
            const profileimg_mobile = () => {
                return <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <img alt={IMG_ALT} src={profileimg_id ? profileimg_id.link : EMPTY} width={200} height={200} />
                </div>
            }

            const maininfo_mobile = (ht) => {
                return <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: ht, alignItems: 'center' }} >

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '20%' }}>
                        {/* TITLE */}
                        <div style={{ display: 'flex', padding: '3px 0px 0px 0px', borderBottom: 'thin solid #707070', width: 'fit-content', fontWeight: 'bold', fontSize: 'small' }}>{title}</div>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 5, }}>
                            {/* LOCATION and HOSTTYPE*/}
                            <div style={{ display: 'flex', width: 'fit-content' }}>{location + ', ' + hosttype}</div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '80%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '40%' }}>
                            {/* DATES */}
                            <div style={{ display: 'flex', flexDirection: 'column', width: '50%', height: '100%', fontWeight: 'bold', padding: 5 }}>
                                <div style={{ display: 'flex', alignItems: 'center', height: '20%', justifyContent: 'center', fontSize: 'small' }}>DATE</div>
                                <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 'normal', height: '80%', justifyContent: 'center', }}>
                                    {data && date_arr && date_arr.map((item, index) => {
                                        return <div key={item + index} style={{ padding: 1, margin: 1, textAlign: 'center', fontSize: 'smaller' }}>{item}</div>
                                    })}
                                </div>
                            </div>

                            {/* FOOD */}
                            <div style={{ display: 'flex', flexDirection: 'column', width: '50%', height: '100%', fontWeight: 'bold', padding: 5, borderLeft: 'thin dashed rgb(232, 232, 232)' }}>
                                <div style={{ display: 'flex', alignItems: 'center', height: '20%', justifyContent: 'center', fontSize: 'small' }}>FOOD</div>
                                <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 'normal', height: '80%', justifyContent: 'center', }}>
                                    {data && food ? <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center', fontSize: 'smaller' }}>{food}</div> : <div />}
                                </div>
                            </div>
                        </div>

                        {/* ADDRESS */}
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '60%', fontWeight: 'bold', padding: 5, paddingTop: 10, borderTop: 'thin dashed rgb(232, 232, 232)', }}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '20%', justifyContent: 'center', fontSize: 'small' }}>ADDRESS</div>
                            <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 'normal', height: '80%', justifyContent: 'center', }}>
                                {data && address ? <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center', fontSize: 'smaller' }}>{address}</div> : <div />}
                            </div>
                        </div>
                    </div>

                </div>
            }

            const itinerary_mobile = (ht) => {
                /* ITINERARY */
                return <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: ht }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', padding: '20px 10px 10px 10px', alignItems: 'center' }}>
                        {data && itinerary ? <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', fontSize: 'small', overflowY: 'auto', overflowX: 'hidden', width: '95%' }}>{itinerary}</div> : <div />}
                    </div>
                </div>
            }

            return (
                <div style={{ height: root_height, width: root_width, padding: 5, display: 'flex', flexDirection: 'column', }}>
                    {profileimg_mobile()}
                    {maininfo_mobile(270)}
                    {pricing}
                    {itinerary_mobile(270)}
                    {dashedline}
                    {tellus_qn}
                    {btns}
                </div>
            )
        }
        else if (device === 'WEB' || device === 'TABLET') {

            const profileimg_web = (
                <img alt={IMG_ALT} src={profileimg_id ? profileimg_id.link : EMPTY} width={200} height={200} />
            )

            const titleAndLocation_web = (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {/* TITLE */}
                    <div style={{ display: 'flex', padding: '3px 0px 0px 0px', borderBottom: 'thin solid #707070', width: 'fit-content', fontWeight: 'bold', fontSize: 'large' }}>{title}</div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 5, }}>
                        {/* LOCATION and HOSTTYPE*/}
                        <div style={{ display: 'flex', width: 'fit-content', }}>{location + ', ' + hosttype}</div>
                    </div>
                </div>
            )

            const maininfo_web = (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>

                    {/* TITLE AND LOCATION  */}
                    {titleAndLocation_web}

                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>

                        {/* DATES */}
                        <div style={{ display: 'flex', flexDirection: 'column', width: '30%', height: '100%', fontWeight: 'bold', padding: 10 }}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '20%', justifyContent: 'center' }}>DATE</div>
                            <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 'normal', height: '80%', justifyContent: 'center', }}>
                                {date_arr && date_arr.map((item, index) => {
                                    return <div key={item + index} style={{ padding: 1, margin: 1, textAlign: 'center' }}>{item}</div>
                                })}
                            </div>
                        </div>

                        {/* ADDRESS */}
                        <div style={{ display: 'flex', flexDirection: 'column', width: '40%', height: '100%', fontWeight: 'bold', padding: 10, borderRight: 'thin dashed rgb(232, 232, 232)', borderLeft: 'thin dashed rgb(232, 232, 232)' }}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '20%', justifyContent: 'center' }}>ADDRESS</div>
                            <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 'normal', height: '80%', justifyContent: 'center', }}>
                                {address ? <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>{address}</div> : <div />}
                            </div>
                        </div>

                        {/* FOOD */}
                        <div style={{ display: 'flex', flexDirection: 'column', width: '30%', height: '100%', fontWeight: 'bold', padding: 10 }}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '20%', justifyContent: 'center' }}>FOOD</div>
                            <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 'normal', height: '80%', justifyContent: 'center', }}>
                                {food ? <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>{food}</div> : <div />}
                            </div>
                        </div>

                    </div>

                </div>
            )

            const itinerary_web = (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }} >
                    {/* ITINERARY */}
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', padding: '20px 10px 10px 10px', alignItems: 'center' }}>
                            {itinerary ? <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '95%' }}>{itinerary}</div> : <div />}
                        </div>
                    </div>
                </div>
            )

            return (
                <div style={{ height: 'max-content', width: root_width, padding: 5 }}>
                    {profileimg_web}
                    {maininfo_web}
                    {dashedline}
                    {pricing}
                    {dashedline}
                    {itinerary_web}
                    {dashedline}
                    {tellus_qn}
                    {btns}
                </div>
            )
        }

    }


}

export default UserHomeHostReq

