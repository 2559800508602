import React from 'react'
import './Highlighttitle.css'

const vb_ = "0 0 1024 1024"
const v_ = "1.1"
const x_ = "http://www.w3.org/2000/svg"

function Highlighttitle(props) {
    const { icon, onClick, selectedsubpage } = props
    return <div className="Highlighttitle_root" onClick={onClick} >
        <div className="Highlighttitle_itemroot" >
            <div className='Highlighttitle_iconandname'>
                {icon}
                <div className='Highlighttitle_txt'>{selectedsubpage}</div>
            </div>
            <svg className='Highlighttitle_back' viewBox={vb_} version={v_} xmlns={x_}><path d="M811.488 424.512Q722.976 336 597.984 336h-336l114.016-114.016q12-12 12-28.512t-12-28-28.512-11.488-28.512 12l-183.008 183.008q-12 12-12 28.512t12 28.512l178.016 178.016q12 12 28.512 12t28-11.488 11.488-28-11.008-28.512l-112.992-112h340q92 0 156.992 64.992t64.992 156.992v192q0 16.992 12 28.512t28.512 11.488 28-11.488 11.488-28.512v-192q0-124.992-88.512-213.504z" p-id="38505"></path></svg>
        </div>
    </div>
}

export default Highlighttitle