import { rocketlaunch, } from '../assets/blog'//  onlineshopping, propose 
import Blog1 from './Blog1/Blog1'
// import Blog2 from './Blog2/Blog2'
// import Blog3 from './Blog3/Blog3'
// import Blog4 from './Blog4/Blog4'
import Beyond1 from './Beyond1/Beyond1'
import Beyond2 from './Beyond2/Beyond2'
import { STARTUP_NAME } from '../config/constants'

export const BLOG_KEY = [] //2, 3, 4
export const BLOG_KEYNAME_MAP = { 'cereweavespecial': 1 } //, 'covidlifedelay': 2, 'whycereweave': 3, 'calculate': 4
export const BLOGCONTENT = {
    1: {
        img: rocketlaunch,
        imgtype: 'svg',
        title: `${STARTUP_NAME} & Your Special Day`,
        subtitle: 'Plan and organise your perfect wedding and event.',
        bgcolor: 'rgba(251, 225, 229, 0.52)',
        // date: new Date('10 January 2020'),
        url: 'cereweavespecial',
        content: Blog1()
    },
    // 2: {
    //     img: onlineshopping,
    //     imgtype: 'svg',
    //     title: 'Covid19 & Wedding',
    //     subtitle: '"How does it affect you now and after?"',
    //     bgcolor: 'rgba(251, 225, 229, 0.52)',
    //     date: new Date('11 January 2020'),
    //     url: 'covidlifedelay',
    //     content: Blog2()
    // },
    // 3: {
    //     img: propose,
    //     imgtype: 'svg',
    //     title: `Wedding planning with ${STARTUP_NAME}`,
    //     subtitle: 'Plan your wedding with us',
    //     bgcolor: 'rgba(251, 225, 229, 0.52)',
    //     date: new Date('15 January 2020'),
    //     url: 'whycereweave',
    //     content: Blog3()
    // },
    // 4: {
    //     img: '🦠 + 👰+ 🧮',
    //     imgtype: 'string',
    //     title: 'Covid19 wedding calculator',
    //     subtitle: 'Predict',
    //     bgcolor: 'rgba(251, 225, 229, 0.52)',
    //     date: new Date('20 January 2020'),
    //     url: 'calculate',
    //     content: Blog4()
    // },
}



export const BEYOND_KEY = []
export const BEYOND_KEYNAME_MAP = { 'coronavirus': 1, 'unichoices': 2 }
export const BEYONDCONTENT = {
    1: {
        img: false,
        title: 'COVID-19 Data Visualisation ',
        subtitle: 'SG',
        bgcolor: 'rgba(251, 225, 229, 0.52)s',
        date: new Date('1 Feburary 2020'),
        url: 'coronavirus',
        content: Beyond1()
    },
    2: {
        img: false,
        title: 'Uni Choices',
        subtitle: 'GPA/IGP',
        bgcolor: 'rgba(251, 225, 229, 0.52)s',
        date: new Date('1 Feburary 2020'),
        url: 'unichoices',
        content: Beyond2()
    }

}