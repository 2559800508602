import {

    FETCH_PROJECT_DETAIL_ACTION_FULFILLED,
    FETCH_PROJECT_DETAIL_ACTION_PENDING,
    FETCH_PROJECT_DETAIL_ACTION_REJECTED,

    FETCH_PROJECT_DETAIL_LOGGED_ACTION_FULFILLED,
    FETCH_PROJECT_DETAIL_LOGGED_ACTION_PENDING,
    FETCH_PROJECT_DETAIL_LOGGED_ACTION_REJECTED

} from '../actions/projectlookup.action'

const initialState = {
    projectlookup_data: [],
    isContentLoading: false,
    isContentLoaded: false,
    savebtn:false,
}

//rmb to unwrap to the point where the basic data structure expose
export default (state = initialState, action) => {
    switch (action.type) {
       
        case FETCH_PROJECT_DETAIL_ACTION_FULFILLED:
            return {
                ...state,
                isContentLoading: false,
                isContentLoaded: true,
                projectlookup_data: action.payload.data.data,
                savebtn:false
            }
        case FETCH_PROJECT_DETAIL_ACTION_PENDING:
            return {
                ...state,
                isContentLoading: true,
                isContentLoaded: false,
            }
        case FETCH_PROJECT_DETAIL_ACTION_REJECTED:
            return {
                ...state,
                isContentLoading: false,
                isContentLoaded: false,
            }




        case FETCH_PROJECT_DETAIL_LOGGED_ACTION_FULFILLED:
            return {
                ...state,
                isContentLoading: false,
                isContentLoaded: true,
                projectlookup_data: action.payload.data.data,
                savebtn: action.payload.data.savebtn
            }
        case FETCH_PROJECT_DETAIL_LOGGED_ACTION_PENDING:
            return {
                ...state,
                isContentLoading: true,
                isContentLoaded: false,
            }
        case FETCH_PROJECT_DETAIL_LOGGED_ACTION_REJECTED:
            return {
                ...state,
                isContentLoading: false,
                isContentLoaded: false,
            }





        default:
            return state


    }
}