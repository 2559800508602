import React, { useEffect, useRef, useState } from 'react'
import { USER_READ_PROFILE, USER_UPDATE_PERSONAL, USER_UPDATE_PERSONAL_PROFILEIMG, USER_CHANGEPASSWORD_UPDATE, USER_RESEND_VERIFIC, USER_SEND_VERIFIC } from '../../config/api'
import { Retrieve_personal_info, getS3fullpath_withbucketname } from '../../config/commonfunctions'
import { Checkdevice, Headtitle, refresh } from '../../components'
import { FORM_ERROR_STYLE, FORM_SECOND, PLUS_ICON, PRESENCE_LOCATION, SUCCESS_UPDATE, FAILURE_UPDATE, INCORRECTFILETYPE_UPDATE, NOSUCHUSER_UPDATE, eventgate } from '../../config/constants'
import { Validateform } from './components/Formvalidate/Formvalidate'
import { Imagedownsize } from '../../components/Imagedownsize/Imagedownsize'
import DatePicker from "react-datepicker";
import axios from 'axios'
import "react-datepicker/dist/react-datepicker.css";
import './Up.css'

var CustomDatepicker = React.forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" ref={ref} onClick={onClick} style={{ padding: 10, display: 'flex', width: '100%', color: 'var(--maindark)', alignItems: 'center', borderRadius: 5, backgroundColor: 'white', border: '1px solid #d9d8d8', outline: 'none', marginBottom: 15, minHeight: 42 }}>
        {value}
    </button>
));

function checkdt(dt) {
    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }
    var finaldt
    try {
        if (isValidDate(dt) || isValidDate(new Date(dt))) finaldt = new Date(dt)
        else finaldt = ''
    }
    catch (e) { finaldt = '' }
    return finaldt
}

function UserProfile() {
    const { token, _id } = Retrieve_personal_info()
    const [name, setName] = useState('')
    const [spousename, setSpousename] = useState('')
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [contact, setContact] = useState('')
    const [profileimg, setProfileimg] = useState('')
    const [verify, setVerify] = useState(0)
    const [location, setLocation] = useState('')
    const [authprovider, setAuthprovider] = useState('')
    const [date_w, setDate_w] = useState('')
    const [date_e, setDate_e] = useState('')
    const [usertype, setUsertype] = useState('')
    const [level, setLevel] = useState(0)
    const [msg, setMsg] = useState('')
    const [profilepicmsg, setProfilepicmsg] = useState('')
    const [oldpw, setOldpw] = useState('')
    const [newpw1, setNewpw1] = useState('')
    const [newpw2, setNewpw2] = useState('')
    const [pwmsg, setPwmsg] = useState('')
    const [verifymsg, setVerifymsg] = useState('')
    const device = Checkdevice()
    const imginputref = useRef(null)

    const init = () => {
        try {
            axios({
                method: 'POST',
                url: USER_READ_PROFILE,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { _id: _id }
            }).then((response) => {
                const { personal_data } = response.data
                const { status } = response
                if (status === 200) {
                    const { name, spousename, email, username, contact, profileimg, verify, location, authprovider, date_w, date_e, usertype, level } = personal_data
                    setName(name)
                    setSpousename(spousename)
                    setEmail(email)
                    setUsername(username)
                    setContact(contact)
                    setProfileimg(profileimg)
                    setVerify(verify)
                    setLocation(location)
                    setAuthprovider(authprovider)
                    setDate_w(checkdt(date_w))
                    setDate_e(checkdt(date_e))
                    setUsertype(usertype)
                    setLevel(level)
                }
                else refresh()
            })
        }
        catch (e) { refresh() }
    }

    useState(() => { init() }, [])
    useEffect(() => { setTimeout(() => { setProfilepicmsg('') }, [FORM_SECOND]) }, [profilepicmsg])
    useEffect(() => { setTimeout(() => { setPwmsg('') }, [FORM_SECOND]) }, [pwmsg])
    useEffect(() => { setTimeout(() => { setPwmsg('') }, [FORM_SECOND]) }, [pwmsg])

    const updatename = (e) => { setName(e.target.value) }
    const updatespousename = (e) => { setSpousename(e.target.value) }
    const updatecontact = (e) => { setContact(e.target.value) }
    const updatelocation = (e) => { updatelocation(e.target.value) }
    const updatecat = (e) => {
        setUsertype(e.target.value)
    }
    const updatewdate = (e) => { setDate_w(e) }
    const updateedate = (e) => { setDate_e(e) }
    const updateimg = () => { imginputref.current.click() }
    const uploadprofileimg = (e) => {
        try {
            var reader = new FileReader()
            let file = e.target.files[0]
            reader.onload = (e) => {
                var img = document.createElement("img");
                img.onload = () => {
                    var result = Imagedownsize(img, 'logo')
                    var newData = new FormData()
                    newData.append('file', result[1])
                    let options = {
                        method: 'POST',
                        url: USER_UPDATE_PERSONAL_PROFILEIMG,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'multipart/form-data' }, //need token from the web
                        data: newData
                    }
                    axios(options).then((result) => {
                        const { data, status } = result
                        const { message, loc } = data
                        if (status === 200) {
                            if (message === 'SUCCESS') {
                                setProfilepicmsg(SUCCESS_UPDATE);
                                setProfileimg(loc)
                            }
                            else if (message === 'NOSUCHUSER') { setProfilepicmsg(NOSUCHUSER_UPDATE) }
                            else if (message === 'INCORRECTFILETYPE') { setProfilepicmsg(INCORRECTFILETYPE_UPDATE) }
                        }
                        else {
                            setProfilepicmsg(FAILURE_UPDATE)
                        }
                    }).catch(e => { refresh() })
                }
                img.src = e.target.result;
            }
            reader.readAsDataURL(file);
        }
        catch (e) { }
    }
    const updateinfo = () => {
        var localError = []
        var gotError_validate = () => {//checks
            var gotError = false
            if (Validateform({ form_key: 'name', form_parts: { name } })) {
                localError.push(<div key='name_error' style={FORM_ERROR_STYLE}>Error : Name field</div>)
                gotError = true
            }
            if (Validateform({ form_key: 'spousename', form_parts: { spousename } })) {
                localError.push(<div key='spousename_error' style={FORM_ERROR_STYLE}>Error : Spouse's Name field</div>)
                gotError = true
            }
            if (Validateform({ form_key: 'contact', form_parts: { contact } })) {
                localError.push(<div key='contact_error' style={FORM_ERROR_STYLE}>Error : Contact field</div>)
                gotError = true
            }
            if (Validateform({ form_key: 'location', form_parts: { location } })) {
                localError.push(<div key='location_error' style={FORM_ERROR_STYLE}>Error : Location field</div>)
                gotError = true
            }
            return gotError
        }

        if (!gotError_validate()) {
            let options = {
                method: 'POST',
                url: USER_UPDATE_PERSONAL,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { name, spousename, contact, location, _id, date_w, date_e, usertype }
            }
            axios(options).then((result) => {
                const { data, status } = result
                const { error } = data// message, token, _id 
                if (error === false && status === 200) {
                    setMsg(SUCCESS_UPDATE)
                    init()
                    setTimeout(() => {
                        setMsg('')
                        setOldpw('')
                        setNewpw1('')
                        setNewpw2('')
                    }, FORM_SECOND)
                }
                else {
                    setMsg(FAILURE_UPDATE)
                }
            })
                .catch(e => {
                    setMsg(FAILURE_UPDATE)
                })

        }
        else {
            this.setState({ message: localError })
        }
    }
    const updateoldpw = (e) => { setOldpw(e.target.value) }
    const updatepw1 = (e) => { setNewpw1(e.target.value) }
    const updatepw2 = (e) => { setNewpw2(e.target.value) }
    const updatepw = (e) => {
        //checks
        var gotError_validate = () => {
            var gotError = false
            if (Validateform({ form_key: 'oldpw', form_parts: { oldpw } })) {
                setPwmsg(FAILURE_UPDATE)
                gotError = true
            }
            else if (Validateform({ form_key: 'newpw', form_parts: { newpw1, newpw2 } })) {
                setPwmsg(FAILURE_UPDATE)
                gotError = true
            }
            return gotError
        }
        if (!gotError_validate()) {
            let options = {
                method: 'POST',
                url: USER_CHANGEPASSWORD_UPDATE,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }, //need token from the web
                data: { _id: _id, item: { provider: 'emailpw', oldpw, newpw1, newpw2 } }
            }
            axios(options).then((response) => {
                const { status } = response
                const { error } = response.data// message, data
                if (error === false && status === 200) {
                    setPwmsg(SUCCESS_UPDATE)
                    setOldpw('')
                    setNewpw1('')
                    setNewpw2('')
                    setTimeout(() => { setPwmsg('') }, FORM_SECOND)
                }
                else {
                    setPwmsg(FAILURE_UPDATE)
                }
            }).catch(e => {
                const { status } = e.response
                setPwmsg(FAILURE_UPDATE)
                if (status === 401) refresh()
            });

        }
    }
    const resend = () => {
        let options = {
            method: 'POST',
            url: USER_RESEND_VERIFIC,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
            data: { _id }
        }
        axios(options).then((result) => {
            const { status } = result
            const { error } = result.data
            if (error === false && status === 200) {
                setVerify(1);
                setVerifymsg('Successfully Send.')
                setTimeout(() => { setVerifymsg('') }, [FORM_SECOND])
            }
        }).catch(e => { refresh() })
    }
    const verifyemail = () => {
        let options = {
            method: 'POST',
            url: USER_SEND_VERIFIC,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
            data: { _id }
        }
        axios(options).then((result) => {
            const { status } = result
            const { error } = result.data
            if (error === false && status === 200) {
                setVerify(1)
                setVerifymsg('Successfully Send.')
                setTimeout(() => { setVerifymsg('') }, [FORM_SECOND])
            }
        }).catch(e => { refresh() })
    }
    return <div className="Up_root">
        <Headtitle name="Profile" color="var(--maindark)" />
        <div className={`container Up_subroot${device}`}>
            <div className="Up_containertop">
                <label>Profile Image</label>
                <div className="Up_profileimgexplain">.png or .jpg format only</div>
                <div className="Up_imgcontainer">
                    {/* <img className="Up_imgdiv" src={profileimg} alt="" width={150} height={150} /> */}
                    {/* <img className="Up_imgdiv" src={ getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG',selectedimg_extlinks[0]) } alt="" width={150} height={150} /> */}
                    <img className="Up_imgdiv" src={getS3fullpath_withbucketname('USER_PROFILE_IMG', profileimg)} alt="" width={150} height={150} />
                    {/* <button className="Up_plusicon" onClick={updateimg}>{PLUS_ICON}</button> */}
                    <button className="Up_plusicon" onClick={updateimg}>{PLUS_ICON}</button>
                </div>
                <input
                    className="Up_imginputroot"
                    accept="image/*"
                    type='file'
                    ref={imginputref}
                    onChange={uploadprofileimg}
                />
                <div>{profilepicmsg}</div>
            </div>
            <div className="Up_containermid">
                <label>Email</label>
                <div className="Up_uneditablediv">{email}</div>
                <label>Username</label>
                <div className="Up_uneditablediv">{username}</div>
                <label>Level</label>
                <div className="Up_uneditablediv">{level}</div>
                <label>Your Name</label>
                <input className="Up_editablediv" onChange={updatename} value={name} />
                <label>Spouse Name</label>
                <input className="Up_editablediv" onChange={updatespousename} value={spousename} />
                <label>Contact</label>
                <input className="Up_editablediv" onChange={updatecontact} value={contact} />
                <label>Region/Location</label>
                <select className="Up_editablediv" onChange={updatelocation}>
                    {PRESENCE_LOCATION.map((val, index) => { return <option key={val + index} value={val} >{val}</option> })}
                </select>
                <label>Category</label>
                <select className="Up_editablediv" onChange={updatecat}>
                    <option>Wedding</option>
                    {eventgate?<option>Event</option> : null}
                </select>
                <label>Wedding Date</label>
                <DatePicker dateFormat={'dd/MM/yyyy'} withPortal fixedHeight selected={date_w} onChange={updatewdate} customInput={<CustomDatepicker />} />
                <label>Event Date</label>
                <DatePicker dateFormat={'dd/MM/yyyy'} withPortal fixedHeight selected={date_e} onChange={updateedate} customInput={<CustomDatepicker />} />
                <div className="Up_msgroot">{msg}</div>
                <div className="Up_btnroot">
                    <button className="Up_btn" onClick={updateinfo}>Update</button>
                </div>
            </div>
            <div className="Up_containermid">
                <label>Old Password</label>
                <input className="Up_editablediv" type={'password'} onChange={updateoldpw} value={oldpw} placeholder="Old Password" />
                <label>New Password</label>
                <input className="Up_editablediv" type={'password'} onChange={updatepw1} value={newpw1} placeholder="New Password" />
                <input className="Up_editablediv" type={'password'} onChange={updatepw2} value={newpw2} placeholder="New Password" />
                <div className="Up_msgroot">{pwmsg}</div>
                <div className="Up_btnroot">
                    <button className="Up_btn" onClick={updatepw}>Change Password</button>
                </div>
            </div>
            <div className="Up_containerbtm">
                {verify === 0 && authprovider === 'emailpw'
                    ? <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <label>Email Verification</label>
                        <button className="Up_editablediv" onClick={verifyemail} >Send Verification Email</button>
                        <div className="Up_msgroot">{verifymsg}</div>
                    </div>
                    : null}
                {verify === 1 && authprovider === 'emailpw'
                    ? <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <label>Email Verification</label>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className="Up_div">Verifying email has been send, please check your email</div>
                            <button className="Up_btn" onClick={resend}>Resend</button>
                        </div>
                        <div className="Up_msgroot">{verifymsg}</div>
                    </div>
                    : null}
                {verify === 2 && authprovider === 'emailpw'
                    ? <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <label>Email Verification</label>
                        <button disabled={true} className="Up_btn" style={{ color: 'var(--firmgreen)', fontWeight: 'bold' }} >Verified</button>
                    </div>
                    : null}

            </div>
        </div>
    </div>
}

export default UserProfile