
import React, { useState, useEffect, useRef } from 'react'
import ContentEditable from 'react-contenteditable'
import { T0_TABLETEMPLATEFORMAT_CSS } from '../../constants' //  eslint-disable-line
import './Classictemplate0.css'

const maxwt = 750.47065
const maxht = 1061.31268

const currentDate = new Date();

// Get the timestamp in milliseconds
const timestamp = currentDate.getTime();

const bannertimestamp = '?' + timestamp + Math.random()
const mainimgtimestamp = '?' + timestamp + Math.random()

function Classictemplate0(props) {

    const { downloadmodal, downloadtransparent, openEdittools, closeEdittools, updateShowingff, updateShowingfs, updateShowingpadding, updateSelectedpart, updateSidebar, paperfocus, updatePaperfocus, updateAlltablenames, updateAllseatnames, updateSelectedtableindex } = props

    const [firsttime, setFirsttime] = useState(true)
    const [defaulttop, setDefaulttop] = useState('')
    const [defaultmain, setDefaultmain] = useState('')
    const [topcss, setTopcss] = useState({})
    const [maincss, setMaincss] = useState({})
    const [banner, setBanner] = useState('')
    const [cornerimg, setCornerimg] = useState('')
    const [alltablenames, setAlltablenames] = useState([])
    const [alltablenamescss, setAlltablenamescss] = useState([])
    const [allseatnames, setAllseatnames] = useState([])
    const [allseatnamescss, setAllseatnamescss] = useState([])
    const [templatecolor, setTemplatecolor] = useState(null)
    const [downloadchart, setDownloadchart] = useState(false)
    const [floralplacement, setFloralplacement] = useState(-1)
    const [onesidedimg, setOnesidedimg] = useState('')
    const [jccanva, setJccanva] = useState('none')
    const [aligncanva, setAligncanva] = useState('none')

    const [gridtables, setGridtables] = useState([])
    const [hovermodal, setHovermodal] = useState(-1)

    useEffect(() => {
        if (firsttime && props) {
            setFirsttime(false)
            const { data, downloadchart } = props //tableobjs, guests, toggleEdittools, updateShowingff, updateShowingfs,
            const { defaulttop, defaultmain, topcss, maincss, banner, cornerimg, onesidedimg, alltablenames, alltablenamescss, allseatnames, allseatnamescss, totaltables, templatecolor, floralplacement, aligncanva, jccanva } = data //  tablecss, tableformat,

            setDefaulttop(defaulttop)
            setDefaultmain(defaultmain)
            setTopcss(topcss)
            setMaincss(maincss)
            setBanner(banner)
            setCornerimg(cornerimg)
            setOnesidedimg(onesidedimg)

            setAlltablenames(alltablenames)
            setAlltablenamescss(alltablenamescss)
            setAllseatnames(allseatnames)
            setAllseatnamescss(allseatnamescss)

            setTemplatecolor(templatecolor)
            setDownloadchart(downloadchart)
            setFloralplacement(floralplacement)

            var temp_gridtables = []
            for (let i = 0; i < totaltables; i++) {
                temp_gridtables.push([])
            }
            setGridtables(temp_gridtables)

            setJccanva(jccanva)
            setAligncanva(aligncanva)
        }

        if (!firsttime) {
            const { data, downloadchart } = props
            const { topcss, maincss, cornerimg, banner, onesidedimg, alltablenames, alltablenamescss, allseatnames, allseatnamescss, templatecolor, floralplacement, aligncanva, jccanva } = data
            setTopcss(topcss)
            setMaincss(maincss)
            setCornerimg(cornerimg)
            setBanner(banner)
            setAlltablenames(alltablenames)
            setAlltablenamescss(alltablenamescss)
            setAllseatnames(allseatnames)
            setAllseatnamescss(allseatnamescss)
            setTemplatecolor(templatecolor)
            setDownloadchart(downloadchart)
            setFloralplacement(floralplacement)
            setOnesidedimg(onesidedimg)
            setJccanva(jccanva)
            setAligncanva(aligncanva)
        }

    }, [props])

    useEffect(() => {
        if (paperfocus) {
            closeEdittools()
            updateSidebar('')
            updateShowingff('')
            updateShowingfs('')
            updateSelectedpart('')
            updatePaperfocus()
        }
    }, [paperfocus])

    const tableOnblur = () => {
        closeEdittools()
        updateSidebar('')
        updateShowingff('')
        updateShowingfs('')
        updateSelectedpart('')
        setHovermodal(-1)
    }

    const griditems = (gridtables && gridtables.length > 0) // eslint-disable-line
        ? gridtables.map((gridtable, ti) => {
            return <div
                key={'table' + ti}
                onFocus={() => { setHovermodal(ti) }}
                onBlur={tableOnblur}
                style={{ padding: alltablenamescss[ti]['padding'], display: 'flex', flexDirection: 'column', position: 'relative', border: hovermodal === ti ? '0.5px dashed black' : 'none' }}
            >
                {hovermodal === ti
                    ? <div style={{ position: 'absolute', top: -40, left: -0.5 }} onClick={() => props.updateAssignmodal(true)}>
                        <button className='T0_ASSIGNBTN' onBlur={tableOnblur} onMouseDown={() => props.updateAssignmodal(true, ti)} >
                            Assign
                        </button>
                    </div>
                    : null}

                <div style={{ ...alltablenamescss[ti], display: 'flex', padding: 0 }}>
                    <ContentEditable
                        className={`T0_TABLENAME ${alltablenamescss[ti]['textDecoration'] === 'underline' ? 'T0_UNDERLINE' : ''}`}
                        spellCheck={false}
                        html={alltablenames[ti]} // innerHTML of the editable div
                        onChange={(e) => {
                            var new_alltablenames = alltablenames.slice()
                            new_alltablenames[ti] = e.target.value
                            setAlltablenames(new_alltablenames)
                            updateAlltablenames(e.target.value, ti)
                        }}
                        onFocus={() => {
                            const { fontFamily, fontSize, padding } = alltablenamescss[ti]
                            updateSelectedtableindex(ti)
                            updateSelectedpart('TABLE')
                            updateSidebar('')
                            openEdittools()
                            updateShowingff(fontFamily)
                            updateShowingfs(fontSize)
                            updateShowingpadding(padding)
                        }}
                        onBlur={tableOnblur}
                        onClick={(e) => { e.stopPropagation() }}
                    />
                </div>
                <div style={{ ...allseatnamescss[ti], display: 'flex', padding: 0 }}>
                    <ContentEditable
                        className={`T0_SEATNAME ${allseatnamescss[ti]['textDecoration'] === 'underline' ? 'T0_UNDERLINE' : ''}`}
                        spellCheck={false}
                        html={allseatnames[ti]} // innerHTML of the editable div
                        onChange={(e) => {
                            var new_allseatnames = allseatnames.slice()
                            new_allseatnames[ti] = e.target.value
                            setAllseatnames(new_allseatnames)
                            updateAllseatnames(e.target.value, ti)
                        }}
                        onFocus={(e) => {
                            const { fontFamily, fontSize } = allseatnamescss[ti]
                            const { padding } = alltablenamescss[ti]
                            updateSelectedtableindex(ti)
                            updateSelectedpart('SEATS')
                            updateSidebar('')
                            openEdittools()
                            updateShowingff(fontFamily)
                            updateShowingfs(fontSize)
                            updateShowingpadding(padding)
                        }}
                        onBlur={tableOnblur}
                        onClick={(e) => { e.stopPropagation() }}
                    />
                </div>
            </div>
        })
        : null

    // maxht - toppart (title, name , banner) = 205px
    const onehundredpercent = `calc( ( ${maxht}px - 205px  ) )`
    const fiftypercent = `calc( ( ${maxht}px - 205px  ) / 2 )`
    const thirtythreepercent = `calc( ( ${maxht}px - 205px  ) / 3 )`

    const tableformat_cssprops_map = {
        '3_0': { width: '50%', height: fiftypercent, maxHeight: fiftypercent, minHeight: fiftypercent },
        '3_1': { width: '33.33%', height: onehundredpercent, maxHeight: onehundredpercent, minHeight: onehundredpercent },
        '4_0': { width: '50%', height: fiftypercent, maxHeight: fiftypercent, minHeight: fiftypercent },
        '5_0': { width: '33.33%', height: fiftypercent, maxHeight: fiftypercent, minHeight: fiftypercent },
        '6_0': { width: '33.33%', height: fiftypercent, maxHeight: fiftypercent, minHeight: fiftypercent },
        '7_0': { width: '33.33%', height: thirtythreepercent, maxHeight: thirtythreepercent, minHeight: thirtythreepercent },
        '8_0': { width: '25%', height: fiftypercent, maxHeight: fiftypercent, minHeight: fiftypercent },
        '8_1': { width: '33.33%', height: thirtythreepercent, maxHeight: thirtythreepercent, minHeight: thirtythreepercent },
        '9_0': { width: '33.33%', height: thirtythreepercent, maxHeight: thirtythreepercent, minHeight: thirtythreepercent },
        '9_1': { width: '25%', height: thirtythreepercent, maxHeight: thirtythreepercent, minHeight: thirtythreepercent },
        '10_0': { width: '25%', height: thirtythreepercent, maxHeight: thirtythreepercent, minHeight: thirtythreepercent },
        '11_0': { width: '25%', height: thirtythreepercent, maxHeight: thirtythreepercent, minHeight: thirtythreepercent },
        '12_0': { width: '25%', height: thirtythreepercent, maxHeight: thirtythreepercent, minHeight: thirtythreepercent },
    }

    const rowcolitems = (gridtables && gridtables.length > 0)
        ? gridtables.map((gridtable, ti) => {
            return <div
                key={'table' + ti}
                onFocus={() => { setHovermodal(ti) }}
                onBlur={tableOnblur}
                style={{
                    ...tableformat_cssprops_map[props.data.tableformat],
                    justifyContent: aligncanva,
                    border: hovermodal === ti ? '0.5px dashed black' : 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                }}
            >
                {hovermodal === ti
                    ? <div style={{ position: 'absolute', top: -40, left: -0.5 }} onClick={() => props.updateAssignmodal(true)}>
                        <button className='T0_ASSIGNBTN' onBlur={tableOnblur} onMouseDown={() => props.updateAssignmodal(true, ti)} >
                            Assign
                        </button>
                    </div>
                    : null}

                <div id='alltablename_root' style={{ ...alltablenamescss[ti], display: 'flex', padding: alltablenamescss[ti]['padding'], lineHeight: alltablenamescss[ti]['lineHeight'] }}>
                    <ContentEditable
                        className={`T0_TABLENAME ${alltablenamescss[ti]['textDecoration'] === 'underline' ? 'T0_UNDERLINE' : ''}`}
                        spellCheck={false}
                        html={alltablenames[ti]} // innerHTML of the editable div
                        onChange={(e) => {
                            var new_alltablenames = alltablenames.slice()
                            new_alltablenames[ti] = e.target.value
                            setAlltablenames(new_alltablenames)
                            updateAlltablenames(e.target.value, ti)
                        }}
                        onFocus={() => {
                            const { fontFamily, fontSize, padding } = alltablenamescss[ti]
                            updateSelectedtableindex(ti)
                            updateSelectedpart('TABLE')
                            updateSidebar('')
                            openEdittools()
                            updateShowingff(fontFamily)
                            updateShowingfs(fontSize)
                            updateShowingpadding(padding)
                        }}
                        onBlur={tableOnblur}
                        onClick={(e) => { e.stopPropagation() }}
                    />
                </div>
                <div id='allseatname_root' style={{ ...allseatnamescss[ti], display: 'flex', padding: allseatnamescss[ti]['padding'], lineHeight: allseatnamescss[ti]['lineHeight'] }}>
                    <ContentEditable
                        className={`T0_SEATNAME ${allseatnamescss[ti]['textDecoration'] === 'underline' ? 'T0_UNDERLINE' : ''}`}
                        spellCheck={false}
                        html={allseatnames[ti]} // innerHTML of the editable div
                        onChange={(e) => {
                            var new_allseatnames = allseatnames.slice()
                            new_allseatnames[ti] = e.target.value
                            setAllseatnames(new_allseatnames)
                            updateAllseatnames(e.target.value, ti)
                        }}
                        onFocus={(e) => {
                            const { fontFamily, fontSize, padding } = allseatnamescss[ti]
                            updateSelectedtableindex(ti)
                            updateSelectedpart('SEATS')
                            updateSidebar('')
                            openEdittools()
                            updateShowingff(fontFamily)
                            updateShowingfs(fontSize)
                            updateShowingpadding(padding)
                        }}
                        onBlur={tableOnblur}
                        onClick={(e) => { e.stopPropagation() }}
                    />
                </div>
            </div>
        })
        : null

    const c1ref = useRef()

    return <div
        id="shiny"
        key='template0'
        style={{
            transform: downloadchart ? `scale(1)` : `scale(${props.zoom})`,
            width: maxwt, height: maxht,

            backgroundColor: downloadtransparent ? 'transparent' : templatecolor,
            boxShadow : downloadmodal? 'none' : '0 2px 8px #0e131812',

            display: 'flex',
            flexDirection: 'column',
            margin: '0px auto',
            overflow: 'hidden',
            position: 'relative',
            // boxShadow: '0 1px 5px #c7c7c7',
            // border: '1px solid #c7c7c7',
            // marginLeft:downloadchart ?2400:0,
            // marginTop:downloadchart ?2960:0,
        }}>
        <div className={floralplacement === 0 ? 'T0_TOPMAINCONTENT' : 'TO_TOPMAINCONTENT1'}>
            <ContentEditable
                key={'topcontenteditable'}
                spellCheck={false}
                className={`T0_TOP_ROOT ${topcss['textDecoration'] === 'underline' ? 'T0_UNDERLINE' : ''}`}
                style={{
                    fontStyle: topcss['fontStyle'],
                    fontSize: topcss['fontSize'],
                    fontFamily: topcss['fontFamily'],
                    fontWeight: topcss['fontWeight'],
                    alignItems: topcss['textAlign'],
                    placeContent: topcss['placeContent'],
                    padding: topcss['padding'],
                    lineHeight: topcss['lineHeight'],
                    color: topcss['color'],
                    // lineHeight: topcss['lineHeight']+'em'
                }}
                html={defaulttop} // innerHTML of the editable div
                disabled={false} // use true to disable edition
                onChange={(e) => {
                    setDefaulttop(e.target.value)
                    props.updateTop(e.target.value)
                }}
                onFocus={() => {
                    const { fontFamily, fontSize } = topcss
                    updateSidebar('')
                    openEdittools()
                    updateShowingff(fontFamily)
                    updateShowingfs(fontSize)
                    updateSelectedpart('TOP')
                }}
                onBlur={tableOnblur}
                onClick={(e) => { e.stopPropagation() }}
            />

            <ContentEditable
                ref={c1ref}
                key={'maincontenteditable'}
                spellCheck={false}
                className={`T0_MAIN_ROOT ${maincss['textDecoration'] === 'underline' ? 'T0_UNDERLINE' : ''}`}
                style={{
                    fontStyle: maincss['fontStyle'],
                    fontSize: maincss['fontSize'],
                    fontFamily: maincss['fontFamily'],
                    fontWeight: maincss['fontWeight'],
                    alignItems: maincss['textAlign'],
                    placeContent: maincss['placeContent'],
                    textDecoration: maincss['textDecoration'],
                    padding: maincss['padding'],
                    color: maincss['color'],
                    // lineHeight: maincss['lineHeight']+'em'

                }}
                html={defaultmain} // innerHTML of the editable div
                disabled={false} // use true to disable edition
                onChange={(e) => {
                    const val = e.currentTarget.textContent;
                    setDefaultmain(val)
                    props.updateMain(val)
                }}
                onFocus={() => {
                    const { fontFamily, fontSize } = maincss
                    updateSidebar('')
                    openEdittools()
                    updateShowingff(fontFamily)
                    updateShowingfs(fontSize)
                    updateSelectedpart('MAIN')
                }}
                onBlur={tableOnblur}
                onClick={(e) => { e.stopPropagation() }}
            />
        </div>

        {floralplacement === 0 ? <img className='T0_TOP_BANNERIMGFP0' alt="" src={banner + (downloadmodal ? '?time=' + new Date().valueOf() : '')} onMouseDown={() => updateSidebar('ASSET', 0)} /> : null}
        {floralplacement === 0 ? <img className='T0_TOP_CORNERFP0 T0_TOP_LEFTFP0' alt="" onMouseDown={() => updateSidebar('ASSET', 0)} src={cornerimg + (downloadmodal ? '?time=' + new Date().valueOf() : '')} /> : null}
        {floralplacement === 0 ? <img className='T0_TOP_CORNERFP0 T0_TOP_RIGHTFP0' alt="" onMouseDown={() => updateSidebar('ASSET', 0)} src={cornerimg + (downloadmodal ? '?time=' + new Date().valueOf() : '')} /> : null}

        {floralplacement === 1 ? <img className='T0_TOP_RGHTP1' alt="" onMouseDown={() => updateSidebar('ASSET', 1)} src={onesidedimg + (downloadmodal ? '?time=' + new Date().valueOf() : '')} /> : null}
        {floralplacement === 1 ? <img className='T0_TOP_RGHTP11' alt="" onMouseDown={() => updateSidebar('ASSET', 1)} src={onesidedimg + (downloadmodal ? '?time=' + new Date().valueOf() : '')} /> : null}
        {floralplacement === 1 ? <img className='T0_TOP_RGHTP111' alt="" onMouseDown={() => updateSidebar('ASSET', 1)} src={onesidedimg + (downloadmodal ? '?time=' + new Date().valueOf() : '')} /> : null}
       
        {/* 

            {floralplacement === 1 ? <img className='T0_TOP_RGHTP1111' alt="" onMouseDown={() => updateSidebar('ASSET', 1)} src={onesidedimg} /> : null}

        */}

        {/* 

            <div style={T0_TABLETEMPLATEFORMAT_CSS[props.data.tableformat]}>
                {griditems}
            </div>

        */}

        <div key={props.jccanva} style={{ display: 'flex', flexFlow: 'row wrap', height: '100%', placeContent: jccanva, alignItems: aligncanva }}>
            {rowcolitems}
        </div>

    </div>
}

export default Classictemplate0