import React, { useEffect, useState, useRef } from 'react'
import { Loading, ScreenHeight, Checkdevice, Nohori, useRecorder } from '../../components' // ScreenWidth
import { ADDMSG_GB, ADDMSG_GBSINGLEMEDIA, ADDMSG_GBTWOMEDIA, ADDMSG_GBINIT } from '../../config/api'
import { CLOSE_ICON, ENGLISH_FF, GB_LOADING_TIME, QUESTIONMARK_SMALL, emojiPattern } from "../../config/constants"; //HEART_LARGE, 
import Compressor from 'compressorjs';
import axios from 'axios'
import twemoji from 'twemoji'
import Page from './components/Page/Page';
import './Guestbook.css'

const MAXEMOJI = 15
const MINNAME = 2
const MAXNAME = 20
const MINMSG = 15
const MAXMSG = 300

const NAME_TOO_LONG = 'Your name is too long.'
const NAME_TOO_SHORT = 'Your name is too short.'
const MSG_TOO_LONG = 'Your message is too long.'
const MSG_TOO_SHORT = 'Your message is too short.'
const EMOJI_TOO_LONG = 'Exceeding the max limit number of emoji.'

let emoRegex = new RegExp(emojiPattern, "g")
const FIFTEENSEC = 15000
const ONESEC = 1000
const DEVICE_WT_MAP = {
    'WEB': 700,
    'TABLET': 700,
    'MOBILE': '100%'
}

function Guestbook(props) {

    const [guestview, setGuestview] = useState('') // FORM || PAGE
    const [name, setName] = useState('')
    const [nameerr, setNameerr] = useState('')
    const [ff, setFf] = useState('')
    const [msg, setMsg] = useState('')
    const [msgerr, setMsgerr] = useState('')
    const [img, setImg] = useState(null)
    const [imgtype, setImgtype] = useState('')
    const [ps, setPs] = useState('LOADING')
    const [submitblock, setSendblock] = useState(false)
    const [audioblobfile, setAudioblobfile] = useState(null)
    const [imgblobfile, setImgblobfile] = useState(null)
    const [recordgate, setRecordgate] = useState(false)
    const [recordsec, setRecordsec] = useState(0)
    const [emojitxt, setEmojitxt] = useState('')
    const [emojidomarr, setEmojidomarr] = useState([])
    const [emojiascii, setEmojiascii] = useState('') // store emoji to be uploaded to backend
    const [emojimodal, setEmojimodal] = useState(false)
    const imgref = useRef(null)
    const ht = ScreenHeight()
    const device = Checkdevice()
    let [audioURL, isRecording, startRecording, stopRecording, clearRecording] = useRecorder();

    const isLandscape = () => window.matchMedia('(orientation:landscape)').matches,
        [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait'),
        onWindowResize = () => {
            clearTimeout(window.resizeLag)
            window.resizeLag = setTimeout(() => {
                delete window.resizeLag
                setOrientation(isLandscape() ? 'landscape' : 'portrait')
            }, 200)
        }

    useEffect(() => {
        props.hidetopbar(true)

        onWindowResize(),// eslint-disable-line
            window.addEventListener('resize', onWindowResize),
            () => window.removeEventListener('resize', onWindowResize)

        if (ps === 'LOADING') {
            const { urlhandler, passcode } = props.match.params
            let options = {
                method: 'POST',
                url: ADDMSG_GBINIT,
                data: { urlhandler, passcode }
            }
            axios(options).then((res) => {
                const { status, data } = res
                const { message, guestview } = data
                if (status === 200 && message === 'SUCCESS') {
                    setGuestview(guestview)
                    setTimeout(() => setPs('CONTENT'), GB_LOADING_TIME);
                }
                else if (status === 200 && message === 'REACHQUOTA') {
                    setTimeout(() => setPs('REACHQUOTA'), GB_LOADING_TIME);
                }
                else if (status === 200 && (message === 'NOSUCHGB' || message === 'FAILURE')) {
                    setTimeout(() => setPs('NOSUCHGB'), GB_LOADING_TIME);
                }
            }).catch((e) => {
                setTimeout(() => setPs('NOSUCHGB'), GB_LOADING_TIME);
            })
        }

    }, [])

    useEffect(() => {
        if (audioURL) {
            const dataURLToAudioBlob = (mediaBlob) => {
                //load blob
                var xhr_get_audio = new XMLHttpRequest();
                xhr_get_audio.open('GET', mediaBlob, true);
                xhr_get_audio.responseType = 'blob';
                xhr_get_audio.onload = function (e) {
                    if (this.status === 200) {
                        var blob = this.response;
                        setAudioblobfile(blob)
                    }
                };
                xhr_get_audio.send();
            }
            dataURLToAudioBlob(audioURL)
        }
    }, [audioURL])

    const onChangename = (e) => {
        if (name.length > 3 && name.length < 21) setNameerr('')
        setName(e.target.value)
    }

    const onChangemsg = (e) => {
        if (msg.length > 10 && msg.length < 350) setMsgerr('')
        setMsg(e.target.value)
    }

    const onEmojichange = (e) => {
        let onlyemojisarr = [...e.target.value.matchAll(emoRegex, "")]
        if (onlyemojisarr.length === 0 && e.target.value.length !== 0) alert('Please enter EMOJI only.');
        var txt = ''
        let emojistr = ''
        let emojidomsarr = []
        for (let i = 0; i < onlyemojisarr.length; i++) txt += onlyemojisarr[i][0];
        if (onlyemojisarr.length > 0) {
            for (let i = 0; i < onlyemojisarr.length; i++) {
                var newval = twemoji.parse(onlyemojisarr[i][0], { ext: '.png',base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/'  })
                const url = newval.split(' ')[4].substr(5).split('"')[0]
                emojidomsarr.push(<img key={i + 'emoji'} src={url} alt="" style={{ width: 32, height: 32 }} />)
                emojistr += onlyemojisarr[i][0]
            }
        }

        if (emojidomsarr.length > MAXEMOJI) {
            return
        }
        else {
            setEmojitxt(txt)
            setEmojidomarr(emojidomsarr)
            setEmojiascii(emojistr)
        }
    }

    const updateEmoji = (value) => {
        if(!(emojitxt.length>MAXEMOJI-1)){
            setEmojitxt([...emojitxt, value])
            setEmojiascii(emojiascii + value)
        }
    }

    const rmvEmoji = (e) => {
        const { value } = e.target
        var new_emojitxt = emojitxt.slice()
        console.log(value)
        new_emojitxt.splice(value, 1)
        setEmojitxt(new_emojitxt)

        var new_emojiascii = new_emojitxt.join('')
        setEmojiascii(new_emojiascii)
        console.log(new_emojiascii, new_emojitxt)

    }

    const onSubmit = () => {

        const checkerr = () => {
            var nameerr = ''
            var msgerr = ''
            var gterr = false

            if (name.length > MINNAME && name.length < MAXNAME + 1) {
                gterr = false
                setNameerr('')
            }

            if (msg.length > MINMSG && msg.length < MAXMSG + 1) {
                gterr = false
                setMsgerr('')
            }

            if (name.length > MAXNAME) {
                nameerr = NAME_TOO_LONG
                gterr = true
                setNameerr(nameerr)
            }
            if (name.length < MINNAME + 1) {
                nameerr = NAME_TOO_SHORT
                gterr = true
                setNameerr(nameerr)
            }

            if (msg.length > MAXMSG) {
                msgerr = MSG_TOO_LONG
                gterr = true
                setMsgerr(msgerr)
            }
            if (msg.length < MINMSG + 1) {
                msgerr = MSG_TOO_SHORT
                gterr = true
                setMsgerr(msgerr)
            }

            if (emojitxt.length > MAXEMOJI + 1) {
                msgerr = EMOJI_TOO_LONG
                gterr = true
                setMsgerr(EMOJI_TOO_LONG)
            }

            return gterr
        }

        setSendblock(true)

        const gterr = checkerr()

        if (!gterr) {
            const { urlhandler, passcode } = props.match.params
            var axios_obj
            if (imgblobfile || audioblobfile) {
                let fd = new FormData();

                var mediafilecounter = 0

                var newimg
                if (imgblobfile) {
                    newimg = new File( // reconstruct image
                        [imgblobfile],
                        urlhandler + '_' + passcode + '.' + imgtype.split('/')[1],
                        { type: imgtype }
                    )
                    fd.append('media', newimg)
                    mediafilecounter = mediafilecounter + 1
                }

                var newaudio
                if (audioblobfile) {
                    newaudio = new File( // reconstruct image
                        [audioblobfile],
                        urlhandler + '_' + passcode + '.wav',
                        { type: 'audio/wav' }
                    )
                    fd.append('media', newaudio)
                    mediafilecounter = mediafilecounter + 1
                }
                fd.append('emojiascii', emojiascii)
                fd.append('urlhandler', urlhandler)
                fd.append('passcode', passcode)
                fd.append('msg', msg)
                fd.append('name', name)
                fd.append('ff', ff)
                // fd.append("document", JSON.stringify(urlhandler));

                axios_obj = {
                    method: 'POST',
                    url: mediafilecounter === 1 ? ADDMSG_GBSINGLEMEDIA : ADDMSG_GBTWOMEDIA,
                    data: fd,
                    header: {
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data',
                    },
                }
            }
            else {
                axios_obj = {
                    method: 'POST',
                    url: ADDMSG_GB,
                    data: { urlhandler, passcode, name, msg, ff, emojiascii }
                }
            }

            axios(axios_obj).then((item) => {
                const { status, data } = item
                const { message } = data
                if (status === 200 && message === 'SUCCESS') {
                    setPs('SUCCESS')
                    setSendblock(false)
                    setGuestview('SUCCESS')
                }
                else if (status === 200 && message === 'REACHQUOTA') {
                    setPs('REACHQUOTA')
                    setSendblock(false)
                }
                else if (status === 200 && message === 'INCORRECTFILETYPE') {
                    setPs('INCORRECTFILETYPE')
                    setSendblock(false)
                }
            }).catch(() => {
                setSendblock(false)
            })
        }
        else {
            setSendblock(false)
        }
    }

    const onSuccess = () => {
        setMsg('')
        setMsgerr('')
        setName('')
        setNameerr('')
        setFf('')
        setImg(null)
        setImgtype('')
        setRecordgate(false)
        setPs('CONTENT')
        setAudioblobfile(null)
        setImgblobfile(null)
        setEmojidomarr([])
        setEmojitxt('')
    }

    const imgrefClick = () => {
        imgref.current.click()
    }

    const clearimg = () => {
        setImg(null)
    }

    const uploadimg = (e) => {
        var reader = new FileReader()
        let file = e.target.files[0]
        if (!file) { return; } // if no file

        reader.onload = (e) => {
            var imgdom = document.createElement("img");
            imgdom.onload = () => {
                if (file.size < 20000000) { // 2MB : 2097152, 20MB : 20000000
                    new Compressor(file, {
                        quality: 0.5,
                        success(file) {
                            setImgtype(file.type)
                            let img = new Image()
                            img.src = URL.createObjectURL(file)
                            setImg(img.src)
                            setImgblobfile(file)
                        },
                        error(err) { }
                    });
                }
            }
            imgdom.src = e.target.result;
        }
        reader.readAsDataURL(file);
        e.target.value = null;
    }

    const imgdom = (
        <div className="Gbguest_imgroot">
            <div className="Gbguest_row">
                <div className="Gbcast_label">Image</div>
                <div className="Gbguest_num">{`${img ? 1 : 0}/1`}</div>
            </div>
            <input ref={imgref} className="Gbguest_imginput" accept="image/*" id="formControlsFile" type='file' name="input-file" onChange={uploadimg} />
            {img ? <div className="Gbguest_img" style={{ backgroundImage: `url(${img})` }} /> : null}
            {/* {img ? <img className="Gbguest_img" src={img} /> : null} */}
            {img ? <button className="Gbguest_imgbtn" onClick={() => setImg(null)}>Clear</button>
                : <button className="Gbguest_imgbtn" onClick={() => imgref.current.click()}>Upload Image</button>}
        </div>
    )



    const startrecord = () => {
        setRecordgate(true);
        startRecording();
        setRecordsec(0)
        const secinterval = setInterval(() => {
            setRecordsec((r) => {
                if (r === -1) {
                    clearInterval(secinterval)
                }
                else {
                    return r + 1
                }
            })
        }, ONESEC)
        setTimeout(() => {
            clearInterval(secinterval)
            setRecordgate(false);
            setRecordsec(-1)
            stopRecording();
        }, FIFTEENSEC);
    }

    const stoprecord = () => {
        setRecordgate(false);
        setRecordsec(-1)
        stopRecording();
    }

    const clearrecord = () => {
        clearRecording()
        setRecordsec(-1)
    }

    const emojidom = (
        <div className="Gbguest_imgroot" style={{ marginBottom: 20 }}>
            <div className="Gbguest_row">
                <span className="Gbcast_label"  >
                    <span aria-label="emojiwishes" role="img" >Emoji Wishes</span>
                    <span aria-label="emojiwishes" role="img">😀🙁😣😄</span>
                </span>
                <div className="Gbguest_wishquestionmark" onClick={() => setEmojimodal(!emojimodal)} >{QUESTIONMARK_SMALL}</div>
            </div>
            {(emojidomarr && emojidomarr.length > 0)
                ? <div className="Gbguest_emojirow">
                    {emojidomarr}
                    <span className="Gbguest_clear" onClick={() => { setEmojidomarr([]); setEmojitxt(''); setEmojiascii('') }}>CLEAR</span>
                </div>
                : null}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input
                    className="Gbguest_emojitxt"
                    value={emojitxt}
                    onChange={onEmojichange}
                />
            </div>
        </div>
    )

    const audiodom = (
        <div className="Gbguest_imgroot">
            <div className="Gbguest_row">
                <div className="Gbcast_label">Audio ( 15s )</div>
                {recordsec > 0 ? <div>{recordsec + 's'}</div> : null}
            </div>
            <div className="Gbguest_audiorecordbtnsrow">
                {audioURL && audioURL.length > 0
                    ? <button className="Gbguest_audioclear" onClick={clearrecord}>Clear Record </button>
                    : <button className={recordgate ? "Gbguest_audiostartrecord" : "Gbguest_audiostart"} onClick={startrecord} disabled={isRecording}>Start Recording</button>}
                {recordgate
                    ? <button className="Gbguest_audiostop" onClick={stoprecord} disabled={!isRecording}>Stop Recording</button>
                    : null}
            </div>
            {!recordgate && audioURL && audioURL.length > 0 ? <audio className="Gbguest_audio" src={audioURL} controls /> : null}
        </div>
    )

    const ffselect = (e) => {
        setFf(e.target.value)
    }

    var formdom = <div className="Gbguest_root" style={{ minHeight: ht }}>
        {ps === 'LOADING' ? <Loading /> : null}
        {ps === 'CONTENT' ? <div className="Gbguest_col" >

            <div className="Gbcast_title">Send your wishes to celebrate this special event.</div>

            <div className="Gbguest_row">
                <div className="Gbcast_label">Your Name</div>
                <div className="Gbguest_num">{`${name.length}/${MAXNAME}`}</div>
            </div>
            <input className="Gbguest_input" onChange={onChangename} value={name} />
            <div className="Gbguest_err">{nameerr}</div>

            <div className="Gbguest_row">
                <div className="Gbcast_label">{`Message (Min ${MINMSG} Max ${MAXMSG} chars)`}</div>
                <div className="Gbguest_num">{`${msg.length}/${MAXMSG}`}</div>
            </div>

            <select className="Gbguest_selectff" onChange={ffselect}>
                {ENGLISH_FF.map((ff, i) => { return <option key={'ff' + i}>{ff}</option> })}
            </select>

            <textarea className="Gbguest_txtarea" style={{ fontFamily: ff }} onChange={onChangemsg} value={msg} />
            <div className="Gbguest_err">{msgerr}</div>

            {emojidom}

            {audiodom}

            {imgdom}

            {submitblock
                ? <button className="Gbguest_sendbtn" >Sending in progress.</button>
                : <button className="Gbguest_sendbtn" onClick={onSubmit}>Send</button>}

        </div> : null}
        {ps === 'SUCCESS'
            ? <div className="Gbguest_col" >
                <div className="Gbcast_title">Your wishes has been delivered.</div>
                <button className="Gbguest_sendbtn" onClick={onSuccess}>Done</button>
            </div>
            : null}
        {ps === 'REACHQUOTA'
            ? <div className="Gbguest_col" >
                <div className="Gbcast_quotatitle">Reach Total Message Quota</div>
            </div>
            : null}
        {ps === 'INCORRECTFILETYPE'
            ? <div className="Gbguest_col" >
                <div className="Gbcast_quotatitle">Incorrect File Type.</div>
            </div>
            : null}
        {ps === 'NOSUCHGB'
            ? <div className="Gbguest_col" >
                <div className="Gbcast_quotatitle">Cannot Find Guestbook</div>
            </div>
            : null}

    </div>

    const emojihelpdom = <div className="RSVP_root" style={{ backgroundColor: '#fffff8' }}>
        <div className="RSVP_subroot" style={{ width: DEVICE_WT_MAP[device], overflowX: 'hidden' }}>
            <div className="Ilookup_closeroot">
                <button className="Ilookup_close" onClick={() => setEmojimodal(false)}>{CLOSE_ICON}</button>
            </div>
            <div className="RSVP_largetitle" style={{ fontSize: 46 }}> Emoji Wishes</div>
            <span className="Gb_text">
                <div>
                    A picture is worth a thousand words.
                    Emoji could also be another way to express your wishings and hope for the couple.
                    Things could be hard to put down in words, you could express them in the form of Emoji.
                    Here are some template for your to use.
                </div>
                <span className="Gb_emoji" aria-label="emojiwishes" role="img" >🛏️💪👶👶👶</span>
                <span className="Gb_emoji" aria-label="emojiwishes" role="img" >🥳🎉🎉🥂🎊</span>
                <span className="Gb_emoji" aria-label="emojiwishes" role="img" >🈵❤️😍💕</span>
            </span>
        </div>
    </div>

    return (ht < 400 && orientation === 'landscape')
        ? <Nohori />
        : emojimodal
            ? emojihelpdom
            : {
                'FORM': formdom,
                'PAGE': <Page
                    name={name} onChangename={onChangename}
                    msg={msg} onChangemsg={onChangemsg}
                    ff={ff} ffselect={ffselect}
                    emojitxt={emojitxt} updateEmoji={updateEmoji} rmvEmoji={rmvEmoji}
                    recordsec={recordsec} audioURL={audioURL} recordgate={recordgate} clearrecord={clearrecord} stoprecord={stoprecord} startrecord={startrecord} isRecording={isRecording}
                    img={img} imgref={imgref} uploadImg={uploadimg} clearImg={clearimg} imgrefClick={imgrefClick}
                    submitblock={submitblock} onSubmit={onSubmit}
                    MAXEMOJI={MAXEMOJI} MINNAME={MINNAME} MAXNAME={MAXNAME} MINMSG={MINMSG} MAXMSG={MAXMSG}
                />,
                '': <div></div>,
                'SUCCESS': <div id="gb_submitcomplete">
                    <div>Thank You.</div>
                </div>,
                'FAILURE': <div id="gb_submitcomplete">
                    <div>Failed to deliever.</div>
                    <div>Please try again</div>
                </div>
            }[guestview]
}

export default Guestbook