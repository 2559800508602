import React from 'react'
import { NUMMTH_CHARMTH_MAP, FS_LH_MAP, f_fs, d_fs } from '../../config/constants'
import { Checkdevice } from '../Checkdevice'
import { iconwrapper } from '../../config/commonfunctions'
import { EXPAND_ICON } from '../../config/svgicons'
import Jfmap from '../../components/Jfmap/Jfmap'
import './Timelineobj.css'

function Timelineobj(props) {

    const DEVICE = Checkdevice()
    var {
        mapobj = { mapstate: false, title: '', lat: 0, long: 0, url: '' },
        itineraryobj = { itinerarystate: false, title: '', link: '', url: '', linkorurl: 'link' },
        whocanview,
        mapmode, mapmode, expandbtn = false,
        date_s = '', date_e = '', time_s = '', time_e = '', name = '', content = '', pri_fs = f_fs, sec_fs = d_fs, wt = '100%', dttimebox_wt = 50, content_ht = 120, from, finalindex, fc = '#333333', bgc,
    } = props

    const { mapstate = false, lat = 0.13, long = 0.1233 } = mapobj
    const { itinerarystate = false, linkorurl = 'link' } = itineraryobj

    const maincss = {
        'Invitationlookup': { padding: props.index === 0 ? '0px 0px 20px 0px' : '20px 0px', paddingBottom: finalindex ? 0 : 20, color: fc },
        'Invitationsimulator': { padding: props.index === 0 ? '0px 0px 15px 0px' : '15px 0px', paddingBottom: finalindex ? 0 : 15, color: fc },
        'Edit': { padding: props.index === 0 ? '0px 0px 5px 0px' : '5px 0px', paddingBottom: finalindex ? 0 : 5, color: fc },
    }

    const root_wt_map = {
        'Invitationlookup': { width: wt, },
        'Invitationsimulator': { width: 'calc( 100% - 40px )', margin: '0px 20px' },
        'Edit': {},
    }

    const titleanddescript_padding_map = {
        'Invitationlookup': 5,
        'Invitationsimulator': 5,
        'Edit': 5
    }

    const maproot_css_map = {
        'Invitationlookup': { width: '100%', paddingBottom: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', },
        'Invitationsimulator': { width: '100%', paddingBottom: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', },
        'Edit': { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', }
    }

    const map_css_map = {
        'Invitationlookup': { height: 150, width: 'calc( 100% - 40px )', margin: '0px 20px' },
        'Invitationsimulator': { height: 150, width: '100%' }, // margin: '0px 20px'
        'Edit': { height: 150, width: '100%' }
    }


    const itinerarybtn_css_map = {
        'Invitationlookup': { padding: titleanddescript_padding_map[from], border: `1px solid ${fc}`, backgroundColor: 'transparent', width: '100%', overflow: 'hidden', fontSize: pri_fs },
        'Invitationsimulator': { padding: titleanddescript_padding_map[from], border: `0.5px solid ${fc}`, backgroundColor: 'transparent', width: '100%', overflow: 'hidden', fontSize: pri_fs },
        'Edit': { padding: titleanddescript_padding_map[from], border: `0.5px solid ${fc}`, backgroundColor: 'transparent', width: '100%', overflow: 'hidden', fontSize: pri_fs },
    }

    const type_map = {
        'Invitationlookup': null,
        'Invitationsimulator': 'small',
        'Edit': 'small',
    }

    const floatingbtn_map = {
        'Invitationlookup': <div style={{ position: 'absolute', zIndex: 401, display: 'flex', right: 0, justifyContent: 'flex-end' }}>
            <a
                href={mapobj['url']}
                target='_blank'
                style={{ padding: 0, margin: 0, backgroundColor: 'transparent', cursor: 'pointer', border: 'none', color: 'transparent' }}>
                {iconwrapper(
                    EXPAND_ICON,
                    {
                        width: 27,
                        height: 27,
                        display: 'flex',
                        borderRadius: 4,
                        padding: 4,
                        margin: 10,
                        placeContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        boxShadow: 'rgba(0, 0, 0, 0.65) 0px 1px 5px',
                        color: 'var(--maindark)'
                    }
                )}
            </a>
        </div>,
        'Invitationsimulator': <div
            style={{ position: 'absolute', zIndex: 401, display: 'flex', width: '100%', justifyContent: 'flex-end' }}
        >
            <a
                className='timelineobj_expandicon'
                href={mapobj['url']}
                target='_blank'
                style={{ padding: 0, margin: 0, backgroundColor: 'transparent', cursor: 'pointer', border: 'none', color: 'transparent' }}>
                {iconwrapper(
                    EXPAND_ICON,
                    {transform:'scale(0.75)', width: 21, height: 21, display: 'flex', borderRadius: 5, padding: 4, margin: 5, placeContent: 'center', alignItems: 'center', backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.65) 0px 1px 5px', color: 'var(--maindark)' }
                )}
            </a>
        </div>,
        'Edit': <div style={{ position: 'absolute', zIndex: 401, display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <a
                href={mapobj['url']}
                target='_blank'
                style={{ padding: 0, margin: 0, backgroundColor: 'transparent', cursor: 'pointer', border: 'none', color: 'transparent' }}>
                {iconwrapper(
                    EXPAND_ICON,
                    { width: 21, height: 21, display: 'flex', borderRadius: 5, padding: 4, margin: 5, placeContent: 'center', alignItems: 'center', backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.65) 0px 1px 5px', color: 'var(--maindark)' }
                )}
            </a>
        </div>,
    }

    const font_style = { fontSize: pri_fs * 0.9 }

    var YR_S, YR_E, MTH_S, MTH_E, DAY_S, DAY_E

    try {
        date_s = new Date(date_s)

        if (date_s.toString() !== 'Invalid Date') {
            YR_S = date_s.getFullYear()
            MTH_S = NUMMTH_CHARMTH_MAP[parseInt(date_s.getMonth(), 10)]
            DAY_S = date_s.getDate()
        }
        else {
            YR_S = ''
            MTH_S = ''
            DAY_S = ''
        }
    }
    catch (e) {
        YR_S = ''
        MTH_S = ''
        DAY_S = ''

    }

    try {
        date_e = new Date(date_e)
        if (date_e.toString() !== 'Invalid Date') {
            YR_E = date_e.getFullYear()

            MTH_E = NUMMTH_CHARMTH_MAP[parseInt(date_e.getMonth(), 10)]

            DAY_E = date_e.getDate()

        }
        else {
            YR_E = ''
            MTH_E = ''
            DAY_E = ''
        }

    }
    catch (e) {
        YR_S = ''
        MTH_S = ''
        DAY_S = ''
    }

    const maptitle = mapobj['title']
    const itinerarytitle = itineraryobj['title']
    const finalurl = linkorurl === 'link' ? itineraryobj['link'] : itineraryobj['url']

    const DATE_S_DAY_DIV = <div style={{ ...font_style, marginRight: 3 }}>{DAY_S + ' '}</div>
    const DATE_S_MTH_DIV = <div style={font_style}>{MTH_S}</div>
    const DATE_S_YR_DIV = <div style={{ ...font_style, paddingRight: 1 }}>{YR_S}</div>

    const DATE_E_DAY_DIV = DAY_E ? <div style={{ ...font_style, marginRight: 3 }}>{DAY_E + ' '}</div> : <div />
    const DATE_E_MTH_DIV = MTH_E ? <div style={font_style}>{MTH_E}</div> : <div />
    const DATE_E_YR_DIV = YR_E && YR_E !== YR_S ? <div style={{ ...font_style, paddingRight: 1 }}>{YR_E}</div> : <div />

    const TIMESTART_DIV = <div style={font_style}>{time_s}</div>
    const TIMEEND_DIV = <div style={font_style}>{time_e}</div>
    const NAME_DIV = <div id='Timelineobj_nametitle' style={{ fontSize: sec_fs, }}>{name}</div>
    const CONTENT_DIV = <div style={{ display: 'flex', padding: '0px 0px 5px 0px', fontSize: pri_fs, lineHeight: FS_LH_MAP[pri_fs], flexWrap: 'wrap', whiteSpace:'pre-wrap' }}>{content}</div>
    const MAP_DIV = mapstate
        ? <div style={maproot_css_map[from]}>
            <div style={{ padding: titleanddescript_padding_map[from], fontSize: pri_fs }}>{maptitle}</div>
            <div style={{ position: 'relative', width: '100%', }}>
                {lat.length > 0 && long.length > 0
                    ? floatingbtn_map[from]
                    : null}
            </div>
            {lat.length > 0 && long.length > 0
                ? <Jfmap
                    key={'latlongtlobj' + lat + long}
                    markersData={[{ latLng: { lat: lat, lng: long } }]}
                    mapmode={mapmode}
                    type={type_map[from]}
                    smallstyle={map_css_map[from]}
                    zc={{ 'Invitationlookup': true, 'Invitationsimulator': false, 'Edit': false }[from]}
                    gpsiconsize={{ 'Invitationlookup': [25, 41], 'Invitationsimulator': [12, 20], 'Edit': [25, 41] }[from]}
                />
                : null}
        </div>
        : null
    const ITINERARY_DIV = itinerarystate
        ? <div style={{ width: '100%', paddingBottom: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {(itinerarytitle && itinerarytitle.length > 0)
                ? <button
                    className='ilookupexpandtxt'
                    style={itinerarybtn_css_map[from]}
                    onClick={() => {
                        const win = window.open(finalurl, "_blank");
                        win.focus();
                    }}
                >
                    {itinerarytitle}
                </button>
                : null}
        </div>
        : null



    return <div id={`Timelineobj_root`} style={{ ...root_wt_map[from], borderBottom: props.bb, }}>
        <div id={`Timelineobj_main`} style={maincss[from]}>
            <div id={`Timelineobj_time`} style={{ width: '25%' }}>
                <div id={`Timelineobj_timeright`} style={{ minWidth: DEVICE === 'MOBILE' || DEVICE === 'TABLET' ? '90%' : '50%', width: DEVICE === 'MOBILE' || DEVICE === 'TABLET' ? '80%' : 'max-content', }}>
                    {DATE_S_YR_DIV}
                    <div id={`Timelineobj_dateright`} style={{ width: dttimebox_wt }}>
                        {DATE_S_DAY_DIV}{DATE_S_MTH_DIV}
                    </div>
                    {TIMESTART_DIV}
                </div>
                {(time_e && time_e.length !== 0) || date_e.toString() !== 'Invalid Date'
                    ? <div id={`Timelineobj_line`} style={{ borderRight: `0.5px solid ${fc}` }} />
                    : null}

                {(time_e && time_e.length !== 0) || date_e.toString() !== 'Invalid Date'
                    ? <div id={`Timelineobj_timeright`} style={{ minWidth: DEVICE === 'MOBILE' || DEVICE === 'TABLET' ? '90%' : '50%', width: DEVICE === 'MOBILE' || DEVICE === 'TABLET' ? '80%' : 'max-content', }}>
                        {DATE_E_YR_DIV}
                        <div id={`Timelineobj_dateright`} style={{ width: dttimebox_wt }}>
                            {DATE_E_DAY_DIV}{DATE_E_MTH_DIV}
                        </div>
                        {TIMEEND_DIV}
                    </div>
                    : null}
            </div>
            <div style={{
                minHeight: content_ht,
                width: { 'Invitationlookup': '75%', 'Invitationsimulator': '80%', 'Edit': '75%' }[from],
                paddingRight: DEVICE === 'MOBILE' && from === 'Invitationlookup' ? 10 : 0, // padding 10 only if is mobile on ilookup
                flexDirection: 'column',
                justifyContent: 'flex-start',
            }}>
                {NAME_DIV}
                {CONTENT_DIV}
                {ITINERARY_DIV}
            </div>

        </div>
        {MAP_DIV}


    </div>


    // return (
    //     <div style={{ display: 'flex', flexDirection: 'column', width: wt, borderBottom: props.bb, }}>
    //         <div style={{
    //             display: 'flex', flexDirection: 'row', height: 'max-content', width: '100%', alignItems: 'flex-start', alignSelf: 'center',
    //             padding: props.index === 0 ? '0px 0px ' + from_padding_map[from] + ' 0px' : from_padding_map[from] + ' 0px',
    //             paddingBottom: finalindex ? 0 : from_padding_map[from],
    //             color: fc
    //         }}>
    //             <div style={{ width: { 'Invitationlookup': '25%', 'Invitationsimulator': '20%', 'Edit': '25%' }[from], alignItems: 'center', alignSelf: 'stretch', padding: '5px 0px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
    //                 <div style={{ minWidth: DEVICE === 'MOBILE' || DEVICE === 'TABLET' ? '90%' : '50%', width: DEVICE === 'MOBILE' || DEVICE === 'TABLET' ? '80%' : 'max-content', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: 'max-content', backgroundColor: 'transparent', borderRadius: 3, }}>
    //                     {DATE_S_YR_DIV}
    //                     <div style={{ display: 'flex', flexDirection: 'row', width: dttimebox_wt, height: 'max-content', overflow: 'hidden', justifyContent: 'center', alignItems: 'center', minHeight: 'max-content' }}>
    //                         {DATE_S_DAY_DIV}{DATE_S_MTH_DIV}
    //                     </div>
    //                     {TIMESTART_DIV}
    //                 </div>
    //                 {(time_e && time_e.length !== 0) || date_e.toString() !== 'Invalid Date'
    //                     ? <div style={{ height: '100%', display: 'flex', justifyContent: 'center', justifyItems: 'center', alignContent: 'center', borderRight: `0.5px solid ${fc}`, minHeight: 20 }} />
    //                     : null}
    //                 {(time_e && time_e.length !== 0) || date_e.toString() !== 'Invalid Date'
    //                     ? <div style={{ display: 'flex', flexDirection: 'column', minWidth: DEVICE === 'MOBILE' || DEVICE === 'TABLET' ? '90%' : '50%', width: DEVICE === 'MOBILE' || DEVICE === 'TABLET' ? '80%' : 'max-content', justifyContent: 'center', alignItems: 'center', minHeight: 'max-content', backgroundColor: 'transparent', borderRadius: 3, }}>
    //                         {DATE_E_YR_DIV}
    //                         <div style={{ display: 'flex', flexDirection: 'row', width: dttimebox_wt, overflow: 'hidden', justifyContent: 'center', alignItems: 'center' }}>
    //                             {DATE_E_DAY_DIV}{DATE_E_MTH_DIV}
    //                         </div>
    //                         {TIMEEND_DIV}
    //                     </div>
    //                     : null}
    //             </div>
    //             <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: { 'Invitationlookup': '75%', 'Invitationsimulator': '80%', 'Edit': '75%' }[from], minHeight: content_ht, }}> {/* borderLeft: '1px solid rgb(221, 221, 221)'  */}
    //                 {NAME_DIV}
    //                 {CONTENT_DIV}
    //                 {itinerarystate
    //                     ? <div style={{ width: '100%', paddingBottom: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    //                         <button
    //                             className='ilookupexpandtxt'
    //                             style={itinerarybtn_css_map[from]}
    //                             onClick={() => {
    //                                 const win = window.open(finalurl, "_blank");
    //                                 win.focus();
    //                             }}
    //                         >
    //                             {itinerarytitle}
    //                         </button>
    //                     </div>
    //                     : null}
    //             </div>
    //         </div>
    //         {mapstate
    //             ? <div style={maproot_css_map[from]}>
    //                 <div style={{ padding: titleanddescript_padding_map[from], fontSize: pri_fs }}>{maptitle}</div>
    //                 <Jfmap markersData={[{ latLng: { lat: lat, lng: long } }]} mapmode={mapmode} type={type_map[from]} smallstyle={map_css_map[from]} />
    //                 {/* <div style={{ padding: titleanddescript_padding_map[from], lineHeight: FS_LH_MAP[sec_fs], fontSize: pri_fs,}}>{mapdescript}</div> */}
    //             </div>
    //             : null}
    //     </div>
    // )


}

export default Timelineobj



// {(time_e && time_e.length !== 0) || date_e.toString() !== 'Invalid Date'
// ? <div style={{ display: 'flex', flexDirection: 'column', minWidth: DEVICE === 'MOBILE' || DEVICE === 'TABLET' ? '90%' : '50%', width: DEVICE === 'MOBILE' || DEVICE === 'TABLET' ? '80%' : 'max-content', justifyContent: 'center', alignItems: 'center', minHeight: 'max-content', backgroundColor: 'transparent', borderRadius: 3, }}>{/*border: time_e || date_e ? '1px solid rgb(221, 221, 221)' : '',*/}
//     {DATE_E_YR_DIV}
//     <div style={{ display: 'flex', flexDirection: 'row', width: dttimebox_wt, overflow: 'hidden', justifyContent: 'center', alignItems: 'center' }}>
//         {DATE_E_DAY_DIV}{DATE_E_MTH_DIV}
//     </div>
// </div>
// : null }

// {(time_e && time_e.length !== 0) || date_e.toString() !== 'Invalid Date'
// ? <div style={{ display: 'flex', flexDirection: 'column', minWidth: DEVICE === 'MOBILE' || DEVICE === 'TABLET' ? '90%' : '50%', width: DEVICE === 'MOBILE' || DEVICE === 'TABLET' ? '80%' : 'max-content', justifyContent: 'center', alignItems: 'center', minHeight: 'max-content', backgroundColor: 'transparent', borderRadius: 3, }}>{/*border: time_e || date_e ? '1px solid rgb(221, 221, 221)' : '',*/}
//     {TIMEEND_DIV}
// </div>
// : null}