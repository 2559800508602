import React, { useState, useEffect, useRef } from 'react'
import { Link } from "react-router-dom";
import { ScreenHeight, ScreenWidth, Headtitle, Processname } from '../../../../components';
import { NEAR_TRANSPARENT_GREY, EMPTYSPACE, EMPTY, WEB, SENDMSG_ICON, SOFT_GREY, TRASH_ICON, BACK_ICON, READABLE_VENDORTYPE_MAP, IMG_ALT, production, NUM_WORDS_CANSEND, LEN_MSG_CANSEND } from '../../../../config/constants';
import { Retrieve_personal_info, getS3fullpath_withbucketname } from '../../../../config/commonfunctions';
import { READ_CONV_VENDOR_START, READ_CONV_USER_START, READ_CONV_SPECIFIC_RM_USER, READ_CONV_SPECIFIC_RM_VENDOR, REMOV_CONV_USER, REMOV_CONV_VENDOR, B_W, READ_INQUIRIES } from '../../../../config/api';
import axios from 'axios'
import './Inquiries.css'

function convertIsoToReadableTime(isoTimestamp) {
    // Create a new Date object from the ISO 8601 timestamp
    const dateObj = new Date(isoTimestamp);
    
    // Format the date part to a readable string (YYYY-MM-DD)
    const datePart = dateObj.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
    
    // Format the time part to a readable string (HH:MM AM/PM)
    const timePart = dateObj.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });
    
    // Combine date and time parts
    const readableDateTime = `${datePart} ${timePart}`;
    
    return readableDateTime;
}

function Inquiries() {

    const { token, _id } = Retrieve_personal_info()

    const [inquiries, setInquiries] = useState([])

    useEffect(() => {

        let options = {
            method: 'POST',
            url: READ_INQUIRIES,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
            data: { _id }
        }

        axios(options).then((response) => {
            const { message, inquiries } = response.data
            if (message === 'SUCCESS') {
                setInquiries(inquiries)
            }
            else {
                setInquiries([])
            }
        })

    }, [token])

    return <div id='Inquiries_root'>
        {inquiries.map((item, x) => {
            const { createdAt, u_contact, u_email, u_message, vti_ids } = item
            return <div className='Inquiries_item' key={x + '_inquiries'}>

                <div className='Inquiries_title'>{`Vendor(s)`}</div>
                <div className='Inquiries_vendorinforoot'>
                    {vti_ids.map((vtiid, y) => {
                        const { displayname, username } = vtiid
                        return <button
                            key={x + '_vtiid_' + y}
                            className='Inquiries_vendorinfo'
                            onClick={() => document.getElementById(x + '_vtiid_' + y + '_link').click()}
                        >
                            <Link
                                id={x + '_vtiid_' + y + '_link'}
                                style={{ width: 0, height: 0, visibility: 'hidden', userSelect:'none' }}
                                to={'/vendorlookup/' + username}
                                target="_blank"
                            />
                            {displayname}
                        </button>
                    })}
                </div>

                <div className='Inquiries_userinforoot'>
                    <div className='Inquiries_yourcontactroot'>
                        <div className='Inquiries_title'>{`Your Info`}</div>
                        <div className='Inquiries_yourcontactitem'>{u_contact}</div>
                        <div className='Inquiries_yourcontactitem'>{u_email}</div>
                    </div>
                    <div className='Inquiries_mainmsgroot'>
                        <div className='Inquiries_title'>{`Your Message`}</div>
                        <div className='Inquiries_mainmsg'>{u_message}</div>
                    </div>

                </div>

                <div className='Inquiries_extrainfo'>
                    {convertIsoToReadableTime(createdAt)}
                </div>

            </div>

        })}
    </div>
}

export default Inquiries