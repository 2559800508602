import {

    FETCH_WEDDINGVENDORS_DATA_ACTION_FULFILLED,
    FETCH_WEDDINGVENDORS_DATA_ACTION_PENDING,
    FETCH_WEDDINGVENDORS_DATA_ACTION_REJECTED,

    FETCH_WEDDINGCONTENTSIZE_DATAINFO_ACTION_FULFILLED,
    FETCH_WEDDINGCONTENTSIZE_DATAINFO_ACTION_PENDING,
    FETCH_WEDDINGCONTENTSIZE_DATAINFO_ACTION_REJECTED,

    UPDATE_USER_FILTER_ACTION_FULFILLED,
    UPDATE_USER_FILTER_ACTION_PEDING,
    UPDATE_USER_FILTER_ACTION_REJECTED

} from '../actions/weddingvendors.action'

const initialState = {
    isLoading: false,
    isLoaded: false,
    data_info: [], // give the size etc
    data: [], // give the real data
    filter_bool_info:false,
}

//rmb to unwrap to the point where the basic data structure expose
export default (state = initialState, action) => {
    switch (action.type) {


        case FETCH_WEDDINGCONTENTSIZE_DATAINFO_ACTION_FULFILLED:
            return {
                ...state,
                data_info: action.payload.data.data,
                isLoading: false,
                isLoaded: true,
            }
        case FETCH_WEDDINGCONTENTSIZE_DATAINFO_ACTION_PENDING:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
            }
        case FETCH_WEDDINGCONTENTSIZE_DATAINFO_ACTION_REJECTED:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
            }


        case FETCH_WEDDINGVENDORS_DATA_ACTION_FULFILLED:
            return {
                ...state,
                data: action.payload.data.data,
                isLoading: false,
                isLoaded: true,

            }
        case FETCH_WEDDINGVENDORS_DATA_ACTION_PENDING:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
            }
        case FETCH_WEDDINGVENDORS_DATA_ACTION_REJECTED:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
            }




        case UPDATE_USER_FILTER_ACTION_FULFILLED:
            return {
                ...state,
                filter_bool_info: action.payload.data,
                isLoading: false,
                isLoaded: true,

            }
        case UPDATE_USER_FILTER_ACTION_PEDING:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
            }
        case UPDATE_USER_FILTER_ACTION_REJECTED:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
            }



        default:
            return state


    }
}