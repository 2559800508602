import React, { useState, useEffect } from 'react'
import { BUTTONSTYLE, } from '../../../../../../config/cssconstants'
import { BOOK_SMALL, DIALOG_SMALL, FS_LH_MAP, PERSON_SMALL, TIME_SMALL, EMPTYSPACE, c_fs, ARROWUP_ICON, ARROWDOWN_ICON, EMPTY_SMALL, X_SMALL } from '../../../../../../config/constants'
import Explain from '../Explain'
import '../components.css'
import './../../Invitationedit.css'
import './Openings.css'

const ICON_TYPE_MAP = {
    'Empty': EMPTY_SMALL,
    'Convo': DIALOG_SMALL,
    'Story': BOOK_SMALL,
}
const TYPE_OPENINGS = ['Empty', 'Convo'] // , 'Story'

const TYPE_MAINTEXT = {
    'Empty': 'So empty...',
    'Convo': 'A short conversational opening for your invitation card.',
    'Story': 'A short story as an opening that you would like to share with you guests.',
}

const EXAMPLES = ['Wedding double act']
const EXAMPLE_CONTENT_MAP = {
    'Wedding double act': {
        names: ['Alice', 'Bob'],
        items: [
            ['Bob', 0, 'Hi,👋👋👋'],
            ['Bob', 3, 'As you may or may not know, Alice and I had been dating for like over 4 years.'],
            ['Bob', 3, 'and since then we have done a lot of stuff.'],
            ['Bob', 3, 'we seen each others through career changes'],
            ['Bob', 3, 'we travel the world together'],
            ['Bob', 3, 'we adopted and taking care of a homeless dog together'],
            ['Bob', 3, 'so we spend lot lot of time together.'],
            ['Alice', 3, 'and yes, when we started dating we were kind of young'],
            ['Alice', 3, 'we are still young but less young.'],
            ['Bob', 3, 'and now seems like the best time to get together.....'],
            ['Bob', 3, 'we would like to share our joy with you.'],
            ['Alice', 3, 'Bob and I would like to invite you to our wedding ceremony.'],
            ['Bob', 3, 'for more details of the wedding ceremony click next'],
        ]
    },
    // 'House-Warming double act': {
    //     names: ['Alice', 'Bob'],
    //     items: [
    //         ['Alice', 4, 'Home sweet home.'],
    //         ['Bob', 4, 'Our contractor has finally done with the renovation'],
    //         ['Alice', 4, 'after a few eternity though....'],
    //         ['Bob', 4, 'On the 15/4/2020, we would like to invite you to our house warming.'],
    //         ['Alice', 4, 'click next to look at the location and schedules.'],
    //     ]
    // }
}

const SECONDS_ARR = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
const TITLE = 'Openings'
const TEXT = `Create engaging openings before your guest view the content of your event invitation.`

function Openings(props) {

    const [firsttime, setFirsttime] = useState(true)

    const {
        openingnames,
        openingtype,  // only Convo or Story
        openingobjs, // convo obj :  [ UNIQUE NAME, SECONDS TEXT APPEAR, TEXT ]
        openingstoryobjs, // story obj
        openingclosing
    } = props

    // [sequence, message, seconds]

    useEffect(() => {
        if (firsttime && props) {
            setFirsttime(false)
        }
    }, [])


    // CONVO
    var NAMES_DIV = ( // openingnames
        <div className="Opening_menuroot" >
            <div className="Iedit_inputlabel">Name</div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
                <input
                    className="Iedit_input"
                    value={openingnames[0]}
                    onChange={(event) => {
                        if (event.target.value.length < 50 && event.target.value !== openingnames[1]) {
                            var local_openingnames = props.openingnames.slice()
                            local_openingnames[0] = event.target.value
                            props.updateOpeningnames(local_openingnames)
                        }
                    }}
                />

                <input
                    className="Iedit_input"
                    value={openingnames[1]}
                    onChange={(event) => {
                        if (event.target.value.length < 50 && event.target.value !== openingnames[0]) {
                            var local_openingnames = props.openingnames.slice()
                            local_openingnames[1] = event.target.value
                            props.updateOpeningnames(local_openingnames)
                        }
                    }}
                />
            </div>
        </div>
    )

    var CLOSING_DIV = ( // openingnames
        <div className="Opening_menuroot" >
            <div className="Iedit_inputlabel">Opening Closing Animation</div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
                <button style={{ color: openingclosing === 0 ? 'white' : '#707070', ...BUTTONSTYLE, borderRadius: 5, padding: 5, margin: '0px 5px', marginLeft: 0, height: 'max-content', backgroundColor: openingclosing === 0 ? '#707070' : 'white', fontWeight: openingclosing === 0 ? 'bold' : '' }} onClick={() => props.updateOpeningclosing(0)}>Circular</button>
                <button style={{ color: openingclosing === 1 ? 'white' : '#707070', ...BUTTONSTYLE, borderRadius: 5, padding: 5, margin: '0px 5px', marginLeft: 0, height: 'max-content', backgroundColor: openingclosing === 1 ? '#707070' : 'white', fontWeight: openingclosing === 1 ? 'bold' : '' }} onClick={() => props.updateOpeningclosing(1)}>Top-down</button>
            </div>
        </div>
    )

    var ADD_DIV = ( // add new Convo obj or Story obj
        <button
            className="ie_addbtn"
            onClick={() => {
                var new_openingobjs = openingobjs.slice()
                new_openingobjs.push([props.openingnames[0], 1, ''])
                props.updateOpeningobjs(new_openingobjs)
            }}>
            +
        </button>
    )

    var CONVOOBJS_DIV = openingobjs.map((item, index) => {
        const shiftUp = (i) => {
            if (i - 1 > -1) {
                var result = openingobjs.slice()
                var temp = result[i - 1]
                result[i - 1] = result[i]
                result[i] = temp
                props.updateOpeningobjs(result)
            }
        }

        const shiftDown = (i) => {
            if (i + 1 < openingobjs.length) {
                var result = openingobjs.slice()
                var temp = result[i + 1]
                result[i + 1] = result[i]
                result[i] = temp
                props.updateOpeningobjs(result)
            }
        }

        const removeObj = (i) => {
            var result = openingobjs.slice()
            result.splice(i, 1)
            props.updateOpeningobjs(result)
        }
        const updateName = (e) => {
            var currobjs = openingobjs.slice()
            currobjs[index][0] = e.target.value
            props.updateOpeningobjs(currobjs)
        }
        const updateTime = (e) => {
            var currobjs = openingobjs.slice()
            currobjs[index][1] = e.target.value
            props.updateOpeningobjs(currobjs)
        }

        const updateMsg = (e) => {
            var currobjs = openingobjs.slice()
            currobjs[index][2] = e.target.value
            props.updateOpeningobjs(currobjs)
        }

        return (
            <div className="Opening_convoitem" key={index + 'obj'} >
                <div style={{ height: 34, flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <div style={{ fontSize: c_fs, lineHeight: FS_LH_MAP[c_fs], marginRight: 10, display: 'flex', alignItems: 'center' }}>{index}</div>
                        <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'white', padding: '0px 5px', alignItems: 'center', }}>
                            {PERSON_SMALL}
                            <select
                                style={{ backgroundColor: 'transparent' }}
                                placeholder="select"
                                value={item[0]}
                                onChange={(e) => updateName(e)}
                            >
                                <option key={openingnames[0] + index} value={openingnames[0]}>{openingnames[0]}</option>
                                <option key={openingnames[1] + index} value={openingnames[1]}>{openingnames[1]}</option>
                            </select>
                        </div>
                        {index === 0
                            ? null
                            : <div style={{ display: 'flex', flexDirection: 'row', padding: '0px 5px', alignItems: 'center' }}>
                                {TIME_SMALL}
                                <select
                                    style={{ backgroundColor: 'transparent' }}
                                    placeholder="select"
                                    value={item[1]}
                                    onChange={(e) => updateTime(e)}
                                >
                                    {SECONDS_ARR.map((item, index) => {
                                        return <option key={item + 'sec' + index} value={item}>{item + ' s'}</option>
                                    })}
                                </select>
                            </div>}
                    </div>
                    <button style={{ display: 'flex', padding: 5, margin: 5, alignItems: 'center', backgroundColor: 'transparent', outline: 'none', border: 'none', color: '#b21212' }} onClick={() => removeObj(index)}>{X_SMALL}</button>
                </div>
                <div style={{ borderLeft: 'none', margin: 5 }}>
                    Message
                    <input
                        className="Iedit_input"
                        value={item[2]}
                        onChange={(e) => updateMsg(e)}
                    />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', height: 'max-content' }}>
                    {index === 0 || index === 1
                        ? null
                        : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'thin solid #707070', borderRadius: 5, margin: '0px 5px' }} onClick={() => shiftUp(index)}> {ARROWUP_ICON}</div>
                    }
                    {index !== 0
                        ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'thin solid #707070', borderRadius: 5, margin: '0px 5px' }} onClick={() => shiftDown(index)}>{ARROWDOWN_ICON}</div>
                        : null}
                </div>
            </div >
        )
    })


    const Convo = (
        <div className="Opening_contentroot">
            {NAMES_DIV}
            {CLOSING_DIV}
            {CONVOOBJS_DIV}
            {ADD_DIV}
            {EMPTYSPACE}
            {EMPTYSPACE}
        </div>
    )
    // CONVO

    // STORY
    const Story = (
        <div style={{ display: 'flex', flexDirection: 'column', padding: 5, width: '100%', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
                Main Text
                <textarea
                    rows="4"
                    cols="50"
                    name="comment"
                    form="usrform"
                    style={{ display: 'block', outlineColor: '#69b1e9', padding: '6px 12px', lineHeight: '1.42857143', color: '#555', backgroundColor: '#fff', backgroundImage: 'none', width: '100%', transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s', boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)', borderRadius: 4, border: '1px solid #ccc' }}
                    onChange={(event) => {
                        if (event.target.value.length < 1000) props.updateOpeningstoryobjs(event.target.value)
                    }}
                    value={openingstoryobjs}
                />
            </div>

        </div>
    )
    // STORY


    const OPENINGTYPE_DIV_MAP = {
        'Empty': null,
        'Convo': Convo,
        'Story': Story
    }

    const EXAMPLE_DIV_MAP = {
        'Empty': null,
        'Convo': <div className="Openings_exampleroot">
            <div className="Openings_exampletitle" >Example</div>
            <div className="Openings_examplerow">
                {EXAMPLES.map((item, index) => {
                    return (
                        <div
                            key={item + index}
                            className="Openings_examplebtn"
                            onClick={() => {
                                props.updateOpeningnames(EXAMPLE_CONTENT_MAP[item].names)
                                props.updateOpeningobjs(EXAMPLE_CONTENT_MAP[item].items)
                            }}
                        >{item}</div>
                    )
                })}
            </div>
        </div>,
        'Story': null,
    }

    return (
        // <div className="Openings_root">
        <div className="Ieditcomp_div">
            <Explain title={TITLE} txt={TEXT} />
            <div className="Openings_body">
                <div className="Openings_content">
                    <div className="Openings_rowapp">
                        {TYPE_OPENINGS.map((item, i) => <button key={item + i} className={"Openings_typeopening" + (openingtype === item)} onClick={() => { props.updateOpeningtype(item) }} >
                            <div className="Openings_typeopeningicon">{ICON_TYPE_MAP[item]}</div>
                            <div className="Openings_typeopeningname">{item}</div>
                        </button>)}
                    </div>
                    <div className="Iedit_subtitle">{openingtype + ' Opening'}</div>
                    <div className="Openings_openingcontent">{TYPE_MAINTEXT[openingtype]}</div>
                    {!firsttime ? EXAMPLE_DIV_MAP[openingtype] : null}
                </div>
                {!firsttime ? OPENINGTYPE_DIV_MAP[openingtype] : null}
            </div>
        </div>
    )
}

export default Openings