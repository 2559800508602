import axios from 'axios'
import { ALL_VENDOR_READ_DETAIL_LINK } from '../../config/api'

export const FETCH_VENDOR_DETAIL_ACTION = 'FETCH_VENDOR_DETAIL_ACTION'
export const FETCH_VENDOR_DETAIL_ACTION_FULFILLED = 'FETCH_VENDOR_DETAIL_ACTION_FULFILLED'
export const FETCH_VENDOR_DETAIL_ACTION_PENDING = 'FETCH_VENDOR_DETAIL_ACTION_PENDING'
export const FETCH_VENDOR_DETAIL_ACTION_REJECTED = 'FETCH_VENDOR_DETAIL_ACTION_REJECTED'
export const UPDATE_USER_LIKE = 'UPDATE_USER_LIKE';
export function fetchvendordetail_action(vendorname, user_id, acct_type, token, email) {
    let options = {
        method: 'POST',
        data: {
            vendorname,
            user_id: user_id, 
            acct_type: acct_type,
            token: token,
        }
    }
    return {
        type: FETCH_VENDOR_DETAIL_ACTION, // obtain common info for user/vendor and vendorlookup.
        payload: axios.post(ALL_VENDOR_READ_DETAIL_LINK, options)
    }
}

export const updateUserLike = (newUserLike) => ({
    type: UPDATE_USER_LIKE,
    payload: newUserLike,
  });























