import React from 'react'

export const subtitle = (text, subtitle = '') => {
    return (
        <div style={{ display: 'flex', padding: '5px 10px', margin: '10px 10px 0px 0px', color: 'var(--maindark)', display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: 'xx-large', fontWeight: 'bold' }}>{text}</div>
            {subtitle.length > 0
                ? <div style={{ color: 'var(--subdark)', marginBottom: 5 }}>{subtitle}</div>
                : null}
        </div>
    )
}

