
import React from 'react'
import DatePicker from "react-datepicker";
import { iconwrapper } from '../../config/commonfunctions';
import { CROSS_ICON } from '../../config/svgicons';
import './Calendar.css'

var CustomDatepicker = React.forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" ref={ref} onClick={onClick} style={{ display: 'flex', height: 40, width: '100%', color: 'var(--maindark)', alignItems: 'center', borderRadius: 5, backgroundColor: 'white', border: '1px solid var(--maindark)', outline: 'none' }}>
        {value}
    </button>
));

function Calandar(props) {

    const {
        check, // check to detect whether to toggle close btn
        selected, // date selected
        onChange, // date picker on change
        updateDate
    } = props

    return <div className='Calendar_root'>
        {check
            ? <button className='Calendar_close' onClick={updateDate}>
                {iconwrapper(CROSS_ICON,{ width: 20, height: 20, display: 'flex', placeContent: 'center', color: 'var(--mainpink)' })}
            </button>
            : null
        }
        <DatePicker
            className='Calendar_datepicker'
            dateFormat={'dd/MM/yyyy'}
            withPortal
            fixedHeight
            selected={selected}
            onChange={onChange}
            customInput={<CustomDatepicker />}
        />
    </div>
}
export default Calandar