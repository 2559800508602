import axios from 'axios'
import { PROJECT_INTERFACE_INFINITESCROLL_DATA, PROJECTINTERFACE_READ } from '../../config/api'

export const FETCH_PROJECTGALLERYCONTENTSIZE_DATAINFO_ACTION = 'FETCH_PROJECTGALLERYCONTENTSIZE_DATAINFO_ACTION'
export const FETCH_PROJECTGALLERYCONTENTSIZE_DATAINFO_ACTION_FULFILLED = 'FETCH_PROJECTGALLERYCONTENTSIZE_DATAINFO_ACTION_FULFILLED'
export const FETCH_PROJECTGALLERYCONTENTSIZE_DATAINFO_ACTION_PENDING = 'FETCH_PROJECTGALLERYCONTENTSIZE_DATAINFO_ACTION_PENDING'
export const FETCH_PROJECTGALLERYCONTENTSIZE_DATAINFO_ACTION_REJECTED = 'FETCH_PROJECTGALLERYCONTENTSIZE_DATAINFO_ACTION_REJECTED'
export function fetchprojectgallerycontentsize_action(token, defaultcat, tag_filter, location_filter, guestcount_filter, filter_gate) {

    var filter_gate_bits = ''
    for (let i = 0; i < filter_gate.length; i++) {
        var curr_bit = +filter_gate[i] // convert filter_gate to 1 and 0s
        filter_gate_bits = filter_gate_bits + curr_bit
    }

    let options = {
        method: 'POST',
        url: PROJECT_INTERFACE_INFINITESCROLL_DATA,
        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
        data: { defaultcat, tag_filter, location_filter, guestcount_filter, filter_gate: parseInt(filter_gate_bits, 2) },
    }
    return {
        type: FETCH_PROJECTGALLERYCONTENTSIZE_DATAINFO_ACTION,
        payload: axios(options)
    }


}

export const FETCH_PROJECTGALLERY_DATA_ACTION = 'FETCH_PROJECTGALLERY_DATA_ACTION'
export const FETCH_PROJECTGALLERY_DATA_ACTION_FULFILLED = 'FETCH_PROJECTGALLERY_DATA_ACTION_FULFILLED'
export const FETCH_PROJECTGALLERY_DATA_ACTION_PENDING = 'FETCH_PROJECTGALLERY_DATA_ACTION_PENDING'
export const FETCH_PROJECTGALLERY_DATA_ACTION_REJECTED = 'FETCH_PROJECTGALLERY_DATA_ACTION_REJECTED'
export function fetchprojectgallerycontent_action(requestContent_list) {
    var link = PROJECTINTERFACE_READ

    let options = {
        method: 'POST',
        data: { requestContent_list }
    }
    return {
        type: FETCH_PROJECTGALLERY_DATA_ACTION,
        payload: axios.post(link, options)
    }
}
