import React, { useState } from 'react'
import { HexColorInput, HexColorPicker } from 'react-colorful'
import { Icon } from 'react-icons-kit/Icon'
import { plus } from 'react-icons-kit/ikons/plus'
import { minus } from 'react-icons-kit/ikons/minus'
import './Palette.css'

function Palette({ key_, sc, colors, onClick, selfselect = true }) {
    const [expand, setExpand] = useState(false)

    return <div key={key_ + Math.random() + 'palette'} className="Palette_root">
        <div className="Palette_subroot">
            {colors && colors.map((c, i) => {
                if (i > -1) {
                    if (c === 'transparent') {
                        return <div key={key_ + i + c} className="Palette_circleroot" style={{ border: sc === c ? '1px solid #cbaf8a' : '1px solid transparent' }}>
                            <div className="Palette_div" style={{ backgroundColor: c, display: 'flex', flexDirection: 'column' }} onClick={() => onClick(c)} >
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%', height: '100%', backgroundColor: 'grey', borderTopLeftRadius: '100%' }} />
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%', height: '100%', backgroundColor: 'white', borderTopRightRadius: '100%' }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%', height: '100%', backgroundColor: 'white', borderBottomLeftRadius: '100%' }} />
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%', height: '100%', backgroundColor: 'grey', borderBottomRightRadius: '100%' }} />
                                </div>
                            </div>
                        </div>
                    }
                    return <div key={key_ + i + c} className="Palette_circleroot" style={{ border: sc === c ? '1px solid #cbaf8a' : '1px solid transparent' }}>
                        <div className="Palette_div" style={{ backgroundColor: c }} onClick={() => onClick(c)} />
                    </div>
                }
                return null
            })}
        </div>
        {selfselect
            ? <div className="Palette_addbtn" onClick={() => setExpand(expand ? false : true)}>
                {expand
                    ? <Icon size={18} icon={minus} />
                    : <Icon size={18} icon={plus} />}
            </div> : null}

        {expand ? <div className="Palette_col">
            <HexColorInput color={sc} onChange={onClick} style={{ width: '100%' }} />
            <HexColorPicker color={sc} onChange={onClick} style={{ width: '100%', marginTop: 20 }} />
        </div> : null}
    </div>
}
export default Palette