import React, { useState, useEffect, useRef } from 'react'
import { Checkdevice } from '../../../../components/Checkdevice'
import { ReactP5Wrapper } from "react-p5-wrapper";
import './Play2.css'
import './Play2mobile.css'
import { guestemoji } from '../../../../config/constants';

const iconcss = { width: '40px', height: '40px', verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden' }
const audioicon = <svg style={{ ...iconcss, width: 25, height: 25 }} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" ><path d="M725.333333 298.666667v426.666666h-85.333333V298.666667h85.333333zM384 298.666667v426.666666H298.666667V298.666667h85.333333z m-170.666667 128v170.666666H128v-170.666666h85.333333z m682.666667 0v170.666666h-85.333333v-170.666666h85.333333z m-341.333333-256v682.666666h-85.333334V170.666667h85.333334z" fill="currentColor" p-id="2312"></path></svg>
const arrowicon = <svg style={iconcss} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path d="M728.223744 520.22784a42.467328 42.467328 0 0 1-11.393024 20.503552L374.90688 882.65728c-16.662528 16.662528-43.677696 16.662528-60.340224 0s-16.662528-43.677696 0-60.340224L626.449408 510.43328 314.614784 198.598656c-16.662528-16.662528-16.662528-43.677696 0-60.340224 16.661504-16.662528 43.676672-16.662528 60.3392 0L716.879872 480.18432c10.860544 10.860544 14.642176 26.120192 11.343872 40.04352z" fill="#323233"></path></svg>
// const audioicon = <svg style={iconcss} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" ><path d="M725.333333 298.666667v426.666666h-85.333333V298.666667h85.333333zM384 298.666667v426.666666H298.666667V298.666667h85.333333z m-170.666667 128v170.666666H128v-170.666666h85.333333z m682.666667 0v170.666666h-85.333333v-170.666666h85.333333z m-341.333333-256v682.666666h-85.333334V170.666667h85.333334z" fill="currentColor" p-id="2312"></path></svg>
// const min_ht = 550

function Play2(props) {
    const { msgs, backtostart } = props
    const [selectindex, setSelectindex] = useState(0) // showing the first image
    const [refready, setRefready] = useState(false)

    const [ff, setFf] = useState('')
    const [img, setImg] = useState('')
    const [name, setName] = useState('')
    const [txt, setTxt] = useState('')
    const [emojiascii, setEmojiascii] = useState('')
    const [audio, setAudio] = useState('')

    const p5ref = useRef()
    const device = Checkdevice()

    useEffect(() => {
        document.addEventListener("keydown", leftrightspacefunc, false);
        document.addEventListener("keydown", leftrightspacefunc, false);
        return () => {
            document.removeEventListener("keydown", leftrightspacefunc, false);
        };
    }, [])

    useEffect(() => {
        if (p5ref.current) {
            setRefready(true)
        }
    }, [p5ref])

    useEffect(()=>{
        if (props.msgs && props.msgs[selectindex]) {
            const { img = '', name = '', txt = '', emojiascii, audio, ff='' } = props.msgs[selectindex]
            setImg(img)
            setName(name)
            setTxt(txt)
            setEmojiascii(emojiascii)
            setAudio(audio)
            setFf(ff)
        }
    },[selectindex])

    const updateback = () => {
        setSelectindex((i) => {
            const finalindex = i === 0
                ? msgs.length - 1
                : i - 1
            return finalindex
        })
    }

    const updatenext = () => {
        setSelectindex((i) => {
            const finalindex = i === msgs.length - 1
                ? 0
                : i + 1
            return finalindex
        })
    }

    function leftrightspacefunc(e) {
        if (e.keyCode === 39) {
            updatenext()
        }
        if (e.keyCode === 37) {
            updateback()
        }
        if (e.keyCode === 32) {
            setSelectindex((i) => {
                var audio = new Audio(msgs[i]['audio'])
                audio.play()
                return i
            })
        }
    }


    const playaudio = (audio) => {
        if (audio) {
            var newa = new Audio(audio)
            newa.play()
        }
    }


    if (device === 'MOBILE') {
        return <div className="play2_rootmobile">
            {/* <button className="Start_backbtn" onClick={backtostart}>Back</button> */}
            {msgs && msgs.length > 0
                ? msgs.map((msg, index) => {
                    const { img, txt, name, audio, emojiascii } = msg
                    return <div key={index + 'card'} className="play2_cardmobile" style={{ marginBottom: msgs.length - 1 === index ? 10 : 0 }} >
                        {img && img.length > 0 ? <img className="play2_cardtopimgmobile" src={img} alt="" /> : null}
                        <div className="play2_colmobile">
                            <div className="play2_btmname">{name}</div>
                            <div className={img ? "play2_btmtxtmobile" : "play2_btmonlytxtmobile"}>{txt}</div>
                            <div className='play2_row'>
                                {emojiascii && emojiascii.length > 0 && guestemoji
                                    ? <div className="play2_emojis">{emojiascii}</div>
                                    : <div className="play2_emojis">{''}</div>}
                                {audio ? <div className="play2_audio" onClick={() => playaudio(audio)}>{audioicon}</div> : null}
                            </div>
                        </div>
                    </div>
                })
                : null}
        </div>
    }
    else {

        return <div id="play2_root" className="play2_root" >
            <div ref={p5ref} className="play2_imgbg">
                {/* <button className="Start_backbtn" onClick={backtostart}>Back</button> */}
                {refready
                    ? <ReactP5Wrapper
                        sketch={(p5) => {
                            const { clientHeight, clientWidth } = p5ref.current
                            let size = 30
                            p5.setup = () => {
                                p5.colorMode(p5.HSB);
                                p5.noStroke();
                                p5.createCanvas(clientWidth, clientHeight)
                                p5.smooth();
                                p5.clear()
                                p5.angleMode(p5.DEGREES)
                                p5.noLoop()
                            };
                            p5.draw = () => {
                                for (let x = 0; x < p5.width + size; x = x + size) {
                                    for (let y = 0; y <= p5.height + size; y = y + size) {
                                        p5.push()
                                        p5.fill('pink');
                                        p5.translate(x, y)
                                        p5.rectMode(p5.CENTER)
                                        p5.square(0, 0, size, 5);
                                        p5.pop()
                                    }
                                }
                            }
                        }}>

                    </ReactP5Wrapper>
                    : null}
                <div className="play2_back" onClick={updateback}>{arrowicon}</div>
                <div className="play2_next" onClick={updatenext}>{arrowicon}</div>

                <div className="play2_card" style={{ fontFamily: ff, position: 'absolute' }}>
                    {(img.length>0)
                        ? <div className="play2_cardtopimg" style={{ backgroundImage: `url(${img})` }} />
                        : null}
                    <div className={img.length>0 ? "play2_cardbtmtxt" : 'play2_cardtxtonly'}>
                        <div className="play2_col">
                            <div className="play2_btmname"> {name}</div>
                            <div className="play2_btmtxt"> {txt}</div>
                        </div>
                        <div className='play2_row'>
                            {emojiascii.length > 0 && guestemoji
                                ? <div className="play2_emojis">{emojiascii}</div>
                                : <div className="play2_emojis">{''}</div>}
                            <div className="play2_audio" onClick={() => playaudio(audio)}>{audioicon}</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    }
}

export default Play2;