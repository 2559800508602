import React, { useEffect, useState } from 'react'
import { MainMenu, Header, MainSelectionBox, } from '../components'
import { PRESENCELOC_ABBREV_MAP, IMG_ALT, ABBREVCITY_CURRENCY_MAP, EMPTY, FS_LH_MAP, WEB, READABLE_VENDORTYPE_MAP, d_fs, e_fs, f_fs, c_fs, MAX_WEB, SUB_FF, BOXSHADOW_CARD, TIER_DIAMOND_POSITION, PRICELIST_FILTER_WEDDING, FILTERS_THAT_CHECKED_ONCE, READABLE_PREACTUAL_MAP } from '../../../config/constants'
import { USER_PL_FIRST_READ_ALL, USER_PL_FIRST_READ_VTS, USER_PL_SAVESETTINGSW, USER_PL_APPLYFILTER, USER_PL_SAVESETTINGSE, USER_PL_SECOND_READ_ALL, USER_PL_SECOND_READ_VTS } from '../../../config/api'
import { Retrieve_personal_info, getS3fullpath_withbucketname } from '../../../config/commonfunctions'
import { ScreenWidth, Processmoney, Checkdevice, Loading } from '../../../components'
import { CLEARSTYLE } from '../../../config/cssconstants'
import Slider from 'rc-slider';
import axios from 'axios'
import 'rc-slider/assets/index.css';
import './Pricelist.css'

const PATHNAME_USERTYPE_MAP = {
    '/pricelist': 'Pricelist',
    '/promo': 'Promo'
}
const Range = Slider.Range;

const { token } = Retrieve_personal_info()

function makeBatches(extra_plids, renderpl_count) {
    var batches = []
    for (let i = 0; i < extra_plids.length;) {
        var batch = []
        for (let j = 0; j < renderpl_count; j++, i++) {
            if (extra_plids[i]) {
                batch.push(extra_plids[i])
            }
            else {
                break
            }
        }
        batches.push(batch)
    }
    return batches
}

const minrange = 0
const maxrange = 50000
const minmaxstep = 100

const NONE = 0
const ASCENDING = 1
const DESCENDING = 2

const Cards = (props) => {

    const { cardwt, cardht, cardmargin, namefs, wedtypefs, currencyfs, displaynamefs, vtfs, pldata, device } = props
    return pldata.map((item, index) => {
        const { _id, name, price, price_fixedval, price_rangestartval, price_rangeendval, currency, displayname, username, profileimg_id, curr_vendortype, logoimg, wedtype, vti_id, tags = [] } = item
        const { tier } = vti_id
        const { link } = profileimg_id
        const pldomclick = () => { document.getElementById('pldata_' + _id).click() }

        const solemnization_text = tags.length> 0 && tags.includes('Solemnization') ?  ', Solemnization':''
        return <div className='Pricelistcard_root' key={_id + index} style={{ width: cardwt, height: cardht, margin: cardmargin, border: '1px solid var(--subgrey)', fontFamily: 'Lato', borderRadius: 7, color: 'var(--maindark)', backgroundColor: '#ffffff', overflow: 'hidden', position: 'relative' }}>
            {tier === 0
                ? <div className='VCARD_DIAMONDROOT' style={{ position: 'absolute', zIndex: 1 }} />
                : <div className={`VCARD_DIAMONDROOT VCARD_DIAMONDROOT_${tier}`} style={{ position: 'absolute', zIndex: 1, height: 35, width: 35, top: 0, placeContent: 'center', right: 0, margin: 3, backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                    <div
                        className='VCARD_DIAMONDCONTENT'
                        style={{
                            backgroundImage: `url('https://asset.weavebliss.com/assets/sprites/diamonds.png')`,
                            backgroundPositionY: TIER_DIAMOND_POSITION[tier].y + 'px',
                            width: 23,
                            height: 23,
                            backgroundSize: 24,
                            position: 'absolute'
                        }}
                    />
                </div>}
            <a id={'pldata_' + _id} className="Pricelist_card_seccomp Pricelist_card_a" href={'pricelistlookup/' + _id} target={"_blank"} >
                <img
                    alt={IMG_ALT}
                    src={getS3fullpath_withbucketname('VENDOR_PRICELIST_IMG', logoimg)}
                    // src={logoimg}
                    onError={(img) => img.target.src = 'https://i.ibb.co/1qTdyzx/photo-1534428954988-f5e9a9690e9b.jpg'}
                    style={{ height: 180, maxHeight: '100%', maxWidth: '100%', minWidth: '100%', width: 'min-content', objectFit: device === 'MOBILE' ? 'cover' : 'fill' }}
                />
            </a>

            <div style={{ fontSize: namefs, padding: '10px 10px 0px 10px', width: '100%', cursor: 'pointer', overflow: 'hidden', whiteSpace: 'wrap' }} onClick={pldomclick}>{name}</div>

            <div style={{ fontSize: wedtypefs, padding: '0px 10px 0px', width: '100%', cursor: 'pointer' }} onClick={pldomclick}>{READABLE_PREACTUAL_MAP[wedtype] + solemnization_text}</div>

            {{
                'Fixed': <div style={{ fontSize: currencyfs, display: 'flex', padding: 10, width: '100%', cursor: 'pointer' }} onClick={pldomclick}>
                    {`$${price_fixedval} ${currency}`}
                </div>,
                'Range': <div style={{ fontSize: currencyfs, display: 'flex', flexDirection: 'row', padding: 10, width: '100%', cursor: 'pointer' }} onClick={pldomclick}>
                    <div>{`$${price_rangestartval}`}</div>
                    <div style={{ padding: '0px 3px' }}>{`-`}</div>
                    <div>{`$${price_rangeendval} ${currency}`}</div>
                </div>
            }[price]}
            <div className='Pricelist_card_btmnamevt' style={{ bottom: 0, position: 'absolute' }}>
                <a
                    className="Pricelist_card_a"
                    href={'vendorlookup/' + username}
                    target={"_blank"}
                    style={{ display: 'flex', flexDirection: 'row', minHeight: '100%', width: '100%', alignItems: 'center', alignSelf: 'center', color: '#707070', fontSize: 12, padding: '12px 10px', color: 'var(--maindark)' }}
                >
                    <div style={{ minWidth: 40, minHeight: 40, marginRight: 5, backgroundImage: 'url(' + getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', link) + ')', backgroundSize: 'cover', borderRadius: 100, border: '1px solid var(--subgrey)' }} />
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: 'transparent' }}>
                        <div style={{ fontSize: displaynamefs }}>{displayname}</div>
                        <div style={{ fontSize: vtfs, display: 'flex', flexDirection: 'row', color: 'var(--subdark)' }}>
                            {curr_vendortype.map((vt, i) => {
                                return i === 0 && curr_vendortype.length === 2
                                    ? <div key={index + '_' + i + '_' + vt} style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ whiteSpace: 'nowrap' }}>{READABLE_VENDORTYPE_MAP[vt]}</div>
                                        <div style={{ padding: '0px 2px', whiteSpace: 'nowrap' }}>/</div>
                                    </div>
                                    : <div key={index + '_' + i + '_' + vt}>{READABLE_VENDORTYPE_MAP[vt]}</div>
                            })}
                        </div>
                    </div>
                </a>
            </div>

        </div>

    })
}

export default function Pricelist(props) {

    const device = Checkdevice()
    const wt = ScreenWidth()

    const [mobiledevice, setMobiledevice] = useState(-1) // 0 - like the normal web vendor card, 1 different type vendor card
    const [tabletdevice, setTabletdevice] = useState(-1) // 0 smaller wt card, 1,  wider wt card

    const [firsttime, setFirsttime] = useState(true) // the gate to detect if is firsttime
    const [loaded, setLoaded] = useState(false) // default is not loaded
    const [abbrevloc, setAbbrevloc] = useState(EMPTY) // user location, will fetch frm backend when firsttime
    const [currcat, setCurrcat] = useState('Wedding') // Wedding or Event
    const [usertype, setUsertype] = useState('Wedding')
    const [currvt, setCurrvt] = useState('All') // single All, Accessories,......
    const [dataloaded, setDataloaded] = useState(false) // used in catFetch & vtFetch & initFetch & to check whether render or nt
    const [pldata, setPldata] = useState([]) // data of all pricelist to be rendered : [{name, price, price_fixedval,price_rangestartval,price_rangeendval,_id, displayname}, {...}, {...} ]
    const [pldatahover, setPldatahover] = useState([]) // maintain the hover state of the pricelistcard
    const [loc, setFullloc] = useState('') // location full name

    // [ Actual-wedding, Pre-wedding, Pre+Actual, Personal, Corporate event ] filter
    const [filterarr, setFilterarr] = useState([])
    const [currfilters, setCurrfilters] = useState([]) // use to store filter arr, in booleans

    // sldier value
    const [currency, setCurrency] = useState(EMPTY)
    const [modalminmax, setModalminmax] = useState([minrange, maxrange])
    const [currminmax, setCurrminmax] = useState([minrange, maxrange])
    const [rangereset, setRangereset] = useState(false)

    // slider price sort
    const [modalpricesort, setModalpricesort] = useState(NONE) // 0 : NONE, 1 : ASCENDING, 2 : DESCENDING
    const [currpricesort, setCurrpricesort] = useState(NONE) // 0 : NONE, 1 : ASCENDING, 2 : DESCENDING

    // gt extra pl_ids to render or nt
    const [gtextra, setGtextra] = useState(false)// indicatie whether gt extra pl_ids to render
    const [batches, setBatches] = useState([]) // batch of array of plids, every array is based on the renderplcount
    const [batch_pldata, setBatch_pldata] = useState([])
    const [rendernow, setRendernow] = useState(false)

    const [name, setName] = useState('')
    const [spousename, setSpousename] = useState('')
    const [date_w, setDate_w] = useState('')

    const [filterpricelistsetting_w, setFilterpricelistsetting_w] = useState({})
    const [filterpricelistsetting_e, setFilterpricelistsetting_e] = useState({})
    const [filterpricelistrange_e, setFilterpricelistrange_e] = useState({})
    const [filterpricelistrange_w, setFilterpricelistrange_w] = useState({})

    async function initFetch(currcat) {
        try {
            let options = {
                method: 'POST',
                url: currvt === 'All' ? USER_PL_FIRST_READ_ALL : USER_PL_FIRST_READ_VTS,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { currvt, currcat }
            }

            await axios(options).then((response) => {
                if (response.status === 200) {
                    const { loc, pldata, extra_plids, gt_extra, renderpl_count, name, spousename, date_w, filterpricelistsetting_w, filterpricelistsetting_e, filterpricelistrange_e, filterpricelistrange_w } = response.data// number of card to render, supply by backend
                    const batches = makeBatches(extra_plids, renderpl_count)// assemble extra_plids into batches
                    setBatches(batches)
                    setAbbrevloc(PRESENCELOC_ABBREV_MAP[loc])
                    setCurrency(ABBREVCITY_CURRENCY_MAP[PRESENCELOC_ABBREV_MAP[loc]])
                    setFullloc(loc)
                    setDataloaded(true)
                    setPldata(pldata)
                    var temp = []
                    for (let i = 0; i < pldata.length; i++) {
                        temp.push('')
                    }
                    setPldatahover(temp)
                    setGtextra(gt_extra)
                    setName(name)
                    setSpousename(spousename)
                    setDate_w(date_w)
                    setFirsttime(false)
                    setLoaded(true)

                    // ------------------------------------------------------------

                    // filterarr (Wedding) - Actual Wedding, Pre-Wedding, Pre + Actual
                    //                        - true/false,     true/false,  true/false

                    // filterarr (Event) - Personal Party,  Corporate Event
                    //                      - true/false,      true/false, 

                    // ------------------------------------------------------------

                    // modalminmax    - [0, 50000]

                    // ------------------------------------------------------------

                    // modalpricesort - low high,    high low
                    //                - true/false,  true/false      

                    // ------------------------------------------------------------

                    setFilterpricelistsetting_w(JSON.parse(JSON.stringify(filterpricelistsetting_w)))
                    setFilterpricelistsetting_e(JSON.parse(JSON.stringify(filterpricelistsetting_e)))
                    setFilterpricelistrange_e(JSON.parse(JSON.stringify(filterpricelistrange_e)))
                    setFilterpricelistrange_w(JSON.parse(JSON.stringify(filterpricelistrange_w)))

                    if (currcat === 'Wedding') {
                        const { filteritem, pricesort } = filterpricelistsetting_w[currvt]
                        const { min, max } = filterpricelistrange_w[currvt][PRESENCELOC_ABBREV_MAP[loc]]
                        setFilterarr(filteritem.slice())
                        setModalpricesort(parseInt(pricesort))
                        setModalminmax([min, max])

                        setCurrfilters(filteritem.slice())
                        setCurrpricesort(parseInt(pricesort))
                        setCurrminmax([min, max])

                    }
                    else if (currcat === 'Event') {
                        const { filteritem, pricesort } = filterpricelistsetting_e[currvt]
                        const { min, max } = filterpricelistrange_e[currvt][PRESENCELOC_ABBREV_MAP[loc]]
                        setFilterarr(filteritem.slice())
                        setModalpricesort(parseInt(pricesort))
                        setModalminmax([min, max])

                        setCurrfilters(filteritem.slice())
                        setCurrpricesort(parseInt(pricesort))
                        setCurrminmax([min, max])
                    }

                }
                else window.location = '/'
            })

        }
        catch (e) {
            console.log('e', e)
            // window.location = '/'
        }
    }

    useEffect(() => {
        const { usertype } = props
        if (usertype && firsttime) {
            setCurrcat(usertype)
            setUsertype(usertype)
            setCurrpricesort([false, false])
            initFetch(usertype)
        }
    }, [props])

    useEffect(() => {

        if (!firsttime) {
            if (currcat === 'Wedding') {
                const { filteritem, pricesort } = filterpricelistsetting_w[currvt]
                const { min, max } = filterpricelistrange_w[currvt][PRESENCELOC_ABBREV_MAP[loc]]

                setFilterarr(filteritem)
                setModalpricesort(parseInt(pricesort))
                setModalminmax([min, max])

                setCurrfilters(filteritem)
                setCurrpricesort(parseInt(pricesort))
                setCurrminmax([min, max])

            }
            else if (currcat === 'Event') {
                const { filteritem, pricesort } = filterpricelistsetting_e[currvt]
                const { min, max } = filterpricelistrange_e[currvt][PRESENCELOC_ABBREV_MAP[loc]]
                setFilterarr(filteritem)
                setModalpricesort(parseInt(pricesort))
                setModalminmax([min, max])

                setCurrfilters(filteritem)
                setCurrpricesort(parseInt(pricesort))
                setCurrminmax([min, max])
            }
        }

    }, [currvt, currcat])

    useEffect(() => {
        // if continue scrolling then will update the pricelist by calling more new pricelist
        if (rendernow) {
            try {
                let options = {
                    method: 'POST',
                    url: currvt === 'All' ? USER_PL_SECOND_READ_ALL : USER_PL_SECOND_READ_VTS,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { plids: batches[0] }
                }
                axios(options).then((response) => {

                    if (response.status === 200) {
                        // the data return, its position will not remain intact, require to maintain its pos here!
                        var resdata = []

                        if (batches[0]) {
                            for (let x = 0; x < batches[0].length; x++) {
                                for (let i = 0; i < response.data.pldata.length; i++) {
                                    if (response.data.pldata[i]._id === batches[0][x]._id) {
                                        resdata.push(response.data.pldata[i])
                                    }
                                }
                            }
                        }

                        setBatch_pldata(resdata) // this will trigger useeffect of batch_pldata
                        batches.shift()
                        setBatches(batches)
                        setRendernow(false)
                    }
                    else window.location = '/'
                })
            }
            catch (e) {
                window.location = '/'
            }
        }
    }, [rendernow])

    useEffect(() => {

        var updateScrolling = async () => { // second fetch trigger when stub div being scroll and reach by user
            // once scroll down, need to extend content by querying db
            // firsttime, gtextra, extraplids
            if (gtextra) {
                const bottomalrdy = document.querySelector('div.bottomalrdy')
                const lastLiOffset = bottomalrdy.offsetTop + bottomalrdy.clientHeight
                const pageOffset = window.pageYOffset + window.innerHeight
                var bottomOffset = 0
                // console.log('scrolling -----------------------------------------------------------------')
                // console.log('pageOffset', pageOffset)
                // console.log('bottomalrdy.clientHeight', bottomalrdy.clientHeight)
                // console.log('bottomalrdy.offsetTop', bottomalrdy.offsetTop)
                // console.log('lastLiOffset - bottomOffset', lastLiOffset - bottomOffset)

                if (Math.ceil(pageOffset) >= (lastLiOffset - 2 - bottomOffset)) {
                    if (batches && batches.length > 0 && rendernow === false) {
                        setRendernow(true)
                    }
                    else window.removeEventListener('scroll', updateScrolling)
                }
            }
        }

        window.addEventListener('scroll', updateScrolling)

        return () => window.removeEventListener('scroll', updateScrolling)

    }, [gtextra])

    useEffect(() => {
        if (batch_pldata.length > 0) {
            setPldata([...pldata, ...batch_pldata])

            var temp = []
            for (let i = 0; i < (pldata.length + batch_pldata.length); i++) {
                temp.push('')
            }
            setPldatahover(temp)
        }
    }, [batch_pldata])

    useEffect(() => {
        if (rangereset) { setRangereset(false) }
    }, [rangereset])

    // for pricelistcard
    useEffect(() => {

        if (device === 'MOBILE') {
            if (wt < 480) {
                setMobiledevice(0)
            }
            else if (wt < 700) {
                setMobiledevice(1)
            }
            else {
                if (wt < 800) {
                    setMobiledevice(2)
                }
            }

        }
        else if (device === 'TABLET') {
            if (wt < 797) {
                setTabletdevice(0)
            }
            else {
                setTabletdevice(1)
            }
        }
    }, [wt])


    function changecurrvt(vt) { // change current vendortype
        if (currvt !== vt) {
            setCurrvt(vt)

            setGtextra(false)
            setBatches([])
            setBatch_pldata([])
            setPldata([])
            setRendernow(false)
            setDataloaded(false)

            try {



                const data = currcat === 'Wedding'
                    ? {
                        currvt: vt, currcat,
                        filters: filterpricelistsetting_w[vt]['filteritem'], sort: filterpricelistsetting_w[vt]['pricesort'],
                        minp: filterpricelistrange_w[vt][PRESENCELOC_ABBREV_MAP[loc]].min, maxp: filterpricelistrange_w[vt][PRESENCELOC_ABBREV_MAP[loc]].max
                    }
                    : {
                        currvt: vt, currcat,
                        filters: filterpricelistsetting_e[vt]['filteritem'], sort: filterpricelistsetting_e[vt]['pricesort'],
                        minp: filterpricelistrange_e[vt][PRESENCELOC_ABBREV_MAP[loc]].min, maxp: filterpricelistrange_e[vt][PRESENCELOC_ABBREV_MAP[loc]].max
                    }

                let options = {
                    method: 'POST',
                    url: vt === 'All' ? USER_PL_FIRST_READ_ALL : USER_PL_FIRST_READ_VTS,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: data
                }

                axios(options).then((response) => {
                    if (response.status === 200) {
                        const { loc, pldata, extra_plids, gt_extra, renderpl_count, name, spousename, date_w } = response.data// number of card to render, supply by backend
                        const batches = makeBatches(extra_plids, renderpl_count)// assemble extra_plids into batches

                        setBatches(batches)
                        setAbbrevloc(PRESENCELOC_ABBREV_MAP[loc])
                        setCurrency(ABBREVCITY_CURRENCY_MAP[PRESENCELOC_ABBREV_MAP[loc]])
                        setFullloc(loc)
                        setDataloaded(true)
                        setPldata(pldata)
                        var temp = []
                        for (let i = 0; i < pldata.length; i++) { temp.push('') }
                        setPldatahover(temp)
                        setGtextra(gt_extra)
                        setName(name)
                        setSpousename(spousename)
                        setDate_w(date_w)
                        setFirsttime(false)
                        setLoaded(true)

                    }
                    else window.location = '/'
                })



            }
            catch (e) { window.location = '/' }

        }
    }

    const changecurrcat = () => { // change current category
        return
        if (currcat === 'Wedding') {
            setCurrcat('Event')
            setDataloaded(false)

            setGtextra(false)
            setBatches([])
            setBatch_pldata([])
            setPldata([])

            initFetch('Event')
        }
        else if (currcat === 'Event') {
            setCurrcat('Wedding')
            setDataloaded(false)

            setGtextra(false)
            setBatches([])
            setBatch_pldata([])
            setPldata([])

            initFetch('Wedding')
        }
    }

    const rmvsinglefilter = (singlef, index) => { // remove a single filter
        const local_currfilters = currfilters.slice()
        local_currfilters[index] = !local_currfilters[index]
        setCurrfilters(local_currfilters)

        setGtextra(false)
        setBatches([])
        setBatch_pldata([])
        setPldata([])

    }

    const rmvsinglepricesort = (index) => { // remove a single pricesort
        const local_currpricesort = currpricesort.slice()
        local_currpricesort[index] = !local_currpricesort[index]
        setCurrpricesort(local_currpricesort)

        setGtextra(false)
        setBatches([])
        setBatch_pldata([])
        setPldata([])

    }



    // A SINGLE PRICELIST CARD DOM ELEMENTS


    function imgDom(link) {
        return link
        return <img
            alt={IMG_ALT}
            src={link}
            style={{ width: '99.5%', height: '99.5%', borderRadius: '50%', justifyContent: 'center', display: 'flex', alignItems: 'center', border: '1px solid #f4f4f4' }}
        />
    }

    function displaynameDom(displayname) {
        // return <div style={{ display: 'flex', padding: 5, }}>{displayname}</div>
        if (device === 'MOBILE') {
            if (displayname.length > 15) { // char more than 20, hidden
                return <div style={{ display: 'flex', padding: '0px 10px', width: '100%', overflow: 'hidden', justifyContent: 'flex-start', fontSize: f_fs, fontWeight: 'bold' }}>{displayname.substring(0, 20) + '...'}</div>;
            }
            else {
                return <div style={{ display: 'flex', padding: '0px 10px', width: '100%', overflow: 'auto', justifyContent: 'flex-start', fontSize: f_fs, fontWeight: 'bold' }}>{displayname}</div>;
            }
        }
        else if (device === 'TABLET') {
            if (displayname.length > 20) { // char more than 20, hidden
                return <div style={{ display: 'flex', padding: '0px 10px', width: '100%', overflow: 'hidden', justifyContent: 'flex-start', fontSize: f_fs, fontWeight: 'bold' }}>{displayname.substring(0, 25) + '...'}</div>;
            }
            else { // show the full name
                return <div style={{ display: 'flex', padding: '0px 10px', width: '100%', overflow: 'auto', justifyContent: 'flex-start', fontSize: f_fs, fontWeight: 'bold' }}>{displayname}</div>;
            }
        }
        else { // WEB
            if (displayname.length > 20) { // char more than 20, hidden
                return <div style={{ display: 'flex', padding: '0px 10px', width: '100%', overflow: 'hidden', justifyContent: 'flex-start', fontSize: f_fs, fontWeight: 'bold' }}>{displayname.substring(0, 25) + '...'}</div>;
            }
            else { // show the full name
                return <div style={{ display: 'flex', padding: '0px 10px', width: '100%', overflow: 'auto', justifyContent: 'flex-start', fontSize: f_fs, fontWeight: 'bold' }}>{displayname}</div>;
            }
        }

    }

    function vtDom(vt) {
        const css = { margin: '0px 10px', backgroundColor: 'white', fontSize: f_fs, color: '#707070' }
        if (vt.length === 1) return <div style={css}>{READABLE_VENDORTYPE_MAP[vt[0]]}</div>
        else return <div style={css}>{READABLE_VENDORTYPE_MAP[vt[0]] + ' / ' + READABLE_VENDORTYPE_MAP[vt[1]]}</div>
    }

    function nameDom(name) {
        var fs = device === 'TABLET' || device === 'WEB' ? d_fs : e_fs
        if (device === 'MOBILE') {
            if (name.length > 40) { // char more than 20, hidden
                return <div style={{ display: 'flex', padding: '0px 10px', width: '100%', overflow: 'hidden', justifyContent: 'center', fontSize: d_fs, fontWeight: 600 }}>{name.substring(0, 40) + '...'}</div>;
            }
            else {
                return <div style={{ display: 'flex', padding: '0px 10px', width: '100%', overflow: 'auto', justifyContent: 'center', fontSize: d_fs, fontWeight: 600 }}>{name}</div>;
            }
        }
        else if (device === 'TABLET') {
            if (name.length > 20) { // char more than 20, hidden
                return <div style={{ display: 'flex', padding: '0px 10px', width: '100%', overflow: 'hidden', justifyContent: 'center', fontSize: fs, fontWeight: 600 }}>{name.substring(0, 40) + '...'}</div>;
            }
            else { // show the full name
                return <div style={{ display: 'flex', padding: '0px 10px', width: '100%', overflow: 'auto', justifyContent: 'center', fontSize: fs, fontWeight: 600 }}>{name}</div>;
            }
        }
        else {
            if (name.length > 20) { // char more than 20, hidden
                return <div style={{ display: 'flex', padding: '0px 10px', width: '100%', overflow: 'hidden', justifyContent: 'center', fontSize: fs, fontWeight: 600 }}>{name.substring(0, 40) + '...'}</div>;
            }
            else { // show the full name
                return <div style={{ display: 'flex', padding: '0px 10px', width: '100%', overflow: 'auto', justifyContent: 'center', fontSize: fs, fontWeight: 600 }}>{name}</div>;
            }
        }
    }

    function wedtypeDom(wedtype) {
        // var css = { backgroundColor: 'white', fontSize: f_fs, color: '#aaaaaa' }
        var css = { backgroundColor: 'white', fontSize: f_fs, color: '#707070' }
        return <div style={css}>{wedtype}</div>
    }

    function priceDom(price, price_dom, price_fixedval, currency, price_rangestartval, price_rangeendval, device) {
        var fs
        var lh
        if (device === 'WEB') {
            fs = c_fs
            lh = FS_LH_MAP[c_fs]
        }
        else if (device === 'TABLET') {
            fs = c_fs
            lh = FS_LH_MAP[c_fs]
        }
        else {
            fs = c_fs
            lh = FS_LH_MAP[c_fs]

        }
        const pricecolor = 'rgb(226, 90, 90)'
        const bordercolor = 'transparent'
        if (price === 'Fixed') {
            price_dom = <div
                className={'pricing_fixed'}
                style={{
                    display: 'flex',
                    color: pricecolor,
                    border: '1px solid ' + bordercolor,
                    borderRadius: 5,
                    padding: 3,
                    overflowX: 'auto',
                    fontSize: fs,
                    lineHeight: lh,
                    fontWeight: 800
                }}>{'$ ' + Processmoney(price_fixedval) + ' ' + currency}</div>
        }
        else if (price === 'Range') {
            price_dom = <div
                className={'pricing_range'}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    color: pricecolor,
                    border: '1px solid ' + bordercolor,
                    borderRadius: 5,
                    padding: 3,
                    overflowX: 'auto',
                    fontSize: fs,
                    lineHeight: lh,
                    fontWeight: 800
                }}>{'$ ' + Processmoney(price_rangestartval) + ' - $ ' + Processmoney(price_rangeendval) + ' ' + currency}</div>
        }
        return price_dom
    }

    function filterDom_modal(wedtype, close) {
      
        const { active, Keys } = PRICELIST_FILTER_WEDDING[currvt] // currvt -> All

        const apply = () => {

            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });

            setGtextra(false)
            setBatches([])
            setBatch_pldata([])
            setPldata([])
            setRendernow(false)
            setDataloaded(false)

            let final_modalpricesort = modalpricesort
            console.log('filterarr', filterarr)
            if (filterarr.includes('Price ( High - Low )')) {
                final_modalpricesort = DESCENDING
                setModalpricesort(DESCENDING)
            }
            else if (filterarr.includes('Price ( Low - High )')) {
                final_modalpricesort = ASCENDING
                setModalpricesort(ASCENDING)
            }
            else{
                final_modalpricesort=NONE
                setModalpricesort(0)
            }

            try {
                let options = {
                    method: 'POST',
                    url: USER_PL_APPLYFILTER,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { currvt, currcat, filters: filterarr, minp: modalminmax[0], maxp: modalminmax[1], sort: final_modalpricesort }
                }

                axios(options).then((response) => {
                    if (response.status === 200) {
                        const { loc, pldata, extra_plids, gt_extra, renderpl_count, name, spousename, date_w } = response.data// number of card to render, supply by backend
                        const batches = makeBatches(extra_plids, renderpl_count)// assemble extra_plids into batches

                        setBatches(batches)
                        setAbbrevloc(PRESENCELOC_ABBREV_MAP[loc])
                        setCurrency(ABBREVCITY_CURRENCY_MAP[PRESENCELOC_ABBREV_MAP[loc]])
                        setFullloc(loc)
                        setDataloaded(true)
                        setPldata(pldata)
                        var temp = []
                        for (let i = 0; i < pldata.length; i++) { temp.push('') }
                        setPldatahover(temp)
                        setGtextra(gt_extra)
                        setName(name)
                        setSpousename(spousename)
                        setDate_w(date_w)
                        setFirsttime(false)
                        setLoaded(true)

                        if (currcat === 'Wedding') {
                            let new_filterpricelistsetting_w = JSON.parse(JSON.stringify(filterpricelistsetting_w))
                            let new_filterpricelistrange_w = JSON.parse(JSON.stringify(filterpricelistrange_w))
                            new_filterpricelistsetting_w[currvt]['pricesort'] = modalpricesort
                            new_filterpricelistsetting_w[currvt]['filteritem'] = filterarr.slice()
                            new_filterpricelistrange_w[currvt][PRESENCELOC_ABBREV_MAP[loc]] = { min: modalminmax[0], max: modalminmax[1] }
                            setFilterpricelistsetting_w(new_filterpricelistsetting_w)
                            setFilterpricelistrange_w(new_filterpricelistrange_w)
                        }
                        else if (currcat === 'Event') {
                            let new_filterpricelistsetting_e = JSON.parse(JSON.stringify(filterpricelistsetting_e))
                            let new_filterpricelistrange_e = JSON.parse(JSON.stringify(filterpricelistrange_e))
                            new_filterpricelistsetting_e[currvt]['pricesort'] = modalpricesort
                            new_filterpricelistsetting_e[currvt]['filteritem'] = filterarr.slice()
                            new_filterpricelistrange_e[currvt][PRESENCELOC_ABBREV_MAP[loc]] = { min: modalminmax[0], max: modalminmax[1] }
                            setFilterpricelistsetting_e(new_filterpricelistsetting_e)
                            setFilterpricelistrange_e(new_filterpricelistrange_e)
                        }

                        setCurrfilters(filterarr.slice())
                        setCurrpricesort(parseInt(modalpricesort))
                        setCurrminmax([currminmax[0], currminmax[1]])
                        // setRendernow(true)

                        axios({
                            method: 'POST',
                            url: currcat === 'Wedding' ? USER_PL_SAVESETTINGSW : USER_PL_SAVESETTINGSE,
                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                            data: { currvt, filters: filterarr, minp: modalminmax[0], maxp: modalminmax[1], sort: final_modalpricesort, loc }
                        }).then((res) => {
                            close()
                        }).catch(e => {
                            close()
                        })
                    }
                    else window.location = '/'
                })

            }
            catch (e) {
                window.location = '/'
            }


        }

        const clearpricerange = () => {
            setModalminmax([minrange, maxrange])
            setRangereset(true)
        }

        const reset = (e) => {
            let new_filterarr = filterarr.slice()
            for (let i = 0; i < PRICELIST_FILTER_WEDDING[currvt][e.target.value].length; i++) {
                const item = PRICELIST_FILTER_WEDDING[currvt][e.target.value][i].header
                if (new_filterarr.includes(item)) {
                    new_filterarr.splice(new_filterarr.indexOf(item), 1)
                }
            }
            setFilterarr(new_filterarr)
        }

        let filtersubtitles = Keys.slice()
        let whattoremove = wedtype === 'Wedding' ? 'Event' : 'Wedding'
        filtersubtitles.splice(Keys.indexOf(whattoremove), 1) // eg, ['Price', 'Wedding', 'Event', 'Valid Year']

        var marks = {}

        const runType0 = (k) => { // can check all items
            let local_filterarr = filterarr.slice()
            if (local_filterarr.includes(k)) {
                local_filterarr.splice(local_filterarr.indexOf(k), 1)
            }
            else {
                local_filterarr.push(k)
            }




            return local_filterarr
        }

        const runType1 = (k, group) => { // only 1 item can be check within that group
            let local_filterarr = filterarr.slice()

            let headers = [] // header in filterarr that is within the group
            for (let i = 0; i < group.length; i++) {
                const { header } = group[i]
                if (local_filterarr.includes(header)) {
                    headers.push(header)
                }
            }

            if (headers.length === 0) { // if current filter do not have k
                local_filterarr.push(k)
            }
            else {
                if (headers.includes(k)) {  // if current filter have k
                    local_filterarr.splice(local_filterarr.indexOf(k), 1)
                }
                else {
                    // remove other header of the same group
                    for (let i = 0; i < headers.length; i++) {
                        local_filterarr.splice(local_filterarr.indexOf(headers[i]), 1)
                    }
                    local_filterarr.push(k)
                }

            }

            return local_filterarr
        }

        return <div className={'FMC_ROOT' + device} style={{ color: '#333333' }}>
            <div key={currcat + 'filters'} style={{ display: 'flex', flexDirection: 'column', WebkitOverflowScrolling: 'touch', }}>

                {/* PRICE RANGE, Slider */}
                <div className="FMC_TITLE">Price Range</div>
                <div className="FMC_BOX">
                    <div style={{ height: 25, width: '100%', justifyContent: 'flex-end', display: 'flex' }}>
                        <button style={CLEARSTYLE} key={wedtype + 'clear'} onClick={clearpricerange} >reset</button>
                    </div>
                    <Range
                        key={'range' + rangereset}
                        style={{ margin: device === 'MOBILE' ? '20px 10px 5px' : '10px 15%' }}
                        dots={false}
                        allowCross={false}
                        marks={marks}
                        step={minmaxstep} min={minrange} max={maxrange}
                        defaultValue={modalminmax}
                        onChange={(val) => { setModalminmax(val) }}
                    />
                    <div className="Pricelist_filtermoneyroot">
                        <div className="Pricelist_filterdollarbox" style={{ width: device === 'MOBILE' ? "45%" : "30%" }}>
                            <div className="Pricelist_filterpricelabel">min price</div>
                            <div className="Pricelist_filterdollar">{'$ ' + Processmoney(modalminmax[0] ? modalminmax[0] : 0) + ' ' + currency}</div>
                        </div>
                        <div className="Pricelist_filterhyphen">-</div>
                        <div className="Pricelist_filterdollarbox" style={{ width: device === 'MOBILE' ? "45%" : "30%" }}>
                            <div className="Pricelist_filterpricelabel">max price</div>
                            <div className="Pricelist_filterdollar">{'$ ' + Processmoney(modalminmax[1] ? modalminmax[1] : 0) + ' ' + currency}</div>
                        </div>
                    </div>
                </div>

                {active
                    ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {filtersubtitles.map((filtertitle, x) => {

                            let checkboxarr = []

                            PRICELIST_FILTER_WEDDING[currvt][filtertitle]
                                .map(({ header, type }, y) => {

                                    const cbclicked = () => {
                                        setFilterarr(
                                            type === 0
                                                ? runType0(header)
                                                : runType1(header, PRICELIST_FILTER_WEDDING[currvt][filtertitle])
                                        )
                                    }

                                    checkboxarr.push(
                                        <div key={header + y} className="FMC_cbrow" onClick={cbclicked}>
                                            <input className="FMC_cb" type="checkbox" checked={filterarr.includes(header)} onChange={cbclicked} />
                                            <label className="FMC_cblbl">{header}</label>
                                        </div>
                                    )

                                })

                            return <div className='FMC_mainbox' key={filtertitle}>
                                <div className="FMC_TITLE">{filtertitle}</div>
                                <div className='FMC_BOX'>
                                    <div className='FMC_RESETBTNBOX'>
                                        <button
                                            style={CLEARSTYLE}
                                            key={wedtype + 'clear'}
                                            value={filtertitle}
                                            onClick={reset}
                                        >
                                            reset
                                        </button>
                                    </div>
                                    {checkboxarr}
                                </div>
                            </div>

                        })}
                    </div>
                    : null}

            </div>
            <div className="FMC_APPLYBTNBOX">
                <button
                    className='Rsvpexplorer_portalsave'
                    style={{ position: 'relative', bottom: 0, backgroundColor: '#ffffff', color: 'var(--maindark)', border: '1px solid var(--maindark)', minHeight: 45, width: '100%', margin: 0 }}
                    onClick={apply}
                >
                    Apply
                </button>
            </div>
        </div>

    }

    const renderData = () => {
        // mobile 1
        // landscape mobile 2
        // tablet 4
        // web 6

        var NUM
        if (wt > 1600) {
            NUM = 5
        }
        else if (wt > WEB) {
            NUM = 4
        }
        else if (wt > 900) {
            NUM = 3
        }
        else if (wt > 600) {
            NUM = 2
        }
        else {
            NUM = 1
        }

        const profileimgdim = device === 'WEB' ? 35 : device === 'TABLET' ? 30 : 35

        const CARDWT = wt > MAX_WEB ? (1980 * 0.9) / NUM : (wt * 0.9) / NUM
        var COLS = 0

        const rowroot_css = { display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignContent: 'center' } //, paddingTop: 20 
        const pricelistroot_css = { display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', paddingTop: 30 }
        const singlepricelisttransparent_css = { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', borderRadius: 5, margin: 7, width: CARDWT, height: CARDWT + 40 }

        if (dataloaded) {
            var col_root = []
            var row_root = []
            for (let i = 0; i < pldata.length; i++) {
                const { name, price, price_fixedval, price_rangestartval, price_rangeendval, currency, displayname, username, profileimg_id, curr_vendortype, _id, logoimg, wedtype } = pldata[i]
                const { link } = profileimg_id

                var price_dom = priceDom(price, price_dom, price_fixedval, currency, price_rangestartval, price_rangeendval, device) // PRICE
                var name_dom = nameDom(name) // PRICELIST'S NAME
                var displayname_dom = displaynameDom(displayname) // VENDOR'S DISPLAYNAME
                var img_dom = imgDom(getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', link)) // PROFILE IMG
                var vt_dom = vtDom(curr_vendortype) // VENDORTYPE/S OF THE PL
                var wedtype_dom = wedtypeDom(wedtype, device)

                row_root.push(
                    <div key={name + i} style={{ borderRadius: 5, fontFamily: SUB_FF }}

                        onMouseOver={(e) => {
                            if (device !== 'tablet') {
                                var temp = pldatahover.slice()
                                temp[i] = BOXSHADOW_CARD
                                setPldatahover(temp)
                            }
                        }}
                        onMouseLeave={() => {
                            if (device !== 'tablet') {
                                var temp = pldatahover.slice()
                                temp[i] = ''
                                setPldatahover(temp)
                            }
                        }}
                    >
                        <div className="Pricelist_card_root" key={name + i + 'children'} style={{ width: CARDWT, height: CARDWT + 40, boxShadow: pldatahover[i] }} >

                            <a className="Pricelist_card_firstcomp Pricelist_card_a" href={'pricelistlookup/' + _id} target={"_blank"} >
                                {name_dom}
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', whiteSpace: 'nowrap', WebkitOverflowScrolling: 'touch', justifyContent: 'center' }}>
                                    {wedtype_dom}
                                </div>
                            </a>
                            <a className="Pricelist_card_seccomp Pricelist_card_a" href={'pricelistlookup/' + _id} target={"_blank"} >
                                <img
                                    alt={IMG_ALT}
                                    src={getS3fullpath_withbucketname('VENDOR_PRICELIST_IMG', logoimg)}
                                    onError={(img) => img.target.src = 'https://i.ibb.co/1qTdyzx/photo-1534428954988-f5e9a9690e9b.jpg'}
                                    style={{ height: '100%', maxHeight: '100%', maxWidth: '100%', minWidth: '100%', width: 'min-content', alignSelf: 'center', border: '1px solid #f4f4f4' }}
                                />
                            </a>

                            <div className="Pricelist_card_thirdcomp">
                                <a className="Pricelist_card_a" href={'pricelistlookup/' + _id} target={"_blank"} style={{ display: 'flex', flexDirection: 'row', width: '95%', height: '50%', justifyContent: 'center', alignItems: 'center', borderRadius: 10, alignSelf: 'center' }}>
                                    {price_dom}
                                </a>
                                <a className="Pricelist_card_a" href={'vendorlookup/' + username} target={"_blank"} style={{ display: 'flex', flexDirection: 'row', width: '95%', height: '50%', minHeight: profileimgdim, alignItems: 'center', alignSelf: 'center', color: '#707070', borderTop: '1px solid rgb(244, 244, 244)', fontSize: f_fs }}>
                                    <div style={{ width: profileimgdim, height: profileimgdim }}>{img_dom}</div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '80%', backgroundColor: 'transparent' }}>
                                        {displayname_dom}
                                        {vt_dom}
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div >
                )

                if (i + 1 === pldata.length) { // fill up empty items with empty spaces
                    var lefthowmany = NUM - row_root.length
                    for (let j = 0; j < lefthowmany; j++) {
                        row_root.push(<div key={'Empty' + j} style={singlepricelisttransparent_css} />)
                    }
                    COLS = COLS + 1
                    col_root.push(<div key={'Col' + COLS} style={rowroot_css}>{row_root.slice()}</div>)
                }
                else if (row_root.length === NUM) { // time to add a new row
                    COLS = COLS + 1
                    col_root.push(<div key={'Col' + COLS} style={rowroot_css}>{row_root.slice()}</div>)
                    row_root = []
                }
            }

            return <div style={pricelistroot_css}>{col_root}</div>

        }
        else return null

    }

    const pricelistcards = () => {



        if (dataloaded) {
            return {
                'WEB': <ul style={{ display: 'grid', gridTemplateColumns: `repeat(auto-fit, 314px)`, justifyContent: 'center', width: '100%', padding: 10, margin: 0, }}>
                    <Cards
                        {...props}
                        pldata={pldata}
                        cardwt={300}
                        cardht={380}
                        cardmargin={7}
                        namefs={16}
                        wedtypefs={12}
                        currencyfs={18}
                        displaynamefs={14}
                        vtfs={12}
                        device={device}
                    />
                </ul>,
                'TABLET': tabletdevice === 0
                    // smaller card
                    ? <ul style={{ display: 'grid', gridTemplateColumns: `repeat(auto-fit, 344px)`, justifyContent: 'center', width: '100%', padding: 10, margin: 0, }}>
                        <Cards
                            {...props}
                            pldata={pldata}
                            cardwt={330}
                            cardht={380}
                            cardmargin={7}
                            namefs={16}
                            wedtypefs={12}
                            currencyfs={18}
                            displaynamefs={14}
                            vtfs={12}
                            device={device}
                        />
                    </ul>
                    // wider card
                    : <ul style={{ display: 'grid', gridTemplateColumns: `repeat(auto-fit, 294px)`, justifyContent: 'center', width: '100%', padding: 10, margin: 0, }}>
                        <Cards
                            {...props}
                            pldata={pldata}
                            cardwt={280}
                            cardht={380}
                            cardmargin={7}
                            namefs={16}
                            wedtypefs={12}
                            currencyfs={18}
                            displaynamefs={14}
                            vtfs={12}
                            device={device}
                        />
                    </ul>,
                'MOBILE': {
                    '-1': null,
                    '0': <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Cards
                            {...props}
                            pldata={pldata}
                            cardwt={'calc(100% - 20px)'}
                            cardht={380}
                            cardmargin={10}
                            namefs={16}
                            wedtypefs={12}
                            currencyfs={18}
                            displaynamefs={14}
                            vtfs={12}
                            device={device}
                        />
                    </div>,
                    '1': <ul style={{ display: 'grid', gridTemplateColumns: `repeat(auto-fit,50%)`, justifyContent: 'center', width: '100%', padding: 5, margin: 0, }}>
                        <Cards
                            {...props}
                            pldata={pldata}
                            cardwt={280}
                            cardht={360}
                            cardmargin={7}
                            namefs={16}
                            wedtypefs={12}
                            currencyfs={18}
                            displaynamefs={14}
                            vtfs={12}
                            device={device}
                        />
                    </ul>,
                    '2': <ul style={{ display: 'grid', gridTemplateColumns: `repeat(auto-fit, 294px)`, justifyContent: 'center', width: '100%', padding: 10, margin: 0, }}>
                        <Cards
                            {...props}
                            pldata={pldata}
                            cardwt={280}
                            cardht={360}
                            cardmargin={7}
                            namefs={16}
                            wedtypefs={12}
                            currencyfs={18}
                            displaynamefs={14}
                            vtfs={12}
                            device={device}
                        />
                    </ul>,
                }[mobiledevice.toString()]
            }[device]
        }




        // return <Pricelistcard/>
    }

    return (
        !loaded
            ? <Loading />
            : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', touchAction: 'manipulation' }}>

                <Header
                    {...props}
                    usertype={PATHNAME_USERTYPE_MAP[props.history.location.pathname]}
                    abbrev_userlocation={abbrevloc}
                    wedorevent={currcat}
                    name={name}
                    spousename={spousename}
                    date_w={date_w}
                >
                    <MainMenu {...props} selectedMainCategory={PATHNAME_USERTYPE_MAP[props.history.location.pathname]} usertype={usertype} />
                </Header>

                <div style={{ position: 'relative', top: 0, backgroundColor: 'white', width: '100%', zIndex: 3, display: 'block' }}>

                    {/* Select of VTs */}
                    <MainSelectionBox

                        {...props}
                        doneloading={()=>{}}

                        // name
                        name={currcat + ' Pricelist'}
                        from="PRICELIST"

                        // box type
                        boxtype={'FAT'}
                        selectedcat={currcat}
                        selectedvt={currvt}

                        // filter data
                        totalfilternum={parseInt(currfilters.length, 10)}

                        // dom elements for PRICELIST
                        filtermainDom={filterDom_modal}
                        updatebeforeopenPlfilterdom={() => {
                            if (currcat === 'Wedding') {
                                const { filteritem, pricesort } = filterpricelistsetting_w[currvt]
                                const { min, max } = filterpricelistrange_w[currvt][PRESENCELOC_ABBREV_MAP[loc]]
                                setFilterarr(filteritem.slice())
                                setModalpricesort(parseInt(pricesort))
                                setModalminmax([min, max])

                                setCurrfilters(filteritem.slice())
                                setCurrpricesort(parseInt(pricesort))
                                setCurrminmax([min, max])
                            }
                            else if (currcat === 'Event') {
                                const { filteritem, pricesort } = filterpricelistsetting_e[currvt]
                                const { min, max } = filterpricelistrange_e[currvt][PRESENCELOC_ABBREV_MAP[loc]]
                                setFilterarr(filteritem.slice())
                                setModalpricesort(parseInt(pricesort))
                                setModalminmax([min, max])

                                setCurrfilters(filteritem.slice())
                                setCurrpricesort(parseInt(pricesort))
                                setCurrminmax([min, max])
                            }
                        }}

                        // functions
                        vtChange={(vt) => changecurrvt(vt)}
                        nameClick={changecurrcat} // Wedding / Event 

                    />
                </div>

                {/* <div style={{ display: 'flex', flexDirection: device === 'WEB' ? 'column' : 'row', justifyContent: device === 'WEB' ? 'flex-start' : 'center', width: '100%', paddingBottom: 50 }}>
                    {renderData()}
                </div> */}

                {pricelistcards()}

                <div className='bottomalrdy' style={{ backgroundColor: 'transparent', height: 10, width: '100%' }} />
            </div>
    )

}
