import React, { useRef, useState, useEffect } from 'react'
import './Bgmusic.css'

function Bgmusic(props) {
    const { musicgate, onMusicdone, updateMusicobjs, musicobjs } = props

    const mediaRef = useRef(null)
    const [localfile, setLocalfile] = useState([])

    useEffect(() => {
        updateMusicobjs(localfile)
    }, [localfile])

    const musicinput_dom = <div style={{ cursor: 'pointer', position: 'relative' }} onClick={() => mediaRef.current.click()}>
        <input
            ref={mediaRef}
            accept=".mp3,audio/*"
            className="inputfile"
            id="formControlsFile"
            type='file'
            multiple="multiple"
            name="input-file"
            style={{ position: 'absolute', cursor: 'pointer', width: 35, height: 35, opacity: 0, padding: 0, left: 0 }}
            onChange={(e) => {
                var temp = []
                for (let i = 0; i < e.target.files.length; i++) {
                    var reader = new FileReader()
                    let file = e.target.files[i]
                    reader.onload = (e) => {

                        // if (file.type.includes("audio")) updateMusicobjs(URL.createObjectURL(file))
                        if (file.type.includes("audio")) {
                            temp.push(URL.createObjectURL(file))
                            setLocalfile((lf) => { return [...lf, URL.createObjectURL(file)] })
                        }

                    }
                    reader.readAsDataURL(file);
                }
                e.target.value = null; // users can reupload similar file
            }}
        />
        {<svg style={{ position: 'absolute', zIndex: 2, cursor: 'pointer', width: 35, height: 35, padding: 3, left: 0, verticalAlign: 'middle', fill: '#707070', overflow: 'hidden', display: 'flex', backgroundColor: 'white', borderRadius: 5, border: 'thin solid #707070' }} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1631"><path d="M383.104 802.133333C375.466667 855.04 321.493333 896 256 896c-70.698667 0-128-47.786667-128-106.666667S185.301333 682.666667 256 682.666667c14.933333 0 29.312 2.133333 42.666667 6.058666V253.098667c0-21.888 13.653333-41.216 33.706666-47.786667l245.333334-75.008c30.890667-10.069333 62.293333 13.994667 62.293333 47.786667v75.008c0 25.002667-23.424 40.96-42.666667 45.568-27.733333 6.613333-98.816 20.821333-213.333333 42.666666v450.176c0 3.84-0.298667 7.381333-0.896 10.666667z" fill="#000000" p-id="1632"></path><path d="M682.666667 554.666667v-85.333334a42.666667 42.666667 0 0 1 85.333333 0v85.333334h85.333333a42.666667 42.666667 0 0 1 0 85.333333h-85.333333v85.333333a42.666667 42.666667 0 0 1-85.333333 0v-85.333333h-85.333334a42.666667 42.666667 0 0 1 0-85.333333h85.333334z" fill="#000000" opacity=".3" p-id="1633"></path></svg>}
    </div>

    return musicgate
        ? <div className="gbcastmusic_root">
            <div className="gbcastmusic_modal">
                <div className="gbcastmusic_row">
                    {musicinput_dom}
                </div>
                <div className="gbcastmusic_top">
                    {musicobjs.map((obj, index) => {
                        return <audio key={'audio' + index} controls={true} className="gbcastmusic_audio">
                            <source type="audio/mp3" src={obj} />
                        </audio>
                    })}
                </div>
                <div className="gbcastmusic_donebtn" onClick={onMusicdone}>Done</div>
            </div>
        </div>
        : null



}

export default Bgmusic