import React from 'react'
import { iconwrapper } from '../../../../../../config/commonfunctions'
import { ADD_ICON } from '../../../../../../config/svgicons'
import './Commonelements.css'

const vb_ = "0 0 1024 1024"
const v_ = "1.1"
const x_ = "http://www.w3.org/2000/svg"
const icon_css = { width: 60, height: 60, userSelect: 'none', pointerEvents: 'none', verticalAlign: 'middle', fill: '#4bb543', overflow: 'hidden' }

export const tick_icon = <svg style={icon_css} viewBox={vb_} version={v_} xmlns={x_}><path d="M926.122667 169.941333a32 32 0 0 1 49.408 40.448l-2.986667 3.669334-608.469333 640a32 32 0 0 1-42.752 3.285333l-3.626667-3.285333-266.24-280.021334a32 32 0 0 1 42.88-47.274666l3.541333 3.2 242.986667 255.573333L926.122667 169.941333z" p-id="6832"></path></svg>

export const id_dom = (id) => {
    return id
        ? <div key={id + '_root'} className='ig_lblinputroot' style={{ width: 'calc( 100% - 20px )', borderRadius: 5, color: '#aaaaaaa', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ padding: '0px 5px 0px 0px', placeContent: 'center', display: 'flex', textAlign: 'center', whiteSpace: 'nowrap' }}>GUEST-UID</div>
            <div key={id + '_txtarea'} className={`ig_lbl`} style={{ color: 'white', backgroundColor: '#333333', padding: 5, borderRadius: 5, width: '100%' }}>{id}</div>
        </div>
        : null
}

export const status_dom = (item, update, updatefrom) => {
    var gate
    var info_dom = null
    if (item) {
        const { date, from, action } = item
        if (action === 'sent' || action === 'replied') {
            gate = true
            info_dom = <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                {action === 'sent'
                    ? <select key={from + 'select'} className='ig_select' style={{ borderTopLeftRadius: action === 'sent' ? 0 : 5, borderTopRightRadius: action === 'sent' ? 0 : 5 }} onChange={updatefrom} value={from}>
                        <option key='whatsapp' value="Whatsapp">Whatsapp</option>
                        <option key='telegram' value="Telegram">Telegram</option>
                        <option key='others' value="Others">Others</option>
                    </select>
                    : null}
                <div style={{ display: 'flex', placeContent: 'flex-end' }}>{date}</div>
            </div>
        }
    }

    return <div key={'status'} className='ig_lblinputroot'>
        <div className='ig_lbl'>Status</div>
        <div className='RSVP_statusrow'>
            <button className={`RSVP_statussent ${item && item.action === 'sent' ? 'RSVP_statusselected' : ''}`} style={{ borderBottomLeftRadius: item && item.action !== 'sent' ? 5 : 0 }} onClick={update} value={'sent'}>Sent</button>
            <div className='RSVP_statusmid' />
            <button className={`RSVP_statusnotsent ${item && item.action === '' ? 'RSVP_statusselected' : ''}`} onClick={update} value={''}>Not Send</button>
            <div className='RSVP_statusmid' />
            <button className={`RSVP_statusnotsent ${item && item.action === 'replied' ? 'RSVP_statusselected' : ''}`} onClick={update} value={'replied'}>Replied</button>
            <div className='RSVP_statusmid' />
            <button className={`RSVP_statusnotsent ${item && item.action === 'update' ? 'RSVP_statusselected' : ''}`} onClick={update} value={'update'}>Update</button>
            <div className='RSVP_statusmid' />
            <button className={`RSVP_statusreplied ${item && item.action === 'reminder' ? 'RSVP_statusselected' : ''}`} style={{ borderBottomRightRadius: item && item.action !== 'sent' ? 5 : 0 }} onClick={update} value={'reminder'}>Reminder</button>
        </div>
        {info_dom}
    </div>
}

export const title_dom = (title) => {
    return <div key={title} style={{ textDecoration: 'underline', margin: '0px 10px 20px 10px', fontSize: 20 }}>{title}</div>
}

export const describe_dom = (txt, css = {}) => {
    return <div id="describe_dom" style={{ padding: '5px 10px 0px 10px', marginBottom: 20, ...css }}>{txt}</div>
}

export const inputdescribe_dom = (txt, css) => {
    return <div id="ce_inputdescribe" style={css}>{txt}</div>
}

// export const dropdown_dom = (selected, options,onChange, rootcss) => {
//     return <select
//         id="dropdown_dom"
//         style={{ border:'',...rootcss}}
//         value={selected}
//         onChange={onChange}
//     >
//         {options}
//     </select>
// }

export const msg_dom = (id, msgs) => {
    return msgs
        ? <div key={id + '_' + msgs[id]} className='Rsvpexplorer_portalmsghighlight'>
            {msgs[id]}
        </div>
        : null
}

export const partialtitle_dom = (title, css) => {
    return <div className='ig_lbl' style={css}>{title}</div>
}

export const input_dom = (title, ph, val, onchange, id, msgs, disabled, extradom, lblcss = {}, rootcss = {}) => {
    return <div key={title + '_input'} id={id} className='ig_lblinputroot' style={rootcss}>
        {partialtitle_dom(title, lblcss)}
        <input id={id} placeholder={ph} className='ig_input' style={{  backgroundColor: disabled ? '#efeff0' : '',...lblcss }} value={val} onChange={onchange} disabled={disabled} />
        {extradom}
        {msg_dom(id, msgs)}
    </div>
}

export const textarea_dom = (title, ph, val, onchange, id, msgs, disabled, extradom, lblcss = {}, rootcss = {}, rows = 3, keydown = null) => {
    return <div key={id + '_txtarea'} id={id} className={`ig_lblinputroot`} style={rootcss}>
        {partialtitle_dom(title, lblcss)}
        <textarea id={id} rows={rows} placeholder={ph} className='ig_txtarea' value={val} onChange={onchange} disabled={disabled} onKeyDown={keydown} />
        {extradom}
        {msg_dom(id, msgs)}
    </div>
}

export const multiplechoice_dom = (title, options, onchange, id, msgs, selected, disabled, extradom, rootcss = {}, allowempty=true) => {
    return <div key={id + '_input'} id={id} className='ig_lblinputroot' style={rootcss}>
        <div className='ig_lbl'>{title}</div>
        <select id={id} className='ig_select' onChange={onchange} value={selected} disabled={disabled} >
            {allowempty? <option key={'0-unselected'}></option> : null}
            {options.map((option, index) => {
                return <option key={id + '_' + index} value={option} >{option}</option>
            })}
        </select>
        {extradom}
        {msg_dom(id, msgs)}
    </div>
}

export const RSVP_textarea_dom = (index, title, ph, val, onchange, id, msgs, disabled, fc, extradom) => {
    return <div key={id + '_txtarea'} id={id} className='RSVP_lblinputroot'>
        <div className='RSVP_lbl' style={{ color: fc }}>{index + '. ' + title}</div>
        <textarea
            className='RSVP_txtarea'
            style={{ border: '1px solid '+ fc, color: fc, resize: 'vertical' }}
            id={id}
            rows='3'
            placeholder={ph}
            value={val}
            onChange={onchange}
            disabled={disabled}
        />
        {extradom}
        {msg_dom(id, msgs)}
    </div>
}

export const RSVP_multiplechoice_dom = (index, title, options, onchange, id, msgs, selected, disabled, fc, templatecolor) => {
    return <div key={id + selected + '_-'} id={id} className='RSVP_lblinputroot'>
        <div className='RSVP_lbl' style={{ color: fc }} >
            {index + '. ' + title}
        </div>
        <div className='RSVP_multiplechoice'>
            {options.map((option, index) => {
                return <button
                    className='RSVP_multiplechoiceoption'
                    disabled={disabled}
                    key={index + '_' + option}
                    id={id}
                    value={option}
                    onClick={onchange}
                    style={{ border: selected === option ? `1px solid ${fc}` : `1px solid ${templatecolor}`, backgroundColor: selected === option ? '#ffffff80' : templatecolor, color: fc }}
                >
                    {option}
                </button>
            })}
        </div>
        {msg_dom(id, msgs)}
    </div>
}

export const twobtn_dom = (dom1, dom2, val1, val2, selectedval, update, btncss) => {
    return <div className='ce_row' key={val1 + "_" + val2 + "_" + selectedval}>
        <button
            className={`ce_twobtn ce_twobtnleft ${selectedval === val1 ? 'ce_twobtnselected' : ''}`}
            style={btncss}
            onClick={update}
            value={val1}
        >
            {dom1}
        </button>
        <div className='ce_btnsline' />
        <button
            className={`ce_twobtn ce_twobtnright ${selectedval === val2 ? 'ce_twobtnselected' : ''}`}
            style={btncss}
            onClick={update}
            value={val2}
        >
            {dom2}
        </button>
    </div>
}

export const radiobtn_dom = (val, update, txt, rootcss = {}) => {
    return <div className='ce_row' key={val} style={rootcss}>
        <label className="Details_switch">
            <input type="checkbox" defaultChecked={val} onChange={update} />
            <span className="Details_slider round" />
        </label>
        <div className='Details_notitxt'>{txt}</div>
    </div>
}

export const radiobtnsmall_dom = (val, update, txt) => {
    return <div className='Rsvpexplorer_radiorow_small'>
        <div className='Details_notitxt_small' >{txt}</div>
        <label className="Details_switch_small">
            <input type="checkbox" defaultChecked={val} onChange={update} />
            <span className="Details_slider_small round" />
        </label>
    </div>
}

export const addentry_btn = (ref, txt, onClick) => { // Rsvpqnportal.jsx | 
    return <button ref={ref} className="Ieditcontact_addbtn" onClick={onClick} >
        {iconwrapper(ADD_ICON, { width: 20, height: 20, display: 'flex', placeContent: 'center', })}
        <div className='Ieditcontact_addtxt'>{txt}</div>
    </button>
}

export const checkbox_dom = (val, update, txt) => {
    return <div className='ce_checkboxroot'>
        <input type="checkbox" id="checkbox" className='ce_checkbox' defaultChecked={val} onChange={update} />
        <div className='ce_checkboxtxt' onClick={update}>{txt}</div>
    </div>
}