import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";

const style = {
  width: "100%",
  height: "300px"
};

function Jfmap(props) {
  
  const { markersData, type, smallstyle, zc = true, gpsiconsize = [25, 41], mapmode = 0 } = props // mapmode control the map filter color

  const [mapuniqid, setMapuniqid] = useState('')
  const [done, setDone] = useState(false)

  // create map
  const mapRef = useRef(null);
  // add layer
  const layerRef = useRef(null);

  useEffect(() => {
    const mapuniqid = Math.random()
    setMapuniqid(mapuniqid)
    setDone(true)
    console.log('props', props)
  }, [])

  useEffect(() => {
    if (done) {
      let local_markersData = markersData
      if (markersData[0].latLng.lat === '' || markersData[0].latLng.lng === '') {
        local_markersData = [{ latLng: { lat: '0', lng: '0' } }]
      }

      mapRef.current = L.map(("map" + mapuniqid), {
        center: [parseFloat(local_markersData[0].latLng.lat), parseFloat(local_markersData[0].latLng.lng)], // center: [1.3521, 103.8198],
        zoom: 30,
        layers: [
          L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            className: 'map-tiles'
          })
        ],
        scrollWheelZoom: false,
        zoomControl: zc,
      })


      layerRef.current = L.layerGroup().addTo(mapRef.current);

      let icon_ = L.icon({
        iconUrl: "https://unpkg.com/leaflet@1.0.3/dist/images/marker-icon.png",
        iconSize: gpsiconsize,
        iconAnchor: [18, 18],
        popupAnchor: [0, -10],
        shadowSize: [0, 0],
        shadowAnchor: [10, 10]
      });

      L.Icon.Default.prototype.options = {
        iconSize: gpsiconsize,
      }

      layerRef.current.clearLayers();
      markersData.forEach(marker => {
        L.marker(
          marker.latLng,
          {
            title: marker.title,
            icon: icon_,
            center: [parseFloat(markersData[0].latLng.lat), parseFloat(markersData[0].latLng.lng)], // center: [1.3521, 103.8198],
          },

        ).addTo(layerRef.current);

      });

      setDone(false)
    }
  }, [done])

  return <div
    id={"map" + mapuniqid}
    style={{
      ...(type === 'small' ? smallstyle : style),
      filter: [
        'none',
        'brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7)'
      ][mapmode]
    }}
  />

}

export default Jfmap;