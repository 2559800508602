import React from 'react'
import { ScreenHeight, ScreenWidth } from '../../../../components';
import { ReactP5Wrapper } from "react-p5-wrapper";

const Openingtransition = (props) => {
    const { color, onend, openingclosing } = props
    const wt = ScreenWidth()
    const ht = ScreenHeight()
    var circ_size = 1
    var rect_size = 1
    function sketch(p5) {
        p5.setup = () => {
            p5.colorMode(p5.HSB);
            p5.noStroke();
            p5.createCanvas(wt, ht)
            p5.smooth();
        };
        p5.draw = () => {
            p5.fill(color);
            if(openingclosing===0){
                p5.ellipse(0, 0, circ_size, circ_size);
                if (ht > wt) {
                    circ_size = circ_size + (ht * 0.01)
                    if (circ_size > (ht*2)) {
                        onend()
                        p5.noLoop();
                    }
                }
                else {
                    circ_size = circ_size + (wt * 0.01)
                    if (circ_size > (wt*2)) {
                        onend()
                        p5.noLoop();
                    }
                }
            }
            else if(openingclosing===1){
                p5.rect(0, 0, wt, rect_size);
                if (ht > wt) {
                    rect_size = rect_size + (ht * 0.01)
                    if (rect_size > (ht*2)) {
                        onend()
                        p5.noLoop();
                    }
                }
                else {
                    rect_size = rect_size + (wt * 0.01)
                    if (rect_size > (wt*2)) {
                        onend()
                        p5.noLoop();
                    }
                }
            }
        
        };
    };

    return <ReactP5Wrapper sketch={sketch} />;

}

export default Openingtransition