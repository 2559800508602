import React from 'react';

class Commonstart {

    pinkbtn(func) {
        return <div className="gblookuppinkbtn" style={{ position: 'fixed', margin: 10, bottom: 0, flexDirection: 'column', display: 'flex', zIndex: 9, left: 'calc( 100% - 50px )', touchAction:'manipulation' }}>
            <button style={{ display: 'flex', backgroundColor: '#eb5d7b', width: 30, height: 30, borderRadius: 30, border: '1px solid white' }} onClick={() => { func(); window.scrollTo(0, 0); }} />
        </div>
    }
    pinkprompt(close, up, down) {
        return <div className="gblookuppinkprompt" style={{ position: 'fixed', left: `calc( 100% - 120px )`, bottom: 0, flexDirection: 'column', display: 'flex', zIndex: 9, touchAction: 'manipulation', userSelect: 'none' }}>
            <button onClick={close} id="Start_pointerbtn" style={{ margin: `10px 10px ${down ? 0 : 10}px 10px` }} > Close </button>
            {up ? <button onClick={up} id="Start_pointerbtn" style={{ margin: '5px 10px 0px 10px' }}> Prev </button> : null}
            {down ? <button onClick={down} id="Start_pointerbtn" style={{ margin: '5px 10px 10px 10px' }} >Next</button> : null}
        </div>
    }
}
export default Commonstart