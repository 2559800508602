import React from 'react';
import './Textfield.css'
function Textfield({ id, label, val, type, onchange, disabled }) {
    return <div className="tf_root" key={'tf' + id}>
        <input
            className={"tf_input tf_fs"}
            autoComplete={label}
            type={type}
            aria-required="true"
            maxLength="75"
            placeholder={label}
            onChange={onchange}
            value={val}
            disabled={disabled}
        />
    </div>
}
export default Textfield
