import { EMPTY, DAYS_ARR, NUMMTH_FULLCHARMTH_MAP,  S3_DIRECTORY_PRODUCTION, S3_DIRECTORY_NONPRODUCTION, production } from "./constants";
import { S3CLOUD_CLOUDFRONT } from "./s3directories";

export const ParseJWT = (token) => {
    try {
        var base64Urlstr = token.split('.')[1];
        var base64 = base64Urlstr.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }
    catch (e) { return null }
}
export const Retrieve_personal_info = () => {
    try {
        const rawToken = localStorage.getItem('token')
        var token = rawToken.substring(4, rawToken.length)
        const _id = ParseJWT(token).id
        var acct_type = ParseJWT(token).acct_type
        if (acct_type !== 'VENDOR' && acct_type !== 'USER') { acct_type = 'UNKNOWN'; token = 'NONE'; }
        else return { token: token, _id: _id, acct_type: acct_type, }
    }
    catch (e) { return { token: EMPTY, _id: EMPTY, acct_type: EMPTY } }
}

function get_bigrams(string) {
    var s = string.toLowerCase()
    var v = s.split('');
    for (var i = 0; i < v.length; i++) { v[i] = s.slice(i, i + 2); }
    return v;
}

export const str_similarity = (str1, str2) => {
    if (str1.length > 0 && str2.length > 0) {
        var pairs1 = get_bigrams(str1);
        var pairs2 = get_bigrams(str2);
        var union = pairs1.length + pairs2.length;
        var hits = 0;
        for (var x = 0; x < pairs1.length; x++) {
            for (var y = 0; y < pairs2.length; y++) {
                if (pairs1[x] === pairs2[y]) hits++;
            }
        }
        if (hits > 0) return ((2.0 * hits) / union);
    }
    return 0.0
}

export const shuffle = (array = []) => {
    var new_array = array
    for (let i = new_array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [new_array[i], new_array[j]] = [new_array[j], new_array[i]]
    }
    return new_array
}

export const importexscript = (src) => {
    const script = document.createElement("script");
    script.src = src
    script.async = true;
    script.onload = () => { }
    document.body.appendChild(script);
}


export const retrieveDateobj = (date) => { // dissect date obj string to mm dd yy
    var dtobj, mm, dd, yy
    dtobj = new Date(date)
    mm = dtobj.getMonth() + 1;
    dd = dtobj.getDate();
    yy = dtobj.getFullYear();

    var readable_date = dd + '/' + mm + '/' + yy
    var d = readable_date.split('/')[0]
    var m = NUMMTH_FULLCHARMTH_MAP[parseInt(readable_date.split('/')[1], 10) - 1]
    var y = readable_date.split('/')[2]

    return {
        date: readable_date,
        day: DAYS_ARR[new Date(date).getDay()],
        d,
        m,
        y
    }
}

export const iconwrapper = (icon, style)=>{
    return <div id='icon-wrapper-shared' style={style}>
        {icon}
    </div>
}


export const getS3fullpath_withbucketname = (key, filename) => {
    return (production
        ? S3CLOUD_CLOUDFRONT + S3_DIRECTORY_PRODUCTION[key]
        : S3CLOUD_CLOUDFRONT + S3_DIRECTORY_NONPRODUCTION[key])
        + filename
}

export const decryptHashed = (encryptedHash, key = 812931982398) => {

    function decrypt(data, key) {
        let decryptedData = "";
        for (let i = 0; i < data.length; i++) {
            decryptedData += String.fromCharCode(data.charCodeAt(i) ^ key);
        }
        return decryptedData;
    }

    // Decrypt the encrypted hash
    const decryptedHash = decrypt(encryptedHash, key);
    return decryptedHash
}
