import React, { useState, } from 'react'//useEffect, useLayoutEffect 
import { EMPTYSPACE, TABLET } from '../../config/constants';
import { BLOGCONTENT, BLOG_KEY } from '../../localdb/Localdb';
import { Headtitle, Processdate, Checkdevice } from '../../components'; //Blogmenu

const imgfs = 40
export default function Blog(props) {

    const device = Checkdevice()
    const [currcard, setCurrcard] = useState(-1)

    // css for web only
    const [hover, setHover] = useState('')
    const [translate, setTranslate] = useState('')

    var card_impt, card_css, outerlayer_padding
    if (device === 'WEB') {
        outerlayer_padding = '90px 80px 80px'
        card_impt = { width: 1000, height: 500, padding: 10, borderRadius: 5, backgroundColor: 'rgba(251, 225, 229, 0.52)' }
        card_css = { width: 300, height: 170, }
    }
    else if (device === 'TABLET') {
        outerlayer_padding = '50px 16px 16px'
        card_impt = { width: '100%', height: 400, backgroundColor: 'rgba(251, 225, 229, 0.52)' }
        card_css = { width: 340, height: 200, }
    }
    else if (device === 'MOBILE') {
        outerlayer_padding = '50px 16px 16px'
        card_impt = { width: 320, height: 190, borderRadius: 5, backgroundColor: 'rgba(251, 225, 229, 0.52)' }
        card_css = { width: 320, height: 190, }
    }

    // all cards except first
    const selected_css = { transform: 'translateY(-8px)', boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)', width: '33.33%', borderRadius: 10, padding: '5px 10px', transition: '0.2s', alignItems: 'center', display: 'flex', flexDirection: 'column' }
    const unselected_css = { transform: 'translateY(0px)', boxShadow: '', width: '33.33%', borderRadius: 10, padding: '5px 10px', transition: '0.2s', alignItems: 'center', display: 'flex', flexDirection: 'column' }

    // click on a blogcard
    const onClickBlogcard = (key) => {
        const url = BLOGCONTENT[key].url
        props.history.push('/blog/' + url)
    }

    const mobile_cards = () => {

        const first_card = () => { // only for web and tablet
            const { title, bgcolor, img, imgtype, subtitle, date } = BLOGCONTENT[1]
            return (
                <div
                    onClick={() => { onClickBlogcard(1) }}
                    style={{ width: '100%', borderRadius: 10, alignItems: 'center', height: 'max-content', display: 'flex', flexDirection: 'column', }}>
                    <div style={{ ...card_impt, backgroundColor: 'transparent', position: 'absolute' }}>{EMPTYSPACE}</div>
                    {imgtype === 'svg' // svg or string
                        ? <object style={{ ...card_css, backgroundColor: bgcolor, padding: 10, borderRadius: 5 }} data={img} type="image/svg+xml">{EMPTYSPACE}</object>
                        : <div style={{ ...card_css, backgroundColor: bgcolor, padding: 10, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 30 }} >{img}</div>}
                    <div style={{ width: '100%', padding: '5px 15px 15px 15px' }}>
                        <div style={{ fontSize: 20, color: '#707070', fontWeight: 'bold', width: '100%', display: 'flex', justifyContent: 'flex-start' }}>{title}</div>
                        <div style={{ fontSize: 15, fontWeight: 'lighter', width: '100%', display: 'flex', justifyContent: 'flex-start' }}> {subtitle}</div>
                        <div style={{ fontSize: 13, fontWeight: 'lighter', width: '100%', display: 'flex', justifyContent: 'flex-start' }}> {Processdate(date)}</div>
                    </div>
                </div>
            )
        }

        const primary_card = () => {// eslint-disable-line
            return (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
                    {BLOG_KEY.map((key, index) => {
                        const { title, bgcolor, img, imgtype, subtitle, date } = BLOGCONTENT[key]
                        return <div
                            onClick={() => onClickBlogcard(key)}
                            key={title + index}
                            style={{ width: '100%', borderRadius: 10, paddingTop: '3vh', alignItems: 'center', height: 'max-content', display: 'flex', flexDirection: 'column', }}>
                            <div style={{ ...card_css, backgroundColor: 'transparent', position: 'absolute' }}>{EMPTYSPACE}</div>
                            {imgtype === 'svg' // svg or string
                                ? <object style={{ ...card_css, backgroundColor: bgcolor, padding: 10, borderRadius: 5 }} data={img} type="image/svg+xml">{EMPTYSPACE}</object>
                                : <div style={{ ...card_css, backgroundColor: bgcolor, padding: 10, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: imgfs }} >{img}</div>}
                            <div style={{ width: '100%', padding: '5px 15px 15px 15px' }}>
                                <div style={{ fontSize: 20, color: '#707070', fontWeight: 'bold', width: '100%', display: 'flex', justifyContent: 'flex-start' }}>{title}</div>
                                <div style={{ fontSize: 15, fontWeight: 'lighter', width: '100%', display: 'flex', justifyContent: 'flex-start' }}> {subtitle}</div>
                                {date ? <div style={{ fontSize: 13, fontWeight: 'lighter', width: '100%', display: 'flex', justifyContent: 'flex-start' }}> {Processdate(date)}</div> : null}
                            </div>
                        </div>
                    })}
                </div>
            )
        }
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {first_card()}
                {primary_card()}
                {/* {EMPTYSPACE} */}
            </div>
        )
    }

    const tablet_cards = () => {

        const first_card = () => { // only for web and tablet
            return (
                <div
                    onClick={() => { onClickBlogcard(1) }}
                    style={{ width: TABLET * 0.9, borderRadius: 10, alignItems: 'center', height: 'max-content', display: 'flex', flexDirection: 'column', }}>
                    <div style={{ ...card_impt, backgroundColor: 'transparent', position: 'absolute' }}>{EMPTYSPACE}</div>
                    <object id={Math.random()} style={{ ...card_impt, padding: 10, borderRadius: 5 }} data={BLOGCONTENT[1].img} type="image/svg+xml">{EMPTYSPACE}</object>
                    <div style={{ width: '100%', padding: '5px 15px 15px 15px' }}>
                        <div style={{ fontSize: 34, color: '#707070', fontWeight: 'bold', width: '100%', display: 'flex', justifyContent: 'flex-start' }}>{BLOGCONTENT[1].title}</div>
                        <div style={{ fontSize: 24, fontWeight: 'lighter', width: '100%', display: 'flex', justifyContent: 'flex-start' }}> {BLOGCONTENT[1].subtitle}</div>
                        <div style={{ fontSize: 13, fontWeight: 'lighter', width: '100%', display: 'flex', justifyContent: 'flex-start' }}> {Processdate(BLOGCONTENT[1].date)}</div>
                    </div>
                </div>
            )
        }

        const primary_card = () => {

            // two by two
            var new_BLOG_KEY = []
            for (let i = 0, j = 0; i < BLOG_KEY.length; j++) {
                new_BLOG_KEY.push([])
                for (let x = 0; x < 2; x++, i++) {
                    if (BLOG_KEY[i]) {
                        new_BLOG_KEY[j].push(BLOG_KEY[i])
                    }
                }
            }

            return (
                <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '5vh', flexWrap: 'wrap', WebkitOverflowScrolling: 'touch', justifyContent: 'center' }}>
                    {new_BLOG_KEY.map((key, index) => {
                        var firstelem = key[0]
                        var secelem = key[1]
                        return (
                            [
                                tablet_post(firstelem, index, onClickBlogcard, key, card_css),
                                secelem ? tablet_post(secelem, index, onClickBlogcard, key, card_css) : tabletempty_post(card_css)
                            ]
                        )
                    })}
                </div>
            )
        }


        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {first_card()}
                {primary_card()}
            </div>
        )

    }

    const desktop_cards = () => {

        const first_card = () => { // only for web and tablet
            const { title, bgcolor, img, imgtype, subtitle, date } = BLOGCONTENT[1]
            return (
                <button
                    key={title + 'first'}
                    onClick={() => onClickBlogcard(1)}
                    style={{ margin: 0, padding: 0, outline: 'none', backgroundColor: 'transparent', border: 'none', }}>
                    <div
                        style={{ transform: translate, boxShadow: hover, width: '100%', borderRadius: 10, paddingTop: 5, transition: '0.2s', alignItems: 'center', height: 'max-content', display: 'flex', flexDirection: 'column' }}
                        onMouseEnter={() => { setHover('0 2px 4px 0 rgba(0,0,0,.2)'); setTranslate('translateY(-8px)') }}
                        onMouseLeave={() => { setHover(''); setTranslate('translateY(0px)') }}
                    >
                        <div style={{ ...card_impt, backgroundColor: 'transparent', position: 'absolute' }}>{EMPTYSPACE}</div>
                        {imgtype === 'svg' // svg or string
                            ? <object style={{ ...card_impt, backgroundColor: bgcolor, margin: 5, borderRadius: 5 }} data={img} type="image/svg+xml" >{EMPTYSPACE}</object>
                            : <div style={{ ...card_impt, backgroundColor: bgcolor, margin: 5, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: imgfs }} >{img}</div>}

                        <div style={{ width: '100%', padding: '5px 15px 15px 15px' }}>
                            <div style={{ fontSize: 34, color: '#707070', fontWeight: 'bold', width: '100%', display: 'flex', justifyContent: 'flex-start' }}>{title}</div>
                            <div style={{ fontWeight: 'lighter', fontSize: 24, width: '100%', display: 'flex', justifyContent: 'flex-start' }}> {subtitle}</div>
                            <div style={{ fontWeight: 'lighter', fontSize: 13, width: '100%', display: 'flex', justifyContent: 'flex-start' }}> {Processdate(date)}</div>
                        </div>
                    </div>
                </button>
            )
        }

        const primary_card = () => {// eslint-disable-line
            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingTop: '5vh', cursor: 'pointer' }}>
                    {BLOG_KEY.map((key, index) => {
                        const { title, bgcolor, img, imgtype, subtitle, date } = BLOGCONTENT[key]
                        return (
                            <div
                                key={title + index}
                                onClick={() => onClickBlogcard(key)}
                                onMouseEnter={() => { setCurrcard(key) }}
                                onMouseLeave={() => { setCurrcard(-1) }}
                                style={currcard === key ? selected_css : unselected_css}
                            >
                                <div style={{ ...card_css, backgroundColor: 'transparent', position: 'absolute' }}>{' '}</div>
                                {imgtype === 'svg' // svg or string
                                    ? <object style={{ ...card_css, backgroundColor: bgcolor, margin: 5, borderRadius: 5 }} data={img} type="image/svg+xml">{EMPTYSPACE}</object>
                                    : <div style={{ ...card_css, backgroundColor: bgcolor, margin: 5, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: imgfs }} >{img}</div>}
                                <div style={{ width: '100%', padding: '5px 15px 15px 15px' }}>
                                    <div style={{ fontSize: 18, color: '#707070', fontWeight: 'bold', width: '100%', display: 'flex', justifyContent: 'flex-start' }}>{title}</div>
                                    <div style={{ fontWeight: 'lighter', fontSize: 13, width: '100%', display: 'flex', justifyContent: 'flex-start' }}> {subtitle}</div>
                                    <div style={{ fontWeight: 'lighter', fontSize: 13, width: '100%', display: 'flex', justifyContent: 'flex-start' }}> {Processdate(date)}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {first_card()}
                {EMPTYSPACE}
                {primary_card()}
            </div>
        )

    }

    function tablet_post(firstelem, index, onClickBlogcard, key, card_css) {
        return <button
            key={BLOGCONTENT[firstelem].title + index}
            onClick={() => onClickBlogcard(firstelem)}
            style={{ margin: 0, outline: 'none', backgroundColor: 'transparent', border: 'none', height: 'max-content', width: 'max-content', justifyContent: 'flex-start' }}
        >
            <div style={{ ...card_css, margin: 0, backgroundColor: 'transparent', position: 'absolute', }} />
            {BLOGCONTENT[firstelem].imgtype === 'svg' // svg or string
                ? <object style={{ ...card_css, margin: 0, backgroundColor: BLOGCONTENT[firstelem].bgcolor, borderRadius: 5 }} data={BLOGCONTENT[firstelem].img} type="image/svg+xml">{EMPTYSPACE}</object>
                : <div style={{ ...card_css, margin: 0, backgroundColor: BLOGCONTENT[firstelem].bgcolor, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: imgfs }}>{BLOGCONTENT[firstelem].img}</div>}
            <div style={{ width: 'max-content', padding: '5px 15px 15px 15px' }}>
                <div style={{ fontSize: 18, color: '#707070', fontWeight: 'bold', width: '100%', display: 'flex', justifyContent: 'flex-start' }}>{BLOGCONTENT[firstelem].title}</div>
                <div style={{ fontWeight: 'lighter', fontSize: 13, width: '100%', display: 'flex', justifyContent: 'flex-start' }}> {BLOGCONTENT[firstelem].subtitle}</div>
                <div style={{ fontWeight: 'lighter', fontSize: 13, width: '100%', display: 'flex', justifyContent: 'flex-start' }}> {Processdate(BLOGCONTENT[firstelem].date)}</div>
            </div>
        </button>;
    }

    function tabletempty_post(card_css) {
        return <button style={{ margin: 0, outline: 'none', backgroundColor: 'transparent', border: 'none', height: 'max-content', width: 'max-content', justifyContent: 'flex-start' }}>
            <div style={{ ...card_css, margin: 0, backgroundColor: 'transparent', position: 'absolute', }} />
            <div style={{ ...card_css, margin: 0, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: imgfs }} />
            <div style={{ width: 'max-content', padding: '5px 15px 15px 15px' }} />
        </button>;
    }

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', minHeight: '100vh', color: '#707070', touchAction: 'manipulation' }}>
            <Headtitle name="Blog" color="var(--maindark)" />
            <div className="container" style={{ padding: outerlayer_padding, margin: '0 auto', }}>
                {/* {Blogmenu(props)} */}
                {device === 'WEB' ? desktop_cards() : null}
                {device === 'TABLET' ? tablet_cards() : null}
                {device === 'MOBILE' ? mobile_cards() : null}
            </div>
        </div>
    )
}

