import React from 'react'
import { EMPTYSPACE, STARTUP_NAME, TAG_OBJ_MAP, } from '../../config/constants'

function Blog1() {
    return (
        [
            {
                key: 'TEXT',
                val: `If you ever plan a wedding, birthday party or corporate event, 
                you would know how stressful it is to plan these big events. 
                Even the professional event planners struggles with the mundane logistics that makes the event possible. `
            },
            {
                key: 'TEXT',
                val: `Don't fret, ${STARTUP_NAME} is here to help. 
                ${STARTUP_NAME} helps you to coordinate and organise details of your event from from start to end on our all-in-one platform.
                We have curated the best wedding/event vendors and provides everything you need in one place.
                If you are planning for your upcoming wedding or event you can come to ${STARTUP_NAME} 
                and really get inspired by viewing project galleries from leading professionals/vendors in the country.`
            },
            {
                key: 'TEXT',
                val: `Most importantly you can see who is connected in those photos, and view their profile page, search for different vendors and request a quote via our chat system for your wedding or event. And to get the most out of ${STARTUP_NAME}, you could utilize the tools and features provided on our platform.`
            },
            {
                key: 'TEXT',
                val: "Tools we provide that can make your wedding/event planning a whole lot easier : "
            },
            {
                key: 'DOM',
                val: (
                    <div className="ulo-why" style={{ height: 450, display: 'flex', flexDirection: 'row', justifyContent: 'center', overflowY: 'auto', scrollbarWidth: 0, position: 'relative', backgroundColor: 'white', alignItems: 'center', width: 'fit-content' }}>
                        {['Search', 'Compare', 'Message & Quote', 'RSVP Website & Guestlist'].map((item, index) => {
                            const { txt, svg, title } = TAG_OBJ_MAP[item]
                            return <div key={item + index} style={{ width: 220, height: 400, borderRadius: 5, margin: 10, padding: 10, fontSize: '1.5em', backgroundColor: '#FBE1E5', display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: 'rgba(0, 0, 0, 0.05) 6px 6px 0px 0px' }}>
                                <div style={{ fontSize: '19px', width: '100%', minHeight: 65, display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', letterSpacing: 1, color: '#707070', textAlign: 'center' }}>
                                    {title}
                                </div>
                                <div style={{ width: '100%', height: 100, display: 'flex', justifyContent: 'center', fontSize: '0.725em', textAlign: 'center', color: '#707070', alignItems: 'center' }}>
                                    {txt}
                                </div>
                                <object id={Math.random()} data={svg} type="image/svg+xml">{EMPTYSPACE}</object>
                            </div>
                        })}
                    </div>
                )
            },
            {
                key: 'DOM',
                val: (
                    <div className="ulo-why" style={{ height: 450, display: 'flex', flexDirection: 'row', justifyContent: 'center', overflowY: 'auto', scrollbarWidth: 0, position: 'relative', backgroundColor: 'white', alignItems: 'center', width: 'fit-content' }}>
                        {['Budget Tool', 'Timeline Itinerary', 'Marketplace SG','Wedding/Event Checklist',].map((item, index) => {
                            // if (item === 'Host & Join' && !hostjoin) return null
                            const { txt, svg, title } = TAG_OBJ_MAP[item]

                            return <div key={item + index} style={{ width: 220, height: 400, borderRadius: 5, margin: 10, padding: 10, fontSize: '1.5em', backgroundColor: '#FBE1E5', display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: 'rgba(0, 0, 0, 0.05) 6px 6px 0px 0px' }}>
                                <div style={{ fontSize: '19px', width: '100%', minHeight: 65, display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', letterSpacing: 1, color: '#707070', textAlign: 'center' }}>
                                    {title}
                                </div>
                                <div style={{ width: '100%', height: 100, display: 'flex', justifyContent: 'center', fontSize: '0.725em', textAlign: 'center', color: '#707070', alignItems: 'center' }}>
                                    {txt}
                                </div>
                                <object id={Math.random()} data={svg} type="image/svg+xml">{EMPTYSPACE}</object>
                            </div>
                        })}
                    </div>
                )
            },
            {
                key: 'DOM',
                val: (
                    <div className="ulo-why" style={{ height: 450, display: 'flex', flexDirection: 'row', justifyContent: 'center', overflowY: 'auto', scrollbarWidth: 0, position: 'relative', backgroundColor: 'white', alignItems: 'center', width: 'fit-content' }}>
                        {[ 'Seating Chart'].map((item, index) => {
                            // if (item === 'Host & Join' && !hostjoin) return null
                            const { txt, svg, title } = TAG_OBJ_MAP[item]

                            return <div key={item + index} style={{ width: 220, height: 400, borderRadius: 5, margin: 10, padding: 10, fontSize: '1.5em', backgroundColor: '#FBE1E5', display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: 'rgba(0, 0, 0, 0.05) 6px 6px 0px 0px' }}>
                                <div style={{ fontSize: '19px', width: '100%', minHeight: 65, display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', letterSpacing: 1, color: '#707070', textAlign: 'center' }}>
                                    {title}
                                </div>
                                <div style={{ width: '100%', height: 100, display: 'flex', justifyContent: 'center', fontSize: '0.725em', textAlign: 'center', color: '#707070', alignItems: 'center' }}>
                                    {txt}
                                </div>
                                <object id={Math.random()} data={svg} type="image/svg+xml">{EMPTYSPACE}</object>
                            </div>
                        })}
                    </div>
                )
            }
        ]
    )
}

export default Blog1