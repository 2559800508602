import React, { useState, useRef, useEffect, useCallback } from 'react'
import { WEB_WEAVEBLISS, ENGLISH_FF, P5REASSIGN_COUNTER, canva_editratio, canva_dlratio, a3wtint, a3htint } from '../../../../../../../../../config/constants';
import { Checkdevice, getCroppedImg, Noti } from '../../../../../../../../../components';
import { ADD_GBARTIMG, ETHERJS, RMV_GBARTIMG, UPDATE_GBARTSAVE } from '../../../../../../../../../config/api';
import { Retrieve_personal_info } from '../../../../../../../../../config/commonfunctions';
import { ReactP5Wrapper } from "react-p5-wrapper";
import { changeDpiBlob } from 'changedpi';
import Commonart from '../components/Commonart/Commonart';
import Cropper from 'react-easy-crop';
import html2canvas from 'html2canvas';
import axios from 'axios'
import './Emojiart.css'
import '../Art.css'

const USERFACING = true // true: user, false: weavebliss

// Direct copy starts here --- 

const DEBUG_STOPDL = false // for debug pupose

const MAXFILESIZE = 1024 * 1024 * 10 // 10MB

const artkey = 'EMOJI1'

const Artcommon = new Commonart()

const GT_CENTER_ITEM = false

function Emojiart(props) {
    const { msgsdata, artmainimg, purchase, urlhandler, updateartmainimg, updateartmaintxt, artmeta, updateartmeta, openpurchase, changecat } = props
    const { token } = Retrieve_personal_info()
    const [pfive, setPfive] = useState(null)
    const [p5reloadclicked, setP5reloadclicked] = useState(false)
    const [download, setDownload] = useState(false)
    const [downsizeratio, setDownsizeratio] = useState(canva_editratio)

    const [localartmeta, setLocalartmeta] = useState({})

    // artmeta data
    const [terms, setTerms] = useState([]) // all emoji url
    const [tfidf, setTfidf] = useState({}) // tf {emojiurl : count of emoji, ....}

    const [metaupdated, setMetaupdated] = useState(false)
    const [maincanvacolor, setMaincanvacolor] = useState('white')
    const [framecolor, setFramecolor] = useState('white')
    const [seed, setSeed] = useState(null)
    const [papersize, setPapersize] = useState('a3') // a4 or a3
    const [orientation, setOrientation] = useState('landscape') // landscape or portrait
    const [frame, setFrame] = useState('framed') // framed or unframed
    const [qractive, setQractive] = useState('NONE') // NONE, LEFT, MID, RIGHT
    const [numemoji, setNumemoji] = useState(-1) // Number of emoji on the canva, if emoji is n number, then loop to make it reach numemoji
    const [centertype, setCentertype] = useState(null) // either Image or Text for center
    const [emojisizeconstant, setEmojisizeconstant] = useState(null)

    // main image
    const [imginfo, setImginfo] = useState(null)
    const [tempimg, setTempimg] = useState(null) // require to make into square
    const [tempcroppedimg, setTempcroppedimg] = useState(null)
    const [imgmodal, setImgmodal] = useState(false)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [error, setError] = useState('')
    const [clearhide, setClearhide] = useState(true)

    // main text
    const [artmaintxt, setArtmaintxt] = useState('')
    const [tempartmaintxt, setTempartmaintxt] = useState('')
    const [maintextmodal, setMaintextmodal] = useState(false)
    const [fs, setFs] = useState(0)
    const [ff, setFf] = useState('')
    const [tempfs, setTempfs] = useState('')
    const [tempff, setTempff] = useState('')

    // show logo for art
    const [logogate, setLogogate] = useState(true)

    const [status, setStatus] = useState('')
    const device = Checkdevice(true)
    const imgref = useRef(null)

    useEffect(() => {
        if (JSON.stringify(artmeta) !== JSON.stringify(localartmeta)) {
            if (artmeta && artmeta[artkey]) {
                const { seed, framecolor, maincanvacolor, papersize, orientation, frame, qractive, numemoji, centertype, fs, ff, emojisizeconstant } = artmeta[artkey]
                setSeed(seed)
                setFramecolor(framecolor)
                setMaincanvacolor(maincanvacolor)
                setPapersize(papersize)
                setOrientation(orientation)
                setFrame(frame)
                setQractive(qractive)
                setNumemoji(numemoji)
                setCentertype(centertype)
                setFs(fs)
                setTempfs(fs)
                setFf(ff)
                setTempff(ff)
                setArtmaintxt(props.artmaintxt ? props.artmaintxt : '')
                setTempartmaintxt(props.artmaintxt ? props.artmaintxt : '')
                setLocalartmeta(props.artmeta)
                setEmojisizeconstant(emojisizeconstant)
            }
            setMetaupdated(true)
        }
    }, [artmeta, msgsdata, props, localartmeta])

    useEffect(() => { if (download) { setDownsizeratio(canva_dlratio) } }, [download])

    useEffect(() => {
        function populatemoreemoji(arr) {
            var terms = arr.slice()
            var differences = numemoji - parseInt(terms.length, 10)
            if (differences > 0) {
                let y = 0
                for (let x = 0; x < differences; x++) {
                    terms.push(terms[y])
                    y = ((y + 1) === parseInt(terms.length, 10)) ? 0 : y + 1
                }
            }
            return terms
        }
        const { tfidf, tf } = msgsdata
        setTfidf(tfidf)
        setTerms(populatemoreemoji(Object.keys(tf)))
    }, [numemoji, msgsdata])

    useEffect(() => {
        if (metaupdated) {
            var a3Paper = orientation === 'landscape' ? { width: a3wtint, height: a3htint } : { width: a3htint, height: a3wtint }
            var oneinchpx = 96 / downsizeratio
            const emojisize = (a3Paper.width / downsizeratio) * emojisizeconstant // default 0.05

            setPfive(
                <div id={'shiny'} style={{ width: a3Paper.width / downsizeratio, height: a3Paper.height / downsizeratio }} >
                    <ReactP5Wrapper
                        sketch={(p5) => {

                            var allimgs = []
                            var termsdata = []

                            function drawframe(wt, ht) {
                                p5.fill(framecolor)
                                p5.rect(0, 0, wt, ht)
                            }

                            function maincanvap5(wt, ht, oneinchpx) {
                                p5.fill(maincanvacolor)
                                if (frame === 'unframed') {
                                    p5.rect(0, 0, wt, ht)
                                }
                                else {
                                    p5.rect(
                                        oneinchpx,
                                        oneinchpx,
                                        wt - (oneinchpx * 2),
                                        ht - (oneinchpx * 2)
                                    )
                                }

                            }

                            p5.preload = () => {
                                for (let x = 0; x < terms.length; x++) { allimgs.push(p5.loadImage(terms[x])) }
                            }

                            p5.setup = () => {
                                p5.randomSeed(seed)
                                p5.colorMode(p5.HSB);
                                p5.noStroke();
                                const canvas = p5.createCanvas(a3Paper.width / downsizeratio, a3Paper.height / downsizeratio);
                                // const canvas = orientation === 'landscape' ? p5.createCanvas(a3Paper.width, clientHeight) : p5.createCanvas(clientHeight, a3Paper.width)
                                canvas.elt.addEventListener("contextmenu", (e) => e.preventDefault())
                                p5.smooth();
                                p5.clear()
                                p5.angleMode(p5.DEGREES)
                                p5.background('white')
                                p5.noLoop()
                            }

                            p5.draw = () => {

                                drawframe(p5.width, p5.height)
                                maincanvap5(p5.width, p5.height, oneinchpx)
                                for (let i = 0; i < allimgs.length; i++) {
                                    const img = allimgs[i]
                                    var counter = 0
                                    while (true) {
                                        var new_x
                                        var new_y
                                        var img_diameter = emojisize * (tfidf[terms[i]] + 1)
                                        var img_radius = img_diameter / 2
                                        if (frame === 'framed') {
                                            if (GT_CENTER_ITEM) { // with centeritem
                                                new_x = p5.random(oneinchpx * 2, p5.width - oneinchpx * 2)
                                                new_y = p5.random(oneinchpx * 2, p5.height - oneinchpx * 2)
                                            }
                                            else { // without centeritem
                                                var largerspace = oneinchpx > img_diameter ? oneinchpx * 2 : img_radius * 3
                                                var startX = oneinchpx
                                                var startY = oneinchpx
                                                var endX = p5.width - largerspace
                                                var endY = p5.height - largerspace
                                                new_x = p5.random(startX, endX)
                                                new_y = p5.random(startY, endY)
                                            }
                                        }
                                        else {
                                            new_x = p5.random(0, p5.width - img_diameter)
                                            new_y = p5.random(0, p5.height - img_diameter)
                                        }
                                        var overlapping = false

                                        for (let k = 0; k < termsdata.length; k++) {
                                            overlapping = p5.dist(termsdata[k].x, termsdata[k].y, new_x, new_y) < (termsdata[k].width / 2 + img_radius) ? true : false
                                            if (overlapping) { break }
                                        }
                                        if (!overlapping) {
                                            const emojicircle = {
                                                img: img,
                                                x: new_x,
                                                y: new_y,
                                                width: img_radius * 2,
                                                height: img_radius * 2
                                            }
                                            termsdata.push(emojicircle)
                                            p5.image(emojicircle.img, emojicircle.x, emojicircle.y, emojicircle.width, emojicircle.height);
                                            break
                                        }
                                        counter = counter + 1;
                                        if (counter === P5REASSIGN_COUNTER) { break }
                                    }
                                }

                            };
                        }} >
                        {!USERFACING && logogate ? Artcommon.logodom(oneinchpx * 1.5, framecolor) : null}
                        {Artcommon.qrcodedom(qractive, urlhandler, oneinchpx * 1.5, oneinchpx)}
                    </ReactP5Wrapper>
                </div>
            )
        }
    }, [device, qractive, artmainimg, terms,
        seed, framecolor, maincanvacolor, papersize, orientation, frame, centertype, fs, ff, artmaintxt, emojisizeconstant,
        metaupdated, logogate, tfidf, urlhandler, downsizeratio])

    useEffect(() => {
        if (download) {
            setTimeout(() => {
                html2canvas(document.getElementById('shiny'), {
                    allowTaint: false,
                    useCORS: true,
                    logging: true,
                    backgroundColor: 'white',
                    scrollX: 0,
                    scrollY: -window.scrollY,
                }).then((canvas) => {// download happens here
                    if (!DEBUG_STOPDL) {
                        const link = document.createElement("a");
                        link.download = Math.random() + ".png";
                        document.body.appendChild(link);
                        canvas.toBlob(function (b) {
                            changeDpiBlob(b, 300).then(function (blob) {
                                // use your changed blob
                                link.href = window.URL.createObjectURL(blob);
                                link.click();
                                setDownsizeratio(canva_editratio)
                            })
                        });
                        document.body.removeChild(link);
                    }
                }).catch(e => {
                    alert('fail to download')
                })
            }, 10000)
            setDownload(false)
        }
    }, [pfive, download])

    useEffect(() => {
        if (p5reloadclicked) {
            function randomIntFromInterval(min, max) { return Math.ceil(Math.random() * (max - min + 1) + min) }// min and max included 
            const rndInt = randomIntFromInterval(0, 999999999999999999)
            setSeed(rndInt)
            setP5reloadclicked(false)
        }
    }, [p5reloadclicked])

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setTempcroppedimg(croppedAreaPixels)
    }, [])

    const runpurchase = () => {// eslint-disable-line

        if (window.ethereum) {
            // window.ethereum
            //     .request({ method: 'eth_requestAccounts' })
            //     .then((result) => {
            window.ethereum
                .request({ method: 'eth_requestAccounts' })
                .then((result) => {
                    // const ethers = window.ethers
                    // const provider = new ethers.providers.Web3Provider(window.ethereum)
                    // const signer = provider.getSigner()
                    // const addr = result[0]
                    // signer.sendTransaction({ to: addr, value: ethers.utils.parseEther(ethers) }).then((a) => {
                    //     console.log('a', a)
                    // })
                    const ethers = window.ethers
                    const provider = new ethers.providers.Web3Provider(window.ethereum)
                    const signer = provider.getSigner()
                    const addr = result[0]
                    ethers.utils.getAddress('asdfsdf')
                    signer.sendTransaction({
                        to: addr,
                        value: ethers.utils.parseEther(12)
                    }).then((a) => {
                        console.log('a', a)
                    })
                })
                .catch((error) => {
                    // If the request fails, the Promise will reject with an error.
                });

            // const script = document.createElement('script');
            // script.src = ETHERJS
            // script.type = 'module'
            // script.async = true;
            // script.onload = (a) => {
            //     window.ethereum
            //         .request({ method: 'eth_requestAccounts' })
            //         .then((result) => {
            //             const ethers = window.ethers
            //             const provider = new ethers.providers.Web3Provider(window.ethereum)
            //             const acctaddress = result[0]
            //             console.log('acctaddress', acctaddress)
            //             provider.getBalance(acctaddress).then((balance) => {
            //                 console.log('balance', balance)
            //             })
            //         })
            //         .catch((error) => {
            //             // If the request fails, the Promise will reject with an error.
            //         });
            //     // const ethers = window.ethers
            //     // const provider = new ethers.providers.Web3Provider(window.ethereum)
            //     // const signer = provider.getSigner()
            //     console.log('window.ethereum.isConnected()', window.ethereum.isConnected())
            // }
            // document.body.appendChild(script);
        }
        else {
            alert('Please install Metamask.')
        }

    }

    const purchasebtn_and_dlbtn_with_eth = ( // eslint-disable-line
        purchase && purchase[artkey] && purchase[artkey]['bought']
            ? <button className="Emojiart_purchase" onClick={() => { setDownload(true) }}>Download</button>
            : <button className="Emojiart_purchase" onClick={() => {
                const script = document.createElement('script');
                script.src = ETHERJS
                script.type = 'module'
                script.async = true;
                script.onload = (a) => {
                    const ethers = window.ethers
                    try {
                        if (!window.ethereum) { alert('No crypto wallet found. Please install it.') }
                        window.ethereum.request({ method: 'eth_requestAccounts' }).then(() => {

                            // const dic_net = {
                            //     name: 'matic',
                            //     chainId: 137,
                            //     _defaultProvider: (providers) => new ethers.providers.JsonRpcProvider('rpc-url')
                            // };

                            // const provider = ethers.getDefaultProvider(dic_net);
                            const provider = new ethers.providers.Web3Provider(window.ethereum)
                            const signer = provider.getSigner()
                            const cereweaveaddr = '0x44Da0941E15D95507D0927D56bC8eD0873151485'
                            ethers.utils.getAddress(cereweaveaddr) // to check if the address exist
                            signer.sendTransaction({
                                to: cereweaveaddr,
                                value: ethers.utils.parseEther('0.02')
                            }).then((a) => { })
                        })
                    }
                    catch (e) { }

                }

                document.body.appendChild(script);

            }}>Purchase</button>
    )

    const updatecentertype = (e) => {
        const { value } = e.target
        var temp_artmeta = artmeta
        temp_artmeta[artkey][centertype] = value
        updateartmeta(temp_artmeta)
        setCentertype(e.target.value);
    }

    const imgortxt_dom = (
        <div className="Art_color">
            <div className="Art_colortitle">Center Object</div>
            <div className="Art_row" style={{ padding: '0px 5px' }}>
                <button className={centertype === 'image' ? 'Art_btnselected' : 'Art_btnunselected'} style={{ borderTopLeftRadius: 5, borderBottomLeftRadius: 5, borderRight: '1px solid #cfcecf' }} value="image" onClick={updatecentertype}>Image</button>
                <button className={centertype === 'text' ? 'Art_btnselected' : 'Art_btnunselected'} style={{ borderTopRightRadius: 5, borderBottomRightRadius: 5, borderLeft: 'none' }} value="text" onClick={updatecentertype}>Text</button>
            </div>
        </div>
    )

    const imginput_dom = (
        <div className="Art_color">
            <div className="Art_colortitle">Main Image (Square Image)</div>
            <div className="Art_row">
                {artmainimg.length === 0
                    ? <div className="Art_img" onClick={() => imgref.current.click()} > + </div>
                    : <div className="Art_img" style={{ backgroundImage: `url(${artmainimg + '?' + Math.random()})`, backgroundColor: 'black' }} onClick={() => {
                        if (artmainimg !== WEB_WEAVEBLISS) {
                            alert('Please clear image first before uploading new one.')
                        }
                        else {
                            imgref.current.click()
                        }
                    }} />}
                {(!clearhide && artmainimg.length > 0)
                    ? <button
                        className="Art_imgclear"
                        onClick={() => {
                            axios({
                                method: 'POST',
                                url: RMV_GBARTIMG,
                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, // need token from the web
                                data: { artmainimg: artmainimg, urlhandler }
                            }).then((item) => {
                                const { message } = item.data
                                if (message === 'SUCCESS') {
                                    setClearhide(true)
                                    updateartmainimg(WEB_WEAVEBLISS)
                                }
                                else { }
                            })
                        }}>Clear</button>
                    : null}
            </div>
            <input
                ref={imgref}
                accept="image/*"
                id="formControlsFile"
                type='file'
                multiple="multiple"
                name="input-file"
                className="Art_imginput"
                onChange={async (e) => {
                    var reader = new FileReader()
                    let file = e.target.files[0]
                    reader.onload = (e) => {
                        var img = document.createElement("img");
                        img.onload = () => {
                            const { size, name, type } = file
                            if (size < MAXFILESIZE) { // 2MB : 2097152, 20MB : 20000000, 1MB : 1048576
                                const { naturalHeight, naturalWidth } = img
                                const local_imginfo = { name: name, type: type }
                                if (naturalHeight !== naturalWidth) {
                                    setTempimg(img.src)
                                    setImginfo(local_imginfo)
                                    setImgmodal(true)
                                }
                                else { uploadimg(file, false, local_imginfo) }
                            }
                            else {
                                updaterror(`Image size must be less than 10MB`)
                            }
                        }
                        img.src = e.target.result;
                    }
                    reader.readAsDataURL(file);
                    e.target.value = ''
                }}
            />
        </div>
    )

    const centertxt_dom = (
        <div className="Art_color">
            <div className="Art_edittxtbtn" onClick={() => { setMaintextmodal(!maintextmodal) }}>Edit Text</div>
        </div>
    )

    const updatestatus = (txt) => {
        setStatus(txt)
        setTimeout(() => { setStatus('') }, 1000)
    }

    const updaterror = (err) => {
        setError(err);
        setTimeout(() => { setError('') }, 1000)
    }

    const uploadimg = (img, needprocess, localimginfo) => {
        const imgtype = localimginfo.type.split('/')[1]
        var data = new FormData()
        var curr_img
        if (needprocess) {
            img.toBlob((blob) => {
                if (needprocess) { // img is a canva
                    curr_img = new File(
                        [blob],
                        urlhandler + '.' + imgtype,
                        { type: 'image/' + imgtype }
                    )
                    data.append('img', curr_img)
                    data.append('urlhandler', urlhandler)
                    axios({
                        method: 'POST',
                        url: ADD_GBARTIMG,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'multipart/form-data' }, // need token from the web
                        data: data
                    }).then((item) => {
                        const { message, url } = item.data
                        if (message === 'SUCCESS') {
                            updatestatus('Updated new image.')
                            updateartmainimg(url)
                            setClearhide(false)
                        }
                        else if (message === 'NOSUCHGB') {
                            updatestatus('Guestbook does not exist, please contact admin.')
                        }
                        closecropmodal()
                    }).catch((e) => { console.log(e) })
                }
            }, 'image/' + imgtype)
        }
        else {
            curr_img = new File(
                [img],
                urlhandler + '.' + imgtype,
                { type: 'image/' + imgtype }
            )
            data.append('img', curr_img)
            data.append('urlhandler', urlhandler)
            axios({
                method: 'POST',
                url: ADD_GBARTIMG,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'multipart/form-data' }, // need token from the web
                data: data
            }).then((item) => {
                const { message, url } = item.data
                if (message === 'SUCCESS') {
                    updatestatus('Updated new image.')
                    updateartmainimg(url)
                    setClearhide(false)
                }
                else if (message === 'NOSUCHGB') {
                    updatestatus('Guestbook does not exist, please contact admin.')
                }
            }).catch((e) => { console.log(e) })
        }
    }

    const closecropmodal = () => {
        setTempimg(null)
        setImginfo(null)
        setTempcroppedimg(null)
        setImgmodal(false)
    }

    const croppedupload = async () => {
        const croppedImage = await getCroppedImg(tempimg, tempcroppedimg)
        if (croppedImage) { uploadimg(croppedImage, true, imginfo) }
    }

    const save = () => {
        var local_artmeta = artmeta ? artmeta : {}
        local_artmeta[artkey] = { seed, framecolor, maincanvacolor, papersize, orientation, frame, qractive, numemoji, centertype, ff, fs, emojisizeconstant }
        var purchase_artkey1 = artkey + '_SOFT'
        var purchase_artkey2 = artkey + '_HARDSOFT'
        axios({
            method: 'POST',
            url: UPDATE_GBARTSAVE,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, // need token from the web
            data: { artmeta: local_artmeta, urlhandler, artmaintxt, purchase_artkey1, purchase_artkey2 }
        }).then((item) => {
            const { message } = item.data
            if (message === 'SUCCESS') {
                updatestatus('Saved.')
                updateartmaintxt(artmaintxt)
                updateartmeta(local_artmeta)
            }
            else { }
        })
    }

    const reload = () => setP5reloadclicked(!p5reloadclicked)
    const updatepapersize = (e) => setPapersize(e.target.value)
    const updateorient = (e) => setOrientation(e.target.value)
    const updateframe = (e) => setFrame(e.target.value)
    const updateframecolor = (c) => setFramecolor(c)
    const updatemaincanvacolor = (c) => setMaincanvacolor(c)
    const updateqrplacement = (e) => qractive === e.target.value ? setQractive('NONE') : setQractive(e.target.value)
    const toggledownload = () => {
        var isSafari = window.safari !== undefined;
        if (!isSafari) {
            setDownload(true)
        }
        else { alert("Download in Safari is not supported. Please use Google Chrome or Firefox browser to download.") }
    }
    const togglepurchase = (e) => { openpurchase(e.target.value) }


    const maintextmodal_dom = maintextmodal
        ? <div className='Art_modal'>
            <div className='Art_modalbg' onClick={() => { setMaintextmodal(false) }} />
            <div className="Art_modalcontent" >
                <div className="Art_colortitle"></div>
                <div className="Art_colortitle">Font Size </div>
                <select className="Art_select" onChange={(e) => { setTempfs(parseInt(e.target.value, 10)) }} value={tempfs}>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                    <option>60</option>
                </select>
                <div className="Art_colortitle">Font Family</div>
                <select className="Art_select" onChange={(e) => { setTempff(e.target.value) }} value={tempff} >
                    {ENGLISH_FF.map((f) => { return <option key={f} value={f}>{f}</option> })}
                </select>
                <div className="Art_colortitle">Main Text</div>
                <textarea className="Art_txtarea" rows="3" wrap="hard" value={tempartmaintxt} style={{ fontFamily: tempff, fontSize: tempfs }} onChange={(e) => {
                    e.target.value.split('\n').length < 4 ? setTempartmaintxt(e.target.value) : alert('Max 3 Lines')
                }} />
                <div className="Art_row" style={{ marginBottom: 5 }} >
                    <button className="Art_reload" style={{ marginTop: 0 }} onClick={() => { setMaintextmodal(false); }} >Cancel</button>
                    <button className="Art_save" style={{ marginTop: 0 }} onClick={() => {
                        setArtmaintxt(tempartmaintxt);
                        setFf(tempff)
                        setFs(tempfs)
                        setMaintextmodal(false);
                    }}>Update</button>
                </div>
            </div>
        </div>
        : null

    const imgmodal_dom = imgmodal ? <div className="Imageic_croproot">
        <div className="Imageic_cropcontainer">
            <Cropper
                image={tempimg}
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
            />
        </div>
        <div className="Imageic_btnsrow">
            <button className="Imageic_btn" onClick={closecropmodal}>Cancel</button>
            <button className="Imageic_btn" onClick={croppedupload}>Upload</button>
        </div>
    </div> : null

    return <div className={"Artwork_root" + device}>
        {Artcommon.backbtn(changecat)}
        <div className={
            download ? "Emojiart_left" + device + '_' : "Emojiart_left" + device} style={(orientation === "landscape" || device === 'TABLET') ? { flexDirection: 'row', justifyContent: 'space-between' } : { flexDirection: 'column', justifyContent: 'space-between' }}>
            <p />{pfive}<p />
        </div>
        <div className={"Artwork_right" + device} >
            <div className="Artwork_righttop">
                {USERFACING ? Artcommon.endbtns(reload, save, purchase, artkey) : null}
                {!USERFACING ? Artcommon.papersizebtns(updatepapersize, papersize) : null}
                {Artcommon.orientbtns(updateorient, orientation)}
                {Artcommon.framebtns(updateframe, frame)}
                {Artcommon.twoselectionbtns('Emoji Count', numemoji, 100, 150, () => setNumemoji(100), () => setNumemoji(150), '<100', '<150')}
                {Artcommon.threeselectionbtns('Emoji Size', emojisizeconstant, '0.035', '0.04', '0.05', (e) => setEmojisizeconstant(e.target.value), 'Small', 'Mid', 'Large')}
                {Artcommon.colorpalette(updatemaincanvacolor, ['white', 'beige'], maincanvacolor, "Canva Color")}
                {frame === 'framed' ? Artcommon.colorpalette(updateframecolor, ['white', 'beige', '#707070', 'black'], framecolor, "Frame Color") : null}
                {!USERFACING ? Artcommon.qrurlbtns(updateqrplacement, qractive, urlhandler) : null}
                {GT_CENTER_ITEM ? imgortxt_dom : null}
                {GT_CENTER_ITEM ? { 'image': imginput_dom, 'text': centertxt_dom }[centertype] : null}{ }
                {!USERFACING ? Artcommon.twoselectionbtns('Logo', logogate, true, false, (e) => { setLogogate(logogate ? null : true) }, () => { setLogogate(!logogate ? null : false) }, 'Show Logo', 'Hide Logo') : null}
            </div>
            {USERFACING ? Artcommon.purchasedlbtns(purchase, artkey, togglepurchase, toggledownload) : Artcommon.puchasedbtnsonly(purchase, artkey, () => { }, toggledownload)}
        </div>
        <Noti noti={error} />
        <Noti noti={status} />
        {maintextmodal_dom}
        {imgmodal_dom}
    </div>
}

export default Emojiart