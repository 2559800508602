import React, { useEffect, useState } from 'react'
import ReactDom from 'react-dom'
import './Portalmodal.css'

const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    zIndex: 1999999999, // set to this instead of 999.... because of recaptcha
}

const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#000',
    opacity: 0.5,
    zIndex: 1999999999
}

const CLOSEBTN_STYLES = {
    backgroundColor: 'transparent',
    border: 'none',
    position: 'absolute',
    padding: 0,
    cursor: 'pointer',
    zIndex: 1,
    color: '#707070',
    height: 60,
    margin: '0px 10px'
}

const FLOATING_CLOSEBTN_STYLES = {
    left: -26,
    top: -26,
    color: '#eb5d7b'
}

const s_ = { width: 30, height: 40, verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden' }
const vb_ = "0 0 1024 1024"
const v_ = "1.1"
const x_ = "http://www.w3.org/2000/svg"

function Portalmodal({ portaltype = 'commonportal', open, children, onClose, closestyle, floatingclose = false, floatingclosecolor = '#707070', backdropvisible, modalstyle = null, animation, floatingdom }) {
    // useEffect(()=>{
    //     console.log(document.getElementById('root').style.overflow)
    //     document.getElementById('root').style.overflow = 'hidden !important'
    //     document.getElementById('app-root').style.overflow = 'hidden !important'
    // },[])

    const [animationstate, setAnimationstate] = useState('opening') // opening or closing

    useEffect(() => {
        setAnimationstate('opening')
    }, [])

    const close = () => {
        if (animation === 'right_left') {
            setAnimationstate('closing')
        }
        else { onClose() }
    }

    if (!open) return null
    return ReactDom.createPortal(
        <div id={`Portalmodal_root`} className={`${backdropvisible ? 'Portalmodal_backdrop' : ''}`} style={{ backgroundColor: false ? 'transparent' : '' }}>
            <div style={OVERLAY_STYLES} />
            {{
                'commonportal': <div
                    style={{ ...MODAL_STYLES, ...modalstyle, }}
                    className={`Portalmodal_modal ${animation==='right_left'? `Portalmodal_${animationstate}`:''}`}
                    onAnimationEnd={() => {
                        if(animationstate==='closing'){
                            onClose()
                            setAnimationstate('opening')
                        }
                    }}
                >
                    <button className='Portalmodal_close' style={{ ...CLOSEBTN_STYLES, ...closestyle, ...(floatingclose ? FLOATING_CLOSEBTN_STYLES : '') }} onClick={close}>
                        <svg className="icon" style={{ ...s_, width: 32, height: 32, borderRadius: '100%', backgroundColor: 'white' }} viewBox={vb_} version={v_} xmlns={x_}><path style={{ fill: floatingclosecolor, }} d="M442.24 517.504l-114.88 115.2a48 48 0 1 0 67.968 67.776l114.688-115.008L624.768 700.48a48 48 0 0 0 67.968-67.84l-114.88-115.136 114.88-115.072a48 48 0 1 0-67.968-67.84L510.016 449.6 395.328 334.592a48 48 0 0 0-67.968 67.84l114.88 115.072zM512 1024A512 512 0 1 1 512 0a512 512 0 0 1 0 1024z" fill="#666666" p-id="10405"></path></svg>
                    </button>
                    {children}
                </div>,
                'userdecide': <div style={{ ...MODAL_STYLES, ...modalstyle }} className="Portalmodal_modal" >
                    {children}
                </div>
            }[portaltype]}
            {floatingdom}
        </div>,
        document.getElementById('portal')
    )
}

export default React.memo(Portalmodal)