import React, { useState, useEffect, useRef } from 'react'
import { EMPTYSPACE, EXPAND_RIGHT_ICON, EXPAND_ICON, TRASH_SMALL, SIXDOT_SMALL, MAIN_PINK_COLOR, b_fs, f_fs, d_fs, c_fs, g_fs, HIDE_SMALL, SHOW_SMALL, DOWNLOAD_SMALL } from '../../../../config/constants'
import { Checkdevice, Jfmodal, Modcfm, Noti, ScreenWidth } from '../../../../components'
import { READ_BUDGET, CREATE_BUDGET, REMOVE_SINGLEBUDGET, UPDATE_BUDGET } from '../../../../config/api';
import { Retrieve_personal_info } from '../../../../config/commonfunctions';
import { BUTTONSTYLE } from '../../../../config/cssconstants';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { CSVLink } from 'react-csv';
import { Prompt } from 'react-router'
import { Selectionbox } from '../Components';
import Chart from './Chart';
import Budgettext from './components/Budgettext';
import Continuepage from '../Components/Continuepage/Continuepage';
import Createnew from '../Components/Createnew/Createnew';
import axios from "axios";
import '../Planning.css'
import './Budgetmaker.css'

const patt = /^\d+(\.\d{1,2})?$/i;

const exceltitle_root = { border: 'none', outline: 'none', textTransform: 'uppercase', letterSpacing: 0.5, alignSelf: 'center' }
const exceltitle = { padding: 5, margin: 5, outline: 'none', border: 'thin solid rgb(238, 238, 238)', borderRadius: 0, letterSpacing: 0.5, alignSelf: 'center' } //, borderBottom: 'thin solid #707070'
const title_ratio = '40%'
const price_ratio = '20%'
const amtpaid_ratio = '20%'
const amtdue_ratio = '20%'

const title_ratio_1 = '50%'
const price_ratio_1 = '50%'

const grid = 1;

const device_css_map = {
    'WEB': { padding: '0px 80px', width: '', display: 'flex', flexDirection: 'column', color: '#707070', },
    'TABLET': { padding: '0px 16px', width: '', display: 'flex', flexDirection: 'column', color: '#707070', },
    'MOBILE': { padding: '', width: '100%', display: 'flex', flexDirection: 'column', color: '#707070', }
}

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#707070' : 'lightgrey',
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? '#707070' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle
});

const CheckArrayZeroes = (arr) => {
    var allzeroes = true
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== 0) {
            allzeroes = false
        }
    }
    return allzeroes
}

function Budgetmaker(props) {
    const { token } = Retrieve_personal_info() // for axios call
    const device = Checkdevice()
    const wt = ScreenWidth()
    const VT_DEVICE_FS_MAP = { 'WEB': b_fs, 'TABLET': c_fs, 'MOBILE': d_fs }
    const CELL_DEVICE_FS_MAP = { 'WEB': d_fs, 'TABLET': d_fs, 'MOBILE': f_fs }
    const TITLE_DEVICE_FS_MAP = { 'WEB': f_fs, 'TABLET': f_fs, 'MOBILE': g_fs }

    const btmref = useRef(null) // click add and scroll to btm

    const [firsttime, setFirsttime] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [savegate, setSavegate] = useState(false)

    const [budget_ids, setBudget_ids] = useState([]) // contain of all budget_ids (budget obj)
    const [currency, setCurrency] = useState('$')
    const [currtotalcost, setCurrtotalcost] = useState(0)
    const [currtotalpaid, setCurrtotalpaid] = useState(0)
    const [currtotaldue, setCurrtotaldue] = useState(0)
    const [currvtobjs_id, setCurrvtobjs_id] = useState('')
    const [currvtobjs, setCurrvtobjs] = useState(null)
    const [currvtobjs_name, setCurrvtobjs_name] = useState('')
    const [currvtobjs_index, setCurrvtobjs_index] = useState(null)
    const [currvtobjs_budget, setCurrvtobjs_budget] = useState(0)

    const [currchart, setCurrchart] = useState('Total') // for chart
    const [currvtobjs_actualcost, setCurrvts_actualcost] = useState([]) // for chart data
    const [currvtobjs_amtpaid, setCurrvts_amtpaid] = useState([]) // for chart data
    const [currvtobjs_amtdue, setCurrvts_amtdue] = useState([]) // for chart data

    const [addclicked, setAddclicked] = useState(false)

    const [csvdata, setCsvdata] = useState([]) // for csv

    const [mode, setModcfme] = useState(-1)
    const [notemodal, setNotemodal] = useState([false, -1]) // 0 - modal gate, 1 - index of sub-vt
    const [hoversub, setHoversub] = useState(null)
    const [quota, setQuota] = useState(null)
    const [page, setPage] = useState('NONE') // NONE, CREATE, OPEN (OPEN EXISTING)
    const [msg, setMsg] = useState('')
    const [savemsg, setSavemsg] = useState('')
    const [hover, setHover] = useState('')
    const [scrollPosition, setScrollPosition] = useState(0);

    const [poptemplate, setPoptemplate] = useState(false) // template modal
    const [rmvmod, setRmvmod] = useState(false)
    const [rmvindex, setRmvindex] = useState(null)
    const [rmvid, setRmvid] = useState(null)

    useEffect(() => {
        if (firsttime) {
            setFirsttime(false)
            try {
                let options = {
                    method: 'POST',
                    url: READ_BUDGET,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                }
                axios(options).then((result) => {
                    const { data } = result
                    const { gtbudgets, quota, budget_objs, budget_ids } = data
                    reorder(gtbudgets, budget_objs, budget_ids);
                    setQuota(quota)
                    setLoaded(true)
                    setPage('NONE')
                }).catch((e) => { })
            }
            catch (e) { }
        }
    }, [firsttime])

    useEffect(() => {
        if (savemsg.length > 0) {
            setTimeout(function () {
                setSavemsg('')
            }, 2000);
        }
    }, [savemsg])

    useEffect(() => {

        // restructure the curr_vtobj for CSVLink to export
        if (currvtobjs && currvtobjs.length > 0) {

            var totalcost = 0
            var totalpaid = 0
            var totaldue = 0
            var new_csvData = []
            var currvtobjs_actualcost = [] // chart
            var currvtobjs_amtpaid = [] // chart
            var currvtobjs_amtdue = [] // chart

            new_csvData.push(['', 'actual_cost', 'amount_due', 'amount_paid', 'estimated_cost']) // title , 'payments'


            for (let i = 0; i < currvtobjs.length; i++) {
                const { vt_name, items, note } = currvtobjs[i]
                if (i !== 0) new_csvData.push([''])
                new_csvData.push([vt_name])
                new_csvData.push([note])
                var actualcost_data = 0 // chart
                var amtpaid_data = 0 // chart
                var amtdue_data = 0 // chart
                for (let j = 0; j < items.length; j++) {

                    const { actual_cost = 0, amount_paid = 0, amount_due = 0, estimated_cost, title } = items[j]
                    var row = [title, actual_cost, amount_due, amount_paid, estimated_cost]
                    new_csvData.push(row)

                    var actualcost = (actual_cost.length === 0 ? 0 : parseInt(actual_cost, 10))
                    var amtpaid = (amount_paid.length === 0 ? 0 : parseInt(amount_paid, 10))
                    var amtdue = (amount_due.length === 0 ? 0 : parseInt(amount_due, 10))

                    actualcost_data = actualcost_data + actualcost // chart
                    amtpaid_data = amtpaid_data + amtpaid// chart 
                    amtdue_data = amtdue_data + amtdue// chart 

                    totalcost = totalcost + actualcost
                    totalpaid = totalpaid + amtpaid
                    totaldue = totaldue + amtdue

                }
                currvtobjs_actualcost.push(actualcost_data)// chart
                currvtobjs_amtpaid.push(amtpaid_data)// chart
                currvtobjs_amtdue.push(amtdue_data)// chart
            }

            // check if currvtobjs_actualcost is all 0? if is all 0s then dont render graph
            var allzeroes = CheckArrayZeroes(currvtobjs_actualcost)
            if (!allzeroes) setCurrvts_actualcost(currvtobjs_actualcost) // chart
            else setCurrvts_actualcost([])

            allzeroes = CheckArrayZeroes(currvtobjs_amtpaid)
            if (!allzeroes) setCurrvts_amtpaid(currvtobjs_amtpaid) // chart
            else setCurrvts_amtpaid([])

            allzeroes = CheckArrayZeroes(currvtobjs_amtdue)
            if (!allzeroes) setCurrvts_amtdue(currvtobjs_amtdue) // chart
            else setCurrvts_amtdue([])

            setCurrtotalcost(totalcost)
            setCurrtotalpaid(totalpaid)
            setCurrtotaldue(totaldue)
            setCsvdata(new_csvData)

        }

    }, [currvtobjs])

    function reorder(gtbudgets, budget_objs, budget_ids) {
        if (gtbudgets) {
            // reorder them based on checklist_ids, as db return messy indexed arr
            var new_objs = budget_objs.map(() => []);
            for (let i = 0; i < budget_objs.length; i++) {
                const { _id } = budget_objs[i];
                var index = budget_ids.indexOf(_id);
                new_objs[index] = budget_objs[i];
            }
            setBudget_ids(new_objs);
        }
        else
            setBudget_ids([]);
    }

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const onCatclick = (i) => {
        var new_vtobjs = currvtobjs.slice()
        new_vtobjs[i].collapse = !new_vtobjs[i].collapse
        setCurrvtobjs(new_vtobjs)
    }

    const onRemove = (i) => {
        var new_vtobjs = currvtobjs.slice()
        new_vtobjs.splice(i, 1)
        if (new_vtobjs.length > 0) {
            setSavegate(true)
            setCurrvtobjs(new_vtobjs)
        }
    }

    const onRemoverowcells = (index, inner_index) => {
        setSavegate(true)
        var new_vtobjs = currvtobjs.slice()
        new_vtobjs[index]['items'].splice(inner_index, 1)
        setCurrvtobjs(new_vtobjs)
    }

    const onDragend = (result) => {
        // dropped outside the list
        if (!result.destination) return;
        const { source, destination, draggableId } = result
        const mainorsub = draggableId.split(' ')[1]
        var new_vtobjs = currvtobjs.slice()

        setSavegate(true)
        if (mainorsub === 'main') {
            const newest = new_vtobjs[source.index]
            new_vtobjs.splice(source.index, 1)
            new_vtobjs.splice(destination.index, 0, newest)
            setCurrvtobjs(new_vtobjs)
        }
        else if (mainorsub === 'primary') {
            var pri_source_index = source.droppableId.split(' ')[1] // the vendortype index
            var pri_dest_index = destination.droppableId.split(' ')[1] // the vendortype index
            var source_index = source.index // the inner index
            var dest_index = destination.index // the inner index
            var temp = new_vtobjs[pri_source_index]['items'][source_index]

            if (pri_source_index === pri_dest_index) { //  a switch in a same cat, conduct a swap
                new_vtobjs[pri_source_index]['items'].splice(source_index, 1)// remove from source
                new_vtobjs[pri_source_index]['items'].splice(dest_index, 0, temp) // insert to dest
            }
            else { // a switch in diff cat, cross pri swap
                new_vtobjs[pri_dest_index]['items'].splice(dest_index, 0, temp) // insert to dest
                new_vtobjs[pri_source_index]['items'].splice(source_index, 1)// remove from source
            }
            setCurrvtobjs(new_vtobjs)
            setHoversub(null)
        }

    }

    const onAddexcelrow = (mainindex) => {

        setSavegate(true)
        var new_vtobjs = currvtobjs.slice()
        new_vtobjs[mainindex]['items'].push({
            "title": "-",
            "estimated_cost": "0",
            "actual_cost": "0",
            "amount_paid": "0",
            "amount_due": "0",
        })// remove from source
        setCurrvtobjs(new_vtobjs)

    }

    const onAddmain = () => {

        // loop all currvtobjs to ensure no repeat of 
        setSavegate(false)
        var new_vtobjs = currvtobjs.slice()
        new_vtobjs.push(
            {
                vt_name: 'New Category',
                icon: "",
                collapse: true,
                note: "",
                items: [
                    {
                        "title": "-",
                        "estimated_cost": "0",
                        "actual_cost": "0",
                        "amount_paid": "0",
                        "amount_due": "0",
                    }
                ]
            }
        )
        setCurrvtobjs(new_vtobjs)


        btmref.current.scrollIntoView({ behavior: 'smooth' });

    }

    const openNotemodal = (index) => {
        setNotemodal([true, index]) // index is the position of the vtobject
    }

    const onVtchange = (index, e) => {
        setSavegate(true)
        var new_vtobjs = currvtobjs.slice()
        new_vtobjs[index]['vt_name'] = e.target.value
        setCurrvtobjs(new_vtobjs)
    }

    const onChangecellval = (key, val, index, inner_index) => {
        // key : title, actual_cost, actual_amount, actual_paid, amount_due
        var new_vtobjs = currvtobjs.slice()
        if (patt.test(val)) new_vtobjs[index]['items'][inner_index][key] = parseFloat(val)
        else if (val === '') new_vtobjs[index]['items'][inner_index][key] = 0
        else return
        if (!savegate) setSavegate(true) // update savegate
        setCurrvtobjs(new_vtobjs)
    }

    const save = () => { // MODE_AND_SAVE_AND_NAME_DIV
        setSavemsg('Processing...')
        setTimeout(() => {
            try {
                axios({
                    method: 'POST',
                    url: UPDATE_BUDGET,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: {
                        budget_id: currvtobjs_id,
                        name: currvtobjs_name,
                        budget_obj: currvtobjs && currvtobjs.length > 0 ? currvtobjs : [],
                        currvtobjs_budget: currvtobjs_budget
                    }
                }).then((result) => {
                    const { message } = result.data
                    if (message === 'SUCCESS') {
                        setSavemsg('Successfully saved!')
                        setSavegate(false)
                        setTimeout(() => setSavemsg(''), 1500)
                    }
                }).catch((e) => { })
            }
            catch (e) {
            }
        }, 1000)
    }

    const switchmode = () => { // MODE_AND_SAVE_AND_NAME_DIV
        mode === 1 ? setModcfme(0) : setModcfme(1)
    }

    const hideall = () => { // hide all vts
        var temp = currvtobjs.slice()
        for (let i = 0; i < temp.length; i++) {
            temp[i].collapse = false
        }
        setCurrvtobjs(temp)
    }

    const showall = () => { // show all vts
        var temp = currvtobjs.slice()
        for (let i = 0; i < temp.length; i++) {
            temp[i].collapse = true
        }
        setCurrvtobjs(temp)
    }

    const MODE_AND_SAVE_AND_NAME_DIV = (
        currvtobjs && currvtobjs.length > 0 ?
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' }}>


                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: 35 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                        <button style={{ ...BUTTONSTYLE, cursor: 'pointer', padding: 5, margincolor: '#707070', alignItems: 'center', whiteSpace: 'nowrap', border: 'thin solid #707070', backgroundColor: 'white', boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px', flexDirection: 'row', display: 'flex', borderRadius: 5 }}
                            onClick={hideall}>{HIDE_SMALL}</button>
                        {EMPTYSPACE}
                        <button style={{ ...BUTTONSTYLE, cursor: 'pointer', padding: 5, margincolor: '#707070', alignItems: 'center', whiteSpace: 'nowrap', border: 'thin solid #707070', backgroundColor: 'white', boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px', flexDirection: 'row', display: 'flex', borderRadius: 5 }}
                            onClick={showall}>{SHOW_SMALL}</button>
                        {EMPTYSPACE}
                        <CSVLink onClick={showall} filename={"budget.csv"} data={csvdata} style={{ outline: 'none', color: '#707070', textDecoration: 'none', padding: 5, borderRadius: 5, border: 'thin solid #707070', width: 'max-content', height: 'max-content', backgroundColor: 'white' }}>
                            {DOWNLOAD_SMALL}
                        </CSVLink>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                        <div style={{ ...BUTTONSTYLE, cursor: 'pointer', padding: 5, color: MAIN_PINK_COLOR, alignItems: 'center', border: 'thin solid ' + MAIN_PINK_COLOR, backgroundColor: 'white', boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px', flexDirection: 'row', display: 'flex', borderRadius: 5 }}
                            onClick={switchmode}>
                            {mode === 1 ? 'Basic' : 'Advanced'}
                        </div>
                        {EMPTYSPACE}
                        {savemsg.length > 0 ? null : <div style={{ ...BUTTONSTYLE, cursor: 'pointer', padding: 5, color: 'white', backgroundColor: MAIN_PINK_COLOR, border: 'thin solid ' + MAIN_PINK_COLOR, boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px', borderRadius: 5 }} onClick={save}>Save</div>}
                    </div>
                </div>

                <input
                    style={{ padding: '0px 5px', margin: '10px 0px', borderRadius: 5, width: '100%', whiteSpace: 'nowrap', textAlign: 'center', outline: 'none', backgroundColor: 'transparent', border: 'thin solid transparent', justifyContent: 'center', fontSize: VT_DEVICE_FS_MAP[device], }}
                    value={currvtobjs_name}
                    placeholder={'Name of budget'}
                    onChange={(e) => { // update two different obj : currvtobjs, and budget_ids
                        setSavegate(true)
                        setCurrvtobjs_name(e.target.value)
                        var curr_budget_ids = budget_ids.slice()
                        curr_budget_ids[currvtobjs_index]['name'] = e.target.value
                        setBudget_ids(curr_budget_ids)
                    }}
                />

                <div style={{ paddingTop: 15, display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: 5 }}> {/* BUDGET */}
                        <div style={{ display: 'flex', flexWrap: 'nowrap', whiteSpace: 'nowrap', height: '100%', fontFamily: 'lato' }}>{'Budget : '}</div>
                        <div style={{ display: 'flex', flexWrap: 'nowrap', whiteSpace: 'nowrap', height: '100%', fontSize: VT_DEVICE_FS_MAP[device], padding: '0px 5px' }}>{'$ '}</div>
                        <input
                            style={{ padding: 5, borderRadius: 5, width: '100%', whiteSpace: 'nowrap', outline: 'none', fontSize: VT_DEVICE_FS_MAP[device], border: 'thin solid #707070', alignSelf: 'flex-start', }}
                            value={currvtobjs_budget}
                            placeholder={'0'}
                            onChange={(e) => {
                                var val = e.target.value

                                if (patt.test(val)) setCurrvtobjs_budget(parseFloat(val));
                                else if (val === '') setCurrvtobjs_budget(0);
                                else return

                                // update savegate
                                if (!savegate) setSavegate(true)

                                // set the main data point
                                var curr_budget_ids = budget_ids.slice()
                                curr_budget_ids[currvtobjs_index]['budgetstr'] = 0
                                setBudget_ids(curr_budget_ids)
                            }}
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> {/* TOTAL CATEGORY */}
                        <div style={{ display: 'flex', flexWrap: 'nowrap', whiteSpace: 'nowrap', fontFamily: 'lato' }}>{'Cat : '}</div>
                        <div style={{ padding: 5, margin: '0px 5px', backgroundColor: '#707070', color: 'white', borderRadius: 5 }}> {currvtobjs.length}</div>
                    </div>

                </div>

            </div>
            : null
    )

    const MASTER_TITLE_DIV = (
        currvtobjs && currvtobjs.length > 0 ?
            <div style={{ textAlign: 'flex-start', padding: '10px 0px' }}>
                {[
                    'Keep track of your spending and overall budget throughout the planning process. You can easily sort the categories and items within the categories based on your preference using our drag and drop function. Click on Advanced button to change to a more detail function. Click on ',
                    <button
                        key={'weddingbtn_bm'}
                        className="planning_templatebtn"
                        onClick={() => setPoptemplate(true)}>
                        Wedding
                    </button>
                    , ' sample to replace your current data with our sample data.'
                    // , ' or '
                    // , <button
                    //     style={{ ...BUTTONSTYLE, display: '', cursor: 'pointer', width: 'max-content', padding: 'auto', color: '#707070', alignItems: 'center', whiteSpace: 'nowrap', border: 'thin solid #707070', backgroundColor: 'white', boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px', borderRadius: 5 }}
                    //     onClick={() => { // MODE_AND_SAVE_AND_NAME_DIV
                    //         // this will override current obj
                    //         var confirmation = window.confirm("This will override current data, this action is irreversible.");
                    //         if (!confirmation) return
                    //         setSavegate(false)
                    //         setCurrvtobjs(romcovid19_data)
                    //     }}>
                    //     ROM P2 [COVID-19]
                    // </button>
                ]}
            </div>
            : null
    )

    var SUMMARY_DIV = (title, result, chartstate) => {
        return <div style={{ padding: '5px 10px', display: 'flex', flexDirection: 'column', cursor: 'pointer', boxShadow: 'rgba(25, 25, 25, 0.25) 0px 2px 3px', margin: '5px 5px 0px 0px', borderRadius: 5, overflowWrap: 'anywhere', backgroundColor: 'white' }} onClick={() => setCurrchart(chartstate)}>
            <div style={{ fontSize: d_fs, fontWeight: 'bold' }}>{title}</div>
            <div>{result}</div>
        </div>
    }

    const Collapsible = (items, note, index) => {

        const notebtn = <div className="planning_usablebtn" style={{ border: note && note.length ? 'thin solid #e25a5a' : 'thin solid #707070', backgroundColor: note && note.length ? '#e25a5a' : 'white', color: note && note.length ? 'white' : '#707070', }} onClick={() => openNotemodal(index)}>Note</div>

        const addbtn = <div className="planning_usablebtn" onClick={() => onAddexcelrow(index)}>New Expense</div>

        const delcatbtn = <div className="planning_delbtn" onClick={() => onRemove(index)}>x</div>

        var local_ac = 0
        var local_ap = 0
        var local_ad = 0
        if (mode === 0) {
            for (let i = 0; i < items.length; i++) {
                const { actual_cost } = items[i]
                local_ac += parseInt(actual_cost, 10)
            }
        }
        else {
            for (let i = 0; i < items.length; i++) {
                const { actual_cost, amount_paid, amount_due } = items[i]
                local_ac += parseInt(actual_cost, 10)
                local_ap += parseInt(amount_paid, 10)
                local_ad += parseInt(amount_due, 10)
            }
        }


        return <div style={{ padding: 5 }}>
            {mode === 1
                ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ color: 'transparent' }} >{SIXDOT_SMALL}</div>
                    <div style={{ ...exceltitle_root, width: title_ratio, fontSize: TITLE_DEVICE_FS_MAP[device] }}>Title</div>
                    <div style={{ ...exceltitle_root, width: price_ratio, fontSize: TITLE_DEVICE_FS_MAP[device] }}>Price</div>
                    <div style={{ ...exceltitle_root, width: amtpaid_ratio, fontSize: TITLE_DEVICE_FS_MAP[device] }}>Amount Paid</div>
                    <div style={{ ...exceltitle_root, width: amtdue_ratio, fontSize: TITLE_DEVICE_FS_MAP[device] }}>Amount Due</div>
                    <div style={{ color: 'transparent' }}>{SIXDOT_SMALL}</div>
                </div>
                : <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ color: 'transparent' }}>{SIXDOT_SMALL}</div>
                    <div style={{ ...exceltitle_root, width: title_ratio_1, fontSize: TITLE_DEVICE_FS_MAP[device] }}>Title</div>
                    <div style={{ ...exceltitle_root, width: price_ratio_1, fontSize: TITLE_DEVICE_FS_MAP[device] }}>Price</div>
                    <div style={{ color: 'transparent' }}>{SIXDOT_SMALL}</div>
                </div>}
            <Droppable droppableId={'primary ' + index} type="primary">
                {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} >
                        {items && items.map(({ title, estimated_cost, actual_cost, amount_paid, amount_due }, inner_index) => {
                            return (
                                <Draggable key={index + ' primary ' + inner_index} draggableId={index + ' primary ' + inner_index} index={inner_index} type="primary" >
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} onMouseEnter={() => { setHoversub(index + 'hoversub' + inner_index) }} onMouseLeave={() => { setHoversub('') }} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                            {mode === 1
                                                ? <div key={inner_index + 'primary'} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                                                    {device === 'MOBILE'
                                                        ? <div onClick={() => onRemoverowcells(index, inner_index)}>{TRASH_SMALL}</div>
                                                        : <div style={{ color: index + 'hoversub' + inner_index === hoversub ? '' : 'transparent' }} onClick={() => onRemoverowcells(index, inner_index)}>{TRASH_SMALL}</div>}
                                                    <input style={{ ...exceltitle, width: title_ratio, fontSize: CELL_DEVICE_FS_MAP[device], }} value={title} onChange={(e) => onChangecellval('title', e.target.value, index, inner_index)} />
                                                    <input style={{ ...exceltitle, width: price_ratio, fontSize: CELL_DEVICE_FS_MAP[device], }} value={actual_cost} onChange={(e) => onChangecellval('actual_cost', e.target.value, index, inner_index)} />
                                                    <input style={{ ...exceltitle, width: amtpaid_ratio, fontSize: CELL_DEVICE_FS_MAP[device], }} value={amount_paid} onChange={(e) => onChangecellval('amount_paid', e.target.value, index, inner_index)} />
                                                    <input style={{ ...exceltitle, width: amtdue_ratio, fontSize: CELL_DEVICE_FS_MAP[device], }} value={amount_due} onChange={(e) => onChangecellval('amount_due', e.target.value, index, inner_index)} />
                                                    {device === 'MOBILE'
                                                        ? <div>{SIXDOT_SMALL}</div>
                                                        : <div style={{ color: index + 'hoversub' + inner_index === hoversub ? '' : 'transparent' }} >{SIXDOT_SMALL}</div>}
                                                </div>
                                                : <div key={inner_index + 'primary'} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                                                    {device === 'MOBILE'
                                                        ? <div onClick={() => onRemoverowcells(index, inner_index)}>{TRASH_SMALL}</div>
                                                        : <div style={{ color: index + 'hoversub' + inner_index === hoversub ? '' : 'transparent' }} onClick={() => onRemoverowcells(index, inner_index)}>{TRASH_SMALL}</div>}
                                                    <input style={{ ...exceltitle, width: title_ratio_1, fontSize: CELL_DEVICE_FS_MAP[device], }} value={title} onChange={(e) => onChangecellval('title', e.target.value, index, inner_index)} />
                                                    <input style={{ ...exceltitle, width: price_ratio_1, fontSize: CELL_DEVICE_FS_MAP[device], }} value={actual_cost} onChange={(e) => onChangecellval('actual_cost', e.target.value, index, inner_index)} />
                                                    {device === 'MOBILE'
                                                        ? <div>{SIXDOT_SMALL}</div>
                                                        : <div style={{ color: index + 'hoversub' + inner_index === hoversub ? '' : 'transparent' }} >{SIXDOT_SMALL}</div>}
                                                </div>}
                                        </div>)}
                                </Draggable>
                            )
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            <div style={{ width: '100%', height: 1, border: 'thin solid #eeee' }} />
            {mode === 1
                ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 2, paddingTop: 10 }} >
                    <div style={{ color: 'transparent' }}>{TRASH_SMALL}</div>
                    <div style={{ ...exceltitle, width: title_ratio, fontSize: CELL_DEVICE_FS_MAP[device], backgroundColor: 'white', color: '#707070', border: 'none', fontWeight: 'bold', textAlign: 'right' }}>Total: </div>
                    <div style={{ ...exceltitle, width: price_ratio, fontSize: CELL_DEVICE_FS_MAP[device], backgroundColor: '#f8f8f8', color: '#707070' }}>{local_ac}</div>
                    <div style={{ ...exceltitle, width: amtpaid_ratio, fontSize: CELL_DEVICE_FS_MAP[device], backgroundColor: '#f8f8f8', color: '#707070' }} >{local_ap}</div>
                    <div style={{ ...exceltitle, width: amtdue_ratio, fontSize: CELL_DEVICE_FS_MAP[device], backgroundColor: '#f8f8f8', color: '#707070' }} >{local_ad}</div>
                    <div style={{ color: 'transparent' }}>{SIXDOT_SMALL}</div>
                </div>
                : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 2, paddingTop: 10 }} >
                    <div style={{ color: 'transparent' }}>{TRASH_SMALL}</div>
                    <div style={{ ...exceltitle, width: title_ratio_1, fontSize: CELL_DEVICE_FS_MAP[device], backgroundColor: 'white', color: '#707070', border: 'none', fontWeight: 'bold', textAlign: 'right' }}>Total : </div>
                    <div style={{ ...exceltitle, width: price_ratio_1, fontSize: CELL_DEVICE_FS_MAP[device], backgroundColor: '#f8f8f8', color: '#707070' }}>{local_ac}</div>
                    <div style={{ color: 'transparent' }}>{SIXDOT_SMALL}</div>
                </div>}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {delcatbtn}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {notebtn}
                    {EMPTYSPACE}
                    {addbtn}
                </div>
            </div>
        </div>
    }

    const Noncollapsable = (items) => {
        return <div className="Checklist_nonequal">{items.length + ' Item'}</div>
    }

    const clickexist = (clickid) => {
        // var switchbudget = () => {
        //     var local_budget_ids = budget_ids.slice()
        //     // update curr vtobj to replace the new one
        //     if (currvtobjs_index > -1 && currvtobjs && currvtobjs.length > 0) {
        //         var temp = currvtobjs.slice()
        //         local_budget_ids[currvtobjs_index].vtobjs = temp
        //         setBudget_ids(local_budget_ids)
        //     }

        //     const { mode, vtobjs, currency, budgetstr, name } = local_budget_ids[index]

        //     setCurrency(currency && currency.length > 0 ? currency : '$')
        //     setModcfme(mode)
        //     setCurrvtobjs(vtobjs)
        //     setCurrvtobjs_id(_id)
        //     setCurrvtobjs_name(name)
        //     setCurrvtobjs_budget(budgetstr)
        //     setCurrvtobjs_index(index)
        //     setCurrchart('Total')
        // }

        // var wannasave
        // if (savegate) { // gt new updates
        //     wannasave = window.confirm('You have not save your recent work, click OK to proceed without saving.')
        //     if (wannasave) {
        //         switchbudget();
        //         setSavegate(false);
        //     }
        //     else return
        // }
        // else { // no changes
        //     switchbudget()
        //     setSavegate(false)
        // }
        // setAddclicked(false)

        if (clickid === currvtobjs_id) {
            // uncheck 
            setCurrvtobjs_id('')
            setPage('NONE')
        }
        else {
            var obj
            for (let i = 0; i < budget_ids.length; i++) {
                if (budget_ids[i]._id === clickid) {
                    obj = budget_ids[i]
                    break
                }
            }
            const { _id, } = obj
            setCurrvtobjs_id(_id)
            setPage('OPEN')
        }

    }

    const removeexist = (_id, index) => {
        setRmvmod(true);
        setRmvid(_id);
        setRmvindex(index)
    }

    const clear = () => {
        setCurrvtobjs([])
        setCurrvtobjs_id(null)
        setCurrvtobjs_name('')
        setCurrvtobjs_budget(0)
        setCurrvtobjs_index(null)
        setMsg('')
        setCurrvts_actualcost([])
        setCurrvts_amtpaid([])
        setCurrvts_amtdue([])
        setCsvdata([])
    }



    const create = () => {
        if (budget_ids && budget_ids.length >= quota) {
            setAddclicked(true)
            setCurrvtobjs(null)
            setCurrvtobjs_name('')
            setCurrvtobjs_index(null)
            setCurrvtobjs_id(null)
            setMsg('You have reached the maximum number of projects')
            setCurrvtobjs_budget(0)
        }
        else {
            try {
                let options = {
                    method: 'POST',
                    url: CREATE_BUDGET,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                }
                axios(options).then((result) => {
                    const { singlebudgetobj } = result.data
                    var curr_budget_ids = budget_ids.slice()
                    curr_budget_ids.push(singlebudgetobj)
                    setBudget_ids(curr_budget_ids)
                }).catch((e) => {
                    setMsg('You have reached the maximum number of projects')
                    setCurrvtobjs(null)
                    setCurrvtobjs_name('')
                    setCurrvtobjs_index(null)
                    setCurrvtobjs_id(null)
                    setCurrvtobjs_budget(0)
                })
            }
            catch (e) { }
        }
    }


    const row_check = wt > 1300
    const graph_root = row_check
        ? { display: 'flex', justifyContent: 'center', flexDirection: 'column', borderTop: 'thin solid #707070', minWidth: 750, position: scrollPosition < 759.38 ? 'static' : 'sticky', placeSelf: 'flex-start', left: 17, top: scrollPosition > 759.38 ? 0 : '' }
        : { display: 'flex', justifyContent: 'center', flexDirection: 'column', borderTop: 'thin solid #707070', minWidth: '100%', position: 'relative' }

    var graph = currvtobjs && currvtobjs.length > 0 && currvtobjs_actualcost.length > 0 // CHART 
        ? <div style={graph_root}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    {SUMMARY_DIV('Total:', `${currency} ` + currtotalcost, 'Total')}
                    {SUMMARY_DIV('Available Budget:', ((currvtobjs_budget - currtotalcost) > 0 ? `${currency} ` : `-${currency} `) + ((currvtobjs_budget - currtotalcost) > 0 ? (currvtobjs_budget - currtotalcost) : -1 * (currvtobjs_budget - currtotalcost)), 'Total')}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    {mode === 1 ? SUMMARY_DIV('Amount Paid:', '$ ' + currtotalpaid, 'Paid') : null}
                    {mode === 1 ? SUMMARY_DIV('Amount Due:', '$ ' + currtotaldue, 'Due') : null}
                </div>
            </div>
            <Chart
                xdim={700}
                ydim={500}
                margin={{ top: 180, bottom: 180, left: 120, right: 120 }}
                hover={hover}
                mode={mode}
                alldata={currvtobjs}
                xdata={currvtobjs.map((item) => { return item.vt_name })}
                ydata={currchart === 'Total'
                    ? currvtobjs_actualcost
                    : currchart === 'Paid'
                        ? currvtobjs_amtpaid
                        : currvtobjs_amtdue}
                title={currchart}
                yaxistitle={'Amount'}
            />
        </div>
        : <div style={{ ...graph_root, textAlign: 'center', height: 100, color: '#707070' }}>
            Not enough data to generate graph.
        </div>

    var drag = <div className={"Bkmer_ragroot" + row_check}>
        {currvtobjs && currvtobjs.length > 0
            ? <DragDropContext onDragEnd={onDragend}> {/* SWAP OF VENDORTYPE */}
                <Droppable droppableId={'main'} type="main">
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                            {currvtobjs.map(({ vt_name, collapse, icon, items, note }, index) => {
                                var local_ac = 0
                                for (let i = 0; i < items.length; i++) {
                                    const { actual_cost } = items[i]
                                    local_ac += parseInt(actual_cost, 10)
                                }
                                return <Draggable key={index + ' main ' + index} draggableId={index + ' main ' + index} index={index} type="main"  >
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} onMouseOver={() => { setHover(currvtobjs[index]['vt_name']) }} onMouseLeave={() => { setHover('') }}>
                                            <div className="Bkmer_dragrectroot" key={index + 'main'} >
                                                <div className="Bkmer_dragtoproot">
                                                    <input className="Bkmer_dragname" style={{ fontSize: VT_DEVICE_FS_MAP[device] }} value={vt_name} onChange={(e) => onVtchange(index, e)} placeholder="New Category" />
                                                    <div className="Bkmer_expandtab" onClick={() => onCatclick(index)}>{collapse ? EXPAND_ICON : EXPAND_RIGHT_ICON}</div>
                                                </div>
                                                {collapse ? null : <div className={"Bkmer_dragprice" + device}>{'$ ' + local_ac}</div>}
                                                {collapse ? Collapsible(items, note, index) : Noncollapsable(items)}
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            : null}

        {currvtobjs && currvtobjs.length > 0// ADD NEW CAT BTN & SUMMARY OF STATS  
            ? <div className="Planning_addnewcatbg">
                <button className="Planning_addnewcatcircular" onClick={() => onAddmain()}>+</button>
            </div>
            : null}

        {currvtobjs && currvtobjs.length > 0 && notemodal[1] !== -1 // notemodal is not default value
            ? <Jfmodal
                show={notemodal[0]}
                title={currvtobjs[notemodal[1]]['vt_name'] + ', Note'}
                onHide={() => { setNotemodal([false, -1]) }}
                device={device}
                content={
                    <div className={"Bkmer_modalroot" + device}>
                        <Budgettext
                            val={currvtobjs[notemodal[1]]['note']}
                            save={(value) => {
                                setSavegate(true)
                                const temp = currvtobjs.slice()
                                temp[notemodal[1]]['note'] = value
                                setCurrvtobjs(temp)
                                setNotemodal([false, -1])
                            }}
                            cancel={() => setNotemodal([false, -1])}
                        />
                    </div>
                }
            />
            : null}
    </div>

    const updateseq = (new_ids) => { setBudget_ids(new_ids); clear(); };
    const rmvexist = (_id, index) => {
        try {
            let options = {
                method: 'POST',
                url: REMOVE_SINGLEBUDGET,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { budget_id: _id }
            }
            axios(options).then((result) => {
                const { message } = result.data
                if (message === 'SUCCESS') {
                    var curr_budget_ids = budget_ids.slice()
                    curr_budget_ids.splice(index, 1)
                    setBudget_ids(curr_budget_ids)
                    clear()
                }
            }).catch((e) => { })
        }
        catch (e) {
        }
    }

    const continueclick = () => {
        // props.history.push('qrbuilder/' + urlhandler + '/edit')
        props.history.push(`budgetbuilder/${currvtobjs_id}/edit`)
    }

    return (
        <div id='planningedit_root'>
            <div id='planningedit_selectionbox'>

                {savegate ? <Prompt message="You have not save your recent work, click OK to proceed without saving." /> : null}

                <Noti noti={savemsg} />

                {loaded
                    ? <Selectionbox
                        where={'budgetmaker'}
                        onClickexist={clickexist}
                        onRemoveexist={removeexist}
                        onAdd={create}
                        updateseq={updateseq}
                        quota={quota}
                        ids={budget_ids}
                        id={currvtobjs_id}
                    /> : null}

                {{
                    'NONE': null,
                    'OPEN': <Continuepage
                        {...props}
                        from={'budgetmaker'}
                        urlhandler={''}
                        passcode={''}
                        lookupclick={null}
                        continueclick={continueclick}
                        savepasscode={null}
                        title={''}
                    />,
                    'CREATE': <Createnew
                        {...props}
                        from={'budgetmaker'}
                        reachquota={quota === budget_ids.length}
                        urlhandler={''}
                        passcode={''}
                        create={create}
                        createsuccess={(id, urlhandler, passcode) => {
                            setCurrvtobjs_id(id)
                            // setCurrchecklist_id(id)
                            setPage('OPEN')
                        }}
                    />
                }[page]}

                {rmvmod ? <Modcfm
                    onHide={() => { setRmvmod(false) }}
                    onOk={() => { rmvexist(rmvid, rmvindex); setRmvmod(false); setPage('NONE') }}
                    onNo={() => { setRmvmod(false) }}
                    txt={'Once you delete this, there is no going back. Please be certain.'}
                /> : null}

            </div>
        </div>
    )

}

export default Budgetmaker