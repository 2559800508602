import React, { useState, memo, useEffect } from 'react'
import { iconwrapper } from '../../../../../../config/commonfunctions'
import { ADD_ICON, CROSS_ICON, TICK_ICON } from '../../../../../../config/svgicons'
import { describe_dom } from '../Commonelements/Commonelements'
import Success from './components/Success'
import './Glmodal.css'

const CROSSICON_CSS = { width: 20, height: 20, display: 'flex', placeContent: 'center' }
const ADDICON_CSS = { width: 24, height: 24, display: 'flex', placeContent: 'center' }

function Addcatcontent(props) {
    const { addcatsavingstate } = props
    const [catholder, setCatholder] = useState([])
    const [addcatmodalguestcats, setAddcatmodalguestcats] = useState([])
    const [once, setOnce] = useState(false)

    useEffect(() => {
        if (props && !once) {
            setAddcatmodalguestcats(props.addcatmodalguestcats)
            setOnce(true)
        }
    }, [props])

    const addcat = () => {
        var temp = addcatmodalguestcats.slice()
        if (catholder.length > 0) {
            if (temp.indexOf(catholder) < 0) temp.push(catholder)
            setAddcatmodalguestcats(temp)
        }
    }

    const delcat = (index) => {
        var temp = addcatmodalguestcats.slice()
        temp.splice(index, 1)
        setAddcatmodalguestcats(temp)
    }

    // <div className='Rsvpexplorer_portal'>
    //     {title_dom}
    //     {(savingstate === 'IDLE' || savingstate === 'SAVING' || savingstate === 'FAILURE')
    //         ? <div className='Rsvpexplorer_portalcontent'>
    //             {qntype_dom}
    //             {colname_dom}
    //             {whocanviewqn_dom}
    //             {qntypecontent_dom}
    //             {saving_btn}
    //         </div>
    //         : null}
    //     {success_dom}
    // </div>

    const general_dom = <div className='Rsvpexplorer_portalcontent'>
        {describe_dom('Create new category tag label. Category tag assigned to your guest allows the specific guest to view exclusive question that is available only under the specific category tag.')}
        <div style={{ display: 'flex', flexDirection: 'row', padding: '0px 10px' }}>
            <input className='Rsvpexplorer_input' style={{ marginRight: 5, marginBottom: 10 }} value={catholder} onChange={(e) => setCatholder(e.target.value)} />
            <button className='Rsvpexplorer_portaladdnewguest' onClick={addcat}>
                {iconwrapper(ADD_ICON, ADDICON_CSS)}
            </button>
        </div>

        <div style={{ border: '1px solid rgb(221, 221, 221)', minHeight: 200, maxHeight: '100%', borderRadius: 5, width: 'calc( 100% - 20px )', margin: '0px 10px', placeSelf: 'center', padding: 5, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', overflowY: 'auto', alignContent: 'start' }}>
            {addcatmodalguestcats && addcatmodalguestcats.map((item, index) => {
                return (
                    <div key={item + index} className='Rsvpexplorer_portaltag' style={{ padding: '5px 0px 5px 14px' }} >
                        <div style={{ display: 'flex', alignContent: 'center', textAlign: 'center', paddingRight: 6 }}>{item}</div>
                        <button
                            style={{ padding: '0px 5px', color: '#b21212', backgroundColor: 'transparent', margin: 0, outline: 'none', border: 'none', }}
                            onClick={() => { delcat(index) }}
                        >
                            {iconwrapper(CROSS_ICON, CROSSICON_CSS)}
                        </button>
                    </div>
                )
            })}
        </div>

        <button className='Rsvpexplorer_portalsave' onClick={() => { props.save(addcatmodalguestcats) }} disabled={addcatsavingstate === 'SAVING'}>
            {{ // IDLE, SAVING, SUCCESS, FAILURE
                'IDLE': 'Save',
                'SAVING': 'Saving...',
                'SUCCESS': 'New Category Saved',
                'FAILURE': 'Error Occurred, please try again later.'
            }[addcatsavingstate]}
        </button>

    </div>

    const success_dom = addcatsavingstate === 'SUCCESS'
        ? <Success
            txt={'Category updated. Click to return.'}
            onClick={props.close} />
        : null

    return <div className='Rsvpexplorer_portal'>
        <div className='Rsvpexplorer_portaltitle'> Add New Category </div>
        {addcatsavingstate !== 'SUCCESS' ? general_dom : success_dom}
    </div>

}

export default memo(Addcatcontent)