import {

    FETCH_HOMEUSER_DATA_BULK_ACTION_FULFILLED,
    FETCH_HOMEUSER_DATA_BULK_ACTION_PENDING,
    FETCH_HOMEUSER_DATA_BULK_ACTION_REJECTED,

    FETCH_HOMEUSER_DATA_SINGLE_ACTION_FULFILLED,
    FETCH_HOMEUSER_DATA_SINGLE_ACTION_PENDING,
    FETCH_HOMEUSER_DATA_SINGLE_ACTION_REJECTED,

} from '../actions/homeuser.action'
import refresh from './../../components/refresh'

const initialState = {
    isLoading: false,
    isLoaded: false,
    homeUser_data: [],
    singleVt_data: [],
    favourite: []// all vendortypeinterface_id of vendors
}

//rmb to unwrap to the point where the basic data structure expose
export default (state = initialState, action) => {

    switch (action.type) {
        case FETCH_HOMEUSER_DATA_BULK_ACTION_FULFILLED:
            return {
                ...state,
                homeUser_data: action.payload.data,
                favourite: action.payload.data.favourite,
                usertype: action.payload.data.usertype,
                isLoading: false,
                isLoaded: true,

            }
        case FETCH_HOMEUSER_DATA_BULK_ACTION_PENDING:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
            }
        case FETCH_HOMEUSER_DATA_BULK_ACTION_REJECTED:
            refresh()
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
            }


        case FETCH_HOMEUSER_DATA_SINGLE_ACTION_FULFILLED:
            return {
                ...state,
                singleVt_data: action.payload.data.data,
                new_favourite: action.payload.data.favourite,
                isLoading: false,
                isLoaded: true,
            }
        case FETCH_HOMEUSER_DATA_SINGLE_ACTION_PENDING:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
            }
        case FETCH_HOMEUSER_DATA_SINGLE_ACTION_REJECTED:
            refresh()
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
            }







        default:
            return state


    }
}