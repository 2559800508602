import React, { useEffect, useState } from 'react'
import { Loading, Noti } from '../../../../../../../components';
import { UPDATE_ARTTYPE } from '../../../../../../../config/api';
import { Retrieve_personal_info } from '../../../../../../../config/commonfunctions';
import { gb_dissectmsgs, gb_extractmsgtfidf } from '../../../../../../../config/gbcf';
import { Emojiart, Wordcube, Charart } from './artworks'
import axios from 'axios'
import './Rart.css'

function Rart({ msgs, device, purchase, urlhandler, artmainimg, artmaintxt, updateartmainimg, updateartmaintxt, artmeta, updateartmeta, openpurchase, arttype, updatearttype }) {
    const ALLARTKEYS = ['EMOJI1', 'WORDCUBE1', 'ALPHA1', 'TREE1', 'PATTERN1']
    // const ALLPURCHASEARTKEYS = ['EMOJI1_SOFT', 'EMOJI1_HARDSOFT', 'WORDCUBE1_SOFT', 'WORDCUBE1_HARDSOFT', 'ALPHA1_SOFT', 'ALPHA1_HARDSOFT']
    const { token } = Retrieve_personal_info()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(false)
    }, []);

    const [artcat, setArtcat] = useState('')
    const [viewcat, setViewcat] = useState('ALPHA1')
    const [status, setStatus] = useState('')

    const changecat = (e) => { setArtcat(e.target.value) }
    const oncontinue = (e) => { setViewcat(e.target.value) }
    const continueview = (e) => { setViewcat('') }
    const updatestatus = (txt) => {
        setStatus(txt)
        setTimeout(() => { setStatus('') }, 1000)
    }

    const img1 = "https://images.unsplash.com/photo-1586023492125-27b2c045efd7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1858&q=80"
    const img2 = "https://images.unsplash.com/photo-1543487945-139a97f387d5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=980&q=80"
    const img3 = "https://images.unsplash.com/photo-1484101403633-562f891dc89a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2348&q=80"
    const img4 = "https://images.unsplash.com/photo-1585264550248-1778be3b6368?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1363&q=80"
    const img5 = "https://images.unsplash.com/photo-1634208006171-6713e0c9a25e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1925&q=80"

    const selectmain = (e) => {
        var new_arttype = e.target.value
        axios({
            method: 'POST',
            url: UPDATE_ARTTYPE,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, // need token from the web
            data: { urlhandler, arttype: new_arttype }
        }).then((item) => {
            const { message } = item.data
            if (message === 'SUCCESS') {
                updatearttype(new_arttype)
                updatestatus('Updated.')
            }
            else { }
        })
    }

    if (loading) return <Loading />
    return device === 'MOBILE'
        ? <div className={'gbrwd_content_m'} >
            <div style={{ margin: 15 }}>Mobile not supported.</div>
        </div>
        : <div className={'gbedit_comp_' + device} style={{ flexDirection: 'column' }}>
            {viewcat === ''
                ? <div className="gbrwd_default" style={{ overflowY: 'auto', fontFamily: 'Lato', height: '100%' }}>
                    <div className="Rart_btnparent" >
                        <button className="Rart_btn" style={{ backgroundColor: '#fef6de', border: '5px solid white', width: 200 }}>
                            Variety of art generators that uses wishes collected from your guest to create meaningful art.
                        </button>
                        <button className={artcat === 'EMOJI1' ? "Rart_btnselect" : "Rart_btn"} onClick={changecat} value={'EMOJI1'} >
                            <div id="Rart_icon">
                                <img src="https://twemoji.maxcdn.com/v/13.1.0/72x72/1f970.png" width="32px" height="32px" alt="" />
                            </div>
                            <div id="Rart_title">Emoji</div>
                            <div id="Rart_content">Turning Emojis wishes into art collage.</div>
                        </button>
                        <button className={artcat === 'WORDCUBE1' ? "Rart_btnselect" : "Rart_btn"} onClick={changecat} value={'WORDCUBE1'} >
                            <div id="Rart_icon">
                                <img src="https://twemoji.maxcdn.com/v/13.1.0/72x72/1f4ac.png" width="32px" height="32px" alt="" />
                            </div>
                            <div id="Rart_title">Word-to-Shape </div>
                            <div id="Rart_content">Translate wishes collected to color shapes. Min 30 msgs.</div>
                        </button>
                        <button className={artcat === 'ALPHA1' ? "Rart_btnselect" : "Rart_btn"} onClick={changecat} value={'ALPHA1'} >
                            <div id="Rart_icon">
                                <img src="https://twemoji.maxcdn.com/v/13.1.0/72x72/1f524.png" width="32px" height="32px" alt="" />
                            </div>
                            <div id="Rart_title">Char-to-Shape </div>
                            <div id="Rart_content">Turning each character into color. Min 30 msgs.</div>
                        </button>
                        {/* <button className={artcat === 'TREE1' ? "Rart_btnselect" : "Rart_btn"} onClick={changecat} value={'TREE1'} >
                            <div id="Rart_icon">
                                <img src="https://twemoji.maxcdn.com/v/13.1.0/72x72/1f332.png" width="32px" height="32px" alt="" />
                            </div>
                            <div id="Rart_title">Tree</div>
                            <div id="Rart_content">{`Turning your wishes into a fractal tree. Wedding & min 30 msgs. `}</div>
                        </button>
                        <button className={artcat === 'PATTERN1' ? "Rart_btnselect" : "Rart_btn"} onClick={changecat} value={'PATTERN1'} >
                            <div id="Rart_icon">
                                <img src="https://twemoji.maxcdn.com/v/13.1.0/72x72/1f984.png" width="32px" height="32px" alt="" />
                            </div>
                            <div id="Rart_title">Weave Pattern</div>
                            <div id="Rart_content">{`Turning wishes into weave patterns. Wedding & min 30 msgs.`}</div>
                        </button> */}
                    </div>
                    {ALLARTKEYS.includes(artcat) > 0
                        ? <div id="Rart_contentroot">
                            <div id="Rart_rowimgs">
                                <img id="Rart_img" src={img1} alt="" />
                                <img id="Rart_img" src={img2} alt="" />
                                <img id="Rart_img" src={img3} alt="" />
                                <img id="Rart_img" src={img4} alt="" />
                                <img id="Rart_img" src={img5} alt="" />
                            </div>
                        </div>
                        : null}

                    {ALLARTKEYS.includes(artcat) > 0
                        ? <div id="Rart_row">
                            {ALLARTKEYS.includes(artcat) > 0
                                ? ((purchase.hasOwnProperty(artcat + '_SOFT') && purchase[artcat + '_SOFT']['status'] === 'FULFILLED') || (purchase.hasOwnProperty(artcat + '_HARDSOFT') && purchase[artcat + '_HARDSOFT']['status'] === 'FULFILLED'))
                                    ? arttype === artcat
                                        ? <button id="Rart_selectbtn" onClick={selectmain} value={''}>Set As Lookup Page ✓</button>
                                        : <button id="Rart_selectbtn" onClick={selectmain} value={artcat}>Set As Lookup Page</button>
                                    : null
                                : null}
                            {ALLARTKEYS.includes(artcat) > 0 ? <button id="Rart_continue" value={artcat} onClick={oncontinue}>Continue</button> : null}
                        </div> : null}

                </div>
                : null}
            {{
                'EMOJI1': <Emojiart msgsdata={gb_dissectmsgs(msgs)} device={device} purchase={purchase} urlhandler={urlhandler} artmainimg={artmainimg} artmaintxt={artmaintxt} updateartmainimg={updateartmainimg} updateartmaintxt={updateartmaintxt} artmeta={artmeta} updateartmeta={updateartmeta} openpurchase={openpurchase} changecat={continueview} />,
                'WORDCUBE1': <Wordcube msgsdata={gb_extractmsgtfidf(msgs)} device={device} changecat={continueview} purchase={purchase} openpurchase={openpurchase} artmeta={artmeta} updateartmeta={updateartmeta} urlhandler={urlhandler} />,
                'ALPHA1': <Charart msgsdata={gb_extractmsgtfidf(msgs)} msgs={gb_dissectmsgs(msgs)} device={device} changecat={continueview} purchase={purchase} openpurchase={openpurchase} artmeta={artmeta} updateartmeta={updateartmeta} urlhandler={urlhandler} />
            }[viewcat]}
            <Noti noti={status} />
        </div>
}

export default Rart