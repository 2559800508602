// import React, { useEffect, useRef } from 'react'
// import './Start.css'

// function Start(props) {
//     const { start, msgs, artmaintxt, artmainimg } = props
//     const button = useRef(null)

//     useEffect(() => {
//         button.current.addEventListener("click", async (e) => {
//             let overlay;
//             let left = e.clientX - button.current.getBoundingClientRect().left;
//             let top = e.clientY - button.current.getBoundingClientRect().y;
//             overlay = document.createElement("span");
//             overlay.style.cssText = `
//       height: 450px;
//         width: 450px;
//         position: absolute;
//         top: ${top}px;
//         left: ${left}px;
//         transform: translate(-50%, -50%) scale(0);
//         background-color: rgba(220, 20, 60, 0.377);
//         border-radius: 50%;
//       z-index: -1;
//       animation: button_animation 1s ease;
//         animation-iteration-count: 1;
//       `;
//             overlay.addEventListener("animationend", (e) => {
//                 e.target.remove();
//             });
//             button.current.appendChild(overlay);
//             console.log(left);
//         });
//     }, [button])
//     return <div className="Start_root">
//         <button ref={button} className="Start_btn" onClick={start}>Start</button>
//     </div>
// }

// export default Start;

import React, { useEffect, useState, useRef } from 'react'
import { Checkdevice, ScreenHeight, ScreenWidth } from '../../../../components';
import Emoji from './components/Emoji/Emoji';
import Wordcube from './components/Wordcube/Wordcube';
import Charart from './components/Charart/Charart';
import { gb_extractmsgtfidf, gb_dissectmsgs } from '../../../../config/gbcf';
import { MAIN_PINK_COLOR } from '../../../../config/constants';
import './Start.css'

const gtype_title_map = {
    'Wedding': 'Your Wedding\'s Recap',
    'House-Warming': 'House-Warming  Recap',
    'Baby Shower': 'Baby Shower Recap',
    'Birthday Party': 'Birthday Recap'
}

function Nonedom(props) {
    useEffect(() => {
        props.startloaded(true)
    }, [props.startloaded])

    return <div className="Startbtn_root">
        <button ref={props.button} className="Start_btn" onClick={props.start}>
            {`Browse
        Guestbook`}
        </button>
    </div>
}

const Emojisummary = ({ gtype, msgs, emojimap }) => {

    const device = Checkdevice()
    const [page, setPage] = useState(0)

    const first = <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ color: 'black', fontWeight: 'bold', padding: 10, fontSize: 24, width: '100%', height: 150 }}>
            Warm wishes and advice from your guest as they celebrate your special day with you
        </div>
        <div style={{ color: 'black', padding: 10, fontSize: 20 }}>
            {(msgs && msgs.length) ? msgs.length + ' wishes from your guests.' : ''}
        </div>
        <div style={{ height: 300, display: 'flex', placeContent: 'center', }}>
            <span style={{ display: 'flex', flexDirection: 'column', backgroundColor: MAIN_PINK_COLOR, borderRadius: '100%', width: 150, height: 150, color: 'white', placeContent: 'center', placeSelf: 'center' }}>
                {(msgs && msgs.length) ? <h2 style={{ textAlign: 'center', marginTop: 0 }}>{msgs.length}</h2> : ''}
                <h3 style={{ textAlign: 'center', marginTop: 0 }}>Wishes</h3>
            </span>
        </div>
    </div>

    const firstweb = <div style={{ display: 'flex', flexDirection: 'row', placeContent: 'center', height: '100%', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ color: 'black', fontWeight: 'bold', padding: 10, fontSize: 24, width: 400, height: 150 }}>
                Warm wishes and advice from your guest as they celebrate your special day with you
            </div>
            <div style={{ color: 'black', padding: 10, fontSize: 16 }}>
                {(msgs && msgs.length) ? msgs.length + ' wishes from your guests.' : ''}
            </div>
        </div>
        <div style={{ height: 300, width: 300, display: 'flex', placeContent: 'center', }}>
            <span style={{ display: 'flex', flexDirection: 'column', backgroundColor: MAIN_PINK_COLOR, borderRadius: '100%', width: 150, height: 150, color: 'white', placeContent: 'center', placeSelf: 'center' }}>
                {(msgs && msgs.length) ? <h2 style={{ textAlign: 'center', marginTop: 0 }}>{msgs.length}</h2> : ''}
                <h3 style={{ textAlign: 'center', marginTop: 0 }}>Wishes</h3>
            </span>
        </div>
    </div>

    const second = <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ display: 'flex', flexDirection: 'column', backgroundColor: MAIN_PINK_COLOR, borderRadius: '100%', width: 150, height: 150, color: 'white', placeContent: 'center' }}>
            <h2 style={{ textAlign: 'center', margin: 0, display: 'flex', flexDirection: 'row', placeContent: 'center', alignItems: 'center' }}>
                <div className='Start_icon'>{'♥️'}</div>
            </h2>
            <h3 style={{ textAlign: 'center', margin: '5px 0px 15px' }}>x100 Collected</h3>
        </span>
    </div>

    const third = <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ display: 'flex', flexDirection: 'column', backgroundColor: MAIN_PINK_COLOR, borderRadius: '100%', width: 150, height: 150, color: 'white', placeContent: 'center' }}>
            {(emojimap && emojimap.length) ? <h2 style={{ textAlign: 'center', marginTop: 0 }}>{Object.keys(emojimap).length}</h2> : ''}
            <h3 style={{ textAlign: 'center', marginTop: 0 }}>Unique Emojis</h3>
        </span>
    </div>

    const indexselected_dom = <div className='Start_heartindexing'>{'♥️'}</div>
    return <div className="Emojisummary_root" >

        <div className="Emojisummary_title">{gtype_title_map[gtype]}</div>
        <div className="Emojisummary_content" >
            <div className={"Emojisummary_pagecontent"} style={{ height: device === 'WEB' ? '80%' : '90%' }}>
                {device === 'MOBILE' ? [first, second, third][page] : [firstweb, second, third][page]}
            </div>
            <div className="Emojisummary_pageindicatorroot">
                {page === 0 ? indexselected_dom : <div className='Start_indexing' />}
                {page === 1 ? indexselected_dom : <div className='Start_indexing' />}
                {page === 2 ? indexselected_dom : <div className='Start_indexing' />}
            </div>
            <div className='Emojisummary_nextbackroot noselect' style={{ height: device === 'WEB' ? '20%' : '10%' }}>
                <button className='Emojisummary_nbbtn noselect' onClick={() => { page === 0 ? setPage(2) : setPage(page - 1) }}>Back</button>
                <button className='Emojisummary_nbbtn noselect' onClick={() => { page === 2 ? setPage(0) : setPage(page + 1) }}>Next</button>
            </div>
        </div>
        <div
            style={{ height: '100vh', position: 'absolute', backgroundImage: 'url(https://i.ibb.co/ypsG5h1/actual.png)', width: '100%', backgroundSize: 'cover', opacity: device === 'WEB' || device === 'TABLET' ? 0.45 : 0.35 }}
        />
    </div>

}

function Start(props) {
    const { start, msgs, artmainimg, artmeta, artmaintxt, arttype, gtype, startloaded } = props
    const [msgsdata, setMsgsdata] = useState([])
    const [firsttime, setFirsttime] = useState(true)
    const [wt, setWt] = useState(-1)
    const [ht, setHt] = useState(-1)
    const [emojimap, setEmojimap] = useState({})
    // const [datetimemap, setDatetimemap] = useState({})
    const [gbbtngate, setGbbtngate] = useState(true)
    const clientWidth = ScreenWidth();
    const clientHeight = ScreenHeight();
    const device = Checkdevice()
    const button = useRef(null)

    useEffect(() => {
        if (firsttime) {
            setWt(clientWidth)
            setHt(clientHeight)
            setMsgsdata(msgs)
            setFirsttime(false)
        }
    }, [])

    useEffect(() => {
        var emojimap = {}
        // var datemap = {}
        for (let i = 0; i < msgs.length; i++) {
            const { emojiascii = [] } = msgs[i]//time
            if (emojiascii && emojiascii.length > 0) {
                for (let j = 0; j < emojiascii.length; j++) {
                    if (!(emojiascii[j] in emojimap)) { emojimap[emojiascii[j]] = 0 }
                    emojimap[emojiascii[j]] = emojimap[emojiascii[j]] + 1

                    // if (!(time in datemap)) { datemap[time] = 0 }
                    // datemap[time] = datemap[time] + 1
                }
            }
        }
        setEmojimap(emojimap)
        // setDatetimemap(datemap)
    }, [msgs])

    const bbtn = <div className="Start_floatbtmbar">
        <button ref={button} onClick={start} className="Start_browsegb">Guestbook</button>
    </div>

    const toggleguestbookbtn = () => {
        setGbbtngate(!gbbtngate)
    }

    return {
        'EMOJI1': <Emoji msgsdata={msgsdata} msgs={msgs} meta={artmeta[arttype]} artmainimg={artmainimg} artmaintxt={artmaintxt} wt={wt} ht={ht} d={device} startloaded={startloaded} toggleguestbookbtn={toggleguestbookbtn}>
            <Emojisummary gtype={gtype} msgs={msgs} emojimap={emojimap} />
            {bbtn}
        </Emoji>,
        'WORDCUBE1': <Wordcube msgsdata={gb_extractmsgtfidf(msgs)} meta={artmeta[arttype]} wt={wt} ht={ht} startloaded={startloaded} toggleguestbookbtn={toggleguestbookbtn}>
            <Emojisummary gtype={gtype} msgs={msgs} emojimap={emojimap} />
            {gbbtngate ? bbtn : null}
        </Wordcube>,
        'ALPHA1': <Charart msgsdata={gb_dissectmsgs(msgs)} meta={artmeta[arttype]} wt={wt} ht={ht} d={device} startloaded={startloaded} toggleguestbookbtn={toggleguestbookbtn}>
            <Emojisummary gtype={gtype} msgs={msgs} emojimap={emojimap} />
            {gbbtngate ? bbtn : null}
        </  Charart>,
        '': <div className="Startbtn_root">
            <button ref={button} className="Start_btn" onClick={start}>
                {`Browse
            Guestbook`}
            </button>
        </div>,
        'NONE': <Nonedom startloaded={startloaded} start={start} button={button}/>
    }[arttype]
}

export default Start;