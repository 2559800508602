import React from 'react'
import './Banners.css'

function Banners(props) {
    const { banners, names } = props
    return <div className="Banners_root">
        <button className={props.selected === '' ? "Banners_selectedimg" : "Banners_img"} key={'emptybanner'} onClick={() => { props.select({ img: '', index: 0 }) }}>
                <div className='Banners_name' style={{ placeContent: 'center', height:'100%', color:  ''=== props.selected ? 'var(--mainpink)' : 'inherit' }}>Blank</div>
        </button>
        {banners.map((banner, i) => {
            const cn = props.selected === banner ? "Banners_selectedimg" : "Banners_img"
            const onclick = () => { props.select({ img: banner, index: i }) }
            const name = names[i]
            return i > 0
                ? <button className={cn} key={banner + i} onClick={onclick}>
                    <div className='Banners_semiroot' style={{ padding: banner === props.selected ? 0 : 2 }}>
                        <div className='Banners_name' style={{ color: banner === props.selected ? 'var(--mainpink)' : 'inherit' }}> {name }</div>
                        <img style={{ width: 'auto', height: 50, margin: 5 }} src={banner} alt="" />
                    </div>
                </button>
                : null
        })}
    </div>
}
export default Banners