import React, { useState, useEffect, useLayoutEffect } from 'react'
import { EMPTYSPACE, NEAR_TRANSPARENT_GREY, PENCIL_ICON_SMALL, EMPTY, EXPLORE_ICON, CHART_ICON, IMG_ALT, READABLE_VENDORTYPE_MAP, PC_WT_MAP, X_SMALL, MAIN_PINK_COLOR, GENERAL_GREY } from '../../config/constants'
import { USER_READ_BOARD, REMOVE_BOARD, EDIT_BOARD_NAME, REMOVE_PI_FROM_BOARD } from '../../config/api'
import { iconwrapper, Retrieve_personal_info } from '../../config/commonfunctions';
import { Checkdevice } from '../../components/Checkdevice';
import { BUTTONSTYLE } from '../../config/cssconstants';
import { Projectcard, Jfmodal, Headtitle, Notiboard, Modcfm, Portalmodal, ScreenWidth, ScreenHeight } from '../../components';
import { Link } from "react-router-dom";
import axios from 'axios'
import './UserBoard.css'
import { TRASH_ICON } from '../../config/svgicons';
import { input_dom } from '../Main/Planning/Invitationmaker/components/Commonelements/Commonelements';

const BOARD_TEXT = 'Pin and save your wedding/event ideas on board. Save and pin up to 100 ideas.'
const ALINE = <div style={{ borderTop: '1px solid #eeeeee', width: '100%' }} />
const USERBOARD_BTN = { border: 'none', color: '#707070', backgroundColor: 'transparent', width: 40, height: 40, padding: 5 }

const numFavbasedonvt = (item, width = 20) => { // number of favourite based on specific vendortype
    return <div style={{ width: width, color: GENERAL_GREY, fontSize: 10, display: 'flex', justifyContent: 'center', padding: '0px 15px 0px 5px' }}>
        {item}
    </div>

}
const numFirstchar = (item, width = 20) => {
    return <div style={{ width: width, color: GENERAL_GREY, fontSize: 10, padding: '0px 5px 0px 15px' }}>
        {item}
    </div>
}

const portalmidcss_web = {
    width: 600,
    height: 660,
    top: 'calc( 50% - 330px )',
    backgroundColor: 'white',
    left: 0, right: 0,
    margin: 'auto',
    transform: 'none',
}

const portalmidcss_mobile = {
    width: '100%',
    height: '100%',
    top: 0,
    backgroundColor: 'white',
    left: 0, right: 0,
    margin: 'auto',
    transform: 'none'
}

const portalmidcsswtlarger_mobile = {
    width: '100%',
    height: '100%',
    top: 0,
    backgroundColor: 'white',
    left: 0, right: 0,
    margin: 'auto',
    transform: 'none',
    overflowX: 'scroll'
}

export default function UserBoard(props) {

    const { token, _id, acct_type } = Retrieve_personal_info()

    // basic data
    const [board_ids, setBoard_ids] = useState([])
    const [favourite_pi_ids, setFavourite_pi_ids] = useState([])

    // action
    const [selectedboardname, setSelectedoardname] = useState('All Projects')
    const [selectedboard, setSelectedboard] = useState(0)

    // for delete a project card
    const [deleteprojectdialog, setDeleteprojectdialog] = useState(false)

    // for delete board
    const [deleteboarddialog, setDeleteboarddialog] = useState(false)

    // use in board and project card
    const [deletedata, setDeletedata] = useState({})

    // for edit name board
    const [editboardname, setEditboardname] = useState('')
    const [editboarddialog, setEditboarddialog] = useState(false)

    // mobile
    const [mobiledialog, setMobiledialog] = useState(false)

    const device = Checkdevice()
    const screen_width = ScreenWidth()
    const screen_height = ScreenHeight()

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [selectedboard])

    useLayoutEffect(() => {
        if (device === 'MOBILE' && mobiledialog) {
            //get original value of body
            const originalStyle = window.getComputedStyle(document.body).overflow;
            //prevent scrolling on mount
            document.body.style.overflow = "hidden";
            // re-enable scrolling when component unmounts
            return () => (document.body.style.overflow = originalStyle);
        }
    }, [mobiledialog]); //empty array to ensures effect is only run when mount and unmount

    function init() {
        let options = {
            method: 'POST',
            url: USER_READ_BOARD,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
            data: { _id, acct_type }
        }
        axios(options).then((result) => {
            const { status } = result
            if (status === 200) {
                var { board_ids, favourite_pi_ids } = result.data.data

                var active_pids = favourite_pi_ids.map(({ _id }) => { return _id })

                // check if vendor deleted the project or not
                for (let x = 0; x < board_ids.length; x++) {
                    const { pi_ids } = board_ids[x]
                    for (let y = 0; y < pi_ids.length; y++) {
                        if (!active_pids.includes(pi_ids[y])) {
                            // add blank pid to each 'board' 
                            var inactive_pid = pi_ids[y]
                            favourite_pi_ids.push({ _id: inactive_pid, creator_profileimg_id: { link: '' } })
                        }
                    }
                }

                board_ids.unshift({ name: 'All Projects', pi_ids: favourite_pi_ids }) // push to the front 
                setBoard_ids(board_ids)
                setFavourite_pi_ids(favourite_pi_ids)
            }
        })
    }

    const sidebar = <div id='Userboard_sidebar' style={{ borderRight: '1px solid var(--lightdark)' }}>
        {board_ids.map(({ name, pi_ids, _id }, i) => {
            const len = pi_ids.length
            return <button
                key={_id + '_' + i}
                className={'Favouritevendor_vt'}
                onClick={() => {
                    setSelectedboard(i);
                    setSelectedoardname(name);
                }}
                style={{
                    borderRight: 'none'
                }}
            >
                {numFirstchar('', 30)}
                <div className={`Favouritevendor_readablevttxt Favouritevendor_readablevt${selectedboard === i ? 'selected' : ''}`}>
                    {name}
                </div>
                {numFavbasedonvt(len, 40)}
            </button>
        })}
    </div>

    const maincontent = <div id='Favouritevendor_maincontent'>

        <div id='Userboard_viewmoreroot'>
            {selectedboard > 0 ? <button id='Userboard_viewmorevendorbtn' onClick={() => { setEditboarddialog(true); setEditboardname(selectedboardname); }}>Edit Board</button> : null}
            {/* <button id='Userboard_viewmorevendorbtn'>View More Projects</button>
            <button id='Userboard_viewmorevendorbtn'>Analytics</button> */}
            {selectedboard ===0 ? null:<button id='Userboard_viewmorevendorbtn' onClick={() => setDeleteboarddialog(true)}>Delete Board</button>}
        </div>

        <div id='Userboard_grid'>

            {favourite_pi_ids.map((item, index) => {
                var pcwt
                if (device === 'MOBILE') {
                    pcwt = PC_WT_MAP['MOBILE']
                }
                else if (device === 'TABLET') {
                    pcwt = PC_WT_MAP['TABLET']
                }
                else {
                    pcwt = PC_WT_MAP['WEB']
                }

                const TRASH_ICON_CSS = { width: 15, height: 15, backgroundColor: 'white', display: 'flex', alignItems: 'center', placeContent: 'center', color: 'var(--mainpink)' }
                const deletebtn = <button className='Userboard_trash' onClick={() => { setDeleteprojectdialog(true); setDeletedata({ _id: item._id }) }}>{iconwrapper(TRASH_ICON, TRASH_ICON_CSS)}</button>

                if (selectedboard === 0) {
                    return <li key={index + 'pc'} style={{ width: pcwt, position: 'relative', listStyleType: 'none', display: 'flex', justifyContent: 'center', margin: '0px 5px' }}>
                        <Projectcard key={'Userboard_cardroot' + index} item={item} viewtype={1} device={device} />
                        {deletebtn}
                    </li>
                }
                else {
                    const projid = item._id
                    if (board_ids[selectedboard].pi_ids.includes(projid)) {
                        return <li key={index + 'pc'} style={{ width: pcwt, position: 'relative', listStyleType: 'none', display: 'flex', justifyContent: 'center', margin: '0px 5px' }}>
                            <Projectcard key={'Userboard_cardroot' + index} item={item} viewtype={1} device={device} />
                            {deletebtn}
                        </li>
                    }
                }
            })}

        </div>

    </div>

    const deleteprojectcardcfm = deleteprojectdialog
        ? <Modcfm
            onHide={() => { setDeleteprojectdialog(false) }}
            onOk={() => {
                if (deleteprojectdialog) {
                    axios({
                        method: 'POST',
                        url: REMOVE_PI_FROM_BOARD,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                        data: { _id: _id, projectinterface_id: deletedata._id, }
                    }).then((res) => {
                        const { message } = res.data
                        if (message === 'SUCCESS') {
                            init()
                            setDeletedata({})
                        }
                    }).catch((e) => {
                        console.log(e)
                    })
                }
            }}
            onNo={() => { setDeleteprojectdialog(false) }}
            txt={'Delete Project. Please be certain.'}
        />
        : null

    const deleteboardcfm = deleteboarddialog
        ? <Modcfm
            onHide={() => { setDeleteboarddialog(false) }}
            onOk={() => {
                if (deleteboarddialog) {
                    axios({
                        method: 'POST',
                        url: REMOVE_BOARD,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                        data: { _id, board_id: board_ids[selectedboard]._id, selectedboardname, pi_ids: board_ids[selectedboard].pi_ids }
                    }).then((res) => {
                        const { message } = res.data
                        if (message === 'SUCCESS') {
                            setEditboardname(false)
                            setSelectedboard(0)
                            setSelectedoardname('All Projects')
                            setDeleteboarddialog(false)
                            setDeletedata({})
                            init()
                        }
                    }).catch((e) => {
                        console.log(e)
                    })
                }
            }}
            onNo={() => { setDeleteboarddialog(false) }}
            txt={'Delete Board. Please be certain.'}
        />
        : null


    const editboardmodal = <Portalmodal
        portaltype={'commonportal'}
        open={editboarddialog}
        onClose={() => {
            setEditboarddialog(false)
        }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={(device === 'WEB' || device === 'TABLET') ? portalmidcss_web : screen_width > screen_height ? portalmidcsswtlarger_mobile : portalmidcss_mobile}
        closestyle={{ right: 0 }}
        floatingclosecolor='var(--maindark)'
    >

        <div className='Portalmodal_vtroot'>
            {input_dom(
                'Board Name',
                'Board Name',
                editboardname,
                (e) => {
                    if (e.target.value.length < 50) {
                        setEditboardname(e.target.value)
                    }
                },
                'boardname_id',
                {},
                false,
                null,
                {},
                { margin: 0, width: '100%', padding: '0px 10px' }
            )}
            <div style={{ width: '100%', display: 'flex', placeContent: 'flex-end', padding: 10 }}>
                <button
                    className='rrequests_sendbtn'
                    onClick={() => {
                        axios({
                            method: 'POST',
                            url: EDIT_BOARD_NAME,
                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                            data: { _id, board_id: board_ids[selectedboard]._id, selectedboardname, new_name: editboardname }
                        }).then((res) => {
                            const { message } = res.data
                            if (message === 'SUCCESS') {
                                // init()
                                let new_board_ids = JSON.parse(JSON.stringify(board_ids))
                                new_board_ids[selectedboard].name = editboardname
                                setBoard_ids(new_board_ids)
                                setEditboardname('')
                                setSelectedoardname(editboardname)
                                setEditboarddialog(false)
                            }
                        }).catch((e) => {
                            console.log(e)
                        })
                    }}>
                    Update
                </button>
            </div>
        </div>
    </Portalmodal>


    const WEB_TABLET_DOM = <div className='Favouritevendor_row' style={{ height: 'calc( 100% - 35px - 85px )' }}>
        {sidebar}
        {maincontent}
        {deleteprojectcardcfm}
        {deleteboardcfm}
        {editboardmodal}
    </div>

    return <div id="Userboard_root">
        <Headtitle name="Board" color="var(--maindark)" />
        {{
            'WEB': WEB_TABLET_DOM,
            'TABLET': WEB_TABLET_DOM,
            'MOBILE': <div className='Favouritevendor_col'>

            </div>
        }[device]}
    </div>

}
