import React, { memo, useEffect, useRef, useState } from 'react'
import './../Mod.css'
const vb_ = "0 0 1024 1024"
const v_ = "1.1"
const x_ = "http://www.w3.org/2000/svg"
const icon_css = { width: 30, height: 30, userSelect: 'none', pointerEvents: 'none', verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden' }

const yesicon = <svg style={icon_css} viewBox={vb_} version={v_} xmlns={x_}><path d="M886.4 240c-12.8-12.8-32-12.8-44.8 0L364.8 716.8l-182.4-182.4c-12.8-12.8-32-12.8-44.8 0-12.8 12.8-12.8 32 0 44.8l204.8 204.8c6.4 6.4 12.8 9.6 22.4 9.6 9.6 0 16-3.2 22.4-9.6L886.4 284.8c12.8-12.8 12.8-32 0-44.8z" p-id="20394"></path></svg>
const noicon = <svg style={icon_css} viewBox={vb_} version={v_} xmlns={x_}><path d="M463.787 512l-253.44-253.44a34.133 34.133 0 0 1 48.213-48.213L512 463.787l253.44-253.44a34.133 34.133 0 1 1 48.213 48.213L560.213 512l253.44 253.44a34.133 34.133 0 1 1-48.213 48.213L512 560.213l-253.44 253.44a34.133 34.133 0 0 1-48.213-48.213z" p-id="20193"></path></svg>
function Modcfm({ onOk, onNo, txt, showtickonly = false, showcrossonly = false, showallonly = true }) {// txtdom, onHide
    const modref = useRef(null)
    const [elemht, setElemht] = useState(0)
    useEffect(() => { setElemht(modref.current.clientHeight) }, [])
    return <div className="Mod_root" key="Mod_root">
        <div ref={modref} className="Mod_subroot" style={{ top: `calc( 50% - ${elemht / 2}px )` }}>
            <div className="Mod_text">{txt}</div>

            {/*
            <div className="Mod_row">
               <div className="Mod_ok" onClick={onOk}>YES</div>
                <div style={{ height: 44, borderLeft: '1px solid rgb(230, 230, 230)' }} />
                <div className="Mod_no" onClick={onNo}>NO</div> 
            </div>
             */}

            {showallonly
                ? <div className="Mod_row">
                    <div className="Mod_ok" onClick={onOk}>{yesicon}</div>
                    <div style={{ height: 44, borderLeft: '1px solid rgb(230, 230, 230)' }} />
                    <div className="Mod_no" onClick={onNo}>{noicon}</div>
                </div>
                : null}

            {showtickonly
                ? <div className="Mod_row">
                    <div className="Mod_ok" style={{ width: '100%' }} onClick={onOk}>{yesicon}</div>
                </div>
                : null}

            {showcrossonly
                ? <div className="Mod_row">
                    <div className="Mod_no" style={{ width: '100%' }} onClick={onNo}>{noicon}</div>
                </div>
                : null}

        </div>
    </div>
}
export default memo(Modcfm)