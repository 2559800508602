import React, { useEffect, useState, memo } from 'react'
import { Togglebutton } from '../../../../../../basic_components'
import { describe_dom, tick_icon, input_dom } from '../Commonelements/Commonelements'
import Success from './components/Success'
import './Glmodal.css'
import { STARTUP_NAME } from '../../../../../../config/constants'

function Alternativecontent({ rsvptype, rsvpalt, altstate, update, close }) {

    const [localrsvptype, setLocalrsvptype] = useState('default') // default or google
    const [localrsvpalt, setLocalrsvpalt] = useState('')
    const [msgs, setMsgs] = useState({})
    const [checking, setChecking] = useState(false)

    useEffect(() => {
        setLocalrsvpalt(rsvpalt[0])
        setLocalrsvptype(rsvptype)
    }, [])

    useEffect(() => {
        if (checking) {
            setChecking(() => { // will return false once everything is done
                let msgs = { 'googlersvpalt': null }
                let error_counter = 0
                if (rsvpalt[0].length > 500) {
                    error_counter++;
                    msgs['googlersvpalt'] = 'You have exceeded 500 character limit.'
                }
                error_counter > 0 ? setMsgs(msgs) : update(localrsvpalt, localrsvptype)
                return false
            })
        }
    }, [checking])

    const general_dom = <div className='Rsvpexplorer_portalcontent'>
        <div style={{ padding: '0px 10px', display: 'flex', flexDirection: 'column' }}>
            <Togglebutton
                rootstyle={{ width: '100%' }}
                singlebtnstyle={{ width: '50%' }}
                funcs={[
                    () => { setLocalrsvptype('default') },
                    () => { setLocalrsvptype('google') }
                ]}
                txts={[`${STARTUP_NAME} RSVP`, 'Google Form RSVP']}
                btnselected={localrsvptype === 'default' ? 0 : 1}
            />
        </div>
        {{
            'default': <div style={{ display: 'flex', flexDirection: 'column' }}>
                {describe_dom('Use our default RSVP tool to collect your guests response.')}
            </div>,
            'google': <div style={{ display: 'flex', flexDirection: 'column' }}>
                {describe_dom('Use Google Form to collect RSVP response')}
                {input_dom('Google Form Link', 'Google Form Link', localrsvpalt, (e) => { setLocalrsvpalt(e.target.value) }, 'googlersvpalt', msgs)}
            </div>
        }[localrsvptype]}
        <button className='Rsvpexplorer_portalsave' onClick={() => { setChecking(true) }} disabled={altstate === 'SAVING'}>
            {{ // IDLE, SAVING, SUCCESS, FAILURE
                'IDLE': 'Update',
                'SAVING': 'Saving...',
                'SUCCESS': 'New Question Created',
                'FAILURE': 'Error Occurred, please try again later.'
            }[altstate]}
        </button>
    </div>

    const success_dom = <Success txt={'RSVP updated. Click to return.'} onClick={close} />

    return <div className='Rsvpexplorer_portal'>
        <div className='Rsvpexplorer_portaltitle'>Replace RSVP</div>
        {altstate !== 'SUCCESS' ? general_dom : success_dom}
    </div>
}

export default memo(Alternativecontent)