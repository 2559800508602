const DEVICE_MAP = { 'MOBILE': 20, 'WEB': 23 }
function Processname(displayname, username, device) {
    const len = DEVICE_MAP[device]
    try {
        if (displayname && displayname.length > len) {
            return displayname.substring(0, len) + '...'
        }
        else if (displayname) {
            return displayname
        }
        else if (username.length > len) {
            return username.substring(0, len) + '...'
        }
        else if (username) {
            return username
        }
        else {
            return ''
        }
    }
    catch (e) {
        return ''
    }

}

export default Processname