import React, { useEffect, useState } from 'react';
import { READ_NONUSER_AUSCIPIOUSTOOL } from '../../../../config/api';
import { f_fs, c_fs, d_fs, e_fs, NUMMTH_CHARMTH_MAP, MAIN_PINK_COLOR } from '../../../../config/constants';
import axios from "axios";
import './Auspicious.css'
import { Checkdevice } from '../../../../components';
import { S3CLOUD_CLOUDFRONT_ASSET } from '../../../../config/s3directories';

const MTH_ARR = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
const YR_ARR = ['2021', '2022', '2023']
const DTSTR = new Date().toLocaleDateString().split('/')

const YR_STATSDATA_MAP = { // https://www.singstat.gov.sg/-/media/files/publications/population/smd2019.pdf
    2019: {
        1: { total: 1717, chinese: 1234, indians: 83, others: 77, interethnic: 323, totalVsOverall: 1717 / 19889, avg: 19889 / 12 },
        2: { total: 1251, chinese: 831, indians: 98, others: 70, interethnic: 252, totalVsOverall: 1251 / 19889, avg: 19889 / 12 },
        3: { total: 1724, chinese: 1273, indians: 72, others: 62, interethnic: 317, totalVsOverall: 1724 / 19889, avg: 19889 / 12 },
        4: { total: 1011, chinese: 543, indians: 71, others: 87, interethnic: 310, totalVsOverall: 1011 / 19889, avg: 19889 / 12 },
        5: { total: 1703, chinese: 1228, indians: 80, others: 74, interethnic: 321, totalVsOverall: 1703 / 19889, avg: 19889 / 12 },
        6: { total: 1939, chinese: 1401, indians: 118, others: 83, interethnic: 337, totalVsOverall: 1939 / 19889, avg: 19889 / 12 },
        7: { total: 1463, chinese: 986, indians: 70, others: 70, interethnic: 337, totalVsOverall: 1463 / 19889, avg: 19889 / 12 },
        8: { total: 953, chinese: 521, indians: 78, others: 75, interethnic: 279, totalVsOverall: 953 / 19889, avg: 19889 / 12 },
        9: { total: 2114, chinese: 1648, indians: 83, others: 58, interethnic: 325, totalVsOverall: 2114 / 19889, avg: 19889 / 12 },
        10: { total: 1879, chinese: 1412, indians: 64, others: 72, interethnic: 331, totalVsOverall: 1879 / 19889, avg: 19889 / 12 },
        11: { total: 2202, chinese: 1711, indians: 105, others: 59, interethnic: 327, totalVsOverall: 2202 / 19889, avg: 19889 / 12 },
        12: { total: 1933, chinese: 1419, indians: 113, others: 78, interethnic: 323, totalVsOverall: 1933 / 19889, avg: 19889 / 12 },
    }
}



// const CHNDAY_ENGDAY_MAP = {
//     "星期一": 1,
//     "星期二": 2,
//     "星期三": 3,
//     "星期四": 4,
//     "星期五": 5,
//     "星期六": 6,
//     "星期日": 7,
// }

const ENG_CHN_MAP = {
    'Wedding 婚': '嫁娶',
    'Reno Dates 装': '动土',
    'Move to Home 移': '移徙',
    'Guo Da Li 大': '祭祀',
    'An Chuang 床': '安床',
}

const cheng_ch_map = {
    '嫁娶': 'Wedding 婚',
    '动土': 'Reno Dates 装',
    '移徙': 'Move to Home 移',
    '祈福': 'Guo Da Li 大',
    '安床': 'An Chuang 床'
}
const ENG_CHNABBREV_MAP = {
    'Wedding 婚': '婚',
    'Reno Dates 装': '装',
    'Move to Home 移': '移',
    'Guo Da Li 大': '大',
    'An Chuang 床': '床',
}

const CHN_ENG_MAP = {
    'Wedding 婚': 'Wedding',
    'Reno Dates 装': 'Reno Date',
    'Move to Home 移': 'Move to Home',
    'Guo Da Li 大': 'Guo Da Li',
    'An Chuang 床': 'An Chuang',
}

const DEVICE_FS_MAP = {
    'WEB': { fontSize: d_fs, width: 30, height: 30, borderRadius: 30 },
    'TABLET': { fontSize: e_fs, width: 23, height: 23, borderRadius: 23 }
}

function makedateDom(dayraw, mth, yr) {
    var day = dayraw.toString().length !== 2 ? '0' + dayraw : dayraw
    return <div style={{ display: 'flex', flexDirection: 'row' }}>{day + ' / ' + mth + ' / ' + yr}</div>
}
var defaultmore = []

function Auspicious(props) {

    const [firsttime, setFirsttime] = useState(true)
    const [tempmth, setTempmth] = useState('')
    const [mth, setMth] = useState('')
    const [yr, setYr] = useState('')
    const [data, setData] = useState([])
    const [more, setMore] = useState([])
    const [selected, setSelected] = useState('')
    const [selectedindex, setSelectedindex] = useState(-2)
    const [types, setTypes] = useState(['Wedding 婚'])
    const [statdata2019, setStatdata2019] = useState({})
    const [expandstatdata2019, setExpandstatdata2019] = useState(false)

    const [prevmthyr, setPrevmthyr] = useState(DTSTR[1] + '/' + DTSTR[2])
    const [mthyr, setMthyr] = useState(DTSTR[1] + '/' + DTSTR[2])
    const device = Checkdevice()
    // useEffect(() => { // activated by auspicious page
    //     if (prevmthyr !== mthyr) { //  make a call to backend
    //         const options = getOptions(page)
    //         axios(options).then((result) => {
    //             const { data } = result
    //             setData(data.result)
    //         }).catch((e) => { })
    //     }
    // }, [mthyr])

    const repackdata = (result) => {
        if (result && Object.keys(result).length > 0 && result.content && result.content.length > 0) {
            var content = result.content.slice()
            defaultmore = []
            var processedData = []
            var more = []
            for (let j = 0; j < content.length; j++) {
                const { week, date } = content[j]
                content[j]['day'] = week
                content[j]['datenum'] = date.split('-')[2]
            }

            for (let y = 0; y < content.length;) {
                var item = []
                for (let x = 0; x < 7; x++, y++) { // 7 days per wk
                    item.push(content[y])
                }
                processedData.push(item)
            }

            for (let x = 0; x < processedData.length; x++) {
                more.push([])
                defaultmore.push([])
            }

            setMore(more)
            setData(processedData)

        }
        else {
            setMore([])
            setData([])
            setSelected('')
            setSelectedindex(-2)
            setMth('')
        }
    }

    useEffect(() => {
        if (firsttime) {
            try {
                const options = {
                    method: 'POST',
                    url: READ_NONUSER_AUSCIPIOUSTOOL,
                    data: { title: mthyr }
                }
                axios(options).then((item) => {
                    const { result } = item.data
                    setMth(DTSTR[1])
                    setTempmth(DTSTR[1])
                    setYr(DTSTR[2])
                    setStatdata2019(YR_STATSDATA_MAP[2019][parseInt(DTSTR[1], 10)])
                    setFirsttime(false)

                    // repack data
                    repackdata(result)
                }).catch((e) => { })
            }
            catch (e) { }
        }
    }, [])

    const onSearch = (newmthyr) => {
        try {
            const options = {
                method: 'POST',
                url: READ_NONUSER_AUSCIPIOUSTOOL,
                data: { title: newmthyr }
            }
            axios(options).then((item) => {
                const { result } = item.data
                setMth(tempmth)
                setStatdata2019(YR_STATSDATA_MAP[2019][parseInt(tempmth, 10)])
                setSelectedindex(-2)
                repackdata(result)
                // setPrevmthyr(mthyr); 
                // setMthyr(newmthyr);


                // console.log('result', result)
                // setMth(DTSTR[1])
                // setTempmth(DTSTR[1])
                // setYr(DTSTR[2])
                // setStatdata2019(YR_STATSDATA_MAP[2019][parseInt(DTSTR[1], 10)])
                // setFirsttime(false)
                // repack data



            }).catch((e) => { })
        }
        catch (e) { }

    }

    var selectionDom = (
        <div className="aus_selectiondomroot">
            <div className='aus_selecttworoot'>
                <div className="aus_mth40" style={{ borderRadius: '10px 0px 0px 0px', borderRight: 'none', marginBottom: 0 }}>
                    <div className="aus_menutitle aus_selecttitle">Month</div>
                    <select className='aus_select aus_selectinput' value={tempmth} onChange={(e) => setTempmth(e.target.value)} name="changemth" >
                        {MTH_ARR.map((value, index) => <option key={value + index} value={value}> {value}</option>)}
                    </select>
                </div>
                <div className="aus_mth40" style={{ borderRadius: '0px 10px 0px 0px', marginBottom: 0 }}>
                    <div className="aus_menutitle aus_selecttitle">Year</div>
                    <select className='aus_select aus_selectinput' value={yr} onChange={(e) => setYr(e.target.value)} name="changeyr" >
                        {YR_ARR.map((value, index) => <option key={value + index} value={value}> {value}</option>)}
                    </select>
                </div>
            </div>
            <button
                className="aus_searchbtn aus_selectsearch"
                onClick={() => {
                    onSearch(tempmth + '/' + yr)
                }}>Search</button>
        </div>
    )

    var lastyrthismthDom = (
        mth && statdata2019 && Object.keys(statdata2019).length > 0
            ? <div className="aus_pastdataroot" onClick={() => setExpandstatdata2019(!expandstatdata2019)}>
                <div className="aus_pastdatatitle">{'Past Data 2019, ' + NUMMTH_CHARMTH_MAP[parseInt(mth, 10) - 1]}</div>
                <div className="aus_pastdatasubroot" >
                    <div key={'total'} className="aus_pastdataitem" >
                        <div style={{ fontSize: f_fs, height: 55, fontWeight: 'bold' }}>Total Marriages</div>
                        <div style={{ fontSize: c_fs, fontWeight: 'bold', letterSpacing: 1 }}>{statdata2019['total']}</div>
                        <div style={{ fontSize: f_fs, fontWeight: 'bold', color: '#f77f7f' }}>{statdata2019['total'] > Math.floor(statdata2019['avg']) ? 'Above Avg' : 'Below Avg'}</div>
                    </div>
                    <div key={'avg'} className="aus_pastdataitem" >
                        <div style={{ fontSize: f_fs, height: 55, fontWeight: 'bold' }}>Average Marriages Per Mth</div>
                        <div style={{ fontSize: c_fs, fontWeight: 'bold', letterSpacing: 1 }}>{Math.floor(statdata2019['avg'])}</div>
                    </div>
                </div>
                <div className="aus_pastdatafrm"><img className="aus_pastdatastatsboard" src={`${S3CLOUD_CLOUDFRONT_ASSET}misc/singstats-logo.png`} alt="" /></div>
            </div>
            : null
    )

    var typefilterDom = (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', overflowX: 'auto', flexWrap: 'no-wrap', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', overflow: 'auto hidden', width: '100%', minWidth: 'fit-content', padding: '10px 0px', overflowX: 'auto', WebkitOverflowScrolling: 'touch' }}>
                {['Wedding 婚', 'Reno Dates 装', 'Move to Home 移', 'Guo Da Li 大', 'An Chuang 床'].map((value, index) => {
                    const engname = CHN_ENG_MAP[value]
                    const chnname = ENG_CHNABBREV_MAP[value]
                    const selected = types.includes(value)
                    const relative_css = selected
                        ? { backgroundColor: MAIN_PINK_COLOR, color: 'white', border: '1px solid transparent', flexDirection: 'column', margin: '5px 5px 5px 0px', padding: '5px 10px', width: 'max-content', borderRadius: 5, fontSize: e_fs, alignItems: 'center', cursor: 'pointer', userSelect: 'none' }
                        : { backgroundColor: 'white', color: 'var(--maindark)', border: '1px solid var(--maindark)', flexDirection: 'column', margin: '5px 5px 5px 0px', padding: '5px 10px', width: 'max-content', borderRadius: 5, fontSize: e_fs, alignItems: 'center', cursor: 'pointer', userSelect: 'none' }
                    const changefilter = () => {
                        var local_types = types.slice()
                        selected ? local_types.splice(types.indexOf(value), 1) : local_types.push(value)
                        var local_more = []
                        for (let i = 0; i < more.length; i++) local_more.push([])
                        setTypes(local_types)
                        setMore(local_more)
                        setSelectedindex(-2)
                        setSelected('')
                    }
                    return <div
                        className={!selected ? "typefilter_btndiv" : ''}
                        key={value + index}
                        style={relative_css}
                        onClick={changefilter}>
                        <div style={{ fontSize: d_fs }}>{chnname}</div>
                        <div style={{ fontSize: e_fs }}>{engname}</div>
                    </div>
                })}
            </div>
        </div>
    )

    var headerDom = data && data.length > 0
        ? <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', fontFamily: 'lato', color: 'var(--maindark)', maxWidth: '100%', minWidth: '100%', height: 'fit-content', justifyContent: 'center', width: '100%', padding: '0px 8px' }}>
            {['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'].map((dt, index) => <div key={dt} style={{ width: '14.2%', minWidth: '14.2%', maxWidth: '14.2%', position: 'relative', justifyContent: 'center', outline: 'none', padding: 0, margin: 0, cursor: 'pointer', display: 'flex', flexDirection: 'row', }}>{dt}</div>)}
        </div>
        : null

    var contentDom = (
        <div className="aus_contentroot">
            {data && data.map((row, x) => {
                return (
                    <div className="aus_contentrow" key={'row' + x}>
                        <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '100%', minWidth: '100%' }}>
                            {row.map((item, y) => {
                                const bt = (x < 1 || (selectedindex > -1 && (selectedindex + 1) === x)) ? '1px solid var(--maindark)' : '1px solid transparent'
                                const bl = y < 1 ? '1px solid var(--maindark)' : '1px solid transparent'
                                const br = '1px solid var(--maindark)'
                                const bb = '1px solid var(--maindark)'
                                const key = x + '_' + y
                                if (item) {
                                    const { yi, ji, datenum } = item // pengzu, gongli, ji, wuxing, sueici, hua, jieri, chong, nongli

                                    var suitable = yi
                                    var forbid = ji
                                    var bool
                                    for (let i = 0; i < types.length; i++) {
                                        var curr_type = types[i]
                                        for (let j = 0; j < suitable.length; j++) {
                                            if (suitable.includes(ENG_CHN_MAP[curr_type])) bool = true
                                            else { bool = false; break; }
                                        }
                                        if (!bool) break
                                    }

                                    return (
                                        <div
                                            key={key}
                                            className={parseInt(item.month, 10) === parseInt(mth, 10) ? '#ffffff' : '#e8e8e8'}
                                            style={{
                                                backgroundColor: selected === key
                                                    ? '#f77f7f59'
                                                    : `${parseInt(item.month, 10) === parseInt(mth, 10) ? '#ffffff' : '#e8e8e8'}`,
                                                width: '14.2%', minWidth: '14.2%', maxWidth: '14.2%',
                                                borderTop: bt, borderLeft: bl, borderRight: br, borderBottom: bb,
                                                height: 80, position: 'relative', outline: 'none', padding: 0, margin: 0,
                                                cursor: 'pointer', display: 'flex', flexDirection: 'row',
                                            }}
                                            onClick={() => {
                                                var local_more = []
                                                var local_key = x + '_' + y

                                                // check if click this one more time
                                                if (key === selected) {
                                                    for (let i = 0; i < more.length; i++) local_more.push([])
                                                    local_key = ''
                                                    setSelectedindex(-2)
                                                }
                                                else {
                                                    for (let i = 0; i < more.length; i++) local_more.push([])

                                                    local_more[x].push(
                                                        <div key={x + 'suitable' + y} style={{ display: 'flex', flexDirection: 'column', width: '100%', color: 'var(--maindark)', minHeight: 'max-content' }}>
                                                            <div style={{ display: 'flex', fontWeight: 'bold', fontSize: c_fs, padding: 5, margin: 5, alignSelf: 'center' }}>
                                                                {makedateDom(datenum, mth, yr)}
                                                            </div>
                                                            <div style={{ display: 'flex', fontWeight: 'bold', fontSize: c_fs, padding: 5, margin: 5, justifyContent: 'flex-start', }}>宜 ( Suitable )</div>
                                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                                {suitable.map((singleyi, index) => {
                                                                    return types.includes(cheng_ch_map[singleyi])
                                                                        ? <div key={index + singleyi} style={{ display: 'flex', padding: 5, margin: 5, borderRadius: 5, border: '1px solid var(--maindark)' }}>{singleyi + ' [' + cheng_ch_map[singleyi] + ']'}</div>
                                                                        : <div key={index + singleyi} style={{ display: 'flex', padding: 5, margin: 5, borderRadius: 5, }}>{singleyi}</div>
                                                                })}
                                                            </div>
                                                            <div style={{ display: 'flex', fontWeight: 'bold', fontSize: c_fs, padding: 5, margin: 5, justifyContent: 'flex-start', }}>忌 ( Forbid )</div>
                                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                                {forbid.map((singleji, index) => {
                                                                    return types.includes(cheng_ch_map[singleji])
                                                                        ? <div key={index + singleji} style={{ display: 'flex', padding: 5, margin: 5, borderRadius: 5, border: '1px solid var(--maindark)' }}>{singleji + ' [' + cheng_ch_map[singleji] + ']'}</div>
                                                                        : <div key={index + singleji} style={{ display: 'flex', padding: 5, margin: 5, borderRadius: 5, }}>{singleji}</div>
                                                                })}
                                                            </div>
                                                        </div>
                                                    )
                                                    setSelectedindex(x)
                                                }
                                                setMore(local_more)
                                                setSelected(local_key)
                                            }
                                            }
                                        >

                                            <div style={{ padding: 3 }}>{item.datenum}</div>

                                            {bool && device !== 'MOBILE'
                                                ? <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', padding: 3 }}>
                                                    {types.map((item, x_index) => {
                                                        return <div key={x_index + 'item'} style={{ ...DEVICE_FS_MAP[device], justifyContent: 'center', display: 'flex', alignItems: 'center', backgroundColor: '#f77f7f', border: '1px solid white', color: 'white', textAlign: 'center', left: 'calc(100% - 25px)' }} >
                                                            {ENG_CHNABBREV_MAP[item]}
                                                        </div>
                                                    })}
                                                </div>
                                                : null}

                                            {bool && device === 'MOBILE'
                                                ? <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', padding: 5 }}>
                                                    <div key={y + 'item'} style={{ fontSize: f_fs, justifyContent: 'center', display: 'flex', alignItems: 'center', backgroundColor: '#f77f7f', width: 22, height: 22, borderRadius: 22, border: '1px solid white', color: 'white', textAlign: 'center', left: 'calc(100% - 25px)' }} >吉</div>
                                                </div>
                                                : null}
                                        </div>
                                    )
                                }
                                else {
                                    return <div key={key} style={{ backgroundColor: '#7070703b', color: 'var(--maindark)', width: '14.2%', minWidth: '14.2%', maxWidth: '14.2%', borderTop: bt, borderLeft: bl, borderRight: br, borderBottom: bb, height: 80, position: 'relative', outline: 'none', padding: 0, margin: 0, cursor: 'pointer', display: 'flex', flexDirection: 'row', }} />
                                }

                            })}
                        </div>
                        {more[x].length > 0
                            ? <div className="aus_moreroot"><div className="aus_moreinner">{more[x]}</div></div>
                            : null}
                    </div>
                )

            })}
            {!data ? <div>No Content</div> : null}
        </div>
    )

    return <div id='planningedit_root' style={{ display: 'flex', flexDirection: 'column', }}>
        {selectionDom}
        {typefilterDom}
        {headerDom}
        {contentDom}
        {lastyrthismthDom}
    </div>
}

export default React.memo(Auspicious)