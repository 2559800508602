
import React, { useState, memo, useEffect } from 'react'
import { iconwrapper } from '../../../../../../config/commonfunctions'
import { CROSS_ICON, ADD_ICON } from '../../../../../../config/svgicons'
import {  describe_dom } from '../Commonelements/Commonelements'
import Success from './components/Success'
import './Glmodal.css'

const CROSSICON_CSS = { width: 20, height: 20, display: 'flex', placeContent: 'center' }
const ADDICON_CSS = { width: 24, height: 24, display: 'flex', placeContent: 'center' }

function Addgrpcontent(props) {
    const { addgrpsavingstate, guestid_guestinfo_map, grp_guestids_map, close } = props
    const [grpholder, setGrpholder] = useState([])
    const [addgrpmodalguestgrps, setAddgrpmodalguestgrps] = useState([])
    const [once, setOnce] = useState(false)
    const [grpcountmap, setGrpcountmap] = useState({})

    useEffect(() => {
        if (props && !once) {
            console.log('grp_guestids_map', grp_guestids_map)
            // const allids = Object.keys(guestid_guestinfo_map)
            // let grpcountmap={}
            // for (let i = 0; i < allids.length; i++) {

            // }

            setAddgrpmodalguestgrps(props.addgrpmodalguestgrps)
            setOnce(true)
        }
    }, [props])

    const addgrp = () => {
        var temp = addgrpmodalguestgrps.slice()
        if (grpholder.length > 0) {
            if (temp.indexOf(grpholder) < 0) temp.push(grpholder)
            setAddgrpmodalguestgrps(temp)
        }
    }

    const delgrp = (index) => {
        var temp = addgrpmodalguestgrps.slice()
        temp.splice(index, 1)
        setAddgrpmodalguestgrps(temp)
    }


    const general_dom = <div className='Rsvpexplorer_portalcontent'>

        {describe_dom('Create a new group label. Arrange your guests into different groups, and the leader of each group will have exclusive rights to edit and update the RSVP questions on behalf of the group members. The first member assigned to a group will become the leader.', {padding:'5px 10px 0px', marginBottom:20})}

        <div style={{ display: 'flex', flexDirection: 'row', padding: '0px 10px' }}>
            <input className='Rsvpexplorer_input' style={{ marginRight: 5, marginBottom: 10 }} value={grpholder} onChange={(e) => setGrpholder(e.target.value)} />
            <button className='Rsvpexplorer_portaladdnewguest' onClick={addgrp}>
                {iconwrapper(ADD_ICON, ADDICON_CSS)}
            </button>
        </div>

        <div style={{ border: '1px solid rgb(221, 221, 221)', minHeight: 200, maxHeight: '100%', borderRadius: 5, width: 'calc( 100% - 20px )', margin: '0px 10px', placeSelf: 'center', padding: 5, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', overflowY: 'auto', alignContent: 'start' }}>
            {addgrpmodalguestgrps && addgrpmodalguestgrps.map((item, index) => {
                return (
                    <div key={item + index} className='Rsvpexplorer_portaltag' style={{ padding: '5px 0px 5px 14px' }} >
                        <div style={{ display: 'flex', alignContent: 'center', textAlign: 'center', paddingRight: 6 }}>{item}</div>
                        <button
                            style={{ padding: '0px 5px', color: '#b21212', backgroundColor: 'transparent', margin: 0, outline: 'none', border: 'none', }}
                            onClick={() => { delgrp(index) }}
                        >
                            {iconwrapper(CROSS_ICON, CROSSICON_CSS)}
                        </button>
                    </div>
                )
            })}
        </div>

        <button className='Rsvpexplorer_portalsave' onClick={() => { props.save(addgrpmodalguestgrps) }} disabled={addgrpsavingstate === 'SAVING'}>
            {{ // IDLE, SAVING, SUCCESS, FAILURE
                'IDLE': 'Save',
                'SAVING': 'Saving...',
                'SUCCESS': 'New Category Saved',
                'FAILURE': 'Error Occurred, please try again later.'
            }[addgrpsavingstate]}
        </button>
    </div>

    const success_dom = <Success txt={'Group updated. Click to return.'} onClick={close} />

    return <div className='Rsvpexplorer_portal'>
        <div className='Rsvpexplorer_portaltitle'>Add New Group</div>
        {addgrpsavingstate !== 'SUCCESS' ? general_dom : success_dom}
    </div>

}

export default memo(Addgrpcontent)