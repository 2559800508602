import React from 'react'
import { iconwrapper } from '../../../../../../../config/commonfunctions'
import { TICK_ICON } from '../../../../../../../config/svgicons'
import './Success.css'

const TICKICON_CSS = { width: 48, height: 48, color: '#4bb543', display: 'flex', placeContent: 'center' }

function Success(props) {
    const {txt, onClick} = props
    return <div onClick={onClick} className='igl_successportal'>
        {iconwrapper(TICK_ICON, TICKICON_CSS)}
        <div className='igl_successtxt'>{txt}</div>
    </div>
}

export default Success
