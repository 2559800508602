import { useEffect, useState, useRef } from "react"
import { refresh } from "./../../components"
import { INIT_CONV_USER, UPDATE_ENQUIRENONUSER, UPDATE_ENQUIREUSER } from './../../config/api';
import { READABLE_VENDORTYPE_MAP, RECAPTCHA_KEY } from "./../../config/constants";
import { Retrieve_personal_info, iconwrapper } from "../../config/commonfunctions";
import { CROSS_ICON, THINEMAIL_ICON, THINTELEGRAM_ICON, THINWHATSAPP_ICON, THINCHAT_ICON } from "../../config/svgicons";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios'
import './Enquirycontent.css'

const general_learnmorefunc = (name, email, contact) => (
    `I'm currently exploring options for my upcoming wedding and would love to learn more about your services you offer.Could you please provide me with more details, and options available?

Here are our contact details for further communication:

Name(s): ${name}
Email: ${email}
Contact Number: ${contact}`
)

const general_checkavailability = (dates, name, email, contact) => (
    `My partner and I are interested in your service for our upcoming wedding. Could you please let us know if the service is available on the following date(s): ${dates}? If so, we would also appreciate any preliminary information about the booking process and package options.
    
Here are our contact details for further communication:
    
Name(s): ${name}
Email: ${email}
Contact Number: ${contact}`
)

const venue_learnmorefunc = (name, email, contact) => (
    `I'm currently exploring options for my upcoming wedding and would love to learn more about your venue and the packages you offer. Could you please provide me with more details regarding availability, capacity, amenities, and any customisation options available?

Here are our contact details for further communication:
        
Name(s): ${name}
Email: ${email}
Contact Number: ${contact}`
)

const venue_checkavailability = (dates, name, email, contact) => (
    `My partner and I are very interested in your venue for our upcoming wedding. Could you please let us know if the venue is available on the following date(s): ${dates}? If so, we would also appreciate any preliminary information about the booking process and package options.
    
Here are our contact details for further communication:
    
Name(s): ${name}
Email: ${email}
Contact Number: ${contact}`
)

export const MESSAGE_TEMPLATE = { // for Enquirycontent

    'Accessories': {
        "Learn More": general_learnmorefunc
    },
    'Beautyhairandmakeup': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Bridalboutiques': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Catering': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Dance': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Decoration': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Dj': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Dressattire': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Emcees': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Entertainment': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Eventrentals': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Florists': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Gifts': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Honeymoon': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Jewellery': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Menswear': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Music': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Paperstationary': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Photobooths': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Photographers': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Transport': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Venue': {
        "Learn More": venue_learnmorefunc,
        "Check Availability": venue_checkavailability
    },
    'Videographers': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Bakeryanddesserts': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    },
    'Planners': {
        "Learn More": general_learnmorefunc,
        "Check Availability": general_checkavailability,
    }
}

function isValidEmail(email) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
}

function isAllNumbers(str) {
    return /^\d+$/.test(str);
}

function converttodatestr(dateobj) {

    // Define the months
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    // Get the day, month, and year
    const day = dateobj.getDate();
    const month = months[dateobj.getMonth()];
    const year = dateobj.getFullYear();

    // Format the date
    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate

}

const iconcss = { display: 'flex', width: 17, height: 17, placeContent: 'center' }

const channeliconcss = { width: 20, height: 20, display: 'flex', alignItems: 'center', color: 'var(--maindark)' }

const CHANNELS_MAP = {
    'Email': iconwrapper(THINEMAIL_ICON, channeliconcss),
    'Whatsapp': iconwrapper(THINWHATSAPP_ICON, channeliconcss),
    'Chat': iconwrapper(THINCHAT_ICON, channeliconcss),
    'Telegram': iconwrapper(THINTELEGRAM_ICON, channeliconcss)
}

function Enquirycontent(props) {

    const [from, setFrom] = useState('') // Vendorlookup, Favouritevendor, Comparevenue, Pricelistlookup, Pricelistcard
    const [channelavailable, setChannelavailable] = useState([])
    const [channel, setChannel] = useState('')

    const [oneormany, setOneormany] = useState('') // send to MANY or ONE

    // for template use
    const [selectedvt, setSelectedvt] = useState('') // default is vt
    const [vt, setVt] = useState('')
    const [svt, setSvt] = useState('')

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('')
    const [message, setMessage] = useState('')

    const [extradata, setExtradata] = useState('')
    const [displayname, setDisplayname] = useState('')
    const [displaymsg, setDisplaymessage] = useState('')
    const [showcaptcha, setShowcaptcha] = useState(false)
    const [vtiids, setVtiids] = useState('')
    const [date, setDate] = useState('')
    const [dates, setDates] = useState([])
    const captchaRef = useRef(null) // for captcha
    const { token, _id, acct_type } = Retrieve_personal_info()

    useEffect(() => {
        if (props) {

            const { data, userdata, allowchat, allowtelegram, allowwhatsapp, allowemail, from, extradata = '', vt, svt, oneormany } = props
            let local_displaynames = ''
            let local_vtiids = []
            for (let i = 0; i < data.length; i++) {
                local_displaynames += data[i].displayname + ((i === data.length - 1) ? '' : ', ')
                local_vtiids.push(data[i].vendortypeinterface_id)
            }
            setDisplayname(local_displaynames)
            setVtiids(local_vtiids)

            if (acct_type === 'USER') { // user
                const { name, spousename, email, contact, date_w, date_e, usertype } = userdata
                let local_compeletename = (name && name.length > 0 ? name + ' & ' : '') + (spousename && spousename.length > 0 ? spousename : '')
                setName(local_compeletename)
                setEmail(email)
                setContact(contact)
                setDates([usertype === 'Wedding' ? converttodatestr(new Date(date_w)) : converttodatestr(new Date(date_e))])
            }

            // set available channels
            let channelavailable = []
            if (allowemail) {
                channelavailable.push('Email')
            }
            if (allowchat) {
                channelavailable.push('Chat')
            }
            if (allowtelegram && data[0].telegram_link.length > 0) {
                channelavailable.push('Telegram')
            }
            if (allowwhatsapp && data[0].whatsapp_link.length > 0) {
                channelavailable.push('Whatsapp')
            }
            setChannelavailable(channelavailable)

            setFrom(from)
            setExtradata(extradata)

            if (channel === '') { setChannel(channelavailable[0]) }

            setOneormany(oneormany)
            setVt(vt)
            setSvt(svt)
            setSelectedvt(vt) // default is vt

        }
    }, [props])

    const toptext_dom = <div className='Rsvpexplorer_portaltitle' style={{ position: 'absolute', top: 0 }}>
        {`Enquiry`}
    </div>

    const sendenquiry_dom = <div className="rrequests_rownowrap" style={{ height: 35, minHeight: 35 }}>
        {`Send an enquiry to `}<div className='rrequests_displayname'>
            {' ' + displayname + ((from === 'Pricelistlookup' || from === 'Pricelistcard') ? (' ( ' + extradata + ' )') : '')}
        </div>.
    </div>

    const channels_dom = <div className='rrequests_rownowrap' style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="enquiry_title" style={{ marginLeft: 0 }}>Message Channels</div>
        <div className="rrequests_row" style={{ margin: 0, overflowY: 'hidden' }}>
            {channelavailable.map((key, i) => {
                return <div
                    key={key + i}
                    className={channel === key ? "enquirycontent_chnselected" : "enquirycontent_chnunselected"}
                    onClick={() => {
                        setChannel(key)
                        setDisplaymessage('')
                    }}
                >
                    <div className="enquirycontent_icon">{CHANNELS_MAP[key]}</div>
                    <div className="enquirycontent_icontxt">{key}</div>
                </div>

            })}
        </div>
    </div>


    const yourname_dom = <div className='rrequests_rownowrap' style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="enquiry_title" style={{ marginLeft: 0 }}>{`Name(s) *`}</div>
        <input
            className='enquirycontent_input'
            onChange={(e) => {
                setName(e.target.value)
            }}
            value={name}
        />
    </div>

    const youremailandnumber_dom = <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className='rrequests_rownowrap' style={{ display: 'flex', flexDirection: 'column', width: '50%', marginRight: 5 }}>
            <div className="enquiry_title" style={{ marginLeft: 0 }}>Email *</div>
            <input
                className='enquirycontent_input'
                onChange={(e) => {
                    setEmail(e.target.value)
                }}
                value={email}
            />
        </div><div className='rrequests_rownowrap' style={{ display: 'flex', flexDirection: 'column', width: '50%', marginLeft: 5 }}>
            <div className="enquiry_title" style={{ marginLeft: 0 }}>Contact *</div>
            <input
                className='enquirycontent_input'
                onChange={(e) => {
                    setContact(e.target.value)
                }}
                value={contact}
            />
        </div>
    </div>

    const date_dom = <div className='rrequests_rownowrap' style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="enquiry_title" style={{ marginLeft: 0 }}>Wedding Date </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <input
                type="date"
                id="datepicker"
                className="enquirycontent_input"
                style={{ width: 140 }}
                value={date}
                onChange={(event) => {
                    setDate(event.target.value)
                }}
            />
            <button
                className="enquirycontentadd_btn"
                onClick={() => {
                    if (date && date.length > 0) {
                        // Get the date value from the input
                        const dateValue = date

                        // Create a Date object
                        const dateobj = new Date(dateValue);

                        const formattedDate = converttodatestr(dateobj)

                        // Assuming setDate is a state setter function
                        if (dates.includes(formattedDate)) {
                            setDate('')
                        }
                        else {
                            setDates([...dates, formattedDate])
                            setDate('')
                        }

                    }

                }}
            >
                Add
            </button>

        </div>
        {dates.length > 0
            ? <div className="enquirycontentadd_dateitems">
                {dates.map((d, i) => {
                    return <div key={d + '_' + i} className="enquirycontentadd_dateitem">
                        <div style={{ margin: '0px 4px' }}>{d}</div>
                        <button
                            className="enquirycontentdeletedate_btn"
                            onClick={() => {
                                let new_dates = dates.slice()
                                new_dates.splice(i, 1)
                                setDates(new_dates)
                            }}>
                            {iconwrapper(CROSS_ICON, iconcss)}
                        </button>
                    </div>
                })}
            </div>
            : null}
    </div>

    const messagetemplate_dom = <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="enquiry_title" style={{ display: 'flex', flexDirection: 'row' }}>
            <div>{`Message Template`}</div>
            ? <div className={`enquirycontent_vtunselected ${selectedvt === vt ? 'enquirycontent_vtselected' : ''}`} onClick={() => { setSelectedvt(vt) }}>
                {`${READABLE_VENDORTYPE_MAP[vt]}`}
            </div>
            {svt.length > 0
                ? <div className={`enquirycontent_vtunselected ${selectedvt === svt ? 'enquirycontent_vtselected' : ''}`} onClick={() => { setSelectedvt(svt) }}>
                    {`${svt.length > 0 ? READABLE_VENDORTYPE_MAP[svt] : ''}`}
                </div>
                : null}
        </div>
        <div className="rrequests_row">

            {MESSAGE_TEMPLATE[selectedvt] && Object.keys(MESSAGE_TEMPLATE[selectedvt]).map((key, index) => {
                return (
                    <button
                        key={key}
                        className="enquirycontent_templatebtn"
                        onClick={() => {
                            setShowcaptcha(false)
                            let msg
                            if (key === 'Check Availability') {
                                let datestr = ''
                                for (let i = 0; i < dates.length; i++) {
                                    if (dates.length - 1 === i) {
                                        datestr += dates[i]
                                    }
                                    else {
                                        datestr += dates[i] + ', '
                                    }
                                }

                                if (datestr === '') {
                                    datestr = ' { YOUR DATES } '
                                }

                                msg = MESSAGE_TEMPLATE[selectedvt][key](datestr, name, email, contact)
                            }
                            else {
                                msg = MESSAGE_TEMPLATE[selectedvt][key](name, email, contact)
                            }
                            setMessage(msg)
                        }}
                    >
                        {key}
                    </button>
                )
            })}
        </div>
    </div>

    const messageinput_dom = <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="enquiry_title">Message</div>
        <textarea
            style={{ fontSize: 14, padding: 5, outline: 'none', margin: '0px 10px', borderRadius: 5, border: '1px solid var(--maindark)' }}
            rows="5"
            value={message}
            disabled={showcaptcha}
            onChange={(e) => { setMessage(e.target.value) }}
            margin="dense"
            variant="outlined"
        />
    </div>

    const emailsend_btn = <div style={{ display: 'flex', margin: 10, placeContent: 'flex-end' }}>
        <button
            className={'rrequests_sendbtn'}
            onClick={() => {

                let result = false
                if (email.length > 0 && isValidEmail(email) && name.length > 0 && message.length > 0) {
                    result = true
                    setDisplaymessage('')
                }
                else {
                    result = false
                }

                setShowcaptcha(result)

                if (!result) {
                    let displaymsg = ''
                    // check email
                    if (email.length === 0 || !isValidEmail(email)) {
                        displaymsg += 'Email is invalid. '
                    }

                    // check email
                    if (name.length === 0) {
                        displaymsg += 'Name is invalid. '
                    }

                    // check message
                    if (message.length === 0) {
                        displaymsg += 'Message is invalid. '
                    }

                    // check contact
                    if (contact.length === 0 || !isAllNumbers(contact)) {
                        displaymsg += 'Number is invalid. '
                    }
                    setDisplaymessage(displaymsg)
                }

            }}
        >
            Send
        </button>
    </div>

    const chatsend_btn = <div style={{ display: 'flex', margin: 10, placeContent: 'flex-end' }}>
        <button
            className={'rrequests_sendbtn'}
            onClick={() => {

                if (message.length > 0) {
                    try {
                        let options = {
                            method: 'POST',
                            url: INIT_CONV_USER,
                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                            data: { message: message, vti_id: vtiids[0] }
                        }
                        axios(options).then((res) => {
                            const { message } = res.data

                            if (message === 'REDIRECT') {
                                window.location = '/chat'
                            }
                            else if (message === 'SUCCESS') {
                                window.location = '/chat'
                            }

                        }).catch(e => {
                            console.log(e)
                        })
                    }
                    catch (e) {
                        console.log(e)
                    }
                }
                else {
                    setDisplaymessage('Message is invalid. ')
                }

            }}
        >
            Enquire
        </button>
    </div>

    const whatsapp_btn = <div style={{ display: 'flex', margin: 10, placeContent: 'flex-end' }}>
        <button
            className={'rrequests_sendbtn'}
            onClick={() => {
                if (message.length > 0) {
                    window.open('https://api.whatsapp.com/send?phone=' + props.data[0].whatsapp_link + '&text=' + message)
                }
                else {
                    setDisplaymessage('Message is invalid. ')
                }
            }}
        >
            Enquire
        </button>
    </div>

    const telegram_btn = <div style={{ display: 'flex', margin: 10, placeContent: 'flex-end' }}>
        <button
            className="rrequests_sendbtn"
            style={{ marginRight: 5 }}
            onClick={() => {
                navigator.clipboard.writeText(message)
                    .then(() => {
                        setDisplaymessage('Copied')
                    })
                    .catch(err => {
                        setDisplaymessage('Failed to copy text.')
                    });
            }}
        >
            Copy Message
        </button>
        <a id='Renderrequests_telegramclick' className="rrequests_telegramsendroot" href={`tg://resolve?domain=${props.data[0].telegram_link}`} />
        <button
            className={'rrequests_sendbtn'}
            onClick={() => { document.getElementById('Renderrequests_telegramclick').click(); setDisplaymessage(''); }}
        >
            Enquire
        </button>
    </div>

    const captcha_dom = <div style={{ display: 'flex', margin: '10px 10px 0px 10px', placeContent: 'flex-end' }}>
        <ReCAPTCHA
            ref={(el) => { captchaRef.current = el }}
            sitekey={RECAPTCHA_KEY}
            onChange={(captchares) => {
                // here send to backend
                if (captchares && captchares.length > 0) {

                    let options = {}
                    if (acct_type === 'USER') { // user
                        options = {
                            method: 'POST',
                            url: UPDATE_ENQUIREUSER,
                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                            data: {
                                message: message,
                                vti_ids: vtiids,
                                captcha: captchares,
                                contact: contact,
                                email: email,
                                name: name,
                                from: from,
                                extradata: extradata
                            }
                        }
                    }
                    else { // non user
                        options = {
                            method: 'POST',
                            url: UPDATE_ENQUIRENONUSER,
                            data: {
                                message: message,
                                vti_ids: vtiids,
                                captcha: captchares,
                                contact: contact,
                                email: email,
                                name: name,
                                from: from,
                                extradata: extradata
                            }
                        }
                    }

                    try {
                        axios(options).then((res) => {
                            const { message } = res.data
                            if (message === 'SUCCESS') {
                                setDisplaymessage('Your message has been successfully sent.')
                                setShowcaptcha(false)
                            }
                            else if (acct_type === 'USER' && message === 'EMAIL_QUOTA_REACH') {
                                setDisplaymessage('Your email quota has been reached. Email quota will be reset on the next day. Or you could use other form of message channels we provided above.')
                                setShowcaptcha(false)
                            }

                            setTimeout(() => {
                                setDisplaymessage('')
                            }, 5000)

                        }).catch(e => {
                            refresh()
                        })
                    }
                    catch (e) {
                        refresh()
                    }
                }

            }}
        />
    </div>

    const displaymsg_dom = <div className="rrequests_displaymsg" style={{ padding: '0px 5px' }}>{displaymsg}</div>

    const emptyspace_dom = <div style={{ display: 'flex', height: 30, minHeight: 30, width: '100%', placeContent: 'flex-end' }} />

    const email_items_dom = <>
        {yourname_dom}
        {youremailandnumber_dom}
        {date_dom}
        {messagetemplate_dom}
        {messageinput_dom}
        {showcaptcha ? captcha_dom : emailsend_btn}
        {displaymsg_dom}
        {emptyspace_dom}
    </>

    const chat_items_dom = <>
        {yourname_dom}
        {youremailandnumber_dom}
        {date_dom}
        {messagetemplate_dom}
        {messageinput_dom}
        {chatsend_btn}
        {displaymsg_dom}
        {emptyspace_dom}
    </>

    const whatsapp_items_dom = <>
        {yourname_dom}
        {youremailandnumber_dom}
        {date_dom}
        {messagetemplate_dom}
        {messageinput_dom}
        {whatsapp_btn}
        {displaymsg_dom}
        {emptyspace_dom}
    </>

    const telegram_items_dom = <>
        {yourname_dom}
        {youremailandnumber_dom}
        {date_dom}
        {messagetemplate_dom}
        {messageinput_dom}
        {telegram_btn}
        {displaymsg_dom}
        {emptyspace_dom}
    </>

    return <div className='Portalmodal_vtroot' style={{ fontFamily: 'Lato', overflowX: 'hidden', position: 'relative' }} >
        {toptext_dom}
        <div className="enquiry_colscroll">
            {sendenquiry_dom}
            {channels_dom}
            {{
                'Email': email_items_dom,
                'Whatsapp': whatsapp_items_dom,
                'Chat': chat_items_dom,
                'Telegram': telegram_items_dom,
            }[channel]}

        </div>
    </div>

}
export default Enquirycontent