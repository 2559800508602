import React, { useEffect, useState, useRef } from "react"
import { Checkdevice, Loading, Modcfm, Portalmodal, Progressbar, refresh } from "../../../../../components"
import {
    ARROWLEFT_ICON, e_fs, PLOBJ, b_fs, d_fs, c_fs, EMPTYSPACE,
    // DOWNLOAD_SMALL, EXPAND_RIGHT_ICON, EXPAND_ICON, HIDE_SMALL, SHOW_SMALL, PENCIL_ICON_SMALLL, MAIN_PINK_COLOR
} from "../../../../../config/constants"
import {
    DOWNLOAD_ICON, TRASH_ICON, ITINERARYTEMPLATE_ICON, UNCOLLAPSE_ICON, COLLAPSE_ICON, TICK_ICON, CIRCLE_ICON, LINERIGHTARROW_ICON, LINEDOWNARROW_ICON, CROSS_ICON, SETTING10_ICON,
    // COLOR_ICON, FRAME_ICON, LAPTOP_ICON, MOBILE_ICON, GRID_ICON, RESIZE_ICON, ITINERARYCOMP_ICON, PRINT_ICON, COPY_ICON, ENTER_ICON, TEMPLATE_ICON, PLANNING_QR_ICON, EYE_ICON, PENCIL_ICON, DOWNARROW_ICON, RIGHTARROW_ICON,
} from "../../../../../config/svgicons"
import { iconwrapper, Retrieve_personal_info } from "../../../../../config/commonfunctions"
import { READ_SINGLECHECKLIST, UPDATE_CHECKLIST, UPDATE_CHECKLISTITEM, UPDATE_CHECKLISTNEWITEM, UPDATE_CHECKLISTNEWSEC, UPDATE_CHECKLISTCAT, UPDATE_CHECKLISTNAME, DELETE_CHECKLISTITEM, DELETE_CHECKLISTCAT, UPDATE_CHECKLISTSWAP, UPDATE_CHECKLISTDONE, UPDATE_CHECKLISTARCHIVE, UPDATE_CHECKLISTPORTALARCHIVE, UPDATE_REVERTARCHIVEITEM, UPDATE_DELETEARCHIVEITEM, UPDATE_CHECKLISTTEMPLATE, UPDATE_CHECKLISTSETTING } from "../../../../../config/api"
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { CSVLink } from 'react-csv';
import Success from "../../Invitationmaker/components/Portalcontent/components/Success"
import { input_dom, partialtitle_dom } from "../../Invitationmaker/components/Commonelements/Commonelements"
import axios from "axios";
import './Checklistedit.css'

const VT_DEVICE_FS_MAP = { 'WEB': b_fs, 'TABLET': c_fs, 'MOBILE': d_fs }
const CELL_DEVICE_FS_MAP = { 'WEB': 17, 'TABLET': d_fs, 'MOBILE': 15 }

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#707070' : 'lightgrey',
});

const getItemStyle = (isDragging, draggableStyle) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    outline: 'none',
    userSelect: 'none', // some basic styles to make the items look a bit nicer
    backgroundColor: 'white',    // change background colour if dragging
    border: isDragging ? '1px solid #707070' : '1px solid transparent',
    minHeight: 60,
    ...draggableStyle,    // styles we need to apply on draggables
});

const getMainobjStyle = (isDragging, draggableStyle) => {
    return isDragging
        ? { border: '1px solid var(--maindark)', ...draggableStyle, }
        : { border: '1px solid transparent', ...draggableStyle, }
}

function Checklistedit(props) {
    // default stuff
    const { token } = Retrieve_personal_info()
    const btmref = useRef(null) // click add and scroll to btm
    const device = Checkdevice()
    const [firsttime, setFirsttime] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [returnhome, setReturnhome] = useState(false)

    // save block
    const [blocksave, setBlocksave] = useState(false)
    const [notitxt, setNotitxt] = useState('')

    // archive
    const [archive, setArchive] = useState([])

    // sorted archive
    const [id_sortedarchive_map, setId_sortedarchive_map] = useState(new Map())
    const [id_sortedarchivename_map, setId_sortedarchivename_map] = useState({})

    // data
    const [update_id, setUpdate_id] = useState('') // verifier to ensure one one checklist exist everytime.
    const [currchecklist_id, setCurrchecklist_id] = useState('') // user clicked and selected a particular timeline obj
    const [currchecklistobjs, setCurrchecklistobjs] = useState([]) // curr selected obj
    const [currchecklistobjs_name, setCurrchecklistobjs_name] = useState('')// curr selected obj's name
    const [currchecklistobjs_index, setCurrchecklistobjs_index] = useState(null) // curr selected obj's index
    const [currchecklistobjs_innerindex, setCurrchecklistobjs_innerindex] = useState(null)// curr selected obj's index

    // setting
    const [setting, setSetting] = useState({ showtrash: false })
    const [portaltempsettingdata, setPortaltempsettingdata] = useState({ showtrash: false, fontSize: 'large' }) // for portal only
    const [opensetting, setOpensetting] = useState(false)

    const [checked, setChecked] = useState(0)
    const [csvdata, setCsvdata] = useState([]) // for csv
    const [archivecsv, setArchivecsv] = useState(null) // for csv
    const [todocsv, setTodocsv] = useState(null) // for csv

    const [expandall, setExpandall] = useState(false)

    // CHECK LIST MODE
    const [checklistmode, setChecklistmode] = useState('TODO') // TODO, ARCHIVE

    const [portalstate, setPortalstate] = useState('') // '', CHECKLIST, SECTION, ITEM, NEW_ITEM, NEW_SEC
    const [portalresult, setPortalresult] = useState('')

    // store check state for ITEM, NEW_ITEM
    const [portalitemcheck, setPortalitemcheck] = useState(false)
    const [portaldueby, setPortaldueby] = useState('')
    const [portalcreatedate, setPortalcreatedate] = useState('')

    // store general data for CHECKLIST, SECTION, ITEM, NEW_ITEM, NEW_SEC
    const [portaltext, setPortaltext] = useState('')
    const [portallink, setPortalink] = useState('')
    const [portalcontent, setPortalcontent] = useState('')

    // havent use yet
    const [portalmsgs, setPortalmsgs] = useState({})

    // drag and drop
    const [dragsavemode, setDragsavemode] = useState(false)
    const [clonechecklistobjs, setClonechecklistobjs] = useState([])

    // template portal
    const [opentemplate, setOpentemplate] = useState(false)
    const [templatemod, setTemplatemod] = useState(false) // for template confirmation
    const [templatekey, setTemplatekey] = useState('') // for template confirmation

    // check or archieve popup
    const [showcheckpopup, setShowcheckpopup] = useState(false)
    const [checkpopupstate, setCheckpopupstate] = useState('DONE') // DONE, ARCHIVE

    // download portal
    const [downloadportal, setDownloadportal] = useState(false)

    // delete item
    const [deletemod, setDeletemod] = useState(false)
    const [deletemainindex, setDeletemainindex] = useState(-1)
    const [deletesubindex, setDeletesubindex] = useState(-1)

    // delete section
    const [deletesectiongate, setDeletesectiongate] = useState(false)

    const iconsize = { 'WEB': 30, 'TABLET': 25, 'MOBILE': 25 }[device]
    const itemiconsize = { 'WEB': 20, 'TABLET': 18, 'MOBILE': 18 }[device]
    const portalleftcss = { width: { 'WEB': 500, 'TABLET': 450, 'MOBILE': '100%' }[device], height: '100%', backgroundColor: 'white', top: 0, left: null, right: 0, transform: 'none' }

    // retrieve itinerary data from urlhandler 
    useEffect(() => {
        props.hidetopbar(true)
        if (firsttime) {
            var id = props.match.params.id
            axios({
                method: 'POST',
                url: READ_SINGLECHECKLIST,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { checklist_id: id }
            })
                .then((checklist) => {
                    const { message, item } = checklist.data
                    if (message === 'SUCCESS') {
                        const { checklistobjs, name, archive, update_id, settings } = item
                        setCurrchecklist_id(id)
                        setCurrchecklistobjs(checklistobjs)
                        setCurrchecklistobjs_name(name)
                        setArchive(archive)
                        setUpdate_id(update_id)
                        setSetting(JSON.parse(JSON.stringify(settings)))
                        setPortaltempsettingdata(JSON.parse(JSON.stringify(settings)))
                        setFirsttime(false)
                        setLoaded(true)
                    }
                }).catch((e) => {
                    // props.history.push('/') 
                })
        }
        return () => props.hidetopbar(false)
    }, [])

    useEffect(() => {
        // restructure the curr_vtobj for CSVLink to export
        if (currchecklistobjs && currchecklistobjs.length > 0) {

            var new_csvData = []

            var total = 0
            var checked = 0

            var totalcollapse = 0

            for (let i = 0; i < currchecklistobjs.length; i++) {

                const { sectionname, links, contents, strikeoff, items, collapse } = currchecklistobjs[i]
                console.log(i, currchecklistobjs[i])
                // new_csvData.push([])
                // new_csvData.push([sectionname])

                for (let j = 0; j < items.length; j++) {

                    if (strikeoff[j]) { checked = checked + 1 }
                    total = total + 1

                    // new_csvData.push([
                    //     items[j],
                    //     // contents[j].length > 0 ? contents[j] : '-',
                    //     links[j].length > 0 ? links[j] : '-',
                    //     strikeoff[j] ? 'YES' : 'NO'
                    // ])
                }

                if (collapse) {
                    totalcollapse = totalcollapse + 1
                }
            }


            checked = Math.floor((checked / total) * 100)

            setChecked(checked)
            setCsvdata(new_csvData)
            setExpandall(totalcollapse === currchecklistobjs.length)
        }

    }, [currchecklistobjs])

    useEffect(() => {

        if (downloadportal) {

            let tododata = []
            if (currchecklistobjs && currchecklistobjs.length > 0) {

                for (let i = 0; i < currchecklistobjs.length; i++) {

                    tododata.push([])
                    const { contents, createdate, dueby, items, links, sectionid, sectionname, strikeoff } = currchecklistobjs[i]
                    tododata.push([sectionname])
                    for (let j = 0; j < items.length; j++) {
                        tododata.push([
                            items[j],
                            strikeoff[j] ? 'Done' : ''
                        ])
                    }
                }
            }
            setTodocsv(
                <CSVLink
                    id='DOWNLOAD_TO_DO'
                    filename={"todo.csv"}
                    data={tododata}
                    style={{ visibility: 'hidden', width: 0, height: 0 }}
                >
                    Download To Do
                </CSVLink>
            )


            let archivedata = []
            if (archive && archive.length > 0) {
                let sectionid_sectionname_map = new Map()
                let sectionid_data_map = new Map()

                for (let i = 0; i < archive.length; i++) {
                    const { content, createdate, dueby, item, link, sectionid, sectionname, strikeoff } = archive[i]
                    sectionid_sectionname_map.set(sectionid, sectionname)
                    let data = []
                    data.push([
                        item,
                        'Done'
                    ])

                    if (sectionid_data_map.has(sectionid)) { // section already exist
                        let existing_arr = sectionid_data_map.get(sectionid)
                        existing_arr.push(data[0])
                        sectionid_data_map.set(sectionid, existing_arr)
                    }
                    else {
                        sectionid_data_map.set(sectionid, data)
                    }
                }

                const keys = sectionid_data_map.keys();
                for (const key of keys) {
                    const sectionname = sectionid_sectionname_map.get(key)
                    archivedata.push([sectionname])
                    for (let j = 0; j < sectionid_data_map.get(key).length; j++) {
                        // console.log(sectionid_data_map.get(key)[j])
                        archivedata.push(sectionid_data_map.get(key)[j])
                    }
                }
                setArchivecsv(archivedata)
            }

            setArchivecsv(
                <CSVLink
                    id='DOWNLOAD_ARCHIVE'
                    filename={"archive.csv"}
                    data={archivedata}
                    style={{ visibility: 'hidden', width: 0, height: 0 }}
                >
                    Download Archive
                </CSVLink>
            )
        }

    }, [downloadportal])

    useEffect(() => {
        setPortalresult('DEFAULT')
    }, [portalstate])

    useEffect(() => {


        // collapse,createdate,dueby,link, sectionid,strikeoff, item


        let id_sortedarchive_map = new Map()
        let id_sortedarchivename_map = {}
        for (let i = 0; i < archive.length; i++) {
            let local_currarchive = archive[i]

            const { sectionid, sectionname } = local_currarchive
            local_currarchive['archive_index'] = i

            if (!Object.keys(id_sortedarchivename_map).includes(sectionid)) {
                id_sortedarchivename_map[sectionid] = sectionname
            }

            if (id_sortedarchive_map.has(sectionid)) {

                id_sortedarchive_map.set(sectionid, [local_currarchive, ...id_sortedarchive_map.get(sectionid)])

            }
            else {
                id_sortedarchive_map.set(sectionid, [local_currarchive])
            }
        }

        setId_sortedarchivename_map(id_sortedarchivename_map)
        setId_sortedarchive_map(id_sortedarchive_map)

    }, [archive])

    useEffect(() => {
        if (!dragsavemode) {
            setClonechecklistobjs([])
        }
    }, [dragsavemode])

    const save = () => {
        setBlocksave(true)
        setNotitxt('Saving...')
        let options = {
            method: 'POST',
            url: UPDATE_CHECKLIST,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }, //need token from the web
            data: {}
        }
        axios(options).then((result) => {
            const { data, status } = result
            const { message } = data
            if (status === 200) {
                timersequence(
                    () => {
                        setBlocksave(false)
                        if (message === 'SUCCESS') {
                            setNotitxt('')
                        }
                    },
                    {
                        'SUCCESS': 'Saved',
                        'FAILURE': 'Failed'
                    }[message]
                )
            }
        }).catch(e => { })

    }

    const showall = () => { // show all vts
        var temp = currchecklistobjs.slice()
        for (let i = 0; i < temp.length; i++) {
            temp[i].collapse = true
        }
        setCurrchecklistobjs(temp)
    }

    // home modcfm start
    const home_mod = returnhome
        ? <Modcfm
            onHide={() => { setReturnhome(false) }}
            onOk={() => { props.history.push(PLOBJ['checklist']['toolurl']) }}
            onNo={() => { setReturnhome(false) }}
            txt={'You are returning to planning tools menu.'}
        />
        : null

    const resetProtaldata = () => {
        setPortalstate('')
        setPortalresult('')
        setPortalitemcheck(false)
        setPortaldueby('')
        setPortalcreatedate('')
        setPortaltext('')
        setPortalink('')
        setPortalcontent('')
        setPortalmsgs({})
        setDeletesectiongate(false)
    }


    const edit_update = () => {

        let curr_checklistobjs = currchecklistobjs.slice()

        let api
        let data
        if (portalstate === 'ITEM') {
            curr_checklistobjs[currchecklistobjs_index]['strikeoff'][currchecklistobjs_innerindex] = portalitemcheck
            curr_checklistobjs[currchecklistobjs_index]['dueby'][currchecklistobjs_innerindex] = portaldueby
            curr_checklistobjs[currchecklistobjs_index]['items'][currchecklistobjs_innerindex] = portaltext
            curr_checklistobjs[currchecklistobjs_index]['links'][currchecklistobjs_innerindex] = portallink
            curr_checklistobjs[currchecklistobjs_index]['contents'][currchecklistobjs_innerindex] = portalcontent
            api = UPDATE_CHECKLISTITEM
            data = {
                update_id: update_id,
                checklist_id: currchecklist_id,
                mainindex: currchecklistobjs_index,
                subindex: currchecklistobjs_innerindex,
                portalitemcheck, portaldueby, portaltext, portallink, portalcontent
            }
        }
        else if (portalstate === 'NEW_ITEM') {
            curr_checklistobjs[currchecklistobjs_index]['strikeoff'].push(portalitemcheck)
            curr_checklistobjs[currchecklistobjs_index]['dueby'].push(portaldueby)
            curr_checklistobjs[currchecklistobjs_index]['items'].push(portaltext)
            curr_checklistobjs[currchecklistobjs_index]['links'].push(portallink)
            curr_checklistobjs[currchecklistobjs_index]['contents'].push(portalcontent)
            api = UPDATE_CHECKLISTNEWITEM
            data = {
                update_id: update_id,
                checklist_id: currchecklist_id,
                mainindex: currchecklistobjs_index,
                portalitemcheck, portaldueby, portaltext, portallink, portalcontent
            }
        }
        else if (portalstate === 'NEW_SEC') {
            if (portaltext.length === 0) {
                setPortalmsgs({ 'NEW_SEC': 'Unable to create. Input field is empty.' })
                return
            }
            else {
                api = UPDATE_CHECKLISTNEWSEC
                data = {
                    update_id: update_id,
                    checklist_id: currchecklist_id,
                    sectionname: portaltext,
                }
            }
        }
        else if (portalstate === 'SECTION') {
            curr_checklistobjs[currchecklistobjs_index]['sectionname'] = portaltext
            api = UPDATE_CHECKLISTCAT
            data = {
                update_id: update_id,
                checklist_id: currchecklist_id,
                sectionname: portaltext,
                mainindex: currchecklistobjs_index,
            }
        }
        else if (portalstate === 'CHECKLIST') {
            api = UPDATE_CHECKLISTNAME
            data = {
                update_id: update_id,
                checklist_id: currchecklist_id,
                name: portaltext,
            }
        }
        try {
            axios({
                method: 'POST',
                url: api,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: data
            })
                .then((result) => {
                    const { message, returndata, new_update_id } = result.data
                    if (message === 'SUCCESS') {
                        setPortalresult('SUCCESS')

                        if (portalstate === 'CHECKLIST') {
                            setCurrchecklistobjs_name(portaltext)
                        }
                        else if (portalstate === 'NEW_SEC') {
                            curr_checklistobjs.push({ // has to be same as backend
                                sectionname: portaltext,
                                sectionid: returndata.sectionid, // wait for return result
                                items: [],
                                strikeoff: [],
                                links: [],
                                contents: [],
                                createdate: [],// ****** THE APPEAR VALUE AND STORE VALUE ARE DIFFERENT ****** 
                                dueby: [],// ****** THE APPEAR VALUE AND STORE VALUE ARE DIFFERENT ****** 
                                collapse: false
                            })
                            setCurrchecklistobjs(curr_checklistobjs)
                        }
                        else if (portalstate === 'NEW_ITEM') {
                            const { new_create_date } = result.data
                            curr_checklistobjs[currchecklistobjs_index]['createdate'].push(new_create_date)
                            setCurrchecklistobjs(curr_checklistobjs)
                        }
                        else {
                            setCurrchecklistobjs(curr_checklistobjs)
                        }

                        setUpdate_id(new_update_id)
                        resetProtaldata()
                    }
                    else if (message === 'CHECKLISTDOUBLE') {
                        refresh()
                    }
                })
                .catch((e) => { })
        }
        catch (e) {
        }
    }

    const edit_portal = <Portalmodal
        animation={device === 'MOBILE' ? "" : "right_left"}
        portaltype={'commonportal'}
        open={portalstate.length > 0}
        onClose={resetProtaldata}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalleftcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className='Rsvpexplorer_portal'>
            <div className='Rsvpexplorer_portaltitle'>
                {{
                    '': '',
                    CHECKLIST: 'Edit Checklist Title',
                    SECTION: 'Edit Items Title',
                    ITEM: 'Edit Item',
                    NEW_ITEM: 'Add New Item',
                    NEW_SEC: 'Add New Category'
                }[portalstate]}
            </div>
            <div className='Rsvpexplorer_portalcontent' style={{ height: '100%' }}>
                {{
                    'DEFAULT': <div className='Rsvpqnexplorer_portalroot'>
                        <div className='Rsvpexplorer_portalcontent'>

                            {input_dom(
                                {
                                    CHECKLIST: 'Checklist Title ',
                                    SECTION: 'Section Name',
                                    ITEM: 'Content',
                                    NEW_ITEM: 'Content',
                                    NEW_SEC: 'Section Name',
                                }[portalstate],
                                ``,
                                portaltext,
                                (e) => {
                                    setPortaltext(e.target.value);
                                    setPortalmsgs({})
                                },
                                portalstate,
                                portalmsgs,
                                null,
                                null,
                                {}
                            )}

                            {portalstate === 'ITEM' || portalstate === 'NEW_ITEM'
                                ? <div className="Checklist_dateroot">
                                    {partialtitle_dom(`Due Date (optional)`, {})}
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', placeContent: 'center' }}>
                                        <input
                                            className="Checklist_dateinput"
                                            type="date"
                                            name="bday"
                                            required
                                            pattern="\d{2}/\d{2}/\d{4}"
                                            value={portaldueby}
                                            onChange={(e) => {
                                                // ****** THE APPEAR VALUE AND STORE VALUE ARE DIFFERENT ****** 
                                                setPortaldueby(e.target.value)
                                            }}
                                        />
                                        <button className='Checklist_calendarbtnclose' onClick={() => setPortaldueby('')}>
                                            {iconwrapper(CROSS_ICON, { width: 20, height: 20, display: 'flex', placeContent: 'center', color: 'var(--mainpink)' })}
                                        </button>
                                    </div>
                                </div>
                                : null}

                            {portalstate === 'ITEM' || portalstate === 'NEW_ITEM'
                                ? <div className="Checklist_checkboxrow" onClick={() => { }}>
                                    <input
                                        className="FMC_cb"
                                        type={'checkbox'}
                                        checked={portalitemcheck}
                                        onChange={() => setPortalitemcheck(!portalitemcheck)}
                                    />
                                    <label
                                        className="Checklist_lbl"
                                        name={''}
                                        htmlFor={''}
                                        onClick={() => setPortalitemcheck(!portalitemcheck)}
                                    >
                                        Set As Done
                                    </label>
                                </div>
                                : null}


                            {portalstate === 'ITEM'
                                ? <div className="Checklist_dateroot" style={{ alignItems: 'flex-end', fontSize: 12 }}>
                                    {partialtitle_dom(`Created On`, {})}
                                    <div style={{ display: 'flex', height: 20, flexDirection: 'row', alignItems: 'center', placeContent: 'start', backgroundColor: 'var(--maindark)', padding: 5, borderRadius: 5, width: 'max-content', color: '#ffffff' }}>
                                        {portalcreatedate}
                                    </div>
                                </div>
                                : null}

                            {portalstate === 'SECTION' || portalstate === 'ITEM'
                                ? <div className="Checklistedit_deleteroot">
                                    {deletesectiongate
                                        ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ marginBottom: 5, color: 'var(--mainpink)', textAlign: 'center' }}>Once you delete this, there is no going back. Please be certain.</div>
                                            <div className="Checklist_deletesectionroot">
                                                <button
                                                    className="Checklist_deletesectioncancel"
                                                    onClick={() => { setDeletesectiongate(false) }}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="Checklist_deletesectioncfm"
                                                    onClick={() => {

                                                        try {
                                                            axios({
                                                                method: 'POST',
                                                                url: portalstate === 'SECTION' ? DELETE_CHECKLISTCAT : DELETE_CHECKLISTITEM,
                                                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                                                data: portalstate === 'SECTION'
                                                                    ? { checklist_id: currchecklist_id, mainindex: currchecklistobjs_index, update_id: update_id }
                                                                    : { checklist_id: currchecklist_id, mainindex: currchecklistobjs_index, subindex: currchecklistobjs_innerindex, update_id: update_id }
                                                            }).then((result) => {
                                                                const { message, new_update_id } = result.data
                                                                if (message === 'SUCCESS') {
                                                                    var local_currchecklistobjs = currchecklistobjs.slice()

                                                                    if (portalstate === 'SECTION') {
                                                                        local_currchecklistobjs.splice(currchecklistobjs_index, 1)
                                                                    }
                                                                    else {
                                                                        local_currchecklistobjs[currchecklistobjs_index]['items'].splice(currchecklistobjs_innerindex, 1)
                                                                        local_currchecklistobjs[currchecklistobjs_index]['strikeoff'].splice(currchecklistobjs_innerindex, 1)
                                                                        local_currchecklistobjs[currchecklistobjs_index]['links'].splice(currchecklistobjs_innerindex, 1)
                                                                        local_currchecklistobjs[currchecklistobjs_index]['contents'].splice(currchecklistobjs_innerindex, 1)
                                                                        local_currchecklistobjs[currchecklistobjs_index]['dueby'].splice(currchecklistobjs_innerindex, 1)
                                                                        local_currchecklistobjs[currchecklistobjs_index]['createdate'].splice(currchecklistobjs_innerindex, 1)
                                                                        setCurrchecklistobjs(local_currchecklistobjs)
                                                                    }

                                                                    setCurrchecklistobjs(local_currchecklistobjs)
                                                                    setCurrchecklistobjs_index(-1)
                                                                    setCurrchecklistobjs_innerindex(-1)
                                                                    setUpdate_id(new_update_id)
                                                                    setDeletesectiongate(false)
                                                                    setPortalstate('')
                                                                }
                                                                else if (message === 'CHECKLISTDOUBLE') { refresh() }
                                                            }).catch((e) => {
                                                                refresh()
                                                            })
                                                        }
                                                        catch (e) {
                                                            refresh()
                                                        }


                                                    }}
                                                >
                                                    Confirm Delete
                                                </button>
                                            </div>
                                        </div>
                                        : <button
                                            className="Checklistedit_delete"
                                            style={{ border: '1px dashed var(--maindark)' }}
                                            onClick={() => { setDeletesectiongate(true) }}
                                        >
                                            {iconwrapper(TRASH_ICON, { width: itemiconsize, height: itemiconsize, display: 'flex', placeContent: 'center' })}
                                        </button>}
                                </div>
                                : null}

                        </div>
                        {(portalstate === 'ITEM' || portalstate === 'NEW_ITEM') && portalitemcheck
                            ? <div className="gl_portalbtnrow">
                                <button
                                    style={{ borderRadius: 10, backgroundColor: '#ffffff', border: '1px solid var(--maindark)', color: 'var(--maindark)', fontSize: 18, padding: '0px 10px' }}
                                    onClick={() => {
                                        try {
                                            axios({
                                                method: 'POST',
                                                url: UPDATE_CHECKLISTPORTALARCHIVE,
                                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                                data: {
                                                    update_id,
                                                    checklist_id: currchecklist_id,
                                                    sectionid: currchecklistobjs[currchecklistobjs_index]['sectionid'],
                                                    sectionname: currchecklistobjs[currchecklistobjs_index]['sectionname'],
                                                    mainindex: currchecklistobjs_index,
                                                    subindex: currchecklistobjs_innerindex,
                                                    portalitemcheck: true,
                                                    portaldueby: currchecklistobjs[currchecklistobjs_index]['dueby'][currchecklistobjs_innerindex],
                                                    portaltext: currchecklistobjs[currchecklistobjs_index]['items'][currchecklistobjs_innerindex],
                                                    portallink: currchecklistobjs[currchecklistobjs_index]['links'][currchecklistobjs_innerindex],
                                                    portalcontent: currchecklistobjs[currchecklistobjs_index]['contents'][currchecklistobjs_innerindex],
                                                    portalcreatedate: currchecklistobjs[currchecklistobjs_index]['createdate'][currchecklistobjs_innerindex],
                                                }
                                            }).then((result) => {
                                                const { message, new_update_id } = result.data
                                                if (message === 'SUCCESS') {
                                                    var local_currchecklistobjs = currchecklistobjs.slice()
                                                    local_currchecklistobjs[currchecklistobjs_index]['items'].splice(currchecklistobjs_innerindex, 1)
                                                    local_currchecklistobjs[currchecklistobjs_index]['strikeoff'].splice(currchecklistobjs_innerindex, 1)
                                                    local_currchecklistobjs[currchecklistobjs_index]['links'].splice(currchecklistobjs_innerindex, 1)
                                                    local_currchecklistobjs[currchecklistobjs_index]['contents'].splice(currchecklistobjs_innerindex, 1)
                                                    local_currchecklistobjs[currchecklistobjs_index]['dueby'].splice(currchecklistobjs_innerindex, 1)
                                                    local_currchecklistobjs[currchecklistobjs_index]['createdate'].splice(currchecklistobjs_innerindex, 1)
                                                    setCurrchecklistobjs(local_currchecklistobjs)
                                                    setUpdate_id(new_update_id)
                                                    resetProtaldata()
                                                }
                                                else if (message === 'CHECKLISTDOUBLE') {
                                                    refresh()
                                                }
                                            }).catch((e) => { })
                                        }
                                        catch (e) {
                                        }
                                    }}
                                >
                                    Archive
                                </button>
                                <button
                                    className='gl_portalupdatebtn'
                                    onClick={edit_update}
                                >
                                    Update
                                </button>
                            </div>
                            : <button
                                className='Rsvpexplorer_portalsave'
                                onClick={edit_update} >
                                Update
                            </button>}

                    </div>,
                    'SUCCESS': <div className='Rsvpqnexplorer_portalroot'>
                        <Success txt={'Successfully Updated.'} onClick={() => { setPortalstate('') }} />
                    </div>,
                    'FAILURE': <div className='Rsvpqnexplorer_portalroot'>
                        <div className='Rsvpqnexplorer_portalmaincontenttitle'>Failed to update.</div>
                        <button className='Rsvpexplorer_portalsave' onClick={() => { }} >OK</button>
                    </div>,
                    'DONE': <div className='Rsvpqnexplorer_portalroot'>
                        <div className='Rsvpqnexplorer_portalmaincontenttitle'>Update successfully</div>
                        <button
                            className='Rsvpexplorer_portalsave'
                            onClick={() => {

                            }}
                        >
                            OK
                        </button>
                    </div>
                }[portalresult]}
            </div>

        </div>

    </Portalmodal>


    const portal_templatecancel = () => {
        setOpentemplate(false);
        setTemplatemod(false);
        setTemplatekey('');
    }

    const portal_templatecfm = () => {
        const value = templatekey
        try {
            axios({
                method: 'POST',
                url: UPDATE_CHECKLISTTEMPLATE,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: {
                    update_id,
                    checklist_id: currchecklist_id,
                    template: value,
                }
            }).then((result) => {
                const { message, new_update_id, new_currchecklistobjs, name } = result.data
                if (message === 'SUCCESS') {
                    setCurrchecklistobjs(new_currchecklistobjs)
                    setUpdate_id(new_update_id)
                    setCurrchecklistobjs_name(name)
                    setOpentemplate(false)
                    setTemplatemod(false)
                    setTemplatekey('')
                }
                else if (message === 'CHECKLISTDOUBLE') {
                    refresh()
                }
            }).catch((e) => {
                refresh()
            })
        }
        catch (e) { }
    }

    const portal_templatecfmcancel = () => {
        setTemplatemod(false)
        setTemplatekey('')
    }

    const portal_assigntemplate = (e) => {
        setTemplatemod(true)
        setTemplatekey(e.target.value)
    }

    const update_portalshowtrash = (e) => {
        let new_portaltempsettingdata = JSON.parse(JSON.stringify(portaltempsettingdata))
        new_portaltempsettingdata['showtrash'] = !new_portaltempsettingdata['showtrash']
        setPortaltempsettingdata(new_portaltempsettingdata)
    }

    const portal_settingcancel = () => {
        setOpensetting(false)
    }

    const setting_portal = <Portalmodal
        animation={device === 'MOBILE' ? "" : "right_left"}
        portaltype={'commonportal'}
        open={opensetting}
        onClose={portal_settingcancel}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalleftcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className='Rsvpexplorer_portal'>
            <div className='Rsvpexplorer_portaltitle'>Setting</div>
            <div className='Rsvpexplorer_portalcontent' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div className="Checklist_dateroot">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', placeContent: 'start', marginBottom: 15 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', placeSelf: 'start', marginBottom: 15 }}>
                                {partialtitle_dom(`Trash`, {})}
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', placeContent: 'start' }}>
                                    <button
                                        onClick={update_portalshowtrash}
                                        value={true}
                                        className={`Budgetsettingroot${portaltempsettingdata['showtrash'] ? '_graphspreadsheet' : ''}`}
                                        style={{ borderRadius: '5px 0px 0px 5px', borderRight: 'none' }}
                                    >
                                        Show
                                    </button>
                                    <button
                                        onClick={update_portalshowtrash}
                                        value={false}
                                        className={`Budgetsettingroot${!portaltempsettingdata['showtrash'] ? '_spreadsheetgraph' : ''}`}
                                        style={{ borderRadius: '0px 5px 5px 0px' }}
                                    >
                                        Hide
                                    </button>
                                </div>
                            </div>

                            {/* 
                            <div style={{ display: 'flex', flexDirection: 'column', placeSelf:'start', marginBottom:15 }}>
                                {partialtitle_dom(`Font Size`, {})}
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', placeContent: 'start' }}>
                                    <button
                                        onClick={update_portalshowtrash}
                                        value={'small'}
                                        className={`Budgetsettingroot${portaltempsettingdata['fontsize'] ? '_graphspreadsheet' : ''}`}
                                        style={{ borderRadius: '5px 0px 0px 5px', borderRight: 'none' }}
                                    >
                                        Small
                                    </button>
                                    <button
                                        onClick={update_portalshowtrash}
                                        value={'medium'}
                                        className={`Budgetsettingroot${portaltempsettingdata['fontsize'] ? '_graphspreadsheet' : ''}`}
                                        style={{ borderRadius: '5px 0px 0px 5px', borderRight: 'none' }}
                                    >
                                        Medium
                                    </button>
                                    <button
                                        onClick={update_portalshowtrash}
                                        value={'large'}
                                        className={`Budgetsettingroot${!portaltempsettingdata['fontsize'] ? '_spreadsheetgraph' : ''}`}
                                        style={{ borderRadius: '0px 5px 5px 0px' }}
                                    >
                                        Large
                                    </button>
                                </div>
                            </div>
                             */}
                        </div>
                    </div>

                </div>
            </div>
            <div className='Rsvpexplorer_portalcontent' style={{ height: '100%' }}>
                <button
                    className='Rsvpexplorer_portalsave'
                    onClick={() => {

                        try {
                            axios({
                                method: 'POST',
                                url: UPDATE_CHECKLISTSETTING,
                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                data: {
                                    update_id,
                                    checklist_id: currchecklist_id,
                                    checklist_settings: portaltempsettingdata
                                }
                            })
                                .then((result) => {
                                    const { message, new_update_id } = result.data
                                    if (message === 'SUCCESS') {
                                        setPortalresult('SUCCESS')
                                        setSetting(JSON.parse(JSON.stringify(portaltempsettingdata)))
                                        setUpdate_id(new_update_id)
                                        resetProtaldata()
                                        portal_settingcancel()
                                    }
                                })
                                .catch((e) => { })
                        }
                        catch (e) {
                        }
                    }} >
                    Update
                </button>
            </div>

        </div>
    </Portalmodal>

    const template_portal = <Portalmodal
        animation={device === 'MOBILE' ? "" : "right_left"}
        portaltype={'commonportal'}
        open={opentemplate}
        onClose={portal_templatecancel}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalleftcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className='Rsvpexplorer_portal'>
            <div className='Rsvpexplorer_portaltitle'>Template</div>
            <div style={{ padding: '0px 10px 10px', color: 'var(--subdark)' }}>Choose from curated, ready-to-use wedding checklists tailored to your needs.</div>
            {templatemod
                ? <div className='Rsvpexplorer_portalcontent' style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className="Checklist_deletetext">By clicking confirm, you acknowledge that your current checklist will be completely wiped out. Archive will remain untouched.</div>
                    <div className="Checklist_deletesectionroot">
                        <button
                            className="Checklist_deletesectioncancel"
                            onClick={portal_templatecfmcancel}
                        >
                            Cancel
                        </button>
                        <button
                            className="Checklist_deletesectioncfm"
                            onClick={portal_templatecfm}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
                : <div className='Rsvpexplorer_portalcontent' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <button className="Checklist_templatebtn" onClick={portal_assigntemplate} value='MONTH_BASED_CHN'>{'Month Based ( Chinese )'} </button>
                    <button className="Checklist_templatebtn" onClick={portal_assigntemplate} value='GENERAL'>{'Item Based ( Chinese )'} </button>
                    <button className="Checklist_templatebtn" onClick={portal_assigntemplate} value='TRADITIONAL_MUSLIM'>{'Month Based ( Muslim )'}</button>
                    <button className="Checklist_templatebtn" onClick={portal_assigntemplate} value='TRADITIONAL_HINDUS'>{'Month Based ( Hindus )'}</button>
                </div>
            }
        </div>
    </Portalmodal>

    const download_portal = <Portalmodal
        animation={device === 'MOBILE' ? "" : "right_left"}
        portaltype={'commonportal'}
        open={downloadportal}
        onClose={() => { setDownloadportal(false) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalleftcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className='Rsvpexplorer_portal'>
            <div className='Rsvpexplorer_portaltitle'>Download</div>
            <div className='Rsvpexplorer_portalcontent' style={{ height: '100%', display: 'flex', flexDirection: 'row' }}>
                <button
                    className="Checklist_downloadbtn"
                    onClick={() => {
                        document.getElementById('DOWNLOAD_TO_DO').click()
                    }}
                    value='GENERAL'
                >
                    Download To Do
                </button>
                <button
                    className="Checklist_downloadbtn"
                    style={{ margin: '0px 10px 0px 10px' }}
                    onClick={() => document.getElementById('DOWNLOAD_ARCHIVE').click()}
                    value='GENERAL'
                >
                    Download Archive
                </button>

                {todocsv}
                {archivecsv}

            </div>
        </div>
    </Portalmodal>

    // top bar start
    const bar_wt_dom = <div style={{ width: '100%', borderBottom: '1px solid #dddddd', padding: 10, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', zIndex: 1040 }}>
        <div style={{ display: 'flex', flexDirection: 'row', fontSize: e_fs, alignItems: 'center', pointerEvents: 'none', userSelect: 'none', opacity: 0 }}></div>
        <button className="planning_backicon" style={{ position: 'absolute', left: 10, }} onClick={() => { setReturnhome(true) }}>{ARROWLEFT_ICON}</button>
        <div style={{ display: 'flex', flexDirection: 'row', fontSize: e_fs, alignItems: 'center' }}></div>
        <div className="ie_previewbtn_root" style={{ position: 'relative', height: 'max-content', margin: 0, right: 0 }}>
            <button className="scedit_savingbtn" style={{ margin: 0, visibility: 'hidden' }} disabled={blocksave} onClick={save}>Save</button>
        </div>
    </div>

    const bar_m_dom = <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'hidden' }}>
        <div style={{ width: '100%', padding: '0px 10px', height: 60, borderBottom: '1px solid #dddddd', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: 'max-content', userSelect: 'none' }}>
            <button className="planning_backicon" onClick={() => { setReturnhome(true) }}>{ARROWLEFT_ICON}</button>
            <button className="scedit_savingbtn" style={{ marginRight: 0, visibility: 'hidden' }} disabled={blocksave} onClick={save}>Save</button>
        </div>
    </div>

    const topbar = { 'WEB': bar_wt_dom, 'TABLET': bar_wt_dom, 'MOBILE': bar_m_dom }[device]

    const onDragend = (result) => {
        if (!result.destination) return;
        if (result.destination.index === result.source.index && result.destination.droppableId === result.source.droppableId) return; // drag and drop on the same pos
        if (!dragsavemode) {
            setDragsavemode(true)
            setClonechecklistobjs(JSON.parse(JSON.stringify(currchecklistobjs)))
        }

        const { source, destination, draggableId } = result
        const mainorsub = draggableId.split(' ')[1]
        var new_checklistobjs = currchecklistobjs.slice()
        if (mainorsub === 'primary') {

            var pri_source_index = source.droppableId.split(' ')[1] // the cat index
            var pri_dest_index = destination.droppableId.split(' ')[1] // the cat index
            var source_index = source.index // the inner index
            var dest_index = destination.index // the inner index

            const temp_subcats = new_checklistobjs[pri_source_index]['items'][source_index]
            const temp_strikeoff = new_checklistobjs[pri_source_index]['strikeoff'][source_index]
            const temp_links = new_checklistobjs[pri_source_index]['links'][source_index]
            const temp_contents = new_checklistobjs[pri_source_index]['contents'][source_index]
            const temp_dueby = new_checklistobjs[pri_source_index]['dueby'][source_index]
            const temp_createdate = new_checklistobjs[pri_source_index]['createdate'][source_index]

            if (pri_source_index === pri_dest_index) {
                //  a switch in a same cat, conduct a swap
                new_checklistobjs[pri_source_index]['items'].splice(source_index, 1)// remove from source
                new_checklistobjs[pri_source_index]['items'].splice(dest_index, 0, temp_subcats) // insert to dest

                new_checklistobjs[pri_source_index]['strikeoff'].splice(source_index, 1)// remove from source
                new_checklistobjs[pri_source_index]['strikeoff'].splice(dest_index, 0, temp_strikeoff) // insert to dest

                new_checklistobjs[pri_source_index]['links'].splice(source_index, 1)// remove from source
                new_checklistobjs[pri_source_index]['links'].splice(dest_index, 0, temp_links) // insert to dest

                new_checklistobjs[pri_source_index]['contents'].splice(source_index, 1)// remove from source
                new_checklistobjs[pri_source_index]['contents'].splice(dest_index, 0, temp_contents) // insert to 

                new_checklistobjs[pri_source_index]['createdate'].splice(source_index, 1)// remove from source
                new_checklistobjs[pri_source_index]['createdate'].splice(dest_index, 0, temp_createdate) // insert to dest

                new_checklistobjs[pri_source_index]['dueby'].splice(source_index, 1)// remove from source
                new_checklistobjs[pri_source_index]['dueby'].splice(dest_index, 0, temp_dueby) // insert to dest
            }
            else {

                // a switch in diff cat, cross pri swap
                new_checklistobjs[pri_dest_index]['items'].splice(dest_index, 0, temp_subcats) // insert to dest
                new_checklistobjs[pri_source_index]['items'].splice(source_index, 1)// remove from source

                new_checklistobjs[pri_dest_index]['strikeoff'].splice(dest_index, 0, temp_strikeoff) // insert to dest
                new_checklistobjs[pri_source_index]['strikeoff'].splice(source_index, 1)// remove from source

                new_checklistobjs[pri_dest_index]['links'].splice(dest_index, 0, temp_links) // insert to dest
                new_checklistobjs[pri_source_index]['links'].splice(source_index, 1)// remove from source

                new_checklistobjs[pri_dest_index]['contents'].splice(dest_index, 0, temp_links) // insert to dest
                new_checklistobjs[pri_source_index]['contents'].splice(source_index, 1)// remove from source

                new_checklistobjs[pri_dest_index]['createdate'].splice(dest_index, 0, temp_createdate) // insert to dest
                new_checklistobjs[pri_source_index]['createdate'].splice(source_index, 1)// remove from source

                new_checklistobjs[pri_dest_index]['dueby'].splice(dest_index, 0, temp_dueby) // insert to dest
                new_checklistobjs[pri_source_index]['dueby'].splice(source_index, 1)// remove from source
            }

            setCurrchecklistobjs(new_checklistobjs)
        }
        else if (mainorsub === 'main') {
            const newest = new_checklistobjs[source.index]
            new_checklistobjs.splice(source.index, 1)
            new_checklistobjs.splice(destination.index, 0, newest)
            setCurrchecklistobjs(new_checklistobjs)
        }
    }

    const Collapsible = (index, items) => {

        const addbtn = <div
            className="Checklist_additem"
            onClick={() => {
                if (!dragsavemode) {
                    setPortalstate('NEW_ITEM')
                    setCurrchecklistobjs_index(index)
                }
            }}
        >
            New Item
        </div>


        return <div className="Checklist_collapsible">
            <Droppable droppableId={'primary ' + index} type="primary">
                {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} >
                        {items && items.map((item, inner_index) => {

                            const checkthis = () => {
                                if (!dragsavemode) {

                                    if (currchecklistobjs[index]['strikeoff'][inner_index]) { // uncheck it and send uncheck to backend
                                        try {
                                            axios({
                                                method: 'POST',
                                                url: UPDATE_CHECKLISTDONE,
                                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                                data: {
                                                    update_id,
                                                    checklist_id: currchecklist_id,
                                                    mainindex: index,
                                                    subindex: inner_index,
                                                    checkstate: false
                                                }
                                            }).then((result) => {
                                                const { message, new_update_id } = result.data
                                                if (message === 'SUCCESS') {
                                                    var local_currchecklistobjs = currchecklistobjs.slice()
                                                    local_currchecklistobjs[index]['strikeoff'][inner_index] = !local_currchecklistobjs[index]['strikeoff'][inner_index]
                                                    setCurrchecklistobjs(local_currchecklistobjs)
                                                    setCurrchecklistobjs_index(index)
                                                    setCurrchecklistobjs_innerindex(inner_index)
                                                    setUpdate_id(new_update_id)
                                                }
                                                else if (message === 'CHECKLISTDOUBLE') {
                                                    refresh()
                                                }
                                            }).catch((e) => { })
                                        }
                                        catch (e) { }

                                    }
                                    else { // check it
                                        setCurrchecklistobjs_index(index)
                                        setCurrchecklistobjs_innerindex(inner_index)
                                        setShowcheckpopup(true) // trigger a popup
                                    }
                                }
                            }

                            const checkstate = currchecklistobjs[index]['strikeoff'][inner_index]

                            const checkbtn = <div className="Checklist_chkbtnroot" onClick={checkthis} >
                                {checkstate
                                    ? iconwrapper(TICK_ICON, { width: itemiconsize, height: itemiconsize, display: 'flex', placeContent: 'center', margin: '0px 15px' })
                                    : iconwrapper(CIRCLE_ICON, { width: itemiconsize, height: itemiconsize, display: 'flex', placeContent: 'center', margin: '0px 15px', color: '#dddddd' })}
                            </div>

                            const contentdiv = <div
                                className="Checklist_content"
                                onClick={() => {
                                    if (!dragsavemode) {
                                        setPortalstate('ITEM');
                                        setPortaltext(currchecklistobjs[index]['items'][inner_index])
                                        setPortalitemcheck(currchecklistobjs[index]['strikeoff'][inner_index])
                                        setPortaldueby(currchecklistobjs[index]['dueby'][inner_index])
                                        setPortalcreatedate(currchecklistobjs[index]['createdate'][inner_index])
                                        setCurrchecklistobjs_innerindex(inner_index)
                                        setCurrchecklistobjs_index(index)
                                    }
                                }}
                                style={{ fontSize: CELL_DEVICE_FS_MAP[device], textDecoration: !checkstate ? '' : 'line-through', backgroundColor: checkstate ? "#d0ffca" : '#ffffff' }}
                            >
                                {currchecklistobjs[index]['items'][inner_index]}
                            </div>

                            const linkdiv = currchecklistobjs[index]['links'][inner_index].length > 0
                                ? <a className="Checklist_link" target="blank" href={currchecklistobjs[index]['links'][inner_index]} >Link</a>
                                : null


                            // const linkdiv = currchecklistobjs[index]['contents'][inner_index].length > 0
                            // ? <a className="Checklist_link" target="blank" href={currchecklistobjs[index]['contents'][inner_index]} >Link</a>
                            // : null

                            const delbtn = !dragsavemode && setting['showtrash']
                                ? <button
                                    className="Checklist_deledititem"
                                    onClick={() => {
                                        if (!dragsavemode) {
                                            setDeletemainindex(index)
                                            setDeletesubindex(inner_index)
                                            setDeletemod(true)
                                        }
                                    }}
                                >
                                    {iconwrapper(TRASH_ICON, { width: itemiconsize, height: itemiconsize, display: 'flex', placeContent: 'center' })}
                                </button>
                                : null

                            return <Draggable
                                key={index + ' primary ' + inner_index} draggableId={index + ' primary ' + inner_index}
                                index={inner_index}
                                type="primary"
                                style={{ border: '1px solid #707070', display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        key={inner_index + 'checklist'}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{ ...getItemStyle(snapshot.isDragging, provided.draggableProps.style), margin: '0px 0px 10px' }}
                                        className='Checklist_collapsible_item'
                                    >
                                        <div style={{ backgroundColor: checkstate ? "#d0ffca" : '#ffffff', display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            {checkbtn}
                                            {contentdiv}
                                        </div>
                                        {checkstate ? null : linkdiv}
                                        {delbtn}
                                    </div>
                                )}
                            </Draggable>
                        })}
                        {items && items.length > 0
                            ? <div >{provided.placeholder}</div>
                            : <div style={{ height: 100 }}>{provided.placeholder}</div>}

                    </div>
                )}
            </Droppable>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div />
                {!dragsavemode ? addbtn : null}
            </div>
        </div>
    }

    const Noncollapsable = (strikeoff) => {
        var true_arr = []
        for (let i = 0; i < strikeoff.length; i++) if (strikeoff[i]) true_arr.push(strikeoff[i])
        return <div className={true_arr.length !== 0 && true_arr.length - strikeoff.length === 0 ? "Checklist_equal" : "Checklist_nonequal"}>
            {true_arr.length + ' / ' + strikeoff.length}
        </div>
    }


    const checklist_dom = !firsttime && currchecklist_id && currchecklist_id.length > 0
        ? <div id='Checklist_main'>
            <div className="Checklistedit_devicebtns">
                <div style={{ display: 'flex', flexDirection: 'row', placeContent: 'space-between', alignItems: 'center', width: '100%', position: 'relative' }}>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                        <button
                            className="itinerary_colorbtn"
                            style={{ position: 'relative', margin: 0 }}
                            onClick={() => {
                                if (!dragsavemode) {
                                    setOpentemplate(true)
                                }
                            }}
                        >
                            {iconwrapper(ITINERARYTEMPLATE_ICON, { width: iconsize, height: iconsize, display: 'flex', placeContent: 'center' })}
                        </button>

                        <button
                            className="itinerary_colorbtn"
                            style={{ position: 'relative', margin: 0 }}
                            onClick={() => {
                                if (currchecklistobjs && currchecklistobjs.length > 0) {
                                    if (expandall) {
                                        let new_currchecklistobjs = currchecklistobjs.slice()
                                        for (let i = 0; i < new_currchecklistobjs.length; i++) {
                                            new_currchecklistobjs[i]['collapse'] = false
                                        }
                                        setCurrchecklistobjs(new_currchecklistobjs)
                                    }
                                    else {
                                        let new_currchecklistobjs = currchecklistobjs.slice()
                                        for (let i = 0; i < new_currchecklistobjs.length; i++) {
                                            new_currchecklistobjs[i]['collapse'] = true
                                        }
                                        setCurrchecklistobjs(new_currchecklistobjs)
                                    }
                                    setExpandall(!expandall)
                                }
                            }}
                        >
                            {iconwrapper(expandall ? COLLAPSE_ICON : UNCOLLAPSE_ICON, { width: iconsize, height: iconsize, display: 'flex', placeContent: 'center' })}
                        </button>

                        <button
                            className="itinerary_colorbtn"
                            style={{ position: 'relative', margin: 0 }}
                            onClick={() => { setOpensetting(true) }}
                        >
                            {iconwrapper(SETTING10_ICON, { width: iconsize, height: iconsize, display: 'flex', placeContent: 'center' })}
                        </button>



                    </div>

                    <button
                        className='scedit_qrbtn'
                        style={{ position: 'relative' }}
                        onClick={() => { setDownloadportal(true) }}
                    >
                        {iconwrapper(DOWNLOAD_ICON, { width: iconsize, height: iconsize })}
                    </button>

                </div>
            </div>

            <div
                className="Checklistedit_title"
                onClick={() => {
                    if (!dragsavemode) {
                        setPortalstate('CHECKLIST')
                        setPortaltext(currchecklistobjs_name)
                    }
                }}
            >
                {currchecklistobjs_name}
            </div>

            <div className="Checklistedit_objsroot" >
                <div style={{ display: 'flex', flexDirection: 'row', padding: '10px 0px 20px 0px', width: '100%', placeContent: 'center' }}>
                    <button
                        className={checklistmode === 'TODO' ? 'Checklistedit_modebtnselected' : 'Checklistedit_modebtn'}
                        style={{ borderRadius: '5px 0px 0px 5px', borderRight: checklistmode === 'TODO' ? '1px solid var(--maindark)' : 'none' }}
                        onClick={() => { if (!dragsavemode) { setChecklistmode('TODO') } }}
                    >
                        To Do
                    </button>
                    <button
                        className={checklistmode === 'ARCHIVE' ? 'Checklistedit_modebtnselected' : 'Checklistedit_modebtn'}
                        style={{ borderRadius: '0px 5px 5px 0px', borderLeft: checklistmode === 'ARCHIVE' ? '1px solid var(--maindark)' : 'none' }}
                        onClick={() => { if (!dragsavemode) { setChecklistmode('ARCHIVE') } }}
                    >
                        Archive
                    </button>
                </div>
            </div>

            {checked > 5 && checklistmode === 'TODO' ? <Progressbar max={100} min={checked} /> : null}

            {checklistmode === 'ARCHIVE'
                ? <div style={{ fontSize: 16, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div>Items in the archive are completed tasks.</div>
                    <div>Only delete and undo actions are allowed.</div>
                </div>
                : null}

            {checklistmode === 'TODO'
                ? <div className="Checklistedit_objsroot">

                    {currchecklistobjs && currchecklistobjs.length > 0
                        ? <DragDropContext onDragEnd={onDragend}>
                            <Droppable droppableId={'main'} type="main">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {currchecklistobjs.map((objitems, index) => {
                                            const { sectionname, collapse, items, strikeoff } = objitems
                                            return <Draggable
                                                key={index + ' main ' + index}
                                                draggableId={index + ' main ' + index}
                                                index={index}
                                                type="main"
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getMainobjStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                    >
                                                        <div
                                                            key={index + 'main'}
                                                            className='Checklist_maindrag'
                                                        >
                                                            <div className={'Checklist_cattitle' + (collapse ? '' : '_withouthover')} >
                                                                <div
                                                                    onClick={() => {
                                                                        if (!dragsavemode) {
                                                                            setPortalstate('SECTION')
                                                                            setPortaltext(sectionname)
                                                                            setCurrchecklistobjs_index(index)
                                                                        }
                                                                    }}
                                                                    style={{ padding: 5, width: '100%', whiteSpace: 'nowrap', border: 'none', outline: 'none', fontSize: VT_DEVICE_FS_MAP[device] }}
                                                                >
                                                                    {sectionname}
                                                                </div>
                                                                <div
                                                                    style={{ padding: 5, display: 'flex', alignItems: 'center' }}
                                                                    onClick={() => {
                                                                        let curr_checklistobjs = currchecklistobjs.slice()
                                                                        curr_checklistobjs[index]['collapse'] = !collapse
                                                                        setCurrchecklistobjs(curr_checklistobjs)
                                                                    }}>
                                                                    {collapse
                                                                        ? iconwrapper(LINEDOWNARROW_ICON, { width: 20, height: 20, display: 'flex' })
                                                                        : iconwrapper(LINERIGHTARROW_ICON, { width: 20, height: 20, display: 'flex' })}
                                                                </div>
                                                            </div>
                                                            {EMPTYSPACE}
                                                            {collapse ? Collapsible(index, items) : Noncollapsable(strikeoff)}
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        : null}

                    {!dragsavemode
                        ? <button
                            style={{ width: '100%', padding: '10px 0px', border: '1px solid var(--maindark)', marginTop: 10, color: '#ffffff', backgroundColor: 'var(--maindark)', borderRadius: 5, fontSize: 26 }}
                            onClick={() => {
                                if (!dragsavemode) {
                                    setPortalstate('NEW_SEC')
                                    setPortaltext('')
                                }
                            }}
                        >
                            +
                        </button>
                        : null}

                    <div style={{ height: 200 }} />

                </div>
                : <div className="Checklistedit_objsroot">
                    {[...id_sortedarchive_map.keys()].map((sectionid, i) => {

                        let sectionitems = id_sortedarchive_map.get(sectionid)
                        let sectionname = id_sortedarchivename_map[sectionid]

                        return <div key={i + 'archiveroot'} className='Checklist_archiveroot' >

                            <div className={'Checklist_archivecattitle'} >
                                <div style={{ padding: 5, width: '100%', whiteSpace: 'nowrap', border: 'none', outline: 'none', fontSize: VT_DEVICE_FS_MAP[device] }} >
                                    {sectionname}
                                </div>

                            </div>

                            <div className='Checklist_archiveitemroot' >
                                {sectionitems.map((sectionitem, j) => {
                                    const { content, createdate, dueby, item, link, sectionid, archive_index } = sectionitem // collapse,sectionname,  strikeoff
                                    return <div key={sectionid + j} style={{ display: 'flex', flexDirection: 'row', width: '100%', borderTop: '1px solid #d3d3d3', }}>

                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', backgroundColor: '#d0ffca', }}>
                                            <div
                                                className="Checklist_chkbtnroot"
                                                style={{ backgroundColor: '#d0ffca', cursor: 'pointer' }}
                                                onClick={() => {

                                                    let new_currchecklistobjs = JSON.parse(JSON.stringify(currchecklistobjs.slice()));

                                                    // add to checklistobjs
                                                    // remove from archive
                                                    // rerun the useeffect to redeine id_sortedarchive_map and id_sortedarchivename_map

                                                    let sectionidexist = false // to indicate section id havent deleted by user
                                                    for (let i = 0; i < currchecklistobjs.length; i++) {
                                                        if (new_currchecklistobjs[i].sectionid === sectionid) {
                                                            // push item to this section
                                                            sectionidexist = true
                                                            new_currchecklistobjs[i]['strikeoff'].push(false)
                                                            new_currchecklistobjs[i]['dueby'].push(dueby)
                                                            new_currchecklistobjs[i]['items'].push(item)
                                                            new_currchecklistobjs[i]['links'].push(link)
                                                            new_currchecklistobjs[i]['contents'].push(content)
                                                            new_currchecklistobjs[i]['createdate'].push(createdate)
                                                            break
                                                        }

                                                    }

                                                    if (!sectionidexist) {
                                                        // section id has already deleted

                                                        new_currchecklistobjs[new_currchecklistobjs.length - 1]['strikeoff'].push(false)
                                                        new_currchecklistobjs[new_currchecklistobjs.length - 1]['dueby'].push(dueby)
                                                        new_currchecklistobjs[new_currchecklistobjs.length - 1]['items'].push(item)
                                                        new_currchecklistobjs[new_currchecklistobjs.length - 1]['links'].push(link)
                                                        new_currchecklistobjs[new_currchecklistobjs.length - 1]['contents'].push(content)
                                                        new_currchecklistobjs[new_currchecklistobjs.length - 1]['createdate'].push(createdate)

                                                    }

                                                    let new_archive = JSON.parse(JSON.stringify(archive.slice()));

                                                    new_archive.splice(archive_index, 1)

                                                    try {
                                                        axios({
                                                            method: 'POST',
                                                            url: UPDATE_REVERTARCHIVEITEM,
                                                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                                            data: {
                                                                update_id: update_id,
                                                                checklist_id: currchecklist_id,
                                                                archive: new_archive,
                                                                checklistobjs: new_currchecklistobjs
                                                            }
                                                        }).then((result) => {
                                                            const { message, new_update_id } = result.data
                                                            if (message === 'SUCCESS') {
                                                                setCurrchecklistobjs(new_currchecklistobjs)
                                                                setUpdate_id(new_update_id)
                                                                setShowcheckpopup(false)
                                                                setArchive(new_archive)
                                                            }
                                                            else if (message === 'CHECKLISTDOUBLE') {
                                                                refresh()
                                                            }

                                                        }).catch((e) => { })
                                                    }
                                                    catch (e) {
                                                    }
                                                }}
                                            >
                                                {iconwrapper(CROSS_ICON, { width: itemiconsize, height: itemiconsize, display: 'flex', placeContent: 'center', margin: '0px 15px' })}
                                            </div>

                                            <div className="Checklist_content" style={{ padding: '0px 10px', fontSize: 16 }}  >
                                                <div>{item}</div>
                                            </div>
                                        </div>

                                        <button
                                            className="Checklist_deledititem"
                                            onClick={() => {
                                                let new_archive = JSON.parse(JSON.stringify(archive.slice()));
                                                new_archive.splice(archive_index, 1)
                                                try {
                                                    axios({
                                                        method: 'POST',
                                                        url: UPDATE_DELETEARCHIVEITEM,
                                                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                                        data: {
                                                            update_id: update_id,
                                                            checklist_id: currchecklist_id,
                                                            archive: new_archive,
                                                        }
                                                    }).then((result) => {
                                                        const { message, new_update_id } = result.data
                                                        if (message === 'SUCCESS') {
                                                            setUpdate_id(new_update_id)
                                                            setShowcheckpopup(false)
                                                            setArchive(new_archive)
                                                        }
                                                        else if (message === 'CHECKLISTDOUBLE') {
                                                            refresh()
                                                        }

                                                    }).catch((e) => { })
                                                }
                                                catch (e) {
                                                }
                                            }}
                                        >
                                            {iconwrapper(TRASH_ICON, { width: itemiconsize, height: itemiconsize, display: 'flex', placeContent: 'center' })}
                                        </button>

                                    </div>
                                })}
                            </div>

                        </div>


                    })}
                </div>}
        </div>
        : null


    const dragsave_dom = dragsavemode
        ? <div className='Rsvpqnexplorer_swappedsavedroot'>

            <button
                className="Rsvpqnexplorer_swappedcancel"
                onClick={() => {
                    setPortalstate('')
                    setCurrchecklistobjs(JSON.parse(JSON.stringify(clonechecklistobjs.slice())))
                    setDragsavemode(false)
                }}
            >
                Cancel
            </button>
            <button
                className="Rsvpqnexplorer_swappedsavedbtn"
                onClick={() => {
                    axios({
                        method: 'POST',
                        url: UPDATE_CHECKLISTSWAP,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                        data: { checklist_id: currchecklist_id, checklistobjs: currchecklistobjs, update_id }
                    })
                        .then((checklist) => {
                            const { message, new_update_id } = checklist.data
                            if (message === 'SUCCESS') {
                                setPortalstate('')
                                setDragsavemode(false)
                                setClonechecklistobjs([])
                                setUpdate_id(new_update_id)
                            }
                            else if (message === 'CHECKLISTDOUBLE') {
                                refresh()
                            }

                        }).catch((e) => {
                            setCurrchecklistobjs(clonechecklistobjs.slice())

                        })
                }}
            >
                Save
            </button>
        </div>
        : null

    const checkorarchieve_mod = showcheckpopup // bear
        ? <Modcfm
            txt={<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div className='igl_statustitle'>{`Update Status for item`}</div>
                <div
                    className='igl_statusbtnorigin'
                    onClick={() => setCheckpopupstate('DONE')}>
                    <button className={`igl_statusbtnroot ${checkpopupstate === 'DONE' ? 'igl_statusbtnroot_highlight' : ''}`}>Done</button>
                    <div className={`igl_statusexplain ${checkpopupstate === 'DONE' ? 'igl_statusexplain_highlight' : ''}`}>Mark item as checked.</div>
                </div>
                <div
                    className='igl_statusbtnorigin'
                    onClick={() => setCheckpopupstate('ARCHIVE')}>
                    <button className={`igl_statusbtnroot ${checkpopupstate === 'ARCHIVE' ? 'igl_statusbtnroot_highlight' : ''}`}>{`Done & Archive`}</button>
                    <div className={`igl_statusexplain ${checkpopupstate === 'ARCHIVE' ? 'igl_statusexplain_highlight' : ''}`}>Mark item as checked and archive it.</div>
                </div>
            </div>}
            showcrossonly={false}
            showtickonly={false}
            showallonly={true}
            onNo={() => {
                setShowcheckpopup(false)
                setCheckpopupstate('DONE') // default state
            }}
            onOk={() => {
                if (checkpopupstate === 'DONE') { // DONE
                    try {
                        axios({
                            method: 'POST',
                            url: UPDATE_CHECKLISTDONE,
                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                            data: {
                                update_id,
                                checklist_id: currchecklist_id,
                                mainindex: currchecklistobjs_index,
                                subindex: currchecklistobjs_innerindex,
                                checkstate: true
                            }
                        }).then((result) => {
                            const { message, new_update_id } = result.data
                            if (message === 'SUCCESS') {
                                var local_currchecklistobjs = currchecklistobjs.slice()
                                local_currchecklistobjs[currchecklistobjs_index]['strikeoff'][currchecklistobjs_innerindex] = true
                                setCurrchecklistobjs(local_currchecklistobjs)
                                setShowcheckpopup(false)
                                setUpdate_id(new_update_id)
                            }
                            else if (message === 'CHECKLISTDOUBLE') {
                                refresh()
                            }
                        }).catch((e) => { })
                    }
                    catch (e) { }

                }
                else if (checkpopupstate === 'ARCHIVE') { // ARCHIVE
                    try {
                        axios({
                            method: 'POST',
                            url: UPDATE_CHECKLISTARCHIVE,
                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                            data: {
                                checklist_id: currchecklist_id,
                                mainindex: currchecklistobjs_index,
                                subindex: currchecklistobjs_innerindex,
                                update_id: update_id
                            }
                        }).then((result) => {
                            const { message, new_update_id } = result.data
                            if (message === 'SUCCESS') {
                                // ARCHIVE:
                                // add to archive 
                                // remove from obj

                                let local_archive = JSON.parse(JSON.stringify(archive))

                                local_archive.push({
                                    sectionname: currchecklistobjs[currchecklistobjs_index]['sectionname'],
                                    sectionid: currchecklistobjs[currchecklistobjs_index]['sectionid'],
                                    item: currchecklistobjs[currchecklistobjs_index]['items'][currchecklistobjs_innerindex],
                                    strikeoff: true, // to archive, need to set as true
                                    link: currchecklistobjs[currchecklistobjs_index]['links'][currchecklistobjs_innerindex],
                                    content: currchecklistobjs[currchecklistobjs_index]['contents'][currchecklistobjs_innerindex],
                                    dueby: currchecklistobjs[currchecklistobjs_index]['dueby'][currchecklistobjs_innerindex],
                                    createdate: currchecklistobjs[currchecklistobjs_index]['createdate'][currchecklistobjs_innerindex],
                                    collapse: false
                                })

                                setArchive(local_archive)

                                let local_currchecklistobjs = JSON.parse(JSON.stringify(currchecklistobjs))
                                local_currchecklistobjs[currchecklistobjs_index]['items'].splice(currchecklistobjs_innerindex, 1)
                                local_currchecklistobjs[currchecklistobjs_index]['strikeoff'].splice(currchecklistobjs_innerindex, 1)
                                local_currchecklistobjs[currchecklistobjs_index]['links'].splice(currchecklistobjs_innerindex, 1)
                                local_currchecklistobjs[currchecklistobjs_index]['contents'].splice(currchecklistobjs_innerindex, 1)
                                local_currchecklistobjs[currchecklistobjs_index]['dueby'].splice(currchecklistobjs_innerindex, 1)
                                local_currchecklistobjs[currchecklistobjs_index]['createdate'].splice(currchecklistobjs_innerindex, 1)
                                setCurrchecklistobjs(local_currchecklistobjs)

                                setUpdate_id(new_update_id)

                                setShowcheckpopup(false)
                            }
                            else if (message === 'CHECKLISTDOUBLE') {
                                refresh()
                            }

                        }).catch((e) => { })
                    }
                    catch (e) {
                    }
                }

            }}
        />
        : null

    const itemdelete_mod = deletemod
        ? <Modcfm
            onHide={() => { setDeletemod(false) }}
            onOk={() => {
                try {
                    axios({
                        method: 'POST',
                        url: DELETE_CHECKLISTITEM,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                        data: { checklist_id: currchecklist_id, mainindex: deletemainindex, subindex: deletesubindex, update_id }
                    }).then((result) => {
                        const { message, new_update_id } = result.data
                        if (message === 'SUCCESS') {
                            var local_currchecklistobjs = currchecklistobjs.slice()
                            local_currchecklistobjs[deletemainindex]['items'].splice(deletesubindex, 1)
                            local_currchecklistobjs[deletemainindex]['strikeoff'].splice(deletesubindex, 1)
                            local_currchecklistobjs[deletemainindex]['links'].splice(deletesubindex, 1)
                            local_currchecklistobjs[deletemainindex]['contents'].splice(deletesubindex, 1)
                            local_currchecklistobjs[deletemainindex]['dueby'].splice(deletesubindex, 1)
                            local_currchecklistobjs[deletemainindex]['createdate'].splice(deletesubindex, 1)
                            setCurrchecklistobjs(local_currchecklistobjs)
                            setUpdate_id(new_update_id)
                            setDeletemod(false)
                            setDeletemainindex(-1)
                            setDeletesubindex(-1)
                        }
                        else if (message === 'CHECKLISTDOUBLE') {
                            refresh()
                        }
                        else {
                            refresh()
                        }

                    }).catch((e) => {
                        refresh()
                    })
                }
                catch (e) { }
            }}
            onNo={() => { setDeletemod(false) }}
            txt={'Once you delete this, there is no going back. Please be certain.'}
        />
        : null

    return !loaded
        ? <Loading ht={'100%'} />
        : <div id="itineraryedit_root" >
            {topbar}
            {home_mod}
            {checklist_dom}
            {dragsave_dom}
            {edit_portal}
            {setting_portal}
            {template_portal}
            {download_portal}
            {checkorarchieve_mod}
            {itemdelete_mod}
            {/* <div ref={btmref} style={{ height: 120, width: 1 }} /> */}
        </div>
}
export default Checklistedit