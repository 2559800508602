import React from 'react'
import './Notfound.css'

function Notfound() {
    return <div className='Notfound_root'>
        <div className='Notfound_404'>404</div>
        <div>Page Not Found</div>
    </div>
}

export default Notfound