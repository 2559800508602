import React, { Component } from 'react'

// Renderreviews import
import { GENERAL_GREY, EMPTY, RECAPTCHA_KEY, MAIN_PINK_COLOR, STARTUP_NAME, READABLE_VENDORTYPE_MAP } from '../../../../config/constants'
import { USER_REVIEWING_VENDOR, READ_REVIEW_OF_A_VENDOR } from '../../../../config/api'
import axios from 'axios'
import { iconwrapper, Retrieve_personal_info } from '../../../../config/commonfunctions';
import { BUTTONSTYLE } from '../../../../config/cssconstants';
import { Reviewcard, Portalmodal } from '../../../../components'

// Ratingstar imports
import { STAR_ICON } from '../../../../config/constants'
import refresh from '../../../../components/refresh';

// recaptcha
import ReCAPTCHA from "react-google-recaptcha";
import { subtitle } from '../Commonfunction';
import { PENCILTHICK_ICON, TICK_ICON } from '../../../../config/svgicons';
import { input_dom, multiplechoice_dom, textarea_dom } from '../../../Main/Planning/Invitationmaker/components/Commonelements/Commonelements';

const reviewtitle = 'Reviews given by customers and partners of the vendors.'

const STAR_DEFAULT_BGCOLOR = GENERAL_GREY
const STAR_SELECTED_BGCOLOR = 'rgb(231, 190, 62)'

class Ratingstar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clicked: false,
            stars_style: [{ color: STAR_DEFAULT_BGCOLOR }, { color: STAR_DEFAULT_BGCOLOR }, { color: STAR_DEFAULT_BGCOLOR }, { color: STAR_DEFAULT_BGCOLOR }, { color: STAR_DEFAULT_BGCOLOR },],
        }
    }

    onMouseEnter = (star_position) => {
        if (this.state.clicked === false) {
            var stars_style = this.state.stars_style
            var total_stars = stars_style.length
            for (let i = 0; i < total_stars; i++) {
                for (let j = 0; j < star_position + 1; j++) {
                    stars_style[j].color = STAR_SELECTED_BGCOLOR
                    this.setState({ stars_style })
                }
            }
        }
    }

    onMouseLeave = () => {
        if (this.state.clicked === false) {
            var stars_style = this.state.stars_style
            var total_stars = stars_style.length
            for (let i = 0; i < total_stars; i++) {
                stars_style[i].color = STAR_DEFAULT_BGCOLOR
                this.setState({ stars_style })
            }
        }
    }

    onClick = (star_position) => {
        const { clicked, stars_style } = this.state
        if (clicked === true) {
            this.setState({
                stars_style: [{ color: STAR_DEFAULT_BGCOLOR }, { color: STAR_DEFAULT_BGCOLOR }, { color: STAR_DEFAULT_BGCOLOR }, { color: STAR_DEFAULT_BGCOLOR }, { color: STAR_DEFAULT_BGCOLOR },],
                clicked: false,
            })
        }
        else if (clicked === false) {
            var total_stars = stars_style.length
            for (let i = 0; i < total_stars; i++) {
                for (let j = 0; j < star_position + 1; j++) {
                    stars_style[j].color = STAR_SELECTED_BGCOLOR
                }
            }
            this.setState({
                stars_style,
                clicked: true,
            }, () => {
                //count score
                var score = 0
                for (let i = 0; i < stars_style.length; i++) {
                    if (stars_style[i].color === STAR_SELECTED_BGCOLOR) {
                        score = score + 1
                    }
                }
                this.props.setScore(score)
            })
        }

    }

    render() {
        const { stars_style } = this.state
        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', float: 'right', }}>
                <span style={{ width: '100%', padding: '0px 10px', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: 10 }}>
                        {stars_style.map((star_style, index) => {
                            return (
                                <button
                                    key={'star' + index}
                                    style={{ display: 'flex', justifyContent: 'center', color: star_style.color, padding: '0px 2px 0px 2px', outline: 'none', backgroundColor: 'transparent', border: 'none' }}
                                    onMouseEnter={() => { this.onMouseEnter(index) }}
                                    onMouseLeave={() => { this.onMouseLeave() }}
                                    onClick={() => { this.onClick(index) }}
                                >
                                    {STAR_ICON}
                                </button>
                            )
                        })}
                    </div>
                </span>
            </div>

        )
    }
}

class Renderreviews extends Component {

    constructor(props) {
        super(props)
        this.state = {

            screenwidth: 0,
            screenheight: 0,

            // modal stuff
            score: 0,
            message: EMPTY,
            name: EMPTY,
            role: 'Couple', // Couple, Guest, Vendor

            modal_review: false, // for dialog
            occasion: EMPTY,
            google_source: '',
            fb_source: '',

            // existing review stuff
            data: [],

            acct_type: EMPTY,
            children: EMPTY,
            displayname: EMPTY,
            owner: EMPTY,
            review_ids: [],
            vendortypeinterface_id: EMPTY,

            reviewcards: [],

            captchacode: EMPTY,// repcaptcha response
            showsubmit: false, // once repcaptcha is success, showsubmit button will be enable

            showcaptcha: false,

            submitsuccess: false,

            vendortype: EMPTY,
            secondaryvendortype: EMPTY,
            reviewvendortype: EMPTY,

            venuerating0: 0,
            venuerating1: 0,
            venuerating2: 0,

            date: EMPTY
        }
        this.onRecaptchachange = this.onRecaptchachange.bind(this);

    }

    componentDidMount = async () => {
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
        const { acct_type, children, displayname, owner, review_ids, vendortypeinterface_id, vendortype, secondaryvendortype } = this.props
        this.setState({
            acct_type,
            children,
            displayname,
            owner,
            review_ids,
            vendortypeinterface_id,
            vendortype: vendortype,
            secondaryvendortype: secondaryvendortype.length > 0 ? secondaryvendortype : EMPTY,
            reviewvendortype: vendortype
        }, () => {
            this.retrieve_data_from()
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
        document.body.style.overflowY = ''
    }

    updateWindowDimensions = () => {
        this.setState({ screenwidth: window.innerWidth, screenheight: window.innerHeight });
    }

    retrieve_data_from = () => {
        const { review_ids } = this.state
        let options = {
            method: 'POST',
            url: READ_REVIEW_OF_A_VENDOR,
            data: { review_ids }
        };
        axios(options).then((result) => {
            const { status } = result;
            const { error, data } = result.data;
            if (error === false && status === 200) {
                this.setState({
                    data: data
                }, () => {
                    var reviewcards = []
                    for (let i = 0; i < data.length; i++) {
                        reviewcards.push(
                            <div key={'reviews_' + i} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Reviewcard
                                    index={i}
                                    obj={data[i]}
                                    profileimg={data[i].reviewer.link}
                                    rating={data[i].rating}
                                    name={data[i].reviewer.name}
                                    vendortype={data[i]}
                                    maxwidth={false}
                                />
                            </div>
                        )
                    }
                    this.setState({ reviewcards: reviewcards })
                });
            }
        }).catch(e => {
            refresh()
        });
    }

    setScore = (score) => {
        this.setState({ score: score })
    }

    setVenuerating0 = (score) => {
        this.setState({ venuerating0: score })
    }

    setVenuerating1 = (score) => {
        this.setState({ venuerating1: score })
    }

    setVenuerating2 = (score) => {
        this.setState({ venuerating2: score })
    }

    onClickSubmit = async (captcharesponse) => {
        if (captcharesponse) {
            const { score, message, occasion, vendortypeinterface_id, google_source, fb_source, review_ids, name, vendortype, venuerating0, venuerating1, venuerating2, date } = this.state
            const { token, _id, acct_type } = Retrieve_personal_info()

            var sources = [
                { key: 'facebook', link: fb_source },
                { key: "google", link: google_source },
            ]

            sources.push({ key: STARTUP_NAME.toLowerCase(), link: '' })

            let options = {
                method: 'POST',
                url: acct_type === 'USER' ? USER_REVIEWING_VENDOR : EMPTY,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: {
                    _id, score, message, name, occasion, reviewee_vti_id: vendortypeinterface_id, reviewer_accttype: acct_type,
                    captchacode: captcharesponse, sources: sources,
                    vendortype, venuerating0, venuerating1, venuerating2,
                    date: date.length > 0 ? new Date(date).toISOString() : null,
                    reviewer_role: this.state.role
                }
            }

            axios(options).then((result) => {
                const { status } = result
                const { error, newreview_id } = result.data
                if (error === false && status === 200) {
                    this.setState({
                        review_ids: [...review_ids, newreview_id],
                    }, () => {
                        this.retrieve_data_from()
                        this.setState({
                            showcaptcha: false,
                            submitsuccess: true,
                            score: 0,
                            message: EMPTY,
                            name: EMPTY,
                            role: 'Couple',
                            occasion: EMPTY,
                            google_source: EMPTY,
                            fb_source: EMPTY,

                        })
                    })
                }
            }).catch((e) => { })
        }
    }

    onRecaptchachange = (response) => {
        if (response) {
            this.setState({ captchacode: response, showsubmit: true })
        }
    }

    RenderPencilButton = () => {
        const { acct_type } = this.state
        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: 20 }}>
                {/* {acct_type == 'USER' || acct_type == 'VENDOR' ? */}
                {acct_type === 'USER'
                    ? <button
                        onClick={() => {
                            this.setState({ modal_review: true }, () => {
                                document.body.style.overflowY = 'hidden'

                            })
                        }}
                        style={{ display: 'flex', padding: 20, height: 'fit-content', color: 'var(--maindark)', outline: 'none', backgroundColor: 'white', borderRadius: '100px', border: '1px solid var(--subgrey)' }}>
                        {iconwrapper(PENCILTHICK_ICON, { width: 20, height: 20, display: 'flex', alignItems: 'center' })}
                    </button>
                    : <button
                        disabled
                        style={{ display: 'flex', padding: 20, height: 'fit-content', color: 'var(--maindark)', outline: 'none', backgroundColor: 'white', borderRadius: '100px', border: '1px solid var(--subgrey)' }}>
                        {iconwrapper(PENCILTHICK_ICON, { width: 20, height: 20, display: 'flex', alignItems: 'center' })}
                    </button>
                }
            </div>
        )
    }

    RenderSubmitReview = () => {
        const { showcaptcha } = this.state
        return showcaptcha
            ? <div style={{ display: 'flex', placeContent: 'flex-end', margin: 10 }}>
                <ReCAPTCHA ref={(el) => { this.recaptcha = el }} sitekey={RECAPTCHA_KEY} onChange={this.onClickSubmit} />
            </div>
            : <button className='Rsvpexplorer_portalsave' style={{ position: 'relative' }} onClick={() => { this.setState({ showcaptcha: true }) }} > Submit </button>


    }

    RenderDisabledSubmitReview = () => {
        return (
            <button disabled style={{ ...BUTTONSTYLE, float: 'right', border: 'none', backgroundColor: 'var(--maindark)', color: 'white' }}>Submit</button>
        )
    }


    render() {
        const RenderDialog = () => {
            const { owner, modal_review, screenwidth, screenheight } = this.state
            const { device } = this.props

            const portalmidcss_web = {
                width: 600,
                // height: 720,
                // top: 'calc( 50% - 360px )',
                backgroundColor: 'white',
                left: 0, right: 0,
                margin: 'auto',
                transform: 'none',
                top: 0,
                height: '100%',
                overflowY: 'scroll',
            }

            const portalmidcss_mobile = {
                width: '100%',
                height: '100%',
                top: 0,
                backgroundColor: 'white',
                left: 0, right: 0,
                margin: 'auto',
                transform: 'none',
                overflowX: 'scroll'

            }

            const portalmidcsswtlarger_mobile = {
                width: '100%',
                height: '100%',
                top: 0,
                backgroundColor: 'white',
                left: 0, right: 0,
                margin: 'auto',
                transform: 'none',
                overflowX: 'scroll'
            }


            return <Portalmodal
                portaltype={'commonportal'}
                open={modal_review}
                onClose={() => {
                    document.body.style.overflow = ''
                    this.setState({ modal_review: false, showsubmit: false })
                }}
                floatingclose={false}
                backdropvisible={true}
                modalstyle={(device === 'WEB' || device === 'TABLET') ? portalmidcss_web : screenwidth > screenheight ? portalmidcsswtlarger_mobile : portalmidcss_mobile}
                closestyle={{ right: 0 }}
                floatingclosecolor='#333333'
            >
                {this.state.submitsuccess
                    ? <div className='Portalmodal_vtroot' style={{ fontFamily: 'Lato', overflowX: 'hidden', position: 'relative', height: '100%', overflow: 'hidden' }} >
                        <div className='Rsvpexplorer_portaltitle' style={{ position: 'absolute', top: 0 }}>Review</div>
                        <button className='igl_successportal' style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', border: 'none', placeItems: 'center' }} onClick={() => { this.setState({ modal_review: false }) }}>
                            {iconwrapper(TICK_ICON, { width: 48, height: 48, color: 'rgb(75, 181, 67)', display: 'flex', })}
                            <div className='igl_successtxt'>Thank you for submitting your review. It will be updated shortly.</div>
                        </button>
                    </div>
                    : <div className='Portalmodal_vtroot' style={{ fontFamily: 'Lato', overflowX: 'hidden', position: 'relative', height: 'auto', overflow: 'hidden' }} >
                        <div className='Rsvpexplorer_portaltitle' style={{ position: 'absolute', top: 0 }}>Review</div>
                        {input_dom(
                            'Name',
                            ``,
                            this.state.name,
                            (e) => {
                                this.setState({ name: e.target.value })
                            },
                            'name_id',
                            '',
                            null,
                            null,
                            {}
                        )}

                        {multiplechoice_dom(
                            'Vendor Type',
                            (this.state.secondaryvendortype && this.state.secondaryvendortype.length > 0)
                                ? [READABLE_VENDORTYPE_MAP[this.state.vendortype], READABLE_VENDORTYPE_MAP[this.state.secondaryvendortype]]
                                : [READABLE_VENDORTYPE_MAP[this.state.vendortype]],
                            (e) => {
                                this.setState({ reviewvendortype: e.target.value })
                            },
                            'multiplechoice_dom_vt',
                            {},
                            this.state.reviewvendortype,
                            null,
                            null,
                            { width: 'calc( 100% - 20px)' },
                            false
                        )}

                        {this.state.reviewvendortype === 'Venue'
                            ? <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 10px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', margin: '0px' }}>
                                    <div>How was the venue?</div>
                                    <Ratingstar setScore={this.setVenuerating0} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', margin: '0px ' }}>
                                    <div>How was the service?</div>
                                    <Ratingstar setScore={this.setVenuerating1} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', margin: '0px 0px 10px 0px' }}>
                                    <div>How was the food?</div>
                                    <Ratingstar setScore={this.setVenuerating2} />
                                </div>

                            </div>
                            : <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 10px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', padding: '0px' }}>
                                    <div>How was the service?</div>
                                    <Ratingstar setScore={this.setScore} />
                                </div>
                            </div>}

                        {multiplechoice_dom(
                            'Couple or Guest?',
                            ['Couple', 'Guest', 'Vendor', 'Others'],
                            (e) => {
                                console.log(e.target.value)
                                this.setState({ role: e.target.value })
                            },
                            'multiplechoice_dom_coupleorguest',
                            {},
                            this.state.role,
                            null,
                            null,
                            { width: 'calc( 100% - 20px)' },
                            false
                        )}


                        {input_dom(
                            'Type of occasion',
                            ``,
                            this.state.occasion,
                            (e) => {
                                this.setState({ occasion: e.target.value })
                            },
                            'ocassion_id',
                            '',
                            null,
                            null,
                            {}
                        )}

                        <div className='ig_lblinputroot' >
                            <div>Date</div>
                            <input
                                type="date"
                                value={this.state.date}
                                style={{ height: 40, borderRadius: 5, border: '1px solid #ccc', boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)' }}
                                required
                                onChange={(e) => {
                                    this.setState({ date: e.target.value })
                                }}
                            />
                        </div>

                        {textarea_dom(
                            'Review',
                            ``,
                            this.state.message,
                            (e) => {
                                this.setState({ message: e.target.value })
                            },
                            'reviewtxt_id',
                            '',
                            null,
                            null,
                            {}
                        )}

                        {input_dom(
                            'Google Review Link',
                            ``,
                            this.state.google_source,
                            (e) => {
                                this.setState({ google_source: e.target.value })
                            },
                            'google_sourceid',
                            '',
                            null,
                            null,
                            {}
                        )}

                        {input_dom(
                            'Facebook Review Link',
                            ``,
                            this.state.fb_source,
                            (e) => {
                                this.setState({ fb_source: e.target.value })
                            },
                            'fb_id',
                            '',
                            null,
                            null,
                            {}
                        )}

                        {owner ? this.RenderDisabledSubmitReview() : this.RenderSubmitReview()}
                    </div>}


            </Portalmodal>
        }

        return (
            // <div style={{ display: 'flex', padding: '15px 0px 100px', flexWrap: 'wrap', justifyContent: 'center', flexDirection: 'column' }}>
            <div style={{ display: 'flex', padding: '30px 0px 40px', flexWrap: 'wrap', justifyContent: 'center', flexDirection: 'column' }}>
                {subtitle('Review', reviewtitle)}
                <div style={{ padding: '0px 10px', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                    <div style={{ margin: '20px 0px 10px 0px' }}>Have you collaborated with this vendor?</div>
                    <button
                        style={{ ...BUTTONSTYLE, color: 'white', backgroundColor: 'var(--maindark)', height: 50, fontWeight: 'normal', margin: '0px 10px 20px 10px', }}
                        onClick={() => {
                            this.setState({
                                modal_review: true,
                                captchacode: EMPTY,// repcaptcha response

                                score: 0,
                                message: EMPTY,
                                name: EMPTY,
                                role: 'Couple', // Couple, Guest, Vendor

                                name: EMPTY,
                                occasion: EMPTY,
                                google_source: '',
                                fb_source: '',
                                showsubmit: false, // once repcaptcha is success, showsubmit button will be enable
                                showcaptcha: false,
                                submitsuccess: false,
                                reviewvendortype: EMPTY,
                                venuerating0: 0,
                                venuerating1: 0,
                                venuerating2: 0,
                                date: EMPTY
                            },
                                () => {
                                    document.body.style.overflowY = 'hidden'
                                })
                        }}
                    >
                        Write A Review
                    </button>
                    {/* {this.state.vendortype ? READABLE_VENDORTYPE_MAP[this.state.vendortype] : ''} */}
                    {/* {this.state.secondaryvendortype ? ' / ' + READABLE_VENDORTYPE_MAP[this.state.secondaryvendortype] : ''} */}
                    <div style={{ display: 'flex', flexDirection: 'column', color: GENERAL_GREY, width: '100%', justifyContent: 'center' }}>{this.state.reviewcards}</div>
                </div>
                {RenderDialog()}
            </div>
        )
    }
}

export default Renderreviews