import { useState, useEffect } from "react";
import axios from "axios";
import refresh from "../components/refresh";

// USAGE :     
// const data = useEndpoint({
//   method: 'POST',
//   url: READ_REVIEW_OF_A_VENDOR,
//   headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
//   data: { _id, token }
// })

export default function useEndpoint(req) {
  const [res, setRes] = useState({
    data: null,
    complete: false,
    pending: false,
    error: false
  });
  useEffect(
    () => {
      setRes({
        data: null,
        pending: true,
        error: false,
        complete: false
      });
      axios(req)
        .then(res => {
          setRes({
            data: res.data,
            pending: false,
            error: false,
            complete: true
          })
        }
        )
        .catch((e) => {
       
          setRes({
            data: null,
            pending: false,
            error: true,
            complete: true
          })
          // The logout function
          refresh()
          // The logout function
        }
        );
    },
    [req.url]
  );
  return res;
}