import React, { useState } from 'react'
import { Checkdevice } from './../Checkdevice'
import './Ticket.css'

// const SPACE_MAP = {
//     'WEB': <div style={{ display: 'flex', flexDirection: 'column' }}>{EMPTYSPACE}</div>,
//     'MOBILE': <div style={{ display: 'flex', flexDirection: 'column' }}>{EMPTYSPACE}{EMPTYSPACE}{EMPTYSPACE}</div>,
//     'TABLET': <div style={{ display: 'flex', flexDirection: 'column' }}>{EMPTYSPACE}</div>,
// }

const CLASSNAME_MAP = {
    'WEB': 'Ticket_web',
    'DESKTOP': 'Ticket_web',
    'MOBILE': 'Ticket_mobile',
    'TABLET': 'Ticket_tablet',
    'FULLSIZE': 'Ticket_full'
}
const verifying_dom = {
    true: <div style={{ padding: 5, borderRadius: 5, color: 'white', backgroundColor: '#189318', width: 'fit-content' }}>Verified</div>,
    false: <div style={{ padding: 5, borderRadius: 5, color: 'white', backgroundColor: 'rgb(206, 29, 23)', width: 'fit-content' }}>Unverified</div>
}

function Ticket(props) {

    const {
        data, // render the data
        verifiedcheck // determine whether to render verified
    } = props

    const device = Checkdevice()
    const classname = CLASSNAME_MAP[device]
    const [showitinerary, setShowitinerary] = useState(false)


    function onFlip() {
        setShowitinerary(!showitinerary)
    }

    const deletebtn = (
        <button
            onClick={() => { props.onDelete() }}
            style={{ outline: 'none', border: 'thin solid transparent', borderRadius: 20, height: '20%', alignItems: 'center', justifyContent: 'center', display: 'flex', backgroundColor: 'white', }}
        >
            Delete
        </button>
    )

    const enquirebtn = (
        <button
            onClick={() => { props.ticketmodal(data) }}
            style={{ outline: 'none', border: 'thin solid transparent', borderRadius: 20, height: '20%', alignItems: 'center', justifyContent: 'center', display: 'flex', backgroundColor: 'white', }}
        >
            Request Ticket
        </button>
    )


    if (device === 'WEB') {
        const titleAndLocation_web = (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {/* TITLE */}
                <div style={{ display: 'flex', padding: '3px 0px 0px 0px', borderBottom: 'thin solid #707070', width: 'fit-content', fontWeight: 'bold', fontSize: device === 'WEB' ? 'large' : 'small' }}>{data.title}</div>
                <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 5, }}>
                    {/* LOCATION and HOSTTYPE*/}
                    <div style={{ display: 'flex', width: 'fit-content', fontSize: device === 'WEB' ? '' : 'smaller' }}>{data.location + ', ' + data.hosttype}</div>
                </div>
            </div>
        )

        const itinerary_web = (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', overflowY: 'auto' }} onClick={() => { onFlip() }}>

                {/* TITLE AND LOCATION  */}
                {titleAndLocation_web}

                {/* ITINERARY */}
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', padding: '20px 10px 10px 10px' }}>
                        {data && data.itinerary ? <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>{data.itinerary}</div> : <div />}
                    </div>
                </div>

            </div>
        )

        const maininfo_web = (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }} onClick={() => { onFlip() }}>

                {/* TITLE AND LOCATION  */}
                {titleAndLocation_web}

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
                    {/* DATES */}
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', height: '100%', fontWeight: 'bold', padding: 10 }}>
                        <div style={{ display: 'flex', alignItems: 'center', height: '20%', justifyContent: 'center' }}>DATE</div>
                        <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 'normal', height: '80%', justifyContent: 'center', }}>
                            {data && data.date_arr && data.date_arr.map((item, index) => {
                                return <div key={item + index} style={{ padding: 1, margin: 1, textAlign: 'center' }}>{item}</div>
                            })}
                        </div>
                    </div>

                    {/* ADDRESS */}
                    <div style={{ display: 'flex', flexDirection: 'column', width: '40%', height: '100%', fontWeight: 'bold', padding: 10, borderRight: 'thin dashed rgb(232, 232, 232)', borderLeft: 'thin dashed rgb(232, 232, 232)' }}>
                        <div style={{ display: 'flex', alignItems: 'center', height: '20%', justifyContent: 'center' }}>ADDRESS</div>
                        <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 'normal', height: '80%', justifyContent: 'center', }}>
                            {data && data.address ? <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>{data.address}</div> : <div />}
                        </div>
                    </div>

                    {/* FOOD */}
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', height: '100%', fontWeight: 'bold', padding: 10 }}>
                        <div style={{ display: 'flex', alignItems: 'center', height: '20%', justifyContent: 'center' }}>FOOD</div>
                        <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 'normal', height: '80%', justifyContent: 'center', }}>
                            {data && data.food ? <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center' }}>{data.food}</div> : <div />}
                        </div>
                    </div>
                </div>

            </div>
        )


        return (
            <div key={classname} style={{ display: 'flex', flexDirection: 'column', borderRadius: 5, width: '100%', maxWidth: 570, minWidth: 570, margin: '20px 5px', height: 280, }} >

                <div>{verifiedcheck ? verifying_dom[data.verified] : null}</div>

                <div style={{ display: 'flex', flexDirection: 'row', border: 'thin solid #707070', borderRadius: 5, width: '100%', height: 230, marginTop: 5, outline: 'none' }} >

                    {showitinerary ? itinerary_web : maininfo_web}

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                        <div style={{ borderBottom: '2px solid #707070', height: 30, width: 30, top: '-18.5px', position: 'relative', borderRadius: 20, backgroundColor: 'white' }} />
                        <div style={{ borderTop: '2px solid #707070', height: 30, width: 30, bottom: '-18.5px', position: 'relative', borderRadius: 20, backgroundColor: 'white' }} />
                    </div>

                    <div style={{ display: 'flex', width: '30%', height: '100%', flexDirection: 'column', justifyContent: 'center' }}>
                        <div style={{ height: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <h2 style={{ fontWeight: 'bold', marginBottom: 0 }}>{data && data.cost ? data.cost : null}</h2>
                            <h4>{data && data.currency ? data.currency : null}</h4>
                        </div>
                        {verifiedcheck ? deletebtn : enquirebtn}
                    </div>

                </div>

            </div>
        )
    }
    else if (device === 'MOBILE') {

        const titleAndLocation_mobile = (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '20%' }}>
                {/* TITLE */}
                <div style={{ display: 'flex', padding: '3px 0px 0px 0px', borderBottom: 'thin solid #707070', width: 'fit-content', fontWeight: 'bold', fontSize: device === 'WEB' ? 'large' : 'small' }}>{data.title}</div>
                <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 5, }}>
                    {/* LOCATION and HOSTTYPE*/}
                    <div style={{ display: 'flex', width: 'fit-content', fontSize: device === 'WEB' ? '' : 'smaller' }}>{data.location + ', ' + data.hosttype}</div>
                </div>
            </div>
        )

        const maininfo_mobile = (ht) => {
            return <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: ht, alignItems: 'center' }} onClick={() => { onFlip() }}>

                {titleAndLocation_mobile}

                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '80%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '40%' }}>
                        {/* DATES */}
                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', height: '100%', fontWeight: 'bold', padding: 5 }}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '20%', justifyContent: 'center', fontSize: 'small' }}>DATE</div>
                            <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 'normal', height: '80%', justifyContent: 'center', }}>
                                {data && data.date_arr && data.date_arr.map((item, index) => {
                                    return <div key={item + index} style={{ padding: 1, margin: 1, textAlign: 'center', fontSize: 'smaller' }}>{item}</div>
                                })}
                            </div>
                        </div>

                        {/* FOOD */}
                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', height: '100%', fontWeight: 'bold', padding: 5, borderLeft: 'thin dashed rgb(232, 232, 232)' }}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '20%', justifyContent: 'center', fontSize: 'small' }}>FOOD</div>
                            <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 'normal', height: '80%', justifyContent: 'center', }}>
                                {data && data.food ? <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center', fontSize: 'smaller' }}>{data.food}</div> : <div />}
                            </div>
                        </div>
                    </div>

                    {/* ADDRESS */}
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '60%', fontWeight: 'bold', padding: 5, paddingTop: 10, borderTop: 'thin dashed rgb(232, 232, 232)', }}>
                        <div style={{ display: 'flex', alignItems: 'center', height: '20%', justifyContent: 'center', fontSize: 'small' }}>ADDRESS</div>
                        <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 'normal', height: '80%', justifyContent: 'center', }}>
                            {data && data.address ? <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center', fontSize: 'smaller' }}>{data.address}</div> : <div />}
                        </div>
                    </div>
                </div>

            </div>
        }

        const itinerary_mobile = (ht) => {
            return <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: ht, alignItems: 'center', overflowY: 'auto' }} onClick={() => { onFlip() }}>
                {titleAndLocation_mobile}
                {/* ITINERARY */}
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', padding: '20px 10px 10px 10px' }}>
                        {data && data.itinerary ? <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', fontSize: 'small', overflowY: 'auto', overflowX: 'hidden' }}>{data.itinerary}</div> : <div />}
                    </div>
                </div>
            </div>
        }

        return (
            <div key={classname} style={{ display: 'flex', flexDirection: 'column', borderRadius: 5, overflowX: 'hidden', overflowY: 'hidden', margin: '20px 5px 20px 0px',width: 250, minWidth: 250, maxWidth: 250 }}>
                <div style={{ display: 'flex', flexDirection: 'column', border: 'thin solid #707070', borderRadius: 5, height: 400, width: 250, minWidth: 250, maxWidth: 250, marginTop: 5, outline: 'none' }}>
                    {showitinerary ? itinerary_mobile(270) : maininfo_mobile(270)}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ borderRight: '2px solid #707070', height: 30, width: 30, left: '-18.5px', position: 'relative', borderRadius: 20, backgroundColor: 'white' }} />
                        <div style={{ borderLeft: '2px solid #707070', height: 30, width: 30, right: '-18.5px', position: 'relative', borderRadius: 20, backgroundColor: 'white' }} />
                    </div>
                    <div style={{ height: 100, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div style={{ height: '70%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ fontWeight: 'bold', marginBottom: 0, fontSize: 30 }}>{data && data.cost ? data.cost : null}</div>
                            <div style={{ fontWeight: 'bold', marginBottom: 0, padding: 5 }}>{data && data.currency ? data.currency : null}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', }}>
                            {verifiedcheck ? deletebtn : enquirebtn}
                        </div>
                    </div>
                </div>

            </div>
        )
    }
    else {
        return <div />
    }


}

export default Ticket