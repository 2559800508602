import React, { Component } from 'react'
import { WEB, EMPTYSPACE, IMG_ALT, LINKEDIN_ICON, EMAIL_ICON, EMPTY, STARTUP_NAME } from '../../config/constants'
import './About.css'
// import { birthday, wedding, hdb, baby } from '../../assets/svgs';

// const image = [birthday, wedding, hdb, baby]
// const chi = ['是否记得他为你所举办的生日派对?', '是否记得进礼堂时怦然心动的感觉？', '是否记得乔迁宴上亲友们给的祝贺？', '是否记得为孩子办满月酒那份喜悦？']
// const eng = ['Do you still recall the joyrous birthday party your loved ones celebrated with you?', 'Do you still remember the exiciting moment while walked down the aisle?', 'Do you still remember the excitement where you prepare for your housewarming party?', 'Do you still remmber the joyful moment of the baby 100 days old party you organise?']
// const textcolor = "transparent"
class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screen_width: 0,
            screen_height: 0,
            lang: 'ENG',
            usertype: EMPTY,
            location: EMPTY
        }
    }
    componentDidMount = async () => {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions)
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }

    updateWindowDimensions = () => {
        this.setState({ screen_width: window.innerWidth, screen_height: window.innerHeight });
    }

    onChangelang = (lang) => { this.setState({ lang: lang }) }

    RenderStory = () => {
        const { lang } = this.state
        var text
        if (lang === 'ENG') {
            text = `
            ${STARTUP_NAME} is an online platform for consumers who are planning for wedding and events to search for relevant services; 
            and for Wedding/Event vendors to promote their services. 
            Selection of professionals to render services for wedding and event is a complicated and challenging matter. 
            Therefore, there is a need to provide a streamlined process to empower consumers to find the right professionals at the optimal price for their wedding and event.
            Hence, I started to pursue this idea of creating ${STARTUP_NAME}.
            `
        }
        else {
            text = "是否还记得 你18岁的生日是如何庆祝的? 是否还记得 求婚的时候被人围观的感觉？ 是否还记得 领取结婚证书后的那种喜悦？ 是否还记得 你孩子满月大家欢聚一堂的那情景？ 是否还记得 你毕业时拿到毕业证书的那种欣慰？ 。。。这些片刻编织了你一生的精彩。我们建立这平台的目的时为你提供庆典所需的优质专业人员。让你能尽情的感受当下。年老时和另一半一起回味的美好记忆。"
        }
        return <div>{text}</div>
    }

    RenderMain = () => {
        return (
            <div className='container'>
                <div className='ourstory-card'>
                    <div>Our Story</div>
                    {EMPTYSPACE}
                    <button className='ourstory-card-btneng' onClick={() => { this.onChangelang('ENG') }}>English</button>
                    <button className='ourstory-card-btnchi' onClick={() => { this.onChangelang('CHI') }}>Chinese</button>
                    {EMPTYSPACE}
                    {this.RenderStory()}
                    {EMPTYSPACE}
                </div>
            </div>
        )
    }

    RenderWeb = (cardwidth) => {
        const { usertype } = this.state
        return (
            <div className='primary'>
                <div className='about-image' />
                <div className='title-brand'>
                    <div>{STARTUP_NAME}</div>
                </div>
               
                {EMPTYSPACE}
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignContent: 'center', padding: '2.5% 10%', fontSize: 18 }}>
                    {`${STARTUP_NAME}`} is an online platform that supports and grows a community for wedding and event market.
                    One of the reason for the creation of {`${STARTUP_NAME}`} is to reduce the high search cost incurred for individuals who are searching for wedding and event vendors.
                    And for vendors to easily reach out to the masses online.
                </div>
                {EMPTYSPACE}
                <div className="container" style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', backgroundColor: 'rgba(251, 225, 229, 0.3)', padding: '3% 5%' }}>
                    {EMPTYSPACE}
                    {this.state.lang === 'ENG' ? <h3> Our Team </h3> : <h3> 团队 </h3>}
                    <div></div>
                    {EMPTYSPACE}
                    <img style={{ display: 'flex', width: 150, height: 150, border: 'solid thin #D9D8D8', borderRadius: 75 }} src={'https://picsum.photos/id/1/200/300'} alt={IMG_ALT} />
                    {EMPTYSPACE}
                    {this.state.lang === 'ENG' ?
                        <div style={{ display: 'flex', flexDirection: 'column', height: 'max-height', width: '100%', alignItems: 'center' }}>
                            <h3 style={{ fontWeight: 'bold' }}>{usertype === 'VENDOR' ? 'Chiang Jia Feng' :''}</h3>
                            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Founder</div>
                            <div style={{ display: 'flex', flexDirection: 'row', height: 'max-content', alignItems: 'center', }}>
                                <div style={{ padding: 2.5 }}> {LINKEDIN_ICON}</div>
                                <div style={{ padding: 2.5 }}> {EMAIL_ICON}</div>
                            </div>
                            {/* {EMPTYSPACE}
                            <div style={{ padding: 3 }}> Proudly made in Singapore.</div> */}
                            {/* <div style={{ fontWeight: 'bold', textAlign: 'center' }}>NUS, CS</div> */}
                            {EMPTYSPACE}
                            {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', border: 'solid thin #D9D8D8', padding: 10, borderRadius: 5 }}>
                                <div style={{ fontWeight: 'bold', padding: 5 }}>" Someone who don't celebrate birthday "</div>
                                <div style={{ fontWeight: 'bold', padding: 5 }}>" Someone who don't like to attend wedding "</div>
                                <div style={{ fontWeight: 'bold', padding: 5 }}>" Single, and never had a relationship before "</div>
                            </div> */}
                        </div>
                        :
                        <div style={{ display: 'flex', flexDirection: 'column', height: 'max-height', width: '100%', alignItems: 'center' }}>
                            <h2 style={{ fontWeight: 'bold' }}>蒋 佳峰</h2>
                            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Founder</div>
                            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>毕业于新加坡国立大学，计算机系</div>
                            {EMPTYSPACE}
                            {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', border: 'solid thin #D9D8D8', padding: 10, borderRadius: 5 }}>
                                <div style={{ fontWeight: 'bold', padding: 5 }}>" 不庆祝生日的怪人 "</div>
                                <div style={{ fontWeight: 'bold', padding: 5 }}>" 一个不喜欢参加婚礼的人 除非逼不得已 "</div>
                                <div style={{ fontWeight: 'bold', padding: 5 }}>" 活到现在都还没交过女朋友的少数雄性 "</div>
                            </div> */}
                        </div>
                    }
                </div>

            </div>
        )
    }
    render() {
        const { screen_width } = this.state
        return screen_width < WEB ? this.RenderWeb('100%') : this.RenderWeb('70%')
    }
}

export default About