import { useEffect, useState } from "react"
import { Noti } from "../../../../../components";
import { iconwrapper } from "../../../../../config/commonfunctions";
import { INVITATIONTYPES_ARR } from "../../../../../config/constants";
import { DOUBLEHEART_ICON } from "../../../../../config/svgicons";
import { input_dom } from "../../Invitationmaker/components/Commonelements/Commonelements";

const iconcss = { width: 30, height: 30, color: 'var(--maindark)' }
const itype_icon_map = {
    'Wedding': iconwrapper(DOUBLEHEART_ICON, iconcss),
    'House-Warming': iconwrapper(DOUBLEHEART_ICON, iconcss),
    'Baby Shower': iconwrapper(DOUBLEHEART_ICON, iconcss),
    'Birthday Party': iconwrapper(DOUBLEHEART_ICON, iconcss),
    'Postponement': iconwrapper(DOUBLEHEART_ICON, iconcss)
}
const MSG_MAP = {
    HANDLER_SAME: 'URL Handler is taken.',
    TOO_SHORT: 'URL Handler too short.',
    FAILED: 'Failed to create.',
    PASSCODEERROR: 'Passcode error.',
    MODPASSCODEERROR: 'Moderator passcode error.'
}

const Createnew = (props) => {

    const { create, reachquota = false, from } = props
    const input_css = { marginLeft: 0, marginRight: 0, width: '100%', margin: 0 }
    const [urlhandler, setUrlhandler] = useState('')
    const [passcode, setPasscode] = useState('')
    const [passcodemod, setPasscodemod] = useState('') // for guestbook only
    const [itype, setItype] = useState('') // use in invitationmaker and guestbook and seatingchart
    const [blocksave, setBlocksave] = useState(false)
    const [msgs, setMsgs] = useState('')
    const [notitxt, setNotitxt] = useState('')

    useEffect(() => {
        const { itype } = props
        if (from === 'invitationmaker' || from === 'guestbook' || from === 'seating') {
            setItype(itype)
        }
    }, [props])

    const unique_dom_map = {
        'invitationmaker': {
            // selection of event
            top: <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row' }}>
                {INVITATIONTYPES_ARR.map((val, index) => {
                    return val === 'Postponement'
                        ? <div className={"planning_option" + (val === itype)} key={val + index} onClick={(e) => setItype(val)} >
                            <div className="planning_optiontop">{itype_icon_map[val]}</div>
                            <div className="planning_optionbtm">{val + ' / Cancellation'}</div>
                        </div>
                        : <div className={"planning_option" + (val === itype)} key={val + index} onClick={(e) => setItype(val)} >
                            <div className="planning_optiontop">{itype_icon_map[val]}</div>
                            <div className="planning_optionbtm"> {val}</div>
                        </div>
                })}
            </div>,
            btm: null,
        },
        'itineraryedit': {
            top: null,
            btm: null,
        },
        'seatingchart': {
            // selection of event
            top: <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row' }}>
                {INVITATIONTYPES_ARR.map((val, index) => {
                    return val === 'Postponement'
                        ? <div className={"planning_option" + (val === itype)} key={val + index} onClick={(e) => setItype(val)} >
                            <div className="planning_optiontop">{itype_icon_map[val]}</div>
                            <div className="planning_optionbtm">{val + ' / Cancellation'}</div>
                        </div>
                        : <div className={"planning_option" + (val === itype)} key={val + index} onClick={(e) => setItype(val)} >
                            <div className="planning_optiontop">{itype_icon_map[val]}</div>
                            <div className="planning_optionbtm"> {val}</div>
                        </div>
                })}
            </div>,
            btm: null,
        },
        'guestbook': {
            // selection of event
            top: <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row' }}>
                {INVITATIONTYPES_ARR.map((val, index) => {
                    return val === 'Postponement'
                        ? <div className={"planning_option" + (val === itype)} key={val + index} onClick={(e) => setItype(val)} >
                            <div className="planning_optiontop">{itype_icon_map[val]}</div>
                            <div className="planning_optionbtm">{val + ' / Cancellation'}</div>
                        </div>
                        : <div className={"planning_option" + (val === itype)} key={val + index} onClick={(e) => setItype(val)} >
                            <div className="planning_optiontop">{itype_icon_map[val]}</div>
                            <div className="planning_optionbtm"> {val}</div>
                        </div>
                })}
            </div>,
            // mode pw
            btm: <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row' }}>
                {input_dom(
                    'Mod Passcode',
                    '',
                    passcodemod,
                    (e) => {
                        const value = e.target.value.includes(' ') ? e.target.value.replace(/\s+/g, "") : e.target.value
                        if (value.length < 50) {
                            setPasscodemod(value)
                        }
                        setMsgs('')
                    },
                    'modpasscode_id',
                    {},
                    false,
                    null,
                    {},
                    input_css
                )}
            </div>
        },
    }
    const msgwrapper = (msg, i) => {
        return <div key={'msg' + i} className='planning_msgcreate'> {msg} </div>
    }

    const timersequence = (run, msg) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                setMsgs(msgwrapper(msg, 4))  // SHOW THIS MSG FIRST
                setTimeout(() => { // after timeone, run will be executed.
                    run();
                    resolve(
                        {
                            'Saved': 'SUCCESS',
                            'Failed': 'FAILURE',
                            'Failed': 'ALRDY_EXIST',
                        }[msg]
                    ); // Resolve the promise when the timers are done
                }, 1250);
            }, 1250);
        });
    };

    return <div id='2-2' style={{ display: 'flex', flexDirection: 'column' }}>
        {reachquota
            ? <div className="Details_grp" style={{ backgroundColor: 'var(--maindark)', color: '#ffffff', width: '100%', height: 40, borderRadius: 5, placeContent: 'center', textAlign: 'center', borderRadius: 5 }}> You have reach the maximum quota.</div>
            : <>
                {unique_dom_map[from] && unique_dom_map[from].top ? unique_dom_map[from].top : null}
                <div className="Details_grp">
                    {input_dom(
                        'URL Handler',
                        '',
                        urlhandler,
                        (e) => {
                            const value = e.target.value.includes(' ') ? e.target.value.replace(/\s+/g, "") : e.target.value
                            if (value.length < 50) {
                                setUrlhandler(value);
                            }
                            setMsgs('')
                        },
                        'urlhandler_id',
                        {},
                        false,
                        null,
                        {},
                        input_css
                    )}
                </div>
                <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row' }}>
                    {input_dom(
                        'Passcode',
                        '',
                        passcode,
                        (e) => {
                            const value = e.target.value.includes(' ') ? e.target.value.replace(/\s+/g, "") : e.target.value
                            if (value.length < 50) {
                                setPasscode(value);
                            }
                            setMsgs('')
                        },
                        'passcode_id',
                        {},
                        false,
                        null,
                        {},
                        input_css
                    )}
                </div>
                {unique_dom_map[from] && unique_dom_map[from].btm ? unique_dom_map[from].btm : null}

                <div className="Details_grp" style={{ display: 'flex', flexDirection: 'column', placeContent: 'flex-end' }}>
                    <button
                        className="planning_contbtn"
                        disabled={blocksave}
                        onClick={() => {
                            let msgs = []

                            if (urlhandler.length < 3) {
                                msgs.push(msgwrapper('URL handler too short. Min 3 characters', 0))
                            }

                            if (passcode.length === 0) {
                                msgs.push(msgwrapper('Passcode cannot be empty.', 1))
                            }

                            if (passcode.length < 3) {
                                msgs.push(msgwrapper('Passcode is too short. Min 3 characters.', 2))
                            }

                            if (passcodemod.length < 3 && from === 'guestbook') {
                                msgs.push(msgwrapper('Mod Passcode is too short. Min 3 characters.', 2))
                            }

                            if (msgs.length === 0) {
                                setBlocksave(true)
                                create(urlhandler, passcode, itype, passcodemod) // passcodemod only exist on 'guestbook' 
                                    .then((items) => {
                                        console.log(items)
                                        const { message, id } = items
                                        if (message === 'SUCCESS') {
                                            setNotitxt('Redirecting...')
                                        }
                                        else {
                                            setNotitxt(MSG_MAP[message])
                                        }

                                        timersequence(
                                            () => {
                                                setBlocksave(false)
                                                if (message === 'SUCCESS') {
                                                    props.createsuccess(id, urlhandler, passcode, itype, passcodemod)
                                                }
                                            },
                                            {
                                                'SUCCESS': 'Saved',
                                                'ALRDY_EXIST': 'URL handler already exist.',
                                                'FAILURE': 'Failed'
                                            }[message]
                                        )
                                    })
                            }
                            setMsgs(msgs)



                        }}
                    >
                        {blocksave ? 'Saving...' : 'Create'}
                    </button>
                    <div style={{ display: 'flex', flexDirection: 'column', placeContent: 'flex-end', marginTop: 5 }}>
                        {msgs}
                    </div>
                    {notitxt && notitxt.length > 0 ? <Noti noti={notitxt} /> : null}
                </div>
            </>}

    </div>
}

export default Createnew