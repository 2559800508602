import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { iconwrapper } from '../../../../../../config/commonfunctions'
import { EMPTYSPACE, WEBSITE_URL } from '../../../../../../config/constants'
import { COPY_ICON, ENTER_ICON } from '../../../../../../config/svgicons'
import { inputdescribe_dom, input_dom, partialtitle_dom } from '../Commonelements/Commonelements'
import Explain from './../Explain'
import './QRcodediv.css'

const TITLE = 'QR Code'
const TEXT = `Printable version QR code for your guest during wedding that will automatically direct them to your invitation card.`
function copyToClipboard(string) {
    let textarea;
    let result;

    try {
        textarea = document.createElement('textarea');
        textarea.setAttribute('readonly', true);
        textarea.setAttribute('contenteditable', true);
        textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
        textarea.value = string;

        document.body.appendChild(textarea);

        textarea.focus();
        textarea.select();

        const range = document.createRange();
        range.selectNodeContents(textarea);

        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        textarea.setSelectionRange(0, textarea.value.length);
        result = document.execCommand('copy');
    } catch (err) {
        console.error(err);
        result = null;
    } finally {
        document.body.removeChild(textarea);
    }

    // manual copy fallback using prompt
    if (!result) {
        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
        result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
        if (!result) {
            return false;
        }
    }
    return true;
}


function QRcodediv(props) {
    const { url, device, itype, passcode, bannerdesign = 0, name, titletext, spousename, babyname, date_e, date_w, dt_type, } = props
    //     'Baby Shower': '',
    //     'Wedding': 'Scan to access ' + name + ' and ' + spousename + "'s wedding invitation card.",
    //     'House-Warming': 'Scan to access ' + name + ' and ' + spousename + "'s house-warming invitation.",
    //     'Birthday Party': '',
    //     'Postponement': 'Scan to access ' + name + ' and ' + spousename + "'s postponement card.",

    const [copymode, setCopymode] = useState(false)

    useEffect(() => {
        if (copymode) {
            setTimeout(() => {
                setCopymode(false)
            }, [1500])
        }
    }, [copymode])

    const onCopy = (e) => {
        try {
            var test = function (regexp) {
                return regexp.test(window.navigator.userAgent);
            }

            if (test(/safari/i)) { // is safari
                copyToClipboard(e.target.value)
                setCopymode(true)
            }
            else {
                navigator.clipboard.writeText(e.target.value)
                setCopymode(true)
            }

        }
        catch (e) {
            console.log(e)
        }
    }

    const commoneditstyle = {
        height: {
            'WEB': 'calc(100% - 70px - 65px)',
            'TABLET': 'calc(100% - 70px - 65px)',
            'MOBILE': 'calc(100% - 70px)'
        }[device],
        marginBottom: {
            'MOBILE': 65
        }[device]
    }

    const copydom = copymode
        ? <div style={{ width: '100vw', position: 'absolute', placeContent: 'center', alignContent: 'center', left: 0, display: 'flex', bottom: 150, zIndex: 1 }}>
            <div style={{ display: 'flex', width: 200, height: 40, backgroundColor: 'var(--maindark)', color: '#ffffff', placeContent: 'center', alignContent: 'center', borderRadius: 5, position: 'fixed', alignItems: 'center' }}>Copied!</div>
        </div>
        : null

    // return <div className="QRcodediv_root">
    return <div id="Ieditcomp_root">
        <div id="Ieditcomp_contentroot">
            <div className='Ieditcomp_div' style={commoneditstyle}>
                <Explain title={TITLE} txt={TEXT} />

                <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row' }}>
                    {input_dom(
                        'Invitation URL Handler',
                        '',
                        `${WEBSITE_URL}/invitation/${url}/passcode=${passcode}`,
                        (e) => {
                            const value = e.target.value.includes(' ') ? e.target.value.replace(/\s+/g, "") : e.target.value
                            if (e.target.value.length < 50) { setUrlhandler(value); }
                        },
                        'urlhandler_id',
                        {},
                        true,
                        null,
                        {},
                        { marginLeft: 0, marginRight: 0, width: '100%', margin: 0, }
                    )}
                    <button className="planning_editbtn" style={{}} onClick={onCopy} value={`${WEBSITE_URL}/invitation/${url}/passcode=${passcode}`}>
                        {iconwrapper(COPY_ICON, { borderRadius: 5, padding: 0, margin: 0, width: 20, height: 20, pointerEvents: 'none' })}
                    </button>
                </div>

                <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row' }}>
                    {input_dom(
                        'Gallery URL Handler',
                        '',
                        `${WEBSITE_URL}/invitation/${url}/passcode=${passcode}/gallery=true`,
                        (e) => { },
                        'gallery_id',
                        {},
                        true,
                        null,
                        {},
                        { marginLeft: 0, marginRight: 0, width: '100%', margin: 0, }
                    )}
                    <button className="planning_editbtn" style={{}} onClick={onCopy} value={`${WEBSITE_URL}/invitation/${url}/passcode=${passcode}/gallery=true`}>
                        {iconwrapper(COPY_ICON, { borderRadius: 5, padding: 0, margin: 0, width: 20, height: 20, userSelect: 'none', pointerEvents: 'none' })}
                    </button>
                </div>

                <Link id='qr_link' to="/tools/qr" target="_blank" style={{ visibility: 'hidden' }} />
                <button
                    className='Ieditcomp_qr'
                    onClick={() => {
                        document.getElementById('qr_link').click()
                    }}
                >
                    <div>QR App</div>
                    {iconwrapper(ENTER_ICON, { width: 24, height: 24, marginLeft: 5 })}
                </button>
            </div>

        </div>
        {copydom}
        {/* 
        <div className='iedit_swaproot'>
            <button
                className='iedit_swaproot'
                style={{ marginRight: 0, width: '100%' }}
                onClick={() => {
                    document.getElementById('qr_link').click()
                }}
            >
                Generate QR
            </button>
        </div> 
        */}

    </div >

}
export default QRcodediv