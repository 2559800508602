import React, { useState } from 'react'
import { RSVPQNS_TEMPLATES } from '../../constants'
import { describe_dom } from "../Commonelements/Commonelements"
import Success from './components/Success'

function Rsvptemplatecontent(props) {

    const { addtemplatersvpqnstate, close, save } = props
    const [templatersvpmcqns, setTemplatersvpmcqns] = useState([]) // store multiple choice qn
    const [templatersvptxtqns, setTemplatersvptxtqns] = useState([]) // store text qn

    const general_dom = <div className='Rsvpexplorer_portalcontent'>
        {describe_dom('Here are some of the ready to use questions for you to get started.')}
        <div className='Rsvpexplorer_portalcontent'>
            <div className='Rsvpexplorer_templatetitle'>Multiple Choice</div>
            {RSVPQNS_TEMPLATES['Multiple Choice'].map((item, i) => {
                const index = i
                const checkexist = templatersvpmcqns.indexOf(index) > -1
                return <button
                    key={'mc_' + index}
                    className={`${checkexist ? 'Portalinnerelem_selected' : 'Portalinnerelem'}`}
                    style={{ borderBottom: RSVPQNS_TEMPLATES['Multiple Choice'].length === 1 ? '1px solid var(--subgrey)' : '' }}
                    value={index}
                    onClick={(e) => {
                        if (checkexist) {
                            var result = templatersvpmcqns.slice()
                            const inner_index = templatersvpmcqns.indexOf(parseInt(e.target.value, 10))
                            result.splice(inner_index, 1)
                            setTemplatersvpmcqns(result)
                        }
                        else {
                            setTemplatersvpmcqns([...templatersvpmcqns, parseInt(index, 10)])
                        }
                    }}>
                    <div className='Rsvpexplorer_portalinnerelemtxt'>{item[0]}</div>
                </button>
            })}
            <div className='Rsvpexplorer_templatetitle' style={{ marginTop: 10 }}>Open-ended</div>
            {RSVPQNS_TEMPLATES['Open-ended'].map((item, i) => {
                const index = i
                const checkexist = templatersvptxtqns.indexOf(index) > -1
                return <button
                    key={'text_' + index}
                    className={`${checkexist ? 'Portalinnerelem_selected' : 'Portalinnerelem'}`}
                    value={index}
                    onClick={(e) => {
                        if (checkexist) {
                            var result = templatersvptxtqns.slice()
                            result.splice(templatersvptxtqns.indexOf(parseInt(e.target.value, 10)), 1)
                            setTemplatersvptxtqns(result)
                        }
                        else {
                            setTemplatersvptxtqns([...templatersvptxtqns, parseInt(index, 10)])
                        }
                    }}>
                    <div className='Rsvpexplorer_portalinnerelemtxt'>{item[0]}</div>
                </button>
            })}
        </div>

        <button
            className='Rsvpexplorer_portalsave'
            disabled={addtemplatersvpqnstate === 'SAVING'}
            onClick={() => save(templatersvpmcqns, templatersvptxtqns)}
        >
            {{ // IDLE, SAVING, SUCCESS, FAILURE  
                'IDLE': 'Add',
                'SAVING': 'Saving...',
                'SUCCESS': 'New Question Added',
                'FAILURE': 'Error Occurred, please try again later.'
            }[addtemplatersvpqnstate]}
        </button>
    </div>

    const success_dom = addtemplatersvpqnstate === 'SUCCESS'
        ? <Success
            txt={'Rsvp questions updated. Click to return.'}
            onClick={close} />
        : null

    return <div className='Rsvpexplorer_portal'>
        <div className='Rsvpexplorer_portaltitle'>RSVP Question Templates</div>
        {addtemplatersvpqnstate !== 'SUCCESS' ? general_dom : success_dom}
    </div>
}

export default Rsvptemplatecontent