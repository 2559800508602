import React from 'react'
import { LINKLARGE_ICON, LOOKUP_ICON, MOD_ICON, PROJECTOR_ICON, GUESTCHAR_ICON, WEBSITE_URL } from '../../../../../../../config/constants'
import './Rdefault.css'
import '../Common.css'
import { iconwrapper } from '../../../../../../../config/commonfunctions'
import { PLANNING_QR_ICON } from '../../../../../../../config/svgicons'

function Rdefault({ passcode, urlhandler, device, passcodemod, id }) {

    const HELPING_TEXT_ARR = [
        'Start by uploading images that you would want to show during your actual day.',
        'Send the guest link to your guest or simply use our qr generator to generate printable QR code to access the guestbook during the actual day.',
        'To view your guestbook, go to the guestbook lookup under the preview section.'
    ]
    const default_cards = [
        {
            icon: PROJECTOR_ICON,
            title: 'Cast 1',
            explain: `Display messages & wishes send from your guest on to the projector/TV.`,
            dom: <div className="gbrwd_defaultcol">
                <input className="gbrwd_defaultpure_txt" onFocus={(event) => event.target.select()} value={`https://${WEBSITE_URL}/guestbookcast/cast1/${urlhandler}/${passcode}/${id}`} onChange={() => { }} />
                <div className="gbrwd_defaulticonandtxt">
                    <div className="gbrwd_default20_icon">{LINKLARGE_ICON} </div>
                    <div className="gbrwd_default80_txt" onClick={() => {
                        const win = window.open(`/guestbookcast/cast1/${urlhandler}/${passcode}/${id}`, "_blank");
                        win.focus();
                    }}>Cast 1 Access </div>
                </div>
            </div>
        },
        {
            icon: PROJECTOR_ICON,
            title: 'Cast 2',
            explain: 'A spare cast in case you required another one.',
            dom: <div className="gbrwd_defaultcol">
                <input className="gbrwd_defaultpure_txt" onFocus={(event) => event.target.select()} value={`https://${WEBSITE_URL}/guestbookcast/cast2/${urlhandler}/${passcode}/${id}`} onChange={() => { }} />
                <div className="gbrwd_defaulticonandtxt">
                    <div className="gbrwd_default20_icon">{LINKLARGE_ICON} </div>
                    <div className="gbrwd_default80_txt" onClick={() => {
                        const win = window.open(`/guestbookcast/cast2/${urlhandler}/${passcode}/${id}`, "_blank");
                        win.focus();
                    }}>Cast 2 Access</div>
                </div>
            </div>
        },
        {
            icon: MOD_ICON,
            title: 'Moderator',
            explain: `Assign someone to moderate wishes you received. Support only 1 mod in any session. `,
            dom: <div className="gbrwd_defaultcol">
                <input className="gbrwd_defaultpure_txt" onFocus={(event) => event.target.select()} value={`https://${WEBSITE_URL}/guestbookmod/${urlhandler}/${passcodemod}`} onChange={() => { }} />
                <div className="gbrwd_defaulticonandtxt">
                    <div className="gbrwd_default20_icon">{LINKLARGE_ICON} </div>
                    <div className="gbrwd_default80_txt" onClick={() => {
                        const win = window.open(`/guestbookmod/${urlhandler}/${passcodemod}`, "_blank");
                        win.focus();
                    }}
                    >Mod Access</div>
                </div>
            </div>
        },
        {
            icon: GUESTCHAR_ICON,
            title: 'Guest Access',
            explain: 'URL for your guest to access and send message to the projected casting.',
            dom: <div className="gbrwd_defaultcol">
                <input className="gbrwd_defaultpure_txt" onFocus={(event) => event.target.select()} value={`https://${WEBSITE_URL}/guestbook/${urlhandler}/${passcode}`} onChange={() => { }} />
                <div className="gbrwd_defaulticonandtxt">
                    <div className="gbrwd_default20_icon">{LINKLARGE_ICON} </div>
                    <div className="gbrwd_default80_txt" onClick={() => {
                        const win = window.open(`/guestbook/${urlhandler}/${passcode}`, "_blank");
                        win.focus();
                    }}>Guest Access</div>
                </div>
                <div
                    className="gbrwd_defaulticonandtxt"
                    onClick={() => {
                        // /qr/ banner design / identifier / urlhandler / passcode
                        const win = window.open(`/qr/-1/gb/${urlhandler}/${passcode}`, "_blank")
                        win.focus()
                    }}>
                    {/* <div className="gbrwd_default20_icon">{QR_NORM} </div> */}
                    <div className="gbrwd_default20_icon">{iconwrapper(PLANNING_QR_ICON, { width: 24, height: 24 })} </div>
                    <div className="gbrwd_default80_txt" >
                        <div>Generate Printable</div>
                        <div>QR Code</div>
                    </div>
                </div>
            </div>
        },
        {
            icon: LOOKUP_ICON,
            title: 'Preview',
            explain: 'Preview your online guestbook page.',
            dom: <div className="gbrwd_defaultcol">
                <input className="gbrwd_defaultpure_txt" onFocus={(event) => event.target.select()} value={`https://${WEBSITE_URL}/guestbooklookup/${urlhandler}/${passcodemod}`} onChange={() => { }} />
                <div className="gbrwd_defaulticonandtxt">
                    <div className="gbrwd_default20_icon">{LINKLARGE_ICON} </div>
                    <div className="gbrwd_default80_txt" onClick={() => {
                        const win = window.open(`/guestbooklookup/${urlhandler}/${passcodemod}`, "_blank");
                        win.focus();
                    }}>Guestbook Lookup</div>
                </div>
            </div>
        }
    ]

    const card = (title, icon, explain, dom, i) => {
        return <div key={"rwd_default" + i} className="rd_card">
            <div className="rd_title">{title}</div>
            <div className="rd_titleicon" >{icon}</div>
            <div className="rd_descrip">{explain}</div>
            <div className="gbrwd_defaultcardlink">{dom}</div>
        </div>
    }


    return device === 'MOBILE'
        ? <div className="gbrwd_content_m">
            <div className="gbrwd_howtouse_m">
                {HELPING_TEXT_ARR.map((txt, i) => {
                    return <div className="gbrwd_howtouserow_m">
                        <div className="gbrwd_index_m">{i + 1}</div>
                        <div>{txt}</div>
                    </div>
                })}
            </div>
            <div className="gbrwd_defaultcardsroot">
                {default_cards.map(({ icon, title, explain, dom }, i) => {
                    return <div key={title + '_' + i} className="gbrwd_def0" >
                        <div className="gbrwd_def1m">
                            <div className="gbrwd_def2m" >{icon}</div>
                            <div className="gbrwd_def3m">{title}</div>
                        </div>
                        <div className="gbrwd_def4m">{explain}</div>
                        {dom}
                    </div>
                })}
            </div>
        </div>
        : <div className="gbrwd_default">
            <div className="gbrwd_howtouse">
                {HELPING_TEXT_ARR.map((txt, i) => {
                    return <div key={i + 'txt'} className="gbrwd_howtouserow">
                        <div className="gbrwd_index">{i + 1}</div>
                        <div className="gbrwd_explaintext">{txt}</div>
                    </div>
                })}
           
            </div>
            <div className="gbrwd_defaultrow">
                {default_cards.map(({ title, icon, explain, dom }, i) => {
                    return card(title, icon, explain, dom, i)
                })}
            </div>

        </div>
}

export default Rdefault