import React, { useState, useEffect } from 'react'
import { BUTTONSTYLE } from '../../../../../config/cssconstants'

function Budgettext(props) {

    const [txt, setTxt] = useState('')

    useEffect(() => {
        setTxt(props.val)
    }, [props])

    return <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <textarea
            rows="4"
            cols="50"
            name="comment"
            form="usrform"
            style={{ display: 'block', outlineColor: '#69b1e9', lineHeight: '1.42857143', height: 300, color: '#555', backgroundColor: '#fff', backgroundImage: 'none', transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s', boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)', borderRadius: 4, border: '1px solid #ccc', width: '100%' }}
            onChange={(e) => setTxt(e.target.value)}
            value={txt}
        />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10 }}>
            <button style={{ ...BUTTONSTYLE, marginRight: 5 }} onClick={() => props.save(txt)}>Save</button>
            <button style={BUTTONSTYLE} onClick={props.cancel}>Cancel</button>
        </div>
    </div>

}

export default Budgettext