import React from "react";
import { ARROWDOWNSMALL_ICON, ARROWUPSMALL_ICON } from "../../../../../../config/constants";

const TableHead = ({ headerGroups, getTableProps }) => {
  return <div {...getTableProps()}>
    {headerGroups.map((headerGroup) => (
      <div {...headerGroup.getHeaderGroupProps()} className="trtablehead">
        {headerGroup.headers.map((column, i) => {
          if (i !== 0) { // not checkbox
            return <div {...column.getHeaderProps()} className="th">
              <div style={{ display: 'flex', flexDirection: 'row', placeContent: 'space-between', }}>
                {/* <div {...column.getSortByToggleProps()} style={{ width: '100%' }}>{column.render("Header")}</div>
                  <div {...column.getSortByToggleProps()} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', placeContent: 'space-between', marginRight: 10 }}>{column.isSorted ? column.isSortedDesc ? ARROWDOWNSMALL_ICON : ARROWUPSMALL_ICON : ''}</div>
                   */}
                <div style={{ width: '100%' }}>{column.render("Header")}</div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', placeContent: 'space-between', marginRight: 10 }}>{column.isSorted ? column.isSortedDesc ? ARROWDOWNSMALL_ICON : ARROWUPSMALL_ICON : ''}</div>
              </div>
              <div {...column.getResizerProps()} className={`resizer ${column.isResizing ? "isResizing" : ""}`} />
            </div>
          }
          else {
            return <div {...column.getHeaderProps()} className="th">
              <div style={{ width: '100%', height: '100%', display: 'flex', placeContent: 'center' }}>{column.render("Header")}</div>
            </div>
          }
        })}
      </div>
    ))}
  </div>
}

export default React.memo(TableHead);
