import React, { useEffect, useState } from 'react'
import { a3wtint, a3htint, canva_dlratio, canva_editratio } from '../../../../../../../../../config/constants';
import { ReactP5Wrapper } from "react-p5-wrapper";
import { Checkdevice, Noti } from '../../../../../../../../../components';
import { changeDpiBlob } from 'changedpi';
import { UPDATE_GBARTSAVE } from '../../../../../../../../../config/api';
import { Retrieve_personal_info } from '../../../../../../../../../config/commonfunctions';
import Commonart from '../components/Commonart/Commonart';
import html2canvas from 'html2canvas';
import axios from 'axios'
import './Charart.css'

const USERFACING = true // true: user, false: weavebliss

// Direct copy starts here --- 

const alphabets = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
const artkey = 'ALPHA1'

// sentence can only hanlde up to 
const Artcommon = new Commonart()

function randomIntFromInterval(min, max) {// min and max included 
    return Math.ceil(Math.random() * (max - min + 1) + min)
}
const COLOR_PALLETES = [
    ["#001219", "#005f73", "#0a9396", "#94d2bd", "#e9d8a6", "#ee9b00", "#ca6702", "#bb3e03", "#ae2012", "#9b2226"],
    ["#fec5bb", "#fcd5ce", "#fae1dd", "#f8edeb", "#e8e8e4", "#d8e2dc", "#ece4db", "#ffe5d9", "#ffd7ba", "#fec89a"],
    ["#d9ed92", "#b5e48c", "#99d98c", "#76c893", "#52b69a", "#34a0a4", "#168aad", "#1a759f", "#1e6091", "#184e77"],
    ["#7400b8", "#6930c3", "#5e60ce", "#5390d9", "#4ea8de", "#48bfe3", "#56cfe1", "#64dfdf", "#72efdd", "#80ffdb"]
]
const DEBUG_STOPDL = false // for debug pupose

function Charart(props) {
    const { changecat, purchase, openpurchase, artmeta, msgsdata, updateartmeta, urlhandler, artmaintxt } = props
    const { token } = Retrieve_personal_info()
    const [firsttime, setFirsttime] = useState(true)
    const [rankedterms, setRankedterms] = useState([])
    const [pfive, setPfive] = useState(null)

    const [viewtype, setViewtype] = useState('YES') // NO or YES

    const [p5reloadclicked, setP5reloadclicked] = useState(false)
    const [download, setDownload] = useState(false)
    const [downsizeratio, setDownsizeratio] = useState(canva_editratio)

    // artmeta
    const [msgslimit, setMsgslimit] = useState(50)
    const [framecolor, setFramecolor] = useState('white')
    const [seed, setSeed] = useState(null)
    const [orientation, setOrientation] = useState('landscape') // landscape or portrait
    const [qractive, setQractive] = useState('NONE') // NONE, LEFT, MID, RIGHT
    const [space, setSpace] = useState(-1)//-1-not selected, 0-no space, 1:small space
    const [shape, setShape] = useState(0)//-1-not selected, 0-rect, 1: circle

    // alphabet color
    const [alphacolors, setAlphcolors] = useState([])

    // non-alphabet color
    const [showbase, setShowbase] = useState(false)
    const [othercolors, setOthercolors] = useState([])

    // show logo for art
    const [logogate, setLogogate] = useState(true)

    const [showwords, setShowwords] = useState(false)

    const [status, setStatus] = useState('')

    const device = Checkdevice(true)

    useEffect(() => {

        if (firsttime && Object.keys(msgsdata).length > 0 && artmeta && artmeta[artkey]) {
            const { othercolors, alphacolors, framecolor, msgslimit, orientation, qractive, seed, viewtype, space, shape } = artmeta[artkey]

            const { sorted } = msgsdata
            setRankedterms(sorted)
            setOthercolors(othercolors)
            setAlphcolors(alphacolors)
            setSeed(seed)
            setFramecolor(framecolor)
            setMsgslimit(msgslimit)
            setOrientation(orientation)
            setQractive(qractive)
            setFirsttime(false)
            setViewtype(viewtype)
            setShape(shape)
            setSpace(space)
        }
        else {
            if (firsttime && Object.keys(msgsdata).length > 0) {
                const { sorted } = msgsdata
                setRankedterms(sorted)
                setFirsttime(false)
            }
        }
    }, [msgsdata, artmeta, firsttime])

    useEffect(() => {
        if (p5reloadclicked) {
            setSeed(randomIntFromInterval(0, 999999999999999999))
            setP5reloadclicked(false)
        }
    }, [p5reloadclicked])

    useEffect(() => {

        var a3Paper = orientation === 'landscape' ? { width: a3wtint, height: a3htint } : { width: a3htint, height: a3wtint }
        const oneinchpx = 96 / downsizeratio
        setPfive(
            <div id={'shiny'} style={{ width: a3Paper.width / downsizeratio, height: a3Paper.height / downsizeratio }}>
                <ReactP5Wrapper
                    sketch={(p5) => {

                        var sentence_arr = props.msgs['msgtxt'].slice().splice(0, msgslimit)
                        var sentence_str = sentence_arr.join(' ')

                        function applycolor(char) {
                            var gt_alphacolors = alphacolors.length > 0
                            var gt_othercolors = othercolors.length > 0
                            var c
                            if (gt_alphacolors) {
                                var index = alphabets.indexOf(char)
                                c = alphacolors[index]
                                if (c) {
                                    p5.stroke(c)
                                    return c
                                }
                            }

                            if (gt_othercolors) {
                                c = p5.random(othercolors)
                                p5.stroke(c)
                                return c
                            }
                            p5.stroke('white')
                            return 'white'
                        }

                        function drawitem(char, x, y, new_wt, new_ht, startpos) {
                            p5.fill(applycolor(char))
                            if (shape === 0) {
                                space === 0
                                    ? p5.rect(
                                        (y * new_wt + startpos),
                                        (x * new_ht + startpos),
                                        new_wt,
                                        new_ht
                                    )
                                    : p5.rect(
                                        (y * new_wt) + (new_wt / 4) + startpos,
                                        (x * new_ht) + (new_ht / 4) + startpos,
                                        new_wt / 2,
                                        new_ht / 2
                                    )
                            }
                            else if (shape === 1) {
                                space === 0
                                    ? p5.ellipse(
                                        (y * new_wt) + (new_wt * 0.5) + startpos,
                                        (x * new_ht) + (new_ht * 0.5) + startpos,
                                        new_wt,
                                        new_ht
                                    )
                                    : p5.ellipse(
                                        (y * new_wt) + (new_wt / 2) + startpos,
                                        (x * new_ht) + (new_ht / 2) + startpos,
                                        new_wt > new_ht ? new_wt / 2 : new_ht / 2,
                                        new_wt > new_ht ? new_ht / 2 : new_wt / 2,
                                    )
                            }
                        }

                        function drawframe() {
                            p5.push()
                            p5.fill(framecolor)
                            p5.strokeWeight(0)
                            p5.rect(0, 0, p5.width, oneinchpx)
                            p5.rect(0, 0, oneinchpx, p5.height)
                            p5.rect(0, p5.height - oneinchpx, p5.width, p5.height)
                            p5.rect(p5.width - oneinchpx, 0, p5.width, p5.height)
                            p5.endShape(p5.CLOSE);
                            p5.pop()
                        }

                        p5.setup = () => {
                            const canvas = p5.createCanvas(a3Paper.width / downsizeratio, a3Paper.height / downsizeratio);
                            canvas.elt.addEventListener("contextmenu", (e) => e.preventDefault())
                            p5.background('white');
                            p5.angleMode(p5.DEGREES)
                        }

                        var startpos = oneinchpx
                        if (viewtype === 'YES') {
                            var x = 0, y = 0, charpointer = 0, drawframegate = false
                            p5.draw = () => {
                                var wt = p5.width - (startpos * 2)
                                var ht = p5.height - (startpos * 2)
                                var cell_size = Math.sqrt((wt * ht) / parseInt(sentence_str.length, 10))
                                var rows = Math.ceil(ht / cell_size)
                                var cols = Math.ceil(wt / cell_size)
                                var new_wt = wt / cols
                                var new_ht = ht / rows

                                drawitem(sentence_str[charpointer], x, y, new_wt, new_ht, startpos)
                                if (charpointer < sentence_str.length) { charpointer += 1 } // increase pointer
                                y += 1
                                if (y === cols) { y = 0; x += 1 }
                                if (!drawframegate) {
                                    drawframe()
                                    drawframegate = true
                                }
                                if (y === rows) { p5.noLoop() }
                            }
                        }
                        else if (viewtype === 'NO') {
                            p5.draw = () => {
                                p5.noLoop()
                                var wt = p5.width - (startpos * 2)
                                var ht = p5.height - (startpos * 2)
                                var cell_size = Math.sqrt((wt * ht) / parseInt(sentence_str.length, 10))
                                var rows = Math.ceil(ht / cell_size)
                                var cols = Math.ceil(wt / cell_size)
                                var new_wt = wt / cols
                                var new_ht = ht / rows

                                var charpointer = 0
                                for (let x = 0; x < rows; x++) {
                                    for (let y = 0; y < cols; y++, charpointer++) {
                                        drawitem(sentence_str[charpointer], x, y, new_wt, new_ht, startpos)
                                    }
                                }
                                drawframe()
                            }
                        }

                    }}>
                    {!USERFACING && logogate ? Artcommon.logodom(oneinchpx * 1.5, framecolor) : null}
                    {Artcommon.qrcodedom(qractive, urlhandler, oneinchpx * 1.5, oneinchpx)}
                </ReactP5Wrapper>
            </div >
        )
    }, [othercolors, alphacolors, qractive, framecolor, downsizeratio, msgslimit, orientation, viewtype, shape, space, logogate, props, urlhandler])


    useEffect(() => { if (download) { setDownsizeratio(canva_dlratio) } }, [download])

    useEffect(() => {
        if (download) {
            setTimeout(() => {
                html2canvas(document.getElementById('shiny'), {
                    allowTaint: false,
                    useCORS: true,
                    logging: true,
                    backgroundColor: 'white',
                    scrollX: 0,
                    scrollY: -window.scrollY,
                }).then((canvas) => {// download happens here
                    if (!DEBUG_STOPDL) {
                        const link = document.createElement("a");
                        link.download = Math.random() + ".png";
                        document.body.appendChild(link);
                        canvas.toBlob(function (b) {
                            changeDpiBlob(b, 300).then(function (blob) {
                                // use your changed blob
                                link.href = window.URL.createObjectURL(blob);
                                link.click();
                                setDownsizeratio(canva_editratio)
                            })
                        });
                        document.body.removeChild(link);
                    }
                }).catch(e => { alert('Fail to download.') })
            }, 10000)
            setDownload(false)
        }
    }, [pfive, download])

    const save = () => {
        var local_artmeta = artmeta ? artmeta : {}
        local_artmeta[artkey] = { msgslimit, framecolor, seed, orientation, qractive, alphacolors, othercolors, viewtype, shape, space }
        var purchase_artkey1 = artkey + '_SOFT'
        var purchase_artkey2 = artkey + '_HARDSOFT'
        axios({
            method: 'POST',
            url: UPDATE_GBARTSAVE,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, // need token from the web
            data: { artmeta: local_artmeta, urlhandler, artmaintxt, purchase_artkey1, purchase_artkey2 }
        }).then((item) => {
            const { message } = item.data
            if (message === 'SUCCESS') {
                updatestatus('Saved.')
                updateartmeta(local_artmeta)
            }
            else { }
        })
    }

    const updatestatus = (txt) => {
        setStatus(txt)
        setTimeout(() => { setStatus('') }, 1000)
    }

    const reload = () => setP5reloadclicked(!p5reloadclicked)

    const togglebasecolorpalette = () => setShowbase(!showbase)

    const toggledownload = () => {
        var isSafari = window.safari !== undefined;
        if (!isSafari) { setDownload(true) }
        else { alert("Download in Safari is not supported. Please use Google Chrome or Firefox browser to download.") }
    }

    const togglepurchase = (e) => { openpurchase(e.target.value) }

    const toggleword = () => setShowwords(!showwords)

    const updateorient = (e) => setOrientation(e.target.value)

    const updateqrplacement = (e) => qractive === e.target.value ? setQractive('NONE') : setQractive(e.target.value)

    const updateframecolor = (c) => setFramecolor(c)

    const alphacolors_dom = (
        <div id="Mta_rightcontainer">
            {COLOR_PALLETES.map((arr, x) => {
                const selectcolor = (e) => {
                    var new_wordcolormap = {}
                    if (alphacolors.includes(e.target.value)) {
                        let temp = alphacolors.slice()
                        temp.splice(alphacolors.indexOf(e.target.value), 1)
                        setAlphcolors(temp)
                        for (let i = 0; i < temp.length; i++) { new_wordcolormap[rankedterms[i]] = temp[i] }
                    }
                    else {
                        if (alphacolors.length < 26) {
                            setAlphcolors([...alphacolors, e.target.value])
                            for (let i = 0; i < [...alphacolors, e.target.value].length; i++) { new_wordcolormap[rankedterms[i]] = [...alphacolors, e.target.value][i] }
                        }
                    }
                }
                return <div id="Mta_colorrow" key={'row' + x}>
                    {arr.map((color, y) => {
                        return <button
                            id={alphacolors.includes(color) ? "Mta_colorselected" : "Mta_colornonselect"}
                            key={`${x}color${y}`}
                            onClick={selectcolor}
                            value={color}
                            style={{ backgroundColor: color, width: `calc( 90%/${arr.length} )`, height: 20 }}
                        />
                    })}
                </div>
            })}
        </div>
    )

    const alphacolorstitle_dom = (
        showwords
            ? <button className="Art_save Art_shorter" style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: '0px solid transparent', height: 60 }} onClick={toggleword}>
                <div id='Mta_txt' style={{ height: 28, minHeight: 28, marginBottom: 2 }}>Alphabet-Color Selection</div>
                <div id='Mta_txt'>Select color for each character.</div>
            </button>
            : <button className="Art_save Art_shorter" style={{ height: 30 }} onClick={toggleword}>
                <div id='Mta_txt' >Alphabet-Color Selection</div>
            </button>
    )

    const basecolor_btn = (
        showbase
            ? <button className="Art_save Art_shorter" style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: '0px solid transparent', height: 60 }} onClick={togglebasecolorpalette}>
                <div id='Mta_txt' style={{ height: 28, minHeight: 28, marginBottom: 2 }}>Base Color</div>
                <div id='Mta_txt'>{`${othercolors.length} base color selected.`} </div>
            </button>
            : <button className="Art_save Art_shorter" style={{ height: 30 }} onClick={togglebasecolorpalette}>
                <div id='Mta_txt'>Base Color</div>
            </button>
    )

    const basecolorpalette_dom = (
        <div id="Mta_rightcontainer">
            {COLOR_PALLETES.map((arr, x) => {
                const selectcolor = (e) => {
                    if (othercolors.includes(e.target.value)) {
                        let temp = othercolors.slice()
                        temp.splice(othercolors.indexOf(e.target.value), 1)
                        setOthercolors(temp)
                    }
                    else setOthercolors([...othercolors, e.target.value])
                }
                return <div id="Mta_colorrow" key={'rowbased' + x}>
                    {arr.map((color, y) => {
                        return <button
                            id={othercolors.includes(color) ? "Mta_colorselected" : "Mta_colornonselect"}
                            key={`${x}othercolors${y}`}
                            onClick={selectcolor}
                            value={color}
                            style={{ backgroundColor: color, width: `calc( 90%/${arr.length} )`, height: 20 }}
                        />
                    })}
                </div>
            })}
        </div>
    )

    const updatemsgsize = (e) => { setMsgslimit(parseInt(e.target.value, 10)) }

    return <div className={"Artwork_root" + device}>
        {Artcommon.backbtn(changecat)}
        <div key={"p5" + seed} className={"Emojiart_left" + device}> <p />{pfive}<p /></div>
        <div className={"Artwork_right" + device}>
            <div className="Artwork_righttop">
                {USERFACING ? Artcommon.endbtns(reload, save, purchase, artkey) : null}
                {Artcommon.orientbtns(updateorient, orientation)}
                {Artcommon.threeselectionbtns('Message Size', msgslimit, 50, 100, 150, updatemsgsize, '<50', '<100', '<150')}
                {Artcommon.twoselectionbtns('Shape', shape, 0, 1, () => { setShape(0) }, () => { setShape(1) }, 'Rect', 'Circle')}
                {Artcommon.twoselectionbtns('Space', space, 0, 1, () => { setSpace(0) }, () => { setSpace(1) }, 'None', 'Large')}
                {Artcommon.twoselectionbtns('Show Drawing Process', viewtype, 'YES', 'NO', () => { setViewtype('YES') }, () => { setViewtype('NO') }, 'Yes', 'No')}
                {alphacolorstitle_dom}
                {showwords ? alphacolors_dom : null}
                {basecolor_btn}
                {showbase ? basecolorpalette_dom : null}
                {Artcommon.colorpalette(updateframecolor, ['white', 'beige', '#707070', 'black'], framecolor, "Frame Color")}
                {!USERFACING ? Artcommon.qrurlbtns(updateqrplacement, qractive, urlhandler) : null}
                {!USERFACING ? Artcommon.twoselectionbtns('Logo', logogate, true, false, (e) => { setLogogate(logogate ? null : true) }, () => { setLogogate(!logogate ? null : false) }, 'Show Logo', 'Hide Logo') : null}
            </div>
            {USERFACING ? Artcommon.purchasedlbtns(purchase, artkey, togglepurchase, toggledownload) : Artcommon.puchasedbtnsonly(purchase, artkey, () => { }, toggledownload)}
        </div>
        <Noti noti={status} />
    </div>
}

export default Charart