
// import React, { Component } from 'react'
// import { EMPTY, TABLET, MAIN_PINK_COLOR } from '../../../../config/constants'
// import { BUTTONSTYLE } from '../../../../config/cssconstants'
// import { Jfmodal } from '../../../../components';
// import { ALL_VENDOR_SEND_MSG, INIT_CONV_USER } from '../../../../config/api';
// import { Retrieve_personal_info } from '../../../../config/commonfunctions';
// import refresh from '../../../../components/refresh';
// import { whatsapp } from 'react-icons-kit/icomoon/whatsapp'
// import { envelopeO } from 'react-icons-kit/fa/envelopeO'
// import { commentO } from 'react-icons-kit/fa/commentO'
// import { telegram } from 'react-icons-kit/icomoon/telegram'
// import Icon from 'react-icons-kit';

import { useEffect, useState, useRef } from "react"
import { Checkdevice, Portalmodal, refresh, ScreenHeight, ScreenWidth } from "../../../../components"
import { iconwrapper, Retrieve_personal_info } from "../../../../config/commonfunctions"
import { THINEMAIL_ICON, THINTELEGRAM_ICON, THINWHATSAPP_ICON, THINCHAT_ICON } from "../../../../config/svgicons"
import { ALL_VENDOR_SEND_MSG, INIT_CONV_USER } from '../../../../config/api';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios'

import './Renderrequests.css'
import { RECAPTCHA_KEY } from "../../../../config/constants";

// export const THINWHATSAPP_ICON = <Icon size={20} icon={whatsapp} />
// export const ENVELOPE_ICON = <Icon size={20} icon={envelopeO} />
// export const CHAT_ICON = <Icon size={20} icon={commentO} />
// export const TELEGRAM_ICON = <Icon size={20} icon={telegram} />

// export const MESSAGE_TEMPLATE = { // for Renderrequests
//     "Learn More": (acct_email, acct_contact, pricelistname = '{PRICELIST NAME}') => { return "Hi, I would like to learn more of your " + pricelistname + " pricelist. You can reach me at " + acct_email + " or " + acct_contact + " or both. I look forward to your response! Thanks so much!" },
//     "Check Availability": (acct_email, acct_contact) => { return "Hi, I am interested in your services! Would you please let me know of your availability during the date {YOUR DATE}? You can reach me at " + acct_email + " or " + acct_contact + " or both. I look forward to your response! Thanks so much!" },
// }

// class Renderrequests extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {

//             wt: 0,
//             ht: 0,

//             modal_request: false,

//             // user info
//             message: '',
//             date: EMPTY,

//             channel: 'Email', // Whatsapp or Email or Chat

//             // vendor info
//             vt: this.props.vt,

//             token: EMPTY,
//             _id: EMPTY,

//             // error
//             displaymsg: EMPTY,
//             disablesendbtn: false,

//         }
//     }
//     componentDidMount = async () => {
//         this.updateWindowDimensions()
//         window.addEventListener('resize', this.updateWindowDimensions)
//         const { token, _id } = Retrieve_personal_info()
//         this.setState({
//             token: token,
//             _id: _id,
//         })
//     }
//     componentWillUnmount() {
//         window.removeEventListener('resize', this.updateWindowDimensions)
//     }
//     updateWindowDimensions = () => {
//         this.setState({ wt: window.innerWidth, ht: window.innerHeight });
//     }

//     // dom elements
//     RenderSendRequest = () => {
//         const { token, disablesendbtn } = this.state
//         const { message, channel } = this.state
//         const { whatsapp_link, vti_id } = this.props
//         return (
//             <div style={{ width: '100%', padding: '10px 0px' }}>
//                 <button
//                     disabled={disablesendbtn ? true : false}
//                     style={{ ...BUTTONSTYLE, borderRadius: 5, backgroundColor: disablesendbtn ? 'var(--maindark)' : MAIN_PINK_COLOR, width: '100%', color: 'white', border: 'none', boxShadow: 'rgba(25, 25, 25, 0.2) 1px 1px 5px', }}
//                     onClick={() => {
//                         this.setState({ disablesendbtn: true }, async () => {
//                             if (message.length === 0) {
//                                 this.setState({ displaymsg: 'You cannot send an empty message' })
//                                 return
//                             }
//                             if (channel === 'Email') {
//                                 try {
//                                     let options = {
//                                         method: 'POST',
//                                         url: ALL_VENDOR_SEND_MSG,
//                                         headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
//                                         data: { message: message, vti_id: vti_id }
//                                     }
//                                     await axios(options).then((res) => {
//                                         const { message } = res.data
//                                         if (message === 'SUCCESS') {
//                                             this.setState({ displaymsg: 'Your message has been successfully sent.', disablesendbtn: true })
//                                         }
//                                         else if (message === 'EMAIL_QUOTA_REACH') {
//                                             this.setState({ displaymsg: 'Your email quota has been reached. Email quota will be reset on the next day. Or you could use other form of message channels we provided above.', disablesendbtn: false })
//                                         }
//                                     }).catch(e => {
//                                         refresh()
//                                     })
//                                 }
//                                 catch (e) {
//                                     refresh()
//                                 }

//                             }
//                             else if (channel === 'Whatsapp') {
//                                 window.open('https://api.whatsapp.com/send?phone=' + whatsapp_link + '&text=' + message)
//                             }
//                             else if (channel === 'Chat') {
//                                 try {
//                                     let options = {
//                                         method: 'POST',
//                                         url: INIT_CONV_USER,
//                                         headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
//                                         data: { message: message, vti_id: vti_id }
//                                     }
//                                     await axios(options).then((res) => {
//                                         const { message } = res.data

//                                         if (message === 'REDIRECT') {
//                                             window.location = '/chat'
//                                         }
//                                         else if (message === 'SUCCESS') {
//                                             window.location = '/chat'
//                                         }

//                                     }).catch(e => {
//                                         console.log(e)
//                                     })
//                                 }
//                                 catch (e) {
//                                     console.log(e)
//                                 }
//                             }

//                         })
//                     }}
//                 >
//                     Send
//                 </button>
//             </div>
//         )
//     }
//     RenderRequestButton = () => {
//         const { token, _id } = this.state
//         const { acct_type, button_text = 'Enquire', button_type = 'BIG', from} = this.props
//         var requestBtnStyle = {}
//         var requestBtnStyleRoot = {}
//         if (button_type === 'BIG') {
//             requestBtnStyleRoot = { display: 'flex', justifyContent: 'flex-end', margin: '10px 0px 10px 0px', alignItems: 'center' }
//             requestBtnStyle = { ...BUTTONSTYLE, height: 65, backgroundColor: MAIN_PINK_COLOR, color: 'white', outline: 'none', border: '1px solid transparent', boxShadow: 'rgba(25, 25, 25, 0.2) 1px 1px 5px' }
//         }
//         else if (button_type === 'SMALL') {
//             requestBtnStyleRoot = { display: 'flex', justifyContent: 'flex-end', margin: '5px 0px 0px 0px', alignItems: 'center' }
//             requestBtnStyle = { ...BUTTONSTYLE, margin: 0, backgroundColor: MAIN_PINK_COLOR, color: 'white', outline: 'none', border: '1px solid transparent', boxShadow: 'rgba(25, 25, 25, 0.2) 1px 1px 5px', }
//         }
//         else if (button_type === 'SMALLEST') {
//             requestBtnStyleRoot = { display: 'flex', justifyContent: 'flex-end', margin: '5px 0px 0px 0px', alignItems: 'center' }
//             requestBtnStyle = { ...BUTTONSTYLE, margin: 0, backgroundColor: MAIN_PINK_COLOR, color: 'white', outline: 'none', border: '1px solid transparent', boxShadow: 'rgba(25, 25, 25, 0.2) 1px 1px 5px', }
//         }

//         if(from==='Pricelist'){
//             requestBtnStyle['borderRadius'] = 5
//         }

//         if (acct_type === 'USER' && token !== EMPTY && _id !== EMPTY) {

//             const enquireclick = () => {
//                 this.setState({ modal_request: !this.state.modal_request }, () => {
//                     if (this.props.from !== 'Pricelist') {
//                         try { this.props.hideenquireminspend() }// only available from vendorlookup
//                         catch (e) { }
//                     }
//                 })
//             }

//             return (
//                 <div style={requestBtnStyleRoot}>
//                     <button style={requestBtnStyle} onClick={enquireclick}>
//                         <font> {button_text} </font>
//                     </button>
//                 </div>
//             )
//         }
//         else {
//             return (
//                 <div style={requestBtnStyleRoot}>
//                     <button disabled style={requestBtnStyle} >
//                         <font> {button_text} </font>
//                     </button>
//                 </div>
//             )
//         }

//     }


//     RenderModal = () => {
//         const { button_text = 'Enquire', acct_email, acct_contact, from = 'none', pricelistname = '', telegram_link } = this.props //acct_name,
//         const { wt, modal_request, message, displaymsg, channel, disablesendbtn } = this.state
//         const device = wt < TABLET ? 'MOBILE' : 'WEB'

//         const CHANNELS_MAP = {
//             'Email': ENVELOPE_ICON,
//             'Whatsapp': THINWHATSAPP_ICON,
//             'Chat': CHAT_ICON,
//             'Telegram': TELEGRAM_ICON
//         }
//         const CHANNELLS_KEY = Object.keys(CHANNELS_MAP)

//         return (
//             <Jfmodal
//                 show={modal_request}
//                 title={button_text}
//                 onHide={() => this.setState({ modal_request: false }, () => {
//                     if (this.props.from !== 'Pricelist') {
//                         try { this.props.hideenquireminspend() } // only available from vendorlookup
//                         catch (e) { }
//                     }
//                 })}
//                 device={device}
//                 content={
//                     <div className={device === 'MOBILE' ? "rrequests_enquiremodalrootMOBILE" : "rrequests_enquiremodalrootWEB"} >
//                         <div className="rrequests_title">Message Channels</div>
//                         <div className="rrequests_row">
//                             {CHANNELLS_KEY.map((key, i) => {
//                                 const { channel } = this.state
//                                 if (key === 'Whatsapp' && this.props.whatsapp_link && this.props.whatsapp_link.length === 0) {
//                                     return null
//                                 }
//                                 else if (key === 'Telegram' && this.props.telegram_link && this.props.telegram_link.length === 0) {
//                                     return null
//                                 }
//                                 return <div
//                                     key={key + i}
//                                     className={channel === key ? "rrequests_msgchnselected" : "rrequests_msgchn"}
//                                     onClick={() => this.setState({ channel: key })}>
//                                     <div className="rrequests_icon">{CHANNELS_MAP[key]}</div>
//                                     <div>{key}</div>
//                                 </div>
//                             })}
//                         </div>

//                         {channel !== 'Telegram' ? <div className="rrequests_title">Message Template</div> : null}
//                         <div style={{ display: 'flex', flexDirection: 'row', }}>
//                             {channel !== 'Telegram' && Object.keys(MESSAGE_TEMPLATE).map((key, index) => {
//                                 return (
//                                     <button
//                                         key={key}
//                                         className="rrequests_templatebtn"
//                                         onClick={() => {
//                                             var msg
//                                             var contact = acct_contact && acct_contact.length > 0 ? acct_contact : '{YOU CONTACT}'
//                                             if (from === 'Pricelist') msg = MESSAGE_TEMPLATE[key](acct_email, contact, pricelistname)
//                                             else msg = MESSAGE_TEMPLATE[key](acct_email, contact)
//                                             this.setState({ message: msg })
//                                         }}
//                                     >
//                                         {key}
//                                     </button>
//                                 )
//                             })}
//                         </div>

//                         {channel !== 'Telegram' ? <div className="rrequests_title">Message</div> : null}
//                         {channel !== 'Telegram' ? <textarea
//                             style={{ fontSize: 16, padding: 5, outline: 'none' }}
//                             multiline
//                             rows="4"
//                             value={message}
//                             onChange={(event) => { this.setState({ message: event.target.value }) }}
//                             margin="dense"
//                             variant="outlined"
//                             fullWidth
//                         /> : null}
//                         {channel !== 'Telegram'
//                             ? this.RenderSendRequest()
//                             : <a className="rrequests_telegramsendroot" style={{ backgroundColor: disablesendbtn ? 'var(--maindark)' : MAIN_PINK_COLOR }} href={`tg://resolve?domain=${telegram_link}`}>Send</a>}

//                         <div className="rrequests_displaymsg">{displaymsg}</div>

//                     </div >
//                 }
//             />
//         )

//     }

//     render() {
//         return (
//             <div style={{ float: 'left' }}>
//                 {this.RenderRequestButton()}
//                 {this.RenderModal()}
//             </div>
//         )
//     }
// }

// export default Renderrequests



const portalmidcss_web = {
    width: 600,
    height: 660,
    top: 'calc( 50% - 330px )',
    backgroundColor: 'white',
    left: 0, right: 0,
    margin: 'auto',
    transform: 'none',
}

const portalmidcss_mobile = {
    width: '100%',
    height: '100%',
    top: 0,
    backgroundColor: 'white',
    left: 0, right: 0,
    margin: 'auto',
    transform: 'none'
}

const portalmidcsswtlarger_mobile = {
    width: '100%',
    height: '100%',
    top: 0,
    backgroundColor: 'white',
    left: 0, right: 0,
    margin: 'auto',
    transform: 'none',
    overflowX: 'scroll'
}

const iconcss = { width: 25, height: 25, display: 'flex', alignItems: 'center' }

export const MESSAGE_TEMPLATE = { // for Renderrequests
    "Learn More": (acct_email, acct_contact, pricelistname = '{PRICELIST NAME}') => { return `Hello, I'm interested in learning more about your ${pricelistname}. Please feel free to contact me at ${acct_email} or ${acct_contact}, or you can reach out using both methods. I look forward to your response! Thank you very much!` },
    "Check Availability": (acct_email, acct_contact) => { return `Hello, I'm interested in your services! Could you please let me know your availability for the date {YOUR DATE}? You can reach me at ${acct_email} or ${acct_contact}, or feel free to use both methods. I look forward to your response! Thank you very much!` },
    // "Check Availability Pricelist" : (acct_email, acct_contact) => { return `Hello, I'm interested in your services! Could you please let me know your availability for the date {YOUR DATE}? You can reach me at ${acct_email} or ${acct_contact}, or feel free to use both methods. I look forward to your response! Thank you very much!` },
}


function Renderrequests(props) {
    const {
        enquirebtnid,
        from = 'none', //  Pricelist || Pricelistcard || Bubble || Comparison || Vendorlookup
        displayname,
        button_text = 'Enquire', // 'BIG' || 'SMALL' || 'SMALLEST'
        pricelistname = '',
        acct_type,
        acct_email,
        acct_contact,
        telegram_link = '',
        whatsapp_link = '',
        hideenquireminspend = () => { },
        vti_id,
    } = props


    const [token, setToken] = useState(null)
    const [_id, set_Id] = useState(null)
    const [modalrequest, setModalrequest] = useState(false)
    const [message, setMessage] = useState('')
    const [displaymsg, setDisplaymessage] = useState('')
    const [channel, setChannel] = useState('')
    const [disablesendbtn, setDisablesendbtn] = useState('')
    const [showcaptcha, setShowcaptcha] = useState(false)   // to detect if captchais verified or not (FOR FORGT PW)
    const captchaRef = useRef(null) // for captcha

    useEffect(() => {
        setShowcaptcha(false)
        setDisablesendbtn('')
    }, [])

    const CHANNELS_MAP = {
        'Email': iconwrapper(THINEMAIL_ICON, iconcss),
        'Whatsapp': iconwrapper(THINWHATSAPP_ICON, iconcss),
        'Chat': iconwrapper(THINCHAT_ICON, iconcss),
        'Telegram': iconwrapper(THINTELEGRAM_ICON, iconcss)
    }
    const CHANNELLS_KEY = Object.keys(CHANNELS_MAP)

    const device = Checkdevice()
    const wt = ScreenWidth()
    const ht = ScreenHeight()

    useEffect(() => {
        const { token, _id } = Retrieve_personal_info()
        setToken(token)
        set_Id(_id)
    }, [])

    useEffect(() => {
        document.body.style.overflow = modalrequest ? 'hidden' : ''
        if (modalrequest && from !== 'Pricelist') {
            hideenquireminspend()
        }
    }, [modalrequest])

    const sendbtn_dom = <button
        disabled={disablesendbtn ? true : false}
        className={'rrequests_sendbtn'}
        onClick={() => {
            if (message.length === 0) {
                setDisablesendbtn(true)
                setDisplaymessage('You cannot send an empty message')
                return
            }
            if (channel === 'Email') {
                // setDisablesendbtn(true)
                setShowcaptcha(true)
            }
            else if (channel === 'Whatsapp') {
                setDisablesendbtn(false)
                window.open('https://api.whatsapp.com/send?phone=' + whatsapp_link + '&text=' + message)
            }
            else if (channel === 'Chat') {
                if (message.length > 0) {
                    setDisablesendbtn(false)
                    try {
                        let options = {
                            method: 'POST',
                            url: INIT_CONV_USER,
                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                            data: { message: message, vti_id: vti_id }
                        }
                        axios(options).then((res) => {
                            const { message } = res.data

                            if (message === 'REDIRECT') {
                                window.location = '/chat'
                            }
                            else if (message === 'SUCCESS') {
                                window.location = '/chat'
                            }

                        }).catch(e => {
                            console.log(e)
                        })
                    }
                    catch (e) {
                        console.log(e)
                    }
                }
                else {
                    setDisplaymessage('You cannot send empty email.')
                }

            }

        }}
    >
        Send
    </button>

    const captcha_dom = <ReCAPTCHA
        ref={(el) => { captchaRef.current = el }}
        sitekey={RECAPTCHA_KEY}
        onChange={(captchares) => {

            if (captchares && captchares.length > 0) {
                try {
                    let options = {
                        method: 'POST',
                        url: ALL_VENDOR_SEND_MSG,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                        data: { message: message, vti_id: vti_id, captcha: captchares }
                    }
                    axios(options).then((res) => {
                        const { message } = res.data
                        if (message === 'SUCCESS') {
                            setDisplaymessage('Your message has been successfully sent.')
                            setDisablesendbtn(false)
                        }
                        else if (message === 'EMAIL_QUOTA_REACH') {
                            setDisplaymessage('Your email quota has been reached. Email quota will be reset on the next day. Or you could use other form of message channels we provided above.')
                            setDisablesendbtn(false)
                        }

                        setTimeout(() => {
                            setDisplaymessage('')
                        }, 5000)

                    }).catch(e => {
                        refresh()
                    })
                }
                catch (e) {
                    refresh()
                }
            }


        }}
    />

    const sendingbtn_dom = <div style={{ height: 45, display: 'flex', width: '100%', placeContent: 'center' }}>
        Sending...
    </div>

    const enquire_btn = <button
        className={'rrequests_sendbtn rrequests_enquirebtn'}
        onClick={() => { document.getElementById('Renderrequests_telegramclick').click() }}
    >
        Enquire
    </button>

    const messagetemplate_dom = <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="rrequests_title">Message Template</div>
        <div className="rrequests_row">
            {Object.keys(MESSAGE_TEMPLATE).map((key, index) => {
                return (
                    <button
                        key={key}
                        className="rrequests_templatebtn"
                        onClick={() => {
                            setShowcaptcha(false)
                            var msg
                            var contact = acct_contact && acct_contact.length > 0 ? acct_contact : '{YOU CONTACT}'
                            if (from === 'Pricelist') msg = MESSAGE_TEMPLATE[key](acct_email, contact, pricelistname)
                            else msg = MESSAGE_TEMPLATE[key](acct_email, contact)
                            setMessage(msg)
                        }}
                    >
                        {key}
                    </button>
                )
            })}
        </div>
    </div>

    const messageinput_dom = <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="rrequests_title">Message</div>
        <textarea
            className="rrequests_txtarea"
            rows="5"
            value={message}
            disabled={showcaptcha}
            onChange={(e) => { setMessage(e.target.value) }}
            margin="dense"
            variant="outlined"
        />
    </div>

    const requestportal = <Portalmodal
        portaltype={'commonportal'}
        open={modalrequest}
        onClose={() => {
            setModalrequest(false)
            setMessage('')
            setDisplaymessage('')
            setChannel('')
            setDisablesendbtn('')
        }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={(device === 'WEB' || device === 'TABLET') ? portalmidcss_web : wt > ht ? portalmidcsswtlarger_mobile : portalmidcss_mobile}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className='Portalmodal_vtroot' style={{ fontFamily: 'Lato', overflowX: 'hidden', position: 'relative' }} >
            <div className='Rsvpexplorer_portaltitle' style={{ position: 'absolute', top: 0 }}>Enquiry</div>

            {{
                'Bubble': <div className="rrequests_rownowrap">
                    {`Send an enquiry to `}<div className='rrequests_displayname'>{' ' + displayname}</div>.
                </div>,
                'Pricelistcardinbuilt': <div className="rrequests_rownowrap">
                    {`Send an enquiry to `}<div className='rrequests_displayname'>{' ' + displayname}</div>{` ( ${pricelistname} )`}.
                </div>,
                'Pricelist': <div className="rrequests_rownowrap">
                    {`Send an enquiry to `}<div className='rrequests_displayname'>{' ' + displayname}</div>{` ( ${pricelistname} )`}.
                </div>,
                'Comparison': <div className="rrequests_rownowrap">
                    {`Send an enquiry to `}<div className='rrequests_displayname'>{' ' + displayname}</div>.
                </div>,
                'Favouritevendor': <div className="rrequests_rownowrap">
                    {`Send an enquiry to `}<div className='rrequests_displayname'>{' ' + displayname}</div>.
                </div>,
                'Vendorlookup': <div className="rrequests_rownowrap">
                    {`Send an enquiry to `}<div className='rrequests_displayname'>{' ' + displayname}</div>.
                </div>
            }[from]}

            <>
                <div className="rrequests_title">Message Channels</div>
                <div className="rrequests_row">
                    {CHANNELLS_KEY.map((key, i) => {
                        if ((key === 'Whatsapp' && whatsapp_link.length === 0) || (key === 'Telegram' && telegram_link.length === 0)) {
                            return null
                        }
                        else {
                            return <div
                                key={key + i}
                                className={channel === key ? "rrequests_msgchnselected" : "rrequests_msgchn"}
                                onClick={() => {
                                    setChannel(key)
                                    setShowcaptcha(false)
                                }}
                            >
                                <div className="rrequests_icon">{CHANNELS_MAP[key]}</div>
                                <div className="rrequests_icontxt">{key}</div>
                            </div>
                        }

                    })}
                </div>
            </>

            {{
                "Telegram": <>
                    <div style={{ width: '100%', display: 'flex', placeContent: 'center', padding: 10 }}>
                        <a id='Renderrequests_telegramclick' className="rrequests_telegramsendroot" href={`tg://resolve?domain=${telegram_link}`} />
                        {enquire_btn}
                    </div>
                </>,
                "Whatsapp": <>
                    {messagetemplate_dom}
                    {messageinput_dom}
                    {message.length > 0
                        ? <div style={{ width: '100%', display: 'flex', placeContent: 'flex-end', padding: 10 }}>
                            {!disablesendbtn
                                ? sendbtn_dom
                                : null}
                        </div>
                        : null}
                </>,
                "Email": <>
                    {messagetemplate_dom}
                    {messageinput_dom}
                    {message.length > 0
                        ? <div style={{ width: '100%', display: 'flex', placeContent: 'flex-end', padding: 10 }}>
                            {!disablesendbtn
                                ? showcaptcha ? captcha_dom : sendbtn_dom
                                : sendingbtn_dom}
                        </div>
                        : null}
                </>,
                "Chat": <>
                    {messagetemplate_dom}
                    {messageinput_dom}
                    {message.length > 0
                        ? <div style={{ width: '100%', display: 'flex', placeContent: 'flex-end', padding: 10 }}>
                            {!disablesendbtn
                                ? sendbtn_dom
                                : null}
                        </div>
                        : null}
                </>,
            }[channel]}


            <div className="rrequests_displaymsg">{displaymsg}</div>

        </div>
    </Portalmodal>

    return <div className={`rrequests_root rrequests_root${from}`} style={{ display: 'flex', placeContent: 'center' }}>
        {acct_type === 'USER' && token !== '' && _id !== ''
            ? <button id={enquirebtnid + '_enquirebtnid'} className={`rrequests_btn rrequest_${from} rrequest_${from}_${device}`} onClick={() => setModalrequest(true)}>
                {button_text}
            </button>
            : <div className="rrequests_txtroot">
                <button className={`rrequests_btn rrequest_${from} rrequest_${from}_${device}`} disabled  >
                    {button_text}
                </button>
            </div>}
        {requestportal}
    </div>

}
export default Renderrequests