import React, { useEffect, useRef, useState } from 'react'
import Gbmessage from '../../../../components/Gbmessage/Gbmessage';
import './Rightexpanded.css'

function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
}

function Rightexpanded(props) {

    const { msgduration, msgtype, casttype } = props //  msgframetype,images
    const msgend = useRef(null)
    const [allmsgs, setAllmsgs] = useState([]) // all unique msgs
    const [activemsgs, setActivemsgs] = useState([]) // msg to show when start looping
    const [activeids, setActiveids] = useState([]) // all unique ids of msgs
    const [firsttime, setFirsttime] = useState(true) // when first loaded
    const [intervalfirsttime, setIntervalfirsttime] = useState(true)
    // const [casttype, setCasttype] = useState(casttype)

    function getIndex() {
        var index // val to return 

        if (activemsgs.length === 0 && intervalfirsttime) {
            setIntervalfirsttime(false)
            return props.startindex
        }

        // if (activemsgs.length > 0) { // loop if no new msg exist
        //     const latest_active_msg = activemsgs[activemsgs.length - 1]
        //     const latest_active_id = latest_active_msg['id']
        //     const id_index_in_activeids = activeids.indexOf(latest_active_id)
        //     index = id_index_in_activeids + 1
        //     if (index >= allmsgs.length) {
        //         index = props.startindex
        //     }
        // }
        if (activemsgs.length > 0) {
            for (let i = 0; i < allmsgs.length; i++) {
                const { id } = allmsgs[i]
                const latest_msgid = activemsgs[activemsgs.length - 1].id
                // console.log(id, latest_msgid)
                if (id === latest_msgid) {
                    if ((i + 1) === allmsgs.length) {
                        index = 0
                    }
                    else {
                        index = i + 1
                    }
                    break
                }
            }
        }

        // priority given to msg that havent appear before
        for (let i = props.startindex; i < allmsgs.length; i++) { // msg index that havent appear in active msgs
            const { id } = allmsgs[i]
            if (!activeids.includes(id)) {
                index = i
                break
            }
        }

        return index
    }

    // useEffect(() => {
    //     setCasttype(casttype)
    // }, [props.casttype])

    useInterval(() => {
        if (allmsgs.length > 0 && msgend && msgend.current) {
            const new_msg = allmsgs[getIndex()] // getindex decide which msg to display
            const id = new_msg['id']
            if (!activeids.includes(id)) {
                setActiveids((activeids) => { return [...activeids, id] })
            }

            setActivemsgs((activemsgs) => {
                var new_activeimgs = activemsgs.slice()
                if (activemsgs.length > 25) {
                    new_activeimgs.splice(0, 14)
                }
                return [...new_activeimgs, new_msg]
            })
            try {
                msgend.current.scrollIntoView({ behavior: 'smooth' })
            }
            catch (e) { }
        }
    }, msgduration);

    useEffect(() => {
        const { msgs } = props
        if (msgs.length > 0 && firsttime) {
            setAllmsgs(msgs)
            setFirsttime(false)
        }
    }, [props.msgs])

    useEffect(() => {
        const { newmsgs, emptynew } = props
        if (newmsgs.length > 0) {
            // ensure all msgs are unique
            var new_allmsgs = allmsgs.slice()
            for (let i = 0; i < newmsgs.length; i++) {
                var samesame = false
                for (let j = 0; j < allmsgs.length; j++) {
                    if (newmsgs[i]['id'] === allmsgs[j]['id']) {
                        samesame = true
                        break
                    }
                }
                if (!samesame) { new_allmsgs.push(newmsgs[i]) }
            }
            setAllmsgs(new_allmsgs)
            emptynew()
        }
    }, [props.newmsgs])

    useEffect(() => {
        if (!firsttime) {
            const { modrmvmsgs } = props
            const modrmvmsgs_ids = modrmvmsgs.map(({ id }) => { return id })

            var temp_activeids = activeids.slice()
            var temp_activemsgs = activemsgs.slice()
            var temp_allmsgs = allmsgs.slice()
            for (let i = 0; i < modrmvmsgs_ids.length; i++) {
                const curr_id = modrmvmsgs_ids[i]
                for (let j = 0; j < temp_activeids.length; j++) {
                    if (temp_activeids[j]['id'] === curr_id) {
                        temp_activeids.splice(j, 1)
                    }
                }
                for (let j = 0; j < temp_activemsgs.length; j++) {
                    if (temp_activemsgs[j]['id'] === curr_id) {
                        temp_activemsgs.splice(j, 1)
                    }
                }
                for (let j = 0; j < temp_allmsgs.length; j++) {
                    if (temp_allmsgs[j]['id'] === curr_id) {
                        temp_allmsgs.splice(j, 1)
                    }
                }
            }

            setActivemsgs(temp_activemsgs)
            setActiveids(temp_activeids)
            setAllmsgs(temp_allmsgs)
        }
    }, [props.modrmvmsgs])

    const guestmsgselect = (e) => {
        var index
        for (let i = 0; i < props.msgs.length; i++) {
            if (e.target.id === props.msgs[i].id) {
                index = i;
                break;
            }
        }
        props.updatestart(index)
    }

    // const BANNER_MAP = {
    //     0: null,
    //     1: <div>couple images</div>,
    //     2: <div className="rightex_banner" style={{ backgroundImage: `url(${images[2]})` }} />,
    //     3: <div>icons</div>
    // }

    const MSGTYPERIGHT_MAP = {
        0: { minWidth: '100vw', maxWidth: '100vw' }, // img
        1: { minWidth: '100vw', maxWidth: '100vw' }, // msgbox
        2: { width: '30vw', minWidth: '30vw' }, // img + msgbox
        // 3: { width: '30vw', minWidth: '30vw' }, // img + small msgbox
        // 4: { minWidth: '0vw', maxWidth: '0vw' }, // img + bullet
    }

    const MSGTYPE_MAP = {
        0: { minWidth: '0vw', maxWidth: '0vw', visibility: 'hidden' }, // img
        1: { minWidth: '50vw', maxWidth: '50vw' }, // msgbox
        2: { width: '30vw', minWidth: '30vw' }, // img + msgbox
        // 3: { width: '30vw', minWidth: '30vw' }, // img + small msgbox
        // 4: { minWidth: '0vw', maxWidth: '0vw' }, // img + bullet
    }

    return <div key={'righteexpanded' + casttype + '_' + msgtype} className={'rightex_root'} style={{ ...MSGTYPERIGHT_MAP[casttype], minHeight: activemsgs.length % 2 === 0 ? 'max-content' : '' }}>
        {/* {BANNER_MAP[msgframetype]} */}
        <div className="rightex_col" style={{ ...MSGTYPE_MAP[casttype] }}>
            {casttype === 0
                ? null
                : casttype === 1 || casttype === 2 ? activemsgs.map((msg, i) => {
                    return <Gbmessage
                        key={'msgexpand' + i + '_' + casttype + '_' + msgtype}
                        msgtype={parseInt(msgtype, 10)}
                        msg={msg}
                        selected={null}
                        onselect={guestmsgselect}
                        style={{
                            // opacity: activemsgs.length - 1 === i ? 1 : (1 - (activemsgs.length - 1 - i) * 0.4),
                            opacity: activemsgs.length - 1 === i ? 1 :0,
                            transition : 'opacity 5s cubic-bezier(0.42, 0, 0.62, 1.7) 0s'
                        }} />
                })
                    : null}
        </div>
        <div ref={msgend} id={'HEADER'} />
    </div>

}

export default Rightexpanded;