import { production } from "../../config/constants"
import { S3CLOUD_CLOUDFRONT_ASSET_HEADER } from "../../config/s3directories"

var weddinghome, weddingvendors, eventhome, eventvendors, projecthome_wedding, pricelisthome_wedding, pricelisthome_event, projecthome_event, guesthome, tools, market, promo

const header = S3CLOUD_CLOUDFRONT_ASSET_HEADER

if (production) {
    weddinghome = header + 'weddinghome.jpg'
    weddingvendors = header + 'weddingvendors.jpg'
    eventhome = header + 'eventhome.jpg'
    eventvendors = header + 'eventvendors.jpg'
    projecthome_wedding = header + 'projecthome-wedding.jpg'
    pricelisthome_wedding = header + 'pricelisthome-wedding.jpg'
    pricelisthome_event = header + 'pricelisthome-event.jpg'
    projecthome_event = header + 'projecthome-event.jpg'
    guesthome = header + 'guesthome.jpg'
    tools = header + 'tools.jpg'
    market = header + 'market.jpg'
    promo = header + 'promo.jpeg'
}
else {
    weddinghome = 'https://i.ibb.co/BqP40mf/weddinghome.jpg'
    weddingvendors = 'https://i.ibb.co/6bj7Wtw/weddingvendors.jpg'
    eventhome = 'https://i.ibb.co/7CgCkfQ/eventhome.jpg'
    eventvendors = 'https://i.ibb.co/cx281Cf/eventvendors.jpg'
    projecthome_wedding = 'https://i.ibb.co/4mXvMHV/projecthome-wedding.jpg'
    pricelisthome_wedding = 'https://i.ibb.co/HHyVQ8t/pricelisthome-wedding.jpg'
    pricelisthome_event = 'https://i.ibb.co/GP6HMTc/pricelisthome-event.jpg'
    projecthome_event = 'https://i.ibb.co/rMVyj82/projecthome-event.jpg'
    guesthome = 'https://i.ibb.co/L0fPZWK/guesthome.jpg'
    tools = 'https://i.ibb.co/h1p5D31/photo-1507504031003-b417219a0fde.jpg'
    market = 'https://i.ibb.co/VM7P13n/market.jpg'
    promo = 'https://i.ibb.co/cgy2CTs/tools.jpg'
}

export {
    weddinghome,
    eventhome,
    eventvendors,
    weddingvendors,
    guesthome,
    projecthome_wedding,
    projecthome_event,
    pricelisthome_wedding,
    pricelisthome_event,
    tools,
    market,
    promo
}


