import React, { useState, useEffect } from 'react'
import { iconwrapper } from '../../../../../../config/commonfunctions'
import { INVITATIONTYPES_ARR, TABLET, production, } from '../../../../../../config/constants'
import { S3CLOUD_CLOUDFRONT_ASSET } from '../../../../../../config/s3directories'
import { TICK_ICON } from '../../../../../../config/svgicons'
import { inputdescribe_dom, partialtitle_dom } from '../Commonelements/Commonelements'
import Explain from './../Explain'
import './Templates.css'

const selected_css = '3px solid var(--mainpink)'
const unselected_css = '1px solid var(--maindark)' // rgb(218, 218, 218)
const imagecolor = 'rgb(214, 214, 214)'
const TITLE = 'Templates'
const TEXT = `Select different template and background color for your event invtitation. We have currently 2 different templates for you to choose from.`
const images_full_link = (num, size) => `${S3CLOUD_CLOUDFRONT_ASSET}couple/couple${num}_${size}.png`

const prebuilttemplates = [
    {
        name: 'Amaryllis Purpurea',
        templatecolors: ['#FFFFF8', '#fcf8e8', '#fcf5e3'],
        templatecolors_img_map: { // img showing based on template color
            '#FFFFF8': S3CLOUD_CLOUDFRONT_ASSET + 'prebuilttemplate/Amaryllis_Purpurea.png',
            '#fcf8e8': S3CLOUD_CLOUDFRONT_ASSET + 'prebuilttemplate/Amaryllis_Purpurea.png',
            '#fcf5e3': S3CLOUD_CLOUDFRONT_ASSET + 'prebuilttemplate/Amaryllis_Purpurea.png',
        },
        templatecolors_data_map: { // data based on template color
            '#FFFFF8': { "menustyle": { "backgroundColor": "#FFFFF8", "color": "#333333", "borderColor": "#333333" }, "templatecolor": "#FFFFF8", "fc": "#333333", },
            '#fcf8e8': { "menustyle": { "backgroundColor": "#fcf8e8", "color": "#333333", "borderColor": "#333333" }, "templatecolor": "#fcf8e8", "fc": "#333333", },
            "#fcf5e3": { "menustyle": { "backgroundColor": "#fcf5e3", "color": "#292e2f", "borderColor": "#292e2f" }, "templatecolor": "#fcf5e3", "fc": "#292e2f" }
        },
        svgoverlay_map: {
            '#FFFFF8': { mainimg: { svgitem: 1, mode: 2, color: "#FFFFF8", replay: true, speed: 0, svgposition: 2, sw: 45 } },
            '#fcf8e8': { mainimg: { svgitem: 1, mode: 2, color: "#fcf8e8", replay: true, speed: 0, svgposition: 2, sw: 45 } },
            '#fcf5e3': { mainimg: { svgitem: 1, mode: 2, color: "#fcf5e3", replay: true, speed: 0, svgposition: 2, sw: 45 } }
        },
        common_data: { "ntm": 0, "ntt": 1, "ntw": 1, "ffobj": { "priff": "Montserrat", "secff": "Cormorant Upright" }, "styleborder": 4, "stylebordercolor": "#c8c09e", "styleborderbr": true, "styleborderwt": false, "styletype": "Amaryllis_Purpurea", "styleorientationmobile": 0, "styleorientation": 0, "styleformationmobile": 0, "styleformation": 2, "sep": `${S3CLOUD_CLOUDFRONT_ASSET}floral/Amaryllis_Purpurea/banner/banner.png`, "menutype": "btmnavbar", },
        images: [
            images_full_link(10, 'lg'),
            images_full_link(10, 'sm')
        ],
    },
    {
        name: 'Blanket Flower',
        templatecolors: ['#FFF7EE', '#fcf8e8',],
        templatecolors_data_map: {
            "#FFF7EE": { "menustyle": { "backgroundColor": "#FFF7EE", "color": "#292e2f", "borderColor": "#292e2f" }, "templatecolor": "#FFF7EE", "fc": "#292e2f" },
            "#fcf8e8": { "menustyle": { "backgroundColor": "#fcf8e8", "color": "#292e2f", "borderColor": "#292e2f" }, "templatecolor": "#fcf8e8", "fc": "#292e2f" },
        },
        templatecolors_img_map: {
            '#FFF7EE': S3CLOUD_CLOUDFRONT_ASSET + 'prebuilttemplate/Blanket_Flower.png',
            '#fcf8e8': S3CLOUD_CLOUDFRONT_ASSET + 'prebuilttemplate/Blanket_Flower.png',
        },
        svgoverlay_map: {
            '#FFF7EE': { mainimg: { svgitem: 1, mode: 2, color: "#FFF7EE", replay: true, speed: 0, svgposition: 2, sw: 45 } },
            '#fcf8e8': { mainimg: { svgitem: 1, mode: 2, color: "#fcf8e8", replay: true, speed: 0, svgposition: 2, sw: 45 } }
        },
        common_data: {
            "ntm": 0, "ntt": 1, "ntw": 1, "ffobj": { "priff": "Montserrat", "secff": "Dancing Script" }, "styleborder": 4, "stylebordercolor": "#c8c09e", "styleborderbr": true, "styleborderwt": false, "sep": `${S3CLOUD_CLOUDFRONT_ASSET}floral/Blanket_Flower/banner/banner.png`, "styletype": "Blanket_Flower", "styleorientationmobile": 0, "styleformationmobile": 0, "styleorientation": 0, "styleformation": 2, "menutype": "btmnavbar"
        },
        images: [
            images_full_link(9, 'lg'),
            images_full_link(9, 'sm')
        ],
    },
    {
        name: 'Damask Rose',
        templatecolors: ['#2a3044', '#293462'],
        templatecolors_data_map: {
            "#2a3044": { "menustyle": { "backgroundColor": "#2a3044", "color": "#ffeec7", "borderColor": "#ffeec7" }, "templatecolor": "#2a3044", "fc": "#ffeec7" },
            "#293462": { "menustyle": { "backgroundColor": "#293462", "color": "#FEB139", "borderColor": "#FEB139" }, "templatecolor": "#293462", "fc": "#FEB139" }
        },
        templatecolors_img_map: {
            "#2a3044": S3CLOUD_CLOUDFRONT_ASSET + 'prebuilttemplate/Damask_Rose.png',
            "#293462": S3CLOUD_CLOUDFRONT_ASSET + 'prebuilttemplate/Damask_Rose.png',
        },
        svgoverlay_map: {
            '#2a3044': { mainimg: { svgitem: 1, mode: 2, color: "#ffeec7", replay: true, speed: 0, svgposition: 2, sw: 45 } },
            '#293462': { mainimg: { svgitem: 1, mode: 2, color: "#FEB139", replay: true, speed: 0, svgposition: 2, sw: 45 } }
        },
        common_data: {
            "ntm": 0, "ntt": 1, "ntw": 1, "ffobj": { "priff": "Montserrat", "secff": "Dancing Script" }, "styleborder": 4, "stylebordercolor": "#c8c09e", "styleborderbr": true, "styleborderwt": false, "sep": `${S3CLOUD_CLOUDFRONT_ASSET}floral/Rosa_Gallica/banner/banner.png`, "styletype": "Rosa_Gallica", "styleorientationmobile": 0, "styleformationmobile": 0, "styleorientation": 0, "styleformation": 0, "menutype": "btmnavbar"
        },
        images: [
            images_full_link(13, 'lg'),
            images_full_link(13, 'sm')
        ],

    },
    {
        name: 'Red Epidendrum',
        templatecolors: ['#FFFFF8',],
        templatecolors_data_map: {
            "#FFFFF8": { "menustyle": { "backgroundColor": "#FFFFF8", "color": "#535648", "borderColor": "#535648" }, "templatecolor": "#FFFFF8", "fc": "#535648" }
        },
        templatecolors_img_map: {
            "#FFFFF8": S3CLOUD_CLOUDFRONT_ASSET + 'prebuilttemplate/Red_Epidendrum.png',
        },
        svgoverlay_map: {
            '#FFFFF8': { mainimg: { svgitem: 1, mode: 2, color: "#FFFFF8", replay: true, speed: 0, svgposition: 2, sw: 45 } },
        },
        common_data: {
            "ntm": 0, "ntt": 1, "ntw": 1, "ffobj": { "priff": "Montserrat", "secff": "Parisienne" }, "styleborder": 4, "stylebordercolor": "#c8c09e", "styleborderbr": true, "styleborderwt": false, "sep": `${S3CLOUD_CLOUDFRONT_ASSET}floral/Red_Epidendrum/banner/banner.png`, "styletype": "Red_Epidendrum", "styleorientationmobile": 0, "styleformationmobile": 0, "styleorientation": 0, "styleformation": 2, "menutype": "btmnavbar"
        },
        images: [
            images_full_link(4, 'lg'),
            images_full_link(4, 'sm')
        ],
    },
    {
        name: 'Mexican Poppy',
        templatecolors: ['#FFE6C7', '#fcf8e8', '#FFFFF8'],
        templatecolors_data_map: {
            "#FFE6C7": { "menustyle": { "backgroundColor": "#FFE6C7", "color": "#454545", "borderColor": "#454545" }, "templatecolor": "#FFE6C7", "fc": "#454545" },
            "#fcf8e8": { "menustyle": { "backgroundColor": "#FFFFF8", "color": "#454545", "borderColor": "#454545" }, "templatecolor": "#fcf8e8", "fc": "#454545" },
            "#FFFFF8": { "menustyle": { "backgroundColor": "#FFFFF8", "color": "#454545", "borderColor": "#454545" }, "templatecolor": "#FFFFF8", "fc": "#454545" }

        },
        templatecolors_img_map: {
            "#FFE6C7": S3CLOUD_CLOUDFRONT_ASSET + 'prebuilttemplate/Mexican_Poppy.png',
            "#fcf8e8": S3CLOUD_CLOUDFRONT_ASSET + 'prebuilttemplate/Mexican_Poppy.png',
            "#FFFFF8": S3CLOUD_CLOUDFRONT_ASSET + 'prebuilttemplate/Mexican_Poppy.png',
        },
        svgoverlay_map: {
            '#FFE6C7': { mainimg: { svgitem: 1, mode: 2, color: "#FFE6C7", replay: true, speed: 0, svgposition: 2, sw: 45 } },
            '#fcf8e8': { mainimg: { svgitem: 1, mode: 2, color: "#fcf8e8", replay: true, speed: 0, svgposition: 2, sw: 45 } },
            '#FFFFF8': { mainimg: { svgitem: 1, mode: 2, color: "#FFFFF8", replay: true, speed: 0, svgposition: 2, sw: 45 } }
        },
        common_data: {
            "ntm": 0, "ntt": 1, "ntw": 1, "ffobj": { "priff": "Montserrat", "secff": "Dancing Script" }, "styleborder": 2, "stylebordercolor": "#c8c09e", "styleborderbr": true, "styleborderwt": false, "sep": `${S3CLOUD_CLOUDFRONT_ASSET}floral/Mexican_Poppy/banner/banner.png`, "styletype": "Mexican_Poppy", "styleorientationmobile": 0, "styleformationmobile": 0, "styleorientation": 0, "styleformation": 2, "menutype": "btmnavbar"
        },
        images: [
            images_full_link(12, 'lg'),
            images_full_link(12, 'sm')
        ],

    },
    {
        name: 'Crimson Damask',
        templatecolors: ['#FAF5E4'],
        templatecolors_data_map: {
            "#FAF5E4": { "menustyle": { "backgroundColor": "#FAF5E4", "color": "#125B50", "borderColor": "#125B50" }, "templatecolor": "#FAF5E4", "fc": "#125B50" }
        },
        templatecolors_img_map: {
            "#FAF5E4": S3CLOUD_CLOUDFRONT_ASSET + 'prebuilttemplate/Crimson_Damask.png',
        },
        svgoverlay_map: {
            '#FAF5E4': { mainimg: { svgitem: 1, mode: 2, color: "#FAF5E4", replay: true, speed: 0, svgposition: 2, sw: 45 } },
        },
        common_data: {
            "ntm": 0, "ntt": 1, "ntw": 1, "ffobj": { "priff": "Montserrat", "secff": "Dancing Script" }, "styleborder": 4, "stylebordercolor": "#c8c09e", "styleborderbr": true, "styleborderwt": false, "sep": `${S3CLOUD_CLOUDFRONT_ASSET}floral/Crimson_Damask/banner/banner.png`, "styletype": "Crimson_Damask", "styleorientationmobile": 0, "styleformationmobile": 0, "styleorientation": 0, "styleformation": 0, "menutype": "btmnavbar"
        },
        images: [
            images_full_link(11, 'lg'),
            images_full_link(11, 'sm')
        ],

    },
    {
        name: 'Gilia Flexuosa',
        templatecolors: ['#FFFFF8'],
        templatecolors_data_map: {
            "#FFFFF8": { "menustyle": { "backgroundColor": "#FFFFF8", "color": "#333333", "borderColor": "#333333" }, "templatecolor": "#FFFFF8", "fc": "#333333" }
        },
        templatecolors_img_map: {
            "#FFFFF8": S3CLOUD_CLOUDFRONT_ASSET + 'prebuilttemplate/Gilia_Flexuosa.png',
        },
        svgoverlay_map: {
            '#FFFFF8': { mainimg: { svgitem: 1, mode: 2, color: "#FFFFF8", replay: true, speed: 0, svgposition: 2, sw: 45 } },
        },
        common_data: {
            "ntm": 0, "ntt": 1, "ntw": 1, "ffobj": { "priff": "Montserrat", "secff": "Kaushan Script" }, "styleborder": 4, "stylebordercolor": "#c8c09e", "styleborderbr": true, "styleborderwt": false, "sep": `${S3CLOUD_CLOUDFRONT_ASSET}floral/Slender_Flowered_Gilia/banner/banner.png`, "styletype": "Slender_Flowered_Gilia", "styleorientationmobile": 0, "styleformationmobile": 0, "styleorientation": 3, "styleformation": 2, "menutype": "btmnavbar"
        },
        images: [
            images_full_link(2, 'lg'),
            images_full_link(2, 'sm')
        ],
    }
]

function Templates({ firsttimeenter, updateTemplate, restoreBackup, updateBackup, saveTemplates, clearBackup, selectedTemplate, itype, wt, device, updatePrebuilttemplate }) {
    const [firsttime, setFirsttime] = useState(true)
    const [selected, setSelected] = useState(null)
    const [showsave, setShowsave] = useState(false) // indicate save activate, when user make any savable actions.
    const [DEVICE_SIZE_MAP, setDEVICE_SIZE_MAP] = useState({})

    const [prebuildtemplatecolor, setPrebuildtemplatecolor] = useState('')
    const [prebuildindex, setPrebuildindex] = useState(-1)

    const commoneditstyle = {
        height: {
            // 'WEB': 'calc(100% - 70px - 65px)',
            'WEB': 'calc(100% - 61px - 60px - 65px )',
            'TABLET': 'calc(100% - 70px - 65px)',
            'MOBILE': 'calc(100% - 70px)'
        }[device],
        marginBottom: {
            'MOBILE': 65
        }[device]
    }

    const firsttimeenter_commoneditstyle = {
        height: {
            // 'WEB': 'calc(100% - 70px - 65px)',
            'WEB': 'calc(100% - 61px  - 65px )',
            'TABLET': 'calc(100% - 70px - 65px)',
            'MOBILE': 'calc(100% - 70px)'
        }[device],
        marginBottom: {
            'MOBILE': 65
        }[device]
    }

    const wtfactor_w = (0.45 * 0.25)
    const wtfactor_t = (0.45 * 0.3333)
    const wtfactor_m = 0.5

    const webconst = `calc( ${wt * wtfactor_w}px - 18px )`
    const tabletconst = `calc( ${wt * wtfactor_t}px - 20px )`
    const mobileconst = `calc( ${wt * wtfactor_m}px - 20px )`

    useEffect(() => {
        setDEVICE_SIZE_MAP({
            'WEB': { minHeight: webconst, minWidth: webconst, maxHeight: webconst, maxWidth: webconst, fontSize: 32, borderRadius: 10, },
            'TABLET': { minHeight: tabletconst, minWidth: tabletconst, maxHeight: tabletconst, maxWidth: tabletconst, fontSize: 32, borderRadius: 10, },
            'MOBILE': { minHeight: mobileconst, minWidth: mobileconst, maxHeight: mobileconst, maxWidth: mobileconst, fontSize: 20, borderRadius: 10, },
        })
    }, [wt])

    useEffect(() => {
        if (firsttime) {
            setSelected(selectedTemplate)
            setFirsttime(false)
        }
    }, [])

    useEffect(() => {
        if (selectedTemplate !== -1) {
            setSelected(selectedTemplate)
        }
    }, [selectedTemplate])

    const btmbarroot_css = {
        'MOBILE': { position: 'fixed', bottom: 0, left: 0, width: '100%' },
        'TABLET': { position: 'fixed', bottom: 10, left: 0, width: wt * 0.45 - 1 },
        'WEB': { position: 'fixed', bottom: 0, left: 0, width: wt * 0.45 - 1 }
    }[device]

    const btmbar = <div className='iedit_swaproot' style={btmbarroot_css}>
        <button
            className='iedit_cancel'
            disabled={!showsave}
            style={{ opacity: !showsave ? 0.5 : 1 }}
            onClick={() => {
                setShowsave(false)
                restoreBackup()
                setPrebuildtemplatecolor('')
                setPrebuildindex(-1)
            }}>
            Revert
        </button>
        <button
            className='iedit_save'
            disabled={!showsave}
            style={{ opacity: !showsave ? 0.5 : 1 }}
            onClick={() => {
                saveTemplates().then(() => {
                    clearBackup()
                    setShowsave(false)
                    setPrebuildtemplatecolor('')
                    setPrebuildindex(-1)
                })
            }}
        >
            Save
        </button>
    </div>

    const firsttemplate = <button
        style={
            wt > 547 && wt < TABLET
                ? {
                    minHeight: `calc( ${wt / 3}px - 20px )`,
                    minWidth: `calc( ${wt / 3}px - 20px )`,
                    maxHeight: `calc( ${wt / 3}px - 20px )`,
                    maxWidth: `calc( ${wt / 3}px - 20px )`,
                    fontSize: { 'WEB': 14, 'TABLET': 13, 'MOBILE': 14 }[device],
                    border: selected === 1 ? selected_css : unselected_css,
                    borderRadius: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    pointer: 'cursor',
                    overflow: 'hidden'
                }
                : {
                    ...DEVICE_SIZE_MAP[device],
                    fontSize: { 'WEB': 14, 'TABLET': 13, 'MOBILE': 14 }[device],
                    border: selected === 1 ? selected_css : unselected_css,
                    borderRadius: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    position: 'relative',
                    pointer: 'cursor',
                    overflow: 'hidden'
                }
        }
        value={1}
        onClick={() => {
            if (selectedTemplate === 1) return;
            if (!showsave) { updateBackup(); setShowsave(true); }
            updateTemplate(1)
        }}>
        <div style={{ transition: '.2s ease-in-out', transform: selected === 1 ? 'scale(0.85)' : '', height: '100%', width: '100%', border: 'thin solid var(--maindark)', borderRadius: 10, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', }} >
            <div style={{ height: '55%', width: '90%', border: 'thin solid var(--maindark)', borderRadius: 10, backgroundColor: imagecolor }} />
        </div>
    </button>


    const secondtemplate = <button
        style={
            wt > 547 && wt < TABLET
                ? {
                    minHeight: `calc( ${wt / 3}px - 20px )`,
                    minWidth: `calc( ${wt / 3}px - 20px ) `,
                    maxHeight: `calc( ${wt / 3}px - 20px ) `,
                    maxWidth: `calc( ${wt / 3}px - 20px ) `,
                    fontSize: { 'WEB': 14, 'TABLET': 13, 'MOBILE': 14 }[device],
                    border: selected === 2 ? selected_css : unselected_css,
                    borderRadius: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    position: 'relative',
                    pointer: 'cursor',
                    margin: 0,
                    padding: 0,
                    overflow: 'hidden'
                }
                : {
                    ...DEVICE_SIZE_MAP[device],
                    fontSize: { 'WEB': 14, 'TABLET': 13, 'MOBILE': 14 }[device],
                    border: selected === 2 ? selected_css : unselected_css,
                    borderRadius: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    position: 'relative',
                    pointer: 'cursor',
                    margin: 0,
                    padding: 0,
                    overflow: 'hidden'
                }
        }
        value={2}
        disabled={true}
        onClick={() => {
            if (selectedTemplate === 2) return;
            if (!showsave) { updateBackup(); setShowsave(true); }
            updateTemplate(2)
        }}>
        <div style={{ transform: selected === 2 ? 'scale(0.85)' : '', height: '100%', width: '100%', borderRadius: 5, backgroundColor: 'white', justifyContent: 'center', alignItems: 'flex-start', display: 'flex', flexDirection: 'column', }} >
            <div style={{ height: '100%', width: '70%', borderRight: 'thin solid var(--maindark)', borderRadius: '5px 0px 0px 5px', backgroundColor: imagecolor }} />
        </div>
        <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, backgroundColor: '#333333d9', color: 'white', fontSize: 14, placeContent: 'center', alignItems: 'center', alignItems: 'center', display: 'flex' }}>
            Coming Soon!
        </div>
    </button>

    return (
        // overflowY: scroll - to make consistent with Images.jsx
        <div className="Ieditcomp_div" style={{ ...firsttimeenter ? firsttimeenter_commoneditstyle : commoneditstyle, overflowY: 'auto' }}>
            <Explain title={TITLE} txt={TEXT} />
            <div className='Details_grp'>
                {partialtitle_dom('Template Style', {})}
                {inputdescribe_dom('Change of template, click to select.')}
                {INVITATIONTYPES_ARR.includes(itype) > 0
                    ? <div className={`Imageinvitationcard_root${device}`} style={{ flexDirection: 'column', padding: 0 }}>
                        {firsttemplate}
                        {secondtemplate}
                    </div>
                    : null}
            </div>
            {DEVICE_SIZE_MAP && Object.keys(DEVICE_SIZE_MAP).length > 0
                ? <div className='Details_grp'>
                    {partialtitle_dom('Pre-Built Template', {})}
                    {inputdescribe_dom(`Get started by choosing a pre-built for your ${itype} website! You can switch to a new template at any time.`)}
                    <div
                        style={
                            wt < 420
                                ? { display: 'flex', flexDirection: 'column', }
                                : {
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(auto-fit,  calc( 50% - 2px ) )',
                                    gridGap: 3
                                }}
                    >
                        {prebuilttemplates.map((item, index) => {
                            const { url, templatecolors, name, templatecolors_img_map } = item

                            const selecttemplate = () => {
                                if (!showsave) { updateBackup(); setShowsave(true); }

                                const templateindex = parseInt(index, 10)
                                const templateobj = prebuilttemplates[templateindex]

                                const local_templatecolor = templateobj.templatecolors[0]
                                setPrebuildtemplatecolor(local_templatecolor)
                                const specific_data = templateobj['templatecolors_data_map'][local_templatecolor]
                                const common_data = templateobj['common_data']

                                const svgoverlay_map = templateobj['svgoverlay_map'][local_templatecolor]
                                setPrebuildindex(templateindex)

                                const combined_data = { ...specific_data, ...common_data, images: templateobj['images'], svgoverlay_map: svgoverlay_map }
                                updatePrebuilttemplate(combined_data)

                            }

                            const selecttemplatecolor = (local_templatecolor) => {

                                if (!showsave) { updateBackup(); setShowsave(true); }

                                const templateindex = parseInt(index, 10)
                                const templateobj = prebuilttemplates[templateindex]

                                setPrebuildtemplatecolor(local_templatecolor)
                                const specific_data = templateobj['templatecolors_data_map'][local_templatecolor]
                                const common_data = templateobj['common_data']

                                const svgoverlay_map = templateobj['svgoverlay_map'][local_templatecolor]

                                setPrebuildindex(templateindex)

                                const combined_data = { ...specific_data, ...common_data, images: templateobj['images'], svgoverlay_map: svgoverlay_map }
                                updatePrebuilttemplate(combined_data)

                            }

                            const tickicon_css = { width: 15, height: 15, display: 'flex', alignItems: 'center', margin: '0px 5px 0px 0px', }
                            const check_index = prebuildindex === index
                            return <div key={index + 'item'} style={{ border: check_index ? '3px solid var(--mainpink)' : '3px solid white', display: 'flex', placeContent: 'flex-start', width: '100%', flexDirection: 'column', padding: 10, borderRadius: 5, boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px inset' }} >

                                <button value={index} onClick={selecttemplate} style={{ border: 'none', display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff', padding: 0 }}>
                                    <div style={{ padding: '5px 0px 15px', width: '100%', textAlign: 'center', placeContent: 'center' }}>{name}</div>

                                    <img
                                        src={(prebuildtemplatecolor === '' || prebuildindex !== index) ? templatecolors_img_map[templatecolors[0]] : templatecolors_img_map[prebuildtemplatecolor]}
                                        style={{ width: '100%', objectFit: 'contain', backgroundColor: 'white' }}
                                    />
                                </button>

                                <ul style={{ padding: '15px 0px 0px 0px', margin: 0, display: 'flex', listStyle: 'none', flexWrap: 'wrap', gridGap: 3, gap: 3, overflow: 'auto', placeContent: 'flex-start' }}>
                                    {templatecolors
                                        ? templatecolors.map((c, i) => {
                                            return <div
                                                key={i + 'color1'}
                                                style={{ border: (check_index && prebuildtemplatecolor === c) ? '2px solid var(--mainpink)' : '2px solid transparent', padding: 1, width: 'max-content', height: 'max-content', display: 'flex', placeContent: 'center', alignItems: 'center', borderRadius: 5 }}>
                                                <li
                                                    style={{
                                                        border: (check_index && prebuildtemplatecolor === c) ? 'none' : '1px solid #dddddd', backgroundColor: c, width: 20, height: 20, cursor: 'pointer', borderRadius: 3,
                                                    }}
                                                    onClick={() => selecttemplatecolor(c)}
                                                />
                                            </div>
                                        })
                                        : null}
                                </ul>

                                <div style={{ display: 'flex', placeContent: 'flex-end', marginTop: 15, display: 'flex', flexDirection: 'column' }}>
                                    <button style={{ margin: 0, padding: '0px', width: 'max-content', border: '1px solid transparent', borderRadius: 10, display: 'flex', alignContent: 'center', placeContent: 'center', backgroundColor: '#ffffff', alignItems: 'center' }}>{iconwrapper(TICK_ICON, tickicon_css)} Seat Chart </button>
                                    <button style={{ margin: 0, padding: '0px', width: 'max-content', border: '1px solid transparent', borderRadius: 10, display: 'flex', alignContent: 'center', placeContent: 'center', backgroundColor: '#ffffff', alignItems: 'center' }}>{iconwrapper(TICK_ICON, tickicon_css)} Itinerary</button>
                                    {/* <button style={{ margin: 0, padding: '0px', width: 'max-content', border: '1px solid transparent', borderRadius: 10, display: 'flex', alignContent: 'center', placeContent: 'center', backgroundColor: '#ffffff', alignItems: 'center' }}>{iconwrapper(TICK_ICON, tickicon_css)} Table Card </button> */}
                                    {/* <button style={{ margin: '20px 0px 0px 0px', padding: '0px 10px', width: 'max-content', border: '1px solid var(--maindark)', borderRadius: 10, display: 'flex', alignContent: 'center', placeContent: 'center', backgroundColor: '#ffffff', placeSelf: 'flex-end' }}>Matching Prints</button> */}
                                </div>

                            </div>

                        })}

                    </div>
                </div>
                : null}
            {btmbar}
        </div>
    )
}

export default Templates