import React, { useEffect, useState } from 'react'
import { CLOSE_ICON } from '../../../../../../config/constants'
import { GUESTLIST_ICON, CATSMALL_ICON, TEMPLATE_ICON, FOLLOWUPPLUS_ICON, DRAG_ICON, PENCIL_ICON, RIGHTARROW_ICON, DOWNARROW_ICON, TRASH_ICON, CAT_ICON, PERSON_ICON, ALLPERSON_ICON, TICK_ICON } from '../../../../../../config/svgicons'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Checkdevice, Portalmodal } from '../../../../../../components';
import {
    INVITATION_GUESTLIST_DELFOLLOWUPQN,
    INVITATION_GUESTLIST_DELMAINQN,
    INVITATION_GUESTLIST_SWAP,
    INVITATION_GUESTLIST_ADDNEWTEMPLATE
} from '../../../../../../config/api';
import { iconwrapper, Retrieve_personal_info } from '../../../../../../config/commonfunctions';
import Rsvptemplatecontent from '../Portalcontent/Rsvptemplatecontent'
import Rsvpqnportal from './components/Rsvpqnportal/Rsvpqnportal';
import axios from 'axios'
import { isMobile } from 'react-device-detect';
import './Rsvpqnexplorer.css'
import { RSVPQNS_TEMPLATES } from '../../constants';
import Success from '../Portalcontent/components/Success';

const MULTIPLECHOICE = 0
const INDEX_WT = 40
// display: 'flex', placeContent: 'center' 
const METAICONS_CSS = { width: 18, height: 18, display: 'flex', placeContent: 'center' }// category and (person or allperson) icon
const ACTIONICONS_CSS = { width: 20, height: 20, display: 'flex', placeContent: 'center' } // trash, pencil and draggabl icons
const ARROW_CSS = { width: 8, height: 8, margin: 10, display: 'flex', }
const TICKICON_CSS = { width: 26, height: 26, margin: '0px 5px 0px 0px' }


function Rsvpqnexplorer(props) {

    const { close, guestqns, invitationid, guestids, guestqnsswapped, cancelswap, AddcatModal, successswap, guestlistreadcounter, rsvpactive, rsvpactivefunc } = props

    const { token } = Retrieve_personal_info()

    // current diagram items
    const [expandablestates, setExpandablestates] = useState([])

    // addqn portal states
    const [addqnbool, setAddqnbool] = useState(false)

    // editqn portal states
    const [editqnbool, setEditqnbool] = useState(false)

    // editfollowupqn portal states
    const [editfollowupqnbool, setEditfollowupqnbool] = useState(false)

    // followup add portal states
    const [addfollowupbool, setAddfollowupbool] = useState(false)

    // for main qn edit
    const [selectedqnindex, setSelectedqnindex] = useState(-1)

    // for followup qn edit & followup qn add
    const [selectedmainqnindex, setSelectedmainqnindex] = useState(-1)
    const [selectedoptionindex, setSelectedoptionindex] = useState(-1)

    // for followup qn edit only
    const [selectedfollowupqnindex, setSelectedfollowupqnindex] = useState(-1)

    // del data and delete modal
    const [del, setDel] = useState(false)
    const [deldata, setDeldata] = useState({})
    const [delreturn, setDelreturn] = useState('DEFAULT') // DEFAULT, SUCCESS, FOLLOWUPEXIST

    // open new cat modal
    const [templatersvpqnmodal, setTemplatersvpqnmodal] = useState(false)
    const [addtemplatersvpqnstate, setAddtemplatersvpqnstate] = useState('IDLE')// IDLE, SAVING, SUCCESS, FAILURE

    // followupqn portal states
    const device = Checkdevice()

    const portalleftcss = { width: { 'WEB': 500, 'TABLET': 450, 'MOBILE': '100%' }[device], height: '100%', backgroundColor: 'white', top: 0, left: null, right: 0, transform: 'none' }

    const getListStyle = isDraggingOver => ({
        // background: isDraggingOver ? '#707070' : 'lightgrey',
        // height: 'max-content',
        // minHeight: '100%',
        // background: 'transparent',
        // width:'calc(100% - 20px)'
        // paddingBottom:100,
    });

    useEffect(() => { // expand states

        // detect whether "question" and "answer" has expanded or not.
        var expandablestates = [] // eg, [ [qn state, [option state, followup qn state], [option state, followup qn state]], [...] , ...]
        for (let i = 0; i < guestqns.length; i++) {
            let questiontype = guestqns[i][1] // 0 is multiplechoice, 1 is text question

            var currqn_expandstate = { 'mainexpand': false, 'optionexpand': [] } // current format, questiontype 1 will remain default

            // if is multiplechoice, loop all options to lookup for followup question
            if (questiontype === 0) { // if is multiplechoice

                let options = guestqns[i][8] // arr of options(arr)

                var optionexpandstate = []
                // each option might have more than 1 question

                for (let followindex = 0; followindex < options.length; followindex++) {
                    // check whether gt follow up question
                    if (options[followindex][1].length > 0) { // option has followup question
                        const num_option_followupqn = options[followindex][1].length
                        let temp = []
                        for (let k = 0; k < num_option_followupqn; k++) {
                            temp.push(true)
                        }
                        // {
                        //     mainexpand : true/false      --- main qn expand 
                        //     optionexpand : [            
                        //         [ true,  [true, true, true] ],  --- 0 : option expand, 1 :  each true, is followup qn expand state
                        //         [ true,  [true, true] ]   --- 0 : option expand, 1 :  each true, is followup qn expand state
                        //         [ false, [          ] ]   --- 0 : option expand, 1 :  pair, is followup qn expand state
                        //     ]
                        // }

                        optionexpandstate.push([true, temp])
                    }
                    else { // option have no followup question
                        optionexpandstate.push([false, []])
                    }
                }
                currqn_expandstate['optionexpand'] = optionexpandstate

            }

            expandablestates.push(currqn_expandstate)

        }

        if (props.rsvpqnexplorerexpandablestates && props.rsvpqnexplorerexpandablestates.length > 0) {
            setExpandablestates(props.rsvpqnexplorerexpandablestates)
        }
        else {
            setExpandablestates(expandablestates)
        }
    }, [guestqns])

    useEffect(() => {
        if (props.rsvpqnexplorerexpandablestates && props.rsvpqnexplorerexpandablestates.length > 0) {
            setExpandablestates(props.rsvpqnexplorerexpandablestates)
        }
    }, [props.rsvpqnexplorerexpandablestates])

    const delfunc = (data) => {
        // setDel(() => {
        //     setDeldata(data)
        //     return true
        // })
        setDel(() => {
            setDeldata(data)
            return true
        })
    }

    function qnindex_dom(qnindex, qn_type, provided, guestqns) {
        const qntypetxt = device === 'MOBILE' ? ['MC', 'OE'][qn_type] : ['Multiple Choice', 'Open-ended'][qn_type]
        const whocanview = guestqns[qnindex][3] // all or catind
        return <div className='Rsvpexplorer_qnindexroot' >
            <div id='Rsvpexplorer_qnindex' style={{ width: INDEX_WT }}>{qnindex + 1}</div>
            <div id='Rsvpexplorer_qnfunctionsroot' style={{ width: `calc( 100% - ${INDEX_WT}px )` }}>
                <div id='Rsvpexplorer_tagroot' >
                    <div className={`Rsvpexplorer_tag Rsvpexplorer_tag${qn_type}`} >{qntypetxt}</div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {whocanview === 'all'
                            ? <div className='Rsvpexplorer_catindiconroot'>
                                {iconwrapper(ALLPERSON_ICON, METAICONS_CSS)}
                                <div className='Rsvpexplorer_catindiconrootnumber'>All</div>
                            </div>
                            : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div className='Rsvpexplorer_catindiconroot'>
                                    {iconwrapper(CATSMALL_ICON, METAICONS_CSS)}
                                    <div className='Rsvpexplorer_catindiconrootnumber'>{guestqns[qnindex][4].length}</div>
                                </div>
                                <div className='Rsvpexplorer_catindiconroot'>
                                    {iconwrapper(PERSON_ICON, METAICONS_CSS)}
                                    <div className='Rsvpexplorer_catindiconrootnumber'>{guestqns[qnindex][5].length}</div>
                                </div>
                            </div>}
                    </div>
                </div>
                <div id='Rsvpexplorer_iconroot' >
                    {guestqnsswapped
                        ? null
                        : <div className='Rsvpexplorer_icon' onClick={() => delfunc({ qnindex: qnindex, qn_type: qn_type, from: 'mainqn', invitationid: invitationid })} >
                            {iconwrapper(TRASH_ICON, ACTIONICONS_CSS)}
                        </div>}
                    {guestqnsswapped
                        ? null
                        : <div className='Rsvpexplorer_icon' onClick={() => { setEditqnbool(true); setSelectedqnindex(qnindex); }}>
                            {iconwrapper(PENCIL_ICON, ACTIONICONS_CSS)}
                        </div>}
                    <div id='Rsvpexplorer_dragicon' {...provided.dragHandleProps}>
                        {iconwrapper(DRAG_ICON, ACTIONICONS_CSS)}
                    </div>
                    <div style={{ width: 5 }} />
                </div>
            </div>
        </div>
    }

    function followupqnindex_dom(followupqnindex, qn_type, provided, optionindex, mainqnindex, followupqnuid, followupqn) { // option index exist only for followup qn
        const qntypetxt = device === 'MOBILE' ? ['MC', 'OE'][qn_type] : ['Multiple Choice', 'Open-ended'][qn_type]
        const followuptxt = device === 'MOBILE' ? 'FOL-UP' : 'Follow-Up Question'
        return <div className='Rsvpexplorer_qnindexroot' style={{ width: `calc( 100% - ${INDEX_WT / 2}px )` }}>
            <div id='Rsvpexplorer_qnindex' style={{ width: INDEX_WT }}>{followupqnindex + 1}</div>
            <div id='Rsvpexplorer_qnfunctionsroot' style={{ width: `calc( 100% - ${INDEX_WT}px )` }}>
                <div id='Rsvpexplorer_tagroot' >
                    <div className={`Rsvpexplorer_tag Rsvpexplorer_tag${qn_type}`}>{qntypetxt}</div>
                    <div className='Rsvpexplorer_tag Rsvpexplorer_tagfollowup'>{followuptxt}</div>
                </div>
                <div id='Rsvpexplorer_iconroot' >
                    {guestqnsswapped
                        ? null
                        : <div className='Rsvpexplorer_icon' onClick={() => { delfunc({ 'mainqnindex': mainqnindex, 'optionindex': optionindex, 'followupqnindex': followupqnindex, 'qn_type': qn_type, 'from': 'followupqn', 'invitationid': invitationid, 'followupqnuid': followupqnuid, 'guestids': guestids, 'followupqn': followupqn }) }}>
                            {iconwrapper(TRASH_ICON, ACTIONICONS_CSS)}
                        </div>}
                    {guestqnsswapped
                        ? null
                        : <div className='Rsvpexplorer_icon'
                            onClick={() => {
                                setEditfollowupqnbool(true)
                                setSelectedmainqnindex(mainqnindex)
                                setSelectedoptionindex(optionindex)
                                setSelectedfollowupqnindex(followupqnindex)
                            }}>
                            {iconwrapper(PENCIL_ICON, ACTIONICONS_CSS)}
                        </div>}
                    <div id='Rsvpexplorer_dragicon' {...provided.dragHandleProps}>
                        {iconwrapper(DRAG_ICON, ACTIONICONS_CSS)}
                    </div>
                </div>
            </div>
        </div>
    }

    function genoption_dom(key, followuptext, optionindex, options, mainoptionexpand_state, num_followup_qnoptions, togglefollowup_func, mainqnindex) {

        const leftlinediv = <div className='leftlinediv' style={{ height: (optionindex === options.length - 1) ? 'calc( 50% + 5px)' : `calc(100% + ${INDEX_WT / 2}px + 10px )`, width: INDEX_WT / 2, marginLeft: -(INDEX_WT / 2) - 1, display: 'flex', position: 'absolute', borderLeft: '1px solid black', top: (optionindex === options.length - 1) ? -5 : -10 }} />
        const horizondallinediv = <div className='horizontallinediv' style={{ display: 'flex', position: 'absolute', borderBottom: '1px solid black', width: INDEX_WT / 2, marginLeft: -(INDEX_WT / 2) - 1, top: '50%' }} />

        return <div key={key} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{ borderBottomLeftRadius: (optionindex === options.length - 1) ? 0 : 5, width: '100%', height: 'max-content', borderRadius: 5, border: '1px solid black', marginTop: 10, backgroundColor: 'white', display: 'flex', flexDirection: 'row', placeSelf: 'flex-end', position: 'relative', }}>
                {leftlinediv}
                {horizondallinediv}
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 50 }}>
                        <div style={{ width: '100%', height: 'max-content', whiteSpace: 'pre-wrap', flexWrap: 'wrap', alignItems: 'center', display: 'flex', padding: 10 }}>
                            {followuptext}
                        </div>
                        {!guestqnsswapped
                            ? <div
                                className='Rsvpexplorer_followupplus'
                                onClick={() => {
                                    setAddfollowupbool(true)
                                    setSelectedmainqnindex(mainqnindex)
                                    setSelectedoptionindex(optionindex)
                                }} >
                                {iconwrapper(FOLLOWUPPLUS_ICON, ACTIONICONS_CSS)}
                            </div>
                            : null}
                        <div style={{ width: 5 }} />
                    </div>

                    {num_followup_qnoptions > 0 // this single option gt expandable element
                        ? <button
                            className='Rsvpexplorer_dropdownbtn'
                            style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', backgroundColor: 'transparent', boxShadow: 'none', border: 'none', argin: 0, width: '100%', placeContent: 'flex-end', padding: 0, fontSize: 12 }}
                            onClick={togglefollowup_func}
                        >
                            <div style={{ width: 'max-content' }}> {num_followup_qnoptions + ' Follow-Up'}</div>
                            {mainoptionexpand_state
                                ? iconwrapper(RIGHTARROW_ICON, ARROW_CSS)
                                : iconwrapper(DOWNARROW_ICON, ARROW_CSS)}
                        </button>
                        : <button
                            className='Rsvpexplorer_dropdownbtn'
                            style={{ opacity: 0, alignItems: 'center', display: 'flex', flexDirection: 'row', backgroundColor: 'transparent', color: 'transparent', boxShadow: 'none', border: 'none', margin: 0, width: '100%', placeContent: 'flex-end', padding: 0, fontSize: 12 }}
                            onClick={togglefollowup_func}
                        >
                            <div style={{ width: 'max-content' }}> {num_followup_qnoptions + ' Follow-Up'}</div>
                            <div style={{ color: 'transparent', fill: 'transparent' }}>{mainoptionexpand_state
                                ? iconwrapper(RIGHTARROW_ICON, ARROW_CSS)
                                : iconwrapper(DOWNARROW_ICON, ARROW_CSS)}</div>
                        </button>}

                </div>
            </div>
        </div>
    }

    function genfollowupoption_dom(key, followuptext, optionindex, options) {

        const leftlinediv = <div className='leftlinediv' style={{ height: (optionindex === options.length - 1) ? 'calc( 100% - 10px )' : 'calc( 100% + 20px )', display: 'flex', position: 'absolute', marginLeft: -(INDEX_WT / 2) - 1, width: -(INDEX_WT / 2) - 1, borderLeft: '1px solid black', top: -10 }} />
        const horizondallinediv = <div className='horizontallinediv' style={{ display: 'flex', position: 'absolute', borderBottom: '1px solid black', width: INDEX_WT / 2, marginLeft: -(INDEX_WT / 2) - 1, top: '50%' }} />

        return <div key={key} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{ borderBottomLeftRadius: (optionindex === options.length - 1) ? 0 : 5, width: '100%', height: 'max-content', borderRadius: 5, border: '1px solid black', marginTop: 10, backgroundColor: 'white', display: 'flex', flexDirection: 'row', placeSelf: 'flex-end', position: 'relative' }}>
                {leftlinediv}
                {horizondallinediv}
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '100%', height: 'max-content', whiteSpace: 'pre-wrap', flexWrap: 'wrap', alignItems: 'center', display: 'flex', padding: 10 }}>
                            {followuptext}
                        </div>
                        <div className='Rsvpexplorer_followupplus' style={{ visibility: 'hidden' }} >
                            {iconwrapper(FOLLOWUPPLUS_ICON, ACTIONICONS_CSS)}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    }

    const onDragend = (result) => {

        const { source, destination, type } = result

        if (!destination) { return; }

        let new_guestqns = JSON.parse(JSON.stringify(guestqns))
        let new_expandablestates = JSON.parse(JSON.stringify(expandablestates))

        var keeptrackold
        if (guestqnsswapped) { // already swapped before, no need to keep track prev state
            keeptrackold = false
        }
        else { // need to keep track of current old states incase user decide not to save
            keeptrackold = true
        }

        if (type === 'main' && source.index !== destination.index) {
            const [removed_guestqns] = new_guestqns.splice(source.index, 1);
            new_guestqns.splice(destination.index, 0, removed_guestqns);

            const [removed_expandablestate] = new_expandablestates.splice(source.index, 1);
            new_expandablestates.splice(destination.index, 0, removed_expandablestate);

            props.updateswap(new_guestqns, new_expandablestates, keeptrackold, expandablestates)
        }
        else if (type === 'followup') {

            const src_arr = source.droppableId.split('_')
            const src_mainqn_index = src_arr[0]
            const src_option_index = src_arr[1]
            const src_followup_index = result.source.index

            const dest_arr = destination.droppableId.split('_')
            const dest_mainqn_index = dest_arr[0]
            const dest_option_index = dest_arr[1]
            const dest_followup_index = result.destination.index

            const [removed_guestqns] = new_guestqns[src_mainqn_index][8][src_option_index][1].splice(src_followup_index, 1)
            new_guestqns[dest_mainqn_index][8][dest_option_index][1].splice(dest_followup_index, 0, removed_guestqns)

            const [removed_expandablestate] = new_expandablestates[src_mainqn_index]['optionexpand'][src_option_index][1].splice(src_followup_index, 1)
            new_expandablestates[dest_mainqn_index]['optionexpand'][dest_option_index][1].splice(dest_followup_index, 0, removed_expandablestate)

            props.updateswap(new_guestqns, new_expandablestates, keeptrackold, expandablestates)

        }
    }

    // const followupempty_dom = (qnindex, optionindex) => { // just a stub for empty followup withpout followup
    //     return <Droppable droppableId={qnindex + '_' + optionindex + '_followup'} type="followup">
    //         {(provided, snapshot) => (
    //             <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
    //                 <Draggable index={0} key={qnindex + '_' + optionindex + '_empty_followup'} draggableId={qnindex + '_' + optionindex + '_empty_followup'} type="followup">
    //                     {(provided, snapshot) => (
    //                         <div id='testblack' ref={provided.innerRef} {...provided.draggableProps} />
    //                     )}
    //                 </Draggable>
    //                 {provided.placeholder}
    //             </div>
    //         )}
    //     </Droppable>
    // }

    const followupdnd_dom = (followupqn, qnindex, optionindex, alloptions_of_currqn) => {
        return <Droppable droppableId={qnindex + '_' + optionindex + '_followup'} type="followup">
            {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                    {(followupqn && followupqn.length > 0)
                        ? followupqn.map((qn, followindex) => {

                            const followexpand_state = expandablestates && expandablestates[qnindex] && expandablestates[qnindex]['optionexpand'] && expandablestates[qnindex]['optionexpand'][optionindex] && expandablestates[qnindex]['optionexpand'][optionindex][1] && expandablestates[qnindex]['optionexpand'][optionindex][1][followindex]
                            const followupqntxt = qn[0]
                            const followupqntype = qn[1]
                            const followupqnuid = qn[3]
                            let followupoptions = followupqntype === 0 ? qn.slice(4, qn.length) : null
                            const key = qnindex + '_' + optionindex + '_' + followindex + '_followup'
                            const draggableid = qnindex + '_' + optionindex + '_' + followindex + '_followupdraggbleid'

                            return <Draggable index={followindex} key={key} draggableId={draggableid} type="followup" >
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps}>
                                        {followupqntype === 0
                                            ? <div className='Rsvpexplorer_followupqn' key={'div' + followindex + '_' + qnindex + '_' + optionindex}>
                                                {followupqnindex_dom(followindex, followupqntype, provided, optionindex, qnindex, followupqnuid, qn)}
                                                {((alloptions_of_currqn.length - 1) === optionindex)
                                                    ? null
                                                    : <div style={{ borderLeft: '1px solid black', height: 'calc( 100% + 10px )', position: 'absolute', marginLeft: -(INDEX_WT / 2) }} />}
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', placeContent: 'flex-end' }}>
                                                    <div style={{ width: `calc( 100% - ${(INDEX_WT / 2) * 3}px + 1px )`, height: 'max-content' }}>
                                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', position: 'relative' }}>
                                                            <div style={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid black', borderTop: 0, backgroundColor: 'white', borderBottomRightRadius: 5, borderBottomLeftRadius: 5, marginTop: -1 }}>
                                                                {followexpand_state ? <div style={{ borderLeft: '1px solid black', height: 'calc( 100% + 10px )', left: -(INDEX_WT / 2) - 1, position: 'absolute' }} /> : null}
                                                                <div style={{ padding: 10, width: '100%' }}>{followupqntxt}</div>
                                                                <button
                                                                    className='Rsvpexplorer_dropdownbtn'
                                                                    style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', backgroundColor: 'transparent', boxShadow: 'none', border: 'none', margin: 0, width: '100%', placeContent: 'flex-end', padding: 0, fontSize: 12 }}
                                                                    onClick={() => {
                                                                        setExpandablestates((old) => {
                                                                            var currexpandablestates = JSON.parse(JSON.stringify(old))
                                                                            currexpandablestates[qnindex]['optionexpand'][optionindex][1][followindex] = !currexpandablestates[qnindex]['optionexpand'][optionindex][1][followindex]
                                                                            return currexpandablestates
                                                                        })
                                                                    }}
                                                                >
                                                                    <div>{followupoptions.length + ' Options'}</div>
                                                                    {followexpand_state
                                                                        ? iconwrapper(RIGHTARROW_ICON, ARROW_CSS)
                                                                        : iconwrapper(DOWNARROW_ICON, ARROW_CSS)}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {followexpand_state ? followupoptions.map((followuptext, followupoptionindex) => genfollowupoption_dom(`followupoptions${followupoptionindex}from${optionindex}`, followuptext, followupoptionindex, followupoptions,)) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            : <div className='Rsvpexplorer_followupqn' key={'div' + followindex + '_' + qnindex + '_' + optionindex} >
                                                {followupqnindex_dom(followindex, followupqntype, provided, optionindex, qnindex, followupqnuid, qn)}
                                                {((alloptions_of_currqn.length - 1) === optionindex)
                                                    ? null
                                                    : <div style={{ borderLeft: '1px solid black', height: 'calc( 100% + 10px )', position: 'absolute', marginLeft: -(INDEX_WT / 2) }} />}
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', placeContent: 'flex-end' }}>
                                                    <div style={{ width: `calc( 100% - ${(INDEX_WT / 2) * 3}px + 1px )`, height: 'max-content' }}>
                                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', position: 'relative' }}>
                                                            <div style={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid black', borderTop: 0, backgroundColor: 'white', borderBottomRightRadius: 5, borderBottomLeftRadius: 5, marginTop: -1 }}>
                                                                <div style={{ padding: 10, width: '100%' }}>{followupqntxt}</div>
                                                                <button className='Rsvpexplorer_dropdownbtn' style={{ alignItems: 'center', display: 'flex', opacity: 0, flexDirection: 'row', backgroundColor: 'transparent', boxShadow: 'none', border: 'none', margin: 0, width: '100%', placeContent: 'flex-end', padding: 0, fontSize: 12 }} >
                                                                    {'_'}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                )}
                            </Draggable>
                        })
                        : null
                    }
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    }

    const updateExpandstate = (from, deldata) => {
        var new_expandablestates = JSON.parse(JSON.stringify(expandablestates))
        if (from === 'mainqn') {
            const { qnindex } = deldata
            new_expandablestates.splice(qnindex, 1)
            return new_expandablestates
        }
        else if (from === 'followupqn') {
            const { mainqnindex, optionindex, followupqnindex } = deldata
            new_expandablestates[mainqnindex]['optionexpand'][optionindex][1].splice(followupqnindex)
            return new_expandablestates
        }
    }

    // RSVP QN TEMPLATE PORTAL START
    const templatersvpqnmodalclose = () => {
        setTemplatersvpqnmodal(false)
        setAddtemplatersvpqnstate('IDLE')
    }

    const saveRsvptemplate = (templatersvpmcqns, templatersvptxtqns) => {

        if (addtemplatersvpqnstate === 'IDLE') {

            setAddtemplatersvpqnstate('SAVING')

            let new_guestqns = []

            for (let i = 0; i < templatersvpmcqns.length; i++) {
                new_guestqns.push(RSVPQNS_TEMPLATES['Multiple Choice'][templatersvpmcqns[i]])
            }

            for (let i = 0; i < templatersvptxtqns.length; i++) {
                new_guestqns.push(RSVPQNS_TEMPLATES['Open-ended'][templatersvptxtqns[i]])
            }

            let new_expandablestates = []
            let combined_guestqns = [...JSON.parse(JSON.stringify(guestqns)), ...JSON.parse(JSON.stringify(new_guestqns))]

            for (let i = 0; i < combined_guestqns.length; i++) {
                if (combined_guestqns[i][1] === 0) { // multiple choice
                    let expandable_bools = []
                    for (let j = 0; j < combined_guestqns[i][8].length; j++) {
                        var option_followupbools = []
                        for (let k = 0; k < combined_guestqns[i][8][j][1].length; k++) {
                            option_followupbools.push(false)
                        }
                        expandable_bools.push([false, option_followupbools])
                    }
                    new_expandablestates.push({ 'mainexpand': false, 'optionexpand': expandable_bools })
                }
                else { // text
                    new_expandablestates.push({ 'mainexpand': false, 'optionexpand': [] })
                }
            }

            let options = {
                method: 'POST',
                url: INVITATION_GUESTLIST_ADDNEWTEMPLATE,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { invitationid, 'guestqns': new_guestqns, 'guestqnsmap': props.guestqnsmap, guestids, guestqnssettingsmap: props.guestqnssettingsmap, guestlistreadcounter }
            }
            axios(options).then((item) => {
                setAddtemplatersvpqnstate('SUCCESS')
                props.successupdate(item.data, new_expandablestates)
                // setTimeout(() => {
                //     templatersvpqnmodalclose()
                //     props.successupdate(item.data, new_expandablestates)
                // }, 1500)
            }).catch((e) => {
                setAddtemplatersvpqnstate('FAILURE')
            })

        }
    }
    // RSVP QN TEMPLATE PORTAL END

    const canceldel = () => {
        setDelreturn('DEFAULT')
        setDel(false)
    }

    const cfmdelete = () => {
        const { from } = deldata
        var url = from === 'mainqn' ? INVITATION_GUESTLIST_DELMAINQN : INVITATION_GUESTLIST_DELFOLLOWUPQN
        try {
            axios({
                method: 'POST',
                url: url,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { ...deldata, guestlistreadcounter }
            }).then((item) => {

                if (item && item.data && item.data.message === 'SUCCESS') {
                    setDelreturn('SUCCESS')
                    props.successupdate(item.data, updateExpandstate(from, deldata))
                }
                else if (item && item.data && item.data.message === "FOLLOWUPEXIST") {
                    setDelreturn('FOLLOWUPEXIST')
                }
            })
        }
        catch (e) { }
    }

    const navbtntxt = (toptxt, btmtxt, txtrootcss) => {
        return <div className="ig_btntxt" style={txtrootcss}>
            <div className='ig_commontxt' style={{ marginTop: 0 }}>{toptxt}</div>
            {btmtxt.length > 0 ? <div className='ig_commontxt'>{btmtxt}</div> : null}
        </div>
    }

    return <div className='igl_root' style={{ backgroundColor: '#efeff0', overflow: 'hidden', height: 'auto' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
            <div className='Iedittopbarleftmenu_root' style={{ width: '100%' }}>
                {!guestqnsswapped
                    ? <>
                        <div className='ig_toprowbtns'>
                            <button className="iglbtnfalse" onClick={close} >
                                {iconwrapper(GUESTLIST_ICON, { width: 26, height: 26 })}
                                {navbtntxt('Guestlist', ``, {})}
                            </button>
                            {!rsvpactive
                                ? <>
                                    <button className="iglbtnfalse" onClick={() => { props.opencatmodal() }} >
                                        {iconwrapper(CAT_ICON, { width: 26, height: 26 })}
                                        {navbtntxt('Category', ``, {})}
                                    </button>
                                    <button className="iglbtnfalse" onClick={() => { setTemplatersvpqnmodal(true) }} >
                                        {iconwrapper(TEMPLATE_ICON, { width: 26, height: 26 })}
                                        {navbtntxt('Template', ``, {})}
                                    </button>
                                </>
                                : null}
                        </div>

                        <div className='ig_publishroot' style={{ placeContent: 'flex-end' }}>
                            {rsvpactive
                                ? <button
                                    className={rsvpactive ? 'ig_published' : 'ig_publish'}
                                    onClick={rsvpactivefunc}>
                                    {rsvpactive ? iconwrapper(TICK_ICON, TICKICON_CSS) : null}
                                    <div>{rsvpactive ? 'Published' : 'Publish'}</div>
                                </button>
                                : <button className="igladdbtn" onClick={() => { setAddqnbool(true) }} >
                                    <div className="igl_plus">+</div>
                                    <div className='Rsvpexplorer_qntxt'>QN</div>
                                </button>
                            }

                        </div>
                    </>
                    : null}

            </div>
        </div>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', position: 'relative', overflowY: 'auto', height: isMobile ? 'calc( 100vh - 170px )' : '100%', overflowX: 'hidden' }}>
            <div className='ig_publishroot' style={{ padding: '10px 10px 0px 10px', placeContent: 'end', width: '100%' }}>
                <button id="Rsvqnexplorer_backbtn" onClick={close}>{CLOSE_ICON}</button>
            </div>
            <DragDropContext className='Rsvpexplorer_temp' onDragEnd={onDragend}  >
                <Droppable droppableId={'main'} type="main">
                    {(provided, snapshot) => (
                        <div id='Rsvpexplorer_dragroot' {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                            {guestqns.map((qnobj, qnindex) => {

                                var qn = qnobj[0]
                                var qn_type = qnobj[1]

                                var alloptions_of_currqn
                                if (qn_type === MULTIPLECHOICE) { // qntype === 1 do not have any options cos is a text qn
                                    // alert(qn_type)
                                    // console.log('qnobj', qnobj)
                                    alloptions_of_currqn = qnobj.slice(8, qnobj.length)[0]
                                }
                                else {
                                    alloptions_of_currqn = []
                                }

                                const mainexpand_state = expandablestates && expandablestates[qnindex] && expandablestates[qnindex]['mainexpand']

                                const expand = () => {
                                    setExpandablestates((old) => {
                                        var currexpandablestates = JSON.parse(JSON.stringify(old))
                                        currexpandablestates[qnindex]['mainexpand'] = !currexpandablestates[qnindex]['mainexpand']
                                        return currexpandablestates
                                    })
                                }

                                return <Draggable key={qnindex + 'qn'} draggableId={qnindex + 'qn'} index={qnindex} type="main" >
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps}>
                                            <div id={`${qnindex === guestqns.length - 1 ? 'Rsvpexplorer_draggablemainlast' : 'Rsvpexplorer_draggablemain'}`}>
                                                {qnindex_dom(qnindex, qn_type, provided, guestqns)}
                                                <div id='Rsvpqnexplorer_singleqnitembtmroot' style={{ width: `calc( 100% - ${INDEX_WT}px )` }}>
                                                    <div id='Rsvpqnexplorer_singleqnitemcontentroot'>
                                                        {qn_type === MULTIPLECHOICE
                                                            ? <div id='Rsvpqnexplorer_singleqnitemcontent'>
                                                                {mainexpand_state ? <div style={{ borderLeft: '1px solid black', height: 'calc( 100% + 10px )', left: -(INDEX_WT / 2) - 1, position: 'absolute' }} /> : null}
                                                                <div id='Rsvpqnexplorer_qn'>{qn}</div>
                                                                <button id='Rsvpexplorer_dropdownbtn' onClick={expand}>
                                                                    <div id="Rsvpexplorer_txtnumb"> {alloptions_of_currqn.length + ' Options'}</div>
                                                                    {mainexpand_state ? iconwrapper(RIGHTARROW_ICON, ARROW_CSS) : iconwrapper(DOWNARROW_ICON, ARROW_CSS)}
                                                                </button>
                                                            </div>
                                                            : <div id='Rsvpqnexplorer_singleqnitemcontent'>
                                                                <div id='Rsvpqnexplorer_qn'>{qn}</div>
                                                                <button id='Rsvpexplorer_dropdownbtn' className='Rsvpexplorer_btnhidden' >
                                                                    <div id='Rsvpexplorer_txtnumb' />
                                                                    {'_'}
                                                                </button>
                                                            </div>}
                                                    </div>
                                                    {qn_type === MULTIPLECHOICE
                                                        ? alloptions_of_currqn.map((optionpair, optionindex) => {
                                                            let optiontxt = optionpair[0]
                                                            let followupqn = optionpair[1]
                                                            const num_followup_qnoptions = ((typeof alloptions_of_currqn[optionindex][1]) === 'object') ? alloptions_of_currqn[optionindex][1].length : null
                                                            const mainoptionexpand_state = expandablestates && expandablestates[qnindex] && expandablestates[qnindex]['optionexpand'] && expandablestates[qnindex]['optionexpand'][optionindex] && expandablestates[qnindex]['optionexpand'][optionindex][0]
                                                            const togglefollowup_func = () => {
                                                                let new_expandablestates = expandablestates.slice()
                                                                expandablestates[qnindex]['optionexpand'][optionindex][0] = !expandablestates[qnindex]['optionexpand'][optionindex][0]
                                                                setExpandablestates(new_expandablestates)
                                                            }
                                                            return mainexpand_state
                                                                ? <div key={qnindex + '_' + optionindex + '_optionobj'} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                                    {genoption_dom('', optiontxt, optionindex, alloptions_of_currqn, mainoptionexpand_state, num_followup_qnoptions, togglefollowup_func, qnindex)}
                                                                    {/* {followupempty_dom(qnindex, optionindex)} */}
                                                                    {mainoptionexpand_state
                                                                        ? followupdnd_dom(followupqn, qnindex, optionindex, alloptions_of_currqn)
                                                                        : null}
                                                                </div>
                                                                : null
                                                        })
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            })}
                            {provided.placeholder}
                            <div style={{ height: 100 }} />
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>

        {guestqnsswapped
            ? <div className='Rsvpqnexplorer_swappedsavedroot'>
                <button
                    className='Rsvpqnexplorer_swappedcancel'
                    onClick={cancelswap}>
                    Cancel
                </button>
                <button
                    className='Rsvpqnexplorer_swappedsavedbtn'
                    onClick={() => {
                        try {
                            axios({
                                method: 'POST',
                                url: INVITATION_GUESTLIST_SWAP,
                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                data: { invitationid, guestqns, guestlistreadcounter }
                            }).then((item) => {
                                item && item.data && item.data.message === 'SUCCESS'
                                    ? successswap(guestqns, expandablestates)
                                    : cancelswap()
                            })
                        }
                        catch (e) { }
                    }} >
                    <div>Save</div>
                </button>
            </div>
            : null}

        {addqnbool
            ? <Rsvpqnportal {...props} from='addnewqn' boolgate={addqnbool} close={() => setAddqnbool(false)} title={"Add New Question"} expandablestates={expandablestates} />
            : null}

        {editqnbool
            ? <Rsvpqnportal {...props} from='editqn' boolgate={editqnbool} close={() => setEditqnbool(false)} title={"Edit Question"} selectedqnindex={selectedqnindex} expandablestates={expandablestates} />
            : null}

        {addfollowupbool
            ? <Rsvpqnportal {...props} from='addnewfollowupqn' boolgate={addfollowupbool} close={() => setAddfollowupbool(false)} title={"Add New Follow-Up Question"} selectedmainqnindex={selectedmainqnindex} selectedoptionindex={selectedoptionindex} expandablestates={expandablestates} />
            : null}

        {editfollowupqnbool
            ? <Rsvpqnportal {...props} from='editfollowupqn' boolgate={editfollowupqnbool} close={() => setEditfollowupqnbool(false)} title={"Edit Followup Question"} selectedmainqnindex={selectedmainqnindex} selectedoptionindex={selectedoptionindex} selectedfollowupqnindex={selectedfollowupqnindex} expandablestates={expandablestates} />
            : null}

        {del
            ? <Portalmodal
                animation={'right_left'}
                portaltype={'commonportal'}
                open={del}
                onClose={() => { setDel(false) }}
                backdropvisible={true}
                modalstyle={portalleftcss}
                closestyle={{ right: 0 }}
                floatingclosecolor='#333333'
            >
                <div className='Rsvpexplorer_portal'>
                    <div className='Rsvpexplorer_portaltitle'>Delete Question</div>
                    <div className='Rsvpexplorer_portalcontent' style={{ height: '100%' }}>
                        {{
                            'DEFAULT': <div className='Rsvpqnexplorer_portalroot'>
                                <div className='Rsvpqnexplorer_portalmaincontenttitle'>This will permanently delete the question. Please be certain.</div>
                                <div className='Rsvpqnexplorer_portalcfmdeleteroot'>
                                    <button className='Rsvpqnexplorer_portalmodalleftbtn' onClick={cfmdelete} >Delete</button>
                                    <button className='Rsvpqnexplorer_portalmodalrightbtn' onClick={canceldel} >Cancel</button>
                                </div>
                            </div>,
                            'SUCCESS': <div className='Rsvpqnexplorer_portalroot'>
                               <Success txt={'Question successfully deleted. Click to return.'} onClick={canceldel} />
                            </div>,
                            'FOLLOWUPEXIST': <div className='Rsvpqnexplorer_portalroot'>
                                <div className='Rsvpqnexplorer_portalmaincontenttitle'>Remove all follow-up questions in order to remove this question.</div>
                                <button className='Rsvpexplorer_portalsave' onClick={canceldel} >OK</button>
                            </div>
                        }[delreturn]}
                    </div>
                </div>

            </Portalmodal>
            : null}

        {AddcatModal}

        {templatersvpqnmodal
            ? <Portalmodal
                animation={'right_left'}
                portaltype={'commonportal'}
                open={templatersvpqnmodal}
                onClose={templatersvpqnmodalclose}
                floatingclose={false}
                backdropvisible={true}
                modalstyle={portalleftcss}
                closestyle={{ right: 0 }}
                floatingclosecolor='#333333'
            >
                <Rsvptemplatecontent
                    templatersvpqnmodal={templatersvpqnmodal}
                    addtemplatersvpqnstate={addtemplatersvpqnstate}
                    close={templatersvpqnmodalclose}
                    save={saveRsvptemplate}
                />
            </Portalmodal>
            : null}
    </div >

}
export default Rsvpqnexplorer

