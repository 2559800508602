import React, { useState, useRef, useEffect } from 'react';
import { EMPTY, PUNCHLINE, RECAPTCHA_KEY, production, GUESTBOOK_SMALL, TOOLS_LARGE, STARTUP_NAME, WEBSITE_URL } from '../../config/constants'
import { Directedline, Login } from './components'
import { BUTTONSTYLE } from '../../config/cssconstants'
import { USER_RESEND_VERIFICLP, USER_FORGTPW } from '../../config/api'
import { Checkdevice, ScreenWidth, Loading, Preloadimages, Footer, Portalmodal } from '../../components'
import { imguserloginmobile, ultxtbgimg } from '../../assets/userlogin'
import { iconwrapper } from '../../config/commonfunctions';
import { WEAVEBLISS_WHITE, PLANNING_INVITE_ICON, THINVENDOR_ICON, THINGUESTLIST_ICON, PLANNING_SEATING_ICON, PLANNING_BUDGET_ICON, PLANNING_TIMELINE_ICON, PLANNING_CHECKLIST_ICON, PLANNING_GUESTBOOK_ICON, THINMOODBOARD_ICON, PLANNING_QR_ICON, PLANNING_COMMUNICATION_ICON } from '../../config/svgicons';
import { input_dom } from '../Main/Planning/Invitationmaker/components/Commonelements/Commonelements';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios'
import './UserLogin.css'
import { S3CLOUD_CLOUDFRONT_USERLOGIN } from '../../config/s3directories';

const logo_div_mobile = iconwrapper(WEAVEBLISS_WHITE, { width: 90, height: 90, color: 'var(--maindark)', display: 'flex', placeSelf: 'center' })
const logo_div = iconwrapper(WEAVEBLISS_WHITE, { width: 100, height: 100, color: 'var(--maindark)', display: 'flex', placeSelf: 'center' })
const icon_css = { width: 33, height: 33, display: 'flex', placeContent: 'center', alignItems: 'center' }

const DEVICE_HEROIMGHT_MAP = { 'TABLET': 350, 'WEB': 400, }
const w_or_e = (pn) => {
    return <div id="UL_worehighlightroot">
        <div className={pn === 'event' ? "ul_second_title" : "ul_first_title"}>Wedding </div>
        <div id="UL_and">{'&'} </div>
        <div className={pn === 'event' ? "ul_first_title" : "ul_second_title"}>Event </div>
    </div>
}
const directeddom = (showall) => {
    return (
        <section className={'UserLoginSectionBtm'}>
            <Directedline
                titles={[
                    'RSVP Website',
                    'Guest List',
                    'Seating Chart',
                    'Digital Guestbook',
                    'Timeline Itinerary',
                    'Budget Tracker',
                    'Checklist',
                    'Vendor & Venue',
                    'Mood Board',
                    'QR Code',
                    'Community'
                ]}
                contents={[
                    'Create a beautiful wedding website that showcases your love story, venue details, and essential information.',
                    'Our guest list feature simplifies RSVP collection from your invitees. Easily manage your guest list, send invitations, and receive responses all in one place.',
                    'Create and manage your wedding seating chart effortlessly with our seating chart tool. Your invitees can view the seating chart online via a unique link or, if you wish, print it for your special day.',
                    'Invitees can sign and leave their well-wishes on the digital guest book during the actual day. Heartfelt messages can be displayed on the screen for everyone to see during the actual day.',
                    `Effortlessly create and manage your event's timeline itinerary. Print it or share it online with a unique URL for your invitees.`,
                    'Track your wedding or event expenses with ease using our budget tracker. Keep tabs on vendor and venue costs seamlessly.',
                    `Stay organized with our checklist feature, ensuring you don't miss any crucial details for your wedding or event.`,
                    'Explore a curated selection of wedding vendors and venues tailored to your style, budget, and location. Connect, compare, and choose the best options for your dream wedding effortlessly.',
                    'Create inspiring mood boards effortlessly with our feature. Gather and organize your visual ideas for any project, wedding, or event seamlessly.',
                    'Create a QR code for swift access to the RSVP website, timeline itinerary,seating chart, and much more.',
                    'A community to connect with other brides, share valuable information and experiences related to wedding vendors and all things wedding-related, and seek advice and recommendations.',
                ]}
                icons={[
                    iconwrapper(PLANNING_INVITE_ICON, icon_css),
                    iconwrapper(THINGUESTLIST_ICON, icon_css),
                    iconwrapper(PLANNING_SEATING_ICON, icon_css),
                    iconwrapper(PLANNING_GUESTBOOK_ICON, icon_css),
                    iconwrapper(PLANNING_TIMELINE_ICON, icon_css),
                    iconwrapper(PLANNING_BUDGET_ICON, icon_css),
                    iconwrapper(PLANNING_CHECKLIST_ICON, icon_css),
                    iconwrapper(THINVENDOR_ICON, icon_css),
                    iconwrapper(THINMOODBOARD_ICON, icon_css),
                    iconwrapper(PLANNING_QR_ICON, icon_css),
                    iconwrapper(PLANNING_COMMUNICATION_ICON, icon_css)
                ]}
                images={[
                    S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/rsvp.gif',
                    S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/guestlist.png',
                    S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/seatchart.png',
                    S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/guestbook.png',
                    S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/timeline.png',
                    S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/budgettracker.png',
                    S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/checklist.png',
                    S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/vendor.png',
                    S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/board.png',
                    S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/qr.png',
                    S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/community.png'
                ]}
                mimages={[
                    ' ',
                    '',
                    ' ',
                    '',
                    ' ',
                    '',
                    '',
                    '',
                    '',
                    '',
                    ''
                ]}
                showall={showall}
            />
        </section>
    )

}

const Usname = <div style={{ display: 'flex', fontSize: 35, width: '100%', placeContent: 'center', fontFamily: 'Marck Script' }}>{STARTUP_NAME}</div>

function FeatureSingleItem({ n, i }, index) {
    return <div
        key={'fsi_' + index}
        className="Ul_singlefeatureitem"
    >
        {i}
        <div style={{ display: 'flex', flexWrap: 'wrap', textAlign: 'center', paddingTop: 8, height: 50 }}>
            {n}
        </div>
    </div>
}

function subtitle_m() {
    return (
        <div className="UserLoginSubtitleRoot" >
            <div className={"UserLoginSubtitleSubRootMOBILE"}>
                {logo_div_mobile}
                {Usname}
                <div className="UserLoginSubtitle_m">
                    <div>{`Wedding & Event Planning Made Simple`}</div>
                    <div>{`Celebrations Made Memorable`}</div>
                </div>
                <div className="UL_iconroot">
                    <div className="UL_iconrow" >
                        {[
                            { n: 'RSVP Website', i: iconwrapper(PLANNING_INVITE_ICON, icon_css) },
                            { n: 'Vendor & Venue', i: iconwrapper(THINVENDOR_ICON, icon_css) },
                            { n: 'Seating Chart', i: iconwrapper(PLANNING_SEATING_ICON, icon_css) },
                        ].map((item, index) => FeatureSingleItem(item, index))}
                    </div>
                    <div className="UL_iconrow">
                        {[
                            { n: 'Guest List', i: iconwrapper(THINGUESTLIST_ICON, icon_css) },
                            { n: 'Timeline Itinerary', i: iconwrapper(PLANNING_TIMELINE_ICON, icon_css) },
                            { n: 'Budget Tracker', i: iconwrapper(PLANNING_BUDGET_ICON, icon_css) },
                        ].map((item, index) => FeatureSingleItem(item, index))}
                    </div>
                    <div className="UL_iconrow" >
                        {[
                            { n: 'Checklist', i: iconwrapper(PLANNING_CHECKLIST_ICON, icon_css) },
                            { n: 'Digital Guestbook', i: iconwrapper(PLANNING_GUESTBOOK_ICON, icon_css) },
                            { n: 'Mood Board', i: iconwrapper(THINMOODBOARD_ICON, icon_css) },
                        ].map((item, index) => FeatureSingleItem(item, index))}
                    </div>
                    <div className="UL_iconrow" >
                        {[
                            { n: 'QR Code', i: iconwrapper(PLANNING_QR_ICON, icon_css) },
                            { n: 'Community', i: iconwrapper(PLANNING_COMMUNICATION_ICON, icon_css) },
                        ].map((item, index) => FeatureSingleItem(item, index))}
                    </div>
                </div>
            </div>
        </div>
    )
}

function Loadimgs() {
    var WEDDING_ARR_IMGS = []
    var EVENT_ARR_IMGS = []
    var P_DETAILS_MAP = {}
    var listofallsrc = []

    if (production) {
        const w = S3CLOUD_CLOUDFRONT_USERLOGIN + 'w/'
        const e = S3CLOUD_CLOUDFRONT_USERLOGIN + 'e/'

        WEDDING_ARR_IMGS = [ // 300 400
            [w + '1.jpeg', w + '2.jpeg', w + '3.jpeg', w + '4.jpeg'],
            [w + '6.jpeg', w + '7.jpeg', w + '8.jpeg', w + '9.jpeg'],
            [w + '11.jpeg', w + '12.jpeg', w + '13.jpeg', w + '14.jpeg'],
            [w + '16.jpeg', w + '17.jpeg', w + '18.jpeg', w + '19.jpeg'],
            [w + '21.jpeg', w + '22.jpeg', w + '23.jpeg', w + '24.jpeg'],
            [w + '26.jpeg', w + '27.jpeg', w + '28.jpeg', w + '29.jpeg']
        ]

        EVENT_ARR_IMGS = [
            [e + '1.jpeg', e + '2.jpeg', e + '3.jpeg', e + '4.jpeg'],
            [e + '5.jpeg', e + '6.jpeg', e + '7.jpeg', e + '8.jpeg'],
            [e + '9.jpeg', e + '10.jpeg', e + '11.jpeg', e + '12.jpeg'],
            [e + '13.jpeg', e + '14.jpeg', e + '15.jpeg', e + '16.jpeg'],
            [e + '17.jpeg', e + '18.jpeg', e + '19.jpeg', e + '20.jpeg'],
            [e + '21.jpeg', e + '22.jpeg', e + '23.jpeg', e + '24.jpeg']
        ]
    }
    else {
        WEDDING_ARR_IMGS = [
            ['https://media.publit.io/file/15-min.jpeg', 'https://images.pexels.com/photos/4688544/pexels-photo-4688544.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500', 'https://images.pexels.com/photos/3204418/pexels-photo-3204418.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500', 'https://images.pexels.com/photos/5544651/pexels-photo-5544651.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500', 'https://media.publit.io/file/5-min-7.jpeg'], // 
            ['https://media.publit.io/file/4-min.jpeg', 'https://images.pexels.com/photos/5529446/pexels-photo-5529446.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500', 'https://images.unsplash.com/photo-1562826772-be179f321470?ixid=MXwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8OXw4OTMzMjcwfHxlbnwwfHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60', 'https://images.unsplash.com/photo-1514432470525-0b010026ae13?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80', 'https://images.unsplash.com/photo-1599693132738-0941d4f76445?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8d2VkZGluZyUyMGRyb25lfGVufDB8MXwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60'],
            ['https://media.publit.io/file/15-min.jpeg', 'https://images.pexels.com/photos/3818913/pexels-photo-3818913.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260', 'https://images.pexels.com/photos/1730877/pexels-photo-1730877.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500', 'https://images.pexels.com/photos/916344/pexels-photo-916344.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500', 'https://media.publit.io/file/11-min-J.jpeg'],//
            ['https://media.publit.io/file/11-min-J.jpeg', 'https://images.pexels.com/photos/1424810/pexels-photo-1424810.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500', 'https://images.pexels.com/photos/931158/pexels-photo-931158.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500', 'https://images.pexels.com/photos/931179/pexels-photo-931179.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500', 'https://media.publit.io/file/14-min.jpeg'],
            ['https://media.publit.io/file/6-min.jpeg', 'https://images.pexels.com/photos/1608589/pexels-photo-1608589.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500', 'https://images.unsplash.com/photo-1552221856-cd364b9822a0?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mzl8fHdlZGRpbmclMjBwcmVwYXJpbmd8ZW58MHwxfDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60', 'https://images.unsplash.com/photo-1581296638467-021fd5e62ea5?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MzI3fHx3ZWRkaW5nJTIwcHJlcGFyaW5nfGVufDB8MXwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60', 'https://media.publit.io/file/15-min.jpeg'],// 
            ['https://media.publit.io/file/5-min-7.jpeg', 'https://images.unsplash.com/photo-1549997966-5dd46b8e2b06?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Njd8fHdlZGRpbmd8ZW58MHwxfDB8YmxhY2s%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60', 'https://images.pexels.com/photos/4997170/pexels-photo-4997170.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500', 'https://images.pexels.com/photos/2111255/pexels-photo-2111255.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500', 'https://media.publit.io/file/13-min.jpeg', 'https://media.publit.io/file/16-min.jpeg'], // ,
        ]

        EVENT_ARR_IMGS = [
            ['https://media.publit.io/file/eventtttt/7-min.jpeg', 'https://media.publit.io/file/6-min.jpeg', 'https://media.publit.io/file/eventtttt/18-min.jpeg', 'https://media.publit.io/file/eventtttt/3-min.jpeg', 'https://media.publit.io/file/eventtttt/11-min.jpeg'], //
            ['https://media.publit.io/file/eventtttt/10-min.jpeg', 'https://media.publit.io/file/eventtttt/1-min.jpeg', 'https://media.publit.io/file/eventtttt/16-min.jpeg', 'https://media.publit.io/file/eventtttt/17-min.jpeg', 'https://media.publit.io/file/eventtttt/14-min.jpeg'], //
            ['https://media.publit.io/file/eventtttt/8-min.jpeg', 'https://media.publit.io/file/eventtttt/4-min.jpeg', 'https://media.publit.io/file/eventtttt/15-min.jpeg', 'https://media.publit.io/file/eventtttt/2-min.jpeg', 'https://media.publit.io/file/eventtttt/13-min.jpeg'],
            ['https://media.publit.io/file/eventtttt/9-min.jpeg', 'https://media.publit.io/file/eventtttt/2-min.jpeg', 'https://media.publit.io/file/eventtttt/13-min.jpeg', 'https://media.publit.io/file/eventtttt/1-min.jpeg', 'https://media.publit.io/file/eventtttt/16-min.jpeg'],
            ['https://media.publit.io/file/eventtttt/6-min.jpeg', 'https://media.publit.io/file/eventtttt/17-min.jpeg', 'https://media.publit.io/file/eventtttt/14-min.jpeg', 'https://media.publit.io/file/eventtttt/10-min.jpeg', 'https://media.publit.io/file/eventtttt/1-min.jpeg'], // 
            ['https://media.publit.io/file/eventtttt/5-min.jpeg', 'https://media.publit.io/file/eventtttt/3-min.jpeg', 'https://media.publit.io/file/eventtttt/11-min.jpeg', 'https://media.publit.io/file/6-min.jpeg', 'https://media.publit.io/file/eventtttt/18-min.jpeg'], // 
        ]

    }

    P_DETAILS_MAP = { // PATH - WEDDING/EVENT DETAILS
        'WEB': [WEDDING_ARR_IMGS, EVENT_ARR_IMGS, 'wedding', 'event', 'weddinghero', 'eventhero'],
        'eventWEB': [EVENT_ARR_IMGS, WEDDING_ARR_IMGS, 'event', 'wedding', 'eventhero1', 'weddinghero1'],
        'weddingWEB': [WEDDING_ARR_IMGS, EVENT_ARR_IMGS, 'wedding', 'event', 'weddinghero', 'eventhero'],
        'TABLET': [WEDDING_ARR_IMGS.slice(1, -1), EVENT_ARR_IMGS.slice(1, -1), 'wedding', 'event', 'weddinghero', 'eventhero'],
        'eventTABLET': [EVENT_ARR_IMGS.slice(1, -1), WEDDING_ARR_IMGS.slice(1, -1), 'event', 'wedding', 'eventhero1', 'weddinghero1'],
        'weddingTABLET': [WEDDING_ARR_IMGS.slice(1, -1), EVENT_ARR_IMGS.slice(1, -1), 'wedding', 'event', 'weddinghero', 'eventhero']
    }

    for (let i = 0; i < P_DETAILS_MAP['weddingWEB'][0].length; i++) {
        for (let j = 0; j < P_DETAILS_MAP['weddingWEB'][0][i].length; j++) {
            new Image().src = P_DETAILS_MAP['weddingWEB'][0][i][j]
            listofallsrc.push(P_DETAILS_MAP['weddingWEB'][0][i][j])
        }
    }

    listofallsrc = listofallsrc.concat([
        // S3CLOUD_CLOUDFRONT_USERLOGIN + '0001.png',
        // S3CLOUD_CLOUDFRONT_USERLOGIN + '0002.gif',
        // S3CLOUD_CLOUDFRONT_USERLOGIN + '0003.png',
        // S3CLOUD_CLOUDFRONT_USERLOGIN + '0004.png',
        // S3CLOUD_CLOUDFRONT_USERLOGIN + '0005.png',
        // S3CLOUD_CLOUDFRONT_USERLOGIN + '0006.png',
        // S3CLOUD_CLOUDFRONT_USERLOGIN + '0007.png',
        // S3CLOUD_CLOUDFRONT_USERLOGIN + '0008.png',
        // S3CLOUD_CLOUDFRONT_USERLOGIN + '0003mobile.png'
        S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/rsvp.gif',
        S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/guestlist.png',
        S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/seatchart.png',
        S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/guestbook.png',
        S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/timeline.png',
        S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/budgettracker.png',
        S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/checklist.png',
        S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/vendor.png',
        S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/board.png',
        S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/qr.png',
        S3CLOUD_CLOUDFRONT_USERLOGIN + 'directedline/sm/community.png'
    ]);

    return { P_DETAILS_MAP, listofallsrc }
}

function UserLogin(props) {
    const pathname = props.history.location.pathname.substring(1).toLowerCase()
    const device = Checkdevice()

    // wt
    const wt = ScreenWidth()
    const [cardwt, setCardwt] = useState(0)

    // resend verification stuff
    const [resendveri, setResendveri] = useState(false)
    const [resendemail, setResendemail] = useState(EMPTY)
    const [resendmsg, setResendmsg] = useState(EMPTY)
    const [resendemailgate, setResendemailgate] = useState(false)

    // forgt pw
    const [forgtpwstate, setForgtpwstate] = useState(false)
    const [forgtpwemail, setForgtpwemail] = useState(EMPTY)
    const [forgtpwmsg, setForgtpwmsg] = useState(EMPTY)
    const [forgtpwbtngate, setForgtpwbtngate] = useState(false)

    // to detect if captchais verified or not (FOR FORGT PW)
    const [captchagate, setCaptchagate] = useState(false)
    const captchaRef = useRef(null) // for captcha
    const [captchacode, setCaptchacode] = useState(EMPTY)

    // to detect if captchais verified or not (FOR VERIFYING TOKEN)
    const [captchagatetoken, setCaptchagatetoken] = useState(false)
    const [captchacodetoken, setCaptchacodetoken] = useState(EMPTY)
    const captchaReftoken = useRef(null) // for captcha

    const [pdetailsmap, setPdetailsmap] = useState({})

    const [firsttime, setFirsttime] = useState(true)

    const [showall, setShowall] = useState(false)

    // login ref
    const loginref = useRef(null)

    useEffect(() => {
        const { P_DETAILS_MAP, listofallsrc } = Loadimgs()

        if (firsttime) {
            setPdetailsmap(P_DETAILS_MAP)
            window.scroll({ top: 0, behavior: "smooth" }) // scroll up
            if (device !== 'MOBILE') {
                tload()
                Preloadimages(listofallsrc, () => {
                    try {
                        setCardwt((wt / 100) * (100 / P_DETAILS_MAP[pathname + device][0].length))
                        setFirsttime(false)
                        myStopFunction()
                    }
                    catch (e) {
                        setFirsttime(false)
                        myStopFunction()
                    }
                })

                var myVar;
                function tload() {
                    myVar = setTimeout(function () {
                        setFirsttime(false)
                    }, 1000);
                }
                function myStopFunction() {
                    clearTimeout(myVar);
                }
            }
            else setFirsttime(false)
        }


    }, [])

    useEffect(() => {
        try { setCardwt((wt / 100) * (100 / pdetailsmap[pathname + device][0].length)) }
        catch (e) { }
    }, [wt])

    const loginfields = <div key={'loginfields'} className={"ullogin_loginfield" + device} >
        <Login />
        <div className="ullogin_loginfieldTop">
            <div className="ullogin_acctright">Don't have an account? </div>
            <div className="ullogin_clicksignup" onClick={() => props.history.push('/signup')}>Sign Up</div>
        </div>
        <div className="ullogin_loginfieldCenter" onClick={() => setForgtpwstate(true)}>Forgotten Password?</div>
        <div className="ullogin_loginfieldBtm" onClick={() => setResendveri(true)}>Account Verification</div>
    </div>

    const lsbtns = <div className="ulloginsignup_root">
        <img id="UL_imguserloginmobile" src={imguserloginmobile} alt="" />
        <div className="ulloginsignup_login" onClick={() => { loginref.current.scrollIntoView({ behavior: 'smooth' }); setShowall(true); }}>LOGIN</div>
        <div className="ulloginsignup_signup" onClick={() => { props.history.push('/signup') }}>SIGN UP</div>
    </div>

    const hiddenbusinesslink = <a id='bzlink' style={{ width: 0, height: 0, visibility: 'hidden' }} target={"_blank"} href={`https://business.${WEBSITE_URL}`} />
    const businessfield = <button
        className={"ullogin_bizbtn" + device}
        target={"_blank"}
        onClick={() => {
            document.getElementById('bzlink').click()
        }}
    >
        Business
    </button>

    const portalmidcss = { width: { 'WEB': 500, 'TABLET': 450, 'MOBILE': '100%' }[device], height: { 'WEB': 500, 'TABLET': 400, 'MOBILE': '100%' }[device], backgroundColor: 'white', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)' }

    const veri_modal = <Portalmodal
        portaltype={'commonportal'}
        open={resendveri}
        onClose={() => {
            setResendveri(false); setResendemail(EMPTY); setResendmsg(EMPTY)
        }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalmidcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='var(--maindark)'>
        <div className={device === 'MOBILE' ? 'Userlogin_modalmobile' : 'Userlogin_modal'} >
            <div style={{ padding: 2.5 }}>Enter your email address and we will send you a new verification email. Please note, verification email might be in your spam mail box.</div>
            {input_dom('', `Email`, resendemail, (e) => { setResendemail(e.target.value); }, 'resendemail', {}, null, null, {}, { padding: 0, margin: 0, width: '100%', marginBottom: 5 })}
            {!captchagate && resendemail.length > 0
                ? <button
                    className='VL_loginbtn'
                    onClick={() => {
                        if (resendemail.length > 0) {
                            setCaptchagate(true)
                        }
                    }}
                >
                    Submit
                </button>
                : null}

            {captchagate
                ? <div id='forgtpw_captcharoot' style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                    <ReCAPTCHA
                        style={{ padding: 0, boxShadow: null, margin: 0 }}
                        ref={(el) => { captchaRef.current = el }}
                        sitekey={RECAPTCHA_KEY}
                        onChange={(res) => {
                            if (res) {
                                let options = {
                                    method: 'POST',
                                    url: USER_RESEND_VERIFICLP,
                                    data: { email: resendemail, captchacodetoken: res }
                                }
                                axios(options).then((result) => {
                                    const { message, } = result.data // close
                                    setResendmsg(message)
                                    setResendemail(EMPTY)
                                    setCaptchagate(false)
                                })

                            }
                        }}
                    />
                </div>
                : null}

            <div style={{ height: 60, marginTop: 8, padding: '0px 2.5px' }}>
                {resendmsg}
            </div>
        </div>
    </Portalmodal>

    const forgtpw_modal = <Portalmodal
        portaltype={'commonportal'}
        open={forgtpwstate}
        onClose={() => {
            setForgtpwstate(false); setForgtpwemail(EMPTY); setForgtpwmsg(EMPTY); setCaptchagate(false); setForgtpwbtngate(false)
        }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalmidcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='var(--maindark)'>
        <div className={device === 'MOBILE' ? 'Userlogin_modalmobile' : 'Userlogin_modal'} >
            <div style={{ padding: 5 }}>Enter your email address and we'll send you a link to change your password.</div>
            {input_dom('', `Email`, forgtpwemail, (e) => { setForgtpwemail(e.target.value); }, 'forgtemailkey', {}, null, null, {}, { padding: 0, margin: 0, width: '100%', marginBottom: 5 })}
            {!captchagate && forgtpwemail.length > 0
                ? <button
                    className='VL_loginbtn'
                    onClick={() => {
                        if (forgtpwemail.length > 0) {
                            setCaptchagate(true)
                        }
                    }}
                >
                    Submit
                </button>
                : null}

            {captchagate
                ? <div id='forgtpw_captcharoot' style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                    <ReCAPTCHA
                        style={{ padding: 0, boxShadow: null, margin: 0 }}
                        ref={(el) => { captchaRef.current = el }}
                        sitekey={RECAPTCHA_KEY}
                        onChange={(res) => {
                            if (res) {
                                var local_forgtpwemail = forgtpwemail.toLowerCase()
                                let options = {
                                    method: 'POST',
                                    url: USER_FORGTPW,
                                    data: { email: local_forgtpwemail, captchacode: res }
                                }
                                axios(options).then((result) => {
                                    const { message } = result.data // close
                                    setForgtpwmsg(message)
                                    setCaptchagate(false)
                                    setForgtpwemail(EMPTY)
                                })

                            }
                        }}
                    />
                </div>
                : null}

            <div style={{ height: 60, marginTop: 8, padding: 10 }}>{forgtpwmsg}</div>
        </div>
    </Portalmodal>

    const imgsslides = () => {
        if (!firsttime) {
            const l_pn = pdetailsmap[pathname + device]
            const imgwt0 = 100 / l_pn[0].length
            const imgwt1 = 100 / l_pn[1].length
            const HEROIMG_CSS = { height: DEVICE_HEROIMGHT_MAP[device], position: 'relative', width: cardwt }
            const firstval = -22 * 5
            const secval = -12.5 * 5
            return <div className="ul_imgsroot">
                <div className="ul_rowimgsroot">
                    {l_pn[0].map((objs, x) => <div key={x + '-row' + wt} id='UL_imgholder' className={l_pn[3]} style={{ width: imgwt0 + '%', top: x % 2 === 0 ? firstval : secval }}>
                        {objs.map((obj, y) => <img key={y + 'w' + wt} className={"ul_sirf_" + x} style={HEROIMG_CSS} src={obj} alt={''} />)}
                    </div>)}
                </div>
                <div className="ul_imgsroot">
                    {l_pn[1].map((objs, x) => <div key={x + '-row' + wt} id='UL_imgholder' className={l_pn[4]} style={{ width: imgwt1 + '%', top: x % 2 === 0 ? firstval : secval }}>
                        {objs.map((obj, y) => <img key={y + 'e' + wt} className={"ul_sirs_" + x} style={HEROIMG_CSS} src={obj} alt={''} />)}
                    </div>)}
                </div>
            </div>
        }
        else return null
    }

    if (firsttime) return <div id="UL_loading"><Loading /></div>

    const MOBILE_DOM = <div className={'UserLoginRoot'}>
        {lsbtns}
        {subtitle_m()}
        <h3 style={{ backgroundColor: 'var(--subpink)', margin: 0, padding: '30px 15px 20px 15px', fontFamily: 'Lato', }}>
            Discover how easy it is to plan a wedding or event with {`${STARTUP_NAME}`}. Our platform provides all the tools you need for a <strong style={{ fontStyle: 'italic', color: 'var(--mainpink)', fontWeight: 'bold' }}>smooth and stress-free</strong> celebration.
        </h3>
        {directeddom(showall)}
        <section ref={loginref} className={'UserLoginSectionTopMOBILE'}>
            <div className="UL_mainloginrootMOBILE">
                <div className="UL_mainloginsubrootMOBILE">
                    {logo_div}
                    {Usname}
                    <div className="UL_maincatandfeaturesroot">
                        <div className={'UL_maincatandfeaturespunchlineMOBILE'}>{PUNCHLINE}</div>
                        {/* {w_or_e(pathname)} */}
                    </div>
                    {loginfields}
                    {businessfield}
                </div>
            </div>
        </section>
        {veri_modal}
        {forgtpwstate ? forgtpw_modal : null}
        {hiddenbusinesslink}
        <Footer />
    </div>

    const WEBTABLET_DOM = <div className={'UserLoginRoot'}>
        <section className={'UserLoginSectionTop' + device}>
            {!firsttime ? <div className={'Ul_svgbg'} /> : null}
            <div className="UL_mainloginroot">
                <div className="UL_mainloginsubroot">
                    {logo_div}
                    {Usname}
                    <div className="UL_maincatandfeaturesroot">
                        <div className="UL_maincatandfeaturespunchline">
                            <div>Wedding & Event Planning Made Simple</div>
                            <div>Celebrations Made Memorable</div>
                        </div>
                        {/* <div className="UL_maincatandfeaturespunchline">{PUNCHLINE}</div> */}
                        {/* {w_or_e(pathname)} */}
                        {/* {features} */}
                    </div>
                    {loginfields}
                    {businessfield}
                </div>
            </div>
            {device === 'WEB' || device === 'TABLET' ? imgsslides() : null}
            {/* <div className="ul_transparency"/> */}
        </section>
        {/* {subtitle_w(device)} */}
        <h2 style={{ backgroundColor: 'var(--subpink)', margin: 0, padding: 100, fontFamily: 'Lato', }}>
            Experience the simplicity of planning your wedding or event with {`${STARTUP_NAME}`}, where all the essential tools are provided for a <strong style={{ fontStyle: 'italic', color: 'var(--mainpink)', fontWeight: 'bold' }}>seamless and stress-free</strong> celebration.
            {/* Discover how easy it is to plan a wedding or event with {`${STARTUP_NAME}`}. Our platform provides all the tools you need for a <strong style={{ fontStyle: 'italic', color: 'var(--mainpink)', fontWeight: 'bold' }}>smooth and stress-free</strong> celebration. */}
        </h2>
        {!firsttime ? directeddom() : null}
        {veri_modal}
        {forgtpw_modal}
        {hiddenbusinesslink}
        <Footer />
    </div>

    return {
        'MOBILE': MOBILE_DOM,
        'TABLET': WEBTABLET_DOM,
        'WEB': WEBTABLET_DOM
    }[device]

}

export default UserLogin