import React, { Component } from 'react'
import { Projectcard } from '../../../../components'
import { PROJECT_READ_SPECIFICIDS } from '../../../../config/api'
import { WEB, TABLET, PC_WT_MAP } from '../../../../config/constants';
import axios from 'axios'
import { subtitle } from '../Commonfunction';

const MESSAGE_READ_PROJECTIDS_SUCCESSFULL = 'You have successfully read projectids.'

const emptydom = <div style={{ height: 200, borderRadius: 5, color: 'var(--subgrey)', display: 'flex', placeContent: 'center', fontSize: 24 }} >
</div>

const projectstitle = 'Projects and portfolios by the vendor.'

class Renderprojects extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projectContent: [], // all projectinterfaces, in array
        }
    }

    componentDidMount = async () => {
        let options = {
            method: 'POST',
            url: PROJECT_READ_SPECIFICIDS,
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', },
            data: { projectinterface_ids: this.props.projectinterface_ids }
        }
        axios(options).then((item) => {
            const { data, status } = item
            const { error, result } = data
            if (error === false && status === 200) {
                this.setState({ projectContent: result })
            }
            else {
                this.setState({ error: MESSAGE_READ_PROJECTIDS_SUCCESSFULL })
            }
        })


    }

    render() {
        const { projectContent = [] } = this.state
        const { screenwidth } = this.props
        const device = screenwidth >= WEB ? 'WEB' : screenwidth >= TABLET ? 'TABLET' : 'MOBILE'
        const pcwt = PC_WT_MAP[device]
        return (
            <div style={{ display: 'flex', padding: '30px 0px 40px', flexDirection: 'column' }}>
                {subtitle('Project',projectstitle)}
                {projectContent.length > 0
                    ? <ul style={{ display: 'grid', gridTemplateColumns: `repeat(auto-fit, minmax(${pcwt}px, max-content))`, justifyContent: 'center', width: '100%', padding: 0, margin: 0, }}>
                        {projectContent.map((item, index) => (
                            <li key={'renderproject' + index} style={{ listStyleType: 'none', width: pcwt, display: 'flex', justifyContent: 'center', margin: '0px 5px' }}>
                                <Projectcard
                                    {...this.props}
                                    key={index}
                                    item={item}
                                    viewtype={1} // 0 : vendor viewing, 1 : user viewing
                                    device={device}
                                />
                            </li>
                        ))}
                    </ul>
                    : <div style={{ margin: '0px 10px' }}>
                        {emptydom}
                    </div>}
            </div>
        )



    }
}

export default Renderprojects
