import React from 'react'
import './Standardaccordion.css'

const arrow = <i fill="currentColor" style={{ display: 'inline-block' }}>
    <svg fill="currentColor" height="15" width="15" viewBox="0 0 1792 1792" style={{ display: "inline-block", verticalAlign: 'middle' }}><path d="M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z"></path>
    </svg>
</i>

function Standardaccordion(props) {

    const {
        title,
        items,
        rootstyle,
        titlestyle,
        itemstyle,
        open,
        updateopen
    } = props

    // const [open, setOpen] = useState(false)

    return <div className={`Standardaccordion_root`} style={rootstyle}>
        <div className={`Standardaccordion_title`} style={titlestyle} onClick={() => { updateopen(title)}}>
            <div>{title}</div>
            <div className={`Standardaccordion_arrow Standardaccordion_arrow${open ? 'down' : 'right'}`}>{arrow}</div>
        </div>
        <div className={`Standardaccordion_col ${open ? 'Standardaccordion_colexpand' : ''}`}>
            {items.map((item, i) => {
                return <div
                    style={itemstyle}
                    key={'accordionitem' + i}
                    className='Standardaccordion_item'
                >
                    {item}
                </div>
            })}
        </div>
    </div>
}

export default Standardaccordion