import {

    FETCH_PROJECTGALLERY_DATA_ACTION_FULFILLED,
    FETCH_PROJECTGALLERY_DATA_ACTION_PENDING,
    FETCH_PROJECTGALLERY_DATA_ACTION_REJECTED,

    FETCH_PROJECTGALLERYCONTENTSIZE_DATAINFO_ACTION_FULFILLED,
    FETCH_PROJECTGALLERYCONTENTSIZE_DATAINFO_ACTION_PENDING,
    FETCH_PROJECTGALLERYCONTENTSIZE_DATAINFO_ACTION_REJECTED

} from '../actions/projectgallery.action'

const initialState = {
    isLoading: false,
    isLoaded: false,
    data_info: [], // give the size etc
    data:[], // give the real data
}

//rmb to unwrap to the point where the basic data structure expose
export default (state = initialState, action) => {
    // try{
    //     console.log(action.payload.data.data)
    // }
    // catch(e){
    //     console.log(e)
    // }

    switch (action.type) {

        case FETCH_PROJECTGALLERYCONTENTSIZE_DATAINFO_ACTION_FULFILLED:
            return {
                ...state,
                data_info: action.payload.data.data,
                isLoading: false,
                isLoaded: true,
            }
        case FETCH_PROJECTGALLERYCONTENTSIZE_DATAINFO_ACTION_PENDING:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
            }
        case FETCH_PROJECTGALLERYCONTENTSIZE_DATAINFO_ACTION_REJECTED:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
            }


        case FETCH_PROJECTGALLERY_DATA_ACTION_FULFILLED:
            return {
                ...state,
                data: action.payload.data.data,
                isLoading: false,
                isLoaded: true,

            }
        case FETCH_PROJECTGALLERY_DATA_ACTION_PENDING:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
            }
        case FETCH_PROJECTGALLERY_DATA_ACTION_REJECTED:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
            }


        default:
            return state


    }
}