import React, { Component } from 'react'
import { FilterModalContent, FilterDisplaySingleElem, Header, MainMenu, Quiz } from '../components'
import { Vendorcard, Ticket, Jfmodal, Loading, Portalmodal } from '../../../components';
import { iconwrapper, Retrieve_personal_info } from '../../../config/commonfunctions'
import { WEB, READABLE_VENDORTYPE_MAP, EMPTYSPACE, EMPTY, TABLET, MENU_COLOR_MAP, PRESENCELOC_ABBREV_MAP, NEXT_PAGE_TIMING, e_fs, HOME_USER_MAX_NUMBER_CARD, RIGHTARROW_ICON_SMALL, LEFTARROW_ICON_SMALL, MAX_WEB, hostjoin, VT_QUIZ_MAP, QUIZ_DOT, MAIN_PINK_COLOR, CLOSE_ICON, STARTUP_NAME, HOME_COLLECTION_VENDORTYPE_WEDDING, WEDDINGVT_ICON_MAP, HOME_COLLECTION_READABLEVT_WEDDING_MAP, QUIZ_DOT_ABSOLUTE } from '../../../config/constants'
import { bindActionCreators } from 'redux'
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { BUTTONSTYLE } from '../../../config/cssconstants';
import { FILTERCURSOR_ICON, REFRESH_ICON, DOTDOTDOT_ICON, QUESTIONMARK_ICON } from '../../../config/svgicons';
import UserHomeCustomise from './UserHomeCustomise/UserHomeCustomise'
import UserHomeHost from './UserHomeHost/UserHomeHost'
import UserHomeHostReq from './UserHomeHostReq/UserHomeHostReq'
import * as types from '../../../redux/actions/homeuser.action'
import './Homeuser.css'
import Vendorcardmobile from '../../../components/Vendorcard/Vendorcardmobile';

const mintime = 4000
const ONE_TEXT = `Click to personalize your ${STARTUP_NAME} homepage.`
const TWO_TEXT = 'Click to view a list of vendors from your selected category.'
const THREE_TEXT = 'Click to change region.'
const FOUR_TEXT = 'Planning tools to help with your wedding/event planning.'
const VTBTN_HT = 120
const VT_HOMEUSER_BUTTONSTYLE = {
    backgroundColor: 'white',
    color: 'var(--maindark)',
    border: '1px solid transparent',
    boxShadow: '0 4px 5px 0 rgba(0, 0, 0, 0.2)',
    margin: 5,
    height: 50,
    padding: 5,
    whiteSpace: 'pre-wrap',
    borderRadius: 15,
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 13,
    letterSpacing: 1,
    outline: 'none',
    minWidth: VTBTN_HT,
    maxWidth: VTBTN_HT,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    userSelect: 'none'
}
function is_touch_device() {
    try {
        document.createEvent("TouchEvent");
        return true;
    } catch (e) {
        return false;
    }
}
const CalWt = (screen_width) => {

    var finalwt
    if (screen_width < WEB + 1) { // TABLET
        finalwt = TABLET - 50 // padding left and right
    }
    else {// IS WEB
        var percent = [1.45, 1.40, 1.35, 1.3, 1.25, 1.2, 1.15, 1.1, 1.05, 1]

        for (let i = 0; i < percent.length; i++) {
            if (screen_width > (WEB * percent[i])) {
                finalwt = (WEB * percent[i]) - 80
                break
            }
        }
    }
    return finalwt
}

const usertype_title_map = {
    'Wedding': ['Wedding'],
    'Event': ['Event'],
    'Guest': ['Attend ', 'stranger\'s', ' wedding | event']
}
const mapStateToProps = (state) => {
    // get state from reducer
    return {
        homeUser_data: state.Homeuser.homeUser_data, // default data fetch from backend
        singleVt_data: state.Homeuser.singleVt_data, // user apply filter on data, this will update homeUser_data with new data

        favourite: state.Homeuser.favourite,
        new_favourite: state.Homeuser.new_favourite,

        isLoaded: state.Homeuser.isLoaded,
        isLoading: state.Homeuser.isLoading,

        usertype: state.Homeuser.usertype, // usertype can only be change in customise

        message: state.Homeuser.message // exist if only Usertype is Guest
    }
}
const mapDispatchToProps = (dispatch) => { // https://www.bilibili.com/video/av23568132/?p=10
    // dispatch action to reducer
    return bindActionCreators(types, dispatch)
}

const { _id, token, acct_type } = Retrieve_personal_info()

var T1, T2, T3, T4

// @connect(mapStateToProps, mapDispatchToProps) // https://www.bilibili.com/video/av23568132/?p=11
class Homeuser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // user info
            _id: EMPTY,
            token: EMPTY,
            acct_type: EMPTY, // USER or VENDOR
            usertype: null, // Wedding or Event
            abbrev_userlocation: EMPTY, // Singapore, Malaysia, China, Taiwan, Hong Kong, Korea

            // screen dim
            screen_width: 0,
            screen_height: 0,

            homeUser_data: {},
            vt_keys: [],
            favourite: [], // favourite vendortypeinterface_id

            // for host modal
            host_gate: false,
            tickets: [],
            ticketmodal: false,
            ticket_data: [],

            // for edit modal
            edit_gate: false,
            data_loaded: false,

            // for filter
            filters: {}, // filters example:['Costume Accessories', ....]
            showfilter: false,
            selectedMainCategory: EMPTY,
            selectedVendorType: EMPTY,

            // for comparison chart
            show_chartdialog: false,

            // for refresh
            refreshStaus: false,

            // device 
            device: null,

            // translate val of all section, default is 0 
            currsection_x: [],
            left_right_counter: [],

            // showGuide
            showGuide: false,
            guidetext: '',
            guidetext_css: '',

            // Quiz
            showQuiz: false,
            quizCat: '',
            quizVt: '',

            date_w: '',
            name: '',
            spousename: '',

            esc: false,

            wedeventclickable: true,
            vtclickable: true,
            locclickable: true,
            appclickable: true

        }


        this.homeuser_header_ref = React.createRef()
        this.homeuser_planningtools_ref = React.createRef()

        var temp = []
        for (let i = 0; i < 30; i++) { //30 is just some random number. would really need some arbitary large number 
            temp.push(React.createRef())
        }
        this.arrOfRefs = temp // arrofrefs is used for both top bar of Guest and (wedding or event) user

        // this.myDivToFocus = React.createRef()
        this.escFunction = this.escFunction.bind(this);

    }

    // componentDidMount(){
    //     this.setState({
    //         device : Checkdevice()
    //     })
    // }

    escFunction(event) {
        if (event.keyCode === 27) {
            //Do whatever when esc is pressed
            clearTimeout(T1);
            clearTimeout(T2);
            clearTimeout(T3);
            clearTimeout(T4);

            this.one.style = "font-size: 34px; outline: none; border: none; font-weight: 600; color: var(--maindark); background-color: transparent; border:thin solid transparent; "
            this.two.style = "display:flex;flex-direction:row; flex-wrap:wrap; justify-content:center; align-items:center; border:thin solid transparent; border-radius:5px;"
            this.homeuser_header_ref.current.style = "background-color: transparent;width: 42px;outline: none;font-size: 13px;border-radius: 5px;"
            this.homeuser_planningtools_ref.current.style = "position:absolute; margin:10px; right:1px; display:flex; flex-direction:column; justify-content:center; align-items:flex-end;"
            this.setState({ showGuide: false, guidetext: '', guidetext_css: {} })

        }
    }

    componentDidMount() {
        // isTablet
        this.updateWindowDimensions()
        window.scroll({ top: 0, behavior: "smooth" }) // scroll up
        window.addEventListener('resize', this.updateWindowDimensions)
        document.addEventListener("keydown", this.escFunction, false);
        this.props.fetchhomeuserinbulk_action(token, _id).then(() => {

            const { usertype } = this.props // from redux
            var state_items
            if (usertype === 'Wedding' || usertype === 'Event') {
                const { homeUser_data, favourite, data_loaded, usertype } = this.props // from redux
                var favourite_ids = favourite.map((item) => item[0])
                var filters = []

                var date_w, name, spousename
                // extract filters based on usertype('Wedding' or 'Event')
                if (usertype === 'Wedding') {
                    filters = homeUser_data.weddingoreventdetail_data.filtersetting_w // is a dicitionary
                    date_w = '' || homeUser_data.weddingoreventdetail_data.date_w
                    name = '' || homeUser_data.userinfocontact.name
                    spousename = '' || homeUser_data.userinfocontact.spousename
                }
                else if (usertype === 'Event') {
                    console.log('homeUser_data event', homeUser_data)
                    filters = homeUser_data.weddingoreventdetail_data.filtersetting_e // is a dicitionary
                }

                var keys = homeUser_data && homeUser_data.data ? Object.keys(homeUser_data.data) : []

                state_items = {
                    _id: _id,
                    token: token,
                    acct_type: acct_type,
                    homeUser_data: homeUser_data,
                    vt_keys: keys,
                    favourite: favourite_ids,
                    data_loaded: data_loaded,
                    filters: filters,
                    usertype: usertype,
                    abbrev_userlocation: PRESENCELOC_ABBREV_MAP[homeUser_data.userinfocontact.location],
                    currsection_x: Array.apply(null, Array(keys.length)).map(Number.prototype.valueOf, 0),
                    left_right_counter: Array.apply(null, Array(keys.length)).map(() => [0, 0]),
                    date_w: date_w,
                    name: name,
                    spousename: spousename
                }
            }
            else if (usertype === 'Guest') {

                const { homeUser_data, data_loaded, usertype } = this.props // from redux
                const { guestdetail_data } = homeUser_data
                const { tickets, selectedtypes_g } = guestdetail_data

                var type_ticket_map = {}
                for (let x = 0; x < selectedtypes_g.length; x++) type_ticket_map[selectedtypes_g[x]] = []

                for (let i = 0; i < tickets.length; i++) {
                    const hosttype = tickets[i].hosttype
                    type_ticket_map[hosttype].push(
                        <Ticket
                            key={tickets[i]._id}
                            data={tickets[i]}
                            verifiedcheck={false} // this will hide the delete button
                            ticketmodal={(data) => {
                                this.setState({ ticket_data: data, ticketmodal: true })
                            }}
                        />
                    )
                }

                var wrap_tickers = (
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '20px 10px', color: '#707070', }}> {/*overflowX: 'auto', overflowY: 'hidden' */}
                        {selectedtypes_g.map((key, index) => {
                            return (
                                <div key={key} ref={this.arrOfRefs[index]} style={{ display: 'flex', flexDirection: 'column', border: 'thin solid #dddddd', borderRadius: 5, margin: '10px 0px', width: '100%' }}>
                                    <div style={{ display: 'flex', padding: 5, margin: 10, borderRadius: 5, minWidth: 155, maxWidth: 155, minHeight: 50, fontWeight: 500, justifyContent: 'center', alignItems: 'center', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 5px 0px', color: '#707070' }}>
                                        {key}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', margin: 5, padding: 5, minHeight: 300, overflowX: 'auto' }}>
                                        {type_ticket_map[key]}
                                    </div>
                                </div>

                            )
                        })}
                    </div>
                )

                state_items = {
                    _id: _id,
                    token: token,
                    acct_type: acct_type,
                    homeUser_data: homeUser_data,
                    data_loaded: data_loaded,
                    usertype: usertype,
                    tickets: wrap_tickers,
                    abbrev_userlocation: PRESENCELOC_ABBREV_MAP[homeUser_data.userinfocontact.location]
                }
            }

            this.setState(state_items, () => {
                this.props.changeOfUsertype(usertype)
            })//this props comes from App.js

        }) // fetch data, after executation, this.props.homeUser_data will be filled with data
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
        document.removeEventListener("keydown", this.escFunction, false);
        window.removeEventListener('scroll', this.updateScrolling)
    }

    componentWillMount() {
        this.scrollListner = window.addEventListener('scroll', this.updateScrolling)
    }

    updateWindowDimensions = () => {
        this.setState({ screen_width: window.innerWidth, screen_height: window.innerHeight });
    }

    applyFilter = (new_filters = []) => { // update new filters to db, and update new data on ui
        const { token, _id, filters, selectedVendorType, usertype, homeUser_data } = this.state
        this.props.fetchhomeuserinsingle_action(token, _id, new_filters, selectedVendorType, usertype).then(() => {
            const { singleVt_data, new_favourite } = this.props
            var final_homeUser_data = homeUser_data
            var final_filters = filters
            final_homeUser_data.data[selectedVendorType] = singleVt_data // update homeUser_data with new filtered data
            final_filters[usertype + selectedVendorType] = new_filters
            this.setState({
                homeUser_data: final_homeUser_data,
                favourite: new_favourite.map((item, index) => { return item[0] }),
                showfilter: false,
                filters: final_filters,

            })
        })

    }

    renderFilterElemsSingleVt = (selectedvt) => {
        const { filters, usertype } = this.state
        const key = usertype + selectedvt
        if (filters[key] !== undefined) {
            return (
                filters[key].map((item, index) => {
                    return (
                        <FilterDisplaySingleElem
                            key={filters[key] + index + item}
                            index={index}
                            item={item}
                            removeSingleFilterElem={(item_del, index_del) => {
                                var new_filters = filters
                                var index = new_filters[key].indexOf(item_del)
                                if (index > -1) new_filters[key].splice(index, 1)
                                this.setState({ selectedVendorType: selectedvt }, () => {
                                    this.applyFilter(new_filters[key])
                                })
                            }}
                        />
                    )
                })
            )
        }
        else {
            return EMPTY
        }
    }

    renderSelectedvtpropbar = (selectedvt, i) => {
        const { screen_width, usertype, refreshStaus, filters, } = this.state
        var reset_section_scroll = () => {
            var currsection_x = this.state.currsection_x.slice()
            currsection_x[i] = 0

            var left_right_counter = this.state.left_right_counter.slice()
            left_right_counter[i] = [0, 0]

            return { currsection_x, left_right_counter }
        }

        const FILTER_BTN = (
            <button
                className={'homeuser_shadybtn'}
                style={{ ...BUTTONSTYLE, border: '1px solid silver', borderRadius: 5, margin: '0px 2.5px', color: 'var(--maindark)', backgroundColor: 'transparent', width: 40, height: 40, padding: 5, zIndex: 2, display: 'flex', placeContent: 'center', alignItems: 'center' }}
                onClick={() => {
                    document.querySelector("#app-root").style.overflow = "hidden";
                    // document.querySelector("#app-root").style.position = "fixed";
                    this.setState({ showfilter: true, selectedVendorType: selectedvt, ...reset_section_scroll() })
                }}
            >
                {iconwrapper(FILTERCURSOR_ICON, { width: 30, height: 30, color: 'var(--maindark)', padding: 5, margin: 0, display: 'flex', alignItems: 'center', placeContent: 'center', pointerEvents:'none' })}
            </button>
        )

        const REFRESH_BTN = (
            <button
                className={'homeuser_shadybtn'}
                disabled={refreshStaus}
                style={{ ...BUTTONSTYLE, border: '1px solid silver', borderRadius: 5, margin: '0px 2.5px', color: 'var(--maindark)', backgroundColor: 'transparent', width: 40, height: 40, padding: 5, zIndex: 2, display: 'flex', placeContent: 'center', alignItems: 'center' }}
                onClick={() => {
                    this.setState({ refreshStaus: true, selectedVendorType: selectedvt }, () => {
                        const new_filters = filters[usertype + selectedvt]
                        this.applyFilter(new_filters)
                        this.setState({ refreshStaus: false, ...reset_section_scroll() })
                    })
                }}
            >
                {iconwrapper(REFRESH_ICON, { width: 30, height: 30, color: 'var(--maindark)', padding: 5, margin: 0, display: 'flex', alignItems: 'center', placeContent: 'center', pointerEvent:'none' })}
            </button>
        )

        const MORE_BTN = (
            <button
                className={'homeuser_shadybtn'}
                style={{ ...BUTTONSTYLE, border: '1px solid silver', borderRadius: 5, margin: '0px 2.5px', color: 'var(--maindark)', backgroundColor: 'transparent', width: 40, height: 40, padding: 5, zIndex: 2, display: 'flex', placeContent: 'center', alignItems: 'center' }}
                onClick={() => this.props.history.push('/' + usertype + '/' + selectedvt)}
            >
                {iconwrapper(DOTDOTDOT_ICON, { width: 20, height: 20, color: 'var(--maindark)', padding: 5, margin: 0, display: 'flex', alignItems: 'center', placeContent: 'center', pointerEvents:'none' })}
            </button>
        )

        const QUIZ_BTN = (
            Object.keys(VT_QUIZ_MAP).includes(selectedvt) && usertype === 'Wedding'
                ? <button
                    className={'homeuser_shadybtn'}
                    style={{ ...BUTTONSTYLE, border: '1px solid silver', borderRadius: 5, margin: '0px 2.5px', color: 'var(--maindark)', backgroundColor: 'transparent', width: 65, height: 40, padding: 5, fontSize: e_fs, zIndex: 2, position: 'relative', }}
                    onClick={() => this.setState({ showQuiz: true, quizCat: usertype, quizVt: selectedvt })}
                >
                    {QUIZ_DOT_ABSOLUTE}
                    <div style={{ fontWeight: 'normal', placeContent: 'center', display: 'flex', textAlign: 'center', pointerEvents:'none' }}>Quiz</div>
                </button>
                : null
        )

        return (
            <div style={{ height: 50, width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', right: 0, placeContent: 'center', overflowY: 'hidden', alignItems: 'center' }}>
                    {screen_width >= WEB ? this.renderFilterElemsSingleVt(selectedvt, usertype) : EMPTY}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', orderRadius: 5, alignItems: 'center', margin: 5 }}>
                    {QUIZ_BTN}
                    {FILTER_BTN}
                    {REFRESH_BTN}
                    {MORE_BTN}
                </div>
            </div >
        )
    }

    updateCustomise_clicked = () => {
        document.querySelector("#app-root").style.overflow = "";
        document.querySelector("#app-root").style.position = "";
        document.querySelector("#app-root").style.width = "";
        this.setState({ edit_gate: false, host_gate: false }, () => {
            setTimeout(() => { this.componentDidMount() }, NEXT_PAGE_TIMING)
        })
    }

    openHost() {
        return () => { this.setState({ host_gate: true }) }
    }

    openEdit() {
        if (this.state.wedeventclickable) {
            return () => {
                document.querySelector("#app-root").style.overflow = "hidden";
                document.querySelector("#app-root").style.position = "fixed";
                document.querySelector("#app-root").style.width = "100%";
                this.setState({ edit_gate: true })
            }
        }
    }

    createVendorcards = (device_type, selectedvt) => {
        const { homeUser_data, favourite, abbrev_userlocation } = this.state
        const { data } = homeUser_data
        var result = []
        if (device_type === 'WEB') {
            console.log(data)
            for (let j = 0; j < data[selectedvt].length; j++) { // data[selectedvt] => _id is vendortype_id
                const item = data[selectedvt][j]
                if (item.vendortypeinterface_id.length > 0) {
                    const { _id, vendortype, secondaryvendortype, vendortype_id, c0, tier } = item.vendortypeinterface_id[0]
                    result.push(
                        <Vendorcard
                            cardwt={250}
                            cardmargin={5}
                            key={selectedvt + j}
                            content={item}
                            type={device_type}
                            userlike={favourite.includes(_id)}
                            vendortype={vendortype}
                            secondaryvendortype={secondaryvendortype}
                            vendortype_id={vendortype_id}
                            vendortypeinterface_id={_id}
                            c0={c0}
                            selectedvt={selectedvt}
                            abbrev_userlocation={abbrev_userlocation}
                            tier={tier}
                            updateHeart={(addorremove, id) => {
                                var curr_fav = [...favourite]
                                if (addorremove) { // add
                                    curr_fav.push(id)
                                    this.setState({ favourite: curr_fav })
                                }
                                else { //remove
                                    var index = favourite.indexOf(id)
                                    curr_fav.splice(index, 1)
                                    this.setState({ favourite: curr_fav })
                                }
                            }}
                        />
                    )
                }

            }
        }
        else if (device_type === 'TABLET') {
            for (let j = 0; j < data[selectedvt].length; j++) { // data[selectedvt] => _id is vendortype_id
                const item = data[selectedvt][j]
                if (item.vendortypeinterface_id.length > 0) {
                    const { _id, vendortype, secondaryvendortype, vendortype_id, c0, tier } = item.vendortypeinterface_id[0]
                    result.push(
                        <Vendorcard
                            cardwt={230}
                            cardmargin={5}
                            key={selectedvt + j}
                            content={item}
                            type={device_type}
                            userlike={favourite.includes(_id)}
                            vendortype={vendortype}
                            secondaryvendortype={secondaryvendortype}
                            vendortype_id={vendortype_id}
                            vendortypeinterface_id={_id}
                            c0={c0}
                            selectedvt={selectedvt}
                            abbrev_userlocation={abbrev_userlocation}
                            tier={tier}
                            updateHeart={(addorremove, id) => {
                                var curr_fav = [...favourite]
                                if (addorremove) { // add
                                    curr_fav.push(id)
                                    this.setState({ favourite: curr_fav })
                                }
                                else { //remove
                                    var index = favourite.indexOf(id)
                                    curr_fav.splice(index, 1)
                                    this.setState({ favourite: curr_fav })
                                }
                            }}
                        />
                    )
                }
            }
        }
        else if (device_type === 'MOBILE') {
            for (let j = 0; j < data[selectedvt].length; j++) { // data[selectedvt] => _id is vendortype_id
                const item = data[selectedvt][j]
                if (item.vendortypeinterface_id.length > 0) {
                    const { _id, vendortype, secondaryvendortype, vendortype_id, c0 } = item.vendortypeinterface_id[0]
                    result.push(
                        <Vendorcardmobile
                            cardwt={350}
                            cardmargin={'10px 10px 10px 0px'}
                            cardimgdim={135}
                            key={selectedvt + j}
                            content={item}
                            type={device_type}
                            userlike={favourite.includes(_id)}
                            vendortype={vendortype}
                            secondaryvendortype={secondaryvendortype}
                            vendortype_id={vendortype_id}
                            vendortypeinterface_id={_id}
                            c0={c0}
                            selectedvt={selectedvt}
                            abbrev_userlocation={abbrev_userlocation}
                            updateHeart={(addorremove, id) => {
                                var curr_fav = [...favourite]
                                if (addorremove) { // add
                                    curr_fav.push(id)
                                    this.setState({ favourite: curr_fav })
                                }
                                else { //remove
                                    var index = favourite.indexOf(id)
                                    curr_fav.splice(index, 1)
                                    this.setState({ favourite: curr_fav })
                                }
                            }}
                        />
                    )
                }
            }
        }

        return result
    }

    renderFilter = (device) => {
        const { showfilter, selectedVendorType, usertype, filters } = this.state
        const filterdom = <FilterModalContent
            onApplyFilter={(new_filters) => { this.applyFilter(new_filters) }}
            filtersetting={filters}
            usertype={usertype}
            selectedVendorType={selectedVendorType}
        />

        if (device === 'MOBILE') {

            const portalmidcss_mobile = {
                width: '100%',
                height: '100%',
                top: 0,
                backgroundColor: 'white',
                left: 0, right: 0,
                margin: 'auto',
                transform: 'none'
            }

            return <Portalmodal
                portaltype={'commonportal'}
                open={showfilter}
                onClose={() => {
                    document.querySelector("#app-root").style.overflow = "";
                    document.querySelector("#app-root").style.position = "";
                    document.querySelector("#app-root").style.width = "";
                    this.setState({ showfilter: false })
                }}
                floatingclose={false}
                backdropvisible={true}
                modalstyle={portalmidcss_mobile}
                closestyle={{ right: 0 }}
                floatingclosecolor='var(--maindark)'
            >
                <div className='Portalmodal_vtroot'>
                    {filterdom}
                </div>
            </Portalmodal>
        }
        else {
            const portalmidcss_web = {
                width: 600,
                height: 600,
                top: 'calc( 50% - 300px )',
                backgroundColor: 'white',
                left: 0, right: 0,
                margin: 'auto',
                transform: 'none'
            }

            return <Portalmodal
                portaltype={'commonportal'}
                open={showfilter}
                onClose={() => {
                    document.querySelector("#app-root").style.overflow = "";
                    document.querySelector("#app-root").style.position = "";
                    document.querySelector("#app-root").style.width = "";
                    this.setState({ showfilter: false })
                }}
                floatingclose={false}
                backdropvisible={true}
                modalstyle={portalmidcss_web}
                closestyle={{ right: 0 }}
                floatingclosecolor='var(--maindark)'
            >
                <div className='Portalmodal_vtroot'>
                    {filterdom}
                </div>
            </Portalmodal>
        }
    }

    renderCircularBtn = () => { // UserQuestion
        const { usertype } = this.state
        if (usertype === 'Wedding' || usertype === 'Event') {
            // return (
            //     <button
            //         onClick={() => {
            //             this.props.history.push('/userquestion')
            //         }}
            //         style={{ ...BUTTONSTYLE, borderRadius: 15, width: 30, height: 30, margin: 10, border: 'none', backgroundImage: 'linear-gradient(104deg, #e36d77, #c85582)', boxShadow: '0 4px 24px 0 rgba(211, 114, 122, 0.5)', color: 'white', padding: 0, justifyContent: 'center', alignItems: 'center', display: 'flex' }}
            //     >
            //         <div style={{ borderRadius: 10, backgroundColor: 'white', width: 10, height: 10 }} />
            //     </button>
            // )
            return <div style={{ width: 40, height: 40, }} />
        }
        else {
            return <div />
        }

    }

    renderDummyBtn = () => {
        return <button style={{ ...BUTTONSTYLE, borderRadius: 15, width: 50, height: 50, backgroundColor: 'transparent', border: 'none' }} />
    }

    triggerRef = (z) => {
        if (this.arrOfRefs[z] && this.arrOfRefs[z].current) {
            this.arrOfRefs[z].current.scrollIntoView({
                behavior: 'smooth', // 'smooth' | 'auto';
                block: 'nearest', // 'start' | 'center' | 'end' | 'nearest';
                inline: 'start', // 'start' | 'center' | 'end' | 'nearest';
            })
        }
    }

    renderUserselected_vts = (device) => {
        const { homeUser_data, usertype, vt_keys } = this.state
        const { data, weddingoreventdetail_data } = homeUser_data

        if (data) {

            var allvtbtns = []
            if (device === 'WEB') {
                var guideBtn = (<button
                    className='planning_editbtn'
                    style={{ fontWeight: 'bold', fontSize: 14 }}
                    onClick={() => {
                        window.scrollTo(0, 0)
                        var one_rect = this.one.getBoundingClientRect();
                        document.getElementById('root').style = `position:fixed; top:0; left:0; width:100%`
                        this.setState({ showGuide: true, guidetext: ONE_TEXT, wedeventclickable: false, guidetext_css: { marginTop: one_rect.bottom, marginLeft: '', justifyContent: 'center', zIndex: 2 } }, () => {
                            this.one.style = "font-size: 34px; outline: none; border: none; font-weight: 600; color: var(--maindark); background-color: transparent;box-shadow:rgba(0, 0, 0, 0.2) 0px 4px 5px 0px; border-radius:5px;background-color:white; position:relative;z-index:6;"
                            T1 = setTimeout(() => {
                                var two_rect = this.two.getBoundingClientRect();
                                this.setState({ guidetext: TWO_TEXT, wedeventclickable: true, vtclickable: false, guidetext_css: { marginTop: two_rect.top - 30, marginLeft: '' } }, () => {
                                    this.one.style = "font-size: 34px; outline: none; border: none; font-weight: 600; color: var(--maindark); background-color: transparent; border:1px solid transparent; "
                                    this.two.style = `font-size: 34px; align-items: center; box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px 0px; background-color: white; border-radius:5px; z-index: 6; `
                                    T2 = setTimeout(() => {
                                        var three_rect = this.homeuser_header_ref.current.getBoundingClientRect();
                                        this.setState({ guidetext: THREE_TEXT, vtclickable: true, locclickable: false, guidetext_css: { marginTop: three_rect.bottom, marginLeft: three_rect.left, justifyContent: '' } }, () => {
                                            this.two.style = "grid-template-columns: repeat(auto-fit, 130px); flex-direction:grid; align-items:center; border-radius:5px;"
                                            this.homeuser_header_ref.current.style = "background-color: transparent;width: 37px;outline: none;font-size: 13px;border-radius: 5px;background-color:white;rgba(0, 0, 0, 0.2) 0px 4px 5px 0px;z-index:6;"
                                            T3 = setTimeout(() => {
                                                var four_rect = this.homeuser_planningtools_ref.current.getBoundingClientRect();
                                                this.setState({ guidetext: FOUR_TEXT, locclickable: true, appclickable: false, guidetext_css: { marginTop: four_rect.bottom, marginLeft: four_rect.left - 200, textAlign: 'start' } }, () => {
                                                    this.homeuser_header_ref.current.style = "background-color: transparent;width: 37px;outline: none;font-size: 13px;border-radius: 5px;"
                                                    this.homeuser_planningtools_ref.current.style = "position:absolute; margin:10px; right:1px; display:flex; flex-direction:column; justify-content:center; align-items:flex-end; rgba(0, 0, 0, 0.2) 0px 4px 5px 0px;z-index:6;"
                                                    T4 = setTimeout(() => {
                                                        this.homeuser_planningtools_ref.current.style = "position:absolute; margin:10px; right:1px; display:flex; flex-direction:column; justify-content:center; align-items:flex-end;"
                                                        this.setState({ showGuide: false, guidetext: '', guidetext_css: {}, appclickable: true })
                                                        document.getElementById('root').style = ``
                                                    }, mintime);
                                                })
                                            }, mintime);
                                        })
                                    }, mintime);
                                })
                            }, mintime);
                        })
                    }}>
                    {iconwrapper(QUESTIONMARK_ICON, { width: 24, height: 24, color: 'var(--maindark)', padding: 7, margin: 0, display: 'flex', alignItems: 'center', placeContent: 'center' })}
                </button>)

                return (
                    <div style={{ maxWidth: MAX_WEB, minHeight: 150, padding: 20, width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'column', backgroundImage: MENU_COLOR_MAP[usertype].background, borderBottom: '1px solid rgb(221, 221, 221)', borderRadius: 5 }}>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: 50, minHeight: 50 }}>

                            {guideBtn} {/* btn 1 */}

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> {/* btn 2 */}

                                {hostjoin && usertype !== 'Guest'
                                    ? <button
                                        onClick={this.openHost()}
                                        style={{ ...BUTTONSTYLE, border: '1px solid transparent', height: 'fit-content', padding: 5, margin: 5, borderRadius: 5, color: 'white', backgroundColor: MAIN_PINK_COLOR }}
                                    >
                                        Host
                                    </button>
                                    : null}

                                {weddingoreventdetail_data && usertype_title_map[weddingoreventdetail_data.usertype].length < 2
                                    ? <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                        <button ref={node => this.one = node} onClick={this.openEdit()} style={{ position: 'relative', fontSize: 32, outline: 'none', fontWeight: 600, color: 'var(--maindark)', backgroundColor: 'transparent', border: 'thin solid transparent', zIndex: 2, }}>
                                            <div style={{ position: 'relative', zIndex: 2, userSelect: 'none' }}>{usertype_title_map[weddingoreventdetail_data.usertype][0]}</div>
                                        </button>
                                        {/* <div className={"Mainselectionbg" + usertype_title_map[weddingoreventdetail_data.usertype][0]} /> */}
                                    </div>
                                    : <button style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'transparent', border: 'none', outline: 'none' }}>
                                        <div style={{ fontSize: 32, outline: 'none', border: 'none', fontWeight: 600, color: 'var(--maindark)', backgroundColor: 'transparent', userSelect: 'none' }}>
                                            {usertype_title_map[weddingoreventdetail_data.usertype][0]}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', color: '#707070', fontWeight: 'bold' }}>
                                            <div>{usertype_title_map[weddingoreventdetail_data.usertype][1]}</div>
                                            <div>{usertype_title_map[weddingoreventdetail_data.usertype][2]}</div>
                                        </div>
                                    </button>}
                            </div>

                            {this.renderCircularBtn()} { /* btn 3 */}

                        </div>

                        <div ref={node => this.two = node} className={`MSB_vtbtnsgrid homeuser_vtbadgeroot`} >
                            {vt_keys.map((item, i) => {
                                return <button
                                    key={'vt_' + i}
                                    className={`MSB_vticon Homeuser_vtbadge`}
                                    onClick={() => { this.triggerRef(i) }}
                                    // onClick={() => { if (!this.state.vtclickable) { this.triggerRef(i) } }}
                                >
                                    {usertype === 'Wedding' && Object.keys(VT_QUIZ_MAP).includes(item)? QUIZ_DOT_ABSOLUTE: null}
                                    {READABLE_VENDORTYPE_MAP[item]}
                                </button>
                            })}
                        </div>

                    </div>
                )
            }
            else if (device === 'MOBILE') {
                for (let i = 0; i < vt_keys.length; i++) {
                    const item = READABLE_VENDORTYPE_MAP[vt_keys[i]]
                    allvtbtns.push(  // vt btns
                        <div key={item} >
                            <button
                                className={`MSB_vticon Homeuser_vtbadge`}
                                onClick={() => { this.triggerRef(i) }}
                            >
                                {usertype === 'Wedding' && Object.keys(VT_QUIZ_MAP).includes(item)
                                    ? <div style={{ display: 'flex', padding: 5, width: 14, height: 14, borderRadius: 10, backgroundColor: 'var(--mainpink)', border: '2px solid #ffffff', position: 'absolute', right: 0, top: 0, margin: 2.5 }} />
                                    : null}
                                {item}
                            </button>
                        </div>
                    )
                }
                const hostbtn_mobile = hostjoin && usertype !== 'Guest'
                    ? <button
                        onClick={this.openHost()}
                        style={{ ...BUTTONSTYLE, border: 'none', height: 'fit-content', padding: '5px', margin: '5px 0px', color: 'white', backgroundColor: '#F77F7F' }}
                    >Host</button>
                    : null

                const mainbtn_mobile = weddingoreventdetail_data && usertype_title_map[weddingoreventdetail_data.usertype].length < 2
                    ? <button
                        onClick={this.openEdit()}
                        style={{ fontSize: 32, outline: 'none', border: 'none', fontWeight: 600, color: 'var(--maindark)', backgroundColor: 'transparent', padding: '0px 5px', }}>
                        {usertype_title_map[weddingoreventdetail_data.usertype][0]}
                    </button>
                    : <button style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'transparent', border: 'none', outline: 'none' }}>
                        <div
                            onClick={this.openEdit()}
                            style={{ fontSize: 32, outline: 'none', border: 'none', fontWeight: 600, color: 'var(--maindark)', backgroundColor: 'transparent' }}>
                            {usertype_title_map[weddingoreventdetail_data.usertype][0]}
                        </div>
                        <div
                            className="userselectedusertypetitle1"
                            onClick={this.openEdit()}
                            style={{ display: 'flex', flexDirection: 'column', color: '#707070', fontWeight: 'bold' }}
                        >
                            <div>{usertype_title_map[weddingoreventdetail_data.usertype][1]}</div>
                            <div>{usertype_title_map[weddingoreventdetail_data.usertype][2]}</div>
                        </div>
                    </button>

                return <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%',
                        justifyContent: weddingoreventdetail_data && usertype_title_map[weddingoreventdetail_data.usertype].length < 2 ? 'space-between' : 'center'
                    }}>
                        <button style={{ ...BUTTONSTYLE, border: 'thin solid transparent', color: 'var(--maindark)', backgroundColor: 'transparent', width: 40, height: 40 }} />
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center' }}>
                            {hostbtn_mobile}
                            {mainbtn_mobile}
                        </div>
                        {this.renderCircularBtn()}
                    </div>
                    <div style={{ display: 'flex', height: 70, flexDirection: 'column', borderBottom: '1px solid var(--subgrey)', alignItems: 'center', width: '100%', overflow: 'hidden', }}>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 6, marginBottom: -6, overflowY: 'hidden', overflowX: 'auto', width: '100%', height: '-webkit-fill-available', WebkitOverflowScrolling: 'touch' }}>
                            {allvtbtns}
                        </div>
                    </div>
                </div>
            }

        }
        return <Loading />
    }

    renderTopguest = (device) => {

        const { usertype, homeUser_data } = this.state

        const { guestdetail_data } = homeUser_data
        const { selectedtypes_g } = guestdetail_data

        var single_selectedtype_dom = selectedtypes_g.map((item, i) => {
            return (
                <button
                    key={item}
                    onClick={() => {
                        if (this.arrOfRefs[i].current) this.arrOfRefs[i].current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start', })
                    }}
                    style={{ display: 'flex', padding: 5, margin: 10, borderRadius: 5, minWidth: 155, maxWidth: 155, minHeight: 50, fontWeight: 500, justifyContent: 'center', alignItems: 'center', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 5px 0px', backgroundColor: 'white', color: '#707070', outline: 'none' }}
                >
                    {item}
                </button>
            )
        })

        var mass_selectedtype_dom = (
            <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', WebkitOverflowScrolling: 'touch', overflowY: 'hidden', marginTop: 10 }}>
                {single_selectedtype_dom}
            </div>
        )

        if (device === 'WEB') {
            return (
                <div style={{ maxWidth: MAX_WEB, alignSelf: 'center', minHeight: 150, padding: 20, width: '100%', display: 'flex', flexDirection: 'column', backgroundImage: MENU_COLOR_MAP[usertype].background, borderBottom: '1px solid rgb(221, 221, 221)', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <button style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'transparent', border: 'none', outline: 'none', alignItems: 'center' }}>
                                <div onClick={this.openEdit()} style={{ fontSize: 32, outline: 'none', border: 'none', fontWeight: 600, color: 'var(--maindark)', backgroundColor: 'transparent' }}>
                                    {usertype_title_map[usertype][0]}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', color: '#707070', fontWeight: 'bold' }}>
                                    <div>{usertype_title_map[usertype][1]}</div>
                                    <div>{usertype_title_map[usertype][2]}</div>
                                </div>
                            </button>

                        </div>
                        {this.renderCircularBtn()}
                    </div>
                    {mass_selectedtype_dom}
                </div>

            )
        }
        else if (device === 'MOBILE') {

            const mainbtn_mobile = (

                <button style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'transparent', border: 'none', outline: 'none' }}>
                    <div
                        onClick={this.openEdit()}
                        style={{ fontSize: 32, outline: 'none', border: 'none', fontWeight: 600, color: 'var(--maindark)', backgroundColor: 'transparent' }}>
                        {usertype_title_map[usertype][0]}
                    </div>
                    <div
                        className="userselectedusertypetitle1"
                        onClick={this.openEdit()}
                        style={{ display: 'flex', flexDirection: 'column', color: '#707070', fontWeight: 'bold' }}
                    >
                        <div>{usertype_title_map[usertype][1]}</div>
                        <div>{usertype_title_map[usertype][2]}</div>
                    </div>
                </button>
            )

            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: usertype_title_map[usertype].length < 2 ? 'space-between' : 'center', width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center' }}> {mainbtn_mobile}</div>
                        {this.renderCircularBtn()}
                    </div>
                    {mass_selectedtype_dom}
                </div>
            )
        }
    }

    renderHeader = () => {
        const { usertype, abbrev_userlocation, name, spousename, date_w, locclickable, appclickable } = this.state
        return <Header
            {...this.props}
            homeuser_header_ref={this.homeuser_header_ref}
            homeuser_planningtools_ref={this.homeuser_planningtools_ref}
            usertype={usertype}
            abbrev_userlocation={abbrev_userlocation}
            name={name}
            spousename={spousename}
            date_w={date_w}
            locclickable={locclickable}
            appclickable={appclickable}
        >
            <MainMenu {...this.props} selectedMainCategory={'Home'} usertype={usertype} /> {/* onClick={() => { this.componentDidMount() }} */}
        </Header>
    }

    RenderEditModal_mobile = () => {
        const { edit_gate, _id, token, homeUser_data, data_loaded } = this.state
        const portalmidcss_mobile = {
            width: { 'WEB': 600, 'TABLET': 600, 'MOBILE': '100%' }['MOBILE'],
            height: { 'WEB': 600, 'TABLET': 600, 'MOBILE': '100%' }['MOBILE'],
            top: { 'WEB': 'calc( 50% - 300px )', 'TABLET': 'calc( 50% - 300px )', 'MOBILE': 0 }['MOBILE'],
            backgroundColor: 'white',
            left: 0, right: 0,
            margin: 'auto',
            transform: 'none'
        }
        return <Portalmodal
            portaltype={'commonportal'}
            open={edit_gate}
            onClose={() => {
                document.querySelector("#app-root").style.overflow = "";
                document.querySelector("#app-root").style.position = "";
                document.querySelector("#app-root").style.width = "";
                this.setState({ edit_gate: false })
            }}
            floatingclose={false}
            backdropvisible={true}
            modalstyle={portalmidcss_mobile}
            closestyle={{ right: 0 }}
            floatingclosecolor='var(--maindark)'
        >
            <div className='Portalmodal_vtroot' >
                <UserHomeCustomise
                    device={'MOBILE'}
                    _id={_id}
                    token={token}
                    wedevent_data={homeUser_data.weddingoreventdetail_data}
                    guest_data={homeUser_data.guestdetail_data}
                    loaded={data_loaded}
                    reinit_main={this.updateCustomise_clicked}
                />
            </div>
        </Portalmodal>
    }

    RenderHostModal_mobile = () => {
        const { host_gate, usertype, token } = this.state
        return (
            <Jfmodal
                show={host_gate}
                title={'Host'}
                onHide={() => { this.setState({ host_gate: false }) }}
                device={'MOBILE'}
                bsSize={'large'}
                content={
                    <UserHomeHost token={token} usertype={usertype} device={'MOBILE'} onClose={() => { this.setState({ host_gate: false }) }} />
                }
            />
        )
    }

    RenderEditModal_web = () => {

        const { edit_gate, _id, token, homeUser_data, data_loaded } = this.state
        return <Portalmodal
            portaltype={'commonportal'}
            open={edit_gate}
            onClose={() => {
                document.querySelector("#app-root").style.overflow = "";
                document.querySelector("#app-root").style.position = "";
                document.querySelector("#app-root").style.width = "";
                this.setState({ edit_gate: false })
            }}
            floatingclose={false}
            backdropvisible={true}
            modalstyle={{
                width: { 'WEB': 600, 'TABLET': 600, 'MOBILE': '100%' }['WEB'],
                height: { 'WEB': 600, 'TABLET': 600, 'MOBILE': '100%' }['WEB'],
                top: { 'WEB': 'calc( 50% - 300px )', 'TABLET': 'calc( 50% - 300px )', 'MOBILE': 0 }['WEB'],
                backgroundColor: 'white',
                left: 0, right: 0,
                margin: 'auto',
                transform: 'none'
            }}
            closestyle={{ right: 0 }}
            floatingclosecolor='var(--maindark)'
        >
            <div className='Portalmodal_vtroot'>
                <UserHomeCustomise
                    device={'WEB'}
                    _id={_id}
                    token={token}
                    wedevent_data={homeUser_data.weddingoreventdetail_data}
                    guest_data={homeUser_data.guestdetail_data}
                    loaded={data_loaded}
                    reinit_main={this.updateCustomise_clicked}
                />
            </div>
        </Portalmodal>
    }

    RenderHostModal_web = () => {
        const { host_gate, usertype, token } = this.state
        return (
            <Jfmodal
                show={host_gate}
                title={'Host'}
                onHide={() => { this.setState({ host_gate: false }) }}
                device={'WEB'}
                bsSize={null}
                content={
                    <UserHomeHost token={token} usertype={usertype} device={'WEB'} onClose={() => { this.setState({ host_gate: false }) }} />
                }
            />
        )
    }

    leftArrow(i) {
        return <div className="homeuser_arrow_1" style={{ left: -20 }}>
            <div className="homeuser_arrow_2" style={{ borderTopRightRadius: 30, borderBottomRightRadius: 30 }}
                onClick={() => {
                    var currsection_x = this.state.currsection_x.slice()
                    currsection_x[i] = parseInt(currsection_x[i], 10) - 240

                    var left_right_counter = this.state.left_right_counter.slice()
                    left_right_counter[i] = [left_right_counter[i][0] + 1, left_right_counter[i][1] - 1]
                    this.setState({ currsection_x, left_right_counter })
                }}>
                <div className="homeuser_arrow">{RIGHTARROW_ICON_SMALL}</div>
            </div>
        </div >
    }

    rightArrow(i) {
        return <div className="homeuser_arrow_1" style={{ right: -20, }}>
            <div className="homeuser_arrow_2" style={{ borderTopLeftRadius: 30, borderBottomLeftRadius: 30 }}
                onClick={() => {
                    var currsection_x = this.state.currsection_x.slice()
                    currsection_x[i] = parseInt(currsection_x[i], 10) + 240

                    var left_right_counter = this.state.left_right_counter.slice()
                    left_right_counter[i] = [left_right_counter[i][0] - 1, left_right_counter[i][1] + 1]
                    this.setState({ currsection_x, left_right_counter })
                }}>
                <div className="homeuser_arrow">{LEFTARROW_ICON_SMALL}</div>
            </div>
        </div>
    }

    RenderQuiz_dom = () => {
        const { showQuiz, screen_height, quizVt, quizCat } = this.state

        const floatingclosecolor = 'var(--maindark)'
        const s_ = { width: 30, height: 40, verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden' }
        const vb_ = "0 0 1024 1024"
        const v_ = "1.1"
        const x_ = "http://www.w3.org/2000/svg"
        const CLOSEBTN_STYLES = {
            backgroundColor: 'transparent',
            border: 'none',
            position: 'absolute',
            padding: 0,
            cursor: 'pointer',
            zIndex: 1,
            color: '#707070',
            height: 60,
            margin: '0px 10px'
        }
        const FLOATING_CLOSEBTN_STYLES = {
            left: -26,
            top: -26,
            color: '#eb5d7b'
        }

        return showQuiz
            ? <div style={{ position: 'fixed', height: screen_height, display: 'flex', flexDirection: 'column', padding: 10, width: '100%', top: 0, backgroundColor: "white", zIndex: 999, overflow: 'auto' }}>
                {/* <button className='homeuser_modalclose' onClick={() => this.setState({ showQuiz: false, quizCat: '', quizVt: '' })}>{CLOSE_ICON}</button> */}
                <button
                    className='Portalmodal_close'
                    style={{ ...CLOSEBTN_STYLES, FLOATING_CLOSEBTN_STYLES }}
                    onClick={() => this.setState({ showQuiz: false, quizCat: '', quizVt: '' })}
                >
                    <svg className="icon" style={{ ...s_, width: 32, height: 32, borderRadius: '100%', backgroundColor: 'white' }} viewBox={vb_} version={v_} xmlns={x_}><path style={{ fill: floatingclosecolor, }} d="M442.24 517.504l-114.88 115.2a48 48 0 1 0 67.968 67.776l114.688-115.008L624.768 700.48a48 48 0 0 0 67.968-67.84l-114.88-115.136 114.88-115.072a48 48 0 1 0-67.968-67.84L510.016 449.6 395.328 334.592a48 48 0 0 0-67.968 67.84l114.88 115.072zM512 1024A512 512 0 1 1 512 0a512 512 0 0 1 0 1024z" fill="#666666" p-id="10405"></path></svg>
                </button>
                <Quiz {...this.props} cat={quizCat} vt={quizVt} token={token} close={() => this.setState({ showQuiz: false, quizCat: '', quizVt: '' })} />
            </div>
            : null
    }

    // Render based on device type ---- START
    RenderWeb = () => { // WEB
        const { screen_width, homeUser_data, usertype, vt_keys, token, currsection_x } = this.state

        if (usertype === 'Guest') {
            const { tickets, ticketmodal, ticket_data } = this.state
            return (
                <div>
                    {this.renderHeader()}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {this.renderTopguest('WEB')}
                        {EMPTYSPACE}
                        {tickets}
                        {this.RenderEditModal_web()}
                        <Jfmodal
                            animationstate={false}
                            show={ticketmodal}
                            title={'Request'}
                            onHide={() => { this.setState({ ticketmodal: false }) }}
                            device={'WEB'}
                            bsSize={'large'}
                            content={<UserHomeHostReq _id={ticket_data._id} token={token} device="WEB" />}
                        />
                    </div>
                </div>
            )

        }
        else if (usertype === 'Wedding' || usertype === 'Event') {
            const { data } = homeUser_data
            const { left_right_counter, showGuide, guidetext, device } = this.state
            if (data && usertype) {

                const { showQuiz } = this.state

                var vendor_doms = []

                for (let i = 0; i < vt_keys.length; i++) {

                    const selectedvt = vt_keys[i]
                    const selectedvt_x_dist = currsection_x[i]

                    var vendorcards_web = this.createVendorcards('WEB', selectedvt)

                    // var max_activecards_num = Math.floor(screen_width / (230 + (230 * 0.7)))
                    // var max_steps = Math.ceil(vendorcards_web.length / max_activecards_num)

                    // var all_cards_wt = parseInt(vendorcards_web.length) * ({ WEB: 240, TABLET: 230, MOBILE: 230 }[device])

                    // const devicewt = { 'WEB': 240, 'TABLET': 230, 'MOBILE': 350 }[device]
                    const devicewt = 250

                    var all_cards_wt = parseInt(vendorcards_web.length, 10) * devicewt


                    var wt_left = all_cards_wt - screen_width
                    var max_steps = 0
                    if (wt_left < 0) {
                        max_steps = 0
                    }
                    else {
                        // max_steps = Math.round((wt_left / ({ WEB: 240, TABLET: 230, MOBILE: 230 }[device])) + 1)
                        max_steps = Math.round((wt_left / devicewt) + 1)

                    }

                    // var max_steps = Math.ceil(vendorcards_web.length / max_activecards_num)

                    vendor_doms.push(
                        <div id='vendorstrip' ref={this.arrOfRefs[i]} key={selectedvt + i} style={{ display: 'flex', flexDirection: 'column', margin: 20, height: 500 * 1.13, maxWidth: MAX_WEB, alignSelf: 'center',position:'relative' }}>
                            <div style={{ fontSize: 24, fontWeight: 600, color: 'var(--maindark)', display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center' }} >
                                <div style={{ width: 'max-content', whiteSpace: 'nowrap', padding: 5, userSelect: 'none', fontFamily: 'lato', borderLeft: 'thick solid #eb5d7b', display: 'flex', flexDirection: 'row' }}>
                                    {iconwrapper(
                                        HOME_COLLECTION_READABLEVT_WEDDING_MAP[READABLE_VENDORTYPE_MAP[selectedvt]],
                                        { width: 35, height: 35,margin:'0px 10px 0px 10px', display: 'flex', placeContent: 'center' }
                                    )}
                                    <div style={{alignItems:'center'}}>{READABLE_VENDORTYPE_MAP[selectedvt]}</div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', maxWidth: MAX_WEB, width: screen_width - 40, borderRadius: 6, backgroundImage: 'linear-gradient(#ffffff, #ffeff773)' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', overflow: 'hidden' }}>{this.renderSelectedvtpropbar(selectedvt, i)}</div>
                                {vendorcards_web.length > HOME_USER_MAX_NUMBER_CARD && left_right_counter[i][1] > 0 && !is_touch_device() ? this.leftArrow(i) : null}
                                <div style={{ display: 'flex', position: 'relative', overflowX: !is_touch_device() ? 'hidden' : 'auto', flexDirection: 'row', alignItems: 'center', height: 420 * 1.13, width: '100%', WebkitOverflowScrolling: 'touch', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', transform: `translate3d(-${selectedvt_x_dist}px, 0px, 0px)`, transitionDuration: '0.5s', transitionProperty: 'transform', }}>
                                        {vendorcards_web}
                                    </div>
                                </div>
                                {vendorcards_web.length > HOME_USER_MAX_NUMBER_CARD && parseInt(left_right_counter[i][1], 10) < max_steps && !is_touch_device() ? this.rightArrow(i) : null}
                            </div>
                        </div>
                    )

                }

                var guide_dom = (
                    showGuide
                        ? <div className="userhomeguidebg" style={{ display: 'flex', flexDirection: 'row', position: 'absolute', backgroundColor: 'rgb(112 112 112 / 0.7)', color: 'white', justifyContent: 'center', textAlign: 'center', width: '100%', top: 0, zIndex: 5, height: '100vh' }}>
                            <div style={{ display: 'flex', width: '100%', color: '#fffff', fontWeight: 'bold', fontSize: 18, flexWrap: 'wrap', justifyContent: 'center', ...this.state.guidetext_css }}>
                                {guidetext}
                            </div>
                        </div>
                        : null
                )

                return (
                    <div>
                        {this.renderHeader()}
                        {guide_dom}
                        {this.RenderQuiz_dom()}
                        {!showQuiz
                            ? <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                                {this.renderUserselected_vts('WEB')}
                                {this.renderFilter('WEB')}
                                {EMPTYSPACE}
                                {this.state.showGuide ? null : vendor_doms}
                                {this.RenderEditModal_web()}
                                {this.RenderHostModal_web()}
                            </div>
                            : null}
                    </div>
                )
            }
        }

        return <div>
            {this.renderHeader()}
            <Loading />
        </div>


    }

    RenderMobile = () => { // MOBILE
        const { screen_width, homeUser_data, usertype, vt_keys, token } = this.state

        if (usertype === 'Guest') {
            const { tickets, ticketmodal, ticket_data } = this.state

            return (
                <div>
                    {this.renderHeader()}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {this.renderTopguest('MOBILE')}
                        {EMPTYSPACE}
                        {tickets}
                        {this.RenderEditModal_mobile()}
                        <Jfmodal
                            show={ticketmodal}
                            title={'Request'}
                            onHide={() => { this.setState({ ticketmodal: false }) }}
                            device={'MOBILE'}
                            bsSize={'large'}
                            content={<UserHomeHostReq _id={ticket_data._id} token={token} device="MOBILE" />}
                        />
                    </div>
                </div>
            )
        }
        else if (usertype === 'Wedding' || usertype === 'Event') {
            const { data, } = homeUser_data
            if (data && usertype) {
                const { showQuiz } = this.state

                var vendor_doms = []
                for (let i = 0; i < vt_keys.length; i++) {
                    const selectedvt = vt_keys[i]
                    vendor_doms.push(
                        <div  id='vendorstrip' key={selectedvt + i} ref={this.arrOfRefs[i]} style={{ display: 'flex', flexDirection: 'column', margin: '30px 0px',width:'100%', overflow:'hidden' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: screen_width, borderBottom: '1px solid rgb(221, 221, 221)', borderLeft: '', borderRight: '' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ fontSize: 18, fontWeight: 600, color: 'var(--maindark)', paddingLeft: 20, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'flex-start', padding: 5 }} >
                                        <div style={{ textAlign: 'start' }}>{READABLE_VENDORTYPE_MAP[selectedvt]} </div> {/* TITLE */}
                                        <div style={{ fontSize: 12, fontWeight: 600, color: 'rgb(206, 210, 210)' }}>
                                            {usertype} {/* WEDDING or EVENT */}
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                        {this.renderSelectedvtpropbar(selectedvt)}
                                    </div>
                                </div>

                                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center', height: 320, overflowY: 'auto', margin: 5, WebkitOverflowScrolling: 'touch', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', }}>
                                        {this.createVendorcards('MOBILE', selectedvt)}
                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                }

                return (
                    <div>
                        {this.renderHeader()}
                        {!showQuiz
                            ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {this.renderUserselected_vts('MOBILE')}
                                {this.renderFilter('MOBILE')}
                                {EMPTYSPACE}
                                {vendor_doms}
                                {this.RenderEditModal_mobile()}
                                {this.RenderHostModal_mobile()}
                            </div>
                            : null}
                        {this.RenderQuiz_dom()}
                    </div>
                )
            }
        }

        return <Loading />

    }
    // Render based on device type ---- END

    render() {
        const { screen_width } = this.state
        return screen_width >= TABLET ? this.RenderWeb() : this.RenderMobile()
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(Homeuser)