import React, { useEffect, useState } from 'react'
import { S3CLOUD_CLOUDFRONT } from '../../../../config/s3directories'
import './ScrollDownImageChangeAndReason.css'

const imgdata = [
    {
        imgleft: S3CLOUD_CLOUDFRONT + "assets/floral/Amaryllis_Purpurea/lookup/btmleft0.png",
        imgright: S3CLOUD_CLOUDFRONT + "assets/floral/Amaryllis_Purpurea/lookup/btmright0.png",
        banner: S3CLOUD_CLOUDFRONT + "assets/floral/Amaryllis_Purpurea/banner/banner.png"
    },
    {
        imgleft: S3CLOUD_CLOUDFRONT + "assets/floral/Blanket_Flower/lookup/btmleft0.png",
        imgright: S3CLOUD_CLOUDFRONT + "assets/floral/Blanket_Flower/lookup/btmright0.png",
        banner: S3CLOUD_CLOUDFRONT + "assets/floral/Blanket_Flower/banner/banner.png"
    },
    {
        imgleft: S3CLOUD_CLOUDFRONT + "assets/floral/Crimson_Damask/lookup/btmleft0.png",
        imgright: S3CLOUD_CLOUDFRONT + "assets/floral/Crimson_Damask/lookup/btmright0.png",
        banner: S3CLOUD_CLOUDFRONT + "assets/floral/Crimson_Damask/banner/banner.png"
    },
    {
        imgleft: S3CLOUD_CLOUDFRONT + "assets/floral/Helenium/lookup/btmleft0.png",
        imgright: S3CLOUD_CLOUDFRONT + "assets/floral/Helenium/lookup/btmright0.png",
        banner: S3CLOUD_CLOUDFRONT + "assets/floral/Helenium/banner/banner.png"
    },
    {
        imgleft: S3CLOUD_CLOUDFRONT + "assets/floral/Leptodactylon_Californicum/lookup/btmleft0.png",
        imgright: S3CLOUD_CLOUDFRONT + "assets/floral/Leptodactylon_Californicum/lookup/btmright0.png",
        banner: S3CLOUD_CLOUDFRONT + "assets/floral/Leptodactylon_Californicum/banner/banner.png"
    },
    {
        imgleft: S3CLOUD_CLOUDFRONT + "assets/floral/Mexican_Poppy/lookup/btmleft0.png",
        imgright: S3CLOUD_CLOUDFRONT + "assets/floral/Mexican_Poppy/lookup/btmright0.png",
        banner: S3CLOUD_CLOUDFRONT + "assets/floral/Mexican_Poppy/banner/banner.png"
    },
    {
        imgleft: S3CLOUD_CLOUDFRONT + "assets/floral/Myosotis_Alpestris/lookup/btmleft0.png",
        imgright: S3CLOUD_CLOUDFRONT + "assets/floral/Myosotis_Alpestris/lookup/btmright0.png",
        banner: S3CLOUD_CLOUDFRONT + "assets/floral/Myosotis_Alpestris/banner/banner.png"
    },
    {
        imgleft: S3CLOUD_CLOUDFRONT + "assets/floral/Red_Epidendrum/lookup/btmleft0.png",
        imgright: S3CLOUD_CLOUDFRONT + "assets/floral/Red_Epidendrum/lookup/btmright0.png",
        banner: S3CLOUD_CLOUDFRONT + "assets/floral/Red_Epidendrum/banner/banner.png"
    },
    {
        imgleft: S3CLOUD_CLOUDFRONT + "assets/floral/Rosa_Gallica/lookup/btmleft0.png",
        imgright: S3CLOUD_CLOUDFRONT + "assets/floral/Rosa_Gallica/lookup/btmright0.png",
        banner: S3CLOUD_CLOUDFRONT + "assets/floral/Rosa_Gallica/banner/banner.png"
    },
    {
        imgleft: S3CLOUD_CLOUDFRONT + "assets/floral/Rosa_Sulfurea/lookup/btmleft0.png",
        imgright: S3CLOUD_CLOUDFRONT + "assets/floral/Rosa_Sulfurea/lookup/btmright0.png",
        banner: S3CLOUD_CLOUDFRONT + "assets/floral/Rosa_Sulfurea/banner/banner.png"
    },
    {
        imgleft: S3CLOUD_CLOUDFRONT + "assets/floral/Slender_Flowered_Gilia/lookup/btmleft0.png",
        imgright: S3CLOUD_CLOUDFRONT + "assets/floral/Slender_Flowered_Gilia/lookup/btmright0.png",
        banner: S3CLOUD_CLOUDFRONT + "assets/floral/Slender_Flowered_Gilia/banner/banner.png"
    },
    {
        imgleft: S3CLOUD_CLOUDFRONT + "assets/floral/White_Odontoglossum/lookup/btmleft0.png",
        imgright: S3CLOUD_CLOUDFRONT + "assets/floral/White_Odontoglossum/lookup/btmright0.png",
        banner: S3CLOUD_CLOUDFRONT + "assets/floral/White_Odontoglossum/banner/banner.png"
    },
]

const reasons = [
    <div className='Presentation_elem'> 1. 100 different designs</div>,
    <div className='Presentation_elem'> 2. highly personalised</div>,
    <div className='Presentation_elem'> 3. matching design for seating chart</div>,
    <div className='Presentation_elem'> 4. matching design for itinerary</div>,
]

const title = <div className='Presentation_twocolumntitle'>Features</div>

const footer = <div className='Presentation_twobtmcolumntitle'>
    <div>✨Read Caption For More✨</div>
    <div className='Presentation_hopping'>👇🏼</div>
</div>

function ScrollDownImageChangeAndReason(props) {

    const [afterscroll, setAfterscroll] = useState(false)
    const [showtitle, setShowtitle] = useState(false)
    const [showfooter, setShowfooter] = useState(false)
    const [data, setData] = useState([])

    const scrolltime = 1400 // roughly the scrolltime needed for the scroll to scroll atw down.
    const elemtime = 1000

    useEffect(() => {

        const pxmove = 1

        if (!afterscroll) {

            let prevht = -1

            const pixelsegment = document.getElementById(props.children.props.id).clientHeight / imgdata.length

            let somemap = {}
            for (let i = 0; i < imgdata.length; i++) {
                somemap[parseInt(Math.floor(pixelsegment * (i + 1)))] = imgdata[i]
            }

            setTimeout(() => {
                const scrollToBottom = () => {
                    var element = document.getElementById(props.children.props.id);
                    var elementHeight = element.clientHeight;

                    if (document.documentElement.scrollTop < elementHeight) {
                        if (somemap[document.documentElement.scrollTop]) {
                            const { imgleft, imgright, banner } = somemap[document.documentElement.scrollTop]
                            document.getElementById('topbg').style.backgroundImage = `url(${imgleft})`
                            document.getElementById('btmbg').style.backgroundImage = `url(${imgright})`
                            const banner_doms = document.getElementsByClassName('ilookup_banner')
                            for (let i = 0; i < banner_doms.length; i++) {
                                banner_doms[i].style.backgroundImage = `url(${banner})`
                            }
                        }

                        if (prevht === (pxmove + document.documentElement.scrollTop)) {

                            setTimeout(() => {
                                setShowtitle(true)
                            }, 1000)

                            setTimeout(() => {
                                setAfterscroll(true)
                            }, 2000)
                            return
                        }
                        else {
                            prevht = 1 + document.documentElement.scrollTop
                            window.requestAnimationFrame(scrollToBottom);
                            window.scrollTo(0, pxmove + document.documentElement.scrollTop);
                        }
                    }
                }
                scrollToBottom();
            }, scrolltime)
        }
    }, [props])

    useEffect(() => {
        if (afterscroll) {
            for (let i = 0; i < reasons.length; i++) {
                setTimeout(() => {
                    setData((data)=>{
                        return [...data, reasons[i]]
                    })
                }, elemtime * i)
            }
        }
    }, [afterscroll])

    useEffect(()=>{
        if(data.length === reasons.length){
            setTimeout(()=>{
                setShowfooter(true)
            }, 1000)
        }
    },[data])

    return <div id='Presentation_root'>
        {props.children}
        <div id='Presentation_mid'>
            <div className='Presentation_twocolumnroot' style={{ backgroundColor: showtitle ? 'rgba(220, 220, 220, 0.31)' : '' }}>
                {showtitle ? title : null}
                {afterscroll 
                ? <div className='ScrollDownImageChangeAndReason_data'>
                    {data}
                </div> 
                : null}
                {showfooter ? footer : null}
            </div>

        </div>
    </div>
}

export default ScrollDownImageChangeAndReason 