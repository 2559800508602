import React, { useState, useEffect } from 'react'
import { READ_SCLOOKUP, READ_SCLOOKUPGTPASS } from '../../config/api'
import { Loading, Loginpasscode, Wrongurllookup } from '../../components'
import Slookcontent from './components/Slookcontent'
import axios from 'axios'

function Sclookup(props) {
    const [firsttime, setFirsttime] = useState(true)
    const [urlstate, setUrlstate] = useState(null) // SUCCESS | GTPASSCODE | WRONGURL
    const [item, setItem] = useState({})

    useEffect(() => {

        // check gt pass on url  - ye - go check pass api
        //                       - no - go retrieve data api
        props.hidetopbar(true)
        if (firsttime) {
            const { urlhandler, passcode } = props.match.params
            try {
                axios({
                    method: 'POST',
                    url: READ_SCLOOKUP,
                    data: { urlhandler, passcode }
                }).then((res) => {
                    const { data } = res
                    const { message, item } = data
                    if(message === 'FAILURE') {
                        window.location = '/'
                    }
                    else{
                        setUrlstate(message)
                        if(message === 'SUCCESS') {
                            setItem(item)
                        }
                    }
                }).catch((e) => {
                    props.history.push('/')
                })
            }
            catch (e) {
                props.history.push('/')
            }
            setFirsttime(false)
        }
        return () => {
            props.hidetopbar(false)
        }

    }, [])

    if (urlstate === 'GTPASSCODE') {
        return <Loginpasscode
            url={READ_SCLOOKUPGTPASS}
            urlhandler={props.match.params.urlhandler}
            onsuccess={(data, passcode, urlhandler) => {
                const { message, item } = data
                if (message === 'SUCCESS') {
                    setItem(item)
                    setUrlstate('SUCCESS')
                    props.history.push('/seatingchartlookup/' + urlhandler + '/passcode=' + passcode)
                }
            }}
        />
    }
    else if (urlstate === 'WRONGURL') {
        return <Wrongurllookup />
    }
    else if (urlstate === 'SUCCESS') {
        return <Slookcontent item={item} />
    }
    else {
        return <Loading />
    }

}

export default Sclookup