import React, { useEffect, useState } from 'react'
import { iconwrapper } from '../../../../../../config/commonfunctions';
import { ADD_ICON, BLOCKLARGE_ICON, CROSS_ICON, DRAG_ICON } from '../../../../../../config/svgicons'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Modcfm } from '../../../../../../components';
import Explain from "../Explain";

const dragsvgcss = { width: 20, height: 20, display: 'flex', placeContent: 'center', margin: 5 }
const crosssvgcss = { ...dragsvgcss, color: 'var(--mainpink)' }
const addsvgcss = { width: 23, height: 23, display: 'flex', placeContent: 'center', pointerEvent: 'none' }
const blocksvgcss = { ...dragsvgcss, color: 'var(--subdark)' }

const TITLE = 'Questions'
const TEXT = `Question & Answers for your guest to know about the couples.`

const DEFAULT_PAGE = 0
const SWAP_OR_EDIT_PAGE = 1

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#707070' : 'white',
    padding: isDraggingOver ? 5 : 0,
});

function Questions(props) {
    const { device, partialwt, showsave, setShowsave, updateBackup, clearBackup, restoreBackup, updateQnas, saveQnas } = props

    const [firsttime, setFirsttime] = useState(true)
    const [page, setPage] = useState(DEFAULT_PAGE) // 0 : default schedule page, 1 : swapping page, 2 : create schedule page, 3 : edit page

    // Questions obj
    const [qndata, setQndata] = useState([])

    // remove 
    const [rmvindex, setRmvindex] = useState(-1) // index to remove
    const [rmvcfm, setRmvcfm] = useState(false) // user click cfm or not cfm delete
    const [showrmvmod, setShowrmvmod] = useState(false) // toggle rmv modal to qn user

    useEffect(() => {
        if (firsttime) {
            setQndata(props.qndata.slice())
            setFirsttime(false)
            setPage(DEFAULT_PAGE)
        }
    }, [])

    useEffect(() => {
        if (!firsttime) {
            setQndata(props.qndata.slice())
        }
    }, [props.qndata])

    useEffect(() => {
        if (rmvcfm) {
            let newqnas = JSON.parse(JSON.stringify(qndata))
            newqnas.splice(rmvindex, 1)
            newqnas = flattenqn(newqnas)
            saveQnas(newqnas, 'remove').then((result) => {
                if (result === 'SUCCESS') {
                    setPage(DEFAULT_PAGE)
                    setShowsave(false)
                    setRmvcfm(false)
                }
            })
        }
    }, [rmvcfm])

    const flattenqn = (data) => {
        var new_qnas = []
        for (let i = 0; i < data.length; i++) {
            const { qn, ans } = data[i]
            new_qnas.push(qn)
            new_qnas.push(ans)
        }
        return new_qnas
    }

    const onDragend = (result) => {
        const { source, destination } = result
        if (!destination) { return }
        else {
            if (page !== SWAP_OR_EDIT_PAGE && source.index !== destination.index) {
                setPage(SWAP_OR_EDIT_PAGE)
            }
            else if (page === SWAP_OR_EDIT_PAGE && source.index === destination.index) {
                return
            }
            if (!showsave) { updateBackup(); setShowsave(true); }
            let curr_qndata = qndata.slice()
            const [removed] = curr_qndata.splice(source.index, 1);
            curr_qndata.splice(destination.index, 0, removed);
            updateQnas(flattenqn(curr_qndata))
            setQndata(curr_qndata)
        }
    }

    const revert = () => {
        setShowsave(false)
        restoreBackup()
        setPage(DEFAULT_PAGE)
    }

    const create = (action) => {
        saveQnas([...flattenqn(qndata), "", ""], action).then((result) => {
            if (result === 'SUCCESS') {
                setPage(DEFAULT_PAGE)
                setShowsave(false)
                clearBackup()
            }
        })
    }

    const update = (action) => {
        saveQnas(flattenqn(qndata), action).then((result) => {
            if (result === 'SUCCESS') {
                setPage(DEFAULT_PAGE)
                setShowsave(false)
                clearBackup()
            }
        })
    }

    const remove = (i) => { // see useEffect
        setShowrmvmod(() => {
            setRmvindex(i)
            return true
        })
    }

    const btmbarstyle = {
        'MOBILE': { position: 'fixed', bottom: 0, left: 0, width: '100%' },
        'TABLET': { position: 'fixed', bottom: 10, left: 0, width: partialwt - 1 },
        'WEB': { position: 'fixed', bottom: 0, left: 0, width: partialwt - 1 }
    }[device]

    const btmbar = {
        // display question
        0: <div className='iedit_swaproot' style={btmbarstyle}>
            <button className='iedit_add' onClick={() => { if (!showsave) { updateBackup(); setShowsave(true); }; create('add'); }}>{iconwrapper(ADD_ICON, addsvgcss)}</button>
        </div>,
        // swap or edit question
        1: <div className='iedit_swaproot' style={btmbarstyle}>
            <button
                className='iedit_cancel'
                disabled={!showsave}
                style={{ opacity: !showsave ? 0.5 : 1 }}
                onClick={revert}
            >
                Revert
            </button>
            <button className='iedit_save' onClick={() => { update('update') }}>Save</button>
        </div>
    }[page]

    const dnd = <div>
        <DragDropContext onDragEnd={onDragend}>
            <Droppable droppableId={'droppable'} >
                {(provided, snapshot) => (
                    <div className='Detailsdrag_root' ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                        {qndata.map((item, x) => {
                            const { qn, ans } = item
                            const i = x
                            return <Draggable key={x + '_'} draggableId={x + 'id'} index={i}>
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <div className="Iedit_twoinputs" key={i + 'qna'} >
                                            {page === DEFAULT_PAGE
                                                ? <span className='Detailsdrag_closeicon' onClick={() => remove(i)}>{iconwrapper(CROSS_ICON, crosssvgcss)}</span>
                                                : <span className='Detailsdrag_closeicon'>{iconwrapper(BLOCKLARGE_ICON, blocksvgcss)}</span>
                                            }
                                            <div className='Detailsdrag_col'>
                                                <input
                                                    className="Iedit_input Iedit_topinput"
                                                    value={qn}
                                                    placeholder={'Question'}
                                                    onChange={(e) => {
                                                        e.persist();
                                                        if (!showsave) { updateBackup(); setShowsave(true); setPage(SWAP_OR_EDIT_PAGE) }
                                                        setQndata((qndata) => {
                                                            var new_qndata = JSON.parse(JSON.stringify(qndata))
                                                            new_qndata[i] = { 'qn': e.target.value, 'ans': ans }
                                                            updateQnas(flattenqn(new_qndata))
                                                            return new_qndata
                                                        })
                                                    }}
                                                />
                                                <input
                                                    className="Iedit_input Iedit_btminput"
                                                    value={ans}
                                                    placeholder={'Answer'}
                                                    onChange={(e) => {
                                                        e.persist();
                                                        if (!showsave) { updateBackup(); setShowsave(true); setPage(SWAP_OR_EDIT_PAGE) }
                                                        setQndata((qndata) => {
                                                            var new_qndata = JSON.parse(JSON.stringify(qndata))
                                                            new_qndata[i] = { 'qn': qn, 'ans': e.target.value }
                                                            updateQnas(flattenqn(new_qndata))
                                                            return new_qndata
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <span className='Detailsdrag_dragicon' {...provided.dragHandleProps}>
                                                {iconwrapper(DRAG_ICON, dragsvgcss)}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
        {btmbar}
    </div>

    // const maincontent = {// 0 : default schedule page, 1 : swapping page, 2 : create schedule page, 3 : edit page
    //     0: dnd,
    //     1: dnd,
    //     2: <div style={{ display: 'flex', flexDirection: 'column' }}>
    //         <div style={{ padding: 10, margin: '5px 0px 5px 0px', display: 'flex', flexDirection: 'column', width: '100%', height: '100%', border: '1px solid rgb(230, 230, 230)', borderRadius: 5 }}>

    //         </div>
    //         {btmbar}
    //     </div>,
    //     3: <div style={{ display: 'flex', flexDirection: 'column' }}>
    //         <div style={{ padding: 10, margin: '5px 0px 5px 0px', display: 'flex', flexDirection: 'column', width: '100%', height: '100%', border: '1px solid rgb(230, 230, 230)', borderRadius: 5 }}>

    //         </div>
    //         {btmbar}
    //     </div>
    // }

    return (
        <div className='Ieditcomp_div'
            style={{
                height: {
                    'WEB': 'calc(100% - 70px - 65px)',
                    'TABLET': 'calc(100% - 70px - 65px)',
                    'MOBILE': 'calc(100% - 70px)'
                }[device],
                marginBottom: {
                    'MOBILE': 65
                }[device]
            }}>
            <Explain title={TITLE} txt={TEXT} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {/* {maincontent[page]} */}
                {dnd}
            </div>
            {showrmvmod
                ? <Modcfm
                    onHide={() => { setShowrmvmod(false) }}
                    onOk={() => { setShowrmvmod(false); setRmvcfm(true); }}
                    onNo={() => { setShowrmvmod(false); setRmvcfm(false); setRmvindex(-1); }}
                    txt={'Once you delete this, there is no going back. Please be certain.'}
                />
                : null}
        </div>
    )
}

export default Questions