import {

    FETCH_VENDOR_DETAIL_ACTION_FULFILLED,
    FETCH_VENDOR_DETAIL_ACTION_PENDING,
    FETCH_VENDOR_DETAIL_ACTION_REJECTED,
    UPDATE_USER_LIKE

} from '../actions/vendorlookup.action'

const initialState = {
    vendorlookup_pri_data: [],
    vendorlookup_sec_data: [],
    isContentLoading: false,
    isContentLoaded: false,
    userlike: false,
    userpersonalinfo: [],
    owner: true,
    allpricelist_ids: [],
    recommendation: [],
    vendorwhocontrol:'VENDOR'
}

//rmb to unwrap to the point where the basic data structure expose
export default (state = initialState, action) => {
    switch (action.type) {
        
        case FETCH_VENDOR_DETAIL_ACTION_FULFILLED:
            return {
                ...state,
                isContentLoading: false,
                isContentLoaded: true,
                vendorlookup_pri_data: action.payload.data.data.vendortype_id,
                vendorlookup_sec_data: action.payload.data.data.secondaryvendortype_id,
                userlike: action.payload.data.data.userlike,
                userpersonalinfo: action.payload.data.data.userpersonalinfo,
                owner: action.payload.data.data.owner,
                allpricelist_ids: action.payload.data.data.allpricelist_ids,
                recommendation: action.payload.data.data.recommendation,
                vendorwhocontrol:action.payload.data.data.vwc,
            }
        case FETCH_VENDOR_DETAIL_ACTION_PENDING:
            return {
                ...state,
                isContentLoading: true,
                isContentLoaded: false,
            }
        case FETCH_VENDOR_DETAIL_ACTION_REJECTED:
            return {
                ...state,
                isContentLoading: false,
                isContentLoaded: false,
            }
        case UPDATE_USER_LIKE:
            return {
                ...state,
                userlike: action.payload,
            };
        default:
            return state


    }
}