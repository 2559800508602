
import React, { useState, useEffect } from 'react'
import { EMPTYSPACE, ARROWDOWN_SMALL } from '../../../../../../config/constants'
import { partialtitle_dom } from '../../../Invitationmaker/components/Commonelements/Commonelements'
import { TRASH_ICON } from '../../../../../../config/svgicons'
import { iconwrapper } from '../../../../../../config/commonfunctions'

function Editsingleguestcontent(props) {
    const { DEVICE_MODALWT_MAP, allcats, device, update, rmv } = props
    const [editsingleguestname, setEditsingleguestname] = useState('')
    const [editsingleguestrela, setEditsingleguestrela] = useState('')
    const [editsingleguestcat, setEditsingleguestcat] = useState('')
    const [editsingleguestindex, setEditsingleguestindex] = useState(-1)
    const [editsingletableindex, setEditsingletableindex] = useState(-1)
    const [catdd, setCatdd] = useState(-1) // category dropdown

    useEffect(() => {
        const { data } = props
        setEditsingleguestname(data[0])
        setEditsingleguestrela(data[1])
        setEditsingleguestcat(data[2])
        setEditsingleguestindex(data[3])
        setEditsingletableindex(data[4]) // exist if is calling from edit table modal
    }, [props])


    return <div className='Rsvpexplorer_portal' >
        <div className='Rsvpexplorer_portaltitle'>Edit Guest</div>
        <div style={{ display: 'flex', flexDirection: 'column', height: 'calc( 100% - 170px)' }}>
            <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 'auto', height: 'auto', width: '100%', overflowY: 'auto', padding: '0px 10px' }}>
                <div key={'edittable'} className="scedit_troot" style={{ padding: 0, border: 'none' }} >                    <div style={{ display: 'flex', flexDirection: 'row', }}>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                        <aside style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', minHeight: 55 }}>
                            {partialtitle_dom('Guest Name', {})}
                            <input
                                style={{ height: 40, padding: 5, borderRadius: 5, width: '100%', whiteSpace: 'nowrap', outline: 'none', fontSize: 14, border: 'thin solid #707070', alignSelf: 'flex-start', }}
                                value={editsingleguestname || ''}
                                onChange={(e) => setEditsingleguestname(e.target.value)} />
                        </aside>

                        <div style={{ height: 15 }} />

                        <aside style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', minHeight: 40 }}>
                            {partialtitle_dom('Relationship', {})}
                            <input
                                style={{ height: 40, padding: 5, borderRadius: 5, width: '100%', whiteSpace: 'nowrap', outline: 'none', fontSize: 14, border: 'thin solid #707070', alignSelf: 'flex-start', }}
                                value={editsingleguestrela || ''}
                                onChange={(e) => setEditsingleguestrela(e.target.value)}
                            />
                        </aside>

                        <div style={{ height: 15 }} />

                        <aside style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', minHeight: 40 }}>
                            {partialtitle_dom('Category', {})}
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', position: 'relative' }}>
                                <input
                                    style={{ height: 40, padding: 5, borderRadius: 5, width: 'calc( 100% - 30px )', whiteSpace: 'nowrap', outline: 'none', fontSize: 14, border: 'thin solid #707070', alignSelf: 'flex-start', }}
                                    value={editsingleguestcat || ''}
                                    onChange={(e) => setEditsingleguestcat(e.target.value)}
                                />
                                <button className="scedit_ddbtn" style={{ height: 40, display: 'flex', placeItems: 'center' }} onClick={() => catdd === 0 ? setCatdd(-1) : setCatdd(0)}> {ARROWDOWN_SMALL}</button>
                            </div>
                        </aside>

                        {catdd === 0
                            ? <div id='catdd' style={{ display: 'flex', flexDirection: 'column', width: '100%', border: '1px solid #707070', marginTop: 5, borderRadius: 5 }}>
                                {/* <div style={{ backgroundColor: '#ff4e4e', color: 'white', width: '100%', textTransform: 'uppercase' }} onClick={() => setCatdd(-1)}>close</div> */}
                                <div style={{ display: 'flex', flexDirection: 'row', padding: 5, flexWrap: 'wrap', }}>
                                    {allcats.map((item, y) => {
                                        return <button
                                            key={y + 'item'}
                                            className="Rsvpexplorer_portaltag"
                                            onClick={() => {
                                                setEditsingleguestcat(item)
                                                setCatdd(-1)
                                            }}>
                                            {item}
                                        </button>
                                    })}

                                </div>
                            </div>
                            : null}

                    </div>
                </div>
                </div>
            </div>

            <div className='gl_portalbtnrow'>
                <button onClick={() => rmv(editsingleguestindex, editsingletableindex)} style={{ borderRadius: 10, backgroundColor: '#ffffff', border: '1px solid var(--maindark)' }}>{iconwrapper(TRASH_ICON, { width: 24, height: 24 })}</button>
                <button className='gl_portalupdatebtn'  onClick={() => update(editsingleguestindex, editsingleguestname, editsingleguestrela, editsingleguestcat, editsingletableindex)}>Update</button>
            </div>



            {/* <button className='Rsvpexplorer_portalsave' onClick={() => update(editsingleguestindex, editsingleguestname, editsingleguestrela, editsingleguestcat, editsingletableindex)}>Update</button> */}
        </div>

    </div>



    // return <div className="scedit_modalroot" style={{ minWidth: DEVICE_MODALWT_MAP[device] }}>
    //     <div className="scedit_gmodal">
    //         <aside style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', minHeight: 55 }}>
    //             <div>Guest Name</div>
    //             <input
    //                 style={{ padding: 5, borderRadius: 5, width: '100%', whiteSpace: 'nowrap', outline: 'none', fontSize: 14, border: 'thin solid #707070', alignSelf: 'flex-start', }}
    //                 value={editsingleguestname || ''}
    //                 onChange={(e) => setEditsingleguestname(e.target.value)} />
    //         </aside>
    //         {EMPTYSPACE}
    //         <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
    //             <aside style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '50%', minHeight: 55 }}>
    //                 <div>Guest Relationship</div>
    //                 <input
    //                     style={{ padding: 5, borderRadius: 5, width: '100%', whiteSpace: 'nowrap', outline: 'none', fontSize: 14, border: 'thin solid #707070', alignSelf: 'flex-start', }}
    //                     value={editsingleguestrela || ''}
    //                     onChange={(e) => setEditsingleguestrela(e.target.value)} />
    //             </aside>
    //             {EMPTYSPACE}
    //             <aside style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '50%', minHeight: 55, overflow: 'visible' }}>
    //                 <div>Guest Category</div>

    //                 <div style={{ width: '100%', display: 'flex', flexDirection: 'row', position: 'relative' }}>
    //                     <input
    //                         style={{ padding: 5, borderRadius: 5, width: '90%', whiteSpace: 'nowrap', outline: 'none', fontSize: 14, border: 'thin solid #707070', alignSelf: 'flex-start', }}
    //                         value={editsingleguestcat || ''}
    //                         onChange={(e) => setEditsingleguestcat(e.target.value)} />

    //                     <button className="scedit_ddbtn" onClick={() => catdd > -1 ? setCatdd(-1) : setCatdd(0)}>{ARROWDOWN_SMALL}</button>
    //                     {catdd === 0
    //                         ? <div style={{ position: 'absolute', display: 'flex', flexDirection: 'column', width: '87%', border: 'thin solid silver', top: '100%', zIndex: 2 }}>
    //                             <div style={{ backgroundColor: '#ff4e4e', color: 'white', width: '100%', textTransform: 'uppercase' }} onClick={() => setCatdd(-1)}>close</div>
    //                             {allcats.map((item, y) => {
    //                                 return <div
    //                                     key={y + 'item'}
    //                                     style={{ backgroundColor: 'white', cursor: 'pointer', padding: 8, borderTop: y !== 0 ? 'thin solid #707070' : '', }}
    //                                     onClick={() => {
    //                                         setEditsingleguestcat(item)
    //                                         setCatdd(-1)
    //                                     }}>{item}</div>
    //                             })}
    //                         </div>
    //                         : null}
    //                 </div>

    //             </aside>
    //         </div>
    //     </div>
    //     {EMPTYSPACE}
    //     <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
    //         <button style={{ ...BUTTONSTYLE, color: 'white', backgroundColor: '#eb5d7b', border: 'thin solid white' }} onClick={() => rmv(editsingleguestindex, editsingletableindex)}>Remove</button>
    //         {EMPTYSPACE}
    //         <button style={{ ...BUTTONSTYLE, width: '100%' }} onClick={() => update(editsingleguestindex, editsingleguestname, editsingleguestrela, editsingleguestcat, editsingletableindex)}>Update</button>
    //     </div>
    // </div>

}

export default Editsingleguestcontent