import React from 'react'
import './T1border.css'
import './T1bordermobile.css'

// simulator mobile and web border
function T1border(props) {
    const { styleborder, simulatordevice, styleborderwt, stylebordercolor, styleborderbr } = props
    const borderstyling = styleborderbr ? 'T1_br' : 'T1_nobr'
    const brborder = styleborderwt ? `2.5px solid ${stylebordercolor}` : `1.5px solid ${stylebordercolor}`
    const top_ = `calc( -1 * ${styleborderwt ? 2.5 : 1.5}px )`
    const left_ = `calc( -1 * ${styleborderwt ? 2.5 : 1.5}px )`
    return (styleborder > -1 && simulatordevice)
        ? {
            'WEB': [
                // 0
                <div className={`T1border_root T1border_0`}>
                    {props.children}
                </div>,
                // 1
                <div className={`T1border_root ${borderstyling}`} style={{ border: brborder, borderRadius: styleborderbr ? '2vmin' : 0 }}>
                    {props.children}
                </div>,
                // 2
                <div className={`T1border_2_root`}>
                    <div style={{ height: 5 + styleborderwt ? 2.5 * 2 : 1.5 * 2 }} />
                    {props.children}
                    <div className={`T1border_2_subroot1 ${borderstyling}`} style={{ border: brborder }} />
                    <div className={`T1border_2_subroot2 ${borderstyling}`} style={{ border: brborder }} />
                </div>,

                // 3
                <div className={`T1border_root`} style={{ border: brborder, borderRadius: styleborderbr ? '2vmin' : 0 }} >
                    <div className={`T1border_5_top_container`} >
                        <div className={`T1border_5_top_item ${borderstyling}`} style={{ border: brborder, top: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0 }} />
                        <div className={`T1border_5_top_item ${borderstyling}`} style={{ border: brborder, top: top_, left: left_, borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0 }} />
                    </div >
                    <div className={`T1border_5_top_container`} style={{ transform: 'scale(-1,1)' }} >
                        <div className={`T1border_5_top_item ${borderstyling}`} style={{ border: brborder, top: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0 }} />
                        <div className={`T1border_5_top_item ${borderstyling}`} style={{ border: brborder, top: top_, left: left_, borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0 }} />
                    </div >
                    {props.children}
                    < div className={`T1border_5_btm_container`} >
                        <div className={`T1border_5_btm_item ${borderstyling}`} style={{ border: brborder, bottom: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} />
                        <div className={`T1border_5_btm_item ${borderstyling}`} style={{ border: brborder, bottom: top_, left: left_, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0 }} />
                    </div >
                    <div className={`T1border_5_btm_container`} style={{ transform: 'scale(-1,1)', right: 0 }}>
                        <div className={`T1border_5_btm_item ${borderstyling}`} style={{ border: brborder, bottom: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} />
                        <div className={`T1border_5_btm_item ${borderstyling}`} style={{ border: brborder, bottom: top_, left: left_, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0 }} />
                    </div >
                </div >,
                // 4
                <div className={`T1border_root`} style={{ border: brborder, borderRadius: styleborderbr ? '2vmin' : 0 }} >
                    <div className={`T1border_6_top_container`} >
                        <div className={`T1border_6_top_item ${borderstyling}`} style={{ border: brborder, top: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0 }} />
                        <div className={`T1border_6_top_item ${borderstyling}`} style={{ border: brborder, top: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0 }} />
                        <div className={`T1border_6_top_item ${borderstyling}`} style={{ border: brborder, top: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0 }} />
                    </div >
                    <div className={`T1border_6_top_container`} style={{ transform: 'scale(-1,1)' }}>
                        <div className={`T1border_6_top_item ${borderstyling}`} style={{ border: brborder, top: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0 }} />
                        <div className={`T1border_6_top_item ${borderstyling}`} style={{ border: brborder, top: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0 }} />
                        <div className={`T1border_6_top_item ${borderstyling}`} style={{ border: brborder, top: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0 }} />
                    </div >
                    {props.children}
                    < div className={`T1border_6_btm_container`} >
                        <div className={`T1border_6_btm_item ${borderstyling}`} style={{ border: brborder, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0 }} />
                        <div className={`T1border_6_btm_item ${borderstyling}`} style={{ border: brborder, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0 }} />
                        <div className={`T1border_6_btm_item ${borderstyling}`} style={{ border: brborder, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0 }} />
                    </div >
                    <div className={`T1border_6_btm_container`} style={{ transform: 'scale(-1,1)', right: 0 }}>
                        <div className={`T1border_6_btm_item ${borderstyling}`} style={{ border: brborder, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0 }} />
                        <div className={`T1border_6_btm_item ${borderstyling}`} style={{ border: brborder, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0 }} />
                        <div className={`T1border_6_btm_item ${borderstyling}`} style={{ border: brborder, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0 }} />
                    </div >
                </div >,



            ],
            'MOBILE': [
                // 0
                <div className={`T1border_mobile_0`} />,
                // 1
                <div className={`T1border_mobile_2 ${borderstyling}`} style={{ border: brborder, borderRadius: styleborderbr ? '2vmin' : 0 }} />,
                // 2
                <div className={`T1border_mobile_4 ${borderstyling}`} style={{ border: brborder, borderRadius: styleborderbr ? '2vmin' : 0 }} >
                    <div className={`T1border_mobile_4 ${borderstyling}`} style={{ border: brborder, margin: 5, width: 'calc(100% - 10px)', height: 'calc(100% - 10px)' }} />
                </div >,
                // 3
                <div className={`T1border_mobile_1`} style={{ border: brborder, borderRadius: styleborderbr ? '2vmin' : 0 }}>
                    <div className={`T1border_5_top_container`}>
                        <div className={`T1border_5_top_item ${borderstyling}`} style={{ border: brborder, top: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0 }} />
                        < div className={`T1border_5_top_item ${borderstyling}`} style={{ border: brborder, top: top_, left: left_, borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0 }} />
                    </div >
                    <div className={`T1border_5_top_container`} style={{ transform: 'scale( -1, 1 )' }}>
                        <div className={`T1border_5_top_item ${borderstyling}`} style={{ border: brborder, top: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0 }} />
                        < div className={`T1border_5_top_item ${borderstyling}`} style={{ border: brborder, top: top_, left: left_, borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0 }} />
                    </div >
                    {props.children}
                    < div className={`T1border_5_btm_container`}>
                        <div className={`T1border_5_btm_item ${borderstyling}`} style={{ border: brborder, bottom: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} />
                        < div className={`T1border_5_btm_item ${borderstyling}`} style={{ border: brborder, bottom: top_, left: left_, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0 }} />
                    </div >
                    <div className={`T1border_5_btm_container`} style={{ transform: 'scale(-1,1)', right: 0 }}>
                        <div className={`T1border_5_btm_item ${borderstyling}`} style={{ border: brborder, bottom: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} />
                        < div className={`T1border_5_btm_item ${borderstyling}`} style={{ border: brborder, bottom: top_, left: left_, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0 }} />
                    </div >
                </div >,

                // 4
                <div className={`T1border_mobile_1`} style={{ border: brborder, borderRadius: styleborderbr ? '2vmin' : 0 }} >
                    <div className={`T1border_6_top_container`} >
                        <div className={`T1border_6_top_item ${borderstyling}`} style={{ border: brborder, borderTop: 'none', borderLeft: 'none', top: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0 }} />
                        < div className={`T1border_6_top_item ${borderstyling}`} style={{ border: brborder, borderTop: 'none', borderLeft: 'none', top: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0 }} />
                        < div className={`T1border_6_top_item ${borderstyling}`} style={{ border: brborder, borderTop: 'none', borderLeft: 'none', top: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0 }} />
                    </div >
                    <div className={`T1border_6_top_container`} style={{ transform: 'scale( -1, 1 )' }}>
                        <div className={`T1border_6_top_item ${borderstyling}`} style={{ border: brborder, borderTop: 'none', borderLeft: 'none', top: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0 }} />
                        < div className={`T1border_6_top_item ${borderstyling}`} style={{ border: brborder, borderTop: 'none', borderLeft: 'none', top: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0 }} />
                        < div className={`T1border_6_top_item ${borderstyling}`} style={{ border: brborder, borderTop: 'none', borderLeft: 'none', top: top_, left: left_, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0 }} />
                    </div >
                    {props.children}
                    < div className={`T1border_6_btm_container`} >
                        <div className={`T1border_6_btm_item ${borderstyling}`} style={{ border: brborder, borderBottom: 'none', borderLeft: 'none', bottom: top_, left: left_, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0 }} />
                        < div className={`T1border_6_btm_item ${borderstyling}`} style={{ border: brborder, borderBottom: 'none', borderLeft: 'none', bottom: top_, left: left_, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0 }} />
                        < div className={`T1border_6_btm_item ${borderstyling}`} style={{ border: brborder, borderBottom: 'none', borderLeft: 'none', bottom: top_, left: left_, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0 }} />
                    </div >
                    <div className={`T1border_6_btm_container`} style={{ transform: 'scale(-1,1)', right: 0 }}>
                        <div className={`T1border_6_btm_item ${borderstyling}`} style={{ border: brborder, borderBottom: 'none', borderLeft: 'none', bottom: top_, left: left_, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0 }} />
                        < div className={`T1border_6_btm_item ${borderstyling}`} style={{ border: brborder, borderBottom: 'none', borderLeft: 'none', bottom: top_, left: left_, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0 }} />
                        < div className={`T1border_6_btm_item ${borderstyling}`} style={{ border: brborder, borderBottom: 'none', borderLeft: 'none', bottom: top_, left: left_, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0 }} />
                    </div >
                </div >,

            ]
        }[simulatordevice][styleborder]
        : <div className="T1_BORDER" style={{ width: '100%', display: 'flex', justifyContent: 'center', zIndex: 998, border: '5px solid transparent', margin: 20, borderRadius: 5, height: 'max-content', overflow: 'hidden', position: 'relative' }}>
            {props.children}
        </div>
}

export default T1border