import React, { useEffect, useState } from 'react'
import { Checkdevice, Portalmodal } from "../../../../../../../../components";
import {  PERSON_ICON, ALLPERSON_ICON, MINUS_ICON, CROSS_ICON, ADD_ICON, TICK_ICON } from '../../../../../../../../config/svgicons'
import { INVITATION_GUESTLIST_SAVERSVPQN, INVITATION_GUESTLIST_EDITRSVPQN, INVITATION_GUESTLIST_SAVERSVPFOLLOWUPQN, INVITATION_GUESTLIST_EDITRSVPFOLLOWUPQN } from '../../../../../../../../config/api';
import { iconwrapper, Retrieve_personal_info } from '../../../../../../../../config/commonfunctions';
import { addentry_btn, radiobtn_dom } from '../../../Commonelements/Commonelements'
import { Togglebutton } from '../../../../../../../../basic_components';
import axios from 'axios'
import Success from '../../../Portalcontent/components/Success';

const METAICONS_CSS = { width: 22, height: 22 }// category and (person or allperson) icon
const CROSSICON_CSS = { width: 24, height: 24, display: 'flex', placeContent: 'center' }
const MINUSICON_CSS = { width: 24, height: 24, display: 'flex', placeContent: 'center' }
const ADDICON_CSS = { width: 24, height: 24, display: 'flex', placeContent: 'center' }
const TICKICON_CSS = { width: 48, height: 48, color: '#4bb543', display: 'flex', placeContent: 'center' }

function Rsvpqnportal(props) {

    const { selectedqnindex, title, from, invitationid, guestid_guestinfo_map, guestcats, successupdate, boolgate, close, guestqns, } = props

    const { token } = Retrieve_personal_info()

    const device = Checkdevice()

    const [guestids, setGuestids] = useState([])

    // addqn portal states
    const [addqntype, setAddqntype] = useState('multiplechoice') //  multiplechoice, text
    const [addqninput, setAddqninput] = useState('') // qn
    const [addqncolname, setAddqncolname] = useState('') // column name for qn
    const [qnchoices, setQnchoices] = useState([]) // array of choices in string
    const [qnchoicesfollowupqns, setQnchoicesfollowupqns] = useState([])
    const [qnid, setQnid] = useState('')

    const [choicesmsg, setQnchoicesmsg] = useState([]) // multiple choice feedback message
    const [addwhocanview, setAddwhocanview] = useState('all') // all, specific
    const [addwhocanviewexpand, setAddwhocanviewexpand] = useState(true) // expand gate to show more of tags or just selection of addwhocnaview
    const [allcheck, setAllcheck] = useState(true)
    const [specific, setSpecific] = useState(false)
    const [catcheck, setCatcheck] = useState(false)
    const [allorcatind, setAllorcatind] = useState('all') // all or catind
    const [tagsarr, setTagsarr] = useState([]) // tags that permit to view & ans the qn
    const [tagsalrdydeleted, setTagsalrdydeleted] = useState([]) // tags that alrdy deleted
    const [optionguest, setOptionguest] = useState('') // id of selected guest 
    const [optionguestarr, setOptionguestarr] = useState([]) // guest ids that permit to view & ans the qn
    const [colnamemsg, setColnamemsg] = useState('')
    const [qnpromptmsg, setQnpromptmsg] = useState('')
    const [qnoptionmsg, setQnoptionmsg] = useState('')
    const [savingstate, setSavingstate] = useState('IDLE') // IDLE, SAVING, SUCCESS, FAILURE
    const [followupqnuid, setFollowupqnuid] = useState(null) // exist only in editfollowupqn, to store uid of followup qn

    useEffect(() => {
        // addnewfollowupqn & addnewqn will not have any effect

        if (from === 'editqn') {
            if (guestqns[selectedqnindex][1] === 0) { // multiple choice
                setAddqninput(guestqns[selectedqnindex][0])
                setAddqntype(['multiplechoice', 'text'][guestqns[selectedqnindex][1]])
                setAddqncolname(guestqns[selectedqnindex][2])
                setAllorcatind(guestqns[selectedqnindex][3])
                setTagsarr(guestqns[selectedqnindex][4])
                setTagsalrdydeleted(guestqns[selectedqnindex][4].filter(x => !guestcats.includes(x)))
                setOptionguestarr(guestqns[selectedqnindex][5])
                setQnid(guestqns[selectedqnindex][7])
                setQnchoices(guestqns[selectedqnindex][8].map((i) => { return i[0] }))
                setQnchoicesmsg(guestqns[selectedqnindex][8].map(() => { return '' }))
                setQnchoicesfollowupqns(guestqns[selectedqnindex][8].map((i) => { return i[1] }))
                //      0 - 'How many pax', 
                //      1 - 0(0 - multiple choices, 1 - text),
                //      2 - rename of 'How many pax' for column name, 
                //      3 - allorcatind (all, catind. can see this qn),
                //      4 - [ tag1, tag2, ... ], 
                //      5 - [ guestid1, guestid2, ... ],
                //      6 - [], maybe needed in the future,
                //      7 - qn id
                //      8 - [ [option1, []], [option2, []], [option3, []] ] // only exist in multiple choice

            }
            else { // open-ended
                setAddqninput(guestqns[selectedqnindex][0])
                setAddqntype(['multiplechoice', 'text'][guestqns[selectedqnindex][1]])
                setAddqncolname(guestqns[selectedqnindex][2])
                setAllorcatind(guestqns[selectedqnindex][3]) // all or catind
                setTagsarr(guestqns[selectedqnindex][4])
                setTagsalrdydeleted(guestqns[selectedqnindex][4].filter(x => !guestcats.includes(x)))
                setOptionguestarr(guestqns[selectedqnindex][5])
                setQnid(guestqns[selectedqnindex][7])
            }

            // determine the all, category and individual btn state
            if (guestqns[selectedqnindex][3] === 'all') {
                setAllcheck(true)
                setSpecific(false)
                setAddwhocanview('all')
            }
            else {
                setAllcheck(false)
                setSpecific(true)
                setAddwhocanview('specific')
            }
        }
        else if (from === 'addnewfollowupqn') {
            // do nothing            
        }
        else if (from === 'editfollowupqn') {
            const { selectedmainqnindex, selectedoptionindex, selectedfollowupqnindex } = props
            var arr = guestqns[selectedmainqnindex][8][selectedoptionindex][1][selectedfollowupqnindex]
            if (arr[1] === 0) {
                setAddqninput(arr[0])
                setAddqntype(['multiplechoice', 'text'][arr[1]])
                setAddqncolname(arr[2])
                setFollowupqnuid(arr[3])
                setQnchoices(arr.slice(4, arr.length))
                setQnchoicesmsg(arr.slice(4, arr.length).map(() => { return '' }))
            }
            else {
                setAddqninput(arr[0])
                setAddqntype(['multiplechoice', 'text'][arr[1]])
                setAddqncolname(arr[2])
                setFollowupqnuid(arr[3])
            }
        }
    }, [from])

    useEffect(() => {
        setGuestids(props.guestids)
    }, [props.guestids])

    const clear = () => {
        setAddqninput('')
        setAddqncolname('')
        setQnchoices([])
    }

    const save = () => {
        if (savingstate === 'IDLE') {
            setSavingstate('SAVING')

            //  text
            //  [ 
            //      'How many pax', 
            //      1(0-multiple choices, text),
            //      rename of 'How many pax' for column name, 
            //      'all' or 'catind',
            //      [tag1, tag2, ... ],
            //      [ guestid1, guestid2, ... ],
            //       text 
            //  ],

            // multiplechoice
            // [ 
            //      'How many pax',
            //      0(0-multiple choices, text), 
            //      rename of 'How many pax' for column name, 
            //      'all' or 'catind',
            //      [ tag1, tag2, ... ], 
            //      [ guestid1, guestid2, ... ],
            //      [option1, []], [option2, []], [option3, []]
            //  ]


            //     for multiple choices without follow up questions, -1 is to indicate no follow up question
            //     [ 
            //      0 - 'How many pax', 
            //      1 - 0(0 - multiple choices, 1 - text),
            //      2 - rename of 'How many pax' for column name, 
            //      3 - allorcatind (all, catind. can see this qn),
            //      4 - [ tag1, tag2, ... ], 
            //      5 - [ guestid1, guestid2, ... ],
            //      6 - [], maybe needed in the future,
            //      7 - 'makdsnmfkjasndfkjsnfkjnfdkj123' id of qn,
            //      8 - [ [option1, []], [option2, []], [option3, []] ]
            //     ],

            //     for text only
            //     [ 
            //      0 - 'How many pax',
            //      1 - 1 (0 - multiple choices, 1 - text),
            //      2 - rename of 'How many pax' for column name, 
            //      3 - allorcatind,
            //      4 - [ tag1, tag2, ... ],
            //      5 - [ guestid1, guestid2, ... ], // specific user can view this qn
            //      6 - [], maybe needed in the future
            //      7 - 'makdsnmfkjasndfkjsnfkjnfdkj123' id of qn,
            //     ],


            setTimeout(() => {

                var errorcounter = 0
                var url

                // check col name
                if (addqncolname.length === 0) {
                    setColnamemsg('Column name cannot be empty.')
                    errorcounter++;
                }

                // check col name cannot longer than 100
                if (addqncolname.length > 100) {
                    setColnamemsg(`Column name cannot be longer than 100 characters.`)
                    errorcounter++;
                }

                // check qn exist
                if (addqninput.length === 0) {
                    setQnpromptmsg('Question prompt cannot be empty.')
                    errorcounter++;
                }

                // check question prompt cannot longer than 500
                if (addqninput.length > 500) {
                    setQnpromptmsg('Question prompt cannot be longer than 500 characters.')
                    errorcounter++;
                }

                if (addqntype === 'multiplechoice') {

                    // check options gt at least 1 & option cannot be empty
                    if (qnchoices.length === 0) {
                        setQnoptionmsg('Require at least 1 option.')
                        errorcounter++;
                    }
                    else {
                        for (let i = 0; i < qnchoices.length; i++) {
                            if (qnchoices[i].length === 0) {
                                errorcounter++;
                                setQnchoicesmsg((curr) => {
                                    curr[i] = 'Multiple choice option cannot be empty.'
                                    return curr
                                })
                            }
                            if (qnchoices[i].length > 500) {
                                errorcounter++;
                                setQnchoicesmsg((curr) => {
                                    curr[i] = 'Multiple choice option cannot be longer than 500 characters.'
                                    return curr
                                })
                            }
                        }

                        // check if mcq options are unique
                        var choicesmap = {}
                        for (let i = 0; i < qnchoices.length; i++) {
                            if (!choicesmap[qnchoices[i]]) {
                                choicesmap[qnchoices[i]] = 0
                            }
                            choicesmap[qnchoices[i]] = choicesmap[qnchoices[i]] + 1

                            if (choicesmap[qnchoices[i]] > 1) {
                                errorcounter++;
                                setQnchoicesmsg((curr) => {
                                    curr[i] = 'Multiple choice option has to be unique.'
                                    return curr
                                })
                            }
                        }

                    }

                }

                if (errorcounter === 0) {
                    let packeddata
                    let newqn
                    var new_expandablestates = props.expandablestates.slice()

                    if (from === 'addnewqn') {

                        // expand state, see rsvpqnexplorer useeffect, need to edit here :qnchoices.map((_) => { return [false, []] })

                        if (addqntype === 'multiplechoice') {
                            let optionexpand_arr = []
                            for (let i = 0; i < qnchoices.length; i++) {
                                optionexpand_arr.push([false, []])
                            }

                            new_expandablestates.push({ 'mainexpand': false, 'optionexpand': optionexpand_arr })

                            var choice_pair = []
                            for (let i = 0; i < qnchoices.length; i++) {
                                choice_pair.push([qnchoices[i], []])
                            }

                            newqn = [addqninput, 0, addqncolname, allorcatind, tagsarr, optionguestarr, [], qnid, choice_pair]
                        }
                        else {
                            new_expandablestates.push({ 'mainexpand': false, 'optionexpand': [] })
                            newqn = [addqninput, 1, addqncolname, allorcatind, tagsarr, optionguestarr, [], qnid]
                        }

                        packeddata = { invitationid, newqn, addqncolname }

                        // url of addnewqn
                        url = INVITATION_GUESTLIST_SAVERSVPQN
                    }
                    else if (from === 'editqn') { // brave
                        const { selectedqnindex, guestqnsuids, } = props

                        // form data to send backend    
                        newqn = addqntype === 'multiplechoice' // element in position 6 is for future purpose
                            ? [addqninput, 0, addqncolname, allorcatind, tagsarr, optionguestarr, [], qnid, qnchoices.map((item, i) => { return [item, qnchoicesfollowupqns[i]] })]//// patch back all follow up questions if any
                            : [addqninput, 1, addqncolname, allorcatind, tagsarr, optionguestarr, [], qnid,]

                        packeddata = { invitationid: invitationid, newqn: newqn, newqncolname: addqncolname, index: selectedqnindex, guestqnuid: guestqnsuids[selectedqnindex] }

                        // url of editqn
                        url = INVITATION_GUESTLIST_EDITRSVPQN
                    }
                    else if (from === 'addnewfollowupqn') {

                        const { selectedmainqnindex, selectedoptionindex } = props

                        // expand state, see rsvpqnexplorer useeffect
                        if (addqntype === 'multiplechoice') {

                            // console.log('before', new_expandablestates)
                            // console.log('before', new_expandablestates)
                            // console.log('selectedmainqnindex', selectedmainqnindex)
                            // console.log('selectedoptionindex', selectedoptionindex)
                            new_expandablestates[selectedmainqnindex]['mainexpand'] = true
                            new_expandablestates[selectedmainqnindex]['optionexpand'][selectedoptionindex][0] = true
                            new_expandablestates[selectedmainqnindex]['optionexpand'][selectedoptionindex][1].push(true)
                            // console.log('after', new_expandablestates)

                        }

                        // form data to send backend
                        var followupqn = addqntype === 'multiplechoice'
                            ? [
                                addqninput, // question text
                                0, // question type
                                addqncolname, // name appear in guestlist/spreadsheet
                                '', // for uuid, generated from backend
                                ...qnchoices // multiple choices elements
                            ]
                            : [
                                addqninput,// question text
                                1,// question type
                                addqncolname, // name appear in guestlist/spreadsheet
                                '' // for uuid
                            ]
                        packeddata = { invitationid, followupqn, followupcolname: addqncolname, selectedmainqnindex, selectedoptionindex }

                        // url of addnewfollowupqn
                        url = INVITATION_GUESTLIST_SAVERSVPFOLLOWUPQN

                    }
                    else if (from === 'editfollowupqn') {

                        const { selectedmainqnindex, selectedoptionindex, selectedfollowupqnindex } = props

                        // expand state, see rsvpqnexplorer useeffect
                        new_expandablestates[selectedmainqnindex]['mainexpand'] = true
                        new_expandablestates[selectedmainqnindex]['optionexpand'][selectedoptionindex][0] = true
                        new_expandablestates[selectedmainqnindex]['optionexpand'][selectedoptionindex][1].push(true)

                        // form data to send backend
                        var newfollowupqn = addqntype === 'multiplechoice'
                            ? [addqninput, 0, addqncolname, '', ...qnchoices] // position 3, is for uid generated from backend
                            : [addqninput, 1, addqncolname, '']
                        packeddata = { invitationid, followupqn: newfollowupqn, followupcolname: addqncolname, followupqnuid, selectedmainqnindex, selectedoptionindex, selectedfollowupqnindex }

                        // url of editfollowupqn
                        url = INVITATION_GUESTLIST_EDITRSVPFOLLOWUPQN
                    }

                    // run axios
                    try {
                        axios({
                            method: 'POST',
                            url: url,
                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                            data: packeddata
                        }).then((item) => {
                            if (item && item.data && item.data.message === 'SUCCESS') {
                                successupdate(item.data, new_expandablestates)
                                setSavingstate('SUCCESS')
                            }
                            else {
                                setSavingstate('FAILURE')
                            }
                        })
                    }
                    catch (e) { }

                }

                if (errorcounter > 0) setSavingstate('IDLE')

            }, 1000)
        }
    }

    const updateviewbtn = (e) => {
        setAddwhocanview(e.target.value)
    }

    const closeaddqnmodal = () => {
        close()
        // reset to org state, copy start from : const [addqntype, setAddqntype]... - const [savingstate, setSavingstate]
        setAddqntype('multiplechoice')
        setAddqninput('')
        setAddqncolname('')
        setQnchoices([])
        setAddwhocanview('all')
        setAddwhocanviewexpand(true)
        setAllcheck(true)
        setSpecific(false)
        setTagsarr([])
        setOptionguest('')
        setOptionguestarr([])
        setColnamemsg('')
        setQnpromptmsg('')
        setQnoptionmsg('')
        setSavingstate('IDLE')
    }

    const title_dom = <div className='Rsvpexplorer_portaltitle'>
        {title}
    </div>

    // const qntype_dom = from === 'editqn' || from === 'editfollowupqn'
    //     ? <div className='Rsvpexplorer_portalrow' key={addqntype}>
    //         {addqntype === 'multiplechoice' ? <button className={`Rsvpexplorer_portalselectbtn Rsvpexplorer_portalleftbtn ${addqntype === 'multiplechoice' ? 'Rsvpexplorer_btnselected' : ''}`} style={{ width: '100%', borderRadius: 5, border: 'none' }} value={'multiplechoice'} >Multiple Choices</button> : null}
    //         {addqntype === 'text' ? <button className={`Rsvpexplorer_portalselectbtn Rsvpexplorer_portalrightbtn ${addqntype === 'text' ? 'Rsvpexplorer_btnselected' : ''}`} style={{ width: '100%', borderRadius: 5, border: 'none' }} value={'text'}>Open-ended</button> : null}
    //     </div>
    //     : <div className='Rsvpexplorer_portalrow' key={addqntype}>
    //         <button className={`Rsvpexplorer_portalselectbtn Rsvpexplorer_portalleftbtn ${addqntype === 'multiplechoice' ? 'Rsvpexplorer_btnselected' : ''}`} value={'multiplechoice'} onClick={(e) => { setAddqntype(e.target.value); clear(); }}>Multiple Choices</button>
    //         <button className={`Rsvpexplorer_portalselectbtn Rsvpexplorer_portalrightbtn ${addqntype === 'text' ? 'Rsvpexplorer_btnselected' : ''}`} value={'text'} onClick={(e) => { setAddqntype(e.target.value); clear(); }}>Open-ended</button>
    //     </div>

    const qntype_dom = from === 'editqn' || from === 'editfollowupqn'
        ? <div className='Rsvpexplorer_portalrow' key={addqntype}>
            <Togglebutton
                rootstyle={{ width: '100%' }}
                singlebtnstyle={{ width: '50%' }}
                funcs={[() => { }, () => { }]}
                txts={['Multiple Choices', 'Open-ended']}
                btnselected={{ 'multiplechoice': 0, 'text': 1 }[addqntype]}
            />
        </div>
        : <div className='Rsvpexplorer_portalrow'>
            <Togglebutton
                rootstyle={{ width: '100%' }}
                singlebtnstyle={{ width: '50%' }}
                funcs={[
                    () => {
                        setAddqntype('multiplechoice');
                        clear();
                    },
                    () => {
                        setAddqntype('text');
                        clear();
                    }
                ]}
                txts={['Multiple Choices', 'Open-ended']}
                btnselected={{ 'multiplechoice': 0, 'text': 1 }[addqntype]}
            />
        </div>

    const colname_dom = <div className='Rsvpexplorer_portalcustomnameroot'>
        <div className='Rsvpexplorer_portalcustomnamelabel'>
            Assign a custom name to your question on guestlist.
        </div>
        <div className='Rsvpexplorer_portalspreadsheetcolrow'>
            <div className='Rsvpexplorer_portalcolname'>Column Name</div>
            <input className="Rsvpexplorer_portalcolinput" value={addqncolname} onChange={(e) => { setAddqncolname(e.target.value); setColnamemsg('') }} />
        </div>
        <div className={`Rsvpexplorer_portalmsg ${colnamemsg.length > 0 ? 'Rsvpexplorer_portalmsghighlight' : ''}`}>{colnamemsg}</div>
    </div>

    const btnremovetag = (tag, i) => {
        if (tagsarr.indexOf(tag) > -1) {
            var new_tags = tagsarr.slice()
            new_tags.splice(tagsarr.indexOf(tag), 1)
            setTagsarr(new_tags)
        }
        else {
            setTagsarr([...tagsarr, tag])
        }
    }

    const whocanviewqn_dom = from === 'addnewqn' || from === 'editqn'
        ? <div className='Rsvpexplorer_portalcustomnameroot'>
            <div className='Rsvpexplorer_portalcustomnamelabel'>Who can view and answer this question?</div>
            <div className='Rsvpexplorer_portalpermittop'> {/* for tag */}

                <button className={`Rsvpexplorer_portaltagbtn ${addwhocanview === 'all' ? 'Rsvpexplorer_portaltagbtnselected' : ''}`} value='all' onClick={updateviewbtn}>
                    <div className='Rsvpexplorer_portaltaginvisible' style={{ margin: '0px 5px 0px 0px' }}>{iconwrapper(ALLPERSON_ICON, METAICONS_CSS)}</div>
                    <div className='Rsvpexplorer_portaltagbtnlbl'>All</div>
                    {allcheck ? iconwrapper(TICK_ICON, METAICONS_CSS) : <div className='Rsvpexplorer_portaltaginvisible' />}
                </button>

                <button className={`Rsvpexplorer_portaltagbtn ${addwhocanview === 'specific' ? 'Rsvpexplorer_portaltagbtnselected' : ''}`} value='specific' onClick={updateviewbtn}>
                    <div className='Rsvpexplorer_portaltaginvisible' style={{ margin: '0px 5px 0px 0px' }}>{iconwrapper(PERSON_ICON, METAICONS_CSS)}</div>
                    <div className='Rsvpexplorer_portaltagbtnlbl'>Specific</div>
                    {specific ? iconwrapper(TICK_ICON, METAICONS_CSS) : <div className='Rsvpexplorer_portaltaginvisible' />}
                </button>

            </div>

            <div className='Rsvpexplorer_portalpermitbtm'> {/* display the content of selected */}
                {addwhocanviewexpand
                    ? {
                        'all': <div className='Rsvpexplorer_portalwhocanviewcontent'>
                            <div className='Rsvpexplorer_portaltagmainlabel'>
                                Anyone with the passcode to access your wedding/event website would be able to view and answer this question.
                            </div>
                            {radiobtn_dom(
                                allcheck,
                                () => {

                                    if (specific) {
                                        setAllcheck(true)
                                        setSpecific(false)
                                        setAllorcatind('all')
                                    }
                                    else {
                                        setAllcheck(false)
                                        setSpecific(true)
                                        setAllorcatind('catind')
                                    }
                                },
                                allcheck ? `"All" Enabled` : `"All" Disabled`
                            )}
                        </div>,
                        'specific': <div className='Rsvpexplorer_portalwhocanviewcontent'>
                            <div className='Rsvpexplorer_portaltagmainlabel'>
                                Select and add specific guest or based on category to allow guest to view and answer the question.
                            </div>
                            {radiobtn_dom(
                                specific,
                                () => {
                                    const new_selectedcheck = !specific
                                    setSpecific(new_selectedcheck)
                                    if (new_selectedcheck) {
                                        setAllcheck(false)
                                        setAllorcatind('catind')
                                    }
                                    else {
                                        setAllcheck(true)
                                        setAllorcatind('all')
                                    }
                                },
                                specific ? `"Selected" Enabled` : `"Selected" Disabled`
                            )}
                            {specific
                                ? <div className='Rsvpexplorer_portaltagcol'>
                                    {/* <div className='Rsvpexplorer_portaltagrow'>
                                        <button className={`Rsvpqnexplorer_lefthalfbtn ${showselected === 'category' ? 'Rsvpqnexplorer_selectedlightpink' : ''}`} onClick={(e) => { setShowselected(e.target.value) }} value={'category'}>Category</button>
                                        <button className={`Rsvpqnexplorer_righthalfbtn ${showselected === 'individual' ? 'Rsvpqnexplorer_selectedlightpink' : ''}`} onClick={(e) => { setShowselected(e.target.value) }} value={'individual'}>Individual</button>
                                    </div> */}
                                    <div className='Rsvpexplorer_portaltagcol' style={{ padding: 10, margin: '10px 0px', border: '1px dashed var(--subdark)', borderRadius: 5 }}>
                                        <div className='Rsvpexplorer_title'>Category</div>
                                        <div style={{ height: 10 }} />
                                        <div className='Rsvpexplorer_portaltaglabel'>Category Tag Collection.</div>
                                        <div className='Rsvpexplorer_portaltagrow'>
                                            {guestcats.map((tag, i) => {
                                                return <button key={i + tag} className={`Rsvpexplorer_portaltag ${tagsarr.indexOf(tag) > -1 ? 'Rsvpexplorer_portaltagselected' : ''} `} onClick={() => { btnremovetag(tag, i) }} > {tag}</button>
                                            })}
                                        </div>
                                        {tagsalrdydeleted.length > 0
                                            ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ height: 10 }} />
                                                <div className='Rsvpexplorer_portaltaglabel'>Tags that had already removed from Category Tag Collection.</div>
                                                <div className='Rsvpexplorer_portaltagrow'>
                                                    {tagsalrdydeleted.map((tag, i) => {
                                                        return <button key={i + tag} className={`Rsvpexplorer_portaltag ${tagsarr.indexOf(tag) > -1 ? 'Rsvpexplorer_portaltagselected' : ''} `} onClick={() => { btnremovetag(tag, i) }} > {tag} </button>
                                                    })}
                                                </div>
                                            </div>
                                            : null}
                                    </div>

                                    <div className='Rsvpexplorer_portaltagcol' style={{ padding: 10, margin: '10px 0px', border: '1px dashed var(--subdark)', borderRadius: 5 }}>
                                        <div className='Rsvpexplorer_title'>Individual</div>
                                        <div style={{ height: 10 }} />
                                        <div className='Rsvpexplorer_portaltaglabel'>{`All Guest(s)`}</div>
                                        <div className='Rsvpexplorer_portaltagrow'>
                                            <select
                                                className='Rsvpexplorer_gl'
                                                value={optionguest}
                                                onChange={(e) => { setOptionguest(e.target.value) }}
                                            >
                                                {['', ...guestids].map((g, i) => {
                                                    if (optionguestarr.indexOf(g) > -1) {
                                                        return null
                                                    }
                                                    if (g === '') {
                                                        return <option key={'emptyguest'} value={''}></option>
                                                    }
                                                    else {
                                                        const name = guestid_guestinfo_map[g]['1-name']
                                                        const contact = guestid_guestinfo_map[g]['3-contact'].length > 0 ? guestid_guestinfo_map[g]['3-contact'] : 'No Contact'
                                                        return <option key={i + 'guest'} value={g}>{name + ' / ' + contact}</option>
                                                    }
                                                })}
                                            </select>
                                            <button
                                                className="Rsvpexplorer_portaladdnewguest"
                                                onClick={() => {
                                                    if (optionguest === '' || optionguestarr.indexOf(optionguest) > -1) {
                                                        return
                                                    }
                                                    else {
                                                        var new_guests = optionguestarr.slice()
                                                        new_guests.push(optionguest)
                                                        setOptionguestarr(new_guests)

                                                        // update guestids
                                                        var new_guestids = guestids.slice()
                                                        var index = guestids.indexOf(optionguest)
                                                        new_guestids.slice(index, 1)
                                                        setGuestids(new_guestids)



                                                        // update option to blank
                                                        setOptionguest('')
                                                    }
                                                }}
                                            >
                                                {iconwrapper(ADD_ICON, ADDICON_CSS)}
                                            </button>
                                        </div>
                                        {optionguestarr.map((g, i) => {
                                            const name1 = guestid_guestinfo_map[g]['1-name']
                                            var contact = guestid_guestinfo_map[g]['3-contact'].length > 0 ? guestid_guestinfo_map[g]['3-contact'] : 'No Contact'
                                            return <div style={{ width: '100%', height: 40, display: 'flex', flexDirection: 'row', alignItems: 'center' }} key={'selected' + i}>
                                                <div style={{ width: 'calc(100% - 45px)', height: 40, alignItems: 'center', display: 'flex' }}>{name1 + ' / ' + contact}</div>
                                                <button

                                                    className="Rsvpexplorer_portaladdnewguest"
                                                    style={{ marginLeft: 5, color: '#eb5d7b', border: 'none' }}
                                                    onClick={() => {

                                                        var new_optionguestsselected = optionguestarr.slice()
                                                        new_optionguestsselected.splice(i, 1)
                                                        setOptionguestarr(new_optionguestsselected)

                                                        var new_guestids = guestids.slice()
                                                        new_guestids.push(g)
                                                        setGuestids(new_guestids)

                                                    }}
                                                >
                                                    {iconwrapper(CROSS_ICON, CROSSICON_CSS)}
                                                </button>
                                            </div>
                                        })}
                                    </div>
                                </div>
                                : null}
                        </div>

                    }[addwhocanview]
                    : <button className='Rsvpexplorer_portalpermitexpand' onClick={() => setAddwhocanviewexpand(true)}>Expand</button>}
                {addwhocanviewexpand ? <button className='Rsvpexplorer_portalpermitexpand' onClick={() => setAddwhocanviewexpand(false)}>Hide</button> : null}
            </div>
        </div>
        : null

    const qntypecontent_dom = {
        'multiplechoice': <div className='Rsvpexplorer_portalqntypecontent'>
            <div className='Rsvpexplorer_portalcustomnamelabel'>
                Question Prompt
            </div>
            <div className='Rsvpexplorer_optioncol'>
                <input placeholder='Question to ask your guest.' className='Rsvpexplorer_input' value={addqninput} onChange={(e) => { setAddqninput(e.target.value); setQnpromptmsg(''); }} />
                <div className='Rsvpexplorer_portalmsg Rsvpexplorer_portalmsghighlight'>{qnpromptmsg}</div>
            </div>
            {qnchoices.map((choice, i) => {
                return <div key={i + '_choice'} className='Rsvpexplorer_optioncol'>
                    <div className='Rsvpexplorer_optionrow'>
                        <input
                            className='Rsvpexplorer_portalmultiplechoiceoption'
                            value={choice}
                            placeholder={`Option ${i + 1}`}
                            onChange={(e) => {
                                var new_addqnchoices = qnchoices.slice()
                                new_addqnchoices[i] = e.target.value
                                setQnchoices(new_addqnchoices)

                                let new_addqnchoicesmsg = choicesmsg.slice()
                                new_addqnchoicesmsg[i] = ''
                                setQnchoicesmsg(new_addqnchoicesmsg)
                            }}
                        />
                        {from === 'editqn'
                            ? <button
                                className="Ieditcontact_del"
                                style={{ marginLeft: 5, color: '#eb5d7b', border: 'none' }}
                                onClick={() => {
                                    if (qnchoicesfollowupqns[i].length > 0) {
                                        alert('unable to delete, due to followup question exist. To delete you are required to delete folloup questions first.')
                                    }
                                    else {
                                        var new_qnchoicesfollowupqns = qnchoicesfollowupqns.slice()
                                        new_qnchoicesfollowupqns.splice(i, 1)
                                        setQnchoicesfollowupqns(new_qnchoicesfollowupqns)

                                        var new_addqnchoices = qnchoices.slice()
                                        new_addqnchoices.splice(i, 1)
                                        setQnchoices(new_addqnchoices)
                                    }
                                }}
                            >



                                {qnchoicesfollowupqns[i].length > 0 ? iconwrapper(MINUS_ICON, MINUSICON_CSS) : iconwrapper(CROSS_ICON, CROSSICON_CSS)}
                            </button>
                            : <button
                                className="Ieditcontact_del"
                                style={{ marginLeft: 5, color: '#eb5d7b', border: 'none' }}
                                onClick={() => {
                                    var new_addqnchoices = qnchoices.slice()
                                    new_addqnchoices.splice(i, 1)
                                    setQnchoices(new_addqnchoices)
                                }}
                            >
                                {iconwrapper(CROSS_ICON, CROSSICON_CSS)}
                            </button>
                        }
                    </div>
                    <div className='Rsvpexplorer_portalmsg Rsvpexplorer_portalmsghighlight'>{choicesmsg[i]}</div>
                </div>
            })}
            {addentry_btn(
                null,
                'Add New Choice',
                () => {
                    setQnchoices([...qnchoices, '']);
                    setQnoptionmsg('');
                    if (from === 'editqn') { setQnchoicesfollowupqns([...qnchoicesfollowupqns, []]) }
                }
            )}
            <div className={`Rsvpexplorer_portalmsg ${qnoptionmsg.length > 0 ? 'Rsvpexplorer_portalmsghighlight' : ''}`}>{qnoptionmsg}</div>
        </div>,
        'text': <div className='Rsvpexplorer_portalqntypecontent'>
            <div className='Rsvpexplorer_portalcustomnamelabel'>Question Prompt</div>
            <input className='Rsvpexplorer_input' placeholder='Question to ask your guest.' value={addqninput} onChange={(e) => { setAddqninput(e.target.value) }} />
            <div className='Rsvpexplorer_emptytextarea'>A empty textbox for your guest to fill in replies of your question.</div>
        </div>
    }[addqntype]

    const saving_btn = <button className='Rsvpexplorer_portalsave' onClick={save} disabled={savingstate === 'SAVING'}>
        {{ // IDLE, SAVING, SUCCESS, FAILURE
            'IDLE': 'Save',
            'SAVING': 'Saving...',
            'SUCCESS': 'New Question Created',
            'FAILURE': 'Error Occurred, please try again later.'
        }[savingstate]}
    </button>

    const success_dom = savingstate === 'SUCCESS'
        ? <Success
            txt={{
                'addnewqn': 'Question successfully added. Click to return to question explorer.',
                'editqn': 'Question successfully edited. Click to return to question explorer.',
                'addnewfollowupqn': 'New follow-up question added. Click to return to question explorer.',
                'editfollowupqn': 'Follow-up question edited. Click to return to question explorer.'
            }[from]}
            onClick={closeaddqnmodal} />
        : null

    return <Portalmodal
        animation={'right_left'}
        portaltype={'commonportal'}
        open={boolgate}
        onClose={closeaddqnmodal}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={{ width: { 'WEB': 500, 'TABLET': 450, 'MOBILE': '100%' }[device], height: '100%', backgroundColor: 'white', top: 0, left: null, right: 0, transform: 'none' }}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className='Rsvpexplorer_portal'>
            {title_dom}
            {(savingstate === 'IDLE' || savingstate === 'SAVING' || savingstate === 'FAILURE')
                ? <div className='Rsvpexplorer_portalcontent'>
                    {qntype_dom}
                    {colname_dom}
                    {whocanviewqn_dom}
                    {qntypecontent_dom}
                    {saving_btn}
                </div>
                : null}
            {success_dom}
        </div>
    </Portalmodal>
}

export default Rsvpqnportal