import React from 'react'
const wt = 150
function Noti({ noti, bg = '#333333' }) {
    return noti && noti.length > 0
        ? <div style={{ position: 'fixed', display: 'flex', top: 0, left: 0, height: '100vh', width: '100vw', backgroundColor: `#00000080`, color: 'white', placeContent: 'center', alignItems: 'center',  zIndex: 9999999999, fontFamily:'Lato', fontSize:14}}>
            <div style={{ position: 'fixed', display: 'flex', width: wt, minWidth: 'max-content', minHeight: 'max-content', backgroundColor: bg, color: 'white', padding: 15, borderRadius: 10, placeContent: 'center',  alignItems: 'center', textAlign: 'center', overflow: 'hidden' }}>
                {noti}
            </div>
        </div>
        : null
}

export default Noti