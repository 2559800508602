import React from 'react'
import { USER_FAVOURITE_VENDOR } from '../../config/api'
import { useEndpoint } from '../../async_components'
import { Retrieve_personal_info } from '../../config/commonfunctions'
import { Headtitle } from '../../components'
import Favouritevendor from './components/Favouritevendor/Favouritevendor'
import './Userfavourite.css'
function UserFavourite(props) {

    const { token, _id, } = Retrieve_personal_info()
    const data = useEndpoint({
        method: 'POST',
        url: USER_FAVOURITE_VENDOR,
        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
        data: { _id, token }
    })

    return (
        <div className="UFav_root">
            <Headtitle name="Favourite" color="var(--maindark)" />
            {data ? <Favouritevendor {...props} data={data} vt={'Accessories'} /> : null}
        </div>
    )

}

export default UserFavourite