// import React, { useEffect, useState, useRef } from 'react'
// import { INVITATION_GUESTLIST_RSVPANSUPDATE, INVITATION_GUESTLIST_RSVPANSADDNEW } from '../../../../config/api'
// import { RECAPTCHA_KEY, EMPTY, aa_fs, d_fs, FS_LH_MAP, CLOSE_ICON, } from '../../../../config/constants'
// import { Checkdevice } from '../../../../components'
// import ReCAPTCHA from "react-google-recaptcha"
// import axios from 'axios'
// import './RSVP.css'

// function uuidv4() {
//     return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)) // eslint-disable-line
// }

// function RSVP(props) {

//     const { ff, closeRsvp, templatecolor, guestid_guestinfo_map, grpmemberid_guestqnsarr_map, grpmemberid_guestinfo_map } = props

//     const captchaRef = useRef(null) // for captcha
//     const device = Checkdevice()
//     const [qnreplies, setQnreplies] = useState([]) // for RSVP qns
//     const [guestname, setGuestname] = useState(EMPTY) // default 
//     const [guestemail, setGuestemail] = useState(EMPTY)// default 
//     const [guestcontact, setGuestcontact] = useState(EMPTY)// default 
//     const [guestaddress, setGuestaddress] = useState(EMPTY) // default 
//     const [rsvpreply, setRsvpreply] = useState('accept')// default 
//     const [blockinputs, setBlockinputs] = useState(false)
//     const [captchagate, setCaptchagate] = useState(false) // to detect if captchais verified or not 
//     const [captchacodetoken, setCaptchatoken] = useState(EMPTY)// store captcha keys
//     const [rsvpprogressmsg, setRsvpprogressmsg] = useState(EMPTY)

//     const DEVICE_WT_MAP = {
//         'WEB': 700,
//         'TABLET': 700,
//         'MOBILE': '100%'
//     }

//     useEffect(() => {
//         console.log('guestqns jnkjn', guestid_guestinfo_map, grpmemberid_guestqnsarr_map, grpmemberid_guestinfo_map)
//         console.log('guestqnsmap',guestqnsmap)
//         if (props) {
//             var guestqns_len = guestqns.length
//             let arr = new Array(guestqns_len).fill('')
//             setQnreplies(arr)
//         }
//     }, [])

//     useEffect(() => {
//         if (captchagate) {
//             const guestid = props.match.params.guestid
//             const urlhandler = props.match.params.invitationlookup
//             const passcode = props.match.params.passcode ? props.match.params.passcode : ''

//             var qn_replies_map = {}
//             for (let i = 0; i < guestqns.length; i++) {
//                 var qn = guestqns[i][0] // [ qn, qntype, choice1 if is multiple choice qntype else is nothing,......]
//                 qn_replies_map[qn] = qnreplies[i]
//             }

//             qn_replies_map['RSVP'] = rsvpreply
//             qn_replies_map['Updated On'] = new Date().toLocaleString()

//             var API_LOC, data

//             if (!guestid) { //no guestid, need to update name, email, contact, address
//                 var uuid = uuidv4()
//                 qn_replies_map['Name'] = guestname
//                 qn_replies_map['Email'] = guestemail
//                 qn_replies_map['Contact'] = guestcontact
//                 qn_replies_map['Address'] = guestaddress

//                 API_LOC = INVITATION_GUESTLIST_RSVPANSADDNEW
//                 data = { uuid, urlhandler, passcode, qn_replies_map, keys: Object.keys(qn_replies_map), captchacodetoken }
//             }
//             else {
//                 API_LOC = INVITATION_GUESTLIST_RSVPANSUPDATE
//                 data = { urlhandler, passcode, qn_replies_map, keys: Object.keys(qn_replies_map), guestid, captchacodetoken }
//             }

//             try {
//                 let options = {
//                     method: 'POST',
//                     url: API_LOC,
//                     data: data
//                 }
//                 axios(options).then((item) => {
//                     const { message } = item.data
//                     if (message === 'SUCCESS') {
//                         setRsvpprogressmsg('Your RSVP has been sent!')
//                     }
//                     else {

//                     }

//                 }).catch((e) => { })
//             }
//             catch (e) { }

//         }
//     }, [captchagate])

//     const leftbtn = (
//         <div className="Ilookup_closeroot">
//             <button className={'Ilookup_close'} onClick={closeRsvp} >{CLOSE_ICON}</button>
//         </div>
//     )

//     const namediv = <div className="RSVP_rsvpdiv" key={'name___namediv'} style={{ fontSize: d_fs, lineHeight: FS_LH_MAP[d_fs] }}>
//         <div className="RSVP_titletxt">Name</div>
//         <input
//             style={{ height: 32, borderRadius: 5, border: '1px solid #ccc' }}
//             disabled={blockinputs}
//             value={guestname}
//             onChange={(event) => {
//                 if (event.target.value.length < 150) {
//                     setGuestname(event.target.value)
//                 }
//             }}
//         />
//     </div>

//     const detaildiv = <div key={'detaildiv'} style={{ display: 'flex', flexDirection: 'column' }}>
//         <div className="RSVP_rsvpdiv" key={'detaildiv_email'} style={{ fontSize: d_fs, lineHeight: FS_LH_MAP[d_fs] }}>
//             <div className="RSVP_titletxt">Email</div>
//             <input
//                 style={{ height: 32, borderRadius: 5, border: '1px solid #ccc' }}
//                 disabled={blockinputs}
//                 placeholder="Only visible to the host (Optional) "
//                 value={guestemail}
//                 onChange={(event) => {
//                     if (event.target.value.length < 150) {
//                         setGuestemail(event.target.value)
//                     }
//                 }}
//             />
//         </div>

//         <div className="RSVP_rsvpdiv" key={'detaildiv_contact'} style={{ fontSize: d_fs, lineHeight: FS_LH_MAP[d_fs] }}>
//             <div className="RSVP_titletxt">Contact</div>
//             <input
//                 style={{ height: 32, borderRadius: 5, border: '1px solid #ccc' }}
//                 disabled={blockinputs}
//                 placeholder="Only visible to the host (Optional) "
//                 value={guestcontact}
//                 onChange={(event) => {
//                     if (event.target.value.length < 150) {
//                         setGuestcontact(event.target.value)
//                     }
//                 }}
//             />
//         </div>
//         <div className="RSVP_rsvpdiv" key={'detaildiv_address'} style={{ fontSize: d_fs, lineHeight: FS_LH_MAP[d_fs] }}>
//             <div className="RSVP_titletxt">Address</div>
//             <input
//                 style={{ height: 32, borderRadius: 5, border: '1px solid #ccc' }}
//                 disabled={blockinputs}
//                 placeholder="Only visible to the host (Optional) "
//                 value={guestaddress}
//                 onChange={(event) => {
//                     if (event.target.value.length < 150) {
//                         setGuestaddress(event.target.value)
//                     }
//                 }}
//             />
//         </div>
//     </div>

//     const rsvpoptionsdiv = <div className="RSVP_rsvpdiv" key={'rsvpoptionsdiv'} style={{ fontSize: d_fs, lineHeight: FS_LH_MAP[d_fs] }}>
//         <div className="RSVP_titletxt">{'Attending?'}</div>
//         <select
//             disabled={blockinputs}
//             style={{ height: 32, borderRadius: 5, border: '1px solid #ccc', backgroundColor: 'white', padding: '1px 2px' }}
//             onChange={(event) => setRsvpreply(event.target.value)}
//             value={rsvpreply}
//         >
//             <option key={'noreply'} value={''}>{''}</option>
//             <option key={'yesattending'} value={'accept'}>{'accept'}</option>
//             <option key={'noattending'} value={'decline'}>{'decline'}</option>
//         </select>
//     </div>

//     const submitbtn = <button className="RSVP_submitbtn" onClick={() => setBlockinputs(true)}>Submit</button>

//     const captchaChange = (res) => { setCaptchatoken(res); res ? setCaptchagate(true) : setCaptchagate(false) }
//     const captchafield = <ReCAPTCHA
//         ref={(el) => { captchaRef.current = el }}
//         sitekey={RECAPTCHA_KEY}
//         onChange={captchaChange}
//     />

//     const rsvpprogressmsgdiv = <div style={{ display: 'flex', flexDirection: 'row', padding: 5, margin: 5, backgroundColor: 'white' }}> {rsvpprogressmsg}</div>

//     const guestid = props.match.params.guestid

//     return (
//         <div className="RSVP_root" style={{ backgroundColor: templatecolor }}>
//             {leftbtn}
//             <div className="RSVP_subroot" style={{ width: DEVICE_WT_MAP[device] }}>
//                 <div className="RSVP_largetitle" style={{ fontFamily: ff, fontSize: aa_fs }}>RSVP</div>
//                 {guestid ? rsvpoptionsdiv : [namediv, rsvpoptionsdiv, detaildiv]}
//                 {guestqns.map((item, index) => {
//                     var qn = item[0]
//                     var qntype = item[1] // 0 is multiple choice, 1 is freetext

//                     var qn_dom
//                     if (qntype === 0) { // multiple choice
//                         var choices = [<option key={'EMPTYOPTION'} value={''}>{''}</option>] // first option is always empty
//                         for (let i = 2; i < item.length; i++) choices.push(<option key={item[i] + i} value={item[i]}>{item[i]}</option>)
//                         qn_dom = (
//                             <select
//                                 className="RSVP_qnselect"
//                                 disabled={blockinputs}
//                                 onChange={(event) => {
//                                     var temp = qnreplies.slice()
//                                     temp[index] = event.target.value
//                                     setQnreplies(temp)
//                                 }}
//                                 value={qnreplies[index]}
//                             >
//                                 {choices}
//                             </select>
//                         )

//                     }
//                     else if (qntype === 1) {
//                         qn_dom = (
//                             <input
//                                 className="RSVP_input"
//                                 disabled={blockinputs}
//                                 placeholder="Only visible to the host (Optional) "
//                                 value={qnreplies[index]}
//                                 onChange={(event) => {
//                                     if (event.target.value.length < 150) {
//                                         var temp = qnreplies.slice()
//                                         temp[index] = event.target.value
//                                         setQnreplies(temp)
//                                     }
//                                 }}
//                             />
//                         )
//                     }

//                     return (
//                         <div className="RSVP_rsvpdiv" key={index + qn + 'qn' + index} style={{ fontSize: d_fs, lineHeight: FS_LH_MAP[d_fs] }}>
//                             <div className="RSVP_titletxt">{qn}</div>
//                             <div>{qn_dom}</div>
//                         </div>
//                     )

//                 })}
//                 <div className="RSVP_captcha">
//                     {blockinputs
//                         ? captchagate
//                             ? rsvpprogressmsgdiv
//                             : captchafield
//                         : submitbtn}
//                 </div>
//             </div>
//         </div>
//     )

// }

// export default RSVP


import React, { useEffect, useState, useRef } from 'react'
import { retrieveDateobj, captalizeFirst } from '../../../../config/commonfunctions'
import { INVITATION_GUESTLIST_RSVPANSUPDATE, INVITATION_GUESTLIST_RSVPANSADDNEW, INVITATION_GUESTLIST_UDPATEEXISTINGRSVP } from '../../../../config/api'
import { RECAPTCHA_KEY, EMPTY, CLOSE_ICON, RSVP_DEFAULT_QNS_WEDDING, RSVP_DEFAULT_QNS_EVENT, cryptobool, CRYPTOQN } from '../../../../config/constants'
import ReCAPTCHA from 'react-google-recaptcha'
import axios from 'axios'
import { RSVP_multiplechoice_dom, RSVP_textarea_dom } from '../../../Main/Planning/Invitationmaker/components/Commonelements/Commonelements'
import './RSVP.css'
import Circularclosebtn from '../../../../basic_components/Button/Circularclosebtn/Circularclosebtn'

const RSVPTXTPASTTENSE_MAP = {
    'accept': 'Accepted',
    'decline': 'Declined',
    '': ''
}

const vb_ = "0 0 1024 1024"
const v_ = "1.1"
const x_ = "http://www.w3.org/2000/svg"
const icon_css = { width: 25, height: 25, margin: '0px 5px', userSelect: 'none', pointerEvents: 'none', verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden' }
const backicon = <svg style={icon_css} viewBox={vb_} version={v_} xmlns={x_}><path d="M598.656 681.344a32 32 0 0 1-45.312 45.312l-192-192a32 32 0 0 1 0-45.312l192-192a32 32 0 1 1 45.312 45.312L429.248 512l169.408 169.344zM512 1024A512 512 0 1 1 512 0a512 512 0 0 1 0 1024z m0-64A448 448 0 1 0 512 64a448 448 0 0 0 0 896z" p-id="15130"></path></svg>

const TIMER_CONSTANT = 1000

function Qnlist(props) {

    const { fc, templatecolor, blockinputs, selectedguestid_defaultqnstate_map } = props

    const [qns, setQns] = useState([])
    const [ans, setAns] = useState({})
    const [msgs, setMsgs] = useState({})
    const [id_qnobj_map, setId_qnobj_map] = useState({})
    const [qnsdommap, setQnsdommap] = useState({})
    const [viewableids, setViewableids] = useState([])
    const [firsttime, setFirsttime] = useState(true)
    const [submitclicked, setSubmitclicked] = useState(false)
    const [showbtn, setShowbtn] = useState('CONTINUE') // CONTINUE, SUBMIT, NONE
    const [btncontinue, setBtncontinue] = useState(false)

    // for text only
    //     [ 
    //      0 - 'How many pax',
    //      1 - 1 (0 - multiple choices, 1 - text),
    //      2 - rename of 'How many pax' for column name, 
    //      3 - allorcatind,
    //      4 - [ tag1, tag2, ... ], // user with tag can view this qn
    //      5 - [ guestid1, guestid2, ... ], // specific user can view this qn
    //      6 - [], maybe needed in the future
    //      7 - 'makdsnmfkjasndfkjsnfkjnfdkj123' id of qn,
    //     ],

    //     for question with follow up questions NOTE, followup qn consist of uid within it
    //     [ 
    //      0 - 'How many pax', 
    //      1 - 0(0 - multiple choices, 1 - text),
    //      2 - rename of 'How many pax' for column name, 
    //      3 - followupqn id
    //      4 - allorcatind (all, catind. can see this qn),
    //      5 - [ tag1, tag2, ... ],  // user with tag can view this qn
    //      6 - [ guestid1, guestid2, ... ], // specific user can view this qn
    //      7 - 'makdsnmfkjasndfkjsnfkjnfdkj123' id of qn,
    //      8 - [ 
    //              [
    //                  option1,
    //                  [ // **** followup qn consist of uid within it
    //                      [question text,  followup qntype, column name, uuid of followup qn ] 】
    //                  ]
    //              [
    //                  option2,
    //                  [
    //                      [question text1,  followup qntype, column name1, uuid of followup qn, option A, option B,  ],
    //                      [question text2,  followup qntype, column name2, uuid of followup qn, option A, option B,  ] 
    //                  ]
    //              ],
    //              [option3, []] 
    //          ]
    //     ],       
    // ]

    const initialize = () => {
        // const { qns, ans, msgs, } = props // fc, templatecolor 
        var local_ans = Object.assign({}, props.ans)
        local_ans['8-rsvp'] = {
            'accept': 'Accept',
            'Accept': 'Accept',
            'decline': 'Decline',
            'Decline': 'Decline',
            '': ''
        }[local_ans['8-rsvp']]
        setAns(local_ans)
        setQns(props.qns.slice())
        setMsgs(Object.assign({}, props.msgs))
    }

    useEffect(() => {
        if (firsttime &&
            props.qns &&
            props.ans && Object.keys(props.ans).length > 0 &&
            props.msgs && Object.keys(props.msgs).length > 0) {
            initialize()
            setFirsttime(false)
        }

        if (submitclicked) {
            initialize()
            setSubmitclicked(false)
        }
    }, [props])

    useEffect(() => {

        if (qns.length > 0 && Object.keys(ans).length > 0 && Object.keys(msgs).length > 0) {

            const checkEmpty = (ans, viewableids) => {

                var count = 0
                for (let i = 0; i < viewableids.length; i++) {
                    var local_id = viewableids[i]
                    const { qntype } = id_qnobj_map[local_id]
                    const value = ans[local_id]
                    if (qntype === 0 && value === '') { // mcq dont allow to leave empty
                        count += 1
                    }
                }
                if (count > 0) {
                    return false
                }
                else if (count === 0) {
                    return true
                }

            }

            const checkComplete = (checkstate, viewableids) => { // will update showbtn
                if (checkstate) { // already check, can continue
                    var lastid = viewableids[viewableids.length - 1]
                    const { main_nextid } = id_qnobj_map[lastid]
                    if (main_nextid === -1) {
                        return 'SUBMIT'
                    }
                    else {
                        return 'CONTINUE'
                    }
                }
                else {
                    return 'NONE'
                }

            }

            const mainTxtchange = (e) => {
                const { id } = e.target;
                let value = e.target.value
                ans[id] = value
                setAns({ ...ans })
                setViewableids((viewableids) => {
                    var checkstate = checkEmpty(ans, viewableids)
                    var btnstate = checkComplete(checkstate, viewableids)

                    // check text qns if has any error
                    var gterror = Object.values(msgs).filter((msg) => msg.length > 0 ? true : false)
                    if (gterror.length > 0) { btnstate = 'NONE' }

                    setShowbtn(btnstate)
                    return viewableids
                })

                setMsgs((msg) => {
                    msg[id] = value.length > 1000 ? 'Text exceeded maximum number of characters.' : ''
                    return msg
                })

            }

            const followupMcqchange = (e) => {
                const { id } = e.target;
                let value = e.target.value
                ans[id] = value
                setAns({ ...ans })
                setViewableids((viewableids) => {
                    var checkstate = checkEmpty(ans, viewableids)
                    var btnstate = checkComplete(checkstate, viewableids)
                    setShowbtn(btnstate)
                    return viewableids
                })
            }

            const followupTxtchange = (e) => {
                const { id } = e.target;
                let value = e.target.value
                ans[id] = value
                setAns({ ...ans })
                setViewableids((viewableids) => {
                    var checkstate = checkEmpty(ans, viewableids)
                    var btnstate = checkComplete(checkstate, viewableids)

                    // check text qns if has any error
                    var gterror = Object.values(msgs).filter((msg) => msg.length > 0 ? true : false)
                    if (gterror.length > 0) { btnstate = 'NONE' }

                    setShowbtn(btnstate)
                    return viewableids
                })


                setMsgs((msg) => {
                    msg[id] = value.length > 1000 ? 'Text exceeded maximum number of characters.' : ''
                    return msg
                })

            }

            let qnsdommap = {}
            let id_qnobj_map = {}
            for (let i = 0; i < qns.length; i++) { // loop all qns and create qnsdom_map

                const title = qns[i][0]
                const qntype = qns[i][1]
                const id = qns[i][7]

                // courage

                // const allqns = Object.keys(selectedguestid_defaultqnstate_map)
                // if(allqns.includes(id) && !selectedguestid_defaultqnstate_map[id].editable){ // is part of default qn AND is uneditable
                //     continue
                // }

                if (qntype === 0) { // multiple choice qn

                    let options = []
                    let optionsarr = qns[i][8]
                    let options_ids_map = {}

                    for (let j = 0; j < optionsarr.length; j++) {

                        options.push(optionsarr[j][0])
                        options_ids_map[optionsarr[j][0]] = []

                        for (let k = 0; k < optionsarr[j][1].length; k++) { // loop all followup qn

                            var followupqn = optionsarr[j][1][k].slice()
                            var followupqntitle = followupqn[0]
                            var followupqntype = followupqn[1]
                            var followupqnid = followupqn[3]
                            var followupindex = (i + 1) + '-' + (k + 1)
                            options_ids_map[optionsarr[j][0]].push(followupqnid)

                            if (followupqntype === 0) { // multiple choice followup qn

                                const followupoptions = followupqn.splice(4)

                                qnsdommap[followupqnid] = <div className='RSVP_followupqn' key={followupqnid + '_input'} id={followupqnid} style={{ borderLeft: `3px solid ${fc}` }}>
                                    {RSVP_multiplechoice_dom(followupindex, followupqntitle, followupoptions, followupMcqchange, followupqnid, msgs, ans[followupqnid], blockinputs, fc, templatecolor)}
                                </div>
                                id_qnobj_map[followupqnid] = {
                                    qntype: followupqntype,
                                    mainorfollowup: 'followup',
                                    main_nextid: qns[i + 1] ? qns[i + 1][7] : -1,
                                    main_id: id
                                }
                            }
                            else {

                                qnsdommap[followupqnid] = <div className='RSVP_followupqn' key={followupqnid + '_input'} id={followupqnid} style={{ borderLeft: `3px solid ${fc}` }} >
                                    {RSVP_textarea_dom(followupindex, followupqntitle, '', ans[followupqnid], followupTxtchange, followupqnid, msgs, blockinputs, fc)}
                                </div>

                                id_qnobj_map[followupqnid] = {
                                    qntype: followupqntype,
                                    mainorfollowup: 'followup',
                                    main_nextid: qns[i + 1] ? qns[i + 1][7] : -1,
                                    main_id: id
                                }
                            }

                        }

                    }

                    const mainMcqchange = (e) => {

                        const { id } = e.target;
                        let value = e.target.value
                        setViewableids((viewableids) => {
                            var new_viewableids = viewableids.slice()

                            // if id is rsvp and value is decline
                            // need to remove any other qns that is shown after rsvp qn 
                            // den show only submit btn 

                            ans[id] = value
                            setAns({ ...ans })

                            if (id === '8-rsvp' && value !== '' && value.toLowerCase() === 'decline') {
                                new_viewableids.splice(new_viewableids.indexOf('8-rsvp') + 1, new_viewableids.length - 1)
                                return new_viewableids
                            }
                            else {
                                let newids = options_ids_map[value]
                                let alloptionids = Object.values(options_ids_map).flat()

                                for (let x = 0; x < alloptionids.length; x++) {
                                    const index_ = new_viewableids.indexOf(alloptionids[x])
                                    if (index_ > -1) {
                                        new_viewableids.splice(index_, 1)
                                    }
                                }

                                if (newids && newids.length > 0) {
                                    var currindex = new_viewableids.indexOf(id)
                                    new_viewableids.splice(currindex + 1, 0, ...newids)
                                }

                                var checkstate = checkEmpty(ans, new_viewableids)
                                var btnstate = checkComplete(checkstate, new_viewableids)

                                setShowbtn(btnstate)

                                return new_viewableids
                            }

                        })

                    }

                    qnsdommap[id] = RSVP_multiplechoice_dom(i + 1, title, options, mainMcqchange, id, msgs, ans[id], blockinputs, fc, templatecolor)

                    id_qnobj_map[id] = {
                        qntype: qntype,
                        mainorfollowup: 'main',
                        main_nextid: qns[i + 1] ? qns[i + 1][7] : -1,
                        options_ids_map: options_ids_map,
                        main_id: null
                    }

                }
                else { // txt open - ended qn
                    qnsdommap[id] = RSVP_textarea_dom(i + 1, title, '', ans[id], mainTxtchange, id, msgs, blockinputs, fc)
                    id_qnobj_map[id] = {
                        qntype: qntype,
                        mainorfollowup: 'main',
                        main_nextid: qns[i + 1] ? qns[i + 1][7] : -1,
                        options_ids_map: null,
                        main_id: null
                    }
                }

            }

            setQnsdommap(qnsdommap)
            setId_qnobj_map(id_qnobj_map)
        }

    }, [qns, ans, msgs])

    useEffect(() => {
        if (Object.keys(qnsdommap).length > 0 && viewableids.length === 0) {
            const FIXED_COLUMNS_ID = cryptobool
                ? ['1-name', '2-email', '3-contact', '4-address', '7-nftwalletaddress']
                : ['1-name', '2-email', '3-contact', '4-address'] // without '0-uid',
            var gtstartingid
            for (let i = 0; i < FIXED_COLUMNS_ID.length; i++) {
                var startingid = FIXED_COLUMNS_ID[i]
                if (selectedguestid_defaultqnstate_map[startingid].editable) {
                    setViewableids([startingid])
                    gtstartingid = true
                    break
                }
            }

            if (!gtstartingid) { // if no startingid
                setViewableids(['8-rsvp'])
            }

            // setViewableids(['1-name'])
        }
    }, [qnsdommap])

    useEffect(() => {

        if (viewableids.length > 0) { // all mcq qn need to be selected in order to proceed
            const lastid = viewableids[viewableids.length - 1]
            const lastid_main_nextid = id_qnobj_map[lastid].main_nextid
            let mcqempty = 0
            for (let i = 0; i < viewableids.length; i++) {
                const id = viewableids[i]
                const { qntype } = id_qnobj_map[id]
                const local_ans = ans[id]
                if (qntype === 0 && local_ans === '') { // multiple choice and is unselected 
                    mcqempty += 1
                }
            }

            if (mcqempty > 0) {
                setShowbtn('NONE')
            }
            else if (mcqempty === 0) {
                if (lastid_main_nextid === -1) {
                    setShowbtn('SUBMIT')
                }
                else {
                    if (lastid === '8-rsvp' && ans['8-rsvp'] !== '' && ans['8-rsvp'].toLowerCase() === 'decline') {
                        setShowbtn('SUBMIT')
                    }
                    else {
                        setShowbtn('CONTINUE')
                    }
                }
            }

        }

        if (viewableids.length === 1 && viewableids[0] === '1-name') {
            setShowbtn('CONTINUE')
        }

    }, [viewableids])

    useEffect(() => {
        if (btncontinue) {
            setBtncontinue((btncontinue) => {
                window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" })
                return !btncontinue
            })
        }
    }, [btncontinue])

    return <div className='RSVP_qnlistroot'>

        {viewableids && viewableids.map((qnkey) => {
            return qnsdommap[qnkey]
        })}

        <div className='RSVP_nextroot'>
            {{
                'CONTINUE': <button
                    className='RSVP_next'
                    style={{ backgroundColor: templatecolor, color: fc, border: `1px solid ${fc}` }}
                    onClick={() => {

                        const qn = qnsdommap[viewableids[viewableids.length - 1]] // latest qn

                        const { id } = qn.props // obtain latest qn id

                        if (true) { // conduct check

                            const { main_nextid } = id_qnobj_map[id]

                            if (main_nextid !== -1) {

                                setViewableids([...viewableids, main_nextid])

                                // check if next id is multiple choice or not
                                // if default ans is selected and has multiple choice
                                // add followup qn to viewableids
                                if (id_qnobj_map[main_nextid].qntype === 0) { // multiplechoice qn

                                    const ans_from_nextid = ans[main_nextid]
                                    const { options_ids_map } = id_qnobj_map[main_nextid]

                                    if (options_ids_map) {

                                        setViewableids((viewableids) => {

                                            var new_viewableids = viewableids.slice()
                                            let newids = options_ids_map[ans_from_nextid]
                                            let alloptionids = Object.values(options_ids_map)

                                            for (let x = 0; x < alloptionids.length; x++) {
                                                const index_ = new_viewableids.indexOf(alloptionids[x])
                                                if (index_ > -1) {
                                                    new_viewableids.splice(index_, 1)
                                                }
                                            }

                                            if (newids && newids.length > 0) {
                                                var currindex = new_viewableids.indexOf(main_nextid)
                                                new_viewableids.splice(currindex + 1, 0, ...newids)
                                            }

                                            return new_viewableids

                                        })

                                    }

                                }

                            }
                            else if (main_nextid === -1) { // already end
                                setShowbtn('SUBMIT')
                            }

                            setBtncontinue(true)

                        }
                    }}
                >
                    Continue
                </button>,
                'SUBMIT': !blockinputs
                    ? <button
                        className='RSVP_next'
                        style={{ backgroundColor: templatecolor, color: fc, border: `1px solid ${fc}` }}
                        onClick={() => {
                            var emptymcqcounter = 0
                            for (let i = 0; i < viewableids.length; i++) {
                                const id = viewableids[i]
                                const { qntype } = id_qnobj_map[id]
                                if (qntype === 0 && ans[id].length === 0) { emptymcqcounter += 1 }
                            }
                            if (emptymcqcounter === 0) {
                                props.submit(ans, viewableids)
                                setSubmitclicked(true)
                            }
                        }}
                    >
                        Submit
                    </button>
                    : null
            }[showbtn]}
        </div>
    </div>

}

function RSVP(props) {

    const { name1, name2, date, time, location, itype, priff, secff, fc, closeRsvp, templatecolor, grpleader, grpmemberid_guestqnsarr_map, grpmemberid_guestinfo_map, updateGrpmemberid_guestinfo_map, currguestid_guestinfo_map, updateCurrguestid_guestinfo_map, rsvp_obj, guestid, guestid_defaultqnstate_map } = props

    const [page, setPage] = useState('MENU') // MENU, LOADING-MENU, LOADING-ANSWERING, ANSWERING
    const [selectedguestid, setSelectedguestid] = useState(EMPTY) // guest id that is currently selected
    const [selectedguesttype, setSelectedguesttype] = useState(EMPTY) // GROUPLEADER, GROUPMEMBER
    const [qns, setQns] = useState([])
    const [ans, setAns] = useState({})
    const [msgs, setMsgs] = useState({})
    const [blockinputs, setBlockinputs] = useState(false)
    const [captchagate, setCaptchagate] = useState(false) // to detect if captchas verified or not
    const [captchacodetoken, setCaptchatoken] = useState(EMPTY)// store captcha keys
    const [newviewableids, setNewviewableids] = useState([]) // hold states from Qnlist

    const [firsttime, setFirsttime] = useState(true)

    const captchaRef = useRef(null) // for captcha

    const clear = () => {
        setSelectedguestid('')
    }

    useEffect(() => {
        if (firsttime) {
            setSelectedguestid('')
            setSelectedguesttype('')
            setQns([])
            setAns({})
            setMsgs({})
            setFirsttime(false)
        }
    }, [])

    useEffect(() => {

        if (captchagate) {

            var urlhandler = props.match.params.invitationlookup.toLowerCase()
            var passcode = props.match.params.passcode
            var guestuid = props.match.params.guestuid

            let new_ans = {}

            for (let i = 0; i < newviewableids.length; i++) {
                const viewableid = newviewableids[i]
                if (viewableid === '8-rsvp') {
                    new_ans[viewableid] = ans[viewableid].toLowerCase()
                }
                else {
                    new_ans[viewableid] = ans[viewableid]
                }

            }

            let new_ans_keys = Object.keys(new_ans)

            try {
                let options = {
                    method: 'POST',
                    url: INVITATION_GUESTLIST_UDPATEEXISTINGRSVP,
                    data: { urlhandler, passcode, guestuid, captchacodetoken, selectedguestid, new_ans, old_rsvp_status: currguestid_guestinfo_map['8-rsvp'] }
                }
                axios(options).then((item) => {

                    const { message } = item.data

                    if (message === 'SUCCESS') {
                        setPage('DONE-UPDATING')
                        var guestinfo_map = {}
                        if (selectedguestid === props.guestid) {
                            guestinfo_map = Object.assign({}, currguestid_guestinfo_map)
                            for (let i = 0; i < new_ans_keys.length; i++) {
                                const key = new_ans_keys[i]
                                guestinfo_map[key] = new_ans[key]
                            }
                            updateCurrguestid_guestinfo_map(guestinfo_map)
                            setAns(new_ans)
                        }
                        else {
                            guestinfo_map = Object.assign({}, grpmemberid_guestinfo_map[selectedguestid])
                            for (let i = 0; i < new_ans_keys.length; i++) {
                                const key = new_ans_keys[i]
                                guestinfo_map[key] = new_ans[key]
                            }
                            updateGrpmemberid_guestinfo_map(selectedguestid, guestinfo_map)
                            setAns(new_ans)
                        }
                    }
                    else {
                        alert('error')
                    }
                }).catch((e) => { })
            }
            catch (e) { }
        }

    }, [captchagate])

    useEffect(() => {
        if (page === 'MENU') {
            clear()
        }
        else if (page === 'LOADING-MENU') {
            clear()
            setTimeout(() => {
                setPage('MENU')
            }, TIMER_CONSTANT)
        }
        else if (page === 'LOADING-ANSWERING') {
            setTimeout(() => {
                setPage('ANSWERING');
            }, TIMER_CONSTANT)
        }
        else if (page === 'ANSWERING') {

            function genqn(qnsequence_arr, ans_map) {
                // qnsequence_arr - qns in sequence that this current user can answer
                // ans_map - id pointing to qn's answer
                let RSVP_DEFAULT_QNS = itype === 'Wedding' ? RSVP_DEFAULT_QNS_WEDDING : RSVP_DEFAULT_QNS_EVENT

                function defaultqns(ans_map) {
                    // brave
                    for (let i = 0; i < RSVP_DEFAULT_QNS.length; i++) {
                        const key = RSVP_DEFAULT_QNS[i][7]
                        if (guestid_defaultqnstate_map[selectedguestid].hasOwnProperty(key)) {
                            const { editable, choice } = guestid_defaultqnstate_map[selectedguestid][key] // editable : boolean, choice : OPTIONAL, MANDATORY
                            if (!editable) {
                                RSVP_DEFAULT_QNS.splice(i, 1)
                                i--;
                            }
                        }
                    }

                    var walletstate = ans_map['7-nftwalletaddress'] // need to check if wallet is disabled (-1)
                    if (walletstate === -1 || !cryptobool) {
                        for (let i = 0; i < RSVP_DEFAULT_QNS.length; i++) {
                            if (RSVP_DEFAULT_QNS[i][0] === CRYPTOQN) {
                                RSVP_DEFAULT_QNS.splice(i, 1)
                            }
                        }
                    }
                    return RSVP_DEFAULT_QNS
                }

                // add default qn to the front
                var local_qnsequence_arr = [...defaultqns(ans_map), ...qnsequence_arr.slice()]

                // form msgs
                var msgs = {}
                var qnids = Object.keys(ans_map)
                for (let i = 0; i < qnids.length; i++) {
                    msgs[qnids[i]] = ''
                }

                // update states
                setQns(local_qnsequence_arr)
                setAns(ans_map)
                setMsgs(msgs)

            }

            if (selectedguesttype === 'GROUPLEADER') {
                // form qn from qn qnsequence_arr
                genqn(rsvp_obj, currguestid_guestinfo_map)
            }
            else if (selectedguesttype === 'GROUPMEMBER') { // GROUPMEMBER
                // form qn from qn qnsequence_arr
                genqn(grpmemberid_guestqnsarr_map[selectedguestid], grpmemberid_guestinfo_map[selectedguestid])
            }

        }
    }, [page, selectedguestid, selectedguesttype, guestid_defaultqnstate_map])

    const title_dom = (
        <div className='RSVP_titleroot' style={{ fontFamily: secff, color: fc }}>RSVP</div>
    )

    const detail_dom = (
        itype === 'Wedding'
            ? <div className='RSVP_detailroot' style={{ color: fc }}>
                <div className='Rsvp_detailcomp' style={{ paddingBottom: 25 }}>{name1 + ' & ' + name2 + ' invite you to their Wedding.'}</div>
                <div className='Rsvp_detailcomp' style={{ paddingTop: 0 }}>{retrieveDateobj(date).date}</div>
                <div className='Rsvp_detailcomp'>{time}</div>
                <div className='Rsvp_detailcomp' style={{ marginBottom: 40 }}>{location}</div>
            </div>
            : null
    )

    const respondgroupmember = (e) => {
        setAns(grpmemberid_guestinfo_map[e.target.value])
        setSelectedguestid(e.target.value)
        setSelectedguesttype('GROUPMEMBER')
        setPage('LOADING-ANSWERING')
    }

    const respondgroupleader = (e) => {
        setAns(currguestid_guestinfo_map)
        setSelectedguestid(e.target.value)
        setSelectedguesttype('GROUPLEADER')
        setPage('LOADING-ANSWERING')
    }

    const getLatestrsvpstate = () => {
        return currguestid_guestinfo_map['8-rsvp'].length > 0
            ? <div className='RSVP_grpleadercomprespondinner' style={{ fontSize: 14 }}>
                {'( ' + RSVPTXTPASTTENSE_MAP[currguestid_guestinfo_map['8-rsvp']] + ' )'}
            </div>
            : null
    }

    const returntomenu = () => {
        setPage('LOADING-MENU');
        setBlockinputs(false);
        setCaptchagate(false);
        setCaptchatoken(EMPTY);
    }

    const submit = (ans, viewableids) => {
        setAns(ans)
        setNewviewableids(viewableids)
        setBlockinputs(true)
    }

    const seperator = Object.keys(grpmemberid_guestinfo_map).length > 0 ? <div className='RSVP_grpleadercompunderline' style={{ backgroundColor: fc }} /> : null

    const selfinfo_dom = (
        <div className='RSVP_grpleaderself'>
            <div className='RSVP_grpleadercomp'>
                <div className='RSVP_grpleadercomprow' >
                    <div className='RSVP_grpleadercompname' style={{ color: fc }}>{currguestid_guestinfo_map['1-name']}</div>
                    <button className='RSVP_grpleadercomprespond' style={{ color: fc, backgroundColor: templatecolor, border: `1px solid ${fc}` }} value={guestid} onClick={respondgroupleader}>
                        <div className='RSVP_grpleadercomprespondinner' style={{ fontSize: 16 }}>Respond</div>
                        {getLatestrsvpstate()}
                        {/* {currguestid_guestinfo_map['8-rsvp'].length > 0 ? <div className='RSVP_grpleadercomprespondinner' style={{ fontSize: 12 }}>{'( ' + RSVPTXTPASTTENSE_MAP[currguestid_guestinfo_map['8-rsvp']] + ' )'}</div> : null} */}
                    </button>
                </div>
                {seperator}
            </div>
        </div>
    )

    const grpleaderinfo_dom = (
        <div className='RSVP_contentroot'>
            {selfinfo_dom}
            {Object.keys(grpmemberid_guestinfo_map).length > 0 ? <div className='RSVP_grpleadertitle' style={{ color: fc, borderBottom: `1px solid ${fc}` }}>RSVP on behalf</div> : null}
            <div className='RSVP_grpleaderothers'>
                {Object.keys(grpmemberid_guestinfo_map).map((grpmemberid, index) => {
                    return <div className='RSVP_grpleadercomp' key={'RSVP_grpmember' + index} >
                        <div className='RSVP_grpleadercomprow'>
                            <div className='RSVP_grpleadercompname' style={{ color: fc }}>{grpmemberid_guestinfo_map[grpmemberid]['1-name']}</div>
                            <button className='RSVP_grpleadercomprespond' style={{ color: fc, backgroundColor: templatecolor, border: `1px solid ${fc}` }} value={grpmemberid} onClick={respondgroupmember}>
                                <div className='RSVP_grpleadercomprespondinner' style={{ fontSize: 16 }}>Respond</div>

                                {/* 
                                    {grpmemberid_guestinfo_map[grpmemberid]['8-rsvp'].length > 0
                                        ? <div className='RSVP_grpleadercomprespondinner' style={{ fontSize: 12 }}>
                                            {'( ' + RSVPTXTPASTTENSE_MAP[grpmemberid_guestinfo_map[grpmemberid]['8-rsvp']] + ' )'}
                                        </div>
                                        : null}
                                */}

                                {grpmemberid_guestinfo_map[grpmemberid]['8-rsvp'].length > 0
                                    ? <div className='RSVP_grpleadercomprespondinner' style={{ fontSize: 14 }}>
                                        {'( ' + RSVPTXTPASTTENSE_MAP[grpmemberid_guestinfo_map[grpmemberid]['8-rsvp']] + ' )'}
                                    </div>
                                    : null}

                                {/* 
                                    {'( ' + RSVPTXTPASTTENSE_MAP[grpmemberid_guestinfo_map[grpmemberid]['8-rsvp']] + ' )'} 
                                */}

                            </button>
                        </div>
                        {seperator}
                    </div>
                })}
            </div>
        </div>
    )

    const noleaderinfo_dom = (
        <div className='RSVP_contentroot'>
            {selfinfo_dom}
        </div>
    )

    const menu_btn = (
        <div className='RSVP_contentroot' style={{ padding: '5px 10px 25px 20px', display: 'flex', placeContent: 'flex-end', overflowY: 'hidden' }}>
            <button className='RSVP_menubtn' style={{ color: fc, backgroundColor: templatecolor, visibility: page === 'DONE-UPDATING' ? 'hidden' : '' }} onClick={returntomenu}>
                <div className='RSVP_arrow' style={{ fill: fc }}>{backicon}</div>
                <div className='RSVP_menutxt'>{' Menu'}</div>
            </button>
        </div>
    )

    return <div className="RSVP_root" style={{ backgroundColor: templatecolor, fontFamily: priff }}>
        {page === 'MENU' ? <Circularclosebtn fc={fc} bgc={templatecolor} clickfunc={closeRsvp} /> : null}
        {{
            'MENU': <div className="RSVP_main">
                {title_dom}
                {detail_dom}
                {grpleader ? grpleaderinfo_dom : noleaderinfo_dom}
            </div>,
            'ANSWERING': <div key={page} className="RSVP_main">
                {title_dom}
                {menu_btn}
                <Qnlist
                    selectedguestid_defaultqnstate_map={guestid_defaultqnstate_map[selectedguestid]}
                    selectedguestid={selectedguestid}
                    qns={qns}
                    ans={ans}
                    msgs={msgs}
                    fc={fc}
                    templatecolor={templatecolor}
                    blockinputs={blockinputs}
                    submit={submit}
                />
                <div className='RSVP_qnlistroot RSVP_recaptcha'>
                    {blockinputs
                        ? <ReCAPTCHA
                            // ref={(el) => { captchaRef.current = el }}
                            ref={captchaRef}
                            sitekey={RECAPTCHA_KEY}
                            onChange={(res) => {
                                console.log('captchaRef', captchaRef)
                                setCaptchatoken(res);
                                res ? setCaptchagate(true) : setCaptchagate(false)
                            }}
                        />
                        : null}
                </div>
            </div>,
            'LOADING-ANSWERING': <div className="RSVP_main">
                {title_dom}
                <div className='RSVP_loading'>Loading...</div>
            </div>,
            'LOADING-MENU': <div className="RSVP_main">
                {title_dom}
                <div className='RSVP_loading'>Returning to menu.</div>
            </div>,
            'DONE-UPDATING': <div className="RSVP_main">
                {title_dom}
                {menu_btn}
                <div className='RSVP_loading' style={{ width: 215 }} >
                    <div style={{ width: '100%', textAlign: 'center' }}>Your RSVP have been updated.</div>
                    <button
                        className='RSVP_finalreturnmenu'
                        style={{ backgroundColor: templatecolor, color: fc, border: `1px solid ${fc}`, width: '100%' }}
                        onClick={returntomenu} >
                        Menu
                    </button>
                </div>
            </div>,
        }[page]}
    </div>
}

export default RSVP