import React from 'react'
// import React, { useState } from 'react'
// import { EMPTYSPACE } from '../../config/constants'
// import { BUTTONSTYLE } from '../../config/cssconstants'
// import { Checkdevice, ScreenWidth } from '../../components'
// import { useEffect } from 'react'

// function Coronavirus() { // eslint-disable-line

//     const keys = ['Gender', 'Age Group', 'Name of hospital admitted', '(imported/local)', 'SG/Non-SG resident', 'Hospitalised/Discharged/Deceased', 'Group', 'Date of confirmation', 'Date of discharged']
//     const new_coronavirus = [
//         {
//             'Gender': 'Male',
//             'Age Group': '60 or above',
//             'Name of hospital admitted': 'Singapore General Hospital',
//             '(imported/local)': 'imported',
//             'SG/Non-SG resident': 'Non-SG resident',
//             'Hospitalised/Discharged/Deceased': 'Hospitalised',
//             'Group': 'Chinese Nationals',
//             'Date of confirmation': '23/01/2020',
//             'Age': '66',
//             "Date of discharged": "Hospitalised",
//             "Case": '1',
//         },
//         {
//             'Gender': 'Female',
//             'Age Group': '33-59',
//             'Name of hospital admitted': 'Tan Tock Seng Hospital',
//             '(imported/local)': 'imported',
//             'SG/Non-SG resident': 'Non-SG resident',
//             'Hospitalised/Discharged/Deceased': 'Discharged',
//             'Group': 'Chinese Nationals',
//             'Date of confirmation': '24/01/2020',
//             'Age': '53',
//             "Date of discharged": "07/02/2020",
//             "Case": '2',
//         },
//         {
//             "Gender": "Male",
//             "Age Group": '33-59',
//             "Name of hospital admitted": 'Singapore General Hospital',
//             "(imported/local)": 'imported',
//             "SG/Non-SG resident": 'Non-SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": "Chinese Nationals",
//             "Date of confirmation": '24/01/2020',
//             "Age": '37',
//             "Date of discharged": "Hospitalised",
//             "Case": '3'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": "Sengkang General Hospital",
//             "(imported/local)": "imported",
//             "SG/Non-SG resident": "Non-SG resident",
//             "Hospitalised/Discharged/Deceased": 'Discharged',
//             "Group": "Chinese Nationals",
//             "Date of confirmation": "25/01/2020",
//             "Age": "36",
//             "Date of discharged": "12/02/2020",
//             "Case": "4"
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "33-59",
//             "Name of hospital admitted": "Tan Tock Seng Hospital",
//             "(imported/local)": "imported",
//             "SG/Non-SG resident": "Non-SG resident",
//             "Hospitalised/Discharged/Deceased": "Hospitalised",
//             "Group": "Chinese Nationals",
//             "Date of confirmation": "24/01/2020",
//             "Age": "56",
//             "Date of discharged": "Hospitalised",
//             "Case": "5"
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": "Changi General Hospital",
//             "(imported/local)": "imported",
//             "SG/Non-SG resident": "Non-SG resident",
//             "Hospitalised/Discharged/Deceased": "Hospitalised",
//             "Group": "Chinese Nationals",
//             "Date of confirmation": "26/01/2020",
//             "Age": "56",
//             "Date of discharged": "Hospitalised",
//             "Case": "6",
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": "NCID",
//             "(imported/local)": "imported",
//             "SG/Non-SG resident": "Non-SG resident",
//             "Hospitalised/Discharged/Deceased": 'Discharged',
//             "Group": "Chinese Nationals",
//             "Date of confirmation": "26/01/2020",
//             "Age": "35",
//             "Date of discharged": "04/02/2020",
//             "Case": "7"
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": "Tan Tock Seng Hospital",
//             "(imported/local)": "imported",
//             "SG/Non-SG resident": "SG resident",
//             "Hospitalised/Discharged/Deceased": "Hospitalised",
//             "Group": "The Life Church And Missions Singapore",
//             "Date of confirmation": "26/01/2020",
//             "Age": "56",
//             "Date of discharged": "Hospitalised",
//             "Case": "8",
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "33-59",
//             "Name of hospital admitted": "Tan Tock Seng Hospital",
//             "(imported/local)": "imported",
//             "SG/Non-SG resident": "SG resident",
//             "Hospitalised/Discharged/Deceased": "Hospitalised",
//             "Group": "The Life Church And Missions Singapore",
//             "Date of confirmation": "29/01/2020",
//             "Age": "56",
//             "Date of discharged": "Hospitalised",
//             "Case": "9",
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": "unknown",
//             "(imported/local)": "imported",
//             "SG/Non-SG resident": "Non-SG resident",
//             "Hospitalised/Discharged/Deceased": 'Discharged',
//             "Group": "Chinese Nationals",
//             "Date of confirmation": "29/01/2020",
//             "Age": "56",
//             "Date of discharged": "09/02/2020",
//             "Case": "10"
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "19-32",
//             "Name of hospital admitted": "Sengkang General Hospital",
//             "(imported/local)": "imported",
//             "SG/Non-SG resident": "Non-SG resident",
//             "Hospitalised/Discharged/Deceased": 'Discharged',
//             "Group": "Chinese Nationals",
//             "Date of confirmation": "30/01/2020",
//             "Age": "31",
//             "Date of discharged": "10/02/2020",
//             "Case": "11"
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "33-59",
//             "Name of hospital admitted": "Tan Tock Seng Hospital",
//             "(imported/local)": "imported",
//             "SG/Non-SG resident": "Non-SG resident",
//             "Hospitalised/Discharged/Deceased": 'Discharged',
//             "Group": "Chinese Nationals",
//             "Date of confirmation": "30/01/2020",
//             "Age": "37",
//             "Date of discharged": "12/02/2020",
//             "Case": "12"
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "60 or above",
//             "Name of hospital admitted": "Tan Tock Seng Hospital",
//             "(imported/local)": "imported",
//             "SG/Non-SG resident": "Non-SG resident",
//             "Hospitalised/Discharged/Deceased": 'Discharged',
//             "Group": "Chinese Nationals",
//             "Date of confirmation": "31/01/2020",
//             "Age": "73",
//             "Date of discharged": "09/02/2020",
//             "Case": "13"
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "19-32",
//             "Name of hospital admitted": "NCID",
//             "(imported/local)": 'imported',
//             "SG/Non-SG resident": 'Non-SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": "Chinese Nationals",
//             "Date of confirmation": '01/02/2020',
//             "Age": '31',
//             "Date of discharged": "Hospitalised",
//             "Case": '14'
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'NCID',
//             "(imported/local)": 'imported',
//             "SG/Non-SG resident": 'Evacuated SG Resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Evacuee',
//             "Date of confirmation": '02/02/2020',
//             "Age": '47',
//             "Date of discharged": "Hospitalised",
//             "Case": '15'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'Singapore General Hospital',
//             "(imported/local)": 'imported',
//             "SG/Non-SG resident": 'Non-SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": "Chinese Nationals",
//             "Date of confirmation": '04/02/2020',
//             "Age": '38',
//             "Date of discharged": "Hospitalised",
//             "Case": '16'
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'NCID',
//             "(imported/local)": 'imported',
//             "SG/Non-SG resident": 'Evacuated SG Resident',
//             "Hospitalised/Discharged/Deceased": 'Discharged',
//             "Group": 'Evacuee',
//             "Date of confirmation": '04/02/2020',
//             "Age": '47',
//             "Date of discharged": "11/02/2020",
//             "Case": '17'
//         },
//         {
//             "Gender": "Female",
//             "Age Group": '19-32',
//             "Name of hospital admitted": 'unknown',
//             "(imported/local)": 'imported',
//             "SG/Non-SG resident": 'Non-SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": "Chinese Nationals",
//             "Date of confirmation": '04/02/2020',
//             "Age": '31',
//             "Date of discharged": "Hospitalised",
//             "Case": '18'
//         },
//         {
//             "Gender": "Female",
//             "Age Group": '19-32',
//             "Name of hospital admitted": "Tan Tock Seng Hospital",
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Young Thai Hang Medical Products Shop',
//             "Date of confirmation": '05/02/2020',
//             "Age": '28',
//             "Date of discharged": "Hospitalised", "Case": '19'
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'NCID',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Young Thai Hang Medical Products Shop',
//             "Date of confirmation": '05/02/2020',
//             "Age": '48',
//             "Date of discharged": "Hospitalised", "Case": '20'
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'Singapore General Hospital',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Young Thai Hang Medical Products Shop',
//             "Date of confirmation": '05/02/2020',
//             "Age": '44',
//             "Date of discharged": "Hospitalised", "Case": '21'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'unknown',
//             "(imported/local)": 'imported',
//             "SG/Non-SG resident": 'Evacuated SG Resident',
//             "Hospitalised/Discharged/Deceased": 'Discharged',
//             "Group": 'Evacuee',
//             "Date of confirmation": '06/02/2020',
//             "Age": '41',
//             "Date of discharged": "09/02/2020",
//             "Case": '22'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": '13-18',
//             "Name of hospital admitted": 'unknown',
//             "(imported/local)": 'imported',
//             "SG/Non-SG resident": 'Evacuated SG Resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Evacuee',
//             "Date of confirmation": '06/02/2020',
//             "Age": '17',
//             "Date of discharged": "Hospitalised",
//             "Case": '23'
//         },
//         {
//             "Gender": "Female",
//             "Age Group": '19-32',
//             "Name of hospital admitted": 'NCID',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Discharged',
//             "Group": 'Young Thai Hang Medical Products Shop',
//             "Date of confirmation": '06/02/2020',
//             "Age": '32',
//             "Date of discharged": "15/02/2020",
//             "Case": '24'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'NCID',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Discharged',
//             "Group": "Chinese Nationals",
//             "Date of confirmation": '07/02/2020',
//             "Age": '40',
//             "Date of discharged": "12/02/2020",
//             "Case": '25'
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'NCID',
//             "(imported/local)": 'imported',
//             "SG/Non-SG resident": 'Non-SG resident',
//             "Hospitalised/Discharged/Deceased": 'Discharged',
//             "Group": "Chinese Nationals",
//             "Date of confirmation": '07/02/2020',
//             "Age": '42',
//             "Date of discharged": "09/02/2020",
//             "Case": '26'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'unknown',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Young Thai Hang Medical Products Shop',
//             "Date of confirmation": '05/02/2020',
//             "Age": '45',
//             "Date of discharged": "Hospitalised",
//             "Case": '27'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": '12 or under',
//             "Name of hospital admitted": 'KK Women’s and Children’s Hospital',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Young Thai Hang Medical Products Shop',
//             "Date of confirmation": '05/02/2020',
//             "Age": '0.5',
//             "Date of discharged": "Hospitalised",
//             "Case": '28'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'Mount Elizabeth Novena Hospital',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Discharged',
//             "Group": 'Unknown',
//             "Date of confirmation": '05/02/2020',
//             "Age": '41',
//             "Date of discharged": "12/02/2020",
//             "Case": '29'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": '19-32',
//             "Name of hospital admitted": 'unknown',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Discharged',
//             "Group": 'Grand Hyatt Singapore',
//             "Date of confirmation": '06/02/2020',
//             "Age": '27',
//             "Date of discharged": '14/02/2020',
//             "Case": '30',
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'Singapore General Hospital',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'The Life Church And Missions Singapore',
//             "Date of confirmation": '09/02/2020',
//             "Age": '53',
//             "Date of discharged": "Hospitalised",
//             "Case": '31'
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'Parkway East Hospital',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Unknown',
//             "Date of confirmation": '09/02/2020',
//             "Age": '42',
//             "Date of discharged": "Hospitalised",
//             "Case": '32'
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'Sengkang General Hospital',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'The Life Church And Missions Singapore',
//             "Date of confirmation": '09/02/2020',
//             "Age": '39',
//             "Date of discharged": "Hospitalised",
//             "Case": '33'
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'NCID',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Discharged',
//             "Group": 'Young Thai Hang Medical Products Shop',
//             "Date of confirmation": '09/02/2020',
//             "Age": '40',
//             "Date of discharged": "12/02/2020",
//             "Case": '34'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": '60 or above',
//             "Name of hospital admitted": 'Singapore General Hospital',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Unknown',
//             "Date of confirmation": '09/02/2020',
//             "Age": '64',
//             "Date of discharged": "Hospitalised",
//             "Case": '35'
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'KK Women’s and Children’s Hospital',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Discharged',
//             "Group": 'Grand Hyatt Singapore',
//             "Date of confirmation": '09/02/2020',
//             "Age": '38',
//             "Date of discharged": "11/02/2020",
//             "Case": '36'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'NCID',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Unknown',
//             "Date of confirmation": '10/02/2020',
//             "Age": '53',
//             "Date of discharged": "Hospitalised", "Case": '37'
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'NCID',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'The Life Church And Missions Singapore',
//             "Date of confirmation": '10/02/2020',
//             "Age": '52',
//             "Date of discharged": "Hospitalised", "Case": '38'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'Jurong West Central',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Discharged',
//             "Group": 'Grand Hyatt Singapore',
//             "Date of confirmation": '10/02/2020',
//             "Age": '51',
//             "Date of discharged": "16/02/2020",
//             "Case": '39'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'NCID',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Discharged',
//             "Group": 'Young Thai Hang Medical Products Shop',
//             "Date of confirmation": '10/02/2020',
//             "Age": '36',
//             "Date of discharged": "12/02/2020",
//             "Case": '40'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": '60 or above',
//             "Name of hospital admitted": "Tan Tock Seng Hospital",
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Unknown',
//             "Date of confirmation": '10/02/2020',
//             "Age": '71',
//             "Date of discharged": "Hospitalised", "Case": '41'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'NCID',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'Non-SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Seletar Aerospace Heights Construction Site',
//             "Date of confirmation": '10/02/2020',
//             "Age": '39',
//             "Date of discharged": "Hospitalised", "Case": '42'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'Sengkang General Hospital',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Unknown',
//             "Date of confirmation": '11/02/2020',
//             "Age": '54',
//             "Date of discharged": "Hospitalised", "Case": '43'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'Khoo Teck Puat Hospital',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Unknown',
//             "Date of confirmation": '11/02/2020',
//             "Age": '37',
//             "Date of discharged": "Hospitalised", "Case": '44'
//         },
//         {
//             "Gender": "Female",
//             "Age Group": '12 or under',
//             "Name of hospital admitted": 'KK Women’s and Children’s Hospital',
//             "(imported/local)": 'imported',
//             "SG/Non-SG resident": 'Evacuated SG Resident',
//             "Hospitalised/Discharged/Deceased": 'Discharged',
//             "Group": 'Evacuee',
//             "Date of confirmation": '11/02/2020',
//             "Age": '2',
//             "Date of discharged": '14/02/2020',
//             "Case": '45'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": "Tan Tock Seng Hospital",
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Unknown',
//             "Date of confirmation": '11/02/2020',
//             "Age": '45',
//             "Date of discharged": "Hospitalised", "Case": '46'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'NCID',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'Non-SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Seletar Aerospace Heights Construction Site',
//             "Date of confirmation": '11/02/2020',
//             "Age": '39',
//             "Date of discharged": "Hospitalised", "Case": '47'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'NCID',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Grace Assembly Of God Church',
//             "Date of confirmation": '11/02/2020',
//             "Age": '34',
//             "Date of discharged": "Hospitalised", "Case": '48'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": 'NUH',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Grace Assembly Of God Church',
//             "Date of confirmation": '11/02/2020',
//             "Age": '46',
//             "Date of discharged": "Hospitalised", "Case": '49'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": '60 or above',
//             "Name of hospital admitted": 'NCID',
//             "(imported/local)": 'local',
//             "SG/Non-SG resident": 'SG resident',
//             "Hospitalised/Discharged/Deceased": 'Hospitalised',
//             "Group": 'Unknown',
//             "Date of confirmation": '12/02/2020',
//             "Age": '62',
//             "Date of discharged": "Hospitalised", "Case": '50'
//         },
//         {
//             'Gender': 'Male',
//             'Age Group': '33-59',
//             'Name of hospital admitted': 'NCID',
//             '(imported/local)': 'local',
//             'SG/Non-SG resident': 'SG resident',
//             'Hospitalised/Discharged/Deceased': 'Hospitalised',
//             'Group': 'Grace Assembly Of God Church',
//             'Date of confirmation': '13/02/2020',
//             'Age': '48',
//             "Date of discharged": "Hospitalised", "Case": '51'
//         },
//         {
//             'Gender': 'Male',
//             'Age Group': '33-59',
//             'Name of hospital admitted': 'Tan Tock Seng Hospital',
//             '(imported/local)': 'local',
//             'SG/Non-SG resident': 'Non-SG resident',
//             'Hospitalised/Discharged/Deceased': 'Hospitalised',
//             'Group': 'Seletar Aerospace Heights Construction Site',
//             'Date of confirmation': '13/02/2020',
//             'Age': '37',
//             "Date of discharged": "Hospitalised", "Case": '52'
//         },
//         {
//             'Gender': 'Male',
//             'Age Group': '33-59',
//             'Name of hospital admitted': 'NCID',
//             '(imported/local)': 'local',
//             'SG/Non-SG resident': 'SG resident',
//             'Hospitalised/Discharged/Deceased': 'Hospitalised',
//             'Group': 'Grace Assembly Of God Church',
//             'Date of confirmation': '13/02/2020',
//             'Age': '54',
//             "Date of discharged": "Hospitalised", "Case": '53'
//         },
//         {
//             'Gender': 'Male',
//             'Age Group': '33-59',
//             'Name of hospital admitted': 'NCID',
//             '(imported/local)': 'local',
//             'SG/Non-SG resident': 'SG resident',
//             'Hospitalised/Discharged/Deceased': 'Hospitalised',
//             'Group': 'Grace Assembly Of God Church',
//             'Date of confirmation': '13/02/2020',
//             'Age': '54',
//             "Date of discharged": "Hospitalised", "Case": '54'
//         },
//         {
//             'Gender': 'Male',
//             'Age Group': '33-59',
//             'Name of hospital admitted': 'NCID',
//             '(imported/local)': 'local',
//             'SG/Non-SG resident': 'SG resident',
//             'Hospitalised/Discharged/Deceased': 'Hospitalised',
//             'Group': 'DBS EMPLOYEE AND FAMILY MEMBER',
//             'Date of confirmation': '13/02/2020',
//             'Age': '30',
//             "Date of discharged": "Hospitalised", "Case": '55'
//         },
//         {
//             'Gender': 'Male',
//             'Age Group': '33-59',
//             'Name of hospital admitted': 'NCID',
//             '(imported/local)': 'local',
//             'SG/Non-SG resident': 'Non-SG resident',
//             'Hospitalised/Discharged/Deceased': 'Hospitalised',
//             'Group': 'Seletar Aerospace Heights Construction Site',
//             'Date of confirmation': '13/02/2020',
//             'Age': '30',
//             "Date of discharged": "Hospitalised", "Case": '56'
//         },
//         {
//             'Gender': 'Male',
//             'Age Group': '33-59',
//             'Name of hospital admitted': 'NCID',
//             '(imported/local)': 'local',
//             'SG/Non-SG resident': 'SG resident',
//             'Hospitalised/Discharged/Deceased': 'Hospitalised',
//             'Group': 'Grace Assembly Of God Church',
//             'Date of confirmation': '13/02/2020',
//             'Age': '26',
//             "Date of discharged": "Hospitalised",
//             "Case": '57'
//         },
//         {
//             'Gender': 'Male',
//             'Age Group': '33-59',
//             'Name of hospital admitted': 'NCID',
//             '(imported/local)': 'local',
//             'SG/Non-SG resident': 'SG resident',
//             'Hospitalised/Discharged/Deceased': 'Hospitalised',
//             'Group': 'Grace Assembly Of God Church',
//             'Date of confirmation': '13/02/2020',
//             'Age': '55',
//             "Date of discharged": "Hospitalised", "Case": '58'
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "60 or above",
//             "Name of hospital admitted": "NCID",
//             "(imported/local)": "local",
//             "SG/Non-SG resident": "SG resident",
//             "Hospitalised/Discharged/Deceased": "Hospitalised",
//             "Group": "Unknown",
//             "Date of confirmation": '14/02/2020',
//             "Date of discharged": "Hospitalised",
//             "Age": "61",
//             "Case": "59"
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "33-59",
//             "Name of hospital admitted": "unknown",
//             "(imported/local)": "local",
//             "SG/Non-SG resident": "SG resident",
//             "Hospitalised/Discharged/Deceased": "Hospitalised",
//             "Group": "Grace Assembly Of God Church",
//             "Date of confirmation": '14/02/2020',
//             "Date of discharged": "Hospitalised",
//             "Age": "51",
//             "Case": "60"
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": "NUH",
//             "(imported/local)": "local",
//             "SG/Non-SG resident": "SG resident",
//             "Hospitalised/Discharged/Deceased": "Hospitalised",
//             "Group": "Grace Assembly Of God Church",
//             "Date of confirmation": '14/02/2020',
//             "Date of discharged": "Hospitalised",
//             "Age": "57",
//             "Case": "61"
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "33-59",
//             "Name of hospital admitted": "Singapore General Hospital",
//             "(imported/local)": "local",
//             "SG/Non-SG resident": "SG resident",
//             "Hospitalised/Discharged/Deceased": "Hospitalised",
//             "Group": "Grace Assembly Of God Church",
//             "Date of confirmation": '14/02/2020',
//             "Date of discharged": "Hospitalised",
//             "Age": "44",
//             "Case": "62",
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "33-59",
//             "Name of hospital admitted": "unknown",
//             "(imported/local)": "local",
//             "SG/Non-SG resident": "SG resident",
//             "Hospitalised/Discharged/Deceased": "Hospitalised",
//             "Group": "Grace Assembly Of God Church",
//             "Date of confirmation": '14/02/2020',
//             "Date of discharged": "Hospitalised",
//             "Age": "54",
//             "Case": "63"
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "33-59",
//             "Name of hospital admitted": "unknown",
//             "(imported/local)": "local",
//             "SG/Non-SG resident": "SG resident",
//             "Hospitalised/Discharged/Deceased": "Hospitalised",
//             "Group": "Unknown",
//             "Date of confirmation": '14/02/2020',
//             "Date of discharged": "Hospitalised",
//             "Age": "50",
//             "Case": "64",
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "60 or above",
//             "Name of hospital admitted": "unknown",
//             "(imported/local)": "local",
//             "SG/Non-SG resident": "SG resident",
//             "Hospitalised/Discharged/Deceased": "Hospitalised",
//             "Group": "DBS EMPLOYEE AND FAMILY MEMBER",
//             "Date of confirmation": '14/02/2020',
//             "Date of discharged": "Hospitalised",
//             "Age": "61",
//             "Case": "65",
//         },
//         {
//             "Gender": "Male",
//             "Age Group": "19-32",
//             "Name of hospital admitted": "unknown",
//             "(imported/local)": "local",
//             "SG/Non-SG resident": "SG resident",
//             "Hospitalised/Discharged/Deceased": "Hospitalised",
//             "Group": "Grace Assembly Of God Church",
//             "Date of confirmation": '14/02/2020',
//             "Date of discharged": "Hospitalised",
//             "Age": "28",
//             "Case": "66"
//         },
//         {
//             "Gender": "Female",
//             "Age Group": "33-59",
//             "Name of hospital admitted": "unknown",
//             "(imported/local)": "local",
//             "SG/Non-SG resident": "SG resident",
//             "Hospitalised/Discharged/Deceased": "Hospitalised",
//             "Group": "Grace Assembly Of God Church",
//             "Date of confirmation": '14/02/2020',
//             "Date of discharged": "Hospitalised",
//             "Age": "56",
//             "Case": "67",
//         },
//         {
//             'Gender': 'Female',
//             'Age Group': '60 or above',
//             'Name of hospital admitted': 'unknown',
//             '(imported/local)': 'local',
//             'SG/Non-SG resident': 'SG resident',
//             'Hospitalised/Discharged/Deceased': 'Hospitalised',
//             'Group': 'Grace Assembly Of God Church',
//             'Date of confirmation': '15/02/2020',
//             "Date of discharged": "Hospitalised",
//             'Age': '79',
//             'Case': '68'
//         },
//         {
//             'Gender': 'Male',
//             'Age Group': '19-32',
//             'Name of hospital admitted': 'unknown',
//             '(imported/local)': 'local',
//             'SG/Non-SG resident': 'Non-SG resident',
//             'Hospitalised/Discharged/Deceased': 'Hospitalised',
//             'Group': 'Seletar Aerospace Heights Construction Site',
//             'Date of confirmation': '15/02/2020',
//             "Date of discharged": "Hospitalised",
//             'Age': '26',
//             'Case': '69'
//         },
//         {
//             'Gender': 'Female',
//             'Age Group': '19-32',
//             'Name of hospital admitted': 'unknown',
//             '(imported/local)': 'local',
//             'SG/Non-SG resident': 'SG resident',
//             'Hospitalised/Discharged/Deceased': 'Hospitalised',
//             'Group': 'Grace Assembly Of God Church',
//             'Date of confirmation': '15/02/2020',
//             "Date of discharged": "Hospitalised",

//             'Age': '27',
//             'Case': '70'
//         },
//         {
//             'Gender': 'Male',
//             'Age Group': '19-32',
//             'Name of hospital admitted': 'unknown',
//             '(imported/local)': 'local',
//             'SG/Non-SG resident': 'SG resident',
//             'Hospitalised/Discharged/Deceased': 'Hospitalised',
//             'Group': 'Grace Assembly Of God Church',
//             'Date of confirmation': '15/02/2020',
//             "Date of discharged": "Hospitalised",
//             'Age': '25',
//             'Case': '71'
//         },
//         {
//             'Gender': 'Male',
//             'Age Group': '33-59',
//             'Name of hospital admitted': 'unknown',
//             '(imported/local)': 'local',
//             'SG/Non-SG resident': 'Non-SG resident',
//             'Hospitalised/Discharged/Deceased': 'Hospitalised',
//             'Group': 'Colleagues Group #1',
//             'Date of confirmation': '15/02/2020',
//             "Date of discharged": "Hospitalised",
//             'Age': '40',
//             'Case': '72'
//         },
//         {
//             'Gender': 'Male',
//             'Age Group': '33-59',
//             'Name of hospital admitted': 'NCID',
//             '(imported/local)': 'local',
//             'SG/Non-SG resident': 'SG resident',
//             'Hospitalised/Discharged/Deceased': 'Hospitalised',
//             'Group': 'Grace Assembly Of God Church',
//             'Date of confirmation': '16/02/2020',
//             "Date of discharged": "Hospitalised",
//             'Age': '43',
//             'Case': '73'
//         },

//         {
//             'Gender': 'Male',
//             'Age Group': '19-32',
//             'Name of hospital admitted': 'Alexandra Hospital',
//             '(imported/local)': 'local',
//             'SG/Non-SG resident': 'SG resident',
//             'Hospitalised/Discharged/Deceased': 'Hospitalised',
//             'Group': 'Grace Assembly Of God Church',
//             'Date of confirmation': '16/02/2020',
//             "Date of discharged": "Hospitalised",
//             'Age': '29',
//             'Case': '74',
//         },
//         {
//             'Gender': 'Female',
//             'Age Group': '60 or above',
//             'Name of hospital admitted': 'NCID',
//             '(imported/local)': 'local',
//             'SG/Non-SG resident': 'SG resident',
//             'Hospitalised/Discharged/Deceased': 'Hospitalised',
//             'Group': 'Family Group #1',
//             'Date of confirmation': '16/02/2020',
//             "Date of discharged": "Hospitalised",
//             'Age': '71',
//             'Case': '75'
//         }
//     ]
//     const ROW_NUM = 8
//     const COLORS = ['rgb(209, 98, 98)', 'rgb(167, 73, 73)', 'rgb(134, 42, 42)', 'rgb(153, 59, 109)', 'rgb(140, 68, 141)', 'rgb(139, 117, 181)', 'rgb(87, 98, 169)', 'rgb(115, 121, 163)', 'rgb(76, 131, 187)', 'rgb(107, 173, 204)', 'rgb(44, 140, 131)', 'rgb(58, 110, 73)', 'rgb(159, 179, 107)', 'rgb(217, 208, 131)', 'rgb(201, 172, 80)', 'rgb(201, 132, 77)', 'rgb(134, 118, 65)', 'rgb(137, 97, 57)', 'rgb(88, 56, 25)', '#707070', 'black']
//     const DAYSINMTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
//     const DIGITMTH_MTH_MAP = { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'June', 7: 'July', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' }
//     const SUBCAT = {
//         'Gender': ['Male', 'Female'],
//         'Age Group': ['12 or under', '13-18', '19-32', '33-59', '60 or above'],
//         'Name of hospital admitted': ['Changi General Hospital', 'Jurong West Central', 'KK Women’s and Children’s Hospital', 'Khoo Teck Puat Hospital', 'Mount Elizabeth Novena Hospital', 'NCID', 'NUH', 'Parkway East Hospital', 'Sengkang General Hospital', 'Singapore General Hospital', 'Tan Tock Seng Hospital', 'Alexandra Hospital', 'unknown'],
//         '(imported/local)': ['imported', 'local'],
//         'SG/Non-SG resident': ['Evacuated SG Resident', 'SG resident', 'Non-SG resident'],
//         'Hospitalised/Discharged/Deceased': ['Hospitalised', 'Discharged', 'Deceased'],
//         'Group': ['Chinese Nationals', 'DBS EMPLOYEE AND FAMILY MEMBER', 'Evacuee', 'Grace Assembly Of God Church', 'Grand Hyatt Singapore', 'Seletar Aerospace Heights Construction Site', 'The Life Church And Missions Singapore', 'Unknown', 'Young Thai Hang Medical Products Shop', 'Colleagues Group #1', "Family Group #1"],
//         'Date of confirmation': ['23/01/2020', '24/01/2020', '25/01/2020', '26/01/2020', '29/01/2020', '30/01/2020', '31/01/2020', '01/02/2020', '02/02/2020', '04/02/2020', '05/02/2020', '06/02/2020', '07/02/2020', '09/02/2020', '10/02/2020', '11/02/2020', '12/02/2020', '13/02/2020', '14/02/2020', '15/02/2020', '16/02/2020'],
//         'Date of discharged': ['04/02/2020', '07/02/2020', '09/02/2020', '10/02/2020', '11/02/2020', '12/02/2020', '14/02/2020', '15/02/2020', '16/02/2020', 'Hospitalised']
//     }
//     function getDays(s, e) {
//         var date1 = new Date(s);
//         var date2 = new Date(e);

//         // To calculate the time difference of two dates 
//         var Difference_In_Time = date2.getTime() - date1.getTime();

//         // To calculate the no. of days between two dates 
//         var diff = Difference_In_Time / (1000 * 3600 * 24);
//         return diff
//     }

//     Array.min = function (array) {
//         return Math.min.apply(Math, array);
//     };

//     const wt = ScreenWidth()
//     const DEVICE = Checkdevice()
//     const casewt = DEVICE === 'WEB' ? (wt * 0.4) / 13 : DEVICE === 'TABLET' ? (wt * 0.6) / 13 : (wt * 0.9) / 12
//     const caseht = DEVICE === 'WEB' ? (wt * 0.4) / 13 : DEVICE === 'TABLET' ? (wt * 0.6) / 13 : (wt * 0.9) / 12
//     const casecardwt = DEVICE === 'WEB' ? (wt * 0.3) : DEVICE === 'TABLET' ? (wt * 0.4) : (wt * 0.3)
//     const [main, setMain] = useState('sort by case')
//     const [filter, setFilter] = useState('') // no filter yet
//     const [subcat, setSubcat] = useState('') // sub category 
//     const [legend, setLegend] = useState(1) // legend toogle, 0 or 1
//     const [discharged, setDischarged] = useState(false) // show discharged or not 
//     const [singlecase, setSinglecase] = useState({})
//     const [borderarr, setBorderarr] = useState([])
//     const [bgcolorarr, setBgcolorarr] = useState([])
//     const [fscolorarr, setFscolorarr] = useState([])
//     const [fsweightarr, setFsweightarr] = useState([])
//     const [casetxtarr, setCasetxtarr] = useState([])
//     const [opacityarr, setOpacityarr] = useState([])
//     const [casefuncarr, setCasefuncarr] = useState([])


//     useEffect(() => {
//         var local_borderarr = []
//         var local_bgcolorarr = []
//         var local_fscolorarr = []
//         var local_fsweightarr = []
//         var local_casetxtarr = []
//         var local_opacityarr = []
//         var local_casefuncarr = []

//         for (let i = 0; i < new_coronavirus.length; i++) {
//             local_borderarr.push('thin solid #707070')
//             local_bgcolorarr.push('white')
//             local_fscolorarr.push('#707070')
//             local_casetxtarr.push(
//                 <div style={{ display: 'flex', flexDirection: 'column' }}>
//                     <div>{DEVICE === 'MOBILE' ? new_coronavirus[i]['Case'] : '# ' + new_coronavirus[i]['Case']}</div>
//                 </div>
//             )
//             local_opacityarr.push(1)
//             local_casefuncarr.push(() => { setSinglecase(new_coronavirus[i]); })

//         }
//         setBorderarr(local_borderarr.slice())
//         setBgcolorarr(local_bgcolorarr.slice())
//         setFscolorarr(local_fscolorarr.slice())
//         setFsweightarr(local_fsweightarr.slice())
//         setCasetxtarr(local_casetxtarr.slice())
//         setOpacityarr(local_opacityarr.slice())
//         setCasefuncarr(local_casefuncarr.slice())
//         // setSinglecase(new_coronavirus[0])

//     }, [])


//     useEffect(() => {
//         var local_casetxtarr = casetxtarr.slice()

//         if (discharged) {
//             for (let i = 0; i < new_coronavirus.length; i++) {
//                 const status = new_coronavirus[i]['Hospitalised/Discharged/Deceased']
//                 if (status === 'Discharged') {
//                     local_casetxtarr[i] = <span role="img" aria-label="sheep">💪</span>
//                 }
//             }
//         }
//         else {
//             for (let i = 0; i < new_coronavirus.length; i++) {
//                 local_casetxtarr[i] = (
//                     <div style={{ display: 'flex', flexDirection: 'column' }}>
//                         <div>{DEVICE === 'MOBILE' ? new_coronavirus[i]['Case'] : '# ' + new_coronavirus[i]['Case']}</div>
//                     </div>
//                 )
//             }
//         }

//         setCasetxtarr(local_casetxtarr)

//     }, [discharged])



//     const filtersClicked = (key) => {

//         var local_borderarr = borderarr
//         var local_bgcolorarr = bgcolorarr
//         var local_fscolorarr = fscolorarr
//         var local_fsweightarr = fsweightarr
//         var local_opacityarr = opacityarr

//         for (let j = 0; j < SUBCAT[key].length; j++) {
//             for (let i = 0; i < new_coronavirus.length; i++) {
//                 var hospitalgrp = new_coronavirus[i][key]
//                 if (hospitalgrp === SUBCAT[key][j]) {
//                     local_borderarr[i] = 'thin solid ' + COLORS[j]
//                     local_bgcolorarr[i] = COLORS[j]
//                     local_fscolorarr[i] = 'white'
//                     local_fsweightarr[i] = 'bold'
//                     local_opacityarr[i] = 1
//                 }
//             }
//         }
//         setFscolorarr(local_fscolorarr.slice())
//         setBgcolorarr(local_bgcolorarr.slice())
//         setBorderarr(local_borderarr.slice())
//         setFsweightarr(local_fsweightarr.slice())
//         setOpacityarr(local_opacityarr.slice())
//         setFilter(key)
//         setSubcat('')
//     }

//     const renderFilters = () => {
//         return (
//             <div style={{ display: 'flex', flexDirection: 'row', width: '100%', overflow: 'auto', flexWrap: DEVICE === 'WEB' || DEVICE === 'TABLET' ? 'wrap' : 'nowrap', color: '#707070', justifyContent: DEVICE === 'TABLET' ? 'center' : 'flex-start', overflowX: 'auto' }}>
//                 {keys.map((item, index) => {
//                     return <button
//                         key={item + index}
//                         style={{ display: 'flex', flexDirection: 'row', padding: 5, width: 'max-content', margin: 5, borderRadius: 5, border: filter === item ? 'thin solid black' : 'thin solid rgb(231, 231, 231)', whiteSpace: 'nowrap', outline: 'none', backgroundColor: 'white' }}
//                         onClick={() => filtersClicked(item)}
//                     >
//                         {item}
//                     </button>
//                 })}
//             </div>

//         )
//     }

//     const render散Circles = () => { // sort by case

//         var col_doms = []

//         for (let i = 0; i < new_coronavirus.length;) {

//             var row_doms = []
//             for (let j = 0; j < ROW_NUM; j++, i++) {

//                 if (i < new_coronavirus.length) {
//                     var style = {
//                         border: singlecase['Case'] === new_coronavirus[i]['Case'] ? '5px solid black' : borderarr[i],
//                         backgroundColor: bgcolorarr[i],
//                         color: fscolorarr[i],
//                         fontWeight: fsweightarr[i],
//                         opacity: opacityarr[i],
//                         display: 'flex',
//                         flexDirection: 'column',
//                         width: casewt,
//                         height: caseht,
//                         margin: 2,
//                         padding: 0,
//                         borderRadius: 5,
//                         textAlign: 'center',
//                         justifyContent: 'center',
//                         alignItems: 'center'

//                     }
//                     row_doms.push(
//                         <button key={new_coronavirus[i]['Case']} style={style} onClick={casefuncarr[i]}>
//                             {casetxtarr[i]}
//                         </button>
//                     )
//                 }
//                 else {
//                     break
//                 }
//             }
//             col_doms.push(
//                 <div key={'row' + i + Math.random()} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
//                     {row_doms}
//                 </div>
//             )

//         }
//         return <div style={{ display: 'flex', flexDirection: 'column' }}>{col_doms}</div>
//     }

//     const render聚Circles = () => { // sort by cat

//         var col_doms = []
//         var style

//         if (filter === '') return render散Circles()

//         var combined = []
//         for (let j = 0; j < SUBCAT[filter].length; j++) {
//             combined.push([])
//             for (let i = 0; i < new_coronavirus.length; i++) {

//                 if (new_coronavirus[i][filter] === SUBCAT[filter][j]) {
//                     style = {
//                         border: singlecase['Case'] === new_coronavirus[i]['Case'] ? '5px solid black' : borderarr[i],
//                         backgroundColor: bgcolorarr[i],
//                         color: fscolorarr[i],
//                         fontWeight: fsweightarr[i],
//                         opacity: opacityarr[i],
//                         display: 'flex',
//                         flexDirection: 'column',
//                         width: casewt,
//                         height: caseht,
//                         margin: 2,
//                         padding: 0,
//                         borderRadius: 5,
//                         textAlign: 'center',
//                         justifyContent: 'center',
//                         alignItems: 'center'
//                     }

//                     combined[j].push(
//                         <button key={new_coronavirus[i]['Case'] + Math.random()} style={style} onClick={casefuncarr[i]}>
//                             {casetxtarr[i]}
//                         </button>
//                     )

//                 }

//             }
//         }

//         // merge arrs
//         var all = []
//         for (let k = 0; k < combined.length; k++) {
//             all = [...all, ...combined[k]]
//         }

//         for (let i = 0; i < all.length;) {

//             var row_doms2 = []
//             for (let j = 0; j < ROW_NUM; j++, i++) {
//                 if (i < new_coronavirus.length) row_doms2.push(all[i])
//                 else break
//             }
//             col_doms.push(
//                 <div key={'row' + i + filter + Math.random()} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
//                     {row_doms2}
//                 </div>
//             )

//         }
//         return <div style={{ display: 'flex', flexDirection: 'column' }}>{col_doms}</div>


//     }

//     const subcatClicked = (key) => {
//         var newBoarderarr = []
//         var newOpacityarr = []
//         if (key === subcat) { // same filter
//             for (let i = 0; i < new_coronavirus.length; i++) {
//                 newBoarderarr.push('thin solid transparent')
//             }
//             setSubcat('')
//         }
//         else { // new filter
//             for (let i = 0; i < new_coronavirus.length; i++) {
//                 if (new_coronavirus[i][filter] === key) {
//                     newBoarderarr.push('1px solid black')
//                     newOpacityarr.push(1)
//                 }
//                 else {
//                     newBoarderarr.push('thin solid transparent')
//                     newOpacityarr.push(0.5)
//                 }
//             }
//             setSubcat(key)
//         }

//         setBorderarr(newBoarderarr.slice())
//         setOpacityarr(newOpacityarr.slice())
//     }

//     const renderLegends = () => {
//         return (
//             <div style={{ display: 'flex', flexDirection: 'column', boxShadow: 'rgb(238, 238, 238) 0px 20px 40px -12px', border: 'thin solid rgb(238, 238, 238)' }}>
//                 <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 200, overflow: 'auto', flexWrap: 'wrap', color: '#707070', justifyContent: 'center', alignItems: 'center', justifyItems: 'center' }}>
//                     {filter.length > 0 && SUBCAT[filter].map((item, index) => {
//                         return (
//                             <button
//                                 key={item + index}
//                                 style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center', padding: 5, width: 'max-content', margin: 5, borderRadius: 5, border: subcat === item ? 'thin solid black' : 'thin solid rgb(231, 231, 231)', whiteSpace: 'nowrap', outline: 'none', backgroundColor: 'white' }}
//                                 onClick={() => subcatClicked(item)}
//                             >
//                                 <div style={{ backgroundColor: COLORS[index], width: 10, height: 10, borderRadius: 10, padding: 5, margin: 5 }} />
//                                 <div style={{ fontSize: 13 }}>{item}</div>
//                             </button>
//                         )
//                     })}
//                 </div>
//                 <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
//                     <button style={{ ...BUTTONSTYLE, width: 'max-content', height: 'max-content', borderRadius: 5, padding: 3, color: '#707070', margin: 5 }} onClick={() => setLegend(1)}>Swap</button>
//                 </div>
//             </div>

//         )
//     }

//     const renderNumlegends = () => {

//         if (filter.length > 0) {
//             var subcat_map = {}
//             var cards = []
//             for (let x = 0; x < SUBCAT[filter].length; x++) {
//                 subcat_map[SUBCAT[filter][x]] = 0
//             }

//             for (let i = 0; i < new_coronavirus.length; i++) {
//                 var local_subcat = new_coronavirus[i][filter]
//                 subcat_map[local_subcat] = subcat_map[local_subcat] + 1
//             }

//             var subcat_keys = Object.keys(subcat_map)
//             for (let j = 0; j < subcat_keys.length; j++) {
//                 cards.push(
//                     <button key={'subcat_map' + subcat_keys[j]} onClick={() => subcatClicked(subcat_keys[j])} style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', alignItems: 'center', minWidth: 130, maxWidth: 130, height: 160, borderRadius: 5, margin: 10, border: subcat_keys[j] === subcat ? 'thin solid black' : 'thin solid rgb(231, 231, 231)', borderBottom: '', color: '#707070', justifyContent: 'center', outline: 'none', padding: 0 }}>
//                         <div style={{ fontSize: 12.5, fontWeight: 'bold', height: '40%', padding: 2, display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>{subcat_keys[j]}</div>
//                         <div style={{ fontSize: 25, fontWeight: 'bold', height: '25%', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>{Math.round((subcat_map[subcat_keys[j]] / new_coronavirus.length) * 100, 2) + '%'}</div>
//                         <div style={{ fontSize: 12.5, fontWeight: 'bold', height: '25%', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>{subcat_map[subcat_keys[j]]}</div>
//                         <div style={{ backgroundColor: COLORS[j], width: 130, height: '10%', borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }} />
//                     </button>
//                 )
//             }
//             return (
//                 <div style={{ display: 'flex', flexDirection: 'column', boxShadow: 'rgb(238, 238, 238) 0px 20px 40px -12px', border: 'thin solid rgb(238, 238, 238)', }}>
//                     <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 200, overflowY: 'hidden', overflowX: 'auto', flexWrap: 'nowrap', color: '#707070', justifyContent: subcat_keys.length > 2 ? 'flex-start' : 'center', alignItems: 'center', justifyItems: 'center' }}>
//                         {cards}
//                     </div>
//                     <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
//                         <button style={{ ...BUTTONSTYLE, width: 'max-content', height: 'max-content', borderRadius: 5, padding: 3, color: '#707070', margin: 5 }} onClick={() => setLegend(0)}>Swap</button>
//                     </div>
//                 </div>

//             )
//         }
//         else {
//             return <div style={{ display: 'flex', flexDirection: 'row', width: '100%', overflow: 'auto', flexWrap: 'wrap', color: '#707070', justifyContent: 'center', boxShadow: 'rgb(238, 238, 238) 0px 20px 40px -12px', border: 'thin solid rgb(238, 238, 238)', alignItems: 'center', justifyItems: 'center' }} />
//         }

//     }

//     const renderMain = () => {
//         return (
//             <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', padding: 3 }}>
//                 <button style={{ backgroundColor: 'white', outline: 'none', padding: 5, margin: 5, bordeRadius: 5, border: main === 'sort by case' ? 'thin solid black' : 'thin solid rgb(231, 231, 231)', fontWeight: main === 'sort by case' ? 'bold' : 'normal', }} onClick={() => setMain('sort by case')}>sort by case</button>
//                 <button style={{ backgroundColor: 'white', outline: 'none', padding: 5, margin: 5, bordeRadius: 5, border: main === 'sort by cat' ? 'thin solid black' : 'thin solid rgb(231, 231, 231)', fontWeight: main === 'sort by cat' ? 'bold' : 'normal', }} onClick={() => setMain('sort by cat')}>sort by cat</button>
//                 {<div style={{ borderLeft: 'thin solid rgb(238, 238, 238)', margin: '0px 5px' }} />}
//                 <button style={{ backgroundColor: 'white', outline: 'none', padding: 5, margin: 5, bordeRadius: 5, border: discharged ? 'thin solid black' : 'thin solid rgb(231, 231, 231)', fontWeight: discharged ? 'bold' : 'normal', }} onClick={() => setDischarged(!discharged)}>discharged</button>
//             </div>
//         )
//     }

//     const renderSinglecase = () => {

//         const styleRoot = { display: 'flex', flexDirection: 'row', padding: 2, alignItems: 'center', borderTop: 'thin solid rgb(238, 238, 238)', width: '100%', }
//         const styleTitle = { padding: 2, margin: 2, whiteSpace: 'flex-wrap', fontSize: 13, width: '40%' }
//         const styleContent = { padding: 2, margin: 2, fontWeight: 'bold', width: '60%' }
//         return (
//             <div style={{ boxShadow: '0 2px 24px 0 rgba(0, 0, 0, 0.12)', width: casecardwt, margin: '0px 10px 15px 10px', border: 'thin solid rgb(238, 238, 238)', color: 'rgb(112, 112, 112)', }}>
//                 <div style={{ display: 'flex', flexDirection: 'column' }}>
//                     <div style={{ display: 'flex', fontSize: 'xx-large', padding: '5px 10px', margin: '10px 10px 0px 0px', fontWeight: 'bold' }}>
//                         {'#' + singlecase['Case']}
//                     </div>
//                     <div style={styleRoot}>
//                         <div style={styleTitle}>Gender</div>
//                         <div style={styleContent}>{singlecase['Gender']}</div>
//                     </div>
//                     <div style={styleRoot}>
//                         <div style={styleTitle}>Age</div>
//                         <div style={styleContent}>{singlecase['Age']}</div>
//                     </div>
//                     <div style={styleRoot}>
//                         <div style={styleTitle}>Name of hospital admitted</div>
//                         <div style={styleContent}>{singlecase['Name of hospital admitted']}</div>
//                     </div>
//                     <div style={styleRoot}>
//                         <div style={styleTitle}>(imported / local)</div>
//                         <div style={styleContent}>{singlecase['(imported/local)']}</div>
//                     </div>
//                     <div style={styleRoot}>
//                         <div style={styleTitle}>SG / Non-SG resident</div>
//                         <div style={styleContent}>{singlecase['SG/Non-SG resident']}</div>
//                     </div>
//                     <div style={styleRoot}>
//                         <div style={styleTitle}>Hospitalised / Discharged / Deceased</div>
//                         <div style={styleContent}>{singlecase['Hospitalised/Discharged/Deceased']}</div>
//                     </div>
//                     <div style={styleRoot}>
//                         <div style={styleTitle}>Group</div>
//                         <div style={styleContent}>{singlecase['Group']}</div>
//                     </div>
//                     <div style={styleRoot}>
//                         <div style={styleTitle}>Date of confirmation</div>
//                         <div style={styleContent}>{singlecase['Date of confirmation']}</div>
//                     </div>
//                     <div style={styleRoot}>
//                         <div style={styleTitle}>Date of discharged</div>
//                         <div style={styleContent}>{singlecase['Date of discharged']}</div>
//                     </div>
//                 </div>
//             </div>
//         )
//     }

//     const renderExtra = () => {

//         const l_wt = DEVICE === 'WEB' || DEVICE === 'TABLET' ? wt * 0.5 : wt * 0.6
//         var data = []
//         var dates = []
//         var mth_dt_map = {}
//         var mth_pxdenom_map = {}
//         var digitmth_width_map = {}
//         var totaldenom = 0 // grand total, sum based on number of day 

//         for (let x = 0; x < new_coronavirus.length; x++) {// get discharged dates
//             if (new_coronavirus[x]['Hospitalised/Discharged/Deceased'] === 'Discharged' && new_coronavirus[x]['Date of discharged'] !== 'Hospitalised') {

//                 var mthcfm = parseInt(new_coronavirus[x]['Date of confirmation'].substr(3, 2), 10)
//                 var dtcfm = parseInt(new_coronavirus[x]['Date of confirmation'].substr(0, 2), 10)
//                 dates.push(new_coronavirus[x]['Date of confirmation'])

//                 if (!mth_dt_map.hasOwnProperty(mthcfm)) mth_dt_map[mthcfm] = []
//                 mth_dt_map[mthcfm].push(dtcfm)

//                 const mthdischarged = parseInt(new_coronavirus[x]['Date of discharged'].substr(3, 2), 10)
//                 const dtdischarged = parseInt(new_coronavirus[x]['Date of discharged'].substr(0, 2), 10)
//                 dates.push(new_coronavirus[x]['Date of discharged'])

//                 data.push([new_coronavirus[x]['Date of confirmation'], new_coronavirus[x]['Date of discharged'], new_coronavirus[x]['Case']])

//                 if (!mth_dt_map.hasOwnProperty(mthdischarged)) mth_dt_map[mthdischarged] = []
//                 mth_dt_map[mthdischarged].push(dtdischarged)

//             }
//         }

//         dates.sort(function (a, b) {
//             a = a.split('/').reverse().join('');
//             b = b.split('/').reverse().join('');
//             return a > b ? 1 : a < b ? -1 : 0;
//             // return a.localeCompare(b);         // <-- alternative 
//         });


//         // sort by cat these dates in the map
//         var mth_key = Object.keys(mth_dt_map)
//         for (let i = 0; i < mth_key.length; i++) {
//             var days = mth_dt_map[mth_key[i]]
//             var min = Array.min(days);
//             var max = Math.max.apply(Math, days)
//             if (i === 0) {
//                 mth_pxdenom_map[mth_key[i]] = max - min
//             }
//             else if (i === mth_key.length - 1) {
//                 mth_pxdenom_map[mth_key[i]] = max - 0
//             }
//             else {
//                 mth_pxdenom_map[mth_key[i]] = DAYSINMTH[mth_key[i] - 1]
//             }
//             totaldenom = totaldenom + mth_pxdenom_map[mth_key[i]]
//         }



//         var casedoms = [] // array or array
//         var grandstart = dates[0].split('/')[1] + '/' + dates[0].split('/')[0] + '/' + dates[0].split('/')[2]
//         var grandend = dates[dates.length - 1].split('/')[1] + '/' + dates[dates.length - 1].split('/')[0] + '/' + dates[dates.length - 1].split('/')[2]
//         var Grand_Difference_In_Days = getDays(grandstart, grandend) + 1

//         var grandstart_firstday = dates[0].split('/')[0]
//         grandstart_firstday = parseInt(grandstart_firstday, 10)
//         var grandstart_firstmth = dates[0].split('/')[1]
//         grandstart_firstmth = parseInt(grandstart_firstmth, 10)



//         for (let q = 0; q < data.length; q++) {
//             var temp = []
//             var start = data[q][0].split('/')[1] + '/' + data[q][0].split('/')[0] + '/' + data[q][0].split('/')[2]
//             var end = data[q][1].split('/')[1] + '/' + data[q][1].split('/')[0] + '/' + data[q][1].split('/')[2]

//             var firstindex = getDays(grandstart, start) // shade this index, as first 
//             var lastindex = getDays(end, grandend) === 0 ? Grand_Difference_In_Days - 1 : Grand_Difference_In_Days - getDays(end, grandend) - 1 // shade this index as last
//             var local_width = l_wt / Grand_Difference_In_Days

//             for (let i = 0, local_grandstartfirstday = grandstart_firstday, local_grandstartmth = grandstart_firstmth; i < Grand_Difference_In_Days; i++, local_grandstartfirstday++) {

//                 if (DEVICE === 'WEB') {
//                     if (i === firstindex) {
//                         temp.push(
//                             <div key={q + 'Grand_Difference_In_Days' + i} style={{ width: local_width, height: local_width, borderRadius: 5, backgroundColor: 'rgb(239, 174, 174)', color: 'white', border: 'thin solid #707070', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
//                                 <span role="img" aria-label="sheep">😷</span>
//                             </div>
//                         )
//                     }
//                     else if (i === lastindex) {
//                         temp.push(
//                             <div key={q + 'Grand_Difference_In_Days' + i} style={{ width: local_width, height: local_width, borderRadius: 5, backgroundColor: '#52c161', color: 'white', border: 'thin solid #707070', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
//                                 <span role="img" aria-label="sheep">💪</span>
//                             </div>
//                         )
//                     }
//                     else {
//                         temp.push(
//                             <div key={q + 'Grand_Difference_In_Days' + i} style={{ width: local_width, height: local_width, borderRadius: 5, color: '#707070', border: 'thin solid #707070', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
//                                 <div>{local_grandstartfirstday}</div>
//                             </div>
//                         )
//                     }
//                 }
//                 else {
//                     temp.push(
//                         <div key={q + 'Grand_Difference_In_Days' + i} style={{ width: local_width, height: local_width, border: 'thin solid #707070', backgroundColor: (i === firstindex) ? 'red' : i === lastindex ? 'green' : 'white' }}>

//                         </div>
//                     )
//                 }



//                 if (digitmth_width_map[local_grandstartmth]) {
//                     digitmth_width_map[local_grandstartmth] = digitmth_width_map[local_grandstartmth] + local_width
//                 }
//                 else {
//                     digitmth_width_map[local_grandstartmth] = 0
//                     digitmth_width_map[local_grandstartmth] = digitmth_width_map[local_grandstartmth] + local_width
//                 }


//                 if (local_grandstartfirstday + 1 > DAYSINMTH[local_grandstartmth - 1]) {
//                     local_grandstartmth = local_grandstartmth + 1
//                     local_grandstartfirstday = 0
//                 }

//             }

//             if (DEVICE === 'WEB' || DEVICE === 'TABLET') {
//                 temp.push(
//                     <button
//                         key={data[q][2] - 1 + 'case' + DEVICE}
//                         onClick={() => {
//                             if (new_coronavirus[data[q][2] - 1] === singlecase) {
//                                 setSinglecase('')
//                             }
//                             else {
//                                 setSinglecase(new_coronavirus[data[q][2] - 1])
//                             }
//                         }}
//                         style={{ display: 'flex', outline: 'none', alignItems: 'center', color: singlecase === new_coronavirus[data[q][2] - 1] ? 'white' : '#707070', backgroundColor: singlecase === new_coronavirus[data[q][2] - 1] ? '#707070' : 'white', justifyContent: 'center', width: local_width * 2, height: local_width, border: 'thin solid #707070', borderTop: q !== 0 ? '' : 'thin solid #707070', fontWeight: 'bold' }}>
//                         {'# ' + data[q][2]}
//                     </button>
//                 )
//             }

//             casedoms.push(
//                 <div key={q + 'Grand_Difference_In_Days'} style={{ display: 'flex', flexDirection: 'column' }}>
//                     <div style={{ display: 'flex', flexDirection: 'row' }}>
//                         {temp}
//                     </div>
//                     {singlecase === new_coronavirus[data[q][2] - 1] ? <div style={{ padding: 10 }}> {renderSinglecase()}</div> : ''}
//                 </div>
//             )

//         }


//         return (
//             <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
//                 <div style={{ display: 'flex', fontSize: 'xx-large', padding: '5px 10px', margin: '10px 10px 0px 0px', color: 'rgb(112, 112, 112)', fontWeight: 'bold' }}>
//                     {'Discharged'}
//                 </div>
//                 <div style={{ display: 'flex', flexDirection: 'column' }}>
//                     {/*number of months*/}
//                     <div style={{ display: 'flex', flexDirection: 'row', width: l_wt }}>
//                         {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: '0px 3px', width: local_width * 2, height: local_width }}></div> */}
//                         {Object.keys(mth_pxdenom_map).map((digitmth, index) => {
//                             var mth = DIGITMTH_MTH_MAP[digitmth]
//                             var curr_wt = digitmth_width_map[digitmth] // wt based on no. of days 
//                             return (
//                                 <div key={mth + index} style={{ display: 'flex', width: curr_wt, borderBottom: 'thin solid #707070', borderLeft: 'thin solid #707070' }}>
//                                     <div style={{ paddingLeft: 3 }}>{mth}</div>
//                                 </div>
//                             )
//                         })}
//                     </div>

//                 </div>
//                 <div style={{ display: 'flex', flexDirection: 'column' }}>
//                     {casedoms}
//                 </div>
//             </div>
//         )

//     }

//     const renderDatasource = () => {
//         return (
//             <div style={{ display: 'flex', flexDirection: 'column' }}>
//                 <div style={{ display: 'flex', fontSize: 'xx-large', padding: '5px 10px', margin: '10px 10px 0px 0px', color: 'rgb(112, 112, 112)', fontWeight: 'bold' }}>
//                     Data Source
//                 </div>
//                 <div>https://thewuhanvirus.com/lab/</div>
//             </div>
//         )
//     }

//     return (
//         <div style={{ display: 'flex', width: '100%', justifyContent: 'center', flexDirection: 'column', color: '#222222', overflowX: 'auto', }}>
//             <div style={{ height: 'max-content', width: '100%', margin: '30px 0px', overflowX: 'scroll', flexDirection: 'row' }} />
//             <div style={{ display: 'flex', flexDirection: 'column' }}>
//                 {renderFilters()}
//                 {EMPTYSPACE}
//                 {legend === 0 ? renderLegends() : renderNumlegends()}
//                 {EMPTYSPACE}
//                 {renderMain()}
//                 {EMPTYSPACE}
//                 {DEVICE === 'WEB' || DEVICE === 'TABLET' ?
//                     <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
//                         {main === 'sort by case' ? render散Circles() : render聚Circles()}
//                         {renderSinglecase()}
//                     </div>
//                     :
//                     main === 'sort by case' ? render散Circles() : render聚Circles()
//                 }
//                 {EMPTYSPACE}
//                 {DEVICE === 'WEB' ? renderExtra() : <div />}
//                 {EMPTYSPACE}
//                 {renderDatasource()}
//             </div>
//         </div>
//     )


// }

// function Beyond1() {

//     return (
//         [
//             {
//                 key: 'TEXT',
//                 val: ""
//             },
//             {
//                 key: 'DOM',
//                 val: <Coronavirus />,
//             }
//         ]
//     )
// }

// export default Beyond1

function Beyond1(){
    return <div/>
}
export default Beyond1