import React, { useState } from 'react'
import './Gbmessageshort.css'

function Gbmessageshort({ msg }) {

    const [expand, setExpand] = useState(false)

    const open = () => {
        setExpand(true)
    }

    const {  txt, id, name } = msg // img, time, key
    return <div key={'gbmsg' + id} id={id} className='Gbmsgshort_root' onClick={open}  >
        <span className="Gbmsgshort_col">
            {!expand
                ? <div className="Gbmsgshort_txt">{txt && txt.length > 10 ? txt.substring(0, 10) : txt}...</div>
                : <div className="Gbmsgshort_txt">{txt}</div>}
            <div className="Gbmsgshort_name">{name}</div>
        </span>
    </div>
}

export default Gbmessageshort