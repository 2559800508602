import React, { useEffect, useState } from 'react'
import { SEND_INVITATION_SMALL, BUDGET_SMALL, TIMELINE_SMALL, TODO_SMALL, CHAIR_SMALL, GUESTBOOK_SMALL, guestbookbool, SUB_FF } from '../../../../config/constants'
import { eventhome, eventvendors, guesthome, projecthome_wedding, projecthome_event, weddinghome, weddingvendors, pricelisthome_wedding, pricelisthome_event, tools, market, promo } from '../../../../assets/homeuser'
import { Checkdevice, Locationselector } from '../../../../components'
import { Search } from './components'
import UserHomeDashboard from '../../HomeUser/UserHomeDashboard/UserHomeDashboard'
import { PLANNING_BUDGET_ICON, PLANNING_CHECKLIST_ICON, PLANNING_GUESTBOOK_ICON, PLANNING_INVITE_ICON, PLANNING_QR_ICON, PLANNING_SEATING_ICON, PLANNING_TIMELINE_ICON } from '../../../../config/svgicons'
import { iconwrapper } from '../../../../config/commonfunctions'
import './Header.css'

const LOGO_TEXT = 'Creating Moments, Celebrating Love'//Creating Moments, Celebrating Love

const HEADERIMG_MAP = {
    'Wedding': { backgroundImage: "url(" + weddinghome + ")", },
    'Weddingweddingvendors': { backgroundImage: "url(" + weddingvendors + ")", },
    'eventvendors': { backgroundImage: "url(" + eventhome + ")", },
    'Eventeventvendors': { backgroundImage: "url(" + eventvendors + ")", },
    'Event': { backgroundImage: "url(" + eventhome + ")", },
    'Guest': { backgroundImage: "url(" + guesthome + ")", },
    'Eventdiscovery': { backgroundImage: "url(" + guesthome + ")", },
    'ProjectWedding': { backgroundImage: "url(" + projecthome_wedding + ")", },
    'ProjectEvent': { backgroundImage: "url(" + projecthome_event + ")", },
    'PricelistWedding': { backgroundImage: "url(" + pricelisthome_wedding + ")", },
    'PricelistEvent': { backgroundImage: "url(" + pricelisthome_event + ")", },
    'PromoWedding': { backgroundImage: "url(" + promo + ")", },
    'PromoEvent': { backgroundImage: "url(" + promo + ")", },
    'ToolsEvent': { backgroundImage: "url(" + tools + ")", },
    'ToolsWedding': { backgroundImage: "url(" + tools + ")", },
    'MarketWedding': { backgroundImage: "url(" + market + ")", },
}



function Header(props) {

    const { usertype, wedorevent = '', children, abbrev_userlocation, name, spousename, date_w, appclickable = true } = props
    const [hoverinvitationsitebuilder, setHoverinvitationsitebuilder] = useState(false)
    const [hoverbudgetbuilder, setHoverbudgetbuilder] = useState(false)
    const [hoveritinerarybuilder, setHoveritinerarybuilder] = useState(false)
    const [hoverchecklist, setHoverchecklist] = useState(false)
    const [hoverseating, setHoverseating] = useState(false)
    const [hoverguestbook, setHoverguestbook] = useState(false)
    const [hoverqr, setHoverqr] = useState(false)
    const [combinedwu, setCombinedwu] = useState('')
    const device = Checkdevice()

    useEffect(() => { setCombinedwu(usertype + wedorevent) }, [usertype, wedorevent])

    const HEADTITLE_CSS_MAP = {
        'Budget': hoverbudgetbuilder ? 'headericonselected' : 'headericonnotselected',
        'Invitation': hoverinvitationsitebuilder ? 'headericonselected' : 'headericonnotselected',
        'Itinerary': hoveritinerarybuilder ? 'headericonselected' : 'headericonnotselected',
        'Checklist': hoverchecklist ? 'headericonselected' : 'headericonnotselected',
        'Seating Chart': hoverseating ? 'headericonselected' : 'headericonnotselected',
        'Guest Book': hoverguestbook ? 'headericonselected' : 'headericonnotselected',
        'QR': hoverqr ? 'headericonselected' : 'headericonnotselected',
    }

    const DEVICE_ICONCSS_MAP = {
        'WEB': { width: 27, height: 27 },
        'TABLET': { width: 27, height: 27 },
        'MOBILE': { width: 24, height: 24 }
    }

    const HT_OBJ = guestbookbool
        ? {
            'Checklist': {
                name: 'Checklist',
                url: '/tools/checklist',
                icon: iconwrapper(PLANNING_CHECKLIST_ICON, DEVICE_ICONCSS_MAP[device]),
                id: 'checklist_id'
            },
            'Budget': {
                name: 'Budget',
                url: '/tools/budget',
                icon: iconwrapper(PLANNING_BUDGET_ICON, DEVICE_ICONCSS_MAP[device]),
                id: 'budget_id'
            },
            'Itinerary': {
                name: 'Timeline Itinerary',
                url: '/tools/itinerary',
                icon: iconwrapper(PLANNING_TIMELINE_ICON, DEVICE_ICONCSS_MAP[device]),
                id: 'itinerary_id'
            },
            'Invitation': {
                name: 'Site Builder & Guest List',
                url: '/tools/invitation',
                icon: iconwrapper(PLANNING_INVITE_ICON, DEVICE_ICONCSS_MAP[device]),
                id: 'invitation_id'
            },
            'Seating Chart': {
                name: 'Seating Chart',
                url: '/tools/seatingchart',
                icon: iconwrapper(PLANNING_SEATING_ICON, DEVICE_ICONCSS_MAP[device]),
                id: 'seatingchart_id'
            },
            'Guest Book': {
                name: 'Guest Book',
                url: '/tools/guestbook',
                icon: iconwrapper(PLANNING_GUESTBOOK_ICON, DEVICE_ICONCSS_MAP[device]),
                id: 'guestbook_id'
            },
            'QR': {
                name: 'QR',
                url: '/tools/qr',
                icon: iconwrapper(PLANNING_QR_ICON, DEVICE_ICONCSS_MAP[device]),
                id: 'qr_id'
            },

        }
        : {
            'Checklist': {
                name: 'Checklist',
                url: '/tools/checklist',
                icon: iconwrapper(PLANNING_CHECKLIST_ICON, DEVICE_ICONCSS_MAP[device]),
                id: 'checklist_id'
            },
            'Budget': {
                name: 'Budget',
                url: '/tools/budget',
                icon: iconwrapper(PLANNING_BUDGET_ICON, DEVICE_ICONCSS_MAP[device]),
                id: 'budget_id'
            },
            'Itinerary': {
                name: 'Timeline Itinerary',
                url: '/tools/itinerary',
                icon: iconwrapper(PLANNING_TIMELINE_ICON, DEVICE_ICONCSS_MAP[device]),
                id: 'itinerary_id'
            },
            'Invitation': {
                name: 'Site Builder & Guest List',
                url: '/tools/invitation',
                icon: iconwrapper(PLANNING_INVITE_ICON, DEVICE_ICONCSS_MAP[device]),
                id: 'invitation_id'
            },
            'Seating Chart': {
                name: 'Seating Chart',
                url: '/tools/seatingchart',
                icon: iconwrapper(PLANNING_SEATING_ICON, DEVICE_ICONCSS_MAP[device]),
                id: 'seatingchart_id'
            },
            'QR': {
                name: 'QR',
                url: '/tools/qr',
                icon: iconwrapper(PLANNING_QR_ICON, DEVICE_ICONCSS_MAP[device]),
                id: 'qr_id'
            }
        }

    const mouseover = (key) => {
        if (key === 'Budget') {
            setHoverbudgetbuilder(true)
        }
        else if (key === 'Invitation') {
            setHoverinvitationsitebuilder(true)
        }
        else if (key === 'Itinerary') {
            setHoveritinerarybuilder(true)
        }
        else if (key === 'Checklist') {
            setHoverchecklist(true)
        }
        else if (key === 'Seating Chart') {
            setHoverseating(true)
        }
        else if (key === 'Guest Book') {
            setHoverguestbook(true)
        }
        else if (key === 'QR') {
            setHoverqr(true)
        }
    }


    const mouseleave = (key) => {
        if (key === 'Budget') {
            setHoverbudgetbuilder(false)
        }
        else if (key === 'Invitation') {
            setHoverinvitationsitebuilder(false)
        }
        else if (key === 'Itinerary') {
            setHoveritinerarybuilder(false)
        }
        else if (key === 'Checklist') {
            setHoverchecklist(false)
        }
        else if (key === 'Seating Chart') {
            setHoverseating(false)
        }
        else if (key === 'Guest Book') {
            setHoverguestbook(false)
        }
        else if (key === 'QR') {
            setHoverqr(false)
        }
    }

    // const PLANNINGAPP_DIV = Object.keys(HT_OBJ).map((key, index) => {
    //     // update like opensea
    //     const { name, url, icon } = HT_OBJ[key]
    //     return <div className="headerplanningdiv" key={name + index}>
    //         {HEADTITLE_CSS_MAP[key] === 'headericonselected' ?
    //             <div className={'headericonselected'}><div className="headericonarrow" />
    //                 <div className={'headericonnameselected'}>{name}</div>
    //             </div>
    //             : null}
    //         <button className="headericonroot" onMouseLeave={() => mouseleave(key)} onMouseOver={() => mouseover(key)} onClick={() => props.history.push(url)} >{icon}</button>
    //     </div>
    // })

    const PLANNINGAPP_DIV = Object.keys(HT_OBJ).map((key, index) => { // update like opensea

        const { name, url, icon, id } = HT_OBJ[key]
        var ext_style = {}

        if (index === 0) {
            ext_style = { borderTopLeftRadius: 5, borderTopRightRadius: 5 }
        }
        else if (index === Object.keys(HT_OBJ).length - 1) {
            ext_style = { borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderBottom: '1px solid #707070' }
        }

        return <div className="headerplanningdiv" key={name + index}>
            {HEADTITLE_CSS_MAP[key] === 'headericonselected'
                ? <div className={'headericonselected'}>
                    <div className="headericonarrow" />
                    <div className={'headericonnameselected'}>{name}</div>
                </div>
                : null}
            <button
                className={`headericonroot headericondevice_${device}`}
                style={ext_style}
                onMouseLeave={() => mouseleave(key)} onMouseOver={() => mouseover(key)}
                onClick={() => {
                    if (appclickable) {
                        props.history.push(url)
                        try {
                            document.getElementById(id).click()
                        }
                        catch (e) { }
                    }

                }}
            >
                {icon}
            </button>
        </div>
    })

    return <div className={"headerroot" + device} key={usertype} style={{ ...HEADERIMG_MAP[combinedwu], fontFamily: SUB_FF, backgroundColor: '#fff5f5' }}>

        <UserHomeDashboard {...props} />

        <div className={"headerabsroot" + device} />

        <div className={"headercontentroot" + device}>

            {/* Creating Moments, Celebrating Love */}
            <div className={"headercontentsubroot" + device}> {/*, height: '40%' */}
                {device !== 'MOBILE'
                    ? <div className="headercolumnfonts">
                        <font color='#555555' size={5} className="headertitlelogorealtext">{LOGO_TEXT}</font>
                    </div>
                    : null}

                {/* SEARCH BAR  */}
                <Search {...props} />
            </div>

            {(wedorevent === 'weddingvendors' || wedorevent === 'Wedding' || wedorevent === 'wedding' || usertype === 'Wedding') && date_w && date_w.length > 0
                ? < div className={"headernameroot" + device} onClick={() => props.history.push('/profile')}>
                    <div className="headerdate">{new Date(date_w).toLocaleString().split(',')[0]}</div>
                    {name.length > 0 && spousename.length > 0
                        ? <div className="headernamesubroot">
                            <div className="headername">{name.substring(0, 1) + ' & ' + spousename.substring(0, 1) + "'s Wedding"}</div>
                        </div>
                        : null}
                </div>
                : null}

        </div>

        {device === 'WEB' ? <div className="headercircularWEB"> {children} </div> : <div className="headercircular"> {children}</div>}

        {/* location */}
        {usertype !== 'Guest' && (usertype === 'Wedding' || usertype === 'Event' || usertype === 'Pricelist' || usertype === 'Promo' || usertype === 'Tools' || usertype === 'Project')
            ? <div className="headerlocroot">
                <Locationselector homeuser_header_ref={props.homeuser_header_ref} abbrev_userlocation={abbrev_userlocation} usertype={usertype} locclickable={props.locclickable} />
            </div>
            : null}

        {/* floating btns */}
        {usertype !== 'Guest' && (usertype === 'Wedding' || usertype === 'Event' || usertype === 'Pricelist' || usertype === 'Promo' || usertype === 'Market' || usertype === 'Project' || usertype === 'Tools')
            ? <div className="headerplanningroot" style={{ boxShadow: !hoverbudgetbuilder && !hoverchecklist && !hoveritinerarybuilder && !hoverinvitationsitebuilder && !hoverseating && !hoverguestbook && !hoverqr ? '#19191940 0px 2px 3px' : 'none' }} ref={props.homeuser_planningtools_ref} >
                {PLANNINGAPP_DIV}
            </div>
            : null}

    </div >
}
export default Header