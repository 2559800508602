import React, { useState, useRef, useEffect } from 'react';
import { Checkdevice } from '../../../../components';
import { iconwrapper } from '../../../../config/commonfunctions';
import { HEARTUSERLOGIN_ICON } from '../../../../config/svgicons';
import { S3CLOUD_CLOUDFRONT_ASSET, S3CLOUD_CLOUDFRONT_USERLOGIN } from '../../../../config/s3directories';
import './Directedline.css';

const IPHONE_FRANE = `${S3CLOUD_CLOUDFRONT_USERLOGIN}2mobile.png`

function isScrolledIntoView(el, showall) {
    const { top, bottom } = el.getBoundingClientRect();
    var isVisible = (top >= 0) && ((bottom + 300) <= window.innerHeight)
    return isVisible || showall;
}

function Directedline({ titles, contents, icons, images, mimages, showall }) {

    const device = Checkdevice()
    const [scrollPos, setScrollPos] = useState(0);
    const [elemindexes, setElemindexes] = useState([])
    const [highlightindex, setHighlightindex] = useState(-1) // current selected index

    const sensor_refs = useRef([])
    sensor_refs.current = []

    const img_refs = useRef([])
    img_refs.current = []

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        var currIndexes = elemindexes.slice()
        if (elemindexes.length !== sensor_refs.current.length) {
            for (let i = 0; i < sensor_refs.current.length; i++) {
                if (!elemindexes.includes(i) && isScrolledIntoView(sensor_refs.current[i], showall)) {
                    img_refs.current[i].className = i % 2 === 0 ? 'DL_SINGLE_IMG_LEFT_SELECTED' : 'DL_SINGLE_IMG_RIGHT_SELECTED'
                    setHighlightindex(i)
                    // this will make mobile device appear if img gt mobile device img
                }
            }
            setElemindexes(currIndexes)
        }

        var firstelem_rect = sensor_refs.current[0].getBoundingClientRect();
        if (scrollPos < firstelem_rect.top + 300) {
            setHighlightindex(-1)
        }
    }, [scrollPos])

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPos(position);
    };

    const addToSensorRefs = el => {
        if (el && !sensor_refs.current.includes(el)) sensor_refs.current.push(el);
    }

    const addToImgRefs = el => {
        if (el && !img_refs.current.includes(el)) img_refs.current.push(el);
    }

    function left(title, content, icon, i, img, mimg, highlight) {
        const i_ = i + 1
        const ICON_DIV = <div className="DL_ICON">{icon}</div>
        const BTMLINE_DIV = <div className="DL_ICON_BTMLINE" style={{ width: 31 }} />
        const TITLE_DIV = <h3 className="DL_TITLE" style={{ color: (highlight && device !== 'MOBILE') ? 'var(--mainpink)' : 'var(--maindark)' }}>{title}</h3>
        const CONTENT_DIV = <h4 className={"DL_CONTENT_L_" + device}>{content}</h4>
        const IMG_DIV = <div className='DL_SINGLE_IMG_LEFT' key={'img' + i} ref={addToImgRefs} alt={''} style={{ display: 'flex', opacity: 0, width: 'max-content', height: 'max-content', position: 'relative' }}>
            <img className='DL_IMG' src={img} alt={''} style={{ border: 'none' }} />
            {mimg.length > 0
                ? <div className={'DL_SINGLE_MIMGLEFT' + device} key={'mimg' + i} >
                    <img src={IPHONE_FRANE} alt={''} style={{ width: '100%', height: 'inherit', position: 'absolute' }} />
                </div>
                : null}
        </div>
        const SENSOR_DIV = <div className='DL_SENSOR' ref={addToSensorRefs} />
        return (
            <div className="DL_SINGLE" key={'DL_SINGLE' + i}>
                <div className="DL_TOP">
                    {SENSOR_DIV}
                    <div className="DL_ICON_ROOT" >
                        {ICON_DIV}
                        {BTMLINE_DIV}
                    </div>
                    <div className="DL_TITLE_N_CONTENT">
                        {TITLE_DIV}
                        {CONTENT_DIV}
                        {IMG_DIV}
                    </div>
                </div>
                {titles.length !== i_
                    ? <div className="DL_BTM" >
                        <div className="DL_TOPRIGHT" style={{ minWidth: 60, height: '50%', display: 'flex', placeContent: 'flex-end' }}>
                            <div style={{ minWidth: 30, height: '100%', borderBottom: '1px solid #707070', borderLeft: '1px solid #707070', borderBottomLeftRadius: 5 }} />
                        </div>
                        <div className="DL_MID" />
                        <div className="DL_BTMLEFT" style={{ minWidth: 60, minHeight: '100%', display: 'flex', placeContent: 'flex-start' }}>
                            <div style={{ minWidth: 30, height: 26, alignSelf: 'flex-end', borderTop: '1px solid #707070', borderRight: '1px solid #707070', borderTopRightRadius: 5 }} />
                        </div>
                    </div>
                    : <div className="DL_ICON" style={{ width: 60, marginBottom: 10 }}>{iconwrapper(HEARTUSERLOGIN_ICON, { width: 33, height: 33 })}</div>}
            </div>
        )
    }

    function right(title, content, icon, i, img, mimg, highlight) {
        const i_ = i + 1
        const ICON_DIV = <div className="DL_ICON">{icon}</div>
        const BTMLINE_DIV = <div className="DL_ICON_BTMLINE" style={{ width: 30 }} />
        const TITLE_DIV = <h3 className="DL_TITLE" style={{ placeContent: 'flex-end', color: (highlight && device !== 'MOBILE') ? 'var(--mainpink)' : 'var(--maindark)' }}>{title}</h3>
        const CONTENT_DIV = <h4 className={"DL_CONTENT_R_" + device} style={{ placeSelf: 'flex-end', textAlign: 'end' }}>{content}</h4>
        const IMG_DIV = <div className='DL_SINGLE_IMG' key={'img' + i} ref={addToImgRefs} alt={''} style={{ display: 'flex', opacity: 0, width: 'max-content', height: 'max-content', position: 'relative', alignSelf: 'flex-end', }}>
            {/* <img className='DL_IMG' src={img} alt={''} style={{ border: '1px solid #c4c3c3' }} /> */}
            <img className='DL_IMG' src={img} alt={''} style={{ border: 'none' }} />
            {mimg.length > 0
                ? <div className={'DL_SINGLE_MIMGRIGHT' + device} key={'mimg' + i} >
                    <img src={IPHONE_FRANE} alt={''} style={{ width: '100%', height: 'inherit', position: 'absolute' }} />
                </div>
                : null}
        </div>
        const SENSOR_DIV = <div className="DL_SENSOR" ref={addToSensorRefs} />
        return (
            <div className="DL_SINGLE" key={'DL_SINGLE' + i} style={{ alignItems: 'flex-end' }}>
                <div className="DL_TOP">
                    <div className="DL_TITLE_N_CONTENT">
                        {TITLE_DIV}
                        {CONTENT_DIV}
                        {IMG_DIV}
                    </div>
                    {SENSOR_DIV}
                    <div className="DL_ICON_ROOT" >
                        {ICON_DIV}
                        {BTMLINE_DIV}
                    </div>
                </div>
                {titles.length !== i_
                    ? <div className="DL_BTM" >
                        <div className="DL_BTMLEFT" style={{ minWidth: 60, minHeight: '100%', display: 'flex', placeContent: 'flex-end' }}>
                            <div style={{ minWidth: 30, height: 26, borderTop: '1px solid #707070', borderTopLeftRadius: 5, borderLeft: '1px solid #707070', alignSelf: 'flex-end' }} />
                        </div>
                        <div className="DL_MID" />
                        <div className="DL_TOPRIGHT" style={{ minWidth: 60, minHeight: '100%', display: 'flex', placeContent: 'flex-start' }}>
                            <div style={{ minWidth: 30, height: 25, borderBottom: '1px solid #707070', borderBottomRightRadius: 5, borderRight: '1px solid #707070' }} />
                        </div>
                    </div>
                    : <div className="DL_ICON" style={{ width: 60, marginBottom: 10 }}>{iconwrapper(HEARTUSERLOGIN_ICON, { width: 33, height: 33 })}</div>}
            </div>
        )
    }

    return (
        <div className="DL_ROOT">
            {parseInt(highlightindex) > -1 && device === 'WEB'
                ? <div className='DL_WEBMENU'>
                    {titles.map((title, i) => {
                        return <div
                            key={'menu_' + title + '_' + i}
                            className={highlightindex === i ? 'DL_SELECTEDTITLE' : 'DL_NONSELECTEDTITLE'}
                        >
                            {title}
                        </div>
                    })}
                </div>
                : null}
            {titles.map((title, i) => i % 2 === 0
                ? left(title, contents[i], icons[i], i, images[i], mimages[i], highlightindex === i)
                : right(title, contents[i], icons[i], i, images[i], mimages[i], highlightindex === i))}
        </div>
    )
}

export default Directedline