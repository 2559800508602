
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Checkdevice, ScreenWidth, Vendorcard } from '../../../../components';
import Vendorcardmobile from '../../../../components/Vendorcard/Vendorcardmobile';
import Vendorcardtablet from '../../../../components/Vendorcard/Vendorcardtablet';
import { VENDORCARD_WT_MOBILE, VENDORCARD_WT_WEB, VENDORCARD_WT_LARGEMOBILE, VENDORCARD_WT_SMALLMOBILE } from '../../../../config/constants'; //MAX_WEB

function VendorCards(props) {

    const { vendorContent, selectedVendorType, favourite, favfunc, unfavfunc, abbrev_userlocation } = props

    const device = Checkdevice()
    const wt = ScreenWidth()

    const [mobiledevice, setMobiledevice] = useState(-1) // 0 - like the normal web vendor card, 1 different type vendor card
    const [tabletdevice, setTabletdevice] = useState(-1) // 0 smaller wt card, 1,  wider wt card

    useEffect(() => {

        if (device === 'MOBILE') {
            if (wt < 480) {
                setMobiledevice(0)
            }
            else if (wt < 700) {
                setMobiledevice(1)
            }
            else {
                if (wt < 800) {
                    setMobiledevice(2)
                }
            }

        }
        else if (device === 'TABLET') {
            if (wt < 797) {
                setTabletdevice(0)
            }
            else {
                setTabletdevice(1)
            }
        }
    }, [wt])

    return {
        'WEB': <ul style={{ display: 'grid', gridTemplateColumns: `repeat(auto-fit, 264px)`, justifyContent: 'center', width: '100%', padding: 10, margin: 0, }}>
            {vendorContent.map((item) => (
                <Vendorcard
                    cardwt={250}
                    cardmargin={7}
                    key={item.username}
                    favfunc={favfunc}
                    unfavfunc={unfavfunc}
                    content={item}
                    type={'WEB'}
                    userlike={favourite.includes(item._id)}
                    vendortype={item.vendortype}
                    vendortype_id={item.vendortype_id}
                    secondaryvendortype={item.secondaryvendortype}
                    vendortypeinterface_id={item._id}
                    c0={item.c0}
                    selectedvt={selectedVendorType}
                    abbrev_userlocation={abbrev_userlocation}
                    updateHeart={(userlike, vti_id) => { props.updateHeart(userlike, vti_id) }}
                    tier={item.tier}
                />
            ))}
        </ul>,
        'TABLET': tabletdevice === 0
            // smaller card
            ? <ul style={{ display: 'grid', gridTemplateColumns: `repeat(auto-fit, 240px)`, justifyContent: 'center', width: '100%', padding: 10, margin: 0, }}>
                {vendorContent.map((item) => (
                    <Vendorcardtablet
                        cardwt={230}
                        cardmargin={5}
                        key={item.username}
                        favfunc={favfunc}
                        unfavfunc={unfavfunc}
                        content={item}
                        type={'WEB'}
                        userlike={favourite.includes(item._id)}
                        vendortype={item.vendortype}
                        vendortype_id={item.vendortype_id}
                        secondaryvendortype={item.secondaryvendortype}
                        vendortypeinterface_id={item._id}
                        c0={item.c0}
                        selectedvt={selectedVendorType}
                        abbrev_userlocation={abbrev_userlocation}
                        updateHeart={(userlike, vti_id) => { props.updateHeart(userlike, vti_id) }}
                        tier={item.tier}
                    />
                ))}
            </ul>

            // wider card
            : <ul style={{ display: 'grid', gridTemplateColumns: `repeat(auto-fit, 254px)`, justifyContent: 'center', width: '100%', padding: 10, margin: 0, }}>
                {vendorContent.map((item) => (
                    <Vendorcardtablet
                        cardwt={240}
                        cardmargin={7}
                        key={item.username}
                        favfunc={favfunc}
                        unfavfunc={unfavfunc}
                        content={item}
                        type={'WEB'}
                        userlike={favourite.includes(item._id)}
                        vendortype={item.vendortype}
                        vendortype_id={item.vendortype_id}
                        secondaryvendortype={item.secondaryvendortype}
                        vendortypeinterface_id={item._id}
                        c0={item.c0}
                        selectedvt={selectedVendorType}
                        abbrev_userlocation={abbrev_userlocation}
                        updateHeart={(userlike, vti_id) => { props.updateHeart(userlike, vti_id) }}
                        tier={item.tier}
                    />
                ))}
            </ul>,
        'MOBILE': {
            '-1': null,
            '0': <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                {vendorContent.map((item) => (
                    <Vendorcardmobile
                        cardwt={'calc(100% - 20px)'}
                        cardmargin={10}
                        cardimgdim={150}
                        key={item.username}
                        favfunc={favfunc}
                        unfavfunc={unfavfunc}
                        content={item}
                        type={'WEB'}
                        userlike={favourite.includes(item._id)}
                        vendortype={item.vendortype}
                        vendortype_id={item.vendortype_id}
                        secondaryvendortype={item.secondaryvendortype}
                        vendortypeinterface_id={item._id}
                        c0={item.c0}
                        selectedvt={selectedVendorType}
                        abbrev_userlocation={abbrev_userlocation}
                        updateHeart={(userlike, vti_id) => { props.updateHeart(userlike, vti_id) }}
                        tier={item.tier}
                    />
                ))}
            </div>,
            '1': <ul style={{ display: 'grid', gridTemplateColumns: `repeat(auto-fit,50%)`, justifyContent: 'center', width: '100%', padding: 5, margin: 0, }}>
                {vendorContent.map((item) => (
                    <Vendorcardtablet
                        cardwt={'calc(100%-10px)'}
                        cardmargin={5}
                        key={item.username}
                        favfunc={favfunc}
                        unfavfunc={unfavfunc}
                        content={item}
                        type={'WEB'}
                        userlike={favourite.includes(item._id)}
                        vendortype={item.vendortype}
                        vendortype_id={item.vendortype_id}
                        secondaryvendortype={item.secondaryvendortype}
                        vendortypeinterface_id={item._id}
                        c0={item.c0}
                        selectedvt={selectedVendorType}
                        abbrev_userlocation={abbrev_userlocation}
                        updateHeart={(userlike, vti_id) => { props.updateHeart(userlike, vti_id) }}
                        tier={item.tier}
                    />
                ))}
            </ul>,
            '2': <ul style={{ display: 'grid', gridTemplateColumns: `repeat(auto-fit,33.33333%)`, justifyContent: 'center', width: '100%', padding: 5, margin: 0, }}>
                {vendorContent.map((item) => (
                    <Vendorcardtablet
                        cardwt={'calc(100%-10px)'}
                        cardmargin={5}
                        key={item.username}
                        favfunc={favfunc}
                        unfavfunc={unfavfunc}
                        content={item}
                        type={'WEB'}
                        userlike={favourite.includes(item._id)}
                        vendortype={item.vendortype}
                        vendortype_id={item.vendortype_id}
                        secondaryvendortype={item.secondaryvendortype}
                        vendortypeinterface_id={item._id}
                        c0={item.c0}
                        selectedvt={selectedVendorType}
                        abbrev_userlocation={abbrev_userlocation}
                        updateHeart={(userlike, vti_id) => { props.updateHeart(userlike, vti_id) }}
                        tier={item.tier}
                    />
                ))}
            </ul>
        }[mobiledevice.toString()]
    }[device]

}

export default VendorCards