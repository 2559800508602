import React from 'react';
import './Appcard.css'

function Appcard({ id, icon, content, onClick, selected, device }) {
    var dn = device === 'WEB' || device === 'TABLET' ? 'w' : 'mt'
    return (
        <button
            key={id}
            id={id}
            className={selected ? "APP_CARD_ROOT_SELECTED_" + dn : "APP_CARD_ROOT_" + dn}
            onClick={onClick}
        >
            {selected ? <div className="APP_CARD_TOPBAR_SELECTED" /> : <div className="APP_CARD_TOPBAR_NONSELECTED" />}
            {icon ? <div className="APP_CARD_ICON">{icon}</div> : null}
            <div className={icon ? "APP_CARD_CONTENT" : "APP_CARD_CONTENTLONG"}>{content}</div>
        </button>
    )
}
export default Appcard