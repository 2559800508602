import React, { useEffect, useState } from 'react'
import { subtitle } from '../Commonfunction'
import { EMPTYSPACE, INVERSE_READABLE_VENDORTYPE_MAP, IMG_ALT } from '../../../../config/constants'
import { ALL_VENDOR_READ_PARTNER } from '../../../../config/api'
import axios from 'axios'
import { Link, } from "react-router-dom";
import { getS3fullpath_withbucketname } from '../../../../config/commonfunctions'

const emptydom = <div style={{ height: 200, borderRadius: 5, color: 'var(--subgrey)', display: 'flex', placeContent: 'center', fontSize: 24 }} >
</div>

const partnertitle = 'Collaborators and partners that the vendor preferred.'

function reconstructdata(ppid_map, links) {

    var new_ppid_map = {}
    var vts = Object.keys(ppid_map)

    for (let x = 0; x < vts.length; x++) {

        var curr_vt = vts[x]
        new_ppid_map[curr_vt] = []

        for (let y = 0; y < ppid_map[curr_vt].length; y++) {

            var profileimg_id = ppid_map[curr_vt][y][0]
            var username = ppid_map[curr_vt][y][1]

            for (let i = 0; i < links.length; i++) {
                const { _id, link } = links[i]
                if (profileimg_id === _id) {
                    // new_ppid_map[curr_vt].push([username, link])
                    new_ppid_map[curr_vt].push(
                        <Link style={{ width: 62, height: 62, margin: 5, border: '1px solid var(--subgrey)', borderRadius: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={link + i} to={'/vendorlookup/' + username} target="_blank">
                            <img
                                alt={IMG_ALT}
                                style={{ overflow: 'hidden', width: 60, height: 60, borderRadius: 60, padding: 2 }}
                                src={getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', link)}
                            />
                        </Link>
                    )
                    break
                }
            }
        }
    }

    return [new_ppid_map, vts]
}

function Renderpartners(props) {

    const [firsttime, setFirsttime] = useState(false)
    const [ppid_map, setPpid_map] = useState({})
    const [vts, setVts] = useState([])
    const [havevendors, setHavevendors] = useState(false)

    useEffect(() => { // cdm
        if (!firsttime) {
            setFirsttime(true)
        }
    }, [props])

    useEffect(() => {
        let havevendors = false
        const vts = Object.keys(ppid_map)
        for (let i = 0; i < vts.length; i++) {
            const key = vts[i]
            if (ppid_map[key].length>0) {
                havevendors = true
                setHavevendors(havevendors)
                break
            }
        }
    }, [ppid_map])

    useEffect(() => {
        if (firsttime) {
            const { vendortype, displayname } = props
            let options = {
                method: 'POST',
                url: ALL_VENDOR_READ_PARTNER,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', },
                data: { vt: vendortype, dn: displayname }
            }
            axios(options).then((item) => {
                const { status, data } = item
                const { ppid_map, links } = data // error, message, 
                console.log('data', data)
                if (status === 200) {

                    var result = reconstructdata(ppid_map, links)
                    var new_ppid_map = result[0]
                    var vts = result[1]
                    setPpid_map(new_ppid_map)
                    setVts(vts)

                }
                else {

                }
            })

        }
    }, [firsttime])

    return (
        <div style={{ display: 'flex', padding: '30px 0px 40px', justifyContent: 'center', flexDirection: 'column', width: '100%', }}>
            {subtitle('Partner', partnertitle)}
            {havevendors
                ? <div style={{ margin: '0px 10px', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                    {vts.map((vt) => {
                        return ppid_map && ppid_map[vt].length > 0
                            ? (
                                <div key={vt + 'tx'} style={{ display: 'flex', flexDirection: 'column', color: 'var(--maindark)', width: '100%', }}>
                                    <h4>{vt}</h4>
                                    <div style={{ display: 'flex', flexDirection: 'row', borderRadius: 5, border: '1px solid var(--subgrey)', overflowX: 'auto' }}>
                                        {ppid_map[vt]}
                                    </div>
                                </div>
                            )
                            : null
                    })}
                </div>
                : <div style={{ margin: '0px 10px' }}>
                    {emptydom}
                </div>}

        </div>
    )

}

export default Renderpartners
