import React, { useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'

const useClosewarning = (msg = "Are you sure?") => {
    const [dirty, setDirty] = useState(false)

    useEffect(() => {
        window.onbeforeunload = dirty && (() => 'Are you sure?')
        return () => window.onbeforeunload = null
    }, [dirty])

    const routerPrompt = <Prompt when={dirty} message={msg} />

    return [routerPrompt, () => setDirty(true), () => setDirty(false)]
}

export default useClosewarning