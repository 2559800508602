import React, { useState, useEffect } from 'react'
import { HOME_COLLECTION_VENDORTYPE_WEDDING, HOME_COLLECTION_VENDORTYPE_EVENT, QUIZ_DOT_ABSOLUTE, EMPTY, READABLE_VENDORTYPE_MAP, VT_QUIZ_MAP, WEDDINGVT_ICON_MAP } from '../../../../config/constants'
import { ScreenWidth, ScreenHeight, Checkdevice, Portalmodal } from '../../../../components'
import { DOTDOTDOT_ICON, FILTERCURSOR_ICON, ARROWDOWN_ICON, PENCILTHICK_ICON, VENUE_ICON } from '../../../../config/svgicons'
import { iconwrapper } from '../../../../config/commonfunctions'
import { useRef } from 'react'
import FilterModalContent from '../FilterModalContent/FilterModalContent'
import { Link } from 'react-router-dom'
import './MainSelectionBox.css'
const vtwt = 100

function MainSelectionBox(props) {

    const screen_width = ScreenWidth()
    const screen_height = ScreenHeight()
    const device = Checkdevice()
    const { name, totalfilternum } = props
    const [firsttime, setFirsttime] = useState(true)// indicate is first time
    const [selectedvt, setSelectedvt] = useState(EMPTY)
    const [selectedcat, setSelectedcat] = useState(EMPTY) // Wedding or Event
    const [HOME_COLLECTION_VENDORTYPE, SET_HOME_COLLECTION_VENDORTYPE] = useState([]) // default is wedding
    const [boxtype, setBoxtype] = useState('SLIM')

    const [counter, setCounter] = useState(-1) // left btn counter
    const [count, setCount] = useState(0)
    const [vtsegment, setVtsegment] = useState([]) // bar segment
    const [expand, setExpand] = useState(true)

    const segrefs = useRef([]);
    const slimrefs = useRef([]);
    const [refdone, setRefdone] = useState(false) // ensure ref got something
    const [scrolltop, setScrolltop] = useState(-1)

    // vt portal
    const [showvt, setShowvt] = useState(false)

    // filter portal
    const [showfilter, setShowfilter] = useState(false)

    const [wtsnapshot, setWtsnapshot] = useState(-1)
    const wt = ScreenWidth()

    // useEffect(() => {
    //     console.log('totalfilternum', totalfilternum)
    //     // console.log('props.filters', props.filters.length)
    // }, [totalfilternum])

    // const retrieve_segment_count = (vt) => {
    //     let count = 0
    //     for (let i = 0; i < vtsegment.length; i++) {
    //         for (let j = 0; j < vtsegment[i].length; j++) {
    //             const { value } = vtsegment[i][j]
    //             if (value === vt) {
    //                 const val = i
    //                 count = val
    //                 break
    //             }
    //         }
    //     }
    //     return count
    // }

    useEffect(() => {
        if (firsttime) {
            const { selectedcat, selectedvt } = props
            setSelectedcat(selectedcat)
            setSelectedvt(selectedvt)
            SET_HOME_COLLECTION_VENDORTYPE(selectedcat === 'Wedding' ? HOME_COLLECTION_VENDORTYPE_WEDDING : HOME_COLLECTION_VENDORTYPE_EVENT)
            setWtsnapshot(wt)
            setFirsttime(false)
            props.doneloading(true)
        }
    }, [props])

    useEffect(() => {
        if (selectedvt !== props.selectedvt) {
            setSelectedvt(props.selectedvt)
        }
    }, [props.selectedvt])

    useEffect(() => { // update of boxtype, boxtype will change if user scrolls
        const { boxtype } = props
        setBoxtype(boxtype)
    }, [props.boxtype])

    useEffect(() => {
        if (selectedvt) {
            window.scroll(0, 0)
            props.vtChange(selectedvt)
        }
    }, [selectedvt])

    useEffect(() => {

        // WEB SINGLE VT : 100px

        if (!firsttime) {

            if (device === 'WEB' || device === 'TABLET') {
                let vendortypebar_wt

                if (boxtype === 'FAT') {
                    // WEB FAT:    35px | 10px | 40px |  vendortype x-axis bar  | 40px | 10px | 35px
                    vendortypebar_wt = wt - (40 * 2) - (40 * 2)
                    // vendortypebar_wt = wt - 35 - 10 - 40 - 40 - 10 - 35
                }
                else if (boxtype === 'SLIM') {
                    if (selectedcat === 'Wedding' && name === 'Wedding Vendors' && (Object.keys(VT_QUIZ_MAP).includes(selectedvt))) {
                        // WEB SLIM :   |  10px  |  leftbtn - 40px  |  vendortype x-axis bar  |  rightbtn - 40px  |  45px   |  45px  |  45px  |  10px  |
                        vendortypebar_wt = wt - 10 - 40 - 40 - 45 - 45 - 45 - 10
                    }
                    else {
                        // WEB SLIM :   |  10px  |  leftbtn - 40px  |  vendortype x-axis bar  |  rightbtn - 40px  |  45px   |  45px  |  10px  |
                        vendortypebar_wt = wt - 10 - 40 - 40 - 45 - 45 - 10
                    }
                }

                let number_of_viewable_vt = Math.floor(vendortypebar_wt / 100)
                let total_available_vt = HOME_COLLECTION_VENDORTYPE.length
                let click_counter_available = Math.ceil(total_available_vt / number_of_viewable_vt)
                let vtsegment = []

                segrefs.current = []
                slimrefs.current = []
                for (let x = 0; x < click_counter_available; x++) {
                    vtsegment.push([])
                    if (boxtype === 'SLIM') {
                        slimrefs.current.push(React.createRef());
                    }
                    else if (boxtype === 'FAT') {
                        segrefs.current.push(React.createRef());
                    }
                }

                // console.log('number_of_viewable_vt', number_of_viewable_vt)
                // console.log('click_counter_available', click_counter_available)
                // console.log('click_counter_available', click_counter_available)
                // console.log(vtsegment)
                // console.log(HOME_COLLECTION_VENDORTYPE)

                let segmentcounter = 0
                for (let x = 0; x < HOME_COLLECTION_VENDORTYPE.length; x++) {
                    vtsegment[segmentcounter].push(HOME_COLLECTION_VENDORTYPE[x])
                    if (vtsegment[segmentcounter].length === number_of_viewable_vt) {
                        segmentcounter += 1
                    }
                    if (segmentcounter === click_counter_available) break;
                }
                setVtsegment(vtsegment)
                setCounter(click_counter_available)
                setCount(0)
                setRefdone(true)
            }
        }
    }, [wt, firsttime, boxtype, selectedcat, expand])

    useEffect(() => {
        // close vt select when wt dimension change when device is MOBILE/TABLET
        if (showvt && wt !== wtsnapshot) {
            document.body.style.position = ''
            document.body.style.overflow = ''
            document.documentElement.scrollTop = 0
            document.getElementById('app-root').style.overflow = ''
            setShowvt(false)
            setScrolltop(-1)
            setWtsnapshot(wt)
        }
    }, [wt])

    useEffect(() => {
        try {
            if (boxtype === 'FAT') {
                if (segrefs.current[count] && segrefs.current[count].current) {
                    segrefs.current[count].current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
                }
            }
            else if (boxtype === 'SLIM') {
                if (slimrefs.current[count] && slimrefs.current[count].current) {
                    slimrefs.current[count].current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
                }
            }
        }
        catch (e) { }
    }, [count])


    // useEffect(() => {

    //     try {
    //         if(refdone){
    //             let count = retrieve_segment_count(selectedvt)
    //             // alert(count)
    //             if (boxtype === 'FAT' && segrefs.current[count] && segrefs.current[count].current) {

    //                 console.log('scroll fat')
    //                 // segrefs.current[count].current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
    //                 segrefs.current[count].current.scrollIntoView({ inline: "nearest" })


    //             }
    //             else if (boxtype === 'SLIM' && slimrefs.current[count] && slimrefs.current[count].current) {
    //                 console.log('scroll slim')
    //                 // slimrefs.current[count].current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
    //                 slimrefs.current[count].current.scrollIntoView({ inline: "nearest" })
    //             }
    //         }

    //     }
    //     catch (e) { }

    // }, [refdone, selectedvt, boxtype, segrefs, segrefs.current,slimrefs, slimrefs.current ])




    // useEffect(() => {

    //     let count = retrieve_segment_count()
    //     console.log(count, segrefs, slimrefs, boxtype, selectedvt)
    //     if (boxtype === 'FAT' && segrefs.current[count]) {
    //         if (segrefs.current[count].current) {
    //             // alert('FAT ' + count)
    //             // segrefs.current[count].current.scrollIntoView({ behavior: 'smooth', block: "center", inline: "nearest" })
    //             // segrefs.current[count].current.scrollIntoView()
    //             segrefs.current[count].current.scrollIntoView({behavior:'instant'})

    //         }
    //     }
    //     else if (boxtype === 'SLIM' && slimrefs.current[count]) {
    //         if (slimrefs.current[count].current) {
    //             // alert('SLIM ' + count)
    //             // slimrefs.current[count].current.scrollIntoView({ behavior: 'smooth', block: "center", inline: "nearest" })
    //             // slimrefs.current[count].current.scrollIntoView()
    //             slimrefs.current[count].current.scrollIntoView({behavior:'instant'})

    //         }
    //     }

    //     // alert(count)
    //     // setCount(count)

    // }, [segrefs, segrefs.current, slimrefs, slimrefs.current])


    const portalmidcss_web = {
        width: 600,
        height: 660,
        top: 'calc( 50% - 330px )',
        backgroundColor: 'white',
        left: 0, right: 0,
        margin: 'auto',
        transform: 'none',
    }

    const portalmidcss_mobile = {
        width: '100%',
        height: '100%',
        top: 0,
        backgroundColor: 'white',
        left: 0, right: 0,
        margin: 'auto',
        transform: 'none'
    }

    const portalmidcsswtlarger_mobile = {
        width: '100%',
        height: '100%',
        top: 0,
        backgroundColor: 'white',
        left: 0, right: 0,
        margin: 'auto',
        transform: 'none',
        overflowX: 'scroll'
    }

    const vt_portal = <Portalmodal
        portaltype={'commonportal'}
        open={showvt}
        onClose={() => {
            document.getElementById('app-root').style.overflowY = ''
            if (device === 'MOBILE') {
                document.body.style.position = ''
                document.documentElement.scrollTop = scrolltop
                setScrolltop(-1)
            }
            document.body.style.overflow = ''
            setShowvt(false)
        }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={(device === 'WEB' || device === 'TABLET') ? portalmidcss_web : screen_width > screen_height ? portalmidcsswtlarger_mobile : portalmidcss_mobile}
        closestyle={{ right: 0 }}
        floatingclosecolor='var(--maindark)'
    >
        <div className='Portalmodal_vtroot'>
            <div className='FMC_ROOTMOBILE'>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, 100px)', placeContent: 'center', margin: 0, padding: (device === 'WEB' || device === 'TABLET') ? '0px 10px' : 0 }}>

                    {HOME_COLLECTION_VENDORTYPE.map((item, index) => {

                        const { value, icon } = item

                        const changevt = () => {
                            document.getElementById('app-root').style.overflowY = ''
                            if (device === 'MOBILE') {
                                document.body.style.position = ''
                                setScrolltop(-1)
                            }
                            document.body.style.overflow = ''
                            setSelectedvt(value)
                            setShowvt(false)
                        }

                        if (value === 'All') { // NO ICON
                            return <div className={`MSB_vticon ${selectedvt === value ? 'MSB_vticonselected' : ''}`} onClick={changevt} key={index + 'vt'} style={{ minWidth: vtwt, maxWidth: vtwt, height: vtwt }}>
                                <div className='MSB_vticon_vtname' style={{ fontSize: 16 }}>All</div>
                            </div>
                        }
                        else { // WITH ICON
                            return <div className={`MSB_vticon ${selectedvt === value ? 'MSB_vticonselected' : ''}`} onClick={changevt} key={index + 'vt'} style={{ minWidth: vtwt, maxWidth: vtwt, height: vtwt, placeContent: 'center' }}>
                                {iconwrapper(icon, { width: 30, height: 30, })}
                                <div className='MSB_vticon_vtname' style={{ fontSize: 12 }}>{READABLE_VENDORTYPE_MAP[value]}</div>
                                {Object.keys(VT_QUIZ_MAP).includes(value) > 0 && name === 'Wedding Vendors'
                                    ? QUIZ_DOT_ABSOLUTE
                                    : null}
                            </div>
                        }

                    })}
                </div>
            </div>

        </div>
    </Portalmodal>

    const filterportal_close = () => {
        document.getElementById('app-root').style.overflowY = ''
        if (device === 'MOBILE') {
            document.body.style.position = ''
            document.documentElement.scrollTop = scrolltop
            setScrolltop(-1)
        }
        document.body.style.overflow = ''
        setShowfilter(false)
    }

    const filter_portal = <Portalmodal
        portaltype={'commonportal'}
        open={showfilter}
        onClose={filterportal_close}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={(device === 'WEB' || device === 'TABLET') ? portalmidcss_web : screen_width > screen_height ? portalmidcsswtlarger_mobile : portalmidcss_mobile}
        closestyle={{ right: 0 }}
        floatingclosecolor='var(--maindark)'
    >
        <div className='Portalmodal_vtroot'>
            {props.from === 'WEDDING' || props.from === 'EVENT'
                ? <FilterModalContent
                    onApplyFilter={(e) => {

                        document.getElementById('app-root').style.overflowY = ''
                        if (device === 'MOBILE') {
                            document.body.style.position = ''
                            document.documentElement.scrollTop = scrolltop
                            setScrolltop(-1)
                        }
                        document.body.style.overflow = ''
                        props.applyFilter(e)
                        setShowfilter(false)
                    }}
                    filtersetting={props.filters}
                    usertype={props.selectedcat}// Wedding or Event
                    selectedVendorType={props.selectedvt}
                />
                : props.filtermainDom(props.selectedcat, filterportal_close)}
        </div>
    </Portalmodal>

    const scrollright_btn = count === 0
        ? <div className='MSB_emptyarrow' />
        : <button
            className='MSB_arrow' key={'FAT_LEFT'}
            onClick={() => { setCount((count) => count - 1); }}
        >
            {iconwrapper(ARROWDOWN_ICON, { width: 25, height: 25, transform: 'rotate(90deg)' })}
        </button>

    const scrollleft_btn = count === counter - 1
        ? <div className='MSB_emptyarrow' />
        : <button
            className='MSB_arrow' key={'FAT_RIGHT'}
            onClick={() => { setCount((count) => count + 1); }}
        >
            {iconwrapper(ARROWDOWN_ICON, { width: 25, height: 25, transform: 'rotate(-90deg)', color: 'var(--subdark)' })}
        </button>

    if (!firsttime) {
        if (device === 'TABLET' || device === 'WEB') {
            const { name, nameClick, selectedcat } = props // filtermodalDom_web, filterelemDom, openFiltermodal

            const open_vt_portal = () => {
                document.body.style.overflow = 'hidden'
                document.getElementById('app-root').style.overflowY = 'scroll'
                setShowvt(true)
            }

            const open_filter_portal = () => {
                document.body.style.overflow = 'hidden'
                document.getElementById('app-root').style.overflowY = 'scroll'
                setShowfilter(true)

                if (props.from === 'PRICELIST') {
                    props.updatebeforeopenPlfilterdom()
                }

            }

            const open_quiz_page = props.quizFunc


            if (boxtype === 'FAT') {

                return <div className="MSB_fatroot">
                    <div className='MSB_fattop'>

                        <button className='MSB_faname' onClick={() => nameClick(selectedcat)}>{name}</button>

                        {!expand
                            ? <div className='MSB_fatvtroot'>

                                {scrollright_btn}

                                <div className='MSB_vtscroll'>

                                    {vtsegment.map((segment, index) => {

                                        const changevt = (value) => { setSelectedvt(value) }

                                        return <div className='MSB_vgsegment' ref={segrefs.current[index]} id={'fatsegment' + index} key={'fatsegment' + index}>

                                            {segment.map((item, index) => {
                                                const { value, icon } = item
                                                if (value === 'All') { // NO ICON
                                                    return <div className={`MSB_vticon ${selectedvt === value ? 'MSB_vticonselected' : ''}`} onClick={() => changevt(value)} key={index + 'vt'} style={{ minWidth: vtwt, maxWidth: vtwt, height: vtwt }}>
                                                        <div className='MSB_vticon_vtname' style={{ fontSize: 16 }}>All</div>
                                                    </div>
                                                }
                                                else { // WITH ICON
                                                    return <div className={`MSB_vticon ${selectedvt === value ? 'MSB_vticonselected' : ''}`} onClick={() => changevt(value)} key={index + 'vt'} style={{ minWidth: vtwt, maxWidth: vtwt, height: vtwt }}>
                                                        {iconwrapper(icon, { width: 30, height: 30, })}
                                                        <div className='MSB_vticon_vtname' style={{ fontSize: 12 }}>{READABLE_VENDORTYPE_MAP[value]}</div>
                                                        {Object.keys(VT_QUIZ_MAP).includes(value) > 0 && name === 'Wedding Vendors'
                                                            ? QUIZ_DOT_ABSOLUTE
                                                            : null}
                                                    </div>
                                                }

                                            })}

                                        </div>

                                    })}

                                </div>

                                {scrollleft_btn}

                            </div>
                            : <div className="MSB_vtbtnsgrid" >

                                {HOME_COLLECTION_VENDORTYPE.map((item, index) => {
                                    const { value, icon } = item

                                    const changevt = () => {
                                        setSelectedvt(value)
                                    }

                                    if (value === 'All') { // NO ICON
                                        return <div className={`MSB_vticon ${selectedvt === value ? 'MSB_vticonselected' : ''}`} onClick={changevt} key={index + 'vt'} style={{ minWidth: vtwt, maxWidth: vtwt, height: vtwt }}>
                                            <div className='MSB_vticon_vtname' style={{ fontSize: 16 }}>All</div>
                                        </div>
                                    }
                                    else { // WITH ICON
                                        return <div className={`MSB_vticon ${selectedvt === value ? 'MSB_vticonselected' : ''}`} onClick={changevt} key={index + 'vt'} style={{ minWidth: vtwt, maxWidth: vtwt, height: vtwt }}>
                                            {iconwrapper(icon, { width: 30, height: 30, })}
                                            <div className='MSB_vticon_vtname' style={{ fontSize: 12 }}>{READABLE_VENDORTYPE_MAP[value]}</div>
                                            {Object.keys(VT_QUIZ_MAP).includes(value) > 0 && name === 'Wedding Vendors'
                                                ? QUIZ_DOT_ABSOLUTE
                                                : null}
                                        </div>
                                    }

                                })}
                            </div>}

                        <button className='MSB_fatexpandhidebtn' onClick={() => { setExpand(!expand); }}>{!expand ? 'Expand' : 'Hide'}</button>

                    </div>
                    <div className='MSB_fatbtm'>

                        <div style={{ display: 'flex', flexDirection: 'row', placeContent: 'start', height: 60, alignItems: 'end', border: '1px solid var(--subgrey)', borderRadius: 15, marginTop: 15, placeContent: 'space-between', overflow: 'hidden' }}>
                            <button className='MSB_vtshowbtn'>
                                {READABLE_VENDORTYPE_MAP[selectedvt]}
                            </button>
                            <button
                                className='planning_editbtn'
                                style={{ minWidth: 110, placeSelf: 'center', border: 'none', height: '100%', padding: 0, margin: 0, backgroundColor: 'transparent', borderRadius: 0, position: 'relative' }}
                                onClick={open_filter_portal}
                            /*  need to save prev filter_arr_bool state */
                            >
                                {iconwrapper(FILTERCURSOR_ICON, { width: 30, height: 30, color: 'var(--maindark)', padding: 5, margin: 0, display: 'flex', alignItems: 'center', placeContent: 'center' })}
                                <div style={{ display: 'flex', flexDirection: 'row', placeContent: 'center', alignItems: 'center' }}>
                                    <div style={{ padding: '0px 5px', letterSpacing: 0.5 }}>Filter</div>
                                    {totalfilternum ? <div style={{ display: 'flex', fontSize: 10, backgroundColor: 'var(--maindark)', color: '#ffffff', borderRadius: 25, width: 20, height: 20, placeContent: 'center', alignItems: 'center' }}>{totalfilternum}</div> : null}
                                </div>
                            </button>
                            {selectedcat === 'Wedding' && name === 'Wedding Vendors' && (Object.keys(VT_QUIZ_MAP).includes(selectedvt))
                                ? <div
                                    onClick={open_quiz_page}
                                    style={{ minWidth: 100, display: 'flex', placeSelf: 'center', height: 40, textAlign: 'center', placeContent: 'center', alignItems: 'center', color: 'var(--maindark)', cursor: 'pointer', height: '100%', borderLeft: '1px solid var(--subgrey)', position: 'relative' }}
                                >
                                    {QUIZ_DOT_ABSOLUTE}
                                    {iconwrapper(PENCILTHICK_ICON, { width: 30, height: 30, color: 'var(--maindark)', padding: 5, margin: 0, display: 'flex', alignItems: 'center', placeContent: 'center' })}
                                    <div style={{ padding: '0px 5px', letterSpacing: 0.5 }}>Quiz</div>
                                </div>
                                : null}

                        </div>
                        {selectedcat === 'Wedding' && selectedvt === 'Venue'
                            ? <button
                                className='MSB_visualdata'
                                style={{ width: 140, flexDirection: 'row', display: 'flex', alignContent: 'center', placeItems: 'center', overflow: 'hidden' }}
                                onClick={() => document.getElementById('comparevenuelink').click()}
                            >
                                <Link id='comparevenuelink' style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/compare/weddingvenue'} target="_blank" />
                                {/* <img
                                 src="https://images.unsplash.com/photo-1523975804200-ad0324aaf9ed?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzB8fHdlZGRpbmclMjBiYW5xdWV0fGVufDB8fDB8fHww" 
                                 style={{
                                    backgroundSize: 'cover',
                                    display: 'flex',
                                    width:'45%',
                                    height:'100%',
                                    objectFit:'cover'
                                 }}
                                 /> */}


                                <div style={{
                                    height: '100%',
                                    backgroundImage: `url(https://images.unsplash.com/photo-1523975804200-ad0324aaf9ed?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzB8fHdlZGRpbmclMjBiYW5xdWV0fGVufDB8fDB8fHww)`,
                                    backgroundSize: 'cover',
                                    display: 'flex',
                                    width: '40%'
                                }} />


                                <div style={{ flexDirection: 'column', display: 'flex', width: '60%' }}>
                                    <div>Compare</div>
                                    <div>Venues</div>
                                </div>
                            </button>
                            : null}
                    </div>
                    {filter_portal}
                </div>

            }
            else if (boxtype === 'SLIM') {

                const { name, selectedcat } = props // nameClick

                const dotdotdot_btn = <button
                    onClick={open_vt_portal}
                    className='planning_editbtn'
                    style={{ placeSelf: 'center' }}
                >
                    {iconwrapper(DOTDOTDOT_ICON, { width: 20, height: 20, color: 'var(--maindark)', padding: 5, margin: 0, display: 'flex', alignItems: 'center', placeContent: 'center', transform: 'rotate(90deg)' })}
                </button>

                const qn_btn = selectedcat === 'Wedding' && name === 'Wedding Vendors' && (Object.keys(VT_QUIZ_MAP).includes(selectedvt))
                    ? <button
                        onClick={open_quiz_page}
                        className='planning_editbtn'
                        style={{ placeSelf: 'center', position: 'relative', }}
                    >
                        {/* <div style={{ display: 'flex', padding: 3, margin: 3, borderRadius: 10, backgroundColor: '#ff647f', border: '1px solid white', position: 'absolute', top: 0, right: 0 }} /> */}
                        <div style={{ position: 'absolute', top: -1, right: -1, margin: -2 }}>{QUIZ_DOT_ABSOLUTE}</div>
                        {iconwrapper(PENCILTHICK_ICON, { width: 30, height: 30, color: 'var(--maindark)', padding: 5, margin: 0, display: 'flex', alignItems: 'center', placeContent: 'center' })}
                    </button>
                    : null

                const filter_btn = <button
                    className='planning_editbtn'
                    style={{ placeSelf: 'center' }}
                    key={'slim_web_filter'}
                    onClick={open_filter_portal}
                >
                    {iconwrapper(FILTERCURSOR_ICON, { width: 30, height: 30, color: 'var(--maindark)', padding: 5, margin: 0, display: 'flex', alignItems: 'center', placeContent: 'center' })}
                </button>

                return <div className="MSB_slimroot">
                    <div className='MSB_slimtop'>
                        {scrollright_btn}
                        <div className="MSB_slimvtsroot">

                            {vtsegment.map((segment, index) => {
                                return <div
                                    ref={slimrefs.current[index]}
                                    id={'slimsegment' + index}
                                    key={'slimsegment' + index}
                                    style={{ display: 'flex', flexDirection: 'row' }}
                                >
                                    {segment.map((item, index) => {
                                        const { value, icon } = item
                                        const changevt = () => { setSelectedvt(value) }

                                        if (value === 'All') { // NO ICON
                                            return <div className={`MSB_vticon ${selectedvt === value ? 'MSB_vticonselected' : ''}`} onClick={changevt} key={index + 'vt'} style={{ minWidth: vtwt, maxWidth: vtwt, height: vtwt, placeContent: 'center' }}>
                                                <div className='MSB_vticon_vtname' style={{ fontSize: 16 }}>All</div>
                                            </div>
                                        }
                                        else { // WITH ICON
                                            return <div className={`MSB_vticon ${selectedvt === value ? 'MSB_vticonselected' : ''}`} onClick={changevt} key={index + 'vt'} style={{ minWidth: vtwt, maxWidth: vtwt, height: vtwt, placeContent: 'flex-end' }}>
                                                {iconwrapper(icon, { width: 30, height: 30, })}
                                                <div className='MSB_vticon_vtname' style={{ fontSize: 12 }}>{READABLE_VENDORTYPE_MAP[value]}</div>
                                                {Object.keys(VT_QUIZ_MAP).includes(value) > 0 && name === 'Wedding Vendors'
                                                    ? QUIZ_DOT_ABSOLUTE
                                                    : null}
                                            </div>
                                        }

                                    })}

                                </div>

                            })}

                        </div>
                        {scrollleft_btn}
                        {dotdotdot_btn}
                        {qn_btn}
                        {filter_btn}
                    </div>
                    {filter_portal}
                    {vt_portal}
                </div>
            }
        }
        else if (device === 'MOBILE') {
            const { name, nameClick, boxtype } = props // openFiltermodal,filtermodalDom_mobile, filtermodalDom_web,

            const open_vt_portal_mobile = () => {
                setScrolltop(document.documentElement.scrollTop)
                document.body.style.position = 'fixed'
                document.body.style.overflow = 'hidden'
                setShowvt(true)
            }

            const open_filter_portal_mobile = () => {
                setScrolltop(document.documentElement.scrollTop)
                document.body.style.position = 'fixed'
                document.body.style.overflow = 'hidden'
                setShowfilter(true)
            }

            const open_quiz_page = props.quizFunc

            const qn_btn = selectedcat === 'Wedding' && name === 'Wedding Vendors' && (Object.keys(VT_QUIZ_MAP).includes(selectedvt))
                ? <button
                    onClick={open_quiz_page}
                    className='planning_editbtn'
                    style={{ position: 'relative', placeSelf: 'center', height: '100%', border: 'none', borderRadius: 0, background: 'none', border: 'none', borderLeft: '1px solid var(--subgrey)', margin: 0, minWidth: 50, height: 50 }}
                >
                    <div style={{ display: 'flex', padding: 3, margin: 3, borderRadius: 10, backgroundColor: '#ff647f', border: '1px solid white', position: 'absolute', top: 0, right: 0 }} />
                    {iconwrapper(PENCILTHICK_ICON, { width: 30, height: 30, color: 'var(--maindark)', padding: 5, margin: 0, display: 'flex', alignItems: 'center', placeContent: 'center' })}

                </button>
                : null

            const filter_btn = <button
                className='planning_editbtn'
                style={{ position: 'relative', placeSelf: 'center', height: '100%', border: 'none', borderRadius: 0, background: 'none', border: 'none', borderLeft: '1px solid var(--subgrey)', margin: 0, minWidth: 50, minHeight: 50 }}
                key={'mobile_filter'}
                onClick={open_filter_portal_mobile}
            >
                {iconwrapper(FILTERCURSOR_ICON, { width: 30, height: 30, color: 'var(--maindark)', padding: 5, margin: 0, display: 'flex', alignItems: 'center', placeContent: 'center' })}
            </button>


            return (
                <div id='MainSelectionBox_mobileslim' style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'row', color: 'var(--maindark)', alignItems: 'center', zIndex: 3, padding: !props.mobilesticky ? '0px 10px' : '', borderBottom: props.mobilesticky ? '1px solid var(--subgrey)' : '', boxShadow: props.mobilesticky ? 'rgb(0 0 0 / 16%) 0 0 6px' : '' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>

                        {boxtype === 'FAT'
                            ? <div style={{ fontSize: 32, display: 'flex', width: '100%', justifyContent: 'center', fontWeight: 'bold' }} onClick={nameClick}>{name}</div>
                            : null}

                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', height: 50, borderRadius: 5, ...props.mobilesticky ? { borderBottom: 'none' } : { border: '1px solid var(--subgrey)' } }}>

                            <button
                                style={{ width: '100%', position: 'relative', height: 40, border: 'none', color: 'var(--maindark)', padding: 0, backgroundColor: 'white', outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', borderRadius: 5, margin: '5px 0px', borderRadius: 5 }}
                                onClick={open_vt_portal_mobile}
                            >

                                <div style={{ fontSize: 14, placeContent: 'center', alignItems: 'center', position: 'absolute', display: 'flex', color: 'var(--maindark)', width: '100%', height: '100%', flexDirection: 'row' }}>
                                    {selectedvt === 'All' ? null : iconwrapper(WEDDINGVT_ICON_MAP[selectedvt], { width: 30, height: 30, color: 'var(--maindark)', margin: '0px 5px 0px 5px', padding: 5, display: 'flex', alignItems: 'center', placeContent: 'center' })}
                                    <div>{selectedvt === '' ? '' : READABLE_VENDORTYPE_MAP[selectedvt]}</div>
                                </div>

                            </button>

                            {qn_btn}
                            {filter_btn}

                        </div>

                    </div>
                    {vt_portal}
                    {filter_portal}
                </div >
            )
        }
    }
    else {
        return null
    }

}

export default MainSelectionBox