import React, { Component } from 'react'
import { WEB } from '../config/constants'

class Sticky extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollingLock: false,
            width: 0,
            height: 0,
        };
        // example how to bind object in React ES6
        this.handleScroll = this.handleScroll.bind(this)
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    handleScroll() {
        const { height, callback } = this.props

        callback != undefined || callback != null ? callback(window.scrollY) : null // eslint-disable-line

        if (window.scrollY > height) {
            // console.log("should lock");
            this.setState({
                scrollingLock: true
            });
        } else if (window.scrollY < height) {

            // console.log("not locked");
            this.setState({
                scrollingLock: false
            });
        }

    }

    render() {
        const { scrollingLock } = this.state
        const { width, children } = this.props
        if (width < WEB) {
            return (// on mobile
                <div style={{ position: scrollingLock ? "fixed" : "relative", top: 0, backgroundColor: 'white', width: width, zIndex: scrollingLock ? 10 : 3 }}>
                    {children}
                </div>
            )
        }
        else {
            return (
                <div style={{ position: scrollingLock ? "fixed" : "relative", top: 0, backgroundColor: 'white', width: width, display: 'block', zIndex: scrollingLock ? 10 : 3 }}>
                    {children}
                </div>
            )

        }


    }
}

export default Sticky