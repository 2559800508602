import React, { useState, useEffect } from 'react'
import { Banners, Checkdevice, Leftrightbtn, } from './../../../../../../components' // Palette, Texture 
import Explain from '../Explain'
import Border from '../Border/Border'
import Highlighttitle from '../Highlighttitle/Highlighttitle'
import { TTCOLOR_CARD_MAP } from '../../../../../../config/constants'
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { iconwrapper } from '../../../../../../config/commonfunctions'
import { BG_ICON, FRAME_ICON, BANNER_ICON, COLOR_ICON, LAPTOP_ICON, MOBILE_ICON } from '../../../../../../config/svgicons'
import { inputdescribe_dom, partialtitle_dom } from '../Commonelements/Commonelements'
import { FLORALTOPLFTBTMRIGHT_ICON, FLORALTOPRIGHTBTMLEFT_ICON, FLORALBTM_ICON, FLORALTOP_ICON, FLORALMID_ICON, FLORALRIGHT_ICON, FLORALLEFT_ICON, FLORALCEILFLOOR_ICON, FLORALCEIL_ICON, FLORALFLOOR_ICON, SQUARECIRCLE_ICON, CIRCLESQUARE_ICON, SQUARESQUARE_ICON, CIRCLECIRCLE_ICON } from '../../../../../../config/svgicons'
import { allimages, banners, bannernames } from '../../../../../../assets/invitationcard/index1'
import './background.css'
import '../components.css'

const TITLE = 'Design'
const TEXT = `Select different design background for your invitation.`
const myiconcss = { width: 42, height: 42, }
const TYPE_ICON_MAP = {
    'Design': iconwrapper(BG_ICON, myiconcss),
    'Frame': iconwrapper(FRAME_ICON, myiconcss),
    "Banners": iconwrapper(BANNER_ICON, myiconcss),
    "Colors": iconwrapper(COLOR_ICON, myiconcss),
}
const smalliconcss = { width: 40, height: 40, }

const ORIENTATIONS_ICONS = [
    iconwrapper(CIRCLECIRCLE_ICON, smalliconcss),
    iconwrapper(CIRCLESQUARE_ICON, smalliconcss),
    iconwrapper(SQUARECIRCLE_ICON, smalliconcss),
    iconwrapper(SQUARESQUARE_ICON, smalliconcss),
]

const FORMAT_ICONS = [
    iconwrapper(FLORALTOPRIGHTBTMLEFT_ICON, smalliconcss),
    iconwrapper(FLORALTOPLFTBTMRIGHT_ICON, smalliconcss),
    iconwrapper(FLORALBTM_ICON, smalliconcss),
    iconwrapper(FLORALTOP_ICON, smalliconcss),
    iconwrapper(FLORALMID_ICON, smalliconcss),
    iconwrapper(FLORALRIGHT_ICON, smalliconcss),
    iconwrapper(FLORALLEFT_ICON, smalliconcss),
    iconwrapper(FLORALCEILFLOOR_ICON, smalliconcss),
    iconwrapper(FLORALCEIL_ICON, smalliconcss),
    iconwrapper(FLORALFLOOR_ICON, smalliconcss)
]

function Background(props) {
    const { save, updateBackup, clearBackup, restoreBackup, updateSelectedsubpage, updateSubpageback, selectedsubpage, subpageexit, updateSubpageexit } = props
    const [firsttime, setFirsttime] = useState(true)
    const [selectedstyletype, setSelectedstyletype] = useState(null)
    const [selectedstyleformation, setSelectedstyleformation] = useState(0)
    const [selectedstyleformationmobile, setSelectedstyleformationmobile] = useState(0)
    const [selectedstyleorientation, setSelectedstyleorientation] = useState(0)
    const [selectedstyleorientationmobile, setSelectedstyleorientationmobile] = useState(0)
    const [itypeoption, setItypeoption] = useState(null) // will keep chaning if user decide to
    const [itype, setItype] = useState(null) // init once, and will no longer change
    const [templatetype, setTemplatetype] = useState(null)
    const [wt, setWt] = useState(null)
    const [showsave, setShowsave] = useState(false) // indicate save activate, when user make any savable actions.
    const [bgcat, setBgcat] = useState('All') // '', All, Floral
    const [bgs, setBgs] = useState(new Map())
    const device = Checkdevice()
    const commoneditstyle = {
        height: {
            'WEB': 'calc(100% - 70px - 65px)',
            'TABLET': 'calc(100% - 70px - 65px)',
            'MOBILE': 'calc(100% - 70px)'
        }[device],
        marginBottom: {
            'MOBILE': 65
        }[device]
    }

    useEffect(() => {
        if (firsttime) {
            const { selectedStyletype, itype, templatetype, wt, selectedStyleformation, selectedStyleformationmobile, selectedStyleorientation, selectedStyleorientationmobile } = props
            setBgcat('All')
            setBgs(allimages)
            setSelectedstyletype(selectedStyletype)
            setTemplatetype(templatetype)
            setItypeoption(itype)
            setItype(itype)
            setWt(wt - 74)
            setSelectedstyleformation(selectedStyleformation ? selectedStyleformation : 0)
            setSelectedstyleformationmobile(selectedStyleformationmobile ? selectedStyleformationmobile : 0)
            setSelectedstyleorientation(selectedStyleorientation ? selectedStyleorientation : 0)
            setSelectedstyleorientationmobile(selectedStyleorientationmobile ? selectedStyleorientationmobile:0)
            setFirsttime(false)
        }
    }, [])

    useEffect(() => {
        if (subpageexit) { // triggered if user click exit from Highlighttitle and tick yes for saveprompt
            setShowsave(false)
            restoreBackup()
            updateSelectedsubpage('')
            updateSubpageexit()
        }
    }, [subpageexit])

    useEffect(() => {
        const { selectedStyletype,  selectedStyleformation, selectedStyleformationmobile, selectedStyleorientation, selectedStyleorientationmobile } = props
        if(!firsttime){
            setSelectedstyletype(selectedStyletype)
            setSelectedstyleformation(selectedStyleformation)
            setSelectedstyleformationmobile(selectedStyleformationmobile)
            setSelectedstyleorientation(selectedStyleorientation)
            setSelectedstyleorientationmobile(selectedStyleorientationmobile)
        }
    }, [props])

    useEffect(() => {
        if (!firsttime) {
            if (props.selectedStyletype !== -1) setSelectedstyletype(props.selectedStyletype)
            if (props && props.wt && props.wt !== wt) setWt(props.wt - 74)
        }
    }, [props])

    const DEVICE_SIZE_MAP = {
        'WEB': { minHeight: Math.floor(wt / 3), minWidth: Math.floor(wt / 3), maxHeight: Math.floor(wt / 3), maxWidth: Math.floor(wt / 3), borderRadius: 10, },
        'TABLET': { minHeight: Math.floor(wt / 2), minWidth: Math.floor(wt / 2), maxHeight: Math.floor(wt / 2), maxWidth: Math.floor(wt / 2), borderRadius: 10, },
        'MOBILE': { minHeight: Math.floor(wt / 0.9), minWidth: Math.floor(wt / 0.9), maxHeight: Math.floor(wt / 0.9), maxWidth: Math.floor(wt / 0.9), backgroundSize: Math.floor(wt / 0.9), borderRadius: 14, },
        // 'MOBILE': { minHeight: Math.floor(wt / 0.9), minWidth: Math.floor(wt / 0.9), maxHeight: Math.floor(wt / 0.9), maxWidth: Math.floor(wt / 0.9), backgroundSize: Math.floor(wt / 0.9), borderRadius: 14, },
    }

    function gen_content(allitems) {
        if (selectedsubpage.length > 0) return null
        return Object.keys(allitems).map((itemtxt, index) => {
            if (itemtxt === '' || itemtxt === ' ' || itemtxt === '  ') {
                return <div key={"empty_content" + index} />
            }
            else {
                return <div
                    className="Ieditcomp_sq"
                    key={itemtxt + index}
                    onClick={() => {
                        if (itemtxt === selectedsubpage) {
                            updateSelectedsubpage('');
                        }
                        else {
                            updateSelectedsubpage(itemtxt);
                        }
                    }}
                >
                    <div className='Ieditcomp_sqtop'>{TYPE_ICON_MAP[itemtxt]}</div>
                    <div className='Ieditcomp_sqbtm'>{itemtxt}</div>
                </div>
            }
        })
    }

    const btmbarroot_css = {
        'MOBILE': { position: 'fixed', bottom: 0, left: 0, width: '100%' },
        'TABLET': { position: 'fixed', bottom: 10, left: 0, width: props.wt - 1 },
        'WEB': { position: 'fixed', bottom: 0, left: 0, width: props.wt - 1 }
    }[device]

    const revert = () => {
        setShowsave(false)
        restoreBackup()
    }

    const btmbar = <div className='iedit_swaproot' style={btmbarroot_css}>
        <button
            className='iedit_cancel'
            disabled={!showsave}
            style={{ opacity: !showsave ? 0.5 : 1 }}
            onClick={revert}>
            Revert
        </button>
        <button
            className='iedit_save'
            disabled={!showsave}
            style={{ opacity: !showsave ? 0.5 : 1 }}
            onClick={() => {
                save().then((result) => {
                    if (result === 'SUCCESS') {
                        clearBackup()
                        setShowsave(false)
                    }
                })
            }}
        >
            Save
        </button>
    </div>


    const [selecteddevice, setSelecteddevice] = useState(-1) // 0:WEB || 1:MOBILE
    useEffect(() => {
        // console.log('selecteddevice', selecteddevice)
    }, [selecteddevice])

    const selecteddevicefunc = (e) => {
        var val = parseInt(e.target.value, 10)
        val === selecteddevice ? setSelecteddevice(-1) : setSelecteddevice(val)
    }

    const WEDDING_ITEMS_MAP = {
        'Design': itypeoption
            ? <div className='Ieditcomp_div' style={commoneditstyle}>
                <Explain title={TITLE} txt={TEXT} />

                {/* Format */}
                <div className='Details_grp'>
                    {partialtitle_dom('Device Specific Settings', {})}
                    {inputdescribe_dom('Assign different design format for different devices')}
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                        <button
                            key={'titlebtn_0' + selecteddevice}
                            className={`Details_settingbtn ${selecteddevice === 0 ? 'Details_selectedbtn' : selecteddevice !== -1 ? 'Details_selectednone' : ''}`}
                            value={0}
                            onClick={selecteddevicefunc}
                        >
                            {iconwrapper(LAPTOP_ICON, { width: 27, height: 27, })}
                            <div>Desktop</div>
                        </button>
                        <button
                            key={'titlebtn_1' + selecteddevice}
                            className={`Details_settingbtn ${selecteddevice === 1 ? 'Details_selectedbtn' : selecteddevice !== -1 ? 'Details_selectednone' : ''}`}
                            value={1}
                            onClick={selecteddevicefunc}

                        >
                            {iconwrapper(MOBILE_ICON, { width: 27, height: 27, })}
                            <div>Mobile</div>
                        </button>
                    </div>
                    {[
                        // WEB
                        <div className='Details_lineacontainer' style={{ marginBottom: 0 }}>

                            <div className='Details_grp' style={{ marginBottom: 0 }}>
                                {partialtitle_dom('Formatting', { margin: '10px 10px 0px 10px' })}
                                {inputdescribe_dom(`Number of line to display your name and your spouse's name.`, { marginLeft: 10 })}
                                <div className='Details_linercol' style={{ marginBottom: 2 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', overflowX: 'scroll' }}>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '0px 5px 10px 5px', placeContent: 'flex-start', padding: 1, borderRadius: 5 }}>
                                            {bgs.get(selectedstyletype).bgformat.map((item, index) => {
                                                const { WEB_TABLET } = item
                                                return WEB_TABLET.state
                                                    ? <div
                                                        key={'bgformat_WT_' + index}
                                                        style={{ color: selectedstyleformation === index ? 'var(--mainpink)' : '' }}
                                                        onClick={() => {
                                                            if (!showsave) { updateBackup(); setShowsave(true); }
                                                            props.updateStyleformation(index);
                                                            setSelectedstyleformation(index);
                                                        }}>
                                                        {FORMAT_ICONS[index]}
                                                    </div>
                                                    : null
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Design Orientation */}
                            <div className='Details_grp'>
                                {partialtitle_dom('Design Orientation', { margin: '10px 10px 0px 10px' })}
                                {inputdescribe_dom('Select Image orientation', { marginLeft: 10 })}
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '0px 10px', placeContent: 'flex-start', padding: 1, borderRadius: 5 }}>
                                    {bgs
                                        .get(selectedstyletype)
                                        .bgformat[selecteddevice === 0 ? selectedstyleformation : selectedstyleformationmobile]
                                        .orientation_allow_list
                                        .map((alloworientation, index) => {
                                            const { state } = alloworientation
                                            if (state) {
                                                return <div
                                                    key={'orient_' + index}
                                                    style={{ color: ((selecteddevice === 0 && selectedstyleorientation === index) || (selecteddevice === 1 && selectedstyleorientationmobile === index)) ? 'var(--mainpink)' : '', marginRight: 5 }}
                                                    onClick={() => {
                                                        if (!showsave) { updateBackup(); setShowsave(true); }

                                                        if (selecteddevice === 0) { // WEBTABLET
                                                            props.updateStyleorientation(index);
                                                            setSelectedstyleorientation(index);
                                                        }
                                                        else { // MOBILe
                                                            props.updateStyleorientationmobile(index);
                                                            setSelectedstyleorientationmobile(index);
                                                        }

                                                    }}
                                                >
                                                    {ORIENTATIONS_ICONS[index]}
                                                </div>
                                            }
                                            else return null
                                        })}
                                </div>
                            </div>

                        </div>,
                        // MOBILE
                        <div className='Details_lineacontainer' style={{ marginBottom: 0 }}>

                            <div className='Details_grp' style={{ marginBottom: 0 }}>
                                {partialtitle_dom('Formatting', { margin: '10px 10px 0px 10px' })}
                                {inputdescribe_dom(`Format of background based on device.`, { marginLeft: 10 })}
                                <div className='Details_linercol' style={{ marginBottom: 2 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', overflowX: 'scroll' }}>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '0px 5px 10px 5px', placeContent: 'flex-start', padding: 1, borderRadius: 5 }}>
                                            {bgs.get(selectedstyletype).bgformat.map((item, index) => {
                                                const { MOBILE } = item
                                                return MOBILE.state
                                                    ? <div
                                                        key={'bgformat_MOBILE_' + index}
                                                        style={{ color: selectedstyleformationmobile === index ? 'var(--mainpink)' : '' }}
                                                        onClick={() => {

                                                            // art
                                                            if (!showsave) { updateBackup(); setShowsave(true); }


                                                            props.updateStyleformationmobile(index);
                                                            setSelectedstyleformationmobile(index);

                                                            props.updateStyleorientationmobile(0);
                                                            setSelectedstyleorientationmobile(0);

                                                        }}>
                                                        {FORMAT_ICONS[index]}
                                                    </div>
                                                    : null
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Design Orientation */}
                            <div className='Details_grp'>
                                {partialtitle_dom('Design Orientation', { margin: '10px 10px 0px 10px' })}
                                {inputdescribe_dom('Select Image orientation', { marginLeft: 10 })}
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '0px 10px', placeContent: 'flex-start', padding: 1, borderRadius: 5 }}>
                                    {bgs
                                        .get(selectedstyletype)
                                        .bgformat[selecteddevice === 0 ? selectedstyleformation : selectedstyleformationmobile]
                                        .orientation_allow_list
                                        .map((alloworientation, index) => {
                                            const { state } = alloworientation
                                            if (state) {
                                                return <div
                                                    key={'orient_' + index}
                                                    style={{ color: ((selecteddevice === 0 && selectedstyleorientation === index) || (selecteddevice === 1 && selectedstyleorientationmobile === index)) ? 'var(--mainpink)' : '', marginRight: 5 }}
                                                    onClick={() => {
                                                        if (!showsave) { updateBackup(); setShowsave(true); }
                                                        if (selecteddevice === 0) { // WEBTABLET
                                                            props.updateStyleorientation(index);
                                                            setSelectedstyleorientation(index);
                                                        }
                                                        else { // MOBILe
                                                            props.updateStyleorientationmobile(index);
                                                            setSelectedstyleorientationmobile(index);
                                                        }

                                                    }}
                                                >
                                                    {ORIENTATIONS_ICONS[index]}
                                                </div>
                                            }
                                            else return null
                                        })}
                                </div>
                            </div>

                        </div>

                    ][selecteddevice]}
                </div>

                <div className='Details_grp'>
                    {partialtitle_dom('Select Designs', {})}
                    {inputdescribe_dom('Designs to carter your need')}
                    <div style={{ display: 'flex', flexDirection: 'row', margin: '5px 0px' }}>
                        <button
                            value='All'
                            key={bgcat + '_All'}
                            onClick={(e) => {
                                setBgcat(e.target.value)
                                setBgs(allimages)
                            }}
                            style={{ height: 30, padding: '0px 10px', marginRight: 5, border: `1px solid var(${bgcat === 'All' ? '--mainpink' : '--maindark'})`, color: `var(${bgcat === 'All' ? '--mainpink' : '--maindark'})`, backgroundColor: 'white', borderRadius: 5 }}
                        >
                            All
                        </button>
                        <button
                            value='Floral'
                            key={bgcat + '_Floral'}
                            onClick={(e) => {
                                setBgcat(e.target.value)
                                const newbgs = new Map();
                                allimages.forEach((value, key) => {
                                    if (value.cat === e.target.value) {
                                        newbgs.set(key, value)
                                    }
                                });
                                setBgs(newbgs)
                            }}
                            style={{ height: 30, padding: '0px 10px', marginRight: 5, border: `1px solid var(${bgcat === 'Floral' ? '--mainpink' : '--maindark'})`, color: `var(${bgcat === 'Floral' ? '--mainpink' : '--maindark'})`, backgroundColor: 'white', borderRadius: 5 }}
                        >
                            Floral
                        </button>
                    </div>
                    <ul className={"bg_btmroot" + device} style={{ display: 'grid', gridTemplateColumns: `repeat(auto-fit, minmax(${DEVICE_SIZE_MAP[device].minWidth}px, max-content))`, justifyContent: 'center', width: '100%', padding: '0px 0px 30px 0px', alignContent: 'flex-start', }}>
                        {[...bgs.keys()].map((imgkey, index) => {
                            const imgobj = bgs.get(imgkey)
                            const { name, background, thumbnail, banner, corner, itype, bgformat } = imgobj
                            const thumbnail_url = thumbnail.url
                            const css = { backgroundImage: `url(${thumbnail_url})` }
                            return <button
                                id='testtt'
                                key={'s' + index}
                                style={{ border: selectedstyletype === imgkey ? '3px solid var(--mainpink)' : '3px solid transparent', display: 'flex', placeContent: 'flex-start', flexDirection: 'column', borderRadius: 5, alignItems: 'center', padding: 3, boxShadow: 'rgb(235 235 235) 0px 0px 0px 1px inset', backgroundColor: 'white' }}
                                onClick={() => {
                                    if (!showsave) { updateBackup(); setShowsave(true); }
                                    props.updateStyleAndBanner(imgkey, banner.url)

                                    // update to initial state
                                    setSelectedstyleformation(0)
                                    setSelectedstyleformationmobile(0)
                                    setSelectedstyleorientation(0)
                                    setSelectedstyleorientationmobile(0)
                                }}
                            >
                                <div
                                    className="bg_common"
                                    style={{ overflow: 'hidden', ...DEVICE_SIZE_MAP[device] }}
                                >
                                    <div className="leftbg" style={css} />
                                    <div className="bg_emptycard" style={{ height: DEVICE_SIZE_MAP[device].minHeight * 0.85 + 'px' }} />
                                    <div className="rightbg" style={css} />
                                </div>
                                <div style={{ display: 'flex', width: '100%', placeContent: "center", padding: '10px 5px', fontSize: 13, textAlign: 'center', }}>
                                    {name}
                                </div>
                            </button>

                        })}
                    </ul>
                    <div style={{ height: 100 }} />
                </div>

                {btmbar}
            </div>
            : null,
        'Frame': <Border
            commoneditstyle={commoneditstyle}
            udpatebackup={() => { if (!showsave) { updateBackup(); setShowsave(true); } }}
            btmbar={btmbar}
            wt={props.wt}
            styleborder={props.selectedStyleborder} updateStyleborder={props.updateStyleborder}
            styleborderwt={props.styleborderwt} updateStyleborderwt={props.updateStyleborderwt}
            styleborderbr={props.styleborderbr} updateStyleborderbr={props.updateStyleborderbr}
            stylebordercolor={props.stylebordercolor} updateStylebordercolor={props.updateStylebordercolor}
        />,
        'Banners': <div className='Ieditcomp_div' style={commoneditstyle}>
            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', position: 'relative' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 15 }}>
                    <Leftrightbtn
                        onLeft={() => {
                            if (!showsave) { updateBackup(); setShowsave(true); }
                            const index = banners.indexOf(props.sep)
                            index === 0 ? props.updateSep(banners[banners.length - 1]) : props.updateSep(banners[index - 1])
                        }}
                        onRight={() => {
                            if (!showsave) { updateBackup(); setShowsave(true); }
                            const index = banners.indexOf(props.sep)
                            index === banners.length - 1 ? props.updateSep(banners[0]) : props.updateSep(banners[index + 1])
                        }}
                    />
                </div>
                <Banners
                    select={(item) => {
                        const { img } = item
                        if (!showsave) { updateBackup(); setShowsave(true); }
                        if (img !== props.sep) props.updateSep(img)
                    }}
                    selected={props.sep}
                    banners={banners}
                    names={bannernames}
                />
            </div>
            {btmbar}
        </div>,
        'Colors': <div className='Ieditcomp_div' style={commoneditstyle}>
            <div className='Details_grp'>
                {partialtitle_dom('Page Background Color', {})}
                {inputdescribe_dom(`Define the background color of your page.`)}
                <div className='Detailsfont_container'>
                    <div className='Detailsfont_left'>
                        <HexColorPicker
                            style={{ height: 200, width: 200 }}
                            color={props.selectedTemplatecolor}
                            onChange={(c) => {
                                if (!showsave) { updateBackup(); setShowsave(true); }
                                props.updateTemplatecolor(c);
                                props.updateTemplatebgcolor('');
                            }}
                        />
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
                            <div style={{ paddingRight: 3, fontSize: 18 }}>#</div>
                            <HexColorInput
                                style={{ width: '100%', borderRadius: 8, padding: '3px 5px', border: '1px solid var(--maindark)' }}
                                color={props.selectedTemplatecolor}
                                onChange={(c) => {
                                    if (!showsave) { updateBackup(); setShowsave(true); }
                                    props.updateTemplatecolor(c);
                                    props.updateTemplatebgcolor('');
                                }}
                            />
                        </div>
                    </div>
                    <ul className='Detailsfont_right'>
                        {TTCOLOR_CARD_MAP[props.selectedTemplate].map((c, i) => {
                            return <div key={i + 'color1'} style={{ padding: 1, width: 'max-content', height: 'max-content', display: 'flex', placeContent: 'center', alignItems: 'center', border: props.selectedTemplatecolor === c ? '2px solid var(--mainpink)' : '2px solid transparent', borderRadius: 5 }}>
                                <li
                                    style={{ width: 20, height: 20, backgroundColor: c, border: 'none', cursor: 'pointer', borderRadius: 3, border: props.selectedTemplatecolor === c ? 'none' : '1px solid #dddddd' }}
                                    onClick={() => {
                                        if (!showsave) { updateBackup(); setShowsave(true); }
                                        props.updateTemplatecolor(c);
                                        props.updateTemplatebgcolor('');
                                    }}
                                />
                            </div>
                        })}
                    </ul>
                </div>
            </div>
            {btmbar}

        </div>,
    }

    /*
        <div className="Iedit_subtitle">Card Color</div>
        <Palette key='cardcolor_p1' 
        sc={props.selectedTemplatecolor}
        colors={TTCOLOR_CARD_MAP[props.selectedTemplate]}
        onClick={(c) => { props.updateTemplatecolor(c); props.updateTemplatebgcolor(''); }} 
        /> 
    */
    /* 0 : card texture, 1: bg texture*/
    /*
        <div className="Iedit_subtitle">Card Texture</div>
        <Texture position={0} textures={props.textures} updateTextures={props.updateTextures} collections={textures_collection} /> 
    */

    // square grid menu
    const squaremenus = {
        'Wedding': <div id={selectedsubpage.length === 0 ? "Ieditcomp_weddinggridroot" : ''}>
            {gen_content(WEDDING_ITEMS_MAP)}
            {selectedsubpage.length > 0
                ? <></>
                : <>
                    <div className='Ieditcomp_sq' style={{ border: 'none', boxShadow: 'none', cursor: 'initial' }} />
                    <div className='Ieditcomp_sq' style={{ border: 'none', boxShadow: 'none', cursor: 'initial' }} />
                    <div className='Ieditcomp_sq' style={{ border: 'none', boxShadow: 'none', cursor: 'initial' }} />
                </>}
        </div>
    }[itype]

    // square grid content
    const content = selectedsubpage.length > 0
        ? <div id="Ieditcomp_contentroot">
            <Highlighttitle onClick={updateSubpageback} icon={TYPE_ICON_MAP[selectedsubpage]} selectedsubpage={selectedsubpage} />
            {WEDDING_ITEMS_MAP[selectedsubpage]}
        </div>
        : null

    return itypeoption
        ? <div id="Ieditcomp_root">
            {squaremenus}
            {content}
        </div>
        : null

}

export default Background