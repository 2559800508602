// import React, { useState } from 'react'
// import { Link } from 'react-router-dom'
// import { READABLE_VENDORTYPE_MAP, EMPTYSPACE, ABBREVCITY_CURRENCY_MAP, BOXSHADOW_CARD, f_fs, VENDORCARD_WT_WEB, VENDORCARD_WT_MOBILE, VENDORCARD_WT_LARGEMOBILE, VENDORCARD_WT_SMALLMOBILE, SUB_FF, VC_NAME_COLOR } from './../../config/constants'
// import { Heart } from './../../async_components'
// import { Checkdevice, Processmoney, Processname } from '..'
// import { ScreenWidth } from '../Dimension';
// import './Vendorcard.css'

// function Vendorcardmobile(props) {

//     const [hover, setHover] = useState({ boxShadow: "" })
//     const DEVICE = Checkdevice()
//     const sw = ScreenWidth()

//     const getImgSrc = () => {
//         const { profileimg_id = [] } = props.content
//         if (profileimg_id.length > 0) {
//             return profileimg_id[0].link
//         }
//         else if ('link' in profileimg_id) {
//             return profileimg_id.link
//         }
//     }
//     const { content, selectedvt,  cardwt, cardmargin } = props
//     const { displayname, pricelist_num = 0, project_num = 0, review_num = 0, username, vendortype, secondaryvendortype, } = content

//     // if is all, render privt and svt
//     // if is not all, render both
//     return (
//         <div
//             onMouseOver={() => { if (DEVICE !== 'TABLET') setHover({ boxShadow: BOXSHADOW_CARD }) }}
//             onMouseLeave={() => { if (DEVICE !== 'TABLET') setHover({ boxShadow: '' }) }}
//             onClick={() => document.getElementById(username).click()}
//             style={{
//                 ...hover, cursor: 'pointer',
//                 width: cardwt, height: 410, border: '1px solid var(--subgrey)',
//                 fontFamily: 'Lato', margin: cardmargin, borderRadius: 7,
//                 color: 'var(--maindark)', backgroundColor: '#ffffff',
//             }}
//             key={username}
//         >
//             <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', placeContent: 'flex-end', height: 40 }}>
//                 <Link id={username} style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/vendorlookup/' + username} target="_blank" />
//                 <div style={{ width: 40, height: 40, display: 'flex', placeContent: 'center', alignItems: 'center' }}>
//                     <Heart
//                         vendortypeinterface_id={props.vendortypeinterface_id}
//                         vendortype={props.vendortype}
//                         secondaryvendortype={props.secondaryvendortype}
//                         vendortype_id={props.vendortype_id}
//                         userlike={props.userlike}
//                         updateHeart={props.updateHeart}
//                         parent={'Vendorcard'}
//                     />
//                 </div>
//             </div>

//             <div style={{ display: 'flex', width: '100%', placeContent: 'center', height: 150, }}>
//                 <img
//                     src={getImgSrc()}
//                     style={{
//                         borderRadius: 150, width: 150, height: 150,
//                         display: 'flex', placeContent: 'center', objectFit: 'cover',
//                         border: '1px solid var(--subgrey)'
//                     }} />
//             </div>

//             <div style={{ width: '100%', display: 'flex', placeContent: 'center', alignItems: 'center', height: 30, margin: '5px 0px', padding: '0px 5px', fontWeight: 'bold' }}>
//                 {Processname(displayname)}
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 50, padding: '0px 5px', }}>
//                 {selectedvt === 'All'
//                     ? <>
//                         <div>{READABLE_VENDORTYPE_MAP[vendortype]}</div>
//                         {secondaryvendortype === '' ? null : <div>{READABLE_VENDORTYPE_MAP[secondaryvendortype]}</div>}
//                     </>
//                     : <div>{vendortype === selectedvt ? READABLE_VENDORTYPE_MAP[vendortype] : READABLE_VENDORTYPE_MAP[secondaryvendortype]}</div>
//                 }
//             </div>

//             {props.content[props.abbrev_userlocation + 'minprice'] === 0
//                 ? <div style={{ height: 50 }} />
//                 : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 50, padding: '0px 5px', }}>
//                     <div style={{
//                         fontSize: 14,
//                         display: 'flex',
//                         flexDirection: 'row',
//                         alignItems: 'baseline',
//                         textAlign: 'center',
//                         width: '100%',
//                         justifyContent: 'center',
//                     }}>
//                         <div style={{ paddingRight: 6, fontSize: 12, fontWeight: 'bold', }}>Starts At </div>
//                         <div style={{ color: 'var(--mainpink)' }}>{'$ ' + Processmoney(props.content[props.abbrev_userlocation + 'minprice']) + ' ' + ABBREVCITY_CURRENCY_MAP[props.abbrev_userlocation]}</div>
//                     </div>
//                 </div>

//             }

//             <div style={{ width: '100%', height: 78, display: 'flex', color: 'var(--maindark)', flexDirection: 'column', borderTop: '1px solid var(--subgrey)', fontFamily: 'Lato' }}>
//                 <div style={{ width: '100%', height: '50%', display: 'flex', borderBottom: '1px solid var(--subgrey)', flexDirection: 'column', placeContent: 'center', alignItems: 'center' }}>
//                     <div style={{ fontSize: 11, }}>Review</div>
//                     <div style={{ fontSize: 13 }}>{parseInt(review_num, 10) === 0 ? '-' : review_num}</div>
//                 </div>
//                 <div style={{ width: '100%', height: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//                     <div style={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', placeContent: 'center', }}>
//                         <div style={{ fontSize: 11, }}>Project</div>
//                         <div style={{ fontSize: 13 }}>{parseInt(project_num, 10) === 0 ? '-' : project_num}</div>
//                     </div>
//                     <div style={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', placeContent: 'center', borderLeft: '1px solid var(--subgrey)' }}>
//                         <div style={{ fontSize: 11, }}>Pricelist</div>
//                         <div style={{ fontSize: 13 }}>{parseInt(pricelist_num, 10) === 0 ? '-' : pricelist_num}</div>
//                     </div>
//                 </div>
//             </div>

//         </div>
//     )


// }


// export default Vendorcardmobile



import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { READABLE_VENDORTYPE_MAP, ABBREVCITY_CURRENCY_MAP, BOXSHADOW_CARD, f_fs, VENDORCARD_WT_WEB, VENDORCARD_WT_MOBILE, VENDORCARD_WT_LARGEMOBILE, VENDORCARD_WT_SMALLMOBILE, SUB_FF, VC_NAME_COLOR, TIER_DIAMOND_POSITION } from './../../config/constants'
import { Heart } from './../../async_components'
import { Processmoney, Processname } from '..'
import { getS3fullpath_withbucketname } from '../../config/commonfunctions'
import './Vendorcard.css'

function Vendorcardmobile(props) {

    const getImgSrc = () => {
        const { profileimg_id = [] } = props.content
        if (profileimg_id.length > 0) {
            return getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', profileimg_id[0].link)
        }
        else if ('link' in profileimg_id) {
            return getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', profileimg_id.link)
        }
    }
    const { content, selectedvt, cardwt, cardmargin, cardimgdim } = props
    const { displayname, pricelist_num = 0, project_num = 0, review_num = 0, username, vendortype, secondaryvendortype, tier } = content
    const viewlookup = () => document.getElementById(username).click()

    let showprice = true
    let calprice

    if (content.vendortype === selectedvt) { // show vt price
        const pricetxt = content[props.abbrev_userlocation + 'minprice']
        calprice = Processmoney(pricetxt) + ' ' + ABBREVCITY_CURRENCY_MAP[props.abbrev_userlocation]
    }
    else if (content.secondaryvendortype === selectedvt) { // show svt price
        const pricetxt = content[props.abbrev_userlocation + 'minprice_svt']
        calprice = Processmoney(pricetxt) + ' ' + ABBREVCITY_CURRENCY_MAP[props.abbrev_userlocation]
    }
    else {
        showprice = false
        calprice = ''
    }

    // if is all, render privt and svt
    // if is not all, render both
    return (
        <div
            key={username}
            style={{
                width: cardwt, fontFamily: 'Lato', borderRadius: 5, margin: cardmargin,
                color: 'var(--maindark)', backgroundColor: '#ffffff',
                border: '1px solid var(--subgrey)',
                flexDirection: 'column'
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'row' }}>

                <div onClick={viewlookup} style={{ width: '100%', display: 'flex', placeContent: 'center', alignItems: 'flex-start', height: 65, flexDirection: 'column', paddingLeft: 10 }}>
                    <div style={{ width: '100%', display: 'flex', fontWeight: 'bold', fontSize: 16, }}>{Processname(displayname)}</div>
                    <div style={{ display: 'flex', flexDirection: 'row', placeContent: 'center', fontSize: 14 }}>
                        {selectedvt === 'All'
                            ? <>
                                <div>{READABLE_VENDORTYPE_MAP[vendortype]}</div>

                                {secondaryvendortype && secondaryvendortype.length > 0
                                    ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ padding: '0px 5px' }}>/</div>

                                        <>{READABLE_VENDORTYPE_MAP[secondaryvendortype]}</>
                                    </div>
                                    : null}
                            </>
                            : <div>{vendortype === selectedvt ? READABLE_VENDORTYPE_MAP[vendortype] : READABLE_VENDORTYPE_MAP[secondaryvendortype]}</div>
                        }
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', placeContent: 'flex-start', height: 65, width: 40, position: 'relative' }}>
                    <Link id={username} style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/vendorlookup/' + username} target="_blank" />
                    <div style={{ width: 40, height: 40, display: 'flex', placeContent: 'center', alignItems: 'center' }}>
                        <Heart
                            vendortypeinterface_id={props.vendortypeinterface_id}
                            vendortype={props.vendortype}
                            secondaryvendortype={props.secondaryvendortype}
                            vendortype_id={props.vendortype_id}
                            userlike={props.userlike}
                            updateHeart={props.updateHeart}
                            parent={'Vendorcard'}
                        />
                    </div>
                    {tier === 0
                        ? <div className='VCARD_DIAMONDROOT' style={{ margin: 0 }} />
                        : <div className={`VCARD_DIAMONDROOT VCARD_DIAMONDROOT_${tier}`} style={{ margin: 0, display: 'flex', position: 'absolute', top: 40 }}>
                            <div className='VCARD_DIAMONDCONTENT' style={{ backgroundImage: `url('https://asset.weavebliss.com/assets/sprites/diamonds.png')`, backgroundPositionY: TIER_DIAMOND_POSITION[tier].y + 'px' }} />
                        </div>}
                </div>

            </div>

            <div onClick={viewlookup} style={{ display: 'flex', flexDirection: 'row', margin: '10px 0px', padding: '0px 10px' }}>

                <div style={{ display: 'flex', width: '100%', placeContent: 'center', height: cardimgdim, width: cardimgdim, margin: 5 }}>
                    <img
                        src={getImgSrc()}
                        style={{
                            borderRadius: cardimgdim, width: cardimgdim, height: cardimgdim,
                            display: 'flex', placeContent: 'center', objectFit: 'cover', alignItems: 'center',
                            border: '1px solid var(--subgrey)'
                        }} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', placeContent: 'center', alignItems: 'center' }}>

                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', placeContent: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '45%', padding: '5px 0px', }}>
                            <div style={{ fontSize: 14, }}>Review</div>
                            <div style={{ fontSize: 16 }}>{parseInt(review_num, 10) === 0 ? '-' : review_num}</div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '45%', padding: '5px 0px', borderLeft: '1px solid #cbcbcb7d', }}>
                            <div style={{ fontSize: 14, }}>Project</div>
                            <div style={{ fontSize: 16 }}>{parseInt(project_num, 10) === 0 ? '-' : project_num}</div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '45%', padding: '5px 0px', borderTop: '1px solid #cbcbcb7d', }}>
                        <div style={{ fontSize: 14, }}>Pricelist</div>
                        <div style={{ fontSize: 16 }}>{parseInt(pricelist_num, 10) === 0 ? '-' : pricelist_num}</div>
                    </div>


                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 40, padding: '0px 5px' }}>

                        {props.content[props.abbrev_userlocation + 'minprice'] === 0
                            ? <div style={{ height: 50 }} />
                            : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 50, padding: '0px 5px', width: '100%', placeContent: 'center' }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'baseline',
                                    textAlign: 'center',
                                    width: '100%',
                                    justifyContent: 'center',
                                }}>
                                    <div style={{ paddingRight: 6, fontSize: 14, fontWeight: 'bold', whiteSpace: 'no-wrap' }}>Starts At </div>
                                    <div style={{ color: 'var(--mainpink)', fontSize: 16 }}>{'$ ' + calprice}</div>
                                </div>
                            </div>

                        }
                    </div>

                </div>


            </div>






            {/* <div style={{ width: '100%', height: 78, display: 'flex', color: 'var(--maindark)', flexDirection: 'column', borderTop: '1px solid var(--subgrey)', fontFamily: 'Lato' }}>
                <div style={{ width: '100%', height: '50%', display: 'flex', borderBottom: '1px solid var(--subgrey)', flexDirection: 'column', placeContent: 'center', alignItems: 'center' }}>
                    <div style={{ fontSize: 11, }}>Review</div>
                    <div style={{ fontSize: 13 }}>{parseInt(review_num, 10) === 0 ? '-' : review_num}</div>
                </div>
                <div style={{ width: '100%', height: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', placeContent: 'center', }}>
                        <div style={{ fontSize: 11, }}>Project</div>
                        <div style={{ fontSize: 13 }}>{parseInt(project_num, 10) === 0 ? '-' : project_num}</div>
                    </div>
                    <div style={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', placeContent: 'center', borderLeft: '1px solid var(--subgrey)' }}>
                        <div style={{ fontSize: 11, }}>Pricelist</div>
                        <div style={{ fontSize: 13 }}>{parseInt(pricelist_num, 10) === 0 ? '-' : pricelist_num}</div>
                    </div>
                </div>
            </div> */}

        </div>
    )


}


export default Vendorcardmobile