import axios from 'axios'
import { READ_USERHOMECONTENT_BULKVT, READ_USERHOMECONTENT_SINGLEVT } from '../../config/api'

export const FETCH_HOMEUSER_DATA_BULK_ACTION = 'FETCH_FETCHUSERHOMECONTENT_BULK_ACTION'
export const FETCH_HOMEUSER_DATA_BULK_ACTION_FULFILLED = 'FETCH_FETCHUSERHOMECONTENT_BULK_ACTION_FULFILLED'
export const FETCH_HOMEUSER_DATA_BULK_ACTION_PENDING = 'FETCH_FETCHUSERHOMECONTENT_BULK_ACTION_PENDING'
export const FETCH_HOMEUSER_DATA_BULK_ACTION_REJECTED = 'FETCH_FETCHUSERHOMECONTENT_BULK_ACTION_REJECTED'
export function fetchhomeuserinbulk_action(token, _id) {
    let options = {
        method: 'POST',
        url: READ_USERHOMECONTENT_BULKVT,
        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
        data: { _id }
    }
    return {
        type: FETCH_HOMEUSER_DATA_BULK_ACTION,
        payload: axios(options)
    }
}

export const FETCH_HOMEUSER_DATA_SINGLE_ACTION = 'FETCH_FETCHUSERHOMECONTENT_SINGLE_ACTION'
export const FETCH_HOMEUSER_DATA_SINGLE_ACTION_FULFILLED = 'FETCH_FETCHUSERHOMECONTENT_SINGLE_ACTION_FULFILLED'
export const FETCH_HOMEUSER_DATA_SINGLE_ACTION_PENDING = 'FETCH_FETCHUSERHOMECONTENT_SINGLE_ACTION_PENDING'
export const FETCH_HOMEUSER_DATA_SINGLE_ACTION_REJECTED = 'FETCH_FETCHUSERHOMECONTENT_SINGLE_ACTION_REJECTED'
export function fetchhomeuserinsingle_action(token, _id, filter_queries, selectedVendorType, usertype) {
    let options = {
        method: 'POST',
        url: READ_USERHOMECONTENT_SINGLEVT,
        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
        data: { _id, filter_queries, selectedVendorType, usertype }
    }
    return {
        type: FETCH_HOMEUSER_DATA_SINGLE_ACTION,
        payload: axios(options)
    }
}





