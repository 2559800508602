import React, { useEffect, useState } from 'react'
import { inputdescribe_dom, partialtitle_dom } from '../../../Invitationmaker/components/Commonelements/Commonelements'
import { iconwrapper } from '../../../../../../config/commonfunctions'
import { PLANNING_QR_ICON } from '../../../../../../config/svgicons'
import './Online.css'

function Online(props) {
    const [onlineselectedpg, setOnlineselectedpg] = useState(-1) // page design that online seatchart inherit
    const [onlinemaintxtliner, setOnlinemaintxtliner] = useState(0) // 0 - oneliner 1 - twoliner

    useEffect(() => {
        const { onlineselectedpg } = props
        setOnlineselectedpg(onlineselectedpg)
    }, [props])

    const textlayerchange = (e) => {
        setOnlinemaintxtliner(parseInt(e.target.value, 10))
    }

    const pagechange = (e) => {
        setOnlineselectedpg(e.target.value)
    }

    const saveandpreview = () => { props.updateOnlineselectedpg(onlineselectedpg, onlinemaintxtliner) }

    const selectadesign = () => {
        setOnlineselectedpg(0)
    }

    return <div id='Sconline_root'>
        <div id='Sconline_centerbox'>
            {props.pages.length > 0
                ? <>
                    {partialtitle_dom('Design & Text From Page', {})}
                    {inputdescribe_dom('Select the style from the template chosen in the Design tab.', {})}
                    <div id='Sconline_row'>
                        {props.pages.map((pg, index) => {
                            return <button
                                className={`Sconline_pageitem ${parseInt(onlineselectedpg, 10) === index ? 'Scoline_selected' : ''}`}
                                key={"pageitem" + index}
                                value={parseInt(index, 10)}
                                onClick={pagechange}
                            >
                                {index + 1}
                            </button>
                        })}
                    </div>
                </>
                : null}

            {partialtitle_dom('Main Text Format', {})}
            {inputdescribe_dom('Choose between a single line or double line for the title of your seating chart.', {})}
            <div id='Sconline_row'>
                <button id={`Sconline_layers`} className={`${parseInt(onlinemaintxtliner, 10) === 0 ? 'Scoline_selected' : ''}`} onClick={textlayerchange} value={0}>Single Line</button>
                <button id={`Sconline_layers`} className={`${parseInt(onlinemaintxtliner, 10) === 1 ? 'Scoline_selected' : ''}`} onClick={textlayerchange} value={1}>Double Line</button>
            </div>


            {partialtitle_dom('QR Code', {})}
            {inputdescribe_dom('Redirect to our QR code editor to create a QR code for your online seating chart.', {})}
            <div id='Sconline_row' onClick={props.openqr}>
                <button style={{ width: 40, height: 40, display: 'flex', placeContent: 'center', border: '1px solid var(--maindark)', borderRadius: 5, backgroundColor: '#ffffff', placeItems: 'center', margin: '3px 0px 10px 0px' }} value={0}>
                    {iconwrapper(PLANNING_QR_ICON, { width: 24, height: 24 })}
                </button>
            </div>

            <button id='Scoline_savepreview' onClick={saveandpreview}>Save And Preview </button>
            {(onlineselectedpg === -1 && props.pages.length === 0) || props.pages.length === 0
                ? <button id='Scoline_savepreview' style={{ backgroundColor: 'var(--mainpink)', color: '#ffffff', border: '1px solid var(--mainpink)' }} onClick={() => props.changetodesignmode()}>Select a Template</button>
                : onlineselectedpg === -1 && props.pages.length > 0
                    ? <button id='Scoline_savepreview' onClick={selectadesign}>Select a Design </button>
                    : <button id='Scoline_savepreview' onClick={saveandpreview}>Save And Preview </button>}

        </div>
    </div>
}

export default Online