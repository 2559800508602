import React from 'react'


// mtype 0 : image, 1 : video

function Leftdefault(props) {

    const { medias } = props

    // useEffect(() => {
    //     return () => {
    //         console.log('unmount bro')
    //         try {
    //             vidref.current.pause();
    //             vidref.current.removeAttribute('src'); // empty source
    //             vidref.current.srcObject = null
    //             vidref.current.load();

    //             audioref.current.pause();
    //             audioref.current.removeAttribute('src'); // empty source
    //             audioref.current.srcObject = null
    //             audioref.current.load();

    //             srcref.current.pause()
    //             srcref.current.removeAttribute('src'); // empty source
    //             srcref.current.srcObject = null
    //             srcref.current.load();
    //             updateMusicindex(0)
    //         }
    //         catch (e) { }
    //     };
    // }, []);

    return <div className={'gbcastclosed_left'}>
        <div className="gbcastmedias_root">
            {medias.map(({ mtype, content, fn, key }, i) => {
                if (mtype === 0) { // image
                    //backgroundImage: `url(${content})`
                    return <div className={'gbcastimg_root'} key={'img_' + i} style={{ backgroundColor:'beige', backgroundImage: `url(${content})` }} >
                        <div className="gbcast_index">
                            <div className="gbcast_hex">#</div>
                            <div>{fn}</div>
                        </div>
                    </div>
                }
                else if (mtype === 1) { // video
                    return <div className={'gbcastvid_root'} key={'media_' + i}  >
                        <video key={key + fn} controls style={{ borderRadius: 5, width: '100%', height: '100%' }}>
                            <source src={content.blobData} type={content.file.type} />
                        </video>
                        <div className="gbcastvid_index">
                            <div className="gbcast_hex">#</div>
                            <div>{fn}</div>
                        </div>
                    </div>
                }
                return null
            })}
        </div>

    </div>

}

export default Leftdefault;