import React, { memo, useState } from 'react'
import { useEffect } from 'react'
import { Togglebutton } from '../../../../../../basic_components'
import { iconwrapper } from '../../../../../../config/commonfunctions'
import { ARROWLEFT_SMALL, ARROWRIGHT_SMALL, EMPTY, cryptobool } from '../../../../../../config/constants'
import { EYE_ICON } from '../../../../../../config/svgicons'
import { id_dom, title_dom, input_dom, msg_dom, textarea_dom, multiplechoice_dom, status_dom, radiobtnsmall_dom } from '../Commonelements/Commonelements'
import Success from './components/Success'
import './Glmodal.css'

const TIMER_CONSTANT = 350
const EYEICON_CSS = { width: 24, height: 24, margin: '0px 5px 0px 0px', display: 'flex' }

function Editguestcontent(props) {
    const { FIXED_COLUMNS_ID, editstate, allguestqnsuids, update, guestcats, guestqns, guestqnsuids, guestgrps, next, back, grp_guestids_map, } = props

    const [page, setPage] = useState(0) // 0 : UPDATE, 1 : CAT, 2 : RSVP 

    const [id, setId] = useState('')
    const [index, setIndex] = useState('')
    const [name, setName] = useState(EMPTY)
    const [email, setEmail] = useState(EMPTY)
    const [contact, setContact] = useState(EMPTY)
    const [address, setAddress] = useState(EMPTY)
    const [cat, setCat] = useState([])
    const [initgroupstate, setInitgroupstate] = useState(null)
    const [group, setGroup] = useState(EMPTY)
    const [initrsvpstate, setInitrsvpstate] = useState(null)
    const [rsvp, setRsvp] = useState(EMPTY)
    const [updatedon, setUpdatedon] = useState([]) // updated on max array is 10
    const [status, setStatus] = useState([])
    const [qnsans, setQnsans] = useState({}) // user defined rsvp qns
    const [tagsalrdydeleted, setTagsalrdydeleted] = useState([]) // tags that alrdy deleted
    const [allguestqns, setAllguestqns] = useState([]) // all guest qns in a single array
    const [msgs, setMsgs] = useState({})
    const [checking, setChecking] = useState(false)
    const [contentchange, setContentchange] = useState(false)
    const [grpldr, setGrpldr] = useState(false)
    const [defaultqn_state_map_counter, setDefaultqn_state_map_counter] = useState(0)
    const [defaultqn_state_map, setDefaultqn_state_map] = useState({ // default state
        "1-name": {
            "editable": false,
            "choice": "" // choice exist only if "editable" is true
        },
        "2-email": {
            "editable": true,
            "choice": "MANDATORY"
        },
        "3-contact": {
            "editable": true,
            "choice": "OPTIONAL"
        },
        "4-address": {
            "editable": true,
            "choice": "OPTIONAL"
        },
        '7-nftwalletaddress': {
            "editable": true,
            "choice": "OPTIONAL"
        }
    })

    const [wallettemp, setWallettemp] = useState(EMPTY)
    const [wallet, setWallet] = useState(EMPTY)
    const [walletstatus, setWalletstatus] = useState(false)

    useEffect(() => {

        if (editstate === 'IDLE') {
            setId(props.edituuid)
            setIndex(props.data['index_uid'])
            setName(props.data['1-name'])
            setEmail(props.data['2-email'])
            setContact(props.data['3-contact'])
            setAddress(props.data['4-address'])
            setGroup(props.data['5-group'])
            if (props.data['5-group'].length > 0) {
                setGrpldr(grp_guestids_map[props.data['5-group']].indexOf(props.edituuid) === 0)
            }
            else {
                setGrpldr(false)
            }

            setInitgroupstate(props.data['5-group'])
            setCat(props.data['6-category'])

            const walletstatus = props.data['7-nftwalletaddress'] === -1 ? false : true
            setWalletstatus(walletstatus)// true implies that wallet can be filled, false implies cannot.
            setWallet(walletstatus ? props.data['7-nftwalletaddress'] : '')

            setRsvp(props.data['8-rsvp'])
            setInitrsvpstate(props.data['8-rsvp'])
            setUpdatedon(props.data['9-updatedon'])
            setStatus(props.data['10-status'])
            setDefaultqn_state_map(JSON.parse(JSON.stringify(props.guestid_defaultqnstate_map[props.edituuid])))

            var new_addqnsans = {}
            for (let i = 1; i < allguestqnsuids.length; i++) {
                const key = allguestqnsuids[i]
                if (FIXED_COLUMNS_ID.indexOf(key) === -1) { // curr id is not in FIXED_COLUMNS_ID, update qnsans
                    new_addqnsans[key] = props.data[key]
                }
            }
            setQnsans(new_addqnsans)
        }
    }, [editstate, props.data])

    useEffect(() => {

        // flatten guestqns into a single array, and add extra info to each guestqn obj
        // add unique id and question category ( mainqn of followupqn )

        function addmoreinfo_mainqn(qntype, currqn, id) {
            return [qntype, id, ...currqn]
        }

        function addmoreinfo_followqn(qntype, currqn) {
            return [qntype, ...currqn]
        }

        let temp_allguestqns = []
        if (guestqns.length > 0) {
            for (let i = 0; i < guestqns.length; i++) {
                let currqn = guestqns[i]
                let qntype = currqn[1]
                let mainqnid = guestqnsuids[i]
                temp_allguestqns.push(addmoreinfo_mainqn('mainqn', currqn, mainqnid))
                if (qntype === 0) {
                    let options = currqn[8]
                    for (let k = 0; k < options.length; k++) { // options
                        var followupqns = options[k][1]
                        if (followupqns.length > 0) {
                            for (let j = 0; j < followupqns.length; j++) {
                                temp_allguestqns.push(addmoreinfo_followqn('followupqn', followupqns[j]))
                            }
                        }
                    }
                }
            }
        }

        setAllguestqns(temp_allguestqns)

    }, [guestqns])


    useEffect(() => {
        if (checking) {
            setChecking(() => { // will return false once everything is done

                let data = {}

                // update data, as data is empty object
                for (let i = 1; i < allguestqnsuids.length; i++) {
                    const qnuid = allguestqnsuids[i]
                    data[qnuid] = qnsans[qnuid] ? qnsans[qnuid] : ''
                }

                data['1-name'] = name
                data['2-email'] = email
                data['3-contact'] = contact
                data['4-address'] = address
                data['5-group'] = group
                data['6-category'] = cat
                data['7-nftwalletaddress'] = wallet
                data['8-rsvp'] = rsvp
                data['9-updatedon'] = updatedon
                data['10-status'] = status

                // loop all data to ensure no more than characters limit
                // and update errormsg
                let new_msgs = msgs
                let keys = Object.keys(data)
                let error_counter = 0
                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i]
                    if (data[key].length > 500) {
                        new_msgs[key] = 'You have exceeded 500 character limit.'
                        error_counter++
                    }
                    else {
                        new_msgs[key] = ""
                    }
                }
                error_counter > 0 ? setMsgs(new_msgs) : update(data, initrsvpstate, grpldr, defaultqn_state_map)
                return false
            })
        }
    }, [checking])

    useEffect(() => {
        setTagsalrdydeleted(cat.filter(x => !guestcats.includes(x)))
    }, [cat])

    const selectbtn_dom = <div className='Rsvpexplorer_portalrow' style={{ marginBottom: 40 }}>
        <Togglebutton
            rootstyle={{ width: '100%' }}
            singlebtnstyle={{ width: '33.33%' }}
            funcs={[
                () => { setPage(0) },
                () => { setPage(1) },
                () => { setPage(2) },
            ]}
            // txts={['Guest Details', `Categories ${cat.length > 0 ? `( ${cat.length} )` : ''}`, 'RSVP Questions']}
            txts={['Guest Details', `Category`, 'RSVP Questions']}
            btnselected={page}
        />
    </div>

    const rsvpaccept_dom = <div className='ig_lblinputroot'>
        <div className='ig_lbl'>RSVP</div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <select className='ig_select' value={rsvp} onChange={(e) => { setRsvp(e.target.value) }}>
                <option value={''}>{'( Unanswered )'}</option>
                <option value={'accept'}>Accept</option>
                <option value={'decline'}>Decline</option>
            </select>
        </div>
        {msg_dom('8-rsvp', msgs)}
    </div>

    const group_dom = <div className='ig_lblinputroot'>
        <div className='ig_lbl'>{'Grouping'}</div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <select className='ig_select' value={group} onChange={(e) => { setGroup(e.target.value); setGrpldr(false) }}>
                <option value={''}>{'( Unanswered )'}</option>
                {guestgrps.map((gg, i) => {
                    return <option key={'guestgrp_' + i} value={gg}>{gg}</option>
                })}
            </select>
        </div>
        {(group && group.length > 0)
            ? <button onClick={() => { setGrpldr(!grpldr) }} style={{ marginTop: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: 'transparent', border: 'none', boxShadow: 'none', paddingLeft: 0 }} >
                <input type='checkbox' checked={grpldr} onChange={() => { setGrpldr(!grpldr) }} />
                <div style={{ paddingLeft: 5 }}>Assign as leader</div>
            </button>
            : null}
        {msg_dom('5-group', msgs)}
    </div>

    const Extradom = (key) => {
        // { // default state
        //     "1-name": {
        //         "editable": false,
        //         "choice": "" // choice exist only if "editable" is true
        //     },
        //     "2-email": {
        //         "editable": true,
        //         "choice": "MANDATORY"
        //     },
        //     "3-contact": {
        //         "editable": true,
        //         "choice": "OPTIONAL"
        //     },
        //     "4-address": {
        //         "editable": true,
        //         "choice": "OPTIONAL"
        //     },
        //     '7-nftwalletaddress': {
        //         "editable": true,
        //         "choice": "OPTIONAL"
        //     }
        // }
        return <div key={defaultqn_state_map[key]['editable'] + defaultqn_state_map_counter} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            {radiobtnsmall_dom(
                defaultqn_state_map[key]['editable'],
                () => {
                    setDefaultqn_state_map_counter((defaultqn_state_map_counter) => defaultqn_state_map_counter + 1)
                    setDefaultqn_state_map((defaultqn_state_map) => {
                        defaultqn_state_map[key]['editable'] = !defaultqn_state_map[key]['editable']
                        return defaultqn_state_map
                    })
                },
                defaultqn_state_map[key]['editable'] ? 'Guest Editable' : 'Guest Uneditable')}
        </div>
    }



    //     <Modcfm
    //     onHide={() => {
    //         setWalletcfmmod(false)
    //     }}
    //     onOk={() => {
    //         setWalletcfmmod(false)
    //         setWalletstatus(false)
    //         setWallet(-1)
    //     }}
    //     onNo={() => {
    //         setWalletcfmmod(false)
    //     }}
    //     txt={
    //         <div>{`Once disable current wallet address will be compeletely wiped out. Please be sure before proceeding.`}</div>
    //     }
    // />

    const disablewallet = () => {
        setWalletstatus(false)
        setWallettemp(wallet)
        setWallet(-1)
    }

    const enablewallet = () => {
        setWalletstatus(true)
        setWallet(wallettemp)
        setWallettemp(EMPTY)
    }

    const update_dom = <div style={{ display: 'flex', flexDirection: 'column' }}>
        {title_dom('Guest Details')}
        {id_dom(id)}
        {status_dom(
            status,
            (e) => { // update status main
                const { value } = e.target
                console.log('value', value)
                setStatus(() => {
                    if (value === 'sent') {
                        return { date: new Date().toLocaleString(), from: '', action: 'sent' }
                    }
                    else if (value === 'replied') {
                        return { date: new Date().toLocaleString(), from: '', action: 'replied' }
                    }
                    else if (value === 'update') {
                        return { date: new Date().toLocaleString(), from: '', action: 'update' }
                    }
                    else if (value === 'reminder') {
                        return { date: new Date().toLocaleString(), from: '', action: 'reminder' }
                    }
                    else if (value === '') {
                        return { date: '', from: '', action: '' }
                    }
                })
            },
            (e) => { // update from
                const { value } = e.target
                setStatus((status) => {
                    var new_status = Object.assign({}, status)
                    new_status['from'] = value
                    return new_status
                })
            }
        )}

        {input_dom('Name', 'Guest Name', name, (e) => { setName(e.target.value); setMsgs((msgs) => { msgs['1-name'] = ''; return msgs; }) }, '1-name', msgs, null, Extradom('1-name'), { borderBottomRightRadius: 0 })}
        {input_dom('Email', 'Guest Email', email, (e) => { setEmail(e.target.value); setMsgs((msgs) => { msgs['2-email'] = ''; return msgs; }) }, '2-email', msgs, null, Extradom('2-email'), { borderBottomRightRadius: 0 })}
        {input_dom('Contact', 'Guest Contact', contact, (e) => { setContact(e.target.value); setMsgs((msgs) => { msgs['3-contact'] = ''; return msgs; }) }, '3-contact', msgs, null, Extradom('3-contact'), { borderBottomRightRadius: 0 })}
        {input_dom('Address', 'Guest Address', address, (e) => { setAddress(e.target.value); setMsgs((msgs) => { msgs['4-address'] = ''; return msgs; }) }, '4-address', msgs, null, Extradom('4-address'), { borderBottomRightRadius: 0 })}
        {cryptobool
            ? <div className='gl_walletbg'>
                {walletstatus
                    ? <>
                        <button className='gl_walletbtn' onClick={disablewallet}>Disable Crypto Wallet</button>
                        {input_dom('Crypto Wallet Address', 'Guest Wallet', wallet, (e) => { setWallet(e.target.value); setMsgs((msgs) => { msgs['7-nftwalletaddress'] = ''; return msgs; }) }, '7-nftwalletaddress', msgs, null, Extradom('7-nftwalletaddress'), { borderBottomRightRadius: 0 })}
                    </>
                    : <button className='gl_walletbtn' onClick={enablewallet}>Enable Crypto Wallet</button>
                }
            </div>
            : null}
        {group_dom}
        {rsvpaccept_dom}
    </div>

    const catselect = (value, currcat, i) => {
        let local_addcat = cat.slice()
        cat.indexOf(currcat) > -1
            ? local_addcat.splice(cat.indexOf(currcat), 1) // alrdy exist, remove it from array
            : local_addcat.push(value)
        setCat(local_addcat)
    }

    const categories_dom = <div style={{ display: 'flex', flexDirection: 'column' }}>
        {title_dom('Category')}

        {(guestcats && guestcats.length > 0)
            ? <div style={{ display: 'flex', flexDirection: 'column', }}>
                <div className='Rsvpexplorer_portaltaglabel' style={{ padding: '0px 10px' }}>Category Tag Collection.</div>
                <div className='Rsvpexplorer_portaltagrow' style={{ padding: 10, border: '1px solid #dddddd', borderRadius: 5, margin: '0px 10px' }}>
                    {guestcats.map((currcat, i) => {
                        return <button
                            key={'cat' + i}
                            className={`Rsvpexplorer_portaltag ${cat.indexOf(currcat) > -1 ? 'Rsvpexplorer_portaltagselected' : ''}`}
                            style={{ width: 'max-content' }}
                            value={currcat}
                            onClick={(e) => { catselect(e.target.value, currcat, i) }}
                        >
                            {currcat}
                        </button>
                    })}
                </div>
            </div>
            : <div style={{ display: 'flex', flexDirection: 'column', }}>
                <div className='Rsvpexplorer_portaltaglabel' style={{ padding: '0px 10px' }}>You do not have any category.</div>
            </div>}

        {tagsalrdydeleted.length > 0
            ? <div style={{ display: 'flex', flexDirection: 'column', }}>
                <div style={{ height: 10 }} />
                <div className='Rsvpexplorer_portaltaglabel' style={{ padding: '0px 10px' }}>Tags that had already removed from Category Tag Collection.</div>
                <div className='Rsvpexplorer_portaltagrow' style={{ padding: 10, border: '1px solid #dddddd', borderRadius: 5, margin: '0px 10px' }}>
                    {tagsalrdydeleted.map((currcat, i) => {
                        return <button
                            key={'cat' + i}
                            className={`Rsvpexplorer_portaltag ${cat.indexOf(currcat) > -1 ? 'Rsvpexplorer_portaltagselected' : ''}`}
                            onClick={(e) => { catselect(e.target.value, currcat, i) }}
                        >
                            {currcat}
                        </button>
                    })}
                </div>
            </div>
            : null}
    </div>

    const rsvpqn_dom = allguestqns.length > 0
        ? <div
            key={'userdefinedqns_dom'}
            style={{ margin: '40px 0px 60px 0px' }}>
            {title_dom('RSVP Questions')}
            {allguestqns.map((qn) => {

                const mainqn_or_folqn = qn[0]

                if (mainqn_or_folqn === 'mainqn') {
                    const uniqid = qn[1]
                    const qnstxt = qn[2]
                    const qntype = qn[3]

                    const onchange = async (e) => {
                        const { id, value } = e.target;
                        qnsans[id] = value;
                        msgs[id] = ''
                        setQnsans({ ...qnsans })
                        setMsgs({ ...msgs })
                        setChecking(false)
                    }

                    if (qntype === 0) { // multiple choice
                        let options = qn[10].map((item) => item[0])
                        return multiplechoice_dom(qnstxt, options, onchange, uniqid, msgs, qnsans[uniqid])
                    }
                    else if (qntype === 1) { // text qn
                        return textarea_dom(qnstxt, '', qnsans[uniqid], onchange, uniqid, msgs)
                    }

                }
                else if (mainqn_or_folqn === 'followupqn') {
                    const uniqid = qn[4]
                    const qnstxt = qn[1]
                    const qntype = qn[2]

                    const onchange = async (e) => {
                        const { id, value } = e.target;
                        qnsans[id] = value;
                        msgs[id] = ''
                        setQnsans({ ...qnsans })
                        setMsgs({ ...msgs })
                        setChecking(false)
                    }

                    if (qntype === 0) { // multiple choice
                        let tempqn = qn.slice()
                        let options = tempqn.splice(5, tempqn.length)
                        return multiplechoice_dom(qnstxt, options, onchange, uniqid, msgs, qnsans[uniqid])
                    }
                    else if (qntype === 1) { // text qn
                        return textarea_dom(qnstxt, '', qnsans[uniqid], onchange, uniqid, msgs)
                    }

                }
                return null
            })}
        </div>
        : null

    const changeguest = (v) => {
        setContentchange(true)
        setTimeout(() => {
            setContentchange(false)
            v === 0 ? back() : next()
        }, TIMER_CONSTANT)
    }

    return contentchange
        ? <div className='Rsvpexplorer_portal'>
            <div className='Rsvpexplorer_portalcontent' style={{ display: 'flex', placeContent: 'center', alignItems: 'center', flexDirection: 'column', cursor: 'pointer', height: '100%' }}>
                <div style={{ fontSize: 24, color: '#333333', padding: '10px 36px' }}>Loading Guest Information</div>
            </div>
        </div>
        : <div className='Rsvpexplorer_portal'>

            <div className='Rsvpexplorer_portaltitle'>{`Edit Guest #${index}`}</div>

            {editstate !== 'SUCCESS'
                ? <>
                    <div className='Rsvpexplorer_portalcontent'>
                        {selectbtn_dom}
                        {page === 0 ? update_dom : null}
                        {page === 1 ? categories_dom : null}
                        {page === 2 ? rsvpqn_dom : null}
                        <div style={{ height: 50, width: '100%' }} />
                    </div>
                    <div className='gl_portalbtnrow'>
                        <button className='gl_portalbtnarrow gl_portalbtnarrowleft' onClick={() => changeguest(0)}>{ARROWLEFT_SMALL}</button>
                        <button className='gl_portalbtnarrow gl_portalbtnarrowright' onClick={() => changeguest(1)}>{ARROWRIGHT_SMALL}</button>
                        {!props.rsvpactive
                            ? <button className='gl_portalupdatebtn' onClick={() => { setChecking(true) }} disabled={editstate === 'SAVING'}>
                                {{ // IDLE, SAVING, SUCCESS, FAILURE
                                    'IDLE': 'Update',
                                    'SAVING': 'Saving...',
                                    'SUCCESS': 'New Question Created',
                                    'FAILURE': 'Error Occurred, please try again later.'
                                }[editstate]}
                            </button>
                            : <button className='gl_portalupdatebtndisabled' disabled={true}>
                                <div>Viewing Mode</div>
                                <div style={{ width: 10 }} />
                                {iconwrapper(EYE_ICON, EYEICON_CSS)}
                            </button>}
                    </div>
                </>
                : <Success txt={'Guest information updated. Click to return.'} onClick={props.returnpage} />
                // : <div className='Rsvpexplorer_portalcontent' style={{ display: 'flex', placeContent: 'center', alignItems: 'center', flexDirection: 'column', cursor: 'pointer', height: '100%' }}>
                //     {tick_icon}
                //     <div style={{ fontSize: 24, color: '#4bb543', padding: '10px 36px' }}>Guest Information Updated</div>
                // </div>
            }

            {/* {walletcfmmod
                ? <Modcfm
                    onHide={() => {
                        setWalletcfmmod(false)
                    }}
                    onOk={() => {
                        setWalletcfmmod(false)
                        setWalletstatus(false)
                        setWallet(-1)
                    }}
                    onNo={() => {
                        setWalletcfmmod(false)
                    }}
                    txt={
                        <div>{`Once disable current wallet address will be compeletely wiped out. Please be sure before proceeding.`}</div>
                    }
                />
                : null} */}

        </div>

}

export default memo(Editguestcontent)