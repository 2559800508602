import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { ReactP5Wrapper } from "react-p5-wrapper";
import { Checkdevice, useWindowPosition } from '../../../../../../components';
import Commonstart from '../components/Commonstart/Commonstart';
import './Charart.css'
const alphabets = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']

var Cs = new Commonstart()
function Charart(props) {
    const { meta, ht, wt, startloaded, toggleguestbookbtn, msgsdata } = props
    const { alphacolors, othercolors, shape, space } = meta
    const [p5sketch, setP5sketch] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loaded, setLoaded] = useState(false)

    const [activatepointer, setActivatepointer] = useState(false)
    const [selector, setSelector] = useState(-1)
    const device = Checkdevice()

    const [shapewt, setShapewt] = useState(-1)
    const [shapeht, setShapeht] = useState(-1)
    const [transparentgrid, setTransparentgrid] = useState([])

    const [onedimmsg, setOnedimmsg] = useState([])
    const [floatingmsg, setFloatingmsg] = useState(null) // dom element of selectedmsg

    const [cols, setCols] = useState([])
    const [rows, setRows] = useState([])

    const [shadeextensionposy, setShadeextposy] = useState(0)
    const [autoscrollposy, setAutoscrollposy] = useState(0)

    const div_under_p5 = useRef(null)
    const scrollpos = useWindowPosition()

    useEffect(() => {
        if (p5sketch) {
            setShadeextposy(document.getElementById('defaultCanvas0') ? document.getElementById('defaultCanvas0').getBoundingClientRect().bottom : null)
        }
    }, [p5sketch])

    const device_canvaht = {
        'MOBILE': ht * 1.15,
        'TABLET': ht * 1.15,
        // 'WEB': a3wtint / DOWNSIZERATIO,
        'WEB': ht,
    }

    const device_canvawt = {
        'MOBILE': wt,
        'TABLET': wt * 0.6,
        // 'WEB': a3htint / DOWNSIZERATIO,
        // 'WEB': wt * 0.7,
        'WEB': wt
    }

    const init = (wt, ht) => {
        if (meta && Object.keys(meta).length > 0) {
            setP5sketch(
                <ReactP5Wrapper
                    sketch={(p5) => {

                        var sentence_arr = msgsdata['msgtxt'].slice()

                        var sentence_str = sentence_arr.join(' ')

                        // const oneinchpx = 96 / DOWNSIZERATIO

                        // function drawframe() {
                        //     p5.push()
                        //     p5.fill(framecolor)
                        //     p5.strokeWeight(0)
                        //     p5.rect(0, 0, p5.width, oneinchpx)
                        //     p5.rect(0, 0, oneinchpx, p5.height)
                        //     p5.rect(0, p5.height - oneinchpx, p5.width, p5.height)
                        //     p5.rect(p5.width - oneinchpx, 0, p5.width, p5.height)
                        //     p5.endShape(p5.CLOSE);
                        //     p5.pop()
                        // }

                        function applycolor(char) {
                            var gt_alphacolors = alphacolors.length > 0
                            var gt_othercolors = othercolors.length > 0
                            var c
                            if (gt_alphacolors) {
                                var index = alphabets.indexOf(char)
                                c = alphacolors[index]
                                if (c) {
                                    p5.stroke(c)
                                    return c
                                }
                            }

                            if (gt_othercolors) {
                                c = p5.random(othercolors)
                                p5.stroke(c)
                                return c
                            }
                            p5.stroke('white')
                            return 'white'
                        }

                        function drawitem(char, x, y, new_wt, new_ht, startpos) {
                            p5.fill(applycolor(char))
                            if (shape === 0) {
                                space === 0
                                    ? p5.rect(
                                        (y * new_wt + startpos),
                                        (x * new_ht + startpos),
                                        new_wt,
                                        new_ht
                                    )
                                    : p5.rect(
                                        (y * new_wt) + (new_wt / 4) + startpos,
                                        (x * new_ht) + (new_ht / 4) + startpos,
                                        new_wt / 2,
                                        new_ht / 2
                                    )
                            }
                            else if (shape === 1) {
                                space === 0
                                    ? p5.ellipse(
                                        (y * new_wt) + (new_wt * 0.5) + startpos,
                                        (x * new_ht) + (new_ht * 0.5) + startpos,
                                        new_wt,
                                        new_ht
                                    )
                                    : p5.ellipse(
                                        (y * new_wt) + (new_wt / 2) + startpos,
                                        (x * new_ht) + (new_ht / 2) + startpos,
                                        new_wt > new_ht ? new_wt / 2 : new_ht / 2,
                                        new_wt > new_ht ? new_ht / 2 : new_wt / 2,
                                    )
                            }
                        }

                        p5.setup = () => {
                            setLoading(false)
                            startloaded(true)
                            p5.createCanvas(device_canvawt[device], ht);
                            p5.background('white');
                            p5.angleMode(p5.DEGREES)
                            p5.noLoop()
                        }

                        p5.draw = () => {
                            // var startpos = oneinchpx
                            var startpos = 0
                            var wt = p5.width - (startpos * 2)
                            var ht = p5.height - (startpos * 2)
                            var cell_size = Math.sqrt((wt * ht) / parseInt(sentence_str.length, 10))
                            var rows = Math.ceil(ht / cell_size)
                            var cols = Math.ceil(wt / cell_size)
                            var new_wt = wt / cols
                            var new_ht = ht / rows
                            setShapewt(new_wt)
                            setShapeht(new_ht)

                            var charpointer = 0

                            // reconstruct sentence_arr, by adding more properties
                            // initilize properties for chartart_sqtransparent
                            var msgindex_arr = []
                            for (let i = 0; i < sentence_arr.length; i++) {
                                msgindex_arr.push([])
                                for (let j = 0; j < sentence_arr[i].length; j++) {
                                    msgindex_arr[msgindex_arr.length - 1].push({ sentindex: i, type: 'char', x: null, y: null }) // char msg
                                }
                                // push a SPACE to the end of every sentence arr
                                msgindex_arr[msgindex_arr.length - 1].push({ sentindex: i, type: 'space', x: null, y: null }) // for space
                            }

                            // pushing msgindex_arr to a single dimension array
                            var local_onedimmsg = []
                            for (let i = 0; i < msgindex_arr.length; i++) {
                                for (let j = 0; j < msgindex_arr[i].length; j++) {
                                    local_onedimmsg.push(msgindex_arr[i][j])
                                }
                            }

                            // loop rows and cols, to draw and obtain individual shape position
                            // and assign these positions to local_onedimmsg objects
                            for (let x = 0, z = 0; x < rows; x++) {
                                for (let y = 0; y < cols; y++, charpointer++, z++) {
                                    drawitem(sentence_str[charpointer], x, y, new_wt, new_ht, startpos)
                                    var posx
                                    var posy
                                    if (shape === 0) { // shape SQUARE or CIRCLE
                                        if (space === 0) { // padding of shape
                                            posx = (y * new_wt + startpos)
                                            posy = (x * new_ht + startpos)
                                        }
                                        else {
                                            posx = (y * new_wt) + (new_wt / 4) + startpos
                                            posy = (x * new_ht) + (new_ht / 4) + startpos
                                        }

                                    }
                                    else if (shape === 1) {
                                        if (space === 0) {
                                            posx = (y * new_wt) + (new_wt * 0.5) + startpos
                                            posy = (x * new_ht) + (new_ht * 0.5) + startpos
                                        }
                                        else {
                                            posx = (y * new_wt) + (new_wt / 2) + startpos
                                            posy = (x * new_ht) + (new_ht / 2) + startpos
                                        }

                                    }

                                    // cols might have more elements than local_onedimmsg
                                    // as it is calculated with reference to the dimension of device
                                    // in order to obtain best look.
                                    try {
                                        local_onedimmsg[z]['x'] = posx
                                        local_onedimmsg[z]['y'] = posy
                                    }
                                    catch (e) {
                                        local_onedimmsg.push({ sentindex: null, x: posx, y: posy })
                                    }
                                }
                            }
                            setOnedimmsg(local_onedimmsg)

                            var rowarr = []
                            for (let i = 0; i < rows; i++) {
                                rowarr.push([])
                            }
                            setRows(rowarr)

                            var colarr = []
                            for (let i = 0; i < cols; i++) {
                                colarr.push([])
                            }
                            setCols(colarr)
                            setLoaded(true)
                            // drawframe()
                        }
                    }}>
                </ReactP5Wrapper>
            )
        }
    }

    useEffect(() => {
        if (!loaded) {
            init(wt, device_canvaht[device])
            setLoading(true)
        }
    }, [msgsdata])

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: autoscrollposy })

    }, [autoscrollposy])

    useLayoutEffect(() => {
        if (activatepointer) {
            //get original value of body
            const originalStyle = window.getComputedStyle(document.body).overflowX;
            //prevent scrolling on mount
            document.body.style.overflow = "hidden";
            // re-enable scrolling when component unmounts
            return () => (document.body.style.overflowX = originalStyle);
        }
        else{
              document.body.style.overflow = "auto";
          
        }
    }, [activatepointer]); //empty array to ensures effect is only run when mount and unmount

    const up = () => {
        var transparentgrid = []
        var msgdisplay_posy = -1
        var autoscroll_posy = -1
        var firsttime = true

        var new_selector = selector === 0 ? msgsdata['msgtxt'].length - 1 : selector - 1

        for (let i = 0, z = 0; i < rows.length; i++) {
            var temp = []
            for (let j = 0; j < cols.length; j++, z++) {
                var condition = onedimmsg[z]['sentindex'] === new_selector && onedimmsg[z]['type'] === 'char'

                temp.push(<div
                    className='chartart_sqtransparent'
                    key={i + '_' + j}
                    style={{
                        left: j * shapewt,
                        top: i * shapeht,
                        width: shapewt * 1.05,
                        height: shapeht * 1.05,
                        backgroundColor: condition ? 'transparent' : '#00000099',
                    }}
                />)
                if (condition) {
                    msgdisplay_posy = (i * shapeht) + shapeht
                    if (firsttime) {
                        firsttime = false;
                        autoscroll_posy = (i * shapeht) - (shapeht * 5)
                    }
                }
            }
            transparentgrid.push(<div key={'row' + i} style={{ display: 'flex', flexDirection: 'row' }}>
                {temp}
            </div>)
        }

        setAutoscrollposy(autoscroll_posy)

        setTransparentgrid(<div style={{ flexDirection: 'column', display: 'flex' }}>
            {transparentgrid}
        </div>)

        setFloatingmsg(<div className='charart_fmsgroot'>
            <div className='charart_fmsgcontent' style={{ top: msgdisplay_posy }}>
                {msgsdata['msgtxt'][new_selector]}
            </div>
        </div>)

        setSelector(new_selector)
    }

    const down = () => {
        var transparentgrid = []
        var msgdisplay_posy = -1
        var autoscroll_posy = -1
        var firsttime = true

        var new_selector = selector === msgsdata['msgtxt'].length - 1 ? 0 : selector + 1

        for (let i = 0, z = 0; i < rows.length; i++) {
            var temp = []
            for (let j = 0; j < cols.length; j++, z++) {
                var condition = onedimmsg[z]['sentindex'] === new_selector && onedimmsg[z]['type'] === 'char'
                temp.push(<div
                    className='chartart_sqtransparent'
                    key={i + '_' + j}
                    style={{
                        left: j * shapewt,
                        top: i * shapeht,
                        width: shapewt * 1.05,
                        height: shapeht * 1.05,
                        backgroundColor: condition ? 'transparent' : '#00000099',
                    }}
                />)
                if (condition) {
                    msgdisplay_posy = (i * shapeht) + shapeht
                    if (firsttime) {
                        firsttime = false;
                        autoscroll_posy = (i * shapeht) - (shapeht * 5)
                    }
                }
            }
            transparentgrid.push(<div key={'row' + i} style={{ display: 'flex', flexDirection: 'row' }}>
                {temp}
            </div>)
        }


        setTransparentgrid(<div style={{ flexDirection: 'column', display: 'flex' }}> {transparentgrid} </div>)

        setAutoscrollposy(autoscroll_posy)

        setFloatingmsg(<div className='charart_fmsgroot'>
            <div className='charart_fmsgcontent' style={{ top: msgdisplay_posy }}>
                {msgsdata['msgtxt'][new_selector]}
            </div>
        </div>)

        setSelector(new_selector)
    }

    const close = () => {
        setActivatepointer(false)
        toggleguestbookbtn()
        window.scrollTo(0,0)
    }

    const opencircle = () => {
        toggleguestbookbtn();
        setActivatepointer(true)
        down()
    }

    const shadextension = <div className="charart_blackext" style={{ top: shadeextensionposy }} />

    return <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', overflowX: 'hidden', visibility: loading ? 'hidden' : 'visible', touchAction: 'manipulation' }}>
        <div ref={div_under_p5} style={{ display: 'flex', flexDirection: 'row', width: '100%', touchAction: 'manipulation' }}>
            {p5sketch}
        </div>
        {props.children}
        {scrollpos > (ht / 2) && !activatepointer
            ? null
            : activatepointer ? Cs.pinkprompt(close, up, down) : Cs.pinkbtn(opencircle)}
        {activatepointer ? transparentgrid : null}
        {activatepointer ? floatingmsg : null}
        {activatepointer ? shadextension : null}

    </div>
}
export default Charart