import React, { useState, useEffect } from 'react'
import { USER_READ_WEDANDEVENT_QN, USER_SUBMIT_ANS } from '../../config/api'
import { Retrieve_personal_info } from '../../config/commonfunctions';
import { Checkdevice, Loading, Checkdate } from '../../components';
import { EMPTYSPACE, EMPTY, CURRENCY_MAP, BUDGET_IN_ENG_SG, BUDGET_IN_ENG_MY, BUDGET_IN_ENG_CH, BUDGET_IN_ENG_TW, BUDGET_IN_ENG_HK, BUDGET_IN_ENG_KR, WEDDING_CUSTOM, MAIN_PINK_COLOR, STARTUP_NAME } from '../../config/constants';
import { userqn_event, userqn_wedding } from '../../assets/svgs';
import { BUTTONSTYLE } from '../../config/cssconstants';
import DatePicker from "react-datepicker";
import axios from 'axios'
import "react-datepicker/dist/react-datepicker.css";
import './UserQuestion.css'

var CustomDatepicker = React.forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" ref={ref} onClick={onClick} style={{ padding: 10, display: 'flex', width: '100%', color: '#707070', alignItems: 'center', borderRadius: 5, backgroundColor: 'white', border: 'thin solid #d9d8d8', outline: 'none', marginBottom: 15 }}>
        {value}
    </button>
));

const BUDGET_IN_ENG_MAP = {
    'Singapore': BUDGET_IN_ENG_SG,
    'Malaysia': BUDGET_IN_ENG_MY,
    'China': BUDGET_IN_ENG_CH,
    'Taiwan': BUDGET_IN_ENG_TW,
    'Hong Kong': BUDGET_IN_ENG_HK,
    'Korea': BUDGET_IN_ENG_KR
}

const usertype_img_map = {
    'Wedding': userqn_wedding,
    'Event': userqn_event
}

const selected_price_css = { color: MAIN_PINK_COLOR, border: 'thin solid ' + MAIN_PINK_COLOR }
const unselected_price_css = { color: '#707070', border: 'thin solid #707070' }

export default function UserQuestion(props) {
    const { token, _id, acct_type } = Retrieve_personal_info()

    const device = Checkdevice()

    const outerpadding = device === 'MOBILE' ? '' : '0px 20%'

    const [BUDGET_IN_ENG, setBUDGET_IN_ENG] = useState([])
    const [rendergate, setRendergate] = useState(false)
    const [mainimg, setMainimg] = useState(undefined)
    const [currpage, setCurrpage] = useState(0)

    const [usertype, setUsertype] = useState(EMPTY)
    const [currency, setCurrency] = useState(EMPTY)
    const [data, setData] = useState({})
    const [name, setName] = useState(EMPTY)
    const [gender, setGender] = useState(undefined)

    // wedding
    const [spousename, setSpousename] = useState(EMPTY)
    const [spousegender, setSpousegender] = useState(undefined)
    const [budgets_w, setBudgets_w] = useState(undefined)
    const [city_w, setCity_w] = useState(undefined)
    const [date_w, setDate_w] = useState(undefined) // year/mth/day 
    const [date_gate_w, setDate_gate_w] = useState(undefined)
    const [numguests_w, setNumguests_w] = useState(undefined)
    const [selectedvts_w, setSelectedvts_w] = useState([])
    const [custom_w, setCustom_w] = useState([]) // array, can have more than one custom

    // event
    const [budgets_e, setBudgets_e] = useState(undefined)
    const [date_e, setDate_e] = useState(undefined) // year/mth/day 
    const [date_gate_e, setDate_gate_e] = useState(false)
    const [numguests_e, setNumguests_e] = useState(undefined)
    // const [selectedvts_e, setSelectedvts_e] = useState([])
    // const [typeofevent_e, setTypeofevent_e] = useState(undefined)


    useEffect(() => {
        props.hidetopbar(true)
        if (acct_type === 'USER') {
            init()
        }
        else {
            props.history.push('/')
        }
        return () => {
            props.hidetopbar(false)
        }
    }, [])

    function init() {
        let options = {
            method: 'POST',
            url: USER_READ_WEDANDEVENT_QN,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
            data: { _id, acct_type }
        }
        axios(options).then((item) => {
            try {
                const { status, data } = item
                const { result } = data

                if (status === 200) {
                    window.scrollTo(0, 0) // scroll to the top

                    const {
                        usertype, location,
                        name, gender,
                        // wedding
                        spousename, spousegender, budgets_w, city_w, date_w, numguests_w, custom_w, // selectedvts_w
                        // event
                        budgets_e, date_e, numguests_e // selectedvts_e

                    } = result

                    if (usertype === 'Guest') {
                        props.hidetopbar(false);
                        props.history.push('/');
                    }

                    // currency 
                    setCurrency(CURRENCY_MAP[location])

                    // pricing
                    setBUDGET_IN_ENG(BUDGET_IN_ENG_MAP[location])

                    // remove loading screen
                    setRendergate(true)

                    // background image
                    setMainimg(<object data={usertype_img_map[usertype]} type="image/svg+xml" style={{ width: '100vw', opacity: '0.8', height: device === 'MOBILE' ? '' : '50vh', }}>{EMPTYSPACE}</object>)

                    // wedding & event
                    setUsertype(usertype)
                    setData(result)
                    setName(name)
                    setGender(gender)

                    // wedding
                    setSpousename(spousename)
                    setSpousegender(spousegender)
                    setBudgets_w(budgets_w)
                    setCity_w(city_w)
                    setDate_w(date_w === '' || new Date(date_w) === 'Invalid Date' ? '' : new Date(date_w))
                    setDate_gate_w(date_w === '' ? true : false)
                    setNumguests_w(numguests_w)
                    setSelectedvts_w(selectedvts_w)
                    setCustom_w(custom_w)

                    // event
                    setBudgets_e(budgets_e)
                    setDate_e(date_e === '' || new Date(date_e) === 'Invalid Date' ? '' : new Date(date_e))
                    setDate_gate_e(date_e === '' ? true : false)
                    // setSelectedvts_e(selectedvts_e)
                    setNumguests_e(numguests_e)
                    // setTypeofevent_e(typeofevent_e)
                }
            }
            catch (e) {
                props.history.push('/')
            }

        })
    }

    function onNext() {
        var gate
        var msg = ""
        if (usertype === 'Wedding') {

            if (currpage === 0) {
                // name : name cannot be empty, and must be lesser than 50 char and more than 2 char
                // spousename : can be empty, if not empty it must be lesser than 50 char and more than 2 char
                if (!name || name.length > 50 || name.length < 2 || (spousename && (spousename.length > 50 || spousename.length < 2))) {
                    gate = false
                    msg = "Error : Name field"
                }
                else gate = true
            }
            else if (currpage === 1) {
                if (date_w || (date_w === EMPTY && date_gate_w)) {// 2019-12-10 or ''
                    gate = true
                }
                else {
                    msg = "Error : Date field"
                    gate = false
                }
            }
            else if (currpage === 2) {
                // guest will not be checked
                // city will be checked only
                if (city_w && city_w.length > 100) {
                    gate = false
                    msg = "Error : Where are you getting married field"
                }
                gate = true

            }
            else if (currpage === 3) {
                // custom will not be checked
                gate = true
            }
            else if (currpage === 4) {
                if (budgets_w) {
                    if (budgets_w === EMPTY || budgets_w === null || budgets_w === undefined) {
                        gate = false
                    }
                    else {
                        gate = true
                    }
                }
            }

        }
        else if (usertype === 'Event') {
            if (currpage === 0) {
                if (!name || name.length > 50 || name.length < 2) {
                    gate = false
                    msg = "Error : Name field"
                }
                else gate = true
            }
            else if (currpage === 1) {
                if (date_e || (date_e === EMPTY && date_gate_e)) { // 2019-12-10 or ''
                    gate = true
                }
                else { // if is empty ""
                    msg = "Error : Date field"
                    gate = false
                }
            }
            else if (currpage === 2) { // guest will not be checked
                gate = true
            }
            else if (currpage === 3) {
                if (budgets_e) {
                    if (budgets_e === EMPTY || budgets_e === null || budgets_e === undefined) {
                        gate = false
                    }
                    else {
                        gate = true
                    }
                }
            }
        }

        return (
            <button
                onClick={() => {
                    if (gate) {
                        setCurrpage(currpage + 1)
                    }
                    else {
                        alert(msg)
                    }
                }}
                style={{ ...BUTTONSTYLE, height: 'max-content' }}
            >Next</button>
        )


    }

    function onBack() {
        if (currpage > 0) {
            return (
                <button onClick={() => { if (currpage > 0) setCurrpage(currpage - 1); }} style={{ ...BUTTONSTYLE, height: 'max-content' }}>Back</button>
            )
        }
        else {
            return <div />
        }

    }

    function onSubmit() {

        return (
            <button
                style={{ ...BUTTONSTYLE, height: 'max-content' }}
                onClick={() => {
                    console.log(usertype, budgets_w, budgets_e)
                    if (usertype === 'Wedding') {
                        if (budgets_w === '' || budgets_w === null || budgets_w === undefined) {
                            alert('Please select a budget')
                            return
                        }
                    }
                    else if (usertype === 'Event') {
                        if (budgets_e === '' || budgets_e === null || budgets_e === undefined) {
                            alert('Please select a budget')
                            return
                        }
                    }
                    let options = {
                        method: 'POST',
                        url: USER_SUBMIT_ANS,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                        data: { _id, acct_type, name, gender, spousename, spousegender, budgets_w, city_w, date_w, numguests_w, custom_w, budgets_e, date_e, numguests_e }
                    }

                    axios(options).then((item) => {
                        try {
                            const { status } = item
                            if (status === 200) {
                                props.history.push('/')
                            }
                            else {
                                alert('Error Occured')
                                props.history.push('/')
                            }
                        }
                        catch (e) {
                            props.history.push('/')
                        }
                    })
                }}>
                Complete
            </button>
        )

    }

    // constant dom element
    const budget_dom = (
        BUDGET_IN_ENG.map((item, index) => {
            var css
            if (usertype === 'Event' && item === budgets_e) {
                css = selected_price_css
            }
            else if (usertype === 'Wedding' && item === budgets_w) {
                css = selected_price_css
            }
            else {
                css = unselected_price_css
            }

            const padding_margin = device === 'MOBILE' ? { padding: 7, margin: 7, marginLeft: 0 } : { padding: 10, margin: 15, marginLeft: 0, minHeight: 35, maxheight: 35, minWidth: 200, maxWidth: 200, justifyContent: 'center' }
            return (
                <button
                    key={item + index}
                    style={{ ...css, ...padding_margin, display: 'flex', flexDirection: 'row', borderRadius: 8, outline: 'none', backgroundColor: 'white', fontWeight: 500 }}
                    onClick={() => {
                        if (usertype === 'Event') {
                            setBudgets_e(item)
                        }
                        else if (usertype === 'Wedding') {
                            setBudgets_w(item)
                        }
                    }}
                >
                    {item + ' ' + currency}
                </button>
            )
        })
    )

    const customs_dom = (
        WEDDING_CUSTOM.map((item, index) => {
            var css
            if (usertype !== 'Wedding') return null
            if (usertype === 'Wedding' && custom_w.includes(item)) {
                css = selected_price_css
            }
            else {
                css = unselected_price_css
            }

            const padding_margin = device === 'MOBILE' ? { padding: 7, margin: 7, marginLeft: 0 } : { padding: 10, margin: 15, marginLeft: 0, minHeight: 35, maxheight: 35, minWidth: 200, maxWidth: 200, justifyContent: 'center' }

            return (
                <button
                    key={item + index}
                    style={{ ...css, ...padding_margin, display: 'flex', flexDirection: 'row', borderRadius: 8, outline: 'none', backgroundColor: 'white', fontWeight: 500 }}
                    onClick={() => {
                        var temp_custom_w = [...custom_w]

                        if (usertype === 'Wedding' && !custom_w.includes(item)) {
                            setCustom_w([...custom_w, item])
                        }
                        else {
                            var arr_index = custom_w.indexOf(item)
                            if (arr_index !== -1) {
                                temp_custom_w.splice(arr_index, 1)
                                setCustom_w(temp_custom_w)
                            }
                        }
                    }}
                >
                    {item}
                </button>
            )
        })
    )

    // Wedding
    const qn_name_wedding = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', height: 'max-content' }}>
                <input
                    className="userquestion_input"
                    value={name}
                    placeholder="Your Name"
                    style={{ width: '100%', maxWidth: device === 'MOBILE' ? '85%' : '95%', backgroundColor: 'transparent', fontSize: 30, outline: 'none', border: 'transparent', borderBottom: 'thin solid #707070', borderRadius: 0, margin: '0px 20px 0px 0px' }}
                    onChange={(e) => { setName(e.target.value) }}
                />
                <select className="genderselect" style={{ border: 'thin solid #707070', maxWidth: device === 'MOBILE' ? '15%' : '5%', minWidth: device === 'MOBILE' ? '15%' : '5%', backgroundColor: 'transparent', outline: 'none', padding: '0px 5px', }} value={gender} onChange={(event) => { setGender(event.target.value) }}>
                    <option value=""></option>
                    <option value="M">M</option>
                    <option value="F">F</option>
                </select>
            </div>
            {EMPTYSPACE}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input
                    className="userquestion_input"
                    value={spousename}
                    placeholder="Spouse's Name"
                    style={{ width: '100%', maxWidth: device === 'MOBILE' ? '85%' : '95%', backgroundColor: 'transparent', fontSize: 30, outline: 'none', border: 'transparent', borderBottom: 'thin solid #707070', borderRadius: 0, margin: '0px 20px 0px 0px' }}
                    onChange={(e) => { setSpousename(e.target.value) }}
                />
                <select className="genderselect" style={{ border: 'thin solid #707070', maxWidth: device === 'MOBILE' ? '15%' : '5%', minWidth: device === 'MOBILE' ? '15%' : '5%', backgroundColor: 'transparent', outline: 'none', padding: '0px 5px', }} value={spousegender} onChange={(event) => { setSpousegender(event.target.value) }}>
                    <option value=""></option>
                    <option value="M">M</option>
                    <option value="F">F</option>
                </select>
            </div>
        </div>
    )

    const qn_date_wedding = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ display: 'flex', color: '#707070' }}>When's your wedding date?</div>
                {device === 'MOBILE' ? // MOBILE
                    <DatePicker
                        dateFormat={'dd/MM/yyyy'}
                        withPortal
                        fixedHeight
                        selected={date_w}
                        onChange={(value) => {
                            var dateobj = new Date(value)
                            if (Checkdate(dateobj) && currpage === 1) setDate_w(dateobj)
                        }}
                        customInput={<CustomDatepicker />}
                    />
                    : <div className="customDatePickerWidth" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'start', height: 50, width: '100%' }}>
                        <DatePicker // DESKTOP AND TABLET
                            // value={date_w === '' ? '' : date_w}
                            withPortal
                            selected={date_w}
                            value={date_w}
                            fixedHeight
                            disabled={date_gate_w}
                            onChange={(value) => {
                                var dateobj = new Date(value)
                                if (Checkdate(dateobj) && currpage === 1) setDate_w(dateobj)
                            }}
                        />
                    </div>
                }

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                    <input
                        style={{ margin: 0, padding: 5 }}
                        type={"checkbox"}
                        checked={date_gate_w}
                        onChange={(e) => {
                            var date_gate = !date_gate_w
                            setDate_gate_w(date_gate)
                            if (date_gate) { // implies no date
                                setDate_w('')
                            }
                            else {
                                setDate_w('') // date becomes empty again
                            }
                        }}
                    />
                    <div style={{ padding: 5 }}>I don't have a date.</div>
                </div>
            </div>
        </div>
    )

    const qn_numguestsANDlocANDcustom_wedding = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ display: 'flex', color: '#707070' }}>About how many guests are you inviting? A rough estimate would do.</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 5 }}>
                <input style={{ width: 150, }} className="form-control" type="number" id="guests-invited" placeholder="50" pattern="\d*" value={numguests_w} onChange={(e) => { setNumguests_w(e.target.value) }} />
            </div>
            {EMPTYSPACE}
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ display: 'flex', color: '#707070' }}>Where are you getting married?</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 5 }}>
                <input style={{ width: 150, }} className="form-control" id="guests-invited" pattern="[A-Za-z]" value={city_w} onChange={(e) => { setCity_w(e.target.value) }} />
            </div>
        </div>

    )

    const qn_custom_wedding = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ display: 'flex', color: '#707070' }}>Tranditional wedding?</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 5 }}>
                {customs_dom}
            </div>
        </div>
    )

    const qn_budget_wedding = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ display: 'flex', color: '#707070' }}>What is your overall budget for your wedding?</div>
            </div>
            {/*budget card*/}
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 5 }}>
                {budget_dom}
            </div>
        </div>
    )

    // Event
    const qn_name_event = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', height: 'max-content' }}>
                <input
                    className={"userquestion_input"}
                    value={name}
                    placeholder="Your Name"
                    style={{ width: '100%', maxWidth: device === 'MOBILE' ? '85%' : '95%', backgroundColor: 'transparent', fontSize: 30, outline: 'none', border: 'transparent', borderBottom: 'thin solid black', borderRadius: 0, margin: '0px 20px 0px 0px' }}
                    onChange={(e) => { setName(e.target.value) }}
                />
                <select className="genderselect" style={{ border: 'thin solid #707070', maxWidth: device === 'MOBILE' ? '15%' : '5%', minWidth: device === 'MOBILE' ? '15%' : '5%', backgroundColor: 'transparent', outline: 'none', padding: '0px 5px', }} value={gender} onChange={(event) => { setGender(event.target.value) }}>
                    <option value=""></option>
                    <option value="M">M</option>
                    <option value="F">F</option>
                </select>
            </div>

        </div>
    )

    const qn_date_event = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
                <div style={{ display: 'flex', color: '#707070' }}>When's your event date?</div>

                {device === 'MOBILE' ?
                    <DatePicker
                        dateFormat={'dd/MM/yyyy'}
                        withPortal
                        fixedHeight
                        selected={date_e}
                        onChange={(value) => {
                            var dateobj = new Date(value)
                            // alert(value)
                            if (Checkdate(dateobj) && currpage === 1) setDate_e(dateobj)
                        }}
                        customInput={<CustomDatepicker />}
                    />
                    :
                    <div className="customDatePickerWidth" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, padding: '5px 0px 5px 5px', width: '100%' }}>
                        <DatePicker // DESKTOP AND TABLET
                            // value={date_w === '' ? '' : date_w}
                            selected={date_e}
                            value={date_e}
                            fixedHeight
                            disabled={date_gate_e}
                            onChange={(value) => {
                                var dateobj = new Date(value)
                                // alert(value)
                                if (Checkdate(dateobj) && currpage === 1) setDate_e(dateobj)
                            }}
                        />
                    </div>

                }

            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                <input
                    style={{ margin: 0, padding: 5 }}
                    type={"checkbox"}
                    checked={date_gate_e}
                    onChange={(e) => {
                        var date_gate = !date_gate_e
                        setDate_gate_e(date_gate)
                        if (date_gate) { // implies no date
                            setDate_e('')
                        }
                        else {
                            setDate_e('') // date becomes empty again
                        }
                    }}
                />
                <div style={{ padding: 5 }}>I don't have a date.</div>
            </div>
        </div>
    )

    const qn_numguests_event = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ display: 'flex', color: '#707070' }}>About how many guests are you inviting? A rough estimate would do.</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 5 }}>
                <input style={{ width: 150, }} className="form-control" type="number" id="guests-invited" placeholder="50" pattern="\d*" value={numguests_e} onChange={(e) => { setNumguests_e(e.target.value) }} />
            </div>
        </div>
    )

    const qn_budget_event = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ display: 'flex', color: '#707070' }}>What is your overall budget for your event?</div>
            </div>
            {/*budget card*/}
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 5 }}>
                {budget_dom}
            </div>
        </div>
    )

    if (rendergate) {
        return (
            <div className="container" style={{ display: 'flex', backgroundColor: 'white', height: '100vh', maxHeight: '100vh', minHeight: '100vh', width: '100vw', top: 0, justifyContent: 'center', flexDirection: 'column', touchAction: 'manipulation' }}>

                {/* title & qns */}
                <div style={{ display: 'flex', minHeight: '55%', justifyContent: 'flex-start', width: '100%', height: 'max-content', color: '#707070', flexDirection: 'column' }}>
                    <h1 style={{ padding: outerpadding }}>{STARTUP_NAME}</h1>
                    <h4 style={{ padding: outerpadding, fontWeight: 'normal', display: 'flex', flexDirection: 'row', whiteSpace: 'nospace' }}>
                        <span>{'Let us know more about you and your upcoming ' + data.usertype + ' plans!'}</span>
                    </h4>
                    {EMPTYSPACE}
                    {/* Start of qns */}
                    <div style={{ padding: outerpadding, display: 'flex', flexDirection: 'column', height: '40%', minHeight: '40%', justifyContent: 'flex-start' }}>

                        {currpage === 0 && usertype === 'Wedding' ? qn_name_wedding : <div />}
                        {currpage === 0 && usertype === 'Event' ? qn_name_event : <div />}

                        {currpage === 1 && usertype === 'Wedding' ? qn_date_wedding : <div />}
                        {currpage === 1 && usertype === 'Event' ? qn_date_event : <div />}

                        {currpage === 2 && usertype === 'Wedding' ? qn_numguestsANDlocANDcustom_wedding : <div />}
                        {currpage === 2 && usertype === 'Event' ? qn_numguests_event : <div />}

                        {currpage === 3 && usertype === 'Wedding' ? qn_custom_wedding : <div />}
                        {currpage === 3 && usertype === 'Event' ? qn_budget_event : <div />}

                        {currpage === 4 && usertype === 'Wedding' ? qn_budget_wedding : <div />}

                    </div>
                </div>

                {/* image */}
                <div style={{ position: 'absolute', transform: "translate(0,0) scale(-1,1) translate(-0,0)", opacity: 0.9, visibility: 'visible', bottom: 0, left: 0 }}>
                    {mainimg}
                </div>

                {/* back & next button & submit */}

                {(usertype === 'Wedding' && currpage !== 4) || (usertype === 'Event' && currpage !== 3) ?
                    <div style={{ display: 'flex', flexDirection: 'row', minHeight: '45%', minWidth: '100%', color: '#707070', justifyContent: 'flex-end', zIndex: 1, padding: outerpadding }}>
                        {onBack()}
                        {EMPTYSPACE}
                        {onNext()}
                    </div>
                    :
                    <div style={{ display: 'flex', flexDirection: 'row', minHeight: '45%', minWidth: '100%', color: '#707070', justifyContent: 'flex-end', zIndex: 1, padding: outerpadding }}>
                        {onBack()}
                        {EMPTYSPACE}
                        {onSubmit()}
                    </div>
                }


            </div>
        )
    }
    else {
        return <Loading />
    }




}