import React, { Component } from 'react'
import { iconwrapper } from '../../../../config/commonfunctions'
import { CROSS_ICON } from '../../../../config/svgicons'

class FilterDisplaySingleElem extends Component {

    render() {
        const { index, item, removeSingleFilterElem } = this.props
        return (
            <div style={{
                borderRadius: '100px 25px',
                display: 'flex',
                textAlign: 'center',
                minHeight: 'max-content',
                maxHeight: 'max-content',
                height: 'max-content',
                flexDirection: 'row',
                padding: '4px 8px 4px 0px',
                margin: '0px 6px 0px 3px',
                color: 'white',
                backgroundColor: 'black',
                borderRadius:100,
                whiteSpace: 'nowrap',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: 13
            }}>
                <button
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        outline: 'none',
                        padding: 0,
                    }}
                    onClick={() => { removeSingleFilterElem(item, index) }}
                >
                    {iconwrapper(
                        CROSS_ICON,
                        {
                            width: 20,
                            height: 20,
                            display: 'flex',
                            alignItems: 'center',
                            margin: '2px 5px',
                            borderRadius: 50,
                            color: 'var(--maindark)',
                            backgroundColor: '#ffffff'
                        }
                    )}
                </button>
                {item}
            </div>
        )
    }
}
export default FilterDisplaySingleElem