import React, { useState, useEffect, useRef, useCallback } from 'react'//{ useEffect, useState } 
import { ALLDEFAULTIMGS, TABLET } from '../../../../../../config/constants'
import { Checkdevice, Noti, ScreenWidth, getCroppedImg, Modcfm } from '../../../../../../components'
import { INVITATION_ADDIMG } from '../../../../../../config/api'
import { getS3fullpath_withbucketname, iconwrapper, Retrieve_personal_info } from '../../../../../../config/commonfunctions'
import { CROSS_ICON, SWAP_ICON, DRAG_ICON, BLINK_ICON, WEDO_PATH, HEART_PATH } from '../../../../../../config/svgicons'
import { inputdescribe_dom, partialtitle_dom, radiobtn_dom } from '../Commonelements/Commonelements'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Cropper from 'react-easy-crop'
import Explain from './../Explain'
import axios from 'axios'
import { HexColorInput, HexColorPicker } from 'react-colorful'
import Highlighttitle from '../Highlighttitle/Highlighttitle'
import './Images.css'

const MAX_NUM = 7 // max number can upload at once 
const TITLE = 'Gallery'
const TXT = "First image will be the main image, and subsequent image can be view from gallery. To entend to the next line in image so to trest the height."
const MAXFILESIZE = 1024 * 1024 * 10 // 10MB

const crosssvgcss = { width: 20, height: 20, display: 'flex', placeContent: 'center', borderRadius: 100, color: 'var(--mainpink)', backgroundColor: 'var(--transparentmaindark)' }
const swapsvgcss = { width: 24, height: 24, display: 'flex' }
const dragsvgcss = { width: 20, height: 20, display: 'flex', placeContent: 'center', margin: 5 }
const blinksvgcss = { width: 25, height: 25, backgroundColor: 'var(--transparentmaindark)', color: 'var(--mainpink)', display: 'flex', padding: 3, alignItems: 'center', }

const colors_arr = ['#333333', '#707070', '#e1d9ca', '#7b5c5c']

var Imagedownsize = (img, type) => {
    var canvas = document.createElement('canvas');
    var ctx1 = canvas.getContext("2d");
    ctx1.drawImage(img, 0, 0);
    var dataurl
    if (type === 'logo') {
        var MAX_WIDTH = 450;
        var MAX_HEIGHT = 450;
        var width = img.width;
        var height = img.height;

        if (width > height) { // this will adjust in ratio
            if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
            }
        } else {
            if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
            }
        }
        canvas.width = width;
        canvas.height = height;
        var ctx2 = canvas.getContext("2d");
        ctx2.drawImage(img, 0, 0, width, height)
        dataurl = canvas.toDataURL("image/png")
    }
    return dataurl
}

const dataURLToBlob = function (dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
        var parts1 = dataURL.split(',');
        var contentType1 = parts1[0].split(':')[1];
        var raw1 = parts1[1];
        return new Blob([raw1], { type: contentType1 });
    }

    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
}



function Images(props) {
    const { svgoverlay_map, updateSubpageback, selectedsubpage, updateSelectedsubpage, imgcss_map, updateImagecssmap, updateSvgoverlaymap, updateBackup, clearBackup, saveMainimgsprop, restoreBackup, subpageexit, updateSubpageexit } = props
    const [id, setId] = useState(null)
    const [images, setImages] = useState([]) // [ index 0 fullsize image , index 0 thumbnail image , ...., index n fullsize image , index n thumbnail image ]
    const [thumbnails, setThumbnails] = useState([])
    const [status, setStatus] = useState('')
    const [error, setError] = useState('')

    // const [selectedsubpage, setSelectedsubpage] = useState('')// '': is show main n gallery, 1: main img/svg update, 2: swap gallery

    const [urlhandler, setUrlhandler] = useState('')
    const [quota, setQuota] = useState(0)
    const [DEVICE_SIZE_MAP, setDEVICE_SIZE_MAP] = useState({})

    const { token } = Retrieve_personal_info()
    const device = Checkdevice()
    const wt = ScreenWidth()

    const addmainimginputref = useRef(null)
    const addimginputref = useRef(null)

    // for cropping image
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [tempmainimg, setTempmainimg] = useState(null)
    const [tempmainimg_small, setTempmainimg_small] = useState(null)
    const [tempmainimginfo, setTempmainimginfo] = useState(null)
    const [tempcroppedimg, setTempcroppedimg] = useState(null)

    const [uploadstate, setUploadstate] = useState('WAITING') // WAITING, UPLOADING

    const [showsave, setShowsave] = useState(false) // indicate save activate, when user make any savable actions.
    const [cfmdel, setCfmdel] = useState(false) // delete confirmation, for main and gallery image
    const [cfmdata, setCfmdata] = useState({}) // {'thumbnail' : 'img url', 'mainimg':'main img url'}


    useEffect(() => {
        setShowsave(false)

        setImages(props.images)

        // need drop the first two image, as the first two images is main images
        let new_thumbnails = []
        for (let i = 2; i < props.images.length; i++) {
            if (i % 2 === 1) {
                new_thumbnails.push(props.images[i])
            }
        }
        setThumbnails(new_thumbnails)

    }, [props.selectedsubpage])


    useEffect(() => {

        if (JSON.stringify(props.images) !== JSON.stringify(images)) {

            setImages(props.images)

            // need drop the first two image, as the first two images is main images
            let new_thumbnails = []
            for (let i = 2; i < props.images.length; i++) {
                if (i % 2 === 1) {
                    new_thumbnails.push(props.images[i])
                }
            }
            setThumbnails(new_thumbnails)

        }
        if (props.urlhandler) setUrlhandler(props.urlhandler)
        if (props.gallery_quota) setQuota(props.gallery_quota)
        if (props.invitation_id) setId(props.invitation_id)
    }, [props])

    useEffect(() => {
        if (subpageexit) { // triggered if user click exit from Highlighttitle and tick yes for saveprompt
            setShowsave(false)
            restoreBackup()
            updateSelectedsubpage('')
            updateSubpageexit()
        }
    }, [subpageexit])

    const wtfactor_w = (0.45 * 0.25)
    const wtfactor_t = (0.45 * 0.3333)
    const wtfactor_m = 0.5

    const webconst = `calc( ${wt * wtfactor_w}px - 18px )`
    const tabletconst = `calc( ${wt * wtfactor_t}px - 20px ) `
    const mobileconst = `calc( ${wt * wtfactor_m}px - 20px )`

    useEffect(() => {
        setDEVICE_SIZE_MAP({
            'WEB': { minHeight: webconst, minWidth: webconst, maxHeight: webconst, maxWidth: webconst, fontSize: 32, borderRadius: 10, },
            'TABLET': { minHeight: tabletconst, minWidth: tabletconst, maxHeight: tabletconst, maxWidth: tabletconst, fontSize: 32, borderRadius: 10, },
            'MOBILE': { minHeight: mobileconst, minWidth: mobileconst, maxHeight: mobileconst, maxWidth: mobileconst, fontSize: 20, borderRadius: 10, },
        })
    }, [wt])

    const btmbarroot_css = {
        'MOBILE': { position: 'fixed', bottom: 0, left: 0, width: '100%' },
        'TABLET': { position: 'fixed', bottom: 0, left: 0, width: wt * 0.45 - 1 },
        'WEB': { position: 'fixed', bottom: 0, left: 0, width: wt * 0.45 - 1 }
    }[device]

    const revert = () => {
        restoreBackup()
        setShowsave(false)
    }

    const onMaineditsave = () => {
        saveMainimgsprop().then((message) => {
            if (message === 'SUCCESS') {
                setShowsave(false)
                clearBackup()
            }
        })
    }

    const update_nonmainimg = (imgraw, imgthumbnail, imgtypes, names, orgimages) => {
        const addimg = (n, previmgs) => {
            if (n === 0) {
                setStatus('Done Uploading')
                setTimeout(() => {
                    let new_images = [...orgimages, ...previmgs]
                    setImages(new_images)
                    props.updateImages(new_images)

                    // need drop the first two image, as the first two images is main images
                    let new_thumbnails = []
                    for (let i = 2; i < new_images.length; i++) {
                        if (i % 2 === 1) {
                            new_thumbnails.push(new_images[i])
                        }
                    }
                    setThumbnails(new_thumbnails)

                    setStatus('')
                }, 1000)
                return true
            }
            else {

                var data = new FormData()
                var index = (imgraw.length - 1) - (n - 1)
                var curr_img = new File( // reconstruct image
                    [dataURLToBlob(imgraw[index])],
                    urlhandler + '.' + imgtypes[index].split('/')[1],
                    { type: imgtypes[index] }
                )
                var curr_thumbnail = new File( // reconstruct image
                    [dataURLToBlob(imgthumbnail[index])],
                    urlhandler + '.' + imgtypes[index].split('/')[1],
                    { type: imgtypes[index] }
                )

                data.append('urlhandler', urlhandler)
                data.append('id', id)
                data.append('img', curr_img)
                data.append('img', curr_thumbnail)
                data.append('imgclass', 'GALLERY')

                axios({
                    method: 'POST',
                    url: INVITATION_ADDIMG,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'multipart/form-data' }, // need token from the web
                    data: data
                }).then((item) => {
                    const { message, urls } = item.data // urls : [ img, thumbnail ]
                    setUploadstate('WAITING')
                    if (message === 'SUCCESS') {
                        setStatus('Uploading ' + names[index])
                        if (previmgs.length > 0) setImages([...images, ...previmgs]) // if gt prev image add them
                        setTimeout(() => { addimg(n - 1, [...previmgs, ...urls]) }, [1000])
                    }
                    else if (message === 'INCORRECTFILETYPE') {
                        setStatus('Incorrect File Type.')
                        setTimeout(() => { setStatus('') }, 1000)
                    }
                    else if (message === 'NOSUCHIC') {
                        setStatus('Invitation Does Not Exist.')
                        setTimeout(() => { setStatus('') }, 1000)
                    }
                    else if (message === 'REACHQUOTA') {
                        setStatus('Reach Max Quota.')
                        setTimeout(() => { setStatus('') }, 1000)
                    }
                }).catch((e) => {
                    setUploadstate('WAITING')
                })
            }
        }

        addimg(parseInt(imgraw.length, 10), [])
    }

    const update_mainimg = (croppedimgcanva, imgthumbnail, imgtypes, names) => {

        var data = new FormData()

        croppedimgcanva.toBlob((blob) => {
            var curr_img = new File( // reconstruct image
                [blob],
                urlhandler + '.' + imgtypes[0].split('/')[1],
                { type: 'image/' + imgtypes[0].split('/')[1] }
            )
            var curr_thumbnail = new File( // reconstruct image
                [dataURLToBlob(imgthumbnail[0])],
                urlhandler + 'thumbnail.' + imgtypes[0].split('/')[1],
                { type: imgtypes[0] }
            )

            data.append('urlhandler', urlhandler)
            data.append('id', id)
            data.append('img', curr_img)
            data.append('img', curr_thumbnail)
            data.append('imgclass', 'MAIN')

            axios({
                method: 'POST',
                url: INVITATION_ADDIMG,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'multipart/form-data' }, // need token from the web
                data: data
            }).then((item) => {
                const { message, urls, removedimgs } = item.data // urls : [ img, thumbnail ]
                if (message === 'SUCCESS') {
                    let new_images = images.slice()
                    if (removedimgs.length > 0) {
                        for (let i = 0; i < removedimgs.length; i++) {
                            new_images.splice(new_images.indexOf(removedimgs[i]), 1)
                        }
                    }
                    new_images = [...urls, ...new_images]

                    setStatus('Uploading ' + names[0])
                    setImages(new_images)
                    props.updateImages(new_images)
                    setTimeout(() => {
                        setTempmainimg(null)
                        setTempmainimginfo(null)
                        setTempcroppedimg(null)
                        setStatus('')
                        setUploadstate('WAITING')
                    }, 1000)
                }
                else if (message === 'INCORRECTFILETYPE') {
                    setStatus('Incorrect File Type.')
                    setTimeout(() => { setStatus('') }, 1000)
                }
                else if (message === 'NOSUCHIC') {
                    setStatus('Invitation Does Not Exist.')
                    setTimeout(() => { setStatus('') }, 1000)
                }
                else if (message === 'REACHQUOTA') {
                    setStatus('Reach Max Quota.')
                    setTimeout(() => { setStatus('') }, 1000)
                }
            }).catch((e) => {
                console.log(e)
            })
        }, 'image/jpeg');
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setTempcroppedimg(croppedAreaPixels)
    }, [])

    const uploading = async () => {
        setUploadstate('UPLOADING')
        const { name, type } = tempmainimginfo
        const croppedImage = await getCroppedImg(tempmainimg, tempcroppedimg)
        if (croppedImage) {
            // if (totalsize === imgraw.length) update_nonmainimg(imgraw, imgthumbnail, imgtypes, names, orgimages, false)
            update_mainimg(croppedImage, tempmainimg_small, type, name, images.slice())
        }
    }

    const add = (
        <button
            style={
                wt > 547 && wt < TABLET
                    ? {
                        minHeight: `calc( ${wt / 3}px - 20px )`,
                        minWidth: `calc( ${wt / 3}px - 20px )`,
                        maxHeight: `calc( ${wt / 3}px - 20px )`,
                        maxWidth: `calc( ${wt / 3}px - 20px )`,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: 'thin solid var(--maindark)',
                        backgroundColor: 'transparent',
                        borderRadius: 10,
                        padding: 0,
                        margin: 0
                    }
                    : {
                        ...DEVICE_SIZE_MAP[device],
                        fontSize: { 'WEB': 14, 'TABLET': 13, 'MOBILE': 14 }[device],
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: 'thin solid var(--maindark)',
                        backgroundColor: 'transparent',
                        cursor: 'pointer',
                        padding: 0,
                        margin: 0
                    }

                // wt > 547 && wt < TABLET
                //     ? {
                //         minHeight: `calc( ${wt / 3}px - 20px )`,
                //         minWidth: `calc( ${wt / 3}px - 20px )`,
                //         maxHeight: `calc( ${wt / 3}px - 20px )`,
                //         maxWidth: `calc( ${wt / 3}px - 20px )`,
                //         display: 'flex',
                //         flexDirection: 'column',
                //         justifyContent: 'center',
                //         alignItems: 'center',
                //         border: 'thin solid var(--maindark)',
                //         backgroundColor: 'transparent',
                //         borderRadius: 10,
                //     }
                //     : { ...DEVICE_SIZE_MAP[device], fontSize: { 'WEB': 14, 'TABLET': 13, 'MOBILE': 14 }[device], display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: 'thin solid var(--maindark)', backgroundColor: 'transparent', cursor: 'pointer' }
            }
            onClick={() => addimginputref.current.click()}
        >
            <div>+</div>
            <div style={{ color: '#acacac', cursor: 'pointer' }}>{'MAX ' + (quota - 1)}</div>
            <div style={{ color: '#acacac' }}>Less than</div>
            <div style={{ color: '#acacac' }}>10MB</div>
            <input
                ref={addimginputref}
                accept="image/*"
                className="inputfile"
                id="formControlsFile"
                type='file'
                multiple="multiple"
                name="input-file"
                style={{ opacity: 0, position: 'absolute' }}
                onChange={async (e) => {

                    if (Array.from(e.target.files).length > MAX_NUM) {
                        setError(`Cannot upload files more than ${MAX_NUM}`);
                        setTimeout(() => {
                            setError('')
                        }, 1000)
                        return;
                    }

                    if (images.length < (quota * 2)) {

                        let imgraw = []
                        let imgthumbnail = []
                        let imgtypes = []
                        let names = []
                        let totalsize = e.target.files.length
                        let orgimages = images.slice()

                        // second loop to add to memory
                        for (let i = 0; i < e.target.files.length; i++) {
                            var reader = new FileReader()
                            let file = e.target.files[i]
                            reader.onload = (e) => {
                                var img = document.createElement("img");
                                img.onload = () => {
                                    const { size, name, type } = file
                                    if (size < MAXFILESIZE) { // 2MB : 2097152, 20MB : 20000000, 1MB : 1048576
                                        names.push(name)
                                        imgtypes.push(type)
                                        imgraw.push(img.src)
                                        imgthumbnail.push(Imagedownsize(img, 'logo'))
                                        if (totalsize === imgraw.length) update_nonmainimg(imgraw, imgthumbnail, imgtypes, names, orgimages, false)
                                    }
                                    else {
                                        setError(`Image size must be less than 20MB`);
                                        setTimeout(() => {
                                            setError('')
                                        }, 3000)
                                    }
                                }
                                img.src = e.target.result;
                            }
                            reader.readAsDataURL(file);
                        }
                    }
                    else {
                        alert('You have reach max quota')
                    }
                    // e.target.value = null;
                }}
            />
        </button>
    )

    const addmain = <button
        style={
            wt > 547 && wt < TABLET
                ? {
                    minHeight: `calc( ${wt / 3}px - 20px )`,
                    minWidth: `calc( ${wt / 3}px - 20px )`,
                    maxHeight: `calc( ${wt / 3}px - 20px )`,
                    maxWidth: `calc( ${wt / 3}px - 20px )`,
                    fontSize: { 'WEB': 14, 'TABLET': 13, 'MOBILE': 14 }[device],
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: 'thin solid var(--maindark)',
                    backgroundColor: 'transparent',
                    borderRadius: 10,
                    pointer: 'cursor'
                }
                : { ...DEVICE_SIZE_MAP[device], fontSize: { 'WEB': 14, 'TABLET': 13, 'MOBILE': 14 }[device], display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: 'thin solid var(--maindark)', backgroundColor: 'transparent', pointer: 'cursor' }
        }
        onClick={() => {
            addmainimginputref.current.click()
        }}
    >
        <div>+</div>
        <div style={{ color: '#acacac', cursor: 'pointer' }}>{'MAX 1'}</div>
        {/* <div style={{ color: '#acacac', textAlign: 'center', }}>{'Preferably Portrait'}</div> */}
        <div style={{ color: '#acacac' }}>Less than </div>
        <div style={{ color: '#acacac' }}>10MB</div>
        <input
            ref={addmainimginputref}
            accept="image/*"
            className="inputfile"
            id="formControlsFile"
            type='file'
            name="input-file"
            style={{ position: 'absolute', visibility: 'hidden', maxWidth: 0, maxHeight: 0 }}
            onChange={async (e) => {
                var reader = new FileReader()
                let file = e.target.files[0]
                reader.onload = (e) => {
                    var img = document.createElement("img");
                    img.onload = () => {
                        if (file.size < 20000000) { // 2MB : 2097152, 20MB : 20000000
                            const { name, type } = file
                            setTempmainimg([img.src])
                            setTempmainimg_small([Imagedownsize(img, 'logo')])
                            setTempmainimginfo({ name: [name], type: [type] })
                            // proceed to cropping
                        }
                        else {
                            setError(`Image size must be less than 10MB`);
                            setTimeout(() => {
                                setError('')
                            }, 3000)
                        }
                    }
                    img.src = e.target.result;
                }
                reader.readAsDataURL(file);
            }}
        />
    </button>

    const crop_dom = tempmainimg
        ? <div className="Imageic_croproot">
            <div className="Imageic_cropcontainer">
                <Cropper
                    image={tempmainimg}
                    crop={crop}
                    zoom={zoom}
                    aspect={3 / 4}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
                <div className="Imageic_btnsrow">
                    {uploadstate === 'WAITING'
                        ? <button
                            key={'imgs_cancel_' + uploadstate}
                            className="Imageic_btn"
                            onClick={() => {
                                setTempmainimg(null)
                                setTempmainimginfo(null)
                                setTempcroppedimg(null)
                            }}>
                            Cancel
                        </button>
                        : null}
                    {uploadstate === 'UPLOADING'
                        ? <div className="Imageic_uploading" > Uploading... </div> : null}
                    {uploadstate === 'WAITING'
                        ? <button
                            key={'imgs_upload_' + uploadstate}
                            className="Imageic_btn"
                            style={{ marginRight: 5 }}
                            disabled={uploadstate === 'UPLOADING'}
                            onClick={uploading}>Upload</button>
                        : null}
                </div>
            </div>
        </div>
        : null

    const onDragend = (result) => {

        const { source, destination } = result
        if (!destination) { return }
        if (!showsave) { updateBackup(); setShowsave(true); }

        let new_thumbnails = thumbnails.slice()
        const [removed] = new_thumbnails.splice(source.index, 1);
        new_thumbnails.splice(destination.index, 0, removed);

        setThumbnails(new_thumbnails)
    }

    const getItemStyle = (draggableStyle) => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 10,
        marginBottom: 5,
        backgroundColor: 'white',
        placeContent: 'space-between',
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? '#707070' : 'white',
        padding: isDraggingOver ? 5 : 0,
    });

    const dragcontent_dom = <DragDropContext onDragEnd={onDragend}>
        <Droppable droppableId={'droppable'} >
            {(provided, snapshot) => (
                <div className='Detailsdrag_root' ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                    {thumbnails.map((img, x) => {
                        return <Draggable key={x + '_'} draggableId={x + 'id'} index={x}>
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(provided.draggableProps.style)}>
                                    <div className='Images_swapimgentity'>
                                        <div className='Images_swapimgindex' >{x + 1}</div>
                                        <img src={getS3fullpath_withbucketname('USER_INVITATIONCARD_IMG', img)} className='Images_swapimg' />
                                    </div>
                                    <span className='Detailsdrag_dragicon'>{iconwrapper(DRAG_ICON, dragsvgcss)}</span>
                                </div>
                            )}
                        </Draggable>
                    })}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    </DragDropContext>

    const content_dom = <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Explain title={TITLE} txt={TXT} />

        <div className='Details_grp'>
            {partialtitle_dom('Main Image', {})}
            {inputdescribe_dom('The first image that will appear once your guest enter.')}
            <div className={`Imageinvitationcard_root${device}`} style={{ flexDirection: 'column', padding: 0 }}>
                {images.length < (quota * 2) ? addmain : null}
                {images.map((thumbnailimg, index) => {
                    // alert(thumbnailimg)
                    if (index === 0 || index === 1) {
                        const fullsizeimg = images[index - 1]
                        return index % 2 === 1
                            ? <div
                                key={'img' + index}
                                style={
                                    wt > 547 && wt < TABLET // for device tht is ibtw mobile and tablet
                                        ? {
                                            minHeight: `calc( ${wt / 3}px - 20px )`,
                                            minWidth: `calc( ${wt / 3}px - 20px )`,
                                            maxHeight: `calc( ${wt / 3}px - 20px )`,
                                            maxWidth: `calc( ${wt / 3}px - 20px )`,
                                            backgroundImage: `url(${(ALLDEFAULTIMGS.indexOf(thumbnailimg) !== -1) ? thumbnailimg : getS3fullpath_withbucketname('USER_INVITATIONCARD_IMG', thumbnailimg)})`,
                                            position: 'relative',
                                            flexDirection: 'column',
                                            boxShadow: '0 0 0 1px rgb(0 0 0 / 10%) inset',
                                            borderRadius: 10,
                                        }
                                        : {
                                            ...DEVICE_SIZE_MAP[device],
                                            backgroundImage: `url(${(ALLDEFAULTIMGS.indexOf(thumbnailimg) !== -1) ? thumbnailimg : getS3fullpath_withbucketname('USER_INVITATIONCARD_IMG', thumbnailimg)})`,
                                            backgroundSize: 'cover',
                                            position: 'relative',
                                            flexDirection: 'column',
                                            boxShadow: '0 0 0 1px rgb(0 0 0 / 10%) inset',
                                            borderRadius: 10
                                        }
                                }
                            >
                                {/* {thumbnailimg} */}
                                <button className='Images_blinkicon' onClick={() => { updateSelectedsubpage(1) }} >{iconwrapper(BLINK_ICON, blinksvgcss)}</button>
                                {/* 
                                    <div style={
                                        wt > 547 && wt < TABLET // for device tht is ibtw mobile and tablet
                                            ? {
                                                minHeight: `calc( ${wt / 3}px - 20px )`,
                                                minWidth: `calc( ${wt / 3}px - 20px )`,
                                                maxHeight: `calc( ${wt / 3}px - 20px )`,
                                                maxWidth: `calc( ${wt / 3}px - 20px )`,
                                                borderRadius: 10,
                                                backgroundImage: `url(${thumbnailimg})`, backgroundSize: 'cover',
                                                position: 'relative', flexDirection: 'column', boxShadow: '0 0 0 1px rgb(0 0 0 / 10%) inset', borderRadius: 10
                                            }
                                            : { ...DEVICE_SIZE_MAP[device], backgroundImage: `url(${thumbnailimg})`, backgroundSize: 'cover',position: 'relative', flexDirection: 'column', boxShadow: '0 0 0 1px rgb(0 0 0 / 10%) inset', borderRadius: 10 }
                                    } /> */}

                                {ALLDEFAULTIMGS.indexOf(images[index - 1]) === -1
                                    ? <button
                                        style={{ display: 'flex', top: 0, right: 0, position: 'absolute', margin: 10, padding: 0, backgroundColor: 'transparent', border: 'none' }}
                                        onClick={() => {
                                            setCfmdel(true)
                                            setCfmdata({ thumbnailimg: thumbnailimg, fullsizeimg: fullsizeimg })
                                        }}
                                    >
                                        {iconwrapper(CROSS_ICON, crosssvgcss)}
                                    </button>
                                    : null}
                                {(ALLDEFAULTIMGS.indexOf(images[index - 1]) !== -1)
                                    ? <div className='Imageic_defaulttxt'>
                                        {/* {ALLDEFAULTIMGS.indexOf(images[index - 1])} */}
                                        <div style={{ fontSize: { 'WEB': 14, 'TABLET': 13, 'MOBILE': 14 }[device] }}>Default Image</div>
                                    </div>
                                    : null}
                            </div>
                            : null
                    }
                    else return null
                })}
            </div>
        </div>

        <div className='Details_grp' >
            {partialtitle_dom('Gallery Image', {})}
            {inputdescribe_dom('Gallery for your guest to view when your guest access your website.')}
            <div style={{ width: '100%', height: 40, display: 'flex', placeContent: 'flex-end', marginBottom: 5 }}>
                <button className="Images_swap_btn" onClick={() => { updateSelectedsubpage(2); }}>{iconwrapper(SWAP_ICON, swapsvgcss)}</button>
            </div>

            <div className={`Imageinvitationcard_root${device}`} >
                {images.length < (quota * 2) ? add : null}
                {images.map((img, index) => {
                    if (index === 0 || index === 1) {
                        return null
                    }
                    else {
                        // return index % 2 === 1
                        //     ? <div key={'img' + index} style={{ position: 'relative', flexDirection: 'column', boxShadow: '0 0 0 1px rgb(0 0 0 / 10%) inset', borderRadius: 10, overflow:'hidden' }}>
                        //         <div style={
                        //             wt > 547 && wt < TABLET
                        //                 ? {
                        //                     minHeight: `calc( ${wt / 3}px - 20px )`,
                        //                     minWidth: `calc( ${wt / 3}px - 20px )`,
                        //                     maxHeight: `calc( ${wt / 3}px - 20px )`,
                        //                     maxWidth: `calc( ${wt / 3}px - 20px )`,
                        //                     fontSize: 32,
                        //                     borderRadius: 10,
                        //                     backgroundImage: `url(${img})`,
                        //                     backgroundSize: 'cover'
                        //                 }
                        //                 : { ...DEVICE_SIZE_MAP[device], backgroundImage: `url(${img})`, backgroundSize: 'cover' }
                        //         } />
                        //         <button
                        //             style={{ display: 'flex', top: 0, right: 0, position: 'absolute', margin: 10, padding: 0, backgroundColor: 'transparent', border: 'none' }}
                        //             onClick={() => {
                        //                 setCfmdel(true)
                        //                 setCfmdata({ thumbnailimg: img, fullsizeimg: images[index - 1] })
                        //                 //  onImgremove(img, images[index - 1])
                        //             }}>
                        //             {iconwrapper(CROSS_ICON, crosssvgcss)}
                        //         </button>
                        //     </div>
                        //     : null
                        return index % 2 === 1
                            ? <div key={'img' + index} style={{ position: 'relative', flexDirection: 'column', boxShadow: '0 0 0 1px rgb(0 0 0 / 10%) inset', borderRadius: 10, overflow: 'hidden', display: 'flex', alignItems: 'center', placeContent: 'center', padding: 0, margin: 0 }}>
                                <div style={
                                    wt > 547 && wt < TABLET
                                        ? {
                                            minHeight: `calc( ${wt / 3}px - 20px )`,
                                            minWidth: `calc( ${wt / 3}px - 20px )`,
                                            maxHeight: `calc( ${wt / 3}px - 20px )`,
                                            maxWidth: `calc( ${wt / 3}px - 20px )`,
                                            fontSize: 32,
                                            borderRadius: 10,
                                            backgroundImage: `url(${getS3fullpath_withbucketname('USER_INVITATIONCARD_IMG', img)})`,
                                            backgroundSize: 'cover'
                                        }
                                        : {
                                            ...DEVICE_SIZE_MAP[device],
                                            backgroundImage: `url(${getS3fullpath_withbucketname('USER_INVITATIONCARD_IMG', img)})`,
                                            backgroundSize: 'cover'
                                        }
                                } />
                                <button
                                    style={{ display: 'flex', top: 0, right: 0, position: 'absolute', margin: 10, padding: 0, backgroundColor: 'transparent', border: 'none' }}
                                    onClick={() => {
                                        setCfmdel(true)
                                        setCfmdata({ thumbnailimg: img, fullsizeimg: images[index - 1] })
                                        //  onImgremove(img, images[index - 1])
                                    }}>
                                    {iconwrapper(CROSS_ICON, crosssvgcss)}
                                </button>
                            </div>
                            : null
                    }
                })}
            </div>

        </div>

    </div>

    const updateColor = (c) => {
        if (!showsave) { updateBackup(); setShowsave(true) }
        let new_svgoverlay_map = JSON.parse(JSON.stringify(svgoverlay_map))
        new_svgoverlay_map['mainimg']['color'] = c
        updateSvgoverlaymap(new_svgoverlay_map)
    }

    const updateAnimationmode = (e) => {
        if (!showsave) { updateBackup(); setShowsave(true) }

        const { value } = e.target
        let new_svgoverlay_map = JSON.parse(JSON.stringify(svgoverlay_map))
        new_svgoverlay_map['mainimg']['mode'] = parseInt(value)
        updateSvgoverlaymap(new_svgoverlay_map)
    }

    const updateAnimatingspeed = (e) => {
        if (!showsave) { updateBackup(); setShowsave(true) }

        const { value } = e.target
        let new_svgoverlay_map = JSON.parse(JSON.stringify(svgoverlay_map))
        new_svgoverlay_map['mainimg']['speed'] = parseInt(value)
        updateSvgoverlaymap(new_svgoverlay_map)
    }

    const updateThickness = (e) => {
        if (!showsave) { updateBackup(); setShowsave(true) }
        const { value } = e.target
        let new_svgoverlay_map = JSON.parse(JSON.stringify(svgoverlay_map))
        new_svgoverlay_map['mainimg']['sw'] = parseInt(value)
        updateSvgoverlaymap(new_svgoverlay_map)
    }

    const updatePosition = (e) => {
        if (!showsave) { setShowsave(true); updateBackup(); }
        const { value } = e.target
        const new_svgoverlay_map = JSON.parse(JSON.stringify(svgoverlay_map))
        new_svgoverlay_map['mainimg']['svgposition'] = parseInt(value)
        updateSvgoverlaymap(new_svgoverlay_map)
    }

    const commoneditstyle = {
        height: {
            'WEB': 'calc(100% - 70px - 65px)',
            'TABLET': 'calc(100% - 70px - 65px)',
            'MOBILE': 'calc(100% - 70px)'
        }[device],
        marginBottom: {
            'MOBILE': 65
        }[device]
    }

    const mainimgeditbtmbar = selectedsubpage === 1
        ? <div className='iedit_swaproot' style={btmbarroot_css} >
            <button className='iedit_cancel'
                disabled={!showsave}
                style={{ opacity: !showsave ? 0.5 : 1 }}
                onClick={revert}
            >
                Revert
            </button>
            <button
                className='iedit_save'
                disabled={!showsave}
                style={{ opacity: !showsave ? 0.5 : 1 }}
                onClick={onMaineditsave}
            >
                Save
            </button>
        </div>
        : null

    const mainimgedit_dom = <div className='Ieditcomp_div' style={commoneditstyle}>

        <div className="Details_grp">
            {partialtitle_dom('Animated Layer', {})}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <button
                    className={`Images_firstsvg ${svgoverlay_map['mainimg']['svgitem'] === 0 ? 'Images_borderselected' : ''}`}
                    style={{
                        borderBottom: svgoverlay_map['mainimg']['svgitem'] === 1
                            ? 'none'
                            : svgoverlay_map['mainimg']['svgitem'] === 0
                                ? '3px solid var(--mainpink)'
                                : '1px solid var(--subgrey)'
                    }}
                    onClick={() => {
                        if (!showsave) { updateBackup(); setShowsave(true) }
                        let new_svgoverlay_map = JSON.parse(JSON.stringify(svgoverlay_map))
                        new_svgoverlay_map['mainimg']['svgitem'] = 0
                        updateSvgoverlaymap(new_svgoverlay_map)
                    }}
                >

                    <div className="Images_svgtxt">Empty</div>
                </button>
                <button
                    className={`Images_midsvg ${svgoverlay_map['mainimg']['svgitem'] === 1 ? 'Images_borderselected' : ''}`}
                    style={{
                        borderTop: svgoverlay_map['mainimg']['svgitem'] === 0
                            ? 'none'
                            : svgoverlay_map['mainimg']['svgitem'] === 1
                                ? '3px solid var(--mainpink)'
                                : 'none',
                        borderBottom: svgoverlay_map['mainimg']['svgitem'] === 2
                            ? 'none'
                            : svgoverlay_map['mainimg']['svgitem'] === 1
                                ? '3px solid var(--mainpink)'
                                : 'none',
                    }}
                    onClick={() => {
                        if (!showsave) { updateBackup(); setShowsave(true) }
                        let new_svgoverlay_map = JSON.parse(JSON.stringify(svgoverlay_map))
                        new_svgoverlay_map['mainimg']['svgitem'] = 1
                        updateSvgoverlaymap(new_svgoverlay_map)
                    }}
                >
                    <svg className='Images_svgitem' width="100%" height="100%" viewBox="0 0 5030 1032" fill="none" xmlns="http://www.w3.org/2000/svg">
                        {WEDO_PATH}
                    </svg>
                    <div className="Images_svgtxt">We Do</div>
                </button>
                <button
                    className={`Images_secondsvg ${svgoverlay_map['mainimg']['svgitem'] === 2 ? 'Images_borderselected' : ''}`}
                    style={{
                        borderTop: svgoverlay_map['mainimg']['svgitem'] === 1
                            ? 'none'
                            : svgoverlay_map['mainimg']['svgitem'] === 2
                                ? '3px solid var(--mainpink)'
                                : '1px solid var(--subgrey)'
                    }}
                    onClick={() => {
                        if (!showsave) { updateBackup(); setShowsave(true) }
                        let new_svgoverlay_map = JSON.parse(JSON.stringify(svgoverlay_map))
                        new_svgoverlay_map['mainimg']['svgitem'] = 2
                        updateSvgoverlaymap(new_svgoverlay_map)
                    }}
                >
                    <svg className='Images_svgitem' width="100%" height="100%" viewBox="0 0 5030 1032" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        {HEART_PATH}
                    </svg>
                    <div className="Images_svgtxt">Heart</div>
                </button>

            </div>
        </div>

        <div className="Details_grp">
            {partialtitle_dom('Animated Text Color', {})}
            {inputdescribe_dom(`Color of animated text. `)}
            <div className='Detailsfont_container'>
                <div className='Detailsfont_left'>
                    <HexColorPicker style={{ height: 200, width: 200 }} color={svgoverlay_map['mainimg']['color']} onChange={updateColor} />
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
                        <div style={{ paddingRight: 3, fontSize: 18 }}>#</div>
                        <HexColorInput color={svgoverlay_map['mainimg']['color']} onChange={updateColor} style={{ width: '100%', borderRadius: 8, padding: '3px 5px', border: '1px solid var(--maindark)' }} />
                    </div>
                </div>
                <ul className='Detailsfont_right'>
                    {colors_arr.map((c, i) => {
                        return <div key={i + 'color1'} style={{ padding: 1, width: 'max-content', height: 'max-content', display: 'flex', placeContent: 'center', alignItems: 'center', border: svgoverlay_map['mainimg']['color'] === c ? '3px solid var(--mainpink)' : '3px solid transparent', borderRadius: 5 }}>
                            <li style={{ width: 20, height: 20, backgroundColor: c, border: 'none', cursor: 'pointer', borderRadius: 3, border: svgoverlay_map['mainimg']['color'] === c ? 'none' : '1px solid #dddddd' }} onClick={() => updateColor(c)} />
                        </div>
                    })}
                </ul>
            </div>
        </div>

        <div className="Details_grp">
            {partialtitle_dom('Animated Text Animation Mode', {})}
            {inputdescribe_dom(`Animation state of animated text.`)}
            <div className='Images_animateselectroot'>
                <button className={`Images_animatestate ${svgoverlay_map['mainimg']['mode'] === 0 ? 'Images_selected' : 'Images_selectednone'}`} value={0} onClick={updateAnimationmode}>Static</button>
                <button className={`Images_animatestate ${svgoverlay_map['mainimg']['mode'] === 1 ? 'Images_selected' : 'Images_selectednone'}`} value={1} onClick={updateAnimationmode}>Entrance Animation</button>
                <button className={`Images_animatestate ${svgoverlay_map['mainimg']['mode'] === 2 ? 'Images_selected' : 'Images_selectednone'}`} value={2} onClick={updateAnimationmode}>Infinite Animation</button>
            </div>
        </div>

        <div className="Details_grp">
            {partialtitle_dom('Animating speed ', {})}
            {inputdescribe_dom(`Speed of animating text`)}
            <div className='Images_animateselectroot'>
                <button className={`Images_animatestate Images_animateht ${svgoverlay_map['mainimg']['speed'] === 0 ? 'Images_selected' : 'Images_selectednone'}`} value={0} onClick={updateAnimatingspeed}>Slow</button>
                <button className={`Images_animatestate Images_animateht ${svgoverlay_map['mainimg']['speed'] === 1 ? 'Images_selected' : 'Images_selectednone'}`} value={1} onClick={updateAnimatingspeed}>Medium</button>
                <button className={`Images_animatestate Images_animateht ${svgoverlay_map['mainimg']['speed'] === 2 ? 'Images_selected' : 'Images_selectednone'}`} value={2} onClick={updateAnimatingspeed}>Fast</button>
            </div>
        </div>

        <div className="Details_grp">
            {partialtitle_dom('Animated Text Thickness', {})}
            {inputdescribe_dom(`Select the thickness level of animated text.`)}
            <div className='Images_animateselectroot'>
                <button className={`Images_animatestate Images_animateht ${svgoverlay_map['mainimg']['sw'] === 15 ? 'Images_selected' : 'Images_selectednone'}`} value={15} onClick={updateThickness}>Thin</button>
                <button className={`Images_animatestate Images_animateht ${svgoverlay_map['mainimg']['sw'] === 30 ? 'Images_selected' : 'Images_selectednone'}`} value={30} onClick={updateThickness}>Thick</button>
                <button className={`Images_animatestate Images_animateht ${svgoverlay_map['mainimg']['sw'] === 45 ? 'Images_selected' : 'Images_selectednone'}`} value={45} onClick={updateThickness}>Thicker</button>
            </div>
        </div>

        <div className="Details_grp">
            {partialtitle_dom('Animated Text Position', {})}
            {inputdescribe_dom(`Position of animated text.`)}
            <div className='Images_animateselectroot'>
                <button className={`Images_animatestate Images_animateht ${svgoverlay_map['mainimg']['svgposition'] === 0 ? 'Images_selected' : 'Images_selectednone'}`} value={0} onClick={updatePosition}>Top</button>
                <button className={`Images_animatestate Images_animateht ${svgoverlay_map['mainimg']['svgposition'] === 1 ? 'Images_selected' : 'Images_selectednone'}`} value={1} onClick={updatePosition}>Center</button>
                <button className={`Images_animatestate Images_animateht ${svgoverlay_map['mainimg']['svgposition'] === 2 ? 'Images_selected' : 'Images_selectednone'}`} value={2} onClick={updatePosition}>Bottom</button>
            </div>
        </div>

        <div className='Details_grp'>
            {partialtitle_dom('Replay Button', {})}
            {inputdescribe_dom(`On to allow user to replay animated text vice versa. Only available on "Infinite" mode.`)}
            {radiobtn_dom(
                svgoverlay_map['mainimg']['replay'] ? true : false,
                () => {
                    if (!showsave) { updateBackup(); setShowsave(true) }
                    let new_svgoverlay_map = JSON.parse(JSON.stringify(svgoverlay_map))
                    new_svgoverlay_map['mainimg']['replay'] = !new_svgoverlay_map['mainimg']['replay']
                    updateSvgoverlaymap(new_svgoverlay_map)
                },
                svgoverlay_map['mainimg']['replay'] ? `Replay Enabled` : `Replay Disabled`,
                { margin: 0 }
            )}
        </div>

        <div className='Details_grp'>
            {partialtitle_dom('Box-shadow for Main Image', {})}
            {inputdescribe_dom(`On box-shadow for your main image vice versa`)}
            {radiobtn_dom(
                imgcss_map['mainimg']['boxShadow'] ? true : false,
                () => {
                    if (!showsave) { updateBackup(); setShowsave(true) }

                    let new_imgcss_map = JSON.parse(JSON.stringify(imgcss_map))
                    new_imgcss_map['mainimg']['boxShadow'] = !new_imgcss_map['mainimg']['boxShadow']
                    updateImagecssmap(new_imgcss_map)
                },
                imgcss_map['mainimg']['boxShadow'] ? `Box-shadow Enabled` : `Box-shadow Disabled`,
                { margin: 0 }
            )}
        </div>

        <div className='Details_grp'>
            {partialtitle_dom('Corner for main image.', {})}
            {inputdescribe_dom(`Select type of corner for your image`)}
            {radiobtn_dom(
                imgcss_map['mainimg']['borderRadius'] ? true : false,
                () => {
                    if (!showsave) { updateBackup(); setShowsave(true) }
                    let new_imgcss_map = JSON.parse(JSON.stringify(imgcss_map))
                    new_imgcss_map['mainimg']['borderRadius'] = !new_imgcss_map['mainimg']['borderRadius']
                    updateImagecssmap(new_imgcss_map)
                },
                imgcss_map['mainimg']['borderRadius'] ? `Round Corner Enabled` : `Round Corner Disabled`,
                { margin: 0 }
            )}
        </div>
        {mainimgeditbtmbar}

    </div>

    const swapbtmbar = selectedsubpage === 2
        ? <div className='iedit_swaproot' style={btmbarroot_css}>
            <button
                className='iedit_cancel'
                disabled={!showsave}
                style={{ opacity: !showsave ? 0.5 : 1 }}
                onClick={() => {
                    setShowsave(false)
                    restoreBackup()

                    // need drop the first two image, as the first two images is main images
                    let new_thumbnails = []
                    for (let i = 2; i < props.images.length; i++) {
                        if (i % 2 === 1) {
                            new_thumbnails.push(props.images[i])
                        }
                    }
                    setThumbnails(new_thumbnails)
                    // setSelectedsubpage(0)
                }}>
                Revert
            </button>
            <button
                className='iedit_save'
                style={{ opacity: !showsave ? 0.5 : 1 }}
                disabled={!showsave}
                onClick={() => {
                    if (showsave) {
                        let new_images = [images[0], images[1]]
                        let local_thumbnails = thumbnails.slice()
                        for (let i = 0; i < local_thumbnails.length; i++) {
                            var thumbnail_index = images.indexOf(local_thumbnails[i])

                            new_images.push(images[thumbnail_index - 1]) // full size image of the current thumbnail
                            new_images.push(images[thumbnail_index])// thumbnail
                        }
                        props.saveSwapimages(new_images).then((message) => {
                            if (message === 'SUCCESS') {
                                setShowsave(false)
                                updateSelectedsubpage('')
                            }
                            else {
                                setShowsave(true)
                                updateSelectedsubpage(2)
                            }
                        })
                    }
                }}
            >
                Save
            </button>
        </div>
        : null

    const galleryimgswap_dom = <div className='Ieditcomp_div' style={commoneditstyle}>
        {dragcontent_dom}
        {swapbtmbar}
    </div>

    if (selectedsubpage === '') {
        return <div className='Ieditcomp_div'>
            {content_dom}
            <Noti noti={status} />
            <Noti noti={error} />
            {cfmdel
                ? <Modcfm
                    onHide={() => { setCfmdel(false) }}
                    onOk={async () => {
                        const { thumbnailimg, fullsizeimg } = cfmdata
                        setCfmdel(false)
                        props.delSingleimage(thumbnailimg, fullsizeimg).then((message) => {
                            const { result, msg } = message
                            if (result && msg === 'Saved') {
                                setCfmdata({})
                            }
                        })
                    }}
                    onNo={() => { setCfmdel(false) }}
                    txt={'Once you delete this, there is no going back. Please be certain.'}
                />
                : null}
            {crop_dom}
        </div>
    }
    else if (selectedsubpage === 1) {
        return <div id="Ieditcomp_root">
            <div id='Ieditcomp_contentroot'>
                <Highlighttitle icon={null} selectedsubpage={selectedsubpage} onClick={updateSubpageback} />
                {mainimgedit_dom}
                <Noti noti={status} />
                <Noti noti={error} />
            </div>
        </div>
    }
    else if (selectedsubpage === 2) {
        return <div id="Ieditcomp_root">
            <div id='Ieditcomp_contentroot'>
                <Highlighttitle icon={null} selectedsubpage={selectedsubpage} onClick={updateSubpageback} />
                {galleryimgswap_dom}
                <Noti noti={status} />
                <Noti noti={error} />
            </div>
        </div>

    }
    return <div style={{ visibility: 'hidden' }} />



}

export default Images