import React, { useState, useEffect } from 'react'
import { Checkdevice, Loading, Modcfm, Noti, ScreenHeight } from '../../../../../components'
import { READ_GBEDIT, RMV_GBEDITMSG, UPDATE_GBEDITCASTTYPE, UPDATE_GBEDITMSGTYPE, UPDATE_GBEDITMODPC, PURCHASE_MM, UPDATE_GBEDITCASTPC, RMV_GBEDITGALLERYIMG, UPDATE_GBEDITGALLERYIMG, PURCHASE_GBS, PURCHASE_GBSH } from '../../../../../config/api'
import { Retrieve_personal_info } from '../../../../../config/commonfunctions'
import { ARROWLEFT_ICON, PLOBJ, WEB_WEAVEBLISS } from '../../../../../config/constants'
import { Leftmenu, Rdefault, Rmsg, Rart, Rsetting, Rgallery, Purchase } from './components'
import { Link } from 'react-router-dom'
import axios from 'axios'
import './Guestbookedit.css'

const dataURLToBlob = function (dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
        var parts1 = dataURL.split(',');
        var contentType1 = parts1[0].split(':')[1];
        var raw1 = parts1[1];
        return new Blob([raw1], { type: contentType1 });
    }

    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
}

const NOTI_CONSTATNT = 3000

// WEB AND TABLET
const rootStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'fixed'
}

function Guestbookedit(props) {

    // LOADING, DEFAULT, MSG, TEMPLATE, GALLERY, DESIGN
    const [cstate, setCstate] = useState('LOADING')

    // leftstate : 0

    // DEFAULT - ALL

    //      |- 0 -> ALL
    // MSG -|
    //      |- 1 -> STATUS

    //           |- 0 -> FIRSTTEMPLATE
    // template -|
    //           |- 1 -> SECONDTEMPLATE

    //          |- 0 -> 
    // setting -|
    //          |- 1 ->

    const [gtype, setGtype] = useState(null) // eslint-disable-line

    const [leftstate, setLeftstate] = useState(0)

    // msg objs
    const [msgs, setMsgs] = useState([])

    const [galleryimgs, setGalleryimgs] = useState([])
    const [galleryquota, setGalleryquota] = useState(0)
    const [error, setError] = useState('')
    const [status, setStatus] = useState('')

    // passcode for guest
    const [passcode, setPasscode] = useState('')

    const [id, setId] = useState('')

    const [urlhandler, setUrlhandler] = useState('')

    const [artmainimg, setArtmainimg] = useState('')

    const [artmaintxt, setArtmaintxt] = useState('')

    const [artmeta, setArtmeta] = useState(-1)

    const [purchase, setPurchase] = useState({}) // { EMOJI1 : {...}, CIRCULAR1: {...}, }

    // passcode for mod
    const [passcodemod, setPasscodemod] = useState('')

    // for notification
    const [notimsg, setNotimsg] = useState('')

    // cast type
    const [casttype, setCasttype] = useState(-1)

    // msg type
    const [msgtype, setMsgtype] = useState(-1)

    // all images in msg
    const [msgimgs, setMsgimgs] = useState([])

    // all audios in msg
    const [msgaudios, setMsgaudios] = useState([])

    // msg quota
    const [msgquota, setMsgquota] = useState(null)

    // change guest passcode cfm 
    const [gpccfm_guest, setGpccfm_guest] = useState(0)
    const [gpctemp_guest, setGpctemp_guest] = useState('')

    // change mod passcode cfm 
    const [gpccfm_mod, setGpccfm_mod] = useState(0)
    const [gpctemp_mod, setGpctemp_mod] = useState('')

    const [rmv, setRmv] = useState(false)
    const [rmvid, setRmvid] = useState('')

    const [arttype, setArttype] = useState(0)

    // for purchase
    const [purchaseitem, setPurchaseitem] = useState('')
    const [openpurchase, setOpenpurchase] = useState(false)

    // var to return to planning home
    const [returnhome, setReturnhome] = useState(false)

    const { token } = Retrieve_personal_info()
    const device = Checkdevice()
    const ht = ScreenHeight()

    useEffect(() => {

        props.hidetopbar(true)
        if (cstate === 'LOADING') {
            try {
                var id = props.match.params.id
                let options = {
                    method: 'POST',
                    url: READ_GBEDIT,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { id }
                }
                axios(options).then((res) => {
                    const { status, data } = res
                    const { gb } = data
                    const { msgs, galleryimgs, passcode, artmainimg, artmaintxt, _id, urlhandler, passcodemod, msgtype, casttype, msgimgs, msgaudios, msgquota, galleryquota, gtype, arttype, artmeta, purchase } = gb
                    if (status === 200) {
                        setMsgs(msgs)
                        setGalleryimgs(galleryimgs)
                        setPasscode(passcode)
                        setCstate('DEFAULT')
                        setId(_id)
                        setUrlhandler(urlhandler)
                        setArtmainimg(artmainimg && artmainimg.length > 0 ? artmainimg : WEB_WEAVEBLISS)
                        setArtmaintxt(artmaintxt)
                        setPasscodemod(passcodemod)
                        setPurchase(purchase)
                        setCasttype(casttype)
                        setMsgtype(msgtype)
                        setMsgimgs(msgimgs)
                        setMsgaudios(msgaudios)
                        setMsgquota(msgquota)
                        setGtype(gtype)
                        setGalleryquota(galleryquota)
                        setArttype(arttype)
                        setArtmeta(artmeta)
                    }
                    else {
                        props.history.push('/')
                    }
                }).catch((e) => {
                    props.history.push('/')
                })
            }
            catch (e) {
                props.history.push('/')
            }
        }
        return () => props.hidetopbar(false)
    }, [])

    // const Nomobile = () => {
    //     return <div style={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '100vh', placeContent: 'center', alignItems: 'center' }}>
    //         <div style={{ color: '#707070' }}>We do not support mobile editing.</div>
    //         <button style={{ ...BUTTONSTYLE, margin: 20 }} onClick={() => { window.history.back(); }}>Back</button>
    //     </div>
    // }

    const ondel = (e) => { // for rightworkdesk single msg deletion
        // if any on going transaction is pending, do not allow delete of msg
        var purchase_keys = Object.keys(purchase)
        var okay = true
        if (purchase_keys && purchase_keys.length > 0) {
            // check obj
            for (let i = 0; i < purchase_keys.length; i++) {
                const { status } = purchase[purchase_keys[i]]
                if (status === 'PENDING') {
                    alert('Unable to delete, you have transaction pending.')
                    okay = false
                }
            }
        }
        if (okay) {
            setRmvid(e.target.value)
            setRmv(true)
        }

    }

    const okdel = () => {
        const value = rmvid
        var imgtodel = ''
        for (let i = 0; i < msgs.length; i++) {
            if (value === msgs[i].id) {
                imgtodel = msgs[i].img
            }
        }

        var audiotodel = ''
        for (let i = 0; i < msgs.length; i++) {
            if (value === msgs[i].id) {
                audiotodel = msgs[i].audio
            }
        }

        try {
            let options = {
                method: 'POST',
                url: RMV_GBEDITMSG,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { id: value, urlhandler: urlhandler, img: imgtodel.length > 0 ? imgtodel : '', audio: audiotodel.length > 0 ? audiotodel : '', purchase }
            }
            axios(options).then((res) => {
                const { status } = res
                if (status === 200) {
                    var new_msgs = msgs.slice()
                    for (let i = 0; i < msgs.length; i++) {
                        const { id } = msgs[i]
                        if (id === value) {
                            new_msgs.splice(i, 1)
                            break
                        }
                    }
                    setMsgs(new_msgs)
                    setNotimsg('Successfully deleted')
                    setTimeout(() => { setNotimsg('') }, NOTI_CONSTATNT)
                }
                else {
                }
            }).catch((e) => {
            })
        }
        catch (e) {
        }
    }
    const rmvmodal = rmv
        ? <Modcfm
            onHide={() => { setRmv(false) }}
            onOk={() => { okdel(); setRmv(false) }}
            onNo={() => { setRmv(false) }}
            txt={'Once you delete this, there is no going back. Please be certain.'}
        />
        : null


    const updatecasttype = (e) => {

        const { value } = e.target
        try {
            var urlhandler = props.match.params.urlhandler
            let options = {
                method: 'POST',
                url: UPDATE_GBEDITCASTTYPE,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { casttype: parseInt(value, 10), urlhandler: urlhandler }
            }
            axios(options).then((res) => {
                const { status } = res
                if (status === '200' || status === 200) {
                    setCasttype(parseInt(value, 10))
                    setNotimsg('Successfully switch cast type')
                    setTimeout(() => { setNotimsg('') }, NOTI_CONSTATNT)
                }
                else {
                }
            }).catch((e) => {
            })
        }
        catch (e) {
        }
    }

    const updatemsgtype = (e) => {

        const { value } = e.target
        try {
            var urlhandler = props.match.params.urlhandler
            let options = {
                method: 'POST',
                url: UPDATE_GBEDITMSGTYPE,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { msgtype: parseInt(value, 10), urlhandler: urlhandler }
            }
            axios(options).then((res) => {
                const { status } = res
                if (status === '200' || status === 200) {
                    setMsgtype(parseInt(value, 10))
                    setNotimsg('Successfully switch message type')
                    setTimeout(() => { setNotimsg('') }, NOTI_CONSTATNT)
                }
                else {
                }
            }).catch((e) => {
            })
        }
        catch (e) {
        }

    }

    const updatec = (e) => {
        window.scrollTo(0, 0);
        if (cstate === e.target.value || e.target.value === undefined || e.target.value === null) {
            setCstate('DEFAULT')
        }
        else {
            setCstate(e.target.value)
        }
    }

    const backc = () => {
        setCstate('DEFAULT');
        setLeftstate(0);
    }

    const updateleftstate = (e) => {
        setLeftstate(parseInt(e.target.value, 10))
    }

    const togglegpccfm_guest = (newpasscode) => {
        setGpctemp_guest(newpasscode)
        setGpccfm_guest(1)
    }

    const togglegpccfm_mod = (newpasscode) => {
        setGpctemp_mod(newpasscode)
        setGpccfm_mod(1)
    }

    const changeguestpc = () => {
        try {
            var urlhandler = props.match.params.urlhandler
            let options = {
                method: 'POST',
                url: UPDATE_GBEDITCASTPC,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { urlhandler, newpasscode: gpctemp_guest, oldpasscode: passcode }
            }
            axios(options).then((res) => {
                const { status } = res
                if (status === 200) {
                    const curr_gpctemp_guest = gpctemp_guest
                    setPasscode(curr_gpctemp_guest)
                    setGpctemp_guest('')
                    setGpccfm_guest(0)
                    setNotimsg('Successfully change.')
                    setTimeout(() => { setNotimsg('') }, NOTI_CONSTATNT)
                }
                else { }
            }).catch((e) => { })
        }
        catch (e) { }
    }

    const changemodpc = () => {
        try {
            var urlhandler = props.match.params.urlhandler
            let options = {
                method: 'POST',
                url: UPDATE_GBEDITMODPC,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { urlhandler, newmodpasscode: gpctemp_mod, oldmodpasscode: passcodemod }
            }
            axios(options).then((res) => {
                const { status } = res
                if (status === 200) {
                    const curr_gpctemp_mod = gpctemp_mod
                    setPasscodemod(curr_gpctemp_mod)
                    setGpctemp_mod('')
                    setGpccfm_mod(0)
                    setNotimsg('Successfully change.')
                    setTimeout(() => { setNotimsg('') }, NOTI_CONSTATNT)
                }
                else { }
            }).catch((e) => { })
        }
        catch (e) { }
    }

    const updategallery = (e) => {
        console.log(galleryimgs.length)
        console.log('galleryquota', galleryquota)
        const { files } = e.target
        if (galleryimgs.length < galleryquota) {
            var imgraw = []
            let names = []
            let imgtypes = []
            var totalsize = e.target.files.length
            for (let i = 0; i < files.length; i++) {
                var reader = new FileReader()
                let file = files[i]
                reader.onload = (e) => {
                    var img = document.createElement("img");
                    img.src = URL.createObjectURL(file);
                    img.onload = () => {
                        if (file.size < 20000000) { // 2MB : 2097152, 20MB : 20000000
                            names.push(file.name)
                            imgtypes.push(file.type)
                            imgraw.push(img.src)
                            if (totalsize === imgraw.length) update_db() // once all imgs loaded update db
                        }
                        else {
                            setError(`Image size must be less than 20MB`);
                            setTimeout(() => {
                                setError('')
                            }, 3000)
                        }
                    }
                    img.src = e.target.result;
                }
                reader.readAsDataURL(file);
            }

            function update_db() {
                const addimg = (n, previmgs) => {
                    if (n === 0) {
                        setStatus('Done Uploading')
                        setTimeout(() => {
                            setGalleryimgs([...galleryimgs, ...previmgs])
                            setStatus('')
                        }, 1000)
                        return true
                    }
                    else {
                        var data = new FormData()
                        var index = (imgraw.length - 1) - (n - 1)
                        var curr_img = imgraw[index]

                        var newimg = new File( // reconstruct image
                            [dataURLToBlob(curr_img)],
                            urlhandler + '_' + passcode + '.' + imgtypes[index].split('/')[1],
                            { type: imgtypes[index] }
                        )
                        data.append('img', newimg)
                        data.append('urlhandler', urlhandler)
                        data.append('id', id)
                        axios({
                            method: 'POST',
                            url: UPDATE_GBEDITGALLERYIMG,
                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'multipart/form-data' }, // need token from the web
                            data: data
                        }).then((item) => {
                            const { message, url } = item.data
                            if (message === 'SUCCESS') {
                                setStatus('Uploading ' + names[index])
                                if (previmgs.length > 0) setGalleryimgs([...galleryimgs, ...previmgs]) // if gt prev image add them
                                setTimeout(() => {
                                    addimg(n - 1, [...previmgs, url])
                                }, [1000])
                            }
                        }).catch((e) => { })
                    }
                }
                addimg(parseInt(imgraw.length, 10), [])
            }
        }
        else {
            alert('You have reach max quota')
        }
        e.target.value = null;
    }

    const rmvgalleryimg = (imgurl) => {
        let options = {
            method: 'POST',
            url: RMV_GBEDITGALLERYIMG,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
            data: { id: id, urlhandler: urlhandler, imgurl: imgurl }
        }
        axios(options).then((res) => {
            const { status } = res
            if (status === 200) {
                const local_galleryimgs = galleryimgs.slice()
                const index = local_galleryimgs.indexOf(imgurl)
                local_galleryimgs.splice(index, 1)
                setGalleryimgs(local_galleryimgs)
                setNotimsg('Successfully deleted')
                setTimeout(() => { setNotimsg('') }, NOTI_CONSTATNT)
            }
            else {
            }
        }).catch((e) => { })
    }

    const onApply = (purchasetype, info, name, email, contact) => {
        const producttype = purchaseitem + '_' + purchasetype // 'EMOJI1_SOFT', 'EMOJI1_HARDSOFT', 'WORDCUBE1_SOFT', 'WORDCUBE1_HARDSOFT', 'ALPHA1_SOFT', 'ALPHA1_HARDSOFT'
        console.log('producttype', producttype)
        axios({
            method: 'POST',
            url: PURCHASE_MM,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, // need token from the web
            data: { urlhandler, producttype, purchase, info, name, email, contact }
        }).then((item) => {
            const { message, new_purchase } = item.data
            if (message === 'SUCCESS') {
                setPurchase(new_purchase)
                setOpenpurchase(false)
                setPurchaseitem('')
                alert('success')
            }
            else {
                alert('failure')

            }
        })
    }

    const oncfm = (data, purchasetype) => {
        const producttype = purchaseitem + '_' + purchasetype
        axios({
            method: 'POST',
            url: data['purchasetype'] === 'HARDSOFT' ? PURCHASE_GBSH : PURCHASE_GBS,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, // need token from the web
            data: { urlhandler, data, producttype, purchase }
        }).then((item) => {
            const { message, new_purchase } = item.data
            if (message === 'SUCCESS') {
                setPurchase(new_purchase)
                setOpenpurchase(false);
                setPurchaseitem('')
                alert('success')
            }
            else {
                alert('failure')

            }
        })
    }

    const lookup_click = () => {
        var elem = document.getElementById('gbedit_lookupbtn')
        elem.click()
    }

    const topmenu = <div id="planning_topbar" className="planning_topbar">
        <button className="planning_backicon" onClick={() => { setReturnhome(true) }}>{ARROWLEFT_ICON}</button>
        {/* 
        <Link id="gbedit_lookupbtn" style={{ position: 'absolute', width: 0, height: 0 }} to={`/guestbooklookup/${urlhandler}/${passcodemod}`} target="_blank"></Link>
        <button className="iepreview_btn" onClick={lookup_click}>{LOOKUPUNSTYLE_ICON}Preview</button>
         */}
    </div>

    const defaultdom = <Rdefault passcode={passcode} urlhandler={urlhandler} device={device} passcodemod={passcodemod} id={id} />
    const msgdom = <Rmsg device={device} leftstate={leftstate} msgs={msgs} ondel={ondel} msgimgs={msgimgs} msgaudios={msgaudios} msgquota={msgquota} purchase={purchase} />
    const gallerydom = <Rgallery device={device} galleryquota={galleryquota} updategallery={updategallery} rmvgalleryimg={rmvgalleryimg} galleryimgs={galleryimgs} />
    const settingdom = <Rsetting device={device} passcode={passcode} passcodemod={passcodemod} togglegpccfm_mod={togglegpccfm_mod} togglegpccfm_guest={togglegpccfm_guest} msgtype={msgtype} casttype={casttype} updatemsgtype={updatemsgtype} updatecasttype={updatecasttype} />
    const artdom = <Rart msgs={msgs} device={device} purchase={purchase} urlhandler={urlhandler} artmainimg={artmainimg} artmaintxt={artmaintxt} arttype={arttype} updateartmainimg={(img) => setArtmainimg(img)} updateartmaintxt={(t) => setArtmaintxt(t)} artmeta={artmeta} updateartmeta={(as) => { setArtmeta(as) }} openpurchase={(item) => { setPurchaseitem(item); setOpenpurchase(true) }} updatearttype={(val) => { setArttype(val) }} />
    const purchasemodal = <Purchase openpurchase={openpurchase} close={() => { setOpenpurchase(false); setPurchaseitem('') }} onConfirm={oncfm} onApply={onApply} purchase={purchase} purchaseitem={purchaseitem} />

    const Gb_w_t = <div style={{ ...rootStyle, height: ht, }}>
        {topmenu}
        <div className="gbedit_wtroot">
            <Leftmenu
                cstate={cstate}
                updatec={updatec}
                back={backc}
                leftstate={leftstate}
                updateleft={updateleftstate}
                device={device}
            />
            <div className={"gbedit_comp_" + device}>
                {{
                    'DEFAULT': defaultdom,
                    'MSG': msgdom,
                    'SETTING': settingdom,
                    'DESIGN': artdom,
                    'GALLERY': gallerydom
                }[cstate]}
            </div>
        </div>
        <Noti noti={status} />
        <Noti noti={error} />
        <Noti noti={notimsg} />
        {gpccfm_guest === 1 ? <Modcfm
            key="guestmodcfm"
            onHide={() => { setGpccfm_guest(0) }}
            onOk={changeguestpc}
            onNo={() => { setGpccfm_guest(0) }}
            txt={'Once passcode change, previously generated qr-code will no longer accessible. You will need to generate new qr code in order to access this link again. Please be certain.'}
        /> : null}
        {gpccfm_mod === 1 ? <Modcfm
            key="modmodcfm"
            onHide={() => { setGpccfm_mod(0) }}
            onOk={changemodpc}
            onNo={() => { setGpccfm_mod(0) }}
            txt={'Please inform your moderator of the password change.'}
        /> : null}
        {rmvmodal}
        {openpurchase
            ? purchasemodal
            : null}
        {returnhome ? <Modcfm
            onHide={() => { setReturnhome(false) }}
            onOk={() => { props.history.push(PLOBJ['guestbook']['toolurl']) }}
            onNo={() => { setReturnhome(false) }}
            txt={'You are returning to planning tools menu.'}
        /> : null}
    </div>

    const Gb_mobile = (
        <div className="gbedit_mroot">
            {topmenu}
            <Leftmenu
                back={() => { setCstate('DEFAULT'); setLeftstate(0); }}
                device={device}
                cstate={cstate}
                updatec={updatec}
                leftstate={leftstate}
                updateleft={updateleftstate}
            />
            <div className={"gbedit_comp_MOBILE"}>
                {{
                    'DEFAULT': defaultdom,
                    'MSG': msgdom,
                    'SETTING': settingdom,
                    'DESIGN': artdom,
                    'GALLERY': gallerydom
                }[cstate]}
            </div>
            <Noti noti={status} />
            <Noti noti={error} />
            <Noti noti={notimsg} />
            {rmvmodal}
            {returnhome ? <Modcfm
                onHide={() => { setReturnhome(false) }}
                onOk={() => { props.history.push(PLOBJ['guestbook']['toolurl']) }}
                onNo={() => { setReturnhome(false) }}
                txt={'You are returning to planning tools menu.'}
            /> : null}
        </div>
    )

    if (cstate === 'LOADING') { return <Loading /> }
    else { return device === 'MOBILE' ? Gb_mobile : Gb_w_t }
}

export default Guestbookedit