function Preloadimages(urls, allImagesLoadedCallback) {
    var loadedCounter = 0;
    urls.forEach(function (url) {
        preloadImage(url, () => {
            loadedCounter++;
            if (loadedCounter === urls.length) allImagesLoadedCallback();
        });
    });
    function preloadImage(url, anImageLoadedCallback) {
        var img = new Image();
        img.onload = anImageLoadedCallback;
        img.src = url;
    }
}

export default Preloadimages