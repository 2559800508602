import React, { Component, useEffect, useState } from 'react'
import { Pricelistcard } from '../../../../components';
import { EMPTYSPACE } from '../../../../config/constants';
import { subtitle } from '../Commonfunction';
import { iconwrapper } from '../../../../config/commonfunctions';
import { COLLAPSE_ICON, UNCOLLAPSE_ICON } from '../../../../config/svgicons';
import './Renderpricelists.css'

const root = { padding: '30px 0px 40px' }

const emptydom = <div style={{ height: 200, borderRadius: 5, color: 'var(--subgrey)', display: 'flex', placeContent: 'center', fontSize: 24 }} >
</div>

const pricelisttitle = 'Price list and packages offered by the vendor.'

function retrieve_filter(allpricelist_ids) {
    let unique_local_filters = new Set();
    let unique_local_filters_map = {}
    unique_local_filters.add('All');
    for (let i = 0; i < allpricelist_ids.length; i++) {
        const { eventorwed, curr_vendortype, vendortype, secondaryvendortype } = allpricelist_ids[i]
        const curr_vendortype_ = curr_vendortype === 'Both'
            ? vendortype + ' & ' + secondaryvendortype
            : curr_vendortype.length > 1 ? curr_vendortype[0] + ', ' + curr_vendortype[1] : curr_vendortype[0]
        unique_local_filters.add(eventorwed + ', ' + curr_vendortype_)


        const curr_vendortype_for_map = curr_vendortype === 'Both' ? vendortype + ' & ' + secondaryvendortype : curr_vendortype
        unique_local_filters_map[eventorwed + ', ' + curr_vendortype_] = eventorwed + ', ' + curr_vendortype_for_map
    }
    unique_local_filters = [...unique_local_filters]
    return { unique_local_filters: unique_local_filters, unique_local_filters_map: unique_local_filters_map };
}

function compile_pricelist(ids, temp_selected_filter, unique_local_filters_map) {
    const selected_filter = unique_local_filters_map[temp_selected_filter]
    if (temp_selected_filter === 'All') return ids
    const result = []
    for (let i = 0; i < ids.length; i++) {
        var id = ids[i]
        const { eventorwed, curr_vendortype, vendortype, secondaryvendortype } = id
        const curr_vendortype_ = curr_vendortype === 'Both' ? vendortype + ' & ' + secondaryvendortype : curr_vendortype
        if (eventorwed + ', ' + curr_vendortype_ === selected_filter) {
            result.push(id)
        }
    }
    return result
}

// class Renderpricelists extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             dropdownselected: 'All',
//             expand: true,
//         }
//     }

//     showall = () => {
//         this.setState({ expand: true })
//     }
//     hideall = () => {
//         this.setState({ expand: false })
//     }

//     render() {
//         const { dropdownselected } = this.state
//         const {
//             vti_id,
//             allpricelist_ids = [], vendortype, secondaryvendortype,
//             loggeduserdata
//         } = this.props

//         if (allpricelist_ids.length === 0) {
//             return (
//                 <div style={root}>
//                     {subtitle('Pricelist', pricelisttitle)}
//                     <div style={{ margin: '0px 10px' }}>
//                         {emptydom}
//                     </div>
//                 </div>
//             )
//         }
//         else {
//             const { unique_local_filters, unique_local_filters_map } = retrieve_filter(allpricelist_ids)
//             return (
//                 <div style={root}>
//                     {subtitle('Pricelist', pricelisttitle)}
//                     {EMPTYSPACE}
//                     <div style={{ margin: '0px 10px' }}>
//                         <div className='Renderpricelists_tagroot'>
//                             {unique_local_filters.map((val, i) => {
//                                 return [
//                                     <div
//                                         key={'filter_' + i}
//                                         className={'Renderpricelists_filtertag'}
//                                         style={{
//                                             ... { padding: '2px 7px', fontSize: 12, borderRadius: 5, cursor: 'pointer', letterSpacing: 0.5, whiteSpace: 'nowrap' },
//                                             border: dropdownselected === val ? '1px solid var(--maindark)' : '1px solid transparent',
//                                             backgroundColor: dropdownselected === val ? 'var(--maindark)' : 'white',
//                                             color: dropdownselected === val ? 'white' : 'var(--maindark)'
//                                         }}
//                                         onClick={() => { this.setState({ dropdownselected: val }) }}
//                                     >
//                                         {val.toUpperCase()}
//                                     </div>,
//                                     i !== unique_local_filters.length - 1
//                                         ? <div key={'slash' + i} className='Renderpricelists_slash' >/</div>
//                                         : null
//                                 ]
//                             })}
//                         </div>
//                         <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', placeContent: 'flex-end', marginBottom: 10 }}>
//                             <button className='Renderpricelists_expand' onClick={() => { this.state.expand ? this.hideall() : this.showall() }}>
//                                 {this.state.expand
//                                     ? iconwrapper(COLLAPSE_ICON, { width: 20, height: 20, display: 'flex', placeContent: 'center', alignItems: 'center', marginLeft: 10 })
//                                     : iconwrapper(UNCOLLAPSE_ICON, { width: 20, height: 20, display: 'flex', placeContent: 'center', alignItems: 'center', marginLeft: 10 })
//                                 }
//                                 <div className='Renderpricelists_expandname'>{this.state.expand ? 'Hide' : 'Expand'}</div>
//                             </button>
//                         </div>
//                         {compile_pricelist(allpricelist_ids, dropdownselected, unique_local_filters_map)
//                             .map((item, index) => <div key={'rpl' + index} >
//                                 <Pricelistcard
//                                     from='Pricelist'
//                                     pltitle={pricelisttitle}
//                                     item={item}
//                                     vendortype={vendortype}
//                                     vt={vendortype} svt={secondaryvendortype}
//                                     vti_id={vti_id}
//                                     expand={this.state.expand} // default is true
//                                     loggeduserdata={loggeduserdata}
//                                     whatsapp_link={this.props.whatsapp_link}
//                                     telegram_link={this.props.telegram_link}
//                                 />
//                             </div>)}

//                     </div>
//                 </div>
//             )
//         }

//     }
// }



function Renderpricelists(props) {
    const { vti_id,
        allpricelist_ids = [], vendortype, secondaryvendortype,
        loggeduserdata } = props
    const [dropdownselected, setDropdownselected] = useState('All')
    const [expand, setExpand] = useState(null)
    useEffect(() => {
        setExpand(true)
    }, [])
    const showall = () => {
        setExpand(true)
    }
    const hideall = () => {
        setExpand(false)
    }

    if (allpricelist_ids.length === 0) {
        return (
            <div style={root}>
                {subtitle('Pricelist', pricelisttitle)}
                <div style={{ margin: '0px 10px' }}>
                    {emptydom}
                </div>
            </div>
        )
    }
    else {
        const { unique_local_filters, unique_local_filters_map } = retrieve_filter(allpricelist_ids)
        return (
            <div style={root}>
                {subtitle('Pricelist', pricelisttitle)}
                {EMPTYSPACE}
                <div style={{ margin: '0px 10px' }}>
                    <div className='Renderpricelists_tagroot'>
                        {unique_local_filters.map((val, i) => {
                            return [
                                <div
                                    key={'filter_' + i}
                                    className={'Renderpricelists_filtertag'}
                                    style={{
                                        ... { padding: '2px 7px', fontSize: 12, borderRadius: 5, cursor: 'pointer', letterSpacing: 0.5, whiteSpace: 'nowrap' },
                                        border: dropdownselected === val ? '1px solid var(--maindark)' : '1px solid transparent',
                                        backgroundColor: dropdownselected === val ? 'var(--maindark)' : 'white',
                                        color: dropdownselected === val ? 'white' : 'var(--maindark)'
                                    }}
                                    onClick={() => { setDropdownselected(val) }}
                                >
                                    {val.toUpperCase()}
                                </div>,
                                i !== unique_local_filters.length - 1
                                    ? <div key={'slash' + i} className='Renderpricelists_slash' >/</div>
                                    : null
                            ]
                        })}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', placeContent: 'flex-end', marginBottom: 10 }}>
                        <button className='Renderpricelists_expand' onClick={() => { expand ? hideall() : showall() }}>
                            {expand
                                ? iconwrapper(COLLAPSE_ICON, { width: 20, height: 20, display: 'flex', placeContent: 'center', alignItems: 'center', marginLeft: 10 })
                                : iconwrapper(UNCOLLAPSE_ICON, { width: 20, height: 20, display: 'flex', placeContent: 'center', alignItems: 'center', marginLeft: 10 })
                            }
                            <div className='Renderpricelists_expandname'>{expand ? 'Hide' : 'Expand'}</div>
                        </button>
                    </div>
                    {compile_pricelist(allpricelist_ids, dropdownselected, unique_local_filters_map)
                        .map((item, index) => {
                            return <Pricelistcard
                                from='Pricelist'
                                pltitle={pricelisttitle}
                                item={item}
                                vendortype={vendortype}
                                vt={vendortype} svt={secondaryvendortype}
                                vti_id={vti_id}
                                expand={expand} // default is true
                                loggeduserdata={loggeduserdata}
                                whatsapp_link={props.whatsapp_link}
                                telegram_link={props.telegram_link}
                                index={index}
                            />
                        })}

                </div>
            </div>
        )
    }


}
export default Renderpricelists