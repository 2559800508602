// import React, { memo, useRef } from 'react'
// import styled from "styled-components";
// import { useTable, useBlockLayout, useResizeColumns, useRowSelect, useSortBy, } from "react-table";
// // import { useTable, useBlockLayout, useResizeColumns, useSortBy } from "react-table";
// // import useRowSelect from './useRowSelect'
// import { useSticky } from "react-table-sticky";
// import { useEffect } from 'react';
// import { Checkdevice } from '../../../../../../components';
// import { Retrieve_personal_info } from '../../../../../../config/commonfunctions';
// import { INVITATION_UPDATETABLCOLWIDTH } from '../../../../../../config/api';
// import TableBody from "../Table/TableBody";
// import TableHead from "../Table/TableHead";
// import './Table.css'
// import axios from 'axios'
// import { useState } from 'react';

// const Styles = styled.div`

//   .table {
//     border: 1px solid #ddd;

//     .trtablehead{
//       background-color: #fff !important;
//     }

//     .tr {
//       background-color: #fff !important;
//       :last-child {
//         .td {
//           border-bottom: 1px solid #ddd;
//         }
//       }
//       :hover {
//         background-color: #fff6f6 !important;
//       }
//     }

//     .th,
//     .td {
//       padding: 5px;
//       border-bottom: 1px solid #ddd;
//       border-right: 1px solid #ddd;
//       background-color:inherit;
//       overflow: hidden;

//       :last-child {
//         border-right: 0;
//       }

//       .resizer {
//         display: inline-block;
//         width: 15px;
//         height: 100%;
//         position: absolute;
//         right: 0;
//         top: 0;
//         transform: translateX(50%);
//         z-index: 1;
//         user-select:none;
//         background-color:var(--hoverpink);
//         border-top-left-radius:100%;
//         border-bottom-left-radius:100%;

//         &.isResizing {
//           background-color:var(--mainpink);
//         }
//       }
//       .resizer:hover{
//         background-color:var(--mainpink);
//       }
//     }

//     &.sticky {
//       overflow: hidden;
//       .header,
//       .footer {
//         position: sticky;
//         z-index: 1;
//         width: fit-content;
//       }

//       .header {
//         top: 0;
//         box-shadow: 2px 0px 0px #000000;
//       }

//       .body {
//         position: relative;
//         z-index: 0;
//       }

//       [data-sticky-td] {
//         position: sticky;
//       }

//       [data-sticky-last-left-td] {
//         box-shadow: 2px 0px 0px #000000;
//       }

//       [data-sticky-first-right-td] {
//         box-shadow: -2px 0px 0px #000000;
//       }
//     }
//   }
// `


// // const IndeterminateCheckbox = React.forwardRef(
// //   ({ indeterminate, ...rest }, ref) => {
// //     const defaultRef = React.useRef()
// //     const resolvedRef = ref || defaultRef
// //     React.useEffect(() => {
// //       resolvedRef.current.indeterminate = indeterminate
// //     }, [resolvedRef, indeterminate])
// //     return (
// //       <>
// //         <input type="checkbox" ref={resolvedRef} {...rest} />
// //       </>
// //     )
// //   }
// // )

// const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
//   const defaultRef = React.useRef()
//   const resolvedRef = ref || defaultRef

//   React.useCallback(() => {
//     resolvedRef.current.indeterminate = indeterminate
//   }, [resolvedRef, indeterminate])

//   return (
//     <div className='Table_checkboxroot'>
//       <input type="checkbox" ref={resolvedRef} {...rest} />
//     </div>
//   )
// }
// )

// function Table(props) {
//   const { updateMaincb, updateSinglecb, columns, data, guestqnssettingsmap, invitationid, urlhandler, updateSettings, rowClick } = props
//   const { token } = Retrieve_personal_info()

//   // const defaultColumn = React.useMemo(() => ({maxWidth: 400}), []);
//   const [firsttime, setFirsttime] = useState(false)
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     rows,
//     prepareRow,
//     state
//   } = useTable(
//     {
//       autoResetPage: false,
//       columns,
//       data,
//       defaultColumn: { maxWidth: 400 },
//     },
//     useSortBy,
//     useRowSelect,
//     useBlockLayout,
//     useResizeColumns,
//     useSticky,
//     hooks => {
//       hooks.visibleColumns.push(columns => [
//         {
//           id: 'selection',
//           Header: ({ getToggleAllRowsSelectedProps }) => {
//             const { checked } = getToggleAllRowsSelectedProps()
//             return <IndeterminateCheckbox
//               {...getToggleAllRowsSelectedProps()}
//               onClick={() => updateMaincb(checked)}
//             />
//           },
//           Cell: ({ row }) => {
//             return <IndeterminateCheckbox
//               {...row.getToggleRowSelectedProps()}
//               onClick={() => updateSinglecb(row.index, row.isSelected)}
//             />
//           },
//           sticky: 'left',
//           width: 40,
//         },
//         ...columns
//       ])
//     }
//   );

//   const device = Checkdevice()
//   const isColumnResizing = !!state.columnResizing.isResizingColumn;
//   const headerref = useRef(null)
//   const bodyref = useRef(null)

//   const handleScrollSecond = scroll => {
//     headerref.current.scrollLeft = scroll.target.scrollLeft;
//     headerref.current.scrollRight = scroll.target.scrollRight;
//   }


//   useEffect(() => {
//     if (!isColumnResizing && !firsttime) {

//       const { headers } = headerGroups[0]

//       let setting_map = guestqnssettingsmap

//       for (let i = 0; i < headers.length; i++) {
//         const { id, width } = headers[i]
//         setting_map[id] = { width: width }
//       }

//       try {
//         let options = {
//           method: 'POST',
//           url: INVITATION_UPDATETABLCOLWIDTH,
//           headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
//           data: { guestqnssettingsmap: setting_map, urlhandler: urlhandler, invitationid: invitationid }
//         }
//         axios(options).then(() => { 
//           updateSettings(setting_map)

//          })
//       }
//       catch (e) { console.log('e', e) }

//     }
//   }, [isColumnResizing])


//   return (
//     <Styles>
//       <div {...getTableProps()} className="table sticky" style={{ position: 'relative' }}>
//         <div key={'gtref'} ref={headerref} style={{ zIndex: 3, width: 'calc( 100vw - 2px )', position: 'sticky', overflowX: 'hidden', overflowY: 'scroll' }} >
//           <TableHead
//             headerGroups={headerGroups}
//             getTableProps={getTableProps}
//           />
//         </div>
//         <div key={'gtref1'} ref={bodyref} onScroll={handleScrollSecond} style={{ height: `calc( 100vh - ${device === 'MOBILE' ? 300 : 200}px )`, width: '100%', overflow: 'scroll' }} >
//           <TableBody
//             rows={rows}
//             getTableBodyProps={getTableBodyProps}
//             prepareRow={prepareRow}
//             isColumnResizing={isColumnResizing}
//             rowClick={rowClick}
//           />
//         </div>
//       </div>
//     </Styles>
//   );


// }

// export default memo(Table)

import React, { memo, useRef, useState } from 'react'
import { useTable, useBlockLayout, useResizeColumns, useRowSelect, useSortBy, } from "react-table";
import { useSticky } from "react-table-sticky";
import { useEffect } from 'react';
import { Checkdevice } from '../../../../../../components';
import { Retrieve_personal_info } from '../../../../../../config/commonfunctions';
import { INVITATION_UPDATETABLCOLWIDTH } from '../../../../../../config/api';
import TableBody from "../Table/TableBody";
import TableHead from "../Table/TableHead";
import styled from "styled-components";
import axios from 'axios'
import './Table.css'
import { isMobile, isTablet } from 'react-device-detect';

const Styles = styled.div`
  .table {
    border: 1px solid #ddd;
    
    .trtablehead{
      background-color: #fff !important;
    }

    .tr {
      background-color: #fff !important;
      :last-child {
        .td {
          border-bottom: 1px solid #ddd;
        }
      }
      :hover {
        background-color: #fff6f6 !important;
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color:inherit;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 20px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        user-select:none;
        background-color:var(--hoverpink);
        border-top-left-radius:100%;
        border-bottom-left-radius:100%;

        &.isResizing {
          background-color:var(--mainpink);
        }
      }
      .resizer:hover{
        background-color:var(--mainpink);
      }
    }

    &.sticky {
      overflow: hidden;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 2px 0px 0px #000000;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 0px #000000;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 0px #000000;
      }
    }
  }
`

// const IndeterminateCheckbox = React.forwardRef(
//   ({ indeterminate, ...rest }, ref) => {
//     const defaultRef = React.useRef()
//     const resolvedRef = ref || defaultRef
//     React.useEffect(() => {
//       resolvedRef.current.indeterminate = indeterminate
//     }, [resolvedRef, indeterminate])
//     return (
//       <>
//         <input type="checkbox" ref={resolvedRef} {...rest} />
//       </>
//     )
//   }
// )

// const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {

//   const defaultRef = React.useRef()
//   const resolvedRef = ref || defaultRef

//   React.useCallback(() => {
//     resolvedRef.current.indeterminate = indeterminate
//   }, [resolvedRef, indeterminate])

//   return <div className='Table_checkboxroot'>
//     <input className='Table_checkbox' type="checkbox" ref={resolvedRef} {...rest} />
//   </div>

// })

function Table(props) {
  const { columns, data, guestqnssettingsmap, invitationid, urlhandler, updateSettings, rowClick, updatePosition } = props
  const { token } = Retrieve_personal_info()
  const [tb, setTb] = useState(null)
  // const defaultColumn = React.useMemo(() => ({maxWidth: 400}), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { maxWidth: 5000 },
    },
    useBlockLayout,
    useResizeColumns,
    useSortBy,
    useRowSelect,
    useSticky,

    // hooks => {
    //   hooks.visibleColumns.push((columns) => [
    //     {
    //       id: 'selection',
    //       Header: ({ getToggleAllRowsSelectedProps }) => {
    //         const { checked } = getToggleAllRowsSelectedProps()
    //         return <IndeterminateCheckbox
    //           {...getToggleAllRowsSelectedProps()}
    //           // key={checked + '_' + colindex + '_top'}
    //           onClick={() => updateMaincb(checked)}
    //         />
    //       },
    //       Cell: ({ row }) => {
    //         const { index, isSelected } = row
    //         // console.log(row.getToggleRowSelectedProps())
    //         return <IndeterminateCheckbox
    //           {...row.getToggleRowSelectedProps()}
    //           // key={isSelected + '_' + index + '_rest' + test}
    //           onClick={() => updateSinglecb(index, isSelected)}
    //         />
    //       },
    //       sticky: 'left',
    //       width: 40,
    //     },
    //     ...columns
    //   ])
    // }
  );

  const device = Checkdevice()
  const isColumnResizing = !!state.columnResizing.isResizingColumn;
  const headerref = useRef(null)
  const bodyref = useRef(null)

  const handleScrollSecond = scroll => {
    headerref.current.scrollLeft = scroll.target.scrollLeft;
    headerref.current.scrollRight = scroll.target.scrollRight;
    if (isMobile || isTablet) {
      const { scrollTop, scrollLeft } = scroll.target
      updatePosition({ x: scrollLeft, y: scrollTop })
    }
  }

  const [firsttime, setFirsttime] = useState(true)

  useEffect(() => {

    if (firsttime) { setFirsttime(false) }

    if (!firsttime && !isColumnResizing) {

      const { headers } = headerGroups[0]

      let setting_map = guestqnssettingsmap

      for (let i = 0; i < headers.length; i++) {
        const { id, width } = headers[i]
        setting_map[id] = { width: width }
      }

      try {
        let options = {
          method: 'POST',
          url: INVITATION_UPDATETABLCOLWIDTH,
          headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
          data: { guestqnssettingsmap: setting_map, urlhandler: urlhandler, invitationid: invitationid }
        }
        axios(options).then(() => { updateSettings(setting_map) })
      }
      catch (e) { console.log('e', e) }

    }

  }, [isColumnResizing])

  useEffect(() => {
    setTb(
      <TableBody
        rowClick={rowClick}
        rows={rows}
        isColumnResizing={isColumnResizing}
        getTableBodyProps={getTableBodyProps}
        prepareRow={prepareRow}
      />
    )
  }, [rows, rowClick, isColumnResizing, columns])

  useEffect(()=>{
    props.updateRendercounter()
  },[tb])

  // useEffect(() => {
  //   console.log('rows')
  //   setTb(
  //     <TableBody
  //       rowClick={rowClick}
  //       rows={rows}
  //       isColumnResizing={isColumnResizing}
  //       getTableBodyProps={getTableBodyProps}
  //       prepareRow={prepareRow}
  //     />
  //   )
  // }, [rows])

  // useEffect(() => {
  //   console.log('rowClick')
  //   setTb(
  //     <TableBody
  //       rowClick={rowClick}
  //       rows={rows}
  //       isColumnResizing={isColumnResizing}
  //       getTableBodyProps={getTableBodyProps}
  //       prepareRow={prepareRow}
  //     />
  //   )
  // }, [rowClick])

  // useEffect(() => {
  //   console.log('isColumnResizing')
  //   setTb(
  //     <TableBody
  //       rowClick={rowClick}
  //       rows={rows}
  //       isColumnResizing={isColumnResizing}
  //       getTableBodyProps={getTableBodyProps}
  //       prepareRow={prepareRow}
  //     />
  //   )
  // }, [isColumnResizing])

  // useEffect(() => {
  //   console.log('columns')
  //   setTb(
  //     <TableBody
  //       rowClick={rowClick}
  //       rows={rows}
  //       isColumnResizing={isColumnResizing}
  //       getTableBodyProps={getTableBodyProps}
  //       prepareRow={prepareRow}
  //     />
  //   )
  // }, [columns])

  // useEffect(() => {
  //   console.log('headerGroups', headerGroups)
  // }, [headerGroups])

  return <Styles>
    <div {...getTableProps()} className="table sticky" style={{ position: 'relative' }}>
      <div className='Table_headwrapper' ref={headerref}>
        <TableHead headerGroups={headerGroups} getTableProps={getTableProps} />
      </div>
      <div className='Table_bodywrapper' ref={bodyref} onScroll={handleScrollSecond} style={{ height: `calc( 100vh - ${device === 'MOBILE' ? 300 : 200}px )`, width: '100%', overflow: 'scroll' }} >
        {tb}
        {/* <TableBody
          rowClick={rowClick}
          rows={rows}
          isColumnResizing={isColumnResizing}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
        />  */}
      </div>
    </div>
  </Styles>



}

export default memo(Table)
