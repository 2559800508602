import React, { useEffect, useState } from 'react'
import { Checkdevice, Loading, Noti, useClosewarning } from '../../components'
import { GB_LOADING_TIME } from "../../config/constants";
import { READ_GBMOD, READ_GBMODNEW, RMV_GBMODMSG, ADD_GBMODHIDEMSG, UPDATE_GBMODCT, UPDATE_GBMODMT } from '../../config/api';
import Gbmessage from '../../components/Gbmessage/Gbmessage';
import Gbmessageshort from '../../components/Gbmessageshort/Gbmessageshort'
import axios from 'axios'
import './Guestbookmod.css'

// 3 minutes * 60 seconds * 1000 milliseconds = 300000ms
// 60mins / 3 = 20 apis/hr 
const API_CALL_DURATION = 3 * 60 * 1000

const midcss = { width: '100vw', height: '100vh', display: 'flex', placeContent: 'center', backgroundColor: 'white', overflow: 'hidden', margin: 10, padding: 10, alignItems: 'center', textAlign: 'center' }

const rmvhidden = (msgs, hidelist) => {
    console.log(hidelist)
    var new_msgs = msgs.slice()

    for (let i = 0; i < hidelist.length; i++) {
        for (let j = 0; j < msgs.length; j++) {
            if (msgs[j].id === hidelist[i]) {
                new_msgs[j] = null
                break;
            }
        }
    }

    var i = 0;
    while (i < new_msgs.length) {
        if (new_msgs[i] === null) {
            new_msgs.splice(i, 1);
        } else {
            ++i;
        }
    }

    return new_msgs
}

const remakeHidelist = (msgs, hidelist) => {
    var new_hidelist = []
    for (let i = 0; i < hidelist.length; i++) {
        for (let j = 0; j < msgs.length; j++) {
            if (msgs[j].id === hidelist[i]) {
                new_hidelist.push(msgs[j])
            }
        }
    }
    return new_hidelist
}

function Guestbookmod(props) {

    const [ps, setPs] = useState('LOADING') // pagestate : LOADING , CONTENT , 
    const [cs, setCs] = useState('LATEST') // contentstate : LATEST, HIDELIST
    const [msgs, setMsgs] = useState([])
    const [moduniqkey, setModuniqkey] = useState('')
    const [hidelist, setHidelist] = useState([]) // hide all the ticked msg locally
    const [blk, setBlk] = useState(false)
    const [casttype, setCasttype] = useState(null)
    const [msgtype, setMsgtype] = useState(null)
    const [notimsg, setNotimsg] = useState('')
    const [Prompt, setDirty, setPristine] = useClosewarning()
    const [signalcounter, setSignalcounter] = useState(0) // eslint-disable-line
    const device = Checkdevice()
    useEffect(() => {
        props.hidetopbar(true)
        if (ps === 'LOADING') {
            const { urlhandler, passcodemod } = props.match.params
            setDirty()
            axios({
                method: 'POST',
                url: READ_GBMOD,
                data: { urlhandler, passcodemod }
            }).then((item) => {
                const { status, data } = item
                const { msgs, moduniqkey, hidelist, msgtype, casttype } = data.gb // hidelist : arr of string
                if (status === 200) {
                    const new_msgs = rmvhidden(msgs, hidelist)
                    const new_hidelist = remakeHidelist(msgs, hidelist)
                    console.log('new_hidelist', new_hidelist)
                    setMsgs(new_msgs)
                    setHidelist(new_hidelist)
                    setModuniqkey(moduniqkey)
                    setCasttype(casttype)
                    setMsgtype(msgtype)
                    setTimeout(() => setPs('CONTENT'), GB_LOADING_TIME);

                    var newInfo = setInterval(() => {
                        setBlk(true)
                        let options = {
                            method: 'POST',
                            url: READ_GBMODNEW,
                            data: { urlhandler, passcodemod, moduniqkey }
                        }
                        axios(options).then((gb) => {
                            const { data } = gb
                            const { msgs, hidelist } = data.gb
                            if (status === 200) {
                                setSignalcounter(0)
                                const new_msgs = rmvhidden(msgs, hidelist)
                                const new_hidelist = remakeHidelist(msgs, hidelist)
                                setMsgs(new_msgs)
                                setHidelist(new_hidelist)
                            }
                            setBlk(false)
                        }).catch((e) => {
                            setBlk(false)
                            setSignalcounter((count) => {
                                if (count === 2) {
                                    setTimeout(() => setPs('ERROR'), GB_LOADING_TIME);
                                    clearInterval(newInfo);
                                }
                                return count + 1
                            })
                        })
                    }, API_CALL_DURATION)

                }
            })
        }
        return () => {
            setPristine()
        }
    }, [])

    const onHide = (e) => {
        if (!blk) {
            const { id } = e.target // id is actually its index
            const { urlhandler, passcodemod } = props.match.params
            axios({
                method: 'POST',
                url: ADD_GBMODHIDEMSG,
                data: { urlhandler, passcodemod, msgid: msgs[id].id, moduniqkey }
            }).then((item) => {
                const { status } = item
                if (status === 200) {
                    var new_msgs = msgs.slice()
                    new_msgs.splice(id, 1)
                    setMsgs(new_msgs)
                    setHidelist([...hidelist, msgs[id]])
                }
                else {

                }
            })
        }
    }

    const onRmv = (e) => {
        if (!blk) {
            const { id } = e.target // id is actually its index
            const { urlhandler, passcodemod } = props.match.params
            console.log('msgs[id]', msgs[id])

            axios({
                method: 'POST',
                url: RMV_GBMODMSG,
                data: { urlhandler, passcodemod, rmvmsg: msgs[id], moduniqkey }
            }).then((item) => {
                const { status } = item
                if (status === 200) {
                    var new_msgs = msgs.slice()
                    new_msgs.splice(id, 1)
                    setMsgs(new_msgs)
                }
                else {

                }
            })
        }
    }

    const updatemsgtype = (e) => {

        const { value } = e.target
        try {
            const { urlhandler, passcodemod } = props.match.params
            let options = {
                method: 'POST',
                url: UPDATE_GBMODMT,
                data: { msgtype: parseInt(value, 10), urlhandler, passcodemod }
            }
            axios(options).then((res) => {
                const { status } = res
                if (status === '200' || status === 200) {
                    setMsgtype(parseInt(value, 10))
                    setNotimsg('Successfully switch message type')
                    setTimeout(() => { setNotimsg('') }, GB_LOADING_TIME)
                }
                else {
                }
            }).catch((e) => {
            })
        }
        catch (e) {
        }

    }


    const updatecasttype = (e) => {
        const { value } = e.target
        try {
            const { urlhandler, passcodemod } = props.match.params
            let options = {
                method: 'POST',
                url: UPDATE_GBMODCT,
                data: { casttype: parseInt(value, 10), urlhandler, passcodemod }
            }
            axios(options).then((res) => {
                const { status } = res
                if (status === '200' || status === 200) {
                    setCasttype(parseInt(value, 10))
                    setNotimsg('Successfully switch message type')
                    setTimeout(() => { setNotimsg('') }, GB_LOADING_TIME)
                }
                else { }
            }).catch((e) => { })
        }
        catch (e) {
        }

    }


    return ps === 'LOADING'
        ? <Loading />
        : <div className="Gbmod_root">
            {ps === 'CONTENT'
                ? <div className="Gbmod_col" style={{ width: device === 'MOBILE' ? '100%' : 400 }} >
                    <div className="Gbmod_topbtnrow">
                        <div className={cs === 'LATEST' ? "Gbmod_topbtnselectedL" : "Gbmod_topbtnL"} onClick={() => setCs('LATEST')}>Latest</div>
                        <div className={cs === 'HIDELIST' ? "Gbmod_topbtnselectedM" : "Gbmod_topbtnM"} onClick={() => setCs('HIDELIST')}>Hide List</div>
                        <div className={cs === 'SETTING' ? "Gbmod_topbtnselectedR" : "Gbmod_topbtnR"} onClick={() => setCs('SETTING')}>Setting</div>
                    </div>
                    {(cs === 'LATEST' && msgs) && msgs.map((msg, i) => {
                        return <div className="Gbmod_msg" key={'msg' + i}>
                            <Gbmessage
                                index={i}
                                msg={msg}
                                selected={false}
                                tickdeldom={
                                    <div className="Gbmod_row">
                                        <div className="Gbmod_tick50" id={i} onClick={onHide}>✓</div>
                                        <div className="Gbmod_del50" id={i} onClick={onRmv}>✘</div>
                                    </div>
                                }
                            />
                        </div>
                    })}
                    {(cs === 'HIDELIST' && hidelist) && hidelist.length > 0
                        ? hidelist.map((msg, i) => {
                            return <div className="Gbmod_msg" key={'hidelistmsg' + i}>
                                <Gbmessageshort index={i} msg={msg} />
                            </div>
                        }) : null}

                    {(cs === 'SETTING') ? <div className="gbrwd_settingroot" style={{ width: 'calc( 100% - 20px)', alignItems: 'center', placeSelf: 'center' }}>
                        <div className={device === 'MOBILE' ? "gbrwd_settinginputsrootm" : "gbrwd_settinginputsroot"}>
                            <div className="gbrwd_settingtitle">Cast Type</div>
                            <button className={casttype === 0 ? "gbrwd_msgtypebtnvalselected" : "gbrwd_msgtypebtnval"} onClick={updatecasttype} value={0}>Media files only.</button>
                            <button className={casttype === 1 ? "gbrwd_msgtypebtnvalselected" : "gbrwd_msgtypebtnval"} onClick={updatecasttype} value={1}>Messages only.</button>
                            <button className={casttype === 2 ? "gbrwd_msgtypebtnvalselected" : "gbrwd_msgtypebtnval"} onClick={updatecasttype} value={2}>Media files and Messages.</button>
                        </div>
                    </div> : null}

                    {(cs === 'SETTING') ? <div className="gbrwd_settingroot" style={{ width: 'calc( 100% - 20px)', alignItems: 'center', placeSelf: 'center' }}>
                        <div className={device === 'MOBILE' ? "gbrwd_settinginputsrootm" : "gbrwd_settinginputsroot"}>
                            <div className="gbrwd_settingtitle">Message Type</div>
                            <button className={msgtype === 0 ? "gbrwd_msgtypebtnvalselected" : "gbrwd_msgtypebtnval"} onClick={updatemsgtype} value={0}>Messages Text only.</button>
                            <button className={msgtype === 1 ? "gbrwd_msgtypebtnvalselected" : "gbrwd_msgtypebtnval"} onClick={updatemsgtype} value={1}>Message Text and Image.</button>
                        </div>
                    </div> : null}
                </div>
                : null}
            {ps === 'ERROR' ? <div style={midcss}>Unable to connect. Please ensure only single Guestbook mod is active everytime.</div> : null}
            {Prompt}
            <Noti noti={notimsg} />
        </div>

}

export default Guestbookmod