import React from 'react'
import './Gbmessage.css'

// msgtype
// 0 : Messages Text only.
// 1 : Message Text and Image.

function Gbmessage({ msg, selected, onselect, tickdeldom = null, style = null, msgtype }) {
    const { key, txt, id, time, name, img, ff } = msg
    return <div key={'gbmsg' + key} id={id} onClick={onselect} className={selected ? "gbcastmsg_singlemsgselected" : "gbcastmsg_singlemsg"} style={{ ...style, fontFamily: ff,  }}>
        <div id={id} onClick={onselect} >{txt}</div>
        {((img && img.length > 0) && (msgtype === 1 || msgtype === 2)) ? <div className="gbcastmsg_mainimg" style={{ backgroundImage: `url(${img})` }} /> : null}
        <span className="gbcast_msgbtm" id={id} onClick={onselect} >
            {/* <div className="gbcast_msgprofileimg" id={id} onClick={onselect}  style={{ backgroundImage: `url(${img})` }} /> */}
            <div className="gbcast_msgright" id={id} onClick={onselect} >
                <div className="gbcast_msgname" id={id} onClick={onselect} >{name}</div>
                <div className={selected ? "gbcast_msgtimeselected" : "gbcast_msgtime"} id={id} onClick={onselect} > {time}</div>
            </div>
        </span>
        {tickdeldom}
    </div>
}

export default Gbmessage