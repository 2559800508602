import React, { useState, useEffect } from 'react'
import { Checkdevice } from '../../../../../../components'
import { CSVReader } from 'react-papaparse'
import { READ_INVGUEST } from '../../../../../../config/api'
import { Retrieve_personal_info } from '../../../../../../config/commonfunctions'
import axios from 'axios'
import './Importcontent.css'
import { describe_dom, partialtitle_dom } from '../../../Invitationmaker/components/Commonelements/Commonelements'
import { EMPTYSPACE } from '../../../../../../config/constants'

function Importcontent(props) {
    const device = Checkdevice()
    const { token } = Retrieve_personal_info()
    const [url, setUrl] = useState('')
    const [glurls, setGlurls] = useState([])
    useEffect(() => {
        axios({
            method: 'POST',
            url: READ_INVGUEST,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
            data: { urlhandler: props.urlhandler }
        }).then((item) => {
            const { data, status } = item
            if (status === 200) {
                setGlurls(["", ...data.item])
            }
            else {
                setGlurls([])
            }
        }).catch((e) => {
            props.history.push('/')
        })
    }, [])
    // return <div className={"Importcontent_root" + device}>
    return <div className="inviteedit_helproot">
        <div className="inviteedit_helptitle">Import Data</div>
        <div className="Importcontent_col">
            {partialtitle_dom('Load Guestlist', {})}
            {describe_dom('Import .csv file from your device. Your file is required to have this your columns.', { padding: 0, color: 'var(--subdark)', fontSize: 12, marginBottom: 0 })}
            <div className="Importcontentt_excelroot">
                <div className="Importcontent_firstrow">
                    <div className="Importcontent_firstrowcell">Name</div>
                    <div className="Importcontent_firstrowcell">Table</div>
                    <div className="Importcontent_firstrowcell">Category</div>
                    <div className="Importcontent_firstrowcell">Relationship</div>
                </div>
                <div className="Importcontent_secrow">
                    <div className="Importcontent_secrowcell">...</div>
                    <div className="Importcontent_secrowcell">...</div>
                    <div className="Importcontent_secrowcell">...</div>
                    <div className="Importcontent_secrowcell" style={{ borderRight: 'thin solid black' }}>...</div>
                </div>
                <div className="Importcontent_thirdrow">
                    <div className="Importcontent_thirdrowcell">...</div>
                    <div className="Importcontent_thirdrowcell">...</div>
                    <div className="Importcontent_thirdrowcell">...</div>
                    <div className="Importcontent_thirdrowcell" style={{ border: 'thin solid black', }}>...</div>
                </div>
            </div>
        </div>
        <aside className="Importcontent_csvreader" id={'csvparent'} >
            <CSVReader cssClass="scedit_csvreader" onDrop={props.ondrop} noDrag >
                <button className="scedit_modalbtn" style={{ width: '100%', marginTop: 0, marginBottom: 0, borderRadius: 5 }} type='button' >Import</button>
            </CSVReader>
        </aside>
        {EMPTYSPACE}
        {partialtitle_dom('RSVP Guestlist', {})}
        {describe_dom('Import guests from your RSVP guest list', { padding: 0, color: 'var(--subdark)', fontSize: 12, marginBottom: 0 })}
        <select className="Importcontent_glroot" style={{ borderRadius: 5, height: 40 }} name="guestlistrsvpn" id="guestlistrsvp" value={url} onChange={(e) => setUrl(e.target.value)}>
            {glurls.map((item, index) => <option key={item.urlhandler + index} value={item.urlhandler}>{item.urlhandler}</option>)}
        </select>

        <button className="scedit_modalbtn" style={{ width: '100%', marginTop: 0, marginBottom: 0, borderRadius: 5 }} onClick={() => props.onclick(url)}>Import</button>
    </div>
}
export default Importcontent