import React, { useState, useEffect } from 'react';
import { EMPTYSPACE, READABLE_VENDORTYPE_MAP, VENDORPROFILE_COLLECTION_VENDORTYPE_EVENT, VENDORPROFILE_COLLECTION_VENDORTYPE_WEDDING, VENDORPROFILE_COLLECTION_TYPE_GUEST, GUESTCATEGORY_KEY, GUESTCATEGORY_DETAIL_MAP, hostjoin, VT_QUIZ_MAP, QUIZ_DOT, MAIN_PINK_COLOR, MENU_EVENT_COLOR, MENU_GUEST_COLOR, eventgate } from '../../../../config/constants'
import { USER_UPDATE_SITE_WEDDING, USER_UPDATE_SITE_EVENT, USER_UPDATE_SITE_GUEST } from '../../../../config/api'
import { BUTTONSTYLE } from '../../../../config/cssconstants'
import { refresh } from '../../../../components';
import axios from 'axios'
import './Userhomecustomise.css'

const VT_BUTTONSTYLE = {
    backgroundColor: 'white',
    outline: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 13,
    width: 'fit-content',
    whiteSpace: 'nowrap',
    color: 'rgb(112, 112, 112)',
    margin: '5px',
    textAlign: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgb(112, 112, 112)',
    borderRadius: 15,
    letterSpacing: 1,
    padding: '8px 19px',
}

const customise_text_map = {
    'Wedding': 'Which wedding vendortypes do you still need?',
    'Event': 'Which event vendortypes do you still need?',
    'Guest': 'Attend a stranger\'s wedding or event.'
}

function initialize_css(selectedvts, collections) {

    var bgcolor = [], border = [], color = []

    // bgcolor
    var selectedbgcolor = MAIN_PINK_COLOR
    var unselectedbgcolor = 'white'

    // border
    var selectedborder = 'thin solid white'
    var unselectedborder = 'thin solid rgb(112, 112, 112)'

    // color
    var selectedcolor = 'white'
    var unselectedcolor = 'rgb(112, 112, 112)'

    for (let i = 0; i < collections.length; i++) {
        const key = collections[i]

        if (selectedvts.includes(key)) {
            bgcolor.push(selectedbgcolor)
            border.push(selectedborder)
            color.push(selectedcolor)
        }
        else {
            bgcolor.push(unselectedbgcolor)
            border.push(unselectedborder)
            color.push(unselectedcolor)
        }
    }
    return [bgcolor, border, color]
}

function UserHomeCustomise(props) {
    const [usertype, setUsertype] = useState('Wedding')

    // wedding
    const [selectedvts_w, setSelectedvts_w] = useState([])
    const [bgcolor_w, setBgcolor_w] = useState([])
    const [border_w, setBorder_w] = useState([])
    const [color_w, setColor_w] = useState([])

    // event
    const [selectedvts_e, setSelectedvts_e] = useState([])
    const [bgcolor_e, setBgcolor_e] = useState([])
    const [border_e, setBorder_e] = useState([])
    const [color_e, setColor_e] = useState([])

    // guest
    const [selectedtypes_g, setSelectedtypes_g] = useState([])
    const [bgcolor_g, setBgcolor_g] = useState([])
    const [border_g, setBorder_g] = useState([])
    const [color_g, setColor_g] = useState([])

    useEffect(() => {
        const { usertype } = props.wedevent_data // loaded
        const { selectedvts_w } = props.wedevent_data //yourname_w, partnername_w, city_w, date_w, numguests_w, budgets_w,
        const { selectedvts_e } = props.wedevent_data //yourname_e, typeofevent_e, date_e, budgets_e,
        const { selectedtypes_g } = props.guest_data

        setUsertype(usertype)
        setSelectedvts_w(selectedvts_w)
        setSelectedvts_e(selectedvts_e)
        setSelectedtypes_g(selectedtypes_g)

        // gave all the btns with color if selected, white if nt
        var [bgcolor_w, border_w, color_w] = initialize_css(selectedvts_w, VENDORPROFILE_COLLECTION_VENDORTYPE_WEDDING)
        var [bgcolor_e, border_e, color_e] = initialize_css(selectedvts_e, VENDORPROFILE_COLLECTION_VENDORTYPE_EVENT)
        var [bgcolor_g, border_g, color_g] = initialize_css(selectedtypes_g, VENDORPROFILE_COLLECTION_TYPE_GUEST)

        setBgcolor_w(bgcolor_w)
        setBorder_w(border_w)
        setColor_w(color_w)

        setBgcolor_e(bgcolor_e)
        setBorder_e(border_e)
        setColor_e(color_e)

        setBgcolor_g(bgcolor_g)
        setBorder_g(border_g)
        setColor_g(color_g)

    }, [props.wedevent_data || props.guest_data])

    // for Wedding, Event & Guest 
    var vtbtns = []
    var collections = []
    var bgcolor = [], border = [], color = []

    if (usertype === 'Wedding') {
        collections = VENDORPROFILE_COLLECTION_VENDORTYPE_WEDDING
        bgcolor = bgcolor_w
        border = border_w
        color = color_w
    }
    else if (usertype === 'Event') {
        collections = VENDORPROFILE_COLLECTION_VENDORTYPE_EVENT
        bgcolor = bgcolor_e
        border = border_e
        color = color_e
    }
    else if (usertype === 'Guest') {
        collections = VENDORPROFILE_COLLECTION_TYPE_GUEST
        bgcolor = bgcolor_g
        border = border_g
        color = color_g
    }

    if (usertype === 'Wedding' || usertype === 'Event') {
        for (let i = 0; i < collections.length; i++) {
            const key = collections[i]
            vtbtns.push(
                <button
                    // className={bgcolor[i] !== MAIN_PINK_COLOR ? 'UHC_vtbtn' : ''}
                    // style={{ ...VT_BUTTONSTYLE, userSelect: 'none', backgroundColor: bgcolor[i], border: border[i], color: color[i] }}
                    className={(bgcolor[i] === MAIN_PINK_COLOR || bgcolor[i] === MENU_EVENT_COLOR) ? 'SELECTED_MSB_vtbtn' : 'MSB_vtbtn'}
                    onClick={() => {
                        const curr_index = i
                        if (usertype === 'Wedding') {
                            let temp = [...selectedvts_w]
                            if (bgcolor[i] === MAIN_PINK_COLOR) { // implies selected, unselect them
                                bgcolor[curr_index] = 'white'
                                border[curr_index] = 'thin solid rgb(112, 112, 112)'
                                color[curr_index] = 'rgb(112, 112, 112)'
                                if (temp.includes(key)) {
                                    var index1 = temp.indexOf(key);
                                    if (index1 > -1) {
                                        temp.splice(index1, 1)
                                    }
                                }
                            }
                            else { // implies not selected, select it
                                bgcolor[curr_index] = MAIN_PINK_COLOR
                                border[curr_index] = 'thin solid white'
                                color[curr_index] = 'white'
                                temp.push(key)
                            }

                            setBgcolor_w(bgcolor)
                            setBorder_w(border)
                            setColor_w(color)
                            setSelectedvts_w(temp)
                        }
                        else if (usertype === 'Event') {
                            let temp = [...selectedvts_e]

                            if (bgcolor[i] === MAIN_PINK_COLOR) { // implies selected, unselect them
                                bgcolor[curr_index] = 'white'
                                border[curr_index] = 'thin solid rgb(112, 112, 112)'
                                color[curr_index] = 'rgb(112, 112, 112)'
                                if (temp.includes(key)) {
                                    var index2 = temp.indexOf(key);
                                    if (index2 > -1) {
                                        temp.splice(index2, 1)
                                    }
                                }
                            }
                            else {// implies not selected, select it
                                bgcolor[curr_index] = MAIN_PINK_COLOR
                                border[curr_index] = 'thin solid white'
                                color[curr_index] = 'white'
                                temp.push(key)

                            }

                            setBgcolor_e(bgcolor)
                            setBorder_e(border)
                            setColor_e(color)
                            setSelectedvts_e(temp)

                        }
                    }}
                    key={key}
                >
                    {READABLE_VENDORTYPE_MAP[key]}
                    {Object.keys(VT_QUIZ_MAP).includes(key) && usertype === 'Wedding' ? QUIZ_DOT : null}
                </button>
            )


            // vtbtns.push(
            //     <button
            //         className={bgcolor[i] !== MAIN_PINK_COLOR ? 'UHC_vtbtn' : ''}
            //         style={{ ...VT_BUTTONSTYLE, userSelect: 'none', backgroundColor: bgcolor[i], border: border[i], color: color[i] }}
            //         onClick={() => {
            //             const curr_index = i
            //             if (usertype === 'Wedding') {
            //                 const temp = [...selectedvts_w]
            //                 if (bgcolor[i] === MAIN_PINK_COLOR) { // implies selected, unselect them
            //                     bgcolor[curr_index] = 'white'
            //                     border[curr_index] = 'thin solid rgb(112, 112, 112)'
            //                     color[curr_index] = 'rgb(112, 112, 112)'
            //                     if (temp.includes(key)) {
            //                         var index1 = temp.indexOf(key);
            //                         if (index1 > -1) {
            //                             temp.splice(index1, 1)
            //                         }
            //                     }
            //                 }
            //                 else { // implies not selected, select it
            //                     bgcolor[curr_index] = MAIN_PINK_COLOR
            //                     border[curr_index] = 'thin solid white'
            //                     color[curr_index] = 'white'
            //                     temp.push(key)
            //                 }

            //                 setBgcolor_w(bgcolor)
            //                 setBorder_w(border)
            //                 setColor_w(color)
            //                 setSelectedvts_w(temp)
            //             }
            //             else if (usertype === 'Event') {
            //                 const temp = [...selectedvts_e]

            //                 if (bgcolor[i] === MENU_EVENT_COLOR) { // implies selected, unselect them
            //                     bgcolor[curr_index] = 'white'
            //                     border[curr_index] = 'thin solid rgb(112, 112, 112)'
            //                     color[curr_index] = 'rgb(112, 112, 112)'
            //                     if (temp.includes(key)) {
            //                         var index2 = temp.indexOf(key);
            //                         if (index2 > -1) {
            //                             temp.splice(index2, 1)
            //                         }
            //                     }
            //                 }
            //                 else {// implies not selected, select it
            //                     bgcolor[curr_index] = MENU_EVENT_COLOR
            //                     border[curr_index] = 'thin solid white'
            //                     color[curr_index] = 'white'
            //                     temp.push(key)

            //                 }

            //                 setBgcolor_e(bgcolor)
            //                 setBorder_e(border)
            //                 setColor_e(color)
            //                 setSelectedvts_e(temp)

            //             }
            //         }}
            //         key={key}
            //     >
            //         {READABLE_VENDORTYPE_MAP[key]}
            //         {Object.keys(VT_QUIZ_MAP).includes(key) && usertype === 'Wedding' ? QUIZ_DOT : null}
            //     </button>
            // )

        }
    }
    else if (usertype === 'Guest') {
        vtbtns = GUESTCATEGORY_KEY.map((key, index) => {
            return (
                <div key={key} style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
                        <h5 style={{ width: '100%', display: 'flex', justifyContent: 'center', color: '#707070' }}>
                            {'- ' + key + ' -'}
                        </h5>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', justifyContent: 'center' }}>
                            {GUESTCATEGORY_DETAIL_MAP[key].map((item, i) => {
                                var curr_index = i
                                if (key === 'Event Type') curr_index = i + GUESTCATEGORY_DETAIL_MAP['Wedding Type'].length
                                return (
                                    <div
                                        style={{ backgroundColor: bgcolor_g[curr_index], border: border_g[curr_index], color: color_g[curr_index], fontWeight: 500, display: 'flex', flexDirection: 'column', borderRadius: 5, justifyContent: 'center', textAlign: 'center', padding: 5, margin: 10, minWidth: 155, minHeight: 50, }}
                                        onClick={() => {
                                            const temp = [...selectedtypes_g]

                                            if (bgcolor[curr_index] === MENU_GUEST_COLOR) { // implies selected, unselect them
                                                bgcolor[curr_index] = 'white'
                                                border[curr_index] = 'thin solid rgb(112, 112, 112)'
                                                color[curr_index] = 'rgb(112, 112, 112)'
                                                if (temp.includes(item)) {
                                                    var index2 = temp.indexOf(item);
                                                    if (index2 > -1) {
                                                        temp.splice(index2, 1)
                                                    }
                                                }
                                            }
                                            else {// implies not selected, select it
                                                bgcolor[curr_index] = MENU_GUEST_COLOR
                                                border[curr_index] = 'thin solid white'
                                                color[curr_index] = 'white'
                                                temp.push(item)

                                            }

                                            setBgcolor_g(bgcolor)
                                            setBorder_g(border)
                                            setColor_g(color)
                                            setSelectedtypes_g(temp)

                                        }}
                                        key={item + i}
                                    >
                                        {item}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {EMPTYSPACE}
                </div>
            )
        })
    }

    function updateCustomise() {

        const { reinit_main = null, _id, token, } = props

        let options = {}
        if (usertype === 'Wedding') {
            const { yourname_w, partnername_w, city_w, date_w, numguests_w, budgets_w, } = props.wedevent_data
            options = {
                method: 'POST',
                url: USER_UPDATE_SITE_WEDDING,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { _id, usertype, yourname_w, partnername_w, city_w, date_w, numguests_w, budgets_w, selectedvts_w }
            }
        }
        else if (usertype === 'Event') {
            const { typeofevent_e, date_e, budgets_e } = props.wedevent_data
            options = {
                method: 'POST',
                url: USER_UPDATE_SITE_EVENT,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { _id, token, usertype, typeofevent_e, date_e, budgets_e, selectedvts_e }
            }
        }
        else if (usertype === 'Guest') {
            options = {
                method: 'POST',
                url: USER_UPDATE_SITE_GUEST,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { _id, token, usertype, selectedtypes_g }
            }
        }

        try {
            axios(options).then((result) => {
                const { data, status } = result
                const { error, } = data //message, token, user_id
                if (error === false && status === 200) {
                    reinit_main()
                }
                else {
                    // setMsg(FAILURE_UPDATE)
                    refresh()
                }
            }).catch(error => {
                const { status } = error.response
                if (status === 401) refresh()
            });

        }
        catch (e) {
            refresh()
        }

    }

    // pass data to state, render btns, onclick change state, rerender
    return (
        <div style={{ color: 'var(--maindark)', userSelect: 'none', height: '100%', placeContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center', justifyContent: 'center', height: 68 }}>
                    <button
                        className={usertype === 'Wedding' ? "selectedcat" : "unselectedcat"}
                        onClick={() => { setUsertype('Wedding') }}
                        style={{ ...BUTTONSTYLE, outline: 'none', border: 'none', padding: 5 }}
                    >
                        <h4 style={{ fontWeight: 'bold', fontSize: 32 }}> Wedding </h4>
                    </button>
                    <div style={{ padding: 5, fontSize: 32 }}>
                        <h4 style={{ fontWeight: 'bold', fontSize: 32 }}>/</h4>
                    </div>
                    <button
                        className={usertype === 'Event' ? "selectedcat" : "unselectedcat"}
                        onClick={() => { setUsertype('Event') }}
                        style={{ ...BUTTONSTYLE, outline: 'none', border: 'none', padding: 5 }}
                    >
                        <h4 style={{ fontWeight: 'bold', fontSize: 32, display: 'flex', flexDirection: 'column' }}>Event</h4>
                    </button>
                    {hostjoin ? <div style={{ padding: 5, }}>
                        <h4 style={{ fontWeight: 'bold', fontSize: 32 }}>/</h4>
                    </div> : null}
                    {hostjoin ? <button
                        className={usertype === 'Guest' ? "selectedcat" : "unselectedcat"}
                        onClick={() => { setUsertype('Guest') }}
                        style={{ ...BUTTONSTYLE, outline: 'none', border: 'none', flexDirection: 'column' }}
                    >
                        <h4 style={{ fontWeight: 'bold' }}> Attend </h4>
                        <h6 style={{ fontWeight: 'bold' }}> {'stranger\'s'} </h6>
                        <h6 style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}> {'wedding | event'} </h6>
                    </button> : null}
                </div>

                {EMPTYSPACE}

                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', height: 40 }}>
                    <font style={{ padding: 10, fontFamily: 'Lato' }}>
                        {customise_text_map[usertype]}
                    </font>
                </div>
            </div>

            {EMPTYSPACE}
            {/* 
            {usertype === 'Guest'
                ? <div style={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap', justifyContent: 'flex-start', padding: 5, height: 500 }}>
                    {vtbtns}
                </div>
                : <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center', padding: 5, height: 500 }}>
                    {vtbtns}
                </div>}
            */}
            <div className='Portalmodal_msbroot'>{vtbtns}</div>

            {EMPTYSPACE}
            <div style={{ padding: 10 }}>
                {!eventgate && usertype === 'Event'
                    ? <button
                        className='Rsvpexplorer_portalsave'
                        style={{
                            position: 'relative',
                            bottom: 0,
                            backgroundColor: '#ffffff',
                            color: 'var(--maindark)',
                            border: '1px solid var(--maindark)',
                            minHeight: 45,
                            width: '100%',
                            margin: 0
                        }}
                    >
                        Coming Soon!
                    </button>
                    : <button
                        className='Rsvpexplorer_portalsave'
                        style={{
                            position: 'relative',
                            bottom: 0,
                            backgroundColor: '#ffffff',
                            color: 'var(--maindark)',
                            border: '1px solid var(--maindark)',
                            minHeight: 45,
                            width: '100%',
                            margin: 0
                        }}
                        onClick={updateCustomise}
                    >
                        Update
                    </button>}

            </div>


        </div>
    )
}

export default UserHomeCustomise