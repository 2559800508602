import React, { useState } from 'react'
import { Checkdevice } from '../../../../components/Checkdevice'
import './Play1.css'
const iconcss = { width: '25px', height: '25px', verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden' }
const audioicon = <svg style={iconcss} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" ><path d="M725.333333 298.666667v426.666666h-85.333333V298.666667h85.333333zM384 298.666667v426.666666H298.666667V298.666667h85.333333z m-170.666667 128v170.666666H128v-170.666666h85.333333z m682.666667 0v170.666666h-85.333333v-170.666666h85.333333z m-341.333333-256v682.666666h-85.333334V170.666667h85.333334z" fill="currentColor" p-id="2312"></path></svg>
const min_ht = 550

const maxwt_map = {
    'WEB': '25%',
    'TABLET': '33.3333333%',
    'MOBILE': '50%',
}

function paddingfunc(device, index) {
    if (device === 'MOBILE' && (index % 2 === 0)) {
        return '5px 2.5px 0px 5px'
    }
    if (device === 'MOBILE' && (index % 2 === 1)) {
        return '5px 5px 0px 2.5px'
    }
    else {
        return 5
    }
}

function Play1(props) {
    const { msgs } = props
    const device = Checkdevice()
    const [playindex, setPlayindex] = useState(-1)

    function playaudio(audio, i) {
        if(audio && playindex===-1){
            var a = new Audio(audio)
            a.play()
            setPlayindex(i)
            a.onended = function(){
                setPlayindex(-1)
            }
        }
    }

    return <div className="play1_root">
        {msgs.map((msg, i) => {
            const { ff, img, name, txt, audio } = msg
            return img.length > 0
                ? <div className="play1_msg" key={i + 'msg'} onClick={() => playaudio(audio, i)} style={{ padding: paddingfunc(device, i), minHeight: min_ht, fontFamily: ff, width: maxwt_map[device], minWidth: maxwt_map[device], maxWidth: maxwt_map[device] }}>
                    <div className="play1_msgsub">
                        <img className="play1_img" src={img} alt={''} style={{ height: min_ht * 0.4, maxHeight: min_ht * 0.4 }} />
                        <div className="play1_txt" style={{ height: min_ht * 0.5 }}>{txt}</div>
                        <div className="play1_name" style={{ height: min_ht * 0.1 }}>{name}</div>
                        {audio ? <div className={playindex === i ? "play1_audioiconon":"play1_audioiconoff"} style={{ margin: paddingfunc(device, i) }}>{audioicon}</div> : null}
                    </div>
                </div>
                : <div className="play1_msg" key={i + 'msg'} onClick={() => playaudio(audio, i)} style={{ padding: paddingfunc(device, i), minHeight: min_ht, fontFamily: ff, width: maxwt_map[device], minWidth: maxwt_map[device], maxWidth: maxwt_map[device] }}>
                    <div className="play1_msgsub" >
                        <div className="play1_txt" style={{ height: min_ht * 0.9 }}>{txt}</div>
                        <div className="play1_name" style={{ height: min_ht * 0.1 }}>{name}</div>
                        {audio ? <div className={playindex === i ? "play1_audioiconon":"play1_audioiconoff"} style={{ margin: paddingfunc(device, i) }}>{audioicon}</div> : null}
                    </div>
                </div>
        })}
    </div>
}

export default Play1;