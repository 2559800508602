import { S3CLOUD_CLOUDFRONT } from "../../config/s3directories"

const edit_paper = S3CLOUD_CLOUDFRONT + 'assets/Inv/edit_paper/'
const edit_img = S3CLOUD_CLOUDFRONT + 'assets/Inv/edit_img/'
const banner = S3CLOUD_CLOUDFRONT + 'assets/Inv/banner/'
const svg = S3CLOUD_CLOUDFRONT + 'assets/Inv/svg/'
const paper = S3CLOUD_CLOUDFRONT + 'assets/Inv/paper/'
const img = S3CLOUD_CLOUDFRONT + 'assets/Inv/img/'
// const edit_templateimg = S3CLOUD_CLOUDFRONT + 'assets/Inv/edit_templateimg/'
// const templateimg = S3CLOUD_CLOUDFRONT + 'assets/Inv/templateimg/'

 
    // for invitationedit 
var aws_icecream_small, aws_icecreamcolor_small, aws_peachflower_small, aws_pinkflower_small, aws_sakura_small,
    aws_smallflower_small, aws_masspurpleflower_small, aws_smallwhite_small, aws_manyred_small, aws_smallmasspurple_small,
    aws_smallwhites_small, aws_whiteredyellow_small, aws_commonpink_small, aws_branchespink_small, aws_cloudpink_small,
    aws_patternint1_small, aws_patterngold1_small, aws_toiletroll_small, aws_emptyshelf_small,

    // for invitationedit 
    floral1_edit, floral2_edit, floral3_edit, floral4_edit, floral5_edit, floral6_edit, floral7_edit, floral8_edit, floral9_edit, floral10_edit,
    floral11_edit, floral12_edit, floral13_edit, floral14_edit, floral15_edit, floral16_edit, floral17_edit, floral18_edit, floral19_edit, floral20_edit,
    floral21_edit, floral22_edit, floral23_edit, floral24_edit, floral25_edit, floral26_edit, floral27_edit, floral28_edit, floral29_edit, floral30_edit,
    floral31_edit, floral32_edit, floral33_edit, floral34_edit, floral35_edit, floral36_edit, floral37_edit, floral38_edit, floral39_edit, floral40_edit,
    floral41_edit, floral42_edit, floral43_edit, floral44_edit, floral45_edit, floral46_edit, floral47_edit, floral48_edit, floral49_edit, floral50_edit,
    floral51_edit, floral52_edit, floral53_edit, floral54_edit, floral55_edit, floral56_edit, floral57_edit, floral58_edit, floral59_edit, floral60_edit,
    floral61_edit, floral62_edit, floral63_edit, floral64_edit, floral65_edit, floral66_edit, floral67_edit, floral68_edit, floral69_edit, floral70_edit,
    floral71_edit, floral72_edit, floral73_edit, floral74_edit, floral75_edit, floral76_edit, floral77_edit, floral78_edit, floral79_edit, floral80_edit,
    floral81_edit, floral82_edit, floral83_edit, floral84_edit, floral85_edit, floral86_edit, floral87_edit, floral88_edit, floral89_edit, floral90_edit,
    floral91_edit, floral92_edit, floral93_edit, floral94_edit, floral95_edit, floral96_edit, floral97_edit, floral98_edit, floral99_edit, floral100_edit,

    // for invitationlookup
    aws_icecream, aws_icecreamcolor, aws_peachflower, aws_pinkflower, aws_sakura,
    aws_smallflower, aws_masspurpleflower, aws_smallwhite, aws_manyred, aws_smallmasspurple,
    aws_smallwhites, aws_whiteredyellow, aws_commonpink, aws_branchespink, aws_cloudpink,
    aws_patternint1, aws_patterngold1, aws_toiletroll, aws_emptyshelf,

    // for invitationlookup
    floral1, floral2, floral3, floral4, floral5, floral6, floral7, floral8, floral9, floral10,
    floral11, floral12, floral13, floral14, floral15, floral16, floral17, floral18, floral19, floral20,
    floral21, floral22, floral23, floral24, floral25, floral26, floral27, floral28, floral29, floral30,
    floral31, floral32, floral33, floral34, floral35, floral36, floral37, floral38, floral39, floral40,
    floral41, floral42, floral43, floral44, floral45, floral46, floral47, floral48, floral49, floral50,
    floral51, floral52, floral53, floral54, floral55, floral56, floral57, floral58, floral59, floral60,
    floral61, floral62, floral63, floral64, floral65, floral66, floral67, floral68, floral69, floral70,
    floral71, floral72, floral73, floral74, floral75, floral76, floral77, floral78, floral79, floral80,
    floral81, floral82, floral83, floral84, floral85, floral86, floral87, floral88, floral89, floral90,
    floral91, floral92, floral93, floral94, floral95, floral96, floral97, floral98, floral99, floral100,

    // for invitationlookup and invitationedit
    twosvg, foursvg, fivesvg, sixsvg, sevensvg, eightsvg

// if (production) { // in production mode

twosvg = svg + '1.svg'
foursvg = svg + '2.svg'
fivesvg = svg + '3.svg'
sixsvg = svg + '4.svg'
sevensvg = svg + '5.svg'
eightsvg = svg + '6.svg'

aws_icecream_small = edit_paper + 'small_icecream.jpeg' // [ for invitationsimulator and invitationedit ] [ start end ]
aws_icecreamcolor_small = edit_paper + 'small_icecreamcolor.jpeg'
aws_peachflower_small = edit_paper + 'small_peachflower.jpeg'
aws_pinkflower_small = edit_paper + 'small_pinkflower.jpeg'
aws_sakura_small = edit_paper + 'small_sakura.jpeg'
aws_smallflower_small = edit_paper + 'small_smallflower.jpeg'
aws_masspurpleflower_small = edit_paper + 'small_masspurpleflower.jpeg'
aws_smallwhite_small = edit_paper + 'small_smallwhite.jpeg'
aws_manyred_small = edit_paper + 'small_manyred.jpeg'
aws_smallmasspurple_small = edit_paper + 'small_smallmasspurple.jpeg'
aws_smallwhites_small = edit_paper + 'small_smallwhites.jpeg'
aws_whiteredyellow_small = edit_paper + 'small_whiteredyellow.jpeg'
aws_commonpink_small = edit_paper + 'small_commonpink.jpeg'
aws_branchespink_small = edit_paper + 'small_branchespink.jpeg'
aws_cloudpink_small = edit_paper + 'small_cloudpink.jpeg'
aws_patternint1_small = edit_paper + 'small_patternint1.jpeg'
aws_patterngold1_small = edit_paper + 'small_patterngold1.jpeg'
aws_toiletroll_small = edit_paper + 'small_toiletroll0.jpeg'
aws_emptyshelf_small = edit_paper + 'small_emptyshelf.jpeg' // [ for invitationsimulator and invitationedit ] [ end ]

aws_icecream = paper + 'icecream.jpeg' // [ for invitationlookup ] [ start ]
aws_icecreamcolor = paper + 'icecreamcolor.jpeg'
aws_peachflower = paper + 'peachflower.jpeg'
aws_pinkflower = paper + 'pinkflower.jpeg'
aws_sakura = paper + 'sakura.jpeg'
aws_smallflower = paper + 'smallflower.jpeg'
aws_masspurpleflower = paper + 'masspurpleflower.jpeg'
aws_smallwhite = paper + 'smallwhite.jpeg'
aws_manyred = paper + 'manyred.jpeg'
aws_smallmasspurple = paper + 'smallmasspurple.jpeg'
aws_smallwhites = paper + 'smallwhites.jpeg'
aws_whiteredyellow = paper + 'whiteredyellow.jpeg'
aws_commonpink = paper + 'commonpink.jpeg'
aws_branchespink = paper + 'branchespink.jpeg'
aws_cloudpink = paper + 'cloudpink.jpeg'
aws_patternint1 = paper + 'patternint1.jpeg'
aws_patterngold1 = paper + 'patterngold1.jpeg'
aws_toiletroll = paper + 'toiletroll0.jpeg'
aws_emptyshelf = paper + 'emptyshelf.jpeg' // [ for invitationlookup ] [ end ]

// 500 500 [ for invitationsimulator and invitationedit ] [ start ]
floral1_edit = edit_img + '0001.png'; floral2_edit = edit_img + '0002.png'; floral3_edit = edit_img + '0003.png'; floral4_edit = edit_img + '0004.png'; floral5_edit = edit_img + '0005.png'; floral6_edit = edit_img + '0006.png'; floral7_edit = edit_img + '0007.png'; floral8_edit = edit_img + '0008.png'; floral9_edit = edit_img + '0009.png'; floral10_edit = edit_img + '0010.png';
floral11_edit = edit_img + '0011.png'; floral12_edit = edit_img + '0012.png'; floral13_edit = edit_img + '0013.png'; floral14_edit = edit_img + '0014.png'; floral15_edit = edit_img + '0015.png'; floral16_edit = edit_img + '0016.png'; floral17_edit = edit_img + '0017.png'; floral18_edit = edit_img + '0018.png'; floral19_edit = edit_img + '0019.png'; floral20_edit = edit_img + '0020.png';
floral21_edit = edit_img + '0021.png'; floral22_edit = edit_img + '0022.png'; floral23_edit = edit_img + '0023.png'; floral24_edit = edit_img + '0024.png'; floral25_edit = edit_img + '0025.png'; floral26_edit = edit_img + '0026.png'; floral27_edit = edit_img + '0027.png'; floral28_edit = edit_img + '0028.png'; floral29_edit = edit_img + '0029.png'; floral30_edit = edit_img + '0030.png';
floral31_edit = edit_img + '0031.png'; floral32_edit = edit_img + '0032.png'; floral33_edit = edit_img + '0033.png'; floral34_edit = edit_img + '0034.png'; floral35_edit = edit_img + '0035.png'; floral36_edit = edit_img + '0036.png'; floral37_edit = edit_img + '0037.png'; floral38_edit = edit_img + '0038.png'; floral39_edit = edit_img + '0039.png'; floral40_edit = edit_img + '0040.png';
floral41_edit = edit_img + '0041.png'; floral42_edit = edit_img + '0042.png'; floral43_edit = edit_img + '0043.png'; floral44_edit = edit_img + '0044.png'; floral45_edit = edit_img + '0045.png'; floral46_edit = edit_img + '0046.png'; floral47_edit = edit_img + '0047.png'; floral48_edit = edit_img + '0048.png'; floral49_edit = edit_img + '0049.png'; floral50_edit = edit_img + '0050.png';
floral51_edit = edit_img + '0051.png'; floral52_edit = edit_img + '0052.png'; floral53_edit = edit_img + '0053.png'; floral54_edit = edit_img + '0054.png'; floral55_edit = edit_img + '0055.png'; floral56_edit = edit_img + '0056.png'; floral57_edit = edit_img + '0057.png'; floral58_edit = edit_img + '0058.png'; floral59_edit = edit_img + '0059.png'; floral60_edit = edit_img + '0060.png';
floral61_edit = edit_img + '0061.png'; floral62_edit = edit_img + '0062.png'; floral63_edit = edit_img + '0063.png'; floral64_edit = edit_img + '0064.png'; floral65_edit = edit_img + '0065.png'; floral66_edit = edit_img + '0066.png'; floral67_edit = edit_img + '0067.png'; floral68_edit = edit_img + '0068.png'; floral69_edit = edit_img + '0069.png'; floral70_edit = edit_img + '0070.png';
floral71_edit = edit_img + '0071.png'; floral72_edit = edit_img + '0072.png'; floral73_edit = edit_img + '0073.png'; floral74_edit = edit_img + '0074.png'; floral75_edit = edit_img + '0075.png'; floral76_edit = edit_img + '0076.png'; floral77_edit = edit_img + '0077.png'; floral78_edit = edit_img + '0078.png'; floral79_edit = edit_img + '0079.png'; floral80_edit = edit_img + '0080.png';
floral81_edit = edit_img + '0081.png'; floral82_edit = edit_img + '0082.png'; floral83_edit = edit_img + '0083.png'; floral84_edit = edit_img + '0084.png'; floral85_edit = edit_img + '0085.png'; floral86_edit = edit_img + '0086.png'; floral87_edit = edit_img + '0087.png'; floral88_edit = edit_img + '0088.png'; floral89_edit = edit_img + '0089.png'; floral90_edit = edit_img + '0090.png';
floral91_edit = edit_img + '0091.png'; floral92_edit = edit_img + '0092.png'; floral93_edit = edit_img + '0093.png'; floral94_edit = edit_img + '0094.png'; floral95_edit = edit_img + '0095.png'; floral96_edit = edit_img + '0096.png'; floral97_edit = edit_img + '0097.png'; floral98_edit = edit_img + '0098.png'; floral99_edit = edit_img + '0099.png'; floral100_edit = edit_img + '0100.png';
// 500 500 [ for invitationsimulator and invitationedit ] [ end ]

// 1000 1000 [ for invitationlookup ][ start ]
floral1 = img + '0001.png'; floral2 = img + '0002.png'; floral3 = img + '0003.png'; floral4 = img + '0004.png'; floral5 = img + '0005.png'; floral6 = img + '0006.png'; floral7 = img + '0007.png'; floral8 = img + '0008.png'; floral9 = img + '0009.png'; floral10 = img + '0010.png';
floral11 = img + '0011.png'; floral12 = img + '0012.png'; floral13 = img + '0013.png'; floral14 = img + '0014.png'; floral15 = img + '0015.png'; floral16 = img + '0016.png'; floral17 = img + '0017.png'; floral18 = img + '0018.png'; floral19 = img + '0019.png'; floral20 = img + '0020.png';
floral21 = img + '0021.png'; floral22 = img + '0022.png'; floral23 = img + '0023.png'; floral24 = img + '0024.png'; floral25 = img + '0025.png'; floral26 = img + '0026.png'; floral27 = img + '0027.png'; floral28 = img + '0028.png'; floral29 = img + '0029.png'; floral30 = img + '0030.png';
floral31 = img + '0031.png'; floral32 = img + '0032.png'; floral33 = img + '0033.png'; floral34 = img + '0034.png'; floral35 = img + '0035.png'; floral36 = img + '0036.png'; floral37 = img + '0037.png'; floral38 = img + '0038.png'; floral39 = img + '0039.png'; floral40 = img + '0040.png';
floral41 = img + '0041.png'; floral42 = img + '0042.png'; floral43 = img + '0043.png'; floral44 = img + '0044.png'; floral45 = img + '0045.png'; floral46 = img + '0046.png'; floral47 = img + '0047.png'; floral48 = img + '0048.png'; floral49 = img + '0049.png'; floral50 = img + '0050.png';
floral51 = img + '0051.png'; floral52 = img + '0052.png'; floral53 = img + '0053.png'; floral54 = img + '0054.png'; floral55 = img + '0055.png'; floral56 = img + '0056.png'; floral57 = img + '0057.png'; floral58 = img + '0058.png'; floral59 = img + '0059.png'; floral60 = img + '0060.png';
floral61 = img + '0061.png'; floral62 = img + '0062.png'; floral63 = img + '0063.png'; floral64 = img + '0064.png'; floral65 = img + '0065.png'; floral66 = img + '0066.png'; floral67 = img + '0067.png'; floral68 = img + '0068.png'; floral69 = img + '0069.png'; floral70 = img + '0070.png';
floral71 = img + '0071.png'; floral72 = img + '0072.png'; floral73 = img + '0073.png'; floral74 = img + '0074.png'; floral75 = img + '0075.png'; floral76 = img + '0076.png'; floral77 = img + '0077.png'; floral78 = img + '0078.png'; floral79 = img + '0079.png'; floral80 = img + '0080.png';
floral81 = img + '0081.png'; floral82 = img + '0082.png'; floral83 = img + '0083.png'; floral84 = img + '0084.png'; floral85 = img + '0085.png'; floral86 = img + '0086.png'; floral87 = img + '0087.png'; floral88 = img + '0088.png'; floral89 = img + '0089.png'; floral90 = img + '0090.png';
floral91 = img + '0091.png'; floral92 = img + '0092.png'; floral93 = img + '0093.png'; floral94 = img + '0094.png'; floral95 = img + '0095.png'; floral96 = img + '0096.png'; floral97 = img + '0097.png'; floral98 = img + '0098.png'; floral99 = img + '0099.png'; floral100 = img + '0100.png';
// 1000 1000 [ for invitationlookup ][ end ]

// ITYPE_IMGLINK_MAP = { // for invitationlookup
//     'Wedding': templateimg + 'large_wedding.jpeg',
//     'House-Warming': templateimg + 'large_housewarming.jpeg',
//     'Baby Shower': templateimg + 'large_baby.jpeg',
//     'Birthday Party': templateimg + 'large_birthday.jpeg',
//     'Postponement': templateimg + 'large_toiletroll.jpeg'
// }

// ITYPE_IMGLINK_MAP_SMALL = { // for invitationsimulator
//     'Wedding': edit_templateimg + 'small_wedding.png',
//     'House-Warming': edit_templateimg + 'small_housewarming.jpeg',
//     'Baby Shower': edit_templateimg + 'small_baby.jpeg',
//     'Birthday Party': edit_templateimg + 'small_birthday.jpeg',
//     'Postponement': edit_templateimg + 'small_toiletroll.jpeg'
// }

const flowers_collection = [
    "",

    floral1, floral2, floral3, floral4, floral5, floral6, floral7, floral8, floral9, floral10,
    floral11, floral12, floral13, floral14, floral15, floral16, floral17, floral18, floral19, floral20,
    floral21, floral22, floral23, floral24, floral25, floral26, floral27, floral28, floral29, floral30,
    floral31, floral32, floral33, floral34, floral35, floral36, floral37, floral38, floral39, floral40,
    floral41, floral42, floral43, floral44, floral45, floral46, floral47, floral48, floral49, floral50,
    floral51, floral52, floral53, floral54, floral55, floral56, floral57, floral58, floral59, floral60,
    floral61, floral62, floral63, floral64, floral65, floral66, floral67, floral68, floral69, floral70,
    floral71, floral72, floral73, floral74, floral75, floral76, floral77, floral78, floral79, floral80,
    floral81, floral82, floral83, floral84, floral85, floral86, floral87, floral88, floral89, floral90,
    floral91, floral92, floral93, floral94, floral95, floral96, floral97, floral98, floral99, floral100,

]

const textures_collection = [
    '',
    S3CLOUD_CLOUDFRONT + 'assets/Inv/texture/1.png',
    // S3CLOUD_CLOUDFRONT + 'assets/Inv/texture/2.png'
]

const banners_collection = [
    '',
    banner + '0001.png', banner + '0002.png', banner + '0003.png', banner + '0004.png', banner + '0005.png', banner + '0006.png', banner + '0007.png', banner + '0008.png', banner + '0009.png', banner + '0010.png',
    banner + '0011.png', banner + '0012.png', banner + '0013.png', banner + '0014.png', banner + '0015.png', banner + '0016.png', banner + '0017.png', banner + '0018.png', banner + '0019.png', banner + '0020.png',
    banner + '0021.png', banner + '0022.png', banner + '0023.png', banner + '0024.png', banner + '0025.png', banner + '0026.png', banner + '0027.png', banner + '0028.png', banner + '0029.png', banner + '0030.png',
    banner + '0031.png', banner + '0032.png', banner + '0033.png', banner + '0034.png', banner + '0035.png', banner + '0036.png', banner + '0037.png', banner + '0038.png', banner + '0039.png', banner + '0040.png',
    banner + '0041.png', banner + '0042.png', banner + '0043.png', banner + '0044.png', banner + '0045.png', banner + '0046.png', banner + '0047.png', banner + '0048.png', banner + '0049.png', banner + '0050.png',
    banner + '0051.png', banner + '0052.png', banner + '0053.png', banner + '0054.png', banner + '0055.png', banner + '0056.png', banner + '0057.png', banner + '0058.png', banner + '0059.png', banner + '0060.png',
    banner + '0061.png', banner + '0062.png', banner + '0063.png', banner + '0064.png', banner + '0065.png', banner + '0066.png', banner + '0067.png', banner + '0068.png', banner + '0069.png', banner + '0070.png',
    banner + '0071.png', banner + '0072.png', banner + '0073.png', banner + '0074.png', banner + '0075.png', banner + '0076.png', banner + '0077.png', banner + '0078.png', banner + '0079.png', banner + '0080.png',
    banner + '0081.png', banner + '0082.png', banner + '0083.png', banner + '0084.png', banner + '0085.png', banner + '0086.png', banner + '0087.png', banner + '0088.png', banner + '0089.png', banner + '0090.png',
    banner + '0091.png', banner + '0092.png', banner + '0093.png', banner + '0094.png', banner + '0095.png', banner + '0096.png', banner + '0097.png', banner + '0098.png', banner + '0099.png', banner + '0100.png',

    // banner + '1001.png',
    // banner + '1002.png',
    // banner + '1003.png',
    // banner + '1004.png',
    // banner + '1005.png',
    // banner + '1006.png',
    // banner + '1008.png',
    // banner + '1009.png',
    // banner + '1010.png',
    // banner + '1011.png',
    // banner + '1012.png',
    // banner + '1013.png',
]

const sccorners_collection = [
    '0',
    '1', '2', '3', '4', '5', '6', 'https://i.ibb.co/NCYsCFD/1000-1000-corner.png', '7', '8', '9',
    '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
    '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
    '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
    '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
    '50', '51', '52', '53', '54', '55', '56', '57', '58', '59',
    '60', '61', '62', '63', '64', '65', '66', '67', '68', '69',
    '70', '71', '72', '73', '74', '75', '76', '77', '78', '79',
    '80', '81', '82', '83', '84', '85', '86', '87', '88', '89',
    '90', '91', '92', '93', '94', '95', '96', '97', '98', '99'
]

const sconesided_collection = [
    '',
    'https://i.ibb.co/Y83hNRh/onesided.png'
]


const BGS_TYPE = [
    '', 'SVG', 'SVG', 'SVG', 'SVG', 'SVG', 'SVG',

    'WP', 'WP', 'WP', 'WP', 'WP', // wallpaper
    'WP', 'WP', 'WP', 'WP', 'WP',
    'WP', 'WP', 'WP', 'WP', 'WP',
    'WP', 'WP', 'WP', 'WP',

    'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG',
    'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG',
    'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG',
    'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG',
    'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG',
    'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG',
    'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG',
    'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG',
    'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG',
    'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG', 'IMG'

]

const corners_collection = [
    '',
    'https://i.ibb.co/YtpZcKW/200-200light.png',
]

export {

    BGS_TYPE,
    banners_collection,
    corners_collection,
    textures_collection,
    flowers_collection,

    twosvg,
    foursvg,
    fivesvg,
    sixsvg,
    sevensvg,
    eightsvg,

    aws_icecream,
    aws_icecreamcolor,
    aws_peachflower,
    aws_pinkflower,
    aws_sakura,
    aws_smallflower,
    aws_masspurpleflower,
    aws_smallwhite,
    aws_manyred,
    aws_smallmasspurple,
    aws_smallwhites,
    aws_whiteredyellow,
    aws_commonpink,
    aws_branchespink,
    aws_cloudpink,
    aws_patternint1,
    aws_patterngold1,
    aws_toiletroll,
    aws_emptyshelf,

    aws_icecream_small,
    aws_icecreamcolor_small,
    aws_peachflower_small,
    aws_pinkflower_small,
    aws_sakura_small,
    aws_smallflower_small,
    aws_masspurpleflower_small,
    aws_smallwhite_small,
    aws_manyred_small,
    aws_smallmasspurple_small,
    aws_smallwhites_small,
    aws_whiteredyellow_small,
    aws_commonpink_small,
    aws_branchespink_small,
    aws_cloudpink_small,
    aws_patternint1_small,
    aws_patterngold1_small,
    aws_toiletroll_small,
    aws_emptyshelf_small,

    floral1_edit, floral2_edit, floral3_edit, floral4_edit, floral5_edit, floral6_edit, floral7_edit, floral8_edit, floral9_edit, floral10_edit,
    floral11_edit, floral12_edit, floral13_edit, floral14_edit, floral15_edit, floral16_edit, floral17_edit, floral18_edit, floral19_edit, floral20_edit,
    floral21_edit, floral22_edit, floral23_edit, floral24_edit, floral25_edit, floral26_edit, floral27_edit, floral28_edit, floral29_edit, floral30_edit,
    floral31_edit, floral32_edit, floral33_edit, floral34_edit, floral35_edit, floral36_edit, floral37_edit, floral38_edit, floral39_edit, floral40_edit,
    floral41_edit, floral42_edit, floral43_edit, floral44_edit, floral45_edit, floral46_edit, floral47_edit, floral48_edit, floral49_edit, floral50_edit,
    floral51_edit, floral52_edit, floral53_edit, floral54_edit, floral55_edit, floral56_edit, floral57_edit, floral58_edit, floral59_edit, floral60_edit,
    floral61_edit, floral62_edit, floral63_edit, floral64_edit, floral65_edit, floral66_edit, floral67_edit, floral68_edit, floral69_edit, floral70_edit,
    floral71_edit, floral72_edit, floral73_edit, floral74_edit, floral75_edit, floral76_edit, floral77_edit, floral78_edit, floral79_edit, floral80_edit,
    floral81_edit, floral82_edit, floral83_edit, floral84_edit, floral85_edit, floral86_edit, floral87_edit, floral88_edit, floral89_edit, floral90_edit,
    floral91_edit, floral92_edit, floral93_edit, floral94_edit, floral95_edit, floral96_edit, floral97_edit, floral98_edit, floral99_edit, floral100_edit,

    floral1, floral2, floral3, floral4, floral5, floral6, floral7, floral8, floral9, floral10,
    floral11, floral12, floral13, floral14, floral15, floral16, floral17, floral18, floral19, floral20,
    floral21, floral22, floral23, floral24, floral25, floral26, floral27, floral28, floral29, floral30,
    floral31, floral32, floral33, floral34, floral35, floral36, floral37, floral38, floral39, floral40,
    floral41, floral42, floral43, floral44, floral45, floral46, floral47, floral48, floral49, floral50,
    floral51, floral52, floral53, floral54, floral55, floral56, floral57, floral58, floral59, floral60,
    floral61, floral62, floral63, floral64, floral65, floral66, floral67, floral68, floral69, floral70,
    floral71, floral72, floral73, floral74, floral75, floral76, floral77, floral78, floral79, floral80,
    floral81, floral82, floral83, floral84, floral85, floral86, floral87, floral88, floral89, floral90,
    floral91, floral92, floral93, floral94, floral95, floral96, floral97, floral98, floral99, floral100,

    sccorners_collection,
    sconesided_collection

}