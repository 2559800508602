import React from 'react'
import { c_fs, d_fs } from '../../../../config/constants'
import { Checkdevice } from '../../../../components'
// const bordercss = ['1px solid transparent', '1px solid #707070']

// FONT SIZE FOR WEB
const datetime_fs_web = c_fs
const schedule_fs_web = d_fs
const qn_fs_web = d_fs
const map_fs_web = d_fs
const btmbar_stub = <div className='ILOOKUP_BTMSTUB' style={{ minHeight: 110 }} />
export const T1border = ({ children, styleborder, styleborderbr, stylebordercolor, styleborderwt }) => {
    const borderradius = styleborderbr ? '4vmin' : '0px'
    const borderwt = styleborderwt ? 5 : 3
    return [
        // 0
        children,
        // 1
        <div style={{ borderRadius: borderradius, border: `${borderwt}px solid ${stylebordercolor}`, width: `calc(100% - ${borderwt}px)`, margin: borderwt, display: 'flex', flexDirection: 'column', minHeight: '100%', alignItems: 'center', justifyContent: 'center', zIndex: 'initial' }}>
            {children}
        </div>,
        // 2
        <div style={{ borderRadius: borderradius, border: `${borderwt}px solid ${stylebordercolor}`, width: `calc(100% - ${borderwt}px)`, margin: borderwt, display: 'flex', flexDirection: 'column', minHeight: '100%', alignItems: 'center', justifyContent: 'center', zIndex: 'initial' }}>
            <div style={{ borderRadius: `calc( ${borderradius} * 0.8 )`, border: `${borderwt}px solid ${stylebordercolor}`, width: `calc( 100% - 12px)`, margin: borderwt * 2, display: 'flex', flexDirection: 'column', minHeight: '100%', alignItems: 'center', justifyContent: 'center', zIndex: 'initial' }}>
                {children}
            </div>
        </div>,
        // 5
        <div className="bordert1" style={{ borderRadius: borderradius, border: `${borderwt}px solid ${stylebordercolor}`, width: `calc(100% - ${borderwt * 2}px)`, margin: borderwt, display: 'flex', flexDirection: 'column', minHeight: '100%', alignItems: 'center', justifyContent: 'center', zIndex: 'initial' }}>
            {children}
            <div className="T1_TOP" style={{ display: 'flex', position: 'absolute', top: 0, left: 0, flexDirection: 'row', width: '100%', height: '100%', placeContent: 'space-between' }}>
                {/* left */}
                <div className="T1_TOP_LEFT" style={{ position: 'relative', top: borderwt * 2, left: borderwt * 2 }}>
                    <div style={{ width: 40, height: 80, position: 'absolute', border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius }} />
                    <div style={{ width: 80, height: 40, position: 'absolute', border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius }} />
                </div >
                {/* right */}
                <div className="T1_TOP_RIGHT" style={{ position: 'relative', top: borderwt * 2, right: borderwt * 2, transform: "scale(-1,1)", }}>
                    <div style={{ width: 40, height: 80, position: 'absolute', border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius }} />
                    <div style={{ width: 80, height: 40, position: 'absolute', border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius }} />
                </div >
            </div>

            <div className="T1_BTM" style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', placeContent: 'space-between', position: 'relative' }}>
                <div className="T1_BTM_LEFT" style={{ position: 'relative' }}>
                    <div style={{ width: 40, height: 80, border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderBottom: 'none', borderTopRightRadius: borderradius, position: 'absolute', bottom: 0 }} />
                    <div style={{ width: 80, height: 40, border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderBottom: 'none', borderTopRightRadius: borderradius, position: 'absolute', bottom: 0 }} />
                </div>
                <div className="T1_BTM_RIGHT" style={{ position: 'relative', transform: "scale(-1,1)" }}>
                    <div style={{ width: 40, height: 80, border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderBottom: 'none', borderTopRightRadius: borderradius, position: 'absolute', bottom: 0 }} />
                    <div style={{ width: 80, height: 40, border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderBottom: 'none', borderTopRightRadius: borderradius, position: 'absolute', bottom: 0 }} />
                </div>
            </div>
        </div>,
        // 6
        <div className="bordert1" style={{ borderRadius: borderradius, border: `${borderwt}px solid ${stylebordercolor}`, width: `calc(100% - ${borderwt * 2}px)`, margin: borderwt, display: 'flex', flexDirection: 'column', minHeight: '100%', alignItems: 'center', justifyContent: 'center', zIndex: 'initial' }}>
            {children}

            <div className="T1_TOP" style={{ display: 'flex', position: 'absolute', top: 0, left: 0, flexDirection: 'row', width: '100%', height: '100%', placeContent: 'space-between' }}>
                {/* left */}
                <div className="T1_TOP_LEFT" style={{ position: 'relative', top: borderwt * 2, left: borderwt * 2 }}>
                    <div style={{ width: 30, height: 90, position: 'absolute', border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, borderTopLeftRadius: 0 }} />
                    <div style={{ width: 60, height: 60, position: 'absolute', border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, borderTopLeftRadius: 0 }} />
                    <div style={{ width: 90, height: 30, position: 'absolute', border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, borderTopLeftRadius: 0 }} />
                </div >
                {/* right */}
                <div className="T1_TOP_RIGHT" style={{ position: 'relative', top: borderwt * 2, right: borderwt * 2, transform: "scale(-1,1)", }}>
                    <div style={{ width: 30, height: 90, position: 'absolute', border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, }} />
                    <div style={{ width: 60, height: 60, position: 'absolute', border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, }} />
                    <div style={{ width: 90, height: 30, position: 'absolute', border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, }} />
                </div >
            </div>
            <div className="T1_BTM" style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', placeContent: 'space-between', position: 'relative' }}>
                {/* left */}
                <div className="T1_BTM_LEFT" style={{ position: 'relative' }}>
                    <div style={{ width: 30, height: 90, border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderBottom: 'none', borderTopRightRadius: borderradius, position: 'absolute', bottom: 0 }} />
                    <div style={{ width: 60, height: 60, border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderBottom: 'none', borderTopRightRadius: borderradius, position: 'absolute', bottom: 0 }} />
                    <div style={{ width: 90, height: 30, border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderBottom: 'none', borderTopRightRadius: borderradius, position: 'absolute', bottom: 0 }} />
                </div>
                {/* right */}
                <div className="T1_BTM_RIGHT" style={{ position: 'relative', transform: "scale(-1,1)" }}>
                    <div style={{ width: 30, height: 90, border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderBottom: 'none', borderTopRightRadius: borderradius, position: 'absolute', bottom: 0 }} />
                    <div style={{ width: 60, height: 60, border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderBottom: 'none', borderTopRightRadius: borderradius, position: 'absolute', bottom: 0 }} />
                    <div style={{ width: 90, height: 30, border: `${borderwt}px solid ${stylebordercolor}`, borderLeft: 'none', borderBottom: 'none', borderTopRightRadius: borderradius, position: 'absolute', bottom: 0 }} />
                </div>
            </div>
        </div>,
    ][styleborder]
}

function T1(props) {

    const {

        wt, ht, WEB_TABLET_WT,

        // DATA FROM BACKEND
        styletype,
        templatecolor,
        textures,
        priff,
        imgcss_map,

        // sequence 
        displayseq,

        // border
        styleborder,
        styleborderbr, // border radius
        stylebordercolor,// border color
        styleborderwt, // border width, true is thick, false is thin

        id_bgstylea_map,
        id_bgstyleb_map,

        showtitle,

        // FACTORY OF DOMS
        titles_dom,
        pagescrolldown_dom,
        mainimg_dom,
        alticlinks_dom,
        qns_dom,
        socialmedia_dom,
        rsvpbtn_dom,
        maintext_dom,
        subtext_dom,
        location_dom,
        datetime_dom,
        schedule_dom,
        map_dom,
        proposal_dom,
        howwemet_dom,
        btmbar_dom,

        // FONT SIZE FOR MOBILE
        maintext_fs_mobile,
        subtext_fs_mobile,
        qn_fs_mobile,
        datetime_fs_mobile,
        schedule_fs_mobile,
        map_fs_mobile,



    } = props

    const fontcolor = '#707070'
    // const fontcolor = 'white'

    const device = Checkdevice()


    // const sqdim = 4
    // const linecolor = 'red'
    // const siunit = 'vw'
    // const borderframe = [
    //     <div key="top_left_sq" style={{ position: 'absolute', top: 0, left: 0, border: `1vw solid ${linecolor}`, height: `${sqdim}vw`, width: `${sqdim}vw`, }} />,
    //     <div key="top_right_sq" style={{ position: 'absolute', top: 0, right: 1, border: `1vw solid ${linecolor}`, height: `${sqdim}vw`, width: `${sqdim}vw`, }} />,
    //     <div key="btm_left_sq" style={{ position: 'absolute', bottom: 0, left: 0, border: `1vw solid ${linecolor}`, height: `${sqdim}vw`, width: `${sqdim}vw`, }} />,
    //     <div key="btm_right_sq" style={{ position: 'absolute', bottom: 0, right: 1, border: `1vw solid ${linecolor}`, height: `${sqdim}vw`, width: `${sqdim}vw`, }} />,
    //     <div key="top" style={{ position: 'absolute', top: sqdim / 2 + 'vw', left: sqdim + 'vw', borderTop: `1vw solid ${linecolor}`, height: '1vw', width: `calc( 100% -  ${sqdim * 2}vw)` }} />,
    //     <div key="left" style={{ position: 'absolute', top: sqdim + 'vw', left: sqdim / 2 + 'vw', borderLeft: `1vw solid ${linecolor}`, height: `calc( 100% -  ${sqdim * 2}vw)`, width: '1vw' }} />,
    //     <div key="right" style={{ position: 'absolute', top: sqdim + 'vw', right: sqdim / 2 + 'vw', borderRight: `1vw solid ${linecolor}`, height: `calc( 100% -  ${sqdim * 2}vw)`, width: '1vw' }} />,
    //     <div key="btm" style={{ position: 'absolute', bottom: sqdim / 2 + 'vw', left: `${sqdim}`, borderTop: `1vw solid ${linecolor}`, height: '1vw', width: `calc( 100% -  ${sqdim * 2}vw)` }} />
    // ]


    // const T1sketch = <Sketch
    //     setup={(p5, canvasParentRef) => {
    //         p5.smooth();
    //         p5.createCanvas(wt*0.5, wt*0.5).parent(canvasParentRef);
    //         p5.colorMode(p5.HSB);
    //         p5.noStroke();
    //     }}
    //     draw={(p5) => {
    //         p5.fill('pink');
    //         p5.ellipse(wt / 4, ht / 4, wt*0.25, wt*0.25);

    //     }}
    // />

    // if (styletype > -1) {
    if (styletype.length > 0) {

        const { borderRadius = false, boxShadow = false } = imgcss_map['mainimg']

        if (device === 'WEB' || device === 'TABLET') {

            const seq_dom = {
                'maintext': maintext_dom(WEB_TABLET_WT * 0.7, d_fs),
                'location': location_dom(WEB_TABLET_WT * 0.7, 999),
                'date': datetime_dom(datetime_fs_web),
                'howwemet': howwemet_dom(WEB_TABLET_WT * 0.7, d_fs),
                'proposal': proposal_dom(WEB_TABLET_WT * 0.7, d_fs),
                'schedule': schedule_dom(WEB_TABLET_WT * 0.7, 'max-content', schedule_fs_web),
                'qna': qns_dom(WEB_TABLET_WT * 0.7, qn_fs_web),
                'map': map_dom(WEB_TABLET_WT * 0.7, map_fs_web),
                'rsvp': rsvpbtn_dom(WEB_TABLET_WT * 0.7)
            }

            const bg_dom = styletype.length > 0 ? id_bgstylea_map[styletype] : null

            const br = (device === 'WEB' || device === 'TABLET') ? 20 : 0
            return <div id="ilookupt1" style={{ overflowY: props.showpromote ? 'hidden' : '', position: 'relative', display: 'flex', flexDirection: 'column', width: '100%', minHeight: ht, alignItems: 'center', color: fontcolor, backgroundColor: templatecolor, backgroundImage: `url(${textures[0]})`, fontFamily: priff, }}>
                {bg_dom}
                <T1border styleborder={styleborder} styleborderwt={styleborderwt} styleborderbr={styleborderbr} stylebordercolor={stylebordercolor} >

                    {titles_dom('100%')}
                    <div style={{ borderRadius: borderRadius ? br : 0, boxShadow: boxShadow ? 'rgba(121, 121, 121, 0.5) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px' : '', marginBottom: 50, zIndex: 999, width: WEB_TABLET_WT, }}>
                        {mainimg_dom('100%', 999, borderRadius ? br : 0)}
                    </div>
                    <div style={{ backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', width: '100%', display: 'flex', flexDirection: 'column', zIndex: 999, justifyContent: 'center' }}>
                        {/* {alticlinks_dom()} */}
                        {/* {subtext_dom(WEB_TABLET_WT * 0.7, d_fs)} */}
                        {/* {socialmedia_dom()} */}
                        {/* 
                            {maintext_dom(WEB_TABLET_WT * 0.7, d_fs)}
                            {location_dom(WEB_TABLET_WT * 0.7, 999)}
                            {datetime_dom(datetime_fs_web)}
                            {howwemet_dom(WEB_TABLET_WT * 0.7, d_fs)}
                            {proposal_dom(WEB_TABLET_WT * 0.7, d_fs)}
                            {schedule_dom(WEB_TABLET_WT * 0.7, 'max-content', schedule_fs_web,)}
                            {qns_dom(WEB_TABLET_WT * 0.7, qn_fs_web)}
                            {map_dom(WEB_TABLET_WT * 0.7, map_fs_web)}
                            {rsvpbtn_dom(WEB_TABLET_WT * 0.7)}
                         */}

                        {/* {displayseq.map((seqkey, i)=>seq_dom[seqkey])} */}
                        {displayseq.map((seqkey) => React.cloneElement(seq_dom[seqkey], { key: seq_dom[seqkey].key }))}

                    </div>
                    {btmbar_dom()}
                    {btmbar_stub}
                </T1border>
            </div>
        }
        else if (device === 'MOBILE') {

            const borderradius = styleborderbr ? '2vmin' : '0px'
            const borderwt = styleborderwt ? 3 : 1.5

            const seq_dom = {
                'maintext': maintext_dom(wt * 0.95, maintext_fs_mobile),
                'location': location_dom(wt * 0.95, 999),
                'date': datetime_dom(datetime_fs_mobile),
                'howwemet': howwemet_dom(wt * 0.95, maintext_fs_mobile),
                'proposal': proposal_dom(wt * 0.95, maintext_fs_mobile),
                'schedule': schedule_dom(wt * 0.95, 'max-content', schedule_fs_mobile),
                'qna': qns_dom(wt * 0.95, qn_fs_mobile),
                'map': map_dom(wt * 0.95, map_fs_mobile),
                'rsvp': rsvpbtn_dom(wt * 0.95),
            }

            const title_border_bg = <div style={{ width: '100%', height: ht, placeContent: 'center', display: 'flex', position: 'relative', scrollSnapAlign: 'start' }}>
                {titles_dom('100%')}
                {[
                    // 0
                    <div style={{ width: 'calc( 100% - 20px )', height: 'calc( 100% - 20px )', border: `1px solid transparent`, position: 'absolute', borderRadius: borderradius, margin: 10, top: 0, left: 0 }} />,
                    // 2
                    <div style={{ width: 'calc( 100% - 10px )', height: 'calc( 100% - 10px )', border: `${borderwt}px solid ${stylebordercolor}`, position: 'absolute', borderRadius: borderradius, margin: 5, top: 0, left: 0 }} />,
                    // 4
                    <div style={{ width: 'calc( 100% - 10px )', height: 'calc( 100% - 10px )', border: `${borderwt}px solid ${stylebordercolor}`, position: 'absolute', borderRadius: borderradius, margin: 5, top: 0, left: 0 }} >
                        <div style={{ width: 'calc( 100% - 10px )', height: 'calc( 100% - 10px )', border: `${borderwt}px solid ${stylebordercolor}`, position: 'absolute', borderRadius: borderradius, margin: 5, top: 0, left: 0 }} />
                    </div>,
                    // 3
                    <div style={{ width: 'calc( 100% - 10px )', height: 'calc( 100% - 10px )', border: `${borderwt}px solid ${stylebordercolor}`, position: 'absolute', borderRadius: borderradius, margin: 5, top: 0, left: 0 }} >
                        {/* top left */}
                        <div style={{ top: 0, left: 0, border: `${borderwt}px solid ${stylebordercolor}`, height: 80, width: 40, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius }} />
                        <div style={{ top: 0, left: 0, border: `${borderwt}px solid ${stylebordercolor}`, height: 40, width: 80, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius }} />

                        {/* top right */}
                        <div style={{ top: 0, left: 'calc( 100% - 40px )', border: `${borderwt}px solid ${stylebordercolor}`, height: 80, width: 40, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, transform: 'scale(-1,1)' }} />
                        <div style={{ top: 0, left: 'calc( 100% - 80px )', border: `${borderwt}px solid ${stylebordercolor}`, height: 40, width: 80, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, transform: 'scale(-1,1)' }} />

                        {/* btm right */}
                        <div style={{ top: 'calc( 100% - 80px )', left: 'calc( 100% - 40px )', border: `${borderwt}px solid ${stylebordercolor}`, height: 80, width: 40, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, transform: 'scale(-1,-1)' }} />
                        <div style={{ top: 'calc( 100% - 40px )', left: 'calc( 100% - 80px )', border: `${borderwt}px solid ${stylebordercolor}`, height: 40, width: 80, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, transform: 'scale(-1,-1)' }} />

                        {/* btm left */}
                        <div style={{ top: 'calc( 100% - 80px )', left: 0, border: `${borderwt}px solid ${stylebordercolor}`, height: 80, width: 40, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, transform: 'scale(1,-1)' }} />
                        <div style={{ top: 'calc( 100% - 40px )', left: 0, border: `${borderwt}px solid ${stylebordercolor}`, height: 40, width: 80, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, transform: 'scale(1,-1)' }} />

                    </div>,
                    // 1
                    <div style={{ width: 'calc( 100% - 10px )', height: 'calc( 100% - 10px )', border: `${borderwt}px solid ${stylebordercolor}`, position: 'absolute', borderRadius: borderradius, margin: 5, top: 0, left: 0 }} >
                        {/* top left */}
                        <div style={{ top: 0, left: 0, border: `${borderwt}px solid ${stylebordercolor}`, height: 90, width: 30, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius }} />
                        <div style={{ top: 0, left: 0, border: `${borderwt}px solid ${stylebordercolor}`, height: 60, width: 60, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius }} />
                        <div style={{ top: 0, left: 0, border: `${borderwt}px solid ${stylebordercolor}`, height: 30, width: 90, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius }} />

                        {/* top right */}
                        <div style={{ top: 0, left: 'calc( 100% - 30px )', border: `${borderwt}px solid ${stylebordercolor}`, height: 90, width: 30, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, transform: 'scale(-1,1)' }} />
                        <div style={{ top: 0, left: 'calc( 100% - 60px )', border: `${borderwt}px solid ${stylebordercolor}`, height: 60, width: 60, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, transform: 'scale(-1,1)' }} />
                        <div style={{ top: 0, left: 'calc( 100% - 90px )', border: `${borderwt}px solid ${stylebordercolor}`, height: 30, width: 90, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, transform: 'scale(-1,1)' }} />

                        {/* btm right */}
                        <div style={{ top: 'calc( 100% - 90px )', left: 'calc( 100% - 30px )', border: `${borderwt}px solid ${stylebordercolor}`, height: 90, width: 30, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, transform: 'scale(-1,-1)' }} />
                        <div style={{ top: 'calc( 100% - 60px )', left: 'calc( 100% - 60px )', border: `${borderwt}px solid ${stylebordercolor}`, height: 60, width: 60, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, transform: 'scale(-1,-1)' }} />
                        <div style={{ top: 'calc( 100% - 30px )', left: 'calc( 100% - 90px )', border: `${borderwt}px solid ${stylebordercolor}`, height: 30, width: 90, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, transform: 'scale(-1,-1)' }} />

                        {/* btm left */}
                        <div style={{ top: 'calc( 100% - 90px )', left: 0, border: `${borderwt}px solid ${stylebordercolor}`, height: 90, width: 30, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, transform: 'scale(1,-1)' }} />
                        <div style={{ top: 'calc( 100% - 60px )', left: 0, border: `${borderwt}px solid ${stylebordercolor}`, height: 60, width: 60, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, transform: 'scale(1,-1)' }} />
                        <div style={{ top: 'calc( 100% - 30px )', left: 0, border: `${borderwt}px solid ${stylebordercolor}`, height: 30, width: 90, position: 'absolute', borderLeft: 'none', borderTop: 'none', borderBottomRightRadius: borderradius, transform: 'scale(1,-1)' }} />

                    </div>

                ][styleborder]}
                {id_bgstylea_map[styletype]}
                {pagescrolldown_dom}
            </div>
            if (showtitle) {
                return <div id="ilookupt1" key={'MOBILE_MAIN'} style={{ overflowY: props.showpromote ? 'hidden' : '', color: fontcolor, backgroundImage: `url(${textures[0]})`, backgroundColor: templatecolor, overflowX: 'hidden', display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', zIndex: 0, fontFamily: priff, }}>
                    {title_border_bg}
                    {mainimg_dom('inherit', 999)}
                    {displayseq.map((seqkey) => React.cloneElement(seq_dom[seqkey], { key: seq_dom[seqkey].key }))}
                    {/* 
                    {alticlinks_dom()}
                    {maintext_dom(wt * 0.95, maintext_fs_mobile)}
                    {subtext_dom(wt * 0.95, subtext_fs_mobile)}
                    {location_dom(wt * 0.95, 999)}
                    {datetime_dom(datetime_fs_mobile)}
                    {howwemet_dom(wt * 0.95, maintext_fs_mobile)}
                    {proposal_dom(wt * 0.95, maintext_fs_mobile)}
                    {schedule_dom(wt * 0.95, 'max-content', schedule_fs_mobile)}
                    {qns_dom(wt * 0.95, qn_fs_mobile)}
                    {map_dom(wt * 0.95, map_fs_mobile)}
                    {socialmedia_dom()}
                    {rsvpbtn_dom(wt * 0.95)} 
                    */}
                    {btmbar_dom()}
                    {btmbar_stub}
                </div>
            }
            else {
                return <div id="ilookupt1" key={'MOBILE_MAIN'} style={{ overflowY: props.showpromote ? 'hidden' : '', color: fontcolor, backgroundImage: `url(${textures[0]})`, backgroundColor: templatecolor, overflowX: 'hidden', display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', zIndex: 0, fontFamily: priff }}>
                    {title_border_bg}
                </div>
            }

        }
    }
}

export default T1