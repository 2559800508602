import React, { useState, useEffect, memo } from 'react'
import { input_dom, textarea_dom, describe_dom } from '../Commonelements/Commonelements'
import Success from './components/Success'
import './Glmodal.css'


function Addmsgcontent({ addmsg, updateaddmsg, addmsgstate, update, close }) {

    const [msgs, setMsgs] = useState({})
    const [checking, setChecking] = useState(false)

    useEffect(() => {
        if (checking) {
            setChecking(() => { // will return false once everything is done
                let msgs = { 'addmsgkey': null, 'addmsgtxtkey': null }
                let error_counter = 0
                for (let i = 0; i < addmsg.length; i++) {
                    if (addmsg[i].length > 500) {
                        error_counter++;
                        msgs[['addmsgkey', 'addmsgtxtkey'][i]] = 'You have exceeded 500 character limit.'
                    }
                }
                error_counter > 0 ? setMsgs(msgs) : update()
                return false
            })
        }
    }, [checking])

    const general_dom = <div className='Rsvpexplorer_portalcontent'>
        {describe_dom('Message templates to assist you to send message.')}
        {input_dom('Subject', 'Subject', addmsg[0], (e) => updateaddmsg([e.target.value, addmsg[1]]), 'addmsgkey', msgs)}
        {textarea_dom('Content', 'Content', addmsg[1], (e) => updateaddmsg([addmsg[0], e.target.value]), 'addmsgtxtkey', msgs)}
        <button className='Rsvpexplorer_portalsave' onClick={() => { setChecking(true) }} disabled={addmsgstate === 'SAVING'}>
            {{ // IDLE, SAVING, SUCCESS, FAILURE
                'IDLE': 'Save',
                'SAVING': 'Saving...',
                'SUCCESS': 'New Question Created',
                'FAILURE': 'Error Occurred, please try again later.'
            }[addmsgstate]}
        </button>

    </div>

    const success_dom = addmsgstate === 'SUCCESS'
        ? <Success
            txt={'Message template updated. Click to return.'}
            onClick={close} />
        : null

    return <div className='Rsvpexplorer_portal'>
        <div className='Rsvpexplorer_portaltitle'>Message Template</div>
        {addmsgstate !== 'SUCCESS' ? general_dom : success_dom}
    </div>



}

// function Addmsgcontent(props) {
//     const { add, DEVICE, DEVICE_MODALWT_MAP } = props

//     const [addmsg, setAddmsg] = useState(['', ''])

//     useEffect(() => {
//         if (props) setAddmsg(props.addmsg)
//     }, [props])

//     return <div className="gl_modalroot" style={{ width: DEVICE === 'MOBILE' || DEVICE === 'TABLET' ? DEVICE_MODALWT_MAP[DEVICE] : '' }}>

//         {/* subject */}
//         <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 15, alignItems: 'flex-start' }}>
//             Subject (Email Subject)
//             <FormControl
//                 style={{ width: '100%' }}
//                 value={addmsg[0]}
//                 onChange={(event) => setAddmsg([event.target.value, addmsg[1]])}
//             />
//         </div>

//         {/* email text */}
//         <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 15, alignItems: 'flex-start' }}>
//             Text
//             <FormControl
//                 style={{ width: '100%' }}
//                 value={addmsg[1]}
//                 onChange={(event) => setAddmsg([addmsg[0], event.target.value])}
//             />
//         </div>

//         <button className={'gl_updatebtn'} onClick={() => add(addmsg)}>Update</button>

//     </div>

// }

export default memo(Addmsgcontent)