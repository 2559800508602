import React, { useEffect, useState } from 'react'
import { FS_LH_MAP, e_fs, MORE_SMALL } from '../../../../../config/constants'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { UPDATE_PLANNINGSEQ } from '../../../../../config/api';
import { iconwrapper, Retrieve_personal_info } from '../../../../../config/commonfunctions';
import { CROSS_ICON, KEYFLORAL_ICON } from '../../../../../config/svgicons';
import axios from 'axios'

function Selectionbox({ onAdd, onClickexist, onRemoveexist, quota, ids, id, updateseq, where }) {
    const { token } = Retrieve_personal_info()
    const [reordering, setReordering] = useState(false)
    const [items, setItems] = useState({})

    useEffect(() => {
        if (reordering) {
            const { list, startIndex, endIndex } = items
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
            try {
                let options = {
                    method: 'POST',
                    url: UPDATE_PLANNINGSEQ,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { start: startIndex, target: endIndex, where: where }
                }
                axios(options).then((item) => {
                    const { status } = item
                    if (status === 200) {
                        setReordering(false)
                        updateseq(result)
                    }
                    else {
                        // need to revert back to orginal state
                    }

                }).catch((e) => { alert('Unable to update') })
            }
            catch (e) {
                alert('Unable to update')
            }
        }
    }, [reordering, items])
    const getItemStyle = (isDragging, draggableStyle) => ({ backgroundColor: 'white', outline: 'none', userSelect: 'none', ...draggableStyle, });
    const getListStyle = (isDraggingOver) => ({ display: 'flex', overflow: 'auto', backgroundColor: 'white', outline: 'none', userSelect: 'none', });
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        if (result.source.index === result.destination.index) {
            return;
        }
        setReordering(true)
        setItems({ list: ids, startIndex: result.source.index, endIndex: result.destination.index })
    }
    return (
        <div id='selectionbox__' style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', overflowX: 'auto', overflowY: 'hidden', minHeight: 120 }}>
            <button className="planning_addbtn" onClick={onAdd}>
                <div >{MORE_SMALL}</div>
                <div style={{ fontWeight: 'bold', fontSize: e_fs, lineHeight: FS_LH_MAP[e_fs] }}>
                    {'MAX ' + quota}
                </div>
            </button>

            {!reordering && where !== 'qrmaker'
                ? <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps} >
                                {ids && ids.length > 0 && ids.map((item, i) => {
                                    const index = i
                                    const { _id, name, urlhandler } = item
                                    const cn = _id === id ? "planning_existroot_selected" : "planning_existroot_unselected"
                                    return <Draggable key={_id} draggableId={_id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                className={cn}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{ ...getItemStyle(snapshot.isDragging, provided.draggableProps.style), backgroundColor: _id === id ? '#fff5f5' : 'white' }}
                                            >
                                                {index === 0
                                                    ? <div
                                                        className="planning_existrootstar"
                                                        style={{ padding: '10px 0px 0px 10px', color: '#b21212', position: 'absolute', width: 20, height: 20, display: 'flex', placeContent: 'center', alignItems: 'center', left: 0 }}
                                                    >
                                                        {iconwrapper(KEYFLORAL_ICON, { width: 20, height: 20, display: 'flex', })}
                                                    </div>
                                                    : null}
                                                <div
                                                    className="planning_existroot_1"
                                                    onClick={() => onClickexist(_id, index)}>{urlhandler ? urlhandler : name}</div> {/* urlhandler take precedence over name */}
                                                {<div
                                                    className="planning_existrootstar"
                                                    style={{ padding: '10px 10px 0px 0px', color: '#b21212', position: 'absolute', width: 20, height: 20, display: 'flex', placeContent: 'center', alignItems: 'center', right: 0, }}
                                                    onClick={() => onRemoveexist(_id, index)}
                                                >
                                                    {iconwrapper(CROSS_ICON, { width: 20, height: 20, display: 'flex', })}
                                                </div>}
                                            </div>
                                        )}
                                    </Draggable>
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                : null}

            {where === 'qrmaker'
                ? ids && ids.length > 0 && ids.map((item, i) => {
                    const index = i
                    const { _id, urlhandler } = item
                    const cn = _id === id ? "planning_existroot_selected" : "planning_existroot_unselected"
                    return <div key={_id} className={cn} style={{ backgroundColor: _id === id ? '#fff5f5' : 'white' }}>
                        <div
                            className="planning_existroot_1"
                            onClick={() => onClickexist(_id, index)}>
                            {urlhandler}
                        </div> {/* urlhandler take precedence over name */}
                        <div
                            className="planning_existrootstar"
                            style={{ padding: '10px 10px 0px 0px', color: '#b21212', position: 'absolute', width: 20, height: 20, display: 'flex', placeContent: 'center', alignItems: 'center', right: 0, }}
                            onClick={() => onRemoveexist(_id, index)}
                        >
                            {iconwrapper(CROSS_ICON, { width: 20, height: 20, display: 'flex', })}
                        </div>
                    </div>
                })
                : null}
        </div>
    )
}
export default Selectionbox
