import React, { useEffect, useState } from 'react'
import { HexColorInput, HexColorPicker } from 'react-colorful'
import { Checkdevice, ScreenWidth } from '../../../../../../components'
import { TABLET } from '../../../../../../config/constants'
import { inputdescribe_dom, partialtitle_dom } from '../Commonelements/Commonelements'
import Explain from '../Explain'
import './Border.css'

const TITLE = 'Frame'
const TEXT = `Select border for your invitation.`
const selected_css = '3px solid #eb5d7b'
const unselected_css = '1px dashed #707070'

// invitationedit border selection border
function Border(props) {
    const {
        updateStyleborder, styleborder,
        updateStyleborderbr, styleborderbr, // border radius
        updateStylebordercolor, stylebordercolor, // border color
        updateStyleborderwt, styleborderwt, // border width, true is thick, false is thin
        btmbar, // dom element
        udpatebackup
    } = props

    const [wt, setWt] = useState(null)
    const [allborders, setAllborders] = useState([])
    const DEVICE = Checkdevice()

    useEffect(() => {
        var brstyling = styleborderbr ? 'border_br' : 'border_nobr'
        var brborder = styleborderwt ? `3px solid ${stylebordercolor}` : `1.5px solid ${stylebordercolor}`
        const allborders = [
            '',
            <div className={`borderitem_root ${stylebordercolor === '#FFFFFF' ? 'borderitem_bg' : ''}`} style={{ fill: stylebordercolor }} >
                <div className={`borderitem1_1 ${brstyling}`} style={{ border: brborder }} />
            </div>,
            <div className={`borderitem_root ${stylebordercolor === '#FFFFFF' ? 'borderitem_bg' : ''}`} style={{ fill: stylebordercolor }} >
                <div className={`borderitem2_1 ${brstyling}`} style={{ border: brborder }} />
                <div className={`borderitem2_2 ${brstyling}`} style={{ border: brborder, borderRadius: styleborderbr ? 'calc( 2vmin - 5px )' : '' }} />
            </div>,
            <div className={`borderitem_root ${stylebordercolor === '#FFFFFF' ? 'borderitem_bg' : ''}`} style={{ fill: stylebordercolor }} >
                <div className={`borderitem5_1 ${brstyling}`} style={{ border: brborder, borderTopLeftRadius: styleborderbr ? 'calc( 2vmin - 5px )' : 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0 }} />
                <div className={`borderitem5_2 ${brstyling}`} style={{ border: brborder, borderTopLeftRadius: styleborderbr ? 'calc( 2vmin - 5px )' : 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0 }} />

            </div>,
            <div className={`borderitem_root ${stylebordercolor === '#FFFFFF' ? 'borderitem_bg' : ''}`} style={{ fill: stylebordercolor }} >
                <div className={`borderitem1_common borderitem6_1 ${brstyling}`} style={{ border: brborder, borderTopLeftRadius: styleborderbr ? 'calc( 2vmin - 5px )' : 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0 }} />
                <div className={`borderitem1_common borderitem6_2 ${brstyling}`} style={{ border: brborder, borderTopLeftRadius: styleborderbr ? 'calc( 2vmin - 5px )' : 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0 }} />
                <div className={`borderitem1_common borderitem6_3 ${brstyling}`} style={{ border: brborder, borderTopLeftRadius: styleborderbr ? 'calc( 2vmin - 5px )' : 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, }} />
            </div>
        ]
        setAllborders(allborders)
    }, [props])

    useEffect(() => {
        const { wt } = props
        setWt(wt - 74)
    }, [props])

    const DEVICE_SIZE_MAP = {
        'WEB': { minHeight: Math.floor(wt / 5), minWidth: Math.floor(wt / 5), maxHeight: Math.floor(wt / 5), maxWidth: Math.floor(wt / 5), fontSize: 32, borderRadius: 10, },
        'TABLET': { minHeight: Math.floor(wt / 4), minWidth: Math.floor(wt / 4), maxHeight: Math.floor(wt / 4), maxWidth: Math.floor(wt / 4), fontSize: 32, borderRadius: 10, },
        'MOBILE': { minHeight: Math.floor(wt / 0.9), minWidth: Math.floor(wt / 0.9), maxHeight: Math.floor(wt / 0.9), maxWidth: Math.floor(wt / 0.9), backgroundSize: Math.floor(wt / 0.9), fontSize: 20, borderRadius: 14, },
    }

    const devicewt = ScreenWidth()
    var selectedfunc = (i) => styleborder === i ? <div className={"bg_tick" + DEVICE}>✓</div> : null
    const allborder_doms = [allborders.map((obj, index) => {
        console.log(wt)
        if (devicewt < TABLET && devicewt > 400) {
            return <div
                className="bg_common"
                key={'s' + index}
                style={{
                    position: 'relative',
                    border: styleborder === index ? selected_css : unselected_css,
                    overflow: 'hidden',
                    minHeight: 120,
                    minWidth: 120,
                    maxHeight: 120,
                    maxWidth: 120,
                    fontSize: 20,
                    borderRadius: 14,
                }}
            >
                {obj}
                <div style={{ width: '100%', height: '100%', position: 'absolute' }} onClick={() => { udpatebackup(); updateStyleborder(index) }}></div>
                {selectedfunc(index)}
            </div>
        }
        else {
            return <div className="bg_common" key={'s' + index} style={{ position: 'relative', border: styleborder === index ? selected_css : unselected_css, ...DEVICE_SIZE_MAP[DEVICE], overflow: 'hidden', margin: 5 }}>
                {obj}
                <div style={{ width: '100%', height: '100%', position: 'absolute' }} onClick={() => { udpatebackup(); updateStyleborder(index) }}></div>
                {selectedfunc(index)}
            </div>
        }

    })]


    const colors_arr = ['#c8c09e', '#C0C0C0', '#707070', '#333333', '#FFFFFF']

    return <div className='Ieditcomp_div' style={props.commoneditstyle}>
        <Explain title={TITLE} txt={TEXT} />

        <div className="Details_grp">
            {partialtitle_dom('Frame Design', {})}
            {devicewt < TABLET && devicewt > 400 // device that is between TABLET and MOBILE, not exactly MOBILE.
                ? <ul className={"bg_btmroot" + DEVICE} style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: 0, marginBottom: 0, alignContent: 'flex-start', }}>
                    {allborder_doms}
                </ul>
                : <ul className={"bg_btmroot" + DEVICE} style={{ display: 'grid', gridTemplateColumns: `repeat(auto-fit, minmax(${DEVICE_SIZE_MAP[DEVICE].minWidth}px, max-content))`, justifyContent: 'center', width: '100%', padding: 0, marginBottom: 0, alignContent: 'flex-start', }}>
                    {allborder_doms}
                </ul>}

        </div>

        <div className="Details_grp">
            {partialtitle_dom('Frame Color', {})}
            {inputdescribe_dom(`Select color that is friendly to overall design of your website.`)}
            <div className='Detailsfont_container'>
                <div className='Detailsfont_left'>
                    <HexColorPicker style={{ height: 200, width: 200 }} color={stylebordercolor} onChange={(c) => { udpatebackup(); updateStylebordercolor(c) }} />
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
                        <div style={{ paddingRight: 3, fontSize: 18 }}>#</div>
                        <HexColorInput color={stylebordercolor} onChange={(c) => { udpatebackup(); updateStylebordercolor(c) }} style={{ width: '100%', borderRadius: 8, padding: '3px 5px', border: '1px solid var(--maindark)' }} />
                    </div>
                </div>
                <ul className='Detailsfont_right'>
                    {colors_arr.map((c, i) => {
                        return <div key={i + 'color1'} style={{ padding: 1, width: 'max-content', height: 'max-content', display: 'flex', placeContent: 'center', alignItems: 'center', border: stylebordercolor === c ? '2px solid var(--mainpink)' : '2px solid transparent', borderRadius: 5 }}>
                            <li style={{ width: 20, height: 20, backgroundColor: c, cursor: 'pointer', borderRadius: 3, border: stylebordercolor === c ? 'none' : '1px solid #dddddd' }} onClick={() => { udpatebackup(); updateStylebordercolor(c) }} />
                        </div>
                    })}
                </ul>
            </div>
        </div>

        <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row' }}>
            <>
                <div className="Details_grp" style={{ marginBottom: 0 }}>
                    {partialtitle_dom('Frame Corner', {})}
                    <div key={'styleborderbr' + styleborderbr} className='border_menu1' style={{ border: '1px solid var(--subgrey)', width: 'calc( 100% - 2.5px)', padding: 10, borderRadius: 5 }}>
                        <div className={`border_iconbr ${styleborderbr ? 'border_styleselected' : ''}`} onClick={() => { udpatebackup(); updateStyleborderbr(true) }} />
                        <div className={`border_noiconbr ${!styleborderbr ? 'border_styleselected' : ''}`} onClick={() => { udpatebackup(); updateStyleborderbr(false) }} />
                    </div>
                </div>
            </>
            <div style={{ width: 5 }} />
            <>
                <div className="Details_grp" style={{ marginBottom: 0 }}>
                    {partialtitle_dom('Frame Thickness', {})}
                    <div key={'styleborderwt' + styleborderwt} className='border_menu3' style={{ border: '1px solid var(--subgrey)', width: 'calc( 100% - 2.5px)', padding: 10, borderRadius: 5 }}>
                        <div className={`border_brthickthin ${styleborderwt ? 'border_styleselected' : ''}`} onClick={() => { udpatebackup(); updateStyleborderwt(true) }} >
                            <div className='border_brthickcontent' />
                        </div>
                        <div className={`border_brthickthin ${!styleborderwt ? 'border_styleselected' : ''}`} onClick={() => { udpatebackup(); updateStyleborderwt(false) }} >
                            <div className='border_brthincontent' />
                        </div>
                    </div>
                </div>
            </>
        </div>


        {btmbar}

    </div>
}

export default Border