import React, { useEffect, useState } from 'react'
import { USER_READINVITATIONSPROJECTS, USER_EDITICPASSCODE, INVITATION_CREATE, INVITATION_DESTROY, } from '../../../../config/api'
import { Retrieve_personal_info } from '../../../../config/commonfunctions'
import {  Modcfm } from '../../../../components'
import Selectionbox from '../Components/Selectionbox/Selectionbox'
import axios from 'axios'
import Continuepage from '../Components/Continuepage/Continuepage'
import Createnew from '../Components/Createnew/Createnew'
import '../Planning.css'

function Invitationmaker(props) {

    window.screen.lockOrientationUniversal = window.screen.lockOrientation || window.screen.mozLockOrientation || window.screen.msLockOrientation;

    const { token } = Retrieve_personal_info()
    const [firsttime, setFirsttime] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [ic_ids, setIc_ids] = useState([])

    const [_id, setId] = useState('') // to indicate is ADD or invitation card id clicked

    // current form, if invitationcard gt items, den render them else default is profile details
    const [itype, setItype] = useState('') // invitation type
    const [urlhandler, setUrlhandler] = useState('') // invitation url handler

    // edit btn modal
    const [passcode, setPasscode] = useState('')

    // quota
    const [quota, setQuota] = useState(0)

    // removal of selectionbox
    const [rmvmod, setRmvmod] = useState(false)
    const [rmvindex, setRmvindex] = useState(null)
    const [rmvid, setRmvid] = useState(null)

    // all pages
    const [page, setPage] = useState('NONE') // NONE, CREATE, OPEN (OPEN EXISTING)

    useEffect(() => {
        if (firsttime) {
            try {
                let options = {
                    method: 'POST',
                    url: USER_READINVITATIONSPROJECTS,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                }
                axios(options).then((invitationcard) => {
                    const { ic_ids, invitationquota, invitationcard_ids } = invitationcard.data
                    reorder(invitationcard_ids && invitationcard_ids.length > 0, ic_ids, invitationcard_ids);
                    setQuota(invitationquota)
                    setLoaded(true)
                    setPage('NONE')
                }).catch((e) => { props.history.push('/') })
            }
            catch (e) {
                props.history.push('/')
            }
            setFirsttime(false)
        }
    }, [])

    function reorder(gticids, ic_ids, invitationcard_ids) {
        if (gticids) {
            // reorder them based on checklist_ids, as db return messy indexed arr
            var new_objs = ic_ids.map(() => []);
            for (let i = 0; i < ic_ids.length; i++) {
                const { _id } = ic_ids[i];
                var index = invitationcard_ids.indexOf(_id);
                new_objs[index] = ic_ids[i];
            }
            setIc_ids(new_objs);
        }
        else {
            setIc_ids([]);
        }
    }

    function clearFields() {
        setItype('')
        setUrlhandler('')
        setPasscode('')
        setPage('NONE')

    }

    const savepasscode = (newpasscode) => {
        return axios({
            method: 'POST',
            url: USER_EDITICPASSCODE,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
            data: { newpasscode, passcode, _id }
        }).then((result) => {
            const { data } = result
            if (data.message === 'SUCCESS') {
                setPasscode(newpasscode)
                return 'SUCCESS'
            }
            else if (data.message === 'UNABLE_SAVE') {
                return 'UNABLE_SAVE'
            }
        }).catch((e) => { console.log(e) })
    }


    const continueclick = () => {
        props.history.push('invitationbuilder/' + _id + '/edit');

        // props.history.push('invitationbuilder/' + _id + '/edit')
    }

    const lookupclick = '/invitation/' + urlhandler + (passcode && passcode.length > 0 ? `/passcode=${passcode}` : '')

    const add = () => {
        setId('')
        setPage('CREATE')
    }

    const clickexist = (item, index) => {
        if (item === _id) {
            // uncheck 
            clearFields()
            setId('')
            setPage('NONE')
        }
        else {
            var obj
            for (let i = 0; i < ic_ids.length; i++) {
                if (ic_ids[i]._id === item) {
                    obj = ic_ids[i]
                    break
                }
            }
            const { _id, itype, urlhandler, passcode } = obj
            clearFields()
            setId(_id)
            setItype(itype)
            setUrlhandler(urlhandler)
            setPasscode(passcode ? passcode : '')
            setPage('OPEN')
        }
    }

    const removeexist =(_id, index) => {
        setRmvmod(true);
        setRmvid(_id);
        setRmvindex(index);
    }

    const updateseq = (new_ids) => { setIc_ids(new_ids); };

    const removecfm = (item, index) => {
        try {
            let options = {
                method: 'POST',
                url: INVITATION_DESTROY,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { ic_id: item }
            }
            axios(options).then((item) => {
                const { data } = item
                const { message } = data
                if (message === 'SUCCESS') {
                    setId('')

                    var local_ic_ids = ic_ids.slice()
                    local_ic_ids.splice(index, 1)
                    setIc_ids(local_ic_ids)
                    clearFields()

                }
            }).catch((e) => {
                props.history.push('/')
            })
        }
        catch (e) {
            props.history.push('/')
        }
    }

    const create = (urlhandler, passcode, itype) => {
        try {
            return axios({
                method: 'POST',
                url: INVITATION_CREATE,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { urlhandler, passcode, itype }
            })
                .then((item) => {
                    const { data } = item
                    const { message } = data
                    if (message === 'SUCCESS') {
                        setIc_ids([...ic_ids, data.ic])
                        setId('')
                        setPasscode('')
                        setUrlhandler('')
                    }
                    return { message, id: data.ic._id } // this will return to Createnew.jsx, then it will call createsuccess and set the id.

                }).catch((e) => { })
        }
        catch (e) {
            props.history.push('/')
        }

    }
    return (
        <div id='planningedit_root'>
            <div id='planningedit_selectionbox'>

                {loaded
                    ? <Selectionbox
                        where={'invitationmaker'}
                        onClickexist={clickexist}
                        onRemoveexist={removeexist}
                        onAdd={add}
                        updateseq={updateseq}
                        quota={quota}
                        ids={ic_ids}
                        id={_id}
                    />
                    : null}

                {{
                    'NONE': null,
                    'OPEN': <Continuepage
                        {...props}
                        from={'invitationmaker'}
                        urlhandler={urlhandler}
                        passcode={passcode}
                        itype={itype}
                        lookupclick={lookupclick}
                        continueclick={continueclick}
                        savepasscode={savepasscode}
                        title={'Change Passcode'}
                    />,
                    'CREATE': <Createnew
                        {...props}
                        from={'invitationmaker'}
                        reachquota={quota === ic_ids.length}
                        urlhandler={urlhandler}
                        passcode={passcode}
                        itype={'Wedding'}
                        create={create}
                        createsuccess={(id, urlhandler, passcode, itype) => {
                            setUrlhandler(urlhandler)
                            setPasscode(passcode)
                            setId(id)
                            setItype(itype)
                            setPage('OPEN')
                        }}
                    />
                }[page]}
                
                {rmvmod ? <Modcfm
                    onHide={() => { setRmvmod(false) }}
                    onOk={() => { removecfm(rmvid, rmvindex); setRmvmod(false) }}
                    onNo={() => { setRmvmod(false) }}
                    txt={'Once you delete this, there is no going back. Link to this Invitation and Guestlist will no longer be functional, please be certain.'}
                    /> : null}
            </div>
        </div>

    )
}
export default Invitationmaker