import React from 'react'
import { STARTUP_NAME } from '../../config/constants'

export function Loading({ show = true, ht = '100vh' }) {
    return show
        ? <div key={'justanotherloading'} style={{ height: ht, overflow: "hidden", display: 'flex', justifyContent: 'center', fontWeight: 'bold', width: '100vw', justifyItems: 'center', alignItems: 'center', position: 'absolute', color: 'var(--maindark)', top: 0, left: 0, zIndex: 10000000, flexDirection: 'column', backgroundColor: 'white' }}>
            <div key={'justanotherloading2'} style={{ position: 'relative', display: 'flex', placeSelf: 'center', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <div key={'c44'} style={{ color: '#333333', padding: '10px 10px 0px 10px', marginTop: 0, fontSize: 34, fontFamily: 'Marck Script', fontWeight: 'normal' }}>{STARTUP_NAME}</div>
                <div style={{ color: '#333333', fontFamily: 'Lato', fontSize: 14 }}>Creating Moments, Celebrating Love</div>
            </div>
        </div>
        : null
}