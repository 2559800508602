import React, { useState, useEffect } from 'react';
import { Checkdevice } from '../../../components/Checkdevice'
import { MAIN_PINK_COLOR } from '../../../config/constants';
import { Header, MainMenu } from '../components';


const featurecss = { display: 'flex', padding: 10, margin: 5, fontSize: '1em', backgroundColor: MAIN_PINK_COLOR, color: 'white', fontWeight: 'bold', minWidth: 'max-content', minHeight: 'max-content', textAlign: 'center', alignItems: 'center', justifyContent: 'center', borderRadius: 10, outline: 'none', flexDirection: 'column', width: 115 }

function EventDiscovery(props) {

    const device = Checkdevice()
    // const paperwt = device === 'WEB' ? 720 : device === 'TABLET' ? 630 : 360
    // const paperht = device === 'WEB' ? 450 : device === 'TABLET' ? 360 : 180// eslint-disable-line

    const [loaded, setLoaded] = useState(false)
    const [keys, setKeys] = useState([])
    const [keysscore, setKeysscore] = useState([])

    useEffect(() => { // componentDidMount
        // need retrieve abbrev_userlocation
        const defaultScoreCardTitle = ['Creative', 'Collaborative', 'Nature', 'Sweat', 'Brainpower']
        var keysscore = []
        for (let i = 0; i < defaultScoreCardTitle.length; i++) {
            keysscore.push(0)
        }
        setKeys(defaultScoreCardTitle)
        setKeysscore(keysscore)
        setLoaded(true)

    }, [])

    const add = (i) => {
        var new_keysscore = keysscore.slice()
        if (new_keysscore[i] === 3) {
            return
        }
        else {
            new_keysscore[i] = new_keysscore[i] + 1
            setKeysscore(new_keysscore)
        }
    }

    const subtract = (i) => {
        var new_keysscore = keysscore.slice()
        if (new_keysscore[i] === 0) {
            return
        }
        else {
            new_keysscore[i] = new_keysscore[i] - 1
            setKeysscore(new_keysscore)
        }
    }


    const search = () => {

        console.log('search')
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', touchAction: 'manipulation' }}>
            <Header {...props} usertype="Eventdiscovery" abbrev_userlocation={'SG'}>
                <MainMenu {...props} selectedMainCategory={'Discover'} usertype={'Event'} />
            </Header>
            <div style={{ display: 'flex', flexDirection: 'column', padding: 5, margin: 5, height: '30%', width: '100%', justifyContent: 'center', alignContent: 'center' }}> {/* border: 'thin solid rgb(230, 230, 230)',boxShadow: '0 1px 2px 0 rgba(0,0,0,.05)' */}
                {/* explaination */}
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', fontSize: 13, textAlign: 'center' }}>
                    {'Use our discovery searches to discover services and vendors you might want to engage.'}
                </div>
                {/* feature row */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: device === 'MOBILE' ? 'flex-start' : 'center', padding: 5, overflowY: 'hidden', overflowX: 'auto', touchAction: 'manipulation' }}>
                    {keys.map((item, i) => {
                        return (
                            <div key={item + i} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div style={featurecss}>
                                    <div>{item}</div>
                                    <div style={{ fontWeight: 500, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <div style={{ fontWeight: 'bold', fontSize: 25 }}>{keysscore[i]}</div>
                                        <div>{'  /  ' + 3}</div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <button style={{ display: 'flex', border: 'thin solid #707070', padding: '5px 10px', margin: 5, borderRadius: 5, outline: 'none', backgroundColor: 'white' }} onClick={() => { subtract(i) }}>-</button>
                                    <button style={{ display: 'flex', border: 'thin solid #707070', padding: '5px 10px', margin: 5, borderRadius: 5, outline: 'none', backgroundColor: 'white' }} onClick={() => { add(i) }}>+</button>
                                </div>
                            </div>
                        )
                    })}
                </div>

                {/* search btn */}
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', color: '#707070', justifyContent: 'center' }}>
                    <button
                        style={{ outline: 'none', display: 'flex', alignContent: 'center', border: 'thin solid #707070', padding: 5, margin: 5, width: 'max-content', borderRadius: 5, justifyContent: 'center', backgroundColor: 'white', fontSize: 'large' }}
                        onClick={search}
                    >
                        Search
                    </button>
                </div>
            </div>
            {loaded ?
                <div>

                </div>
                :
                <div />}

        </div>
    )



}

export default EventDiscovery