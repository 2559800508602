import React, { Component } from 'react'
import { USER_CHECK_VERIFIC } from '../../config/api';
import { EMPTY } from '../../config/constants';
import refresh from '../../components/refresh';
import axios from 'axios'

const USER_VERIFY_MSG = {
    "ERROR_INCORRECT_USER": "Incorrect User.",
    "ERROR_TOKEN_EXPIRED": "Token Expired.",
    "ERROR_ALRDY_VERIFIED": "User Already Verified.",
    "SUCCESS": "Successfully verified. You may now log in."
}

class UserVerify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            msg: EMPTY,
        }
    }

    componentDidMount = async () => {
        const { email, verifyingtoken } = this.props.match.params
        let options = {
            method: 'POST',
            url: USER_CHECK_VERIFIC,
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', },
            data: { email: email, web_verifyingtoken: verifyingtoken }
        }

        axios(options).then((result) => {
            const { data } = result // status
            const { message } = data // error
            this.setState({ msg: USER_VERIFY_MSG[message] })

        }).catch(e => {
            refresh()
        })
    }

    render() {
        const { msg } = this.state //verify
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', touchAction: 'manipulation', height: 'calc(100vh - 35px)', color:'var(--maindark)' }}>
              <h4>{msg}</h4>
            </div>
          );
    }
}

export default UserVerify