import React, { useState, useRef, useEffect } from 'react'
import { TRASH_ICON, DOWNLOAD_SMALL, a4ht, a4wt, guestemoji } from '../../../../../../../config/constants'
import { ReactP5Wrapper } from "react-p5-wrapper";
import html2canvas from 'html2canvas';
import './Rmsg.css'
import '../Rstyle.css'
import '../Common.css'

const usericon = <svg className="rwd_nameicon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2266"><path d="M772.016477 696.022177c-39.228443-39.229466-85.763292-68.49807-136.530536-86.546122 26.774807-13.283538 51.500954-30.976502 73.254398-52.729945 52.55189-52.550867 81.494059-122.422214 81.494059-196.74085s-28.941146-144.189983-81.494059-196.741873c-52.550867-52.550867-122.422214-81.493036-196.74085-81.493036s-144.189983 28.942169-196.741873 81.493036c-52.55189 52.550867-81.494059 122.422214-81.494059 196.741873s28.941146 144.189983 81.494059 196.74085c21.753443 21.753443 46.480614 39.446407 73.256444 52.729945-50.76929 18.049075-97.303116 47.316655-136.532583 86.546122-66.188468 66.187445-104.009865 153.166425-107.422591 246.208495l48.730864 0c3.387144-80.028685 36.140105-154.783249 93.129051-211.770148 55.771211-55.771211 128.557958-88.326675 206.650547-92.867084 6.27389 0.418532 12.582573 0.645706 18.929118 0.645706 6.345522 0 12.656251-0.227174 18.929118-0.645706 78.091566 4.54041 150.880359 37.095873 206.650547 92.867084 56.987922 56.986899 89.741907 131.741463 93.129051 211.770148l48.730864 0C876.027365 849.188602 838.204945 762.209622 772.016477 696.022177zM282.466792 360.004237c0-126.564557 102.96814-229.53372 229.53372-229.53372 126.564557 0 229.53372 102.969163 229.53372 229.53372 0 120.304993-93.040023 219.280192-210.942293 228.77545-6.170536-0.304945-12.369725-0.460488-18.591427-0.460488-6.222725 0-12.420891 0.155543-18.59245 0.460488C375.505791 579.284429 282.466792 480.30923 282.466792 360.004237z" p-id="2267"></path></svg>
const Messagecard = ({ index, ondel, msg, ondl, dlstate }) => {
    const { id, name, time, img, audio, txt, ff, emojiascii } = msg
    return <div className="rwd_msgroot" style={{ placeContent: 'space-between' }}>
        <div className="rwd_txt" style={{ fontFamily: ff }}>{txt}</div>
        <div>
            {img.length > 0
                ? <div className="rmsg_img" style={{ backgroundImage: `url(${img})` }} />
                : <div className="rmsg_img" style={{ backgroundImage: `url(${img})`, border: 'thin solid transparent' }} />}
            {audio && audio.length > 0
                ? <audio className="rwd_audioplayer" controls><source src={audio} type="audio/mpeg" /></audio>
                : <div className="rwd_audioplayer" />}
            {guestemoji
                ? <div className="rwd_emojiroot">
                    <span className="rwd_emojilabel" role="img" aria-label="wishes">✨Emoji Wishes✨</span>
                    <div className="rwd_emoji">{emojiascii}</div>
                </div>
                : null}
            <div className='rwd_nameroot' style={{ marginBottom: 5 }}>
                {usericon}
                <div className="rwd_name">{name}</div>
            </div>
            <div className="rwd_col">
                <div className="rwd_row">
                    <div className="rwd_index">{'# ' + parseInt(index + 1, 10)}</div>
                    <div className="rwd_time">{time}</div>
                </div>
                <div className="rwd_row" style={{marginTop:5}}>
                    <div className="rwd_time" style={{marginLeft:0}}>{txt.split(' ').length + ' words'}</div>
                    <div className="rwd_time">{txt.split('').length + ' letter count'}</div>
                </div>
            </div>
            <div className="rwd_menurow">
                {!dlstate
                    ? <button className="rwd_deleteroot" onClick={ondl}>
                        <div className="rwd_deleteicon">{DOWNLOAD_SMALL}</div>
                    </button>
                    : null}
                <button className="rwd_deleteroot" value={id} onClick={ondel}>
                    <div className="rwd_deleteicon">{TRASH_ICON}</div>
                </button>
            </div>
        </div>

    </div>
}

export default function Rmsg({ device, leftstate, msgs, ondel, msgimgs, msgaudios, msgquota }) {

    // download gallery single page
    const [dlstate, setDlstate] = useState(false)
    const [dldom, setDldom] = useState(null)
    const [imginfo, setImginfo] = useState({ orientation: null, imgwt: null, imght: null, imgindex: null, nonimg: null })
    const [nonimgindex, setNonimgindex] = useState(-1)
    const [imgindex, setImgindex] = useState(-1)
    const a4wthtref = useRef(null)

    // useEffect(() => { // trigger if is img
    //     if (imginfo['imgindex'] !== null && a4wthtref.current) {
    //         const { orientation, imgwt, imgindex } = imginfo
    //         const { img, ff, name, txt } = msgs[imgindex]
    //         if (img.length > 0) {
    //             setDldom(
    //                 <div id={'rmsgselecteddom'} key={'imgtodl' + imgindex} style={{ display: 'flex', position: 'relative', width: a4wt, minHeight: a4ht, maxHeight: a4ht, fontFamily: ff, backgroundColor: '#282535', placeContent: 'center' }}>
    //                     <div className="rwd_subcard" style={{ width: `calc(${a4wt} * 0.8)`, minHeight: `calc(${a4ht} * 0.8)`, maxHeight: `calc(${a4ht} * 0.8)`, placeSelf: 'center', backgroundColor: 'rgb(255, 249, 237)' }}>
    //                         <ReactP5Wrapper
    //                             sketch={(p5) => {
    //                                 const { clientHeight, clientWidth } = a4wthtref.current
    //                                 let size = 30
    //                                 p5.setup = () => {
    //                                     p5.colorMode(p5.HSB);
    //                                     p5.noStroke();
    //                                     p5.createCanvas(clientWidth, clientHeight)
    //                                     p5.smooth();
    //                                     p5.clear()
    //                                     p5.angleMode(p5.DEGREES)
    //                                     p5.noLoop()
    //                                 };
    //                                 p5.draw = () => {

    //                                     for (let x = 0; x < p5.width + size; x = x + size) {
    //                                         for (let y = 0; y <= p5.height + size; y = y + size) {
    //                                             p5.push()
    //                                             p5.fill('pink');
    //                                             p5.translate(x, y)
    //                                             p5.rectMode(p5.CENTER)
    //                                             p5.square(0, 0, size, 5);
    //                                             p5.pop()
    //                                         }
    //                                     }

    //                                     // var colorarr = [
    //                                     //     '#ffadad',
    //                                     //     '#ffd6a5',
    //                                     //     '#fdffb6',
    //                                     //     '#caffbf',
    //                                     //     '#9bf6ff',
    //                                     //     '#a0c4ff',
    //                                     //     '#bdb2ff',
    //                                     //     '#ffc6ff'
    //                                     // ]
    //                                     // for (let x = 0; x < p5.width + size; x = x + size) {
    //                                     //     for (let y = 0; y <= p5.height + size; y = y + size) {
    //                                     //         p5.push()
    //                                     //         p5.fill(p5.random(colorarr))
    //                                     //         p5.translate(x, y)
    //                                     //         p5.rotate(90)
    //                                     //         p5.rectMode(p5.CENTER)
    //                                     //         p5.square(0, 0, size, 5);
    //                                     //         p5.pop()
    //                                     //     }
    //                                     // }

    //                                 };
    //                             }} >
    //                             <div style={{ position: 'absolute', width: `calc(${a4wt} * 0.35)`, minHeight: `calc(${a4ht} * 0.7)`, maxHeight: `calc(${a4ht} * 0.7)`, backgroundColor: 'white', display: 'flex', flexDirection: 'column', borderTopLeftRadius: 20, borderTopRightRadius: 20, zIndex: 9999999, placeSelf: 'flex-end', color: 'black', boxShadow: '0 1px 1px rgb(0 0 0 / 15%)' }}>
    //                                 <div style={{ height: `calc(${a4ht} * 0.75 * 0.65 )`, width: '100%', backgroundColor: 'black', display: 'flex', placeContent: 'center', borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
    //                                     <img
    //                                         crossOrigin={'anonymous'}
    //                                         src={img + '?v=' + new Date().getTime()}
    //                                         alt=""
    //                                         style={
    //                                             orientation === 'landscape'
    //                                                 ? {
    //                                                     maxHeight: `calc( ( ( ${a4ht} * 0.75 * 0.65 ) * ${imgwt}px ) / ${a4wt} )`, // ((a4ht * 0.75 * 0.65) * imgwt) / a4wt
    //                                                     minWidth: `calc( ${a4wt} * 0.35 )`,
    //                                                     maxWidth: `calc( ${a4wt} * 0.35 )`,
    //                                                     width: `calc( ${a4wt} * 0.35 )`,
    //                                                     backgroundColor: 'black',
    //                                                     display: 'flex',
    //                                                     objectFit: 'contain',
    //                                                     alignSelf: 'center'
    //                                                 }
    //                                                 : orientation === 'portrait'
    //                                                     ? {
    //                                                         height: '100%',
    //                                                         backgroundColor: 'black',
    //                                                         objectFit: 'contain'
    //                                                     }
    //                                                     : orientation === 'even'
    //                                                         ? {
    //                                                             width: '100%',
    //                                                             height: '100%',
    //                                                             backgroundColor: 'black',
    //                                                             objectFit: 'contain',
    //                                                             margin: 20,
    //                                                             border: '5px solid black',
    //                                                             borderTop: 'none',
    //                                                             borderBottom: 'none',
    //                                                         }
    //                                                         : null
    //                                         } />
    //                                 </div>
    //                                 <div style={{ width: '100%', height: `calc( ${a4ht} * 0.75 * 0.05)`, padding: `calc( ${a4wt} * 0.01 ) calc(${a4wt} * 0.01 ) 0px calc(${a4wt} * 0.01 )`, fontSize: `calc( ${a4wt} * 0.018 )`, display: 'flex', alignItems: 'center' }}> {name}</div>
    //                                 <div style={{ width: '100%', height: `calc( ${a4ht} * 0.75 * 0.3)`, padding: `calc( ${a4wt} * 0.005 ) calc(${a4wt} * 0.01 ) 0px calc(${a4wt} * 0.01 )`, fontSize: `calc( ${a4wt} * 0.013 )` }}> {txt}</div>
    //                             </div>
    //                         </ReactP5Wrapper>
    //                     </div>
    //                 </div >
    //             )
    //         }
    //         setImgindex(imgindex)
    //     }
    // }, [imgindex])

    useEffect(() => { 
        if (imgindex > -1) {
            const { ff, name, txt, img } = msgs[imgindex] // emojiascii
            setDldom(
                <div
                    id={'rmsgselecteddom'}
                    key={'imgtodl' + imgindex}
                    style={{
                        fontFamily: ff,
                        width: 320,
                        backgroundColor: 'white'
                    }}
                >
                    {/* <div key={'img' + imgindex} className="grwd_imgroot" style={{ backgroundImage: `url(${img})` }} /> */}
                    <div style={{ width: 320, height: 320, backgroundColor: 'black', backgroundImage: `url(${img})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize:'contain' }} />
                    <div style={{ padding: 10 }}>
                        <div style={{ marginBottom: 10 }}>{name}</div>
                        <div>{txt}</div>
                    </div>
                </div>
            )
        }
    }, [imgindex])

    useEffect(() => { 
        if (nonimgindex > -1) {
            const { ff, name, txt } = msgs[nonimgindex] // emojiascii
            setDldom(
                <div
                    id={'rmsgselecteddom'}
                    style={{
                        fontFamily: ff,
                        width: 320,
                        border: '1px solid #333333',
                        backgroundColor: 'white'
                    }}
                >
                    <div style={{ padding: 10 }}>
                        <div style={{ marginBottom: 10 }}>{name}</div>
                        <div>{txt}</div>
                    </div>
                </div>
            )
            // setDldom(
            //     <div key={'imgtodl' + nonimgindex} id={'imgtodl' + nonimgindex} style={{ display: 'flex', position: 'relative', width: a4wt, minHeight: a4ht, maxHeight: a4ht, fontFamily: ff, backgroundColor: '#282535', placeContent: 'center' }}>
            //         <div className="rwd_subcard" style={{ width: `calc(${a4wt} * 0.8)`, minHeight: `calc(${a4ht} * 0.8)`, maxHeight: `calc(${a4ht} * 0.8)`, placeSelf: 'center', backgroundColor: 'rgb(255, 249, 237)' }}>
            //             <ReactP5Wrapper sketch={(p5) => {
            //                 const { clientHeight, clientWidth } = a4wthtref.current
            //                 let size = 30
            //                 p5.setup = () => {
            //                     p5.colorMode(p5.HSB);
            //                     p5.noStroke();
            //                     p5.createCanvas(clientWidth, clientHeight)
            //                     p5.smooth();
            //                     p5.clear()
            //                     p5.angleMode(p5.DEGREES)
            //                     p5.noLoop()
            //                 };
            //                 p5.draw = () => {
            //                     for (let x = 0; x < p5.width + size; x = x + size) {
            //                         for (let y = 0; y <= p5.height + size; y = y + size) {
            //                             p5.push()
            //                             p5.fill('pink');
            //                             p5.translate(x, y)
            //                             p5.rectMode(p5.CENTER)
            //                             p5.square(0, 0, size, 5);
            //                             p5.pop()
            //                         }
            //                     }
            //                 };
            //             }} >
            //                 <div style={{ position: 'absolute', width: `calc(${a4wt} * 0.35)`, minHeight: `calc(${a4ht} * 0.7)`, maxHeight: `calc(${a4ht} * 0.7)`, backgroundColor: 'white', display: 'flex', flexDirection: 'column', borderTopLeftRadius: 20, borderTopRightRadius: 20, zIndex: 9999999, placeSelf: 'flex-end', color: 'black', boxShadow: '0 1px 1px rgb(0 0 0 / 15%)' }}>
            //                     <div style={{ width: '100%', height: `calc( ${a4ht} * 0.75 * 0.05)`, padding: `calc( ${a4wt} * 0.01 ) calc(${a4wt} * 0.01 ) 0px calc(${a4wt} * 0.01 )`, fontSize: `calc( ${a4wt} * 0.018 )`, display: 'flex', alignItems: 'center' }}> {name}</div>
            //                     <div style={{ width: '100%', height: `calc( ${a4ht} * 0.75 * 0.3)`, padding: `calc( ${a4wt} * 0.005 ) calc(${a4wt} * 0.01 ) 0px calc(${a4wt} * 0.01 )`, fontSize: `calc( ${a4wt} * 0.013 )` }}> {txt + ' / ' + emojiascii}</div>
            //                 </div>
            //             </ReactP5Wrapper>
            //         </div>
            //     </div >
            // )
        }
    }, [nonimgindex])

    useEffect(() => {
        if (dldom) {
            html2canvas(document.getElementById('rmsgselecteddom'), {
                scale: '5',
                allowTaint: false,
                useCORS: true,
                logging: true,
                backgroundColor: 'black',
                scrollX: 0,
                scrollY: -window.scrollY,
                imageTimeout: 55000,
            }).then((canvas) => {// download happens here
                const fileName = imgindex + 1;
                const link = document.createElement("a");
                link.download = fileName + ".png";
                document.body.appendChild(link);
                canvas.toBlob(function (blob) {
                    link.href = window.URL.createObjectURL(blob);
                    link.click();
                });
                setImginfo({ orientation: null, imgwt: null, imght: null, imgindex: null }) //  reset img info
                document.body.removeChild(link);
                setDlstate(false)
                setImgindex(-1)
                setNonimgindex(-1)
            }).catch(e => { })
        }
    }, [dldom])

    const ondl = (i) => {
        if (msgs.length > 0) {
            setDlstate(true)
            if (msgs[i]['img'].length > 0) { // is image
                var orientation
                var img = new Image()
                img.src = msgs[i]['img'];
                img.onload = () => {
                    if (img.naturalWidth > img.naturalHeight) {
                        orientation = 'landscape';
                    }
                    else if (img.naturalWidth < img.naturalHeight) {
                        orientation = 'portrait';
                    }
                    else {
                        orientation = 'even';
                    }
                    setImginfo({ orientation: orientation, imgwt: img.naturalWidth, imght: img.naturalHeight, imgindex: i })
                    setImgindex(i)
                }
            }
            else { // only text
                setNonimgindex(parseInt(i))
            }
        }
    }

    return device === 'MOBILE'
        ? <div className="gbrwd_content_m">
            <div ref={a4wthtref} className="rwd_subcard" style={{ width: `calc(${a4wt} * 0.8)`, minHeight: `calc(${a4ht} * 0.8)`, top: `calc(${a4ht} * 0.8)`, position: 'absolute', backgroundColor: '#fff9ed', display: 'flex', placeContent: 'center', alignItems: 'end', }} />
            {msgs.map((msg, index) => {
                const { id, name, time, img, audio, txt, ff } = msg
                return <div className="gbrwd_msgrootm" key={'msgstrip' + index}>
                    <div className="gbrwd_msgtxtm" style={{ fontFamily: ff }}>{txt}</div>

                    {img.length > 0
                        ? <div className="rmsg_img" style={{ backgroundImage: `url(${img})` }} />
                        : <div className="rmsg_img" style={{ backgroundImage: `url(${img})`, border: '1px solid transparent' }} />}
                    {audio && audio.length > 0 ? <audio className="rwd_audioplayer" controls><source src={audio} type="audio/mpeg" /></audio> : <div className="rwd_audioplayer" />}
                    <div className="gbrwd_msgnamem">{name}</div>
                    <div className="gbrwd_msgrowm">
                        <div className="rwd_row">
                            <div className="rwd_index">{'# ' + parseInt(index + 1, 10)}</div>
                            <div className="rwd_time">{time}</div>
                            <div className="rwd_time">{txt.split(' ').length + ' w'}</div>
                            <div className="rwd_time">{txt.split('').length + ' c'}</div>
                        </div>
                    </div>
                    <div className='rwd_menurow'>
                        <button className="rwd_deleteroot" value={id} onClick={ondel}>
                            <div className="rwd_deleteicon">{TRASH_ICON}</div>
                        </button>
                    </div>
                </div>
            })}
        </div>
        : <div className={"gbrwd_root" + device}>
            <div ref={a4wthtref} className="rwd_subcard" style={{ width: `calc(${a4wt} * 0.8)`, minHeight: `calc(${a4ht} * 0.8)`, top: `calc(${a4ht} * -0.8)`, position: 'absolute', backgroundColor: '#fff9ed', display: 'flex', placeContent: 'center', alignItems: 'end' }} />
            <div className="gbrwd_msgroot">
                {leftstate === 0
                    ? <div className="gbrwd_msgcol">
                        <div className="gbrwd_numbers">
                            <div className="gbrwd_numberitem">
                                <div>{'Total'}</div>
                                <div className="gbrwd_digit">{msgs.length + ' / ' + msgquota} </div>
                            </div>
                        </div>
                        <div className="gbrwd_msggrid">
                            {msgs.map((msg, i) => {
                                return <Messagecard
                                    key={i + 'msg'}
                                    index={i}
                                    msg={msg}
                                    ondel={ondel}
                                    ondl={() => {
                                        const index = i
                                        ondl(index)
                                    }}
                                    dlstate={dlstate}
                                />
                            })}
                        </div>
                    </div>
                    : null}
                {leftstate === 1
                    ? <div className="gbrwd_msgcol">
                        <div className="gbrwd_numbers">
                            <div className="gbrwd_numberitem">
                                <div>{'Images'}</div>
                                <div className="gbrwd_digit">{msgimgs.length} </div>
                            </div>
                        </div>
                        <div className="gbrwd_imggrid">
                            {msgs.map(({ img }, i) => {
                                if (img.length > 0) return <div key={'img' + i} className="grwd_imgroot" style={{ backgroundImage: `url(${img})` }} />
                                else return null
                            })}
                        </div>
                    </div>
                    : null}
                {leftstate === 2
                    ? <div className="gbrwd_msgcol" style={{ width: '100%' }}>
                        <div className="gbrwd_numbers">
                            <div className="gbrwd_numberitem">
                                <div>{'Audios'}</div>
                                <div className="gbrwd_digit">{msgaudios.length} </div>
                            </div>
                        </div>
                        <div className="gbrwd_msggrid">
                            {msgs.map(({ audio }, i) => {
                                return audio && audio.length > 0
                                    ? <div className="rwd_msgroot" key={'aud' + i}>
                                        <audio className="rwd_audioplayer" controls><source src={audio} type="audio/mpeg" /></audio>
                                    </div>
                                    : null
                            })}
                        </div>
                    </div>
                    : null}
                {leftstate === 3
                    ? <div className="gbrwd_msgcol" style={{ width: '100%' }}>
                        <button
                            onClick={() => {
                                const node = document.getElementById('rmsgdownloadable');
                                if (node) {

                                    // html2canvas(
                                    //     node
                                    // ).then((canvas,) => {
                                    //     const dataURL = canvas.toDataURL('image/png');
                                    //     // Create a link element
                                    //     const link = document.createElement('a');
                                    //     link.href = dataURL;
                                    //     link.download = 'screenshot.png'; // Set the desired file name
                                    //     // Trigger a click event to start the download
                                    //     document.body.appendChild(link);
                                    //     link.click();
                                    //     document.body.removeChild(link);
                                    // });

                                    // html2canvas(node).then((canvas) => {// download happens here
                                    //     const link = document.createElement("a");
                                    //     link.download = "screenshot.png";
                                    //     document.body.appendChild(link);
                                    //     canvas.toBlob(function (blob) {
                                    //         link.href = window.URL.createObjectURL(blob);
                                    //         link.click();
                                    //     });
                                    //     document.body.removeChild(link);
                                    // }).catch(e => { })

                                    // will have error, split into many even segment to download all 

                                    html2canvas(document.getElementById('rmsgdownloadable'), {
                                        allowTaint: false,
                                        useCORS: true,
                                        logging: true,
                                        scrollX: 0,
                                        scrollY: -window.scrollY,
                                    }).then((canvas) => {// download happens here
                                        const fileName = imgindex + 1;
                                        const link = document.createElement("a");
                                        link.download = fileName + ".jpg";
                                        document.body.appendChild(link);
                                        canvas.toBlob(function (blob) {
                                            link.href = window.URL.createObjectURL(blob);
                                            link.click();
                                        });
                                        document.body.removeChild(link);
                                    }).catch(e => {
                                        console.log('e', e)
                                    })

                                }
                            }}
                        >
                            Download
                        </button>
                        <div id="rmsgdownloadable" style={{ height: 'max-content' }}>
                            {[...msgs, ...msgs, ...msgs, ...msgs, ...msgs, ...msgs, ...msgs, ...msgs, ...msgs, ...msgs, ...msgs, ...msgs, ...msgs, ...msgs, ...msgs, ...msgs].map((item, index) => {
                                const { txt, ff, name, img } = item // img
                                return <div key={index + 'msg' + Math.random()} id='rmsgcard' style={{ fontFamily: ff }}>
                                    {img.length > 0
                                        ? <div className="rmsg_img" style={{ backgroundImage: `url(${img})` }} />
                                        // ? <div  className="grwd_imgroot" style={{ backgroundImage: `url(${img})` }} />
                                        : null}

                                    <div style={{ marginBottom: 10 }}>{name}</div>
                                    <div>{txt}</div>
                                </div>
                            })}
                        </div>
                    </div>
                    : null}
                <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', placeContent: 'center', top: `calc(${a4ht} * -1000)` }}>
                    {dldom}
                </div>
                {/* <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', top: '50%' }}>{dldom}</div> */}
            </div>
        </div>
}