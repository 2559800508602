import React from 'react'
import { Checkdevice, ScreenWidth } from '../../../../components'
import { iconwrapper } from '../../../../config/commonfunctions'
import { HOME_SMALL, WEDDING_SMALL, PRICELIST_SMALL, PROMOTION_SMALL, PROJECTEVENT_SMALL, PROJECTWED_SMALL, TOOLS_SMALL, SHOP_SMALL, PARTY_SMALL, promotion, marketbool } from '../../../../config/constants'
import { MAIN_HOME_ICON, MAIN_VENDOR_ICON, MAIN_PRICELIST_ICON, MAIN_GALLERY_ICON, MAIN_APPS_ICON, MAIN_MARKET_ICON } from '../../../../config/svgicons'
import './MainMenu.css'

const MAIN_MENU_SEPARATOR = <div style={{ backgroundColor: 'silver', width: 1, opacity: 0.5 }} />//backgroundColor: 'rgba(25, 25, 25, 0.05)', 

// var MAIN_MENU_ITEMS = {
//     'Wedding': [
//         { val: 'Home', icon: HOME_SMALL, dir: '/' },
//         { val: 'Wedding', icon: WEDDING_SMALL, dir: '/wedding/all' },
//         { val: 'Pricelist', icon: PRICELIST_SMALL, dir: '/pricelist' },
//         { val: 'Project', icon: PROJECTWED_SMALL, dir: '/projectgallery' },
//         { val: 'Tools', icon: TOOLS_SMALL, dir: '/tools' },
//         { val: 'Market', icon: SHOP_SMALL, dir: '/market' },
//     ],
//     'Event': [
//         { val: 'Home', icon: HOME_SMALL, dir: '/' },
//         { val: 'Event', icon: PARTY_SMALL, dir: '/event/all' },
//         { val: 'Pricelist', icon: PRICELIST_SMALL, dir: '/pricelist' },
//         { val: 'Project', icon: PROJECTEVENT_SMALL, dir: '/projectgallery' },
//     ],
//     'Guest': []
// }

const iconstyle = { width: 26, height: 26 }
var MAIN_MENU_ITEMS = marketbool
    ? {
        'Wedding': [
            { val: 'Home', icon: iconwrapper(MAIN_HOME_ICON, iconstyle), dir: '/' },
            { val: 'Wedding', icon: iconwrapper(MAIN_VENDOR_ICON, iconstyle), dir: '/wedding/all' },
            { val: 'Pricelist', icon: iconwrapper(MAIN_PRICELIST_ICON, iconstyle), dir: '/pricelist' },
            { val: 'Project', icon: iconwrapper(MAIN_GALLERY_ICON, iconstyle), dir: '/projectgallery' },
            { val: 'Tools', icon: iconwrapper(MAIN_APPS_ICON, iconstyle), dir: '/tools' },
            { val: 'Market', icon: iconwrapper(MAIN_MARKET_ICON, iconstyle), dir: '/market' }
        ],
        'Event': [
            { val: 'Home', icon: HOME_SMALL, dir: '/' },
            { val: 'Event', icon: PARTY_SMALL, dir: '/event/all' },
            { val: 'Pricelist', icon: PRICELIST_SMALL, dir: '/pricelist' },
            { val: 'Project', icon: PROJECTEVENT_SMALL, dir: '/projectgallery' },
        ],
        'Guest': []
    }
    : {
        'Wedding': [
            { val: 'Home', icon: iconwrapper(MAIN_HOME_ICON, iconstyle), dir: '/' },
            { val: 'Wedding', icon: iconwrapper(MAIN_VENDOR_ICON, iconstyle), dir: '/wedding/all' },
            { val: 'Pricelist', icon: iconwrapper(MAIN_PRICELIST_ICON, iconstyle), dir: '/pricelist' },
            { val: 'Project', icon: iconwrapper(MAIN_GALLERY_ICON, iconstyle), dir: '/projectgallery' },
            { val: 'Tools', icon: iconwrapper(MAIN_APPS_ICON, iconstyle), dir: '/tools' },
        ],
        'Event': [
            { val: 'Home', icon: HOME_SMALL, dir: '/' },
            { val: 'Event', icon: PARTY_SMALL, dir: '/event/all' },
            { val: 'Pricelist', icon: PRICELIST_SMALL, dir: '/pricelist' },
            { val: 'Project', icon: PROJECTEVENT_SMALL, dir: '/projectgallery' },
        ],
        'Guest': []
    }



var RAWVALUE_RENDERVALUE_MAP = {
    'Home': 'Home',
    'Vendor': 'Vendor',
    'Pricelist': 'Pricelist',
    'Project': 'Gallery',
    'Tools': 'Tools',
    'Market': 'Market',
    'Event': 'Vendor',
    'Wedding': 'Vendor'
}

if (promotion) {
    RAWVALUE_RENDERVALUE_MAP['Promo'] = 'Promo'
    MAIN_MENU_ITEMS['Wedding'].push({ val: 'Promo', icon: PROMOTION_SMALL, dir: '/promo' })
    MAIN_MENU_ITEMS['Event'].push({ val: 'Promo', icon: PROMOTION_SMALL, dir: '/promo' })
}

function MainMenu(props) {
    const { selectedMainCategory, usertype } = props
    const wt = ScreenWidth()
    const device = Checkdevice()
    if (device === 'WEB' || device === 'TABLET') {
        return (
            <div className={"mm_rootWEB"}>
                {usertype
                    ? MAIN_MENU_ITEMS[usertype]
                        .map(({ val, dir, icon }, i) => (
                            <div key={val + i} style={{ display: 'flex', flexDirection: 'row' }} >
                                <div className={selectedMainCategory === val ? 'mm_wt_selected' : 'mm_wt'} onClick={() => { props.history.push(dir) }}>
                                    {icon}
                                    <div style={{ marginTop: 5, }}>
                                        {RAWVALUE_RENDERVALUE_MAP[val]}
                                    </div>
                                </div>
                                {MAIN_MENU_ITEMS[usertype].length === i + 1
                                    ? null
                                    : MAIN_MENU_SEPARATOR}
                            </div>
                        ))
                    : null}
            </div>
        )
    }
    else { // mobile
        return (
            <div className={"mm_root" + device}>
                {usertype
                    ? MAIN_MENU_ITEMS[usertype].map(({ val, dir, icon }, i) => (
                        <div
                            key={val + i}
                            className={selectedMainCategory === val ? 'mm_m_selected' : 'mm_m'}
                            style={{
                                minWidth: (parseInt(wt / Object.keys(MAIN_MENU_ITEMS[usertype]).length) > 130)
                                    ? (parseInt(wt / (Object.keys(MAIN_MENU_ITEMS[usertype]).length + 1)))
                                    : (parseInt(wt / Object.keys(MAIN_MENU_ITEMS[usertype]).length)),
                                margin: '5px 0px'

                            }}

                            onClick={() => { props.history.push(dir) }}
                        >
                            {icon}
                            <div style={{ marginTop: 5, fontWeight: 'normal' }}>
                                {val === 'Wedding' || val === 'Event' ? 'Vendor' : RAWVALUE_RENDERVALUE_MAP[val]}
                            </div>
                        </div>
                    ))
                    : null}
            </div>
        )
    }
}

export default MainMenu
