import React, { useState, useEffect } from 'react';
import { Checkdevice, Loading, Portalmodal, Processmoney } from '../../components';
import { USER_READ_SINGLEPL, USER_READ_SINGLEPLNONUSER } from '../../config/api';
import { getS3fullpath_withbucketname, iconwrapper, Retrieve_personal_info } from '../../config/commonfunctions';
import { IMG_ALT, DEVICE_DIM_MAP, ARROWLEFT_ICON, ARROWRIGHT_SMALL, ARROWLEFT_SMALL, READABLE_PREACTUAL_MAP, READABLE_VENDORTYPE_MAP, PRESENCELOC_CAPABBREV_MAP, IMG_SMALL } from '../../config/constants';
import axios from 'axios'
import Preloadimages from '../../components/Preloadimages';
import './Pricelistlookup.css'
import { DOTDOTDOT_ICON, DOLLAR_ICON, LOCATION_ICON, DURATION_ICON, DELIEVERABLES_ICON, PLUS_ICON } from '../../config/svgicons';
import Enquirycontent from '../../components/Enquirycontent/Enquirycontent';
import Notfound from '../Notfound/Notfound';

const { token, _id, acct_type } = Retrieve_personal_info()

function Paperpricelistimg(props) { // eslint-disable-line

    const { wt, ht, gap = false } = props

    if (gap) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: wt, height: ht, justifyContent: 'space-between', alignContent: 'center', boxShadow: '0 10px 50px 0 rgba(0, 0, 0, 0.15)', backgroundColor: 'transparent' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '49.5%', height: '100%', border: '1px solid var(--maindark)', backgroundColor: 'white' }}> {/* sq 1 */}

                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '49.5%', height: '100%', justifyContent: 'space-between', backgroundColor: 'transparent' }}>
                    <div style={{ width: '100%', height: '49%', border: '1px solid var(--maindark)', backgroundColor: 'white' }}> {/* sq 2 */}

                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '49%', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '48.5%', height: '100%', border: '1px solid var(--maindark)', backgroundColor: 'white' }}> {/* sq 3 */}

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '48.5%', height: '100%', border: '1px solid var(--maindark)', backgroundColor: 'white' }}> {/* sq 4 */}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: wt, height: ht, justifyContent: 'center', alignContent: 'center', boxShadow: '0 10px 50px 0 rgba(0, 0, 0, 0.15)' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '50%', height: '100%', border: '1px solid var(--maindark)', borderRight: '' }}>

                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '50%', height: '100%', }}>
                    <div style={{ width: '100%', height: '50%', border: '1px solid var(--maindark)', borderBottom: '' }}>

                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', border: '1px solid var(--maindark)', borderLeft: '', borderRight: '' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', height: '100%', borderRight: '1px solid var(--maindark)', borderLeft: '1px solid var(--maindark)' }}>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', height: '100%', borderRight: '1px solid var(--maindark)' }}>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

function commontitle(title, icon) {
    return <div className='pllook_commontitleroot'>
        {icon}
        <div className='pllook_titletext'>{title}</div>
    </div>
}

function bullets(val, index, fs) {
    // return <li key={index} className='pllook_bullet'>{val}</li>
    return <div key={index} className='pllook_bullet'>{'• ' + val}</div>
}

function Pricelistlookup(props) {

    const device = Checkdevice()
    const paperwt = DEVICE_DIM_MAP[device].wt
    const paperht = DEVICE_DIM_MAP[device].ht
    const commontext_size = device === 'MOBILE' ? 13 : 14
    const head_css = { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white', paddingTop: 10 }
    const imghead_css = { width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', }
    const [firsttime, setFirsttime] = useState(true)
    const [data, setData] = useState({})
    const [user, setUser] = useState(null)
    const [v_whatsapp_link, setV_whatsapp_link] = useState('')
    const [v_telegram_link, setV_telegram_link] = useState('')
    const [imgscreen, setImgscreen] = useState(false) // "Show All" clicked
    const [imgselectedindex, setImgselectedindex] = useState(0) // for imgscreen
    const [pltype, setPltype] = useState(-1)  // price list type, 0: vendor fill up data from weavebliss, 1: redirect to vendor pricelist
    const [downloadablelink, setDownloadablelink] = useState('')
    const [redirectlink, setRedirectlink] = useState('')
    const [notfound, setNotfound] = useState(false)

    const [keycode, setKeycode] = useState(-1)

    // ---------- Enquiry Portal related data END

    const mobileportalcss = { width: '100%', height: '100%', backgroundColor: 'white', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)', overflowY: 'auto' }
    const webtabletportalcss = { width: 600, height: 650, backgroundColor: 'white', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)' }

    // show enquire portal
    const [showenquire, setShowenquire] = useState(false)

    // for both single enquire and all selected enquire
    const [enquiredata, setEnquiredata] = useState([])

    // login user data
    const [loggeduserdata, setLoggeduserdata] = useState({
        'name': '',
        'spousename': '',
        'date_w': '',
        'date_e': '',
        'email': '',
        'contact': '',
        'usertype': '', // wedding, event or guest
        'location': ''
    })

    // ---------- Enquiry Portal related data END


    useEffect(() => {
        if (firsttime) {
            const { pricelist_id } = props.match.params

            var api
            var senddata
            if (acct_type === 'USER') {
                api = USER_READ_SINGLEPL
                senddata = { plid: pricelist_id, }
            }
            else {
                api = USER_READ_SINGLEPLNONUSER
                senddata = { plid: pricelist_id, _id }
            }

            try {
                axios({
                    method: 'POST',
                    url: api,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: senddata
                }).then((response) => {
                    if (response.status === 200) {
                        const { message, singlepldata, user, v_whatsapp_link, v_telegram_link } = response.data
                        if (Object.keys(singlepldata).length === 0) {
                            setFirsttime(false)
                            setNotfound(true)
                        }
                        else {
                            const { mainimg, subimgs, pltype, downloadablelink, redirectlink } = singlepldata
                            if (message === 'SUCCESS') {
                                setData(singlepldata)
                                setV_whatsapp_link(v_whatsapp_link)
                                setV_telegram_link(v_telegram_link)
                                if (pltype === 1) { setDownloadablelink(downloadablelink); setRedirectlink(redirectlink); }
                                Preloadimages([mainimg, ...subimgs], () => { setFirsttime(false) })
                                setPltype(pltype)
                                setFirsttime(false)
                                setNotfound(false)
                                if (acct_type === 'USER') { setLoggeduserdata(user); setUser(user); }
                            }
                            else window.location = '/'
                        }

                    }
                    else window.location = '/'
                })
            }
            catch (e) {
                window.location = '/'
            }
        }
    }, [props])

    useEffect(() => {
        if (imgscreen) { // if img screen is activate den can click
            const keyFunc = (event) => setKeycode(event.keyCode)// listener trigger setstate for keycode, keycode in turn trigger useeffect, then trigger left or right
            window.addEventListener('keydown', keyFunc);
            return () => window.removeEventListener('keydown', keyFunc);
        }
    }, [imgscreen]);

    useEffect(() => {
        if (keycode > -1) {
            if (keycode === 39) { // key right
                rightclick()
            }
            else if (keycode === 37) { // key left
                leftclick()
            }
            else if (keycode === 27) { // key esc
                exitclick()
            }
            setKeycode(-1)
        }
    }, [keycode])

    const leftclick = () => {
        const { subimgs = [] } = data
        imgselectedindex > 0 ? setImgselectedindex(imgselectedindex - 1) : setImgselectedindex(subimgs.length - 1)
    }

    const rightclick = () => {
        const { subimgs = [] } = data
        imgselectedindex < subimgs.length - 1 ? setImgselectedindex(imgselectedindex + 1) : setImgselectedindex(0)
    }

    const exitclick = () => {
        setImgscreen(false);
        setImgselectedindex(0);
        setKeycode(-1)
        props.hidetopbar(false);
    }

    const nameDom = (name, wt) => <div className="pllook_name" style={{ width: wt, minHeight: 60, fontSize: device === 'MOBILE' ? 28 : 32, }}>{name}</div>

    const imgDom = (mainimg, subimgs, wt, ht) => {
        const iconcss = { width: 15, height: 15, margin: '8px 10px 10px 0px', display: 'flex', placeContent: 'center', alignItems: 'center' }
        return mainimg && mainimg.length > 0 ?
            <div style={{ display: 'flex', width: 'max-content', position: 'relative', maxWidth: '-webkit-fill-available' }}>
                <img alt={''} src={getS3fullpath_withbucketname('VENDOR_PRICELIST_IMG', mainimg)} style={{ minWidth: wt, maxWidth: wt, maxHeight: 400, display: 'block', objectFit: 'cover', height: 'auto' }} />
                {subimgs && subimgs.length !== 0
                    ? <button className={'pllook_show'} onClick={() => { setImgscreen(true); setImgselectedindex(0); props.hidetopbar(true); }}>
                        {/* {iconwrapper(DOTDOTDOT_ICON, iconcss)} */}
                        {iconwrapper(IMG_SMALL, iconcss)}
                        View All
                    </button>
                    : null}  {/*only hero image, dont show btn */}
            </div >
            : null
    }

    const vidDom = (vid, wt) => {
        return vid && vid.length > 0 && vid[0] && vid[0].length > 0
            ? <div style={{ display: 'flex', width: 'max-content', position: 'relative', maxWidth: '-webkit-fill-available', paddingTop: 10 }}>
                <iframe
                    // src={vid[0]}
                    src={getS3fullpath_withbucketname('VENDOR_PRICELIST_IMG', vid[0])}
                    frameBorder='0'
                    allow='autoplay; encrypted-media'
                    allowFullScreen
                    title='video'
                    style={{ minWidth: wt, maxWidth: wt, minHeight: device === 'MOBILE' ? 375 : 400, }}
                />
            </div>
            : null
    }


    const indicatorDom = (wedtype, eventorwed, location, wt, tags) => {
        const fs = device === 'MOBILE' ? 16 : 18
        const indcss = { width: wt, fontSize: fs, color: 'var(--maindark)', padding: '10px 0px 0px 0px', display: 'flex', placeContent: 'center' }
        const indcontentcss = { display: 'flex', alignSelf: 'flex-start', width: '100%' }
        if (eventorwed === 'Wedding') {
            return <div style={indcss}>
                <div style={indcontentcss}>{PRESENCELOC_CAPABBREV_MAP[location] + ', ' + READABLE_PREACTUAL_MAP[wedtype] + (tags.includes('Solemnization') ? ', Solemnization' : '')}</div>
            </div>
        }
        else if (eventorwed === 'Event') {
            return <div style={indcss}>
                <div style={indcontentcss}>{PRESENCELOC_CAPABBREV_MAP[location] + ', ' + READABLE_PREACTUAL_MAP[wedtype]}</div>
            </div>
        }
        else if (eventorwed === 'Wedding & Event' || eventorwed === 'Both') {
            return <div style={indcss}>
                <div style={indcontentcss}>{PRESENCELOC_CAPABBREV_MAP[location] + ', ' + READABLE_PREACTUAL_MAP[wedtype] + (tags.includes('Solemnization') ? ', Solemnization' : '')}</div>
            </div>
        }

    }

    const tagDom = (curr_vts = [], cat, wt) => {
        const fs = 12
        var tags
        if (curr_vts.length > 1) tags = [cat, READABLE_VENDORTYPE_MAP[curr_vts[0]], READABLE_VENDORTYPE_MAP[curr_vts[1]]]
        else tags = [cat, READABLE_VENDORTYPE_MAP[curr_vts[0]]]

        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: wt, justifyContent: 'flex-start', fontSize: fs, padding: '10px 0px 0px 0px', flexWrap: 'wrap' }}>
                {tags.length > 0 && tags.map((val, i) => {
                    return <div className='pllook_tag' key={i + 'tag'}>
                        <div>{val && val.length > 0 ? val.toString().toUpperCase() : null}</div>
                    </div>
                })}
                <div className='pllook_tag' key={'VENDORTAG'}>
                    <div>{'VENDOR'}</div>
                </div>
            </div>
        )
    }


    // ---------- Enquiry Portal related data END
    const enquire_click = () => {
        const { displayname, vti_id } = data
        setShowenquire(true)
        setEnquiredata([{ displayname, vendortypeinterface_id: vti_id, telegram_link: v_telegram_link, whatsapp_link: v_whatsapp_link, }])
    }


    // const enquire_btn = <button className={'rrequests_btn rrequest_Vendorlookup rrequest_Vendorlookup_'+device} onClick={enquire_click}>Enquire</button>
    const enquire_btn = <button className={`Vendorlookup_enquirebtn Vendorlookup_enquirebtn${device}`} onClick={enquire_click}>Enquire</button>
    const enquiry_portal = <Portalmodal

        portaltype={'commonportal'}
        open={showenquire}
        onClose={() => { setShowenquire(false); setEnquiredata([]) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={{ 'WEB': webtabletportalcss, 'TABLET': webtabletportalcss, 'MOBILE': mobileportalcss }[device]}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <Enquirycontent
            from="Pricelistlookup"
            userdata={loggeduserdata}
            data={enquiredata}
            allowchat={true}
            allowtelegram={true}
            allowwhatsapp={true}
            allowemail={true}
            extradata={data.name}
            oneormany={'ONE'} // send to only one vendor
            vt={data.vendortype}
            svt={data.secondaryvendortype}
        />
    </Portalmodal>
    // ---------- Enquiry Portal related data END

    const vdetailDom = (profileimg_id = {}, displayname, username, vendortype, secondaryvendortype, wt, user, acct_type, name, vti_id) => {
        const { link } = profileimg_id
        const fs_displayname = device === 'MOBILE' ? 16 : 22
        const fs_vt = device === 'MOBILE' ? 13 : 14
        const vtstyle = { fontSize: fs_vt, display: 'flex', alignContent: 'flex-start', paddingLeft: 10, textAlign: 'left' }
        const displaynamestyle = { fontSize: fs_displayname, display: 'flex', paddingLeft: 10, textAlign: 'left', }

        return (
            <div className={`VL_btmbar VL_btmbar${device}`}>
                <div style={{ width: wt, display: 'flex', flexDirection: 'row', alignSelf: 'center', alignItems: 'center' }}>
                    {/* <Link to={'/vendorlookup/' + username} id='vlookupclick' style={{ width: 0, height: 0, visibility: 'hidden', position: 'absolute' }} /> */}
                    <a id='vlookupclick' href={'/vendorlookup/' + username} target={"_blank"} style={{ width: 0, height: 0, visibility: 'hidden', position: 'absolute' }} />
                    <button style={{ outline: 'none', backgroundColor: 'white', width: '100%', border: 'none', padding: 0, margin: 0 }} onClick={() => { document.getElementById('vlookupclick').click() }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'var(--maindark)' }} >
                            <div style={{ width: 45, height: 45 }}>
                                <img
                                    alt={IMG_ALT}
                                    // src={link}
                                    src={getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', link)}
                                    style={{ width: '99.5%', height: '99.5%', borderRadius: '50%', justifyContent: 'center', display: 'flex', alignItems: 'center', border: '1px solid var(--subgrey)' }}
                                />
                            </div>
                            <div style={{ width: '80%', display: 'flex', flexDirection: 'column', color: 'var(--maindark)', }}>
                                <div style={displaynamestyle}>{displayname}</div>
                                {secondaryvendortype === '' || secondaryvendortype === null || secondaryvendortype === undefined
                                    ? <div style={vtstyle}>{READABLE_VENDORTYPE_MAP[vendortype]}</div>
                                    : <div style={vtstyle}>{READABLE_VENDORTYPE_MAP[vendortype] + ' / ' + READABLE_VENDORTYPE_MAP[secondaryvendortype]}</div>}
                            </div>
                        </div>
                    </button>
                    {enquire_btn}
                    {/* {enquireDom(user, acct_type, name, vendortype, vti_id, wt, displayname)} */}
                </div>
            </div>

        )
    }

    const priceDom = (price, price_fixedval, currency, price_rangestartval, price_rangeendval, price_addnote, wt) => {
        var price_dom
        if (price === 'Fixed') {
            price_dom = <div style={{ display: 'flex', padding: 10, overflowX: 'auto', fontFamily: 'Lato' }}>{'$ ' + Processmoney(price_fixedval) + ' ' + currency}</div>
        }
        else if (price === 'Range') {
            price_dom = <div style={{ display: 'flex', flexDirection: 'row', padding: 10, overflowX: 'auto', fontFamily: 'Lato' }}>{'$ ' + Processmoney(price_rangestartval) + ' - $ ' + Processmoney(price_rangeendval) + ' ' + currency}</div>
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: wt, border: '1px solid var(--subgrey)', color: 'var(--maindark)', borderRadius: 10, margin: 10, minHeight: 100, placeContent: 'flex-start', alignItems: 'center' }}>
                {commontitle(
                    'Pricing',
                    DOLLAR_ICON ? iconwrapper(DOLLAR_ICON, { width: 20, height: 20, display: 'flex', placeContent: 'center' }) : null
                )}
                <div style={{ fontSize: 20, display: 'flex', justifyContent: 'center' }}>
                    {price_dom}
                </div>
                {price_addnote && price_addnote.length > 0
                    ? <div style={{ display: 'flex', placeContent: 'center', fontSize: commontext_size, padding: '10px 15px', whiteSpace: 'pre', textAlign: 'center' }}>
                        {price_addnote}
                    </div>
                    : null}
            </div>
        )
    }

    const destinationDom = (destination, destination_addnote, wt) => {
        const DESTINATION_KEY_MAP = {
            '': 'None',
            'None': 'None',
            'Local': 'Local',
            'Overseas': 'Overseas',
            'Both': 'Both',
            'Local + Local': 'Local(Pre) & Local(Actual)',
            'Local + Overseas': 'Local(Pre) & Overseas(Actual)',
            'Overseas + Local': 'Overseas(Pre) & Local(Actual)',
            'Overseas + Overseas': 'Overseas(Pre) & Overseas(Actual)'
        }
        return destination !== 'None' || destination !== ''
            ? <div style={{ display: 'flex', flexDirection: 'column', width: (wt / 2) - 10, border: '1px solid var(--subgrey)', color: 'var(--maindark)', borderRadius: 10, margin: '10px 10px 10px 0px', minHeight: 100 }}>
                {commontitle(
                    'Destination',
                    LOCATION_ICON ? iconwrapper(LOCATION_ICON, { width: 20, height: 20, display: 'flex', placeContent: 'center' }) : null
                )}
                <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 5px' }}>{DESTINATION_KEY_MAP[destination]}</div>
                {destination_addnote && destination_addnote.length > 0 ? <div style={{ display: 'flex', justifyContent: 'center', fontSize: commontext_size, padding: '10px 15px' }}>{destination_addnote}</div> : null}
            </div>
            : null
    }

    const durationDom = (duration, duration_fixedval, duration_rangestartval, duration_rangeendval, duration_addnote, wt) => {

        const DURATION_KEY_MAP = {
            '': 'None',
            'None': 'None',
            'Range': duration_rangestartval + ' - ' + duration_rangeendval + ' hr',
            'Fixed': duration_fixedval + ' hr'
        }

        var durationval = DURATION_KEY_MAP[duration]

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: (wt / 2) - 10, border: '1px solid var(--subgrey)', color: 'var(--maindark)', borderRadius: 10, margin: '10px 0px 10px 10px', minHeight: 100 }}>
                {commontitle(
                    'Duration',
                    DURATION_ICON ? iconwrapper(DURATION_ICON, { width: 20, height: 20, display: 'flex', placeContent: 'center' }) : null
                )}
                <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 5px' }}>{durationval}</div>
                {duration_addnote && duration_addnote.length > 0 ? <div style={{ display: 'flex', justifyContent: 'center', fontSize: commontext_size, padding: '10px 15px' }}>{duration_addnote}</div> : null}
            </div>
        )

    }

    const delieverablesDom = (deliverables_items = [], wt) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: wt, border: '1px solid var(--subgrey)', color: 'var(--maindark)', borderRadius: 10, margin: 10, minHeight: 100 }}>
                {commontitle(
                    'Delieverables',
                    DELIEVERABLES_ICON ? iconwrapper(DELIEVERABLES_ICON, { width: 20, height: 20, display: 'flex', placeContent: 'center' }) : null
                )}
                {deliverables_items.map((val, index) => {
                    return bullets(val, index, commontext_size)
                })}
            </div>
        )
    }

    const additionalchargesDom = (additionalcharges_items, wt) => {
        if (additionalcharges_items && additionalcharges_items.length > 0) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', width: wt, border: '1px solid var(--subgrey)', color: 'var(--maindark)', borderRadius: 10, margin: 10, minHeight: 100, alignContent: 'center' }}>
                    {commontitle(
                        'Additional Charges',
                        PLUS_ICON ? iconwrapper(PLUS_ICON, { width: 20, height: 20, display: 'flex', placeContent: 'center' }) : null
                    )}
                    {additionalcharges_items.map((val, index) => {
                        return bullets(val, index, commontext_size)
                    })}
                </div>
            )
        }
        return null

    }

    const descriptionDom = (description, wt) => {
        if (description && description.length > 0) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', width: wt, border: '1px solid var(--subgrey)', color: 'var(--maindark)', borderRadius: 10, margin: 10, minHeight: 100, alignContent: 'center' }}>
                    {commontitle('Description', null)}
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignContent: 'center', padding: '10px 15px', fontSize: commontext_size, }}>
                        {description}
                    </div>
                </div>
            )
        }
        return null
    }

    const othersDom = (others, wt) => {
        if (others && others.length > 0) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', width: wt, border: '1px solid var(--subgrey)', color: 'var(--maindark)', borderRadius: 10, margin: 10, minHeight: 100, alignContent: 'center' }}>
                    {commontitle('Others', null)}
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignContent: 'center', padding: '10px 15px', fontSize: commontext_size, }}>
                        {others}
                    </div>
                </div>
            )
        }
        return null
    }

    const termsandcondDom = (tnc, wt) => {
        if (tnc && tnc.length > 0) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', width: wt, border: '1px solid var(--subgrey)', color: 'var(--maindark)', borderRadius: 10, margin: 10, minHeight: 100, alignContent: 'center' }}>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignContent: 'center', padding: 10 }}>
                        <div style={{ fontWeight: 'bold', paddingLeft: 5, fontSize: 16 }}>{'Terms & Conditions'}</div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center', padding: '10px 15px', fontSize: commontext_size, }}>
                        {tnc}
                    </div>
                </div>
            )
        }
        return null
    }

    const enquireDom = (user, acct_type, plname, vendortype, vti_id, wt, displayname) => {
        if (user) {
            const { contact, email, name } = user
            // return (
            //     <Renderrequests
            //         displayname={displayname}
            //         from='Pricelist' // unique for this only, will allow user to click enquire and send its name tgt
            //         pricelistname={plname}
            //         key={plname}
            //         acct_type={acct_type}
            //         acct_contact={contact}
            //         acct_email={email}
            //         acct_name={name}

            //         whatsapp_link={v_whatsapp_link} // vendor
            //         telegram_link={v_telegram_link}// vendor

            //         button_text={'Enquire'}
            //         button_type={'SMALL'}
            //         vti_id={vti_id}
            //         vt={vendortype}
            //     />
            // )
            return <button
                className={`Vendorlookup_enquirebtn Vendorlookup_enquirebtn${device}`}
                onClick={() => {

                }}>
                Enquire
            </button>

        }
        else {
            return <div />
        }
    }

    const backarrowDom = (subimgs) => {
        var imgcounter = imgselectedindex + 1 + ' / ' + subimgs.length
        return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'fixed', zIndex: 3, alignItems: 'center', height: 60, width: '100%', padding: 10 }}>
            <button className="planning_backicon" onClick={exitclick}>{ARROWLEFT_ICON}</button>
            <div style={{ paddingLeft: 'calc(87vw - 40px)', whiteSpace: 'nowrap' }}>{imgcounter}</div>
        </div>
    }

    const showfullsizeimgDom = (subimgs, wt) => {
        if (device === 'WEB' || device === 'TABLET') {
            const mid_wt = device === 'WEB' ? '90vw' : '95vw'

            return (
                <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', height: '100vh' }} >
                    <div style={{ display: 'flex', height: 'max-content', flexDirection: 'row', maxHeight: '70vh', width: mid_wt, placeContent: subimgs.length > 1 ? 'space-between' : 'center' }}>
                        {subimgs.length > 1 ? <button className={'pllook_arrow'} onClick={leftclick}>{ARROWLEFT_SMALL}</button> : null}
                        <img
                            id={imgselectedindex}
                            key={'img'}
                            alt={''}
                            // src={subimgs[imgselectedindex]}
                            src={getS3fullpath_withbucketname('VENDOR_PRICELIST_IMG', subimgs[imgselectedindex])}

                            style={{ objectFit: 'scale-down', verticalAlign: 'bottom', width: wt, height: 'auto' }}
                        />
                        {subimgs.length > 1 ? <button className={'pllook_arrow'} onClick={rightclick}>{ARROWRIGHT_SMALL}</button> : null}
                    </div>
                </div>
            )
        }
        else if (device === 'MOBILE') {
            var leftrightdom = { height: '100%', width: '50vw', display: 'flex', alignItems: 'center', color: 'white', fontWeight: 'bold', fontSize: 56, justifyContent: 'center' }
            return (
                <div className="carousel-container" style={{ display: 'flex', overflowY: 'hidden' }} >
                    <div className="carousel-slide" style={{ display: 'flex', width: '100%', padding: '10vh 0px', overflowY: 'hidden', position: 'relative' }}>
                        <img
                            key={'img'}
                            alt={''}
                            // src={subimgs[imgselectedindex]}
                            src={getS3fullpath_withbucketname('VENDOR_PRICELIST_IMG', subimgs[imgselectedindex])}
                            style={{ objectFit: 'contain', verticalAlign: 'bottom', minWidth: '100vw', height: '80vh' }}
                        />
                        <div style={{ position: 'absolute', display: 'flex', flexDirection: 'row', height: '100vh', zIndex: 2 }}>
                            <div style={leftrightdom} onClick={leftclick} />
                            <div style={leftrightdom} onClick={rightclick} />
                        </div>
                    </div>
                </div>
            )
        }
    }

    const pdfDom = (wt) => {
        return <button
            className='pllook_pdfitem' style={{ width: wt, fontSize: 20 }}
            onClick={() => {
                let link = ''
                if (downloadablelink.length > 0) { link = getS3fullpath_withbucketname('VENDOR_PRICELIST_IMG', downloadablelink) }
                else { link = redirectlink }
                window.open(link, '_blank');  // Open link in a new tab
            }}
        >
            {downloadablelink.length > 0 ? `Pricelist Detail` : ''}
            {redirectlink.length > 0 ? `Pricelist Detail` : ''}
        </button>
    }

    if (data && !firsttime) {
        const { name, wedtype, curr_vendortype, eventorwed, location, profileimg_id, displayname, username, vendortype, secondaryvendortype, price, price_fixedval, currency, price_rangestartval, price_rangeendval, price_addnote, deliverables_items, destination, destination_addnote, duration, duration_fixedval, duration_rangestartval, duration_rangeendval, duration_addnote, additionalcharges_items, description, others, vti_id, mainimg, subimgs, tnc, vids, tags } = data

        const local = !imgscreen
            ? <div style={head_css}>
                {nameDom(name, paperwt)}
                {imgDom(mainimg, subimgs, paperwt, paperht)}
                {vidDom(vids, paperwt)}
                {indicatorDom(wedtype, eventorwed, location, paperwt, tags)}
                {tagDom(curr_vendortype, eventorwed, paperwt)}
                {priceDom(price, price_fixedval, currency, price_rangestartval, price_rangeendval, price_addnote, paperwt)}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {destinationDom(destination, destination_addnote, paperwt)}
                    {durationDom(duration, duration_fixedval, duration_rangestartval, duration_rangeendval, duration_addnote, paperwt)}
                </div>
                {delieverablesDom(deliverables_items, paperwt)}
                {additionalchargesDom(additionalcharges_items, paperwt)}
                {descriptionDom(description, paperwt)}
                {othersDom(others, paperwt)}
                {termsandcondDom(tnc, paperwt)}
                {subimgs && subimgs.length > 0
                    ? subimgs.map((img, i) => {
                        return <div key={'preloadimg' + i} style={{ visibility: 'hidden', backgroundImage: 'url("' + img + '")', width: 0, height: 0 }} />
                    }) : null}
                <div style={{ height: 120 }} />
            </div>
            : <div style={imghead_css} >
                {backarrowDom(subimgs)}
                {showfullsizeimgDom(subimgs, paperwt)}
            </div>

        const redirect = !imgscreen
            ? <div style={head_css}>
                {nameDom(name, paperwt)}
                {imgDom(mainimg, subimgs, paperwt, paperht)}
                {vidDom(vids, paperwt)}
                {indicatorDom(wedtype, eventorwed, location, paperwt, tags)}
                {tagDom(curr_vendortype, eventorwed, paperwt)}
                {priceDom(price, price_fixedval, currency, price_rangestartval, price_rangeendval, price_addnote, paperwt)}
                {pdfDom(paperwt)}
                {subimgs && subimgs.length > 0
                    ? subimgs.map((img, i) => {
                        return <div key={'preloadimg' + i} style={{ visibility: 'hidden', backgroundImage: 'url("' + img + '")', width: 0, height: 0 }} />
                    })
                    : null}
                <div style={{ height: 120 }} />
            </div>
            : <div style={imghead_css} >
                {backarrowDom(subimgs)}
                {showfullsizeimgDom(subimgs, paperwt)}
            </div>

        return notfound && !firsttime
            ? <Notfound />
            : <div className="pllook_root">
                {[local, redirect][pltype]}
                {vdetailDom(profileimg_id, displayname, username, vendortype, secondaryvendortype, paperwt, user, acct_type, name, vti_id)}
                {enquiry_portal}
            </div>

    }
    else {
        return <Loading show={true} />
    }

}

export default Pricelistlookup