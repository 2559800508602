import React, { Component } from 'react'
import { Header, MainMenu } from '../components'
import { iconwrapper, Retrieve_personal_info } from '../../../config/commonfunctions'
import { Projectcard, Loading, Portalmodal } from '../../../components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { BUTTONSTYLE, CLEARSTYLE } from '../../../config/cssconstants';
import { WEB, TABLET, PC_WT_MAP, MAIN_PINK_COLOR, production } from '../../../config/constants'; // EXPAND_ICON
import { Link } from 'react-router-dom'
import { Standardaccordion } from '../../../basic_components';
import { FILTERCURSOR_ICON, MAPGPS_ICON } from '../../../config/svgicons'
import * as types from '../../../redux/actions/projectgallery.action'
import './projectgallery.css'

function bodyhide() {
    document.body.style.overflow = "hidden"
    document.body.style.position = "fixed"
    document.body.style.height = "10px"
}
function bodyappear() {
    document.body.style.overflow = "auto"
    document.body.style.position = ""
    document.body.style.height = ""
}
// const category = {
//     Wedding: {
//         layer1: ['All', 'Pre-Wedding', 'Actual Wedding', 'Wedding Dresses', 'Wedding Decoration'],
//         layer2: [ // name , [ radio-0 check-1, 'detail1', 'detail2'....]
//             ['Styles', 1], ['Casual', 'Country', 'Formal/Black-tie/White-tie', 'Glamorous', 'Modern', 'Romantic', 'Rustic', 'Traditional Chinese ', 'Traditional Malay ', 'Traditional Indian', 'Traditional', 'Vintage', 'Fall', 'Spring', 'Winter', 'Summer', 'Garden'],
//             ['Settings', 1], ['Ballroom', 'Beach/Waterfront', 'Farm/Barn', 'Garden', 'Country Club', 'Historic Estate/Mansion', 'Hotel/Inn/Resort', 'Museum/Gallery', 'Outdoor', 'Park/Ranch', 'Bar/Restaurant', 'Vineyard/Winery', 'Private Residence', 'Raw Space', 'Hotels, Inns & Resorts', 'Ballrooms & Banquet Halls', 'Historic Estates & Mansions', 'Golf & Country Clubs', 'Farms & Barns', 'Restaurants', 'Parks', 'Gardens', 'Beaches', 'Vineyards & Wineries', 'Museums & Galleries', 'Urban & Downtown', 'Lodges & Retreats', 'Zoos'],
//             ['Themes', 0], ['Sports', 'Fashion', 'Music', 'Animal', 'Games', 'Nightclub', 'Movie/TV', 'Holiday', 'Other',],
//             ['Guest Count', 0], ['Under 25', '25-50', '50-100', '100-150', '150-200', '200-500', '1000+']
//         ],
//     },
//     Event: {
//         layer1: ['All', 'Team Building', 'Conference', 'Gala Parties', 'Workshop', 'Fashion Show', 'Birthday'],
//         layer2: [
//             ['Styles', 1], ['Casual', 'Country', 'Formal/Black-tie/White-tie', 'Glamorous', 'Modern', 'Romantic', 'Rustic', 'Traditional', 'Vintage', 'Fall', 'Spring', 'Winter', 'Summer', 'Garden'],
//             ['Settings', 1], ['Ballroom', 'Beach/Waterfront', 'Farm/Barn', 'Garden', 'Country Club', 'Historic Estate/Mansion', 'Hotel/Inn/Resort', 'Museum/Gallery', 'Outdoor', 'Park/Ranch', 'Bar/Restaurant', 'Vineyard/Winery', 'Private Residence', 'Raw Space', 'Hotels, Inns & Resorts', 'Ballrooms & Banquet Halls', 'Historic Estates & Mansions', 'Golf & Country Clubs', 'Farms & Barns', 'Restaurants', 'Parks', 'Gardens', 'Beaches', 'Vineyards & Wineries', 'Museums & Galleries', 'Urban & Downtown', 'Lodges & Retreats', 'Zoos'],
//             ['Themes', 0], ['Sports', 'Fashion', 'Music', 'Animal', 'Games', 'Nightclub', 'Movie/TV', 'Holiday', 'Other',],
//             ['Guest Count', 0], ['Under 25', '25-50', '50-100', '100-150', '150-200', '200-500', '1000+']
//         ],
//     },
//     Guest:{
//         layer1:[],
//         layer2:[]
//     }
// }

const mapStateToProps = (state) => {
    // get state from reducer
    return {
        isLoading: state.Projectgallery.isLoading,
        isLoaded: state.Projectgallery.isLoaded,
        data_info: state.Projectgallery.data_info, // give the size etc
        data: state.Projectgallery.data, // give the real data
    }
}

const mapDispatchToProps = (dispatch) => { // https://www.bilibili.com/video/av23568132/?p=10
    // dispatch action to reducer
    return bindActionCreators(types, dispatch)
}

// @connect(mapStateToProps, mapDispatchToProps) // https://www.bilibili.com/video/av23568132/?p=11
class ProjectGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {

            loaded: false,
            firsttime: true,

            // user info
            _id: '',
            token: '',
            acct_type: '',

            // screen dim
            screen_width: 0,
            screen_height: 0,

            // category
            selectedMainCategory: 'Project',

            // for infiinite scrolling
            scrolling: false,

            data: [],
            page_counter: 0,
            batchesVendortypeinterface_ids: [],

            begin: 0, // start of window // need to manually reset, only in project 
            rendercard_count: 0, // begin + rendercard_count, is end of window, shared with project and vendors
            projectinterface_ids: [], // list of _ids for window to generate
            totalpage_count: 0, // not using currently
            totalpage_counter: 0,

            defaultcat: this.props.usertype ? this.props.usertype : null, // either Wedding or Event
            catlayer1: null,
            l1item_selected: 'All',
            mobile_filter: false,

            abbrev_userlocation: '',

            filters: [ // {Wedding or event, styles or settings or themes, guest count, label, radio-0 or checkbox-1}

                { cat: 'Both', filter: 'Location', label: 'Singapore', checked: false, type: 1 }, // need special treatment
                { cat: 'Both', filter: 'Location', label: 'Malaysia', checked: false, type: 1 },
                { cat: 'Both', filter: 'Location', label: 'Taiwan', checked: false, type: 1 },
                { cat: 'Both', filter: 'Location', label: 'Hong Kong', checked: false, type: 1 },
                { cat: 'Both', filter: 'Location', label: 'China', checked: false, type: 1 },
                { cat: 'Both', filter: 'Location', label: 'Korea', checked: false, type: 1 },

                { cat: 'Both', filter: 'Guest Count', label: 'Under 25', checked: false, type: 1 }, // need special treatment
                { cat: 'Both', filter: 'Guest Count', label: '25-99', checked: false, type: 1 },
                { cat: 'Both', filter: 'Guest Count', label: '100-149', checked: false, type: 1 },
                { cat: 'Both', filter: 'Guest Count', label: '150-199', checked: false, type: 1 },
                { cat: 'Both', filter: 'Guest Count', label: '200-299', checked: false, type: 1 },
                { cat: 'Both', filter: 'Guest Count', label: '300-399', checked: false, type: 1 },
                { cat: 'Both', filter: 'Guest Count', label: '400-499', checked: false, type: 1 },
                { cat: 'Both', filter: 'Guest Count', label: '500-999', checked: false, type: 1 },
                { cat: 'Both', filter: 'Guest Count', label: '1000+', checked: false, type: 1 },

                // { cat: 'Wedding', filter: 'Styles', label: 'Casual', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Styles', label: 'Country', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Styles', label: 'Formal/Black-tie/White-tie', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Styles', label: 'Glamorous', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Styles', label: 'Modern', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Styles', label: 'Romantic', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Styles', label: 'Rustic', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Styles', label: 'Traditional Chinese', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Styles', label: 'Traditional Malay', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Styles', label: 'Traditional Indian', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Styles', label: 'Traditional', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Styles', label: 'Vintage', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Styles', label: 'Fall', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Styles', label: 'Spring', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Styles', label: 'Winter', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Styles', label: 'Summer', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Styles', label: 'Garden', checked: false, type: 1 },

                // { cat: 'Wedding', filter: 'Settings', label: 'Ballroom', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Beach/Waterfront', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Farm/Barn', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Garden', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Country Club', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Historic Estate/Mansion', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Hotel/Inn/Resort', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Museum/Gallery', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Outdoor', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Parl/Ranch', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Bar/Restaurant', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Vineyard/Winery', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Private Residence', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Raw Space', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Hotels, Inns & Resorts', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Ballrooms & Banquet Halls', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Historic Estates & Mansions', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Golf & Country Clubs', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Parks', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Beaches', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Museums & Galleries', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Lodges & Retreats', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Urban & Downtown', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Settings', label: 'Zoos', checked: false, type: 1 },

                // { cat: 'Wedding', filter: 'Themes', label: 'Sports', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Themes', label: 'Fashion', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Themes', label: 'Music', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Themes', label: 'Games', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Themes', label: 'Nightclub', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Themes', label: 'Movie/TV', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Themes', label: 'Holiday', checked: false, type: 1 },
                // { cat: 'Wedding', filter: 'Themes', label: 'Other', checked: false, type: 1 },

            ],
            filters_pos: [0, 6,],// 13, 30, 54
            filterheaders: ['Location', 'Guest Count',],//'Styles', 'Settings', 'Themes',
            filterheader_expand: [true, true,],//false, false, false

            // [Location, Guest count, Tags]
            filter_gate: [false, false, false],// use to determine which api to use

            tag_filter: [],
            location_filter: [],
            guestcount_filter: [],

            device: '',

            name: '',
            spousename: '',
            date_w: '',

        }
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0)
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
        const { _id, token, acct_type } = Retrieve_personal_info()
        this.setState({ _id: _id, token: token, acct_type: acct_type, }, () => this.initialize_projectgallery())
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
        window.removeEventListener('scroll', this.updateScrolling)
    }

    UNSAFE_componentWillMount() {
        this.scrollListner = window.addEventListener('scroll', this.updateScrolling)
    }

    updateScrolling = () => {
        // once scroll down, need to extend content by querying db
        const { scrolling, page_counter, batchesVendortypeinterface_ids, totalpage_count, totalpage_counter } = this.state
        var { isLoading, isLoaded, } = this.props
        if (!isLoading && isLoaded) {
            if (scrolling) return
            const bottomalrdy = document.querySelector('div.bottomalrdy')
            const lastLiOffset = bottomalrdy.offsetTop + bottomalrdy.clientHeight
            const pageOffset = window.pageYOffset + window.innerHeight
            var bottomOffset = 0
            // console.log('scrolling -----------------------------------------------------------------')
            // console.log('pageOffset', pageOffset)
            // console.log('bottomalrdy.clientHeight', bottomalrdy.clientHeight)
            // console.log('bottomalrdy.offsetTop', bottomalrdy.offsetTop)
            // console.log('lastLiOffset - bottomOffset', lastLiOffset - bottomOffset)
            // console.log('page_counter', page_counter)
            // console.log('totalpage_count', totalpage_count)
            // console.log('totalpage_counter', totalpage_counter)
            if (totalpage_counter > totalpage_count) return
            if (pageOffset >= (lastLiOffset - 2 - bottomOffset) && page_counter < batchesVendortypeinterface_ids.length) this.extendContent_projectgallery()

        }

    }

    extendContent_projectgallery = () => { // extend content after scrolling for projects
        const { begin, rendercard_count, totalpage_counter, projectinterface_ids, } = this.state//projectContent
        var end = begin + rendercard_count
        var requestContent_list = projectinterface_ids.slice(begin, end)
        this.props.fetchprojectgallerycontent_action(requestContent_list).then(() => {
            var { data } = this.props//isLoading, isLoaded,
            this.setState({
                data: [...this.state.data, ...data], //append latest content 
                scrolling: false,
                begin: end,
                totalpage_counter: totalpage_counter + 1,
            })
        })
    }

    splitthis = (arr, num) => {
        var arrs = []
        for (let i = 1; i < arr.length; i++) {
            var temp = []
            for (let x = 0; x < num; x++) {
                temp.push(arr[i])
            }
            arrs.push(temp)
        }
        return arrs
    }

    initialize_projectgallery = () => {
        var filter_raw_projectinterfaceids = (projectinterface_ids) => {
            var result = []
            for (let i = 0; i < projectinterface_ids.length; i++) {
                result.push(projectinterface_ids[i]._id)
            }
            return result
        }
        const { token } = Retrieve_personal_info()
        const { defaultcat, tag_filter, location_filter, guestcount_filter, filter_gate } = this.state
        this.props.fetchprojectgallerycontentsize_action(token, defaultcat, tag_filter, location_filter, guestcount_filter, filter_gate).then(() => {
            const { rendercard_count, projectinterface_ids, usertype, name, spousename, date_w, location } = this.props.data_info
            const { begin } = this.state
            var processed_projectinterface_ids = filter_raw_projectinterfaceids(projectinterface_ids)
            var end = begin + rendercard_count
            var requestContent_list = processed_projectinterface_ids.slice(begin, end)
            this.props.fetchprojectgallerycontent_action(requestContent_list).then(() => {
                var { data } = this.props //isLoading, isLoaded,
                var batchesVendortypeinterface_ids = this.splitthis(processed_projectinterface_ids, rendercard_count)
                if (defaultcat === null) { // defaultcat is still unknown...need set
                    this.setState({
                        abbrev_userlocation: location,
                        defaultcat: usertype,
                        projectinterface_ids: processed_projectinterface_ids,
                        data: data,
                        rendercard_count,
                        begin: end,
                        totalpage_count: Math.ceil(projectinterface_ids.length / rendercard_count),
                        name: name,
                        spousename: spousename,
                        date_w: date_w,
                        batchesVendortypeinterface_ids
                    }, () => { this.setState({ loaded: true, firsttime: false }) })
                }
                else {
                    this.setState({
                        abbrev_userlocation: location,
                        projectinterface_ids: processed_projectinterface_ids,
                        data: data,
                        rendercard_count,
                        begin: end,
                        totalpage_count: Math.ceil(projectinterface_ids.length / rendercard_count),
                        name: name,
                        spousename: spousename,
                        date_w: date_w,
                        batchesVendortypeinterface_ids
                    }, () => { this.setState({ loaded: true, firsttime: false }) })
                }
            })

        })
    }

    updateWindowDimensions = () => {
        this.setState({ screen_width: window.innerWidth, screen_height: window.innerHeight });
    }

    render_filtercomp = () => {
        const { filters, filterheaders, filterheader_expand, filters_pos, screen_width } = this.state
        const device = screen_width <= WEB ? 'MOBILE' : 'WEB'
        var sidebar = []
        for (let x = 0; x < filters_pos.length; x++) { // filters_pos and filtersheaders and filterheader_expand same length
            const start = filters_pos[x]
            const end = x + 1 < filters_pos.length ? filters_pos[x + 1] : filters.length
            var filters_arr = []
            for (let y = start; y < end; y++) {
                const { type, checked, label, filter, } = filters[y] //cat
                const label_id = label + filter
                const updatebox = () => {
                    var local_filters = filters
                    var local_tag_filter = this.state.tag_filter
                    var local_guestcount_filter = this.state.guestcount_filter
                    var local_location_filter = this.state.location_filter
                    var local_filter_gate = this.state.filter_gate

                    if (local_filters[y].checked) { // remove item
                        local_filters[y].checked = false
                        if (filter === 'Location') { // need special treatment
                            local_location_filter.splice(local_location_filter.indexOf(label), 1)
                            if (local_location_filter.length === 0) local_filter_gate[0] = false
                        }
                        else if (filter === 'Guest Count') { // need special treatment
                            local_guestcount_filter.splice(local_guestcount_filter.indexOf(label), 1)
                            if (local_guestcount_filter.length === 0) local_filter_gate[1] = false
                        }
                        else {
                            local_tag_filter.splice(local_tag_filter.indexOf(label), 1)
                            if (local_tag_filter.length === 0) local_filter_gate[2] = false
                        }
                    }
                    else { // add item
                        local_filters[y].checked = true
                        if (filter === 'Location') { // need special treatment
                            local_location_filter.push(label)
                            local_filter_gate[0] = true
                        }
                        else if (filter === 'Guest Count') { // need special treatment
                            local_guestcount_filter.push(label)
                            local_filter_gate[1] = true
                        }
                        else {
                            local_tag_filter.push(label)
                            local_filter_gate[2] = true
                        }
                    }

                    if (device !== 'MOBILE') {
                        this.setState({
                            filter_gate: local_filter_gate,
                            location_filter: local_location_filter,
                            guestcount_filter: local_guestcount_filter,
                            tag_filter: local_tag_filter,
                            filters: local_filters,

                            data: [],
                            page_counter: 0,
                            batchesVendortypeinterface_ids: [],
                            begin: 0, // start of window // need to manually reset, only in project 
                            rendercard_count: 0, // begin + rendercard_count, is end of window, shared with project and vendors
                            projectinterface_ids: [], // list of _ids for window to generate
                            totalpage_counter: 0,

                        }, () => this.initialize_projectgallery())
                    }
                    else {
                        this.setState({
                            filter_gate: local_filter_gate,
                            location_filter: local_location_filter,
                            guestcount_filter: local_guestcount_filter,
                            tag_filter: local_tag_filter,
                            filters: local_filters,
                        })
                    }


                }
                filters_arr.push(
                    <div key={label_id} className="FMC_cbrow" onClick={updatebox}>
                        <input
                            id={label_id}
                            className="FMC_cb"
                            type={type === 1 ? "checkbox" : "radio"}
                            checked={checked}
                            onChange={updatebox}
                        />
                        <label className={"FMC_cblbl"} name={label_id} htmlFor={label_id} >
                            {label}
                        </label>
                    </div>
                )

            }
            const reset_btn = <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', padding: 5, fontFamily: 'Lato' }}>
                <div
                    style={CLEARSTYLE}
                    onClick={() => {
                        // this will clear all related checked checkbox
                        var local_filters = filters
                        var local_tag_filter = this.state.tag_filter
                        var filter_name = local_filters[start].filter
                        var local_filter_gate = this.state.filter_gate
                        var local_location_filter = this.state.location_filter
                        var local_guestcount_filter = this.state.guestcount_filter

                        if (filter_name === 'Location') {
                            for (let y = start; y < end; y++) { // uncheck all & remove tag frm tag_filter
                                local_filters[y]['checked'] = false // uncheck
                            }
                            local_filter_gate[0] = false
                            local_location_filter = []
                        }
                        else if (filter_name === 'Guest Count') {
                            for (let y = start; y < end; y++) { // uncheck all & remove tag frm tag_filter
                                local_filters[y]['checked'] = false // uncheck
                            }
                            local_filter_gate[1] = false
                            local_guestcount_filter = []
                        }
                        else { // normal tag
                            for (let y = start; y < end; y++) { // uncheck all & remove tag frm tag_filter
                                local_filters[y]['checked'] = false// uncheck

                                // remove of tag in tag_filter
                                const index = local_tag_filter.indexOf(local_filters[y].label)
                                local_tag_filter.splice(index, 1)
                            }

                            if (local_tag_filter.length === 0) local_filter_gate[2] = false
                        }

                        this.setState({
                            filters: local_filters,
                            tag_filter: local_tag_filter,
                            location_filter: local_location_filter,
                            guestcount_filter: local_guestcount_filter,

                            data: [],
                            page_counter: 0,
                            batchesVendortypeinterface_ids: [],
                            begin: 0, // start of window // need to manually reset, only in project 
                            rendercard_count: 0, // begin + rendercard_count, is end of window, shared with project and vendors
                            projectinterface_ids: [], // list of _ids for window to generate
                            totalpage_counter: 0,

                        }, () => this.initialize_projectgallery())

                    }}
                >Reset</div>
            </div>

            // const device_rootstyle = {
            //     'MOBILE': { boxShadow: x === filters_pos.length - 1 ? 'rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px' : 'rgb(0 0 0 / 20%) 0px 0px 3px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px' },
            //     'TABLET': { boxShadow: 'rgb(0 0 0 / 20%) 0px 0px 3px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px' },
            //     'WEB': { boxShadow: x === filters_pos.length - 1 ? 'rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px' : 'rgb(0 0 0 / 20%) 0px 0px 3px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px', borderBottomRightRadius: x === filters_pos.length - 1 ? 10 : 0 },
            // }

            const device_rootstyle = {
                'MOBILE': { borderTop: x === 0 ? '1px solid var(--lightdark)' : '', borderBottom: '1px solid var(--lightdark)' },
                'TABLET': { borderTop: x === 0 ? '1px solid var(--lightdark)' : '', borderBottom: '1px solid var(--lightdark)' },
                'WEB': { boxShadow: x === filters_pos.length - 1 ? 'rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px' : 'rgb(0 0 0 / 20%) 0px 0px 3px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px', borderBottomRightRadius: x === filters_pos.length - 1 ? 10 : 0 },
            }

            sidebar.push(
                <div key={filterheaders[x]} style={{ display: 'flex', flexDirection: 'column', width: screen_width <= WEB ? '100%' : null, }}>
                    <Standardaccordion
                        title={filterheaders[x]}
                        items={[...filters_arr, reset_btn]}
                        rootstyle={device_rootstyle[device]}
                        titlestyle={{ fontSize: 16, padding: '0px 15px 0px 20px' }}
                        itemstyle={{ fontSize: 16, height: 40, width: '100%', display: 'flex', placeContent: 'start', padding: '0px 10px' }}
                        open={filterheader_expand[x]}
                        updateopen={(title) => {
                            const index = filterheaders.indexOf(title)
                            var new_filterheader_expand = filterheader_expand.slice()
                            new_filterheader_expand[index] = !new_filterheader_expand[index]
                            this.setState({ filterheader_expand: new_filterheader_expand })
                        }}
                    />
                    <div style={{ padding: 1, }} />
                </div>
            )
        }
        return sidebar
    }

    projectcards = (pcwt) => {
        const { data, screen_width } = this.state
        const device = screen_width >= WEB ? 'WEB' : screen_width >= TABLET ? 'TABLET' : 'MOBILE'
        return data.map((item, index) => <li key={index + 'pc'} style={{ listStyleType: 'none', width: pcwt, display: 'flex', justifyContent: 'center', margin: '0px 5px' }}>
            <Projectcard
                {...this.props}
                key={index}
                item={item}
                viewtype={1} // 0 : vendor viewing, 1 : user viewing
                device={device}
            />
        </li>)
    }

    applyClick = () => {
        bodyappear()
        this.setState({
            data: [],
            page_counter: 0,
            batchesVendortypeinterface_ids: [],
            begin: 0, // start of window // need to manually reset, only in project 
            rendercard_count: 0, // begin + rendercard_count, is end of window, shared with project and vendors
            projectinterface_ids: [], // list of _ids for window to generate
            totalpage_counter: 0,
            mobile_filter: false
        }, () => this.initialize_projectgallery())
    }

    render() {

        const { screen_width, defaultcat, } = this.state //l1item_selected
        const { usertype, } = this.props

        var device
        if (screen_width < TABLET) device = 'MOBILE'
        else if (screen_width - 1 < WEB) device = 'TABLET'
        else device = 'WEB'
        const device_titlefs_map = {
            'WEB': 32,
            'TABLET': 32,
            'MOBILE': 32
        }
        // var layer1cat = ( // top bar
        //     <div style={{ display: 'flex', flexDirection: 'row', borderRadius: 6, alignItems: 'center', width: '100%', height: 60, overflow: 'hidden', }}>
        //         <div style={{ display: 'flex', flexDirection: 'row', marginTop: 12, overflow: 'auto', width: '100%', height: '100%', alignItems: 'center', justifyContent: screen_width > WEB ? 'center' : '', }}>
        //             {category[defaultcat]['layer1'].map((l1_item) => {
        //                 return (
        //                     <button
        //                         key={Math.random()}
        //                         onClick={() => { this.setState({ l1item_selected: l1_item }) }}
        //                         style={{ ...BUTTONSTYLE, height: 'max-content', outline: 'none', margin: '0px 5px', padding: '7px 5px', fontSize: 13, letterSpacing: '1px', whiteSpace: 'nowrap', fontWeight: 500, color: l1_item === l1item_selected ? MAIN_PINK_COLOR : 'rgb(112, 112, 112)', border: l1_item === l1item_selected ? 'thin solid ' + MAIN_PINK_COLOR : 'thin solid transparent' }}
        //                     >
        //                         {l1_item}
        //                     </button>
        //                 )
        //             })}
        //         </div>
        //     </div>
        // )



        // var later2cat_mobiletablet = ( // mobile filter
        //     <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', height: 50 }}>
        //         <button style={{ ...BUTTONSTYLE, border: '1px solid rgb(233, 233, 233)', color: 'rgb(112, 112, 112)', backgroundColor: 'transparent', width: 40, height: 40, padding: 5, margin: 5 }}
        //             onClick={() => { this.setState({ mobile_filter: !this.state.mobile_filter }) }}>
        //             <div>{FILTERCURSOR_ICON}</div>
        //         </button>
        //     </div>
        // )

        // var filtermodal_mobiletablet = (
        //     <Jfmodal
        //         show={this.state.mobile_filter}
        //         title={'Filter'}
        //         onHide={() => { this.setState({ mobile_filter: !this.state.mobile_filter }) }}
        //         device={device === 'TABLET' ? 'WEB' : device === 'WEB' ? 'WEB' : 'MOBILE'}
        //         bsSize={'large'}
        //         content={
        //             <div style={{ display: 'flex', flexDirection: 'column', WebkitOverflowScrolling: 'touch', minHeight: device === 'MOBILE' ? '-webkit-fill-available' : 'max-content', overflowY: 'auto', overflowX: 'hidden', width: '100%' }}>
        //                 <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'flex-start', }}>
        //                     <div style={{ backgroundColor: 'white', height: 20, zIndex: 1, marginTop: -20, width: '100%', position: 'absolute' }} />
        //                     {this.render_filtercomp()}
        //                     {/* <div style={{ width: '100%', display: 'flex', placeContent: 'flex-end', padding: 10, }}>
        //                         <button
        //                             style={{ ...BUTTONSTYLE, marginBottom: device === "MOBILE" ? 50 : 0, display: 'flex', width: 'max-content' }}
        //                             onClick={this.applyClick}>Apply</button>
        //                     </div> */}
        //                     {device === 'MOBILE'
        //                         ? <button style={{ ...BUTTONSTYLE, margin: '50px 10px 200px 10px', width: 'calc( 100% - 20px )', alignSelf: 'center' }} onClick={this.applyClick}>Apply</button>
        //                         : <div className="FMC_APPLYBTNBOX">
        //                             <button style={BUTTONSTYLE} onClick={this.applyClick}>Apply</button>
        //                         </div>}

        //                 </div>
        //             </div>
        //         }
        //     />
        // )

        const portalmidcss_mobile = {
            width: '100%',
            height: '100%',
            top: 0,
            backgroundColor: 'white',
            left: 0, right: 0,
            margin: 'auto',
            transform: 'none'
        }

        const portalmidcss_web = {
            width: 600,
            height: 660,
            top: 'calc( 50% - 330px )',
            backgroundColor: 'white',
            left: 0, right: 0,
            margin: 'auto',
            transform: 'none',
        }


        const portalmidcsswtlarger_mobile = {
            width: '100%',
            height: '100%',
            top: 0,
            backgroundColor: 'white',
            left: 0, right: 0,
            margin: 'auto',
            transform: 'none',
            overflowX: 'scroll'
        }

        var filtermodal_mobiletablet = (
            // <Portalmodal
            //     portaltype={'commonportal'}
            //     open={this.state.mobile_filter}
            //     onClose={() => {
            //         bodyappear()
            //         this.setState({ mobile_filter: !this.state.mobile_filter })
            //     }}
            //     floatingclose={false}
            //     backdropvisible={true}
            //     modalstyle={portalmidcss_mobile}
            //     closestyle={{ right: 0 }}
            //     floatingclosecolor='#333333'
            // >


            <Portalmodal
                portaltype={'commonportal'}
                open={this.state.mobile_filter}
                onClose={() => {
                    bodyappear()
                    this.setState({ mobile_filter: !this.state.mobile_filter })
                }}
                floatingclose={false}
                backdropvisible={true}
                modalstyle={(device === 'WEB' || device === 'TABLET') ? portalmidcss_web : screen_width > this.state.screen_height ? portalmidcsswtlarger_mobile : portalmidcss_mobile}
                closestyle={{ right: 0 }}
                floatingclosecolor='var(--maindark)'
            >
                <div className='Portalmodal_vtroot' >
                    {this.render_filtercomp()}
                    <div className="FMC_APPLYBTNBOX">
                        <button
                            className='Rsvpexplorer_portalsave'
                            style={{
                                position: 'relative',
                                bottom: 0,
                                backgroundColor: '#ffffff',
                                color: 'var(--maindark)',
                                border: '1px solid var(--maindark)',
                                minHeight: 45,
                                width: '100%',
                                margin: 0
                            }}
                            onClick={this.applyClick}
                        >
                            Apply
                        </button>
                    </div>

                </div>

            </Portalmodal>
        )

        const wedOrEventClick = () => {
            return
            window.scrollTo(0, 0)
            var local_filters = this.state.filters.slice();
            if (defaultcat !== 'Event') {
                for (let i = 0; i < local_filters.length; i++) local_filters[i]['checked'] = false;
                this.setState({
                    defaultcat: 'Event',
                    l1item_selected: 'All',
                    projectinterface_ids: [],
                    data: [],
                    rendercard_count: 0,
                    begin: 0,
                    totalpage_count: 0,
                    totalpage_counter: 0,
                    filter: local_filters,
                    location_filter: [],
                    tag_filter: [],
                    guestcount_filter: [],
                    filter_gate: [false, false, false],
                    firsttime: true
                }, () => {
                    this.initialize_projectgallery();
                });
            }
            else if (defaultcat !== 'Wedding') {
                for (let i = 0; i < local_filters.length; i++) local_filters[i]['checked'] = false;
                this.setState({
                    defaultcat: 'Wedding',
                    l1item_selected: 'All',
                    projectinterface_ids: [],
                    data: [],
                    rendercard_count: 0,
                    begin: 0,
                    totalpage_count: 0,
                    totalpage_counter: 0,
                    location_filter: [],
                    tag_filter: [],
                    guestcount_filter: [],
                    filter_gate: [false, false, false],
                    firsttime: true
                }, () => {
                    this.initialize_projectgallery();
                });
            }
        }

        var pcwt
        if (device === 'MOBILE') {
            pcwt = PC_WT_MAP['MOBILE']
        }
        else if (device === 'TABLET') {
            pcwt = PC_WT_MAP['TABLET']
        }
        else {
            pcwt = PC_WT_MAP['WEB']
        }

        const filter_btn = <button
            className='planning_editbtn'
            style={{ position: 'relative', placeSelf: 'center', height: '100%', border: 'none', borderRadius: 0, background: 'none', border: 'none', margin: 0, minWidth: 50, minHeight: 50 }}
            key={'mobile_filter'}
            onClick={() => {
                bodyhide()
                this.setState({ mobile_filter: !this.state.mobile_filter })
            }}
        >
            {iconwrapper(FILTERCURSOR_ICON, { width: 30, height: 30, color: 'var(--maindark)', padding: 5, margin: 0, display: 'flex', alignItems: 'center', placeContent: 'center' })}
        </button>

        const mapclick = () => document.getElementById('projectgallerymap').click()
        const mapbtn_with_new_feature = defaultcat === 'Wedding'
            ? <div className={'Projectgallery_mapbtn' + device} style={{ borderRadius: { WEB: 15, TABLET: 0, MOBILE: 0 }[device] }} onClick={() => { }} >
                {iconwrapper(MAPGPS_ICON, { userSelect: 'none', width: 35, height: 35, color: 'var(--maindark)', margin: '0px 5px 0px 10px' })}
                <button style={{ userSelect: 'none', width: '100%', position: 'relative', height: 40, border: 'none', color: 'var(--maindark)', padding: 0, backgroundColor: 'white', outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', borderRadius: 5, margin: '5px 0px', borderRadius: 5 }}>
                    <div style={{ userSelect: 'none', fontSize: 14, placeContent: 'center', alignItems: 'center', position: 'absolute', display: 'flex', color: 'var(--maindark)', width: '100%', height: '100%', flexDirection: 'column', whiteSpace: 'pre' }}>
                        <div>Pre-Wedding</div>
                        <div>Location Curate</div>
                    </div>
                </button>
            </div>
            : null
            
        const mapbtn = defaultcat === 'Wedding'
            ? <div className={'Projectgallery_mapbtn' + device} style={{ borderRadius: { WEB: 15, TABLET: 0, MOBILE: 0 }[device] }} onClick={() => { }} >
                <button style={{ userSelect: 'none', width: '100%', position: 'relative', height: 40, border: 'none', color: 'var(--maindark)', padding: 0, backgroundColor: 'white', outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', borderRadius: 5, margin: '5px 0px', borderRadius: 5 }}>
                <div className={`Projectgalleryfeaturebtninner${device}`}>
                        <div>New Feature</div>
                        <div>Coming Soon!</div>
                    </div>
                </button>
            </div>
            : null

        const { name, spousename, date_w, firsttime, selectedMainCategory, abbrev_userlocation } = this.state
        return firsttime
            ? <Loading />
            : <div className="Projgallery_root">

                <Header {...this.props} abbrev_userlocation={abbrev_userlocation} usertype={'Project'} wedorevent={defaultcat} name={name} spousename={spousename} date_w={date_w}>
                    <MainMenu {...this.props} selectedMainCategory={selectedMainCategory} usertype={usertype} />
                </Header>

                {/* Wedding / Event */}
                <div className={'Projgallerysubroot' + device}>
                    <Link className={`Projgallerymapbtn${device}`} to={'/map'} target="_blank" style={{ visibility: 'hidden', width: 0, height: 0, position: 'absolute' }} id={`projectgallerymap`} />
                    {{
                        'WEB': <>
                            <div className={'Projgalleryinnersubroot' + device} style={{ placeContent: 'center' }}>
                                <div className="ProjgallerydefaultcatWT">
                                    <button className="ProjgallerytypegalleryWT"
                                        style={{ fontSize: device_titlefs_map[device] }}
                                        onClick={wedOrEventClick}
                                    >
                                        {defaultcat}
                                    </button>
                                </div>
                            </div>
                            {mapbtn}
                        </>,
                        'TABLET': <>
                            <div className={'Projgalleryinnersubroot' + device} style={{ placeContent: 'center' }}>
                                <div className="ProjgallerydefaultcatWT">
                                    <button className="ProjgallerytypegalleryWT"
                                        style={{ fontSize: device_titlefs_map[device] }}
                                        onClick={wedOrEventClick}
                                    >
                                        {defaultcat}
                                    </button>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', placeSelf: 'center', alignItems: 'center', borderRadius: 5, border: '1px solid var(--subgrey)', }}>
                                {mapbtn}{filter_btn}
                            </div>

                        </>,
                        'MOBILE': <>
                            <div className={'Projgalleryinnersubroot' + device} style={{ placeContent: 'center' }}>
                                <div className="ProjgallerydefaultcatM">
                                    <button className="ProjgallerytypegalleryM"
                                        style={{ fontSize: device_titlefs_map[device] }}
                                        onClick={wedOrEventClick}
                                    >
                                        {defaultcat}
                                    </button>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', placeSelf: 'center', alignItems: 'center', borderRadius: 5, border: '1px solid var(--subgrey)', }}>
                                {mapbtn}{filter_btn}
                            </div>
                        </>
                    }[device]}

                </div>

                <div style={{ display: 'flex', width: '100%', flexDirection: screen_width <= WEB ? 'column' : 'row', justifyContent: screen_width <= WEB ? 'flex-start' : 'center', }}> {/*minHeight: screen_height,*/}

                    {screen_width <= WEB
                        ? null
                        : <div style={{ width: screen_width < WEB ? '35vw' : 280, display: 'flex', flexDirection: 'column', color: '#707070' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: MAIN_PINK_COLOR, borderTopRightRadius: 10 }}> {/* padding: '0 24px 0 24px', */}
                                <button style={{ ...BUTTONSTYLE, border: '1px solid transparent', color: 'white', backgroundColor: 'transparent', width: 40, height: 40, padding: 5, margin: 5 }}>
                                    {iconwrapper(FILTERCURSOR_ICON, { width: 30, height: 30, color: '#ffffff', padding: 5, margin: 0, display: 'flex', alignItems: 'center', placeContent: 'center' })}
                                </button>
                                <h4 style={{ fontSize: 18, color: 'white', fontFamily: 'Lato' }}>Filter</h4>
                            </div>
                            {this.render_filtercomp()}
                        </div>}

                    {screen_width <= WEB ? <div className="container"> {filtermodal_mobiletablet}</div> : null}

                    <div style={{ width: screen_width <= WEB ? '100vw' : 'calc( 100% - 220px )', display: 'flex', justifyContent: 'center', alignContent: 'center', justifyItems: 'center', paddingTop: { 'WEB': 30, 'TABLET': 30, 'MOBILE': 5 }[device], height: '100%' }}>
                        <ul style={{ display: 'grid', gridTemplateColumns: `repeat(auto-fit, minmax(${pcwt}px, max-content))`, justifyContent: 'center', width: '100%', padding: 0, margin: 0, }}>
                            {this.projectcards(pcwt)}
                        </ul>
                    </div>

                </div>

                <div className='bottomalrdy' style={{ backgroundColor: 'transparent', height: 10, width: '100%' }} />

            </div >
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectGallery)