import React from 'react'
import { Modal } from "react-bootstrap"
import { GENERAL_GREY, EMPTYSPACE, SS_LINE } from '../../config/constants';
import './Jfmodal.css'
const CLASSNAME_MAP = {
    'WEB': 'Jfmodal_web',
    'Web': 'Jfmodal_web',
    'DESKTOP': 'Jfmodal_web',
    'MOBILE': 'Jfmodal_mobile',
    'Mobile': 'Jfmodal_mobile',
    'TABLET': 'Jfmodal_tablet',
    'FULLSIZE': 'Jfmodal_full'
}
function Jfmodal(props) {
    const { show, content, title, device, onHide, bsSize, onKeyDown, animationstate = true } = props
    const classname = CLASSNAME_MAP[device]
    return (
        <Modal
            animation={animationstate}
            dialogClassName={classname}
            style={{ color: GENERAL_GREY }}
            show={show}
            onHide={onHide}
            bsSize={bsSize}
            onKeyDown={onKeyDown}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            {SS_LINE}
            {EMPTYSPACE}
            {content}
        </Modal>
    )
}

export default Jfmodal
