import React, { useState, useEffect, } from 'react';
import axios from 'axios'
import { Retrieve_personal_info } from '../../config/commonfunctions';
// import {  ControlLabel, FormControl, HelpBlock, FormGroup,  } from 'react-bootstrap'
import {  EMPTYSPACE, PACKAGE_ICON, EMPTY } from '../../config/constants';
import { READ_U_QUOTE, U_ACCEPT_QUOTE, U_REJECT_QUOTE, U_ARCHIVE_QUOTE, READARCHIVE_U_QUOTE } from '../../config/api';
import { Checkdevice } from '../../components/Checkdevice';
import { BUTTONSTYLE } from '../../config/cssconstants';
import refresh from '../../components/refresh';
import { Headtitle } from '../../components';

const { token } = Retrieve_personal_info()

const quotestate_map = { //'#7f7fff' rgb(84, 163, 106) 
    0: <div style={{ backgroundColor: '#f9edc7', color: '#5f5f5f', padding: '6px 10px', borderRadius: 12, }}>Pending</div>,
    1: <div style={{ backgroundColor: '#d2f0cd', color: '#5f5f5f', padding: '6px 10px', borderRadius: 12 }}>Accepted</div>,
    2: <div style={{ backgroundColor: ' #ffe0df', color: '#5f5f5f', padding: '6px 10px', borderRadius: 12 }}>Rejected</div>
}

// function FieldGroup({ id, label, help, ...props }) {
//     return (
//         <FormGroup controlId={id}>
//             <ControlLabel>{label}</ControlLabel>
//             <FormControl {...props} />
//             {help && <HelpBlock>{help}</HelpBlock>}
//         </FormGroup>
//     );
// }

function Status() {

    // const [acceptedquote_ids, setAcceptedquote_ids] = useState([])
    // const [acct_type, setAcct_type] = useState()
    // const [deletequote_ids, setDeletequote_ids] = useState([])
    // const [pendingquote_ids, setPendingquote_ids] = useState([])
    const [quote_ids, setQuote_ids] = useState([])
    const [loaded, setLoaded] = useState(false)
    const CURR_DEVICE = Checkdevice()

    const [acceptdata, setAcceptdata] = useState([false])
    const [rejectdata, setRejectdata] = useState([false])

    useEffect(() => { // componentDidMount
        if (token !== null && token !== EMPTY && token !== undefined) init()
    }, [])

    useEffect(() => {
        if (acceptdata[0]) {
            var quote_id = acceptdata[1]
            var v_username = acceptdata[2]
            let options = {
                method: 'POST',
                url: U_ACCEPT_QUOTE, // ACCEPT OR REJECT ACTION
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { quote_id, v_username }
            }
            axios(options).then((res) => {
                const { message } = res.data//data

                if (message === 'SUCCESS') {
                    init()
                    setAcceptdata(false)
                    setRejectdata(false)
                }
                else if (message === 'FAILURE') {
                    refresh()
                }


            }).catch((e) => {
                refresh()
            })
        }
    }, [acceptdata])

    useEffect(() => {
        if (rejectdata[0]) {
            var quote_id = rejectdata[1]
            var v_username = rejectdata[2]
            let options = {
                method: 'POST',
                url: U_REJECT_QUOTE, // ACCEPT OR REJECT ACTION
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { quote_id, v_username }
            }
            axios(options).then((res) => {
                const { message } = res.data//data

                if (message === 'SUCCESS') {
                    init()
                    setAcceptdata(false)
                    setRejectdata(false)
                }
                else if (message === 'FAILURE') {
                    refresh()
                }
            }).catch((e) => {
                refresh()
            })
        }
    }, [rejectdata])

    function init() {
        let options = {
            method: 'POST',
            url: READ_U_QUOTE,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
        }
        axios(options).then((res) => {
            const { data, message } = res.data

            if (message === 'SUCCESS') {
                // setAcceptedquote_ids(data.acceptedquote_ids)
                // setAcct_type(data.acct_type)
                // setDeletequote_ids(data.deletequote_ids)
                // setPendingquote_ids(data.pendingquote_ids)
                setQuote_ids(data.quote_ids)
                setLoaded(true)
            }


        }).catch((e) => {

        })
    }

    // function onAction(action, quote_id, v_username) {
    //     var link = action === 'ACCEPT' ? U_ACCEPT_QUOTE : U_REJECT_QUOTE

    //     let options = {
    //         method: 'POST',
    //         url: link, // ACCEPT OR REJECT ACTION
    //         headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
    //         data: { quote_id, v_username }
    //     }
    //     axios(options).then((res) => {
    //         const { data, message } = res.data

    //         if (message === 'SUCCESS') {
    //             init()
    //         }
    //         else if (message === 'FAILURE') {
    //             refresh()
    //         }


    //     }).catch((e) => {

    //     })
    // }

    function allQuotes() {
        if (!loaded) return <div />
        return (
            quote_ids.map((item, index) => {
                var { createdAt, currency, fileinfo, name, price, quotestate, _id, v_username, v_displayname, v_vt, v_svt } = item//u_username,
                const card_w = CURR_DEVICE === 'WEB' ? '60%' : '100%'
                var date_created = (new Date(createdAt)).toLocaleDateString()
                var fileinfo_dom = fileinfo.map((link) => {
                    return (
                        <div onClick={() => { window.location = link }} key={Math.random()} style={{ width: 40, height: 40, borderRadius: 5, border: 'thin solid rgb(186, 186, 186)', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 5 }}>
                            {PACKAGE_ICON}
                        </div>
                    )
                })
                var onArchive = () => {
                    if (quotestate === 1) { // quote accepted, can archive
                        let options = {
                            method: 'POST',
                            url: U_ARCHIVE_QUOTE,
                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                            data: { 'quote_id': _id }
                        }
                        axios(options).then((res) => {
                            const { message } = res.data//data
                            if (message === 'SUCCESS') {
                                init()
                            }
                            else {
                                refresh()
                            }
                        }).catch((e) => {
                            refresh()
                        })
                    }
                    else { // cannot be deleted

                    }
                }


                return (
                    <div key={Math.random()} style={{ display: 'flex', flexDirection: 'column', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 5px', width: card_w, height: 'max-content', marginBottom: 40, borderRadius: 10, }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 'max-content', padding: '0px 5px 5px 5px', borderRadius: 10, }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center' }}>
                                <div style={{ fontWeight: 'normal', marginTop: 5 }}>{date_created}</div>
                                <div style={{ fontWeight: 'bold', marginTop: 5, fontSize: 'small', whiteSpace: 'nowrap' }}>{quotestate_map[quotestate]}</div>
                            </div>
                            <h3 style={{ textDecoration: 'underline', display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-start', whiteSpace: 'nowrap', overflowX: 'scroll', overflowY: 'hidden', padding: '0px 5px' }}>
                                {name}
                            </h3>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h4 style={{ fontWeight: '500', padding: '0px 5px', whiteSpace: 'nowrap' }}>{v_displayname}</h4>
                                <div style={{ fontWeight: '500', padding: '0px 5px', color: 'rgb(173, 173, 173)', whiteSpace: 'nowrap' }}>{v_vt + ' & ' + v_svt}</div>
                            </div>
                            {EMPTYSPACE}
                            <div style={{ padding: '0px 5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', color: '#4667b1', width: '100%', borderTop: '1px solid rgb(238, 238, 238)', borderBottom: '1px solid rgb(238, 238, 238)' }}>
                                <h3 >{currency + ' $ ' + price}</h3>

                            </div>
                            {EMPTYSPACE}
                            <div style={{ display: 'flex', flexDirection: 'row', padding: '0px 5px', }}>
                                {fileinfo_dom}
                            </div>
                            {EMPTYSPACE}
                        </div>
                        <div>
                            {
                                quotestate === 0 ?
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', borderTop: '1px solid rgb(238, 238, 238)', alignItems: 'center' }}>
                                        <button
                                            style={{ ...BUTTONSTYLE, width: '50%', border: 'none', borderRadius: '0px 0px 0px 10px' }}
                                            disabled={acceptdata[0]}
                                            onMouseEnter={(event) => { event.target.style.backgroundColor = 'rgb(170,184,194, 0.1)'; }}
                                            onMouseLeave={(event) => { event.target.style.backgroundColor = 'transparent' }}
                                            onClick={() => setAcceptdata([true, _id, v_username])}
                                        >
                                            Accept
                                        </button>
                                        <div style={{ borderLeft: '2px solid rgb(238, 238, 238)', height: '100%', width: '1px' }}>{'\u00A0'}</div>
                                        <button
                                            style={{ ...BUTTONSTYLE, width: '50%', border: 'none', borderRadius: '0px 0px 10px 0px' }}
                                            disabled={rejectdata[0]}
                                            onMouseEnter={(event) => { event.target.style.backgroundColor = 'rgb(170,184,194, 0.1)'; }}
                                            onMouseLeave={(event) => { event.target.style.backgroundColor = 'transparent' }}
                                            onClick={() => setRejectdata([true, _id, v_username])}
                                        >
                                            Reject
                                        </button>
                                    </div>
                                    :
                                    quotestate === 1 ? // quote accepted will allow archive
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', borderTop: '1px solid rgb(238, 238, 238)', alignItems: 'center' }}>
                                            <button
                                                style={{ ...BUTTONSTYLE, width: '100%', border: 'none', borderRadius: '0px 0px 10px 10px', }}
                                                onClick={onArchive}
                                                onMouseEnter={(event) => { event.target.style.backgroundColor = 'rgb(170,184,194, 0.1)'; }}
                                                onMouseLeave={(event) => { event.target.style.backgroundColor = 'transparent' }}
                                            >
                                                Archive
                                        </button>
                                        </div>
                                        :
                                        <div />
                            }
                        </div>
                    </div>
                )

                // return (
                //     <div key={Math.random()} style={{ display: 'flex', flexDirection: 'column', border: 'thin solid #707070', width: card_w, height: 'max-content', padding: '0px 5px 5px 5px', marginBottom: 40, borderRadius: 5, }}>
                //         {
                //             quotestate === 1 ? // quote accepted will allow archive
                //                 <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', }}>
                //                     <button style={{ display: 'flex', width: 'fit-content', color: SOFT_GREY, border: 'none', outline: 'none', padding: 5, backgroundColor: 'transparent' }} onClick={onArchive}>Archive</button>
                //                 </div>
                //                 :
                //                 <div />
                //         }
                //         <h4 style={{ textDecoration: 'underline', display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>{name}</h4>
                //         <h5 style={{ display: 'flex', flexDirection: 'row' }}>
                //             Quote From : <div style={{ fontWeight: 'bold', padding: '0px 5px' }}>{v_username + ' / ' + v_displayname}</div>
                //         </h5>
                //         <h5 style={{ display: 'flex', flexDirection: 'row' }}>
                //             Type : <div style={{ fontWeight: 'bold', padding: '0px 5px' }}>{v_vt + ' & ' + v_svt}</div>
                //         </h5>
                //         <h5 style={{ display: 'flex', flexDirection: 'row' }}>
                //             Status : <div style={{ fontWeight: 'bold', padding: '0px 5px' }}>{quotestate_map[quotestate]}</div>
                //         </h5>
                //         <h5 style={{ display: 'flex', flexDirection: 'row' }}>
                //             Price :
                //         </h5>
                //         <div style={{ display: 'flex', flexDirection: 'row', border: 'thin solid ' + SOFT_GREY, borderRadius: 5, justifyContent: 'center', backgroundColor: 'rgb(239, 239, 239)' }}>
                //             <h3 style={{ paddingRight: 5 }}>{currency}</h3>
                //             <h3>{price}</h3>
                //         </div>
                //         <div style={{ display: 'flex', flexDirection: 'row', padding: '10px 0px' }}>{fileinfo_dom}</div>
                //         {EMPTYSPACE}
                //         {
                //             quotestate === 0 ?
                //                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                //                     <button
                //                         style={{ ...BUTTONSTYLE }}
                //                         disabled={acceptdata[0]}
                //                         onClick={() => setAcceptdata([true, _id, v_username])}
                //                     >
                //                         Accept
                //                     </button>
                //                     {EMPTYSPACE}
                //                     <button
                //                         style={{ ...BUTTONSTYLE }}
                //                         disabled={rejectdata[0]}
                //                         onClick={() => setRejectdata([true, _id, v_username])}
                //                     >
                //                         Reject
                //                     </button>
                //                 </div>
                //                 :
                //                 <div />
                //         }
                //         {EMPTYSPACE}
                //         <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>{date_created}</div>
                //     </div>
                // )
            })
        )
    }

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', paddingBottom: 20, alignItems: 'center', }}>
            {allQuotes()}
        </div>
    )
}

function Archive() {

    const [quotearchive_ids_dom, set_quotearchive_ids_dom] = useState([])
    const CURR_DEVICE = Checkdevice()

    useEffect(() => { // componentDidMount
        if (token !== null && token !== EMPTY && token !== undefined) init()
    }, [])

    function init() {
        let options = {
            method: 'POST',
            url: READARCHIVE_U_QUOTE,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
        }

        axios(options).then((res) => {
            const { data, message } = res.data
            if (message === 'SUCCESS') {
                // const card_w = CURR_DEVICE === 'WEB' ? '70%' : '100%'

                const { quotearchive_ids } = data
                var dom = []
                dom.push(
                    <div key={Math.random()} style={{ width: 'max-content', display: 'flex', flexDirection: 'row', fontSize: '14px', fontWeight: 'bold', textTransform: 'uppercase' }}>
                        <div style={{ width: '150px', maxWidth: '150px', minWidth: '150px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            username
                        </div>
                        <div style={{ width: '150px', maxWidth: '150px', minWidth: '150px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            issue date
                        </div>
                        <div style={{ width: '150px', maxWidth: '150px', minWidth: '150px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            amount
                        </div>
                        <div style={{ width: '150px', maxWidth: '150px', minWidth: '150px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            files
                        </div>
                    </div>
                )
                for (let i = 0; i < quotearchive_ids.length; i++) {
                    var item = quotearchive_ids[i]
                    const { currency, fileinfo, price, u_username, createdAt } = item
                    var date_created = (new Date(createdAt)).toLocaleDateString()
                    var fileinfo_dom = fileinfo.map((link) => {
                        return (
                            <div onClick={() => { window.location = link }} key={Math.random()} style={{ width: 40, height: 40, borderRadius: 5, border: 'thin solid rgb(186, 186, 186)', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 5, }}>
                                {PACKAGE_ICON}
                            </div>
                        )
                    })
                    // borderBottom: 'thin solid rgb(112, 112, 112)'
                    dom.push(
                        <div key={Math.random()} style={{ width: 'max-content', height: 60, display: 'flex', flexDirection: 'row', marginTop: 5, marginBottom: 5, paddingTop: 5, paddingBottom: 5, textAlign: 'center', borderTop: '1px solid rgb(238, 238, 238)' }}>
                            <div style={{ width: '150px', maxWidth: '150px', minWidth: '150px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                {u_username}
                            </div>
                            <div style={{ width: '150px', maxWidth: '150px', minWidth: '150px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                {date_created}
                            </div>
                            <div style={{ width: '150px', maxWidth: '150px', minWidth: '150px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                {currency + ' ' + price}
                            </div>
                            <div style={{ width: '155px', maxWidth: '155px', minWidth: '155px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', whiteSpace: 'nowrap', }}>
                                {fileinfo_dom}
                            </div>
                        </div>
                    )
                }

                set_quotearchive_ids_dom(dom)
            }

        }).catch((e) => {

        })


    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: CURR_DEVICE === 'WEB' || CURR_DEVICE !== 'MOBILE' ? 'center' : 'flex-start', }}>
            {quotearchive_ids_dom}
        </div>
    )
}

function UserQuote(props) {

    const [view, setView] = useState('Status')


    function handleClick(val) {
        setView(val)
    }

    function page() {
        if (view === 'Status') {
            return <Status />
        }
        else if (view === 'Archive') {
            return <Archive />
        }
    }

    return (
        <div style={{ flexDirection: 'column', color: 'rgb(112, 112, 112)', touchAction:'manipulation' }}>
            <Headtitle name={"Transaction & Quote"} color="var(--maindark)" />
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10, alignItems: 'center'}}>
                <div style={{ display: 'flex', flexDirection: 'row', margin:10, padding:10, border:'thin solid rgb(238, 238, 238)', alignItems:'center', borderRadius:5 }}>
                    <button onClick={() => { handleClick('Status') }} style={{ padding: 10, letterSpacing: '0.1em', backgroundColor: view === 'Status' ? 'rgb(239, 239, 239)' : 'transparent', borderRadius: 10, fontWeight: 'bold', outline: 'none', border: 'none' }}>
                        Status
                    </button>
                    {EMPTYSPACE}
                    <button onClick={() => { handleClick('Archive') }} style={{ padding: 10, letterSpacing: '0.1em', backgroundColor: view === 'Archive' ? 'rgb(239, 239, 239)' : 'transparent', borderRadius: 10, fontWeight: 'bold', outline: 'none', border: 'none' }}>
                        Archive
                    </button>
                    {EMPTYSPACE}
                </div>
            </div>
            {EMPTYSPACE}
            <div className='container' style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                {EMPTYSPACE}
                {page()}
                {EMPTYSPACE}
            </div>
        </div>
    )
}

export default UserQuote