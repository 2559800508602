import React from 'react'
import { Icon } from 'react-icons-kit/Icon'
import {arrow_left} from 'react-icons-kit/ikons/arrow_left'
import {arrow_right} from 'react-icons-kit/ikons/arrow_right'

import './Leftrightbtn.css'

// change to icon
function Leftrightbtn({ onLeft, onRight }) {
    return <div className="lrb_root">
        <button className="lrb_left" onClick={onLeft}>
            <Icon size={20} icon={arrow_left} />
        </button>
        <button className="lrb_right" onClick={onRight}>
            <Icon size={20} icon={arrow_right} />
        </button>
    </div>
}

export default Leftrightbtn