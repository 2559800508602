import axios from 'axios'
import {  vendors_contentsizelink_event_map, USER_UPDATER_FILTER, VENDORTYPEINTERFACE_READ_RANDOMVENDORTYPE } from '../../config/api'



export const FETCH_EVENTCONTENTSIZE_DATAINFO_ACTION = 'FETCH_EVENTCONTENTSIZE_DATAINFO_ACTION'
export const FETCH_EVENTCONTENTSIZE_DATAINFO_ACTION_FULFILLED = 'FETCH_EVENTCONTENTSIZE_DATAINFO_ACTION_FULFILLED'
export const FETCH_EVENTCONTENTSIZE_DATAINFO_ACTION_PENDING = 'FETCH_EVENTCONTENTSIZE_DATAINFO_ACTION_PENDING'
export const FETCH_EVENTCONTENTSIZE_DATAINFO_ACTION_REJECTED = 'FETCH_EVENTCONTENTSIZE_DATAINFO_ACTION_REJECTED'
export function fetcheventvendorscontentsize_action(selectedVendorType, user_id) {
    var link = vendors_contentsizelink_event_map[selectedVendorType]
    let options = {
        method: 'POST',
        data: { user_id, selectedVendorType }
    }
    return {
        type: FETCH_EVENTCONTENTSIZE_DATAINFO_ACTION,
        payload: axios.post(link, options)
    }
}

export const FETCH_EVENTVENDORS_DATA_ACTION = 'FETCH_EVENTVENDORS_DATA_ACTION'
export const FETCH_EVENTVENDORS_DATA_ACTION_FULFILLED = 'FETCH_EVENTVENDORS_DATA_ACTION_FULFILLED'
export const FETCH_EVENTVENDORS_DATA_ACTION_PENDING = 'FETCH_EVENTVENDORS_DATA_ACTION_PENDING'
export const FETCH_EVENTVENDORS_DATA_ACTION_REJECTED = 'FETCH_EVENTVENDORS_DATA_ACTION_REJECTED'
export function fetcheventvendors_action(selectedVendorType, requestContent_list) {

    let options = {
        method: 'POST',
        data: { selectedVendorType, requestContent_list }
    }
    return {
        type: FETCH_EVENTVENDORS_DATA_ACTION,
        payload: axios.post(VENDORTYPEINTERFACE_READ_RANDOMVENDORTYPE, options)
    }
}


export const UPDATE_USER_FILTER_ACTION = 'UPDATE_USER_FILTER_ACTION'
export const UPDATE_USER_FILTER_ACTION_FULFILLED = 'UPDATE_USER_FILTER_ACTION_FULFILLED'
export const UPDATE_USER_FILTER_ACTION_PEDING = 'UPDATE_USER_FILTER_ACTION_PEDING'
export const UPDATE_USER_FILTER_ACTION_REJECTED = 'UPDATE_USER_FILTER_ACTION_REJECTED'
export function updateuserfilter_action(token, filter_queries, selectedVendorType, selectedMainCategory = 'Event') {
    let options = {
        method: 'POST',
        url: USER_UPDATER_FILTER,
        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
        data: { filter_queries, selectedVendorType, selectedMainCategory }
    }

    return {
        type: UPDATE_USER_FILTER_ACTION,
        payload: axios(options)
    }


}

