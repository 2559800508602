import React, { useState, useEffect, useLayoutEffect } from 'react'
import { READABLE_VENDORTYPE_MAP, VENDORPROFILE_COLLECTION_VENDORTYPE_WEDDING, WEB, TABLET, GENERAL_GREY, NEAR_TRANSPARENT_GREY, EMPTYSPACE, EMPTY, PRESENCELOC_ABBREV_MAP, MAIN_PINK_COLOR, COLLECTION_VENDORTYPE, WEDDINGVT_ICON_MAP, HOME_COLLECTION_VENDORTYPE_WEDDING, TIER_DIAMOND_POSITION_EXCEL } from '../../../../config/constants';
import { ScreenWidth, Jfmodal, Loading, Checkdevice, Notiboard, Modcfm, Portalmodal } from '../../../../components';
import { BUTTONSTYLE } from '../../../../config/cssconstants';
import { USER_FAVOURITE_VENDOR_VT_BASED, USER_UNFAV_A_VENDOR, USER_UPDATE_FAVVTGRADINGSCHEMA, USER_UPDATE_FAVVTISCORE } from '../../../../config/api';
import { getS3fullpath_withbucketname, iconwrapper, Retrieve_personal_info } from '../../../../config/commonfunctions';
import { ComparisonContent } from '../ComparisonContent/ComparisonContent';
import { FormControl, FormGroup, } from 'react-bootstrap'
import { EMAIL_ICON, TELEPHONE_ICON, THINEMAIL_ICON, THINFACEBOOK_ICON, THININSTAGRAM_ICON, THINLINE_ICON, THINTELEGRAM_ICON, THINWECHAT_ICON, THINWHATSAPP_ICON, CROSSHEART_ICON, VENDORLOOKUP_ICON } from '../../../../config/svgicons';
import { Link } from 'react-router-dom';
import { Renderrequests } from '../../../Vendorlookup/components';
import Enquirycontent from '../../../../components/Enquirycontent/Enquirycontent';
import axios from "axios";
import './Favouritevendor.css'

const FV_TEXT = 'Shortlist vendors by adding them to your favourite list. Vendors are auto categorised based on their primary vendor type, basic informations are display accordingly for better comparing. We also provide you tools to compare each vendor.'
const USER_ENSURE_FAV_SCHEMA_IS_10 = 'Please ensure total weight is equal to 10.'

const mobileportalcss = { width: '100%', height: '100%', backgroundColor: 'white', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)', overflowY: 'auto' }
const webtabletportalcss = { width: 600, height: 650, backgroundColor: 'white', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)' }

const iconstyle_map = {
    'WEB': { width: 24, height: 24, color: 'var(--maindark)', margin: 10 },
    'TABLET': { width: 24, height: 24, color: 'var(--maindark)', margin: 10 },
    'MOBILE': { width: 30, height: 30, color: 'var(--maindark)', margin: 10 }
}
const vtwt = 100

function extract_vtidmap_and_vtnumlikemap(favourite_vendor_raw) {
    var vt_numlike_map = {};
    var vt_id_map = {};
    for (let i = 0; i < favourite_vendor_raw.length; i++) {
        var [_id, vendortype, secondaryvendortype] = favourite_vendor_raw[i]
        // number of like per vt
        if (vendortype in vt_numlike_map) { // vendortype
            vt_numlike_map[vendortype] = vt_numlike_map[vendortype] + 1;
            vt_id_map[vendortype].push(_id);
        }
        else {
            vt_numlike_map[vendortype] = 1;
            vt_id_map[vendortype] = [_id];
        }
        if (secondaryvendortype !== EMPTY) {
            if (secondaryvendortype in vt_numlike_map) { // secondaryvendortype
                vt_numlike_map[secondaryvendortype] = vt_numlike_map[secondaryvendortype] + 1;
                vt_id_map[secondaryvendortype].push(_id);
            }
            else {
                vt_numlike_map[secondaryvendortype] = 1;
                vt_id_map[secondaryvendortype] = [_id];
            }
        }
    }

    return { vt_id_map, vt_numlike_map };
}

function extract_first_char_to_map() {
    var local_vt_firstchar_map = {}
    var temp_arr = []; // get the first char to assist user to search through vendortypes
    for (let i = 0; i < VENDORPROFILE_COLLECTION_VENDORTYPE_WEDDING.length; i++) {
        var first_char = VENDORPROFILE_COLLECTION_VENDORTYPE_WEDDING[i][0];
        if (!temp_arr.includes(first_char)) {
            temp_arr.push(first_char);
            local_vt_firstchar_map[VENDORPROFILE_COLLECTION_VENDORTYPE_WEDDING[i]] = first_char;
        }
    }
    return local_vt_firstchar_map
}

const vt_firstchar_map = extract_first_char_to_map();// obtain first char of vts, and group them based on that

export default function Favouritevendor(data) {
    const { vt } = data

    // unpack data from UserFavourite
    // first get data for accessories 
    // second setState to data
    const [block, setBlock] = useState(false)
    const [mobiledialog, setMobiledialog] = useState(false)// for mobile dialog, trigger to open. ONLY FOR MOBILE
    const [vt_id_map, setVtidmap] = useState(null)
    const [vendorusername_data_map, setVendorusername_data_map] = useState({})

    const [vt_numlike_map, setVtnumlikemap] = useState(null) // set number of like at the sideselectionvt based on the vt
    const [favourite_vendor, setFavouritevendor] = useState(null) // default data from UserFavourite
    const [loggeduserdata, setLoggeduserdata] = useState({
        'name': '',
        'spousename': '',
        'date_w': '',
        'date_e': '',
        'email': '',
        'contact': '',
        'usertype': '', // wedding, event or guest
        'location': '',
    })
    const [vendordetail_obj, setVendordetailobj] = useState([]) // form a map {vt: [vt object]}
    const [selectedvt, setSelectedvt] = useState(EMPTY) // first selected vendortype
    const [grading, setGrading] = useState(false) // open grading dialog
    const [gradingmsg, setGradingmsg] = useState(EMPTY) // msg appear in grading dialog
    const [grademap, setGrademap] = useState({}) // all the grading of all vendortypes { vt:[pricelist, project, review,vt:[pricelist, project, review,.... ]}
    // const [gradepricelist, setGradepricelist] = useState(0) // set for grading schema
    // const [gradeproject, setGradeproject] = useState(0) // set for grading schema
    // const [gradereview, setGradereview] = useState(0) // set for grading schema
    const [grade, setGrade] = useState(0) // only use in Jfmodal
    const [vtiidvt_score_map, setVtiidvtscoremap] = useState({})// {vendortypeinterface _id concat with vendortype : score}< individual vendor score >

    const [abbrev_userlocation, setAbbrev_userlocation] = useState(EMPTY)

    // enquire portal
    const [showenquire, setShowenquire] = useState(false)
    const [showenquireall, setShowenquireall] = useState(false)
    const [showenquirepartial, setShowenquirepartial] = useState(false)
    const [enquiredata, setEnquiredata] = useState([])

    // unfavourite 
    const [heartdata, setHeartdata] = useState({})
    const [heartdialog, setHeartdialog] = useState(false)

    const { token, _id } = Retrieve_personal_info() // for axios call
    const device = Checkdevice()

    function vtSwitch(vt, vti_ids = [], grademap) {
        if (vt !== selectedvt) {
            setSelectedvt(vt) //selected new vt, by highlighting
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (vti_ids.length !== 0) { // no vti_ids we dont have to request for more vendorprofile details
                axios({
                    method: 'POST',
                    url: USER_FAVOURITE_VENDOR_VT_BASED,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                    data: { _id, token, vt, vti_ids }
                }).then(res => {
                    const { data, status } = res
                    var { result } = data
                    if (status === 200) {
                        for (let index = 0; index < result.length; index++) {
                            var item = result[index]
                            var { vendortypeinterface_id } = result[index] // _id
                            var { secondaryvendortype } = vendortypeinterface_id
                            if (vt === secondaryvendortype) {
                                item.secondaryvendortype = vt
                                item.vendortype = vendortypeinterface_id.vendortype
                                item._id = vendortypeinterface_id.vendortype_id
                                item.vendortypeinterface_id = vendortypeinterface_id._id
                            }
                            else {
                                item.vendortypeinterface_id = item.vendortypeinterface_id._id
                            }
                        }

                        result.map((item) => { return item.favourite = true }) // this is to set like for all vendors 

                        let local_vendorusername_data_map = {}
                        for (let i = 0; i < result.length; i++) {
                            const { username } = result[i]
                            local_vendorusername_data_map[username] = result[i]
                        }
                        setVendorusername_data_map(JSON.parse(JSON.stringify(local_vendorusername_data_map)))
                        setVendordetailobj(result)
                    }
                    else {
                        alert('error')
                    }

                })

            }
            else { // set to empty
                setVendordetailobj([])
            }
            // setGradepricelist(grademap[vt][0])
            // setGradeproject(grademap[vt][1])
            // setGradereview(grademap[vt][2])
            setGrade([grademap[vt][0], grademap[vt][1], grademap[vt][2]])
        }
    }

    const numFavbasedonvt = (item, width = 20) => { // number of favourite based on specific vendortype
        return <div style={{ width: width, color: GENERAL_GREY, fontSize: 10, display: 'flex', justifyContent: 'center', padding: '0px 15px 0px 5px' }}>
            {vt_numlike_map && vt_numlike_map[item]
                ? vt_numlike_map[item]
                : null}
        </div>

    }

    const numFirstchar = (item, width = 20) => {
        return <div style={{ width: width, color: GENERAL_GREY, fontSize: 10, padding: '0px 5px 0px 15px' }}>
            {(vt_firstchar_map && vt_firstchar_map[item])
                ? vt_firstchar_map[item]
                : null}
        </div>
    }

    useEffect(() => {
        if (vt !== selectedvt && data.data.complete && !block) { //change in vt && ensure data is compeletely loaded 

            const favourite_vendor_raw = data.data.data.favourite_vendor // [[vendortypeinterface_id,vt, svt],......]
            // set basic properties, 
            if (favourite_vendor_raw) {
                var { vt_id_map, vt_numlike_map } = extract_vtidmap_and_vtnumlikemap(favourite_vendor_raw);
                const { user, fvgradingscheme, fvscore } = data.data.data
                // setState 
                setBlock(true)
                setFavouritevendor(favourite_vendor_raw)
                setLoggeduserdata(JSON.parse(JSON.stringify(user)))
                setVtidmap(vt_id_map)
                setVtnumlikemap(vt_numlike_map)
                // setGradepricelist(fvgradingscheme[vt][0])
                // setGradeproject(fvgradingscheme[vt][1])
                // setGradereview(fvgradingscheme[vt][2])
                setGrademap(fvgradingscheme) // only set once
                setVtiidvtscoremap(fvscore)
                setAbbrev_userlocation(PRESENCELOC_ABBREV_MAP[user.location])
                vtSwitch(vt, vt_id_map[vt], fvgradingscheme)
            }
        }
    }, [data])

    const updateHeart = (vendortypeinterface_id, vendortype_id, vendortype, secondaryvendortype) => {
        setHeartdata({ vendortypeinterface_id, vendortype_id, vendortype, secondaryvendortype })
        setHeartdialog(true)
    }

    const ENQUIRY_PORTAL = <Portalmodal
        portaltype={'commonportal'}
        open={showenquire}
        onClose={() => { setShowenquire(false); setEnquiredata([]) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={{ 'WEB': webtabletportalcss, 'TABLET': webtabletportalcss, 'MOBILE': mobileportalcss }[device]}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <Enquirycontent
            from="Favouritevendor"
            userdata={loggeduserdata}
            data={enquiredata}
            allowtelegram={true}
            allowwhatsapp={true}
            allowchat={true}
            allowemail={true}
            oneormany={'ONE'} // send to ONE or MANY vendors
            vt={selectedvt}
            svt={''}
        />
    </Portalmodal>


    {/* <Renderrequests
                                    displayname={displayname}
                                    enquirebtnid='Favouritevendor'
                                    from='Favouritevendor'
                                    acct_type={'USER'}
                                    button_type={'SMALL'}
                                    acct_contact={loggeduserdata.contact}
                                    acct_email={loggeduserdata.email}
                                    acct_name={loggeduserdata.name}
                                    whatsapp_link={whatsapp_link}
                                    vti_id={vendortypeinterface_id}
                                /> */}
    {/* <Renderrequests
                            displayname={displayname}
                            enquirebtnid='Favouritevendor'
                            from='Favouritevendor'
                            acct_type={'USER'}
                            button_type={'SMALL'}
                            acct_contact={loggeduserdata.contact}
                            acct_email={loggeduserdata.email}
                            acct_name={loggeduserdata.name}
                            whatsapp_link={whatsapp_link}
                            vti_id={vendortypeinterface_id}
                        /> */}

    // const temp = <Renderrequests
    //     displayname={displayname}
    //     enquirebtnid='Favouritevendor'
    //     from='Favouritevendor'
    //     acct_type={'USER'}
    //     button_type={'SMALL'}
    //     acct_contact={loggeduserdata.contact}
    //     acct_email={loggeduserdata.email}
    //     acct_name={loggeduserdata.name}
    //     whatsapp_link={whatsapp_link}
    //     vti_id={vendortypeinterface_id}
    // />

    const enquire_click = (username) => {
        setShowenquire(true)
        setEnquiredata([JSON.parse(JSON.stringify(vendorusername_data_map[username]))])
    }

    // to be continue
    const enquirepartial_click = () => {
        setShowenquirepartial(true)

    }

    // to be continue
    const enquireall_click = () => {
        setShowenquireall(true)
        let local_enquiredata = []
        for (let i = 0; i < Object.keys(vendorusername_data_map).length; i++) {
            local_enquiredata.push(vendorusername_data_map[username])
        }
    }

    const sidebar = <div id='Favouritevendor_sidebar'>
        {VENDORPROFILE_COLLECTION_VENDORTYPE_WEDDING.map((item, index) => {
            const readablevt = READABLE_VENDORTYPE_MAP[item]
            return <button
                className={`Favouritevendor_vt`}
                key={index + item}
                style={{
                    borderTop: (vt_firstchar_map && vt_firstchar_map[item] && index != 0) ? '1px solid var(--lightdark)' : 'none',
                    borderBottom: index === Object.keys(VENDORPROFILE_COLLECTION_VENDORTYPE_WEDDING).length - 1 ? '1px solid var(--lightdark)' : 'none'
                }}
                onClick={() => {
                    vtSwitch(item, vt_id_map[item], grademap)
                }}
            >
                {numFirstchar(item, 30)}
                <div className={`Favouritevendor_readablevttxt Favouritevendor_readablevt${item === selectedvt ? 'selected' : ''}`}>
                    {readablevt}
                </div>
                {numFavbasedonvt(item, 40)}
            </button>
        })}
    </div>

    const maincontent = <div id='Favouritevendor_maincontent'>

        <div id='Favouritevendor_viewmoreroot'>
            <button id='Favouritevendor_viewmorevendorbtn'>
                <div className='Favouritevendor_maincontenticon'>
                    {iconwrapper(WEDDINGVT_ICON_MAP[selectedvt], iconstyle_map[device])}
                </div>
                <div className='Favouritevendor_selectedvttitle'>
                    {READABLE_VENDORTYPE_MAP[selectedvt]}
                </div>
            </button>
        </div>

        <div id='Favouritevendor_spreadsheet'>
            <div id='Favouritevendor_titlerow'>
                <div className='Favouritevendor_singlecell' style={{ minWidth: 50 }}></div>
                <div className='Favouritevendor_singlecell'>Name</div>
                <div className='Favouritevendor_singlecell'>Vendor Type</div>
                <div className='Favouritevendor_singlecell'>Contact Number</div>
                <div className='Favouritevendor_singlecell'>Social</div>
                <div className='Favouritevendor_singlecell'>Email</div>
                <div className='Favouritevendor_singlecell'>Website</div>
                <div className='Favouritevendor_singlecell'>Min-Pricing</div>
                <div className='Favouritevendor_singlecell'>Lookup</div>
                <div className='Favouritevendor_singlecell'>Enquire</div>
                <div className='Favouritevendor_singlecell'>Unfavourite</div>
            </div>
            {vendordetail_obj.map((obj, index) => {
                const { vendortypeinterface_id, vendortype_id, vendortype, secondaryvendortype, displayname, profileimg_id, username, website_link, website_links, whatsapp_link, instagram_link, facebook_link, name, contact, contacts, publicemail, publicemails, tier } = obj
                const { link } = profileimg_id
                const img = <img src={getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', link)} width={25} height={25} className='Favouritevendor_vendorprofileimg' />
                const rowicon_css = { minWidth: 21, minHeight: 21, maxWidth: 21, maxHeight: 21, display: 'flex', placeContent: 'center', padding: 0, margin: 0 }
                const minprice = obj[abbrev_userlocation + 'minprice']

                return <div className='Favouritevendor_spreadsheetcellsrow' key={'row' + index}>
                    <div className='Favouritevendor_nontitlesinglecell ' style={{ minWidth: 50 }}>{img}</div>
                    <div className='Favouritevendor_nontitlesinglecell'>
                        {displayname}
                        {tier === 0
                            ? null
                            : <div className={`VCARD_DIAMONDROOT VCARD_DIAMONDROOT_${tier}`} style={{ zIndex: 1, maxHeight: 20, maxWidth: 20, placeContent: 'center', margin: 0, backgroundColor: 'transparent' }}>
                                <div
                                    className='VCARD_DIAMONDCONTENT'
                                    style={{
                                        backgroundImage: `url('https://asset.weavebliss.com/assets/sprites/diamonds.png')`,
                                        backgroundPositionY: TIER_DIAMOND_POSITION_EXCEL[tier].y + 'px',
                                        width: 13,
                                        height: 13,
                                        backgroundSize: 13,
                                        position: 'absolute'
                                    }}
                                />
                            </div>}
                    </div>
                    <div className='Favouritevendor_nontitlesinglecell'>{READABLE_VENDORTYPE_MAP[selectedvt]}</div>
                    <div className='Favouritevendor_nontitlesinglecell'>{contacts.length > 0 ? contacts[0]['content'] : ''}</div>
                    <div className='Favouritevendor_nontitlesinglecell Favouritevendor_rowiconpack'>
                        <button className='Favouritevendor_cellicon' onClick={() => document.getElementById('VL_insta').click()}>
                            <a id='VL_insta' href={instagram_link} target="_blank" style={{ visibility: 'hidden' }} />
                            {iconwrapper(THININSTAGRAM_ICON, rowicon_css)}
                        </button>
                        <button className='Favouritevendor_cellicon' onClick={() => document.getElementById('VL_fb').click()}>
                            <a id='VL_fb' href={facebook_link} target="_blank" style={{ visibility: 'hidden' }} />
                            {iconwrapper(THINFACEBOOK_ICON, rowicon_css)}
                        </button>
                    </div>
                    <div className='Favouritevendor_nontitlesinglecell'>{publicemails.length > 0 ? publicemails[0]['content'] : ''}</div>
                    <div className='Favouritevendor_nontitlesinglecell'>{website_links.length > 0 ? website_links[0]['content'] : ''}</div>
                    <div className='Favouritevendor_nontitlesinglecell'>{minprice}</div>
                    <div className='Favouritevendor_nontitlesinglecell'>
                        <button className='Favouritevendor_cellicon' onClick={() => document.getElementById(`link_${username}`).click()}>
                            <Link id={`link_${username}`} to={'/vendorlookup/' + username} target="_blank" style={{ visibility: 'hidden' }} />
                            {iconwrapper(VENDORLOOKUP_ICON, rowicon_css)}
                        </button>
                    </div>
                    <div className='Favouritevendor_nontitlesinglecell'>

                        <button className='Favouritevendor_enquirebtn' onClick={() => enquire_click(username)}>Enquire</button>

                    </div>
                    <div className='Favouritevendor_nontitlesinglecell'>
                        <button className='Favouritevendor_cellicon' onClick={() => updateHeart(vendortypeinterface_id, vendortype_id, vendortype, secondaryvendortype)}>{iconwrapper(CROSSHEART_ICON, rowicon_css)}</button>
                    </div>
                </div>
            })}
        </div>
    </div>

    const heartcfm = heartdialog
        ? <Modcfm
            onHide={() => { setHeartdialog(false) }}
            onOk={() => {
                if (heartdialog) {
                    try {
                        const { vendortypeinterface_id, vendortype_id, vendortype, secondaryvendortype } = heartdata

                        const { token, _id, acct_type } = Retrieve_personal_info()
                        if (acct_type === 'USER') {
                            let options = {
                                method: 'POST',
                                url: USER_UNFAV_A_VENDOR,
                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                                data: { _id, token, acct_type, vendortypeinterface_id, vendortype, secondaryvendortype: secondaryvendortype === null ? EMPTY : secondaryvendortype, vendortype_id }
                            }
                            axios(options).then((result) => {
                                const { data, status } = result
                                const { error } = data
                                if (status === 200 && error === false) {

                                    var localvendordetail_obj = [...vendordetail_obj]
                                    var localfavouritevendor = [...favourite_vendor]

                                    for (let i = 0; i < localfavouritevendor.length; i++) { // localfavouritevendor = [ [vendortypeinterface_id, vt, svt], [vendortypeinterface_id, vt, svt], ....]
                                        if (localfavouritevendor[i][0] === vendortypeinterface_id) {
                                            localfavouritevendor.splice(i, 1)
                                        }
                                    }

                                    setFavouritevendor(localfavouritevendor)
                                    for (let i = 0; i < localvendordetail_obj.length; i++) {
                                        if (vendortypeinterface_id === localvendordetail_obj[i].vendortypeinterface_id) {
                                            localvendordetail_obj.splice(i, 1)

                                            var { vt_numlike_map, vt_id_map } = extract_vtidmap_and_vtnumlikemap(localfavouritevendor);
                                            setVtnumlikemap(vt_numlike_map);
                                            setVtidmap(vt_id_map);
                                            setVendordetailobj(localvendordetail_obj);
                                            break
                                        }
                                    }

                                    setHeartdialog(false)

                                    // remove row from rows.
                                }
                            })
                        }
                    }
                    catch (e) {
                        setHeartdata({})
                        setHeartdialog(false)
                    }
                }
            }}
            onNo={() => { setHeartdialog(false) }}
            txt={'Unfavourite vendor. Please be certain.'}
        />
        : null

    const WEB_TABLET_DOM = <div className='Favouritevendor_row' style={{ height: 'calc( 100% - 35px - 85px )' }}>
        {sidebar}
        {maincontent}
        {heartcfm}
    </div>

    return <div id='Favouritevendor_root'>
        {{
            'WEB': WEB_TABLET_DOM,
            'TABLET': WEB_TABLET_DOM,
            'MOBILE': <div className='Favouritevendor_col' style={{ height: '100%' }}>
                <div id='Favouritevendor_txt'>Select the category to view vendors you have favorited.</div>
                <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', overflowY: 'hidden' }}>
                    {HOME_COLLECTION_VENDORTYPE_WEDDING.map((item, index) => {
                        const { value, icon } = item
                        if (value === 'All') return
                        return <div onClick={() => vtSwitch(value, vt_id_map[value], grademap)} className={`MSB_vticon ${selectedvt === value ? 'MSB_vticonselected' : ''}`} key={index + 'vt'} style={{ minWidth: vtwt, maxWidth: vtwt, height: vtwt, placeContent: 'flex-start' }}>
                            {iconwrapper(icon, { width: 30, height: 30, })}
                            <div className='MSB_vticon_vtname' style={{ fontSize: 12 }}>{READABLE_VENDORTYPE_MAP[value]}</div>
                        </div>
                    })}
                </div>
                <div id='Favouritevendor_spreadsheet' >
                    <div id='Favouritevendor_titlerow'>
                        <div className='Favouritevendor_singlecell' style={{ minWidth: 50 }}></div>
                        <div className='Favouritevendor_singlecell'>Name</div>
                        <div className='Favouritevendor_singlecell'>Vendor Type</div>
                        <div className='Favouritevendor_singlecell'>Contact Number</div>
                        <div className='Favouritevendor_singlecell'>Social</div>
                        <div className='Favouritevendor_singlecell'>Email</div>
                        <div className='Favouritevendor_singlecell'>Website</div>
                        <div className='Favouritevendor_singlecell'>Min-Pricing</div>
                        <div className='Favouritevendor_singlecell'>Lookup</div>
                        <div className='Favouritevendor_singlecell'>Enquire</div>
                        <div className='Favouritevendor_singlecell'>Unfavourite</div>
                    </div>
                    {vendordetail_obj.map((obj, index) => {
                        const { vendortypeinterface_id, vendortype_id, vendortype, secondaryvendortype, displayname, profileimg_id, username, website_link, website_links, whatsapp_link, instagram_link, facebook_link, name, contact, contacts, publicemail, publicemails, tier } = obj
                        const { link } = profileimg_id
                        const img = <img src={getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', link)} width={25} height={25} className='Favouritevendor_vendorprofileimg' />
                        const rowicon_css = { width: 20, height: 20, display: 'flex', placeContent: 'center' }
                        const minprice = obj[abbrev_userlocation + 'minprice']

                        return <div className='Favouritevendor_spreadsheetcellsrow' key={'row' + index}>
                            <div className='Favouritevendor_nontitlesinglecell ' style={{ minWidth: 50 }}>{img}</div>
                            <div className='Favouritevendor_nontitlesinglecell'>
                                {tier === 0
                                    ? null
                                    : <div className={`VCARD_DIAMONDROOT VCARD_DIAMONDROOT_${tier}`} style={{ zIndex: 1, maxHeight: 20, maxWidth: 20, placeContent: 'center', margin: 0, backgroundColor: 'transparent' }}>
                                        <div
                                            className='VCARD_DIAMONDCONTENT'
                                            style={{
                                                backgroundImage: `url('https://asset.weavebliss.com/assets/sprites/diamonds.png')`,
                                                backgroundPositionY: TIER_DIAMOND_POSITION_EXCEL[tier].y + 'px',
                                                width: 13,
                                                height: 13,
                                                backgroundSize: 13,
                                                position: 'absolute'
                                            }}
                                        />
                                    </div>}
                                {displayname}
                            </div>
                            <div className='Favouritevendor_nontitlesinglecell'>{selectedvt}</div>
                            <div className='Favouritevendor_nontitlesinglecell'>{contacts.length > 0 ? contacts[0]['content'] : ''}</div>
                            <div className='Favouritevendor_nontitlesinglecell Favouritevendor_rowiconpack'>
                                <button className='Favouritevendor_cellicon' onClick={() => document.getElementById('VL_insta').click()}>
                                    <a id='VL_insta' href={instagram_link} target="_blank" style={{ visibility: 'hidden' }} />
                                    {iconwrapper(THININSTAGRAM_ICON, rowicon_css)}
                                </button>
                                <button className='Favouritevendor_cellicon' onClick={() => document.getElementById('VL_fb').click()}>
                                    <a id='VL_fb' href={facebook_link} target="_blank" style={{ visibility: 'hidden' }} />
                                    {iconwrapper(THINFACEBOOK_ICON, rowicon_css)}
                                </button>
                            </div>
                            <div className='Favouritevendor_nontitlesinglecell'>{publicemails.length > 0 ? publicemails[0]['content'] : ''}</div>
                            <div className='Favouritevendor_nontitlesinglecell'>{website_links.length > 0 ? website_links[0]['content'] : ''}</div>
                            <div className='Favouritevendor_nontitlesinglecell'>{minprice}</div>
                            <div className='Favouritevendor_nontitlesinglecell'>
                                <button className='Favouritevendor_cellicon' onClick={() => document.getElementById(`link_${username}`).click()}>
                                    <Link id={`link_${username}`} to={'/vendorlookup/' + username} target="_blank" style={{ visibility: 'hidden' }} />
                                    {iconwrapper(VENDORLOOKUP_ICON, rowicon_css)}
                                </button>
                            </div>
                            <div className='Favouritevendor_nontitlesinglecell'>
                                <button className='Favouritevendor_enquirebtn' onClick={() => enquire_click(username)}>Enquire</button>

                            </div>
                            <div className='Favouritevendor_nontitlesinglecell'>
                                <button className='Favouritevendor_cellicon' onClick={() => updateHeart(vendortypeinterface_id, vendortype_id, vendortype, secondaryvendortype)}>{iconwrapper(CROSSHEART_ICON, rowicon_css)}</button>
                            </div>
                        </div>
                    })}
                </div>
                {heartcfm}
            </div>
        }[device]}
        {ENQUIRY_PORTAL}
    </div>
}
