import React, { useState, useEffect } from 'react';
import { Checkdevice, Loading, Processmoney } from '../../components';
import { READ_MARKETLOOKUP } from '../../config/api';
import { Retrieve_personal_info } from '../../config/commonfunctions';
import { WHATSAPP_ICON_LARGE, EMPTYSPACE, LINK_ICON, MARKET_NUMMTH_CHARMTH_MAP, FILTERDATE_MAP, TELEGRAM_ICON_LARGE } from '../../config/constants';
import { BUTTONSTYLE } from '../../config/cssconstants';
import axios from 'axios'
import './Marketlookup.css'


const CURRDT = new Date()
const CURRMTH = CURRDT.getMonth()
const CURRYR = CURRDT.getFullYear()
const root_css = { height: 'max-content', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', touchAction: 'manipulation', alignItems: 'center' }
const DEVICE_MTHWT_MAP = { 'MOBILE': 22, 'TABLET': 40, 'WEB': 50 }
const { token } = Retrieve_personal_info()

function Marketlookup(props) {

    const device = Checkdevice()
    const wt = device === 'WEB' ? 720 : device === 'TABLET' ? 630 : 360
    const [firsttime, setFirsttime] = useState(true)
    const [dataloaded, setDataloaded] = useState(false)
    const [data, setData] = useState({})


    useEffect(() => {
        if (firsttime) {
            const { marketlisting_id } = props.match.params
            initFetch(marketlisting_id)
            setFirsttime(false)
        }
    }, [])

    useEffect(() => {
        if (data && Object.keys(data) && Object.keys(data).length > 0) {
            setDataloaded(true)
        }
    }, [data])


    async function initFetch(marketlisting_id) {

        try {
            let options = {
                method: 'POST',
                url: READ_MARKETLOOKUP,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { marketlisting_id }
            }
            await axios(options).then((response) => {
                if (response.status === 200) {
                    const result = response.data.currlist
                    setData(result)
                }
                else window.location = '/'
            })
        }
        catch (e) {
            window.location = '/'
        }
    }

    const titleDom = (title, wt) => {
        const fs = device === 'MOBILE' ? 21 : 32
        const ht = 60
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: wt, minHeight: ht, justifyContent: 'flex-start', alignItems: 'center', fontSize: fs, flexWrap: 'wrap', padding: '15px 0px', color: '#707070', borderRadius: 10, textAlign: 'center' }}>
                {title}
            </div>
        )
    }

    const pricingDom = (oprice, price, currency, wt) => {
        const price_fs = 21
        const oprice_fs = price_fs * 0.8

        return (
            <div style={{ display: 'flex', width: wt, justifyContent: 'center', padding: '10px 0px', flexWrap: 'wrap', fontFamily: 'lato', flexDirection: 'column', alignItems: 'center' }}>
                {oprice ? <del style={{ fontSize: oprice_fs }}>{'$' + Processmoney(oprice) + ' ' + currency}</del> : null}
                <div style={{ color: 'rgb(226, 90, 90)', fontWeight: 'bold', fontSize: price_fs }}>{'$' + Processmoney(price) + ' ' + currency}</div>
            </div>
        )
    }

    const vtDom = (vt, wt) => {

        const fs = 12
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: wt, justifyContent: 'flex-start', fontSize: fs, flexWrap: 'wrap', margin: 5 }}>
                <div style={{ display: 'flex', color: 'white', padding: '6px 8px', margin: '0px 8px 8px 0px', letterSpacing: 0.5, borderRadius: 4, backgroundColor: '#707070' }}>{vt && vt.length > 0 ? vt.toString().toUpperCase() : null}</div>
                <div style={{ display: 'flex', color: 'white', padding: '6px 8px', margin: '0px 8px 8px 0px', letterSpacing: 0.5, borderRadius: 4, backgroundColor: '#707070' }}>{vt && vt.length > 0 ? 'USER' : null}</div>
            </div>
        )
    }


    const descripDom = (descrip, wt) => {
        const fs = 14
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: wt, justifyContent: 'flex-start', fontSize: fs, marginBottom: 20, flexWrap: 'wrap', whiteSpace: 'break-spaces', border: 'thin solid rgb(112 112 112 / 30%)', padding: 10, borderRadius: 5, }}>
                <font style={{ fontWeight: 'bold', fontSize: 16, color: 'rgb(112, 112, 112)' }}>Description</font>
                {EMPTYSPACE}
                <div style={{whiteSpace:'pre-wrap'}}>{descrip && descrip.length > 0 ? descrip.split('/n') : null}</div>
            </div>
        )
    }

    const ddDom = (dd, wt) => {
        const fs = 14
        if (dd.length > 0) {
            const mth_blocked_css = { width: DEVICE_MTHWT_MAP[device], height: DEVICE_MTHWT_MAP[device], backgroundColor: '#80808082', alignItems: 'center', placeContent: 'center', display: 'flex', padding: 5, marginRight: 5, borderRadius: 5, cursor: 'pointer', }
            const mth_css = { width: DEVICE_MTHWT_MAP[device], height: DEVICE_MTHWT_MAP[device], alignItems: 'center', placeContent: 'center', display: 'flex', padding: 5, marginRight: 5, borderRadius: 5, cursor: 'pointer' }
            const yr_css = { fontWeight: 'bold', color: '#707070' }
            return (
                <div style={{ display: 'flex', flexDirection: 'column', width: wt, justifyContent: 'flex-start', fontSize: fs, marginBottom: 20, flexWrap: 'wrap', whiteSpace: 'break-spaces', border: 'thin solid rgb(112 112 112 / 30%)', padding: 10, borderRadius: 5, }}>
                    <font style={{ fontWeight: 'bold', fontSize: 16, color: 'rgb(112, 112, 112)' }}>Delieverable Months</font>
                    {EMPTYSPACE}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {Object.keys(FILTERDATE_MAP).map((year, index_x) => {
                            return (<div key={year + index_x} style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                                <div style={yr_css}>{year}</div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {FILTERDATE_MAP[year].map((mth, index_y) => {
                                        const key = mth + ' / ' + year + index_y
                                        const mth_str = device === 'MOBILE' ? mth : MARKET_NUMMTH_CHARMTH_MAP[mth]
                                        if (parseInt(mth, 10) < parseInt(CURRMTH, 10) && parseInt(year, 10) === parseInt(CURRYR, 10)) {
                                            return <del key={key} style={mth_blocked_css}>{mth_str}</del>
                                        }
                                        else {
                                            return <div key={key} className={dd.indexOf(mth + ' / ' + year) > -1 ? 'dd_selected' : 'dd'} style={mth_css}>{mth_str}</div>
                                        }
                                    })}
                                </div>
                            </div>)
                        })}
                    </div>
                </div>
            )
        }
        else {
            return null
        }


    }

    const tagDom = (tags, wt) => {
        const fs = 14
        if (tags.length > 0 && tags[0].length > 0) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', width: wt, justifyContent: 'flex-start', fontSize: fs, marginBottom: 20, flexWrap: 'wrap', whiteSpace: 'break-spaces', border: 'thin solid rgb(112 112 112 / 30%)', padding: 10, borderRadius: 5, }}>
                    <font style={{ fontWeight: 'bold', fontSize: 16, color: 'rgb(112, 112, 112)' }}>Tags</font>
                    {EMPTYSPACE}
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {tags && tags.map((date, index) => {
                            return <div key={date + index} style={{ display: 'flex', color: '#707070', padding: '6px 8px', letterSpacing: 0.5, borderRadius: 4, border: 'thin solid #707070', backgroundColor: 'white', width: 'max-content', marginRight: 5 }}>
                                {date}
                            </div>
                        })}
                    </div>
                </div>
            )
        }
        else {
            return null
        }

    }


    const vendorDom = (vname, vlink, wt) => {
        const fs = 14
        if (vlink && vlink.length > 0) {
            return (// href={vlink} target={"_blank"}
                <div style={{ color: '#707070', textDecoration: 'none', display: 'flex', flexDirection: 'column', width: wt, justifyContent: 'flex-start', fontSize: fs, flexWrap: 'wrap', whiteSpace: 'break-spaces', border: 'thin solid rgb(112 112 112 / 30%)', padding: 10, borderRadius: 5, }}>
                    <font style={{ fontWeight: 'bold', fontSize: 16, color: 'rgb(112, 112, 112)' }}>Vendor's Info</font>
                    {EMPTYSPACE}
                    <div>{vname}</div>
                    <div onClick={() => {
                        window.open(vlink)
                    }} style={{ padding: 5, boxShadow: 'rgba(0, 0, 0, 0.05) 0px 3px 9px 3px', maxWidth: '100%', overflow: 'hidden', display: 'flex', justifyContent: 'center', border: 'thin solid rgb(221, 221, 221)', borderRadius: 5, cursor: 'pointer' }}>
                        {vlink}
                    </div>
                </div>
            )
        }

    }

    const linksDom = (link1, link2, link3, wt) => {

        const fs = 14
        const link_css = { ...BUTTONSTYLE, marginRight: 5, cursor: 'pointer', textDecoration: 'none', color: '#707070', placeContent: 'center', alignItems: 'center' }
        if (link1.length > 0 || link2.length > 0 || link3.length > 0) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', width: wt, justifyContent: 'flex-start', fontSize: fs, whiteSpace: 'break-spaces', border: 'thin solid rgb(112 112 112 / 30%)', padding: 10, borderRadius: 5, marginBottom: 20 }}>
                    <font style={{ fontWeight: 'bold', fontSize: 16, color: 'rgb(112, 112, 112)' }}>More Information</font>
                    {EMPTYSPACE}
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {link1.length > 0 ? <a style={link_css} href={link1} target="_blank"> {LINK_ICON} </a> : null}
                        {link2.length > 0 ? <a style={link_css} href={link2} target="_blank"> {LINK_ICON} </a> : null}
                        {link3.length > 0 ? <a style={link_css} href={link3} target="_blank"> {LINK_ICON} </a> : null}
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }

    const enquireDom = (contact, telegram, title, wt) => {
        const enquire_msg = "Hi, I am interested in you package: " + title + '. And would like to enquire further about the package.'
        const enquireFunc = () => { window.open('https://api.whatsapp.com/send?phone=' + contact + '&text=' + enquire_msg) }
        return (
            <div style={{ cursor: 'pointer', display: 'flex', padding: 0, border: 'thin solid rgb(221, 221, 221)', position: 'fixed', zIndex: 3, bottom: 0, backgroundColor: 'white', justifyContent: 'center', width: wt, outline: 'none', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 3px 9px 3px', transition: ' background-color 0.25s ease 0s, color 0.25s ease 0s' }}>
                {/* <div style={{ color: '#707070', fontWeight: 'bold', alignItems: 'center', display: 'flex' }}>Enquire</div> */}
                {contact ? <div onClick={enquireFunc} style={{ ...BUTTONSTYLE, border: 'none', color: '#25D366' }}>{WHATSAPP_ICON_LARGE}</div> : null}
                {telegram ? <a href={`tg://resolve?domain=${telegram}`} style={{ ...BUTTONSTYLE, border: 'none', color: '#1f92ce' }}>{TELEGRAM_ICON_LARGE}</a> : null}
            </div>
        )
    }

    if (dataloaded) {
        return (
            <div style={root_css}>
                {titleDom(data.title, wt)}
                {pricingDom(data.oprice, data.price, data.currency, wt)}
                {vtDom(data.vt, wt)}
                {descripDom(data.descrip, wt)}
                {ddDom(data.delievereabledates, wt)}
                {tagDom(data.tags, wt)}
                {linksDom(data.link1, data.link2, data.link3, wt)}
                {vendorDom(data.vname, data.vlink, wt)}
                <div style={{ height: 120 }} />
                {enquireDom(data.contact, data.telegram, data.title, '100%')}
            </div>
        )
    }
    else return <Loading show={false} />

}


export default Marketlookup