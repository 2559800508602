import React, { useEffect, useState } from 'react'
import { PUREARROWLEFT_ICON, ENGLISH_FF, STARTUP_NAME } from '../../../../config/constants'
import './Page.css'

const EMOJISARR = require('./Pageemojis.json')
const EMOJICAT = ['', 'Objects', 'Symbols', 'Flags', 'Smileys & Emotion', 'People & Body', 'Animals & Nature', 'Food & Drink', 'Travel & Places', 'Activities', 'Objects', 'Symbols', 'Flags', 'Smileys & Emotion', 'People & Body', 'Animals & Nature', 'Food & Drink', 'Travel & Places', 'Activities', 'Objects', 'Symbols', 'Flags']

const NAME_TOO_LONG = 'Your name is too long.'
const NAME_TOO_SHORT = 'Your name is too short.'
const MSG_TOO_LONG = 'Your message is too long.'
const MSG_TOO_SHORT = 'Your message is too short.'
const EMOJI_TOO_LONG = 'Exceeding the max limit number of emoji.'

function Page({
    name, onChangename,
    msg, onChangemsg,
    ff, ffselect,
    emojitxt, updateEmoji, rmvEmoji,
    recordsec, audioURL, recordgate, clearrecord, startrecord, stoprecord, isRecording,
    img, imgref, uploadImg, clearImg, imgrefClick,
    submitblock, onSubmit,
    MAXEMOJI, MINNAME, MAXNAME, MINMSG, MAXMSG

}) {
    const [currpage, setCurrpage] = useState(0)
    const [curremojisarr, setCurremojisarr] = useState([])
    const [currcat, setCurrcat] = useState('')
    const [err, setErr] = useState('')

    const maincolor = 'white'
    const subcolor = 'black'

    useEffect(() => {
        document.body.style.backgroundColor = "#fed8e0";
        document.body.style.position = "relative";
        document.body.style.overflow = "hidden";
        document.body.style.display = "block";
        // document.body.style.height = "100vh";
        document.body.style.top = 0
        document.body.style.right = 0
        document.body.style.bottom = 0
        document.body.style.left = 0

        document.getElementById('root').style.overflow = "hidden";
        document.getElementById('root').style.position = "relative";

        document.getElementById('app-root').overflow = "hidden";
        document.getElementById('app-root').position = "fixed";

        setCurremojisarr(EMOJISARR)

    }, [])
    const incpage = () => {
        window.scrollTo(0, 0);
        var gterr = false
        if (currpage === 1) {// name page
            if (name.length < MINNAME) {
                setErr(NAME_TOO_SHORT)
                gterr = true
            }
            if (name.length > MAXNAME) {
                setErr(NAME_TOO_LONG)
                gterr = true
            }
        }
        else if (currpage === 2) { // msg page
            if (msg.length < MINMSG) {
                setErr(MSG_TOO_SHORT)
                gterr = true
            }
            if (msg.length > MAXMSG) {
                setErr(MSG_TOO_LONG)
                gterr = true
            }
        }
        else if (currpage === 3) { // emoji page
            if (emojitxt.length > MAXEMOJI) {
                setErr(EMOJI_TOO_LONG)
                gterr = true
            }
        }

        if (!gterr) {
            setErr('')
            setCurrpage(currpage + 1)
        }
    }
    const decpage = () => {
        window.scrollTo(0, 0);
        setCurrpage(currpage - 1)
        setErr('')
    }
    const updatecat = (e) => {
        const { value } = e.target
        setCurrcat(value)
        if (value === '') {
            setCurremojisarr(EMOJISARR);
        }
        else {
            var new_curremojisarr = []
            for (let i = 0; i < EMOJISARR.length; i++) {
                const { category } = EMOJISARR[i]
                if (category === value) {
                    new_curremojisarr.push(EMOJISARR[i])
                }
            }
            setCurremojisarr(new_curremojisarr)
        }
    }

    const nextbtn = <button id="gbpage_nextbtn" style={{ backgroundColor: maincolor, color: subcolor, border: '1px solid ' + subcolor }} onClick={incpage}>Next</button>
    const backbtn = <button id="gbpage_backbtn" style={{ backgroundColor: subcolor, border: '1px solid ' + subcolor }} onClick={decpage}>{PUREARROWLEFT_ICON}</button>

    const firstpage = <div id="gbpage_root">
        <div id="gbpage_upper" style={{ touchAction: 'manipulation', userSelect: 'none' }}>
            <div id="gbpage_title">Welcome to {`${STARTUP_NAME}`}</div>
            <div id="gbpage_title">Digital Guestbook</div>
        </div>
        <div id="gbpage_lower" style={{ touchAction: 'manipulation', userSelect: 'none' }}>
            <div id="gbpage_btnrow">
                <button id="gbpage_nextbtn" style={{ backgroundColor: maincolor, color: subcolor, border: '1px solid ' + subcolor }} onClick={incpage}>Begin</button>
            </div>
            <div id="gbpage_err">{err}</div>
        </div>
    </div>

    const secpage = <div id="gbpage_root">
        <div id="gbpage_upper">
            <div id="gbpage_namerow">
                <div id="gbpage_name">Your Name</div>
                <div >{`${name.length}/${MAXNAME}`}</div>
            </div>
            <input id="gbpage_input" onChange={onChangename} value={name}/>
        </div>
        <div id="gbpage_lower">
            <div id="gbpage_btnrow">
                {backbtn}
                {nextbtn}
            </div>
            <div id="gbpage_err">{err}</div>
        </div>
    </div>

    const thirdpage = <div id="gbpage_root">
        <div id="invisible_top" style={{width:0, height:0}}/>
        <div id="gbpage_upper" style={{ placeContent: 'start' }}>
            <div id="gbpage_namerow">
                <div id="gbpage_name">{`Message (Min ${MINMSG} Max ${MAXMSG} chars)`}</div>
                <div >{`${msg.length}/${MAXMSG}`}</div>
            </div>
            <textarea id="gbpage_txtarea" onChange={onChangemsg} style={{ fontFamily: ff }} value={msg} />
            <select id="gbpage_ffdropdown" onChange={ffselect} value={ff}>
                {['', ...ENGLISH_FF].map((ff, i) => { return <option key={'ff' + i}>{ff}</option> })}
            </select>
        </div>
        <div id="gbpage_btnrow">
            {backbtn}
            {nextbtn}
        </div>
        <div id="gbpage_err">{err}</div>
    </div>

    const fourthpage = <div id="gbpage_root">
        <div id="gbpage_upper" style={{ placeContent: 'start' }}>
            <div id="gbpage_namerow">
                <div id="gbpage_name">Emoji Wishes</div>
                <div >{`${emojitxt.length}/${MAXEMOJI}`}</div>
            </div>
            <div id="gbpage_emojiinput">
                {emojitxt && emojitxt.map((singleemoji, indx) => {
                    return <button id="gbpage_emojibtn" key={'singleemoji' + indx} onClick={rmvEmoji} value={indx}>{singleemoji}</button>
                })}
            </div>
            <div id="gbpage_emojikeyboard">
                <div id="gbpage_name">Category</div>
                <select id="gbpage_catselect" onChange={updatecat} value={currcat}>
                    {EMOJICAT.map((cat, i) => { return <option key={'cat' + i}>{cat}</option> })}
                </select>
                <div id="gbpage_slidercontainer">
                    {curremojisarr.map(({ emoji }, index) => {
                        return <span id="gbpage_singleemoji" key={index + 'emoji'} onClick={() => updateEmoji(emoji)}>{emoji}</span>
                    })}
                </div>
                <div id="gbpage_emojisearch">
                    <input id="gbpage_emojisearchinput" onChange={(e) => {
                        var new_curremojisarr = []
                        for (let i = 0; i < EMOJISARR.length; i++) {
                            const { tags } = EMOJISARR[i]
                            for (let j = 0; j < tags.length; j++) {
                                var tag = tags[j]
                                if (tag === e.target.value) {
                                    new_curremojisarr.push(EMOJISARR[i])
                                    break
                                }
                            }
                        }
                        setCurremojisarr(new_curremojisarr)
                    }} />
                </div>
            </div>
        </div>
        <div id="gbpage_btnrow">
            {backbtn}
            {nextbtn}
        </div>
        <div id="gbpage_err">{err}</div>
    </div>

    const fifthpage = <div id="gbpage_root">
        <div id="gbpage_upper" style={{ placeContent: 'start' }}>
            <div id="gbpage_namerow">
                <div id="gbpage_name">Audio ( 15s )</div>
                {recordsec > 0 ? <div>{recordsec + 's'}</div> : null}
            </div>
            <div id="gbpage_audiobtnsrow">
                {audioURL && audioURL.length > 0
                    ? <button id="gbpage_audioclear" onClick={clearrecord}>Clear Record</button>
                    : <button id={recordgate ? "gbpage_audiostartrecord" : "gbpage_startrecord"} onClick={startrecord} disabled={isRecording} >Start Recording</button>}
                {recordgate
                    ? <button id="gbpage_audiostop" onClick={stoprecord} disabled={!isRecording}>Stop Recording</button>
                    : null}
            </div>
            {!recordgate && audioURL && audioURL.length > 0 ? <audio className="Gbguest_audio" src={audioURL} controls /> : null}
        </div>
        <div id="gbpage_btnrow">
            {backbtn}
            {nextbtn}
        </div>
        <div id="gbpage_err">{err}</div>
    </div>

    const sixthpage = <div id="gbpage_root">
        <div id="gbpage_upper" style={{ placeContent: 'start' }}>
            <div id="gbpage_namerow">
                <div id="gbpage_name">Image</div>
                <div>{`${img ? 1 : 0}/1`}</div>
            </div>
            <input ref={imgref} className="gbpage_invisibleinput" accept="image/*" id="formControlsFile" type='file' name="input-file" onChange={uploadImg} />
            {img
                ? <div id="gbpage_img" style={{ backgroundImage: `url(${img})` }} />
                : null}
            {img
                ? <button id="gbpage_imgbtn" onClick={clearImg}>Clear</button>
                : <button id="gbpage_imgbtn" onClick={imgrefClick}>Upload Image</button>}
        </div>
        <div id="gbpage_btnrow">
            {backbtn}
            {nextbtn}
        </div>
        <div id="gbpage_err">{err}</div>
    </div>

    const seventhpage = <div id="gbpage_root" style={{ placeContent: 'center' }}>
        <div id="gbpage_lower" style={{ touchAction: 'manipulation', userSelect: 'none' }}>
            <div id="gbpage_btnrow">
                {backbtn}
                {submitblock
                    ? <button id="gbpage_nextbtn" style={{ backgroundColor: maincolor, color: subcolor, border: '1px solid ' + subcolor }} >Sending...</button>
                    : <button id="gbpage_nextbtn" style={{ backgroundColor: maincolor, color: subcolor, border: '1px solid ' + subcolor }} onClick={onSubmit}>Send</button>}
            </div>
        </div>
    </div>


    // return [firstpage, secpage, thirdpage, fourthpage, fifthpage, sixthpage, seventhpage][currpage]
    return [firstpage, secpage, thirdpage, fifthpage, sixthpage, seventhpage][currpage]

}

export default Page