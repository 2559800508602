import React, { useState, useEffect } from 'react';
import { BUTTONSTYLE } from '../../../../config/cssconstants';
import { EMPTYSPACE, GUESTCATEGORY_DETAIL_MAP, EMPTY, MENU_GUEST_COLOR, CURRENCY_ARR, COUNTRIES, SUPPORT_EMAIL } from '../../../../config/constants';
import { USER_READHOST, USER_CREATEHOST, USER_DELETESINGLEHOST, USER_ACCEPTUSER, USER_REJECTUSER } from '../../../../config/api';
import axios from 'axios';
import { Ticket, Jfmodal, Checkdate, Loading } from '../../../../components';//Processdate
import DatePicker from "react-datepicker";
import '../../../../../node_modules/react-datepicker/dist/react-datepicker-cssmodules.css';
import './UserHomeHost.css'

const PROPOSITION = 'Host your wedding or event for strangers to join.'
const selectedcss = { backgroundColor: MENU_GUEST_COLOR, border: 'thin solid white', color: 'white', display: 'flex', flexDirection: 'column', alignContent: 'center', borderRadius: 5, justifyContent: 'center', textAlign: 'center', padding: '0px 5px', margin: '5px 10px 10px 10px', minWidth: 155, minHeight: 50, outline: 'none', alignItems: 'center' }
const unselectedcss = { backgroundColor: 'white', border: 'thin solid rgb(158, 158, 158)', color: 'rgb(112, 112, 112)', display: 'flex', flexDirection: 'column', alignContent: 'center', borderRadius: 5, justifyContent: 'center', textAlign: 'center', padding: '0px 5px', margin: '5px 10px 10px 10px', minWidth: 155, minHeight: 50, outline: 'none', alignItems: 'center' }


const createHost = async (props, selectedtype, title, date_arr, location, address, food, cost, currency, numticket, itinerary, setData, setHoststatus, ) => {

    var msg

    if (!GUESTCATEGORY_DETAIL_MAP['Wedding Type'].includes(selectedtype) && !GUESTCATEGORY_DETAIL_MAP['Event Type'].includes(selectedtype)) {
        msg = 'Please select a type.'
        alert(msg)
        return
    }

    if (title === '' || title.length < 5) {
        msg = 'Title has to be at least 5 characters.'
        alert(msg)
        return
    }

    if (date_arr && (date_arr.length === 0 || date_arr.length > 5)) { // and cannt be today's date
        msg = 'Date cannot be empty and cannot be more than 5 dates'
        alert(msg)
        return
    }

    if (location === '') {
        msg = 'Please select a location.'
        alert(msg)
        return
    }

    if (address === '' || address.length < 5) {
        msg = 'Address cannot be empty and has to be at least 5 characters.'
        alert(msg)
        return
    }

    if (food === '' || food.length < 5) {
        msg = 'Location cannot be empty and has to be at least 5 characters.'
        alert(msg)
        return
    }

    if (!Number(cost) && cost.length > 1) {
        msg = 'Cost cannot be empty and has to be at least 1 digit'
        alert(msg)
        return
    }

    if (!CURRENCY_ARR.includes(currency)) {
        msg = 'Please select a currency'
        alert(msg)
        return
    }


    if (parseInt(numticket, 10) < 1) {
        msg = 'Number of ticket must be above 1'
        alert(msg)
        return
    }

    if (itinerary.length > 2000) {
        msg = 'Are you serious?'
        alert(msg)
        return
    }

    try {
        let options = {
            method: 'POST',
            url: USER_CREATEHOST,
            headers: { 'Authorization': 'Bearer ' + props.token, 'Content-Type': 'application/json', },
            data: { selectedtype, title, date_arr, address, location, food, cost, currency, numticket, itinerary }
        };
        await axios(options).then((response) => {
            if (response.status === 200) {
                const { data } = response
                const { message } = data
                if (message === 'PROFILEIMG_NEED_UPDATE') {
                    alert('Please update your profile image.')
                }
                else if (message === 'SUCCESS') {
                    setHoststatus('SHOW_STATUS')
                    setData([response.data.data]) // userhost data
                    return
                }
                else {
                    window.location.reload();
                }
            }
            else {
                window.location.reload();
            }
        });
    }
    catch (e) {
        window.location.reload();
    }

}

const deleteTicket = async (props, _id) => {
    try {
        let options = {
            method: 'POST',
            url: USER_DELETESINGLEHOST,
            headers: { 'Authorization': 'Bearer ' + props.token, 'Content-Type': 'application/json', },
            data: { userhost_id: _id }
        };
        await axios(options).then((response) => {
            if (response.status === 200) {
                window.location.reload();
            }
            else {
                window.location.reload();
            }
        });
    }
    catch (e) {
        window.location.reload();
    }

}

function UserHomeHost(props) {

    const [firsttime, setFirsttime] = useState(true)
    const [hoststatus, setHoststatus] = useState('Loading')
    const [guestcattype, setGuestcattype] = useState(EMPTY)

    const [selectedtype, setSelectedtype] = useState(EMPTY)
    const [title, setTitle] = useState(EMPTY)
    const [date, setDate] = useState(EMPTY)
    const [date_arr, setDate_arr] = useState([])
    const [location, setLocation] = useState(EMPTY)
    const [address, setAddress] = useState(EMPTY)
    const [food, setFood] = useState(EMPTY)
    const [numticket, setNumticket] = useState(0)
    const [cost, setCost] = useState(EMPTY)
    const [currency, setCurrency] = useState(EMPTY)
    const [itinerary, setItinerary] = useState(EMPTY)

    const [iuinfo, setIuinfo] = useState(false) // interested user info
    const [iuname, setIuname] = useState(EMPTY) // interested user name
    const [iuinfodata, setIuinfodata] = useState([])// interested user info data

    const [data, setData] = useState({}) // store new object or old objects

    useEffect(() => {

        if (firsttime) {

            async function fetchData() {
                try {
                    let options = {
                        method: 'POST',
                        url: USER_READHOST,
                        headers: { 'Authorization': 'Bearer ' + props.token, 'Content-Type': 'application/json', },
                    }
                    await axios(options).then((response) => {
                        if (response.status === 200) {
                            const { message } = response.data
                            if (message === 'PERMIT_CHANGE') {
                            }
                            else if (message === 'SHOW_STATUS') {
                                setData(response.data.result)

                            }
                            setHoststatus(message)
                        }
                        else {
                            window.location.reload()
                        }
                    })
                } catch (e) {
                    console.error(e);
                }
            }

            const { usertype } = props



            fetchData()
            setFirsttime(false)
            setGuestcattype(usertype === 'Event' ? 'Event Type' : 'Wedding Type')



        }

    }, [firsttime])

    const { device = 'WEB' } = props

    const createbtn = (
        <div style={{ width: '100%', height: '40px', display: 'flex', justifyContent: 'center' }}>
            <button
                style={{ ...BUTTONSTYLE }}
                onClick={() => { createHost(props, selectedtype, title, date_arr, location, address, food, cost, currency, numticket, itinerary, setData, setHoststatus) }}
            >
                Create
            </button>
        </div>
    )

    const formdetails_permit = () => {
        var subtitle = selectedtype && props.usertype ? selectedtype : props.usertype
        var title_qn = 'Title'
        var date_qn = 'When is your ' + subtitle + ' date?'
        var loc_qn = 'Address of your ' + subtitle + '?'
        var food_qn = 'Type of food?'
        var cost_qn = 'Fee per ticket?'
        var numticket_qn = 'Number of ticket available?'
        var itinerary_qn = 'Tell us more about the itinerary of your ' + subtitle + '?'
        return (
            <div style={{ display: 'flex', flexDirection: 'column', padding: 5, width: '100%', }}>

                {/* TITLE */}
                <h5 style={{ display: 'flex', color: '#707070', marginBottom: 0 }}>{title_qn} </h5>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 5 }}>
                    <input
                        className="form-control"
                        id="title-guest"
                        pattern="[A-Za-z]"
                        value={title}
                        onChange={(e) => { setTitle(e.target.value) }}
                    />
                </div>

                <div style={{ paddingTop: 10 }} />

                {/* DATE */}
                <h5 style={{ display: 'flex', color: '#707070', marginBottom: 0 }}>{date_qn} </h5>
                <div style={{ display: 'flex', flexDirection: 'column', border: 'thin solid rgb(225, 225, 225)' }}>
                    <div className='userhomehost_txtfield' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                        {
                            device === 'MOBILE' ?
                                // <TextField
                                //     id="date"
                                //     type="date"
                                //     InputLabelProps={{ shrink: true, }}
                                //     value={date}
                                //     style={{ backgroundColor: 'white', height: 50, borderRadius: 7, width: '85%' }}
                                //     InputProps={{ style: { fontSize: 15, height: 50, width: '100%', paddingLeft: 10 } }}
                                //     onChange={(e) => {
                                //         const { value } = e.target
                                //         var dateobj = new Date(value)
                                //         var processed_date = Processdate(dateobj)
                                //         setDate(processed_date)
                                //     }}
                                // />
                                <div style={{backgroundColor:'red'}}>need to replace with datepicker next time</div>
                                :
                                <div className="customDatePickerWidth" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, padding: '5px 0px 5px 5px', width: '100%' }}>
                                    <DatePicker
                                        dateFormat={'dd/MM/yyyy'}
                                        selected={date}
                                        fixedHeight
                                        onChange={(value) => {
                                            var dateobj = new Date(value)
                                            setDate(dateobj)
                                        }}
                                    />
                                </div>
                        }
                        <button
                            style={{ ...BUTTONSTYLE, padding: 5, margin: 5, width: '15%' }}
                            onClick={() => {
                                // .toLocaleDateString()
                                if (!date_arr.includes(date.toLocaleDateString()) && date.toLocaleDateString() !== EMPTY && Checkdate(date)) { // has to be unique
                                    setDate_arr([...date_arr, date.toLocaleDateString()])
                                }
                            }}
                        >
                            Add
                        </button>
                    </div>
                    <div style={{ height: 50, width: '100%', overflowX: 'auto', overflowY: 'hidden', display: 'flex', flexDirection: 'row', }}>
                        {date_arr.map((dt, index) => {
                            return (
                                <button
                                    key={dt + index}
                                    style={{ padding: '3px 5px', margin: '3px 5px', height: 40, border: 'thin solid rgb(225, 225, 225)', borderRadius: 3, textAlign: 'center', alignItems: 'center', display: 'flex', outline: 'none', backgroundColor: 'white' }}
                                    onClick={() => {
                                        var new_date_arr = [...date_arr]
                                        var index_of_date = date_arr.indexOf(dt)
                                        new_date_arr.splice(index_of_date, 1)
                                        setDate_arr(new_date_arr)
                                    }}
                                >
                                    {dt}
                                </button>
                            )
                        })}
                    </div>
                </div>

                <div style={{ paddingTop: 5 }} />

                {/* ADDRESS */}
                <h5 style={{ display: 'flex', color: '#707070', marginBottom: 0 }}>{loc_qn} </h5>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5, width: '100%' }}>
                    <input
                        className="form-control"
                        id="address-invited"
                        pattern="[A-Za-z]"
                        value={address}
                        onChange={(e) => { setAddress(e.target.value) }}
                    />
                    <div style={{ display: 'flex', padding: 5, }} />

                    <select
                        style={{ outline: 'none', backgroundColor: 'white', minWidth: 90, maxWidth: 90 }}
                        onChange={(e) => { setLocation(e.target.value) }}
                        value={location}
                    >
                        {COUNTRIES.map((item, index) => {
                            return <option key={index + item} value={item} >{item}</option>
                        })}
                    </select>
                </div>

                {/* FOOD */}
                <h5 style={{ display: 'flex', color: '#707070', marginBottom: 0 }}>{food_qn} </h5>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 5 }}>
                    <input
                        className="form-control"
                        id="guests-invited"
                        pattern="[A-Za-z]"
                        value={food}
                        onChange={(e) => { setFood(e.target.value) }}
                    />
                </div>

                {/* COST */}
                <h5 style={{ display: 'flex', color: '#707070', marginBottom: 0 }}>{cost_qn} </h5>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                    <input
                        className="form-control"
                        id="cost"
                        pattern="\d*"
                        value={cost}
                        onChange={(e) => { setCost(parseInt(e.target.value, 10)) }}
                    />
                    <div style={{ display: 'flex', padding: 5, }} />
                    <select
                        style={{ outline: 'none', backgroundColor: 'white', minWidth: 90, maxWidth: 90 }}
                        onChange={(e) => { setCurrency(e.target.value) }}
                        value={currency}
                    >
                        {[...CURRENCY_ARR, EMPTY].map((item, index) => {
                            return <option key={index + item} value={item} >{item}</option>
                        })}
                    </select>
                </div>

                {/* NUMBER TICKET*/}
                <h5 style={{ display: 'flex', color: '#707070', marginBottom: 0 }}>{numticket_qn} </h5>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                    <input
                        className="form-control"
                        type="number"
                        id="numberticket"
                        placeholder="1"
                        pattern="\d*"
                        value={numticket}
                        onChange={(e) => { setNumticket(e.target.value) }}
                    />
                </div>

                {/*ITINERARY*/}
                <h5 style={{ display: 'flex', color: '#707070', marginBottom: 0 }}>{itinerary_qn} </h5>
                <textarea
                    style={{ width: '100%', height: 100, overflow: 'hidden', overflowWrap: 'break-word', resize: 'none', outline: 'none', borderColor: 'transparent', border: '1px solid #ccc', marginTop: 5 }}
                    value={itinerary}
                    onChange={(event) => { setItinerary(event.target.value) }}
                />

            </div>
        )
    }

    const selecttype_permit = () => {

        const renderWedtypecards_permit = () => {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', }}>
                    <div style={{ display: 'flex', flexDirection: 'column', border: 'thin solid rgba(225, 225, 225, 1)', marginBottom: 5 }}>
                        <div style={{ display: 'flex', padding: '0px 5px' }}> Wedding</div>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                            {
                                GUESTCATEGORY_DETAIL_MAP['Wedding Type'].map((item, index) => {
                                    return (
                                        <button
                                            onClick={() => { setSelectedtype(item) }}
                                            key={item + index}
                                            style={{ ...selectedtype === item ? selectedcss : unselectedcss }}
                                        >
                                            {item}
                                        </button>
                                    )
                                })
                            }
                            <div style={{ padding: 5, margin: 5 }} />
                        </div>
                    </div>
                </div>
            )
        }

        const renderEventtypecards_permit = () => {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', border: 'thin solid rgba(225, 225, 225, 1)', marginBottom: 5 }}>
                        <div style={{ display: 'flex', padding: '0px 5px' }}> Event</div>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                            {
                                GUESTCATEGORY_DETAIL_MAP['Event Type'].map((item, index) => {
                                    return (
                                        <button
                                            onClick={() => { setSelectedtype(item) }}
                                            key={item + index}
                                            style={{ ...selectedtype === item ? selectedcss : unselectedcss, }}
                                        >
                                            {item}
                                        </button>
                                    )
                                })
                            }
                            <div style={{ padding: 5, margin: 5 }} />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'row', padding: 5, width: '100%' }}>
                {
                    guestcattype === 'Wedding Type' ?
                        <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', WebkitOverflowScrolling: 'touch', }}>
                            {renderWedtypecards_permit()}
                            <div style={{ paddingLeft: 5 }} />
                            {renderEventtypecards_permit()}
                        </div>
                        :
                        <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', WebkitOverflowScrolling: 'touch', }}>
                            {renderEventtypecards_permit()}
                            <div style={{ paddingLeft: 5 }} />
                            {renderWedtypecards_permit()}
                        </div>
                }
            </div>
        )
    }

    const renderHost = () => {
        if (hoststatus === 'PERMIT_CREATE') {
            return (
                <div key={'Allow'}>
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'flex-start', minHeight: 300, justifyContent: 'center', width: '100%' }}>
                        <h5 style={{ padding: 0, margin: 0, paddingLeft: 5 }}>Type of Host</h5>
                        {selecttype_permit()}
                        <div style={{ paddingBottom: 5 }} />
                        {formdetails_permit()}
                    </div>
                    <div style={{ paddingBottom: 10 }} />
                    {createbtn}
                </div>
            )
        }
        else if (hoststatus === 'SHOW_STATUS') {

            var data_dom = []

            for (let i = 0; i < data.length; i++) {
                const curr_id = data[i]._id
                const iunames = data[i].iunames
                const iunames_accepted = data[i].iunames_accepted
                const iunames_successpayment = data[i].iunames_successpayment
                const iunames_info_map = data[i].iunames_info_map
                const ticket_dom = (
                    <Ticket
                        onDelete={() => {
                            if (iunames_successpayment && iunames_successpayment.length > 0) {
                                alert(`Please contact us at ${SUPPORT_EMAIL} to delete current ticket.`)
                                return
                            }

                            if (window.confirm('Once you delete a ticket, there is no going back. Please be certain.')) {
                                deleteTicket(props, curr_id)
                            }
                            else {
                                return
                            }
                        }}
                        data={data[i]}
                        verifiedcheck={true}
                    />
                )

                data_dom.push(
                    <div key={data[i].updatedAt} style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', }}>
                            {ticket_dom}
                        </div>
                        {iunames ?
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                                    By clicking ‘Accept’ you are agreeing to sell your ticket to the user.
                                    Please note that the action is irreversible.
                                    Once the user makes the payment, you will be informed and you are obliged to fulfill the items that you had offered.
                                    And payment would be sent to you 3 days after the whole wedding/event is over.
                                </div>
                                {EMPTYSPACE}
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 'max-content', justifyContent: 'center' }}>
                                    <div style={{ display: 'flex', padding: 5, margin: 5, minWidth: 113, maxWidth: 113, justifyContent: 'center', fontWeight: 'bold' }}>Username</div>
                                    <div style={{ display: 'flex', padding: 5, margin: 5, minWidth: 113, maxWidth: 113, justifyContent: 'center', fontWeight: 'bold' }}>Details </div>
                                    <div style={{ display: 'flex', padding: 5, margin: 5, minWidth: 113, maxWidth: 113, justifyContent: 'center', fontWeight: 'bold' }}>Status</div>
                                </div>
                                {iunames.map((iuname) => {
                                    var lastbtn
                                    if (iunames_successpayment.includes(iuname)) {
                                        lastbtn = <button style={{ ...BUTTONSTYLE, display: 'flex', padding: 5, minWidth: 113, maxWidth: 113, justifyContent: 'center', borderRadius: 5 }}>Paid</button>
                                    }
                                    else if (iunames_accepted.includes(iuname)) {
                                        lastbtn = <button style={{ ...BUTTONSTYLE, display: 'flex', padding: 5, minWidth: 113, maxWidth: 113, justifyContent: 'center', borderRadius: 5 }}>Accepted</button>
                                    }
                                    else {
                                        lastbtn = (
                                            <div style={{ display: 'flex', flexDirection: 'row', minWidth: 113, maxWidth: 113, overflowX: 'auto' }}>
                                                <button
                                                    style={{ ...BUTTONSTYLE, display: 'flex', padding: 5, width: '47.5%', marginRight: '2.5%', justifyContent: 'center', borderRadius: 5 }}
                                                    onClick={async () => {
                                                        try {
                                                            let options = {
                                                                method: 'POST',
                                                                url: USER_ACCEPTUSER,
                                                                headers: { 'Authorization': 'Bearer ' + props.token, 'Content-Type': 'application/json', },
                                                                data: { iuname, userhost_id: curr_id }
                                                            };
                                                            await axios(options).then((response) => {
                                                                if (response.status === 200) {
                                                                    window.location.reload();
                                                                }
                                                                else {
                                                                    window.location.reload();
                                                                }
                                                            });
                                                        }
                                                        catch (e) {
                                                            window.location.reload();
                                                        }
                                                    }}
                                                >Accept</button>
                                                <button
                                                    style={{ ...BUTTONSTYLE, display: 'flex', padding: 5, width: '47.5%', marginLeft: '2.5%', justifyContent: 'center', borderRadius: 5 }}
                                                    onClick={async () => {
                                                        try {
                                                            let options = {
                                                                method: 'POST',
                                                                url: USER_REJECTUSER,
                                                                headers: { 'Authorization': 'Bearer ' + props.token, 'Content-Type': 'application/json', },
                                                                data: { iuname, userhost_id: curr_id }
                                                            };
                                                            await axios(options).then((response) => {
                                                                if (response.status === 200) {
                                                                    window.location.reload();
                                                                }
                                                                else {
                                                                    window.location.reload();
                                                                }
                                                            });
                                                        }
                                                        catch (e) {
                                                            window.location.reload();
                                                        }
                                                    }}
                                                >Reject</button>

                                            </div>
                                        )
                                    }

                                    return (
                                        <div key={iuname} style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 'max-content', justifyContent: 'center', paddingBottom: 5 }}>
                                            <div style={{ display: 'flex', padding: 5, margin: 5, minWidth: 113, maxWidth: 113, justifyContent: 'center', alignItems: 'center', borderRight: 'thin dashed rgb(232, 232, 232)', overflowX: 'auto' }}>{iuname}</div>
                                            <div style={{ display: 'flex', padding: 5, margin: 5, minWidth: 113, maxWidth: 113, justifyContent: 'center', alignItems: 'center', borderRight: 'thin dashed rgb(232, 232, 232)', overflowX: 'auto' }}>
                                                <div
                                                    style={{ width: 20, height: 20, backgroundColor: '#707070', borderRadius: 3 }}
                                                    onClick={() => {
                                                        setIuinfo(true)
                                                        setIuname(iuname)
                                                        setIuinfodata(iunames_info_map[iuname])
                                                    }}
                                                />
                                            </div>
                                            {lastbtn}
                                        </div>
                                    )
                                })}
                            </div>
                            :
                            null}
                    </div>
                )
            }

            return (
                <div key={'Status'} style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center', minHeight: 300, justifyContent: 'center' }}>
                    {data_dom}
                </div>
            )
        }
    }

    const renderSingleIuser = () => {
        return (
            <Jfmodal
                show={iuinfo}
                title={iuname}
                onHide={() => { setIuinfo(false) }}
                device={device}
                bsSize={'large'}
                content={
                    <div style={{ padding: '20px 10px' }}>{iuinfodata}</div>
                }
            />
        )
    }
    return (
        <div style={{ color: 'rgb(112, 112, 112)', height: device === 'WEB' ? '' : 'max-content', padding: '0px 10px', minHeight: 300 }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{PROPOSITION}</div>
            {EMPTYSPACE}
            {hoststatus === 'Loading' ? <div style={{ width: '100%', display: 'flex', justifyContent: 'center', minHeight: 300, }}><Loading /></div> : null}
            {hoststatus === 'Success' ? <div style={{ width: '100%', display: 'flex', justifyContent: 'center', minHeight: 300, }}>Success</div> : null}
            {renderHost()}
            {EMPTYSPACE}
            {renderSingleIuser()}
        </div>
    )
}

export default UserHomeHost

