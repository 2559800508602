import React, { useState } from 'react'
import './Rsetting.css'
import '../Common.css'
export default function Rsetting({ passcode, passcodemod, device, togglegpccfm_mod, togglegpccfm_guest, msgtype, casttype, updatemsgtype, updatecasttype, }) {

    const [newpasscode, setNewpasscode] = useState('')
    const [newmodpasscode, setNewmodpasscode] = useState('')
    const [newpasscodeerr, setNewpasscodeerr] = useState([])
    const [newmodpasscodeerr, setNewmodpasscodeerr] = useState([])

    const Checkpasscode = (pc) => {
        var errormsg = []
        const re = /^[a-zA-Z0-9]*$/
        if (!re.test(pc.toLowerCase())) {
            errormsg.push(<div key={Math.random()}>Space prohibited!</div>)
        }
        if (pc.length < 5) {
            if (pc.length === 0) {
                errormsg.push(<div key={Math.random()}>Passcode is required.</div>)
            }
            else {
                errormsg.push(<div key={Math.random()}>Passcode too short!</div>)
            }
        }

        if (pc.length > 100) {
            errormsg.push(<div key={Math.random()}>Passcode too long!</div>)
        }

        return errormsg
    }

    const updatePc = () => {
        var errdoms = Checkpasscode(newpasscode)
        if (errdoms.length > 0) {
            setNewpasscodeerr(errdoms) // gt error
        }
        else {
            togglegpccfm_guest(newpasscode);
            setNewpasscode('')
            setNewpasscodeerr([])
        }
    }

    const updatePcmod = () => {
        var errdoms = Checkpasscode(newmodpasscode)
        if (errdoms.length > 0) {
            setNewmodpasscodeerr(errdoms) // gt error
        }
        else {
            togglegpccfm_mod(newmodpasscode);
            setNewmodpasscode('')
            setNewmodpasscodeerr([])
        }
    }


    return device !== 'MOBILE'
        ? <div className="gbrwd_settingroot">
            <div className="gbrwd_settinginputsroot" style={{ width: 400 }}>
                <div className="gbrwd_settingtitle">Guest Passcode</div>
                <input className="gbrwd_settinginput" value={passcode} onChange={() => { }} />
                <input className="gbrwd_settingnewinput" value={newpasscode} onChange={(e) => { setNewpasscode(e.target.value.toLowerCase()) }} placeholder="New cast passcode" />
                <button className="gbrwd_settingchangebtn" onClick={updatePc}  >Change</button>
                <div className="gbrwd_settingerrs">{newpasscodeerr}</div>
            </div>
            <div className="gbrwd_settinginputsroot" style={{ width: 400 }}>
                <div className="gbrwd_settingtitle">Moderator Passcode</div>
                <input className="gbrwd_settinginput" value={passcodemod} onChange={() => { }} />
                <input className="gbrwd_settingnewinput" value={newmodpasscode} onChange={(e) => { setNewmodpasscode(e.target.value.toLowerCase()) }} placeholder="New mod passcode" />
                <button className="gbrwd_settingchangebtn" onClick={updatePcmod} >Change</button>
                <div className="gbrwd_settingerrs">{newmodpasscodeerr}</div>
            </div>
            <div className="gbrwd_settinginputsroot" style={{ width: 400 }}>
                <div className="gbrwd_settingtitle">Cast Type</div>
                <button className={casttype === 0 ? "gbrwd_msgtypebtnvalselected" : "gbrwd_msgtypebtnval"} onClick={updatecasttype} value={0}>Media files only.</button>
                <button className={casttype === 1 ? "gbrwd_msgtypebtnvalselected" : "gbrwd_msgtypebtnval"} onClick={updatecasttype} value={1}>Messages only.</button>
                <button className={casttype === 2 ? "gbrwd_msgtypebtnvalselected" : "gbrwd_msgtypebtnval"} onClick={updatecasttype} value={2}>Media files and Messages.</button>
            </div>
            <div className="gbrwd_settinginputsroot" style={{ width: 400 }}>
                <div className="gbrwd_settingtitle">Message Type</div>
                <button className={msgtype === 0 ? "gbrwd_msgtypebtnvalselected" : "gbrwd_msgtypebtnval"} onClick={updatemsgtype} value={0}>Messages Text only.</button>
                <button className={msgtype === 1 ? "gbrwd_msgtypebtnvalselected" : "gbrwd_msgtypebtnval"} onClick={updatemsgtype} value={1}>Message Text and Image.</button>
            </div>
            {/* <div className="gbrwd_settinginputsroot">
                <div className="gbrwd_settingtitle">Form Type</div>
            </div> */}
        </div>
        : <div className="gbrwd_content_m">
            <div className="gbrwd_settinginputsrootm">
                <div className="gbrwd_settingtitle">Cast Passcode</div>
                <input className="gbrwd_settinginput" value={passcode} onChange={() => { }} />
                <input className="gbrwd_settingnewinput" value={newpasscode} onChange={(e) => { setNewpasscode(e.target.value) }} placeholder="New cast passcode" />
                <button className="gbrwd_settingchangebtn" onClick={updatePc}  >Change</button>
                <div className="gbrwd_settingerrs">{newpasscodeerr}</div>
            </div>
            <div className="gbrwd_settinginputsrootm">
                <div className="gbrwd_settingtitle">Moderator Passcode</div>
                <input className="gbrwd_settinginput" value={passcodemod} onChange={() => { }} />
                <input className="gbrwd_settingnewinput" value={newmodpasscode} onChange={(e) => { setNewmodpasscode(e.target.value) }} placeholder="New mod passcode" />
                <button className="gbrwd_settingchangebtn" onClick={updatePcmod} >Change</button>
                <div className="gbrwd_settingerrs">{newmodpasscodeerr}</div>
            </div>
            <div className="gbrwd_settinginputsrootm">
                <div className="gbrwd_settingtitle">Cast Type</div>
                <button className={casttype === 0 ? "gbrwd_msgtypebtnvalselectedm" : "gbrwd_msgtypebtnvalm"} onClick={updatecasttype} value={0}>Media files only.</button>
                <button className={casttype === 1 ? "gbrwd_msgtypebtnvalselectedm" : "gbrwd_msgtypebtnvalm"} onClick={updatecasttype} value={1}>Messages only.</button>
                <button className={casttype === 2 ? "gbrwd_msgtypebtnvalselectedm" : "gbrwd_msgtypebtnvalm"} onClick={updatecasttype} value={2}>Media files and Messages.</button>
            </div>
            <div className="gbrwd_settinginputsrootm">
                <div className="gbrwd_settingtitle">Message Type</div>
                <button className={msgtype === 0 ? "gbrwd_msgtypebtnvalselectedm" : "gbrwd_msgtypebtnvalm"} onClick={updatemsgtype} value={0}>Messages Text only.</button>
                <button className={msgtype === 1 ? "gbrwd_msgtypebtnvalselectedm" : "gbrwd_msgtypebtnvalm"} onClick={updatemsgtype} value={1}>Message Text and Image.</button>
            </div>
            {/* <div className="gbrwd_settinginputsrootm">
                <div className="gbrwd_settingtitle">Form Type </div>
            </div> */}
        </div>
}
