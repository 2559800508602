import React, { useState, useEffect } from 'react'
import { Banners, Checkdevice, Jfmodal, Leftrightbtn, Loading, Modcfm, Noti, Portalmodal } from '../../../../components'
import { iconwrapper, Retrieve_personal_info } from '../../../../config/commonfunctions'
import { READ_SCEDIT, REPLACE_SCDATA, UPDATE_SC, UPDATE_SINGLEGUEST, UPDATE_SINGLETABLE, RMV_SINGLEGUEST, UPDATE_SWAP, RMV_SINGLETABLE, UPDATE_TEMPLATE } from './../../../../config/api'
import { ARROWLEFT_ICON, ENGLISH_FF, hideseatcorner, LOOKUPUNSTYLE_ICON, ADDNEWUSER_SMALL, EMPTYSPACE, WEBSITE_URL, PLOBJ, STARTUP_NAME } from '../../../../config/constants'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Addguestcontent, Addtablecontent, Editsingleguestcontent, Edittablecontent, Importcontent } from './Components'
import { CSVDownloader } from 'react-papaparse'
import { Scdesign, Online } from './Components'
import { banners, flowers, corners, bannernames } from '../../../../assets/invitationcard/index1'
import { Togglebutton } from '../../../../basic_components'
import { describe_dom, input_dom, partialtitle_dom } from '../Invitationmaker/components/Commonelements/Commonelements'
import { COPY_ICON, CROSS_ICON, ENTER_ICON, SETTING_ICON, TICK_ICON, ITINERARYTEMPLATE_ICON } from '../../../../config/svgicons'
import { Link } from 'react-router-dom'
import axios from 'axios'
import './Seatchartedit.css'
import './../Planning.css'
// import { banners, corners_collection, flowers } from '../../../../assets/invitationcard'
// import { saveAs } from 'file-saver'
const tableiconcss = { width: 20, height: 20, marginLeft: 5, color: 'var(--donegreen)', display: 'flex' }
const tablecrossiconcss = { width: 20, height: 20, marginLeft: 5, color: 'red', display: 'flex' }
function copyToClipboard(string) {
    let textarea;
    let result;

    try {
        textarea = document.createElement('textarea');
        textarea.setAttribute('readonly', true);
        textarea.setAttribute('contenteditable', true);
        textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
        textarea.value = string;

        document.body.appendChild(textarea);

        textarea.focus();
        textarea.select();

        const range = document.createRange();
        range.selectNodeContents(textarea);

        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        textarea.setSelectionRange(0, textarea.value.length);
        result = document.execCommand('copy');
    } catch (err) {
        console.error(err);
        result = null;
    } finally {
        document.body.removeChild(textarea);
    }

    // manual copy fallback using prompt
    if (!result) {
        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
        result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
        if (!result) {
            return false;
        }
    }
    return true;
}

const tablesvg = <svg height="21" width="21" fill="inherit" style={{ display: 'inline-block', verticalAlign: 'middle' }} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="20271"><path d="M512 106.666667C285.866667 106.666667 42.666667 155.733333 42.666667 266.666667v106.666666c0 104.533333 221.866667 155.733333 437.333333 160v192c-106.666667 4.266667-217.6 29.866667-217.6 96 0 70.4 134.4 96 251.733333 96s251.733333-25.6 251.733334-96c0-64-110.933333-91.733333-217.6-96V469.333333h-32c-266.666667 0-405.333333-68.266667-405.333334-96v-21.333333C194.133333 403.2 356.266667 426.666667 512 426.666667s317.866667-23.466667 405.333333-74.666667v21.333333c0 25.6-102.4 76.8-298.666666 91.733334v64c189.866667-12.8 362.666667-64 362.666666-155.733334v-106.666666C981.333333 155.733333 738.133333 106.666667 512 106.666667z m32 704v-21.333334c78.933333 4.266667 132.266667 19.2 149.333333 32-21.333333 12.8-83.2 32-181.333333 32s-162.133333-19.2-181.333333-32c19.2-10.666667 70.4-27.733333 149.333333-32v21.333334h64zM512 362.666667c-266.666667 0-405.333333-68.266667-405.333333-96S245.333333 170.666667 512 170.666667s405.333333 68.266667 405.333333 96S778.666667 362.666667 512 362.666667z" p-id="20272"></path></svg>
const NEW_ENGLISH_FF = ['', ...ENGLISH_FF]

const TABLE_MAX = 400
const GUEST_MAX = 2000
const MAX_GUEST_PER_TABLE = 101

const DEVICE_MODALWT_MAP = {
    'WEB': 500,
    'TABLET': 500,
}

const getTableStyle = isDraggingOver => ({
    backgroundColor: isDraggingOver ? '#lightgrey' : 'transparent',
    width: '100%',
    height: '100%',
    minHeight: 289,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

});

const getGuestliststyle = isDraggingOver => ({
    backgroundColor: isDraggingOver ? '#lightgrey' : 'transparent',
    width: 240,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const Nomobile = () => {
    return <div style={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '100vh', placeContent: 'center', alignItems: 'center' }}>
        <div style={{ padding: 10 }}>Sorry, this feature is currently only supported on a desktop or laptop and is not available on mobile devices.</div>
        <button id='itineraryedit_mobilebtmbtn' onClick={() => props.history.push('/')}>Home</button>
    </div>
}

const Modalsubtitle = (txt) => {
    return <div style={{ top: 60, position: 'fixed', border: '1px solid #707070', padding: '0px 5px', borderRadius: 5, marginBottom: 20, color: 'white', backgroundColor: '#707070' }}>
        {txt}
    </div>
}

const Extractpageprops = (data) => {
    // aligncanva: "center"
    // allseatnames: (4) ['<div>Prem Kumar</div><div>Shanti</div><div>Jun Kai…>Shabir</div><div>Shabir</div><div>Jia Ming</div>', '<div>Lim Jun Jie</div><div>Shantha</div><div>Jia W…>Haliq</div><div>Lim Jun Jie</div><div>Andy</div>', '<div>Yi Wei</div><div>Jonathan</div><div>Haliq</di…v>Haliq</div><div>Jonathan</div><div>Yi Wei</div>', '<div>Andy</div><div>Jia Wei</div><div>Mary</div><d…div>Haliq</div><div>Jia Ming</div><div>Mary</div>']
    // allseatnamescss: (4) [{…}, {…}, {…}, {…}]
    // alltablenames: (4) ['Table 1', 'Table 2', 'Table 3', 'Table 4']
    // alltablenamescss: (4) [{…}, {…}, {…}, {…}]
    // banner: `${S3CLOUD_CLOUDFRONT_ASSET}Inv/banner/0007.png`
    // cornerimg: "https://i.ibb.co/NCYsCFD/1000-1000-corner.png"
    // defaultmain: "Alice & Bob"
    // defaulttop: "SEATING ARRANGEMENT"
    // floralplacement: 0
    // jccanva: "flex-start"
    // maincss: {fontFamily: 'Dancing Script', fontStyle: '', fontSize: 80, fontWeight: 'bold', placeContent: 'center', …}
    // tablecss: "SMALL"
    // tableformat: "4_0"
    // template: 0
    // templatecolor: "#fffef8"
    // templatetype: "CLASSIC"
    // topcss: {fontFamily: 'Nanum Myeongjo', fontStyle: '', fontSize: 15, fontWeight: '', placeContent: 'center', …}
    // totaltables: 4
    const {
        banner,
        cornerimg,
        defaultmain,
        defaulttop,
        topcss,
        maincss,
        alltablenamescss,
        allseatnamescss,
        floralplacement,
        template,
        templatecolor,
    } = data

    var tabletitlecss = alltablenamescss.length > 0 ? alltablenamescss[0] : null
    var seatscontainercss = allseatnamescss.length > 0 ? allseatnamescss[0] : null
    return {
        banner,
        cornerimg,
        defaultmain,
        defaulttop,
        topcss,
        maincss,
        tabletitlecss,
        seatscontainercss,
        floralplacement,
        template,
        templatecolor
    }

}

function Seatchartedit(props) {

    const device = Checkdevice()
    const { token } = Retrieve_personal_info()
    const [firsttime, setFirsttime] = useState(true)
    const [load, setLoad] = useState(false)

    const [scid, setScid] = useState('')
    const [save_id, setSave_id] = useState('')
    const [uid, setUid] = useState('') // eslint-disable-line
    const [urlhandler, setUrlhandler] = useState('')
    const [pages, setPages] = useState([]) // pages data for PRINTING
    const [onlineselectedpg, setOnlineselectedpg] = useState(-1) // page design that online corners inherit
    const [onlineselectedpgprops, setOnlineselectedpgprops] = useState({}) // page design that online corners inherit
    const [onlineselectedsave, setOnlineselectedsave] = useState(false) // use to detect "Save and Preview" is clicked
    const [etype, setEtype] = useState('') // eslint-disable-line
    const [passcode, setPasscode] = useState('')
    const [name, setName] = useState('') // table name

    const [noti, setNoti] = useState('')
    const [swapindex, setSwapindex] = useState(-1)
    const [exceltableobjs, setExceltableobjs] = useState([])
    const [editedsignal, setEditedsignal] = useState(false) // prevent user from clicking preview if nt save
    const [tableobjs, setTableobjs] = useState([]) // { name: 'TABLE 1', max: 3, seatnames: ['name 1', 'name 2', 'name 3'], relas: ['Husband', 'Wife', 'Wife'], cats: ['Husband friend', 'Husband friend', 'Husband friend', ] },
    const [guests, setGuests] = useState([]) // { name: 'Name 1', rela: 'Husband', cat:'' },

    const [ondrag, setOndrag] = useState(false)

    const [addtablemodal, setAddtablemodal] = useState(false) // add table modal
    const [addguestmodal, setAddguestmodal] = useState(false) // add guest modal
    const [edittablemodal, setEdittablemodal] = useState(false) // edit table modal
    const [edittabledata, setEdittabledata] = useState([])
    const [editsingleguestmodal, setEditsingleguestmodal] = useState(false) // edit non-seated guest modal
    const [editsingleguestdata, setEditsingleguestdata] = useState([])
    const [editseatedguestmodal, setEditseatedguestmodal] = useState(false) // edit seated guest modal
    const [editseatedguestdata, setEditseatedguestdata] = useState([])

    const [allcats, setAllcats] = useState([]) // calculate cats on the fly

    const [mmarr, setMmarr] = useState([]) // massmove array
    const [mmarrsize, setMmarrsize] = useState([-1, -1])

    const [importmodal, setImportmodal] = useState(false)
    const [help, setHelp] = useState(false)
    const [edittype, setEdittype] = useState(0)

    const [qrmodal, setQrmodal] = useState(false)
    const [exportmodal, setExportmodal] = useState(false)

    const [imgs, setImgs] = useState(['', '', '']) // [ WEB device img , MOBILE device img, CORNER img]
    const [style, setStyle] = useState({ fontFamily: '' })

    const [bannermod, setBannermod] = useState(false)// banner modal

    const [selectedswapindex, setSelectedswapindex] = useState(-1)

    const [returnhome, setReturnhome] = useState(false)// var to return to planning home

    const [mode, setMode] = useState(0)// 0: edit mode, 1: design mode, 2: online mode

    const [showsetting, setShowsetting] = useState(false)

    const [showtemplate, setShowtemplate] = useState(false)
    const [template, setTemplate] = useState(-1)

    const [copymode, setCopymode] = useState(false)

    const [swapmade, setSwapmade] = useState(false) // if swap is made, switch showsave to true
    const [beforeswapdata, setBeforeswapdata] = useState({})
    const [showsave, setShowsave] = useState(false)

    useEffect(() => {
        if (copymode) {
            setTimeout(() => {
                setCopymode(false)
            }, [1500])
        }
    }, [copymode])


    const onCopy = (e) => {
        try {
            var test = function (regexp) {
                return regexp.test(window.navigator.userAgent);
            }

            if (test(/safari/i)) { // is safari
                copyToClipboard(e.target.value)
            }
            else {
                navigator.clipboard.writeText(e.target.value)
            }
            setCopymode(true)
        }
        catch (e) {
            console.log(e)
        }
    }

    const portalleftcss = { width: { 'WEB': 500, 'TABLET': 400, 'MOBILE': '100%' }[device], height: '100%', backgroundColor: 'white', top: 0, left: null, right: 0, transform: 'none' }

    useEffect(() => {

        props.hidetopbar(true)

        if (firsttime) {
            setLoad(true)
            try {
                const { id } = props.match.params

                axios({
                    method: 'POST',
                    url: READ_SCEDIT,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { id }
                }).then((item) => {
                    const { data, status } = item
                    const { etype, u_id, _id, urlhandler, tableobjs, guests, save_id, passcode, name, imgs, style, pages, onlineselectedpg, onlineselectedpgprops = {} } = data.sc
                    if (status === 200) {
                        setScid(_id)
                        setUid(u_id)
                        setEtype(etype)
                        setPasscode(passcode)
                        setUrlhandler(urlhandler)
                        setTableobjs(tableobjs)
                        setGuests(guests)
                        setSave_id(save_id)
                        updateExcelresult(tableobjs, guests)
                        setName(name)
                        setImgs(imgs)
                        style ? setStyle(style) : setStyle({ fontFamily: '' })
                        console.log(pages)
                        setPages(pages)
                        setEditedsignal(false)
                        setLoad(false)
                        setOnlineselectedpg(onlineselectedpg)
                        setOnlineselectedpgprops(Object.assign(onlineselectedpgprops, {}))
                    }
                }).catch((e) => {
                    console.log('e', e)
                    props.history.push('/')
                })
            }
            catch (e) { props.history.push('/') }
            setFirsttime(false)
        }

        return () => { props.hidetopbar(false) }

    }, [])

    useEffect(() => { // update allcats, all all guest related modal triggered
        if (addguestmodal || editsingleguestmodal || editseatedguestmodal) {
            var temp = new Set()
            for (let i = 0; i < tableobjs.length; i++) {
                const { cats } = tableobjs[i]
                for (let j = 0; j < cats.length; j++) temp.add(cats[j])
            }
            for (let i = 0; i < guests.length; i++) temp.add(guests[i].cat)
            temp.delete('')
            temp.delete(null)
            temp.delete(undefined)
            setAllcats(Array.from(temp))
        }
    }, [addguestmodal, editsingleguestmodal, editseatedguestmodal])

    useEffect(() => {
        if (addtablemodal || editseatedguestmodal || edittablemodal || editsingleguestmodal) setMmarr([])
    }, [addtablemodal || editseatedguestmodal || edittablemodal || editsingleguestmodal])

    useEffect(() => {
        if (mmarrsize[1] > 0) {
            setTimeout(() => {
                setMmarrsize([-1, -1])
            }, 1000)
        }
    }, [mmarrsize])

    useEffect(() => {
        if (onlineselectedsave) {
            Savefunc(guests, tableobjs)
            setOnlineselectedsave(false)
            setTimeout(() => {
                const win = window.open(('/seatingchartlookup/' + urlhandler + (passcode && passcode.length > 0 ? `/passcode=${passcode}` : '')), "_blank")
                win.focus();
            }, 2200)

        }
    }, [onlineselectedsave])

    useEffect(() => {
        if (swapmade) { // swap being made
            // show save button if swap is being made
            setShowsave(true)
        }
    }, [swapmade])

    // const modalsep = <div style={{ display: 'flex', flexDirection: 'row', placeContent: 'center', width: '100%', alignItems: 'center' }}>
    //     <div style={{ width: '45%', borderTop: 'thin dashed #f77f7f', margin: '40px 0px', height: 1, color: '#f77f7f' }} />
    //     <div style={{ width: '10%', placeContent: 'center', textAlign: 'center', fontWeight: 'bold', color: 'white', backgroundColor: '#f77f7f', borderRadius: 10, padding: 2 }}>{HEART_ICON}</div>
    //     <div style={{ width: '45%', borderTop: 'thin dashed #f77f7f', margin: '40px 0px', height: 1, color: '#f77f7f' }} />
    // </div>

    const modalsep = <div style={{ display: 'flex', flexDirection: 'row', placeContent: 'center', width: '100%', alignItems: 'center', borderTop: '1px dashed black', margin: '45px 0px' }}>
    </div>

    const updateExcelresult = (tableobjs, guests) => {
        var local_excelresult = []
        local_excelresult.push(['Name', 'Relationship', 'Max', 'Table', 'Category'])
        for (let i = 0; i < tableobjs.length; i++) { // seated guests
            const { name, max, seatnames, relas, cats } = tableobjs[i]
            for (let x = 0; x < seatnames.length; x++) {
                local_excelresult.push([seatnames[x], relas[x], max, name, cats[x]])
            }
        }
        for (let j = 0; j < guests.length; j++) {
            const { name, rela } = guests[j]
            local_excelresult.push([name, rela, '', ''])
        }
        setExceltableobjs(local_excelresult)
        setEditedsignal(true)
    }

    const onDragendseat = (result) => {
        setOndrag(true)
        setMmarr([])
        if (!result.destination) return;
        const { source, destination } = result
        var local_tables = tableobjs.slice()
        var local_guests = guests.slice()

        function storebackup() {
            setBeforeswapdata({
                'guests': JSON.parse(JSON.stringify(guests)),
                'tableobjs': JSON.parse(JSON.stringify(tableobjs))
            })
        }

        if (source.droppableId === 'guestlist') { // guestlist -> seat || guestlist -> guestlist
            const desttable_index = destination.droppableId.split('_')[1]
            const dest_index = destination.index
            const src_index = source.index

            if (source.droppableId !== destination.droppableId) { // guestlist -> seat
                if (!swapmade) {
                    setSwapmade(true)
                    storebackup()
                }
                const temp = guests[src_index]
                local_guests.splice(src_index, 1)
                local_tables[desttable_index]['seatnames'].splice(dest_index, 0, temp.name) // insert
                local_tables[desttable_index]['relas'].splice(dest_index, 0, temp.rela) // insert
                local_tables[desttable_index]['cats'].splice(dest_index, 0, temp.cat) // insert
                setGuests(local_guests)
                setTableobjs(local_tables)
                updateExcelresult(local_tables, local_guests)


            }
            else { // guestlist -> guestlist
                if (!swapmade) {
                    setSwapmade(true)
                    storebackup()
                }
                const temp = guests[src_index]
                local_guests[src_index] = local_guests[dest_index]
                local_guests[dest_index] = temp
                setGuests(local_guests)
                updateExcelresult(local_tables, local_guests)

            }
        }
        else if (destination.droppableId === 'guestlist') { // seat -> guestlist

            if (!swapmade) {
                setSwapmade(true)
                storebackup()
            }

            const srctable_index = source.droppableId.split('_')[1]
            const src_index = source.index
            const dest_index = destination.index

            const temp_name = local_tables[srctable_index]['seatnames'][src_index]
            const temp_rela = local_tables[srctable_index]['relas'][src_index]
            const temp_cat = local_tables[srctable_index]['cats'][src_index]
            local_tables[srctable_index]['seatnames'].splice(src_index, 1)
            local_guests.splice(dest_index, 0, { name: temp_name, rela: temp_rela, cat: temp_cat }) // insert

            setGuests(local_guests)
            setTableobjs(local_tables)
            updateExcelresult(local_tables, local_guests)
        }
        else { // seat <-> seat

            if (!swapmade) {
                setSwapmade(true)
                storebackup()
            }

            // need ensure gt enough seats
            const srctable_index = source.droppableId.split('_')[1]
            const src_index = source.index
            const desttable_index = destination.droppableId.split('_')[1]
            const dest_index = destination.index

            const temp_seatname = local_tables[srctable_index]['seatnames'][src_index]
            const temp_rela = local_tables[srctable_index]['relas'][src_index]
            const temp_cat = local_tables[srctable_index]['cats'][src_index]
            local_tables[srctable_index]['seatnames'].splice(src_index, 1)
            local_tables[srctable_index]['relas'].splice(src_index, 1)
            local_tables[srctable_index]['cats'].splice(src_index, 1)
            local_tables[desttable_index]['seatnames'].splice(dest_index, 0, temp_seatname) // insert
            local_tables[desttable_index]['relas'].splice(dest_index, 0, temp_rela) // insert
            local_tables[desttable_index]['cats'].splice(dest_index, 0, temp_cat) // insert
            setTableobjs(local_tables)
            updateExcelresult(local_tables, local_guests)
        }
        setOndrag(false)
    }

    const portalmidcss = { width: { 'WEB': 500, 'TABLET': 450, 'MOBILE': '100%' }[device], height: { 'WEB': 500, 'TABLET': 400, 'MOBILE': '100%' }[device], backgroundColor: 'white', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)' }

    const importdata_modal = <Portalmodal
        portaltype={'commonportal'}
        open={importmodal}
        onClose={() => { setImportmodal(false) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalmidcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'>
        <div className={device === 'MOBILE' ? 'SearchmodalM' : 'Searchmodal'} >
            <Importcontent
                urlhandler={urlhandler}
                sep={modalsep}
                onclick={(url) => {
                    axios({
                        method: 'POST',
                        url: REPLACE_SCDATA,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                        data: { url }
                    }).then((item) => {
                        const { status, data } = item
                        const { guestid_guestinfo_map } = data.item[0]
                        var names = []
                        if (status === 200) {
                            var arr = Object.keys(guestid_guestinfo_map)
                            for (let i = 0; i < arr.length; i++) {
                                const key = arr[i]
                                if (guestid_guestinfo_map[key].Name.length !== 0) {
                                    names.push({
                                        'name': guestid_guestinfo_map[key].Name,
                                        'rela': '',
                                        'cat': ''
                                    })
                                }
                            }
                            setGuests([...guests, ...names])
                        }
                    }).catch((e) => { })

                }}
                ondrop={(row_obj) => {
                    try {
                        var name_index = -1
                        var table_index = -1
                        var relationship_index = -1
                        var category_index = -1
                        for (let x = 0; x < row_obj[0].data.length; x++) {
                            if (row_obj[0].data[x].toLowerCase() === 'name') {
                                name_index = x
                            }
                            if (row_obj[0].data[x].toLowerCase() === 'table') {
                                table_index = x
                            }
                            if (row_obj[0].data[x].toLowerCase() === 'relationship') {
                                relationship_index = x
                            }
                            if (row_obj[0].data[x].toLowerCase() === 'category') {
                                category_index = x
                            }
                        }

                        var local_guests = []
                        var local_tablemap = {}
                        var local_tablenames = []
                        for (let i = 1; i < row_obj.length; i++) {
                            const { data } = row_obj[i]
                            var name = data[name_index]
                            var table = data[table_index]
                            var relation = data[relationship_index]
                            var category = data[category_index]
                            if (table.length === 0) { // not seated
                                local_guests.push({ name: name, rela: relation, cat: category })
                            }

                            if (table.length > 0) { // seated
                                if (local_tablemap[table]) {
                                    local_tablemap[table]['seatnames'].push(name)
                                    local_tablemap[table]['relas'].push(relation)
                                    local_tablemap[table]['cats'].push(category)
                                }
                                else {
                                    local_tablemap[table] = { name: table, seatnames: [name], relas: [relation], cats: [category] }
                                    local_tablenames.push(table)
                                }
                            }

                        }

                        var local_tableobjs = []
                        for (let i = 0; i < local_tablenames.length; i++) {
                            const tname = local_tablenames[i]
                            local_tableobjs[i] = { name: tname, max: local_tablemap[tname].seatnames.length, seatnames: local_tablemap[tname].seatnames, relas: local_tablemap[tname].relas, cats: local_tablemap[tname].cats }
                        }

                        setGuests(local_guests)
                        setTableobjs(local_tableobjs)
                    }
                    catch (e) {
                        alert('Please make sure you upload the correct format.')
                    }
                }}
            />
        </div>
    </Portalmodal>

    const edittypenonselected = { height: 40, padding: '0px 10px', border: '1px solid var(--maindark)', backgroundColor: '#ffffff', marginRight: 5, borderRadius: 5 }
    const edittypeselected = { height: 40, padding: '0px 10px', border: '1px solid var(--mainpink)', backgroundColor: 'var(--mainpink)', marginRight: 5, borderRadius: 5, color: '#ffffff' }

    const setting_modal = <Portalmodal
        portaltype={'commonportal'}
        open={showsetting}
        onClose={() => { setShowsetting(false) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalmidcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'>
        <div className={device === 'MOBILE' ? 'SearchmodalM' : 'Searchmodal'} >
            <div className="inviteedit_helproot">
                <div className="inviteedit_helptitle" >Settings</div>
                {partialtitle_dom('Mode', {})}
                {describe_dom('Simplify mode will show simple information, whereas detail mode will show the relationship and category of guests.', { marginBottom: 10, paddingLeft: 0, paddingRight: 0 })}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <button style={edittype === 0 ? edittypeselected : edittypenonselected} onClick={() => setEdittype(0)}>Simplify</button>
                    <button style={edittype === 1 ? edittypeselected : edittypenonselected} onClick={() => setEdittype(1)}>Detail</button>
                </div>
            </div>

            {/* <button className={'scedit_rightmode'} onClick={() => setEdittype(edittype === 0 ? 1 : 0)}>{edittype === 0 ? 'Simplify' : 'Detail'}</button> */}
        </div>
    </Portalmodal>

    const template_modal = <Portalmodal
        portaltype={'commonportal'}
        open={showtemplate}
        onClose={() => { setShowtemplate(false) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalmidcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'>
        <div className={device === 'MOBILE' ? 'SearchmodalM' : 'Searchmodal'} >
            <div className="inviteedit_helproot">
                <div className="inviteedit_helptitle" >Template</div>
                {partialtitle_dom('Sample Data', {})}
                {describe_dom('Replace your current seating chart with placeholder values.', { marginBottom: 10, paddingLeft: 0, paddingRight: 0 })}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <button style={template === 0 ? edittypeselected : edittypenonselected} onClick={() => setTemplate(0)}>Dummy Value</button>
                </div>
                <button
                    className="scedit_modalbtn"
                    onClick={() => {
                        try {
                            axios({
                                method: 'POST',
                                url: UPDATE_TEMPLATE,
                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                data: { scid, urlhandler, save_id, passcode, template }
                            }).then((item) => {
                                const { message, new_saveid, templatejson } = item.data
                                if (message === 'SUCCESS') {
                                    setTableobjs(templatejson)
                                    setSave_id(new_saveid)
                                    setShowtemplate(false)
                                }
                                else {
                                    alert('expired')
                                }
                            }).catch((e) => {
                                console.log(e)
                            })
                        }
                        catch (e) { console.log(e) }
                    }}
                    style={{ marginBottom: 0, width: '100%', borderRadius: 5, display: 'flex' }}
                >
                    Update
                </button>
            </div>
        </div>
    </Portalmodal>

    const edittable_modal = <Portalmodal
        animation="right_left"
        portaltype="commonportal"
        open={edittablemodal}
        onClose={() => { setEdittablemodal(false) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalleftcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <Edittablecontent
            DEVICE_MODALWT_MAP={{ 'WEB': 500, 'TABLET': 400, 'MOBILE': '100%' }[device]}
            device={device}
            data={edittabledata}
            update={(i, txt, max) => {
                try {
                    axios({
                        method: 'POST',
                        url: UPDATE_SINGLETABLE,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                        data: { scid, urlhandler, save_id, passcode, seatingstate: 'UNSEATED', tableindex: i, tablename: txt, tablequota: max }
                    }).then((item) => {
                        const { message, new_saveid } = item.data
                        if (message === 'SUCCESS') {
                            var local_tablesobjs = tableobjs.slice()
                            var local_guests = guests.slice()
                            local_tablesobjs[i]['name'] = txt
                            local_tablesobjs[i]['max'] = max
                            setTableobjs(local_tablesobjs)
                            updateExcelresult(local_tablesobjs, local_guests)
                            setEdittablemodal(false)
                            setSave_id(new_saveid)
                        }
                        else {
                            alert('expired')
                        }
                    }).catch((e) => {
                        console.log(e)
                    })
                }
                catch (e) { console.log(e) }
            }}
            rmv={(i) => {

                var local_tablesobjs = JSON.parse(JSON.stringify(tableobjs))


                if (local_tablesobjs[i]['seatnames'].length === 0) {
                    local_tablesobjs.splice(i, 1)
                    try {
                        axios({
                            method: 'POST',
                            url: RMV_SINGLETABLE,
                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                            data: { scid, urlhandler, save_id, passcode, tableobjs: local_tablesobjs }
                        }).then((item) => {
                            const { message, new_saveid } = item.data
                            if (message === 'SUCCESS') {
                                setTableobjs(local_tablesobjs)
                                setEdittablemodal(false)
                                setSave_id(new_saveid)
                            }
                            else {
                                alert('expired')
                            }
                        }).catch((e) => {
                            console.log(e)
                        })
                    }
                    catch (e) { console.log(e) }

                }
                else {
                    alert('Please remove all guests from this table before deletion.')
                }


            }}
        />
    </Portalmodal>

    const addtable_modal = <Portalmodal
        animation="right_left"
        portaltype="commonportal"
        open={addtablemodal}
        onClose={() => setAddtablemodal(false)}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalleftcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <Addtablecontent
            DEVICE_MODALWT_MAP={{ 'WEB': 500, 'TABLET': 400, 'MOBILE': '100%' }[device]}
            TABLE_MAX={TABLE_MAX}
            device={device}
            Modalsubtitle={Modalsubtitle}
            update={(addtableobjs) => {
                if (addtableobjs.length + tableobjs.length <= TABLE_MAX) {
                    for (let i = 0; i < addtableobjs.length; i++) {// checks
                        const { max } = addtableobjs[i]
                        if ((max < 1) || (max > MAX_GUEST_PER_TABLE)) {
                            alert('Max table cannot below 1 or more than 100.')
                            return
                        }
                    }
                    var final_tableobjs = tableobjs ? [...tableobjs, ...addtableobjs] : addtableobjs
                    setTableobjs(final_tableobjs)
                    updateExcelresult(final_tableobjs, guests.slice())
                    setAddtablemodal(false)
                    Savefunc(guests, final_tableobjs)

                }
                else {
                    alert('You have reach max number of tables.')
                }

            }}
        />
    </Portalmodal>

    const addguest_modal = <Portalmodal
        animation="right_left"
        portaltype="commonportal"
        open={addguestmodal}
        onClose={() => setAddguestmodal(false)}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalleftcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <Addguestcontent
            GUEST_MAX={GUEST_MAX}
            allcats={allcats}
            update={(addguestnum, addguestname, addguestrela, addguestcat) => {

                var curr_guests_len = guests.length || 0
                var curr_tableseatedguests_len = 0

                for (let j = 0; j < tableobjs.length; j++) {
                    curr_tableseatedguests_len += tableobjs[j].seatnames.length
                }

                var total_num_guests = curr_guests_len + curr_tableseatedguests_len + addguestnum
                if (total_num_guests <= GUEST_MAX) {
                    var local_guestsobjs = []
                    for (let i = 0; i < addguestnum; i++) {
                        if (addguestname && addguestname[i] && addguestname[i].length > 0) {
                            // need check if its empty, if empty add empty string to it
                            local_guestsobjs.push({
                                'name': addguestname[i],
                                'rela': addguestrela[i],
                                'cat': addguestcat[i],
                            })
                        }
                        else {
                            alert('Please ensure input not empty.')
                            return
                        }
                    }
                    var final_guests = guests ? [...guests, ...local_guestsobjs] : local_guestsobjs
                    setGuests(final_guests)
                    updateExcelresult(tableobjs.slice(), final_guests)
                    setAddguestmodal(false)
                    Savefunc(final_guests, tableobjs)

                }
                else {
                    alert('You have reached the maximum number of guests.')
                }

            }}
        />
    </Portalmodal>

    const help_modal = <Portalmodal
        portaltype={'commonportal'}
        open={help}
        onClose={() => { setHelp(false) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalmidcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'>
        <div className={device === 'MOBILE' ? 'SearchmodalM' : 'Searchmodal'} >
            <div className="inviteedit_helproot">

                <div className="inviteedit_helptitle" >Arrange Seat</div>
                <div className="ie_modaltext">
                    <div className='ie_num'>1</div>
                    <div> Upload .csv file or add the guests manually to the seating chart</div>
                </div>
                <div className="ie_modaltext">
                    <div className='ie_num'>2</div>
                    <div> Decide on the number of tables and seats</div>
                </div>
                <div className="ie_modaltext">
                    <div className='ie_num'>3</div>
                    <div>Start by assigning guest to their tables</div>
                </div>
                {EMPTYSPACE}
                <div className="inviteedit_helptitle" >Design</div>
                <div className="ie_modaltext">
                    <div className='ie_num'>1</div>
                    <div>Start by selecting the template by click the '+' sign at the bottom of Design page.</div>
                </div>
                <div className="ie_modaltext">
                    <div className='ie_num'>2</div>
                    <div>After selecting a template, you would have to choose the number of table your seating chart should have. You could have multiple seating charts, and the min table is 3 and max table is 12. </div>
                </div>
                <div className="ie_modaltext">
                    <div className='ie_num'>3</div>
                    <div>Each guest will automatically assigned to the seating chart.</div>
                </div>
                {EMPTYSPACE}
                <div className="inviteedit_helptitle" >Online</div>
                <div className="ie_modaltext">
                    <div className='ie_num'>1</div>
                    <div>If you have multiple chart with different design, you could choose the one that you want your online seating chart to look like.</div>
                </div>
                <div className="ie_modaltext">
                    <div className='ie_num'>2</div>
                    <div>Main text format referring to whether allow next line for the text on main text. </div>
                </div>
            </div>
        </div>
    </Portalmodal>

    const editsingleguest_modal = <Portalmodal // non seated guest
        animation="right_left"
        portaltype="commonportal"
        open={editsingleguestmodal}
        onClose={() => setEditsingleguestmodal(false)}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalleftcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <Editsingleguestcontent
            DEVICE_MODALWT_MAP={DEVICE_MODALWT_MAP}
            allcats={allcats}
            data={editsingleguestdata}
            device={device}
            update={(i, n, r, c) => {

                let local_guest = guests.slice()
                local_guest[i]['name'] = n
                local_guest[i]['rela'] = r
                local_guest[i]['cat'] = c

                try {
                    axios({
                        method: 'POST',
                        url: UPDATE_SINGLEGUEST,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                        data: { scid, urlhandler, save_id, passcode, seatingstate: 'UNSEATED', singleguestobj: local_guest[i], guestindex: i }
                    }).then((item) => {
                        const { message, new_saveid } = item.data
                        if (message === 'SUCCESS') {
                            var local_guest = guests.slice()
                            var local_tableobjs = tableobjs.slice()
                            local_guest[i]['name'] = n
                            local_guest[i]['rela'] = r
                            local_guest[i]['cat'] = c
                            setGuests(local_guest)
                            updateExcelresult(local_tableobjs, local_guest)
                            setSave_id(new_saveid)
                            setEditsingleguestmodal(false)
                        }
                        else {
                            alert('expired')
                        }
                    }).catch((e) => {
                        console.log(e)
                    })
                }
                catch (e) { console.log(e) }
            }}
            rmv={(guestindex) => {
                var local_guests = JSON.parse(JSON.stringify(guests))
                local_guests.splice(guestindex, 1)
                try {
                    axios({
                        method: 'POST',
                        url: RMV_SINGLEGUEST,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                        data: { scid, urlhandler, save_id, passcode, guestindex, seatingstate: 'UNSEATED', guests: local_guests }
                    }).then((item) => {
                        const { message, new_saveid } = item.data
                        if (message === 'SUCCESS') {
                            setGuests(local_guests)
                            updateExcelresult(tableobjs, local_guests)
                            setSave_id(new_saveid)
                            setEditsingleguestmodal(false)
                        }
                        else {
                            alert('expired')
                        }
                    }).catch((e) => {
                        console.log(e)
                    })
                }
                catch (e) { console.log(e) }


                // var local_guest = guests.slice()
                // local_guest.splice(i, 1)
                // setGuests(local_guest)
                // setEditsingleguestdata([])
                // setEditsingleguestmodal(false)
            }}
        />
    </Portalmodal>

    const editseatedsingleguest_modal = <Portalmodal // seated guest
        animation="right_left"
        portaltype="commonportal"
        open={editseatedguestmodal}
        onClose={() => setEditseatedguestmodal(false)}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalleftcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <Editsingleguestcontent
            DEVICE_MODALWT_MAP={DEVICE_MODALWT_MAP}
            allcats={allcats}
            data={editseatedguestdata}
            device={device}
            update={(tgi, n, r, c, ti) => {

                let local_tableobjs = JSON.parse(JSON.stringify(tableobjs.slice()))
                local_tableobjs[ti]['seatnames'][tgi] = n
                local_tableobjs[ti]['relas'][tgi] = r
                local_tableobjs[ti]['cats'][tgi] = c
                let singletableobj = JSON.parse(JSON.stringify(local_tableobjs[ti]))

                try {
                    axios({
                        method: 'POST',
                        url: UPDATE_SINGLEGUEST,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                        data: { scid, urlhandler, save_id, passcode, seatingstate: 'SEATED', singletableobj, tableindex: ti, tableguestindex: tgi, guestindex: null, name: n, rela: r, cat: c }
                    }).then((item) => {
                        const { message, new_saveid } = item.data
                        if (message === 'SUCCESS') {
                            var local_tableobjs = tableobjs.slice()
                            local_tableobjs[ti]['seatnames'][tgi] = n
                            local_tableobjs[ti]['relas'][tgi] = r
                            local_tableobjs[ti]['cats'][tgi] = c
                            setTableobjs(local_tableobjs)
                            updateExcelresult(local_tableobjs, guests.slice())
                            setEditseatedguestdata([])
                            setSave_id(new_saveid)
                            setEditseatedguestmodal(false)
                        }
                        else {
                            alert('expired')
                        }
                    }).catch((e) => {
                        console.log(e)
                    })
                }
                catch (e) { console.log(e) }

            }}
            rmv={(gi, ti) => { // guestindex and tableindex
                var local_tableobjs = JSON.parse(JSON.stringify(tableobjs))
                local_tableobjs[ti]['seatnames'].splice(gi, 1)
                local_tableobjs[ti]['relas'].splice(gi, 1)
                local_tableobjs[ti]['cats'].splice(gi, 1)
                try {
                    axios({
                        method: 'POST',
                        url: RMV_SINGLEGUEST,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                        data: { scid, urlhandler, save_id, passcode, singletableobj: local_tableobjs[ti], tableindex: ti, seatingstate: 'SEATED' }
                    }).then((item) => {
                        const { message, new_saveid } = item.data
                        if (message === 'SUCCESS') {
                            setTableobjs(local_tableobjs)
                            updateExcelresult(local_tableobjs, guests)
                            setSave_id(new_saveid)
                            setEditseatedguestmodal(false)
                        }
                        else {
                            alert('expired')
                        }
                    }).catch((e) => {
                        console.log(e)
                    })
                }
                catch (e) { console.log(e) }

            }}
        />
    </Portalmodal>

    const copydom = copymode
        ? <div id='copy_dom' style={{ width: '100vw', position: 'absolute', placeContent: 'center', alignContent: 'center', left: 0, display: 'flex', bottom: 150, zIndex: 99999999999999 }}>
            <div style={{ display: 'flex', width: 200, height: 40, backgroundColor: 'var(--maindark)', color: '#ffffff', placeContent: 'center', alignContent: 'center', borderRadius: 5, position: 'fixed', alignItems: 'center' }}>Copied!</div>
        </div>
        : null

    const qr_modal = <Portalmodal
        portaltype={'commonportal'}
        open={qrmodal}
        onClose={() => { setQrmodal(false) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalmidcss}
        closestyle={{ right: 0 }}
        floatingdom={copydom}
        floatingclosecolor='#333333'>
        <div className={device === 'MOBILE' ? 'SearchmodalM' : 'Searchmodal'} >
            <div className="inviteedit_helproot">
                <div className="inviteedit_helptitle" >QR Code</div>
                <p className="ie_modaltext" style={{ marginBottom: 15 }}>Printable version QR code for your guest during wedding that will automatically direct them to your online seating chart.</p>
                <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row' }}>
                    {input_dom(
                        'Seating Chart URL Handler',
                        '',
                        `${WEBSITE_URL}/seatingchartlookup/${urlhandler}/passcode=${passcode}`,
                        (e) => {
                        },
                        'urlhandler_id',
                        {},
                        true,
                        null,
                        {},
                        { marginLeft: 0, marginRight: 0, width: '100%', margin: 0, }
                    )}
                    <button
                        className="planning_editbtn"
                        style={{}}
                        onClick={onCopy}
                        value={`${WEBSITE_URL}/seatingchartlookup/${urlhandler}/passcode=${passcode}`}>
                        {iconwrapper(COPY_ICON, { borderRadius: 5, padding: 0, margin: 0, width: 20, height: 20, pointerEvents: 'none' })}
                    </button>
                </div>
                <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row', placeContent: 'end' }}>
                    <Link id='qr_link' to="/tools/qr" target="_blank" style={{ visibility: 'hidden' }} />
                    <button
                        className='Ieditcomp_qr'
                        onClick={() => {
                            document.getElementById('qr_link').click()
                        }}
                    >
                        <div>QR App</div>
                        {iconwrapper(ENTER_ICON, { width: 24, height: 24, marginLeft: 5 })}
                    </button>
                </div>
            </div>
        </div>
    </Portalmodal>

    const exportdata_modal = <Portalmodal
        portaltype={'commonportal'}
        open={exportmodal}
        onClose={() => { setExportmodal(false) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalmidcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'>
        <div className={device === 'MOBILE' ? 'SearchmodalM' : 'Searchmodal'} >
            <div className="inviteedit_helproot">
                <div className="inviteedit_helptitle">Export Data</div>
                {partialtitle_dom('Download Guest List', {})}
                {describe_dom('Download your seat chart guest list.', { marginBottom: 10, paddingLeft: 0, paddingRight: 0 })}
                {((exceltableobjs && exceltableobjs.length > 0) || (guests && guests.length > 0)) && !ondrag && !edittablemodal && !addtablemodal && !addguestmodal && !editsingleguestmodal && !editseatedguestmodal
                    ? <CSVDownloader data={exceltableobjs} filename={`${STARTUP_NAME}_csv`} style={{ width: '100%', }} type={'link'} className='scedit_csvdownloaderbtn' >
                        <button
                            onClick={() => {
                                try {
                                    document.getElementsByClassName('scedit_csvdownloaderbtn').click()
                                }
                                catch (e) {

                                }
                            }}
                            className="scedit_modalbtn"
                            style={{ marginBottom: 0, marginTop: 0, borderRadius: 5 }}
                        >
                            Download
                        </button>
                    </CSVDownloader>
                    : null}

                {/* <Exportcontent
                    sep={modalsep}
                    csvdownload_div={// to make sure excel gt ready data || guest gt objs && not on drag and modal all close
                        ((exceltableobjs && exceltableobjs.length > 0) || (guests && guests.length > 0)) && !ondrag && !edittablemodal && !addtablemodal && !addguestmodal && !editsingleguestmodal && !editseatedguestmodal
                            ? <CSVDownloader data={exceltableobjs} filename={`${STARTUP_NAME}_csv`} style={{ width: '100%' }} type={'link'} >
                                <button className="scedit_modalbtn" >Download</button>
                            </CSVDownloader>
                            : null}
                    imgdownload_div={<button
                        className="scedit_modalbtn"
                        style={{ marginBottom: 44 }}
                        onClick={() => {
                            // var elem = document.getElementById("scedit_righttableroot");
                            // alert(elem.scrollHeight)
                            // alert(elem.clientHeight)
                            // const a1_v = { height: 9933, width: 7016 } // single row 4 cards, 
                            // const a1_h = { heigh: 7016, width: 9933 } // 
                            // const a4 = {height: 3508, width: 2480}   
                            // domtoimage.toBlob(document.getElementById('scedit_righttableroot'), a1_h).then(function (blob) {
                            //     saveAs(blob, 'my-node.png');
                            // });
                        }}>Download</button>} */}
                {/* /> */}

            </div>
        </div>
    </Portalmodal>



    // const exportdata_modal = <Jfmodal // EXPORT DATA MODAL
    //     show={exportmodal}
    //     title={'Export Guest List'}
    //     onHide={() => setExportmodal(false)}
    //     device={device}
    //     content={<Exportcontent
    //         sep={modalsep}
    //         csvdownload_div={// to make sure excel gt ready data || guest gt objs && not on drag and modal all close
    //             ((exceltableobjs && exceltableobjs.length > 0) || (guests && guests.length > 0)) && !ondrag && !edittablemodal && !addtablemodal && !addguestmodal && !editsingleguestmodal && !editseatedguestmodal
    //                 ? <CSVDownloader data={exceltableobjs} filename={`${STARTUP_NAME}_csv`} style={{ width: '100%' }} type={'link'} >
    //                     <button className="scedit_modalbtn" >Download</button>
    //                 </CSVDownloader>
    //                 : null}
    //         imgdownload_div={<button
    //             className="scedit_modalbtn"
    //             style={{ marginBottom: 44 }}
    //             onClick={() => {
    //                 // var elem = document.getElementById("scedit_righttableroot");
    //                 // alert(elem.scrollHeight)
    //                 // alert(elem.clientHeight)
    //                 // const a1_v = { height: 9933, width: 7016 } // single row 4 cards, 
    //                 // const a1_h = { heigh: 7016, width: 9933 } // 
    //                 // const a4 = {height: 3508, width: 2480}   
    //                 // domtoimage.toBlob(document.getElementById('scedit_righttableroot'), a1_h).then(function (blob) {
    //                 //     saveAs(blob, 'my-node.png');
    //                 // });
    //             }}>Download</button>}
    //     />}
    // />


    const edittablefunc = (name, max, tableIndex) => {
        if (!swapmade) {
            setEdittablemodal(true)
            setEdittabledata([name, max, tableIndex])
        }
    }

    const swapfunc = (ti) => {
        if (window.confirm(tableobjs[swapindex].name + ' will swap with ' + tableobjs[ti].name)) {
            var local_tableobjs = JSON.parse(JSON.stringify(tableobjs))
            var temp = local_tableobjs[ti]
            local_tableobjs[ti] = local_tableobjs[swapindex]
            local_tableobjs[swapindex] = temp

            try {
                axios({
                    method: 'POST',
                    url: UPDATE_SWAP,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { scid, urlhandler, save_id, passcode, tableobjs: local_tableobjs }
                }).then((item) => {
                    const { message, new_saveid } = item.data
                    if (message === 'SUCCESS') {
                        setTableobjs(local_tableobjs)
                        setSwapindex(-1)
                        setSave_id(new_saveid)
                        setEditseatedguestmodal(false)
                    }
                    else {
                        alert('expired')
                    }
                }).catch((e) => {
                    console.log(e)
                })
            }
            catch (e) { console.log(e) }


        }
    }

    const Savefunc = (guests, tableobjs) => {

        const expired = () => {
            setNoti('Session Expired')
            setTimeout(() => {
                props.history.push('/')
            }, 1500)
        }
        setEditedsignal(false)
        setNoti('Saving...')
        setTimeout(() => {
            try {
                axios({
                    method: 'POST',
                    url: UPDATE_SC,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { tableobjs, guests, scid, urlhandler, save_id, passcode, name, imgs, style, pages, onlineselectedpg, onlineselectedpgprops }
                }).then((item) => {
                    const { message, new_saveid } = item.data
                    if (message === 'SUCCESS') {
                        setSave_id(new_saveid)
                        setNoti('Saved')
                        setTimeout(() => { setNoti('') }, 1500)
                    }
                    else expired()
                }).catch((e) => { expired() })
            }
            catch (e) { expired() }
        }, 1000)
    }

    const mode0 = (n) => <div>{n}</div>

    const mode1 = (name, rela, cat) => {
        return <div className="scedit_modeoneroot" >
            <div className="scedit_modeonename">{name}</div>
            <div className="scedit_modeonerela">{'rel : ' + rela}</div>
            <div className="scedit_modeonecat">{'cat : ' + cat}</div>
        </div>
    }

    const LEFT = (
        <div className="scedit_leftroot">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', minHeight: 50 }}>
                {mmarr.length > 0 ? <div className={"scedit_iconput"} onClick={() => {
                    var local_tableobjs = tableobjs.slice()
                    var local_guests = guests.slice()
                    var ptable_mem = {}
                    var g_mem = []
                    for (let i = 0; i < mmarr.length; i++) {
                        const ind = mmarr[i]
                        if (typeof (ind) !== 'number' && ind.includes('_')) {
                            var indexes = ind.split('_')
                            const ti = parseInt(indexes[0], 10)
                            const si = parseInt(indexes[1], 10)
                            const name = local_tableobjs[ti]['seatnames'][si]
                            const rela = local_tableobjs[ti]['relas'][si]
                            const cat = local_tableobjs[ti]['cats'][si]

                            if (ptable_mem[ti]) {
                                ptable_mem[ti].push(si)
                                ptable_mem[ti].sort((a, b) => a - b);
                            }
                            else ptable_mem[ti] = [si]

                            local_guests = [
                                {
                                    name: name && name.length > 0 ? name : '',
                                    rela: rela && rela.length > 0 ? rela : '',
                                    cat: cat && cat.length > 0 ? cat : ''
                                },
                                ...local_guests
                            ]

                        }
                        else {
                            const { name, rela, cat } = guests[ind]
                            local_guests = [
                                {
                                    name: name && name.length > 0 ? name : '',
                                    rela: rela && rela.length > 0 ? rela : '',
                                    cat: cat && cat.length > 0 ? cat : ''
                                },
                                ...local_guests
                            ]
                            g_mem.push(ind)
                        }
                    }

                    // removal of used boxes
                    g_mem.sort((a, b) => a - b);

                    var ptable_keys = Object.keys(ptable_mem)
                    for (let i = 0; i < ptable_keys.length; i++) {
                        const ti = ptable_keys[i]
                        for (let j = 0; j < ptable_mem[ti].length; j++) {
                            const si = ptable_mem[ti][j] - j
                            local_tableobjs[ti]['seatnames'].splice(si, 1)
                            local_tableobjs[ti]['relas'].splice(si, 1)
                            local_tableobjs[ti]['cats'].splice(si, 1)
                        }
                    }

                    for (let i = 0; i < g_mem.length; i++) {
                        local_guests.splice(g_mem[i] - i, 1)
                    }
                    setMmarrsize([1, mmarr.length])
                    setMmarr([])
                    setTableobjs(local_tableobjs)
                    setGuests(local_guests)
                }}>PUT</div> : null}


            </div>
            <Droppable droppableId={'guestlist'} type="main" style={{ display: 'flex', placeContent: 'center', overflowX: 'hidden', position: 'relative', height: 'calc( 100vh - 113px )', minHeight: 'calc( 100vh - 113px )' }}>
                {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} style={getGuestliststyle(snapshot.isDraggingOver)}>
                        {guests && guests.map((guest, guestIndex) => {
                            const { name, rela, cat } = guest
                            const guestclick = () => {
                                if (!swapmade) {
                                    setEditsingleguestmodal(true)
                                    setEditsingleguestdata([name, rela, cat, guestIndex])
                                }
                            }
                            const massmoveclick = () => {
                                if (tableobjs.length > 0) {
                                    var temp = mmarr.slice()
                                    if (mmarr.includes(guestIndex)) {
                                        temp.splice(mmarr.indexOf(guestIndex), 1)
                                    }
                                    else temp.push(guestIndex)
                                    setMmarr(temp)
                                }
                            }
                            return <Draggable key={'GUEST_' + guestIndex} draggableId={'GUEST_' + guestIndex} index={guestIndex} type="main">
                                {(p, s) => (
                                    <div key={guestIndex + 'guest'} ref={p.innerRef} {...p.draggableProps} {...p.dragHandleProps} >
                                        <div className="scedit_guestrow" style={{ paddingBottom: guestIndex === guests.length - 1 ? 80 : 0 }}>
                                            <div className={mmarr.includes(guestIndex) ? "scedit_selectedguestindex" : "scedit_guestindex"} onClick={massmoveclick} >{guestIndex + 1}</div>
                                            <div
                                                className="scedit_gstripleft"
                                                onClick={guestclick}
                                                style={{ border: (mmarrsize[0] === 1 && guestIndex < mmarrsize[1]) ? '1px solid red' : '' }}
                                            >
                                                {edittype === 0 ? mode0(name) : mode1(name, rela, cat)}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            <div style={{ height: 80 }}></div>
            {/* <div style={{ display: 'flex', flexDirection: 'row', minHeight: 80, width: '100%' }}>
                <button className="scedit_addbtn" onClick={() => { setAddguestmodal(true) }}>
                    <div className="scedit_plus">+</div>
                    {ADDNEWUSER_SMALL}
                </button>
            </div> */}
        </div>
    )

    const updatecorners = () => {
        const index = corners.indexOf(imgs[2])
        const final_index = index === corners.length - 1 ? 0 : index + 1
        const newbanner = corners[final_index]
        setImgs([imgs[0], imgs[1], newbanner])
    }

    const updateff = (e) => {
        setStyle({ fontFamily: e.target.value })
    }

    const device_fs_map = {
        'WEB': 50,
        'TABLET': 40,
        'MOBILE': '5vmin'
    }

    const right_seating_tools = (
        <div className='scedit_righttableroot' >
            {tableobjs && Object.keys(tableobjs) && tableobjs.map(({ name, seatnames, max, relas, cats }, tableIndex) => {
                const tableKey = 'table_' + tableIndex
                var border_css = (seatnames.length - max === 0) ? '2px solid var(--donegreen)' : seatnames.length > max ? '2px solid red' : '1px solid var(--subgrey)' // 1px solid #707070
                const tableproperties_div = <div style={{ padding: 5, width: '100%', borderBottom: '1px solid var(--subgrey)', marginBottom: 10, display: 'flex', justifyContent: 'space-between' }} onClick={() => edittablefunc(name, max, tableIndex)}>
                    <div>{name}</div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div key={max}>{seatnames.length + ' / ' + max}</div>
                        {seatnames.length - max === 0
                            ? iconwrapper(TICK_ICON, tableiconcss)
                            : seatnames.length > max
                                ? iconwrapper(CROSS_ICON, tablecrossiconcss)
                                : null}
                    </div>
                </div>

                if (swapindex > -1) {
                    return <div className="scedit_table" key={tableKey} style={{ border: border_css }}>
                        {swapindex !== tableIndex
                            ? <div
                                style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', borderRadius: 5, backgroundColor: '#00000066', display: 'flex', alignItems: 'center', placeContent: 'center', color: 'white', cursor: 'pointer', fontSize: 16, }}
                                onMouseMove={() => { setSelectedswapindex(tableIndex) }}
                                onClick={() => swapfunc(tableIndex)} >
                                {selectedswapindex === tableIndex ? 'Click To Swap' : ''}
                            </div>
                            : null}
                        {tableproperties_div}
                        <Droppable droppableId={'tableindex_' + tableIndex} type="main" style={{ height: '100%', marginBottom: 80 }}>
                            {(provided, snapshot) => (
                                <div className="scedit_droppable" {...provided.droppableProps} ref={provided.innerRef} style={getTableStyle(snapshot.isDraggingOver)}>
                                    {seatnames && seatnames.map((seatname, seatIndex) => {
                                        return <Draggable key={tableIndex + '_' + seatIndex} draggableId={tableIndex + '_' + seatIndex} index={seatIndex} type="main">
                                            {(provided, snapshot) => (
                                                <div style={{ width: '100%' }} key={seatIndex + 'seatname'} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                                                    <div className="scedit_singleseatedroot">
                                                        <div className="scedit_singleseatednum">{seatIndex + 1}</div>
                                                        <div className="scedit_gstripright">{edittype === 0 ? mode0(seatname) : mode1(seatname, relas[seatIndex], cats[seatIndex])}</div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    })}
                                    {provided.placeholder}
                                </div>)}
                        </Droppable>
                        <div
                            // className={tableIndex !== swapindex ? "scedit_iconhide" : "scedit_icon"}
                            className={"scedit_icon"}
                            onClick={() => tableIndex === swapindex ? setSwapindex(-1) : setSwapindex(tableIndex)}>
                            {swapindex === tableIndex ? 'Cancel' : 'Swap'}
                        </div>
                    </div>
                }
                else
                    return <div className="scedit_table" key={tableKey} style={{ border: border_css }}>
                        {tableproperties_div}
                        <Droppable droppableId={'tableindex_' + tableIndex} type="main" style={{ height: '100%', width: '100%' }}>
                            {(provided, snapshot) => (
                                <div className="scedit_droppable" {...provided.droppableProps} ref={provided.innerRef} style={getTableStyle(snapshot.isDraggingOver)} >
                                    {seatnames && seatnames.map((seatname, i) => {
                                        const seatIndex = i
                                        const seatedguestclick = () => {
                                            if (!swapmade) {
                                                setEditseatedguestmodal(true)
                                                setEditseatedguestdata([seatname, relas[seatIndex], cats[seatIndex], seatIndex, tableIndex])
                                            }
                                        }
                                        const massmoveclick = () => {
                                            var temp = mmarr.slice()
                                            if (mmarr.includes(tableIndex + '_' + seatIndex)) {
                                                temp.splice(mmarr.indexOf(tableIndex + '_' + seatIndex), 1)
                                            }
                                            else temp.push(tableIndex + '_' + seatIndex)
                                            setMmarr(temp)
                                        }
                                        return <Draggable className="scedit_droppable" key={tableIndex + '_' + seatIndex} draggableId={tableIndex + '_' + seatIndex} index={seatIndex} type="main">
                                            {(provided, snapshot) => (
                                                <div key={seatIndex + 'seatname'} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                                                    <div className="scedit_singleseatedroot">
                                                        <div className={mmarr.includes(tableIndex + '_' + seatIndex) ? "scedit_singleselectedseatednum" : "scedit_singleseatednum"} onClick={massmoveclick} >{seatIndex + 1}</div>
                                                        <div
                                                            className="scedit_gstripright"
                                                            style={{ border: (mmarrsize[0] === 0 && tableIndex === mmarrsize[2] && mmarrsize[1] + 1 > seatnames.length - i) ? '1px solid red' : '' }}
                                                            onClick={seatedguestclick}
                                                        >
                                                            {edittype === 0 ? mode0(seatname) : mode1(seatname, relas[seatIndex], cats[seatIndex])}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    })}
                                    {provided.placeholder}
                                </div>)}
                        </Droppable>
                        <div style={{ display: 'flex', flexDirection: 'row', placeContent: 'flex-end' }}>
                            {mmarr.length > 0 ? <div className={"scedit_iconput"} onClick={() => {
                                var local_tableobjs = tableobjs.slice()
                                var local_guests = guests.slice()
                                var ptable_mem = {}
                                var g_mem = []
                                for (let i = 0; i < mmarr.length; i++) {
                                    const ind = mmarr[i]
                                    if (typeof (ind) !== 'number' && ind.includes('_')) {
                                        var indexes = ind.split('_')
                                        const ti = parseInt(indexes[0], 10) // table index
                                        const si = parseInt(indexes[1], 10) // seat index
                                        const name = local_tableobjs[ti]['seatnames'][si]
                                        const rela = local_tableobjs[ti]['relas'][si]
                                        const cat = local_tableobjs[ti]['cats'][si]

                                        if (ptable_mem[ti]) {
                                            ptable_mem[ti].push(si)
                                            ptable_mem[ti].sort((a, b) => a - b);
                                        }
                                        else ptable_mem[ti] = [si]

                                        local_tableobjs[tableIndex]['seatnames'].push(name && name.length > 0 ? name : '')
                                        local_tableobjs[tableIndex]['relas'].push(rela && rela.length > 0 ? rela : '')
                                        local_tableobjs[tableIndex]['cats'].push(cat && cat.length > 0 ? cat : '')
                                    }
                                    else {
                                        const { name, rela, cat } = guests[ind]
                                        local_tableobjs[tableIndex]['seatnames'].push(name && name.length > 0 ? name : '')
                                        local_tableobjs[tableIndex]['relas'].push(rela && rela.length > 0 ? rela : '')
                                        local_tableobjs[tableIndex]['cats'].push(cat && cat.length > 0 ? cat : '')
                                        g_mem.push(ind)
                                    }
                                }

                                // removal of used boxes
                                g_mem.sort((a, b) => a - b);

                                var ptable_keys = Object.keys(ptable_mem)
                                for (let i = 0; i < ptable_keys.length; i++) {
                                    const ti = ptable_keys[i]
                                    for (let j = 0; j < ptable_mem[ti].length; j++) {
                                        const si = ptable_mem[ti][j] - j
                                        local_tableobjs[ti]['seatnames'].splice(si, 1)
                                        local_tableobjs[ti]['relas'].splice(si, 1)
                                        local_tableobjs[ti]['cats'].splice(si, 1)
                                    }
                                }

                                for (let i = 0; i < g_mem.length; i++) {
                                    local_guests.splice(g_mem[i] - i, 1)
                                }

                                setMmarrsize([0, mmarr.length, tableIndex])
                                setMmarr([])
                                setTableobjs(local_tableobjs)
                                setGuests(local_guests)
                            }}>PUT</div> : null}
                            {tableobjs.length > 1 && !swapmade
                                ? <div key={'before'} className={"scedit_icon"} onClick={() => { setSwapindex(tableIndex); setMmarr([]); }}>Swap</div>
                                : null}
                        </div>
                    </div>
            })}
        </div>
    )

    const right_edit_designtools = ( // eslint-disable-line 
        <div className={'scedit_props'}>
            <div className="scedit_imgroot">
                <div className="scedit_imgs">
                    {hideseatcorner ? null : <div className="scedit_cornerleft" style={{ backgroundImage: `url(${imgs[2]})` }} onClick={updatecorners}></div>}
                    <div key={imgs[0] + 'left'} className="scedit_imgtop" onClick={() => { setBannermod(true) }} style={{ backgroundImage: 'url(' + imgs[0] + ')' }} />
                    {hideseatcorner ? null : <div className="scedit_cornerright" style={{ backgroundImage: `url(${imgs[2]})` }} onClick={updatecorners}></div>}
                </div>
                <div className="scedit_txtroot">
                    <div className="scedit_row">
                        <select className="scedit_ffroot" onChange={updateff} value={style.fontFamily}>
                            {NEW_ENGLISH_FF.map((item, index) => <option key={item + index} value={item}>{item}</option>)}
                        </select>
                        <Leftrightbtn
                            onLeft={() => {
                                const index = NEW_ENGLISH_FF.indexOf(style.fontFamily)
                                index === 0 ? updateff({ target: { value: NEW_ENGLISH_FF[NEW_ENGLISH_FF.length - 1] } }) : updateff({ target: { value: NEW_ENGLISH_FF[index - 1] } })
                            }}
                            onRight={() => {
                                const index = NEW_ENGLISH_FF.indexOf(style.fontFamily)
                                index === NEW_ENGLISH_FF.length - 1 ? updateff({ target: { value: NEW_ENGLISH_FF[0] } }) : updateff({ target: { value: NEW_ENGLISH_FF[index + 1] } })
                            }}
                        />
                    </div>
                    <textarea className={'scedit_txtarea'} key={style.fontFamily} style={{ fontFamily: style.fontFamily ? style.fontFamily : 'inherit', fontSize: device_fs_map[device], color: '#707070' }} id="w3review" name="w3review" rows="2" cols="50" onChange={(e) => setName(e.target.value)} value={name} />
                </div>
            </div>
        </div>
    )

    // {mode === 1 ? right_design_tools : null}
    const RIGHT = (
        <div className="scedit_right">
            <div className='scedit_topmenubar'>
                {/* <button className={'scedit_rightmode'} onClick={() => setEdittype(edittype === 0 ? 1 : 0)}>{edittype === 0 ? 'Simplify' : 'Detail'}</button> */}
                {/* <button className={'scedit_rightmode'} onClick={() => setShowsetting(true)}>Setting</button> */}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <button className='scedit_qrbtn' style={{ backgroundColor: '#ffffff', height: 40, width: 40, alignItems: 'center', placeContent: 'center', marginLeft: 10 }} onClick={() => setShowsetting(true)}>
                        {iconwrapper(SETTING_ICON, { width: 24, height: 24, })}
                    </button>
                    <button className='scedit_qrbtn' style={{ backgroundColor: '#ffffff', height: 40, width: 40, alignItems: 'center', placeContent: 'center' }} onClick={() => { if (!swapmade) { setShowtemplate(true) } }}>
                        {iconwrapper(ITINERARYTEMPLATE_ICON, { width: 24, height: 24, })}
                    </button>
                </div>

                <div className='scedit_btnrow'>
                    <div className={'scedit_righttext'}>
                        <div>{tableobjs.length}</div>
                        <div>{'Tables'}</div>
                    </div>
                    <div className={'scedit_righttext'}>
                        <div>{guests.length}</div>
                        <div>{'Unseated'}</div>
                    </div>
                    <div className={'scedit_righttext'}>
                        <div>{tableobjs.reduce((tot, arr) => tot + arr.seatnames.length, 0)}</div>
                        <div>{'Seated'}</div>
                    </div>
                    <div className={'scedit_righttext'} style={{ marginRight: 10 }}> {/* return the sum with previous value */}
                        <div>{guests.length + tableobjs.reduce((tot, arr) => tot + arr.seatnames.length, 0)}</div>
                        <div>{'Guests'}</div>
                    </div>
                    {/* <button className="scedit_preview" onClick={() => {
                        const win = window.open(('/seatingchartlookup/' + urlhandler + (passcode && passcode.length > 0 ? `/passcode=${passcode}` : '')), "_blank")
                        win.focus();
                    }}>
                        {LOOKUPUNSTYLE_ICON} Preview
                    </button> */}
                </div>
            </div>
            {/* <button className="scedit_addtablebtn" onClick={() => setAddtablemodal(true)}>
                <div className='scedit_plus'>+</div>
                <div style={{ width: 32, height: 31, paddingLeft: 2, fill: 'white', display: 'flex', placeContent: 'center', alignItems: 'center' }}>{tablesvg}</div>
            </button> */}

            {!swapmade
                ? <div className="scedit_btncol" >
                    <button className='scedit_portalbtn scedit_btntop' onClick={() => setAddtablemodal(true)}>
                        <div className='scedit_plus'>+</div>
                        <div style={{ width: 32, height: 31, paddingLeft: 2, fill: 'var(--mainpink)', display: 'flex', placeContent: 'center', alignItems: 'center' }}>{tablesvg}</div>
                    </button>
                    <button className='scedit_portalbtn scedit_btnbtm' onClick={() => { setAddguestmodal(true) }}>
                        <div className="scedit_plus">+</div>
                        {ADDNEWUSER_SMALL}
                    </button>
                </div>
                : null}


            {right_seating_tools}
        </div >
    )

    // useEffect(() => {
    //     if (!swapmade && !showsave) {
    //         setBeforeswapdata({})
    //     }
    // }, [mode, swapmade, showsave])

    const resetswapdata = () => {
        if (showsave && swapmade) {
            const { guests, tableobjs } = beforeswapdata
            setShowsave(false)
            setSwapmade(false)
            setGuests(JSON.parse(JSON.stringify(guests)))
            setTableobjs(JSON.parse(JSON.stringify(tableobjs)))
        }
    }

    const guestoreditorbtn = <div className='scedit_selectorrow'>
        <Togglebutton
            funcs={[
                () => {
                    setMode(mode === 0 ? 1 : 0)
                    resetswapdata()
                },
                () => {
                    setMode(mode === 1 ? 0 : 1)
                    resetswapdata()
                },
                () => {
                    setMode(mode === 2 ? 0 : 2)
                    resetswapdata()
                }
            ]}
            txts={['Arrange Seat', 'Design', 'Online']}
            btnselected={mode}
        />
        <div className='scedit_qnmark' onClick={() => { setHelp(true) }}>?</div>
    </div>

    const savebtn = editedsignal
        ? <button className="scedit_savingbtn" style={{ marginRight: 0 }} onClick={() => Savefunc(guests, tableobjs)}>Save</button>
        : <button className="scedit_savebtn" style={{ marginRight: 0 }} onClick={() => Savefunc(guests, tableobjs)}>Save</button>
    const exportbtn = <button className="scedit_exportbtn" onClick={() => { if (!swapmade) { setExportmodal(true) } }}>Export Data</button>
    const importbtn = <button className="scedit_importbtn" onClick={() => { if (!swapmade) { setImportmodal(true) } }}>Import Data</button>
    // const qrbtn = <button className="scedit_qrbtn" style={{ marginRight: 0 }} onClick={() => setQrmodal(true)}>{iconwrapper(PLANNING_QR_ICON, { width: 24, height: 24 })}</button>
    const backicon = <button className="planning_backicon" style={{ left: 10, position: 'absolute' }} onClick={() => { setReturnhome(true) }}>{ARROWLEFT_ICON}</button>
    const gapdom = <div style={{ width: 5 }} />
    const Scroot = () => {
        return !load
            ? <div className="scedit_root" id='scedit_root'>

                {[
                    // Arrange Seat
                    <div className="planning_topbar" >
                        <div className="scedit_dlimportsavehidden">{exportbtn}{gapdom}{importbtn}</div>
                        {backicon}
                        {guestoreditorbtn}
                        <div className="scedit_dlimportsave">{exportbtn}{gapdom}{importbtn}</div>
                    </div>,
                    // Design
                    <div className="planning_topbar" >
                        <div className="scedit_dlimportsavehidden"> {savebtn}</div>
                        {backicon}
                        {guestoreditorbtn}
                        <div className="scedit_dlimportsave"> {savebtn}</div>
                    </div>,
                    // Online
                    <div className="planning_topbar" >
                        <div className="scedit_dlimportsave" style={{ opacity: 0, left: 10, userSelect: 'none', pointerEvents: 'none' }} />
                        {backicon}
                        {guestoreditorbtn}
                        <div className="scedit_dlimportsave" />
                    </div>
                ][mode]}
                {[
                    <div className="scedit_bottom">
                        <DragDropContext onDragEnd={onDragendseat}>
                            {RIGHT}
                            {LEFT}
                        </DragDropContext>
                    </div>,
                    <Scdesign
                        tableobjs={tableobjs}
                        guests={guests}
                        pages={pages}
                        updatePages={(pgs) => { setPages(Object.assign(pgs, {})) }}
                    />,
                    <Online
                        pages={pages}
                        onlineselectedpg={onlineselectedpg}
                        updateOnlineselectedpg={(val, onlinemaintxtliner) => {
                            var data = Extractpageprops(pages[parseInt(val, 10)], onlinemaintxtliner)
                            data['onlinemaintxtliner'] = onlinemaintxtliner // 0 - oneliner 1 - twoliner
                            setOnlineselectedpgprops(Object.assign(data, {}))
                            setOnlineselectedpg(parseInt(val, 10))
                            setOnlineselectedsave(true)
                        }}
                        changetodesignmode={()=>setMode(1)}
                        openqr={()=>{setQrmodal(true)}}
                    />
                ][mode]}
                {template_modal}
                {setting_modal}
                {edittable_modal}
                {addtable_modal}
                {addguest_modal}
                {editsingleguest_modal}
                {editseatedsingleguest_modal}
                {importdata_modal}
                {exportdata_modal}
                {help_modal}
                {qr_modal}
                <Noti noti={noti} />
                <Jfmodal
                    show={bannermod}
                    onHide={() => { setBannermod(false) }}
                    title={'Select Banner'}
                    device={device}
                    content={
                        <div className="Qr_bannermodal">
                            <Banners
                                select={({ index }) => { setImgs([banners[index], flowers[index], imgs[2]]); setBannermod(!bannermod); }}
                                selected={imgs[0]}
                                banners={banners}
                                names={bannernames}
                            />
                        </div>
                    }
                />
                {returnhome
                    ? <Modcfm
                        onHide={() => { setReturnhome(false) }}
                        onOk={() => { props.history.push(PLOBJ['seatingchart']['toolurl']) }}
                        onNo={() => { setReturnhome(false) }}
                        txt={'You are returning to planning tools menu.'}
                    />
                    : null}

                {showsave
                    ? <div className='Rsvpqnexplorer_swappedsavedroot'>
                        <button
                            id='Scedit_cancelbtn'
                            className='Rsvpqnexplorer_swappedcancel'
                            onClick={() => {
                                const { guests, tableobjs } = beforeswapdata
                                setShowsave(false)
                                setSwapmade(false)
                                setGuests(JSON.parse(JSON.stringify(guests)))
                                setTableobjs(JSON.parse(JSON.stringify(tableobjs)))
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className='Rsvpqnexplorer_swappedsavedbtn'
                            onClick={() => {
                                try {
                                    axios({
                                        method: 'POST',
                                        url: UPDATE_SWAP,
                                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                        data: { scid, urlhandler, save_id, passcode, guests, tableobjs }
                                    }).then((item) => {
                                        const { message, new_saveid } = item.data
                                        if (message === 'SUCCESS') {
                                            setSave_id(new_saveid)
                                            setSwapmade(false)
                                            setBeforeswapdata({})
                                            setShowsave(false)
                                        }
                                        else {
                                            alert('expired')
                                        }
                                    }).catch((e) => {
                                        console.log(e)
                                    })
                                }
                                catch (e) { console.log(e) }
                            }}
                        >
                            Save
                        </button>

                    </div>
                    : null}
            </div>
            : null
    }

    return load ? <Loading /> : device === 'MOBILE' ? Nomobile() : Scroot()

}

export default Seatchartedit