import React, { useEffect, useState, useRef } from "react"; // useRef
import { Checkdevice, Portalmodal, ScreenHeight } from "../../components";
import { iconwrapper, shuffle } from "../../config/commonfunctions";
import { USER_READMAP } from "../../config/api";
import { PROFILE_SMALL, STARTUP_NAME, WEBSITE_URL } from "../../config/constants";
import axios from 'axios'
import L from "leaflet";
import './Jmap.css'
import './Jmapmobile.css'
import { CATSMALL_ICON, WEAVEBLISS_WHITE } from "../../config/svgicons";

//https://leafletjs.com/examples/custom-icons/

const genpopup = (loc, tags, img, device, mobilefunc) => {
    const { src } = img//detail
    var tagssyntax = ''

    for (let i = 0; i < tags.length; i++) {
        var tag = tags[i]
        tagssyntax += `
            <div key={'Jmap_popuptag' + ${i}} class="Jmap_popuptag">
                ${'# ' + tag}
            </div>
        `
    }

    if (device === 'WEB') {
        return `<div class="Jmap_popuproot">
            <div class="Jmap_popuptitle">${loc}</div>
            <img src=${src} class="Jmap_imgroot">
            <div class="Jmap_popuptags">${tagssyntax}</div>
         </div>`
    }
    else {


        return `<div class="Jmap_popuproot">
            <div class="Jmap_popuptitle">${loc}</div>
            <img src=${src} class="Jmap_imgroot">
            <div class="Jmap_popuptags">${tagssyntax}</div>
            <button id="demo" class="Jmap_popupbtnclick">View</button>
        </div>`
    }


}

const gentooltip = (loc) => {
    return `<div class="Jmap_tooltiproot">
    <div class="Jmap_popuptitle">${loc}</div>
    </div>`
}

const createCircle = (latLng, txt, radius, borderWidth, tags, loc, img, gallery) => {
    var size = radius * 2;
    var style = 'style="width: ' + size + 'px; height: ' + size + 'px; border-width: ' + borderWidth + 'px;"';
    var iconSize = size + (borderWidth * 2);
    var icon = L.divIcon({
        html: '<span class="Jmap_circle Jmap_circlu"' + style + '>' + txt + '</span>',
        className: '',
        iconSize: [iconSize, iconSize]
    });
    var marker = L.marker(latLng, {
        icon: icon,
        tags,
        loc,
        img,
        gallery
    });
    return (marker);
}

const isthereotherexistingselectedtags = (newtag, markertags, selectedtags) => {
    var local_selectedtags = selectedtags.slice()
    local_selectedtags.splice(selectedtags.indexOf(newtag), 1)
    for (let i = 0; i < local_selectedtags.length; i++) {
        if (markertags.includes(local_selectedtags[i])) { return true }
    }
    return false
}

const sortGallery = (gallery) => {
    var ourvendors = []
    var others = []
    for (let i = 0; i < gallery.length; i++) {
        const { cvendor } = gallery[i]
        cvendor ? ourvendors.push(gallery[i]) : others.push(gallery[i])
    }

    return [...shuffle(ourvendors), ...shuffle(others)]
}

function Jmap(props) {
    const device = Checkdevice()
    const mapRef = useRef(null);
    const layerRef = useRef(null);
    const [alltags, setAlltags] = useState([])
    const [selectedtags, setSelectedtags] = useState([])
    const [selectedmarker, setSelectedmarker] = useState('') // marker selected, affect the right screen
    const [markers, setMarkers] = useState([])
    const [unactivemarkers, setUnactivemarkers] = useState([]) // markers that gt removed during runtime
    const [gallery, setGallery] = useState([]) // array of gallery obj
    const [mobilegallerymodal, setMobilegallerymodal] = useState(false) // mobile gallery modal
    const [mobiletagmodal, setMobiletagmodal] = useState(false)// mobile tag modal
    const [mobiletagold, setMobiletagold] = useState([])

    const ht = ScreenHeight()
    function markerhovered() {
        if (!this.isPopupOpen()) this.openTooltip();
    }

    function markerclicked(e) {
        const { loc, gallery } = e.target.options // tags
        setSelectedmarker(loc)
        setGallery(gallery)
    }

    useEffect(() => {
        props.hidetopbar(true)
        try {
            mapRef.current = L.map('map', {
                center: [1.3521, 103.8198],
                zoom: 12,
                layers: [L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", { attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' })],
                scrollWheelZoom: true,
                zoomControl: false
            })
            L.control.zoom({ position: 'bottomright' }).addTo(mapRef.current);
            layerRef.current = L.layerGroup().addTo(mapRef.current);

            axios({
                method: 'GET',
                url: USER_READMAP
            }).then((res) => {
                const { data, msg } = res.data
                if (msg === 'SUCCESS') {
                    const alllocs = Object.keys(data)
                    var curr_alltags = alltags.slice()
                    var curr_markers = markers.slice()
                    for (let i = 0; i < alllocs.length; i++) {
                        const loc = alllocs[i]
                        const { tags, lat, long, img, gallery } = data[loc]

                        var marker = createCircle(
                            [lat, long],
                            gallery && gallery.length > 0 ? gallery.length.toString() : '0',
                            15,
                            3,
                            tags,
                            loc,
                            img,
                            gallery ? sortGallery(gallery) : []
                        )

                        if (device === 'WEB') {
                            marker.bindPopup(genpopup(loc, tags, img, device))
                            marker.bindTooltip(gentooltip(loc))
                        }
                        else {
                            marker.bindPopup(genpopup(loc, tags, img, device)).on("popupopen", (e) => {
                                var popUp = e.target.getPopup()
                                popUp.getElement()
                                    .querySelector("#demo")
                                    .addEventListener("click", e => {
                                        setSelectedmarker(loc)
                                        setGallery(gallery)
                                        setMobilegallerymodal(true)
                                    })
                            })
                        }

                        marker.on('mouseover', markerhovered);
                        marker.on('click', markerclicked);
                        mapRef.current.addLayer(marker);
                        curr_markers.push(marker)
                        for (let j = 0; j < tags.length; j++) {
                            if (!curr_alltags.includes(tags[j])) {
                                curr_alltags.push(tags[j])
                            }
                        }
                    }
                    setMarkers(curr_markers)
                    setAlltags(curr_alltags)
                    setSelectedtags(curr_alltags)
                }
            })
        }
        catch (e) { console.log(e) }
    }, [])

    const updatemarker = (e) => {
        console.log('e', e)
        const { value } = e.target
        const curr_selectedtag = value
        var ams = [] // active makers
        var ums = unactivemarkers.slice() // unactive markers
        var local_selectedtags = selectedtags.slice()

        if (local_selectedtags.includes(curr_selectedtag)) {// all tags by default selected, remove tag
            for (let i = 0; i < markers.length; i++) { // loop all markers

                const { tags } = markers[i]['options'] // each marker has an arr of tags

                if (tags.includes(curr_selectedtag) // if curr_selectedtag in tags 
                    && isthereotherexistingselectedtags(value, tags, selectedtags)) { // and other tag is still selected
                    ams.push(markers[i])// update active markers
                }
                else if (tags.includes(curr_selectedtag)) { // if curr_selectedtag in tags 
                    ums.push(markers[i]) // update unactive markers and rmv markers frm map
                    mapRef.current.removeLayer(markers[i])
                }
                else {
                    ams.push(markers[i])
                }
            }
            local_selectedtags.splice(local_selectedtags.indexOf(value), 1)
        }
        else { // add tag
            ams = markers.slice()
            var new_ums = []
            for (let i = 0; i < unactivemarkers.length; i++) {
                const { tags } = unactivemarkers[i]['options']
                if (tags.includes(value)) {
                    ams.push(unactivemarkers[i])
                    mapRef.current.addLayer(unactivemarkers[i])
                }
                else {
                    new_ums.push(unactivemarkers[i])
                }
            }
            ums = new_ums.slice()
            local_selectedtags.push(value)
        }
        setUnactivemarkers(ums)
        setMarkers(ams)
        setSelectedtags(local_selectedtags)
    }

    const backhome = () => {
        props.hidetopbar(false)
        props.history.push('/')
    }

    const updateMobilegallerymodal = () => {
        setMobilegallerymodal(false)
    }

    const updateMobiletagmodal = () => {
        setMobiletagmodal(false)
        setMobiletagold([])
    }

    return <div className="Jmapcenter">
        Coming Soon!
    </div>

    return device === 'WEB'
        ? <div className="Jmap_root">
            <div className="Jmap_logoroot" onClick={backhome}>
                {iconwrapper(WEAVEBLISS_WHITE, { width: 30, height: 30, color: '#ffffff' })}
                <div className="Jmap_txt">{`${STARTUP_NAME}`}</div>
            </div>
            <div className="Jmap_subroot">
                <div className="Jmap_map" id="map" />
                <div className="Jmap_right">
                    <div className="Jmap_righttitle">{selectedmarker}</div>
                    <div className="Jmap_galleryroot">
                        {gallery
                            ? gallery.map(({ cvendor, img, link, vname, username }, x) => {
                                return cvendor
                                    ? <div className="Jmap_rightusroot">
                                        <a key={x + 'in'} className="Jmap_rightus" href={link} target="_blank">{vname}</a>
                                        <a className="Jmap_rightuslookup" href={`https://${WEBSITE_URL}/vendorlookup/${username}`} target="_blank">{PROFILE_SMALL}</a>
                                    </div>
                                    : <a key={x + 'out'} className="Jmap_rightout" href={link} target="_blank">{vname}</a>
                            })
                            : null}
                    </div>
                </div>
            </div>
            <div className="Jmap_tagsroot">
                {alltags.map((tag, i) => {
                    return <button
                        className={"Jmap_tag" + selectedtags.includes(tag)}
                        key={tag + i + selectedtags.includes(tag)}
                        onClick={updatemarker}
                        value={tag}>
                        {'# ' + tag}
                    </button>
                })}
            </div>
        </div>
        : <div className="Jmap_mobileroot">
            <div className="Jmap_mapmobile" style={{ height: ht }} id="map" />
            <button
                className="Jmap_taghexmobile"
                onClick={() => {
                    setMobiletagmodal(true)
                    setMobiletagold(selectedtags.slice())
                }}>
                {iconwrapper(CATSMALL_ICON, { width: 21, height: 21, display: 'flex', placeContent: 'center' })}
                <div style={{ paddingLeft: 10, fontSize: 14 }}>Tags</div>
            </button>
            <Portalmodal
                portaltype={'commonportal'}
                open={mobiletagmodal}
                onClose={() => {
                    setMobiletagmodal(false)
                    const old_alltags = mobiletagold.slice()
                    setSelectedtags(old_alltags)
                    setMobiletagold([])

                }}
                floatingclose={false}
                backdropvisible={true}
                modalstyle={{}}
                closestyle={{ right: 0 }}
                floatingclosecolor='#333333'>
                <div className={'Rsvpexplorer_portal'} >
                    <div className='Rsvpexplorer_portaltitle'>Tags</div>
                    <div className="Jmap_tagwrap">
                        {alltags.map((tag, i) => {
                            return <button
                                key={tag + i + selectedtags.includes(tag)}
                                className={"Jmap_tagmobile" + selectedtags.includes(tag)}
                                onClick={updatemarker}
                                value={tag}>
                                {'# ' + tag}
                            </button>
                        })}
                    </div>
                    <button className="Rsvpexplorer_portalsave" onClick={updateMobiletagmodal}>
                        Done
                    </button>
                </div>
            </Portalmodal>
            <Portalmodal
                portaltype={'commonportal'}
                open={mobilegallerymodal}
                onClose={updateMobilegallerymodal}
                floatingclose={false}
                backdropvisible={true}
                modalstyle={{}}
                closestyle={{ right: 0 }}
                floatingclosecolor='#333333'>
                <div className={'Rsvpexplorer_portal'} >
                    <div className='Rsvpexplorer_portaltitle'>{selectedmarker}</div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 100, margin: '0px 10px 100px 10px' }}>
                        {gallery.map(({ cvendor, img, link, vname, username }, x) => {
                            return cvendor
                                ? <div key={x + 'in'} style={{ display: 'flex', flexDirection: 'row' }}>
                                    <a className="Jmap_rightus" href={link} target="_blank">{vname}</a>
                                    <a className="Jmap_rightuslookupmobile" href={`https://${WEBSITE_URL}/vendorlookup/${username}`} target="_blank">{PROFILE_SMALL}</a>
                                </div>
                                : <a key={x + 'out'} className="Jmap_rightout" href={link} target="_blank">{vname}</a>
                        })}
                    </div>
                    <button className="Rsvpexplorer_portalsave" onClick={updateMobilegallerymodal}>
                        Done
                    </button>
                </div>
            </Portalmodal>
        </div>

}
export default Jmap