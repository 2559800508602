import React, { useEffect, useState } from 'react'
import {
    WEB,
    SS_LINE, EMPTYSPACE, EMPTY, READABLE_VENDORTYPE_MAP, NAV_BAR_HEIGHT, TABLET, IMG_ALT, MAX_WEB,
    COU_ICON, MAIN_PINK_COLOR, CONTACT_WEAVEBLISS, TIER_DIAMOND_POSITION_VL,
} from '../../config/constants'
import { Renderprojects, Renderpricelists, Renderdetail, Renderreviews, Renderpartners } from './components'
import { subtitle } from './components/Commonfunction';
import { Heart } from '../../async_components'
import { getS3fullpath_withbucketname, iconwrapper, Retrieve_personal_info } from '../../config/commonfunctions';
import { Loading, Portalmodal } from '../../components';
import { BUTTONSTYLE } from '../../config/cssconstants';

// css
import {
    THINFACEBOOK_ICON,
    THINYOUTUBE_ICON,
    THINTIKTOK_ICON,
    THINLINKEDIN_ICON,
    THININSTAGRAM_ICON,
    THINTELEGRAM_ICON,
    THINWECHAT_ICON,
    THINWHATSAPP_ICON,
    THINLINE_ICON,
    THINEMAIL_ICON,
    THINLOCATION_ICON,
    THINLINK_ICON,
    RSVPTELEPHONE_ICON,
} from '../../config/svgicons';
import axios from 'axios'
import { ALL_VENDOR_READ_DETAIL_LINK } from '../../config/api';
import './Vendorlookup.css'
import Enquirycontent from '../../components/Enquirycontent/Enquirycontent';

const WHATSAPP_MSG_CONTENT = (displayname) => {
    return 'https://api.whatsapp.com/send?phone=' + CONTACT_WEAVEBLISS + '&text=' + `I would like to claim the following listing: ${displayname}`
}
const SEP_LINE = <div className='Renderdetail_sep' />

const LINE_ICON = <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 20, width: 19, height: 15, backgroundColor: 'var(--maindark)' }}><div style={{ fontSize: 6, color: 'white', paddingTop: 1, paddingLeft: 1 }}>LINE</div><div style={{ height: 5, width: 5, border: 0, position: 'absolute', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', borderBottom: '5px solid var(--maindark)', borderRadius: 15, transform: 'rotate(330deg) translate(-5px,5px)' }}></div></div>

const WEB_LEFT_ROOT_WIDTH = 350

const iconcss = { margin: '0px 15px 0px 15px', width: 18, height: 18, display: 'flex', alignItems: 'center', placeSelf: 'center' }
const iconcss_lg = { margin: '0px 15px 0px 15px', width: 22, height: 22, display: 'flex', alignItems: 'center', placeSelf: 'center', }

const unpacklocation = (vendortype_id, secondaryvendortype_id) => {
    if (secondaryvendortype_id) {
        return {
            sg: secondaryvendortype_id.sg,
            ch: secondaryvendortype_id.ch,
            my: secondaryvendortype_id.my,
            hk: secondaryvendortype_id.hk,
            tw: secondaryvendortype_id.tw,
            kr: secondaryvendortype_id.kr,
            idn: secondaryvendortype_id.idn,

            sgminprice_vt: vendortype_id.sgminprice,
            chminprice_vt: vendortype_id.chminprice,
            myminprice_vt: vendortype_id.myminprice,
            hkminprice_vt: vendortype_id.hkminprice,
            twminprice_vt: vendortype_id.twminprice,
            krminprice_vt: vendortype_id.krminprice,
            idnminprice_vt: vendortype_id.idnminprice,

            sgminprice_svt: secondaryvendortype_id.sgminprice,
            chminprice_svt: secondaryvendortype_id.chminprice,
            myminprice_svt: secondaryvendortype_id.myminprice,
            hkminprice_svt: secondaryvendortype_id.hkminprice,
            twminprice_svt: secondaryvendortype_id.twminprice,
            krminprice_svt: secondaryvendortype_id.krminprice,
            idnminprice_svt: secondaryvendortype_id.idnminprice,
        }
    }
    else {
        return {
            sg: vendortype_id.sg,
            ch: vendortype_id.ch,
            my: vendortype_id.my,
            hk: vendortype_id.hk,
            tw: vendortype_id.tw,
            kr: vendortype_id.kr,
            idn: vendortype_id.idn,

            sgminprice_vt: vendortype_id.sgminprice,
            chminprice_vt: vendortype_id.chminprice,
            myminprice_vt: vendortype_id.myminprice,
            hkminprice_vt: vendortype_id.hkminprice,
            twminprice_vt: vendortype_id.twminprice,
            krminprice_vt: vendortype_id.krminprice,
            idnminprice_vt: vendortype_id.idnminprice,
        }
    }
}

function moredom(arr, toggle, key) {
    return arr && arr.length > 0 // show first contact
        ? <div style={{ display: 'flex', flexDirection: 'row' }}>
            {key === 'WEBSITE_LINKS'
                ? <a className="VL_infocontent" href={'https://' + arr[0]['content']} target="_blank" rel="noopener noreferrer">{arr[0]['content'].length > 0 ? arr[0]['content'] : '-'}</a>
                : null}

            {key === 'CONTACTS'
                ? arr[0]['content'].length === 10 && arr[0]['content'][0] === '6' && arr[0]['content'][1] === '5'
                    ? ('+65 ') + arr[0]['content'].slice(2)
                    : arr[0]['content'].length === 0
                        ? '-'
                        : <div>{arr[0]['content']}</div>
                : null}

            {key === 'PUBLICEMAILS'
                ? <a className='VL_infocontent'>{arr[0]['content']}</a>
                : null}

            {arr.length > 1
                ? <div className='VL_infomore' onClick={toggle}> + {arr.length - 1}</div>
                : null}
        </div>
        : '-';
}

function Vendorlookup(props) {

    const [screenwidth, setScreenwidth] = useState(0)
    const [screenheight, setScreenheight] = useState(0)
    const [scrollinglock, setScrollinglock] = useState(false)

    //user stuff
    const [acct_type, setAcct_type] = useState(EMPTY)// user, vendor or EMPTY
    const [acct_name, setAcct_name] = useState(EMPTY)
    const [acct_email, setAcct_email] = useState(EMPTY)
    const [acct_contact, setAcct_contact] = useState(EMPTY)
    const [acct_location, setAcct_location] = useState(EMPTY)
    const [acct_usertype, setAcct_usertype] = useState(EMPTY)// Wedding, Event, Guest or NOUT

    // web stuff
    const [selected_category, setSelected_category] = useState('') // Detail, Project, Pricelist, Review
    const [instagram_link, setInstagram_link] = useState(EMPTY)
    const [facebook_link, setFacebook_link] = useState(EMPTY)
    const [youtube_link, setYoutube_link] = useState(EMPTY)
    const [tiktok_link, setTiktok_link] = useState(EMPTY)
    const [linkedin_link, setLinkedin_link] = useState(EMPTY)
    const [whatsapp_link, setWhatsapp_link] = useState(EMPTY) // Request service
    const [wechat_link, setWechat_link] = useState(EMPTY)
    const [telegram_link, setTelegram_link] = useState(EMPTY)
    const [line_link, setLine_link] = useState(EMPTY)

    // for other contacts
    const [othercontacts_dialog_state, setOthercontacts_dialog_state] = useState(false) // open or close the othercontacts dialog
    const [othercontacts_dialog_content, setOthercontacts_dialog_content] = useState(EMPTY) // most likely the id of the particular(line or wechat.....) contact
    const [othercontacts_dialog_link_action, setOthercontacts_dialog_link_action] = useState(EMPTY) // the button clicked action
    const [othercontacts_dialog_endtextinfo, setOthercontacts_dialog_endtextinfo] = useState(EMPTY) // to indicate if the chat is available in mobile or web

    //vendor stuff
    // is common between primary and secondaryvendor
    const [profileimg_id, setProfileimg_id] = useState(EMPTY) // RenderHead
    const [displayname, setDisplayname] = useState(EMPTY) // RenderHead
    const [vendortype, setVendortype] = useState(EMPTY) // RenderHead
    const [secondaryvendortype, setSecondaryvendortype] = useState(EMPTY) // RenderHead
    const [gtsvt, setGtsvt] = useState(false)
    const [about, setAbout] = useState(EMPTY) // RenderHead
    const [location, setLocation] = useState(EMPTY) // RenderHead
    const [tier, setTier] = useState(EMPTY)

    const [since, setSince] = useState(-1) // RenderExperience
    const [hosted, setHosted] = useState(-1) // RenderExperience
    const [teamnumber, setTeamnumber] = useState(-1) // RenderExperience

    const [contact, setContact] = useState(EMPTY)// RenderInfo
    const [contacts, setContacts] = useState([])// RenderInfo - [ {title:'', content:''}, {title:'', content:''}, {title:'', content:''} ]
    const [publicemail, setPublicemail] = useState(EMPTY)// RenderInfo
    const [publicemails, setPublicemails] = useState([])// RenderInfo - [ {title:'', content:''}, {title:'', content:''}, {title:'', content:''} ]
    const [website_link, setWebsite_link] = useState(EMPTY)// RenderInfo
    const [website_links, setWebsite_links] = useState([])// RenderInfo - [ {title:'', content:''}, {title:'', content:''}, {title:'', content:''} ]
    const [address, setAddress] = useState(EMPTY)// RenderInfo

    const [showmore, setShowmore] = useState(EMPTY) // CONTACTS, PUBLICEMAILS, WEBSITE_LINKS

    const [latlong, setLatlong] = useState([])//RenderDetail

    const [showmap, setShowmap] = useState([])//RenderDetail

    const [description, setDescription] = useState(EMPTY) //RenderContent 

    const [allpricelist_ids, setAllpricelist_ids] = useState([]) //Renderpricelists

    const [projectinterface_ids, setProjectinterface_ids] = useState([]) // for Project components
    const [vendortypeinterface_id, setVendortypeinterface_id] = useState(EMPTY) // for Favourite store ids 
    const [vendortype_id, setVendortype_id] = useState(EMPTY)

    const [eventorwed, setEventorwed] = useState(EMPTY) // to determine event vendor or wedding vendor

    // only in primary
    const [pri_specific, setPri_specific] = useState(EMPTY)

    // only in secondary
    const [sec_specific, setSec_specific] = useState(EMPTY)

    const [qnsans, setQnsans] = useState([]) // vendor's self defined questions

    const [owner, setOwner] = useState(true)

    const [review_ids, setReview_ids] = useState([])

    const [ready, setReady] = useState(false)

    const [mobile_hideenquireminspend, setMobile_hideenquireminspend] = useState(false) // false- no hide, true hide

    const [vendorwhocontrol, setVendorwhocontrol] = useState('') //VENDOR or WEAVEBLISS

    const [location_price_map, setLocation_price_map] = useState({})

    const [recommendation, setRecommendation] = useState({})

    const [userlike, setUserlike] = useState(false)

    const [loading, setLoading] = useState(true)

    const [reviewloading, setReviewloading] = useState(true)

    const [firsttime, setFirsttime] = useState(true)

    // const device = screenwidth < TABLET ? 'MOBILE' : screenwidth < WEB + 1 ? 'TABLET' : 'WEB'
    // const device = Checkdevice()
    const [device, setDevice] = useState(EMPTY)


    // ---------- Enquiry Portal related data END

    const mobileportalcss = { width: '100%', height: '100%', backgroundColor: 'white', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)', overflowY: 'auto' }
    const webtabletportalcss = { width: 600, height: 650, backgroundColor: 'white', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)' }

    // show enquire portal
    const [showenquire, setShowenquire] = useState(false)

    // for both single enquire and all selected enquire
    const [enquiredata, setEnquiredata] = useState([])

    // login user data
    const [loggeduserdata, setLoggeduserdata] = useState({
        'name': '',
        'spousename': '',
        'date_w': '',
        'date_e': '',
        'email': '',
        'contact': '',
        'usertype': '', // wedding, event or guest
        'location': ''
    })

    // ---------- Enquiry Portal related data END


    const updateScroll = () => {
        setScrollinglock(window.scrollY > (50 + NAV_BAR_HEIGHT) ? true : false)
    }

    const updateWindowDimensions = () => {
        setScreenheight(window.innerHeight)
        setScreenwidth(window.innerWidth)
    }


    useEffect(() => {

        try {
            const { vendorname } = props.match.params
            let { token, _id, acct_type } = Retrieve_personal_info()

            axios
                .post(
                    ALL_VENDOR_READ_DETAIL_LINK,
                    {
                        method: 'POST',
                        data: {
                            vendorname,
                            user_id: _id,
                            acct_type: acct_type,
                            token: token,
                        }
                    })
                .then((result) => {

                    const {
                        owner,
                        recommendation,
                        userlike,
                        userpersonalinfo,
                        vendortype_id,
                        secondaryvendortype_id,
                        vwc,
                        allpricelist_ids
                    } = result.data.data

                    const {
                        profileimg_id, displayname, about, vendortype, location, secondaryvendortype, tier,
                        since, hosted, teamnumber,
                        contact, contacts, publicemail, publicemails, address, latlong, showmap,
                        description,
                        website_link,//  Request Service
                        website_links,// Request Service
                        ready,

                        instagram_link,
                        facebook_link,
                        youtube_link,
                        linkedin_link,
                        tiktok_link,
                        whatsapp_link, // for request
                        wechat_link,
                        telegram_link,
                        line_link,
                        projectinterface_ids,
                        project_num,
                        vendortypeinterface_id,
                        _id, //vendortype_id.
                        review_ids,
                        eventorwed,

                        qnsans
                    } = vendortype_id

                    setVendorwhocontrol(vwc)

                    setLoggeduserdata(userpersonalinfo)

                    setAcct_contact(userpersonalinfo.contact)
                    setAcct_usertype(userpersonalinfo.usertype)
                    setAcct_email(userpersonalinfo.email)
                    setAcct_name(userpersonalinfo.name)
                    setAcct_location(userpersonalinfo.location)

                    setEventorwed(eventorwed)

                    setProfileimg_id(profileimg_id)
                    setDisplayname(displayname)
                    setAbout(about)
                    setVendortype(vendortype)
                    setSecondaryvendortype(secondaryvendortype)
                    setGtsvt(secondaryvendortype ? true : false)
                    setLocation(location)
                    setTier(tier)

                    setSince(since)
                    setHosted(hosted)
                    setTeamnumber(teamnumber)

                    setContact(contact)
                    setContacts(JSON.parse(JSON.stringify(contacts)))
                    setPublicemail(publicemail)
                    setPublicemails(JSON.parse(JSON.stringify(publicemails)))
                    setWebsite_link(website_link)

                    // remove all https://
                    let new_websitelinks = JSON.parse(JSON.stringify(website_links))
                    for (let i = 0; i < new_websitelinks.length; i++) {
                        new_websitelinks[i]['content'] = new_websitelinks[i]['content'].startsWith('https://') ? new_websitelinks[i]['content'].slice(8) : new_websitelinks[i]['content']
                    }
                    setWebsite_links(new_websitelinks)
                    // setWebsite_links(JSON.parse(JSON.stringify(website_links)))
                    setAddress(address)
                    setLatlong(latlong)
                    setShowmap(showmap)

                    setDescription(description)

                    setInstagram_link(instagram_link)
                    setFacebook_link(facebook_link)
                    setYoutube_link(youtube_link)
                    setTiktok_link(tiktok_link)
                    setLinkedin_link(linkedin_link)

                    setWhatsapp_link(whatsapp_link)
                    setWechat_link(wechat_link)
                    setTelegram_link(telegram_link)
                    setLine_link(line_link)

                    setVendortypeinterface_id(vendortypeinterface_id)
                    setVendortype_id(_id)

                    setPri_specific(vendortype_id != null ? vendortype_id.specific : [])
                    setSec_specific(secondaryvendortype_id != null ? secondaryvendortype_id.specific : [])

                    setLocation_price_map(unpacklocation(vendortype_id, secondaryvendortype_id))

                    setProjectinterface_ids(projectinterface_ids)

                    setOwner(owner)
                    setReview_ids(review_ids)
                    setReady(ready)
                    setQnsans(qnsans)

                    setAllpricelist_ids(allpricelist_ids)

                    setRecommendation(recommendation)

                    setUserlike(userlike)

                    setAcct_type(acct_type)

                    try {
                        let lastelemname = props.history.location.pathname.split('/')[props.history.location.pathname.split('/').length - 1]
                        if (lastelemname.toLowerCase() === 'review') {
                            setSelected_category('Review')
                        }
                        else if (lastelemname.toLowerCase() === 'pricelist') {
                            setSelected_category('Pricelist')
                        }
                        else {
                            setSelected_category('Detail')
                        }
                    }
                    catch (e) { setSelected_category('Detail') }

                    setLoading(false)
                })
                .catch((e) => {

                })

        }
        catch (e) {

        }

        if (firsttime) {
            window.addEventListener('resize', updateWindowDimensions)
            window.addEventListener('scroll', updateScroll)
            setFirsttime(false)
        }
        else {

        }

        return () => {
            window.removeEventListener('resize', updateWindowDimensions)
            window.removeEventListener('scroll', updateScroll)
        }

    }, [])

    useEffect(() => {

        setScreenheight(window.innerHeight)
        setScreenwidth(window.innerWidth)
        setDevice(window.innerWidth < TABLET ? 'MOBILE' : window.innerWidth < WEB + 1 ? 'TABLET' : 'WEB')

    }, [window.innerHeight, window.innerWidth])

    // useEffect(()=>{
    //     if(!loading){
    //         try{
    //             let lastelemname = props.history.location.pathname.split('/')[props.history.location.pathname.split('/').length-1]
    //             if(lastelemname.toLowerCase()==='review'){
    //                 setSelected_category('Review')
    //             }
    //         }
    //         catch(e){}
    //     }
    // }, [loading])

    const redirectPage = (vt) => {
        const { history } = props
        if (acct_usertype === 'Wedding' || acct_usertype === 'Event') {
            history.push('../' + acct_usertype + '/' + vt.toLowerCase())
        }
    }

    const genRecommendationdom = () => {
        var doms = []
        if (recommendation) {
            if (recommendation.usernames && recommendation.usernames.length > 0) {
                const { usernames, displaynames, profileimg_ids, vts, svts } = recommendation
                for (let i = 0; i < usernames.length; i++) {
                    if (profileimg_ids && profileimg_ids[i]) {
                        try {
                            const url = getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', profileimg_ids[i].link)
                            const username = usernames[i]
                            doms.push(
                                <div
                                    key={url + i}
                                    className='VL_similarvendor'
                                    onClick={() => document.getElementById(i + '_' + url + '_' + username).click()}
                                >
                                    <img alt={IMG_ALT} src={url} style={{ width: 50, height: 50, borderRadius: 25 }} />
                                    <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                                        <div style={{ whiteSpace: 'nowrap', overflowX: 'hidden', fontSize: 14, display: 'flex' }}>
                                            {displaynames[i]}
                                        </div>
                                        <div style={{ display: 'flex', fontSize: 12, textAlign: 'left', flexDirection: 'column', justifyContent: 'flex-start', color: 'var(--subdark)' }}>
                                            <div style={{ overflowX: 'hidden' }}>{READABLE_VENDORTYPE_MAP[vts[i]]}</div>
                                            {svts[i]
                                                ? <div style={{ overflowX: 'hidden' }}>{READABLE_VENDORTYPE_MAP[svts[i]]}</div>
                                                : <div style={{ textAlign: 'left', color: 'transparent', }}>{'EMPTY'}</div>
                                            }
                                        </div>
                                    </div>
                                    <a
                                        id={i + '_' + url + '_' + username}
                                        style={{ visibility: 'hidden', left: 0, top: 0, border: 'none', outline: 'none', backgroundColor: 'transparent', textAlign: 'center', alignItems: 'center', minWidth: 250, maxWidth: 250, borderRadius: 5, position: 'absolute', textDecoration: 'none' }}
                                        href={'/vendorlookup/' + usernames[i]}
                                        target="_blank"
                                    />
                                </div>
                            )
                        }
                        catch (e) {
                        }
                    }
                }

                return (
                    <>
                        <div style={{ display: 'flex', backgroundColor: 'white', flexDirection: 'column', padding: '30px 0px 40px', }}>
                            {subtitle('Similar Vendors')}
                            <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', WebkitOverflowScrolling: 'touch', margin: '0px 5px' }}>
                                {doms}
                            </div>
                        </div>
                        {SEP_LINE}

                    </>

                )
            }
            else return null
        }

    }

    const RenderContentButtons = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'white', justifyContent: 'center' }}>
                <div style={{ display: 'flex', backgroundColor: 'white', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <button
                        className={`VL_topbtn ${selected_category === 'Detail' ? 'VL_selectedtopbtn' : ''}`}
                        onClick={() => { setSelected_category('Detail') }}>
                        Detail
                    </button>
                    <button
                        className={`VL_topbtn ${selected_category === 'Project' ? 'VL_selectedtopbtn' : ''}`}
                        onClick={() => { setSelected_category('Project') }}>
                        Project
                    </button>
                    <button
                        className={`VL_topbtn ${selected_category === 'Pricelist' ? 'VL_selectedtopbtn' : ''}`}
                        onClick={() => { setSelected_category('Pricelist') }}>
                        Pricelist
                    </button>
                    <button
                        className={`VL_topbtn ${selected_category === 'Review' ? 'VL_selectedtopbtn' : ''}`}
                        onClick={() => { setSelected_category('Review') }}>
                        Review
                    </button>
                    <button
                        className={`VL_topbtn ${selected_category === 'Partner' ? 'VL_selectedtopbtn' : ''}`}
                        onClick={() => { setSelected_category('Partner') }}>
                        Partner
                    </button>
                </div>
            </div>
        )
    }

    const RenderOtherContacts = () => {

        const click_to_chat_btn_state = othercontacts_dialog_link_action === EMPTY

        var renderContactModal = () => <Portalmodal
            portaltype={'commonportal'}
            open={othercontacts_dialog_state}
            onClose={() => { setOthercontacts_dialog_state(false) }}
            floatingclose={false}
            backdropvisible={true}
            modalstyle={{
                width: 250,
                height: 200,
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
            }}
            closestyle={{ right: 0 }}
            floatingclosecolor='#333333'
        >
            <div className='Portalmodal_vtroot' style={{ fontFamily: 'Lato', overflowX: 'hidden', position: 'relative' }} >
                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <div style={{ height: 'max_content', width: 'max_content', display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center', padding: '0px 5px', }}>
                        <div style={{ width: '100%', minHeight: 40, backgroundColor: 'rgb(241, 241, 241)', border: '1px solid var(--subgrey)', borderRadius: 5, padding: 5, margin: 5, display: 'flex', flexDirection: 'column', overflowX: 'hidden', placeContent: 'center' }}>
                            {othercontacts_dialog_content}
                        </div>
                    </div>
                    <button
                        disabled={click_to_chat_btn_state}
                        style={{ ...BUTTONSTYLE, margin: 10, borderRadius: 5, justifyContent: 'center', fontWeight: 'none', textAlign: 'center' }}
                        onClick={() => { window.open(othercontacts_dialog_link_action) }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', placeContent: 'center' }}>
                            <div>
                                {click_to_chat_btn_state ? EMPTY : 'Click to Chat'}
                            </div>
                            <div style={{ paddingLeft: 5, fontSize: 10, display: 'flex', alignItems: 'center', placeContent: 'center', textAlign: 'center' }}>
                                {click_to_chat_btn_state ? 'Click To Chat Unavailable' : othercontacts_dialog_endtextinfo}
                            </div>
                        </div>
                    </button>
                </div>

            </div>
        </Portalmodal>

        var gtwhatsapp = whatsapp_link && whatsapp_link.length > 0
        var gtwechat = wechat_link && wechat_link.length > 0
        var gttelegram = telegram_link && telegram_link.length > 0
        var gtline = line_link && line_link.length > 0

        if (gtwhatsapp || gtwechat || gttelegram || gtline) {
            return (
                <div style={{ minHeight: 60, width: '100%', display: 'flex', placeContent: 'start', flexDirection: 'column', marginBottom: 15 }}>
                    {renderContactModal()}
                    <font style={{ color: 'var(--maindark)', margin: 10, fontSize: 14 }}>Other Contacts</font>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '0px 0 0px 0', color: 'var(--maindark)', alignItems: 'center' }}>
                        {gtwhatsapp
                            ? <div
                                className='VL_iconroot'
                                onClick={() => {
                                    setOthercontacts_dialog_state(true)
                                    setOthercontacts_dialog_content(whatsapp_link)
                                    setOthercontacts_dialog_link_action('https://api.whatsapp.com/send?phone=' + whatsapp_link)
                                    setOthercontacts_dialog_endtextinfo('( Mobile or Web )')
                                }}
                            >
                                {iconwrapper(THINWHATSAPP_ICON, iconcss)}
                            </div>
                            : null}

                        {gtwechat
                            ? <div
                                className='VL_iconroot'
                                onClick={() => {
                                    setOthercontacts_dialog_state(true)
                                    setOthercontacts_dialog_content(wechat_link)
                                    setOthercontacts_dialog_link_action(EMPTY)
                                }}
                            >
                                {iconwrapper(THINWECHAT_ICON, iconcss)}
                            </div>
                            : null}

                        {gttelegram
                            ? <div
                                className='VL_iconroot'
                                onClick={() => {
                                    setOthercontacts_dialog_state(true)
                                    setOthercontacts_dialog_content(telegram_link)
                                    setOthercontacts_dialog_link_action(EMPTY)
                                }}
                            >
                                {iconwrapper(THINTELEGRAM_ICON, iconcss)}
                            </div>
                            : null}

                        {gtline
                            ? <div
                                className='VL_iconroot'
                                onClick={() => {
                                    setOthercontacts_dialog_state(true)
                                    setOthercontacts_dialog_content(line_link)
                                    setOthercontacts_dialog_link_action('line://ti/p/@' + curr_line_link)
                                    setOthercontacts_dialog_endtextinfo(' Web ')
                                }}
                            >
                                {iconwrapper(THINLINE_ICON, iconcss)}
                            </div>
                            : null}

                    </div>
                </div>
            )
        }
        else {
            return (
                <div style={{ minHeight: 60, width: '100%', display: 'flex', placeContent: 'start', flexDirection: 'column', marginBottom: 15 }}>
                    <font style={{ color: 'var(--maindark)', margin: 10, fontSize: 14 }}>Other Contacts</font>
                    <div style={{ height: 20, margin: '0px 15px' }}>-</div>
                </div>
            )
        }

    }

    const RenderSocialMedia = () => {
        return (
            <div style={{ minHeight: 60, width: '100%', display: 'flex', placeContent: 'start', flexDirection: 'column', marginBottom: 15 }}>
                <font style={{ color: 'var(--maindark)', margin: 10, fontSize: 14 }}>Social Media</font>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '0px' }}>
                    {instagram_link.length === 0 && facebook_link.length === 0 && youtube_link.length === 0 && tiktok_link.length === 0 && linkedin_link.length === 0
                        ? <div style={{ height: 20, margin: '0px 15px' }}>-</div>
                        : <>
                            {instagram_link && instagram_link.length > 0
                                ? <div
                                    className='VL_iconroot'
                                    onClick={() => { document.getElementById('VL_insta').click() }}
                                >
                                    <a id='VL_insta' href={instagram_link} target="_blank" style={{ visibility: 'hidden' }} />
                                    {iconwrapper(THININSTAGRAM_ICON, iconcss)}
                                </div>
                                : null}

                            {facebook_link && facebook_link.length > 0
                                ? <div
                                    className='VL_iconroot'
                                    onClick={() => { document.getElementById('VL_fb').click() }}
                                >
                                    <a id='VL_fb' href={facebook_link} target="_blank" style={{ visibility: 'hidden' }} />
                                    {iconwrapper(THINFACEBOOK_ICON, iconcss)}
                                </div>
                                : null}

                            {youtube_link && youtube_link.length > 0
                                ? <div
                                    className='VL_iconroot'
                                    onClick={() => { document.getElementById('VL_yt').click() }}
                                >
                                    <a id='VL_yt' href={youtube_link} target="_blank" style={{ visibility: 'hidden' }} />
                                    {iconwrapper(THINYOUTUBE_ICON, iconcss)}
                                </div>
                                : null}

                            {tiktok_link && tiktok_link.length > 0
                                ? <div
                                    className='VL_iconroot'
                                    onClick={() => { document.getElementById('VL_tiktok').click() }}
                                >
                                    <a id='VL_tiktok' href={tiktok_link} target="_blank" style={{ visibility: 'hidden' }} />
                                    {iconwrapper(THINTIKTOK_ICON, iconcss)}
                                </div>
                                : null}

                            {linkedin_link && linkedin_link.length > 0
                                ? <div
                                    className='VL_iconroot'
                                    onClick={() => { document.getElementById('VL_linkedin').click() }}
                                >
                                    <a id='VL_linkedin' href={linkedin_link} target="_blank" style={{ visibility: 'hidden' }} />
                                    {iconwrapper(THINLINKEDIN_ICON, iconcss)}
                                </div>
                                : null}
                        </>
                    }

                </div>
            </div>

        )
    }

    const RenderInfo = () => {
        return <div className="Vl_inforoot">
            <div className="Vl_infofirst" > {/* CONTACT ICON */}
                <div className="Vl_infoicon">{iconwrapper(RSVPTELEPHONE_ICON, iconcss)}</div>
                <div className="VL_infocontent">
                    {moredom(contacts, () => setShowmore('CONTACTS'), 'CONTACTS')}
                </div>

            </div>
            <div className="Vl_infofirst">{/*EMAIL ICON*/}
                <div className="Vl_infoicon">{iconwrapper(THINEMAIL_ICON, iconcss)}</div>
                <div className="VL_infocontent">
                    {moredom(publicemails, () => setShowmore('PUBLICEMAILS'), 'PUBLICEMAILS')}
                </div>
            </div>
            <div className="Vl_infofirst">{/* LINK ICON */}
                <div className="Vl_infoicon">{iconwrapper(THINLINK_ICON, iconcss)}</div>
                <div className="VL_infocontent">
                    {moredom(website_links, () => setShowmore('WEBSITE_LINKS'), 'WEBSITE_LINKS')}
                </div>
            </div>
            <div className="Vl_infofirst" style={{ paddingBottom: 15 }}>{/* BUILDING ICON */}
                <div className="Vl_infoicon">{iconwrapper(THINLOCATION_ICON, iconcss)}</div>
                <div className="VL_infocontent">{address.length > 0 ? address : '-'}</div>
            </div>
        </div>


    }

    const RenderHeart = () => {
        var { token, _id } = Retrieve_personal_info()
        if (acct_type === 'USER' && token !== EMPTY && _id !== EMPTY) {
            return <div style={{ display: 'flex', height: 48, width: 48, border: '1px solid grey', borderRadius: 50, placeItems: 'center', placeContent: 'center' }}>
                <Heart
                    vendortypeinterface_id={vendortypeinterface_id}
                    vendortype={vendortype}
                    secondaryvendortype={secondaryvendortype}
                    vendortype_id={vendortype_id}
                    userlike={userlike}
                    hoverfunc={false}
                    updateHeart={(userlike_state) => {
                        setUserlike(userlike_state)
                        // alert('hi')
                        // props.updateUserLike(userlike_state)
                    }}
                />
            </div>
        }
        else {
            return <div />
        }
    }

    const RenderHead = (SCREEN) => {

        // sum of elem height within renderHead_vendorlookup must be equal to renderHead_vendorlookup height including of paddings
        return (
            <div key={'renderHead_vendorlookup'} style={{ maxHeight: 460, width: '100%', overflow: 'hidden', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'var(--maindark)', overflowX: 'hidden' }}>

                <div style={{ minHeight: 30, width: '100%', display: 'flex', flexDirection: 'row', padding: '0px 5px', color: 'var(--maindark)', alignItems: 'center' }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                        {COU_ICON}{EMPTYSPACE}{' ' + location}
                    </div>
                    {tier === 0
                        ? null
                        : <div
                            className='VCARD_DIAMONDCONTENT'
                            style={{
                                backgroundImage: `url('https://asset.weavebliss.com/assets/sprites/diamonds.png')`,
                                backgroundPositionY: TIER_DIAMOND_POSITION_VL[tier].y + 'px',
                                width: 20,
                                height: 20,
                                backgroundSize: 20,
                                right: 5,
                            }}
                        />
                    }
                    {/* {tier === 0
                        ? <div className='VCARD_DIAMONDROOT' style={{ position: 'absolute', zIndex: 1 }} />
                        : <div className={`VCARD_DIAMONDROOT VCARD_DIAMONDROOT_${tier}`} style={{ position: 'absolute', zIndex: 1, height: 35, width: 35, top: 0, placeContent: 'center', right: 0, margin: 3, backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                            <div
                                className='VCARD_DIAMONDCONTENT'
                                style={{
                                    backgroundImage: `url('https://asset.weavebliss.com/assets/sprites/diamonds.png')`,
                                    backgroundPositionY: TIER_DIAMOND_POSITION_VL[tier].y + 'px',
                                    width: 20,
                                    height: 20,
                                    backgroundSize: 20,
                                    position: 'absolute'
                                }}
                            />
                        </div>} */}
                    {/* {SCREEN === 'MOBILE' ? <div style={{ display: 'flex' }}>{RenderHeart()}</div> : null} */}
                </div>

                <div style={{ height: 170, padding: 1, width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
                    <img
                        alt={typeof (profileimg_id.link) === 'function' ? '' : getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', profileimg_id.link)}
                        src={typeof (profileimg_id.link) === 'function' ? '' : getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', profileimg_id.link)}
                        style={{ height: 170, padding: 4, width: 170, overflow: 'hidden', border: '1px solid var(--subgrey)', borderRadius: 150 }}
                    />
                </div>

                <div className='VL_displayname'>{displayname}</div>

                <div className="Vl_vts">
                    <button onClick={() => { redirectPage(vendortype) }} style={{ outline: 'none', backgroundColor: 'white', border: 'none' }}>
                        {READABLE_VENDORTYPE_MAP[vendortype]}
                    </button>
                    {secondaryvendortype !== ''
                        ? <button style={{ outline: 'none', backgroundColor: 'white', border: 'none', padding: 0, margin: 0 }}>/</button>
                        : null}
                    {secondaryvendortype !== ''
                        ? <button onClick={() => { redirectPage(secondaryvendortype) }} style={{ outline: 'none', backgroundColor: 'white', border: 'none' }}>
                            {READABLE_VENDORTYPE_MAP[secondaryvendortype]}
                        </button>
                        : null}
                </div>

                {teamnumber === 0 && hosted === 0 && since === 0
                    ? SS_LINE
                    : <div className="VL_numinfo">
                        <span className={`Vl_numsingle`} style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                            <div className="Vl_numsubtitle">Team of</div>
                            <div >{teamnumber > 99 ? '99+' : teamnumber === 0 ? '-' : teamnumber}</div>
                        </span>
                        <span className={`Vl_numsingle`} style={{ borderLeft: 'none', borderRight: 'none', borderRadius: 0, }}>
                            <div className="Vl_numsubtitle">Hosted </div>
                            <div >
                                {hosted > 99 ? '99+' : hosted === 0 ? '-' : hosted}
                                {hosted === 0 ? <div /> : eventorwed === 'Wedding' ? ' Weddings' : eventorwed === 'Both' ? ' W/E' : ' Events'}
                            </div>
                        </span>
                        <span className={`Vl_numsingle`} style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}>
                            <div className="Vl_numsubtitle">Since year</div>
                            <div >
                                {since === 0 ? '-' : since}
                            </div>
                        </span>
                    </div>}

                {/* */}

                {/* <div className="VL_numinfo">
                   
                </div> */}

                {/* <div style={{ height: 140, width: '100%', padding: 5, display: 'flex', alignItems: 'center', wordWrap: 'break-word', textAlign: 'center', justifyContent: 'center' }}>
                    {about}
                </div> */}

                {/* {SS_LINE} */}
            </div>
        )
    }


    // ---------- Enquiry Portal related data END
    const enquire_click = () => {
        setShowenquire(true)
        setEnquiredata([{ displayname, vendortypeinterface_id, telegram_link, whatsapp_link, }])
    }


    const enquire_btn = <button className={`Vendorlookup_enquirebtn Vendorlookup_enquirebtn${device}`} onClick={enquire_click}>Enquire</button>
    const enquiry_portal = <Portalmodal

        portaltype={'commonportal'}
        open={showenquire}
        onClose={() => { setShowenquire(false); setEnquiredata([]) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={{ 'WEB': webtabletportalcss, 'TABLET': webtabletportalcss, 'MOBILE': mobileportalcss }[device]}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <Enquirycontent
            from="Vendorlookup"
            userdata={loggeduserdata}
            data={enquiredata}
            allowchat={true}
            allowtelegram={true}
            allowwhatsapp={true}
            allowemail={true}
            oneormany={'ONE'} // send to ONE or MANY vendors
            vt={vendortype}
            svt={secondaryvendortype}
        />
    </Portalmodal>
    // ---------- Enquiry Portal related data END

    const renderpricelist_dom = <Renderpricelists
        vti_id={vendortypeinterface_id}
        allpricelist_ids={allpricelist_ids}
        loggeduserdata={loggeduserdata}
        vendortype={vendortype}
        secondaryvendortype={secondaryvendortype}
        whatsapp_link={whatsapp_link}
        telegram_link={telegram_link}
    >
        Pricelist
    </Renderpricelists>

    const RenderWeb = (recommendation_dom) => {

        if (!loading && ready) { // LOADED
            const takeover_dom = vendorwhocontrol === 'WEAVEBLISS'
                ? <div style={{ backgroundColor: '#ffffff' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: 10, border: '1px solid var(--maindark)', borderRadius: 10, margin: 10, backgroundColor: '#ffffff' }}>
                        <div>Are you owner of this page?</div>
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', placeItems: 'flex-start', marginTop: 5 }}>
                            <div style={{ textAlign: 'start' }}>Claim your listing for free, create pricelist, update your profile and much more.</div>
                            <button
                                style={{ width: 150, minWidth: 100, height: 24, color: 'var(--maindark)', backgroundColor: '#ffffff', border: '1px solid var(--maindark)', borderRadius: 10, marginTop: 5 }}
                                onClick={() => {
                                    window.open(WHATSAPP_MSG_CONTENT(displayname))
                                }}>
                                Whatsapp Us
                            </button>
                        </div>
                    </div>
                </div>
                : null
            return (
                <div style={{ display: 'flex', justifyContent: 'center', height: 'max-content', backgroundColor: '#eeeeee', overflowX: 'hidden', position: 'relative', borderTop: '1px solid var(--lightdark)', }}>{/*  maxWidth: '-webkit-fill-available'   */}
                    <div style={{ minHeight: screenheight - NAV_BAR_HEIGHT, width: 'max-content', display: 'flex', flexDirection: 'column', backgroundColor: '#eeeeee', margin: '50px 50px 120px', overflowX: 'hidden' }}>
                        <div className="VL_btmbar VL_btmbarWEB" >
                            {RenderHeart()}
                            {enquire_btn}
                        </div>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            overflowX: 'hidden',
                            width: screenwidth > MAX_WEB ? '' : WEB_LEFT_ROOT_WIDTH + screenwidth - WEB_LEFT_ROOT_WIDTH - 125
                        }}>
                            <div style={{
                                // maxHeight: 760, minHeight: 760,
                                // maxHeight: 650, minHeight: 650,
                                height: 'max-content',
                                minWidth: WEB_LEFT_ROOT_WIDTH - 10,
                                maxWidth: WEB_LEFT_ROOT_WIDTH - 10,
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: 'white',
                                position: scrollinglock ? 'fixed' : "relative",
                                top: scrollinglock ? 0 : null,
                            }}>
                                {RenderHead('WEB')}
                                {RenderInfo()}
                                {SS_LINE}
                                {RenderOtherContacts()}
                                {SS_LINE}
                                {RenderSocialMedia()}
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: 'min-content',
                                width: screenwidth - WEB_LEFT_ROOT_WIDTH - 125,
                                maxWidth: MAX_WEB,
                                boxShadow: 'rgba(25, 25, 25, 0.2) 1px 1px 5px',
                                backgroundColor: 'white',
                                marginLeft: scrollinglock ? WEB_LEFT_ROOT_WIDTH : 10,
                            }}>
                                <div style={{ height: 50, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', borderBottom: '1px solid var(--lightdark)', alignItems: 'center' }}>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{RenderContentButtons()}</div>
                                </div>
                                <div style={{ backgroundColor: 'white', minHeight: 750, height: 'max-content' }}>
                                    {selected_category === 'Detail'
                                        ? <Renderdetail
                                            eventorwed={eventorwed}
                                            description={description}
                                            pri_specific={pri_specific}
                                            sec_specific={sec_specific}
                                            location={location}
                                            vendortype={vendortype}
                                            secondaryvendortype={secondaryvendortype}
                                            latlong={latlong}
                                            qnsans={qnsans}
                                            gtsvt={gtsvt}
                                            location_pricepresence_map={location_price_map}
                                            acct_location={acct_location}
                                            showmap={showmap}
                                        >
                                            Detail
                                        </Renderdetail>
                                        : null}
                                    {selected_category === 'Project'
                                        ? <Renderprojects projectinterface_ids={projectinterface_ids} screenwidth={screenwidth}>Project</Renderprojects>
                                        : null}
                                    {selected_category === 'Pricelist'
                                        ? renderpricelist_dom
                                        : null}
                                    {selected_category === 'Review'
                                        ? <Renderreviews
                                            device={'WEB'} displayname={displayname} acct_type={acct_type} vendortypeinterface_id={vendortypeinterface_id} owner={owner}
                                            review_ids={review_ids} vendortype={vendortype} secondaryvendortype={secondaryvendortype} hideenquireminspend={() => this.setState({ mobile_hideenquireminspend: false })}>
                                            Review
                                        </Renderreviews>
                                        : null}
                                    {selected_category === 'Partner'
                                        ? <Renderpartners displayname={displayname} vendortype={vendortype}>Partner</Renderpartners>
                                        : null}
                                </div>
                                {selected_category === 'Project' || selected_category === 'Pricelist' || selected_category === 'Review' || selected_category === 'Partner'
                                    ? SS_LINE
                                    : null}
                                {recommendation_dom}
                                {takeover_dom}
                            </div>
                        </div>
                    </div>
                    {moredom_portal}
                    {enquiry_portal}
                </div>
            )
        }
        else if (loading) { // CONTENT LOADING
            return <Loading />
        }
        else { //NO SUCH USER
            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '100vh', alignContent: 'center', touchAction: 'manipulation', alignItems: 'center', color: 'var(--maindark)', fontWeight: 'bold' }}>
                    <div>No Such Vendor</div>
                </div>
            )
        }

    }

    const RenderMobileTablet = (recommendation_dom, device) => {
        const width_ = screenwidth > TABLET ? 720 : '100%'
        const height_ = { 'WEB': 80, 'TABLET': 80, 'MOBILE': 80 }[device]

        const STICK_CSS = !mobile_hideenquireminspend
            ? { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', position: 'fixed', height: height_, width: '100%', backgroundColor: 'white', zIndex: 1040, bottom: 0, boxShadow: '0 -3px 8px 0 rgba(37, 37, 37, .1)' }
            : { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }

        if (!loading && ready) {

            const takeover_dom = vendorwhocontrol === 'WEAVEBLISS'
                ? <div style={{ backgroundColor: '#ffffff' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: 10, border: '1px solid var(--maindark)', borderRadius: 10, margin: 10, backgroundColor: '#ffffff' }}>
                        <div>Are you owner of this page?</div>
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', placeItems: 'flex-start', marginTop: 5 }}>
                            <div style={{ textAlign: 'start' }}>Claim your listing for free, create pricelist, update your profile and much more. </div>
                            <button
                                style={{ width: 150, minWidth: 100, height: 24, color: 'var(--maindark)', backgroundColor: '#ffffff', border: '1px solid var(--maindark)', borderRadius: 10, marginTop: 5 }}
                                onClick={() => {
                                    window.open(WHATSAPP_MSG_CONTENT(displayname))
                                }}>
                                Whatsapp Us
                            </button>
                        </div>
                    </div>
                </div>
                : null

            return (
                <div style={{ backgroundColor: '#eeeeee', justifyContent: 'center', touchAction: 'manipulation' }}>
                    <div style={{ display: 'flex', alignSelf: 'center', flexDirection: 'column' }}>
                        <div style={{ height: '270px', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', width: width_, height: 'max-content', backgroundColor: 'white', alignSelf: 'center' }}>
                            {RenderHead('MOBILE')}
                            {RenderInfo()}
                            {SS_LINE}
                            {RenderOtherContacts()}
                            {SS_LINE}
                            {RenderSocialMedia()}
                            {SS_LINE}
                            <div style={STICK_CSS}>
                                {RenderHeart()}
                                {enquire_btn}
                            </div>
                        </div>
                        <div style={{ width: width_, margin: '5px 0px 0px 0px', display: 'flex', alignSelf: 'center', flexDirection: 'column', overflowX: 'hidden' }}>
                            {RenderContentButtons()}
                            {SS_LINE}
                            <div style={{ backgroundColor: 'white', minHeight: 720 }}>
                                {selected_category === 'Detail' ? <Renderdetail eventorwed={eventorwed} description={description} pri_specific={pri_specific} sec_specific={sec_specific} location={location} vendortype={vendortype} secondaryvendortype={secondaryvendortype} latlong={latlong} qnsans={qnsans} gtsvt={gtsvt} location_pricepresence_map={location_price_map} acct_location={acct_location}>Detail</Renderdetail> : null}
                                {selected_category === 'Project' ? <Renderprojects projectinterface_ids={projectinterface_ids} screenwidth={screenwidth}>Project</Renderprojects> : null}
                                {selected_category === 'Pricelist' ? renderpricelist_dom : null}
                                {selected_category === 'Review' ? <Renderreviews device={device} displayname={displayname} acct_type={acct_type} vendortypeinterface_id={vendortypeinterface_id} owner={owner} review_ids={review_ids} vendortype={vendortype} secondaryvendortype={secondaryvendortype}>Review</Renderreviews> : null}
                                {selected_category === 'Partner' ? <Renderpartners displayname={displayname} vendortype={vendortype}>Partner</Renderpartners> : null}
                            </div>
                            {selected_category === 'Project' || selected_category === 'Pricelist' || selected_category === 'Review' || selected_category === 'Partner'
                                ? SS_LINE
                                : null}
                            {recommendation_dom}
                            {takeover_dom}
                            <div style={{ height: 90 }} />
                        </div>
                    </div>
                    {moredom_portal}
                    {enquiry_portal}
                </div>
            )
        }
        else if (loading) { // content must be loading
            return <Loading />
        }
        else {
            return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '100vh', alignContent: 'center', touchAction: 'manipulation', alignItems: 'center', fontWeight: 'bold' }}>
                <div>No Such Vendor</div>
            </div>
        }
    }

    const moredom_portal = <Portalmodal
        portaltype={'commonportal'}
        open={showmore.length > 0}
        onClose={() => { setShowmore(EMPTY) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={{
            width: { 'WEB': 400, 'TABLET': 400, 'MOBILE': 260 }[device],
            height: { 'WEB': 500, 'TABLET': 400, 'MOBILE': 400 }[device],
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
        }}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className='Portalmodal_vtroot' style={{ fontFamily: 'Lato', overflowX: 'hidden', position: 'relative' }} >
            <div className='Rsvpexplorer_portaltitle' style={{ top: 0, left: 0, position: 'absolute' }}>
                {{
                    'CONTACTS': 'Contacts',
                    'PUBLICEMAILS': 'Emails',
                    'WEBSITE_LINKS': 'Links',
                }[showmore]}
            </div>
            <div className={'VL_moreportalcontent' + device}>
                {showmore
                    ? {
                        'CONTACTS': contacts,
                        'PUBLICEMAILS': publicemails,
                        'WEBSITE_LINKS': website_links,
                    }[showmore].map((item, index) => {
                        const { title, content } = item
                        return <div
                            className='VL_moreitemroot'
                            style={{ borderBottom: index === { 'CONTACTS': contacts, 'PUBLICEMAILS': publicemails, 'WEBSITE_LINKS': website_links }[showmore].length - 1 ? 'none' : '1px solid var(--subgrey)' }}
                            onClick={() => { if (showmore === 'WEBSITE_LINKS') document.getElementById('link_' + index).click() }}
                        >
                            {{
                                'CONTACTS': iconwrapper(RSVPTELEPHONE_ICON, iconcss_lg),
                                'PUBLICEMAILS': iconwrapper(THINEMAIL_ICON, iconcss_lg),
                                'WEBSITE_LINKS': iconwrapper(THINLINK_ICON, iconcss_lg)
                            }[showmore]}

                            {showmore === 'WEBSITE_LINKS'
                                ? <a id={'link_' + index} href={'https://' + content} target="_blank" style={{ visibility: 'hidden' }} />
                                : null}

                            <div className='VL_titlecontentroot'>
                                <div className='VL_moretitle'>{title}</div>
                                {showmore === 'CONTACTS'
                                    ? <div className='VL_morecontent'>
                                        {content.length === 10 && content[0] === '6' && content[1] === '5'
                                            ? ('+65 ') + content.slice(2)
                                            : <div className='VL_morecontent'>{content}</div>}
                                    </div>
                                    : <div className='VL_morecontent'>{content}</div>}

                            </div>
                        </div>
                    })
                    : null}
            </div>
        </div>
    </Portalmodal>

    const recommendationdom = genRecommendationdom()

    return loading
        ? <Loading />
        : device !== 'WEB'
            ? RenderMobileTablet(recommendationdom, device)
            : RenderWeb(recommendationdom)


}

export default Vendorlookup