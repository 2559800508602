import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { describe_dom, partialtitle_dom, radiobtn_dom, twobtn_dom } from "../Commonelements/Commonelements"
import Success from './components/Success'

function Settingcontent(props) {
    const { settingsavingstate } = props
    const [guestlistsettings, setGuestlistsettings] = useState({})
    const [counter, setCounter] = useState(0)
    useEffect(() => {
        if (props) {
            setGuestlistsettings(props.guestlistsettings)
        }
    }, [props])

    const updateCellsize = (e) => {
        setGuestlistsettings((guestlistsettings) => {
            guestlistsettings['cellsize'] = guestlistsettings['cellsize'] === 0 ? 1 : 0
            setCounter(counter + 1)
            return guestlistsettings
        })
    }

    const updateSticky = (e) => {
        setGuestlistsettings((guestlistsettings) => {
            guestlistsettings['sticky'] = guestlistsettings['sticky'] === 0 ? 1 : 0
            setCounter(counter + 1)
            return guestlistsettings
        })
    }

    const general_dom = <div className='Rsvpexplorer_portalcontent'>
        {describe_dom('Update setting for your guestlist.')}
        <div style={{ display: 'grid', gridTemplateColumns: isMobile ? '100%' : '50% 50%', padding:'0px 10px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', margin: '0px 0px 15px 0px' }}>
                {partialtitle_dom('Cell Size')}
                <div style={{ height: 5 }} />
                {twobtn_dom(
                    'Small',
                    'Large',
                    'Small',
                    'Large',
                    ['Small', 'Large'][guestlistsettings['cellsize']],
                    updateCellsize,
                    {}
                )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', margin: '0px 0px 15px 0px' }}>
                {partialtitle_dom('Fixed Columns')}
                <div style={{ height: 5 }} />
                {radiobtn_dom(guestlistsettings['sticky'], updateSticky, ['Off', 'On'][guestlistsettings['sticky']])}
            </div>
        </div>
        <button className='Rsvpexplorer_portalsave' onClick={() => { props.save(guestlistsettings) }} disabled={settingsavingstate === 'SAVING'}>
            {{ // IDLE, SAVING, SUCCESS, FAILURE
                'IDLE': 'Save',
                'SAVING': 'Saving...',
                'SUCCESS': 'Success',
                'FAILURE': 'Error Occurred, please try again later.'
            }[settingsavingstate]}
        </button>
    </div>

    const success_dom = <Success txt={'Settings updated. Click to return.'} onClick={props.close} />

    return guestlistsettings
        ? <div className='Rsvpexplorer_portal'>
            <div className='Rsvpexplorer_portaltitle'>Settings</div>
            {settingsavingstate !== 'SUCCESS' ? general_dom : success_dom}
        </div>
        : null
}
export default Settingcontent